import { useSelector } from 'react-redux';

import appStore from '../../../../redux/stores/app.store';
import { useFormatMessage } from '../../../../intl/intl';
import DateTimeUtils from '../../../../utils/DateTimeUtils';
import { AppStoreSelectors as appSelector } from '../../../../redux/selectors/app-store-selectors';

import type { ExpiredAt } from '../types';

export function useExpiredAtFormatted(expiredAt: ExpiredAt) {
  const zoneId = appStore.getTz();
  const hourLabel = useFormatMessage({ id: 'globals.hours' }).toLowerCase();
  const minuteLabel = useFormatMessage({ id: 'globals.minutes' }).toLowerCase();
  const language: 'es' | 'pt' = useSelector(appSelector.selectLanguage) ?? 'es';
  const tDiff = -DateTimeUtils.getDiffFromNow(expiredAt, 'hours', zoneId, true);
  if (Number.isNaN(tDiff)) return '';
  const argsToLocalDate = [expiredAt, 'MMM DD, YYYY, hh:mm:ss A', language];
  let result: string = DateTimeUtils.getLocaleDateString(...argsToLocalDate);
  if (tDiff >= 24.0) result = result.split(',', 2).join(',');
  else if (tDiff > 1) result = `${Math.round(tDiff)} ${hourLabel}`;
  else if (tDiff > 0) result = `${Math.floor(tDiff * 60.0)} ${minuteLabel}`;
  return result;
}
