import { useDispatch } from 'react-redux';
import { useTheme, useMediaQuery } from '@material-ui/core';

import hooks from './hooks';
import { useFormatMessage } from '../../../../intl/intl';
import dialogsManagerActions from '../../../../redux/actions/dialogsManager.actions';

import { ArticleDetail } from '../article-detail';
import { CategoryDetail } from '../category-detail';
import { CategoryButton } from '../categories-button';
import { SubCategoryMobile } from '../subcategory-mobile';
import { CustomBanner } from '../../../common/custom-banner';

import { textIds } from '../../consts';

import useStyles from './style';

export function HelpCenterBody() {
  const componentClasses = useStyles();
  const selectedArticle = hooks.useSelectedArticle();
  const selectedCategoryId = hooks.useSelectedCategoryId();
  const theme = useTheme();
  const dispatch = useDispatch();
  const image = hooks.useImageUrl(theme);

  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const bannerTitle = useFormatMessage(textIds.bannerTitle);
  const bannerSubTitle = useFormatMessage(textIds.bannerSubtitle);
  const bannerDescription = useFormatMessage(textIds.bannerDescription);

  const handleOpen = () => {
    const source = 'help_center';
    dispatch(dialogsManagerActions.openOnBoardingDialog(source));
  };

  return (
    <div className={componentClasses.root}>
      <CustomBanner
        onClick={handleOpen}
        backgroundImg={image}
        subTitle={bannerSubTitle}
        description={bannerDescription}
        title={bannerTitle}
      />
      <div className={componentClasses.contentContainer}>
        {!isMdUp && !selectedCategoryId && !selectedArticle && (
          <CategoryButton />
        )}
        {!isMdUp && !!selectedCategoryId && !selectedArticle && (
          <SubCategoryMobile />
        )}
        {!isMdUp && !!selectedCategoryId && selectedArticle && (
          <ArticleDetail />
        )}
        {isMdUp && !!selectedCategoryId && (
          <>
            {!selectedArticle && <CategoryDetail />}
            {selectedArticle && <ArticleDetail />}
          </>
        )}
      </div>
    </div>
  );
}
