import { clsx } from 'clsx';

import { PickupLabel } from '../pickup-label';
import RTStateLabel from '../rt-state-label';
import { MarketplaceLabel } from '../marketplace-label';

import { OrderDeliveryMethodType } from '../../constants/OrderDeliveryMethodType';
import useStyles from './DeliveryMethodLabel.styles';

const getDeliveryMethodComponent = deliveryMethod => {
  switch (deliveryMethod) {
    case OrderDeliveryMethodType.MARKETPLACE:
      return MarketplaceLabel;
    case OrderDeliveryMethodType.PICKUP:
      return PickupLabel;
    case OrderDeliveryMethodType.DELIVERY:
    default:
      return RTStateLabel;
  }
};

export function DeliveryMethodLabel(props) {
  const { deliveryMethod, classes = {}, ...extraProps } = props;
  const _classes = useStyles();
  const DeliveryMethodComponent = getDeliveryMethodComponent(deliveryMethod);
  return (
    <DeliveryMethodComponent
      classes={{
        root: clsx(_classes.root, classes?.root),
        iconRoot: clsx(_classes.iconRoot, classes?.iconRoot),
      }}
      {...extraProps}
    />
  );
}
