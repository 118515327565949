const confirmButtonText = {
  id: 'globals.confirm',
};

const label = {
  id: 'globals.minutes',
};

const title = {
  id: 'rtAssignedIssueDialog.title',
};
const description = {
  id: 'rtAssignedIssueDialog.description',
};

const textIds = {
  confirmButtonText,
  description,
  label,
  title,
};

export default textIds;
