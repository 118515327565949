import { addRTAction, addRTSelector } from '../../store';
import { dispatchAction } from '../../../../shared/infra';

import type { OnCreateAddRTParams } from '../../../domain';

export function addRTCreateHandler() {
  function closeDialog() {
    dispatchAction(addRTAction.closeDialog());
  }

  function createAddRT(params: OnCreateAddRTParams) {
    dispatchAction(addRTAction.create(params));
  }

  return {
    addRT: {
      status: addRTSelector.status(),
      selectedOrderId: addRTSelector.selectedOrderId(),
      validatedData: addRTSelector.validatedData(),
      createdOrderId: addRTSelector.createdAddRT()?.orderId ?? null,
    },
    closeDialog,
    createAddRT,
  };
}
