const acceptanceDateLabel = { id: 'orderBill.acceptanceDateLabel' };

const commentsLabel = { id: 'comments' };

const paymentMethodLabel = { id: 'orderBill.paymentMethodLabel' };

const deliveryPrimeLabel = { id: 'orderBill.deliveryPrimeLabel' };

const printingDateLabel = { id: 'orderBill.printingDateLabel' };

const retryOrderTotalsLabel = { id: 'orderBill.retryOrderTotalsLabel' };

const userAddressLabel = { id: 'orderBill.userAddressLabel' };

const userPhoneLabel = { id: 'orderBill.userPhoneLabel' };

const zipCodeLabel = { id: 'orderBill.zipCodeLabel' };

const deliveryMethodLabel = { id: 'orderBill.deliveryMethodLabel' };

const pickupLabel = { id: 'orderBill.pickupLabel' };

const replaceTextLabel = { id: 'suggestedProductComment.textLabel' };

const replaceProductLabel = { id: 'suggestedProductComment.replaceProduct' };

const neighborhoodLabel = {
  id: 'orderDetailDialogCustomerBox.neighborhoodLabel',
};

const deliveryLabel = { id: 'ordersKanbanTabs.pendingToDeliver.mobile' };

const scanQrLabel = { id: 'orderBill.scanQrLabel' };

const exclusiveUseLabel = { id: 'orderBill.exclusiveUseLabel' };

const deliveryCodeLabel = { id: 'orderBill.deliveryCodeLabel' };

const textIds = {
  acceptanceDateLabel,
  commentsLabel,
  deliveryMethodLabel,
  paymentMethodLabel,
  deliveryPrimeLabel,
  pickupLabel,
  printingDateLabel,
  retryOrderTotalsLabel,
  userAddressLabel,
  userPhoneLabel,
  zipCodeLabel,
  replaceTextLabel,
  replaceProductLabel,
  neighborhoodLabel,
  deliveryLabel,
  scanQrLabel,
  exclusiveUseLabel,
  deliveryCodeLabel,
};

export default textIds;
