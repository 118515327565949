import React from 'react';
import { Grid } from '@material-ui/core';

import { useStyles } from './DeactivatedProductsStores.styles';
import { DeactivatedProductsStoreBox } from '../deactivated-store-box';

export function DeactivatedProductsByStores({
  deactivatedProductsAllStores,
}: Props) {
  const styles = useStyles();

  return (
    <Grid container className={styles.storeBoxes} spacing={1}>
      {deactivatedProductsAllStores.map((store: any) => (
        <DeactivatedProductsStoreBox
          key={store.storeId}
          storeName={store.storeName}
          deactivatedProductsQuantity={store.products}
          deactivatedToppingsQuantity={store.toppings}
        />
      ))}
    </Grid>
  );
}
interface Props {
  deactivatedProductsAllStores: any;
}
