import { makeStyles } from '@material-ui/core';

import { colorTheme } from '../../shared/styles/colors';

import type { RTStatus } from '../../business/shared/domain';

export default makeStyles({
  chip: ({ rtStatus }: Props) => ({
    height: 'inherit',
    padding: '0.25rem 1rem',
    color: colorTheme.rtStatus[rtStatus].text,
    backgroundColor: colorTheme.rtStatus[rtStatus].bg,
  }),
  label: {
    padding: 0,
    fontWeight: 600,
    fontSize: '0.625rem',
    lineHeight: '1.125rem',
  },
});

interface Props {
  rtStatus: RTStatus;
}
