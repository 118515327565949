import { makeStyles } from '@material-ui/core';

import Colors from '../../../../../../constants/Colors';
import { neutral } from '../../../../../../shared/styles/colors';

export const componentsStyle = makeStyles(() => ({
  description: {
    fontSize: '1rem',
    whiteSpace: 'normal',
    paddingTop: '1rem',
  },
  logo: {
    height: '4.25rem',
    width: '4rem',
    borderRadius: '.5rem',
  },
  logoChrome: {
    height: '4rem',
    width: '4rem',
  },
  logoAliados: {
    height: '5rem',
    width: '2.9rem',
  },
  stepWrapper: {
    background: neutral.D4,
    borderRadius: '0.75rem',
  },
  step: {
    fontSize: '0.875rem',
    fontWeight: 600,
    backgroundColor: Colors.DODGER_BLUE,
    color: 'white',
    width: '2rem',
    '& >.MuiChip-label': {
      padding: '0',
    },
  },
  stepText: {
    fontSize: '0.875rem',
  },
  stepHighlight: {
    color: Colors.DODGER_BLUE,
    fontWeight: 600,
    textDecoration: 'underline',
  },
}));
