import { subscribeEvent, publishEvent, unsubscribeEvent } from '../../libs';

function openStoreSuspended() {
  const EVENT_NAME = 'OPEN_DIALOG_STORE_SUSPENDED';
  let theListener: ListenerFN = _ => undefined;
  return {
    dispatch: (d: DetailOpenStoreSuspended) => publishEvent(EVENT_NAME, d),
    remove: () => unsubscribeEvent(EVENT_NAME, theListener),
    add: (lt: ListenerFN) => {
      theListener = lt;
      subscribeEvent(EVENT_NAME, theListener);
    },
  };
}

export const dialogStoreSuspended = {
  open: openStoreSuspended(),
};

type ListenerFN = (event: EventStoreSuspended) => void;

type EventStoreSuspended = CustomEvent<DetailOpenStoreSuspended>;

interface DetailOpenStoreSuspended {
  storeId: number;
}
