import { createSlice } from '@reduxjs/toolkit';

import type { PrinterStartupNotifications } from '../domain';

const INITIAL_STATE: PrinterStartupNotifications = {
  isNotified: {
    firstMode: false,
    secondMode: false,
  },
};

const printerSlice = createSlice({
  name: 'printer',
  initialState: INITIAL_STATE,
  reducers: {
    setIsNotifiedFirstMode: state => {
      state.isNotified.firstMode = true;
    },
    setIsNotifiedSecondMode: state => {
      state.isNotified.secondMode = true;
    },
  },
});

export const PRINTER_SLICE_NAME = printerSlice.name;
export const printerReducer = printerSlice.reducer;
export const printerActions = printerSlice.actions;
