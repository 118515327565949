import 'date-fns';
import { clsx } from 'clsx';
import { useState } from 'react';
import { Grid } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { ArrowDropDown } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';

import { useTexts } from './hooks';
import { transformDateToString, transformStringToDate } from '../utils';

import {
  KEYBOARD_DATE_PICKER_PROPS,
  MAX_DAYS_TO_DEACTIVATE_ITEMS,
} from '../../constants';
import useStyles from './CustomDeactivationDatePicker.styles';

import type { ICustomDeactivationDatePicker } from '../../types';

export function CustomDeactivationDatePicker(
  props: ICustomDeactivationDatePicker,
) {
  const {
    deactivationDate = '',
    handleDeactivationDate = () => {},
    handleRadioChange = () => {},
  } = props;

  const buttonText = useTexts(deactivationDate);
  const _classes = useStyles();

  const [open, setOpen] = useState(false);

  let maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + MAX_DAYS_TO_DEACTIVATE_ITEMS);

  const handleChange = (date: Date | null) => {
    if (date === null) {
      handleRadioChange('');
      return;
    }
    const Date = transformDateToString(date);
    handleDeactivationDate(Date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container>
        <div
          className={clsx(
            _classes.selectDateButton,
            !!deactivationDate && _classes.selectDateButtonActive,
          )}
          onClick={event => {
            event.stopPropagation();
            if (!open) {
              setOpen(true);
            }
          }}
        >
          <span>
            {deactivationDate ? buttonText.onSelected : buttonText.initial}
          </span>
          <KeyboardDatePicker
            open={open}
            onClose={() => {
              setTimeout(() => setOpen(false), 10);
            }}
            disableToolbar
            variant={KEYBOARD_DATE_PICKER_PROPS.variant}
            format={KEYBOARD_DATE_PICKER_PROPS.format}
            margin={KEYBOARD_DATE_PICKER_PROPS.margin}
            id={KEYBOARD_DATE_PICKER_PROPS.id}
            label={KEYBOARD_DATE_PICKER_PROPS.label}
            value={transformStringToDate(deactivationDate) || new Date()}
            onChange={handleChange}
            KeyboardButtonProps={KEYBOARD_DATE_PICKER_PROPS.KeyboardButtonProps}
            disablePast
            maxDate={maxDate}
            keyboardIcon={<ArrowDropDown />}
            TextFieldComponent={props => (
              <TextField
                {...props}
                type={KEYBOARD_DATE_PICKER_PROPS.TextFieldType}
                className={_classes.keyboardDatePickerTextField}
              />
            )}
            onFocus={() => {}}
            onBlur={() => {}}
          />
        </div>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
