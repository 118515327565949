import { cargoKanbanHandler } from '../../infra';

// TODO: should be being called from the domain layer
// TODO: example: infra/core can called a domain/contracts
import type {
  CancelCostOrderRequest,
  CancelOrderRequest,
  OrdersRequest,
} from '../../infra/core/requests';
import type { IKanbanCase } from '../../domain';
import type { CancelOrderStatus, CargoOrderFilters } from '../../domain';

export function CargoKanbanCase(): IKanbanCase {
  const {
    dispatch,
    getOrders,
    getKanbanStatus,
    getCancelCostOrder,
    getCancelCostOrderStatus,
    getCancelCargoOrderStatus,
    requestCargoOrderList,
    requestCancelOrderCost,
    requestCancelOrder,
    getLastUpdate,
    getOrderFilters,
  } = cargoKanbanHandler();
  const {
    onSetCancelOrderStatus,
    onSetLastUpdate,
    onSetFilterOrders,
  } = dispatch();
  const orders = getOrders();
  const status = getKanbanStatus();
  const cancelCost = getCancelCostOrder();
  const cancelCostStatus = getCancelCostOrderStatus();
  const cancelCargoOrderStatus = getCancelCargoOrderStatus();
  const lastUpdate = getLastUpdate();
  const orderFilters = getOrderFilters();

  const isCancelCostLoading = cancelCostStatus === 'isLoading';
  const isCancelCargoOrderLoading = cancelCargoOrderStatus === 'isLoading';
  const isCancelOrderSuccessful = cancelCargoOrderStatus === 'successful';
  const isCancelOrderRejected = cancelCargoOrderStatus === 'rejected';

  const storeIdSelected: number = 0;

  const fetchOrders = (value: OrdersRequest) => requestCargoOrderList(value);

  const requestCancelCost = (value: CancelCostOrderRequest) =>
    requestCancelOrderCost(value);

  const cancelOrder = (value: CancelOrderRequest) => requestCancelOrder(value);

  const setCancelOrderStatus = (value: CancelOrderStatus) =>
    onSetCancelOrderStatus(value);

  const setLastUpdate = (value: string) => onSetLastUpdate(value);

  const setFilterOrders = (value: CargoOrderFilters) =>
    onSetFilterOrders(value);

  return {
    status,
    orders,
    cancelCost,
    storeIdSelected,
    fetchOrders,
    requestCancelCost,
    cancelCostStatus,
    cancelOrder,
    cancelCargoOrderStatus,
    setCancelOrderStatus,
    setLastUpdate,
    isCancelCostLoading,
    isCancelCargoOrderLoading,
    isCancelOrderSuccessful,
    isCancelOrderRejected,
    lastUpdate,
    orderFilters,
    setFilterOrders,
  };
}
