import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  printerIsNotifiedFirstService,
  printerIsNotifiedSecondService,
} from '../../infra';
import { useStartupPrinterBannerLang } from './useStartupPrinterBannerLang';

import { PartnerId } from 'business/shared/domain';
import { printerAnalytics } from 'features/startup-notifications/infra/analytics';
import paths from '../../../../../constants/Paths';

export function useStartupPrinterBanner() {
  const [show, setShow] = useState<boolean>(false);

  const {
    isNotifiedSecondMode,
    setIsNotifiedSecondMode,
  } = printerIsNotifiedSecondService();
  const { isNotifiedFirstMode } = printerIsNotifiedFirstService();
  const partnerId: PartnerId = useSelector(
    ({ partnerReducer }: any) => partnerReducer?.profile?.id,
  );

  const lang = useStartupPrinterBannerLang();

  const _handleOnDismiss = () => {
    setIsNotifiedSecondMode();
    setShow(false);
  };

  const handlePrimaryBtn = () => {
    _handleOnDismiss();
    printerAnalytics.logPrinterCampaign({
      partnerId,
      clickOptions: 'know_more',
      type: 'banner',
    });
  };
  const handleClose = () => {
    _handleOnDismiss();
    printerAnalytics.logPrinterCampaign({
      partnerId,
      clickOptions: 'close',
      type: 'banner',
    });
  };

  useEffect(() => {
    if (isNotifiedFirstMode && !isNotifiedSecondMode) setShow(true);
  }, [isNotifiedFirstMode, isNotifiedSecondMode]);

  return {
    show,
    lang,
    goTo: {
      cta: paths.goToHelpCenterWithCategoryAndArticle(
        '5f20af6cefb229001249e6ff',
        '5f50188118dc5d001a78d0f5',
      ),
    },
    handlePrimaryBtn,
    handleClose,
  };
}
