import { useEffect } from 'react';

import { useCancelModalLang } from './useCancelModalLang';
import { CargoKanbanCase } from '../../../../../../../../../../../business/cargo';

import type { OrderId } from '../../../../../../../../../../../business/shared/domain';

export function useCancelModal(props: CancelDialogProps) {
  const { orderId, onClose } = props;

  const {
    cancelCost,
    isCancelCargoOrderLoading,
    isCancelCostLoading,
    cancelOrder,
    requestCancelCost,
  } = CargoKanbanCase();

  const lang = useCancelModalLang({ orderId });

  function onCancelCargoOrder() {
    cancelOrder({ orderId });
    onClose();
  }

  useEffect(() => {
    requestCancelCost({ orderId });
  }, [orderId]);

  return {
    lang,
    cancelCost,
    isCancelCostLoading,
    isCancelCargoOrderLoading,
    onCancelCargoOrder,
  };
}

interface CancelDialogProps {
  orderId: OrderId;
  onClose: () => void;
}
