import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { orderMgmtSearchAdapter } from '../adapters/orderMgmt-search.adapter';

import { ItemSuggestion, orderMgmtStatus } from '../../domain/models';
import { ItemSearchRequest } from '../core/requests';
import { searchRepository } from '../repositories';

export interface OrderMgmtItemSearch {
  isOpen: boolean;
  status: orderMgmtStatus;
  search: {
    results: ItemSuggestion[];
    itemCategoryIndex?: number;
    itemCategoryDescription?: string;
  };
  searchAttempts: number;
}

const initialState: OrderMgmtItemSearch = {
  isOpen: false,
  status: orderMgmtStatus.Idle,
  search: { results: [], itemCategoryIndex: 0, itemCategoryDescription: '' },
  searchAttempts: 0,
};

export const searchOrderItem = createAsyncThunk(
  'orderMgmtItemSearchDialog/searchOrderMgmtItem',
  async (payload: ItemSearchRequest) => {
    const response = await searchRepository.getBy(payload);
    const dataItems = payload?.is_product
      ? response?.data?.products
      : response?.data?.toppings?.toppings;
    return {
      results: (dataItems || []).map(item =>
        orderMgmtSearchAdapter.toItemSearchResult(item),
      ),
      ...(!payload?.is_product &&
        response?.data?.toppings && {
          itemCategoryIndex: response?.data?.toppings.topping_category_index,
          itemCategoryDescription:
            response?.data?.toppings.topping_category_description,
        }),
    };
  },
);

export const orderMgmtItemSearchSlice = createSlice({
  name: 'orderMgmtItemSearchDialog',
  initialState,
  reducers: {
    clear: state => {
      state.isOpen = false;
      state.status = orderMgmtStatus.Idle;
      state.search = {
        results: [],
        itemCategoryIndex: 0,
        itemCategoryDescription: '',
      };
      state.searchAttempts = 0;
    },
    close: state => {
      state.isOpen = false;
    },
    open: state => {
      state.isOpen = true;
    },
    setLoading: state => {
      state.status = orderMgmtStatus.Pending;
    },
    clearSearch: state => {
      state.search = {
        results: [],
        itemCategoryIndex: 0,
        itemCategoryDescription: '',
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(searchOrderItem.pending, state => {
        state.status = orderMgmtStatus.Pending;
      })
      .addCase(searchOrderItem.fulfilled, (state, { payload }) => {
        state.status = !!payload?.results.length
          ? orderMgmtStatus.Successful
          : orderMgmtStatus.EmptyResponse;
        state.search.results = payload?.results;
        state.search.itemCategoryIndex = payload?.itemCategoryIndex ?? 0;
        state.search.itemCategoryDescription =
          payload?.itemCategoryDescription ?? '';
        state.searchAttempts = 0;
      })
      .addCase(searchOrderItem.rejected, state => {
        state.status = orderMgmtStatus.Rejected;
        state.searchAttempts++;
      });
  },
});

export const orderMgmtItemSearchDialogActions =
  orderMgmtItemSearchSlice.actions;
