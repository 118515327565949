import React, { Component } from 'react';
import MenuIcon from './MenuIcon';

class FoodMenuIcon extends Component {
  d2 = 'M3 18.187V3l9 2.813L21 3v15.187L12 21l-9-2.813zm9-12.374V21';

  render() {
    const { selected } = this.props;

    return <MenuIcon d2={this.d2} selected={selected} />;
  }
}

export default FoodMenuIcon;
