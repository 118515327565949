import MainLayout from '../main-layout';
import { CookingColumn } from '../cooking-column';
import OrdersKanbanLayout from '../orders-kanban-layout';
import PendingToAcceptColumn from '../pending-to-accept-column/';
import { PendingToDeliverColumn } from '../pending-to-deliver-column';

function OrdersKanbanPage({ title }) {
  return (
    <MainLayout title={title}>
      <OrdersKanbanLayout title={title}>
        <PendingToAcceptColumn />
        <CookingColumn />
        <PendingToDeliverColumn />
      </OrdersKanbanLayout>
    </MainLayout>
  );
}

export default OrdersKanbanPage;
