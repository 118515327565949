import { makeStyles } from '@material-ui/core';
import { neutral } from '../../../../shared/styles/colors';

export const OrderMgmtAcceptChangesDialogStyles = makeStyles(() => ({
  root: {
    alignItems: 'flex-start',
  },
  paper: {
    height: '20rem',
    width: '29rem',
    padding: '1rem',
    position: 'relative',
  },
  header: {
    height: 0,
    padding: 0,
    borderBottomColor: 'transparent',
  },
  title: {
    fontWeight: 600,
    fontSize: '1.5rem',
    color: neutral.D20,
    alignSelf: 'flex-start',
    marginBottom: '0.5rem',
  },
  description: {
    fontWeight: 400,
    fontSize: '1rem',
    color: neutral.D16,
  },
  items: {
    height: '2.5rem',
    overflow: 'auto',
    width: '100%',
  },
  item: {
    color: neutral.D16,
    fontSize: '1rem',
    fontWeight: 600,
  },
}));
