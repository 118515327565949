// Material UI
import { makeStyles } from '@material-ui/core';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  dateFilterTitle: {
    color: neutral.D20,
    fontFamily: 'Poppins',
    fontSize: 17,
    fontWeight: 'normal',
    lineHeight: 'normal',
    marginBottom: 24,
    marginTop: 0,
  },
  dateSelectorRoot: {
    borderTop: `1px solid ${neutral.D10}`,
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
  },
}));
