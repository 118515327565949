import { Box, Typography } from "@material-ui/core";
import useStyles from './checkCode.style'

export function CheckCode(props: Props) {
  const { label, code } = props
  const styles = useStyles()

  return (
    <Box
      className={styles.checkCodeContainer}
      display='flex'
      alignItems='center'
      justifyContent='center'
      padding='0.25rem 0.75rem'
      >
        <Typography className={styles.checkCode}>
          {label}: {code}
        </Typography>
    </Box>   
  )
}

interface Props {
  label: string;
  code: string;
}