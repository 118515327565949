// Primary libraries (third party library)
import React from 'react';

// Our components and custom hooks
import { hooks } from './hooks';
import { CustomSearchTextField } from '../../../common/custom-search-text-field';
import StoreStatusBadgeFilter from '../../../store-status/store-status-badge-filter/StoreStatusBadgeFilter';
import { ToggleAllStoreStatus } from '../../../store-status/toggle-all-store-status';

// Constants and styles
import useStyles from './StoreManagerHeader.styles';

// Types
import type { IStoreManagerHeader } from '../../interfaces';

// +++++ StoreManagerHeader
export function StoreManagerHeader(props: IStoreManagerHeader) {
  const {
    filteredText,
    setFilteredText,
    storeStatusBadges,
    filterStatusBadge,
    setFilterStatusBadge,
  } = props;

  const texts = hooks.useTexts();
  const componentClasses = useStyles();

  const onChangeFilterText = (e: { target: { value: string } }) => {
    setFilteredText(e.target.value);
  };

  const onCleanFilteredText = () => {
    setFilteredText('');
  };

  return (
    <div className={componentClasses.storeStatusHeader}>
      <div className={componentClasses.storeStatusSearch}>
        <CustomSearchTextField
          classes={{ root: componentClasses.storeStatusSearchInput }}
          placeholder={texts.placeholderOfSearch}
          onChange={onChangeFilterText}
          onClickCancelButton={onCleanFilteredText}
          value={filteredText}
          fullWidth
        />
      </div>
      <div className={componentClasses.storeStatusFilters}>
        <div className={componentClasses.badgesContainer}>
          <span className={componentClasses.badgeFilterText}>
            {texts.filters}
          </span>
          <div className={componentClasses.badgeFiltersContent}>
            {storeStatusBadges.map((badge, idx) => (
              <StoreStatusBadgeFilter
                key={idx}
                setFilter={setFilterStatusBadge}
                isFiltering={filterStatusBadge === badge.status}
                {...{ badge }}
              />
            ))}
          </div>
        </div>
        <div className={componentClasses.toggleStoresBtnContainer}>
          <ToggleAllStoreStatus />
        </div>
      </div>
    </div>
  );
}
// ----- StoreManagerHeader
