import produce from 'immer';
import { connect } from 'react-redux';

import DateTimeUtils from '../../utils/DateTimeUtils';
import OrderActions from '../../redux/actions/orders-actions';

import OrdersHistorical from './OrdersHistorical';

const mapStateToProps = ({
  ordersReducer: {
    gettingOrdersHistoricalFailedMessage: errorText,
    historicalFilter,
    isGettingOrdersHistoricalFailedBeingShown,
    isOrdersHistoricalLoading,
    ordersHistorical,
    ordersHistoricalPage: currentPage,
  },
  partnerReducer: { profile },
  appReducer: { language },
}) => {
  const formattedOrders = !!ordersHistorical
    ? ordersHistorical.map(order => {
        return produce(order, orderDraft => {
          orderDraft.createAtFormated = DateTimeUtils.getLocaleDateString(
            order.created_at,
            'MMM DD, YYYY, hh:mm:ss A',
            language,
          );
        });
      })
    : [];
  const showErrorState = isGettingOrdersHistoricalFailedBeingShown;
  const showEmptyState =
    !showErrorState && (!formattedOrders || !formattedOrders.length);
  const showTable =
    !showErrorState && !!formattedOrders && !!formattedOrders.length;

  return {
    currentPage,
    errorText,
    isLoading: isOrdersHistoricalLoading,
    profile,
    ordersHistorical: formattedOrders,
    selectedStoreId: parseInt(historicalFilter['selectedStoreId']),
    showEmptyState,
    showErrorState,
    showTable,
  };
};

const mapDispatchToProps = {
  clearOrdersHistorical: OrderActions.clearOrdersHistorical,
  getOrdersHistorical: OrderActions.getOrdersHistorical,
  getOrderDetail: OrderActions.getOrderDetailAndStoreKeeperChannel,
  qualifyPositiveRT: OrderActions.qualifyPositiveRT,
  qualifyNegativeRT: OrderActions.qualifyNegativeRT,
  getQualifyNegativeReasons: OrderActions.getQualifyNegativeReasons,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersHistorical);
