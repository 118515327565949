const urls = {
  // Blind
  GET_ORDER_ID_BY_PICKUP_CODE: 'api/blind/orders/partner/:partnerId/code/:code',
  BLACKLIST_HANDSHAKE: 'api/blind/partners/:partnerId/blacklist-handshake',
  GET_WHETHER_ORDER_IS_VALIDATED: 'api/blind/orders/:orderId/validated',
  // Orders
  ADD_MORE_COOKINGTIME:
    'api/rs-aliados-gateway/orders/partner/status/proxy/order-modification/partner_add_cooking_time/:orderId',
  CANCEL_ORDER:
    'api/rs-aliados-gateway/orders/partner/status/proxy/order-modification/refuse_order_by_partner/:orderId',
  CLOSE_ORDER_BY_PARTNER_PICKUP:
    'api/rs-aliados-gateway/orders/partner/status/proxy/order-modification/close_order_by_partner_pickup/:orderId',
  DELIVER_TO_RAPPITENDERO:
    'api/rs-aliados-gateway/orders/partner/status/proxy/order-modification/delivery_to_rappitendero/:orderId',
  GET_ORDER_DETAIL:
    'api/rs-aliados-gateway/orders/partner/status/orders/:orderId',
  GET_ORDER_STATUS: 'api/rs-aliados-gateway/orders/partner/status/v2',
  GET_QUALIFY_NEGATIVE_REASONS:
    'api/rs-aliados-gateway/partner/incidents/rules',
  GET_ORDER_RT_INCIDENT:
    'api/rs-aliados-gateway/partner/incidents/validates?order_id=:orderId',
  GET_ORDERS_HISTORICAL:
    'api/rs-aliados-gateway/orders/partner/status/orders/history?store_id=:storeId&page=:pageNumber&per_page=10',
  HAND_TO_DOMICILIARY:
    'api/rs-aliados-gateway/orders/partner/status/proxy/order-modification/hand_to_domiciliary/:orderId',
  READY_FOR_PICK_UP:
    'api/rs-aliados-gateway/orders/partner/status/proxy/order-modification/ready_for_pick_up/:orderId',
  SEARCH_RT_NOW:
    'api/rs-aliados-gateway/orders/partner/status/proxy/order-modification/search_rt_now/:orderId',
  SET_AUTO_ACCEPT: 'api/rs-aliados-gateway/groot/partners/auto-accept',
  TAKE_ORDER:
    'api/rs-aliados-gateway/orders/partner/status/orders/take/standard/:orderId',
  GET_REJECT_REASONS:
    'api/rs-aliados-gateway/orders/partner/status/proxy/refuse-reasons/orderRefuseActive?isMarketplace=:isMarketPlace',
  REJECT_NEW_ORDER:
    'api/rs-aliados-gateway/orders/partner/status/proxy/order-modification/refuse_order_by_partner/:orderId',
  REJECT_ORDER_BY_STOCK_OUT_AS_SUPPORT:
    'api/rs-aliados-gateway/orders/partner/status/proxy/order-modification/refuse_partner_after_take_support/:orderId',
  REJECT_TOOK_ORDER:
    'api/rs-aliados-gateway/orders/partner/status/proxy/order-modification/refuse_partner_after_take/:orderId',
  ACTIVATE_PRODUCT:
    'api/rs-aliados-gateway/groot/partners/proxy/menu/products/available/true',
  DEACTIVATE_PRODUCTS:
    'api/rs-aliados-gateway/groot/partners/proxy/menu/products/available/false',
  ACTIVATE_TOPPING:
    'api/rs-aliados-gateway/groot/partners/proxy/menu/topping/activate',
  DEACTIVATE_TOPPING:
    'api/rs-aliados-gateway/groot/partners/proxy/menu/topping/deactivate',
  DEACTIVATE_ORDER_PRODUCTS_TOPPINGS:
    'api/rs-aliados-gateway/groot/partners/proxy/menu/products-toppings/deactivate/list/turn/off',
  CHANGE_ORDER_STATE: 'api/v2/orders/partner-order-state-change',
  ADD_WHIM:
    'api/rs-aliados-gateway/orders/partner/status/proxy/order-modification/add_whim_partner/:orderId',

  // It is no longer used since OM came in
  // ORDERS_STOCK_OUTS: 'api/rs-aliados-gateway/groot/partners/proxy/menu/orders/:orderId/stock-outs',

  REMOVE_WHIM:
    'api/rs-aliados-gateway/orders/partner/status/proxy/order-modification/remove_whim_partner/:orderId',
  SALES_SUMMARY:
    'api/rs-aliados-gateway/orders/partner/status/stores/:storeId/summary-by-date/:date',
  QUALIFY_RT: 'api/rs-aliados-gateway/partner/incidents/storekeepers',
  SEND_NOTIFICATION_TO_RT:
    'api/rs-aliados-gateway/orders/partner/status/orders/:orderId/rt/order-ready',
  // Storekeepers
  GET_STOREKEEPER_CHANNEL: 'api/storekeepers/position/:id/channel',
  RELEASE_RT_NOW:
    'api/rs-aliados-gateway/orders/partner/status/proxy/order-modification/release_storekeeper_by_partner/:orderId',

  // GLOBAL
  GET_ALL_AVAILABLE_COUNTRIES: '/ms/country-data/available-countries',
  RESOLVE_COUNTRY: '/location/resolve-country?lat=:lat&lng=:lng',
  // PARTNERS
  CHECK_APP_VERSION: `api/rs-aliados-gateway/groot/partners/app-version/check/${process.env.REACT_APP_EVENT_ORIGIN}/:appVersion`,
  GET_SETTINGS: 'api/rs-aliados-gateway/groot/partners/store-settings',
  LOGIN: 'api/rs-aliados-gateway/login',
  LOGOUT: 'api/rs-aliados-gateway/groot/partners/logout?device_id=:deviceId',
  NOTIFICATIONS:
    'api/rs-aliados-gateway/groot/partners/notifications?page=:page&per_page=:perPage',
  // NOTIFICATIONS_UNSEEN:
  //   'api/rs-aliados-gateway/groot/partners/notifications/unseen/actions',
  LAST_NOTIFICATION:
    'api/rs-aliados-gateway/groot/partners/notifications/last/actions',
  NOTIFICATIONS_PUT_SEEN:
    'api/rs-aliados-gateway/groot/partners/notifications/seen/:notificationId',
  NOTIFICATIONS_PUT_SEEN_ALL_ACTIONS:
    'api/rs-aliados-gateway/groot/partners/notifications/seen/all/actions',
  NOTIFICATIONS_PUT_SEEN_ALL_NOTIFICATIONS:
    'api/rs-aliados-gateway/groot/partners/notifications/seen/all',
  REFRESH_TOKEN: 'api/rocket/refresh-token',
  REGISTER_FCM_TOKEN:
    'api/rs-aliados-gateway/groot/partners/fcm/register-token',
  PROFILE: 'api/rs-aliados-gateway/groot/partners/profile',
  PARTNER_STATUS: 'api/rs-aliados-gateway/groot/partners/state',
  PARTNER_STATE: 'api/rs-aliados-gateway/groot/partners/state-notification-new',
  PARTNER_STATE_VALIDATE:
    'api/rs-aliados-gateway/groot/partners/state-validate',
  INTEGRATED_STORE_STATUS:
    'api/rs-aliados-gateway/groot/partners/switch-stores/enabled',
  STORE_DIAGNOSTICS:
    'api/rs-aliados-gateway/groot/partners/stores/:storeId/diagnostics',
  DEACTIVATE_MASSIVE_TOPPINGS:
    'api/rs-aliados-gateway/groot/partners/proxy/menu/topping/deactivate/list',
  // PARTNER STORE LOGS
  CREATE_CONNECTIVITY_ISSUE:
    'api/rs-aliados-gateway/partner-store-logs/connectivity/issues',
  PARTNER_STORE_LOGS_ORDER_EVENT:
    'api/rs-aliados-gateway/partner-store-logs/order-event',
  PARTNER_STORE_LOGS_DELIVERED_CANCELED_ORDERS:
    'api/rs-aliados-gateway/partner-store-logs/delivered/cancelled/orders',
  // REAL TIME
  REAL_TIME_INTERFACE_ACK: 'realtime-interface/chat/:messageId/ack',
  REAL_TIME_INTERFACE_CHAT: 'realtime-interface/chat',
  REAL_TIME_INTERFACE_LOGIN: 'realtime-interface/firebase/login',
  REAL_TIME_INTERFACE_NOTIFICATION: 'realtime-interface/notification',
  REAL_TIME_INTERFACE_FIREBASE_STATUS: 'realtime-interface/firebase/status',
  // SUPPORT ACTIONS MANAGER
  SUPPORT_ACTIONS_MANAGER_PARTNER_TYPIFICATIONS:
    'support-actions-manager/partner/typifications',
  GET_ARTICLE: `support-actions-manager/help-center/${process.env.REACT_APP_KUSTOMER_ARTICLES_FOLDER}/support-options/article/:articleId`,
  GET_HELP_CENTER_TREE: `support-actions-manager/help-center/${process.env.REACT_APP_KUSTOMER_ARTICLES_FOLDER}/support-menu`,
  // SUPPORT SUGGESTIONS
  GET_NO_RT_ISSUE_SUGGESTIONS:
    'api/rs-aliados-gateway/groot/partners/support/step/no-rt/orders/:orderId',
  GET_STOCK_OUT_ISSUE_SUGGESTIONS:
    'api/rs-aliados-gateway/groot/partners/support/step/no-product?delivery_method=:deliveryMethod',
  GET_SUPPORT_REASONS:
    'api/rs-aliados-gateway/groot/partners/support/step/start-flow?storekeeper=:isStorekeeperFinal&delivery_method=:deliveryMethod',
  GET_WITH_RT_ISSUE_SUGGESTIONS:
    'api/rs-aliados-gateway/groot/partners/support/step/with-rt',
  GET_SUPPORT_CALIFICATION:
    'api/rs-aliados-gateway/partner/incidents/support-rating/orders/:orderId',
  SEND_SUPPORT_CALIFICATION:
    'api/rs-aliados-gateway/partner/incidents/support-rating',
  // PROXY SESSION
  SUPPORT_ACTIONS_PHONE_PROXY_SESSION:
    'api/rs-aliados-gateway/groot/partners/proxy/phone-session',
  // MENU
  GET_CORRIDORS:
    'api/rs-aliados-gateway/groot/partners/proxy/menu/:storeId?device=2',
  GET_CORRIDOR_PRODUCTS:
    'api/rs-aliados-gateway/groot/partners/proxy/menu/:storeId/corridors/:corridorId/products',
  GET_PRODUCT_TOPPINGS:
    'api/rs-aliados-gateway/groot/partners/proxy/menu/:storeId/products/:productId/toppings',
  GET_MENU_BY_STORE:
    'api/rs-aliados-gateway/groot/partners/proxy/menu/:storeId/all',

  // STORE_SCHEDULES
  CREATE_HOLIDAY_SCHEDULE: 'ms/store-schedules-api/schedules/holiday',
  CREATE_SPECIAL_DAY: 'ms/store-schedules-api/special',
  CREATE_EXTEND_CLOSING_HOURS_DAY: 'api/rs-aliados-gateway/special/schedules',
  CREATE_SPECIAL_DAY_SCHEDULE: 'ms/store-schedules-api/schedules/special',
  CREATE_EXTEND_CLOSING_HOURS_SCHEDULE:
    'api/rs-aliados-gateway/special/blocks/schedules',
  DELETE_SCHEDULE: 'ms/store-schedules-api/schedules/:scheduleId',
  DELETE_SPECIAL_DAY: 'ms/store-schedules-api/special/:selectedDayId',
  GET_STORE_SCHEDULES:
    'ms/store-schedules/schedules?store_id=:storeId&city_id=:cityId',
  GET_STORE_SCHEDULES_SUMMARY:
    'ms/store-schedules-api/schedules/summay?date=:date&city_id=:cityId&store_id=:storeId',
  UPDATE_SCHEDULE: 'ms/store-schedules-api/schedule',

  // Img medium logos for rest
  RESTAURANTS_LOGO: `:urlImage/restaurants_logo`,

  /// stores reasons
  REASONS_STORES:
    'api/rs-aliados-gateway/groot/partners/suspension-reasons/by-partner/list',
  /// replace stockout suggest product
  STOCK_OUT_SUGGEST_PRODUCT:
    'api/rs-aliados-gateway/orders/partner/status/orders/:orderId/process-stockout',
  SUGGESTION_PRODUCT_REPLACEMENT:
    'api/rs-aliados-gateway/orders/partner/status/orders/:orderId/process-product-substitution',

  // Onboarding
  ONBOARDING_LIST:
    'api/rs-aliados-gateway/groot/partners/on-boarding/flows/delivery-method?platform=web',
  // log out reasons
  LOG_OUT_REASONS: 'api/rs-aliados-gateway/groot/partners/logout-reason',
  SEND_LOG_OUT_REASON: 'api/rs-aliados-gateway/groot/partners/logout',
  // PM AUTOMATION
  PM_AUTOMATION_SET_AS_SEEN:
    'api/rs-aliados-gateway/groot/partners/pm-automation/forms/:formId/seen',
  PM_AUTOMATION_STORE_SKIP_CLOSE:
    'api/rs-aliados-gateway/groot/partners/pm-automation/forms/:formId/skip-close',
  PM_AUTOMATION_STORE_COMPLETE_CLOSE:
    'api/rs-aliados-gateway/groot/partners/pm-automation/forms/:formId/complete-close',
  PM_AUTOMATION_STORE_ERROR_CLOSE:
    'api/rs-aliados-gateway/groot/partners/pm-automation/forms/:formId/error-close',
  PM_AUTOMATION_STORE_PHONE_CONTACT:
    'api/rs-aliados-gateway/groot/partners/pm-automation/forms/:formId/store-contact-step',
  GET_PM_AUTOMATION_STORE_PHONE_CONTACT:
    'api/rs-aliados-gateway/groot/partners/pm-automation/forms/:formId/store-contact',
  GET_PM_AUTOMATION_STORE_SCHEDULE:
    'api/rs-aliados-gateway/groot/partners/pm-automation/forms/:formId/store-schedules',
  DELETE_DAY_STORE_SCHEDULE:
    'api/rs-aliados-gateway/groot/partners/pm-automation/forms/:formId/schedules-step/intervals/:intervalId',
  UPDATE_DAYS_STORE_SCHEDULE:
    'api/rs-aliados-gateway/groot/partners/pm-automation/forms/:formId/schedules-step/intervals/:intervalId',
  CREATE_DAYS_STORE_SCHEDULE:
    'api/rs-aliados-gateway/groot/partners/pm-automation/forms/:formId/schedules-step/intervals',
  // STORE STATUS DETAIL
  GET_STORE_STATUS_DETAIL:
    'api/rs-aliados-gateway/rest-aliados-state-ms/:partnerId/store-status-detail',
  SWITCH_PARTNER_STORES_STATUS:
    'api/rs-aliados-gateway/groot/partners/switch-stores/enabled/deactivate',

  GET_DEACTIVATED_PRODUCTS:
    'api/rs-aliados-gateway/groot/partners/proxy/menu/all/products-toppings',

  LIFTING_SUSPENSION_STATUS_BY_STORE:
    'api/rs-aliados-gateway/rest-aliados-state-ms/unsuspended/store?store_id=:storeId',

  SATURATION_LIST_BY_STORE_WITH_SEVERITY_TIMES:
    'api/rs-aliados-gateway/groot/partners/stores/saturation/with-severity-times',
  CHANGE_SATURATION_AS_CREATE_DEMAND:
    'api/rs-aliados-gateway/groot/partners/stores/saturation/create-demand-history',
  CHECK_STORE_ELIGIBILITY_FOR_FAST_OPERATION:
    'api/rest-ops-partner-assign/fast-operation/check-store-eligibility',
  FAST_OPERATION_TOGGLE_STATUS:
    'api/rest-ops-partner-assign/fast-operation/toggle-store-status',
};
export default urls;
