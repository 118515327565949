import React from 'react';
import { Box, Typography } from '@material-ui/core';

import useRequestStyles from './request.styles';
import { CoverageStatus } from '../../../../business/cargo';
import AlertIcon from '../../../../components/icons/alert-icon/AlertIcon';
import { useRequestLang } from './hooks';

export function Disclaimer({ coverageStatus, deliveryCost }: Props) {
  const styles = useRequestStyles({ coverageStatus });
  const _covStatus = coverageStatus || 'hasCoverage';
  const { disclaimerTitleText, disclaimerDescriptionText } = useRequestLang({
    amount: `${deliveryCost}`,
    coverageStatus: _covStatus,
  });

  const iconType: any = {
    [`${coverageStatus === ''}`]: 'info',
    [`${coverageStatus === 'hasCoverage'}`]: 'info',
    [`${coverageStatus === 'noCoverage'}`]: 'warning',
    [`${coverageStatus === 'notAvailable'}`]: 'danger',
  }['true'];

  return (
    <Box
      className={styles.disclaimerContainer}
      gridArea='header'
      display='flex'
      padding='0.5rem 1rem'
      gridGap='0.5rem'
    >
      <AlertIcon type={iconType} />
      <Box>
        <Typography className={styles.disclaimerTitle}>
          {disclaimerTitleText}
        </Typography>
        <Typography className={styles.disclaimerDescription}>
          {disclaimerDescriptionText}
        </Typography>
      </Box>
    </Box>
  );
}

interface Props {
  coverageStatus: CoverageStatus;
  deliveryCost: number;
}
