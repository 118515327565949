class IndexedDBOrder {
  constructor(id, beingManaged, partner_order_state, printedDate, state) {
    this.id = id;
    this.beingManaged = beingManaged;
    this.partner_order_state = partner_order_state;
    this.printedDate = printedDate;
    this.state = state;
  }
}

export default IndexedDBOrder;
