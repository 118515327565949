import ReactGA from 'react-ga';

export const GACategories = {
  ERRORS: 'errors',
  PARTNER_STATE: 'partner_state',
  ORDER_OTHER_ACTIONS: 'order_other_actions',
  ORDER_MODIFICATIONS: 'order_modifications',
  EXTERNAL_SITES: 'external_sites',
  MENU_MANAGEMENT: 'menu_management',
  PARTNER_ACTIONS: 'partner_actions',
  CONFIGURATIONS: 'configurations',
  SCHEDULES: 'schedules',
};

export const GAActions = {
  CONTROLLED_ERRORS: 'controlled_errors',
  NO_TAGGED_ERRORS: 'no_tagged_errors',
  UNCONTROLLED_ERRORS: 'uncontrolled_errors',
  CLICK_STORE_CLOSED: 'click_store_closed',
  CLICK_STORE_OPEN: 'click_store_open',
  ASSIGN_TO_PARTNER_CLICK_ON_DETAIL:
    'ready_for_pick_up=click-from-order-detail',
  COLLAPSE_ORDER_LIST: 'collapse_order_list',
  EXPAND_ORDER_LIST: 'expand_order_list',
  OK_DIALOG_STORE_CLOSED: 'ok_dialog_store_closed',
  DEACTIVATE_STORE: 'deactivate_store',
  CLICK_STORE_DISABLED: 'click_store_disabled',
  CTA_RAPPI_GROWTH_APP_ANDROID: 'cta_rappi_growth_app_android',
  CTA_RAPPI_GROWTH_WEB: 'cta_rappi_growth_app_android',
  DEACTIVATE_PRODUCTS: 'deactivate_products',
  ACTIVATE_PRODUCTS: 'activate_products',
  DEACTIVATE_TOPPING: 'deactivate_topping',
  ACTIVATE_TOPPING: 'activate_topping',
  FAILURE_CANCEL_MULTIPLE_TOPPINGS: 'failure_cancel_multiple_toppings',
  FAILURE_GO_TO_MENU_MULTIPLE_TOPPINGS: 'failure_go_to_menu_multiple_toppings',
  DEACTIVATE_DIALOG_STORE_OPEN: 'deactivate_dialog_store_open',
  OK_DIALOG_STORE_OPEN: 'ok_dialog_store_open',
  CTA_ALERTBOX: 'cta_alertBox',
  CTA_INAPPDIALOG: 'cta_inAppDialog',
  CLOSE_INAPPDIALOG: 'close_inAppDialog',
  INSTALL_PLUG_IN: 'install_plug_in',
  DEACTIVATE_MULTIPLE_TOPPINGS: 'deactivate_multiple_toppings',
  HAND_TO_DOMICILIARY: 'hand_to_domiciliary',
  READY_FOR_PICK_UP: 'ready_for_pick_up',
  PARTNER_ADD_COOKING_TIME: 'partner_add_cooking_time',
  RELEASE_STOREKEEPER_BY_PARTNER: 'release_storekeeper_by_partner',
  CANCELED_BY_PARTNER_PRE_TAKE: 'canceled_by_partner_pre_take',
  CANCELED_BY_PARTNER_AFTER_TAKE: 'canceled_by_partner_after_take',
  TAKEN_VISIBLE_ORDER: 'taken_visible_order',
  TAKEN_AUTOMATIC_ORDER: 'taken_automatic_order',
  READY_FOR_PICK_UP_CLICK_ON_ORDER_LIST:
    'ready_for_pick_up=click-from-order-list',
  SAVE_HOLIDAY_SCHEDULE: 'save_holiday_schedule',
  DELETE_HOLIDAY_SCHEDULE: 'delete_holiday_schedule',
  SAVE_SPECIALDAY_SCHEDULE: 'save_specialday_schedule',
  DELETE_SPECIALDAY_SCHEDULE: 'delete_specialday_schedule',
  CREATE_SPECIALDAY: 'create_specialday',
  DELETE_SPECIALDAY: 'delete_specialday',
  TURN_ON_KANBAN: 'turn_on_kanban',
  TURN_OFF_KANBAN: 'turn_off_kanban',
};

export const buildGAData = (code, message, params, partnerId = '') => {
  const BaseGAData = `partner_id=${partnerId}#code=${code}#message=${message}`;
  if (!params) {
    return BaseGAData;
  }
  let GADataParams = '';
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const element = params[key];
      GADataParams = GADataParams + `#${key}=${JSON.stringify(element)}`;
    }
  }
  return BaseGAData + GADataParams;
};

export const analitycsEvent = (category, action, label) => {
  try {
    ReactGA.event({
      category,
      action,
      label,
    });
  } catch (ex) {
    console.error(ex);
  }
};

const GAUtils = {
  analitycsEvent,
  buildGAData,
  GAActions,
  GACategories,
};

export default GAUtils;
