import { useFormatMessage } from '../../../intl/intl';

export function useStoresSchedulesPageLang({ supportPhone }: Props) {
  return {
    layout: {
      title: useFormatMessage({ id: 'storesSchedulePage.title' }),
    },
    page: {
      regular: {
        title: useFormatMessage({ id: 'storesSchedulePage.regularSchedule' }),
        description: useFormatMessage({
          id: 'storesSchedulePage.regularScheduleDescription',
        }),
        info: useFormatMessage(
          { id: 'storesSchedulePage.regularScheduleAlert' },
          { phone: supportPhone },
        ),
      },
      holiday: {
        title: useFormatMessage({ id: 'storesSchedulePage.holidays' }),
        description: useFormatMessage({
          id: 'storesSchedulePage.holidaysScheduleDescription',
        }),
        info: useFormatMessage({
          id: 'storesSchedulePage.holidayScheduleAlert',
        }),
      },
      specialDay: {
        title: useFormatMessage({ id: 'storesSchedulePage.specialDay' }),
        description: useFormatMessage({
          id: 'storesSchedulePage.specialDayScheduleDescription',
        }),
        info: useFormatMessage({
          id: 'storesSchedulePage.specialDayScheduleAlert',
        }),
      },
    },
  };
}

interface Props {
  supportPhone: string;
}
