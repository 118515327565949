import { useState } from 'react';
import { OrderMgmtProduct } from '../../../../../business/order-mgmt';
import { OrderMgmtAnalytics } from '../../../../../business/order-mgmt/analitycs';
import { orderMgmtAdapter } from '../../../../../business/order-mgmt/infra/adapters/orderMgmt.adapter';
import { useOrderManagement } from '../../../hooks';

export const useOrderMgmtCard = ({ product, productId }: Props) => {
  const [anchorProductMenu, setAnchorProductMenu] = useState(null);
  const [anchorToppingMenu, setAnchorToppingMenu] = useState(null);
  const {
    onOpenItemSearchDialog,
    orderId,
    storeId,
    onOpenReplaceProductDialog,
  } = useOrderManagement();

  const openProductMenu = Boolean(anchorProductMenu);

  const hasProductItemSuggestions = (product?.suggestions || []).length > 0;
  const hasProductItemToppingSuggestions = (product?.toppings || []).some(
    topping => topping?.suggestions,
  );

  const handleReplaceProduct = () => {
    const item = { ...product, productId: product?.id, isProduct: true };
    const itemToReplace = orderMgmtAdapter.toOrderMgmtItemToReplace(
      item,
      orderId,
    );
    hasProductItemToppingSuggestions
      ? onOpenReplaceProductDialog('')
      : onOpenItemSearchDialog();
    OrderMgmtAnalytics.whenOrderActions(
      { orderId, storeId },
      { item: 'product', action: 'replace' },
    );
    handleCloseProductMenu();
  };

  const handleCloseProductMenu = () => {
    setAnchorProductMenu(null);
  };

  const handleClickProductManageMenuOptions = e => {
    setAnchorProductMenu(e.currentTarget);
  };

  const handleDeleteProduct = () => {
    console.log('DELETING PRODUCT...');
  };

  const openToppingMenu = Boolean(anchorToppingMenu);

  const handleClickToppingManageMenuOptions = e => {
    setAnchorToppingMenu(e.currentTarget);
  };

  const handleCloseToppingMenu = () => {
    setAnchorToppingMenu(null);
  };

  const handleReplaceTopping = topping => {
    const item = { ...topping, productId, isProduct: false };
    const itemToReplace = orderMgmtAdapter.toOrderMgmtItemToReplace(
      item,
      orderId,
    );
    onOpenItemSearchDialog();
    OrderMgmtAnalytics.whenOrderActions(
      { orderId, storeId },
      { item: 'topping', action: 'replace' },
    );
    handleCloseToppingMenu();
  };

  const handleDeleteTopping = () => {
    console.log('DELETING TOPPING...');
  };

  return {
    anchorProductMenu,
    setAnchorProductMenu,
    openProductMenu,
    hasProductItemSuggestions,
    handleReplaceProduct,
    handleCloseProductMenu,
    handleClickProductManageMenuOptions,
    handleDeleteProduct,
    anchorToppingMenu,
    setAnchorToppingMenu,
    openToppingMenu,
    handleClickToppingManageMenuOptions,
    handleCloseToppingMenu,
    handleReplaceTopping,
    handleDeleteTopping,
    hasProductItemToppingSuggestions,
  };
};

interface Props {
  product?: OrderMgmtProduct;
  productId?: string;
}
