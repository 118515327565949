import { HandoffDetail } from '../models';

export const CHECKOUT_RT_DEBOUNCE_TIME = 3000;

export const MAX_QUANTITY_OF_PACKAGES = 40;

export const defaultValueHandoffDetail: HandoffDetail = {
  hasDrinksOutside: null,
  quantityOfPackages: NaN,
};
