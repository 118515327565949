// Material UI
import { makeStyles } from '@material-ui/styles'

export const infoboxStyles = makeStyles(theme => ({
  containerComments: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    border: '1px solid black',
    padding: 5,
  },
  fontTextCommentDescripcion: {
    fontSize: 12,
    fontFamily: 'Lucida Console',
    fontStyle: 'normal',
    fontWeight: 400,
    paddingLeft: 4,
  },
  comment: {
    fontSize: 12,
    fontFamily: 'Lucida Console',
    fontStyle: 'normal',
    fontWeight: 400,
    paddingLeft: 4,
    paddingBottom: 4,
  },
}))
