// Consts
import DeactivationType from '../constants/DeactivationType'
import urls from '../constants/Urls'
// Utils
import UrlUtils from '../utils/url-utils/UrlUtils'
// Services
import http from './HttpService'
import LocalStorageService from './local-storage-service'

const deactivateToppings = (
  store_id,
  toppingIds,
  deactivationType,
  deactivationDate,
) => {
  const baseUrl = LocalStorageService.getBaseUrl()
  const payload = {
    store_id,
    topping_id: toppingIds,
    deactivation_type: deactivationType || DeactivationType.DAILY,
    ...(deactivationDate && { out_of_stock_activation_date: deactivationDate }),
  }
  return http.put(`${baseUrl}${urls.DEACTIVATE_MASSIVE_TOPPINGS}`, payload)
}

const getCorridors = storeId => {
  const params = { storeId }
  const baseUrl = LocalStorageService.getBaseUrl()
  const url = UrlUtils.replaceUrlParams(urls.GET_CORRIDORS, params)
  return http.get(`${baseUrl}${url}`)
}

const getCorridorProducts = (storeId, corridorId) => {
  const baseUrl = LocalStorageService.getBaseUrl()
  const params = { storeId, corridorId }
  const url = UrlUtils.replaceUrlParams(urls.GET_CORRIDOR_PRODUCTS, params)
  return http.get(`${baseUrl}${url}`)
}

const getProductToppings = (storeId, productId) => {
  const baseUrl = LocalStorageService.getBaseUrl()
  const params = { storeId, productId }
  const url = UrlUtils.replaceUrlParams(urls.GET_PRODUCT_TOPPINGS, params)
  return http.get(`${baseUrl}${url}`)
}

const getMenuByStore = storeId => {
  const baseUrl = LocalStorageService.getBaseUrl()
  const url = UrlUtils.replaceUrlParams(urls.GET_MENU_BY_STORE, { storeId })
  return http.get(`${baseUrl}${url}`)
}

const activateProduct = (storeId, productId) => {
  const baseUrl = LocalStorageService.getBaseUrl()
  const payload = {
    store_id: storeId,
    product_id: productId,
  }
  return http.put(`${baseUrl}${urls.ACTIVATE_PRODUCT}`, payload)
}

const deactivateProduct = (
  storeId,
  productId,
  deactivationType,
  deactivationDate = null,
) => {
  const baseUrl = LocalStorageService.getBaseUrl()
  const payload = {
    deactivation_type: deactivationType,
    store_id: storeId,
    product_id: productId,
    ...(deactivationDate && { out_of_stock_activation_date: deactivationDate }),
  }

  return http.put(`${baseUrl}${urls.DEACTIVATE_PRODUCTS}`, payload)
}

const activateTopping = (storeId, toppingId) => {
  const baseUrl = LocalStorageService.getBaseUrl()
  const payload = {
    store_id: storeId,
    topping_id: toppingId,
  }
  return http.put(`${baseUrl}${urls.ACTIVATE_TOPPING}`, payload)
}

const deactivateTopping = (
  storeId,
  toppingId,
  deactivationType,
  deactivationDate,
) => {
  const baseUrl = LocalStorageService.getBaseUrl()
  const payload = {
    deactivation_type: deactivationType,
    store_id: storeId,
    topping_id: toppingId,
    ...(deactivationDate && { out_of_stock_activation_date: deactivationDate }),
  }
  return http.put(`${baseUrl}${urls.DEACTIVATE_TOPPING}`, payload)
}

export default {
  deactivateToppings,
  getCorridors,
  getCorridorProducts,
  getMenuByStore,
  getProductToppings,
  activateProduct,
  deactivateProduct,
  activateTopping,
  deactivateTopping,
}
