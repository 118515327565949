import { useSelector } from 'react-redux';

import { ADD_RT_SLICE_NAME } from './addRT.const';

import type { AddRTGlobalState, CargoStoreList } from '../../../domain';

export function cargoStoresSelector() {
  return useSelector((s: any) => s.cargo.stores as CargoStoreList);
}

const _getState = (s: any): AddRTGlobalState => s[ADD_RT_SLICE_NAME];

export const addRTSelector = {
  status: () => useSelector(s => _getState(s).status),

  selectedOrderId: () => useSelector((s: any) => _getState(s).selectedOrderId),

  validatedData: () => useSelector((s: any) => _getState(s).validatedData),

  createdAddRT: () => useSelector((s: any) => _getState(s).createdAddRT),

  error: () => useSelector((s: any) => _getState(s).error),
};
