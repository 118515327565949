import { useEffect, useState } from 'react';

import type { CookingClockProps, State } from './CookingClock.domain';

const INITIAL_STATE: State = { label: '', value: 100, statusTime: 'danger' };

export function useCookingClock(props: CookingClockProps) {
  const { cookingTime, cookingTimeStartedAt } = props;

  const [state, setState] = useState<State>(INITIAL_STATE);

  const initTime = new Date(cookingTimeStartedAt.replace(' ', 'T'));

  const warningTime = 5;
  const dangerTime = 3;

  function calculateCookingClock(): void {
    const [MM, _, fullMinutes] = calcCookingTimer({ initTime, cookingTime });
    if (MM <= 0) {
      setState(INITIAL_STATE);
      return;
    }

    setState({
      label: `${MM}’`,
      value: 100 - Math.ceil((fullMinutes / cookingTime) * 100),
      statusTime: {
        [`${MM <= cookingTime && MM > warningTime}`]: 'success',
        [`${MM <= warningTime && MM <= warningTime}`]: 'warning',
        [`${MM <= dangerTime}`]: 'danger',
      }['true'],
    });
  }

  useEffect(() => {
    if (!cookingTime || !cookingTimeStartedAt) return;
    calculateCookingClock();
    const intervalID = setInterval(calculateCookingClock, 1_000);
    return () => {
      clearInterval(intervalID);
    };
  }, [cookingTime, cookingTimeStartedAt]);

  return { ...state };
}

function calcCookingTimer(props: CalcCookingTimerProps): number[] {
  const { initTime, cookingTime } = props;
  const finalTime: Date = new Date(initTime);
  finalTime.setMinutes(initTime.getMinutes() + cookingTime);
  const timeDiffInMinutes = (finalTime.getTime() - Date.now()) / 1_000 / 60;
  const minutes: number = Math.trunc(timeDiffInMinutes);
  const seconds: number = Math.ceil((timeDiffInMinutes - minutes) * 60);
  return [minutes, seconds, timeDiffInMinutes];
}

interface CalcCookingTimerProps {
  initTime: Date; // as local time
  cookingTime: CookingClockProps['cookingTime'];
}
