import { get } from 'lodash';

import appStore from '../../redux/stores/app.store';
import LocalStorageService from '../../services/local-storage-service';
import ChromeExtensionService from '../../services/ChromeExtensionService';
import { checkWebPlatformDevice } from '../../business/common/browser/domain';

import { PWEXCodes } from '../../exceptions';
import EventCodes from './consts/eventCodes';
import { geoqueueCase, geoqueueHelper } from '../../business/order/geoqueue';

const initialize = async () => {
  try {
    const key = process.env.REACT_APP_AMPLITUD_API_KEY;
    if (!key) {
      console.error(`Amplitude wasn't initilized because no key was provided`);
      return;
    }

    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement('script');
      r.type = 'text/javascript';
      r.integrity =
        'sha384-5fhzC8Xw3m+x5cBag4AMKRdf900vw3AoaLty2vYfcKIX1iEsYRHZF4RLXIsu2o+F';
      r.crossOrigin = 'anonymous';
      r.async = true;
      r.src = 'https://cdn.amplitude.com/libs/amplitude-8.21.4-min.gz.js';
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
          console.log('[Amplitude] Error: could not load SDK');
        }
      };
      var s = t.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(r, s);
      function i(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        'add',
        'append',
        'clearAll',
        'prepend',
        'set',
        'setOnce',
        'unset',
        'preInsert',
        'postInsert',
        'remove',
      ];
      for (var c = 0; c < a.length; c++) {
        i(o, a[c]);
      }
      n.Identify = o;
      var l = function () {
        this._q = [];
        return this;
      };
      var u = [
        'setProductId',
        'setQuantity',
        'setPrice',
        'setRevenueType',
        'setEventProperties',
      ];
      for (var p = 0; p < u.length; p++) {
        i(l, u[p]);
      }
      n.Revenue = l;
      var d = [
        'init',
        'logEvent',
        'logRevenue',
        'setUserId',
        'setUserProperties',
        'setOptOut',
        'setVersionName',
        'setDomain',
        'setDeviceId',
        'enableTracking',
        'setGlobalUserProperties',
        'identify',
        'clearUserProperties',
        'setGroup',
        'logRevenueV2',
        'regenerateDeviceId',
        'groupIdentify',
        'onInit',
        'onNewSessionStart',
        'logEventWithTimestamp',
        'logEventWithGroups',
        'setSessionId',
        'resetSessionId',
        'getDeviceId',
        'getUserId',
        'setMinTimeBetweenSessionsMillis',
        'setEventUploadThreshold',
        'setUseDynamicConfig',
        'setServerZone',
        'setServerUrl',
        'sendEvents',
        'setLibrary',
        'setTransport',
      ];
      function v(t) {
        function e(e) {
          t[e] = function () {
            t._q.push([e].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          e(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? '$default_instance' : e).toLowerCase();
        if (!Object.prototype.hasOwnProperty.call(n._iq, e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);

    window.amplitude.getInstance().init(key);
    await Amplitude.setUser();
  } catch (ex) {
    console.error(ex);
  }
};

const logFailedRTRelease = (orderId, storekeeperId) => {
  const params = {
    orderId,
    storekeeperId,
  };

  Amplitude.logEvent(Amplitude.EventCodes.RELEASE_RT_ERROR, params);
};

const logEvent = (code, params) => {
  try {
    window.amplitude.getInstance().logEvent(code, params ?? null);
  } catch (ex) {
    console.error(ex);
  }
};

const logLogIn = successful => {
  const params = {
    successful,
  };

  Amplitude.logEvent(Amplitude.EventCodes.LOG_IN, params);
};

const logLogOut = () => {
  Amplitude.logEvent(Amplitude.EventCodes.LOG_OUT);
};

const logNewOrdersRawReception = rawNewOrders => {
  if (!rawNewOrders || !rawNewOrders.length) {
    return;
  }
  const orders = rawNewOrders.map(order => ({
    id: order.id,
    partner_order_state: order.partner_order_state,
    state: order.state,
  }));

  const params = { orders };

  Amplitude.logEvent(Amplitude.EventCodes.NEW_ORDER_RAW_RECEPTION, params);
};

const logNewOrdersReception = (newOrders, hasExtension, extensionVersion) => {
  if (!newOrders || !newOrders.length) {
    return;
  }

  for (const newOrder of newOrders) {
    const params = {
      order_id: get(newOrder, 'id', 0),
      order_created_at: get(newOrder, 'created_at', null),
      delivery_method: get(newOrder, 'delivery_method', null),
      IS_GEOQUEUE: geoqueueHelper.isGeoqueue(newOrder),
      chrome_extension: hasExtension ? 'Y' : 'N',
      chrome_extension_version: extensionVersion,
    };

    Amplitude.logEvent(Amplitude.EventCodes.EVENT_NEW_ORDER, params);
  }
};

const logClickDynActionCTA = (type, dynId, CTAIndex) => {
  const params = {
    NOTIFICATION_ACTION_ID: dynId,
    NOTIFICATION_ACTION_TYPE: type,
    NOTIFICATION_CTA_LEVEL: CTAIndex + 1,
  };

  Amplitude.logEvent(Amplitude.EventCodes.NOTIFICATION_ACTION_CTA, params);
};

const logCloseDynActionCTA = (type, dynId) => {
  const params = {
    NOTIFICATION_ACTION_ID: dynId,
    NOTIFICATION_ACTION_TYPE: type,
  };

  Amplitude.logEvent(Amplitude.EventCodes.NOTIFICATION_ACTION_CLOSE, params);
};

const logClickOpenDetailFromOrderCard = order => {
  if (!order) {
    return;
  }

  const params = {
    order_id: get(order, 'id', null),
    partner_order_state: get(order, 'partner_order_state', null),
    state: get(order, 'state', null),
    store_id: get(order, 'store_id', null),
  };

  Amplitude.logEvent(Amplitude.EventCodes.EVENT_OPEN_ORDER_DETAIL, params);
};

const logClickOrderTotalsRetryButton = (order_id, errorType) => {
  const params = {
    order_id,
    ORDER_TOTALS_ERROR_TYPE: errorType,
  };
  Amplitude.logEvent(Amplitude.EventCodes.ORDER_TOTALS_DETAIL_RETRY, params);
};

const logOrderDelivered = (
  order,
  deliverMethod,
  hasExtension,
  extensionVersion,
) => {
  if (!order) {
    return;
  }

  const params = {
    order_id: get(order, 'id', null),
    STORE_ID: get(order, 'store_id', null),
    delivery_method: deliverMethod,
    chrome_extension: hasExtension ? 'Y' : 'N',
    chrome_extension_version: extensionVersion,
  };

  Amplitude.logEvent(Amplitude.EventCodes.EVENT_ORDER_DELIVERED, params);
};

const logOrderTaken = (
  order,
  selectedCookingTime,
  hasExtension,
  extensionVersion,
) => {
  if (!order) {
    return;
  }

  const PRODUCTS_WITH_DEFECT_RATE =
    order?.detail?.products
      ?.filter(x => x.has_defect_rate_report)
      .map(product => product.id) ?? [];

  const params = {
    order_id: get(order, 'id', null),
    cooking_time: selectedCookingTime,
    delivery_method: get(order, 'delivery_method', null),
    chrome_extension: hasExtension ? 'Y' : 'N',
    chrome_extension_version: extensionVersion,
    PRODUCTS_WITH_DEFECT_RATE,
  };

  Amplitude.logEvent(Amplitude.EventCodes.EVENT_ORDER_TAKEN, params);
};

const logReadyForPickup = (
  order,
  success,
  hasExtension,
  extensionVersion,
  ready_source,
) => {
  if (!order) {
    return;
  }

  const params = {
    order_id: get(order, 'id', null),
    STORE_ID: get(order, 'store_id', null),
    IS_GEOQUEUE: geoqueueHelper.isGeoqueue(order),
    CLICK_SUCCESSFUL: success,
    ready_source,
    delivery_method: get(order, 'delivery_method', null),
    chrome_extension: hasExtension ? 'Y' : 'N',
    chrome_extension_version: extensionVersion,
  };

  Amplitude.logEvent(Amplitude.EventCodes.EVENT_ORDER_READY, params);
};

const logSuccessfulRTRelease = (orderId, storekeeperId) => {
  const params = {
    orderId,
    storekeeperId,
  };

  Amplitude.logEvent(Amplitude.EventCodes.RELEASE_RT_SUCCESS, params);
};

const logException = ex => {
  const exParams = get(ex, 'params', null);
  let amplitudeParams = {};

  if (!!exParams && typeof exParams === 'object') {
    amplitudeParams = { ...ex.params };
  }

  amplitudeParams.ERROR_CODE = get(ex, 'PWEXCode', null) || PWEXCodes.PWEX0;
  amplitudeParams.message = get(ex, 'message', null);

  amplitudeParams.partnerId = appStore.getPartnerId();
  amplitudeParams.stack = get(ex, 'stack', null);

  Amplitude.logEvent(Amplitude.EventCodes.ERROR_PWEX, amplitudeParams);
};

const setAnonymousUser = async () => {
  window.amplitude.getInstance().setUserId(null);
  const identify = new window.amplitude.Identify();
  window.amplitude.getInstance().identify(identify);
  await ChromeExtensionService.setAmplitudeAnonymousUser();
};

const setUser = async () => {
  const errorNotPartnerId = 'not partnerId';
  const errorNotCountryCode = 'not countryCode';
  try {
    const partnerId = appStore.getPartnerId();
    if (!Boolean(partnerId)) throw Error(errorNotPartnerId);
    const countryCode = LocalStorageService.getCountryCode();
    if (!Boolean(countryCode)) throw Error(errorNotCountryCode);
    window.amplitude.getInstance().setUserId(`${countryCode}_${partnerId}`);
    const identify = new window.amplitude.Identify();
    identify.set('partner_country', countryCode);
    identify.set('WEB_PLATFORM_DEVICE', checkWebPlatformDevice());
    window.amplitude.getInstance().identify(identify);
    await ChromeExtensionService.setAmplitudeUser(countryCode, partnerId);
  } catch (ex) {
    if ([errorNotPartnerId, errorNotCountryCode].includes(ex.message)) {
      await Amplitude.setAnonymousUser();
    }
  }
};

const logShowPhoneButton = (orderId, partnerOrderState, orderState, isOM) => {
  const params = {
    order_id: orderId,
    partner_order_state: partnerOrderState,
    state: orderState,
    IS_OM: isOM,
  };
  Amplitude.logEvent(Amplitude.EventCodes.PROXY_SESSION_START_FLOW, params);
};

const logSuccessfulProxySessionCreation = (orderId, isOM) => {
  const params = {
    order_id: orderId,
    IS_OM: isOM,
  };
  Amplitude.logEvent(Amplitude.EventCodes.PROXY_SESSION_END_SUCCESS, params);
};

const logProxySessionInvalidPhone = orderId => {
  const params = {
    order_id: orderId,
  };
  Amplitude.logEvent(Amplitude.EventCodes.PROXY_SESSION_INPUT_ERROR, params);
};

const logFailedProxySessionCreation = orderId => {
  const params = {
    order_id: orderId,
  };
  Amplitude.logEvent(Amplitude.EventCodes.PROXY_SESSION_END_ERROR, params);
};

const logSuccessFulWhimCreation = orderId => {
  const params = {
    order_id: orderId,
  };
  Amplitude.logEvent(Amplitude.EventCodes.ADD_WHIM_SUCCESS, params);
};

const logFailedWhimCreation = orderId => {
  const params = {
    order_id: orderId,
  };
  Amplitude.logEvent(Amplitude.EventCodes.ADD_WHIM_SERVICE_ERROR, params);
};

const logSuccessfulWhimDelete = (orderId, WhimId) => {
  const params = {
    order_id: orderId,
    whim_id: WhimId,
  };
  Amplitude.logEvent(Amplitude.EventCodes.DELETE_WHIM_SUCCESS, params);
};

const logOnClickAddWhimButton = orderId => {
  const params = {
    order_id: orderId,
  };
  Amplitude.logEvent(Amplitude.EventCodes.ADD_WHIM_START_FLOW, params);
};

const logOnOpenSupportReasonsDialog = orderId => {
  const params = {
    order_id: orderId,
  };
  Amplitude.logEvent(Amplitude.EventCodes.SUPPORT_START_FLOW, params);
};

const logOnSelectedSupportReasonList = (orderId, selectReason) => {
  const params = {
    order_id: orderId,
    SUPPORT_REASON_ID: selectReason,
  };
  Amplitude.logEvent(Amplitude.EventCodes.SUPPORT_SELECT_REASON, params);
};

const logOnClickSelectedSuggestion = (orderId, selectedSolution) => {
  const params = {
    order_id: orderId,
    SUPPORT_SOLUTION_ID: selectedSolution,
  };
  Amplitude.logEvent(Amplitude.EventCodes.SUPPORT_SELECT_SOLUTION, params);
};

const logSuccessfulArticle = articleId => {
  const params = {
    article_id: articleId,
  };
  Amplitude.logEvent(Amplitude.EventCodes.HELP_CENTER_OPEN_ARTICLE, params);
};

const logSuccessfulCategory = sectionId => {
  const params = {
    category_id: sectionId,
  };
  Amplitude.logEvent(Amplitude.EventCodes.HELP_CENTER_OPEN_CATEGORY, params);
};

const logSuccessfulMassiveToppingDeactivaction = (storeId, ToppingsLength) => {
  const params = {
    store_id: storeId,
    ammount_topping_selected: ToppingsLength,
  };
  Amplitude.logEvent(
    Amplitude.EventCodes.SAVE_TOPPING_MASSIVE_STOCKOUT_DRAWER,
    params,
  );
};

const logOnOpenMassiveToppingDeactivactionDrawer = storeId => {
  const params = {
    store_id: storeId,
  };
  Amplitude.logEvent(
    Amplitude.EventCodes.OPEN_TOPPING_MASSIVE_STOCKOUT_DRAWER,
    params,
  );
};

const logOnOpenStoreDiagnosticDialog = storeId => {
  const params = {
    store_id: storeId,
  };
  Amplitude.logEvent(Amplitude.EventCodes.DIAGNOSTIC_CLICK, params);
};
const logOnDisconnectionPartnerVoluntary = (
  partnerId,
  hasExtension,
  appVersion,
) => {
  const params = {
    partner_id: partnerId,
    chrome_extension: hasExtension,
    app_version: appVersion,
  };
  Amplitude.logEvent(Amplitude.EventCodes.PARTNER_DISCONNECTION, params);
};
const logOnConnectionPartnerVoluntary = (
  partnerId,
  hasExtension,
  appVersion,
) => {
  const params = {
    partner_id: partnerId,
    chrome_extension: hasExtension,
    app_version: appVersion,
  };
  Amplitude.logEvent(Amplitude.EventCodes.PARTNER_CONNECTION, params);
};
const logNetworkError = (partnerId, hasExtension, appVersion) => {
  const params = {
    partner_id: partnerId,
    chrome_extension: hasExtension,
    app_version: appVersion,
  };
  Amplitude.logEvent(Amplitude.EventCodes.NETWORK_DISCONNECTION, params);
};
const logOnConnectionEstablished = (partnerId, hasExtension, appVersion) => {
  const params = {
    partner_id: partnerId,
    chrome_extension: hasExtension,
    app_version: appVersion,
  };
  Amplitude.logEvent(Amplitude.EventCodes.NETWORK_CONNECTION, params);
};
const logOpenNotificationCenter = partnerId => {
  const params = {
    partner_id: partnerId,
  };
  Amplitude.logEvent(Amplitude.EventCodes.NOTIFICATION_CENTER_OPEN, params);
};
const logOnClickNotificationLink = (partnerId, label, url, id) => {
  const params = {
    partner_id: partnerId,
    label: label,
    url: url,
    NOTIFICATION_ID: id,
  };
  Amplitude.logEvent(Amplitude.EventCodes.NOTIFICATION_CTA, params);
};

const logOnClickNotifyRT = (partnerId, orderId) => {
  const order = appStore.getOrderById(orderId);
  const params = {
    partner_id: partnerId,
    order_id: orderId,
    store_id: order?.store_id ?? null,
  };
  Amplitude.logEvent(Amplitude.EventCodes.NOTIFY_RT, params);
};

const logSendSuggestProduct = (
  orderId,
  partnerId,
  suggestion,
  refuseReason,
) => {
  const params = {
    order_id: orderId,
    partner_id: partnerId,
    suggestion_text: suggestion,
    reject_reason: refuseReason,
  };
  Amplitude.logEvent(Amplitude.EventCodes.SEND_SUGGESTION, params);
};
const logRejectOrder = (orderId, partnerId) => {
  const params = {
    order_id: orderId,
    partner_id: partnerId,
  };
  Amplitude.logEvent(Amplitude.EventCodes.REJECT_ORDER, params);
};
const logStartOnboarding = (partnerId, source) => {
  const params = {
    partner_id: partnerId,
    source: source,
  };
  Amplitude.logEvent(Amplitude.EventCodes.START_ONBOARDING_FLOW, params);
};
const logEndOnboarding = (partnerId, source) => {
  const params = {
    partner_id: partnerId,
    source: source,
  };
  Amplitude.logEvent(Amplitude.EventCodes.END_ONBOARDING_FLOW, params);
};

const logSelectedArticle = (
  partnerId,
  categoryName,
  subCategoryName,
  articleName,
) => {
  const params = {
    partner_id: partnerId,
    category_name: categoryName,
    sub_category: subCategoryName,
    article_name: articleName,
  };
  Amplitude.logEvent(Amplitude.EventCodes.SELECT_ARTICLE_RA, params);
};
const logDownloadChromeExtension = partnerId => {
  const params = {
    partner_id: partnerId,
  };
  Amplitude.logEvent(Amplitude.EventCodes.DONWLOAD_CHROME_EXTENSION, params);
};

const logStockOutReplacementSelection = (partnerId, status) => {
  const params = {
    partner_id: partnerId,
    selection: status,
  };
  Amplitude.logEvent(Amplitude.EventCodes.STOCKOUT_MODAL_1, params);
};
const logOpenPMDialogIntro = (partnerId, storeId) => {
  const params = {
    partner_id: partnerId,
    store_id: storeId,
  };
  Amplitude.logEvent(Amplitude.EventCodes.PM_MODAL_INTRO, params);
};
const logSendCompletePMDialog = (partnerId, storeId) => {
  const params = {
    partner_id: partnerId,
    store_id: storeId,
  };
  Amplitude.logEvent(Amplitude.EventCodes.PM_MODAL_FINISH, params);
};

const logSelectedSideBarOption = (partnerId, option) => {
  const params = {
    PARTNER_ID: partnerId,
    SIDEBAR_SECTION: option,
  };
  Amplitude.logEvent(Amplitude.EventCodes.SIDEBAR_SELECTION, params);
};

const logLoginScreenSelection = selectedOption => {
  const params = {
    option_selected: selectedOption,
  };
  Amplitude.logEvent(Amplitude.EventCodes.LOGIN_SCREEN_OPTIONS, params);
};

const logErrorOnLogin = (email, httpStatus, httpResponseBody) => {
  const params = {
    email: email,
    http_status_code: httpStatus,
    http_response_body: httpResponseBody,
  };
  Amplitude.logEvent(Amplitude.EventCodes.LOGIN_ERROR, params);
};
const logPrintedOrder = eventParams => {
  const params = {
    ...eventParams,
    PARTNER_ID: appStore.getPartnerId() || 0,
  };
  Amplitude.logEvent(Amplitude.EventCodes.EVENT_PRINTED_ORDER, params);
};

const Amplitude = {
  EventCodes,
  initialize,
  logClickDynActionCTA,
  logClickOpenDetailFromOrderCard,
  logClickOrderTotalsRetryButton,
  logOnClickNotificationLink,
  logCloseDynActionCTA,
  logOnConnectionEstablished,
  logEvent,
  logException,
  logFailedProxySessionCreation,
  logFailedRTRelease,
  logFailedWhimCreation,
  logLogIn,
  logLogOut,
  logNetworkError,
  logNewOrdersRawReception,
  logNewOrdersReception,
  logOnClickAddWhimButton,
  logOnClickSelectedSuggestion,
  logOnConnectionPartnerVoluntary,
  logOnOpenMassiveToppingDeactivactionDrawer,
  logOnOpenSupportReasonsDialog,
  logOnOpenStoreDiagnosticDialog,
  logOnSelectedSupportReasonList,
  logOrderDelivered,
  logOrderTaken,
  logOpenNotificationCenter,
  logOnDisconnectionPartnerVoluntary,
  logProxySessionInvalidPhone,
  logReadyForPickup,
  logRejectOrder,
  logShowPhoneButton,
  logSuccessfulArticle,
  logSuccessfulCategory,
  logSuccessfulMassiveToppingDeactivaction,
  logSuccessfulProxySessionCreation,
  logSuccessfulRTRelease,
  logSuccessFulWhimCreation,
  logSuccessfulWhimDelete,
  logSendSuggestProduct,
  setAnonymousUser,
  setUser,
  logOnClickNotifyRT,
  logStartOnboarding,
  logEndOnboarding,
  logSelectedArticle,
  logDownloadChromeExtension,
  logStockOutReplacementSelection,
  logOpenPMDialogIntro,
  logSendCompletePMDialog,
  logSelectedSideBarOption,
  logLoginScreenSelection,
  logErrorOnLogin,
  logPrintedOrder,
};

export default Amplitude;
