import React from 'react';

export function TrashIcon({ type }: Props) {
  const colorType = {
    ['disabled']: '#cdcdce',
    ['danger']: '#b81b1b',
    ['default']: '#404041',
  }[type || 'default'];

  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.58443 1.12598C6.72723 1.12598 5.97355 1.70072 5.751 2.53307C5.74583 2.5524 5.74128 2.57189 5.73736 2.59151L5.55516 3.50328C5.52723 3.64239 5.40453 3.74273 5.26318 3.74273C5.26171 3.74273 5.26023 3.74273 5.25875 3.74274H2.8125C2.29473 3.74274 1.875 4.16247 1.875 4.68024C1.875 5.198 2.29473 5.61774 2.8125 5.61774H15.531C16.0488 5.61774 16.4685 5.198 16.4685 4.68024C16.4685 4.16247 16.0488 3.74274 15.531 3.74274H13.0849C13.0834 3.74273 13.0819 3.74273 13.0804 3.74273C12.9391 3.74273 12.8164 3.64224 12.7884 3.50314L12.6063 2.59151C12.6023 2.57189 12.5978 2.5524 12.5926 2.53307C12.3701 1.70072 11.6164 1.12598 10.7592 1.12598H7.58443ZM10.9242 3.74274L10.7779 3.01024C10.7739 3.00473 10.7673 3.00098 10.7592 3.00098H7.58443C7.57631 3.00098 7.56972 3.00473 7.56575 3.01024L7.41938 3.74274H10.9242ZM14.569 6.16702C15.0851 6.20863 15.4697 6.66074 15.4281 7.17683L15.4101 7.39886C15.3985 7.54143 15.3816 7.7474 15.3606 8.00119C15.3187 8.5087 15.2603 9.20766 15.1945 9.97333C15.0633 11.5004 14.9016 13.3063 14.7819 14.3836C14.7069 15.0616 14.4473 15.6923 13.9474 16.1567C13.4452 16.6231 12.7934 16.8357 12.0987 16.8483C10.1315 16.8838 8.15996 16.8861 6.19012 16.8445C5.50347 16.8304 4.86434 16.6082 4.37508 16.1419C3.89014 15.6797 3.63514 15.0567 3.56133 14.3889C3.44099 13.3024 3.27936 11.4949 3.14853 9.96875C3.08293 9.20358 3.02479 8.50572 2.98305 7.99917C2.96218 7.74587 2.9454 7.54035 2.93383 7.39811L2.91591 7.17669C2.87441 6.66058 3.25916 6.2085 3.77526 6.167C4.29136 6.12551 4.74338 6.51031 4.78487 7.02641L4.80267 7.24619C4.81418 7.38783 4.8309 7.59265 4.85171 7.84518C4.89334 8.35029 4.9513 9.04602 5.01668 9.8086C5.14777 11.3378 5.30754 13.1226 5.42494 14.1825C5.46142 14.5129 5.5702 14.6907 5.66869 14.7846C5.7628 14.8743 5.92669 14.9637 6.22907 14.9699C8.17116 15.0109 10.1183 15.0087 12.0645 14.9736C12.3979 14.9675 12.5743 14.873 12.6712 14.7829C12.7704 14.6908 12.8809 14.5153 12.9184 14.177L13.3264 9.81283L13.5592 7.02615C13.6008 6.51006 14.0529 6.12541 14.569 6.16702Z'
        fill={colorType}
      />
    </svg>
  );
}

interface Props {
  type?: 'disabled' | 'danger' | 'warning' | 'default';
}
