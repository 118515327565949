import { clsx } from 'clsx';
import { Box } from '@material-ui/core';

import CustomButton from '../common/buttons/custom-button';

import useStyles from './styles';
import { CheckCode } from '../main-kanban-card/components/check-code';
import { Alert } from '@material-ui/lab';
import { ExpandMore } from '@material-ui/icons';
import { OrderDeliveryDetail } from '../../features/order/delivery-detail';

export function KanbanCardFooter(props) {
  const {
    classes = {},
    buttonText = '',
    footerText = '',
    secondaryButtonText = '',
    onClick = () => {},
    onClickSecondaryButton = () => {},
    productConfirmationCode = null,
    productConfirmationCodeLabel = '',
    isInCooking = false,
    hasMoreOrdersReady = false,
    orderId = null,
    storeId = null,
    orderState = null,
    hasOrderDeliveryDetailsUpdated = false,
    isOpenOrderDeliveryDetails = false,
    onToggleOpenOrderDeliveryDetails = () => {},
    completeOrderDetailText = '',
    showOrderDeliveryDetails = false,
    hasOrderDeliveryDetailMessage = false,
    isMandatory = false
  } = props;

  const showRTHandoffBtn = !!productConfirmationCode && hasOrderDeliveryDetailsUpdated && !isInCooking && !hasMoreOrdersReady;
  const showMainBtn = !!buttonText && !showRTHandoffBtn;
  
  const _classes = useStyles({ showOrderDeliveryDetails, hasOrderDeliveryDetailMessage });

  if (showOrderDeliveryDetails) {
    return (
      <Box display='flex' flexDirection='column'>
        {!isOpenOrderDeliveryDetails && (
          <Alert 
            severity='error' 
            classes={{ root: _classes.alert, ico: _classes.alertIcon, message: _classes.alertMessage }} 
            action={<ExpandMore />}
            onClick={onToggleOpenOrderDeliveryDetails}
          >
            <span>{completeOrderDetailText}</span>
          </Alert>
        )}
        {isOpenOrderDeliveryDetails && (
          <OrderDeliveryDetail 
            orderId={orderId}
            storeId={storeId}
            orderState={orderState}
            onClose={onToggleOpenOrderDeliveryDetails}
            isInKanban
            isMandatory={isMandatory}
            hasDeliveryDetailMessage={hasOrderDeliveryDetailMessage}
          />
        )}
      </Box>
    )
  }

  return (
    <>
      {(!!footerText ||
        !!buttonText ||
        !!secondaryButtonText ||
        !!productConfirmationCode) && (
        <div className={clsx(_classes.root, classes?.root)}>
          {!!footerText && (
            <div className={clsx(_classes.footerText, classes?.footerText)}>
              {footerText}
            </div>
          )}
          {showMainBtn && (
            <CustomButton
              classes={{
                root: _classes.buttonRoot,
                submitButton: _classes.buttonSubmitRoot,
              }}
              fullWidth={false}
              onClick={async e => {
                await onClick(e);
              }}
              variant='contained'
            >
              {buttonText}
            </CustomButton>
          )}
          {!!secondaryButtonText && (
            <CustomButton
              classes={{
                root: _classes.secondaryButtonRoot,
                submitButton: _classes.secondaryButtonSubmitRoot,
              }}
              fullWidth={false}
              onClick={async e => {
                await onClickSecondaryButton(e);
              }}
              variant='text'
            >
              {secondaryButtonText}
            </CustomButton>
          )}
          {showRTHandoffBtn && (
            <CheckCode 
              label={productConfirmationCodeLabel} 
              code={productConfirmationCode}
            />
          )}
        </div>
      )}
    </>
  );
}
