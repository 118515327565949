import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../../shared/styles/colors';
import { BOX_SHADOW_OPT_2, VARIANT } from '../../shared/consts';

import type { Saturation } from '../../../../business/highDemand';

export default makeStyles({
  option: ({ selected = false, demandSelected }: Props) => ({
    width: 'fit-content',
    padding: '0.5rem 0.75rem',
    borderRadius: '0.5rem',
    boxShadow: 'none',
    backgroundColor: getBgColor({ selected, demandSelected }),
    border: `1px solid ${VARIANT[demandSelected].color}`,
    '&:hover': {
      boxShadow: selected ? 'none' : BOX_SHADOW_OPT_2,
      backgroundColor: getBgColor({ selected, demandSelected, hover: true }),
    },
  }),
  title: ({ selected = false, demandSelected }: Props) => ({
    fontWeight: 600,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    textAlign: 'center',
    color: getColor({ selected, demandSelected }),
  }),
});

function getColor({ selected, demandSelected }: Props) {
  return selected ? neutral.white : VARIANT[demandSelected].color;
}

function getBgColor(props: Props & { hover?: boolean }) {
  const { demandSelected, selected, hover = false } = props;
  const color = VARIANT[demandSelected].color;
  const bgColor = VARIANT[demandSelected].bgColor;
  if (hover) return selected ? color : bgColor;
  return selected ? color : neutral.white;
}

interface Props {
  selected: boolean;
  demandSelected: Saturation;
}
