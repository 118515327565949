import { createSlice } from '@reduxjs/toolkit';

import { addRTFetching } from './addRT.thunk';
import { ADD_RT_SLICE_NAME, INIT_ADD_RT_STATE } from './addRT.const';

const addRTSlice = createSlice({
  name: ADD_RT_SLICE_NAME,
  initialState: INIT_ADD_RT_STATE,
  reducers: {
    clear: () => addRTSlice.getInitialState(),
    closeDialog: () => addRTSlice.getInitialState(),
  },
  extraReducers(builder) {
    builder
      .addCase(addRTFetching.validate.pending, (_, { meta }) => ({
        ...addRTSlice.getInitialState(),
        selectedOrderId: meta.arg.orderId,
        status: 'validationInProgress',
      }))
      .addCase(addRTFetching.validate.fulfilled, (state, { payload }) => {
        state.status = 'validationAccepted';
        state.validatedData = payload;
      })
      .addCase(addRTFetching.validate.rejected, (state, { payload }) => {
        state.status = 'validationDenied';
        state.error = payload;
      });
    builder
      .addCase(addRTFetching.create.pending, state => {
        state.status = 'creationInProgress';
      })
      .addCase(addRTFetching.create.fulfilled, (state, { payload }) => {
        state.status = 'creationAccepted';
        state.createdAddRT = payload;
      })
      .addCase(addRTFetching.create.rejected, (state, { payload }) => {
        state.status = 'creationDenied';
        state.error = payload;
      });
  },
});

export const addRTSliceReducer = addRTSlice.reducer;

export const addRTAction = {
  ...addRTSlice.actions,
  ...addRTFetching,
};
