// Material UI
import { makeStyles } from '@material-ui/core';
// Constant
import Colors from '../../constants/Colors';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  appBarRoot: {
    boxShadow: 'none',
    height: 24,
    zIndex: theme.zIndex.drawer + 1,
  },
  closedPartnerState: {
    backgroundColor: `${Colors.WARNING_FONT_COLOR} !important`,
  },
  deactivatedPartnerState: {
    backgroundColor: `${Colors.SECONDARY_COLOR} !important`,
  },
  drawerPaper: {
    backgroundColor: neutral.D2,
    border: 'none',
    height: '100%',
    paddingTop: 24,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    whiteSpace: 'nowrap',
    width: 320,
  },
  openPartnerState: { backgroundColor: `${Colors.PRIMARY_COLOR} !important` },
}));
