import { clsx } from 'clsx';
import { useEffect, useState } from 'react';

import { CustomSelect, utils } from '../custom-select';

import useStyles from './styles';

import type { ChangeEvent } from 'react';
import type { Option, Props } from './interfaces';

export function CustomNumberSelectorBlock(props: Props) {
  const {
    label,
    description,
    min = 5,
    max = 20,
    value = 5,
    incrementedBy = 5,
    classes = {},
    onChange,
  } = props;

  const _classes = useStyles();

  const [minutesOptions, setMinutesOptions] = useState<Option[]>([
    { label, value },
  ]);

  useEffect(() => {
    const generatedMinutesOptions = utils.getNumberOptions(
      min,
      max,
      incrementedBy,
    );

    setMinutesOptions(generatedMinutesOptions);
  }, [min, max, incrementedBy]);

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <div className={clsx(_classes.description, classes?.description)}>
        {description}
      </div>
      <div className={_classes.timeSelectorContainer}>
        <CustomSelect
          value={value}
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            const eventValue = parseInt(event?.target.value);
            onChange(Number.isInteger(eventValue) ? eventValue : 0);
          }}
          options={minutesOptions}
        />
        <div className={_classes.label}>
          <span>{label}</span>
        </div>
      </div>
    </div>
  );
}
