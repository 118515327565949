// Material UI
import { makeStyles } from '@material-ui/core';
// Colors
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  article: {},
  articleContainer: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    position: 'relative',
    backgroundColor: neutral.white,
    [theme.breakpoints.up('md')]: {
      padding: 17,
      backgroundColor: 'transparent',
    },
  },
  sectionContent: {
    backgroundColor: neutral['20'],
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
}));
