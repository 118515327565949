// React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Utils
import OrdersUtils from '../../utils/OrdersUtils'
// Actions
import ordersKanbanActions from '../../redux/actions/ordersKanban.actions'
// Components
import OrderListChipBox from './OrderListChipBox'

const mapStateToProps = (
  { ordersReducer: { orders } },
  { intl: { formatMessage } },
) => {
  const canceledOrdersTitle = formatMessage({
    id: 'orderListChipBox.canceledOrdersTitle',
  })
  const newOrdersTitle = formatMessage({
    id: 'orderListChipBox.newOrdersTitle',
  })

  const showNewOrdersButton = OrdersUtils.filterNew(orders).length > 0
  const showCanceledOrdersButton =
    !showNewOrdersButton &&
    OrdersUtils.filterBeingManagedCanceled(orders).length > 0

  return {
    canceledOrdersTitle,
    newOrdersTitle,
    showCanceledOrdersButton,
    showNewOrdersButton,
  }
}

const mapDispatchToProps = {
  onClickBase: ordersKanbanActions.setKanbanIndex,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(OrderListChipBox),
)
