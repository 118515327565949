// React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Actions
import AppActions from './../../redux/actions/app-actions'
import OrderActions from '../../redux/actions/orders-actions'
// Components
import OrdersHistoricalFilterDrawer from './OrdersHistoricalFilterDrawer'

const mapStateToProps = (
  { ordersReducer, partnerReducer },
  { intl: { formatMessage } },
) => {
  return {
    currentSelectedStoreId: ordersReducer.historicalFilter.selectedStoreId,
    open: ordersReducer.historicalFilter.open,
    stores: partnerReducer.profile.stores,
  }
}
const mapDispatchToProps = {
  applyOrdersHistoricalFilter: OrderActions.redirectOrdersHistoricalFilter,
  onClearFilter: OrderActions.clearOrdersHistoricalFilter,
  onClose: OrderActions.closeOrdersHistoricalFilterDrawer,
  openSnackBarError: AppActions.openSnackBarError,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(OrdersHistoricalFilterDrawer),
)
