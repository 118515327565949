import { makeStyles } from '@material-ui/core';

import Colors from '../../../../constants/Colors';
import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(() => ({
  root: {
    color: neutral.D15,
  },
  iconRoot: {
    width: '2rem',
    height: '2rem',
    color: Colors.SUCCESS_COLOR,
  },
}));
