import { useEffect, useState } from 'react';

import appStore from '../../redux/stores/app.store';
import DateTimeUtils from '../../utils/DateTimeUtils';

import type { RTStatus } from '../../business/shared/domain';
import type { OrderResponseFromStatusV2 } from '../../business/shared/infra/core';

export function useRtArrivesOrWaitingTime(props: Props) {
  const { orderId } = props;

  const [theTime, setTheTime] = useState<number>(NaN);
  const [etaTimer, setEtaTimer] = useState<number>(NaN);
  const [rtStatus, setRtStatus] = useState<RTStatus>('searching');
  const [waitingStopwatch, setWaitingStopwatch] = useState<number>(NaN);

  const zoneId = appStore.getTz();
  const order: OrderResponseFromStatusV2 = appStore.getOrderById(orderId) ?? {};
  const showCounter: boolean = order.show_counter ?? false;
  const rtWaitingSince: string = order.rt_waiting_since ?? '';
  const etaToStore: number = Math.ceil(order.eta_to_store ?? NaN);
  const etaToStoreCreatedAt: TimeIsoString = order.eta_to_store_created_at;
  const existETA = etaToStore && etaToStoreCreatedAt;
  const etaCAT = existETA ? new Date(etaToStoreCreatedAt).getTime() : null;
  const isExcessiveWaitingTime = order.rt_waiting_time_exceeded;

  useEffect(() => {
    let rtWaitingTimerInterval: NodeJS.Timeout;
    let etaTimerInterval: NodeJS.Timeout;
    if (!showCounter && rtWaitingSince) {
      setTheTime(NaN);
      setRtStatus('waiting');
    } else if (showCounter && rtWaitingSince) {
      // @ts-ignore
      if (etaTimerInterval) clearInterval(etaTimerInterval ?? 0);
      setRtStatus(isExcessiveWaitingTime ? 'waitingMuch' : 'waiting');
      const rtWS = new Date(rtWaitingSince.replace(' ', 'T')).getTime();
      const waitingTime = DateTimeUtils.getDiffFromNow(rtWS, 'seconds', zoneId);
      setWaitingStopwatch(waitingTime);
      rtWaitingTimerInterval = setInterval(() => {
        setWaitingStopwatch(v => v + 10 ?? waitingTime);
      }, 10_000);
    } else if (existETA) {
      setRtStatus('onTheWay');
      const diffNow = DateTimeUtils.getDiffFromNow(etaCAT, 'seconds', zoneId);
      const eta: number = etaToStore - diffNow;
      setEtaTimer(eta);
      etaTimerInterval = setInterval(() => setEtaTimer(v => v - 10), 10_000);
    }
    return () => {
      clearInterval(etaTimerInterval);
      clearInterval(rtWaitingTimerInterval);
    };
  }, [order]);

  useEffect(() => {
    if (waitingStopwatch) {
      const waitingTime = Math.floor(waitingStopwatch / 60.0);
      setTheTime(waitingTime);
    } else if (etaTimer) {
      const arrivesTime = etaTimer >= 0 ? Math.ceil(etaTimer / 60.0) : 0;
      setTheTime(arrivesTime);
    }
  }, [etaTimer, waitingStopwatch]);

  return {
    theTime,
    rtStatus,
  };
}

type TimeIsoString = string | undefined | null;

interface Props {
  orderId: number;
}

// Origin logic to show -> rtWaitingSince using showCounter and zoneId
// previous at Revamped iconography and RT actions [RA-806]
// into DeliveryMethodLabel component
