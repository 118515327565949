import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../../shared/styles/colors';

import type { GlobalMode } from '../../shared/types';

interface Props {
  notCode?: boolean;
  globalMode?: GlobalMode;
}

export default makeStyles(() => ({
  root: ({ globalMode = false, notCode = false }: Props) => ({
    width: 'fit-content',
    height: 'inherit',
    padding: '0.5rem 0.75rem',
    display: notCode ? 'flex' : 'grid',
    alignItems: 'center',
    columnGap: globalMode ? '0.25rem' : 0,
    gridTemplateRows: globalMode ? '1fr auto' : 'auto',
    gridTemplateColumns: globalMode ? '1fr auto' : 'auto',
    textAlign: globalMode ? 'center' : 'left',
    border: `1px solid ${neutral['20']}`,
    borderRadius: '0.5rem',
    color: neutral['100'],
    backgroundColor: neutral.white,
    cursor: globalMode ? 'pointer' : 'auto',
    '&:focus-visible': {
      outline: 'none',
    },
  }),
  checkinCodeTextLine: {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
  },
}));
