// Consts
import { countryCodes } from '../../constants/CountryCodes';
//external lib
import { orderBy } from 'lodash';
// Utils
import Urls from '../../constants/Urls';
import UrlUtils from '../url-utils';
import StringUtils from '../StringUtils';

const isArgentina = countryCode => {
  return countryCode === countryCodes.ARGENTINA;
};
const isBrazil = countryCode => {
  return countryCode === countryCodes.BRAZIL;
};
const isColombia = countryCode => {
  return countryCode === countryCodes.COLOMBIA;
};
const isCostaRica = countryCode => {
  return countryCode === countryCodes.COSTA_RICA;
};
const isChile = countryCode => {
  return countryCode === countryCodes.CHILE;
};
const isEcuador = countryCode => {
  return countryCode === countryCodes.ECUADOR;
};
const isPeru = countryCode => {
  return countryCode === countryCodes.PERU;
};
const isMexico = countryCode => {
  return countryCode === countryCodes.MEXICO;
};
const isUruguay = countryCode => {
  return countryCode === countryCodes.URUGUAY;
};

const sortByName = countrys => {
  return orderBy(countrys, ['name']);
};

const getCountryImageUrl = countryCode => {
  if (process.env.NODE_ENV === 'development') {
    return 'https://images.dev.rappi.com';
  }
  switch (countryCode) {
    case countryCodes.COLOMBIA:
      return 'https://images.rappi.com';
    case countryCodes.CHILE:
      return `https://images.rappi.${countryCode}`;
    case countryCodes.ARGENTINA:
      return `https://images.rappi.com.${countryCode}`;
    case countryCodes.BRAZIL:
      return `https://images.rappi.com.${countryCode}`;
    case countryCodes.ECUADOR:
      return `https://images.rappi.com.${countryCode}`;
    case countryCodes.MEXICO:
      return `https://images.rappi.com.${countryCode}`;
    case countryCodes.COSTA_RICA:
      return `https://images-v2.rappi.co.${countryCode}`;
    case countryCodes.PERU:
      return `https://images.rappi.${countryCode}`;
    case countryCodes.URUGUAY:
      return `https://images.rappi.com.${countryCode}`;
    default:
      return `https://images.rappi.com.${countryCode}`;
  }
};

const formatLogoURL = (countryCode, logoURL) => {
  if (StringUtils.isNullOrEmpty(logoURL)) return '';
  let urlImage;
  urlImage = getCountryImageUrl(countryCode);
  const baseUrl = UrlUtils.replaceUrlParams(Urls.RESTAURANTS_LOGO, {
    urlImage,
  });
  return `${baseUrl}/${logoURL}`;
};

export default {
  isArgentina,
  isBrazil,
  isChile,
  isColombia,
  isCostaRica,
  isEcuador,
  isMexico,
  isPeru,
  isUruguay,
  sortByName,
  formatLogoURL,
  getCountryImageUrl,
};
