import { Box, Button, CircularProgress, Drawer } from '@material-ui/core';

import { Header, Subtitle } from './comps';

import makeClasses from './MainDrawer.style';

import type { MainDrawerProps } from './MainDrawer.types';

export function MainDrawer(Props: MainDrawerProps) {
  const {
    size = 'medium',
    onClose,
    onGoBack,
    subtitle,
    customLoading,
    isLoading = false,
    headerTitle = null,
    primaryBtn,
    secondaryBtn,
    extraActions,
    customActions,
    ...drawerProps
  } = Props;

  const showDefaultActions =
    !Boolean(customActions) &&
    (Boolean(primaryBtn?.text) ||
      Boolean(secondaryBtn?.text) ||
      Boolean(extraActions));

  const innerClasses = makeClasses({ size });

  return (
    <Drawer
      onClose={onClose}
      classes={{ paper: innerClasses.drawer }}
      {...drawerProps}
    >
      <Header
        onClose={onClose}
        onGoBack={onGoBack}
        classes={{
          root: innerClasses.header,
          titleContainer: innerClasses.headerTitle,
          titleH1: innerClasses.headerTitleH1,
        }}
      >
        {headerTitle}
      </Header>

      <main className={innerClasses.main}>
        {!!subtitle && (
          <section className={innerClasses.subtitleSection}>
            <Subtitle subtitle={subtitle} />
          </section>
        )}
        {!isLoading && drawerProps.children}
        {isLoading &&
          (customLoading ?? (
            <Box marginY='4rem' textAlign='center'>
              <CircularProgress size={48} />
            </Box>
          ))}
      </main>

      <footer className={innerClasses.footer}>
        {customActions}
        {showDefaultActions && (
          <>
            {extraActions}
            {Boolean(secondaryBtn?.text) && (
              <Button
                aria-label='secondary'
                color='primary'
                variant='text'
                size='small'
                onClick={secondaryBtn?.onClick}
                endIcon={
                  secondaryBtn?.loading && <CircularProgress size={16} />
                }
                disabled={
                  secondaryBtn?.loading || !Boolean(secondaryBtn?.onClick)
                }
              >
                {secondaryBtn?.text}
              </Button>
            )}
            {Boolean(primaryBtn?.text) && (
              <Button
                aria-label='primary'
                color='primary'
                variant='contained'
                size='small'
                onClick={primaryBtn?.onClick}
                endIcon={primaryBtn?.loading && <CircularProgress size={16} />}
                disabled={primaryBtn?.loading || !Boolean(primaryBtn?.onClick)}
              >
                {primaryBtn?.text}
              </Button>
            )}
          </>
        )}
      </footer>
    </Drawer>
  );
}
