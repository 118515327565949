import useStyles from './CustomReadOnlyHourInterval.styles';

function CustomReadOnlyHourInterval({ open, close }) {
  const _classes = useStyles();
  const showCloseBox = !!open || !!close;
  const openDisplay = !!open ? open : '-';
  const closeDisplay = !!close ? close : '-';
  return (
    <div className={_classes.root}>
      <div className={_classes.boxOpen}>{openDisplay}</div>
      {showCloseBox && <div className={_classes.boxClose}>{closeDisplay}</div>}
    </div>
  );
}

export default CustomReadOnlyHourInterval;
