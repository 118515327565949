// React
import React, { useEffect, useState } from 'react';
// Components
import CustomItemsFilterDrawer from './../common/custom-items-filter-drawer';

const OrdersListFilterDrawer = ({
  applyOrdersListFilter,
  onClearFilter,
  onClose,
  open,
  currentSelectedStoresIds,
  stores,
  title,
}) => {
  const [selectedStoresIds, setSelectedStoresIds] = useState(
    currentSelectedStoresIds,
  );

  useEffect(() => {
    setSelectedStoresIds(currentSelectedStoresIds);
  }, [currentSelectedStoresIds]);

  const handleApplyFilter = () => {
    applyOrdersListFilter(selectedStoresIds);
  };

  const handleItemsSelectionChange = selectedItems => {
    setSelectedStoresIds(selectedItems);
  };

  return (
    <CustomItemsFilterDrawer
      drawerTitle={title}
      items={stores}
      onApplyFilter={handleApplyFilter}
      onClearFilter={onClearFilter}
      onClose={onClose}
      onItemsSelectionChange={handleItemsSelectionChange}
      open={open}
      selectedItems={selectedStoresIds}
      showAllOption
    ></CustomItemsFilterDrawer>
  );
};

OrdersListFilterDrawer.defaultProps = {
  currentSelectedStoresIds: [],
};

export default OrdersListFilterDrawer;
