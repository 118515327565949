import { green, neutral, orange, red, teal, yellow } from './colors.const';

export const colorTheme = {
  brand: {
    neon: '#FF441F',
    primary: {
      main: teal[50],
      light: teal[20],
    },
    secondary: {
      main: red[50],
    },
  },
  elevated: {
    regular: '#2122240A',
    strong: '#21222414',
    hard: '#21222429',
  },
  orderType: {
    bundle: {
      primary: {
        main: teal[80],
        contrastText: teal[10],
      },
    },
  },
  rtStatus: {
    searching: {
      bg: neutral['20'],
      text: neutral['80'],
    },
    onTheWay: {
      bg: green['10'],
      text: green['60'],
    },
    waiting: {
      bg: orange['10'],
      text: orange['60'],
    },
    waitingMuch: {
      bg: red['10'],
      text: red['50'],
    },
  },
  turbo: {
    danger: red[50],
    tag: green.D7,
    warning: yellow[50],
  },
};
