const confirmButtonText = {
  id: 'supportDialog.confirm',
};

const description = {
  id: 'supportDialog.description',
};

const title = {
  id: 'supportDialog.title',
};

const textIds = {
  confirmButtonText,
  description,
  title,
};

export default textIds;
