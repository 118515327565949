// Consts
import urls from '../constants/Urls';
// Utils
import UrlUtils from '../utils/url-utils/UrlUtils';
// Services
import http from './HttpService';
import LocalStorageService from './local-storage-service';

const getFirebaseStatus = () => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  return http.get(
    `${microserviceUrl}${urls.REAL_TIME_INTERFACE_FIREBASE_STATUS}`,
  );
};
const loginFirebase = () => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  return http.get(`${microserviceUrl}${urls.REAL_TIME_INTERFACE_LOGIN}`);
};
const sendSupportChatMessageAck = (messageId) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.REAL_TIME_INTERFACE_ACK, {
    messageId,
  });
  return http.put(`${microserviceUrl}${replacedUrl}`);
};

const sendBulkSupportChatMessageAck = (messages) => {
  if (!messages) {
    return;
  }

  for (const message of messages) {
    sendSupportChatMessageAck(message.id);
  }
};

const sendSupportChatMessage = (message, orderId, partnerId, partnerName) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const payload = {
    name: partnerName,
    chat_type: 'tablet_support',
    order_id: orderId,
    data_type: 'text',
    data: message,
    receiver_type: 'support',
    sender_type: 'tablet',
    receiver_id: 0,
    sender_id: partnerId,
  };

  return http.post(
    `${microserviceUrl}${urls.REAL_TIME_INTERFACE_CHAT}`,
    payload,
  );
};

const postNotification = (
  orderId,
  partnerId,
  partnerName,
  typification,
  typificationDescription,
) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();

  const payload = {
    chat_type: typification,
    data: typificationDescription,
    data_type: 'text',
    name: partnerName,
    notification_type: typification,
    order_id: orderId,
    receiver_id: '0',
    receiver_type: 'support',
    sender_id: partnerId,
    sender_type: 'tablet',
  };
  return http.post(
    `${microserviceUrl}${urls.REAL_TIME_INTERFACE_NOTIFICATION}`,
    payload,
  );
};

export default {
  getFirebaseStatus,
  loginFirebase,
  postNotification,
  sendBulkSupportChatMessageAck,
  sendSupportChatMessage,
};
