// External libs
import { createReducer } from '@reduxjs/toolkit'
// Interfaces
import { State } from '../interfaces'
import { OnBoardingDialogType } from '../../../constants/OnBoardingDialogType'
// Actions
import actions from '../actions'
//StepList
const initialState: State = {
  open: false,
  steps: [
    {
      code: '',
      index: 0,
      type: OnBoardingDialogType.Info,
      title: '',
      description: '',
      image_xs: '',
      image_sm: '',
      image_md: '',
      image_lg: '',
      image_xl: '',
    },
  ],
  source: '',
  currentStepIndex: 0,
}

export default createReducer(initialState, {
  [actions.clearAction.type]: state => clear(state),
  [actions.closeAction.type]: state => close(state),
  [actions.openAction.type]: (state, { payload: { steps, source } }) =>
    open(state, steps, source),
  [actions.nextStepAction.type]: state => nextStep(state),
  [actions.backStepAction.type]: state => backStep(state),
})

const clear = (state: State) => {
  return initialState
}

const close = (state: State) => {
  state.open = false
  return state
}

const open = (state: State, steps = state.steps, source: string) => {
  state.open = true
  state.steps = steps
  state.source = source
  return state
}

const nextStep = (state: State) => {
  state.currentStepIndex =
    state.currentStepIndex === state.steps.length - 1
      ? state.currentStepIndex
      : state.currentStepIndex + 1
  return state
}
const backStep = (state: State) => {
  state.currentStepIndex =
    state.currentStepIndex === 0
      ? state.currentStepIndex
      : state.currentStepIndex - 1
  return state
}
