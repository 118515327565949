// React
import React from 'react'
// External libs
import ReactMarkdown from 'react-markdown'
// Interfaces
import { Props } from './props'
// Styles
import { useTheme, useMediaQuery } from '@material-ui/core'
import useStyles from './styles'

const OnBoardingInfoBody: React.FC<Props> = ({
  title = '',
  description = '',
  image = '',
}) => {
  const componentClasses = useStyles()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <div className={componentClasses.container}>
      <div className={componentClasses.banner}>
        <img
          className={componentClasses.img}
          src={image}
          alt='onboarding-info-img'
        />
      </div>
      <div className={componentClasses.description}>
        {!isMdUp && <div className={componentClasses.mobileTitle}>{title}</div>}
        <ReactMarkdown
          className={componentClasses.markDownDescription}
          linkTarget='_blank'
        >
          {description}
        </ReactMarkdown>
      </div>
    </div>
  )
}

export default OnBoardingInfoBody
