// External libs
import { get } from 'lodash'
// Consts
import { partnerStateType } from '../constants/PartnerStateType'
import PartnerTypes from '../constants/PartnerTypes'

const isIntegratedPartner = profile => {
  const partnerType = get(profile, 'type')
  return PartnerTypes.PARTNERS_INTEGRADO === partnerType
}
const isClosed = partnerState => {
  return partnerState === partnerStateType.CLOSED
}
const isOpen = partnerState => {
  return partnerState === partnerStateType.OPEN
}
export default {
  isIntegratedPartner,
  isClosed,
  isOpen,
}
