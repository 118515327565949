import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => ({
  beingEditedContainer: {
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, min-content)',
  },
  cancelButtonButton: {
    padding: 3,
  },
  cancelButtonIcon: {
    height: 18,
    width: 18,
  },
  checkButtonButton: {
    padding: 3,
  },
  checkButtonIcon: {
    height: 18,
    width: 18,
  },
  editButtonButton: {
    padding: 3,
  },
  editButtonIcon: {
    color: neutral['50'],
    height: 18,
    width: 18,
  },
  notBeingEditedContainer: {
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, min-content)',
  },
  pickersContainer: {
    alignItems: 'center',
    display: 'grid',
    gridAutoRows: 'min-content',
    gridColumnGap: 14,
    gridTemplateColumns: 'repeat(2, 70px)',
    [theme.breakpoints.up('md')]: {
      gridColumnGap: 90,
      gridTemplateColumns: 'repeat(2, 100px)',
    },
  },
  root: {
    alignItems: 'center',
    display: 'grid',
    gridAutoRows: 'min-content',
  },
}));
