// External libs
import { createActions } from 'redux-actions';
// Actions
import appStore from '../stores/app.store';

const { setKanbanIndexAction, setOrdersAction } = createActions({
  SET_KANBAN_INDEX_ACTION: index => ({ index }),
  SET_ORDERS_ACTION: (orders, searchFieldText, tz) => ({
    orders,
    searchFieldText,
    tz,
  }),
});

const setKanbanIndex = index => dispatch => {
  dispatch(setKanbanIndexAction(index));
};

const setOrders = () => dispatch => {
  const filteredBeingManagedOrdersBySelectedStore = appStore.getFilteredBeingManagedOrdersBySelectedStores();
  const tz = appStore.getTz();
  const searchFieldText = appStore.getSearchFieldText();
  dispatch(
    setOrdersAction(
      filteredBeingManagedOrdersBySelectedStore,
      searchFieldText,
      tz,
    ),
  );
};

export default {
  setKanbanIndex,
  setKanbanIndexAction,
  setOrders,
  setOrdersAction,
};
