import { get } from 'lodash';

export const replaceUrlParams = (url, params) => {
  if (!url || !params) {
    return url;
  }
  let newUrl = url.slice(0);
  Object.keys(params).map(function(param) {
    newUrl = newUrl.replace(':' + param, get(params, param, ''));
    return null;
  });

  return newUrl;
};

/**
 * Ensures that the url uses https instead of http;
 * @function ensureUrlFormat
 * @param {string} url
 */
export const ensureHttps = url => {
  if (!url) {
    return url;
  }
  return url.replace('http://', 'https://');
};

/**
 * Ensures that the url uses https instead of http, and it always ends with /
 * @function ensureUrlFormat
 * @param {string} url
 */
export const ensureUrlFormat = url => {
  if (!url) {
    return url;
  }
  // First, we need to ensure that the url
  let urlFormatted = ensureHttps(url);

  if (urlFormatted.slice(-1) !== '/') {
    // If the URL doesn't end with /, add it. This is done because the data changes frequently so it is necessary to ensure the same url pattern.
    urlFormatted += '/';
  }

  return urlFormatted;
};

export default {
  ensureHttps,
  ensureUrlFormat,
  replaceUrlParams,
};
