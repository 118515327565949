import { SUSPENDED_NOTIFY_SS } from '../constants';

import type {
  StoreId,
  SuspendedStoreNotifyAlerted,
  SuspendedStoreNotifyResp,
} from '../models';

function getCache(): SuspendedStoreNotifyAlerted[] {
  const result = window.sessionStorage.getItem(SUSPENDED_NOTIFY_SS);
  return result ? JSON.parse(result) : null;
}

function setCache(list: SuspendedStoreNotifyAlerted[]) {
  window.sessionStorage.setItem(SUSPENDED_NOTIFY_SS, JSON.stringify(list));
}

function initialize() {
  const currentCache = getCache();
  if (currentCache === null) setCache([]);
}

function updateAll(ssn: SuspendedStoreNotifyResp[] = []) {
  const ssnCache = getCache();
  const ssnNextCache = ssn.map(nextStore => ({
    ...nextStore,
    alerted:
      ssnCache?.find(
        ({ storeId, createdAt }) =>
          storeId === nextStore.storeId && createdAt === nextStore.createdAt,
      )?.alerted ?? false,
  }));
  setCache(ssnNextCache);
  return ssnNextCache;
}

function updateByStoreId({ storeId = NaN }: StoreId) {
  const cache = getCache();
  setCache(
    cache.map(s => ({
      ...s,
      alerted: s.storeId === storeId || s.alerted,
    })),
  );
}

export const cache = {
  initialize,
  updateAll,
  updateByStoreId,
};

export function getCacheSomeStoreIsNotAlerted() {
  const ssnCache = getCache();
  return ssnCache?.some(item => !item.alerted);
}
