import { makeStyles } from '@material-ui/core';

import { neutral, teal } from '../../styles/colors';

export default makeStyles({
  radioGroup: {
    gap: '0.5rem',
  },
  rootLabel: {
    margin: 0,
  },
  label: {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
  },
  radioRoot: {
    padding: '0 0.125rem',
  },
  radioPrimaryColor: {
    color: neutral['50'],
    '&$checked': {
      color: teal['50'],
    },
  },
});
