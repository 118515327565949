// React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Components
import ChromeExtensionDownloadSetting from './ChromeExtensionDownloadSetting'
//actions
import AppActions from '../../redux/actions/app-actions'

const mapStateToProps = (
  { ordersReducer, partnerReducer },
  { intl: { formatMessage } },
) => {
  const chromeExtensionTitle = formatMessage({
    id: 'settings.chromeExtensionTitle',
  })
  const chromeExtensionDescription = formatMessage({
    id: 'settings.chromeExtensionDescription',
  })
  const chromeExtensionButton = formatMessage({
    id: 'settings.chromeExtensionButton',
  })

  return {
    chromeExtensionTitle,
    chromeExtensionDescription,
    chromeExtensionButton,
    partnerId: partnerReducer.profile.id,
  }
}
const mapDispatchToProps = {
  openSnackBarSuccess: AppActions.openSnackBarSuccess,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ChromeExtensionDownloadSetting),
)
