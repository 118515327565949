import { makeStyles } from '@material-ui/core';

import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  actionsBoxRoot: {
    marginTop: 'auto',
    padding: '1.5rem',
    [theme.breakpoints.up('md')]: {
      borderTop: ` 1px solid ${neutral['20']}`,
    },
  },
  itemsContainer: {
    display: 'grid',
    gridRowGap: '24px',
    gridAutoRows: 'min-content',
    margin: '24px 0px',
  },
  root: {
    alignContent: 'space-between',
    background: neutral.white,
    display: 'grid',
  },
}));
