// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {
    color: neutral.D20,
    fontFamily: 'Poppins',
    fontSize: 17,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    width: '100%',
  },
  titleContainer: {
    margin: '24px 16px 0px 16px',
  },
  toppingsContainer: {
    padding: '0px 16px 40px 16px',
  },
  toppingBoxRoot: {
    marginTop: '24px',
  },
}));
