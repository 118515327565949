const okButtonText = {
  id: 'storeDiagnosticsDialog.okButtonText',
}
const title = {
  id: 'storeDiagnosticsDialog.title',
}

const textIds = {
  okButtonText,
  title,
}

export default textIds
