export const countryCodes = {
  ARGENTINA: 'AR',
  BRAZIL: 'BR',
  CHILE: 'CL',
  COLOMBIA: 'CO',
  COSTA_RICA: 'CR',
  DEV: 'Dev',
  ECUADOR: 'EC',
  MEXICO: 'MX',
  PERU: 'PE',
  URUGUAY: 'UY',
};
export default { countryCodes };
