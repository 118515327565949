import { Chip } from '@material-ui/core';

import { useOrderTypeChipTexts } from './useOrderTypeChipTexts';
import { ReactComponent as BundleIcon } from '../../assets/images/icons/multipleBag.svg';

import makeClasses from './OrderTypeChip.style';
import { orderTypeAsFlags } from './OrderTypeChip.util';

import type { ChipProps } from '@material-ui/core';
import type { OrderType } from './OrderTypeChip.type';

export function OrderTypeChip(props: Props) {
  const {
    orderType,
    extraLabel = '',
    classes: externalClasses,
    ...overProps
  } = props;
  if (orderType === 'normal') return null;

  const text = useOrderTypeChipTexts({ orderType });

  const classes = makeClasses();

  return (
    <Chip
      role='status'
      aria-live='polite'
      label={`${text} ${extraLabel}`.trim()}
      icon={<TheIcon orderType={orderType} />}
      classes={{
        root: classes.root,
        icon: classes.icon,
        label: classes.label,
        ...externalClasses,
      }}
      {...overProps}
    />
  );
}

function TheIcon({ orderType }: { orderType: OrderType }) {
  const { isBundle } = orderTypeAsFlags(orderType);
  return isBundle ? <BundleIcon /> : null;
}

interface Props extends ChipProps {
  orderType: OrderType;
  extraLabel?: string;
}
