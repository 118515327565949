import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import {
  editScheduleArticleId,
  editScheduleCategoryId,
  infoArticleId,
  infoCategoryId,
  storeCtaOpts,
} from '../../../../../../components/store-status/constants';
import Paths from '../../../../../../constants/Paths';
import dialogsManagerActions from '../../../../../../redux/actions/dialogsManager.actions';
import EventActions from '../../../../../../redux/actions/event-actions/event.actions';
import partnerActions from '../../../../../../redux/actions/partners-actions/partners.actions';
import { dialogStoreSuspended } from '../../../../../../shared/services';

import type {
  PartnerId,
  StoreId,
} from '../../../../../../business/shared/domain';
import type { TpStoreCtaOpts } from '../../../../../../components/store-status/types';

export function useEmptyStoreStatus({
  cta,
  storeId,
  partnerId,
  onClose,
}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matchUrl = useMatch(Paths.STORE_MANAGEMENT);
  const [isLoading, setIsLoading] = useState(false);

  function handleCTA() {
    setIsLoading(true);
    if (cta === storeCtaOpts['cta.activate'].cta) {
      const payload = { storeIds: [storeId], enabled: true };
      dispatch(partnerActions.switchPartnerStoresStatus(payload));
    }

    if (cta === storeCtaOpts['cta.contact-support'].cta) {
      dispatch(dialogsManagerActions.openSupportDialog());
    }

    if (cta === storeCtaOpts['cta.edit-schedules'].cta) {
      navigate(
        Paths.goToHelpCenterWithCategoryAndArticle(
          editScheduleCategoryId,
          editScheduleArticleId,
        ),
      );
    }

    if (cta === storeCtaOpts['cta.info'].cta) {
      navigate(
        Paths.goToHelpCenterWithCategoryAndArticle(
          infoCategoryId,
          infoArticleId,
        ),
      );
    }

    if (cta === storeCtaOpts['cta.desuspended'].cta) {
      dialogStoreSuspended.open.dispatch({ storeId });
    }

    if (storeCtaOpts[cta]?.amplitud?.code) {
      EventActions.onClickStoreStatusCardCTA({
        storeId,
        partnerId,
        onStoreAdminPage: !!matchUrl?.pathname,
        ctaType: storeCtaOpts[cta].amplitud?.code,
      });
    }

    setTimeout(() => {
      setIsLoading(false);
      onClose();
    }, 3_000);
  }

  return {
    isLoading,
    handleCTA,
  };
}

interface Props {
  storeId: StoreId;
  partnerId: PartnerId;
  cta: TpStoreCtaOpts;
  onClose: () => void;
}
