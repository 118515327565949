import React from 'react';
import { Box, Typography } from '@material-ui/core';
import OrderMgmtStyles from '../../OrderManagement.style';
import { useOrderManagement, useOrderMgmtLang } from '../../hooks';
import { MIN_NUMBER_OF_ITEM_SUGGESTIONS } from '../../../../business/order-mgmt';
import { TrashIcon } from '../../../icons/trash-icon';

export const UndoSuggestionChangesChip = ({
  productId,
  toppingId = '',
}: Props) => {
  const componentClasses = OrderMgmtStyles();
  const {
    totalNumberOfItemSuggestions,
    onOpenUndoSuggestionDialog,
    onOpenUndoChangesDialog,
  } = useOrderManagement();
  const { undoText } = useOrderMgmtLang();

  const handleUndoItemSuggestions = () => {
    if (totalNumberOfItemSuggestions === MIN_NUMBER_OF_ITEM_SUGGESTIONS) {
      onOpenUndoChangesDialog();
    } else {
      onOpenUndoSuggestionDialog(productId, toppingId);
    }
  };

  return (
    <Box
      onClick={handleUndoItemSuggestions}
      className={componentClasses.undoChangesContainer}
      display='flex'
      alignItems='center'
      justifyContent='flex-start'
      padding='0.5rem'
      maxWidth='178px'
      gridGap='0.5rem'
      borderRadius='0.5rem'
      alignSelf='flex-end'
    >
      <TrashIcon type='danger' />
      <Typography className={componentClasses.undoChanges}>
        {undoText}
      </Typography>
    </Box>
  );
};

interface Props {
  productId: string;
  toppingId?: string;
}
