// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import Colors from '../../../constants/Colors';
import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => ({
  dialogPaper: {
    borderRadius: 8,
  },
  buttonRoot: {
    marginLeft: '28px',
  },
  button: {
    fontFamily: 'Poppins Bold',
    fontSize: '13px !important',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    padding: '6px 15px',
    textTransform: 'none',
    minWidth: '110px',
    width: 'auto',
  },
  container: {
    // Avoids dialog paper desconfiguration after printing
    height: '100% !important',
    borderRadius: 8,
  },
  drawer: {},
  drawerPaper: {},
  titleContainer: {
    borderBottom: `1px solid ${neutral.D10}`,
    padding: '30px 24px 20px 24px',
  },
  title: {
    color: neutral.D20,
    fontFamily: 'Poppins Bold',
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    padding: '0px',
  },
  subtitle: {
    marginTop: '10px',
    fontFamily: 'Poppins',
    fontSize: 13,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  contentContainer: {
    borderBottom: `1px solid ${neutral.D10}`,
    padding: '24px 24px',
    [theme.breakpoints.up('md')]: {
      padding: '24px 24px',
    },
  },
  contentTextContainer: {
    color: neutral.D20,
    fontFamily: 'inherit',
    fontSize: 17,
    fontWeight: 'normal',
    marginBottom: '0px !important',
    '& span b': {
      color: Colors.SECONDARY_COLOR,
    },
  },
  buttonsConatiner: {
    display: 'flex',
    padding: '20px 24px',
  },
  buttonLabel: {
    color: neutral.D15,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  actionSecondaryDisabled: {},
}));
