import { useFormatMessage } from '../../../intl/intl';

export function useStoreSuspensionAlertTexts(props: {
  isCTADesuspended: boolean;
  isFirstOpening: boolean;
}) {
  const { isCTADesuspended = false, isFirstOpening = false } = props;

  const btnPrimary = useFormatMessage({ id: 'store.dialog.suspended.btn.1' });

  const body2ByDsTextId = isFirstOpening
    ? 'store.dialog.suspended.body2.byDesuspended.first'
    : 'store.dialog.suspended.body2.byDesuspended.next';

  return {
    title: useFormatMessage({ id: 'store.dialog.suspended.title' }),
    body1: useFormatMessage({ id: 'store.dialog.suspended.body1' }),
    body2: useFormatMessage({
      id: isCTADesuspended
        ? body2ByDsTextId
        : 'store.dialog.suspended.body2.byInfo',
    }),
    btnPrimary: isCTADesuspended && !isFirstOpening ? btnPrimary : null,
    btnSecondary: useFormatMessage({ id: 'store.dialog.suspended.btn.2' }),
    alertError: useFormatMessage({ id: 'store.dialog.suspended.isError' }),
    successTitle: useFormatMessage({
      id: 'store.dialog.suspended.isSuccess.title',
    }),
    successMsg: useFormatMessage({
      id: 'store.dialog.suspended.isSuccess.msg',
    }),
  };
}
