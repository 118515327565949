import { makeStyles } from '@material-ui/core';
import { green, neutral } from '../../../../shared/styles/colors';
import type { TurboTagProps } from './TurboTag';
import { turboTagTextSize } from './turbo-tag.const';

export default makeStyles({
  container: {
    background: green.D7,
    borderRadius: '0.5rem',
    padding: '0.125rem 0.625rem',
  },
  text: ({ variant }: TurboTagProps) => ({
    color: neutral.white,
    fontWeight: 600,
    fontSize: turboTagTextSize[variant],
  }),
});
