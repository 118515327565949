// Material UI
import { makeStyles } from '@material-ui/core';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  body: {
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.up('md')]: {
      paddingLeft: 43,
      paddingRight: 43,
    },
  },
  headerRoot: {
    gridTemplateColumns: 'auto auto min-content',
    padding: 4,
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'min-content auto min-content',
      borderBottom: 'none',
      padding: '0px 0px 0px 43px',
    },
  },
  dialogTitle: {
    fontSize: 18,
    padding: '12px 0px 12px 12px',
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      width: 576,
      height: 532,
    },
  },
  stepper: {
    gridArea: 'stepper',
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.up('md')]: {},
  },
  title: {
    gridArea: 'title',
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.up('md')]: {
      paddingTop: 42,
    },
  },
  iconImage: {
    gridArea: 'icon',
    width: 64,
    height: 64,
    [theme.breakpoints.up('sm')]: {
      width: 104,
      height: 104,
    },
    [theme.breakpoints.up('md')]: {
      width: 64,
      height: 64,
    },
  },
  description: {
    gridArea: 'description',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  prefix: {
    gridArea: 'prefix',
    height: 48,
  },
  editButton: {
    borderRadius: '100%',
    gridArea: 'edit',
    width: 42,
    height: 42,
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.up('md')]: {},
    // '&:hover': {
    //   color: 'rgb(0 0 0 / 4%)',
    //   backgroundColor: 'rgb(0 0 0 / 4%)',
    // },
  },
  inputContainer: {
    display: 'grid',
    gridArea: 'inputContainer',
    gridTemplateAreas: `"prefix input edit"`,
    alignItems: 'flex-start',
    gridTemplateColumns: 'min-content auto min-content',
    [theme.breakpoints.up('sm')]: {
      padding: '0px 48px 0px 60px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '24px 118px 0px 0px',
    },
  },
  descriptionBox: {
    display: 'grid',
    gridArea: 'descriptionBox',
    gridTemplateAreas: `"icon description"`,
    gridTemplateColumns: '54px 1fr',
    gridColumnGap: 16,
    alignItems: 'center',
    padding: '30px 12px 0px 0px',
    [theme.breakpoints.up('sm')]: {
      rowGap: 54,
      padding: '4px 60px 0px 60px',
      gridTemplateAreas: `"icon"
                          "description"`,
      gridTemplateColumns: 'auto',
      placeContent: 'center',
      placeItems: 'center',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateAreas: `"icon description"`,
      padding: 0,
    },
  },
  root: {
    display: 'grid',
    gridRowGap: 50,
    gridTemplateAreas: `"stepper"
                        "descriptionBox"
                        "inputContainer"`,
    padding: '34px 4px 34px 16px',
    [theme.breakpoints.up('sm')]: {
      padding: '50px 57px',
      gridRowGap: 60,
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateAreas: `"stepper"
                        "title"
                        "descriptionBox"
                        "inputContainer"`,
      padding: 0,
      gridRowGap: 20,
    },
  },
  phoneInput: {
    gridArea: 'input',
    paddingLeft: 8,
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.up('md')]: {},
  },
  inputLabel: {
    borderRadius: 8,
  },
  footerButtonSubmit: {
    fontSize: 16,
    gridArea: 'footerButtonSubmit',
    justifySelf: 'end',
  },
  containerButtons: {
    fontSize: 16,
    borderTop: `1px solid ${neutral.D10}`,
    position: 'fixed',
    bottom: 0,
    width: '100%',
    gap: 14,
    padding: 16,
    display: 'grid',
    gridTemplateAreas: `"footerButtonSubmit"
                        "footerRejectButton"`,
  },
}));
