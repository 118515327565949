// Material UI
import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  root: {
    padding: 10,
  },
  title: {
    display: 'flex',
    alignItems: 'self-start',
    gap: '13px',
    fontSize: 18,
    fontWeight: 'bold',
    paddingTop: 22,
    paddingBottom: 22,
    paddingLeft: 7,
  },
  arrowIcon: {
    cursor: 'pointer',
  },
  secondaryTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    paddingTop: 22,
    paddingBottom: 22,
    paddingLeft: 11,
  },
  article: {},
}))
