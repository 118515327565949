import { clsx } from 'clsx';
import Box from '@material-ui/core/Box';

import CustomButton, {
  Colors,
  Variants,
} from '../../../common/buttons/custom-button';
import { ConfirmAccept } from './components/confirm-accept';

import { useOrderActions, useOrderActionsLang } from './hooks';
import orderActionStyles from './orderActions.styles';

export function TimeoutOrderActions() {
  const {
    showUndoChanges,
    undoChanges,
    handleConfirm,
    isOpenDialog,
  } = useOrderActions();
  const { acceptBtnText, undoChangesText } = useOrderActionsLang(
    showUndoChanges,
  );
  const styles = orderActionStyles();
  return (
    <>
      {isOpenDialog && showUndoChanges && <ConfirmAccept />}
      <Box className={clsx(styles.root, styles.orderManagementActionsRoot)}>
        {showUndoChanges && (
          <CustomButton
            color={Colors.SECONDARY}
            onClick={undoChanges}
            fullWidth={false}
            variant={Variants.TEXT}
          >
            {undoChangesText}
          </CustomButton>
        )}
        <CustomButton
          onClick={handleConfirm}
          variant={Variants.CONTAINED}
          fullWidth={false}
          disabled={false}
        >
          {acceptBtnText}
        </CustomButton>
      </Box>
    </>
  );
}
