// Material UI
import { makeStyles } from '@material-ui/core';
// Constant
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {},

  flexContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    padding: 24,
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0px 16px 16px 16px',
    border: `1px solid ${neutral.D10}`,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexWrap: 'nowrap',
      padding: 24,
      flexDirection: 'row',
      border: `1px solid ${neutral.D10}`,
      justifyContent: 'space-between',
      margin: '24px 24px 0px 24px',
    },
  },
  secondaryDescriptionFonts: {
    fontFamily: 'Poppins',
    fontSize: '13px',
    letterSpacing: '0',
    color: neutral.D15,
    paddingBottom: '30px',
  },
  primaryDescriptionFonts: {
    fontSize: '17px',
    letterSpacing: '0',
    fontWeight: 'bold',
    color: neutral.D20,
  },
  buttonContent: {
    width: 'max-content',
    fontSize: '13px !important',
    fontFamily: 'Poppins',
    letterSpacing: '0',
    [theme.breakpoints.up('md')]: {},
  },
}));
