import { combineReducers } from 'redux';

import appReducer from './app.reducer';
import RootActions from '../actions/root.actions';

import addHolidayScheduleDialogReducer from './addHolidayDialog.reducer';
import customerPhoneProxySessionDialogReducer from './customerPhoneProxySessionDialog.reducer';
import deleteSpecialDayDialogReducer from './deleteSpecialDayDialog.reducer';
import dialogsManagerReducer from './dialogsManager.reducer';
import { reducer as dynAlertSnackbar } from '../dyn-alert-snackbar';
import { reducer as dynInAppDialog } from '../dyn-in-app-dialog';
import failedProxySessionDialogReducer from './failedProxySessionDialog.reducer';
import { reducer as helpCenterPage } from '../help-center-page';
import mainLayoutReducer from './mainLayout.reducer';
import menuReducer from './menu.reducer';
import newSpecialDayDialogReducer from './newSpecialDayDialog.reducer';
import notificationsDrawerReducer from './notifications-drawer-reducer';
import orderDetailDialogReducer from './orderDetailDialog.reducer';
import orderRejectionByStockOutDialogReducer from './orderRejectionByStockOutDialog.reducer';
import orderRejectionReasonsDialogReducer from './orderRejectionReasonsDialog.reducer';
import ordersKanbanReducer from './ordersKanban.reducer';
import ordersReducer from './orders.reducer';
import orderWhimDialogReducer from './orderWhimDialog.reducer';
import partnerReducer from './partner.reducer';
import rtDialogReducer from './RTDialog.reducer';
import rtReleaseConfirmationDialogReducer from './rt-release-confirmation-dialog-reducer';
import rtReportReasonsDialogReducer from './RTReportReasonsDialog.reducer';
import salesSummaryReducer from './salesSummary.reducer';
import settingsReducer from './settings.reducer';
import storesSchedulesReducer from './storesSchedules.reducer';
import successfulProxySessionDialogReducer from './successfulProxySessionDialog.reducer';
import suspendedStoreAlertSnackbarReducer from './suspended-store-alert-snackbar-reducer';
import { reducer as cancelByStockOutSuggestionConfirmationDialog } from '../cancel-by-stock-out-suggestion-confirmation-dialog';
import { reducer as cookedOrderConfirmationIssueDialog } from '../cooked-order-confirmation-issue-dialog';
import { reducer as itemsDeactivationStockOutIssueDialog } from '../items-deactivation-stock-out-issue-dialog';
import { reducer as justCookedOrderIssueDialog } from '../just-cooked-order-issue-dialog';
import { reducer as noRTSupportSuggestions } from '../no-rt-support-suggestions';
import { reducer as orderRejectionByMotoboyLackDialog } from '../order-rejection-by-motoboy-lack-dialog';
import { reducer as orderRejectionBySaturatedStoreDialog } from '../order-rejection-by-saturated-store-dialog';
import { reducer as RTAssignedIssueSuggestionDialog } from '../rt-assigned-issue-suggestions-dialog';
import { reducer as RTLackIssueSuggestionsDialog } from '../rt-lack-issue-suggestions-dialog';
import { reducer as stockOutIssueSuggestionsDialog } from '../stock-out-issue-suggestions-dialog';
import { reducer as supportDialog } from '../support-dialog';
import { reducer as supportReasonsDialog } from '../support-reasons-dialog';
import { reducer as storeDiagnosticsDialog } from '../store-diagnostics-dialog';
import { reducer as suggestProductDialog } from '../suggest-product-dialog';
import { reducer as errorSuggestingProductDialog } from '../error-suggesting-product-dialog';
import { reducer as successfulSuggestingProductDialog } from '../successful-suggesting-product-dialog';
import { reducer as productSuggestionAvailabilityDialog } from '../product-suggestion-availability-dialog';
import { reducer as CheckErrorServiceDialog } from '../check-error-service-dialog';
import { reducer as onBoardingDialog } from '../onboarding-dialog';
import { reducer as logOutDialog } from '../log-out-dialog';
import { reducer as logOutReasonsDialog } from '../log-out-reasons-dialog';
import { reducer as startPMFlowDialog } from '../start-PM-flow-dialog';
import { reducer as errorPMFlowDialog } from '../error-PM-flow-dialog';
import { reducer as endPMFlowDialog } from '../end-PM-flow-dialog';
import { reducer as phonePMFlowDialog } from '../phone-PM-flow-dialog';
import { reducer as schedulePMFlowDialog } from '../schedule-PM-flow-dialog';
import { reducer as multiStoreDropDialog } from '../multi-store-drop-dialog';
import { reducer as singleStoreDropDialog } from '../single-store-drop-dialog';
import { reducer as extendClosingHoursDialog } from '../extend-closing-hours-dialog';
import { reducer as deactivatedProductsDialog } from '../deactivated-products-dialog';
import {
  orderMgmtItemSearchSlice,
  OM_STATE_REDUCERS,
  OM_STATE_NAME,
} from '../../business/order-mgmt/infra';
import { BrowserService } from '../../business/common/browser';
import { CARGO_SLICE_COMBINE } from '../../business/cargo/infra/store';
import { ORDER_REDUCERS, ORDER_STATE_NAME } from '../../business/order';
import { STARTUP_NOTIFICATIONS_REDUCERS } from '../../features/startup-notifications/infra';

const allReducers = combineReducers({
  addHolidayScheduleDialogReducer,
  appReducer,
  cancelByStockOutSuggestionConfirmationDialog,
  cookedOrderConfirmationIssueDialog,
  customerPhoneProxySessionDialogReducer,
  deleteSpecialDayDialogReducer,
  dialogsManagerReducer,
  dynAlertSnackbar,
  failedProxySessionDialogReducer,
  helpCenterPage,
  itemsDeactivationStockOutIssueDialog,
  justCookedOrderIssueDialog,
  logOutDialog,
  logOutReasonsDialog,
  mainLayoutReducer,
  menuReducer,
  newSpecialDayDialogReducer,
  noRTSupportSuggestions,
  notificationsDrawerReducer,
  orderDetailDialogReducer,
  orderRejectionByMotoboyLackDialog,
  orderRejectionBySaturatedStoreDialog,
  orderRejectionByStockOutDialogReducer,
  orderRejectionReasonsDialogReducer,
  ordersKanbanReducer,
  ordersReducer,
  orderWhimDialogReducer,
  partnerReducer,
  RTAssignedIssueSuggestionDialog,
  rtDialogReducer,
  RTLackIssueSuggestionsDialog,
  rtReleaseConfirmationDialogReducer,
  rtReportReasonsDialogReducer,
  salesSummaryReducer,
  settingsReducer,
  stockOutIssueSuggestionsDialog,
  storeDiagnosticsDialog,
  suggestProductDialog,
  storesSchedulesReducer,
  successfulProxySessionDialogReducer,
  supportDialog,
  supportReasonsDialog,
  suspendedStoreAlertSnackbarReducer,
  CheckErrorServiceDialog,
  onBoardingDialog,
  dynInAppDialog,
  productSuggestionAvailabilityDialog,
  successfulSuggestingProductDialog,
  errorSuggestingProductDialog,
  startPMFlowDialog,
  errorPMFlowDialog,
  endPMFlowDialog,
  phonePMFlowDialog,
  schedulePMFlowDialog,
  multiStoreDropDialog,
  singleStoreDropDialog,
  extendClosingHoursDialog,
  deactivatedProductsDialog,
  [OM_STATE_NAME]: OM_STATE_REDUCERS,
  orderManagementItemSearchDialog: orderMgmtItemSearchSlice.reducer,
  [BrowserService.stateName]: BrowserService.reducer,
  [CARGO_SLICE_COMBINE.cargo.name]: CARGO_SLICE_COMBINE.cargo.reducer,
  [CARGO_SLICE_COMBINE.addRT.name]: CARGO_SLICE_COMBINE.addRT.reducer,
  [ORDER_STATE_NAME]: ORDER_REDUCERS,
  ...STARTUP_NOTIFICATIONS_REDUCERS,
});

export const rootReducer = (state, action) => {
  /*eslint no-fallthrough: 0*/
  switch (action.type) {
    case RootActions.LOGOUT_ACTION:
      //This is done to reset the state to default of all reducers.
      state = undefined;
      break;
    default:
      return allReducers(state, action);
  }
};
