// Material UI
import { makeStyles } from '@material-ui/core';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => {
  return {
    Container: {
      display: 'grid',
      gridGap: 24,
      padding: 24,
    },
    increseReasonText: {
      fontFamily: 'Poppins',
      fontSize: '13px',
      color: neutral.D15,
      letterSpacing: '0',
      paddingTop: '6px',
      display: 'grid',
    },
    priceReasonText: {
      fontFamily: 'Poppins',
      fontSize: '13px',
      color: neutral.D15,
      letterSpacing: '0',
      paddingTop: '6px',
      display: 'grid',
    },
    textarea: {
      borderColor: neutral.D10,
      width: '100%',
    },
    dollarIcon: {
      color: neutral.D10,
    },
    headerRoot: {
      [theme.breakpoints.up('md')]: {
        padding: 12,
      },
    },
    footerRoot: {
      borderTop: `1px solid ${neutral.D10}`,
    },
    paper: {
      [theme.breakpoints.up('md')]: {
        height: 456,
        width: 557,
        overflowY: 'hidden',
      },
    },
  };
});
