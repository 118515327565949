// React
import { connect } from 'react-redux'
// Utils
import OrdersUtils from '../../utils/OrdersUtils'
import PartnerUtils from '../../utils/PartnerUtils'
// Actions
import MainLayoutActions from '../../redux/actions/mainLayout.actions'
import PartnersActions from '../../redux/actions/partners-actions'
import OrdersAction from './../../redux/actions/orders-actions'
import storesSchedulesActions from '../../redux/actions/storesSchedules.actions'
// Components
import MainLayout from './MainLayout'
import MenuActions from '../../redux/actions/menu.actions'
import salesSummaryActions from '../../redux/actions/salesSummary.actions'

const mapStateToProps = ({
  orderDetailDialogReducer: { orderId: orderIdInDialog },
  ordersReducer: { orders },
  partnerReducer,
  appReducer: { language },
}) => {
  const managedOrders = OrdersUtils.filterBeingManaged(orders)
  const showPartnerStateSwitch = !PartnerUtils.isIntegratedPartner(
    partnerReducer.profile,
  )
  // Pickup button must be shown only if the partner isn't in the blacklist for handshake and there is any pickup order.
  const showPickupButton =
    !partnerReducer.profile.isInBlacklistHandShake &&
    OrdersUtils.somePickupOrder(managedOrders)

  return {
    orderId: orderIdInDialog,
    showPickupButton,
    showPartnerStateSwitch,
    notificationsUnseen: partnerReducer.notificationsUnseen,
    language,
  }
}

const mapDispatchToProps = {
  getOrderDetailAndStoreKeeperChannel:
    OrdersAction.getOrderDetailAndStoreKeeperChannel,
  getOrders: OrdersAction.getOrders,
  getState: PartnersActions.getState,
  openMenuFilterDrawer: MenuActions.openMenuFilterDrawer,
  openOrdersHistoricalFilterDrawer:
    OrdersAction.openOrdersHistoricalFilterDrawer,
  openSalesSummaryFilterDrawer: salesSummaryActions.openFilterDrawer,
  openStoresSchedulesFilterDrawer:
    storesSchedulesActions.openStoresSchedulesFilterDrawer,
  openPickupCodeDialog: MainLayoutActions.openPickupCodeDialog,
  pollOrderDetailAndStoreKeeperChannel:
    OrdersAction.pollOrderDetailAndStoreKeeperChannel,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
