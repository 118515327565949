import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect } from 'react';
import { LinearProgress, Typography } from '@material-ui/core';

import appStore from '../../redux/stores/app.store';
import PartnerActions from '../../redux/actions/partners-actions';
import EventActions from '../../redux/actions/event-actions/event.actions';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';
import LocalStorageService from '../../services/local-storage-service/LocalStorageService';

import {
  useDeactivatedProductsDialogTexts,
  useGetDeactivatedProductsByStore,
  useHasMoreThanOneStore,
  useHasNoProductsToShow,
  useOpen,
} from './hooks';
import CustomDialog from '../common/custom-dialog/CustomDialog';
import { WelcomeBox } from './components/welcome-box/WelcomeBox';
import { DeactivatedProductsByStores } from './components/deactivated-products-by-stores';

import { deactivatedProductsDialogStyles } from './DeactivatedProductsNotificationsDialog.styles';

export function DeactivatedProductsNotificationsDialog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const styles = deactivatedProductsDialogStyles();

  const isOpen = useOpen();
  const partnerId = appStore.getPartnerId();
  const country = LocalStorageService.getCountryCode();
  const hasNoProductsToShow = useHasNoProductsToShow();
  const deactivatedProductsAllStores = useGetDeactivatedProductsByStore();
  const hasMoreThanOneStore = useHasMoreThanOneStore();
  const {
    title,
    disclaimer,
    isOkToRender,
    disclaimerNumberProducts,
    disclaimerNumberToppings,
    actionButton,
    cancelButton,
  } = useDeactivatedProductsDialogTexts();

  const handleClose = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  const handleReadyLater = () => {
    handleClose();
    hasNoProductsToShow
      ? EventActions.onClickImReady(partnerId, country)
      : EventActions.onClickDoLater(partnerId, country);
  };

  const handleSubmit = () => {
    const profile = appStore.getProfile();
    const firstStore = profile.stores && profile.stores[0]?.id;
    navigate(`/menu?store_id=${firstStore}`);
    handleClose();
    hasNoProductsToShow
      ? EventActions.onClickManageMenu(partnerId, country)
      : EventActions.onClickManageProducts(partnerId, country);
  };

  useEffect(() => {
    if (partnerId && isOpen) dispatch(PartnerActions.getDeactivatedProducts());
  }, [partnerId]);

  return (
    <CustomDialog
      classes={{
        childrenContainer: styles.body,
        headerRoot: styles.headerRoot,
        paper: styles.paper,
        footerRoot: styles.footerRoot,
        title: styles.title,
        closeButton: styles.closeButton,
      }}
      actions={[
        {
          text: cancelButton,
          onClick: hasNoProductsToShow ? handleSubmit : handleReadyLater,
          variant: 'contained',
          fullWidth: false,
          classes: { submitButton: styles.cancelButton },
        },
        {
          text: actionButton,
          onClick: hasNoProductsToShow ? handleReadyLater : handleSubmit,
          color: 'primary',
          disabled: false,
          variant: 'contained',
          fullWidth: false,
          classes: { submitButton: styles.readyButton },
        },
      ]}
      onClose={handleClose}
      open={isOpen}
      title={isOkToRender ? title : ''}
      CustomDialogHeaderChildren={<WelcomeBox />}
      showCloseButton
    >
      {!isOkToRender && <LinearProgress />}
      {isOkToRender && (
        <ShowDisclaimer
          fullText={disclaimer}
          nProducts={disclaimerNumberProducts.toString()}
          nToppings={disclaimerNumberToppings.toString()}
        />
      )}
      {isOkToRender && hasMoreThanOneStore && (
        <DeactivatedProductsByStores
          deactivatedProductsAllStores={deactivatedProductsAllStores}
        />
      )}
    </CustomDialog>
  );
}

function ShowDisclaimer(props: ShowDisclaimerProps) {
  const { fullText, nProducts, nToppings } = props;
  const isEmpty = nProducts === '0' && nToppings === '0';
  const TextForIsNotEmpty = () => {
    const styles = deactivatedProductsDialogStyles();
    const indexNP = fullText.indexOf(nProducts);
    const textP1 = fullText.split(nProducts, 1);
    const extraText = fullText.slice(indexNP + nProducts.length);
    const [textP2, textP3] = extraText.split(nToppings);
    return (
      <>
        {textP1}
        <span className={styles.spanNumberOfShutdowns}>{nProducts}</span>
        {textP2}
        <span className={styles.spanNumberOfShutdowns}>{nToppings}</span>
        {textP3}
      </>
    );
  };
  return (
    <Typography>
      {isEmpty && fullText}
      {!isEmpty && <TextForIsNotEmpty />}
    </Typography>
  );
}

interface ShowDisclaimerProps {
  fullText: string;
  nProducts: string;
  nToppings: string;
}
