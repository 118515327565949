// React
import React from 'react'
// Material UI
import { SvgIcon } from '@material-ui/core'
// Constant
import Colors from '../../../constants/Colors'

/**
 * Funcional react component for a blue lupe icon.
 * @function
 * @param {object} props - React props.
 * @return {JSX.Element} - Rendered Component
 */
const BlueLupeIcon = ({ classes }) => {
  return (
    <SvgIcon
      classes={{
        root: classes.root,
      }}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 114 59'
    >
      <g fill='none' fillRule='evenodd'>
        <path
          fill={Colors.ZIRCON}
          d='M108.93 0a4.766 4.766 0 0 1 0 9.531H81.698a4.766 4.766 0 1 1 0 9.532h14.978a4.766 4.766 0 0 1 0 9.531h-6.927c-3.319 0-6.009 2.134-6.009 4.766 0 1.755 1.362 3.343 4.085 4.766a4.766 4.766 0 0 1 0 9.53H31.317a4.766 4.766 0 0 1 0-9.53H4.766a4.766 4.766 0 1 1 0-9.532h27.232a4.766 4.766 0 0 0 0-9.531h-17.02a4.766 4.766 0 1 1 0-9.532H42.21a4.766 4.766 0 0 1 0-9.531h66.72zm0 19.063a4.766 4.766 0 1 1 0 9.531 4.766 4.766 0 0 1 0-9.531z'
        />
        <g transform='translate(27.233 .68)'>
          <circle
            cx='22.807'
            cy='22.807'
            r='22.807'
            fill={Colors.ZIRCON}
            stroke={Colors.MALIBU}
            strokeWidth='.769'
          />
          <path
            fill={Colors.WHITE}
            stroke={Colors.MALIBU}
            strokeLinecap='round'
            strokeWidth='.769'
            d='M19.153 40.504c1.197.221 2.415.336 3.654.345 9.964 0 18.042-8.078 18.042-18.042 0-9.964-8.078-18.041-18.042-18.041-2.56 0-4.994.533-7.2 1.494a18.113 18.113 0 0 0-8.87 8.338 17.965 17.965 0 0 0-1.971 8.21 17.975 17.975 0 0 0 4.55 11.979'
          />
          <path
            stroke={Colors.MALIBU}
            strokeLinecap='round'
            strokeWidth='.769'
            d='M11.436 36.815a18.023 18.023 0 0 0 4.904 2.84'
          />
          <path
            stroke={Colors.ANAKIWA}
            strokeLinecap='round'
            strokeWidth='.769'
            d='M23.148 10.212c-1.173 0-2.31.156-3.39.449-5.5 1.49-9.546 6.515-9.546 12.487'
          />
          <path
            stroke={Colors.MALIBU}
            strokeWidth='1.702'
            d='M41.53 40.849l4.085 4.085'
          />
          <path
            fill={Colors.SOLITUDE}
            stroke={Colors.MALIBU}
            strokeWidth='.769'
            d='M44.625 43.944a3.766 3.766 0 0 0 0 5.326l7.547 7.547a3.766 3.766 0 0 0 5.326-5.326l-7.548-7.547a3.766 3.766 0 0 0-5.325 0z'
          />
          <path
            stroke={Colors.White}
            strokeLinecap='round'
            strokeWidth='1.702'
            d='M48.338 45.615l7.489 7.488'
          />
        </g>
        <path
          stroke={Colors.ANAKIWA}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='.769'
          d='M90.889 18.382H79.195m16.46 0h-1.513m-7.559 5.295h-5.566M22.126 32.679H10.433m-3.965 0H3.594m21.716 5.976h-5.566'
        />
      </g>
    </SvgIcon>
  )
}

BlueLupeIcon.defaultProps = {
  classes: {},
}

export default BlueLupeIcon
