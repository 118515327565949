import { makeStyles } from '@material-ui/core';

import Colors from '../../../../constants/Colors';
import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(() => ({
  iconRoot: {
    width: '2rem',
    height: '2rem',
    position: 'relative',
    color: Colors.PRIMARY_COLOR,
  },
  message: {},
  root: {
    color: neutral.D15,
    display: 'grid',
    fontSize: 11,
    gridColumnGap: 6,
    gridAutoFlow: 'column',
    gridAutoColumns: 'min-content max-content',
    lineHeight: 'normal',
    alignItems: 'center',
  },
  tooltip: { margin: '2px 0px' },
  counter: {
    color: Colors.WEB_ORANGE,
    fontFamily: 'Poppins Bold',
    fontWeight: 'bold',
    fontSize: 12,
    width: 'max-content',
  },
  label: {
    display: 'grid',
    gridColumnGap: 6,
    gridAutoFlow: 'column',
    alignItems: 'center',
  },
}));
