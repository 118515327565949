// React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Components
import CustomReadOnlyHourInterval from './CustomReadOnlyHourInterval'

const mapStateToProps = (state, props) => {
  return {}
}

const mapDispatchToProps = {}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(CustomReadOnlyHourInterval),
)
