import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import RTMap from '../rt-map';
import RTDetailBox from '../rt-detail-box';
import CustomDialog from '../common/custom-dialog';
import { RTReportButton } from '../rt-report-button';
import { RTReleaseButton } from '../rt-release-button';

import Hooks from './hooks';
import useStyles from './styles';
import { useFormatMessage } from '../../intl/intl';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';

export function RTDialog() {
  const styles = useStyles();
  const dispatch = useDispatch();

  const open = Hooks.useOpen();
  const showMoreBtns = Hooks.useShowMoreBtns();
  const handleBack = Hooks.useHandleBack(dispatch);
  const { orderId, storeId } = Hooks.useSimplifiedOrderInfo();

  const title = useFormatMessage({ id: 'RTDialog.title' });

  const handleClose = () => dispatch(dialogsManagerActions.close());

  return (
    <CustomDialog
      classes={{
        headerRoot: styles.headerRoot,
        paper: styles.paper,
      }}
      onClickBackButton={handleBack}
      onClose={handleClose}
      open={open}
      showBackButton
      showCloseButton
      title={title}
    >
      <Box className={styles.body}>
        <RTDetailBox />
        <RTMap classes={{ root: styles.RTMapRoot }} />
        {showMoreBtns && (
          <Box className={styles.buttonsContainer}>
            <RTReleaseButton
              orderId={orderId}
              storeId={storeId}
              setBG={true}
              showCaption={true}
            />
            <RTReportButton
              orderId={orderId}
              storeId={storeId}
              setBG={true}
              showCaption={true}
            />
          </Box>
        )}
      </Box>
    </CustomDialog>
  );
}
