import {
  FormControl,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import { useMemo } from 'react';
import { ArrowDropDown } from '@material-ui/icons';

import { RenderValue } from './components/render-value';

import UrlUtils from '../../../utils/url-utils';
import StringUtils from '../../../utils/StringUtils';
import { useFormatMessage } from '../../../intl/intl';
import { textIds } from './components/render-value/consts';

import useStyles from './styles';

import type { ChangeEvent, ReactNode } from 'react';
import type { AvailableCountry } from '../../../interfaces';
import type { RenderValueProps } from './components/render-value';

export function CountrySelector(props: Props) {
  const { list, value, error, helperText, isLoading, onChange } = props;

  const placeholder = useFormatMessage(textIds.placeholder);

  const classes = useStyles();

  const countriesMenuItems = useMemo(
    () =>
      list.map(country => (
        <MenuItem key={country.code} value={country.code}>
          <img
            className={classes.flag}
            alt='flag'
            src={UrlUtils.ensureHttps(country.flag)}
          />
          {country.name}
        </MenuItem>
      )),
    [classes.flag, list],
  );

  const getCountrySelectorIcon = () => {
    return <ArrowDropDown className={classes.icon} />;
  };
  return (
    <FormControl
      classes={{ root: classes.root }}
      disabled={isLoading}
      error={error}
    >
      <Select
        value={!!value ? value.code : placeholder}
        onChange={onChange}
        renderValue={() => {
          return <RenderValue isLoading={isLoading} value={value} />;
        }}
        IconComponent={getCountrySelectorIcon}
        inputProps={{
          readOnly: isLoading || !list || !list.length,
        }}
        input={
          <OutlinedInput
            classes={{
              input: classes.countryOutlinedInput,
              root: classes.rootInput,
              notchedOutline: classes.notchedOutlineInput,
              focused: classes.focusedInput,
              disabled: classes.disabledInput,
              error: classes.error,
            }}
            labelWidth={0}
          />
        }
      >
        {countriesMenuItems}
      </Select>
      {!StringUtils.isNullOrEmpty(helperText) && (
        <FormHelperText classes={{ root: classes.helperText }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

interface Props extends RenderValueProps {
  list: AvailableCountry[];
  error?: boolean;
  helperText?: string | null;
  onChange: (
    event: ChangeEvent<{ name?: string; value: unknown }>,
    child: ReactNode,
  ) => void;
}
