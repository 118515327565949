import { useDispatch } from 'react-redux';

import { useDialogDeleteWhimFromOrderLang } from './useDialogDeleteWhimFromOrderLang';

import appStore from '../../../../../redux/stores/app.store';
import appActions from '../../../../../redux/actions/app-actions/app.actions';
import orderService from '../../../../../services/order-service/OrderService';
import eventActions from '../../../../../redux/actions/event-actions/event.actions';
import orderActions from '../../../../../redux/actions/orders-actions/orders.actions';

import type { WhimId } from '../../../../../business/shared/domain';

export function useDialogDeleteWhimFromOrder({ whimId }: Props) {
  const dpRedux = useDispatch();

  const lang = useDialogDeleteWhimFromOrderLang();

  const handleConfirmDeletion = async () => {
    let hasError = false;
    const orderId = appStore.getOrderIdInOrderDetailDialog();
    try {
      await orderService.removeWhim(whimId, orderId);
    } catch (ex) {
      hasError = true;
      dpRedux(appActions.handleCatch(ex));
    } finally {
      if (!hasError) {
        dpRedux(orderActions.getOrderDetailAndStoreKeeperChannel(orderId));
        dpRedux(
          appActions.openSnackBarSuccess(
            lang.snackbar.success.msg,
            lang.snackbar.success.title,
          ),
        );
        eventActions.onSuccessfulWhimDelete(orderId, whimId);
      }
    }
  };

  return {
    lang,
    handleConfirmDeletion,
  };
}

interface Props {
  whimId: WhimId;
}
