import { clsx } from 'clsx';

import CustomLoader from '../common/custom-loader';
import CustomReadOnlyHourIntervalList from '../../components/common/custom-read-only-hour-inteval-list';
import StoreScheduleTableHeader from '../store-schedule-table-header';
import { StoreScheduleTableRow } from '../store-schedule-table-row';

import useStyles from './RegularStoreScheduleTable.styles';

function RegularStoreScheduleTable(props) {
  const { day, isLoading = false, schedules = {}, classes = {} } = props;
  const _classes = useStyles();
  return (
    <div className={clsx(_classes.root, classes.root)}>
      <CustomLoader open={isLoading} />
      <StoreScheduleTableHeader day={day} />
      {Object.keys(schedules).map((dayCode, index) => {
        return (
          <StoreScheduleTableRow
            key={`schedules-${index}`}
            title={schedules[dayCode].title}
          >
            <CustomReadOnlyHourIntervalList
              intervals={schedules[dayCode].schedules}
            />
          </StoreScheduleTableRow>
        );
      })}
    </div>
  );
}

export default RegularStoreScheduleTable;
