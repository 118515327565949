// Redux
import { createAction } from '@reduxjs/toolkit';
// Interfaces
import { SupportSuggestion } from '../../../interfaces';

const closeAction = createAction('RTLackIssueSuggestionsDialog/close');

const clearAction = createAction('RTLackIssueSuggestionsDialog/clear');

const openAction = createAction<{
  orderId: number;
  description: string;
  supportSuggestions: SupportSuggestion[];
}>('RTLackIssueSuggestionsDialog/open');

const clear = () => (dispatch: any) => {
  dispatch(clearAction());
};

const close = () => (dispatch: any) => {
  dispatch(closeAction());
};

const open = (
  orderId: number,
  description: string,
  supportSuggestions: SupportSuggestion[],
) => (dispatch: any) => {
  dispatch(openAction({ orderId, description, supportSuggestions }));
};

const actions = {
  clear,
  clearAction,
  close,
  closeAction,
  open,
  openAction,
};

export default actions;
