// External libs
import { createActions } from 'redux-actions';

const {
  openDeleteSpecialDayDialogAction,
  closeDeleteSpecialDayDialogAction,
} = createActions({
  OPEN_DELETE_SPECIAL_DAY_DIALOG_ACTION: selectedDayId => ({ selectedDayId }),
  CLOSE_DELETE_SPECIAL_DAY_DIALOG_ACTION: () => ({}),
});

const openDeleteSpecialDayDialog = selectedDayId => dispatch => {
  dispatch(openDeleteSpecialDayDialogAction(selectedDayId));
};
const closeDeleteSpecialDayDialog = () => dispatch => {
  dispatch(closeDeleteSpecialDayDialogAction());
};

export default {
  openDeleteSpecialDayDialog,
  openDeleteSpecialDayDialogAction,
  closeDeleteSpecialDayDialog,
  closeDeleteSpecialDayDialogAction,
};
