import utils from './utils';
import http from '../HttpService';
import DateTimeUtils from '../../utils/DateTimeUtils';
import LocalStorageService from '../local-storage-service';

import urls from '../../constants/Urls';
import { APP_VERSION } from '../../constants/commons';
import { ConnectivityIssueType } from '../../constants/ConnectivityIssueType';

import type { ConnectivityIssue } from './interfaces';

const createConnectivityIssue = async (
  connectivityIssue: ConnectivityIssue,
) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();

  return http.post(
    `${microserviceUrl}${urls.CREATE_CONNECTIVITY_ISSUE}`,
    utils.parseConnectivityIssueToRequest(connectivityIssue),
  );
};

const tryCreateNetworkDisconnectionIssueFromLS = async (
  partnerId: number,
  tz: string,
) => {
  try {
    const lastNetworkErrorEvent = LocalStorageService.getNetworkErrorEvent(tz);

    if (!lastNetworkErrorEvent) {
      return;
    }

    const deviceId = LocalStorageService.getDeviceId() || '';

    const disconnectionDetailIssue = {
      origin: ConnectivityIssueType.NETWORK_DISCONNECTION,
      deviceTimestamp: DateTimeUtils.formatToTime(
        lastNetworkErrorEvent.dateTime,
      ),
    };

    const connectivityIssue = {
      partnerId,
      deviceId,
      deviceDate: DateTimeUtils.formatToDate(lastNetworkErrorEvent.dateTime),
      platform: process.env.REACT_APP_EVENT_ORIGIN || '',
      version: APP_VERSION,
      issues: [disconnectionDetailIssue],
    };

    await PartnerStoreLogsService.createConnectivityIssue(connectivityIssue);
    LocalStorageService.removeNetworkErrorEvent();
  } catch (ex) {
    console.error('Error at creating network disconnection issue from LS', ex);
  }
};

const tryCreateNetworkConnectionIssue = async (
  partnerId: number,
  tz: string,
) => {
  try {
    const version = APP_VERSION;
    const platform = process.env.REACT_APP_EVENT_ORIGIN || '';
    const deviceId = LocalStorageService.getDeviceId() || '';

    const momentNow = DateTimeUtils.getMomentNow(tz);

    const connectionIssueDate = DateTimeUtils.formatToDate(momentNow);

    const connectionDetailIssue = {
      origin: ConnectivityIssueType.NETWORK_CONNECTION,
      deviceTimestamp: DateTimeUtils.formatToTime(momentNow),
    };

    const lastNetworkErrorEvent = LocalStorageService.getNetworkErrorEvent(tz);

    if (!lastNetworkErrorEvent) {
      const connectionIssue = {
        partnerId,
        deviceId,
        deviceDate: connectionIssueDate,
        platform,
        version,
        issues: [connectionDetailIssue],
      };
      await PartnerStoreLogsService.createConnectivityIssue(connectionIssue);
      return;
    }

    const disconnectionIssueDate = DateTimeUtils.formatToDate(
      lastNetworkErrorEvent.dateTime,
    );

    const disconnectionDetailIssue = {
      origin: ConnectivityIssueType.NETWORK_DISCONNECTION,
      deviceTimestamp: DateTimeUtils.formatToTime(
        lastNetworkErrorEvent.dateTime,
      ),
    };

    if (connectionIssueDate === disconnectionIssueDate) {
      const connectivityIssue = {
        partnerId,
        deviceId,
        deviceDate: connectionIssueDate,
        platform,
        version,
        issues: [disconnectionDetailIssue, connectionDetailIssue],
      };
      await PartnerStoreLogsService.createConnectivityIssue(connectivityIssue);
      return;
    }

    const onlyDisconnectionIssue = {
      partnerId,
      deviceId,
      deviceDate: disconnectionIssueDate,
      platform,
      version,
      issues: [disconnectionDetailIssue],
    };

    const onlyConnectionIssue = {
      partnerId,
      deviceId,
      deviceDate: connectionIssueDate,
      platform,
      version,
      issues: [connectionDetailIssue],
    };

    await PartnerStoreLogsService.createConnectivityIssue(
      onlyDisconnectionIssue,
    );

    await PartnerStoreLogsService.createConnectivityIssue(onlyConnectionIssue);

    LocalStorageService.removeNetworkErrorEvent();
  } catch (ex) {
    console.error('Error at creating network connection issue', ex);
  }
};

const PartnerStoreLogsService = {
  createConnectivityIssue,
  tryCreateNetworkDisconnectionIssueFromLS,
  tryCreateNetworkConnectionIssue,
};

export default PartnerStoreLogsService;
