// Material UI
import { makeStyles } from '@material-ui/core';
import Colors from '../../constants/Colors';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(() => ({
  root: {
    border: `1px solid ${neutral.D10}`,
    padding: '1rem 0.625rem',
    zIndex: 10,
  },
  title: {
    paddingBottom: '0.5rem',
    fontSize: '10px',
    letterSpacing: '1px',
    color: neutral.D15,
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
  },
  addressContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumnGap: 8,
  },
  addressDetailContainer: {
    display: 'grid',
  },
  box: {
    backgroundColor: neutral.white,
    display: 'grid',
    gridRowGap: 16,
  },
  userInfoContainer: {
    color: neutral.D15,
    display: 'grid',
    gridRowGap: 5,
    fontSize: '11px',
    lineHeight: 'normal',
  },
  userInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  showMapButton: {
    fontSize: '12px !important',
    justifySelf: 'end',
    lineHeight: 'normal',
    padding: 0,
  },
  showMapButtonRoot: {
    alignSelf: 'end',
    justifySelf: 'end',
  },
  userName: {
    fontSize: '16px',
    color: neutral.D20,
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumnGap: 10,
    wordBreak: 'break-all',
  },
  userPhone: {
    wordBreak: 'break-all',
  },
  email: {},
  userInfoRuc: {},
  customAlertRoot: {
    marginTop: '5px',
    marginBottom: '5px',
    letterSpacing: 0,
    textAlign: 'initial !important',
    width: '100%',
  },
  VIPTypeChip: {
    backgroundColor: Colors.INFO_COLOR,
    color: Colors.INFO_FONT_COLOR,
    borderRadius: 8,
    cursor: 'pointer',
    fontWeight: 'bold',
    textAlign: 'center',
    alignSelf: 'flex-start',
    width: 56,
    padding: 6,
  },
  newValueChip: {
    backgroundColor: Colors.INFO_COLOR,
    color: Colors.INFO_FONT_COLOR,
    borderRadius: 8,
    cursor: 'pointer',
    fontWeight: 'bold',
    textAlign: 'center',
    alignSelf: 'flex-start',
    width: 'auto',
    padding: 6,
  },
  userTypeInfoBox: {},
  badgeContainer: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content max-content',
    gridGap: 24,
  },
  propioTypeChip: {
    fontFamily: 'Poppins Bold',
    backgroundColor: neutral.D10,
    color: Colors.PROPIO_FONT_COLOR,
    fontWeight: 'bold',
    fontSize: 11,
    cursor: 'pointer',
    textAlign: 'center',
    borderRadius: 8,
    width: 56,
    padding: 6,
  },
  callUserDisclaimer: {
    padding: '1rem',
    gap: '1rem',
  },
  callUserDisclaimerIcon: {
    fontSize: '1.5rem!important',
    fontWeight: 'bold',
  },
  callUserDisclaimerText: {
    fontWeight: 400,
  },
}));
