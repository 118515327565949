// Material UI
import { makeStyles } from '@material-ui/core/styles';
// Consts
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {
    fontFamily: 'Poppins',
    fontSize: 13,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  box: {
    alignItems: 'center',
    border: `solid 1px ${neutral.D10}`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 4px 5px 16px',
    whiteSpace: 'pre-wrap',
  },
  name: {},
  price: {
    color: neutral.D15,
    marginLeft: 'auto',
    marginRight: 16,
  },
  title: {},
  titleContainer: {
    color: neutral.D15,
    marginBottom: 10,
    whiteSpace: 'pre-wrap',
  },
}));
