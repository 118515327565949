// React
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// External libs
import { get, find } from 'lodash';
// Services
import PartnersService from '../../services/partners-service';
// Actions
import appStore from '../../redux/stores/app.store';
import MenuActions from './../../redux/actions/menu.actions';
// Components
import MenuBoard from './MenuBoard';

const getBreadCrumbLinks = (
  corridors,
  displayOutOfStock,
  outOfStockLabel,
  products,
  selectedCorridorId,
  selectedProductId,
  storeName,
) => {
  const currentCorridor = find(corridors, {
    corridor_id: selectedCorridorId,
  });
  const product = selectedProductId
    ? find(products, {
        product_id: selectedProductId,
      })
    : null;

  const links = [];

  links.push({
    label: storeName,
    action: () => {
      MenuActions.selectCorridor(null)(appStore.store.dispatch);
      MenuActions.selectProduct(null)(appStore.store.dispatch);
    },
  });
  if (!displayOutOfStock && !currentCorridor && !product) {
    return links;
  }
  if (displayOutOfStock)
    links.push({
      label: outOfStockLabel,
    });
  if (currentCorridor)
    links.push({
      label: currentCorridor.corridor_name,
      action: () => MenuActions.selectProduct(null)(appStore.store.dispatch),
    });
  if (product) links.push({ label: product.name });

  return links;
};

const mapStateToProps = (
  {
    menuReducer: {
      menus,
      displayOutOfStock,
      filter,
      selectedCorridorId,
      selectedProductId,
    },
    partnerReducer: { profile },
  },
  { intl: { formatMessage } },
) => {
  const outOfStockLabel = formatMessage({
    id: 'menuBoard.outOfStock.label',
  });

  const selectedStoreId = +filter.selectedStoreId;
  const corridors = get(menus, [selectedStoreId, 'corridors'], []);
  const allProducts = get(menus, [selectedStoreId, 'products'], []);

  const stores = get(profile, ['stores']);
  const storeName = PartnersService.getStoreName(stores, selectedStoreId);
  const currentCorridor =
    corridors &&
    find(corridors, {
      corridor_id: selectedCorridorId,
    });

  const links = getBreadCrumbLinks(
    corridors,
    displayOutOfStock,
    outOfStockLabel,
    allProducts,
    selectedCorridorId,
    selectedProductId,
    storeName,
  );

  return {
    currentCorridor,
    displayOutOfStock,
    links,
    selectedProductId,
    storeName,
  };
};

const mapDispatchToProps = {
  openMenuFilterDrawer: MenuActions.openMenuFilterDrawer,
  getMenuByStore: MenuActions.getMenuByStore,
  selectProduct: MenuActions.selectProduct,
  activateProduct: MenuActions.activateProduct,
  deactivateProduct: MenuActions.deactivateProduct,
  activateTopping: MenuActions.activateTopping,
  deactivateTopping: MenuActions.deactivateTopping,
  onDisplayOutOfStock: MenuActions.displayOutOfStock,
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MenuBoard),
);
