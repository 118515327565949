// Material UI
import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  customButtonSecondaryRoot: { fontSize: '12px', lineHeight: '16px' },
  substitutionButtonIcon: { marginRight: '.5rem' },
  root: {
    display: 'grid',
    gridRowGap: 15,
    gridTemplateColumns: 'auto',
  },
}));
