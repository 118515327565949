import { makeStyles } from '@material-ui/core';

import { neutral } from '../../shared/styles/colors';

export default makeStyles({
  input: {
    padding: '14px',
  },
  icon: {
    color: neutral['50'],
  },
  root: {
    backgroundColor: neutral.white,
  },
});
