import { useState, useCallback, useMemo } from 'react';
import { get, filter, find } from 'lodash';

import CustomChatMessage from './../common/custom-chat-message';
import CustomChatInputMessage from './../common/custom-chat-input-message';
import RtInfoCardSupportChat from './../rt-info-card-support-chat';
import MessageOriginType from '../../constants/MessageOriginType';
import { CustomRating } from '../common/custom-rating';

import { textIds } from './consts';
import SupportSender from '../../constants/SupportSender';
import { useFormatMessage } from '../../intl/intl';
import hooks from './hooks';

import useStyles from './styles';

const MAX_LENGTH = 250;
const MIN_LENGTH = 3;
const MIN_SCORE_SHOW_BOX = 4;

function SupportChat(props: Props) {
  const {
    messages = '',
    orderId = 0,
    partnerId = 0,
    partnerName = '',
    onEnterKeyPress = () => {},
    onConfirmCalification = () => {},
  } = props;

  const supportScore = hooks.useSelectSupportScore();
  const score = get(supportScore, 'rating', 0);
  const comment = get(supportScore, 'comment', '');
  const scoreTitle = useFormatMessage(textIds.scoreTitle);
  const feedBackPlaceholder = useFormatMessage(textIds.feedbackComment);
  const save = useFormatMessage(textIds.save);
  const componentClasses = useStyles();
  const handleChange = (e: any) => {
    setMessageToSend(e.currentTarget.value);
  };

  const [messageToSend, setMessageToSend] = useState<string>('');
  const [value, setValue] = useState<number>(score || 0);
  const [rate, setRate] = useState<number>(score || 0);
  const [feedback, setFeedback] = useState<string>(comment || '');
  const [showButton, setShowButton] = useState<boolean>(true);
  const showContainerFeedback = !!rate ? rate <= 3 : false;

  const supportSendedMessage = useMemo(
    () =>
      filter(messages, p => {
        return get(p, 'sender_type', '') === SupportSender.SUPPORT_SENDER_TYPE;
      }),
    [messages],
  );
  const firstSupportMessage = useMemo(
    () =>
      find(supportSendedMessage, message => {
        return (
          get(message, 'sender_type', '') === SupportSender.SUPPORT_SENDER_TYPE
        );
      }),
    [supportSendedMessage],
  );

  const showScore = get(firstSupportMessage, 'sender_type', false);

  const sendFeedBackWithoutComments = useCallback(
    async score => {
      if (score >= MIN_SCORE_SHOW_BOX) {
        await onConfirmCalification(score, null);
      }
    },
    [onConfirmCalification],
  );

  const handleFeedBackRate = useCallback(
    (e, newValue) => {
      setValue(newValue);
      setRate(newValue);
      sendFeedBackWithoutComments(newValue);
    },
    [sendFeedBackWithoutComments],
  );

  const handleFeedBackText = useCallback(e => {
    const eventValue = get(e, 'currentTarget.value', '');
    setFeedback(eventValue);
    setShowButton(true);
  }, []);

  const onConfirmSendCalification = useCallback(async () => {
    await onConfirmCalification(rate, feedback);
  }, [onConfirmCalification, feedback, rate]);

  return (
    <div className={componentClasses.root}>
      <RtInfoCardSupportChat />
      {showScore && (
        <CustomRating
          titleLabel={scoreTitle}
          maxLengthChar={MAX_LENGTH}
          minLengthChar={MIN_LENGTH}
          placeHolderBox={feedBackPlaceholder}
          buttonTextLabel={save}
          onHandleConfirmRate={onConfirmSendCalification}
          handleFeedBackRate={handleFeedBackRate}
          handleFeedBackText={handleFeedBackText}
          showContainerFeedback={showContainerFeedback}
          feedback={feedback}
          value={value}
          showButton={showButton}
        />
      )}
      <div className={componentClasses.messagesContainer}>
        {messages.map((message: any) => {
          return (
            <CustomChatMessage
              key={get(message, 'id', 0)}
              data={get(message, 'data', '')}
              originType={
                get(message, 'sender_type', '') === 'tablet'
                  ? MessageOriginType.RECEIVER
                  : MessageOriginType.SENDER
              }
              timeDiff={get(message, 'timeDiffLabel', '')}
            />
          );
        })}
      </div>
      <CustomChatInputMessage
        onChange={handleChange}
        onKeyPress={(ev: any) => {
          if (
            ev.key !== 'Enter' ||
            messageToSend === undefined ||
            messageToSend === null ||
            messageToSend.trim() === ''
          ) {
            return;
          }
          ev.preventDefault();
          onEnterKeyPress(messageToSend, orderId, partnerId, partnerName);
          setMessageToSend('');
        }}
        value={messageToSend}
      />
    </div>
  );
}

export default SupportChat;

interface Props {
  messages?: any;
  onEnterKeyPress?: any;
  onConfirmCalification?: any;
  orderId?: number;
  partnerId?: number;
  partnerName?: string;
  score?: number;
  comment?: string;
}
