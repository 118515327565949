const confirmButtonText = {
  id: 'globals.confirm',
};

const label = {
  id: 'itemsDeactivationStockOutIssueDialog.label',
};

const title = {
  id: 'itemsDeactivationStockOutIssueDialog.title',
};

const textIds = {
  confirmButtonText,
  label,
  title,
};

export default textIds;
