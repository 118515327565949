import { makeStyles } from '@material-ui/styles';

import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(() => ({
  bold: {
    fontWeight: 'bold',
  },
  cardRoot: {
    padding: '0.75rem 1rem 1rem 0.75rem',
  },
  headerRoot: {
    padding: 0,
  },
  headerTitle: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  headerAction: {
    alignSelf: 'flex-end',
  },
  contentRoot: {
    padding: '0.5rem 0 1rem',
    color: neutral.D20,
  },
  checkinCodeBox: {
    padding: '0.5rem',
    width: 'fit-content',
    borderRadius: '0.5rem',
    backgroundColor: neutral.D6,
  },
  checkinCodeText: {
    fontSize: '0.875rem',
  },
  infoBox: {
    paddingLeft: '0.5rem',
    display: 'grid',
    rowGap: '0.25rem',
  },
  infoTypoBody: {
    fontSize: '0.875rem',
  },
  actionsRoot: {
    padding: '0 0 0.5rem',
    columnGap: '1rem',
    justifyContent: 'flex-end',
  },
}));
