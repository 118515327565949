import { clsx } from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { Link as RouterLink } from 'react-router-dom';
import { Dialog, Link, Typography } from '@material-ui/core';

import { useStartupPrinterDialog } from './hooks';

import imgSrcRtAndBagCode from './assets/img-001.svg';
import makeClasses from './StartupPrinterDialog.style';

export function StartupPrinterDialog() {
  const {
    open,
    lang,
    anchorHref,
    handleCloseDialog,
    handlePrimaryBtn,
    handleSecondaryBtn,
  } = useStartupPrinterDialog();

  const _classes = makeClasses();

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth='sm'
      onClose={handleCloseDialog}
      classes={{ paper: _classes.dialogPaper }}
    >
      <header>
        <nav className={_classes.nav}>
          <CloseIcon aria-label='close' onClick={handleCloseDialog} />
        </nav>
        <Typography variant='h1' className={_classes.title}>
          {lang.title.part1}
          <br />
          {lang.title.part2}
        </Typography>
      </header>

      <main className={_classes.content}>
        <img src={imgSrcRtAndBagCode} alt='img-rt-code' />
        <section className={_classes.contentSection}>
          <Typography variant='h2'>{lang.content.title}</Typography>
          <Typography dangerouslySetInnerHTML={{ __html: lang.content.p1 }} />
          <Typography dangerouslySetInnerHTML={{ __html: lang.content.p2 }} />
          <Typography dangerouslySetInnerHTML={{ __html: lang.content.p3 }} />
        </section>
      </main>

      <aside>
        <Typography className={_classes.note}>{lang.aside.note}</Typography>
      </aside>

      <footer className={_classes.footer}>
        <Link
          component={RouterLink}
          to={anchorHref.knowMore}
          onClick={handleSecondaryBtn}
          className={_classes.link}
        >
          {lang.link.knowMore}
        </Link>
        <Link
          component={RouterLink}
          to={anchorHref.configPrinter}
          onClick={handlePrimaryBtn}
          className={clsx(_classes.link, _classes.lastLink)}
        >
          {lang.link.configPrinter}
        </Link>
      </footer>
    </Dialog>
  );
}
