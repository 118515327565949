import { makeStyles } from '@material-ui/core';

import { neutral } from '../../shared/styles/colors';

export default makeStyles({
  root: {
    width: '40px',
    height: '40px',
    borderRadius: 50,
  },
  colorPrimary: {
    width: '40px',
    height: '40px',
    borderRadius: 50,
    color: neutral['20'],
  },
});
