import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';

import { selectors as onBoardingDialogDialogSelectors } from '../../redux/onboarding-dialog';

const useOpen = () => {
  return useSelector(onBoardingDialogDialogSelectors.selectOpen);
};

const useStepsList = () => {
  return useSelector(onBoardingDialogDialogSelectors.selectSteps);
};

const useStep = () => {
  return useSelector(onBoardingDialogDialogSelectors.selectCurrentStep);
};

const useCurrentStepIndex = () => {
  return useSelector(onBoardingDialogDialogSelectors.selectCurrentStepIndex);
};

const useSource = () => {
  return useSelector(onBoardingDialogDialogSelectors.selectSource);
};

const useMediaQueryCode = (theme: any) => {
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isXlUp = useMediaQuery(theme.breakpoints.up('xl'));
  if (isXlUp) {
    return 'xl';
  }
  if (isLgUp) {
    return 'lg';
  }
  if (isMdUp) {
    return 'md';
  }
  if (isSmUp) {
    return 'sm';
  }
  return 'xs';
};

const useImageUrl = (theme: any, step: any) => {
  const mediaQueryCode = useMediaQueryCode(theme);
  return step[`image_${mediaQueryCode}`];
};

const hooks = {
  useOpen,
  useStepsList,
  useStep,
  useCurrentStepIndex,
  useImageUrl,
  useSource,
};

export default hooks;
