// Redux
import { createActions } from 'redux-actions';
// Utils
import { formatMessage } from '../../intl/intl';
// Services
import LocalStorageService from '../../services/local-storage-service';
import SettingsService from '../../services/SettingsService';
// Actions
import appActions from './app-actions';
import EventActions from './event-actions';
import appStore from '../stores/app.store';

const {
  getSettingsAction,
  loadSettingsFromLocalStorageAction,
  setAutoAcceptAction,
  setAutoPrintAction,
} = createActions({
  GET_SETTINGS_ACTION: settings => ({
    settings,
  }),
  LOAD_SETTINGS_FROM_LOCAL_STORAGE_ACTION: isAutoPrintActive => ({
    isAutoPrintActive,
  }),
  SET_AUTO_ACCEPT_ACTION: isAutoAcceptActive => ({ isAutoAcceptActive }),
  SET_AUTO_PRINT_ACTION: isAutoPrintActive => ({ isAutoPrintActive }),
});

const getSettings = () => async dispatch => {
  try {
    const { data: settings } = await SettingsService.getSettings();
    dispatch(getSettingsAction(settings));
  } catch (ex) {
    await appActions.handleCatch(ex)(dispatch);
  }
};

const loadSettingsFromLocalStorage = () => async dispatch => {
  try {
    const isAutoPrintActive = LocalStorageService.getAutoPrintEnabled();
    dispatch(loadSettingsFromLocalStorageAction(isAutoPrintActive));
  } catch (ex) {
    await appActions.handleCatch(ex)(dispatch);
  }
};

const setAutoAccept = isActive => async dispatch => {
  const activeMsgId = 'settings.autoAccept.message.success';
  const deactiveMsgId = 'settings.autoAccept.message.deactivate';
  const successMsgId = isActive ? activeMsgId : deactiveMsgId;
  const successMessage = formatMessage({ id: successMsgId });
  const partnerId = appStore.getPartnerId();
  try {
    await SettingsService.setAutoAccept(isActive);
    dispatch(setAutoAcceptAction(isActive));
    appActions.openSnackBarSuccess(successMessage)(dispatch);
    if (isActive) EventActions.onChangeAutoAcceptOrders({ partnerId });
  } catch (ex) {
    await appActions.handleCatch(ex)(dispatch);
  }
};

const setAutoPrint = isActive => async dispatch => {
  try {
    LocalStorageService.setAutoPrintEnabled(isActive);
    dispatch(setAutoPrintAction(isActive));

    return {
      hasFailed: false,
    };
  } catch (ex) {
    await appActions.handleCatch(ex)(dispatch);
    return {
      hasFailed: true,
      ex,
    };
  }
};

export default {
  getSettings,
  getSettingsAction,
  loadSettingsFromLocalStorage,
  loadSettingsFromLocalStorageAction,
  setAutoAccept,
  setAutoAcceptAction,
  setAutoPrint,
  setAutoPrintAction,
};
