import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { textIds } from './consts';
import { Serverities } from '../../constants/Severities';
import { useFormatMessage } from '../../intl/intl';
import hooks from './hooks';
import orderActions from '../../redux/actions/orders-actions';
import CustomInAppDialog from '../common/custom-in-app-dialog';

export function CheckErrorServiceDialog() {
  const open = hooks.useOpen();
  const dispatch = useDispatch();
  const title = useFormatMessage(textIds.title);
  const description = useFormatMessage(textIds.description);
  const accept = useFormatMessage(textIds.accept);

  const handleClose = useCallback(() => {
    dispatch(orderActions.closeCheckErrorServiceDialog());
  }, [dispatch]);

  return (
    <CustomInAppDialog
      buttonText={accept}
      description={description}
      onClick={handleClose}
      onClose={handleClose}
      open={open}
      title={title}
      type={Serverities.ERROR}
      showCloseButton
      showMainButton
    />
  );
}
