import { useSelector } from 'react-redux';

import { selectors as partnerSelectors } from '../../redux/partner';

const useProfile = () => useSelector(partnerSelectors.selectProfile);

const useStoreStatusDetail = () =>
  useSelector(partnerSelectors.selectStoreStatusDetail);

const useIsStoreStatusDetailLoading = () =>
  useSelector(partnerSelectors.selectIsStoreStatusDetailLoading);

const useIsSwitchPartnerStoresStatusLoading = () =>
  useSelector(partnerSelectors.selectIsSwitchStoreStatusLoading);

const hooks = {
  useProfile,
  useStoreStatusDetail,
  useIsStoreStatusDetailLoading,
  useIsSwitchPartnerStoresStatusLoading,
};

export default hooks;
