// External libs
import { handleActions } from 'redux-actions';
import addHolidayScheduleDialogActions from '../actions/addHolidayScheduleDialog.actions';

const initialState = {
  open: false,
  holiday: {},
};
export default handleActions(
  {
    [addHolidayScheduleDialogActions.openAddHolidayScheduleDialogAction]: (
      state,
      { payload: { holiday } },
    ) => toggleDialog(state, true, holiday),
    [addHolidayScheduleDialogActions.closeAddHolidayScheduleDialogAction]: state =>
      toggleDialog(state, false, {}),
  },
  initialState,
);
const toggleDialog = (state, open, holiday) => {
  return {
    ...state,
    holiday,
    open,
  };
};
