export const CARGO_PATH = {
  HAS_CARGO:
    'api/rs-aliados-gateway/orders/partner/status/cargo/get-cargo-stores',
  VALIDATE_COVERAGE:
    'api/rs-aliados-gateway/orders/partner/status/cargo/validate',
  CREATE_ORDER: 'api/rs-aliados-gateway/orders/partner/status/cargo/order',
  ORDER_LIST: 'api/rs-aliados-gateway/orders/partner/status/cargo/order-list',
  ORDER_CANCEL_COST:
    'api/rs-aliados-gateway/orders/partner/status/cargo/cancel/cost/:orderId',
  CANCEL_ORDER: 'api/rs-aliados-gateway/orders/partner/status/cargo/:orderId',

  VALIDATE_ADD_RT:
    'api/rs-aliados-gateway/orders/partner/status/cargo/rappi-order/:orderId',
  CREATE_ORDER_ADD_RT:
    'api/rs-aliados-gateway/orders/partner/status/cargo/order',
} as const;
