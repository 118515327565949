const alertText = {
  id:
    'orderDetailDialogCookingTimeSelectorSection.marketplaceSection.alertText',
};

const description = {
  id:
    'orderDetailDialogCookingTimeSelectorSection.marketplaceSection.description',
};

const title = {
  id: 'orderDetailDialogCookingTimeSelectorSection.marketplaceSection.title',
};

const fastOperationText = {
  id: 'orderDetailDialogCookingTimeSelectorSection.marketplaceSection.fastOperation',
}

const textIds = {
  alertText,
  description,
  title,
  fastOperationText
};

export default textIds;
