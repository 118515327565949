import React from 'react'
// Constant
import Colors from '../../../constants/Colors'

const SucessIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='54'
      height='54'
      viewBox='0 0 54 54'
    >
      <g fill='none' fillRule='evenodd'>
        <path fill={Colors.WHITE} d='M0 0h50v48H0z' />
        <path
          fill={Colors.ICEBERG}
          d='M40.232 36.444c-5 2-4.764-.859-10-4-5-3-15 9-22 3-5.579-4.782-5.579-24 4-24 4.286 0 6 4 14 0 7.216-3.608 10.836-4.718 15.892 4.482 5.341 9.72 1.346 19.222-1.892 20.518z'
        />
        <g stroke={Colors.PRIMARY_COLOR}>
          <g fill={Colors.WHITE_ICE}>
            <path d='M12.5 18.265h26v16h-26zM12.5 18.265h26v-3h-26z' />
          </g>
          <g fill={Colors.WHITE_ICE}>
            <path d='M19.5 21.265v2h16v-2h-16zM19.5 25.265v2h16v-2h-16zM19.5 29.265v2h16v-2h-16zM15.5 21.265h2v2h-2zM15.5 25.265h2v2h-2zM15.5 29.265h2v2h-2z' />
          </g>
          <g transform='translate(31.91 27.856)'>
            <circle cx='6.154' cy='6.154' r='6.154' fill={Colors.WHITE_ICE} />
            <g fill={Colors.FUN_BLUE} transform='translate(2.23 2.66)'>
              <rect
                width='1'
                height='6.385'
                x='4.798'
                y='.5'
                rx='.5'
                transform='rotate(36 4.923 3.692)'
              />
              <rect
                width='1'
                height='2.692'
                x='1.954'
                y='4.069'
                rx='.5'
                transform='rotate(-54 2.079 5.415)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SucessIcon
