import React, { Component } from 'react';
import MenuIcon from './MenuIcon';

class ConfigIcon extends Component {
  d2 =
    'M5.636 18.364l1.989-1.989-1.99 1.99zM18.188 12a6.188 6.188 0 1 1-12.376 0 6.188 6.188 0 0 1 12.375 0zM12 5.812V3v2.813zM12 21v-2.813V21zm6.188-9H21h-2.813zM3 12h2.813H3zm13.375 4.375l1.989 1.989-1.989-1.989zM5.635 5.636l1.99 1.989-1.99-1.989zm10.74 1.989l1.989-1.989-1.989 1.989z';

  render() {
    const { selected } = this.props;

    return <MenuIcon d2={this.d2} selected={selected} />;
  }
}

export default ConfigIcon;
