import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => ({
  stepLabelRoot: {
    fontSize: '1em',
    lineHeight: 'normal',
  },
  stepIconRoot: ({ activeColor }) => ({
    color: neutral['20'],
    fontSize: 20,
    [theme.breakpoints.up('sm')]: {
      fontSize: 30,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 22,
    },
    '&$stepIconActive': {
      color: activeColor,
    },
    '&$stepIconCompleted': {
      color: activeColor,
    },
  }),
  label: {
    color: `${neutral['50']} !important`,
    fontSize: 10,
    lineHeight: 1.5,
    marginTop: '5px !important',
    wordBreak: 'break-word',
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 11,
    },
  },
  stepConnectorRoot: {
    padding: 0,
  },
  alternativeLabel: {
    padding: 0,
  },
  root: {
    padding: 0,
  },
}));
