export interface GenerateURLParams {
  url: string;
  base?: string;
  pathParams?: Record<string, string | number>;
  queryParams?: Record<string, string | number>;
}

export function generateURL(props: GenerateURLParams): string {
  const { url, base, pathParams, queryParams } = props;
  const _url = new URL(url, base);
  if (pathParams) {
    Object.entries(pathParams).forEach(([key, value]) => {
      _url.pathname = _url.pathname.replace(`:${key}`, String(value));
    });
  }
  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      _url.searchParams.set(key, String(value));
    });
  }
  return _url.href;
}
