import { sortBy, get } from 'lodash';
import OrderService from './order-service';

let formatMessage = null;
const setFormatMessage = fm => {
  formatMessage = fm;
};

const processProducts = products => {
  products.forEach(product => {
    const result = get(product, 'toppings', []) || [];
    result
      .map(topping => {
        return topping.topping_category_index;
      })
      .sort((a, b) => {
        return b - a;
      });
    product.toppings = result;
    //make groups using the Toppings_category
    product.groupedToppings = groupToppingsByCategory(product.toppings);
    product.quantityText = `${product.units}x`;
    //validates that if have toppings
    const thereAreToppings = product.toppings.some(
      topping => topping.units > 1,
    );
    // show message of remminder for Toppings when is more than 1 of itselfs
    product.toppingMessage = thereAreToppings
      ? OrderService.getProductReminder(product, formatMessage)
      : false;
  });
};

const getProductsByCorridors = products => {
  const productsByCorridor = {};

  products.forEach(product => {
    if (!productsByCorridor[product.corridor_id]) {
      const productByCorridor = {};
      productByCorridor.corridor_id = product.corridor_id;
      productByCorridor.corridor_name = product.corridor_name;
      productByCorridor.products = [product];

      productsByCorridor[product.corridor_id] = productByCorridor;
    } else {
      productsByCorridor[product.corridor_id].products = [
        ...productsByCorridor[product.corridor_id].products,
        product,
      ];
    }
  });
  return productsByCorridor;
};

const groupToppingsByCategory = toppings => {
  const toppingCategories = [];
  for (const topping of toppings) {
    const existingToppingCategory = toppingCategories.find(
      tc => tc.name === topping.topping_category,
    );
    if (existingToppingCategory) {
      existingToppingCategory.toppings.push(topping);
      continue;
    }
    const toppingCategory = {
      index: topping.topping_category_index,
      name: topping.topping_category,
      toppings: [topping],
    };

    toppingCategories.push(toppingCategory);
  }
  return sortToppingCategoriesAndToppingsByIndex(toppingCategories);
};

const sortToppingCategoriesAndToppingsByIndex = toppingCategories => {
  // Sort topping categories by index and then by name.
  toppingCategories = sortBy(toppingCategories, ['index', 'name']);

  for (const toppingCategory of toppingCategories) {
    // Sort toppings of each topping category by topping index and then by description
    toppingCategory.toppings = sortBy(toppingCategory.toppings, [
      'topping_index',
      'description',
    ]);
  }
  return toppingCategories;
};

export default {
  getProductsByCorridors,
  processProducts,
  setFormatMessage,
};
