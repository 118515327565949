import { useMediaQuery } from '@material-ui/core';

const useMediaQueryCode = (theme: any) => {
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isXlUp = useMediaQuery(theme.breakpoints.up('xl'));
  if (isXlUp) {
    return 'xl';
  }
  if (isLgUp) {
    return 'lg';
  }
  if (isMdUp) {
    return 'md';
  }
  if (isSmUp) {
    return 'sm';
  }
  return 'xs';
};

const hooks = { useMediaQueryCode };

export default hooks;
