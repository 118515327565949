// External libs
import { createAction } from '@reduxjs/toolkit';

const closeAction = createAction('successfulSuggestingProductDialog/close');

const clearAction = createAction('successfulSuggestingProductDialog/clear');

const maximizeAction = createAction(
  'successfulSuggestingProductDialog/maximize',
);

const openAction = createAction<{
  orderId: number;
  selectedProduct: string;
  suggestion: string;
}>('successfulSuggestingProductDialog/open');

const clear = () => (dispatch: any) => {
  dispatch(clearAction());
};

const close = () => (dispatch: any) => {
  dispatch(closeAction());
};

const maximize = () => (dispatch: any) => dispatch(maximizeAction());

const open = (orderId: number, selectedProduct: string, suggestion: string) => (
  dispatch: any,
) => {
  dispatch(
    openAction({
      orderId,
      selectedProduct,
      suggestion,
    }),
  );
};

const actions = {
  clear,
  clearAction,
  close,
  closeAction,
  maximize,
  maximizeAction,
  open,
  openAction,
};

export default actions;
