import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../../shared/styles/colors';

export default makeStyles({
  container: {
    padding: '1rem',
    borderRadius: '0.5rem',
    border: `1px solid ${neutral['20']}`,
    backgroundColor: neutral.white,
    '& > *': {
      marginBottom: '0.5rem',
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
  },
  header: {
    display: 'flex',
    gap: '2rem',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: neutral['100'],
  },
  description: {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    color: neutral['70'],
  },
  optionTitle: {
    marginBottom: '0.5rem',
    fontWeight: 600,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    color: neutral['80'],
  },
});
