// Material UI
import { makeStyles } from '@material-ui/core';
// Const
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => {
  return {
    seccionTitle: {
      color: neutral['100'],
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: '2rem',
    },
    advancedFiltersText: {
      color: neutral['60'],
      display: 'none',
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.71,
      margin: 0,
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        display: 'initial',
      },
    },
    root: {
      width: '100%',
      minHeight: '3rem',
      marginTop: '0.5rem',
      padding: '0 1rem',
      display: 'flex',
      gap: '0.5rem',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: neutral['10'],
      borderTop: `1px solid ${neutral.D10}`,
      [theme.breakpoints.up('md')]: {
        padding: '0.5rem 1.4rem 0 1.45rem',
        margin: 0,
        minHeight: '2rem',
      },
    },
    chip: {
      alignSelf: 'flex-end',
      display: 'grid',
      gridAutoFlow: 'row',
      gridAutoRows: 'max-content',
      padding: '8px 16px 0px 16px',
      [theme.breakpoints.up('md')]: {
        justifySelf: 'center',
        padding: '0px 16px 0px 16px',
      },
    },
    orderSearchTextFieldRoot: {
      borderRadius: '0.5rem',
      border: `1px solid ${neutral[20]}`,
      width: '100%',
      height: '100%',
      '&> div': {
        height: '100%',
      },
      [theme.breakpoints.up('md')]: {
        width: 'initial',
      },
    },
    input: {
      width: '11.75rem',
      height: '2rem',
      padding: 0,
      fontSize: '0.875rem',
      lineHeight: '1.5rem',
    },
  };
});
