import { COVERAGE_STATUS, CARGO_ORDER_STATUS } from '../../domain';

import type {
  CoverageStatus,
  CargoOrderCreated,
  VerifyCoverage,
  CargoOrder,
  CargoStore,
  ValidatedAddRT,
} from '../../domain';
import type {
  CoverageValidateResponse,
  RTCargoResponse,
  CargoOrdersResponse,
  CargoStoreListResponse,
  ValidateAddRTResponse,
} from '../core/responses';

const toValidateCoverage = (
  response: CoverageValidateResponse,
): VerifyCoverage => {
  const getStatus: { [key: number]: CoverageStatus } = {
    200: 'hasCoverage',
    401: 'notAvailable',
    402: 'noCoverage',
  };
  return {
    price: response.estimated_price,
    status: getStatus[response.cargo_code] ?? COVERAGE_STATUS.exception,
  };
};
const toCreateOrder = (response: RTCargoResponse): CargoOrderCreated => {
  return {
    isSuccessful: response.cargo_code === 200,
    orderId: response.rappi_order_id,
  };
};

const toCancelCost = (response: object): number => {
  return +JSON.stringify(response)
    .split('')
    .filter(item => item === '.' || Number.isInteger(+item))
    .join('');
};

const toCargoStores = (response: CargoStoreListResponse): Array<CargoStore> =>
  response.map(res => ({
    address: res.address,
    id: res.store_id,
    mapPoint: { lat: res.lat, lng: res.lng },
    name: res.name,
    isEnabled: res.is_enabled,
  })) || [];

const toOrderList = (response: CargoOrdersResponse): Array<CargoOrder> =>
  response.map(res => ({
    id: res?.rappi_order_id,
    storeId: res?.store_id,
    storeName: res?.store_name,
    clientName: res?.client_name,
    phoneNumber: res?.client_phone,
    status:
      CARGO_ORDER_STATUS[res?.status] ?? CARGO_ORDER_STATUS['in_progress'],
    followUpLink: res?.tracking_link,
  }));

function toValidateAddRT(resp: ValidateAddRTResponse): ValidatedAddRT {
  return {
    storeId: resp.store_id,
    deliveryCost: resp.estimated_price,
    orderCost: resp.total_value,
    client: {
      name: resp.client_name,
      phone: resp.phone,
      address: resp.client_address,
      addressComplement: resp.client_address_complement,
      addressCity: resp.client_address_city,
      mapPoint: {
        lat: resp.client_lat,
        lng: resp.client_lng,
      },
    },
  };
}

export const responseAdapter = {
  toValidateCoverage,
  toCreateOrder,
  toCancelCost,
  toCargoStores,
  toOrderList,
  toValidateAddRT,
} as const;
