import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { textIds } from './consts';

import { useFormatMessage } from '../../intl/intl';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';
import { actions as itemsDeactivationStockOutIssueDialogActions } from '../../redux/items-deactivation-stock-out-issue-dialog';
import orderActions from '../../redux/actions/orders-actions';
import hooks from './hooks';

import CustomDialog from '../common/custom-dialog';
import { OrderProductsToppingsChecker } from '../order-products-toppings-checker';

import useStyles from './styles';

export function ItemsDeactivationStockOutIssueDialog() {
  const componentClasses = useStyles();

  const label = useFormatMessage(textIds.label);
  const confirmButtonText = useFormatMessage(textIds.confirmButtonText);

  const open = hooks.useOpen();
  const products = hooks.useProducts();
  const selectedProducts = hooks.useSelectedProducts();
  const selectedToppings = hooks.useSelectedToppings();
  const title = hooks.useTitle();

  const isConfirmButtonDisabled = useMemo(() => {
    return selectedProducts.length === 0 && selectedToppings.length === 0;
  }, [selectedProducts, selectedToppings]);

  const dispatch = useDispatch();

  const handleBack = useCallback(() => {
    dispatch(dialogsManagerActions.back());
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  const handleConfirm = useCallback(() => {
    dispatch(
      orderActions.deactivateProductsToppingsFromStockOutIssue(
        selectedProducts,
        selectedToppings,
      ),
    );
  }, [dispatch, selectedProducts, selectedToppings]);

  const handleProductSelection = useCallback(
    newSelectedProducts => {
      dispatch(
        itemsDeactivationStockOutIssueDialogActions.setSelectedProducts(
          newSelectedProducts,
        ),
      );
    },
    [dispatch],
  );

  const handleToppingSelection = useCallback(
    newSelectedToppings => {
      dispatch(
        itemsDeactivationStockOutIssueDialogActions.setSelectedToppings(
          newSelectedToppings,
        ),
      );
    },
    [dispatch],
  );

  return (
    <CustomDialog
      classes={{
        headerRoot: componentClasses.headerRoot,
        childrenContainer: componentClasses.body,
        paper: componentClasses.paper,
      }}
      actions={[
        {
          text: confirmButtonText,
          onClick: handleConfirm,
          color: 'primary',
          disabled: isConfirmButtonDisabled,
          variant: 'contained',
          fullWidth: false,
        },
      ]}
      onClickBackButton={handleBack}
      onClose={handleClose}
      open={open}
      showBackButton
      showCloseButton
      title={title}
    >
      <OrderProductsToppingsChecker
        classes={{
          root: componentClasses.checkerRoot,
        }}
        label={label}
        selectedProducts={selectedProducts}
        selectedToppings={selectedToppings}
        onProductSelection={handleProductSelection}
        onToppingSelection={handleToppingSelection}
        products={products}
      />
    </CustomDialog>
  );
}
