import { makeStyles } from '@material-ui/core/styles';
// Constants
import Colors from '../../constants/Colors';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  placeholderBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto 0',
  },
  placeholderImg: {
    display: 'block',
    margin: ' 0 auto 20px',
  },
  placeholderBoxText: {
    color: neutral.D15,
    fontSize: 13,
    fontWeight: 'normal',
    marginBottom: 0,
    marginTop: 0,
    textAlign: 'center',
  },
  actionContainer: {
    textAlign: 'center',
  },
  placeholderBoxAction: {
    color: Colors.PRIMARY_COLOR,
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: 13,
    fontWeight: 'bold',
    padding: '6px 15px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}));
