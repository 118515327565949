import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  thumbAction: {
    justifyContent: 'space-between',
    minWidth: '3.75rem',
    display: 'flex',
  },
  qualifyed: {
    justifyContent: 'flex-end',
  },
});
