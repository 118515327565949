import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, CircularProgress, Typography } from '@material-ui/core';

import { useLang } from '../../shared/hooks';
import PartnerActions from '../../../../redux/actions/partners-actions/partners.actions';

import makeClasses from './CheckInCodeRTRoot.style';

import checkInCodeIcon from '../../../../assets/images/icons/checkin_code_icon.svg';

import type { ReactChild } from 'react';
import type { ButtonProps } from '@material-ui/core/Button';

import type { GlobalMode } from '../../shared/types';
import type { CheckInCodeRTModel } from '../../shared/models';

export function CheckInCodeRTRoot(props?: Props) {
  const {
    checkinCode = '',
    globalMode = false,
    children = null,
    onClick = () => undefined,
    ...btnProps
  } = props ?? {};
  const notCode = !Boolean(checkinCode);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const lang = useLang();
  const styles = makeClasses({ globalMode, notCode });
  const handleOnClick = event => {
    if (globalMode) {
      setIsLoading(true);
      dispatch(PartnerActions.getProfile());
      setTimeout(() => {
        onClick(event);
        setIsLoading(false);
      }, 1_000);
    }
  };
  return (
    <Box
      component='button'
      className={styles.root}
      onClick={handleOnClick}
      {...btnProps}
    >
      {globalMode && (
        <>
          {isLoading && <CircularProgress size={16} />}
          {!isLoading && <img alt='checkin-code' src={checkInCodeIcon} />}
        </>
      )}
      <Typography classes={{ root: styles.checkinCodeTextLine }}>
        {notCode ? lang.multiStore : lang.singleStore}
        {!notCode ? `: ${checkinCode}` : ''}
      </Typography>
      {children}
    </Box>
  );
}

interface Props
  extends ButtonProps<'button', Pick<CheckInCodeRTModel, 'checkinCode'>> {
  globalMode?: GlobalMode;
  children?: ReactChild;
}
