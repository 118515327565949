enum PartnerTypes {
  DISTILLER = 'distiller',
  PARTNER = 'partner',
  PARTNERS_INTEGRADO = 'integration',
  RAPPI_BOX = 'rappibox',
  RESTAURANT = 'restaurant',
  SHOPPER = 'shopper',
}

export default PartnerTypes
