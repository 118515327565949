export function WarningIcon() {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.77295 2.66667C5.82785 2.66667 3.43962 5.0549 3.43962 8C3.43962 10.9451 5.82785 13.3333 8.77295 13.3333C11.7187 13.3333 14.1063 10.9452 14.1063 8C14.1063 5.05485 11.7187 2.66667 8.77295 2.66667ZM1.77295 8C1.77295 4.13443 4.90738 1 8.77295 1C12.6392 1 15.7729 4.13449 15.7729 8C15.7729 11.8655 12.6392 15 8.77295 15C4.90738 15 1.77295 11.8656 1.77295 8ZM8.76967 4.63607C9.22991 4.63607 9.60301 5.00916 9.60301 5.4694V8.4154C9.60301 8.87564 9.22991 9.24874 8.76967 9.24874C8.30944 9.24874 7.93634 8.87564 7.93634 8.4154V5.4694C7.93634 5.00916 8.30944 4.63607 8.76967 4.63607ZM8.76968 9.69727C8.30944 9.69727 7.93634 10.0704 7.93634 10.5306C7.93634 10.9908 8.30944 11.3639 8.76968 11.3639H8.77634C9.23658 11.3639 9.60968 10.9908 9.60968 10.5306C9.60968 10.0704 9.23658 9.69727 8.77634 9.69727H8.76968Z'
        fill='#FF7E2E'
      />
    </svg>
  );
}
