import React from 'react'
//style
import useStyles from './CustomChatMessage.style'
import { messageDataType } from '../../../constants/MessageDataType'
import MessageOriginType from '../../../constants/MessageOriginType'

const CustomChatMessage = ({ data, messageDataType, originType, timeDiff }) => {
  const classes = useStyles({ originType })

  switch (messageDataType) {
    default:
      return (
        <div className={classes.textMessageContainer}>
          <span className={classes.textMessage}>{data}</span>
          <span className={classes.timeDiff}>{timeDiff}</span>
        </div>
      )
  }
}
CustomChatMessage.defaultProps = {
  classes: {},
  messageDataType: messageDataType.TEXT,
  messageOriginType: MessageOriginType.SENDER,
}
export default CustomChatMessage
