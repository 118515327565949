import { makeStyles } from '@material-ui/styles';

import { neutral } from '../../../shared/styles/colors';

export default makeStyles({
  root: {
    margin: '1.5rem 0px',
    backgroundColor: neutral.white,
  },
  header: {
    color: neutral.D20,
    fontSize: '1rem',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    padding: '0.5rem 1rem',
  },
  content: {
    fontSize: '0.875rem',
    borderTop: `1px solid ${neutral.D10}`,
    padding: '0.5rem 1rem',
  },
});
