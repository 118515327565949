import { createAsyncThunk } from '@reduxjs/toolkit';
import { cargoSliceName } from './cargo.slice';
import { CargoRepository } from '../../repositories';
import {
  CoverageValidateRequest,
  RTCargoRequest,
  CancelOrderCostRequest,
  OrdersListRequest,
  CancelCargoOrderRequest,
} from '../../core/requests';
import { responseAdapter } from '../../adapters';

export const getCargoStores = createAsyncThunk(
  `${cargoSliceName}/getCargoStores`,
  async (payload: Array<number>, { rejectWithValue }) => {
    try {
      const response = await CargoRepository.getCargoStores(payload);
      return responseAdapter.toCargoStores(response.data);
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);
export const getVerifationCoverage = createAsyncThunk(
  `${cargoSliceName}/getVerificationCoverage`,
  async (payload: CoverageValidateRequest, { rejectWithValue }) => {
    try {
      const response = await CargoRepository.validateCoverage(payload);
      return responseAdapter.toValidateCoverage(response.data);
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);
export const createOrder = createAsyncThunk(
  `${cargoSliceName}/createOrder`,
  async (payload: RTCargoRequest, { rejectWithValue }) => {
    try {
      const response = await CargoRepository.createOrder(payload);
      const value = responseAdapter.toCreateOrder(response.data);
      if (!value.isSuccessful) {
        return rejectWithValue(value);
      }
      return value;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

export const getCargoOrderList = createAsyncThunk(
  `${cargoSliceName}/getOrderList`,
  async (request: OrdersListRequest, { rejectWithValue }) => {
    try {
      const response = await CargoRepository.getOrderList(request);
      return {
        orderId: request.order_id,
        storeList: request.store_ids,
        data: responseAdapter.toOrderList(response.data),
      };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

export const getCancelCostOrder = createAsyncThunk(
  `${cargoSliceName}/getCancelCost`,
  async (request: CancelOrderCostRequest, { rejectWithValue }) => {
    try {
      const response = await CargoRepository.requestCancelCost(request);
      return responseAdapter.toCancelCost(response.data);
    } catch (ex) {
      return rejectWithValue(0);
    }
  },
);

export const cancelCargoOrder = createAsyncThunk(
  `${cargoSliceName}/cancelCargoOrder`,
  async (request: CancelCargoOrderRequest, { rejectWithValue }) => {
    try {
      const response = await CargoRepository.cancelOrder(request);
      return { orderId: request.rappi_order_id };
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);
