const title = {
  id: 'suggestProductDialog.title',
};
const subTitle = {
  id: 'productSuggestionAvailabilityDialog.subTitle',
};
const description = {
  id: 'productSuggestionAvailabilityDialog.description',
};
const infoText = {
  id: 'productSuggestionAvailabilityDialog.infoText',
};
const charLimitText = {
  id: 'suggestProductDialog.charLimitText',
};
const replaceWithText = {
  id: 'suggestProductDialog.replaceWithText',
};
const nothaveProductText = {
  id: 'suggestProductDialog.notHaveProduct',
};
const rejectOrder = {
  id: 'productSuggestionAvailabilityDialog.rejectOrder',
};

const productPlaceHolder = { id: 'globals.products' };

const confirm = { id: 'productSuggestionAvailabilityDialog.send' };

const textIds = {
  charLimitText,
  description,
  infoText,
  nothaveProductText,
  rejectOrder,
  replaceWithText,
  subTitle,
  title,
  productPlaceHolder,
  confirm,
};

export default textIds;
