// React
import React from 'react';
// Components
import CustomSwipeableDialog from '../common/custom-swipeable-dialog';
import PartnerNextInterval from '../partner-next-interval';
// Styles
import useStyles from './ClosedPartnerStateDialog.styles';
import {
  analitycsEvent,
  GACategories,
  GAActions,
} from './../../utils/ga-utils';

const ClosedPartnerStateDialog = ({
  clarification,
  confirmText,
  description,
  onClose,
  open,
  title,
  partnerId,
}) => {
  const componentClasses = useStyles();
  return (
    <CustomSwipeableDialog
      buttons={[
        {
          color: 'primary',
          label: confirmText,
          onClick: () => {
            analitycsEvent(
              GACategories.PARTNER_STATE,
              GAActions.OK_DIALOG_STORE_CLOSED,
              `partner_id=${partnerId}`,
            );
            onClose();
          },
          variant: 'contained',
        },
      ]}
      classes={{
        dialogPaper: componentClasses.dialogPaper,
      }}
      onClose={onClose}
      open={open}
      title={title}
    >
      <div className={componentClasses.content}>
        <div className={componentClasses.description}>
          <span>{description}</span>
        </div>
        <PartnerNextInterval
          classes={{ root: componentClasses.partnerNextInterval }}
        />
        <div className={componentClasses.clarification}>
          <span>{clarification}</span>
        </div>
      </div>
    </CustomSwipeableDialog>
  );
};

export default ClosedPartnerStateDialog;
