import {
  CHECKOUT_RT_DEBOUNCE_TIME,
  CheckoutRTStatus,
  ICheckoutRTCase,
  ICheckoutRTCaseParams,
  MAX_QUANTITY_OF_PACKAGES,
} from '../../domain';
import { checkoutRTHelper } from '../../domain/helpers';
import { checkoutRTHandler } from '../../infra';

export function checkoutRTCase({
  code,
}: ICheckoutRTCaseParams): ICheckoutRTCase {
  const { stateStatus, update, clear, get, currentData, getV2Orders } = checkoutRTHandler();
  const status: CheckoutRTStatus = {
    isInitial: checkoutRTHelper.isInitial(stateStatus),
    updateInprogress: checkoutRTHelper.updateInProgress(stateStatus),
    isUpdateDenied: checkoutRTHelper.isUpdateDenied(stateStatus),
    isUpdated: checkoutRTHelper.isUpdated(stateStatus),
    getInProgress: checkoutRTHelper.getInProgress(stateStatus),
    isGetLoaded: checkoutRTHelper.isGetLoaded(stateStatus),
    isNotReadyToSetData: checkoutRTHelper.isNotReadyToSetData(stateStatus),
    isNotReadyToUpdateData: checkoutRTHelper.isNotReadyToUpdateData(
      stateStatus,
    ),
  };
  const showSection = checkoutRTHelper.showSection(code);

  return {
    debonceTime: CHECKOUT_RT_DEBOUNCE_TIME,
    defaultValue: currentData,
    maxQuantityOfPackages: MAX_QUANTITY_OF_PACKAGES,
    showSection,
    status,
    updateHandoffDetail: update,
    clear,
    getHandoffDetail: get,
    getV2Orders
  };
}
