// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../shared/styles/colors';

export default makeStyles({
  productBoxImageRoot: {
    marginTop: 12,
  },
  productBoxProductInfoContainer: {
    marginTop: 12,
  },
  productBoxRoot: {
    alignItems: 'flex-start',
    borderBottom: `1px solid ${neutral.D10}`,
    padding: '4px 0px 16px 16px',
  },
  productsContainer: {
    borderLeft: `1px solid ${neutral.D10}`,
    borderRight: `1px solid ${neutral.D10}`,
    borderTop: `1px solid ${neutral.D10}`,
    display: 'flex',
    flexDirection: 'column',
    margin: '16px 16px 0px 16px',
  },
  root: {
    color: neutral.D20,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Poppins',
    fontSize: 17,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    width: '100%',
  },
  titleContainer: {
    margin: '24px 16px 0px 16px',
  },
});
