import { OrderResponse } from '../../../../shared/infra';

const _isGeoqueue = (order: OrderResponse) =>
  order.special_order?.includes('geoqueue') ?? false;

export const geoqueueHelper = {
  isGeoqueue: (order: OrderResponse) => _isGeoqueue(order),
  showSection: (order: OrderResponse) =>
    _isGeoqueue(order) && order.partner_order_state === 'cooking',
};
