import NewKanbanCard from '../new-kanban-card';
import CancelKanbanCard from '../cancel-kanban-card';
import { KanbanOMCard } from '../order-management/shared';
import { CustomKanbanColumn } from '../common/custom-kanban-column';

import { ordersWithSameBundleIdAndStore } from '../../shared/utils/orders';
import { usePendingToAcceptColumn } from './hooks';

function PendingToAcceptColumn({ emptyText, title, allOrders = [] }) {
  const {
    showEmpty,
    canceledOrders,
    pendingOrders,
  } = usePendingToAcceptColumn();

  return (
    <CustomKanbanColumn
      count={canceledOrders.length + pendingOrders.length}
      emptyText={emptyText}
      showEmpty={showEmpty}
      title={title}
    >
      {canceledOrders.map(order => (
        <CancelKanbanCard
          key={`${order.id}`}
          order={order}
          showChipOfBundle={
            !!ordersWithSameBundleIdAndStore({ allOrders, order }).length
          }
        />
      ))}
      {pendingOrders.map(order => {
        if (!!order?.state_order_management?.state) {
          return <KanbanOMCard key={`${order.id}`} order={order} />;
        }
        return (
          <NewKanbanCard
            key={`${order.id}`}
            order={order}
            showChipOfBundle={
              !!ordersWithSameBundleIdAndStore({ allOrders, order }).length
            }
          />
        );
      })}
    </CustomKanbanColumn>
  );
}

export default PendingToAcceptColumn;
