import { useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import MainLayout from '../main-layout';
import MenuAdvancedFilterToolbar from '../menu-advanced-filter-toolbar';
import MenuBoard from '../menu-board';
import { MenuSearch } from '../menu-search';
import MenuSearchField from '../menu-search-field';
import MenuStoreRequiredBox from './../menu-store-required-box';
import CustomAlert from '../common/custom-alert/CustomAlert';

import { useFormatMessage } from '../../intl/intl';
import { Serverities } from '../../constants/Severities';
import Paths from '../../constants/Paths';
import useStyles from './MenuPage.styles';

const TopMessageCustomAlert = () => {
  const componentClasses = useStyles();
  const menuEditableInfoLabel = useFormatMessage({
    id: 'menu.editableInfoLabel',
  });
  return (
    <CustomAlert
      classes={{ root: componentClasses.topMessage }}
      type={Serverities.INFO}
      text={menuEditableInfoLabel}
    />
  );
};

function MenuPage(props) {
  const {
    firstStoreId,
    onStoreQueryParamChange,
    showMenuBoard,
    showMenuSearch,
    selectedStoreId,
    title,
  } = props;

  const { search } = useLocation();

  const navigate = useNavigate();

  const queryParamStoreId = new URLSearchParams(search).get('store_id');

  const componentClasses = useStyles();
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  useEffect(() => {
    if (!firstStoreId) {
      return;
    }
    if (!!queryParamStoreId) {
      onStoreQueryParamChange(queryParamStoreId);
    } else {
      navigate(Paths.getMenuQueryParamsPath(firstStoreId));
    }
  }, [firstStoreId, queryParamStoreId]);

  return (
    <MainLayout title={title}>
      <div className={componentClasses.root}>
        <MenuAdvancedFilterToolbar />
        {isMdUp && <TopMessageCustomAlert />}
        <div className={componentClasses.content}>
          {!selectedStoreId && <MenuStoreRequiredBox />}
          {selectedStoreId && (
            <>
              {!isMdUp && <MenuSearchField />}
              {!isMdUp && <TopMessageCustomAlert />}
              {showMenuBoard && <MenuBoard />}
              {showMenuSearch && <MenuSearch />}
            </>
          )}
        </div>
      </div>
    </MainLayout>
  );
}

export default MenuPage;
