// Material UI
import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  bodyContainer: {
    display: 'grid',
    gridRowGap: 24,
    fontSize: 17,
  },
  formItemContainer: {
    display: 'grid',
    gridRowGap: 10,
  },
  dialogPaper: {
    width: '488px',
  },
  root: {},
}));
