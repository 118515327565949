// React
import React from 'react';
// Components
import CustomDrawer from '../common/custom-drawer';
import useStyles from './NoDeactivatedToppingDrawer.styles';
import CustomButton from '../common/buttons/custom-button';
import { analitycsEvent, GACategories, GAActions } from '../../utils/ga-utils';
import { useNavigate } from 'react-router-dom';
import Paths from '../../constants/Paths';

const NoDeactivatedToppingDrawer = ({
  cancelButtonText,
  confirmButtonText,
  explanationText,
  open,
  onClose,
  title,
  toppingsList,
  toppingsListDescription,
  partnerId,
}) => {
  const classes = useStyles();

  const navigate = useNavigate();

  return (
    <CustomDrawer
      open={open}
      onClose={() => {
        analitycsEvent(
          GACategories.MENU_MANAGEMENT,
          GAActions.FAILURE_CANCEL_MULTIPLE_TOPPINGS,
          `partner_id=${partnerId}`,
        );
        onClose();
      }}
      title={title}
    >
      <div className={classes.content}>
        <div className={classes.titleContainer}>{toppingsListDescription}</div>
        <div className={classes.toppingsList}>
          {toppingsList.map((topping, index) => {
            return (
              <div key={`td-${index}`} className={classes.topping}>
                {topping}
              </div>
            );
          })}
        </div>
        <div className={classes.explanationText}>{explanationText}</div>
      </div>
      <div className={classes.actionsContainer}>
        <CustomButton
          classes={{
            root: classes.button,
            submitButton: classes.cancelButton,
          }}
          fullWidth={false}
          onClick={() => {
            analitycsEvent(
              GACategories.MENU_MANAGEMENT,
              GAActions.FAILURE_CANCEL_MULTIPLE_TOPPINGS,
              `partner_id=${partnerId}`,
            );
            onClose();
          }}
          text={cancelButtonText}
        ></CustomButton>
        <CustomButton
          classes={{
            root: classes.button,
            submitButton: classes.confirmButton,
          }}
          color='primary'
          fullWidth={false}
          onClick={() => {
            analitycsEvent(
              GACategories.MENU_MANAGEMENT,
              GAActions.FAILURE_GO_TO_MENU_MULTIPLE_TOPPINGS,
              `partner_id=${partnerId}`,
            );
            navigate(Paths.MENU);
            onClose();
          }}
          text={confirmButtonText}
          variant='contained'
        ></CustomButton>
      </div>
    </CustomDrawer>
  );
};

NoDeactivatedToppingDrawer.defaultProps = {
  toppingsList: [],
  open: false,
};

export default NoDeactivatedToppingDrawer;
