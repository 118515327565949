import { useEffect } from 'react';

import {
  ERROR_CODES,
  OrderDialogApp,
  orderMgmtCurrentScreen,
  ordermgmtDialogFacade,
  orderMgmtFacade,
  orderMgmtState,
  orderMgmtStatus,
  showOrderDetailTimeout,
} from '../../../business/order-mgmt';
import { OrderMgmtAnalytics } from '../../../business/order-mgmt/analitycs';
import { orderMgmtDetailFacade } from '../../../business/order-mgmt/infra/store/order-detail';
import ChromeExtensionService from '../../../services/ChromeExtensionService';
import OrdersUtils from '../../../utils/OrdersUtils';
import { useOrderMgmtAcceptRemoveChangesDialog } from '../components/order-mgmt-accept-remove-changes-dialog/hooks';
import { useOrderMgmtReplaceProductDialog } from '../components/order-mgmt-replace-product-dialog/hooks';
import { useOrderMgmtUndoChangesDialog } from '../components/order-mgmt-undo-changes-dialog/hooks';
import { useOrderMgmtLang } from './useOrderMgmtLang';

export function useOrderManagement() {
  const {
    selectOrderId,
    selectCreatedAt,
    selectTotalValue,
    selectIsKanbanUserAppChatEnabled,
    selectOrderDetail,
    selectOrders,
    onOpenSupportWebChat,
    onClickReplaceButton,
    onOpenCustomerPhoneSessionDialog,
  } = orderMgmtFacade();

  const { selectStartedAt } = orderMgmtDetailFacade();
  const {
    selectPartnerId,
    selectIsOpen,
    selectProducts,
    selectCurrentScreen,
    selectStatus,
    selectErrorCode,
    selectHasAnyProductHadSuggestions,
    selectHasAnyToppingHadSuggestions,
    selectIsOpenBy,
    selectEditType,
    onSetSubstitutionProductItems,
    onSetSubstitutionToppingItems,
    onSetOrder,
    onOpenItemSearchDialog,
    onSetCurrentScreen,
    onUndoProductSuggestions,
    onSendProductSubstitutionItemsRequest,
    onUndoToppingSuggestions,
    onCleanOrderMgmtDialog,
    onOpenUndoSuggestionDialog,
    onOpenReplaceProductDialog,
    numberOfProductSuggestions,
    numberOfToppingSuggestions,
    totalNumberOfItemSuggestions,
    selectCurrentOrderState,
    onSetCurrentOrderState,
    onSetSelectProduct,
    onSetSelectTopping,
    closeDialog,
    onOpenUndoChangesDialog,
    onConfirmUndoChangesButton,
    onUncheckProductToppings,
    onDeactivateProductsAndToppingsRequest,
    selectCanSendChanges,
    onSetRemoveProduct,
    onUndoRemoveProductsChanges,
    onOpenAcceptRemoveChangesDialog,
    onRemoveProductsFromOrderRequest,
    onCloseOrderDetail,
  } = ordermgmtDialogFacade();
  const {
    onConfirm,
    onClose: onCloseUndoChangesDialog,
  } = useOrderMgmtUndoChangesDialog();
  const {
    onClose: onCloseReplaceChangesDialog,
  } = useOrderMgmtReplaceProductDialog();
  const {
    onClose: onCloseAcceptRemoveChangesDialog,
  } = useOrderMgmtAcceptRemoveChangesDialog();
  const {
    isSuccessful,
    hasError,
    rejectHandler,
    successfulHandler,
  } = OrderDialogApp();
  const { rejectedToppingMaxLimit } = useOrderMgmtLang();

  useEffect(() => {
    const isIdleScreen = selectCurrentScreen === orderMgmtCurrentScreen.Idle;
    if (selectIsOpen && isIdleScreen) {
      onSetOrder(selectOrderDetail);
      onSetCurrentScreen(orderMgmtCurrentScreen.Dialog);
    }
  }, [selectCurrentScreen, selectIsOpen]);

  useEffect(() => {
    if (isSuccessful) {
      const notCallChatSupport =
        isDeactivatingItemsState || hasOrderProductsRemoved;
      successfulHandler(storeId, selectOrderId, notCallChatSupport);
    } else if (hasError) {
      const message =
        selectErrorCode === ERROR_CODES.toppingMaxLimit
          ? rejectedToppingMaxLimit
          : '';
      rejectHandler(message);
    }
  }, [selectStatus]);

  useEffect(() => {
    if (selectIsOpen) {
      ChromeExtensionService.pauseAudio();
    }
  }, [selectIsOpen]);

  useEffect(() => {
    const newOrders = OrdersUtils.filterNew(selectOrders).filter(
      order => !order?.state_order_management,
    );
    if (newOrders.length > 1 && selectIsOpen) {
      ChromeExtensionService.playOrderMgmtNewOrderSound();
    }
  }, [selectOrders]);

  const productsByCorridor = selectOrderDetail?.productsByCorridor;
  const storeId =
    selectOrderDetail?.stores && selectOrderDetail?.stores[0]?.store_id;
  const createdOrderAt = selectOrderDetail?.created_at;
  const userId = selectOrderDetail?.user?.id;
  const userName = selectOrderDetail?.user?.first_name;
  const phone = selectOrderDetail?.user?.phone;
  const isOrderEnabledToEdit = selectOrderDetail?.is_enabled;
  const deactivatedProductsAndToppings =
    selectOrderDetail?.deactivated_products_and_toppings ?? {};
  const wasOrderItemsDeactivated =
    Object.keys(deactivatedProductsAndToppings).length !== 0;
  const deactivateProductIds =
    deactivatedProductsAndToppings?.product_ids ?? [];
  const deactivateToppingIds =
    deactivatedProductsAndToppings?.topping_ids ?? [];
  const areAllProductsDeactivated = (
    selectOrderDetail?.products || []
  ).every(product => deactivateProductIds.includes(product?.id));
  const hasOrderOnlyOneProduct =
    (selectProducts || []).filter(product => !product.isRemoved).length === 1;
  const hasOrderProductsRemoved = selectProducts.some(
    product => product?.isRemoved,
  );
  const removedProducts = selectProducts.filter(product => product.isRemoved);
  const hasOrderProductsSelected = selectProducts.some(
    product => product.isSelected,
  );

  const getShowSupportWebChatButton = () => {
    const userAppChatEnabled = selectOrderDetail?.user_app_chat_enabled ?? true;
    return userAppChatEnabled && selectIsKanbanUserAppChatEnabled;
  };

  const isSendingRequest = selectStatus === orderMgmtStatus.Pending;

  const isResumeScreen = selectCurrentScreen === orderMgmtCurrentScreen.Resume;

  const isDeactivatingItemsState =
    selectCurrentOrderState === orderMgmtState.DeactivateItems;
  const isSendingUserApproval =
    selectCurrentOrderState === orderMgmtState.SendUserApproval;
  const hasNoUserResponse =
    selectCurrentOrderState === orderMgmtState.NoUserResponse;
  const isInEditionMode = isSendingUserApproval || hasNoUserResponse;

  const showSupportWebChatButton = getShowSupportWebChatButton();

  const isOfferReplaceItemsEnabled = selectProducts.some(
    product =>
      product.isSelected ||
      (product?.toppings || []).some(topping => topping.isSelected),
  );

  const isOrderBeingCooked = OrdersUtils.isBeingCooking(
    selectOrderDetail.partner_order_state,
  );

  const isOrderWaiting =
    selectOrderDetail?.state_order_management?.state === 'to_wait';

  const isOrderAcceptedByUser =
    selectOrderDetail?.state_order_management?.state === 'accepted';
  const isOrderTimingOut = showOrderDetailTimeout(
    selectOrderDetail?.state_order_management?.state,
    selectOrderDetail?.partner_order_state,
  );

  const showEditBtn =
    !isOrderBeingCooked && !isInEditionMode && !isOrderAcceptedByUser;

  const handleSendItemSubstitutions = () => {
    if (!isResumeScreen) return;
    OrderMgmtAnalytics.whenSendSubstitution(
      { orderId: selectOrderId, storeId },
      {
        item:
          selectHasAnyProductHadSuggestions &&
          !selectHasAnyToppingHadSuggestions
            ? 'product'
            : !selectHasAnyProductHadSuggestions &&
              selectHasAnyToppingHadSuggestions
            ? 'topping'
            : 'both',
        optionType: selectCurrentOrderState as any,
      },
    );
    onSendProductSubstitutionItemsRequest(
      selectProducts,
      selectOrderId,
      selectPartnerId,
      storeId,
    );
  };

  const handleDeactivateProductsAndToppings = () => {
    onDeactivateProductsAndToppingsRequest(
      selectProducts,
      storeId,
      selectOrderId,
    );
    OrderMgmtAnalytics.whenSelectOutOfStockItem(
      { orderId: selectOrderId, storeId },
      { isOpenBy: selectIsOpenBy },
    );
    closeDialog();
  };

  const handleRemoveProductsFromOrder = () => {
    onRemoveProductsFromOrderRequest({
      order: selectOrderDetail,
      storeId,
      products: removedProducts,
    });
    onCloseAcceptRemoveChangesDialog();
    onCloseOrderDetail();
  };

  const handleConfirmUndoChanges = () => {
    OrderMgmtAnalytics.whenRemoveChanges(
      { orderId: selectOrderId, storeId },
      { action: 'discard' },
    );
    onConfirm();
  };

  const handleUndoChangesDialogClose = () => {
    onCloseUndoChangesDialog();
  };

  const handleContinueProductChanges = (productId: number) => {
    onCloseReplaceChangesDialog();
    onUncheckProductToppings(productId);
  };

  const handleReplaceProductDialogClose = () => {
    onCloseReplaceChangesDialog();
  };

  const handleOpenSupportWebChat = () => {
    const isOM = true;
    const cleanOM = true;
    const openOrderDetail = false;
    closeDialog(cleanOM, openOrderDetail);
    onOpenSupportWebChat(isOM);
  };

  const handleOrderOption = option => {
    if (!option) return;
    if (option !== orderMgmtState.DeactivateItems) {
      const cleanOM = false;
      const openOrderDetail = false;
      const newOrderSound = false;
      closeDialog(cleanOM, openOrderDetail, newOrderSound);
    }
    onSetCurrentOrderState(option);
    OrderMgmtAnalytics.whenEditOrder(
      { storeId, orderId: selectOrderId },
      {
        isOpenBy: selectIsOpenBy!,
        editType: selectEditType!,
        optionType: option,
      },
    );
  };

  const handleCloseEditionMode = () => {
    const cleanOM = true;
    const openOrderDetail = false;
    closeDialog(cleanOM, openOrderDetail);
  };

  const handleCheckProductSelected = productId => {
    const product = selectProducts.find(
      product => `${product.id}` === `${productId}`,
    );
    const hasProductHaveToppingsSelected = (product?.toppings || []).some(
      topping => topping.isSelected,
    );
    hasProductHaveToppingsSelected
      ? onOpenReplaceProductDialog(productId)
      : handleSelectProduct(productId);
  };

  const handleCheckIfProductHasBeenRemoved = productId => {
    const product = selectProducts.find(
      product => `${product.id}` === `${productId}`,
    );
    return product?.isRemoved;
  };

  const handleSelectProduct = productId => {
    onSetSelectProduct(productId);
  };

  const handleSelectTopping = (productId, toppingId) => {
    onSetSelectTopping(productId, toppingId);
  };

  const handleRemoveProduct = productId => {
    onSetRemoveProduct(productId);
  };

  const handleOpenCustomerPhoneSessionDialog = () => {
    const cleanOM = true;
    const openOrderDetail = false;
    closeDialog(cleanOM, openOrderDetail);
    onOpenCustomerPhoneSessionDialog({ isOM: true });
  };

  const checkIfProductIsSelected = productId => {
    return selectProducts.find(product => `${product.id}` === `${productId}`)
      ?.isSelected;
  };

  const checkIfToppingIsSelected = (productId, toppingId) => {
    const product = selectProducts.find(
      product => `${product.id}` === `${productId}`,
    );
    return (product?.toppings || []).find(
      topping => `${topping.id}` === `${toppingId}`,
    )?.isSelected;
  };

  const checkIfProductHasToppingsSelected = (productId, toppingId) => {
    const product = selectProducts.find(
      product => `${product.id}` === `${productId}`,
    );
    const topping = (product?.toppings || []).find(
      topping => topping.isSelected,
    );
    return (
      (product?.toppings || []).some(topping => topping.isSelected) &&
      `${toppingId}` !== `${topping?.id}`
    );
  };

  const handleClickReplaceButton = ({ editType }) => {
    onClickReplaceButton({
      isOpenBy: isOrderBeingCooked ? 'Post Take' : 'Pre Take',
      editType,
    });
  };

  return {
    isOpen: selectIsOpen,
    partnerId: selectPartnerId,
    orderId: selectOrderId,
    createdAt: selectCreatedAt,
    totalValue: selectTotalValue,
    products: selectProducts,
    currentScreen: selectCurrentScreen,
    isSendingRequest,
    hasAnyProductHadSuggestions: selectHasAnyProductHadSuggestions,
    hasAnyToppingHadSuggestions: selectHasAnyToppingHadSuggestions,
    storeId,
    userId,
    createdOrderAt,
    productsByCorridor,
    getShowSupportWebChatButton,
    showSupportWebChatButton,
    onSetSubstitutionProductItems,
    onSetSubstitutionToppingItems,
    selectOrderDetail,
    onSetOrder,
    onOpenItemSearchDialog,
    onSetCurrentScreen,
    onUndoProductSuggestions,
    onSendProductSubstitutionItemsRequest,
    onUndoToppingSuggestions,
    onCleanOrderMgmtDialog,
    onOpenUndoSuggestionDialog,
    handleOpenSupportWebChat,
    onOpenReplaceProductDialog,
    numberOfProductSuggestions,
    numberOfToppingSuggestions,
    totalNumberOfItemSuggestions,
    isResumeScreen,
    handleSendItemSubstitutions,
    handleConfirmUndoChanges,
    handleUndoChangesDialogClose,
    handleContinueProductChanges,
    handleReplaceProductDialogClose,
    userName,
    phone,
    onSetCurrentOrderState,
    isDeactivatingItemsState,
    isSendingUserApproval,
    hasNoUserResponse,
    isInEditionMode,
    handleOrderOption,
    handleClickReplaceButton,
    handleCloseEditionMode,
    onOpenSupportWebChat,
    handleCheckProductSelected,
    handleSelectProduct,
    handleSelectTopping,
    isOfferReplaceItemsEnabled,
    closeDialog,
    onOpenUndoChangesDialog,
    onConfirmUndoChangesButton,
    checkIfProductIsSelected,
    checkIfToppingIsSelected,
    checkIfProductHasToppingsSelected,
    isOrderEnabledToEdit,
    handleDeactivateProductsAndToppings,
    handleOpenCustomerPhoneSessionDialog,
    canSendChangesByTime: selectCanSendChanges,
    isOrderBeingCooked,
    deactivateProductIds,
    deactivateToppingIds,
    wasOrderItemsDeactivated,
    areAllProductsDeactivated,
    hasOrderOnlyOneProduct,
    handleRemoveProduct,
    handleCheckIfProductHasBeenRemoved,
    hasOrderProductsRemoved,
    onUndoRemoveProductsChanges,
    onOpenAcceptRemoveChangesDialog,
    removedProducts,
    handleRemoveProductsFromOrder,
    hasOrderProductsSelected,
    isOrderWaiting,
    isOrderTimingOut,
    showEditBtn,
    startedAt: selectStartedAt,
  };
}
