import Colors from '../../constants/Colors';
import StoreIcon from '../icons/store-icon/StoreIcon';

import useStyles from './styles';

export function StoreNameAlert({ storeName }: { storeName: string }) {
  const componentClasses = useStyles();
  return (
    <div className={componentClasses.root}>
      <div className={componentClasses.icon}>
        <StoreIcon color={Colors.AZURE_RADIANCE} />
      </div>
      <div className={componentClasses.storeName}>{storeName}</div>
    </div>
  );
}
