import { makeStyles } from '@material-ui/core';

export default makeStyles(() => {
  return {
    switchThumb: {
      display: 'inherit',
    },
    control: ({ secondaryColor }) => {
      return {
        color: secondaryColor,
      };
    },
    controlTrack: ({ secondaryColor }) => {
      return { backgroundColor: secondaryColor };
    },
    label: ({ checked, primaryColor, secondaryColor }) => {
      return {
        color: checked ? primaryColor : secondaryColor,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        lineHeight: 'normal',
      };
    },
    formControlLabelRoot: {
      margin: '0px',
    },
  };
});
