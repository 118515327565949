// Utils
import dateTimeUtils from '../../utils/DateTimeUtils'
// Interfaces
import {
  LoadActionPayload,
  StoreDiagnostic,
  DiagnosticRule,
} from './interfaces'
import {
  DiagnosticResponse,
  StoreDiagnosticResponse,
} from '../../services/store-service'

const parseToDiagnosticRule = (
  diagnosticResponse: DiagnosticResponse,
): DiagnosticRule => {
  return {
    message: diagnosticResponse.message || '',
    value: diagnosticResponse.value || false,
  }
}

const parseToDiagnosticRules = (
  diagnosticResponses: DiagnosticResponse[],
): DiagnosticRule[] => {
  return diagnosticResponses.map(diagnosticResponse =>
    parseToDiagnosticRule(diagnosticResponse),
  )
}

const parseToStoreDiagnostic = (
  storeDiagnosticResponse: StoreDiagnosticResponse,
  tz: string,
): StoreDiagnostic => {
  const diagnostics = parseToDiagnosticRules(
    storeDiagnosticResponse.diagnostics,
  )
  return {
    alertMessage: storeDiagnosticResponse.alertMessage || '',
    diagnostics,
    lastUpdated: dateTimeUtils.getDateTzNow(tz),
  }
}

const parseToLoadDiagnosticActionPayload = (
  selectedStoreId: number,
  storeDiagnosticResponse: StoreDiagnosticResponse,
  tz: string,
): LoadActionPayload => {
  const storeDiagnostic = parseToStoreDiagnostic(storeDiagnosticResponse, tz)
  return {
    selectedStoreId,
    storeDiagnostic,
  }
}

const utils = { parseToLoadDiagnosticActionPayload }

export default utils
