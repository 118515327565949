import { useSelector } from 'react-redux';

import { selectors as partnerSelectors } from '../../../../../redux/partner';

import type { PartnerSaturationDTO, PartnerStateReturn } from '../../core';

export function partnerState(): PartnerStateReturn {
  const stores = useSelector(
    partnerSelectors.selectSimpleStoreStatusNotification,
  );
  const saturation = useSelector(
    partnerSelectors.selectPartnerSaturation,
  ) as PartnerSaturationDTO;
  const isFastOperation = useSelector(partnerSelectors.selectIsFastOperation);
  return {
    stores,
    saturation,
    isFastOperation,
  };
}
