import { makeStyles } from '@material-ui/core';
import { blue, neutral } from '../../../../../../shared/styles/colors';

export default makeStyles({
  icon: ({ disabled }: Props) => ({
    fill: disabled ? neutral.D15 : blue.D6,
  }),
});

interface Props {
  disabled: boolean;
}
