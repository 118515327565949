import { useSelector } from 'react-redux';

import OrdersSelector from '../../../redux/selectors/orders-selectors';

const useDeliveryMethod = () =>
  useSelector(OrdersSelector.selectOrderDeliveryMethodInOrderDetailDialog);

const hooks = {
  useDeliveryMethod,
};

export default hooks;
