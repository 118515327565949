import React from 'react';
import { Box, Typography } from '@material-ui/core';
import OrderMgmtStyles from '../../../OrderManagement.style';
import warningIcon from '../../../../../assets/images/icons/warningIcon.svg';

export const OrderMgmtDisclaimer = ({ title, description }: Props) => {
  const componentClasses = OrderMgmtStyles();

  return (
    <Box
      className={componentClasses.disclaimerContent}
      display='flex'
      flexDirection='column'
      gridGap='0.5rem'
      padding='1rem'
      marginBottom='1rem'
      width='100%'
    >
      {title && (
        <Typography
          className={componentClasses.disclaimerTitle}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      <Box display='flex' alignItems='center'>
        <img
          className={componentClasses.disclaimerIcon}
          width={18}
          height={18}
          alt='warning-icon'
          src={warningIcon}
        />
        <Typography
          className={componentClasses.disclaimerDescription}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Box>
    </Box>
  );
};

interface Props {
  title?: string;
  description: string;
}
