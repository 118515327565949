// React
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// Actions
import orderActions from '../../../redux/actions/orders-actions';
// Components
import { OrderHistoryCard } from './OrderHistoryCard';

const mapStateToProps = (state, { intl: { formatMessage } }) => {
  return {};
};

const mapDispatchToProps = {
  onClick: orderActions.openOrderDetailDialog,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(OrderHistoryCard),
);
