import { useDispatch, useSelector } from 'react-redux';
import dialogsManagerActions from '../../../../redux/actions/dialogsManager.actions';
import orderDetailDialogActions from '../../../../redux/actions/orderDetailDialog.actions';
import { selectors as partnerSelectors } from '../../../../redux/partner';
import OrderDetailDialogSelectors from '../../../../redux/selectors/order-detail-dialog-selectors/orderDetailDialog.selectors';
import OrdersSelector from '../../../../redux/selectors/orders-selectors/orders.selectors';
import { OrderMgmtAnalytics } from '../../analitycs';
import { orderDetailApp } from '../../application';

export function orderMgmtFacade() {
  const dispatch = useDispatch();
  const { disabledAutoTakeInOrder, selectStartedAt } = orderDetailApp();
  const selectOrders = useSelector(OrdersSelector.selectOrders);
  const selectOrderId = useSelector(OrderDetailDialogSelectors.selectOrderId);
  const selectOrderDetail =
    {
      ...selectOrders.find(x => x?.id === selectOrderId)?.detail,
      storekeeper: {},
    } ?? {};

  const selectTotalValue = useSelector(
    OrdersSelector.selectFormattedOrderTotalValueInOrderDetailDialog,
  );
  const selectIsKanbanUserAppChatEnabled = useSelector(
    partnerSelectors.selectIsKanbanUserAppChatEnabled,
  );
  const selectCreatedAt = selectOrderDetail?.created_at ?? '';

  const onOpenSupportWebChat = (isOM: boolean = false) => {
    const orderId = selectOrderId;
    const orderDetail = selectOrderDetail;
    const storeId = orderDetail?.stores[0]?.store_id;
    if (!orderId) return;
    dispatch(dialogsManagerActions.close());
    dispatch(orderDetailDialogActions.clearOrderDetailDialogAction());
    window.postMessage({
      openUserChat: true,
      orderId,
      isOM,
    });
    OrderMgmtAnalytics.whenOpenChat({ orderId, storeId }, { isOM });
    // isOM
    //   ? OrderMgmtAnalytics.whenOpenChat({ orderId, storeId }, { isOM })
    //   : EventActions.onOpenChatFromOrderDetail({
    //       orderId,
    //       storeId,
    //       createdAt: orderDetail?.created_at,
    //       userId: orderDetail?.user?.id,
    //     });
  };

  const onClickReplaceButton = async ({ isOpenBy, editType }) => {
    const isAutoTakeDisabled = false;
    await dispatch(
      dialogsManagerActions.openOrderManagementDialog({ isOpenBy, editType }),
    );
    disabledAutoTakeInOrder(isAutoTakeDisabled);
  };

  const onOpenCustomerPhoneSessionDialog = ({ isOM }) => {
    dispatch(
      dialogsManagerActions.openCustomerPhoneProxySessionDialog({ isOM }),
    );
  };

  return {
    selectOrderId,
    selectCreatedAt,
    selectOrderDetail,
    selectTotalValue,
    selectIsKanbanUserAppChatEnabled,
    selectOrders,
    selectStartedAt,
    onOpenSupportWebChat,
    onClickReplaceButton,
    onOpenCustomerPhoneSessionDialog,
  };
}
