import { BrowserApp } from '../../../../business/common/browser';

export function useBanner() {
  const { redirect2Install, closeBanner } = BrowserApp();

  const bannerClose = () => {
    closeBanner();
  };

  return {
    redirect2Install,
    bannerClose,
  };
}
