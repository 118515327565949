import { useSelector } from 'react-redux';

import ordersSelector from '../../../../../../../redux/selectors/orders-selectors';

const useTotalDetailList = () =>
  useSelector(
    ordersSelector.selectFormattedOrderTotalDetailsInOrderDetailDialog,
  );

const useTotalLabel = () =>
  useSelector(ordersSelector.selectOrderTotalLabelInOrderDetailDialog);

const useTotalValue = () =>
  useSelector(ordersSelector.selectFormattedOrderTotalValueInOrderDetailDialog);

const hooks = {
  useTotalDetailList,
  useTotalLabel,
  useTotalValue,
};

export default hooks;
