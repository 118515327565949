// React
import React, { useState } from 'react'
// Material UI
import CustomSwipeableDialog from './../common/custom-swipeable-dialog'
// Styles
import useStyles from './PickupCodeDialog.styles'
import CustomTextField from './../common/custom-text-field'

const disableConfirmButton = (firstChar, secondChar, thirdChar, fourthChar) => {
  return (
    firstChar === undefined ||
    firstChar === null ||
    firstChar === '' ||
    secondChar === undefined ||
    secondChar === null ||
    secondChar === '' ||
    thirdChar === undefined ||
    thirdChar === null ||
    thirdChar === '' ||
    fourthChar === undefined ||
    fourthChar === null ||
    fourthChar === ''
  )
}

const PickupCodeDialog = ({
  cancelText,
  confirmText,
  history,
  message,
  onClose,
  onConfirm,
  open,
  partnerId,
  title,
}) => {
  const componentClasses = useStyles()
  // To avoid material UI errors, it is important to initialize the variables with empty strings.
  const [firstChar, setFirstChar] = useState('')
  const [secondChar, setSecondChar] = useState('')
  const [thirdChar, setThirdChar] = useState('')
  const [fourthChar, setFourthChar] = useState('')

  let firstCharRef
  let secondCharRef
  let thirdCharRef
  let fourthCharRef

  const cleanCodeChars = () => {
    setFirstChar('')
    setSecondChar('')
    setThirdChar('')
    setFourthChar('')
  }

  const handleKeyUp = (
    e,
    currentInputValue,
    currentInputValueSetState,
    nextInputRef,
    previousInputRef,
  ) => {
    if (e.which === 8 || e.which === 46) {
      // if backspace or supr were keyed up
      if (currentInputValue === '') {
        // If the current input value is empty, focus the previous input.
        previousInputRef.focus()
        return
      }

      // If the current input value is not empty, just empty it.
      currentInputValueSetState('')
      return
    }

    var charTyped = String.fromCharCode(e.which)
    if (/[a-z\d]/i.test(charTyped)) {
      // Set char and focus next input
      currentInputValueSetState(charTyped.toUpperCase())
      nextInputRef.focus()
      return
    }
  }

  return (
    <CustomSwipeableDialog
      buttons={[
        {
          variant: 'outlined',
          color: 'tertiary',
          onClick: () => {
            cleanCodeChars()
            onClose()
          },
          label: cancelText,
        },
        {
          color: 'primary',
          disabled: disableConfirmButton(
            firstChar,
            secondChar,
            thirdChar,
            fourthChar,
          ),
          label: confirmText,
          onClick: () => {
            const code = firstChar + secondChar + thirdChar + fourthChar

            onConfirm(code, history, partnerId)
            cleanCodeChars()
          },
          variant: 'contained',
        },
      ]}
      classes={{
        dialogPaper: componentClasses.dialogPaper,
      }}
      message={message}
      onClose={() => {
        cleanCodeChars()
        onClose()
      }}
      open={open}
      title={title}
    >
      <div className={componentClasses.inputsContainer}>
        <CustomTextField
          autoFocus={true}
          classes={{ input: componentClasses.inputCode }}
          inputRef={input => {
            firstCharRef = input
          }}
          maxLength={1}
          onKeyUp={e => {
            handleKeyUp(e, firstChar, setFirstChar, secondCharRef, firstCharRef)
          }}
          placeholder={'X'}
          required={false}
          value={firstChar}
        />
        <CustomTextField
          classes={{ input: componentClasses.inputCode }}
          inputRef={input => {
            secondCharRef = input
          }}
          maxLength={1}
          onKeyUp={e => {
            handleKeyUp(
              e,
              secondChar,
              setSecondChar,
              thirdCharRef,
              firstCharRef,
            )
          }}
          placeholder={'X'}
          required={false}
          value={secondChar}
        />
        <CustomTextField
          classes={{ input: componentClasses.inputCode }}
          inputRef={input => {
            thirdCharRef = input
          }}
          maxLength={1}
          onKeyUp={e => {
            handleKeyUp(
              e,
              thirdChar,
              setThirdChar,
              fourthCharRef,
              secondCharRef,
            )
          }}
          placeholder={'X'}
          required={false}
          value={thirdChar}
        />
        <CustomTextField
          classes={{ input: componentClasses.inputCode }}
          inputRef={input => {
            fourthCharRef = input
          }}
          maxLength={1}
          onKeyUp={e => {
            handleKeyUp(
              e,
              fourthChar,
              setFourthChar,
              fourthCharRef,
              thirdCharRef,
            )
          }}
          placeholder={'X'}
          required={false}
          value={fourthChar}
        />
      </div>
    </CustomSwipeableDialog>
  )
}
PickupCodeDialog.defaultProps = {
  open: false,
}
export default PickupCodeDialog
