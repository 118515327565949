import hooks from './hooks';

import { CustomAlertSnackbar } from '../common/custom-alert-snackbar';

export function DynAlertSnackbar() {
  const message = hooks.useMessage();
  const styleRules = hooks.useStyleRules();
  const buttons = hooks.useButtons();
  const onClose = hooks.useOnClose();
  const open = hooks.useOpen();
  return (
    <CustomAlertSnackbar
      buttons={buttons}
      message={message}
      open={open}
      onClose={onClose}
      styleRules={styleRules}
    />
  );
}
