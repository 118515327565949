import { clsx } from 'clsx';

import ClockIcon from '../icons/clock-icon';

import useStyles from './PartnerNextInterval.styles';

export const intervalTypes = {
  NEXT: 'NEXT',
  TODAY: 'TODAY',
};

function PartnerNextInterval({ nextInterval, classes = {} }) {
  const _classes = useStyles();
  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <ClockIcon />
      <div className={clsx(_classes.nextInterval, classes?.nextInterval)}>
        <span>{nextInterval}</span>
      </div>
    </div>
  );
}

export default PartnerNextInterval;
