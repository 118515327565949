import { useFormatMessage } from '../../../intl/intl';

export function useCargoLang({
  isCheckingCoverage,
  isCargoOrderList,
}: Props = {}) {
  const messageCategory = 'cargo';

  const formDialogTitleText = useFormatMessage({
    id: `${messageCategory}.form.title`,
  });

  const orderListFilterTitleText = useFormatMessage({
    id: `${messageCategory}.orderList.title`,
  });

  const coverageDialogTitleText = useFormatMessage({
    id: `${messageCategory}.coverage.title`,
  });

  const dialogTitleText = isCheckingCoverage
    ? coverageDialogTitleText
    : isCargoOrderList
    ? orderListFilterTitleText
    : formDialogTitleText;

  return {
    dialogTitleText,
  };
}

interface Props {
  isCheckingCoverage?: boolean;
  isCargoOrderList?: boolean;
}
