// External libs
import { groupBy } from 'lodash';
import { handleActions } from 'redux-actions';
// Consts
import PartnerOrderStateType from '../../constants/PartnerOrderStateType';
// Utils
import OrdersUtils from '../../utils/OrdersUtils';
// Actions
import OrdersKanbanActions from '../actions/ordersKanban.actions';

const initialState = {
  orders: {
    [PartnerOrderStateType.CANCEL_APP]: [],
    [PartnerOrderStateType.COOKING]: [],
    [PartnerOrderStateType.NEWORDER]: [],
    [PartnerOrderStateType.READYANDPACKED]: [],
  },
  index: 0,
};

export default handleActions(
  {
    [OrdersKanbanActions.setKanbanIndexAction]: (
      state,
      { payload: { index } },
    ) => setKanbanIndex(state, index),
    [OrdersKanbanActions.setOrdersAction]: (
      state,
      { payload: { orders, searchFieldText, tz } },
    ) => setOrders(state, orders, searchFieldText, tz),
  },
  initialState,
);

const setKanbanIndex = (state, index) => {
  return { ...state, index };
};

const setOrders = (state, orders, searchFieldText, tz) => {
  // Filter kanban orders
  const kanbanOrders = {};
  const groupOrders = groupBy(orders, order => order.partner_order_state);

  let newOrders = OrdersUtils.filterNew(orders);

  newOrders = OrdersUtils.filterBySearchText(newOrders, searchFieldText);

  OrdersUtils.sortByCreatedAt(newOrders);

  kanbanOrders[PartnerOrderStateType.NEWORDER] = newOrders;

  let cookingOrders = groupOrders[PartnerOrderStateType.COOKING] || [];

  cookingOrders = OrdersUtils.filterBySearchText(
    cookingOrders,
    searchFieldText,
  );

  OrdersUtils.sortByRestingCT(cookingOrders, tz);

  kanbanOrders[PartnerOrderStateType.COOKING] = cookingOrders;

  // +++ Orders ready and packed to final column (ToDeliver)
  let readyAndPackedOrders =
    groupOrders[PartnerOrderStateType.READYANDPACKED] || [];
  OrdersUtils.sortByCreatedAt(readyAndPackedOrders);
  OrdersUtils.sortByRTState(readyAndPackedOrders);
  readyAndPackedOrders = OrdersUtils.filterBySearchText(
    readyAndPackedOrders,
    searchFieldText,
  );
  kanbanOrders[PartnerOrderStateType.READYANDPACKED] = readyAndPackedOrders;
  // --- Orders ready and packed to final column (ToDeliver)

  let canceledOrders = groupOrders[PartnerOrderStateType.CANCEL_APP] || [];

  canceledOrders = OrdersUtils.filterBySearchText(
    canceledOrders,
    searchFieldText,
  );

  kanbanOrders[PartnerOrderStateType.CANCEL_APP] = canceledOrders;

  return { ...state, orders: kanbanOrders };
};
