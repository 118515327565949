import { createAsyncThunk } from '@reduxjs/toolkit';
import { orderRepository } from '../../repositories';
import { OM_ORDER_MODIFICATION_SLICE_NAME } from './core';

const sendOrderModification = createAsyncThunk(
  `${OM_ORDER_MODIFICATION_SLICE_NAME}/sendOrderModification`,
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await orderRepository.sendModification(payload);
      return response;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

const deactivateProductAndToppings = createAsyncThunk(
  `${OM_ORDER_MODIFICATION_SLICE_NAME}/deactivateProductAndToppings`,
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await orderRepository.deactivateItems(payload);
      return response;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

const removeProductsFromOrder = createAsyncThunk(
  `${OM_ORDER_MODIFICATION_SLICE_NAME}/removeProductsFromOrder`,
  async (
    { payload, orderId }: { payload: any; orderId: number },
    { rejectWithValue },
  ) => {
    try {
      const response = await orderRepository.removeProductsFromOrder(
        payload,
        orderId,
      );
      return response;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

export const orderModificationThunks = {
  sendOrderModification,
  deactivateProductAndToppings,
  removeProductsFromOrder,
} as const;
