import { createSlice } from '@reduxjs/toolkit';

import { orderMgmtStatus } from '../../../domain/models';
import { INITIAL_STATE, OM_ORDER_MODIFICATION_SLICE_NAME } from './core';
import { orderModificationThunks } from './orderModification.thunks';

export const orderMgmtDialogSlice = createSlice({
  name: OM_ORDER_MODIFICATION_SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    clear: () => ({ ...INITIAL_STATE }),
    close: state => {
      state.isOpen = false;
    },
    closeUndoChangesDialog: state => {
      state.undoChangesDialogIsOpen = false;
    },
    closeUndoSuggestionDialog: state => {
      state.undoSuggestionDialog.isOpen = false;
    },
    closeReplaceProductDialog: state => {
      state.replaceProductDialog.isOpen = false;
      state.replaceProductDialog.productId = '';
    },
    closeOrderMgmtAcceptOrderDialog: state => {
      state.isAcceptOrderDialogOpen = false;
    },
    closeAcceptRemoveChangesDialog: state => {
      state.acceptRemoveChangesDialogIsOpen = false;
    },
    open: (state, { payload: { isOpenBy, editType } }) => {
      state.isOpen = true;
      state.isOpenBy = isOpenBy;
      state.editType = editType;
    },
    disabledSendChanges: state => {
      state.canSendChangesByTimeout = false;
    },
    openUndoChangesDialog: state => {
      state.undoChangesDialogIsOpen = true;
    },
    openUndoSuggestionDialog: (state, { payload }) => {
      state.undoSuggestionDialog.isOpen = true;
      state.undoSuggestionDialog.productId = payload.productId;
      state.undoSuggestionDialog.toppingId = payload.toppingId;
    },
    openReplaceProductDialog: (state, { payload: { productId } }) => {
      state.replaceProductDialog.isOpen = true;
      state.replaceProductDialog.productId = productId;
    },
    openOrderMgmtAcceptOrderDialog: state => {
      state.isAcceptOrderDialogOpen = true;
    },
    openAcceptRemoveChangesDialog: state => {
      state.acceptRemoveChangesDialogIsOpen = true;
    },
    setOrder: (state, { payload: { orderId, subtotal, products } }: any) => ({
      ...state,
      orderId,
      subtotal,
      products,
    }),
    setSubstitutionProductItems: (state, { payload }) => {
      state.products = state.products.map(product => {
        if (product.id === payload.itemId) {
          product.suggestions = [...payload.suggestions];
        }
        return product;
      });
    },
    setSubstitutionToppingItems: (state, { payload }) => {
      state.products = state.products.map(product => {
        if (product.id !== payload.productId) return product;
        product.toppings?.map(topping => {
          if (topping.id === payload.itemId) {
            topping.suggestions = [...payload.suggestions];
            topping.categoryIndex = payload.toppingCategoryIndex;
            topping.categoryDescription = payload.toppingCategoryDescription;
          }
          return topping;
        });
        return product;
      });
    },
    setCurrentScreen: (state, { payload }) => {
      state.currentScreen = payload;
    },
    undoProductSuggestions: (state, { payload: productId }) => {
      state.products.map(product => {
        if (product.id === productId) product.suggestions = undefined;
        return product;
      });
    },
    undoToppingSuggestions: (state, { payload: { productId, toppingId } }) => {
      state.products.map(product => {
        if (product.id !== productId) return product;
        (product.toppings || []).map(topping => {
          if (topping.id === toppingId) topping.suggestions = undefined;
          return topping;
        });
        return product;
      });
    },
    uncheckProductToppings: (state, { payload }) => {
      state.products.map(product => {
        if (`${product.id}` !== `${payload}`) return product;
        product.isSelected = true;
        (product?.toppings || []).map(topping => (topping.isSelected = false));
      });
    },
    setCurrentOrderState: (state, { payload }) => {
      state.currentOrderState = payload;
    },
    setSelectProduct: (state, { payload: productId }) => {
      state.products.map(product => {
        if (product.id === productId) product.isSelected = !product.isSelected;
        return product;
      });
    },
    setSelectTopping: (state, { payload: { productId, toppingId } }) => {
      state.products.map(product => {
        if (product.id !== productId) return product;
        (product.toppings || []).map(topping => {
          if (topping.id === toppingId)
            topping.isSelected = !topping.isSelected;
          return topping;
        });
      });
    },
    setRemoveProduct: (state, { payload: productId }) => {
      state.products.map(product => {
        if (product.id === productId) product.isRemoved = true;
        return product;
      });
    },
    undoRemoveProductsChanges: state => {
      state.products.map(product => (product.isRemoved = false));
    },
  },
  extraReducers(builder) {
    builder
      .addCase(orderModificationThunks.sendOrderModification.pending, state => {
        state.status = orderMgmtStatus.Pending;
      })
      .addCase(
        orderModificationThunks.sendOrderModification.fulfilled,
        state => {
          state.status = orderMgmtStatus.Successful;
        },
      )
      .addCase(
        orderModificationThunks.sendOrderModification.rejected,
        (state, action) => {
          state.status = orderMgmtStatus.Rejected;
          state.errorCode = `${action?.payload}`;
        },
      )
      .addCase(
        orderModificationThunks.deactivateProductAndToppings.pending,
        state => {
          state.status = orderMgmtStatus.Pending;
        },
      )
      .addCase(
        orderModificationThunks.deactivateProductAndToppings.fulfilled,
        state => {
          state.status = orderMgmtStatus.Successful;
        },
      )
      .addCase(
        orderModificationThunks.deactivateProductAndToppings.rejected,
        (state, action) => {
          state.status = orderMgmtStatus.Rejected;
          state.errorCode = `${action?.payload}`;
        },
      )
      .addCase(
        orderModificationThunks.removeProductsFromOrder.pending,
        state => {
          state.status = orderMgmtStatus.Pending;
        },
      )
      .addCase(
        orderModificationThunks.removeProductsFromOrder.fulfilled,
        state => {
          state.status = orderMgmtStatus.Successful;
        },
      )
      .addCase(
        orderModificationThunks.removeProductsFromOrder.rejected,
        (state, action) => {
          state.status = orderMgmtStatus.Rejected;
          state.errorCode = `${action?.payload}`;
        },
      );
  },
});

export const orderMgmtDialogActions = orderMgmtDialogSlice.actions;
export const orderMgmtModificationReducer = orderMgmtDialogSlice.reducer;
