import { useEffect } from 'react';

import {
  COVERAGE_STATUS,
  CargoRequestRtCase,
} from '../../../../../business/cargo';
import {
  COVERAGE_VALUES,
  CargoAnalytics,
} from '../../../../../business/cargo/analytics';

import type { coverageValues } from '../../../../../business/cargo/analytics';

export function useCoverage() {
  const { newOrder, coverageStatus, partnerId, stores } = CargoRequestRtCase();

  const _coverageEmpty = coverageStatus === COVERAGE_STATUS[''];

  const _coverageValue: { [key: string]: coverageValues } =
    {
      [COVERAGE_STATUS.hasCoverage]: COVERAGE_VALUES.inside,
      [COVERAGE_STATUS.noCoverage]: COVERAGE_VALUES.out,
      [COVERAGE_STATUS.notAvailable]: COVERAGE_VALUES.no_rt,
    } || '';

  const hasCoverage = coverageStatus === COVERAGE_STATUS.hasCoverage;

  useEffect(() => {
    if (!_coverageEmpty) {
      CargoAnalytics.whenVerifyCoverage(
        partnerId,
        _coverageValue[coverageStatus],
      );
    }
  }, [coverageStatus]);

  return {
    deliveryAddress: newOrder.deliveryAddress,
    deliveryCost: newOrder?.shippingCost ?? 0,
    hasCoverage,
    selectedStore: newOrder.store,
    stores,
    coverageStatus,
  };
}
