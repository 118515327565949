import { makeStyles } from '@material-ui/core';

import Colors from '../../constants/Colors';
import { neutral } from '../../shared/styles/colors';

export const deactivatedProductsDialogStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    top: '0.25rem',
    color: 'white',
    right: '0.5rem',
  },
  cancelButton: {
    backgroundColor: neutral.white,
    color: neutral.D16,
    borderRadius: '.5rem',
    height: '2.625rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: neutral.white,
    },
  },
  title: {
    fontSize: '1.5rem',
    whiteSpace: 'normal',
    paddingTop: '9.2rem',
  },
  readyButton: {
    height: '2.625rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.PRIMARY_COLOR,
    },
  },
  body: {
    flex: 'none',
    color: neutral.D20,
    fontSize: '1rem',
    height: '100%',
    lineHeight: 'normal',
    padding: '1rem 2rem',
  },
  headerRoot: {
    gridTemplateColumns: 'auto',
    [theme.breakpoints.up('md')]: {
      padding: '1.5rem',
    },
  },
  footerRoot: {
    columnGap: '0.5rem',
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      width: 'auto',
      maxWidth: 950,
    },
  },
  spanNumberOfShutdowns: {
    fontWeight: 'bolder',
    color: Colors.DANGER_COLOR,
  },
}));
