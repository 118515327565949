import { useState } from 'react';

const MAX_COUNTS_PER_WEEK = 24;
const MAX_COUNTS_PER_DAY = 4;
const CACHE_KEY = 'MAX_COUNTS_TO_HIGH_DEMAND';
const EVENT_DATES_INIT: EventDates = {
  expires: '',
  stores: {},
};

export function useMaxCounts() {
  const [eventDates, setEventDates] = useState(validEventDatesCache());

  const setNewCountByStore = ({ storeId }: StoreIdProp) => {
    setEventDates(saveEventDateByStore({ storeId, eventDates }));
  };

  const getFlagsForMaxCountsByStore = ({ storeId }: StoreIdProp) => {
    let [perWeek, perDay] = [0, 0];
    const dates = eventDates.stores[storeId];
    if (dates?.length !== undefined) {
      const currentDate = getCurrenLocalDate();
      perWeek = dates.length;
      perDay = dates.filter(d => d === currentDate)?.length ?? 0;
    }
    return {
      hasMaxPerWeek: perWeek >= MAX_COUNTS_PER_WEEK,
      hasMaxPerDay: perDay >= MAX_COUNTS_PER_DAY,
    };
  };

  return {
    setNewCountByStore,
    getFlagsForMaxCountsByStore,
  };
}

function readEventDates(): EventDates | null {
  const data = window.localStorage.getItem(CACHE_KEY);
  return !!data ? JSON.parse(data) : null;
}

function saveEventDates(data: EventDates) {
  window.localStorage.setItem(CACHE_KEY, JSON.stringify(data));
  return data;
}

function validEventDatesCache(): EventDates {
  let eventDates: EventDates =
    readEventDates() ?? JSON.parse(JSON.stringify(EVENT_DATES_INIT));
  const weekendDate = getLocalWeekendDate();
  if (weekendDate > eventDates.expires) {
    eventDates.expires = weekendDate;
    eventDates.stores = {};
  }
  return saveEventDates(eventDates);
}

function saveEventDateByStore(params: SaveEventDateByStoreParams): EventDates {
  const { storeId, eventDates } = params;
  const currentDate = getCurrenLocalDate();
  let data = JSON.parse(JSON.stringify(eventDates));
  if (!data.stores[storeId]) data.stores[storeId] = [currentDate];
  else data.stores[storeId].push(currentDate);
  return saveEventDates(data);
}

function getCurrenLocalDate() {
  // format of French (Canada) 'fr-CA' is equal to YYYY-MM-DD
  return new Date().toLocaleDateString('fr-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}

function getLocalWeekendDate() {
  const DAYS_OF_WEEK = 7;
  const currentDate = new Date(getCurrenLocalDate() + 'T00:00');
  const daysUntilSunday = DAYS_OF_WEEK - currentDate.getDay();
  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + daysUntilSunday,
  ).toLocaleDateString('fr-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}

interface StoreIdProp {
  storeId: number;
}

interface EventDates {
  expires: string; // YYYY-MM-DD
  stores: {
    [p: number]: string[]; // 'YYYY-MM-DD'[]
  };
}

interface SaveEventDateByStoreParams extends StoreIdProp {
  eventDates: EventDates;
}
