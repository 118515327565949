// React
import React from 'react';
// Material UI
import { useMediaQuery, useTheme } from '@material-ui/core';
// Styles
import useStyles from './MenuAdvancedFilterToolbar.styles';
// Components
import CustomAdvancedFilterToolbar from './../common/custom-advanced-filter-toolbar';
import MenuFilterDrawer from './../menu-filter-drawer';

const MenuAdvancedFilterToolbar = ({
  classes,
  onClick,
  selectedFilterOptions,
}) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const componentClasses = useStyles();

  return (
    <div>
      <CustomAdvancedFilterToolbar
        classes={{ root: componentClasses.advancedFilterToolbarRoot }}
        onClick={onClick}
        selectedFilterOptions={selectedFilterOptions}
        showAdvancedToolBar={!isMdUp}
      />
      <MenuFilterDrawer />
    </div>
  );
};

MenuAdvancedFilterToolbar.defaultProps = {
  classes: {},
  selectedFilterOptions: [],
};

export default MenuAdvancedFilterToolbar;
