// External libs
import { handleActions } from 'redux-actions';
import deleteSpecialDayDialogActions from '../actions/deleteSpecialDayDialog.actions';

const initialState = {
  open: false,
};
export default handleActions(
  {
    [deleteSpecialDayDialogActions.openDeleteSpecialDayDialogAction]: (
      state,
      { payload: { selectedDayId } },
    ) => toggleDialog(state, true, selectedDayId),
    [deleteSpecialDayDialogActions.closeDeleteSpecialDayDialogAction]: state =>
      toggleDialog(state, false),
  },
  initialState,
);
const toggleDialog = (state, open, selectedDayId) => {
  return {
    ...state,
    selectedDayId,
    open,
  };
};
