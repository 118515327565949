import CustomAlert from '../common/custom-alert';
import OrderDetailDialogMainSectionItem from '../order-detail-dialog-main-section-item';

import { useOrderDetailDialogMarketplaceBox } from './hooks';

import useStyles from './OrderDetailDialogMarketplaceBox.styles';

export function OrderDetailDialogMarketplaceBox() {
  const { lang } = useOrderDetailDialogMarketplaceBox();
  const _classes = useStyles();
  return (
    <OrderDetailDialogMainSectionItem
      classes={{ body: _classes.root }}
      title={lang.title}
    >
      <div className={_classes.description}>{lang.description}</div>
      <CustomAlert
        classes={{
          root: _classes.customAlertPaymentMethodRoot,
        }}
        text={lang.paymentMethodMsg}
      />
    </OrderDetailDialogMainSectionItem>
  );
}
