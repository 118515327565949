//React
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// Actions
import partnersActions from '../../redux/actions/partners-actions';
// Components
import OpenPartnerStateDialog from './OpenPartnerStateDialog';

const getListDescription = (formatMessage, totalStores) => {
  const id =
    totalStores > 1
      ? 'openPartnerStateDialog.listDescription.plural'
      : 'openPartnerStateDialog.listDescription';
  return formatMessage({
    id,
  });
};

const mapStateToProps = (
  {
    partnerReducer: {
      isOpenPartnerStateDialogOpened,
      profile: { stores, id: partnerId },
    },
  },
  { intl: { formatMessage } },
) => {
  const totalStores = (stores && stores.length) || 0;
  const confirmText = formatMessage({
    id: 'openPartnerStateDialog.confirmText',
  });
  const deactivateStoreText = formatMessage({
    id: 'openPartnerStateDialog.deactivateStore',
  });

  const listDescription = getListDescription(formatMessage, totalStores);

  const title = formatMessage({ id: 'openPartnerStateDialog.title' });

  return {
    confirmText,
    deactivateStoreText,
    listDescription,
    open: isOpenPartnerStateDialogOpened,
    title,
    totalStores,
    partnerId,
  };
};

const mapDispatchToProps = {
  onClickDeactivate: partnersActions.consumePartnerStateValidate,
  onClose: partnersActions.closeOpenPartnerStateDialog,
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(OpenPartnerStateDialog),
);
