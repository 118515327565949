import { orderMgmtState } from '../../../business/order-mgmt';
import { useFormatMessage } from '../../../intl/intl';

export function useOrderMgmtLang({
  orderId,
  user,
}: { orderId?: number; user?: string } = {}) {
  const messageCategory = 'orderManagement';
  const subcategoryMessageMainDialog = 'mainDialog';

  const title = useFormatMessage(
    { id: `${messageCategory}.title` },
    { id: orderId },
  );
  const disclaimerReplaceText = useFormatMessage({
    id: `${messageCategory}.disclaimerReplaceText`,
  });
  const totalLabel = useFormatMessage({ id: `${messageCategory}.totalLabel` });
  const rejectButtonText = useFormatMessage({
    id: `${messageCategory}.rejectButtonText`,
  });
  const undoChangesButtonText = useFormatMessage({
    id: `${messageCategory}.undoChangesButtonText`,
  });
  const continueButtonText = useFormatMessage({
    id: `${messageCategory}.continueButtonText`,
  });

  const replaceProductText = useFormatMessage({
    id: `${messageCategory}.replaceProductText`,
  });

  // const deleteProductText = useFormatMessage({
  //   id: `${messageCategory}.deleteProductText`,
  // });

  const replaceToppingText = useFormatMessage({
    id: `${messageCategory}.replaceToppingText`,
  });

  const deleteToppingText = useFormatMessage({
    id: `${messageCategory}.deleteToppingText`,
  });

  const orderToBeConfirmText = useFormatMessage({
    id: `${messageCategory}.toBeConfirmedText`,
  });

  const subtotalOrderText = useFormatMessage({
    id: `${messageCategory}.totalLabel`,
  });

  const userCanChooseText = useFormatMessage({
    id: `${messageCategory}.userCanChooseText`,
  });

  const soldOutProductText = useFormatMessage({
    id: `${messageCategory}.soldOutProductText`,
  });

  const soldOutToppingText = useFormatMessage({
    id: `${messageCategory}.soldOutToppingText`,
  });

  const orderSummaryText = useFormatMessage({
    id: `${messageCategory}.orderSummaryText`,
  });

  const replaceDisclaimerText = useFormatMessage({
    id: `${messageCategory}.replaceDisclaimerText`,
  });

  const insteadOfText = useFormatMessage({
    id: `${messageCategory}.insteadOfText`,
  });

  const itemDisclaimerText1 = useFormatMessage({
    id: `${messageCategory}.itemDisclaimerText1`,
  });

  const itemDisclaimerText2 = useFormatMessage({
    id: `${messageCategory}.itemDisclaimerText2`,
  });

  const itemDisclaimerText3 = useFormatMessage({
    id: `${messageCategory}.itemDisclaimerText3`,
  });

  const undoText = useFormatMessage({
    id: `${messageCategory}.undoText`,
  });

  const finalPriceMayVaryText = useFormatMessage({
    id: `${messageCategory}.finalPriceMayVaryText`,
  });

  const sendChangesText = useFormatMessage({
    id: `${messageCategory}.sendChangesText`,
  });

  const anyToppingDoubtsText = useFormatMessage({
    id: `${messageCategory}.anyToppingDoubtsText`,
  });
  const rejectedToppingMaxLimit = useFormatMessage({
    id: `${messageCategory}.topping.max.limit`,
  });

  const editCountdownText = useFormatMessage({
    id: `${messageCategory}.editCountdownTitle`,
  });

  const infoUserDescriptionText = useFormatMessage(
    {
      id: `${messageCategory}.${subcategoryMessageMainDialog}.infoUserDescription`,
    },
    { user },
  );

  const infoUserTitleText = useFormatMessage({
    id: `${messageCategory}.${subcategoryMessageMainDialog}.infoUserTitle`,
  });

  const optionOneDescriptionText = useFormatMessage({
    id: `${messageCategory}.${subcategoryMessageMainDialog}.optionOneDescription`,
  });

  const optionOneTitleText = useFormatMessage({
    id: `${messageCategory}.${subcategoryMessageMainDialog}.optionOneTitle`,
  });

  const optionTwoDescriptionText = useFormatMessage({
    id: `${messageCategory}.${subcategoryMessageMainDialog}.optionTwoDescription`,
  });

  const optionTwoTitleText = useFormatMessage({
    id: `${messageCategory}.${subcategoryMessageMainDialog}.optionTwoTitle`,
  });

  const optionThreeDescriptionText = useFormatMessage({
    id: `${messageCategory}.${subcategoryMessageMainDialog}.optionThreeDescription`,
  });

  const optionThreeTitleText = useFormatMessage({
    id: `${messageCategory}.${subcategoryMessageMainDialog}.optionThreeTitle`,
  });

  const optionsTexts = [
    {
      title: optionOneTitleText,
      description: optionOneDescriptionText,
      option: orderMgmtState.DeactivateItems,
    },
    {
      title: optionTwoTitleText,
      description: optionTwoDescriptionText,
      option: orderMgmtState.SendUserApproval,
    },
    {
      title: optionThreeTitleText,
      description: optionThreeDescriptionText,
      option: orderMgmtState.NoUserResponse,
    },
  ];

  const deactivatedItemsText = useFormatMessage({
    id: `${messageCategory}.${subcategoryMessageMainDialog}.deactivatedItems`,
  });

  const callUserText = useFormatMessage({
    id: `${messageCategory}.${subcategoryMessageMainDialog}.callUserText`,
  });

  const startChatText = useFormatMessage({
    id: `${messageCategory}.${subcategoryMessageMainDialog}.startChat`,
  });

  const tooltipOptionDisclaimerText = useFormatMessage({
    id: `${messageCategory}.${subcategoryMessageMainDialog}.tooltipOptionDisclaimer`,
  });

  return {
    title,
    disclaimerReplaceText,
    totalLabel,
    rejectButtonText,
    undoChangesButtonText,
    continueButtonText,
    replaceProductText,
    replaceToppingText,
    deleteToppingText,
    orderToBeConfirmText,
    subtotalOrderText,
    userCanChooseText,
    soldOutProductText,
    soldOutToppingText,
    orderSummaryText,
    insteadOfText,
    itemDisclaimerText1,
    itemDisclaimerText2,
    itemDisclaimerText3,
    undoText,
    finalPriceMayVaryText,
    sendChangesText,
    anyToppingDoubtsText,
    rejectedToppingMaxLimit,
    infoUserDescriptionText,
    infoUserTitleText,
    optionsTexts,
    deactivatedItemsText,
    replaceDisclaimerText,
    callUserText,
    editCountdownText,
    startChatText,
    tooltipOptionDisclaimerText,
  };
}
