import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../../shared/styles/colors';

export default makeStyles({
  dialog: {
    width: '100%',
    maxWidth: '48rem',
  },
  title: {
    padding: '0 1rem',
    textAlign: 'center',
  },
  p2: {
    margin: '1rem 0',
  },
  tableRowOnHead: {
    '& th': {
      backgroundColor: neutral['10'],
    },
  },
  tableRowOnBody: {
    '&:nth-of-type(even)': {
      backgroundColor: neutral['10'],
    },
  },
});
