import { clsx } from 'clsx';
import { IconButton } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';

import useStyles from './CustomMinusButton.styles';

export function CustomMinusButton({ disabled, onClick, classes = {} }) {
  const _classes = useStyles({ disabled });
  return (
    <IconButton
      aria-label='Minus'
      className={clsx(classes?.button)}
      disabled={disabled}
      onClick={onClick}
    >
      <RemoveIcon
        className={clsx(
          _classes.icon,
          classes.icon,
          disabled && classes?.iconDisabled,
        )}
      />
    </IconButton>
  );
}
