// Redux
import { createAction } from '@reduxjs/toolkit';

const closeAction = createAction('ItemsDeactivationStockOutIssueDialog/close');

const clearAction = createAction('ItemsDeactivationStockOutIssueDialog/clear');

const openAction = createAction<{ orderId: number }>(
  'ItemsDeactivationStockOutIssueDialog/open',
);

const setSelectedProductsAction = createAction<{ selectedProducts: number[] }>(
  'ItemsDeactivationStockOutIssueDialog/setSelectedProducts',
);

const setSelectedToppingsAction = createAction<{ selectedToppings: number[] }>(
  'ItemsDeactivationStockOutIssueDialog/setSelectedToppings',
);

const clear = () => (dispatch: any) => {
  dispatch(clearAction());
};

const close = () => (dispatch: any) => {
  dispatch(closeAction());
};

const open = (orderId: number) => (dispatch: any) => {
  dispatch(openAction({ orderId }));
};

const setSelectedProducts = (selectedProducts: number[]) => (dispatch: any) =>
  dispatch(setSelectedProductsAction({ selectedProducts }));

const setSelectedToppings = (selectedToppings: number[]) => (dispatch: any) =>
  dispatch(setSelectedToppingsAction({ selectedToppings }));

const actions = {
  clear,
  clearAction,
  close,
  closeAction,
  open,
  openAction,
  setSelectedProducts,
  setSelectedProductsAction,
  setSelectedToppings,
  setSelectedToppingsAction,
};

export default actions;
