import { Chip, Tooltip } from '@material-ui/core';

import { useSaturationChipLang } from './useSaturationChipLang';
import makeClasses from './SaturationChip.style';

import type { SaturationStateProp } from '../../shared/models';

export function SaturationChip(props: SaturationStateProp) {
  const { variant = 'regular' } = props;
  const classes = makeClasses({ variant });
  const text = useSaturationChipLang({ variant });
  return (
    <Tooltip
      arrow
      placement='bottom'
      title={text.tooltip}
      classes={{ tooltip: classes.tooltip }}
    >
      <Chip
        component='mark'
        size='small'
        label={text.legend}
        classes={{ root: classes.chip }}
      />
    </Tooltip>
  );
}
