// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  body: {
    height: '100%',
    paddingTop: 24,
    [theme.breakpoints.up('md')]: {
      padding: '0px',
      paddingBottom: '40px',
    },
  },
  headerRoot: {
    [theme.breakpoints.up('md')]: {
      padding: 12,
    },
  },
  orderRejectionReasonsRoot: {
    borderTop: `1px solid ${neutral.D10}`,
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      borderTop: `none`,
    },
  },
  orderRejectionReasonsTitle: {
    paddingLeft: '16px',
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
      margin: '40px 0px 40px 0px',
    },
  },
  paper: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      bottom: 0,
      height: '100vh',
      padding: '1rem',
      position: 'absolute',
      right: 0,
      top: 0,
      width: '44.625rem',
      overflowY: 'hidden',
    },
  },
  root: {},
}));
