import GoogleMapReact from 'google-map-react';
import { Box, MenuItem, TextField, Typography } from '@material-ui/core';

import {
  CoverageStatus,
  MAP_CENTER,
  MAP_ZOOM,
} from '../../../../business/cargo';
import { Disclaimer } from './Disclaimer';
import { useCoverage, useRequestLang } from './hooks';
import { HouseIcon } from '../../../../components/icons/house-icon/HouseIcon';
import CustomMarker from '../../../../components/common/custom-marker/CustomMarker';
import { RestaurantIcon } from '../../../../components/icons/restaurant-icon';
import { InputPlacesAutocomplete } from '../../../../components/input-places-autocomplete';

import useRequestStyles from './request.styles';

export function Coverage() {
  const styles = useRequestStyles();
  const {
    pickupPointLabelText,
    deliveryAddressLabelText,
    coverageMapDisclaimerText,
  } = useRequestLang();
  const {
    deliveryAddress,
    selectedStore,
    stores,
    coverageStatus,
    deliveryCost,
  } = useCoverage();

  return (
    <>
      <Disclaimer
        coverageStatus={coverageStatus as CoverageStatus}
        deliveryCost={deliveryCost}
      />
      <Box
        display='flex'
        flexDirection='column'
        gridGap='0.5rem'
        margin='1.25rem 0'
      >
        <TextField
          classes={{ root: styles.disabled }}
          defaultValue={selectedStore ? selectedStore.id : ''}
          label={pickupPointLabelText}
          variant='outlined'
          disabled
          select
        >
          {stores.map(option => (
            <MenuItem key={`s-${option.id}`} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        {selectedStore?.address && (
          <Typography className={styles.addressLabel}>
            {selectedStore?.address}
          </Typography>
        )}
        <InputPlacesAutocomplete
          defaultValue={deliveryAddress?.completeAddress}
          onChange={undefined}
          label={deliveryAddressLabelText}
          variant='outlined'
          disabled
        />
        <Box width='100%' height='18.75rem' margin='1rem 0'>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
            defaultZoom={MAP_ZOOM}
            defaultCenter={{
              lat: selectedStore?.mapPoint.lat ?? MAP_CENTER.lat,
              lng: selectedStore?.mapPoint.lng ?? MAP_CENTER.lng,
            }}
            center={{
              lat: deliveryAddress?.coordinates.lat,
              lng: deliveryAddress?.coordinates.lng,
            }}
          >
            <CustomMarker
              className={styles.imgMarkerContainer}
              lat={selectedStore?.mapPoint.lat}
              lng={selectedStore?.mapPoint.lng}
            >
              <RestaurantIcon className={styles.restaurantIcon} />
            </CustomMarker>
            <CustomMarker
              className={styles.imgMarkerContainer}
              lat={deliveryAddress?.coordinates?.lat}
              lng={deliveryAddress?.coordinates?.lng}
            >
              <HouseIcon />
            </CustomMarker>
          </GoogleMapReact>
          <Typography component='p' className={styles.mapDisclaimer}>
            {coverageMapDisclaimerText}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
