// Material UI
import { makeStyles } from '@material-ui/core';
import Colors from '../../../../constants/Colors';
import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(theme => ({
  commentWrapper: {
    marginBottom: 20,
    marginLeft: 15,
  },
  notes: {
    fontSize: 13,
    color: neutral.D15,
    marginBottom: 3,
  },
  comments: {
    fontSize: 14,
    color: Colors.SECONDARY_COLOR,
  },
  addValue: {
    fontSize: 13,
    fontFamily: 'Poppins Bold',
    color: Colors.PRIMARY_COLOR,
    marginTop: 14,
    textTransform: 'capitalize',
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));
