import { useSelector } from 'react-redux';

import selectors from '../selectors';
import { selectors as supportReasonsDialogSelectors } from '../../../redux/support-reasons-dialog';

const useOpen = () => {
  return useSelector(supportReasonsDialogSelectors.selectOpen);
};

const useDescription = () => {
  return useSelector(supportReasonsDialogSelectors.selectDescription);
};

const useSupportSuggestions = () => {
  return useSelector(selectors.selectOptions);
};

const useTitle = () => {
  return useSelector(selectors.selectTitle);
};

const hooks = {
  useOpen,
  useDescription,
  useSupportSuggestions,
  useTitle,
};

export default hooks;
