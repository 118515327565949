import { makeStyles } from '@material-ui/core';
import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(() => ({
  checkCodeContainer: {
    borderRadius: '62.5rem',
    background: neutral.D10
  },
  checkCode: {
    color: neutral.D18,
    fontSize: '0.75rem',
    fontWeight: 600,
  },
}))