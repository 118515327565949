// React
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
// Actions
import AppActions from './../../redux/actions/app-actions'
import MenuActions from '../../redux/actions/menu.actions'
// Components
import MenuAdvancedFilterToolbar from './MenuAdvancedFilterToolbar'

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = {
  onClick: MenuActions.openMenuFilterDrawer,
  openSnackBarError: AppActions.openSnackBarError,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(MenuAdvancedFilterToolbar),
)
