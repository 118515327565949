import { subscribeEvent, publishEvent, unsubscribeEvent } from '../../libs';

function mainDialog() {
  const EVENT_NAME = 'CARGO_MAIN_DIALOG';
  let theListener: ListenerFN = _ => undefined;

  return {
    dispatch: () => publishEvent(EVENT_NAME),
    remove: () => unsubscribeEvent(EVENT_NAME, theListener),
    add: (lt: ListenerFN) => {
      theListener = lt;
      subscribeEvent(EVENT_NAME, theListener);
    },
  };
}

export const cargoDialog = {
  callMainDialog: mainDialog(),
};

type ListenerFN = (event: EventCargo) => void;

type EventCargo = CustomEvent;
