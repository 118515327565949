import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from './styles';
import { VARIANTS } from './constants';
import type { CustomDialog02Props } from './models';

export function CustomDialog02(props: CustomDialog02Props) {
  const {
    onCloseByExitIcon = () => undefined,
    variant = 'error',
    title = 'WITHOUT TITLE',
    srcImage = null,
    hideImage = false,
    children = null,
    customActions = null,
    primaryBtnText = null,
    primaryBtnOnClick = () => undefined,
    primaryBtnLoading = false,
    secondaryBtnText = null,
    secondaryBtnOnClick = () => undefined,
    secondaryBtnLoading = false,
    classes: dialogClasses = {},
    ...extraDialogProps
  } = props;

  const styles = useStyles({ variant });

  return (
    <Dialog
      fullWidth={true}
      classes={{ paper: styles.dialog, ...dialogClasses }}
      {...extraDialogProps}
    >
      <Box component='header' className={styles.header}>
        <Box component='nav' className={styles.headerTop}>
          <IconButton onClick={onCloseByExitIcon}>
            <CloseIcon fontSize='small' />
          </IconButton>
        </Box>
        {!hideImage && (
          <img
            width='100%'
            height='100%'
            alt='title image on dialog'
            className={styles.headerImage}
            src={srcImage ?? VARIANTS[variant].image}
          />
        )}
        <Typography component='h1' className={styles.title}>
          {title}
        </Typography>
      </Box>

      <Box component='main' className={styles.main}>
        {children}
      </Box>

      {customActions}
      {!Boolean(customActions) && (
        <DialogActions className={styles.actions}>
          {Boolean(secondaryBtnText) && (
            <Button
              color={variant === 'success' ? 'primary' : 'secondary'}
              variant='text'
              onClick={secondaryBtnOnClick}
              disabled={secondaryBtnLoading}
              classes={{ root: styles.actionsSecondaryBtn }}
              endIcon={<ProgressToBtn show={secondaryBtnLoading} />}
            >
              {secondaryBtnText}
            </Button>
          )}
          {Boolean(primaryBtnText) && (
            <Button
              color={variant === 'success' ? 'primary' : 'secondary'}
              variant='contained'
              onClick={primaryBtnOnClick}
              disabled={primaryBtnLoading}
              classes={{ root: styles.actionsPrimaryButton }}
              endIcon={<ProgressToBtn show={primaryBtnLoading} />}
            >
              {primaryBtnText}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}

function ProgressToBtn({ show = false }: { show: boolean }) {
  if (!show) return null;
  return <CircularProgress size={16} />;
}
