import { MainDrawer } from '../../../../shared/components';
import { ChangeDemandState, ListOfStores } from './components';

import { useHighDemandDialog } from './hooks';

import makeClasses from './HighDemandDialog.style';
import { EmptyStoreStatus } from './components/empty-store-status';
import { PartnerId } from '../../../../business/shared/domain';

export function HighDemandDialog(props: Props) {
  const { open, isSingleStore, onClose, partnerId } = props;
  const {
    texts,
    goBackBtn,
    primaryBtn,
    isLoadingStoreList,
    goToDemandView,
    storeSelected,
    severityTimes,
    processStep,
    onSelectDemandState,
    isActiveStore,
    activeStores,
    onHoldStores,
    inactiveStores,
    suspededStores,
    handleNavigate,
  } = useHighDemandDialog({
    onCloseDialog: onClose,
    isSingleStore,
    partnerId,
  });
  const classes = makeClasses();

  return (
    <MainDrawer
      classes={{ paper: classes.paper }}
      open={open}
      anchor='right'
      onClose={onClose}
      disableBackdropClick
      onGoBack={goBackBtn.onClick}
      headerTitle={texts.title}
      aria-labelledby='high-demand-dialog'
      primaryBtn={{
        text: texts.primaryBtn,
        onClick: primaryBtn.onClick,
        loading: primaryBtn.isLoading,
      }}
    >
      {processStep.storeList && (
        <ListOfStores
          onClickStore={goToDemandView}
          isLoading={isLoadingStoreList}
          activeStores={activeStores}
          onHoldStores={onHoldStores}
          inactiveStores={inactiveStores}
          suspededStores={suspededStores}
          navigate={handleNavigate}
        />
      )}
      {processStep.statusChange && (
        <ChangeDemandState
          store={storeSelected}
          severityTimes={severityTimes}
          onSelect={onSelectDemandState}
          isActiveStore={isActiveStore}
          partnerId={partnerId}
          onClose={onClose}
        />
      )}
      {processStep.emptyStoreStatus && (
        <EmptyStoreStatus
          store={storeSelected}
          isActiveStore={isActiveStore}
          partnerId={partnerId}
          onClose={onClose}
        />
      )}
    </MainDrawer>
  );
}

interface Props {
  open: boolean;
  onClose: () => void;
  isSingleStore: boolean;
  partnerId: PartnerId;
}
