import { useFormatMessage } from '../../../../../../intl/intl';

export function useChangeDemandStateTexts(props: Props) {
  const { hasMaxUsesPerDay = false, hasMaxUsesPerWeek = false } = props;

  const demandTitle = 'highDemand.changeState.demand.title';
  const mergeHighTitle = 'highDemand.changeState.demand.mergeHighs.title';
  const ttlTitle = 'highDemand.changeState.ttl.title';

  const maxUsePerDay = 'highDemand.error.maxUse.perDay.msg';
  const maxUsePerWeek = 'highDemand.error.maxUse.perWeek.msg';
  const maxUsesMsg = hasMaxUsesPerWeek
    ? maxUsePerWeek
    : hasMaxUsesPerDay
    ? maxUsePerDay
    : '';
  const [maxUsesMsgP1, maxUsesMsgP2] = [
    maxUsesMsg ? `${maxUsesMsg}.p1` : '',
    maxUsesMsg ? `${maxUsesMsg}.p2` : '',
  ];

  return {
    alerts: {
      maxUse: {
        msg: {
          p1: useFormatMessage({ id: maxUsesMsgP1 }),
          p2: useFormatMessage({ id: maxUsesMsgP2 }),
        },
      },
    },
    demand: {
      title: useFormatMessage({ id: demandTitle }),
      mergeSuperHigh: {
        title: useFormatMessage({ id: mergeHighTitle }),
      },
    },
    ttl: {
      title: useFormatMessage({ id: ttlTitle }),
    },
    extendSchedule: useFormatMessage({
      id: 'extendClosingHoursDialog.extendSchedule',
    }),
    pauseStore: useFormatMessage({ id: 'extendClosingHoursDialog.pauseStore' }),
  };
}

interface Props {
  hasMaxUsesPerDay: boolean;
  hasMaxUsesPerWeek: boolean;
}
