import { useFormatMessage } from '../../../intl/intl';
import { CustomRadioGroup } from '../custom-radio-group';
import CustomDrawer from './../custom-drawer/CustomDrawer';
import CustomButton from './../buttons/custom-button/CustomButton';
import CustomCheckBoxGroup from './../custom-check-box-group/CustomCheckBoxGroup';

import useStyles from './CustomItemsFilterDrawer.styles';

function CustomItemsFilterDrawer(props) {
  const {
    children,
    clearFilterButtonText,
    confirmButtonText,
    drawerTitle,
    filterTitle,
    items = [],
    listTitle,
    onApplyFilter,
    onClearFilter,
    onClose,
    onItemsSelectionChange,
    open,
    searchFieldPlaceHolder,
    selectedItems = [],
    showAllOption,
    unique = false,
  } = props;

  const _drawerTitle = useFormatMessage({ id: 'global.advancedFilter' });

  const _confirmButtonText = useFormatMessage({
    id: 'customItemsFilterDrawer.confirmButtonText',
  });

  const _clearFilterButtonText = useFormatMessage({
    id: 'customItemsFilterDrawer.clearFilterButtonText',
  });

  const _filterTitle = useFormatMessage({
    id: 'customItemsFilterDrawer.filterTitle',
  });

  const _listTitle = useFormatMessage({
    id: 'customItemsFilterDrawer.listTitle',
  });

  const _searchFieldPlaceHolder = useFormatMessage({
    id: 'customItemsFilterDrawer.searchFieldPlaceHolder',
  });

  const _classes = useStyles();

  const onChangeCheckBoxGroup = selectedItemIds => {
    onItemsSelectionChange(selectedItemIds);
  };

  const onChangeRadioGroup = selectedItemId => {
    onItemsSelectionChange([selectedItemId]);
  };

  return (
    <CustomDrawer
      open={open}
      onClose={onClose}
      title={drawerTitle || _drawerTitle}
    >
      <div className={_classes.drawerContent}>
        <div className={_classes.drawerBody}>
          {!unique && (
            <CustomCheckBoxGroup
              filterTitle={filterTitle || _filterTitle}
              items={items}
              listTitle={listTitle || _listTitle}
              onChange={onChangeCheckBoxGroup}
              searchFieldPlaceHolder={
                searchFieldPlaceHolder || _searchFieldPlaceHolder
              }
              selectedItems={selectedItems}
              showAllOption={showAllOption}
            />
          )}
          {unique && (
            <CustomRadioGroup
              filterTitle={filterTitle || _filterTitle}
              items={items}
              listTitle={listTitle || _listTitle}
              onChange={onChangeRadioGroup}
              searchFieldPlaceHolder={
                searchFieldPlaceHolder || _searchFieldPlaceHolder
              }
              selectedItem={selectedItems[0]}
            />
          )}
        </div>
        {children}
        <div className={_classes.actionsContainer}>
          <CustomButton
            classes={{ submitButton: _classes.clearFilterButton }}
            fullWidth={false}
            onClick={onClearFilter}
            text={clearFilterButtonText || _clearFilterButtonText}
          ></CustomButton>
          <CustomButton
            color='primary'
            fullWidth={false}
            onClick={onApplyFilter}
            text={confirmButtonText || _confirmButtonText}
            variant='outlined'
          ></CustomButton>
        </div>
      </div>
    </CustomDrawer>
  );
}

export default CustomItemsFilterDrawer;
