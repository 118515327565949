//React
import React, { useEffect } from 'react';

const ExternalAuthPage = ({ access, authExternally, lat, lng, refresh }) => {
  useEffect(() => {
    authExternally(access, lat, lng, refresh);
  }, []);
  return <div>cargando</div>;
};

export default ExternalAuthPage;
