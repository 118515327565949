// External libs
import { createSelector } from 'reselect'

const selectSlice = (state: any) => state.singleStoreDropDialog

const selectOpen = createSelector(selectSlice, reducer => reducer.open)

const selectors = {
  selectOpen,
}

export default selectors
