import { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import hooks from './hooks';
import { textIds } from './consts';
import { useFormatMessage } from '../../intl/intl';
import OrderActions from '../../redux/actions/orders-actions/orders.actions';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';

import CustomDialog from '../common/custom-dialog';
import { OrderProductsToppingsChecker } from '../order-products-toppings-checker';

import useStyles from './styles';

export function OrderRejectionByStockOutDialog() {
  const componentClasses = useStyles();
  const confirmButtonText = useFormatMessage(textIds.confirmButtonText);

  const label = hooks.useLabel();
  const open = hooks.useOpen();
  const products = hooks.useProducts();
  const title = hooks.useTitle();
  const refuseReason = hooks.useRefuseReason();
  const refuseReasonsDescription = hooks.useRefuseReasonDescription();

  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
  const [selectedToppings, setSelectedToppings] = useState<number[]>([]);

  useEffect(() => {
    if (!open) {
      setSelectedProducts([]);
      setSelectedToppings([]);
    }
  }, [open]);

  const isConfirmButtonDisabled = useMemo(() => {
    return selectedProducts.length === 0 && selectedToppings.length === 0;
  }, [selectedProducts, selectedToppings]);

  const dispatch = useDispatch();

  const handleBack = useCallback(() => {
    dispatch(dialogsManagerActions.back());
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  const handleConfirm = useCallback(async () => {
    dispatch(
      OrderActions.rejectByStockOut(
        products,
        selectedProducts,
        selectedToppings,
        refuseReason,
        refuseReasonsDescription,
      ),
    );
  }, [
    dispatch,
    products,
    selectedProducts,
    selectedToppings,
    refuseReason,
    refuseReasonsDescription,
  ]);

  return (
    <CustomDialog
      classes={{
        headerRoot: componentClasses.headerRoot,
        childrenContainer: componentClasses.body,
        paper: componentClasses.paper,
      }}
      actions={[
        {
          text: confirmButtonText,
          onClick: handleConfirm,
          color: 'primary',
          disabled: isConfirmButtonDisabled,
          variant: 'contained',
          fullWidth: false,
        },
      ]}
      onClickBackButton={handleBack}
      onClose={handleClose}
      open={open}
      showBackButton
      showCloseButton
      title={title}
    >
      <OrderProductsToppingsChecker
        classes={{
          root: componentClasses.orderRejectionByStockOutRoot,
        }}
        label={label}
        selectedProducts={selectedProducts}
        selectedToppings={selectedToppings}
        onProductSelection={setSelectedProducts}
        onToppingSelection={setSelectedToppings}
        products={products}
      />
    </CustomDialog>
  );
}
