// React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Components
import OrderWhimDialog from './OrderWhimDialog'
// Actions
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions'
import orderWhimDialogActions from '../../redux/actions/orderWhimDialog.actions'

const mapStateToProps = (
  {
    orderWhimDialogReducer: {
      orderId,
      open,
      productId,
      productName,
      messageError,
    },
  },
  { intl: { formatMessage } },
) => {
  const title = formatMessage({
    id: 'orderWhimDialog.title',
  })
  const nameInputLabel = formatMessage({
    id: 'orderWhimDialog.nameInputLabel',
  })
  const priceInputLabel = formatMessage({
    id: 'orderWhimDialog.priceInputLabel',
  })

  const comment = formatMessage({ id: 'orderWhimDialog.nameInputPlaceholder' })
  const confirm = formatMessage({ id: 'orderWhimDialog.confirmButtonText' })
  const inputErrorName = formatMessage({
    id: 'orderWhimDialog.error.inputName',
  })

  return {
    comment,
    confirm,
    nameInputLabel,
    inputErrorName,
    open,
    orderId,
    priceError: messageError,
    priceInputLabel,
    productId,
    productName,
    title,
  }
}
const mapDispatchToProps = {
  onClose: dialogsManagerActions.back,
  onBack: dialogsManagerActions.back,
  onConfirm: dialogsManagerActions.confirmOrderWhim,
  clearErrorText: orderWhimDialogActions.clearErrorText,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(OrderWhimDialog),
)
