import { useFormatMessage } from '../../../../../intl/intl';

export function useLang() {
  return {
    title: useFormatMessage({ id: 'login.title' }),
    emailLabelInput: useFormatMessage({ id: 'globals.email' }),
    createNowText: useFormatMessage({ id: 'login.createNow' }),
    rememberMeText: useFormatMessage({ id: 'login.rememberMe' }),
    passwordLabelInput: useFormatMessage({ id: 'globals.password' }),
    createAccountText: useFormatMessage({ id: 'login.createAccount' }),
    submitButtonText: useFormatMessage({ id: 'login.submitButtonText' }),
    forgotPasswordText: useFormatMessage({ id: 'login.forgotPassword' }),
  };
}
