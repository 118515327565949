import { useDispatch } from 'react-redux';

import { useFormatMessage } from '../../intl/intl';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';

export function useShowPhoneButton() {
  const dispatch = useDispatch();

  const callUserText = useFormatMessage({ id: 'showPhoneButton.label' });

  const handleOnClick = () => {
    dialogsManagerActions.openCustomerPhoneProxySessionDialog()(dispatch);
  };

  return {
    lang: {
      callUser: callUserText,
    },
    handleOnClick,
  };
}
