import { makeStyles } from '@material-ui/core';

import Colors from '../../../constants/Colors';
import { neutral } from '../../styles/colors';

export default makeStyles(theme => ({
  dialog: {
    maxWidth: '45rem',
    margin: '0.5rem 0',
    padding: '2rem 1.5rem 1.5rem',
    borderRadius: '0.5rem',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: neutral.D20,
  },
  nav: {
    alignSelf: 'end',
    lineHeight: 1,
  },
  closeIcon: {
    padding: 0,
    '& svg': {
      fill: neutral.D20,
    },
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    lineHeight: 1.25,
    textAlign: 'center',
    color: neutral.D20,
  },
  subtitle: {
    marginTop: '0.5rem',
    fontSize: '1rem',
    lineHeight: 1.5,
    textAlign: 'center',
    color: neutral.D16,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '80%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
    },
  },
  main: {
    padding: '2rem 0 1rem',
    overflowY: 'scroll',
  },
  actions: {
    padding: '1.5rem 0 0',
    columnGap: '1rem',
  },
  actionsSecondaryBtn: {
    padding: '0.5rem 1rem',
    fontSize: '0.75rem',
    lineHeight: 1.75,
    borderRadius: '0.5rem',
    color: Colors.PRIMARY_COLOR,
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.875rem',
    },
  },
  actionsPrimaryButton: {
    padding: '0.5rem 2rem',
    fontSize: '0.75rem',
    lineHeight: 1.75,
    borderRadius: '0.5rem',
    backgroundColor: Colors.PRIMARY_COLOR,
    '&:disabled': {
      color: neutral.D16,
      backgroundColor: neutral.D10,
    },
    '& svg.MuiCircularProgress-svg': {
      color: Colors.INFO_FONT_COLOR,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.875rem',
    },
  },
}));
