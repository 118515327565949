import { useSelector } from 'react-redux';

import { selectors as helpCenterSelectors } from '../../../redux/help-center-page';

const useArticle = () => useSelector(helpCenterSelectors.selectSelectedArticle);

const useArticleId = () =>
  useSelector(helpCenterSelectors.selectSelectedArticleId);

const useSelectedCategoryId = () =>
  useSelector(helpCenterSelectors.selectedCategoryId);

const useData = () => useSelector(helpCenterSelectors.selectData);

const useIsLoading = () =>
  useSelector(helpCenterSelectors.selectIsArticleLoading);

const hooks = {
  useArticle,
  useIsLoading,
  useData,
  useSelectedCategoryId,
  useArticleId,
};

export default hooks;
