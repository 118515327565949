import { Store, SuspendedStore, StoreWithSuspensionReason } from './interfaces'
import { SuspendedStoresResponse } from '../../services/partners-service/interfaces'

const parseToSuspendeStore = (
  suspendedStores: SuspendedStoresResponse,
): SuspendedStore => {
  return {
    id: suspendedStores.store_id || 0,
    reason: suspendedStores.reason || '',
    dateFrom: suspendedStores.dateFrom || '',
  }
}
const parseToSuspendeStores = (
  suspendedStores: SuspendedStoresResponse[],
): SuspendedStore[] => {
  return suspendedStores.map(store => parseToSuspendeStore(store))
}

const SuspensionStoreWithReason = (
  stores: Store[],
  suspendedStores: SuspendedStore[],
): StoreWithSuspensionReason[] => {
  const storeWithSuspensionReason = stores.map(store => {
    const suspendedStore = suspendedStores.find(ss => ss.id === store.id)
    const suspensionReason = suspendedStore?.reason || ''
    return { ...store, suspensionReason }
  })
  return storeWithSuspensionReason
}

const utils = { parseToSuspendeStores, SuspensionStoreWithReason }
export default utils
