// React
import { useEffect } from 'react';
// Redux
import { useDispatch } from 'react-redux';
// External Lib
import { useLocation, useParams } from 'react-router-dom';
// Consts
import { textIds } from './consts';
import { ZIndexType } from '../../constants/ZIndexType';
// Tools
import { useFormatMessage } from '../../intl/intl';
// Actions
import { actions as helpCenterActions } from '../../redux/help-center-page';
// Hooks
import hooks from './hooks';
// Components
import { HelpCenterBody } from './components/help-center-body';
import Drawer from './components/drawer';
import MainLayout from '../main-layout';
import CustomLoader from '../common/custom-loader';
// Styles
import useStyles from './styles';
import { useTheme, useMediaQuery } from '@material-ui/core';

export function HelpCenterPage() {
  const componentClasses = useStyles();
  const {
    categoryId: selectedCategoryId,
    articleId: selectedArticleId,
  } = useParams<any>();
  const isLoading = hooks.useIsLoading();
  const dispatch = useDispatch();
  const title = useFormatMessage(textIds.title);
  const location = useLocation();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const articleIdParam = new URLSearchParams(location.search).get(
      'articleId',
    );
    const articleIdToSet = !!articleIdParam
      ? articleIdParam.toString()
      : selectedArticleId;
    dispatch(
      helpCenterActions.loadData(selectedCategoryId, articleIdToSet, isMdUp),
    );
  }, [dispatch, location, selectedArticleId, selectedCategoryId, isMdUp]);

  return (
    <MainLayout
      classes={{
        sectionContent: componentClasses.sectionContent,
      }}
      title={title}
    >
      <Drawer />
      <div className={componentClasses.articleContainer}>
        <CustomLoader
          invisible
          open={isLoading}
          zIndexType={ZIndexType.DRAWER}
        />
        <HelpCenterBody />
      </div>
    </MainLayout>
  );
}
