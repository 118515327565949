const emailTextFieldLabel = {
  id: 'accountProfile.emailTextFieldLabel',
}
const generalInformationTitle = {
  id: 'accountProfile.generalInformationTitle',
}
const idTextFieldLabel = {
  id: 'accountProfile.idTextFieldLabel',
}
const nameTextFieldLabel = {
  id: 'accountProfile.nameTextFieldLabel',
}
const title = { id: 'accountProfile.title' }

const storesTitle = {
  id: 'accountProfile.storesTitle',
}
const storesRecordatoryText = {
  id: 'accountProfile.storesRecordatoryText',
}

const textIds = {
  emailTextFieldLabel,
  generalInformationTitle,
  idTextFieldLabel,
  nameTextFieldLabel,
  title,
  storesTitle,
  storesRecordatoryText,
}

export default textIds
