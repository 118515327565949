import { useEffect } from 'react';
import { orderMgmtTimeoutCase } from '../../../../../business/order-mgmt';
import { OrderMgmtAnalytics } from '../../../../../business/order-mgmt/analitycs';
import { orderMgmtDetailFacade } from '../../../../../business/order-mgmt/infra/store/order-detail';

export function useTimeoutDisclaimer() {
  const {
    orderId,
    storeId,
    showCountdownTime,
    isCountdownComplete,
    hasToppingWithSuggestions,
    rejectOrder,
  } = orderMgmtTimeoutCase();
  const { selectProducts, onGetProductsWithOM } = orderMgmtDetailFacade();

  useEffect(() => {
    onGetProductsWithOM();
  }, [selectProducts]);

  const handleRejectedBtn = async () => {
    OrderMgmtAnalytics.whenInactivityAction(
      { orderId, storeId },
      { action: 'rejected' },
    );
    rejectOrder();
  };

  return {
    showCountdownTime,
    isCountdownComplete,
    hasToppingWithSuggestions,
    handleRejectedBtn,
  };
}
