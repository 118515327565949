import { useDispatch } from 'react-redux';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';

import CustomTextField from '../common/custom-text-field';
import CustomDialog from '../common/custom-dialog/CustomDialog';
import CustomButton, {
  Colors,
  Variants,
} from '../common/buttons/custom-button';

import hooks from './hooks';
import { useFormatMessage } from '../../intl/intl';
import OrderActions from '../../redux/actions/orders-actions/orders.actions';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';

import stockOutsuggestImg from '../../assets/images/stock-out-suggestion-image.png';
import { textIds } from './consts';
import useStyles from './styles';

export function SuggestProductDialog() {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const rejectOrder = useFormatMessage(textIds.rejectOrder);
  const title = useFormatMessage(textIds.title);
  const [charCounterText] = useState<number>(50);
  const [counter, setCounter] = useState<number>(0);
  const charLimitText = useFormatMessage(textIds.charLimitText, {
    charCounter: counter,
    charCounterDefault: charCounterText,
  });
  const charDefaultText = useFormatMessage(textIds.charDefaultText);
  const replaceWithText = useFormatMessage(textIds.replaceWithText);
  const nothaveProductText = useFormatMessage(textIds.nothaveProductText);
  const productPlaceHolder = useFormatMessage(textIds.productPlaceHolder);
  const confirm = useFormatMessage(textIds.confirm);
  const selectedProduct = hooks.useSelectedProduct();
  const open = hooks.useOpen();
  const refuseReason = hooks.useRefuseReason();
  const refuseReasonDescription = hooks.useRefuseReasonsDescription();
  const productName = hooks.useProductName();
  const [valueText, setValueText] = useState<string>('');
  const componentClasses = useStyles();
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(dialogsManagerActions.close());
    setValueText('');
  }, [dispatch]);

  const handleBack = useCallback(() => {
    dispatch(dialogsManagerActions.back());
    setValueText('');
  }, [dispatch]);

  const onConfirm = useCallback(() => {
    dispatch(
      OrderActions.sendSuggestProduct(
        valueText,
        selectedProduct,
        refuseReason,
        refuseReasonDescription,
        productName,
      ),
    );
    setValueText('');
  }, [
    dispatch,
    valueText,
    selectedProduct,
    refuseReason,
    refuseReasonDescription,
    productName,
  ]);

  const onRefuse = useCallback(() => {
    dispatch(
      OrderActions.sendSuggestProduct(
        '',
        selectedProduct,
        refuseReason,
        refuseReasonDescription,
        productName,
      ),
    );
    setValueText('');
    dispatch(dialogsManagerActions.close());
  }, [
    dispatch,
    selectedProduct,
    refuseReason,
    refuseReasonDescription,
    productName,
  ]);

  const onChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    const eventValue = event.currentTarget.value ?? '';
    setCounter(eventValue.length);
    const replacedEventValue = eventValue.replace(
      /([^A-Za-zÀ-ÖØ-öø-ÿ0-9 ])|,|-|_|' '|\+|\.|\{|\(|\)|\}|\?|¿|'|!|#|\$|°|"|\/|%|&|=|\*/gi,
      '',
    );
    setValueText(replacedEventValue);
  }, []);
  return (
    <CustomDialog
      actions={
        isMdUp
          ? [
              {
                text: rejectOrder,
                onClick: onRefuse,
                fullWidth: false,
                disabled: false,
                color: 'primary',
                variant: 'outlined',
              },
              {
                text: confirm,
                onClick: onConfirm,
                fullWidth: false,
                disabled: valueText.length < 10,
                color: 'primary',
                variant: 'contained',
                classes: { submitButton: componentClasses.buttonSubmit },
              },
            ]
          : []
      }
      classes={{
        childrenContainer: componentClasses.body,
        headerRoot: componentClasses.headerRoot,
        paper: componentClasses.paper,
      }}
      CustomDialogHeaderChildren={''}
      onClose={handleClose}
      onClickBackButton={handleBack}
      open={open}
      showBackButton
      showCloseButton
      title={title}
    >
      <div className={componentClasses.container}>
        <img
          src={stockOutsuggestImg}
          alt='suggestion-img'
          className={componentClasses.stockOutsuggestImg}
        />
      </div>
      <div className={componentClasses.productName}>
        {nothaveProductText} &nbsp;
        <strong>{productName}</strong>
      </div>
      <div className={componentClasses.containerProduct}>
        <CustomTextField
          classes={{
            textFieldRoot: componentClasses.suggestProductInput,
            input: componentClasses.input,
            labelOutlined: componentClasses.inputLabelOutlined,
            notchedOutlineInput: componentClasses.inputLabel,
          }}
          name='input'
          value={valueText}
          placeholder={productPlaceHolder}
          label={replaceWithText}
          variant='outlined'
          maxLength={50}
          onChange={onChange}
          helperText={counter ? charLimitText : charDefaultText}
        />
      </div>
      {!isMdUp && (
        <div className={componentClasses.containerFooterButtons}>
          <CustomButton
            classes={{ submitButton: componentClasses.confirmButtonFooter }}
            color={Colors.PRIMARY}
            fullWidth={true}
            onClick={onConfirm}
            variant={Variants.CONTAINED}
            disabled={valueText.length < 10}
          >
            {confirm}
          </CustomButton>
          <CustomButton
            classes={{ submitButton: componentClasses.rejectButtonFooter }}
            color={Colors.PRIMARY}
            fullWidth={true}
            onClick={onRefuse}
            variant={Variants.OUTLINED}
          >
            {rejectOrder}
          </CustomButton>
        </div>
      )}
    </CustomDialog>
  );
}
