import {
  Lock,
  Storefront,
  VisibilityOff,
  WarningRounded,
} from '@material-ui/icons';

import Colors from '../../constants/Colors';

import { green, neutral, orange, red } from '../../shared/styles/colors';
import type { ItfStoreCtaOpts, ItfStoreStatusStatusOpts } from './types';

export const storeStatusStatusOpts: ItfStoreStatusStatusOpts = {
  ACTIVE: {
    status: 'ACTIVE',
    bgColor: Colors.GREEN_ACTIVE_STATUS,
    textId: 'store.status.status.active',
    elementIcon: Storefront,
    amplitudFilterType: 'active',
    color: green[50],
    fontColor: neutral[100],
  },
  ON_HOLD: {
    status: 'ON_HOLD',
    bgColor: Colors.ANZAC,
    textId: 'store.status.status.on_hold',
    elementIcon: Lock,
    amplitudFilterType: 'closed',
    color: orange.D4,
    fontColor: orange.D4,
  },
  INACTIVE: {
    status: 'INACTIVE',
    bgColor: neutral.D18,
    textId: 'store.status.status.inactive',
    elementIcon: VisibilityOff,
    amplitudFilterType: 'deactivated',
    color: neutral[50],
    fontColor: neutral[50],
  },
  SUSPENDED: {
    status: 'SUSPENDED',
    bgColor: Colors.SECONDARY_COLOR_DARK,
    textId: 'store.status.status.suspended',
    elementIcon: WarningRounded,
    amplitudFilterType: 'suspended',
    color: red[50],
    fontColor: red[50],
  },
};

export const storeCtaOpts: ItfStoreCtaOpts = {
  'cta.info': {
    cta: 'cta.info',
    textId: 'store.status.cta.info.btn',
    amplitud: {
      code: 'more_info',
    },
  },
  'cta.contact-support': {
    cta: 'cta.contact-support',
    textId: 'store.status.cta.support.btn',
    amplitud: {
      code: 'contact_support',
    },
  },
  'cta.deactivate': {
    cta: 'cta.deactivate',
    textId: 'store.status.cta.deactivate.btn',
    amplitud: {
      code: 'deactivate_store',
      action: 'deactivate',
    },
  },
  'cta.activate': {
    cta: 'cta.activate',
    textId: 'store.status.cta.activate.btn',
    amplitud: {
      code: 'activate_store',
      action: 'activate',
    },
  },
  'cta.edit-schedules': {
    cta: 'cta.edit-schedules',
    textId: 'store.status.cta.schedules.btn',
    amplitud: {
      code: 'edit_schedule',
    },
  },
  'cta.desuspended': {
    cta: 'cta.desuspended',
    textId: 'store.status.cta.desuspended.btn',
    amplitud: {
      code: 'unsuspend_store',
    },
  },
};

export enum CtaUiModes {
  Left = 'left',
  Right = 'right',
  Center = 'center',
  FullWidth = 'fullWidth',
  CenterPlus = 'centerPlus',
}

export const storeStatusDropdownTextIds = {
  storeName: 'storeName',
  error: 'store.status.dropdownBtn.error',
  multiStore: 'store.status.dropdownBtn.multiStore',
  manageStores: 'store.status.dropdownBtn.multiStore.goTo',
};

export const editScheduleCategoryId = '5f20af6b9001ac00136d4991';
export const editScheduleArticleId = '5f5015d7a2813a00190c7dd1';
export const infoCategoryId = '5f4ff6cc0ad31b001a424834';
export const infoArticleId = '5fcad881447ed4001b2008ac';

export const amplitudPartnerType = {
  many: 'many',
  single: 'single',
};
