// React
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// Components
import MenuToppingsList from './MenuToppingsList';

const mapStateToProps = (
  { menuReducer: { selectedProductToppingCategories } },
  { intl: { formatMessage } },
) => {
  const title = formatMessage({ id: 'globals.toppings' });

  return { title, toppingCategories: selectedProductToppingCategories };
};

const mapDispatchToProps = {};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MenuToppingsList),
);
