import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { clsx } from 'clsx';
import { FormGroup } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import CustomTextField from './../custom-text-field/CustomTextField';
import { CustomCheckBoxLabel } from './../custom-check-box-label';

import useStyles from './CustomCheckBoxGroup.styles';

const CustomCheckBoxGroup = ({
  allOptionText,
  classes,
  filterTitle,
  intl: { formatMessage },
  items,
  listTitle,
  onChange,
  searchFieldPlaceHolder,
  selectedItems,
  showAllOption,
}) => {
  allOptionText =
    allOptionText ||
    formatMessage({
      id: 'global.all',
    });

  const _classes = useStyles();

  const [filteredItems, setFilteredItems] = useState(
    items.map(item => item.id.toString()),
  );

  useEffect(() => {
    setFilteredItems(items.map(item => item.id.toString()));
  }, [items]);

  const onChangeItemFilter = event => {
    const text = event.target.value.trim();
    if (text === '') {
      setFilteredItems(items.map(item => item.id.toString()));
      return;
    }
    const filteredItemsResult = items
      .filter(item => {
        return (
          item.name && item.name.toLowerCase().search(text.toLowerCase()) !== -1
        );
      })
      .map(item => item.id.toString());
    setFilteredItems(filteredItemsResult);
  };

  const onChangeAllOption = e => {
    const itemsIds = items.map(item => item.id.toString());
    const toSelect = itemsIds.length === selectedItems.length ? [] : itemsIds;
    onChange(toSelect);
  };

  const onSelectorChange = e => {
    const selectedItem = e.currentTarget.value;
    const toSelect =
      selectedItems.indexOf(selectedItem) > -1
        ? selectedItems.filter(item => item !== selectedItem)
        : [...selectedItems, selectedItem];
    onChange(toSelect);
  };

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      {!!filterTitle && (
        <h4 className={clsx(_classes.filterTitle, classes?.filterTitle)}>
          {filterTitle}
        </h4>
      )}
      <CustomTextField
        placeholder={searchFieldPlaceHolder}
        onChange={onChangeItemFilter}
        fullWidth={true}
        endAdornment={<SearchIcon className={_classes.searchIcon} />}
      />
      {!!listTitle && (
        <h5 className={clsx(_classes.listTitle, classes?.listTitle)}>
          {listTitle}
        </h5>
      )}
      <FormGroup>
        {showAllOption &&
          items.length > 0 &&
          filteredItems.length === items.length && (
            <CustomCheckBoxLabel
              checked={items.length === selectedItems.length}
              label={allOptionText}
              onChange={onChangeAllOption}
            ></CustomCheckBoxLabel>
          )}
        {/* TODO: this requires refactoring */}
        {items.map((item, index) =>
          filteredItems.indexOf(item.id.toString()) > -1 ? (
            <CustomCheckBoxLabel
              key={`cCheckBox-${index}`}
              checked={selectedItems.indexOf(item.id.toString()) > -1}
              label={item.name}
              onChange={onSelectorChange}
              value={item.id.toString()}
            />
          ) : null,
        )}
      </FormGroup>
    </div>
  );
};

CustomCheckBoxGroup.defaultProps = {
  classes: {},
  items: [],
  selectedItems: [],
  showAllOption: false,
};

export default injectIntl(CustomCheckBoxGroup);
