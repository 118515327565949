// Redux
import { createAction } from '@reduxjs/toolkit';
// Interfaces
import { Step } from '../interfaces';

const closeAction = createAction('OnBoardingDialog/close');

const clearAction = createAction('OnBoardingDialog/clear');

const openAction = createAction<{
  steps: Step[];
  source: string;
}>('OnBoardingDialog/open');

const nextStepAction = createAction('OnBoardingDialog/nextStep');
const backStepAction = createAction('OnBoardingDialog/backStep');

const clear = () => (dispatch: any) => {
  dispatch(clearAction());
};

const close = () => (dispatch: any) => {
  dispatch(closeAction());
};

const open = (steps: Step[], source: string) => (dispatch: any) => {
  dispatch(
    openAction({
      steps,
      source,
    }),
  );
};
// JumpToStep
const nextStep = () => (dispatch: any) => {
  dispatch(nextStepAction());
};
const backStep = () => (dispatch: any) => {
  dispatch(backStepAction());
};

const actions = {
  clear,
  clearAction,
  close,
  closeAction,
  open,
  openAction,
  nextStep,
  nextStepAction,
  backStepAction,
  backStep,
};

export default actions;
