import { COMPONENT_TYPE } from '../domain';
import { ACTION_TYPE, AnalyticActions, handleProperties } from './core';

import type {
  AnalyticProperties,
  AnalyticHasChromeExtensionProperties,
  TestType,
} from './core';
import type { ComponentType, ContentType } from '../domain';

export const BrowserAnalytics = {
  whenOpenDialog,
  whenNeedHelp,
  whenInstall,
  whenClose,
  whenCloseOutside,
  whenFirstLoadWebApp,
} as const;

function whenOpenDialog(
  partnerId: number,
  type: ContentType,
  test: TestType = '',
) {
  const props: AnalyticProperties = {
    partnerId,
    component: COMPONENT_TYPE.dialog,
    action: ACTION_TYPE.open,
    type,
    test,
  };
  AnalyticActions.logEventChrome(handleProperties(props));
}

function whenNeedHelp(
  partnerId: number,
  component: ComponentType,
  type: ContentType,
  test: TestType = '',
) {
  const props: AnalyticProperties = {
    partnerId,
    component,
    action: ACTION_TYPE.need_help,
    type,
    test,
  };
  AnalyticActions.logEventChrome(handleProperties(props));
}

function whenInstall(
  partnerId: number,
  component: ComponentType,
  type: ContentType,
  test: TestType = '',
) {
  const props: AnalyticProperties = {
    partnerId,
    component,
    action: ACTION_TYPE.install,
    type,
    test,
  };
  AnalyticActions.logEventChrome(handleProperties(props));
}

function whenClose(
  partnerId: number,
  component: ComponentType,
  type: ContentType,
  test: TestType = '',
) {
  const props: AnalyticProperties = {
    partnerId,
    component,
    action: ACTION_TYPE.closed,
    type,
    test,
  };
  AnalyticActions.logEventChrome(handleProperties(props));
}

function whenCloseOutside(
  partnerId: number,
  component: ComponentType,
  type: ContentType,
  test: TestType = '',
) {
  const props: AnalyticProperties = {
    partnerId,
    component,
    action: ACTION_TYPE.outside,
    type,
    test,
  };
  AnalyticActions.logEventChrome(handleProperties(props));
}

function whenFirstLoadWebApp(params: AnalyticHasChromeExtensionProperties) {
  AnalyticActions.logEventHasChromeExtension(params);
}
