import { Box, Button } from '@material-ui/core';

import { useHighDemandBtn } from './useHighDemandBtn';
import makeClasses from './HighDemandBtn.style';

import type { ButtonProps } from '@material-ui/core/Button';
import type { SaturationStateProp } from '../../shared/models';

import { RightArrowIcon } from '../../shared/assets/icons/right-arrow/';
import { StoreStatusDrop } from '../../../../components/store-status/store-status-drop';
import { TurboTag } from '../turbo-tag';

export function HighDemandBtn(props: HighDemandBtnProps) {
  const {
    onClick,
    isSingleStore = true,
    everyIsActive,
    hasActiveTurboStores,
  } = props;

  const { text, variant } = useHighDemandBtn({
    isSingleStore,
    pureVariant: props.variant,
  });

  const classes = makeClasses({ variant });

  return (
    <Box className={classes.container} onClick={onClick}>
      <StoreStatusDrop />
      {hasActiveTurboStores ? (
        <TurboTag variant='header' />
      ) : (
        variant !== 'deactivated' &&
        !everyIsActive && (
          <Button
            size='large'
            type='button'
            variant='contained'
            onClick={onClick}
            classes={{ contained: classes.btn }}
          >
            {text.status} {text.demandTimer}
          </Button>
        )
      )}
      <RightArrowIcon />
    </Box>
  );
}

interface HighDemandBtnProps
  extends SaturationStateProp,
    Pick<ButtonProps, 'onClick'> {
  isSingleStore?: boolean;
  everyIsActive: boolean;
  hasActiveTurboStores: boolean;
}
