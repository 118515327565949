import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import appStore from './../../redux/stores/app.store';
import AppActions from './../../redux/actions/app-actions';
import OrdersActions from '../../redux/actions/orders-actions';
import OrdersHistoricalAdvancedFilterToolbar from './OrdersHistoricalAdvancedFilterToolbar';

import history from '../../history';
import Paths from '../../constants/Paths';

const mapStateToProps = (
  { ordersReducer, partnerReducer: { profile } },
  { intl: { formatMessage } },
) => {
  const selectedStore = profile.stores.find(
    store => store.id === +ordersReducer.historicalFilter.selectedStoreId,
  );

  const firstStoreId =
    profile.stores && profile.stores[0] && profile.stores[0].id;

  const selectedStoreFilterOption = selectedStore && {
    label: selectedStore.name,
    id: selectedStore.id,
    onDelete: () => {
      OrdersActions.setStoreInOrdersHistoricalFilter(null)(
        appStore.store.dispatch,
      );
      history.push(Paths.ORDERS_HISTORY);
    },
  };

  const selectedFilterOptions = [];

  if (selectedStoreFilterOption) {
    selectedFilterOptions.push(selectedStoreFilterOption);
  }

  return {
    firstStoreId,
    selectedFilterOptions,
  };
};

const mapDispatchToProps = {
  onClick: OrdersActions.openOrdersHistoricalFilterDrawer,
  onStoreQueryParamChange: OrdersActions.setStoreInOrdersHistoricalFilter,
  openSnackBarError: AppActions.openSnackBarError,
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(OrdersHistoricalAdvancedFilterToolbar),
);
