import {
  ordermgmtDialogFacade,
  stateOMType,
} from '../../../../../business/order-mgmt';
import Colors from '../../../../../constants/Colors';
import orderActions from '../../../../../redux/actions/orders-actions';

export function useKanbanOMCard(state: stateOMType, id: number) {
  const { onOpenOrderDetail } = ordermgmtDialogFacade();
  const getColors = {
    ['to_wait']: {
      background: Colors.WARNING_FONT_COLOR,
      color: Colors.WHITE,
      border: Colors.WARNING,
      box: Colors.WARNING_BG,
      boxText: Colors.BROWN,
      backgroundBox: Colors.WARNING_BG,
      padding: '0.25rem',
      borderRadius: '1rem',
    },
    ['accepted']: {
      background: Colors.PRIMARY_COLOR,
      color: Colors.WHITE,
      border: Colors.PRIMARY_COLOR,
      box: Colors.PRIMARY_COLOR,
    },
  };

  return {
    cardColors: getColors[state] ?? {
      background: Colors.DANGER_LIGHT_COLOR,
      color: Colors.DANGER_LIGHT_1,
      border: Colors.DANGER_LIGHT_COLOR,
      box: Colors.DANGER_LIGHT_2,
      boxText: Colors.WHITE,
      backgroundBox: Colors.DANGER_LIGHT_2,
      padding: '0.25rem',
      borderRadius: '1rem',
    },
    openDetailDialog: () => onOpenOrderDetail(id),
  };
}
