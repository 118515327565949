import OrderActions from '../../../../../redux/actions/orders-actions/orders.actions';
import { dispatchAction } from '../../../../shared/infra';
import { OnUpdateHandoffDetail } from '../../domain';
import { onGetHandoffDetailRequest } from '../core/requests';
import { checkoutRTActions, checkoutRTSelectors } from '../store/checkoutRT';

export function checkoutRTHandler() {
  function clear() {
    dispatchAction(checkoutRTActions.clear());
  }
  function update(payload: OnUpdateHandoffDetail) {
    dispatchAction(checkoutRTActions.update(payload));
  }
  function get(params: onGetHandoffDetailRequest) {
    dispatchAction(checkoutRTActions.get(params));
  }
  function getV2Orders() {
    dispatchAction(OrderActions.getOrders())
  }

  return {
    stateStatus: checkoutRTSelectors.status(),
    currentData: checkoutRTSelectors.currentData(),
    clear,
    update,
    get,
    getV2Orders
  };
}
