// Material UI
import { makeStyles } from '@material-ui/core';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => {
  return {
    board: {
      backgroundColor: neutral[10],
      flex: '1 1 1px',
      height: '100%',
      padding: '0.75rem',
      width: '100%',
      display: 'grid',
      gridTemplateRows: '100%',
      overflow: 'auto',
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(3, minmax(100px, 1fr))',
        gap: '0.5rem',
      },
    },
    root: {
      flexDirection: 'column',
      display: 'flex',
      height: '100%',
    },
    swipeableViewsRoot: {
      height: '100%',
      '& div.react-swipeable-view-container': {
        height: '100%',
      },
    },
  };
});
