// External libs
import produce from 'immer'
import { handleActions } from 'redux-actions'
// Consts
import PartnerOrderStateType from './../../constants/PartnerOrderStateType'
// Utils
import OrdersUtils from '../../utils/OrdersUtils'
//Services
import OrderProductsProcessService from '../../services/OrderProductsProcessService'
// Actions
import OrderActions from '../actions/orders-actions'
import StoreKeeperActions from './../actions/StoreKeeper.actions'

export const initialState = {
  failsV2Count: 0,
  failsErrorServiceCount: 0,
  filter: { open: false, selectedStoresIds: [] },
  gettingOrdersHistoricalFailedMessage: null,
  historicalFilter: { open: false, selectedStoreId: null },
  isCheckNetworkDialogOpened: false,
  isGettingOrdersHistoricalFailedBeingShown: false,
  isOrdersHistoricalLoading: false,
  orderDetailPollingBlackList: [],
  orders: [],
  rejectionReasons: [],
  ordersHistorical: [],
  ordersHistoricalPage: 1,
  qualifyNegativeReasons: [],
  historicalOrderDetail: {},
  rtsInStoreTotal: 0,
  rtsOnTheWayTotal: 0,
  searchFieldText: '',
}

export default handleActions(
  {
    [OrderActions.acknowledgeCanceledOrderAction]: (
      state,
      { payload: { orderId } },
    ) => removeOrder(orderId, state),
    [OrderActions.addToOrderDetailPollingBlacklistAction]: (
      state,
      { payload: { orderId } },
    ) => addToOrderDetailPollingBlacklistAction(state, orderId),

    [OrderActions.applyOrdersHistoricalFilterAction]: (
      state,
      { payload: { selectedStoreId } },
    ) => applyOrdersHistoricalFilter(selectedStoreId, state),

    [OrderActions.applyOrdersListFilterAction]: (
      state,
      { payload: { selectedStoresIds } },
    ) => applyOrdersListFilter(selectedStoresIds, state),

    [OrderActions.clearOrdersHistoricalAction]: state =>
      clearOrdersHistorical(state),

    [OrderActions.clearOrdersListFilterAction]: state =>
      applyOrdersListFilter([], state),

    [OrderActions.closeSupportChatDrawerAction]: (
      state,
      { payload: { orderId } },
    ) => toggleSupportChatDrawer(orderId, state, false),

    [OrderActions.sendToPrintAction]: (state, { payload: { orderId } }) =>
      togglePendingToPrint(orderId, true, state),

    [OrderActions.sendToPrintBulkAction]: (state, { payload: { orderIds } }) =>
      togglePendingToPrintBulk(orderIds, true, state),

    [OrderActions.confirmPrintAction]: (state, { payload: { orderId } }) =>
      togglePendingToPrint(orderId, false, state),

    [OrderActions.confirmPrintBulkAction]: (state, { payload: { orderIds } }) =>
      togglePendingToPrintBulk(orderIds, false, state),

    [OrderActions.confirmReceptionAction]: (state, { payload: { orderIds } }) =>
      confirmReception(orderIds, state),

    [OrderActions.decreaseCookingTimeToTakeOrderAction]: (
      state,
      { payload: { minCookingTime, orderId, cookingTimeStep } },
    ) =>
      decreaseCookingTimeToTakeOrder(
        minCookingTime,
        orderId,
        cookingTimeStep,
        state,
      ),

    [OrderActions.getOrderDetailAction]: (
      state,
      { payload: { orderDetail } },
    ) => getOrderDetail(orderDetail, state),

    [OrderActions.getRejectionReasonsAction]: (
      state,
      { payload: { rejectionReasons } },
    ) => getRejectionReasons(rejectionReasons, state),

    [OrderActions.getQualifyNegativeReasonsAction]: (
      state,
      { payload: { data } },
    ) => getQualifyNegativeReasons(state, data),

    [OrderActions.getOrdersHistoricalAction]: (
      state,
      { payload: { ordersHistorical, page } },
    ) => getOrdersHistorical(state, ordersHistorical, page),

    [OrderActions.getOrdersAction]: (state, { payload: { orders, zoneId } }) =>
      getOrders(orders, state, zoneId),

    [OrderActions.hideGettingOrdersHistoricalFailedAction]: state =>
      toggleIsGettingOrdersHistoricalFailedBeingShown(state, null, false),

    [OrderActions.increaseCookingTimeToTakeOrderAction]: (
      state,
      { payload: { maxCookingTime, orderId, cookingTimeStep } },
    ) =>
      increaseCookingTimeToTakeOrder(
        maxCookingTime,
        orderId,
        cookingTimeStep,
        state,
      ),
    [OrderActions.clearNetworkErrorCountAction]: state =>
      clearNetworkErrorCount(state),
    [OrderActions.increaseNetworkErrorCountAction]: state =>
      increaseNetworkErrorCount(state),
    [OrderActions.listenOrderSupportChatAction]: (
      state,
      { payload: { orderId, supportChatMessages } },
    ) => listenOrderSupportChat(orderId, supportChatMessages, state),

    [OrderActions.listenOrderSupportNotificationsAction]: (
      state,
      { payload: { orderId, supportNotificationsMessages } },
    ) =>
      listenOrderSupportNotifications(
        orderId,
        supportNotificationsMessages,
        state,
      ),

    [OrderActions.closeCheckNetworkDialogAction]: state =>
      toggleCheckNetworkDialog(false, state),

    [OrderActions.closeDialogExtraCTAction]: (
      state,
      { payload: { orderId } },
    ) => toggleDialogExtraCT(false, orderId, state),

    [OrderActions.closeOrdersHistoricalFilterDrawerAction]: state =>
      toggleOrdersHistoricalFilterDrawer(false, state),

    [OrderActions.closeOrdersListFilterDrawerAction]: state =>
      toggleOrdersListFilterDrawer(false, state),

    [OrderActions.openCheckNetworkDialogAction]: state =>
      toggleCheckNetworkDialog(true, state),

    [OrderActions.openDialogExtraCTAction]: (state, { payload: { orderId } }) =>
      toggleDialogExtraCT(true, orderId, state),

    [OrderActions.openOrdersHistoricalFilterDrawerAction]: state =>
      toggleOrdersHistoricalFilterDrawer(true, state),

    [OrderActions.openOrdersListFilterDrawerAction]: state =>
      toggleOrdersListFilterDrawer(true, state),

    [OrderActions.openSupportChatDrawerAction]: (
      state,
      { payload: { orderId, supportScore } },
    ) => toggleSupportChatDrawer(orderId, state, true, supportScore),

    [OrderActions.setDeliveredToRTBeforeCanceledAction]: (
      state,
      { payload: { orderId } },
    ) => removeOrder(orderId, state),

    [OrderActions.setFirebaseStatusAction]: (
      state,
      { payload: { orderId, realTimeDatabase, realTimeStatus } },
    ) => setFirebaseStatus(orderId, realTimeDatabase, realTimeStatus, state),

    [OrderActions.setInitialCTToTakeOrderAction]: (
      state,
      { payload: { orderId, initialCTToTakeOrder } },
    ) => setInitialCTToTakeOrder(state, orderId, initialCTToTakeOrder),

    [OrderActions.setOrdersHistoricalIsNotLoadingAction]: state =>
      setOrdersHistoricalLoading(state, false),
    [OrderActions.setOrdersHistoricalIsLoadingAction]: state =>
      setOrdersHistoricalLoading(state, true),
    [OrderActions.setSearchFieldTextAction]: (state, { payload: { text } }) =>
      setSearchFieldText(text, state),

    [OrderActions.setNotDeliveredToRTBeforeCanceledAction]: (
      state,
      { payload: { orderId } },
    ) => removeOrder(orderId, state),

    [OrderActions.setStoreInOrdersHistoricalFilterAction]: (
      state,
      { payload: { storeId } },
    ) => applyOrdersHistoricalFilter(storeId, state),

    [OrderActions.setRTCountersAction]: (
      state,
      { payload: { rtsInStoreTotal, rtsOnTheWayTotal } },
    ) => setRTCounters(state, rtsInStoreTotal, rtsOnTheWayTotal),

    [OrderActions.showGettingOrdersHistoricalFailedAction]: (
      state,
      { payload: { exceptionMessage } },
    ) =>
      toggleIsGettingOrdersHistoricalFailedBeingShown(
        state,
        exceptionMessage,
        true,
      ),

    [OrderActions.markRTqualificationAction]: (
      state,
      { payload: { qualification } },
    ) => markRTqualificationAction(state, qualification),

    [StoreKeeperActions.getStorekeeperChannelAction]: (
      state,
      { payload: { channel, database, orderId } },
    ) => getStoreKeeperChanel(orderId, channel, database, state),

    [StoreKeeperActions.listenRTPositionAction]: (
      state,
      { payload: { bearing, lat, lng, precision, orderId } },
    ) => listenRTPosition(bearing, lat, lng, precision, orderId, state),
    [OrderActions.clearFailServiceErrorCountAction]: state =>
      clearFailServiceErrorCount(state),
    [OrderActions.increaseFailServiceErrorCountAction]: state =>
      increaseFailServiceErrorCount(state),
  },
  initialState,
)

const togglePendingToPrintFromClonedOrders = (
  clonedOrders,
  orderId,
  pendingToPrint,
) => {
  const orderToModifyIndex = clonedOrders.findIndex(
    order => order.id === +orderId,
  )

  if (orderToModifyIndex === -1) {
    return
  }

  clonedOrders[orderToModifyIndex] = {
    ...clonedOrders[orderToModifyIndex],
    pendingToPrint,
    printed: !pendingToPrint,
  }
}

const togglePendingToPrint = (orderId, pendingToPrint, state) => {
  if (!orderId) {
    return state
  }

  // Shallow clone the list of orders.
  const shallowClonedOrders = [...state.orders]

  togglePendingToPrintFromClonedOrders(
    shallowClonedOrders,
    orderId,
    pendingToPrint,
  )

  // Shallow clone the state and update it with the shallow cloned list of orders updated.
  return {
    ...state,
    orders: shallowClonedOrders,
  }
}

const togglePendingToPrintBulk = (orderIds, pendingToPrint, state) => {
  if (!orderIds || !orderIds.length) {
    return state
  }

  // Shallow clone the list of orders.
  const shallowClonedOrders = [...state.orders]

  for (const orderId of orderIds) {
    togglePendingToPrintFromClonedOrders(
      shallowClonedOrders,
      orderId,
      pendingToPrint,
    )
  }

  return {
    ...state,
    orders: shallowClonedOrders,
  }
}

const addToOrderDetailPollingBlacklistAction = (state, orderId) => {
  const alreadyAdded = state.orderDetailPollingBlackList.some(
    id => +id === +orderId,
  )

  if (alreadyAdded) {
    return state
  }

  const shalowClonedOrderDetailPollingBlacklist = [
    ...state.orderDetailPollingBlackList,
    orderId,
  ]

  return {
    ...state,
    orderDetailPollingBlackList: shalowClonedOrderDetailPollingBlacklist,
  }
}

const applyOrdersHistoricalFilter = (selectedStoreId, state) => {
  return {
    ...state,
    ordersHistorical: [],
    ordersHistoricalPage: 1,
    historicalFilter: { ...state.filter, selectedStoreId },
  }
}

const applyOrdersListFilter = (selectedStoresIds, state) => {
  return { ...state, filter: { ...state.filter, selectedStoresIds } }
}

const clearOrdersHistorical = state => {
  return {
    ...state,
    ordersHistorical: [],
  }
}

const confirmReception = (orderIds, state) => {
  // Shallow clone the list of orders.
  const shallowClonedOrders = [...state.orders]

  for (const orderId of orderIds) {
    const orderToModifyIndex = shallowClonedOrders.findIndex(
      order => order.id === orderId,
    )

    if (orderToModifyIndex === -1) {
      continue
    }

    shallowClonedOrders[orderToModifyIndex] = {
      ...shallowClonedOrders[orderToModifyIndex],
      confirmedReception: true,
    }
  }

  // Shallow clone the state and update it with the shallow cloned list of orders updated.
  return {
    ...state,
    orders: shallowClonedOrders,
  }
}

const getOrdersHistorical = (state, ordersHistorical, page) => {
  return {
    ...state,
    ordersHistorical: [...state.ordersHistorical, ...ordersHistorical],
    ordersHistoricalPage: page,
  }
}

const getQualifyNegativeReasons = (state, qualifyNegativeReasons) => {
  return {
    ...state,
    qualifyNegativeReasons,
  }
}

const markRTqualificationAction = (state, qualification) => {
  const { ordersHistorical } = state
  ordersHistorical.forEach(order => {
    if (order.id === qualification.orderId) {
      order.incident = qualification.value
    }
  })

  return {
    ...state,
    ordersHistorical: [...ordersHistorical],
  }
}

const decreaseCookingTimeToTakeOrder = (
  minCookingTime,
  orderId,
  cookingTimeStep,
  state,
) => {
  return produce(state, stateDraft => {
    const order = stateDraft.orders.find(order => order.id === orderId)

    if (!order || !order.detail) {
      return
    }

    const cookingTimeToTakeOrder = order.detail.cookingTimeToTakeOrder

    if (cookingTimeToTakeOrder === minCookingTime) {
      return
    }

    const newCookingTime =
      cookingTimeToTakeOrder -
      cookingTimeStep +
      order.detail.maxCookingTimeOffset

    if (newCookingTime >= minCookingTime) {
      // assignments must be done on draft object
      order.detail.cookingTimeToTakeOrder = newCookingTime
      order.detail.minCookingTimeOffset = 0
      order.detail.maxCookingTimeOffset = 0
      return
    }

    order.detail.minCookingTimeOffset = minCookingTime - newCookingTime
    order.detail.maxCookingTimeOffset = 0
    order.detail.cookingTimeToTakeOrder = minCookingTime
  })
}
const getStoreKeeperChanel = (orderId, channel, database, state) => {
  const selectedOrderIndex = state.orders.findIndex(
    orderInStore => orderInStore.id === orderId,
  )

  if (selectedOrderIndex === -1) {
    return state
  }

  const shallowClonedSelectedOrder = {
    ...state.orders[selectedOrderIndex],
  }

  shallowClonedSelectedOrder.detail = {
    ...shallowClonedSelectedOrder.detail,
    storekeeper: {
      ...shallowClonedSelectedOrder.detail.storekeeper,
      channel,
      database,
    },
  }

  const shallowClonedOrders = [...state.orders]

  shallowClonedOrders[selectedOrderIndex] = shallowClonedSelectedOrder

  return {
    ...state,
    orders: shallowClonedOrders,
  }
}
const getOrderDetail = (orderDetail, state) => {
  if (!orderDetail) {
    // If no order was received, return no changes.
    return state
  }

  // shallow clone the current orders.
  const shallowClonedOrders = [...state.orders]

  // If there are orders in the store, get the index in store of the order received in the service.
  const orderIndexInStore = shallowClonedOrders.findIndex(
    shallowOrder => shallowOrder.id === orderDetail.id,
  )
  if (orderIndexInStore === -1) {
    // If no order is found in the state, it means the order is not going to be managed.
    // Create a header for the detail and set the flag to be not managed.
    // Set values by default of order detail.

    orderDetail.isSupportChatDrawerOpened = false

    orderDetail.supportChatMessages = []

    orderDetail.supportNotificationsMessages = []

    if (
      !orderDetail.partner_order_state ||
      orderDetail.partner_order_state.trim() === ''
    ) {
      orderDetail.partner_order_state = PartnerOrderStateType.NEWORDER
    }

    orderDetail.predict_cooking_time = orderDetail.predict_cooking_time || {}
    orderDetail.products = orderDetail.products || []
    orderDetail.storekeeper = orderDetail.storekeeper || {}
    orderDetail.stores = orderDetail.stores || []
    orderDetail.user = orderDetail.user || {}
    orderDetail.whims = orderDetail.whims || []

    OrderProductsProcessService.processProducts(orderDetail.products)

    orderDetail.productsByCorridor = OrderProductsProcessService.getProductsByCorridors(
      orderDetail.products,
    )

    const orderHeader = {
      id: orderDetail.id,
      confirmedReception: false,
      beingManaged: false,
      delivery_method: orderDetail.delivery_method,
      detail: orderDetail,
      partner_id: orderDetail.partner_id,
      partner_order_state: orderDetail.partner_order_state,
      state: orderDetail.state,
      store_id:
        orderDetail.stores && orderDetail.stores[0] && orderDetail.stores[0].id,
    }
    shallowClonedOrders.push(orderHeader)
    return {
      ...state,
      orders: shallowClonedOrders,
    }
  }

  // Set values by default of order detail.

  orderDetail.isSupportChatDrawerOpened =
    shallowClonedOrders[orderIndexInStore].detail.isSupportChatDrawerOpened ||
    false

  orderDetail.supportChatMessages =
    shallowClonedOrders[orderIndexInStore].detail.supportChatMessages || []

  orderDetail.supportNotificationsMessages =
    shallowClonedOrders[orderIndexInStore].detail
      .supportNotificationsMessages || []

  orderDetail.partner_order_state =
    orderDetail.partner_order_state || PartnerOrderStateType.NEWORDER

  orderDetail.predict_cooking_time = orderDetail.predict_cooking_time || {}
  orderDetail.products = orderDetail.products || []
  orderDetail.storekeeper = orderDetail.storekeeper || {}
  orderDetail.user = orderDetail.user || {}
  orderDetail.whims = orderDetail.whims || []

  // sort topping categories

  // end

  OrderProductsProcessService.processProducts(orderDetail.products)

  orderDetail.productsByCorridor = OrderProductsProcessService.getProductsByCorridors(
    orderDetail.products,
  )

  // Merge products local state
  for (const productInRedux of shallowClonedOrders[orderIndexInStore].detail
    .products) {
    const productIndexToUpdate = orderDetail.products.findIndex(
      p => p.id === productInRedux.id,
    )

    if (productIndexToUpdate === -1) {
      // If no product is found, it means it is a new product so it doesn't have any local state to merge.
      continue
    }

    orderDetail.products[productIndexToUpdate] = {
      ...productInRedux,
      ...orderDetail.products[productIndexToUpdate],
    }
  }
  // Merge whims local state
  for (const whimInRedux of shallowClonedOrders[orderIndexInStore].detail
    .whims) {
    const whimIndexToUpdate = orderDetail.whims.findIndex(
      w => w.id === whimInRedux.id,
    )

    if (whimIndexToUpdate === -1) {
      // If no whims is found, it means it is a new whim so it doesn't have any local state to merge.
      continue
    }

    orderDetail.whims[whimIndexToUpdate] = {
      ...whimInRedux,
      ...orderDetail.whims[whimIndexToUpdate],
    }
  }

  //orderDetail.products = orderDetail.products || [];
  orderDetail.stores = orderDetail.stores || []

  // Clone all existing properties of the order.
  // Clone all existing properties of the order detail and then update the object with the new info received from the service.
  // This helps to avoid deleting properties that are used only in the frontend app.

  shallowClonedOrders[orderIndexInStore] = {
    ...shallowClonedOrders[orderIndexInStore],
    detail: {
      ...shallowClonedOrders[orderIndexInStore].detail,
      ...orderDetail,
    },
  }

  return {
    ...state,
    orders: shallowClonedOrders,
  }
}

const getRejectionReasons = (rejectionReasons, state) => {
  for (const reason of rejectionReasons) {
    reason.isSelected = false
  }

  return {
    ...state,
    rejectionReasons: rejectionReasons || [],
  }
}

const getOrders = (orders, state, zoneId) => {
  let ordersBeingManagedToSet = []
  for (const order of orders) {
    const orderInStore = state.orders.find(o => o.id === +order.id)

    if (!orderInStore) {
      const orderToSave = produce(order, orderDraft => {
        orderDraft.detail = { products: [], user: {}, whims: [] }

        if (
          !orderDraft.partner_order_state ||
          orderDraft.partner_order_state.trim() === ''
        ) {
          orderDraft.partner_order_state = PartnerOrderStateType.NEWORDER
        }
        orderDraft.beingManaged = true
      })

      ordersBeingManagedToSet.push(orderToSave)
      continue
    }

    const orderToSet = {
      ...orderInStore,
      ...order,
      beingManaged: true,
    }

    //Set value by default for confirmed reception.
    orderToSet.confirmedReception = orderToSet.confirmedReception
      ? orderToSet.confirmedReception
      : false
    orderToSet.detail = orderInStore.detail || {
      products: [],
      user: {},
      whims: [],
    }
    if (
      !orderToSet.partner_order_state ||
      orderToSet.partner_order_state.trim() === ''
    ) {
      orderToSet.partner_order_state = PartnerOrderStateType.NEWORDER
    }
    ordersBeingManagedToSet.push(orderToSet)
  }

  // Concat not managed orders. This is necessary to keep in memory orders opened from historical.
  const notManagedOrders = state.orders.filter(order => !order.beingManaged)
  // Get only the ids of the orders to set.
  const ordersToSetIds = ordersBeingManagedToSet.map(
    orderToSet => orderToSet.id,
  )
  // Filter the nonManagedOrders that aren't yet in the orders to set.
  const notManagedOrdersNonRepeated = notManagedOrders.filter(
    notManagedOrder => ordersToSetIds.indexOf(notManagedOrder.id) === -1,
  )
  const totalOrdersToSet = ordersBeingManagedToSet.concat(
    notManagedOrdersNonRepeated,
  )

  return {
    ...state,
    orders: totalOrdersToSet,
    zoneId,
  }
}

const clearNetworkErrorCount = state => {
  return { ...state, failsV2Count: 0 }
}

const increaseNetworkErrorCount = state => {
  const { failsV2Count } = state

  const newFailsV2Count = failsV2Count + 1

  return {
    ...state,
    failsV2Count: newFailsV2Count,
  }
}
const clearFailServiceErrorCount = state => {
  return { ...state, failsErrorServiceCount: 0 }
}
const increaseFailServiceErrorCount = state => {
  const { failsErrorServiceCount } = state

  const newFailsErrorServiceCount = failsErrorServiceCount + 1

  return {
    ...state,
    failsErrorServiceCount: newFailsErrorServiceCount,
  }
}

const increaseCookingTimeToTakeOrder = (
  maxCookingTime,
  orderId,
  cookingTimeStep,
  state,
) => {
  return produce(state, stateDraft => {
    const order = stateDraft.orders.find(order => order.id === orderId)

    if (!order || !order.detail) {
      return
    }

    const cookingTimeToTakeOrder = order.detail.cookingTimeToTakeOrder

    if (cookingTimeToTakeOrder === maxCookingTime) {
      return
    }

    const newCookingTime =
      cookingTimeToTakeOrder +
      cookingTimeStep -
      order.detail.minCookingTimeOffset

    if (newCookingTime <= maxCookingTime) {
      order.detail.cookingTimeToTakeOrder = newCookingTime
      order.detail.maxCookingTimeOffset = 0
      order.detail.minCookingTimeOffset = 0

      return
    }

    order.detail.maxCookingTimeOffset = newCookingTime - maxCookingTime
    order.detail.cookingTimeToTakeOrder = maxCookingTime
    order.detail.minCookingTimeOffset = 0
  })
}

const setRTCounters = (state, rtsInStoreTotal, rtsOnTheWayTotal) => {
  return {
    ...state,
    rtsInStoreTotal,
    rtsOnTheWayTotal,
  }
}

/**
 * Sets the check network dialog state to opened or closed.
 * @param {bool} isCheckNetworkDialogOpened - Whether the dialog is going to be opened or not.
 * @param {object} state - Current state.
 */
const toggleCheckNetworkDialog = (isCheckNetworkDialogOpened, state) => {
  return { ...state, isCheckNetworkDialogOpened }
}

const toggleDialogExtraCT = (
  isAddMoreCookingTimeDialogOpened,
  orderId,
  state,
) => {
  if (!orderId) {
    return state
  }

  const shallowClonedOrders = [...state.orders]

  const orderIndexToUpdate = shallowClonedOrders.findIndex(
    order => order.id === +orderId,
  )

  if (
    orderIndexToUpdate === -1 ||
    !shallowClonedOrders[orderIndexToUpdate].detail
  ) {
    return state
  }

  const shallowCloneDetail = {
    ...shallowClonedOrders[orderIndexToUpdate].detail,
    isAddMoreCookingTimeDialogOpened,
  }

  shallowClonedOrders[orderIndexToUpdate] = {
    ...shallowClonedOrders[orderIndexToUpdate],
    detail: shallowCloneDetail,
  }

  return {
    ...state,
    orders: shallowClonedOrders,
  }
}

const toggleSupportChatDrawer = (
  orderId,
  state,
  isSupportChatDrawerOpened,
  supportScore,
) => {
  if (!orderId) {
    return state
  }

  const shallowClonedOrders = [...state.orders]

  const orderIndexToUpdate = shallowClonedOrders.findIndex(
    order => order.id === +orderId,
  )

  if (
    orderIndexToUpdate === -1 ||
    !shallowClonedOrders[orderIndexToUpdate].detail
  ) {
    return state
  }

  const shallowClonedDetail = {
    ...shallowClonedOrders[orderIndexToUpdate].detail,
    isSupportChatDrawerOpened,
    supportScore,
  }

  shallowClonedOrders[orderIndexToUpdate] = {
    ...shallowClonedOrders[orderIndexToUpdate],
    detail: shallowClonedDetail,
  }

  return {
    ...state,
    orders: shallowClonedOrders,
  }
}

const listenRTPosition = (bearing, lat, lng, precision, orderId, state) => {
  const selectedOrderIndex = state.orders.findIndex(
    orderInStore => orderInStore.id === orderId,
  )

  if (selectedOrderIndex === -1) {
    return state
  }

  const shallowClonedSelectedOrder = {
    ...state.orders[selectedOrderIndex],
  }

  shallowClonedSelectedOrder.detail = {
    ...shallowClonedSelectedOrder.detail,
    storekeeper: {
      ...shallowClonedSelectedOrder.detail.storekeeper,
      bearing,
      lat,
      lng,
      precision,
    },
  }

  const shallowClonedOrders = [...state.orders]

  shallowClonedOrders[selectedOrderIndex] = shallowClonedSelectedOrder

  return {
    ...state,
    orders: shallowClonedOrders,
  }
}

const listenOrderSupportChat = (orderId, supportChatMessages, state) => {
  const selectedOrderIndex = state.orders.findIndex(
    orderInStore => orderInStore.id === +orderId,
  )

  if (selectedOrderIndex === -1) {
    return state
  }

  const shallowClonedSelectedOrder = {
    ...state.orders[selectedOrderIndex],
  }

  shallowClonedSelectedOrder.detail = {
    ...shallowClonedSelectedOrder.detail,
    supportChatMessages,
  }

  const shallowClonedOrders = [...state.orders]

  shallowClonedOrders[selectedOrderIndex] = shallowClonedSelectedOrder

  return {
    ...state,
    orders: shallowClonedOrders,
  }
}

const listenOrderSupportNotifications = (
  orderId,
  supportNotificationsMessages,
  state,
) => {
  const selectedOrderIndex = state.orders.findIndex(
    orderInStore => orderInStore.id === +orderId,
  )

  if (selectedOrderIndex === -1) {
    return state
  }

  const shallowClonedSelectedOrder = {
    ...state.orders[selectedOrderIndex],
  }

  shallowClonedSelectedOrder.detail = {
    ...shallowClonedSelectedOrder.detail,
    supportNotificationsMessages,
  }

  const shallowClonedOrders = [...state.orders]

  shallowClonedOrders[selectedOrderIndex] = shallowClonedSelectedOrder

  return {
    ...state,
    orders: shallowClonedOrders,
  }
}

const toggleOrdersHistoricalFilterDrawer = (open, state) => {
  return { ...state, historicalFilter: { ...state.historicalFilter, open } }
}

const toggleOrdersListFilterDrawer = (open, state) => {
  return { ...state, filter: { ...state.filter, open } }
}

const removeOrder = (orderId, state) => {
  const shallowClonedOrders = [...state.orders].filter(
    order => order.id !== +orderId,
  )
  return { ...state, orders: shallowClonedOrders }
}

const setFirebaseStatus = (
  orderId,
  realTimeDatabase,
  realTimeStatus,
  state,
) => {
  const selectedOrderIndex = state.orders.findIndex(
    orderInStore => orderInStore.id === orderId,
  )

  if (selectedOrderIndex === -1) {
    return state
  }

  const shallowClonedSelectedOrder = {
    ...state.orders[selectedOrderIndex],
  }

  shallowClonedSelectedOrder.detail = {
    ...shallowClonedSelectedOrder.detail,
    realTimeDatabase,
    realTimeStatus,
  }

  const shallowClonedOrders = [...state.orders]

  shallowClonedOrders[selectedOrderIndex] = shallowClonedSelectedOrder

  return {
    ...state,
    orders: shallowClonedOrders,
  }
}

const setInitialCTToTakeOrder = (state, orderId, initialCTToTakeOrder) => {
  return produce(state, stateDraft => {
    const orderDetail = OrdersUtils.findOrderDetailById(
      stateDraft.orders,
      orderId,
    )
    if (!orderDetail) {
      return
    }
    orderDetail.cookingTimeToTakeOrder = initialCTToTakeOrder
    orderDetail.minCookingTimeOffset = 0
    orderDetail.maxCookingTimeOffset = 0
  })
}

const setOrdersHistoricalLoading = (state, isOrdersHistoricalLoading) => {
  return { ...state, isOrdersHistoricalLoading }
}

const setSearchFieldText = (searchFieldText, state) => {
  return {
    ...state,
    searchFieldText,
  }
}

const toggleIsGettingOrdersHistoricalFailedBeingShown = (
  state,
  gettingOrdersHistoricalFailedMessage = null,
  isGettingOrdersHistoricalFailedBeingShown,
) => {
  return produce(state, stateDraft => {
    stateDraft.gettingOrdersHistoricalFailedMessage = gettingOrdersHistoricalFailedMessage
    stateDraft.isGettingOrdersHistoricalFailedBeingShown = isGettingOrdersHistoricalFailedBeingShown
  })
}
