export const COUNTRY_PHONE_MAP = [
  { prefix: 54, format: '.. (..) .... ....', countryCode: 'ar' }, // Argentina
  { prefix: 52, format: '.. ... .... ....', countryCode: 'mx' }, // Mexico
  { prefix: 57, format: '.. (...) ... ....', countryCode: 'co' }, // Colombia
  { prefix: 55, format: '.. (..) .... ....', countryCode: 'br' }, // Brazil
  { prefix: 56, format: '.. (..) .... ....', countryCode: 'cl' }, // Chile
  { prefix: 598, format: '... ... ... ...', countryCode: 'uy' }, // Uruguay
  { prefix: 51, format: '.. (..) .... ....', countryCode: 'pe' }, // Peru
  { prefix: 506, format: '... .... ....', countryCode: 'cr' }, // Costa Rica
  { prefix: 593, format: '... . ... ....', countryCode: 'ec' }, // Ecuador
  { prefix: 5939, format: '... .. ... ....', countryCode: 'ec' }, // Ecuador mobile numbers
];

const formatNumber = (number, format) => {
  let formatted = '+';
  let numberIndex = 0;

  for (let i = 0; i < format.length && numberIndex < number.length; i++) {
    if (format[i] === '.') {
      formatted += number[numberIndex];
      numberIndex++;
    } else {
      formatted += format[i];
    }
  }

  return formatted.trim();
};

export const getFormattedValue = value => {
  if (!value) {
    return '';
  }

  const displayValue = value.replace(/\D/g, '');

  const matches = COUNTRY_PHONE_MAP.filter(c =>
    displayValue.startsWith(c.prefix),
  );

  if (matches.length > 0) {
    // The use the match with longer prefix
    const country = matches.sort((a, b) => b.prefix - a.prefix)[0];
    return formatNumber(displayValue, country.format);
  }

  return '+' + displayValue;
};
