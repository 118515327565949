import { validatePartnerSaturation } from '../domain';
import { highDemandBtnHandler } from '../infra';

import type { HighDemandBtnService } from '../domain';

export const highDemandBtnService = {
  get,
} as const;

function get(): ReturnType<HighDemandBtnService['get']> {
  const handler = highDemandBtnHandler.get();
  const { someActive, totalStores } = handler.storesListByStatus.reduce(
    (acc, store) => ({
      totalStores: acc.totalStores + store.total,
      someActive: acc.someActive || store.status === 'active',
    }),
    { someActive: false, totalStores: 0 },
  );
  const isSingleStore = totalStores <= 1;
  const isHidden = handler.saturation === 'hidden';
  const saturation = someActive ? handler.saturation : 'deactivated';
  const everyIsActive =
    handler.storesListByStatus.every(store => store.status === 'active') &&
    saturation !== 'fast' &&
    !isSingleStore;

  return {
    isHidden,
    isSingleStore,
    saturation: validatePartnerSaturation(saturation),
    everyIsActive,
  };
}
