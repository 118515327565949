import { useEffect, useState } from 'react';

import {
  cargoAddRTCreateCase,
  CargoUICase,
} from '../../../../../../business/cargo';
import { useDialogLang } from './useDialogLang';

import type { MouseEvent } from 'react';
import type {
  AddRTBehavior,
  ValidatedAddRT,
} from '../../../../../../business/cargo';
import { CargoAnalytics } from '../../../../../../business/cargo/analytics';
import type { OrderId } from '../../../../../../business/shared/domain';

export function useDialog(props: Props) {
  const { orderId, behavior } = props;

  const [partialValue, setPartialValue] = useState<number>(NaN);
  const [isErrorValue, setIsErrorValue] = useState<boolean>(false);

  const {
    openDialog,
    isLoading,
    dataToCreate,
    isCreationDenied,
    isCreationAccepted,
    createdOrderId,
    onCloseDialog,
    onCreateOrder,
  } = cargoAddRTCreateCase({ orderId });
  const {
    setStatus: onSetStatusMainCargo,
    onSetOpenDialog: onSetOpenKanbanCargo,
    showErrorDialog: openErrorMsg,
    showSuccessfulDialog: openSuccessMsg,
    partnerId,
  } = CargoUICase(); // TODO: need refactor!!

  const lang = useDialogLang({
    createdOrderId,
    deliveryCost: dataToCreate?.deliveryCost,
  });

  function handleChangePartialValue(value: number) {
    setPartialValue(value);
    if (!Boolean(value)) setIsErrorValue(true);
    setIsErrorValue(false);
  }

  function handleCloseDialog(event: MouseEvent) {
    event.stopPropagation();
    onCloseDialog();
  }

  function handleCreateOrder(event: MouseEvent) {
    event.stopPropagation();
    if (!Boolean(partialValue)) {
      setIsErrorValue(true);
      return;
    }
    onCreateOrder({
      payload: {
        ...(dataToCreate as ValidatedAddRT),
        marketplaceOrderId: orderId,
        partialValue,
      },
    });
  }

  useEffect(() => {
    if (isCreationAccepted) {
      CargoAnalytics.whenSendRequest(partnerId, behavior);
      onSetOpenKanbanCargo(true);
      onSetStatusMainCargo('list');
      onCloseDialog(); // is used to close the dialog of additional RT
      openSuccessMsg(lang.success.title, lang.success.description, 3_000);
    }
    if (isCreationDenied) {
      openErrorMsg(lang.error.title, lang.error.description, 5_000);
    }
  }, [isCreationAccepted, isCreationDenied]);

  return {
    lang,
    openDialog,
    isLoading,
    isErrorValue,
    dataToForm: {
      name: dataToCreate?.client.name,
      address: dataToCreate?.client.address,
      addressComplement: dataToCreate?.client.addressComplement,
      partialValue: partialValue || 0,
    },
    handleChangePartialValue,
    handleCloseDialog,
    handleCreateOrder,
  };
}

interface Props {
  orderId: OrderId;
  behavior: AddRTBehavior;
}
