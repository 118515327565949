import { makeStyles } from '@material-ui/core';

import { neutral } from '../../shared/styles/colors';

export default makeStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  text: {
    color: neutral['50'],
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    marginTop: '20px',
  },
});
