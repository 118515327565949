import React from 'react';
import RestoreIcon from '@material-ui/icons/Restore';
import { Box, makeStyles, Typography } from '@material-ui/core';

import { neutral } from '../../../../../../shared/styles/colors';

export function PreviousQueries(props: PreviousQueriesProps) {
  const { show = false, previousQueries = [], onClick = () => {} } = props;
  const classes = makeClasses();
  if (!show) return null;
  return (
    <Box component='aside' className={classes.queries}>
      {previousQueries.map(textOption => (
        <Box
          className={classes.query}
          onClick={() => onClick(textOption)}
          key={`text-option-${textOption.replaceAll(' ', '')}`}
        >
          <RestoreIcon />
          <Typography>{textOption}</Typography>
        </Box>
      ))}
    </Box>
  );
}

function makeClasses() {
  return makeStyles({
    queries: {
      position: 'absolute',
      padding: '0.5rem 1rem',
      display: 'flex',
      flexDirection: 'column',
      rowGap: '0.5rem',
      color: neutral.D17,
      backgroundColor: neutral.D2,
      top: 56,
      left: 0,
      right: 0,
      zIndex: 10,
    },
    query: {
      display: 'flex',
      columnGap: '0.5rem',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
        color: neutral.D20,
      },
    },
  })();
}

interface PreviousQueriesProps {
  show?: boolean;
  previousQueries?: string[];
  onClick(str: string): void;
}
