import { useRequestLang } from '../../request/hooks';
import { CargoRequestRtCase, CargoUICase } from '../../../../../business/cargo';

import type { RTRequest } from '../../../../../business/cargo';

export function useDialogData() {
  const {
    isOpenDialog,
    status: statusUI,
    setStatus,
    clearNewOrder,
  } = CargoUICase();

  const {
    newOrder,
    status,
    coverageStatus,
    setNewOrder,
    verifyCoverage,
    createOrder,
  } = CargoRequestRtCase();

  const { defaultValueAddressRef } = useRequestLang();

  const isLoading = status === 'isLoading';

  const isCheckingCoverage = statusUI === 'coverage';
  const isCargoOrderList = statusUI === 'list';
  const isCargoFormRequest = statusUI === 'form';

  const isCoverageAvailable = coverageStatus === 'hasCoverage';
  const hasNoCoverage = coverageStatus === 'noCoverage';
  const isNotCoverageAvailable = coverageStatus === 'notAvailable';

  const isRTRequestFormDisabled = () => {
    const {
      shippingCost,
      deliveryReferences,
      deliveryAddress,
      ...rest
    } = newOrder;
    const hasDeliveryAddress =
      !!deliveryAddress?.city &&
      !!deliveryAddress?.coordinates?.lat &&
      !!deliveryAddress?.coordinates?.lng;
    return Object.values(rest).some(value => !value) || !hasDeliveryAddress;
  };

  const isDisabled = () => {
    if (isCargoFormRequest) return isRTRequestFormDisabled();
    if (isCheckingCoverage) return isNotCoverageAvailable || hasNoCoverage;
  };

  const requestCoverage = () => {
    let value = { ...newOrder };
    if (!newOrder?.deliveryReferences) {
      value = {
        ...newOrder,
        deliveryReferences: defaultValueAddressRef,
      };
      setNewOrder({ deliveryReferences: defaultValueAddressRef });
    }
    verifyCoverage(value);
  };

  const createRequest = (value: Partial<RTRequest>) => {
    createOrder(value);
  };

  const handleCoverageBackClick = () => {
    if (isNotCoverageAvailable) clearNewOrder();
    if (isCoverageAvailable || hasNoCoverage) setStatus('form');
  };

  const handleCoverageNextClick = () => {
    if (hasNoCoverage) setStatus('list');
    if (isCoverageAvailable) createRequest(newOrder);
  };

  return {
    isOpenDialog,
    rtRequest: newOrder,
    requestCoverage,
    isLoading,
    isCheckingCoverage,
    isCargoOrderList,
    isCargoFormRequest,
    isRTRequestFormDisabled,
    isCoverageAvailable,
    hasNoCoverage,
    isNotCoverageAvailable,
    coverageStatus,
    createRequest,
    clearNewOrder,
    setStatus,
    isDisabled,
    handleCoverageBackClick,
    handleCoverageNextClick,
  };
}
