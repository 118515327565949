import { makeStyles } from '@material-ui/core';

export default makeStyles({
  rejectionReasonsContainer: {
    flex: '1 1 1px',
    overflow: 'auto',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '24px',
  },
  title: {
    marginBottom: '24px',
  },
  rejectionReasonItem: {
    height: '4.5rem',
    fontSize: 14,
  },
});
