const EventCodes = {
  ADD_WHIM_SERVICE_ERROR: 'ADD_WHIM_SERVICE_ERROR',
  ADD_WHIM_START_FLOW: 'ADD_WHIM_START_FLOW',
  ADD_WHIM_SUCCESS: 'ADD_WHIM_SUCCESS',
  DELETE_WHIM_SUCCESS: 'DELETE_WHIM_SUCCESS',
  DIAGNOSTIC_CLICK: 'DIAGNOSTIC_CLICK',
  ERROR_PWEX: 'ERROR_PWEX',
  EVENT_NEW_ORDER: 'event_new_order',
  EVENT_OPEN_ORDER_DETAIL: 'event_open_order_detail',
  EVENT_ORDER_CHAT_USER_FLOW: 'EVENT_ORDER_CHAT_USER_FLOW',
  EVENT_ORDER_DELIVERED: 'event_order_delivered',
  EVENT_ORDER_READY: 'event_order_ready',
  EVENT_ORDER_TAKEN: 'event_order_taken',
  EVENT_ORDER_MANAGE_MENU: 'EVENT_ORDER_MANAGE_MENU',
  EVENT_ORDER_MANAGE_PRODUCTS: 'EVENT_ORDER_MANAGE_PRODUCTS',
  EVENT_ORDER_DO_LATER: 'EVENT_ORDER_DO_LATER',
  EVENT_ORDER_IM_READY: 'EVENT_ORDER_IM_READY',
  SWITCH_OFF_PRODUCT: 'BUTTON_TURNOFF_PRODUCT',
  HELP_CENTER_OPEN_ARTICLE: 'HELP_CENTER_OPEN_ARTICLE',
  HELP_CENTER_OPEN_CATEGORY: 'HELP_CENTER_OPEN_CATEGORY',
  SELECT_ARTICLE_RA: 'SELECT_ARTICLE_RA',
  LOG_IN: 'event_login',
  LOG_OUT: 'event_logout',
  NEW_ORDER_RAW_RECEPTION: 'NEW_ORDER_RAW_RECEPTION',
  NOTIFICATION_ACTION_CLOSE: 'NOTIFICATION_ACTION_CLOSE',
  NOTIFICATION_ACTION_CTA: 'NOTIFICATION_ACTION_CTA',
  OPEN_TOPPING_MASSIVE_STOCKOUT_DRAWER: 'OPEN_TOPPING_MASSIVE_STOCKOUT_DRAWER',
  ORDER_TOTALS_DETAIL_RETRY: 'ORDER_TOTALS_DETAIL_RETRY',
  PARTNER_DISCONNECTION: 'PARTNER_DISCONNECTION',
  PARTNER_CONNECTION: 'PARTNER_CONNECTION',
  NETWORK_CONNECTION: 'NETWORK_CONNECTION',
  NETWORK_DISCONNECTION: 'NETWORK_DISCONNECTION',
  NOTIFICATION_CENTER_OPEN: 'NOTIFICATION_CENTER_OPEN',
  NOTIFICATION_CTA: 'NOTIFICATION_CTA',
  PROXY_SESSION_END_ERROR: 'proxy_session_end_error',
  PROXY_SESSION_END_SUCCESS: 'proxy_session_end_success',
  PROXY_SESSION_INPUT_ERROR: 'proxy_session_input_error',
  PROXY_SESSION_START_FLOW: 'proxy_session_start_flow',
  RELEASE_RT_ERROR: 'release_rt_error',
  RELEASE_RT_SUCCESS: 'release_rt_success',
  SAVE_TOPPING_MASSIVE_STOCKOUT_DRAWER: 'SAVE_TOPPING_MASSIVE_STOCKOUT_DRAWER',
  SUPPORT_SELECT_REASON: 'SUPPORT_SELECT_REASON',
  SUPPORT_SELECT_SOLUTION: 'SUPPORT_SELECT_SOLUTION',
  SUPPORT_START_FLOW: 'SUPPORT_START_FLOW',
  SEND_SUGGESTION: 'SEND_SUGGESTION',
  REJECT_ORDER: 'REJECT_ORDER',
  NOTIFY_RT: 'NOTIFY_RT',
  EVENT_ORDER_FIND_RT: 'EVENT_ORDER_FIND_RT',
  CLICK_REPORT_RT: 'BUTTON_CLICKED_REPORT_RT',
  CLICK_FREEUP_RT: 'BUTTON_CLICKED_FREEUP_RT',
  START_ONBOARDING_FLOW: 'START_ONBOARDING_FLOW',
  END_ONBOARDING_FLOW: 'END_ONBOARDING_FLOW',
  DONWLOAD_CHROME_EXTENSION: 'DONWLOAD_CHROME_EXTENSION',
  STOCKOUT_MODAL_1: 'STOCKOUT_MODAL_1',
  PM_MODAL_INTRO: 'PM_MODAL_INTRO',
  PM_MODAL_FINISH: 'PM_MODAL_FINISH',
  LOGIN_SCREEN_OPTIONS: 'LOGIN_SCREEN_OPTIONS',
  SIDEBAR_SELECTION: 'SIDEBAR_SELECTION',
  LOGIN_ERROR: 'LOGIN_ERROR',
  CLICK_SUMMARY: 'CLICK_SUMMARY',
  CLICK_SUMMARY_CTA: 'CLICK_SUMMARY_CTA',
  CLICK_FILTER: 'CLICK_FILTER',
  TOGGLE_ALL: 'TOGGLE_ALL',
  CLICK_CTA: 'CLICK_CTA',
  SWITCH_ON_AUTOACCEPT_ORDERS: 'AUTOACCEPT_ORDERS_CONFIRMATION',
  EVENT_PRINTED_ORDER: 'EVENT_PRINTED_ORDER',
  EVENT_SUSPENSION_LIFTING: 'EVENT_SUSPENSION_LIFTING',
};

export default EventCodes;
