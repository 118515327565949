import { clsx } from 'clsx';

import { BaseCustomAlert } from '../base-custom-alert';

import useStyles from './styles';

import type { BaseProps } from '../../../../../interfaces';

export function SuccessCustomAlert({ classes, ...extraProps }: Props) {
  const _classes = useStyles();
  return (
    <BaseCustomAlert
      classes={{ root: clsx(_classes.root, classes?.root) }}
      {...extraProps}
    />
  );
}

interface Props extends BaseProps {
  text?: string | null;
}
