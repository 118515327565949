import DialogAlertIcon from '../icons/dialog-alert-icon';

import useStyles from './OrderDetailDialogErrorBody.styles';

function OrderDetailDialogErrorBody(props) {
  const { message, expressionText, title } = props;
  const componentClasses = useStyles();
  return (
    <div className={componentClasses.root}>
      <DialogAlertIcon />
      <div className={componentClasses.infoContainer}>
        <div className={componentClasses.headerContainer}>
          <div className={componentClasses.expresionContainer}>
            {expressionText}
          </div>
          <div className={componentClasses.titleContainer}>{title}</div>
        </div>
        {!!message && (
          <div className={componentClasses.messageContainer}>{message}</div>
        )}
      </div>
    </div>
  );
}

export default OrderDetailDialogErrorBody;
