import { Box } from '@material-ui/core';
import { OrderMgmtCard } from '../order-mgmt-card/OrderMgmtCard';
import { OrderMgmtProduct } from '../../../../business/order-mgmt';
import { TimeoutProducts } from '../../timeout';

export const OrderMgmtProducts = ({
  products,
  orderId,
  isEnabled = true,
  isTimeoutState,
}: Props) => {
  if (isTimeoutState) {
    return <TimeoutProducts />;
  }
  return (
    <Box overflow='auto'>
      {products.map(product => (
        <OrderMgmtCard
          key={`c-${product.id}`}
          product={product}
          orderId={orderId}
          isEnabled={isEnabled}
        />
      ))}
    </Box>
  );
};

interface Props {
  products: Array<OrderMgmtProduct>;
  orderId: number;
  isEnabled?: boolean;
  isTimeoutState: boolean;
}
