import { useSelector } from 'react-redux';
import OrderDetailDialogSelectors from '../../../../../../redux/selectors/order-detail-dialog-selectors/orderDetailDialog.selectors';
import OrdersSelector from '../../../../../../redux/selectors/orders-selectors/orders.selectors';

const _selectOrderId = () =>
  useSelector(OrderDetailDialogSelectors.selectOrderId);
const _selectOrders = () => useSelector(OrdersSelector.selectOrders);

export const orderSelectors = {
  getAll: () => _selectOrders(),
  orderId: () => _selectOrderId(),
  order: () => ({
    ..._selectOrders().find(x => x?.id === _selectOrderId()),
  }),
  detail: () => ({
    ..._selectOrders().find(x => x?.id === _selectOrderId())?.detail,
  }),
};
