import { clsx } from 'clsx';
import { Backdrop, CircularProgress } from '@material-ui/core';

import useStyles from './CustomLoader.styles';

function CustomLoader(props) {
  const {
    classes = {},
    invisible,
    onClick = () => {},
    open = false,
    size = 48,
    zIndexType = null,
  } = props;

  const _classes = useStyles({ size, zIndexType });

  return (
    <>
      {open && (
        <CircularProgress
          size={size}
          color='primary'
          classes={{
            root: clsx(
              _classes.customCircularProgressRoot,
              classes?.customCircularProgressRoot,
            ),
          }}
        />
      )}
      <Backdrop
        classes={{ root: clsx(_classes.backdropRoot, classes?.backdropRoot) }}
        invisible={invisible}
        open={open}
        onClick={onClick}
      />
    </>
  );
}

export default CustomLoader;
