import { makeStyles } from '@material-ui/core';

import { StoreStatusDTO } from '../../../../shared/infra';
import { neutral } from '../../../../shared/styles/colors';
import { storeStatusStatusOpts } from '../../constants';

export default makeStyles({
  button: ({ status, isSingleStore }: msDropdownBtnProps) => ({
    padding: 0,
    fontSize: '0.75rem',
    color: isSingleStore
      ? storeStatusStatusOpts[status]?.fontColor
      : neutral[100],
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }),
  buttonStartIcon: {
    margin: '0 0.25rem',
  },
  status: ({ status }: msDropdownBtnProps) => ({
    backgroundColor: storeStatusStatusOpts[status]?.color,
    width: '0.375rem',
    height: '0.375rem',
    borderRadius: '50%',
  }),
  drawerPaper: {
    backgroundColor: neutral.white,
  },
  popoverPaper: {
    maxWidth: '24rem',
  },
  drawerCloseBtn: {
    margin: '0 0 1rem',
    padding: '1.125rem',
    fontSize: '1rem',
    lineHeight: 1.25,
    color: neutral.D17,
  },
});

interface msDropdownBtnProps {
  status: StoreStatusDTO;
  isSingleStore?: boolean;
}
