import axios from 'axios';
import { get } from 'lodash';
import history from '../history';
// Consts
import urls from '../constants/Urls';
// Services
import appStore from './../redux/stores/app.store';
import LocalStorageService from './local-storage-service';

function getAuthorizationHeader() {
  const accessToken = localStorage.getItem('accessToken');

  return accessToken ? `Bearer ${accessToken}` : '';
}

function redirectToLogin() {
  history.replace('/login');
  return;
}

function redirectToLoginCleaningStorage() {
  LocalStorageService.clear();

  redirectToLogin();
  return;
}

const refreshToken = async () => {
  let baseUrl = LocalStorageService.getBaseUrl();

  const refreshTokenDTO = {
    refresh_token: LocalStorageService.getRefreshToken(),
  };
  return axios.post(`${baseUrl}${urls.REFRESH_TOKEN}`, refreshTokenDTO);
};

// Headers configuration.
axios.defaults.headers.common[
  'event-origin'
] = `${process.env.REACT_APP_EVENT_ORIGIN}`;
axios.defaults.headers.common[
  'APP-VERSION'
] = `${process.env.REACT_APP_APP_VERSION}`;

axios.interceptors.request.use(config => {
  if (!LocalStorageService.isAuthenticated()) {
    // If the user doesn't have a session in localStorage, return.
    return config;
  }

  const url = get(config, 'url', null);

  if (!!url && url.indexOf(urls.GET_ALL_AVAILABLE_COUNTRIES) === -1) {
    // Available countries service mustn't have token bearer.
    config.headers.Authorization = getAuthorizationHeader();
  }
  const profile = appStore.getProfile();
  if (!profile || !profile.id) {
    // If no profile is set in redux store, return.
    return config;
  }
  config.headers['AUTH_USER'] = profile.id;
  return config;
});

axios.interceptors.response.use(undefined, async error => {
  if (
    !error.response ||
    error.response.status !== 401 ||
    window.location.pathname === '/login'
  ) {
    return Promise.reject(error.response);
  }
  if (error.response.config.url.includes(urls.REFRESH_TOKEN)) {
    redirectToLoginCleaningStorage();
    console.warn('401 at consuming Refresh Token', error.response);
    return Promise.reject(error);
  }
  if (!error.response.headers) {
    redirectToLoginCleaningStorage();
    console.warn('no headers', error.response);
    return Promise.reject(error);
  }

  const xRefreshToken: string | null | undefined =
    error.response.headers['x-refresh-token'];

  if (xRefreshToken !== 'true') {
    console.warn('xrefreshToken invalid', error.response.headers);
    redirectToLoginCleaningStorage();
    return Promise.reject(error);
  }

  try {
    const { data: response } = await refreshToken();

    LocalStorageService.setAccessToken(response.access_token);
    const missingURL = error.config.url;
    const missingMethod = error.config.method;
    const missingData = error.config.data;
    console.warn('retry request', missingURL, missingMethod, missingData);
    const axiosRequest = axios({
      method: missingMethod,
      url: missingURL,
      data: missingData,
    });
    return axiosRequest;
  } catch (ex) {
    console.warn('error retrying request', ex);
    redirectToLoginCleaningStorage();
    return Promise.reject(error);
  }
});

export default {
  all: axios.all,
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch
};
