import { useFormatMessage } from '../../intl/intl';
import { orderTypeAsFlags } from './OrderTypeChip.util';

import type { OrderType } from './OrderTypeChip.type';

export function useOrderTypeChipTexts(props: Props) {
  const { orderType } = props;
  const { isBundle } = orderTypeAsFlags(orderType);

  const orderTypeBaseId = 'orderType.chip';
  const bundleId = `${orderTypeBaseId}.bundle`;
  const priorityId = `${orderTypeBaseId}.priority`;

  return useFormatMessage({ id: isBundle ? bundleId : priorityId });
}

interface Props {
  orderType: OrderType;
}
