import { ChevronRightRounded } from '@material-ui/icons';
import { Box, Button, Typography } from '@material-ui/core';

import makeClasses from './dropdown-option.style';

import type { ButtonProps } from '@material-ui/core/Button';

export function DropdownOption(props: OptionProps) {
  const { title, description, onClick, disabled, startIcon } = props;
  const _classes = makeClasses();
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={<ChevronRightRounded />}
      classes={{ root: _classes.btnRoot, label: _classes.btnLabel }}
    >
      <Box flex={1}>
        <Typography component='h5' className={_classes.title}>
          {title}
        </Typography>
        <Typography component='span' className={_classes.description}>
          {description}
        </Typography>
      </Box>
    </Button>
  );
}

type OptBtnProps = Pick<ButtonProps, 'onClick' | 'disabled' | 'startIcon'>;

interface OptionProps extends OptBtnProps {
  title: string;
  description: string;
}
