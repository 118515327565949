//React
import { connect } from 'react-redux'
// Actions
import menuActions from '../../redux/actions/menu.actions'
// Components
import ToppingsMassiveDeactivationDrawer from './ToppingsMassiveDeactivationDrawer'
import StringUtils from '../../utils/StringUtils'

const mapStateToProps = ({
  menuReducer: {
    filter,
    toppingsMassiveDeactivationDrawer: {
      open,
      storeId,
      toppings,
      suggestedToppings,
      deactivationType,
      deactivationDate,
    },
    menus,
  },
  partnerReducer,
}) => {
  const deactivatedToppingNamesList = StringUtils.doubleQuotesConcat(
    toppings,
    'description',
  )

  suggestedToppings = suggestedToppings.map(t => ({
    ...t,
    selected: false,
  }))
  return {
    deactivatedToppingNamesList,
    deactivationType,
    deactivationDate,
    open,
    storeId,
    suggestedToppings,
    partnerId: partnerReducer.profile.id,
  }
}

const mapDispatchToProps = {
  onClose: menuActions.closeToppingsMassiveDeactivationDrawer,
  onConfirm: menuActions.deactivateToppings,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToppingsMassiveDeactivationDrawer)
