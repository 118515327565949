// External libs
import { createAction } from '@reduxjs/toolkit';
// Services
import PMAutomationService from '../../../services/PM-automation-service';
// Actions
import AppActions from '../../actions/app-actions/app.actions';
// Interfaces
import { Interval, Day } from '../interfaces';
// Utils
import utils from '../utils';

const closeAction = createAction('schedulePMFlowDialog/close');

const clearAction = createAction('schedulePMFlowDialog/clear');

const maximizeAction = createAction('schedulePMFlowDialog/maximize');

const cancelEditionAction = createAction<{ intervalId: number }>(
  'schedulePMFlowDialog/cancelEdition',
);
const setScheduleAction = createAction<{ days: Day[]; intervals: Interval[] }>(
  'schedulePMFlowDialog/setSchedule',
);

const openAction = createAction<{
  formId: number;
  days: Day[];
  intervals: Interval[];
}>('schedulePMFlowDialog/open');

const setBeingEditedIntervalIdAction = createAction<{
  beingEditedIntervalId: number | null;
}>('schedulePMFlowDialog/beingEditedIntervalId');

const createNewIntervalAction = createAction<{ day: string }>(
  'schedulePMFlowDialog/createNewInterval',
);

const clear = () => (dispatch: any) => {
  dispatch(clearAction());
};

const close = () => (dispatch: any) => {
  dispatch(closeAction());
};

const maximize = () => (dispatch: any) => dispatch(maximizeAction());

const getSchedule = async (formId: number): Promise<any> => {
  const response = await PMAutomationService.getPMAutomationStoreSchedule(
    formId,
  );
  const days = utils.getDays();
  const intervals = utils.getIntervals(response.data);
  return [days, intervals];
};
const setSchedule = (formId: number) => async (dispatch: any) => {
  const [days, intervals] = await getSchedule(formId);
  dispatch(setScheduleAction({ days, intervals }));
};
const endEdition = (formId: number) => async (dispatch: any) => {
  await dispatch(setSchedule(formId));
  dispatch(setBeingEditedIntervalId(null));
};

const open = (formId: number) => async (dispatch: any) => {
  try {
    const [days, intervals] = await getSchedule(formId);
    dispatch(openAction({ formId, days, intervals }));
  } catch (ex) {
    AppActions.handleCatch(ex, true)(dispatch);
  }
};
const deleteInterval = (formId: number, id: number) => async (
  dispatch: any,
) => {
  try {
    await PMAutomationService.deleteDaySchedule(formId, id);
    await dispatch(setSchedule(formId));
  } catch (ex) {
    AppActions.handleCatch(ex, true)(dispatch);
  }
};

const cancelIntervalEdition = (intervalId: number) => async (dispatch: any) => {
  dispatch(cancelEditionAction({ intervalId }));
};
const confirmIntervalEdition = (
  intervalId: number,
  formId: number,
  startsTime: string,
  endsTime: string,
  day: string,
) => async (dispatch: any) => {
  try {
    if (intervalId === 0) {
      await dispatch(createInterval(formId, day, startsTime, endsTime));
    } else {
      await dispatch(updateInterval(intervalId, formId, startsTime, endsTime));
    }
  } catch (ex) {
    AppActions.handleCatch(ex, true)(dispatch);
  }
};

const updateInterval = (
  intervalId: number,
  formId: number,
  startsTime: string,
  endsTime: string,
) => async (dispatch: any) => {
  await PMAutomationService.updateDaySchedule(
    intervalId,
    formId,
    startsTime,
    endsTime,
  );
  await dispatch(endEdition(formId));
};
const createInterval = (
  formId: number,
  day: string,
  startsTime: string,
  endsTime: string,
) => async (dispatch: any) => {
  await PMAutomationService.createDaySchedule(
    formId,
    day,
    startsTime,
    endsTime,
  );
  await dispatch(endEdition(formId));
};
const setBeingEditedIntervalId = (beingEditedIntervalId: number | null) => (
  dispatch: any,
) => {
  dispatch(setBeingEditedIntervalIdAction({ beingEditedIntervalId }));
};
const createNewInterval = (day: string) => (dispatch: any) => {
  dispatch(createNewIntervalAction({ day }));
};

const actions = {
  clear,
  clearAction,
  close,
  closeAction,
  maximize,
  maximizeAction,
  open,
  openAction,
  deleteInterval,
  updateInterval,
  setBeingEditedIntervalId,
  setBeingEditedIntervalIdAction,
  createInterval,
  createNewInterval,
  createNewIntervalAction,
  setScheduleAction,
  cancelIntervalEdition,
  cancelEditionAction,
  confirmIntervalEdition,
};

export default actions;
