import { useMemo } from 'react';

import { Day } from '../day';
import Typography, { Variants, FontWeight } from '../../../common/typography';

import utils from '../../../../redux/schedule-PM-flow-dialog/utils';
import useStyles from './styles';

import type { ScheduleDay } from '../../../../redux/schedule-PM-flow-dialog';

export function DayGroup({ scheduleDay = [], hourList = [] }: Props) {
  const componentClasses = useStyles();

  const [weekdays, weekends] = useMemo(() => {
    return utils.partionByDayType(scheduleDay);
  }, [scheduleDay]);

  return (
    <div className={componentClasses.root}>
      <Typography
        classes={{ root: componentClasses.weekdaysLabel }}
        variant={Variants.Subheadline}
        fontWeight={FontWeight.Bold}
      >
        {'Semana'}
      </Typography>
      <div className={componentClasses.weekdaysContainer}>
        {weekdays.map((day, index: number) => {
          return <Day key={index} day={day} hourList={hourList} />;
        })}
      </div>
      <Typography
        classes={{ root: componentClasses.weekendsLabel }}
        variant={Variants.Subheadline}
        fontWeight={FontWeight.Bold}
      >
        {'Fin de Semana'}
      </Typography>
      <div className={componentClasses.weekendsContainer}>
        {weekends.map((day, index: number) => {
          return <Day key={index} day={day} hourList={hourList} />;
        })}
      </div>
    </div>
  );
}

interface Props {
  week: string;
  hourList: any[];
  scheduleDay: ScheduleDay[];
}
