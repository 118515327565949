import { clsx } from 'clsx';

import Typography, { Variants } from '../typography';

import useStyles from './styles';

import type { FontWeight } from '../typography';
import type { BaseProps } from '../../../interfaces';

export function CustomKeyValueBox(props: Props) {
  const {
    value,
    label,
    labelFontWeight,
    valueFontWeight,
    labelVariant = Variants.Caption1,
    valueVariant = Variants.Caption1,
    classes = {},
  } = props;

  const _classes = useStyles();

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <Typography
        classes={{ root: clsx(classes?.label) }}
        fontWeight={labelFontWeight}
        variant={labelVariant}
      >
        {label}
      </Typography>
      <Typography
        classes={{ root: clsx(classes?.value) }}
        fontWeight={valueFontWeight}
        variant={valueVariant}
      >
        {value}
      </Typography>
    </div>
  );
}

interface Props extends BaseProps {
  label: string;
  labelVariant?: Variants;
  labelFontWeight?: FontWeight;
  value: number | string;
  valueVariant?: Variants;
  valueFontWeight?: FontWeight;
}
