import type { AnalyticEventProps, PropertiesType } from './analytics.model';

function _camelToSnake(camelCase: string, isLegacy: boolean): string {
  const transform = camelCase.replace(/([A-Z])/g, '_$1');
  return isLegacy ? camelCase : transform.toUpperCase();
}

function _transformBoolean(value: boolean, typing: string[]) {
  return value ? typing[0] : typing[1];
}

/**
 * Transforms an object of parameters to a format suitable for use in Amplitude analytics events,
 * converting keys from `camelCase` to `snake_case` and transforming boolean values as required.
 *
 * @param {object} params - parameters required for the event
 * @param {string[]} [booleanParse=['true', 'false']] - Array of strings representing boolean values in the result
 * @param {string[]} [skip=[]] - Array of Event properties to be excluded
 * @returns {PropertiesType}  Object with transformed parameters with amplitude analytics requirements
 */
export function transformToAnalyticsParams(
  params: PropertiesType,
  { booleanParse = ['true', 'false'], skip = [] }: AnalyticEventProps = {},
): PropertiesType {
  const result: PropertiesType = {};
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const snakeKey = _camelToSnake(key, skip.includes(key));
      result[snakeKey] =
        typeof params[key] === 'boolean'
          ? _transformBoolean(params[key], booleanParse)
          : params[key];
    }
  }
  return result;
}
