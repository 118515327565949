// Material UI
import { makeStyles } from '@material-ui/core';
// Constant
import { neutral } from '../../../../shared/styles/colors';

export default makeStyles({
  button: {
    border: `1px solid ${neutral.D10}`,
    color: neutral.D15,
    padding: '6px',
  },
  icon: {
    color: neutral.D15,
  },
});
