import { neutral } from '../../shared/styles/colors';

export default theme => ({
  boxContent: {
    height: '100%',
  },
  boxRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  breadcrumbRoot: {
    borderBottom: `1px solid ${neutral.D10}`,
  },
  root: {
    backgroundColor: neutral.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginTop: 24,
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
  menuNavigation: {
    backgroundColor: neutral.white,
    boxShadow: `0 1px 0 0 ${neutral.D10}`,
    margin: '-23px -8px 24px',
    padding: '20px 16px',
    '& span': {
      color: neutral.D15,
      fontSize: 13,
      '&:last-child': {
        color: neutral.D20,
      },
    },
    '& img': {
      margin: '0 4px',
      paddingBottom: 3,
      verticalAlign: 'bottom',
    },
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    overflow: 'hidden',
  },
  listSecondaryActionRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.only('xs')]: {
      right: 14,
    },
  },
  optionsButton: {
    marginRight: -6,
  },
  header: {
    alignItems: 'center',
    borderBottom: `1px solid ${neutral.D10}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '11px 24px',
  },
  searchInputRoot: {
    maxWidth: 290,
  },
});
