const textLabel = { id: 'suggestedProductComment.textLabel' }
const replaceProduct = { id: 'suggestedProductComment.replaceProduct' }
const comments = { id: 'comments' }

const textIds = {
  comments,
  textLabel,
  replaceProduct,
}

export default textIds
