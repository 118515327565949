// Redux
import { createAction } from '@reduxjs/toolkit';

const closeAction = createAction('orderRejectionByMotoboyLackDialog/close');

const clearAction = createAction('orderRejectionByMotoboyLackDialog/clear');

const openAction = createAction<{ orderId: number; refuseReason: any }>(
  'orderRejectionByMotoboyLackDialog/open',
);

const clear = () => (dispatch: any) => {
  dispatch(clearAction());
};

const close = () => (dispatch: any) => {
  dispatch(closeAction());
};

const open = (orderId: number, refuseReason: any) => (dispatch: any) => {
  dispatch(openAction({ orderId, refuseReason }));
};

const actions = {
  clear,
  clearAction,
  close,
  closeAction,
  open,
  openAction,
};

export default actions;
