import { formatCurrency } from '../../../../utils/CurrencyUtils';
import { ItemSuggestion } from '../../domain/models';
import { ItemSearchRequest } from '../core/requests';

const toSearchRequest = (
  item: ItemSuggestion,
  query: string,
): ItemSearchRequest => ({
  order_id: item?.orderId ?? 0,
  name: query.trim(),
  is_product: !!item?.isProduct,
  product_id: Number(item?.productId),
  topping_id: item?.isProduct ? null : item?.id,
});
const toItemSearchResult = (response: any): ItemSuggestion => ({
  id: response?.id ?? 0,
  img: response?.image ?? '',
  name: response?.name ?? response?.description,
  price: formatCurrency(response?.price || 0),
  realPrice: response?.real_price ?? response?.price,
  description: response?.description ?? '',
  categoryId: response?.topping_category_id ?? 0,
  categoryIndex: response?.index ?? 0,
  ageRestriction: !!response?.age_restriction,
  hasToppings: !!response?.has_toppings,
  productId: response?.product_id ?? 0,
});

export const orderMgmtSearchAdapter = {
  toSearchRequest,
  toItemSearchResult,
};
