// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => ({
  root: ({
    smWidth,
    xsWidth,
    showBackButton,
  }: {
    smWidth?: string;
    xsWidth?: string;
    showBackButton: boolean;
  }) => ({
    width: xsWidth,
    [theme.breakpoints.up('sm')]: {
      width: smWidth,
    },
  }),
  paper: ({
    smWidth,
    xsWidth,
    showBackButton,
  }: {
    smWidth?: string;
    xsWidth?: string;
    showBackButton: boolean;
  }) => ({
    width: xsWidth,
    [theme.breakpoints.up('sm')]: {
      width: smWidth,
    },
  }),
  headerContainer: {
    alignItems: 'center',
    borderBottom: `1px solid ${neutral.D10}`,
    display: 'flex',
    flexDirection: 'row',
    padding: '28px 4px 4px 16px',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      padding: '38px 4px 20px 16px',
    },
  },
  childrenContainer: {
    display: 'flex',
    flex: '1 1 100%',
    flexDirection: 'column',
    overflow: 'auto',
    position: 'relative',
  },
  title: {
    fontFamily: 'Poppins Bold',
    fontSize: '1.25em',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: neutral.D20,
  },
  titleContainer: ({
    smWidth,
    xsWidth,
    showBackButton,
  }: {
    smWidth?: string;
    xsWidth?: string;
    showBackButton: boolean;
  }) => ({
    alignItems: 'center',
    display: 'flex',
    marginLeft: showBackButton ? '-12px' : '0px',
  }),
}));
