import { useFormatMessage } from '../../../../intl/intl';

export function useDrinksAndBagsLang() {
  return {
    bagsQuantityText: useFormatMessage({
      id: 'orderDetailDialogBagsDrinksBox.bagsQuantity',
    }),
    checkCodeText: useFormatMessage({
      id: 'orderDetailDialogBagsDrinksBox.checkCode',
    }),
    outsideDrinksText: useFormatMessage({
      id: 'orderDetailDialogBagsDrinksBox.outsideDrinks',
    }),
    titleText: useFormatMessage({
      id: 'orderDetailDialogBagsDrinksBox.title',
    }),
    loadingText: useFormatMessage({
      id: 'orderDetailDialogBagsDrinksBox.loading',
    }),
    successText: useFormatMessage({
      id: 'orderDetailDialogBagsDrinksBox.success',
    }),
    errorText: useFormatMessage({
      id: 'orderDetailDialogBagsDrinksBox.error',
    }),
    retryText: useFormatMessage({
      id: 'orderDetailDialogBagsDrinksBox.retry',
    }),
    yesText: useFormatMessage({
      id: 'orderDetailDialogBagsDrinksBox.yes',
    }),
    noText: useFormatMessage({
      id: 'orderDetailDialogBagsDrinksBox.no',
    }),
    completeOrderDetailsPlease: useFormatMessage({
      id: 'orderDetailDialogBagsDrinksBox.mandatoryInput.completeOrderDetailsPlease'
    }),
    confirmOrderDetails: useFormatMessage({
      id: 'orderDetailDialogBagsDrinksBox.mandatoryInput.confirmOrderDetails'
    }),
    required: useFormatMessage({
      id: 'orderDetailDialogBagsDrinksBox.mandatoryInput.required'
    })

  };
}
