// Material UI
import { makeStyles } from '@material-ui/core'
// Constant
import Colors from '../../../constants/Colors'

export default makeStyles(theme => ({
  root: {
    alignItems: 'center',
    backgroundColor: Colors.WHITE,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
  },
  separator: {
    margin: '0px 4px',
  },
  text: {
    fontSize: '13px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
}))
