import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../shared/styles/colors';

export default makeStyles({
  root: {
    borderBottom: `1px solid ${neutral['20']}`,
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'auto min-content',
    padding: 16,
  },
  feedbackBox: {
    padding: 16,
  },
  button: {
    textAlign: 'right',
  },
});
