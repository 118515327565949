import { useSelector } from 'react-redux';

import { selectors as helpCenterSelectors } from '../../../../redux/help-center-page';

const useSelectedArticle = () =>
  useSelector(helpCenterSelectors.selectSelectedArticle);

const useSelectedCategoryId = () =>
  useSelector(helpCenterSelectors.selectedCategoryId);

const useSelectedSubCategoryId = () =>
  useSelector(helpCenterSelectors.selectedSubCategoryId);

const useSection = () =>
  useSelector(helpCenterSelectors.selectSelectedCategory);

const useSelectedCategory = () =>
  useSelector(helpCenterSelectors.selectSelectedCategory);

const hooks = {
  useSelectedArticle,
  useSelectedCategoryId,
  useSection,
  useSelectedCategory,
  useSelectedSubCategoryId,
};

export default hooks;
