import { Button } from '@material-ui/core';

import { useOrderDetailWhim } from './hooks';
import { DialogDeleteWhimFromOrder } from './comps';

import makeClasses from './OrderDetailWhim.styles';

import type { OrderDetailWhimProps } from './orderDetailWhim.model';

export function OrderDetailWhim(props: OrderDetailWhimProps) {
  const { id, name, price, showDeleteBtn } = props;

  const {
    lang,
    isOpenDialog,
    formattedPrice,
    handleOpenDialog,
    handleCloseDialog,
  } = useOrderDetailWhim({ price });

  const _classes = makeClasses();

  return (
    <aside className={_classes.content}>
      <h1 className={_classes.title}>{lang.title}</h1>
      <main className={_classes.whimContent}>
        <p>{name}</p>
        <p>{formattedPrice}</p>
      </main>
      {showDeleteBtn && (
        <>
          <Button
            color='secondary'
            onClick={handleOpenDialog}
            classes={{ root: _classes.deleteWhim }}
          >
            {lang.delete}
          </Button>
          <DialogDeleteWhimFromOrder
            whimId={id}
            open={isOpenDialog}
            onClose={handleCloseDialog}
          />
        </>
      )}
    </aside>
  );
}
