import { useFormatMessage } from '../../../../../intl/intl';

export const useOrderMgmtAcceptOrderDialogLang = () => {
  const messageCategory = 'orderManagement';
  const messageSubcategory = 'acceptOrderDialog';

  const cancelButtonText = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.cancelButton`,
  });

  const confirmButtonText = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.confirmButton`,
  });

  const acceptOrderDescriptionText = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.description`,
  });

  const acceptOrderDisclaimerText = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.disclaimer`,
  });

  const acceptOrderTitleText = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.title`,
  });

  return {
    cancelButtonText,
    confirmButtonText,
    acceptOrderDescriptionText,
    acceptOrderDisclaimerText,
    acceptOrderTitleText,
  };
};
