import React from 'react';
import BrokenImageIcon from '@material-ui/icons/BrokenImageOutlined';
import { Box, Checkbox, makeStyles, Typography } from '@material-ui/core';

import Colors from '../../../../../../constants/Colors';

import type { OrderMgmtItem } from '../../../../../../business/order-mgmt';
import { ChipOrderDisclaimer } from '../../../../components/order-mgmt-dialog/components';
import { neutral } from '../../../../../../shared/styles/colors';

export function SuggestionItem(props: SuggestionItemProps) {
  const {
    name,
    price,
    img = '',
    checked = false,
    disabled = false,
    onClick = () => {},
    hasToppings,
    disclaimer = '',
  } = props;
  const classes = makeClasses();

  return (
    <Box component='article' onClick={onClick} className={classes.resultItem}>
      <Box component='figure' className={classes.itemImageContainer}>
        {img ? (
          <img src={img} alt={'IMG'} className={classes.itemImage} />
        ) : (
          <BrokenImageIcon fontSize='large' />
        )}
      </Box>
      <Box flex={1}>
        <Typography className={classes.itemName} component={'p'}>
          {name}
        </Typography>
        <Typography className={classes.itemPrice} component={'span'}>
          {price}
        </Typography>
        {hasToppings && (
          <ChipOrderDisclaimer
            disclaimer={disclaimer}
            type='warning'
            styles={{ container: classes.itemChipDisclaimer }}
            hasIcon
          />
        )}
      </Box>
      <Checkbox
        color='primary'
        checked={checked}
        disabled={disabled}
        classes={{ root: classes.itemCheckbox }}
      />
    </Box>
  );
}

function makeClasses() {
  return makeStyles({
    resultItem: {
      width: '100%',
      padding: '1rem',
      display: 'flex',
      gap: '0.5rem',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: neutral.D4,
      borderBottom: `1px solid ${neutral.D11}`,
      '&:hover': {
        backgroundColor: `${Colors.PRIMARY_COLOR_DARK}28`,
      },
    },
    itemImageContainer: {
      display: 'flex',
      margin: 0,
      minWidth: '2.625rem',
      maxWidth: '2.625rem',
      maxHeight: '2.625rem',
      overflow: 'hidden',
      objectFit: 'cover',
      alignItems: 'center',
    },
    itemImage: {
      width: '100%',
    },
    itemName: {
      fontSize: '0.875rem',
      fontWeight: 600,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    itemPrice: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    itemCheckbox: {
      padding: 0,
    },
    itemChipDisclaimer: {
      fontWeight: 600,
      margin: 0,
    },
  })();
}

interface SuggestionItemProps
  extends Pick<OrderMgmtItem, 'name' | 'price' | 'img' | 'hasToppings'> {
  checked: boolean;
  disabled: boolean;
  onClick(): void;
  disclaimer?: string;
}
