// Interfaces
import { Step } from './interfaces'
//Interfaces
import { StepResponse } from '../../services/onboarding-service'
import { OnBoardingDialogType } from '../../constants/OnBoardingDialogType'
import { default as EnumUtils } from '../../utils/EnumUtils/EnumUtils'

const parseToStep = (onboarding: StepResponse): Step => {
  return {
    code: onboarding.code || '',
    index: onboarding.index || 0,
    type: EnumUtils.getKeyByValueOrDefault<OnBoardingDialogType>(
      OnBoardingDialogType,
      OnBoardingDialogType.Info,
      onboarding.type,
    ),
    title: onboarding.title || '',
    description: onboarding.description || '',
    image_xs: onboarding.image_xs || '',
    image_sm: onboarding.image_sm || '',
    image_md: onboarding.image_md || '',
    image_lg: onboarding.image_lg || '',
    image_xl: onboarding.image_xl || '',
  }
}

const formatTitle = (steps: Step[]): Step[] => {
  let tipIndex = 1

  return steps.map(step => {
    if (
      step.type === OnBoardingDialogType.Info ||
      step.type === OnBoardingDialogType.Tour
    ) {
      tipIndex = 1
      return { ...step }
    }

    const formattedTitle = `Tip ${tipIndex}. ${step.title}`
    tipIndex++
    return { ...step, title: formattedTitle }
  })
}

const parseToStepArray = (stepResponses: StepResponse[]): Step[] => {
  return formatTitle(stepResponses.map(parseToStep))
}

const utils = { parseToStepArray }

export default utils
