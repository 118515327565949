import { useDispatch } from 'react-redux';

import Hooks from './hooks';
import { useFormatMessage } from '../../intl/intl';
import NotificationsDrawerActions from '../../redux/actions/notifications-drawer-actions';
import SuspendedStoreAlertSnackbarActions from '../../redux/actions/suspended-store-alert-snackbar-actions';

import { CustomAlertSnackbar } from '../common/custom-alert-snackbar';

export function SuspendedStoreAlertSnackbar() {
  const showMoreButtonText = useFormatMessage({
    id: 'suspendedStoreAlertSnackbar.showMoreButtonText',
  });

  const understoodButtonText = useFormatMessage({
    id: 'suspendedStoreAlertSnackbar.understoodButtonText',
  });

  const dispatch = useDispatch();

  const message = Hooks.useMessage();
  const open = Hooks.useOpen();

  const actions = [
    {
      onClick: () => {
        SuspendedStoreAlertSnackbarActions.close()(dispatch);
      },
      text: understoodButtonText,
    },
    {
      onClick: () => {
        SuspendedStoreAlertSnackbarActions.close()(dispatch);
        NotificationsDrawerActions.open()(dispatch);
      },
      text: showMoreButtonText,
    },
  ];

  return (
    <CustomAlertSnackbar
      buttons={actions}
      message={message}
      onClickCancel={() => SuspendedStoreAlertSnackbarActions.close()(dispatch)}
      open={open}
    />
  );
}
