import { MainKanbanCard } from '../main-kanban-card';
import OrderListChipBox from '../order-list-chip-box';
import { CustomKanbanColumn } from '../common/custom-kanban-column';

import { useCookingColumn } from './hooks';

export function CookingColumn() {
  const {
    text,
    ordersInCooking,
    numberOfOrdersInCooking,
    showEmptyTextOfOrders,
  } = useCookingColumn();

  return (
    <CustomKanbanColumn
      Chip={<OrderListChipBox />}
      title={text.title}
      emptyText={text.noOrders}
      count={numberOfOrdersInCooking}
      showEmpty={showEmptyTextOfOrders}
    >
      {ordersInCooking.map(order => (
        <MainKanbanCard key={`cooking-${order.id}`} order={order} />
      ))}
    </CustomKanbanColumn>
  );
}
