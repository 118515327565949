import { clsx } from 'clsx';

import { InStoreRTStateLabel } from './components/in-store-rt-state-label';
import { OnTheWayRTStateLabel } from './components/on-the-way-rt-state-label';
import { SearchRTStateLabel } from './components/searching-rt-state-label';
import { RtWaitingSinceLabel } from './components/rt-waiting-since-label';

import useStyles from './RTStateLabel.styles';

import { RTStepType } from '../../business/shared/domain';

const getRTStateComponent = (rtStep, isRtWaitingMuch) => {
  switch (rtStep) {
    case RTStepType.onTheWay:
      return OnTheWayRTStateLabel;
    case RTStepType.inStore:
      return isRtWaitingMuch ? RtWaitingSinceLabel : InStoreRTStateLabel;
    default:
      return SearchRTStateLabel;
  }
};

function RTStateLabel(props) {
  const {
    classes = {},
    rtStep = 1,
    rtStepMessage = '',
    isRtWaitingMuch = false,
    rtWaitingSince = 0,
    isGeoqueue,
    zoneId,
    ...extraProps
  } = props;
  const componentClasses = useStyles();
  const RTStateComponent = getRTStateComponent(rtStep, isRtWaitingMuch);
  return (
    <RTStateComponent
      classes={{
        root: clsx(componentClasses.root, classes?.root),
        iconRoot: clsx(componentClasses.iconRoot, classes?.iconRoot),
      }}
      isGeoqueue={isGeoqueue}
      {...extraProps}
      stateMessage={rtStepMessage}
      rtWaitingSince={rtWaitingSince}
      // showCounter={isRtWaitingMuch}
      zoneId={zoneId}
    />
  );
}

export default RTStateLabel;
