import { connect } from 'react-redux';

import ToppingBoxesList from './ToppingBoxesList';

const mapStateToProps = ({ menuReducer: { filter, menus } }) => {
  const selectedStoreId = +filter.selectedStoreId;
  const products = menus[selectedStoreId]?.products ?? [];
  const toppingCategories = menus[selectedStoreId]?.toppings ?? [];

  return {
    products,
    toppingCategories,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ToppingBoxesList);
