import { makeStyles } from '@material-ui/core';
import { neutral } from '../../../../../../shared/styles/colors';

export default makeStyles({
  title: {
    color: neutral.D16,
    fontSize: '0.625rem',
    fontWeight: 600,
    letterSpacing: '1.2px',
    textTransform: 'uppercase',
  },
});
