// External libs
import { createSelector } from 'reselect'

const selectSlice = (state: any) => state.successfulSuggestingProductDialog

const selectOpen = createSelector(selectSlice, reducer => reducer.open)

const selectedSuggestion = createSelector(
  selectSlice,
  reducer => reducer.suggestion,
)
const selectedProduct = createSelector(
  selectSlice,
  reducer => reducer.selectedProduct,
)

const selectors = {
  selectOpen,
  selectedSuggestion,
  selectedProduct,
}

export default selectors
