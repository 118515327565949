import { makeStyles } from '@material-ui/core';
import { neutral, orange, yellow } from '../../../../../shared/styles/colors';

export const geoqueuesRTSectionStyles = makeStyles(theme => ({
  icon: {
    position: 'relative',
    height: '2rem',
    width: '2rem',
  },
  message: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    color: neutral['70'],
  },
  messageStrong: {
    fontWeight: 600,
    color: neutral['80'],
  },
  root: {
    color: neutral['90'],
    display: 'grid',
    alignItems: 'center',
    gridColumnGap: 6,
    gridAutoFlow: 'column',
    gridAutoColumns: 'min-content auto',
    fontSize: '0.75rem',
    lineHeight: 'normal',
  },
  disclaimerWrapper: {
    backgroundColor: yellow[10],
    color: orange[60],
    borderRadius: '0.5rem',
  },
  disclaimerText: {
    fontSize: '0.75rem',
  },
}));
