import { useSelector } from 'react-redux';

import Selectors from '../selectors';

const useTotalCharge = () => useSelector(Selectors.selectTotalCharge);

const Hooks = {
  useTotalCharge,
};

export default Hooks;
