import { useDispatch } from 'react-redux';
import { Button, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { hooks } from './hooks';

import EventActions from '../../../redux/actions/event-actions/event.actions';

import { storeCtaOpts } from '../constants';
import useCStyles from './ToggleAllStoreStatus.styles';

import type { TpStoreCtaOpts } from '../types';

export function ToggleAllStoreStatus() {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isToggleOff, setIsToggleOff] = useState(true);

  const dispatch = useDispatch();
  const partnerId = hooks.usePartnerId();
  const storeDetails = hooks.useStoreDetails();
  const texts = hooks.useTexts({ isToggleOff });
  const toggleAllStores = hooks.useActionToTogglePartnerState({ isToggleOff });

  const classes = useCStyles();

  const handleToggleStores = () => {
    dispatch(toggleAllStores());
    if (!isToggleOff) {
      setLoading(true);
      EventActions.onToggleAllStoreStates({
        partnerId,
        action: storeCtaOpts['cta.activate'].amplitud?.action,
        storeIds: storeDetails
          .filter(item => item.cta_type === storeCtaOpts['cta.activate'].cta)
          .map(item => item.store_id),
      });
    }
  };

  const existSomeCtaType = (ctaType: TpStoreCtaOpts) => {
    return storeDetails.some(item => item.cta_type === ctaType);
  };

  useEffect(() => {
    setLoading(false);
    if (existSomeCtaType(storeCtaOpts['cta.deactivate'].cta)) {
      setDisabled(false);
      setIsToggleOff(true);
      return;
    }
    if (existSomeCtaType(storeCtaOpts['cta.activate'].cta)) {
      setDisabled(false);
      setIsToggleOff(false);
      return;
    }
    setDisabled(true);
    setIsToggleOff(true);
    // eslint-disable-next-line
  }, [storeDetails]);

  return (
    <Button
      size='large'
      color='primary'
      variant='outlined'
      onClick={handleToggleStores}
      disabled={disabled || loading}
      classes={{ root: classes.root, label: classes.label }}
      endIcon={loading ? <CircularProgress size='1rem' /> : null}
    >
      {texts.toggleStores}
    </Button>
  );
}
