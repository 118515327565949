import React from 'react';
import { Box, Tooltip } from '@material-ui/core';
import useStyles from './CustomOutlineEditButton.styles';
import { EditPencilIcon } from '../../../icons/edit-pencil-icon';

export const CustomOutlineEditButton = ({
  onClick,
  isInEditionMode,
  isDisabled = false,
  showTooltip = false,
  tooltipText = '',
}: Props) => {
  const styles = useStyles({ isInEditionMode, isDisabled });

  return (
    <CustomOutLineEditButtonWrapper
      condition={showTooltip}
      wrapper={children => (
        <Tooltip
          classes={{ tooltip: styles.tooltip }}
          title={tooltipText}
          placement='bottom'
          arrow
        >
          {children}
        </Tooltip>
      )}
    >
      <Box
        className={styles.CustomOutlineEditButton}
        display='flex'
        justifyContent='center'
        alignItems='center'
        width='2.375rem'
        height='2.375rem'
        onClick={isDisabled ? null : onClick}
      >
        <EditPencilIcon
          isInEditionMode={isInEditionMode}
          isDisabled={isDisabled}
        />
      </Box>
    </CustomOutLineEditButtonWrapper>
  );
};

interface Props {
  onClick: any;
  isInEditionMode: boolean;
  isDisabled?: boolean;
  showTooltip?: boolean;
  tooltipText?: string;
}

const CustomOutLineEditButtonWrapper = ({ condition, wrapper, children }) => {
  return condition ? wrapper(children) : children;
};
