import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { IntervalBox } from '../interval-box';
import { EmptyInterval } from '../empty-interval';
import { CustomAddButton } from '../../../common/buttons/custom-add-button';
import Typography, { Variants, FontWeight } from '../../../common/typography';
import { CustomAddMobileButton } from '../../../common/buttons/custom-add-mobile-button';

import hooks from './hooks';
import { useFormatMessage } from '../../../../intl/intl';
import { actions as schedulePMFlowDialogActions } from '../../../../redux/schedule-PM-flow-dialog';

import textIds from './textIds';
import useStyles from './styles';

import type { ScheduleDay } from '../../../../redux/schedule-PM-flow-dialog';
import type { Interval } from '../../../../redux/schedule-PM-flow-dialog';

export function Day({ day, hourList = [] }: Props) {
  const componentClasses = useStyles();
  const beingEdited = hooks.useBeingEditedIntervalId();
  const addmobileLabel = useFormatMessage(textIds.addmobileLabel);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useDispatch();

  const handleClickAddInterval = useCallback(() => {
    dispatch(schedulePMFlowDialogActions.createNewInterval(day.code));
  }, [dispatch, day.code]);

  return (
    <div className={componentClasses.root}>
      <Typography
        classes={{ root: componentClasses.dayNameContainer }}
        variant={Variants.Sublabel}
        fontWeight={FontWeight.Bold}
      >
        {day.dayName}
      </Typography>
      <div className={componentClasses.detailContainer}>
        <div className={componentClasses.intervalsContainer}>
          {day.intervals.length === 0 ? (
            <EmptyInterval />
          ) : (
            day.intervals.map((interval: Interval, index: number) => {
              return (
                <IntervalBox
                  key={index}
                  interval={interval}
                  hourList={hourList}
                />
              );
            })
          )}
        </div>
        {isMdUp ? (
          <CustomAddButton
            classes={{
              icon: componentClasses.icon,
              button: componentClasses.button,
            }}
            disabled={beingEdited !== null}
            onClick={handleClickAddInterval}
          />
        ) : (
          <CustomAddMobileButton
            classes={{
              icon: componentClasses.iconMobile,
              button: componentClasses.buttonMobile,
            }}
            textLabel={addmobileLabel}
            disabled={beingEdited !== null}
            onClick={handleClickAddInterval}
          />
        )}
      </div>
    </div>
  );
}

interface Props {
  day: ScheduleDay;
  hourList: string[];
}
