import { makeStyles } from '@material-ui/core';

import { red, teal, yellow } from '../../../shared/styles/colors';

export default makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    height: '1.5rem',
    boxShadow: 'none',
  },
  logo: {
    height: '0.75rem',
  },
  openPartnerState: { background: teal['50'] },
  closedPartnerState: { background: yellow['50'] },
  deactivatedPartnerState: { background: red['50'] },
});
