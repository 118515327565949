// React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Components
import RappiLogoAppBar from './RappiLogoAppBar'

const mapStateToProps = ({
  partnerReducer: {
    profile: { partnerState },
  },
}) => {
  return {
    partnerState,
  }
}
const mapDispatchToProps = {}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(RappiLogoAppBar),
)
