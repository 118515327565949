const title = {
  id: 'endPMFlowDialog.title',
}
const cancelButton = {
  id: 'endPMFlowDialog.cancelButton',
}
const submitButton = {
  id: 'endPMFlowDialog.submitButton',
}
const description = {
  id: 'endPMFlowDialog.description',
}
const bannerDescription = {
  id: 'endPMFlowDialog.bannerDescription',
}
const textIds = {
  title,
  submitButton,
  cancelButton,
  description,
  bannerDescription,
}

export default textIds
