import { clsx } from 'clsx';
import Step from '@material-ui/core/Step';
import Stepper from '@material-ui/core/Stepper';
import Tooltip from '@material-ui/core/Tooltip';
import StepLabel from '@material-ui/core/StepLabel';

import CustomStepConnector from './../custom-step-connector';

import useStyles from './CustomStepper.styles';

export function CustomStepper(props) {
  const { activeStep, activeColor, steps, classes = {} } = props;

  const _classes = useStyles({ activeColor });

  const connector = (
    <CustomStepConnector activeColor={activeColor} alternativeLabel />
  );

  return (
    <Stepper
      classes={{
        root: clsx(_classes.stepConnectorRoot, classes?.root),
        alternativeLabel: clsx(
          _classes.alternativeLabel,
          classes?.alternativeLabel,
        ),
      }}
      activeStep={activeStep}
      alternativeLabel
      connector={connector}
    >
      {steps.map(step => (
        <Tooltip key={step.index} title={step.message}>
          <Step>
            <StepLabel
              classes={{
                root: clsx(_classes.stepLabelRoot, classes?.stepLabelRoot),
                iconContainer: clsx(classes?.iconContainer),
                label: clsx(_classes.label, classes?.label),
              }}
              StepIconProps={{
                classes: {
                  active: clsx(
                    _classes.stepIconActive,
                    classes?.stepIconActive,
                  ),
                  completed: clsx(
                    _classes.stepIconCompleted,
                    classes?.stepIconCompleted,
                  ),
                  root: clsx(_classes.stepIconRoot, classes?.stepIconRoot),
                },
              }}
            >
              {step.name}
            </StepLabel>
          </Step>
        </Tooltip>
      ))}
    </Stepper>
  );
}
