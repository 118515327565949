import {
  getIsTimeoutState,
  OrderMgmtProduct,
  OrderMgmtTopping,
} from '../domain';
import {
  orderDetailAdapter,
  orderMgmtAdapter,
  OrderMgmtDisclaimerAdapter,
} from '../infra/adapters';
import {
  OMProduct,
  OMToppingDTO,
  OrderDetailResponse,
  OrderManagementResponse,
  ProductResponse,
} from '../infra/core/responses';
import { orderMgmtDetailFacade } from '../infra/store/order-detail';

export function orderDetailApp() {
  const {
    selectOrderId,
    selectOrderDetail: orderDetail,
    selectProductToRemove,
    selectToppingsToRemove,
    selectProducts,
    selectStartedAt,
    onAcceptOrder,
    onAddProducts,
    onAddToppingIdList,
    onSetOrderId,
    onSetOrderState,
    onDisabledAutoTake
  } = orderMgmtDetailFacade();

  const get = () => {
    const products = orderDetail?.products;
    const orderManagement = orderDetail?.state_order_management ?? null;
    const hasOrderManagement =
      orderDetail?.state_order_management != null &&
      orderDetail?.partner_order_state !== 'cooking';
    const showProductsWithOM =
      hasOrderManagement &&
      orderDetail?.state_order_management?.state !== 'accepted';
    const orderId = orderDetail?.id;
    const storeId = orderDetail?.stores?.[0]?.store_id || 0;
    const isEnabledToEdit = orderDetail?.is_enabled;
    const createdAt = orderDetail?.created_at;

    return {
      products,
      orderManagement,
      hasOrderManagement,
      showProductsWithOM,
      orderId,
      storeId,
      isEnabledToEdit,
      createdAt,
    };
  };

  const handle = () => {
    let toppings: OrderMgmtTopping[] = [];
    const orderManagement: OrderManagementResponse =
      orderDetail?.state_order_management;

    onSetOrderId(orderDetail.id);
    onSetOrderState(orderManagement.state);

    if (orderManagement?.toppings) {
      const { toppings2OM, filtered } = handleToppings(
        orderManagement?.toppings,
      );
      toppings = toppings2OM;
      onAddToppingIdList(filtered);
    }
    const orderProducts = [...orderDetail?.products];
    const orderMgmtProducts = [...(orderManagement?.products || [])];
    const products = handleProducts(orderMgmtProducts, orderProducts, toppings);
    onAddProducts(products);
  };

  const handleProducts = (
    productsOM: OMProduct[],
    productsOrder: ProductResponse[],
    toppings: OrderMgmtTopping[],
  ): OrderMgmtProduct[] => {
    const productsSuggestions = [
      ...productsOM.filter(x => !!x?.replacement_product_options?.length),
    ].map(x => OrderMgmtDisclaimerAdapter.toOrderMgmtProduct(x));

    const products = productsOrder.map(p =>
      orderMgmtAdapter.toOrderMgmtProduct(p),
    );

    return products.map(product => {
      const suggestions = productsSuggestions.find(
        s => product.pivot === parseInt(s.id), //? This condition is established by the back team. It should be id vs id but it's ***
      )?.suggestions;

      return {
        ...product,
        id: `${product.pivot}`,
        hasSuggestions: !!suggestions?.length,
        suggestions,
        toppings: product.toppings?.map(
          topping =>
            toppings.find(
              t => t.id === topping.id && t.productId === topping.productId,
            ) ?? topping,
        ),
      };
    });
  };

  const handleToppings = (dto: OMToppingDTO[]) => {
    const toppings = [...(dto ?? [])];
    try {
      const productIdList = [...new Set(toppings.map(x => x.order_product_id))];
      const filtered = productIdList.map(productId => {
        return {
          product_id: productId,
          topping_list: toppings
            .filter(topping => topping.order_product_id === productId)
            .map(x => x.topping_id),
        };
      });
      const toppings2OM = toppings.map(x =>
        OrderMgmtDisclaimerAdapter.toOrderMgmtTopping(x),
      );

      return { filtered, toppings2OM };
    } catch (ex) {
      return {
        filtered: [],
        toppings2OM: toppings.map(x =>
          OrderMgmtDisclaimerAdapter.toOrderMgmtTopping(x),
        ),
      };
    }
  };

  const acceptOrderWithTimeout = async (order: OrderDetailResponse) => {
    const products = selectProductToRemove.map(x => parseInt(x));
    const payload = await orderDetailAdapter.toAcceptTimeoutRequest(
      order,
      products,
      selectToppingsToRemove,
    );
    onAcceptOrder(selectOrderId, payload);
  };

  const totalProductsInOrder = selectProducts.length;

  const disabledAutoTakeInOrder = (state: boolean) => {
    const payload = { order_id: selectOrderId, state };
    onDisabledAutoTake(payload);
  };

  const isTimeoutOrder = getIsTimeoutState(
    orderDetail?.state_order_management?.state,
  );

  return {
    get,
    isTimeoutOrder,
    handle,
    acceptOrderWithTimeout,
    totalProductsInOrder,
    disabledAutoTakeInOrder,
    selectOrderId,
    selectStartedAt
  };
}
