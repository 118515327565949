import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';

import hooks from './hooks';
import MainLayout from '../main-layout';
import { useFormatMessage } from '../../intl/intl';
import { CustomStateBlock } from '../common/custom-state-block';
import { StoreManagerHeader } from './components/store-manager-header';
import { StoreManagerStatusList } from './components/store-manager-status-list';

import PartnerActions from '../../redux/actions/partners-actions';
import EventActions from '../../redux/actions/event-actions/event.actions';

import { getStoresStatusBadges } from './utils';

import useStyles from './StoreManagerPage.styles';
import { textIds } from './consts';
import { storeStatusStatusOpts as SSSOpts } from '../store-status/constants';

import type { TpStoreStatusStatus } from '../store-status/types';
import type { IStoreStatusBadge, IStoreManager } from './interfaces';

export function StoreManagerPage() {
  const componentClasses = useStyles();
  const title = useFormatMessage(textIds.title);
  const stores = hooks.useStoreStatusDetail();
  const isStoresStatusDetailLoading = hooks.useIsStoreStatusDetailLoading();
  const isSwitchPartnerStoresStatusLoading = hooks.useIsSwitchPartnerStoresStatusLoading();
  const isLoading =
    isStoresStatusDetailLoading || isSwitchPartnerStoresStatusLoading;
  const dispatch = useDispatch();
  const profile = hooks.useProfile();
  const [filteredText, setFilteredText] = useState<string>('');
  const [filterStatusBadge, setFilterStatusBadge] = useState<
    TpStoreStatusStatus | ''
  >('');
  const [filteredStores, setFilteredStores] = useState<IStoreManager[]>([]);

  const storeStatusBadges: IStoreStatusBadge[] = getStoresStatusBadges([
    ...stores,
  ]);
  const showStoreManagerContent = !!filteredStores.length;
  const showEmptyState = !filteredStores.length && !isLoading;
  const emptyStoresStateText = useFormatMessage({
    id: 'storeManager.emptyState',
  });
  const customEmptyStateBlockProps = { text: emptyStoresStateText ?? '' };
  const isSingleStore = stores?.length === 1;

  useEffect(() => {
    if (profile.id) dispatch(PartnerActions.getStoreStatusDetail());
  }, [profile.id, dispatch]);

  useEffect(() => {
    if (!filteredText) setFilteredStores([...stores]);
    if (filteredText) {
      setFilterStatusBadge(ps => (ps ? '' : ps));
      const FILTERED_STORES = stores.filter(({ store_name }: IStoreManager) =>
        store_name.toLowerCase().includes(filteredText.toLowerCase()),
      );
      setFilteredStores([...FILTERED_STORES]);
    }
  }, [filteredText, stores]);

  useEffect(() => {
    if (!filterStatusBadge) setFilteredStores([...stores]);
    if (filterStatusBadge) {
      setFilteredText(ps => (ps ? '' : ps));
      const FILTERED_STORES = stores.filter(
        ({ status }: IStoreManager) => status === filterStatusBadge,
      );
      setFilteredStores([...FILTERED_STORES]);
      EventActions.onClickFilterOnStoreAdminPage({
        partnerId: profile.id,
        filterType: SSSOpts[filterStatusBadge].amplitudFilterType,
      });
    }
  }, [filterStatusBadge, stores, profile]);

  return (
    <MainLayout
      classes={{ sectionContent: componentClasses.sectionContent }}
      title={title}
    >
      <Backdrop
        style={{ zIndex: 2 }}
        open={!stores.length && isStoresStatusDetailLoading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <StoreManagerHeader
        filteredText={filteredText}
        setFilteredText={setFilteredText}
        filterStatusBadge={filterStatusBadge}
        storeStatusBadges={storeStatusBadges}
        setFilterStatusBadge={setFilterStatusBadge}
      />
      {showStoreManagerContent && (
        <StoreManagerStatusList
          partnerId={profile.id}
          filteredStores={filteredStores}
          isSingleStore={isSingleStore}
        />
      )}
      {showEmptyState && <CustomStateBlock {...customEmptyStateBlockProps} />}
    </MainLayout>
  );
}
