import { useSelector } from 'react-redux';

import { useLang } from './useLang';
import OrdersSelectors from '../../../redux/selectors/orders-selectors';

export function useOrderDetailDialogMarketplaceBox() {
  const paymentMethod = useSelector(
    OrdersSelectors.selectOrderDetailPaymentMethodInOrderDetailDialog,
  );

  const lang = useLang({ paymentMethod });

  return {
    lang,
  };
}
