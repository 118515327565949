// External libs
import { createAction } from '@reduxjs/toolkit'
// Services
import partnersService from '../../../services/partners-service'
// Actions
import AppActions from '../../actions/app-actions/app.actions'
// External libs
import { get } from 'lodash'
// Urils
import utils from '../utils'
// Interface
import { LogOutReasons } from '../interfaces'

const closeAction = createAction('logOutReasonsDialog/close')

const clearAction = createAction('logOutReasonsDialog/clear')

const maximizeAction = createAction('logOutReasonsDialog/maximize')

const openAction = createAction('logOutReasonsDialog/open')

const loadReasonsAction = createAction<LogOutReasons[]>(
  'logOutReasonsDialog/loadReasons',
)

const clear = () => (dispatch: any) => {
  dispatch(clearAction())
}

const close = () => (dispatch: any) => {
  dispatch(closeAction())
}

const maximize = () => (dispatch: any) => dispatch(maximizeAction())

const open = () => async (dispatch: any) => {
  try {
    const response = await partnersService.getLogOutReasons()
    const reasons = utils.parseToLogOutReasons(get(response, 'data', []))
    dispatch(loadReasonsAction(reasons))
    dispatch(openAction())
  } catch (ex) {
    await dispatch(AppActions.handleCatch(ex))
  }
}

const actions = {
  clear,
  clearAction,
  close,
  closeAction,
  maximize,
  maximizeAction,
  loadReasonsAction,
  open,
  openAction,
}

export default actions
