// React
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import RootActions from '../../redux/actions/root.actions';
// Components
import ExternalAuthPage from './ExternalAuthPage';

const mapStateToProps = (state, { location: { search } }) => {
  const params = new URLSearchParams(search).get(search);
  return {
    access: params.get('access'),
    lat: params.get('lat'),
    lng: params.get('lng'),
    refresh: params.get('refresh'),
  };
};

const mapDispatchToProps = {
  authExternally: RootActions.authExternally,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ExternalAuthPage),
);
