import { Events } from './Analytics.const';
import Amplitude from '../../../../tools/amplitude/amplitude';

export const AnalyticsActions = {
  logHighDemandApply,
};

function logHighDemandApply(params) {
  Amplitude.logEvent(Events.HDB.name, params);
}
