import _ from 'lodash';

import OrderStateType from './../constants/OrderStateType';
import PaymentMethodType from './../constants/PaymentMethod';
import { DELIVERY_METHOD_OPTION } from '../business/shared/domain';
import PartnerOrderStateType from './../constants/PartnerOrderStateType';

import RTUtils from './RTUtils';
import DateTimeUtils from './DateTimeUtils';

const emitNotificationRTSound = (orders = []) => {
  return orders.some(order => order?.notification_rtsound);
};

const filterBeingManaged = orders => {
  if (!orders) {
    return [];
  }
  return orders.filter(order => isBeingManaged(order));
};

const filterBeingManagedCanceled = orders => {
  return orders.filter(order => order.beingManaged && isCanceled(order.state));
};

const filterBySearchText = (orders, searchFieldText) => {
  if (!orders) {
    return [];
  }
  if (!searchFieldText || searchFieldText.trim() === '') {
    return orders;
  }

  return orders.filter(
    order => order.id.toString().indexOf(searchFieldText) > -1,
  );
};

const filterByStores = (orders, storeIds = []) => {
  const ensuredStoreIds = storeIds.map(storeId => +storeId);
  return orders.filter(order => ensuredStoreIds.indexOf(order.store_id) > -1);
};

const filterCanceled = orders => {
  return orders.filter(order => isCanceled(order.state));
};

const filterCooking = orders => {
  return orders.filter(order => isBeingCooking(order.partner_order_state));
};

const filterBeingCookingWithExpiredCT = (orders, tz) => {
  const cookingOrders = filterCooking(orders);

  return cookingOrders.filter(order => {
    const acceptanceTime = order.cooking_time_started_at;
    const ct = order.cooking_time;

    if (!acceptanceTime || ct === undefined || ct === null) {
      return false;
    }

    const diffFromNowInMinutes = DateTimeUtils.getDiffFromNow(
      acceptanceTime,
      'minutes',
      tz,
    );

    return diffFromNowInMinutes - ct > 0;
  });
};

const filterFullDelivery = orders => {
  return orders.filter(order => isFullDelivery(order.delivery_method));
};
const filterFullDeliveryAndMarketplace = orders => {
  return orders.filter(
    order =>
      isFullDelivery(order.delivery_method) ||
      isMarketPlace(order.delivery_method),
  );
};

const filterNew = orders => {
  return [
    ...orders.filter(order => isNew(order.partner_order_state)),
    ...filterNewOrderMgmtOrders(orders),
  ];
};

const filterNewOrderMgmtOrders = orders => {
  return orders.filter(
    order =>
      order?.partner_order_state === OrderStateType.ONHOLD &&
      order?.state_order_management &&
      Object.keys(order?.state_order_management).length,
  );
};

const findOrderDetailById = (orders, orderId) => {
  const order = _.find(orders, { id: orderId });
  return _.get(order, 'detail', null);
};

const getTotalOfProducts = products => {
  let total = 0;

  if (!products) {
    return total;
  }

  products.forEach(product => {
    total += product.units;
  });
  return total;
};

const isBeingManaged = order => {
  return order && order.beingManaged;
};

/**
 *
 * @param {*} orderState Be careful. It is the state of the order, not the partner order state.
 */
const isCanceled = orderState => {
  if (!orderState) {
    return false;
  }
  return orderState.indexOf('cancel') > -1;
};

const isFinished = orderState => {
  return (
    orderState === OrderStateType.ARRIVE ||
    orderState === OrderStateType.PENDING_REVIEW ||
    orderState === OrderStateType.FINISHED ||
    orderState === OrderStateType.ON_THE_ROUTE
  );
};

const isBeingIntegrated = orderDetail => {
  const isNewOrder = isNew(orderDetail.partner_order_state);

  if (!isNewOrder) {
    return false;
  }

  const isStoreIntegrated = isIntegratedStore(orderDetail.is_integration_store);

  return isStoreIntegrated;
};

const isOrderIntegrated = orderDetail => {
  return _.get(orderDetail, 'is_integration_store', false);
};

const isRTNotifyAvailable = order => {
  return _.get(order, 'show_button_rt_order_ready', false);
};

const isIntegratedStore = integratedStore => {
  if (!integratedStore) {
    return false;
  }
  return integratedStore;
};

const isMarketPlace = deliveryMethod => {
  return deliveryMethod === DELIVERY_METHOD_OPTION.marketplace;
};

const isBeingCooking = partnerOrderState => {
  return partnerOrderState === PartnerOrderStateType.COOKING;
};
const isDeliveredToRT = partnerOrderState => {
  return partnerOrderState === PartnerOrderStateType.DELIVEREDTORT;
};

const isReadyAndPacked = partnerOrderState => {
  return partnerOrderState === PartnerOrderStateType.READYANDPACKED;
};

const isNew = partnerOrderState => {
  return (
    !partnerOrderState ||
    partnerOrderState.trim() === '' ||
    partnerOrderState === PartnerOrderStateType.NEWORDER ||
    partnerOrderState === PartnerOrderStateType.CONTINUE_ORDER
  );
};

const isInStore = orderState => {
  return (
    orderState === OrderStateType.CREATED ||
    orderState === OrderStateType.INSTORE ||
    orderState === OrderStateType.INPROGRESS ||
    orderState === OrderStateType.SCHEDULED
  );
};

const isCanceledByPartner = orderState => {
  return orderState === OrderStateType.CANCELED_PARTNER_AFTER_TAKE;
};

const isCanceledByInactivity = orderState => {
  return orderState === OrderStateType.CANCELED_BY_PARTNER_INACTIVITY;
};

const isFullDelivery = deliveryMethod => {
  if (!deliveryMethod) {
    return false;
  }
  return deliveryMethod === DELIVERY_METHOD_OPTION.delivery;
};

const isRejectedByPartner = orderState => {
  return orderState === OrderStateType.CANCELORDERBYPARTNER;
};

const isOrderTotalsSuccessful = totals => {
  if (!totals) {
    return false;
  }

  return !_.get(totals, 'errors', null);
};

const isPickup = deliveryMethod => {
  if (!deliveryMethod) {
    return false;
  }
  return deliveryMethod === DELIVERY_METHOD_OPTION.pickup;
};

const isRTAssigned = orderDetail => {
  return orderDetail.storekeeper && orderDetail.storekeeper.id;
};

const isRTInStore = order => {
  if (!order || !order.eta_steps) {
    return false;
  }
  return RTUtils.isRTInStore(order.eta_steps.active_step);
};

const isRTOnTheWay = order => {
  if (!order || !order.eta_steps) {
    return false;
  }
  return RTUtils.isRTOnTheWay(order.eta_steps.active_step);
};

const filterWithRTInStore = orders => {
  if (!orders) {
    return [];
  }
  return orders.filter(isRTInStore);
};

const countWithRTInStore = orders => {
  if (!orders) {
    return 0;
  }
  return filterWithRTInStore(orders).length;
};

const filterWithRTOnTheWay = orders => {
  if (!orders) {
    return [];
  }
  return orders.filter(isRTOnTheWay);
};

const countWithRTOnTheWay = orders => {
  if (!orders) {
    return 0;
  }

  const filteredOrders = filterWithRTOnTheWay(orders);

  return filteredOrders.length;
};

const isRTInStoreFromDetail = orderDetail => {
  if (
    !orderDetail ||
    !orderDetail.rt_state ||
    !orderDetail.rt_state.eta_steps ||
    !orderDetail.rt_state.eta_steps.length
  ) {
    return false;
  }

  const activeStep = RTUtils.getActiveStep(orderDetail.rt_state.eta_steps);

  return !!activeStep && RTUtils.isRTInStore(activeStep.index);
};

const isRTOnTheWayFromDetail = orderDetail => {
  if (
    !orderDetail ||
    !orderDetail.rt_state ||
    !orderDetail.rt_state.eta_steps ||
    !orderDetail.rt_state.eta_steps.length
  ) {
    return false;
  }

  const activeStep = RTUtils.getActiveStep(orderDetail.rt_state.eta_steps);

  return !!activeStep && activeStep.index === 3;
};

const isSearchingForRTFromDetail = orderDetail => {
  if (
    !orderDetail ||
    !orderDetail.rt_state ||
    !orderDetail.rt_state.eta_steps ||
    !orderDetail.rt_state.eta_steps.length
  ) {
    return false;
  }

  const activeStep = RTUtils.getActiveStep(orderDetail.rt_state.eta_steps);

  return !!activeStep && (activeStep.index === 1 || activeStep.index === 2);
};

const separateOrdersById = (orders, orderIds) => {
  const includedOrders = [];
  const nonIncludedOrders = [];

  for (const order of orders) {
    const included = orderIds.indexOf(order.id) > -1;
    included ? includedOrders.push(order) : nonIncludedOrders.push(order);
  }

  return [includedOrders, nonIncludedOrders];
};

const showChargeToUser = (paymentMethod, totalCharge) => {
  return paymentMethod !== PaymentMethodType.GOOGLE_PAY && totalCharge !== 0;
};

const somePendingOrders = orders => {
  if (!orders || !orders.length) {
    return false;
  }
  return _.some(
    orders,
    order =>
      isNew(order.partner_order_state) ||
      isBeingCooking(order.partner_order_state) ||
      isReadyAndPacked(order.partner_order_state) ||
      isDeliveredToRT(order.partner_order_state),
  );
};

const somePickupOrder = orders => {
  if (!orders) {
    return false;
  }
  return _.some(orders, order => isPickup(order.delivery_method));
};

const sortByRestingCT = (orders, tz) => {
  orders.sort((order1, order2) => {
    const acceptanceTime1 = order1.cooking_time_started_at;
    const ct1 = order1.cooking_time;

    if (!acceptanceTime1 || ct1 === undefined || ct1 === null) {
      return 1;
    }

    const acceptanceTime2 = order2.cooking_time_started_at;
    const ct2 = order2.cooking_time;

    if (!acceptanceTime2 || ct2 === undefined || ct2 === null) {
      return -1;
    }

    const diffFromNowInMinutes1 = DateTimeUtils.getDiffFromNow(
      acceptanceTime1,
      'minutes',
      tz,
    );

    const restingCT1 = diffFromNowInMinutes1 - ct1;

    const diffFromNowInMinutes2 = DateTimeUtils.getDiffFromNow(
      acceptanceTime2,
      'minutes',
      tz,
    );

    const restingCT2 = diffFromNowInMinutes2 - ct2;

    return restingCT2 - restingCT1;
  });
};

const sortByRTState = orders => {
  orders.sort((order1, order2) => {
    if (!order1.eta_steps) {
      return 1;
    }
    if (!order2.eta_steps) {
      return -1;
    }

    return order2.eta_steps.active_step - order1.eta_steps.active_step;
  });
};

const sortByCreatedAt = orders => {
  orders.sort((order1, order2) => {
    return new Date(order1.created_at) - new Date(order2.created_at);
  });
};

const isOnHold = (partnerOrderState = '') => {
  return partnerOrderState === PartnerOrderStateType.ON_HOLD;
};

export default {
  countWithRTInStore,
  countWithRTOnTheWay,
  emitNotificationRTSound,
  filterBeingCookingWithExpiredCT,
  filterBeingManaged,
  filterBeingManagedCanceled,
  filterBySearchText,
  filterByStores,
  filterCanceled,
  filterCooking,
  filterFullDelivery,
  filterFullDeliveryAndMarketplace,
  filterNew,
  findOrderDetailById,
  getTotalOfProducts,
  isCanceled,
  isBeingCooking,
  isBeingIntegrated,
  isCanceledByInactivity,
  isCanceledByPartner,
  isBeingManaged,
  isDeliveredToRT,
  isReadyAndPacked,
  isFinished,
  isFullDelivery,
  isInStore,
  isIntegratedStore,
  isMarketPlace,
  isNew,
  isOrderIntegrated,
  isOrderTotalsSuccessful,
  isPickup,
  isRejectedByPartner,
  isRTAssigned,
  isRTInStore,
  isRTOnTheWay,
  isRTInStoreFromDetail,
  isRTOnTheWayFromDetail,
  isSearchingForRTFromDetail,
  separateOrdersById,
  showChargeToUser,
  somePendingOrders,
  somePickupOrder,
  sortByCreatedAt,
  sortByRestingCT,
  sortByRTState,
  isRTNotifyAvailable,
  isOnHold,
};
