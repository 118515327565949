import { useSelector } from 'react-redux';

import selectors from '../selectors';
import { selectors as RTLackIssueSuggestionsSelectors } from '../../../redux/rt-lack-issue-suggestions-dialog';

const useOpen = () => {
  return useSelector(RTLackIssueSuggestionsSelectors.selectOpen);
};

const useOrderId = () => {
  return useSelector(RTLackIssueSuggestionsSelectors.selectOrderId);
};

const useDescription = () =>
  useSelector(RTLackIssueSuggestionsSelectors.selectDescription);

const useSupportSuggestions = () => useSelector(selectors.selectOptions);

const useTitle = () => {
  return useSelector(selectors.selectTitle);
};

const hooks = {
  useOpen,
  useOrderId,
  useDescription,
  useSupportSuggestions,
  useTitle,
};

export default hooks;
