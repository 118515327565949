// External libs
import { createReducer } from '@reduxjs/toolkit';
// Interfaces
import { State } from '../interfaces';
// Actions
import actions from '../actions';

const defaultOrderId: number = 0;

const initialState: State = {
  orderId: defaultOrderId,
  open: false,
  selectedProducts: [],
  selectedToppings: [],
};

export default createReducer(initialState, {
  [actions.clearAction.type]: state => clear(state),
  [actions.closeAction.type]: state => close(state),
  [actions.openAction.type]: (state, { payload: { orderId } }) =>
    open(state, orderId),
  [actions.setSelectedProductsAction.type]: (
    state,
    { payload: { selectedProducts } },
  ) => setSelectedProducts(state, selectedProducts),
  [actions.setSelectedToppingsAction.type]: (
    state,
    { payload: { selectedToppings } },
  ) => setSelectedToppings(state, selectedToppings),
});

const clear = (state: State) => {
  return initialState;
};

const close = (state: State) => {
  state.open = false;
  return state;
};

const open = (state: State, orderId: number = state.orderId) => {
  state.open = true;
  state.orderId = orderId;
  return state;
};

const setSelectedProducts = (state: State, selectedProducts: number[]) => {
  state.selectedProducts = selectedProducts;
  return state;
};

const setSelectedToppings = (state: State, selectedToppings: number[]) => {
  state.selectedToppings = selectedToppings;
  return state;
};
