import { clsx } from 'clsx';
import { SvgIcon } from '@material-ui/core';

import { neutral, red } from '../../../shared/styles/colors';
import Colors from '../../../constants/Colors';
import useStyles from './EmptyStateIcon.styles';

export function EmptyStateIcon({ classes = {} }) {
  const _classes = useStyles();
  return (
    <SvgIcon
      classes={{ root: clsx(_classes.root, classes?.root) }}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 80 80'
    >
      <g fill='none' fillRule='evenodd' transform='translate(.335)'>
        <ellipse
          cx='40.165'
          cy='40'
          fill={red['10']}
          fillRule='nonzero'
          rx='40.165'
          ry='40'
        />
        <path
          fill={neutral.white}
          stroke={Colors.AFFAIR}
          strokeWidth='.667'
          d='M29.308 13.742a3.535 3.535 0 0 0-4.092 2.866l-6.382 36.045-.001.005a3.535 3.535 0 0 0 2.87 4.093l26.692 4.687a3.535 3.535 0 0 0 4.093-2.865l6.382-36.045v-.005A3.535 3.535 0 0 0 56 18.429l-26.692-4.687z'
        />
        <g stroke={Colors.AFFAIR} strokeWidth='.667'>
          <path
            fill={neutral.white}
            d='M18.722 18.784v41.743h32.24V27.971l-9.206-9.187H18.722z'
          />
          <path d='M29.035 43.373H40.65M24.196 39.518h21.292' />
          <path
            fill={Colors.WISP_PINK}
            d='M22.594 31.177v5.116H47.09v-5.116H22.594z'
          />
          <g>
            <path d='M50.197 28.858l-9.906-9.619v9.619h9.906z' />
          </g>
        </g>
        <g transform='translate(38.836 42.585)'>
          <ellipse
            cx='12.375'
            cy='12.082'
            fill={neutral.D7}
            stroke={Colors.AFFAIR}
            strokeWidth='.667'
            rx='12.05'
            ry='12'
          />
          <rect
            width='10.711'
            height='1.333'
            y='4.667'
            fill={Colors.AFFAIR}
            rx='.667'
            transform='translate(7.02 6.748)'
          />
        </g>
      </g>
    </SvgIcon>
  );
}
