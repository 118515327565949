import { makeStyles } from '@material-ui/core';

import Colors from '../../../constants/Colors';
import { neutral, teal } from '../../styles/colors';

export default makeStyles({
  container: {
    position: 'relative',
    display: 'inline-flex',
  },
  toBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  specialMargin: {
    margin: '2%',
  },
  circularBase: {
    height: 'inherit',
    color: teal['10'],
  },
  label: {
    fontSize: '0.75rem',
    fontWeight: 600,
    color: neutral['80'],
  },
  circularProgress: (props: any) => ({
    strokeLinecap: 'round',
    color:
      props?.statusTime === 'danger'
        ? Colors.DANGER_COLOR
        : props?.statusTime === 'warning'
        ? Colors.WARNING_FONT_COLOR
        : Colors.MALACHITE,
  }),
});
