// React
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// Material UI
import { useMediaQuery, useTheme } from '@material-ui/core';
// Consts
import Paths from '../../constants/Paths';
// Components
import CustomAdvancedFilterToolbar from './../common/custom-advanced-filter-toolbar';
import OrdersHistoricalFilterDrawer from './../orders-historical-filter-drawer';

const OrdersHistoricalAdvancedFilterToolbar = ({
  classes,
  firstStoreId,
  onClick,
  onStoreQueryParamChange,
  selectedFilterOptions,
}) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const { search } = useLocation();

  const queryParamStoreId = new URLSearchParams(search).get('store_id');

  const navigate = useNavigate();

  useEffect(() => {
    if (!firstStoreId) {
      return;
    }
    if (!!queryParamStoreId) {
      onStoreQueryParamChange(queryParamStoreId);
    } else {
      navigate(Paths.getOrdersHistoricalQueryParamsPath(firstStoreId));
    }
  }, [firstStoreId, queryParamStoreId, navigate]);

  return (
    <>
      <CustomAdvancedFilterToolbar
        classes={{ ...classes }}
        onClick={onClick}
        selectedFilterOptions={selectedFilterOptions}
        showAdvancedToolBar={!isMdUp}
      />
      <OrdersHistoricalFilterDrawer />
    </>
  );
};

OrdersHistoricalAdvancedFilterToolbar.defaultProps = {
  classes: {},
  selectedFilterOptions: [],
};

export default OrdersHistoricalAdvancedFilterToolbar;
