enum Colors {
  PRIMARY_COLOR = '#26aba4',
  PRIMARY_COLOR_DARK = '#21938d',
  SECONDARY_COLOR = '#ff2525',
  SECONDARY_COLOR_DARK = '#b81b1b',
  PROPIO_FONT_COLOR = '#0a75e5',

  WARNING_CHIP_COLOR = '#fff7e2',
  WARNING_COLOR = '#fff3da',
  WARNING_DARK_COLOR = '#ad832e',
  SUCCESS_COLOR = '#26aba4',
  SUCCESS_LIGHT_COLOR = '#d4eeed',
  DANGER_COLOR = '#ff2525',
  DANGER_LIGHT_COLOR = '#ffe9e9',
  DANGER_LIGHT_COLOR_2 = '#FFF7F7',
  DANGER_LIGHT_COLOR_3 = '#f44336',
  INFO_COLOR = '#e7e3f4',
  INFO_COLOR_2 = '#3B76DB',
  INFO_DARK_COLOR = '#e7e3f4',

  WARNING_FONT_COLOR = '#ffc043',
  SUCCESS_FONT_COLOR = '#51bcb6',
  DANGER_FONT_COLOR = '#ff5656',
  INFO_FONT_COLOR = '#7356bf',

  //Other Colors
  WHITE = '#fff',
  TEMPTRESS = '#3c000a',
  SHERPA_BLUE = '#005950',
  TALL_POPPY = '#aa273e',
  BLACK = '#000',
  CHABLIS = '#ffeff0',
  ICEBERG = '#daf3ef',
  WHITE_ICE = '#edfcfb',
  FUN_BLUE = '#1867B0',
  LAVENDER_BLUSH = '#FFECEF',
  AFFAIR = '#694c84',
  JACARTA = '#462663',
  RED = '#FF0000',
  BLUE_MARGARITA = '#6A5ACD',
  NAVAJO_WHITE = '#FFE3AA',
  JEWEL = '#178038',
  SPRING_WOOD = '#F6F1EC',
  SPICY_MIX = '#804445',
  FUZZY_WUZZY_BROWN = '#C15558',
  WISP_PINK = '#FDEBEE',
  ZIRCON = '#F3F7FF',
  MALIBU = '#75A4FE',
  ANAKIWA = '#A4C3FE',
  SOLITUDE = '#E8F0FE',
  MALACHITE = '#0CC665',
  GRANNY_APPLE = '#DBF6E8',
  FLUSH_ORANGE = '#FF7D00',
  NEGRONI = '#FFDEBE',
  RED_ORANGE = '#FF441F',
  WEB_ORANGE = '#FFAA00',
  INFO_LIGHT_COMMENT_FONT_COLOR = '#e02020',
  CORNFLOWER_BLUE = '#dae7ff',
  DODGER_BLUE = '#4589FF',
  DODGER_BLUE_INTENSE = '#0f62fe',
  AZURE_RADIANCE = '#007AFF',
  ANZAC = '#dda63a',
  BROWN = '#856423',
  BROWN_2 = '#33260D',

  // Design System Colors
  GREEN_ACTIVE_STATUS = '#088343',
  WARNING = '#FFCE6B',
  WARNING_BG = '#FFE9BC',
  WARNING_LIGHT_1 = '#FFE9C6',
  WARNING_LIGHT_2 = '#FFF7E7',
  WARNING_LIGHT_4 = '#FEFBF6',
  WARNING_LIGHT_5 = '#FFFDF6',

  BACKGROUND_COLOR_RT_STATE = '#EAFCE6',

  DANGER_LIGHT_1 = '#DB2020',
  DANGER_LIGHT_2 = '#FF8787',
  DANGER_LIGHT_3 = '#FFD6D6',

  CYAN = '#1FD8B5',
  CYAN_SHADE = '#23aeb9',
}

export default Colors;
