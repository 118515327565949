export const WIDTH = {
  small: '20rem',
  medium: '25rem',
  large: '32rem',
  xLarge: '45rem',
  big: '67.5rem',
  full: '100%',
} as const;

export const PADDING = {
  small: '0.50rem',
  medium: '0.75rem',
  large: '1.00rem',
  xLarge: '1.25rem',
  big: '1.50rem',
  full: '1.50rem',
} as const;
