import { useFormatMessage } from '../../../../intl/intl';

export function usePrintedChecklist(props: Props) {
  const { orderId, userName } = props;

  const defectRateLabel = useFormatMessage({ id: `defectRate.title` });
  const commentsLabel = useFormatMessage({ id: `comments` });
  const checkProductsLabel = useFormatMessage({
    id: `orderBill.checkProducts`,
  });

  return {
    defectRateLabel,
    commentsLabel,
    checkProductsLabel,
    forRTLabel: {
      title: useFormatMessage({ id: 'orderBill.checkForRT.title' }),
      item1: useFormatMessage({ id: 'orderBill.checkForRT.item1' }),
      item2: useFormatMessage(
        { id: 'orderBill.checkForRT.item2' },
        { userName, orderId },
      ),
    },
  };
}

interface Props {
  orderId: number;
  userName: string;
}
