import appStore from '../../../redux/stores/app.store';
import { getDiffFromNow } from '../../../shared/utils/date-time';
import type { AdditionalStoreInfo, StoreStatusDetail } from '../domain';
import { operationalStateTurboAdapter as adapter } from './adapters';
import { operationalStateTurboRepository as repository } from './repositories';

export function operationalStateTurboHandler() {
  const partnerId = appStore.getPartnerId();

  async function _getProfileExtended(storeStatusDetail: StoreStatusDetail[]) {
    try {
      const lastUpdate = repository.getLastUpdate();
      const { hours: diffInHours } = getDiffFromNow(lastUpdate);

      if (diffInHours < 24) {
        const data = repository.getProfileBackgroundLocal();
        return adapter.toStoreDetailAdditionalInfo(storeStatusDetail, data);
      }
      const storeIds = storeStatusDetail.map(item => item.id);
      const response = await repository.getProfileBackground(
        partnerId,
        storeIds,
      );
      const data: AdditionalStoreInfo[] = adapter.toAdditionalStoreInfo(
        response.data,
      );
      if (!data.length) {
        return storeStatusDetail;
      }
      repository.setProfileBackgroundLocal(data);
      repository.setLastUpdateByNow();

      return adapter.toStoreDetailAdditionalInfo(storeStatusDetail, data);
    } catch (ex) {
      return storeStatusDetail;
    }
  }

  async function getStoreStatusDetail(): Promise<StoreStatusDetail[]> {
    try {
      const response = await repository.getStoreStatusDetail(partnerId);
      const storeStatusDetail = adapter.toStoresStatusDetail(response.data);

      return await _getProfileExtended(storeStatusDetail);
    } catch (ex) {
      return [];
    }
  }

  return {
    getStoreStatusDetail,
    partnerId,
  };
}
