import { clsx } from 'clsx';
import { useState } from 'react';
import { Fade, Tooltip } from '@material-ui/core';
import { InfoOutlined, KeyboardArrowRight } from '@material-ui/icons';

import PickupIcon from '../../icons/pickup-icon';
import MustacheIcon from '../../icons/mustache-icon';
import { CargoAdditionalRT } from '../../../features/cargo';
import { CustomIncident } from './components/custom-incident';
import HistoricalQualifyNegativeDrawer from '../../historical-qualify-negative-drawer';

import OrdersUtils from '../../../utils/OrdersUtils';
import { useFormatMessage } from '../../../intl/intl';
import { formatCurrency } from '../../../utils/CurrencyUtils';
import { DELIVERY_METHOD_OPTION } from '../../../business/shared/domain';

import makeClasses from './OrderHistoryCard.styles';

export function OrderHistoryCard(props) {
  const {
    orderState,
    orderId,
    storeId,
    storekeeperId,
    createdAt,
    price,
    priceWithDiscount = NaN,
    fullName,
    profilePic,
    incident,
    deliveryMethod,
    // history,
    partnerId,
    // getOrderDetail,
    qualifyPositiveRT,
    // qualifyNegativeRT,
    getQualifyNegativeReasons,
    onClick,
    showQualifyRT,
  } = props;

  const isPickup = deliveryMethod === DELIVERY_METHOD_OPTION.pickup;
  const showPickupIcon = !storekeeperId && isPickup;
  const showMustacheIcon = !storekeeperId && !isPickup;

  const _classes = makeClasses();
  const currentStateClass = getStateClass(_classes, orderState);

  const [qualifyNegOpen, setQualifyNegOpen] = useState(false);

  const texts = useOrderHistoryCardLang({ orderState });

  const handleOpenOrderDetail = () => onClick(orderId);

  const handleClickQualifyNegative = () => {
    getQualifyNegativeReasons();
    setQualifyNegOpen(true);
  };

  const handleClickQualifyPositive = () => {
    qualifyPositiveRT(orderId, partnerId, storekeeperId);
  };

  return (
    <>
      <div
        role='row'
        data-tag='card-row'
        className={_classes.rowCard}
        onClick={handleOpenOrderDetail}
      >
        <div role='cell' data-tag='cellState'>
          <label>{texts.state}</label>
          <p className={clsx(_classes.state, currentStateClass)}>
            {texts.currentState}
          </p>
        </div>
        <div role='cell' data-tag='cellOrderId'>
          <label>{texts.orderId}</label>
          <span>{orderId}</span>
        </div>
        <div role='cell' data-tag='cellDateTime'>
          <label>{texts.dateTime}</label>
          <span>{createdAt}</span>
        </div>
        <div role='cell' data-tag='cellPrice'>
          <label>{texts.price}</label>
          <span>{formatCurrency(price)}</span>
        </div>
        <div role='cell' data-tag='cellPrice'>
          <label>{texts.priceWithDiscount}</label>
          <span>{formatCurrency(priceWithDiscount)}</span>
        </div>
        <div role='cell' data-tag='cellRT'>
          <label>{texts.rt}</label>
          <p className={_classes.rt}>
            {showPickupIcon && <PickupIcon />}
            {showMustacheIcon && <MustacheIcon />}
            {fullName ?? texts.nonRT}
          </p>
        </div>
        <div role='cell' data-tag='cellAdditionalRT'>
          <label className={_classes.AddRTLabel}>
            {texts.additionalRT}
            <Tooltip
              arrow
              TransitionComponent={Fade}
              title={texts.additionalRTTooltip}
              classes={{ tooltipArrow: _classes.addRTLabelTooltip }}
            >
              <InfoOutlined style={{ fontSize: '1rem' }} />
            </Tooltip>
          </label>
          <CargoAdditionalRT
            orderId={orderId}
            storeId={storeId}
            orderStatus={orderState}
            deliveryMethod={deliveryMethod}
          />
        </div>
        <div role='cell' data-tag='cellQualify'>
          <label>{texts.qualify}</label>
          <CustomIncident
            enabled={showQualifyRT}
            incident={incident}
            onPositive={handleClickQualifyPositive}
            onNegative={handleClickQualifyNegative}
          />
        </div>
        <div role='cell' data-tag='cellOpenDetail'>
          <label></label>
          <KeyboardArrowRight className={_classes.openDetailIcon} />
          <p className={_classes.openDetailText}>{texts.openDetail}</p>
        </div>
      </div>

      <HistoricalQualifyNegativeDrawer
        fullName={fullName}
        open={qualifyNegOpen}
        orderId={orderId}
        partnerId={partnerId}
        profilePic={profilePic}
        setQualifyNegOpen={setQualifyNegOpen}
        storekeeperId={storekeeperId}
      />
    </>
  );
}

// +++++++++++++++++++
// AUXILIAR FUNCTIONS
// +++++++++++++++++++
function getStateClass(classes, orderState) {
  if (OrdersUtils.isInStore(orderState)) return classes.inStore;
  else if (OrdersUtils.isFinished(orderState)) return classes.finished;
  return classes.canceled;
}

function useOrderHistoryCardLang({ orderState }) {
  const currentStateText = getCurrentStateLabel(orderState);
  return {
    state: useFormatMessage({ id: 'orders.historical.header.state' }),
    orderId: useFormatMessage({ id: 'orders.historical.header.orderId' }),
    dateTime: useFormatMessage({ id: 'orders.historical.header.dateTime' }),
    price: useFormatMessage({ id: 'orders.historical.header.price' }),
    priceWithDiscount: useFormatMessage({
      id: 'orders.historical.header.priceWithDiscount',
    }),
    rt: useFormatMessage({ id: 'orders.historical.header.rt' }),
    additionalRT: useFormatMessage({ id: 'cargo.addRT.orderHistory.label' }),
    additionalRTTooltip: useFormatMessage({
      id: 'cargo.addRT.orderHistory.tooltip',
    }),
    qualify: useFormatMessage({ id: 'orders.historical.header.qualify' }),
    currentState: currentStateText,
    nonRT: useFormatMessage({ id: 'orders.historical.nonRT' }),
    openDetail: useFormatMessage({ id: 'orders.historical.openDetail' }),
  };
}

function getCurrentStateLabel(orderState) {
  let stateLabelId = 'orders.historical.unknown';
  if (OrdersUtils.isInStore(orderState)) {
    stateLabelId = 'orders.historical.in_store';
  } else if (OrdersUtils.isFinished(orderState)) {
    stateLabelId = 'orders.historical.finished';
  } else if (OrdersUtils.isCanceledByPartner(orderState)) {
    stateLabelId = 'orders.historical.canceled_partner_after_take';
  } else if (OrdersUtils.isCanceledByInactivity(orderState)) {
    stateLabelId = 'orders.historical.canceled_by_partner_inactivity';
  } else if (OrdersUtils.isRejectedByPartner(orderState)) {
    stateLabelId = 'orders.historical.canceled_partner_order_refused';
  } else if (OrdersUtils.isCanceled(orderState)) {
    stateLabelId = 'orders.historical.canceled';
  }
  return useFormatMessage({ id: stateLabelId });
}
