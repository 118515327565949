import { clsx } from 'clsx';

import useStyles from './CustomReadOnlyPrefixPhone.styles';

export function CustomReadOnlyPrefixPhone(props) {
  const { flag = '', prefixNumber = '', classes = {} } = props;
  const _classes = useStyles();
  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <img className={_classes.flag} alt='countryFlag' src={flag} />
      <div className={_classes.prefixNumber}>{prefixNumber}</div>
    </div>
  );
}
