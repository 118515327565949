import { IconButton } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';

export function CustomAddCircleOutlineButton({
  color,
  disabled,
  onClick,
  text,
}) {
  return (
    <IconButton
      aria-label='Add'
      color={color}
      disabled={disabled}
      onClick={onClick}
    >
      <AddCircleOutline text={text} />
    </IconButton>
  );
}
