import { makeStyles } from '@material-ui/core';
import { neutral } from '../../../../shared/styles/colors';

export const OrderMgmtUndoSuggestionDialogStyles = makeStyles(() => ({
  paper: {
    height: '22rem',
    width: '45.6875rem',
    padding: '1rem',
    position: 'relative',
  },
  undoSuggestionDialogHeader: {
    height: '1rem',
    borderBottomColor: 'transparent',
  },
  undoSuggestionDialogHeaderTitle: {
    fontWeight: 600,
    fontSize: '1.5rem',
    color: neutral.D20,
  },
  undoSuggestionDialogHeaderDescription: {
    fontWeight: 400,
    fontSize: '1rem',
    color: neutral.D18,
  },
  disclaimerItem: {
    padding: 0,
  },
}));
