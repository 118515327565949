import { useSelector } from 'react-redux';
import { useFormatMessage } from '../../intl/intl';
import { selectors as StoreStatusSelectors } from '../../redux/partner';
import { selectors as extendClosingHoursDialogSelectors } from '../../redux/extend-closing-hours-dialog';
import { AppStoreSelectors } from '../../redux/selectors/app-store-selectors';
import { StoresSchedulesSelectors } from '../../redux/selectors/stores-schedules-selectors';
import { DEFAULT_STORE_DAY_SCHEDULE, LL_FORMAT_DATE } from './constants';
import {
  formatStoreExtendSchedulesNextStartTime,
  formatStoreExtendSchedulesPreviousEndTime,
  formatStoreExtendSchedulesSlotEnable,
  getDayByNumber,
  getFormatDay,
  getSpecialDay,
} from './utils';
import DateTimeUtils from '../../utils/DateTimeUtils';

const useOpen = () => useSelector(extendClosingHoursDialogSelectors.selectOpen);

const useAreStoresSchedulesLoading = () =>
  useSelector(StoresSchedulesSelectors.selectAreStoresSchedulesLoading);

const useStoresSchedules = () =>
  useSelector(StoresSchedulesSelectors.selectStoresSchedules);

const useStoreStatus = (selectedStoreId: Number) =>
  useSelector(StoreStatusSelectors.selectStoreStatusDetail).filter(
    element => element.store_id === selectedStoreId,
  )[0]?.status;

const useStoresSchedulesFilter = () =>
  useSelector(StoresSchedulesSelectors.selectStoresSchedulesFilter);

const useStoreSchedules = () => {
  const { selectedStoreId } = useStoresSchedulesFilter();
  const storesSchedules = useStoresSchedules();
  return storesSchedules[selectedStoreId]?.schedules;
};

const useNormalDaySchedules = () => {
  const storeSchedules = useStoreSchedules();
  const normalDays = storeSchedules?.normal;
  const date = new Date();
  const numberDay = date.getDay();
  const day = getDayByNumber(numberDay) || '';
  const normalDay = normalDays && normalDays[day.toLocaleLowerCase()];
  return normalDay?.schedules || [];
};

const useHolidayDaySchedules = () => {
  const storeSchedules = useStoreSchedules();
  const holidayDays = storeSchedules?.holidays || [];
  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  const currentDay = date.getDate();
  const holidayDay = holidayDays.find(
    ({ month, day }: any) => month === currentMonth && day === currentDay,
  );
  return holidayDay?.schedules || [];
};

const useSpecialDaySchedules = () => {
  const storeSchedules = useStoreSchedules();
  const specialDays = storeSchedules?.specials || [];
  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  const currentDay = date.getDate();
  const specialDay = specialDays.find(
    ({ month, day }: any) => month === currentMonth && day === currentDay,
  );
  return specialDay?.schedules || [];
};

const useStoreDaySchedules = () => {
  const normalDaySchedules = useNormalDaySchedules();
  const holidayDaySchedules = useHolidayDaySchedules();
  const specialDaySchedules = useSpecialDaySchedules();

  const storeDaySchedules = {
    [(normalDaySchedules.length > 0).toString()]: normalDaySchedules,
    [(holidayDaySchedules.length > 0).toString()]: holidayDaySchedules,
    [(specialDaySchedules.length > 0).toString()]: specialDaySchedules,
  }['true'];

  return formatStoreExtendSchedulesPreviousEndTime([
    ...formatStoreExtendSchedulesSlotEnable([
      ...formatStoreExtendSchedulesNextStartTime([
        ...(storeDaySchedules || DEFAULT_STORE_DAY_SCHEDULE),
      ]),
    ]),
  ]);
};

const useHasHolidayOrNormalDaySlotsSchedules = () => {
  const normalDaysSchedules = useNormalDaySchedules();
  const holidayDaySchedules = useHolidayDaySchedules();
  return normalDaysSchedules.length > 1 || holidayDaySchedules.length > 1;
};

const useLanguage = () => useSelector(AppStoreSelectors.selectLanguage);

const useExtendClosingHoursDialogTexts = (turn = 1) => {
  const cancelButtonLabel = useFormatMessage({ id: 'globals.cancel' });
  const saveButtonLabel = useFormatMessage({
    id: 'extendClosingHoursDialog.saveSchedule',
  });
  const openTitleLabel = useFormatMessage({
    id: 'extendClosingHoursDialog.openTitle',
  });
  const extendTitleLabel = useFormatMessage({
    id: 'extendClosingHoursDialog.extendTitle',
  });
  const openDisclaimerLabel = useFormatMessage({
    id: 'extendClosingHoursDialog.openDisclaimer',
  });
  const extendDisclaimerLabel = useFormatMessage({
    id: 'extendClosingHoursDialog.extendDisclaimer',
  });
  const extendOneTurnLabel = useFormatMessage({
    id: 'extendClosingHoursDialog.todaySchedule',
  });
  const extendTurnLabel = useFormatMessage(
    {
      id: 'extendClosingHoursDialog.todayShifts',
    },
    {
      turn: turn,
    },
  );
  const opensLabel = useFormatMessage({ id: 'extendClosingHoursDialog.opens' });
  const closesLabel = useFormatMessage({
    id: 'extendClosingHoursDialog.closes',
  });

  const openErrorLabel = useFormatMessage({
    id: 'extendClosingHoursDialog.openError',
  });

  const closeErrorLabel = useFormatMessage({
    id: 'extendClosingHoursDialog.closeError',
  });

  const nextErrorLabel = useFormatMessage({
    id: 'extendClosingHoursDialog.nextError',
  });

  const previousErrorLabel = useFormatMessage({
    id: 'extendClosingHoursDialog.previousError',
  });

  return {
    cancelButtonLabel,
    saveButtonLabel,
    openTitleLabel,
    extendTitleLabel,
    openDisclaimerLabel,
    extendDisclaimerLabel,
    extendOneTurnLabel,
    extendTurnLabel,
    opensLabel,
    closesLabel,
    openErrorLabel,
    closeErrorLabel,
    nextErrorLabel,
    previousErrorLabel,
  };
};

const useExtendSchedulesDates = () => {
  const language = useLanguage();
  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  const currentDay = date.getDate();
  const todayDay = getFormatDay(date.getDay());
  const todayDate = DateTimeUtils.getFormattedNextDate(
    currentMonth,
    currentDay,
    language,
    LL_FORMAT_DATE,
  );

  return { todayDay, todayDate };
};

const useExtendScheduleSpecialDay = () => {
  const storeSchedules = useStoreSchedules();
  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  const currentDay = date.getDate();
  const todaySpecialDay = getSpecialDay({
    storeSchedules,
    currentDay,
    currentMonth,
  });

  return { todaySpecialDay };
};

const hooks = {
  useOpen,
  useAreStoresSchedulesLoading,
  useStoreStatus,
  useStoresSchedules,
  useStoreSchedules,
  useStoresSchedulesFilter,
  useLanguage,
  useExtendClosingHoursDialogTexts,
  useExtendSchedulesDates,
  useExtendScheduleSpecialDay,
  useNormalDaySchedules,
  useHolidayDaySchedules,
  useSpecialDaySchedules,
  useStoreDaySchedules,
  useHasHolidayOrNormalDaySlotsSchedules,
};

export default hooks;
