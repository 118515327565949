import { BrowserHandler } from '../domain';
import {
  BrowserActions,
  BrowserSelectors,
  browserDialogName,
  browserDialogReducer,
} from './store';

export const browserHandler = () => {
  return {
    stataName: browserDialogName,
    reducer: browserDialogReducer,
    get,
    dispatch,
  };
};

function get(): ReturnType<BrowserHandler['get']> {
  const {
    selectDialog,
    selectContentType,
    selectComponentType,
  } = BrowserSelectors();
  return {
    dialogState: selectDialog,
    contentType: selectContentType,
    component: selectComponentType,
  };
}
function dispatch() {
  return {
    ...BrowserActions(),
  };
}
