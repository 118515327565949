import OrdersUtils from '../../../../utils/OrdersUtils';

import type { AddRTBehavior, AddRTStatus } from '../types';
import type { DeliveryMethod } from '../../../shared/domain';

export const addRTHelper = {
  isDisplayedToOrderHistorical: (params: isDisplayedToOHParams) => {
    const { behavior, isCargoStore } = params;
    return behavior === 'orderHistorical' && Boolean(isCargoStore);
  },

  isDisplayedToDetailMarketplace: (params: isDisplayedToDMPParams) => {
    const { behavior, deliveryMethod, isEnabledByCargo } = params;
    return (
      behavior === 'detailMarketplace' &&
      deliveryMethod === 'marketplace' &&
      isEnabledByCargo
    );
  },

  isEnabledToOrderHistorical: (params: IsEnabledTo) => {
    const { orderStatus, behavior, deliveryMethod } = params;
    const isOrderInStore = OrdersUtils.isInStore(orderStatus);
    const isOrderFinished = OrdersUtils.isFinished(orderStatus);
    if (behavior !== 'orderHistorical') return false;
    return deliveryMethod !== 'pickup' && (isOrderInStore || isOrderFinished);
  },

  isEnabledToDetailMarketplace: (params: IsEnabledTo) => {
    const { orderStatus, behavior, deliveryMethod } = params;
    const isOrderInStore = OrdersUtils.isInStore(orderStatus);
    if (behavior !== 'detailMarketplace') return false;
    return deliveryMethod === 'marketplace' && isOrderInStore;
  },

  isValidationInProgress: (s: AddRTStatus) => s === 'validationInProgress',

  isValidationDenied: (s: AddRTStatus) => s === 'validationDenied',

  isCreationInProgress: (s: AddRTStatus) => s === 'creationInProgress',

  isCreationAccepted: (s: AddRTStatus) => s === 'creationAccepted',

  isCreationDenied: (s: AddRTStatus) => s === 'creationDenied',

  isDialogEnabledByAddRTStatus: (s: AddRTStatus) => {
    return (
      s === 'validationAccepted' ||
      s === 'creationInProgress' ||
      s === 'creationAccepted' ||
      s === 'creationDenied'
    );
  },
};

interface isDisplayedToOHParams {
  isCargoStore: boolean;
  behavior: AddRTBehavior;
}

interface isDisplayedToDMPParams {
  behavior: AddRTBehavior;
  isEnabledByCargo: boolean;
  deliveryMethod: DeliveryMethod;
}

interface IsEnabledTo {
  orderStatus: string;
  behavior: AddRTBehavior;
  deliveryMethod: DeliveryMethod;
}
