import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';

import Typography, {
  Variants as TypographyVariant,
  FontWeight,
} from '../../components/common/typography';
import CustomDialog from '../common/custom-dialog';
import CustomTextField from '../common/custom-text-field';
import { CustomEditButton } from '../common/buttons/custom-edit-button';
import { CustomReadOnlyPrefixPhone } from '../common/custom-read-only-prefix-phone';
import CustomButton, {
  Colors,
  Variants,
} from '../common/buttons/custom-button';

import hooks from './hooks';
import StringUtils from '../../utils/StringUtils';
import { useFormatMessage } from '../../intl/intl';
import localStorageService from '../../services/local-storage-service';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';
import { actions as phonePMFlowDialogActions } from '../../redux/phone-PM-flow-dialog';

import iconPhone from '../../assets/images/pm-flow/iconPhone.png';
import { textIds } from './consts';
import useStyles from './styles';

import type { ChangeEvent, KeyboardEvent } from 'react';

// TODO: should move to a separate file or use a different approach
let phoneInput: any;

export function PhonePMFlowDialog() {
  const MAX_LENGTH = 20;
  const phone = hooks.usePhone();
  const phoneErrorMessage = hooks.useErrorMessage();
  const [localPhoneNumber, setLocalPhoneNumber] = useState<string>(phone);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>(phoneErrorMessage);
  const dispatch = useDispatch();
  const componentClasses = useStyles();
  const open = hooks.useOpen();
  const countryFlag = localStorageService.getFlag();
  const countryPrefixCode = localStorage.getItem('phonePrefix');
  const formId = hooks.useFormId();

  const theme = useTheme<any>();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const title = useFormatMessage(textIds.title);
  const description = useFormatMessage(textIds.description);
  const cancelText = useFormatMessage(textIds.cancelButton);
  const submitText = useFormatMessage(textIds.submitButton);
  const placeHolder = useFormatMessage(textIds.placeHolder);

  const handleClose = useCallback(async () => {
    dispatch(dialogsManagerActions.close());
    dispatch(phonePMFlowDialogActions.sendSkipCloseForm(formId));
  }, [dispatch, formId]);

  const handleBack = useCallback(() => {
    dispatch(dialogsManagerActions.openStartPMFlowDialog());
    setLocalPhoneNumber(phone);
  }, [dispatch, phone]);

  const handleKeyUp = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }
    const keyValue = event.key;
    const isNumber = StringUtils.isNumber(keyValue);
    if (!isNumber) {
      event.preventDefault();
      return;
    }
  }, []);

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const eventValue = event.currentTarget?.value ?? '';
      const replacedEventValue = eventValue.replace(
        /[a-z]|,|-|_|ñ|´|' '|\+|\?|=|á|é|ú|ó|í|\*/gi,
        '',
      );
      setLocalPhoneNumber(replacedEventValue);
    },
    [],
  );

  const handleOnEdit = useCallback(() => {
    setIsDisabled(!isDisabled);
  }, [isDisabled]);

  const onConfirm = useCallback(() => {
    dispatch(
      phonePMFlowDialogActions.sendStorePhoneNumber(formId, localPhoneNumber),
    );
    setLocalPhoneNumber(phone);
  }, [formId, localPhoneNumber, phone, dispatch]);

  useEffect(() => {
    setLocalPhoneNumber(phone);
    setErrorMessage(phoneErrorMessage);
  }, [phone, phoneErrorMessage]);

  useEffect(() => {
    if (!isDisabled) {
      phoneInput?.focus();
    }
  }, [isDisabled]);

  return (
    <CustomDialog
      classes={{
        childrenContainer: componentClasses.body,
        headerRoot: componentClasses.headerRoot,
        paper: componentClasses.paper,
        title: componentClasses.dialogTitle,
      }}
      CustomDialogHeaderChildren={''}
      onClose={handleClose}
      onClickBackButton={handleBack}
      open={open}
      showBackButton={false}
      showCloseButton
      title={!isMdUp ? title : ''}
      actions={
        isMdUp
          ? [
              {
                text: cancelText,
                onClick: handleBack,
                color: 'primary',
                variant: 'outlined',
                fullWidth: false,
              },
              {
                text: submitText,
                onClick: onConfirm,
                color: 'primary',
                variant: 'contained',
                fullWidth: false,
              },
            ]
          : []
      }
      disableBackdropClick
      disableEnforceFocus
      disableEscapeKeyDown
    >
      <div className={componentClasses.root}>
        {isMdUp && (
          <Typography
            variant={TypographyVariant.Headline}
            fontWeight={FontWeight.Bold}
            classes={{ root: componentClasses.title }}
          >
            {title}
          </Typography>
        )}
        <div className={componentClasses.descriptionBox}>
          <img
            alt='icon-phone'
            src={iconPhone}
            className={componentClasses.iconImage}
          />
          <Typography
            classes={{ root: componentClasses.description }}
            variant={TypographyVariant.Caption1}
            fontWeight={FontWeight.Regular}
          >
            {description}
          </Typography>
        </div>
        <div className={componentClasses.inputContainer}>
          <CustomReadOnlyPrefixPhone
            classes={{
              root: componentClasses.prefix,
            }}
            flag={countryFlag}
            prefixNumber={countryPrefixCode}
          />
          <CustomTextField
            inputRef={(input: any) => {
              phoneInput = input;
            }}
            classes={{
              textFieldRoot: componentClasses.phoneInput,
              notchedOutlineInput: componentClasses.inputLabel,
            }}
            name='input'
            maxLength={MAX_LENGTH}
            disabled={isDisabled}
            readOnly={isDisabled}
            helperText={errorMessage}
            error={!!errorMessage}
            required
            onKeyPress={handleKeyUp}
            placeholder={placeHolder}
            useExternalLabel
            onChange={handleOnChange}
            fullWidth
            value={localPhoneNumber}
          />
          <CustomEditButton
            classes={{ button: componentClasses.editButton }}
            onClick={handleOnEdit}
            disabled={false}
          ></CustomEditButton>
        </div>
      </div>
      {!isMdUp && (
        <div className={componentClasses.containerButtons}>
          <CustomButton
            className={componentClasses.footerButtonSubmit}
            color={Colors.PRIMARY}
            fullWidth
            onClick={onConfirm}
            variant={Variants.CONTAINED}
          >
            {submitText}
          </CustomButton>
          <CustomButton
            className={componentClasses.footerButtonSubmit}
            color={Colors.PRIMARY}
            fullWidth
            onClick={handleBack}
            variant={Variants.OUTLINED}
          >
            {cancelText}
          </CustomButton>
        </div>
      )}
    </CustomDialog>
  );
}
