import { makeStyles } from '@material-ui/core';
import Colors from '../../constants/Colors';
import { neutral, red } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  cancelButton: {
    backgroundColor: neutral.white,
    color: neutral.D16,
    border: `1px solid ${neutral.D16}`,
    borderRadius: '.5rem',
    height: '2.625rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: neutral.white,
    },
  },
  submitButton: {
    height: '2.625rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.PRIMARY_COLOR,
    },
  },
  body: {
    flex: 'none',
    color: neutral.D20,
    fontSize: 16,
    height: '100%',
    lineHeight: 'normal',
    padding: '1rem 2rem',
  },
  headerRoot: {
    gridTemplateColumns: 'max-content auto min-content',
    [theme.breakpoints.up('md')]: {
      padding: '1.5rem',
    },
  },
  footerRoot: {
    gridColumnGap: '0.5rem',
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      width: 600,
    },
  },
  root: {},
  extendClosingHoursDialog: {},
  extendClosingHoursDisclaimer: {
    marginBottom: '1.5rem',
  },
  extendClosingHoursDate: {
    backgroundColor: neutral['10'],
    borderRadius: '1rem',
    display: 'flex',
    marginBottom: '1.5rem',
    padding: '0.7rem 1rem',
    gap: '1rem',
  },
  checkDate: {
    '& span': {
      fontWeight: 'bold',
    },
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
  extendClosingHoursList: {
    rowGap: '2.5rem',
  },
  extendClosingHoursItem: {},
  extendClosingLabel: {
    alignItems: 'center',
    backgroundColor: neutral.D6,
    borderRadius: '0.5rem',
    display: 'flex',
    fontWeight: 'bold',
    justifyContent: 'center',
    padding: '.5rem',
    height: '100%',
    width: '100%',
  },
  extendClosingStartTime: {
    width: '100%',
  },
  extendClosingEndTime: {
    width: '100%',
  },
  extendTimeError: {
    alignItems: 'center',
    color: red.D19,
    display: 'flex',
    gap: '0.5rem',
    width: '100%',
    fontSize: 14,
    paddingLeft: '1rem',
  },
}));
