export const EVENT_NAME = 'EVENT_CARGO';

export const propertiesNames = {
  partnerId: 'PARTNER_ID',
  option: 'OPTION',
  action: 'ACTION',
  coverage: 'COVERAGE',
  orderId: 'ORDER_ID',
  cargoType: 'CARGO_TYPE',
} as const;

export const OPTION_VALUES = {
  request_rt: 'request_rt',
  orders: 'orders',
  how_cargo_works: 'how_cargo_works',
} as const;

export const ACTION_VALUES = {
  menu: 'menu',
  verify_coverage: 'verify_coverage',
  send_request: 'send_request',
  cancel_order: 'cancel_order',
  tracking_link: 'tracking_link',
} as const;

export const COVERAGE_VALUES = {
  inside: 'inside',
  out: 'out',
  no_rt: 'no_rt',
} as const;

export const TYPE_VALUES = {
  marketplace: 'marketplace',
  defect_switch: 'defect_switch',
  open_market: 'open_market',
} as const;

export type optionValues = keyof typeof OPTION_VALUES;
export type actionValues = keyof typeof ACTION_VALUES;
export type coverageValues = keyof typeof COVERAGE_VALUES;
export type typeValues = keyof typeof TYPE_VALUES;
