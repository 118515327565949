// Redux
import { createActions } from 'redux-actions';

const {
  closeRTReportReasonsDialogAction,
  clearRTReportReasonsDialogAction,
  openRTReportReasonsDialogAction,
  setRTReportReasonsDialogIsLoadingAction,
  setRTReportReasonsDialogIsNotLoadingAction,
  setRTReportReasonsDialogReasonsAction,
} = createActions({
  CLOSE_R_T_REPORT_REASONS_DIALOG_ACTION: () => {},
  CLEAR_R_T_REPORT_REASONS_DIALOG_ACTION: () => {},
  OPEN_R_T_REPORT_REASONS_DIALOG_ACTION: orderId => ({ orderId }),
  SET_R_T_REPORT_REASONS_DIALOG_IS_LOADING_ACTION: () => ({}),
  SET_R_T_REPORT_REASONS_DIALOG_IS_NOT_LOADING_ACTION: () => ({}),
  SET_R_T_REPORT_REASONS_DIALOG_REASONS_ACTION: reasons => ({ reasons }),
});

const clearRTReportReasonsDialog = () => dispatch => {
  dispatch(clearRTReportReasonsDialogAction());
};

const closeRTReportReasonsDialog = () => dispatch => {
  dispatch(closeRTReportReasonsDialogAction());
};

const openRTReportReasonsDialog = orderId => dispatch => {
  dispatch(openRTReportReasonsDialogAction(orderId));
};

const setRTReportReasonsDialogIsLoading = () => dispatch => {
  dispatch(setRTReportReasonsDialogIsLoadingAction());
};

const setRTReportReasonsDialogIsNotLoading = () => dispatch => {
  dispatch(setRTReportReasonsDialogIsNotLoadingAction());
};

const setRTReportReasonsDialogReasons = reasons => dispatch => {
  dispatch(setRTReportReasonsDialogReasonsAction(reasons));
};

export default {
  clearRTReportReasonsDialog,
  clearRTReportReasonsDialogAction,
  closeRTReportReasonsDialog,
  closeRTReportReasonsDialogAction,
  openRTReportReasonsDialog,
  openRTReportReasonsDialogAction,
  setRTReportReasonsDialogIsLoading,
  setRTReportReasonsDialogIsLoadingAction,
  setRTReportReasonsDialogIsNotLoading,
  setRTReportReasonsDialogIsNotLoadingAction,
  setRTReportReasonsDialogReasons,
  setRTReportReasonsDialogReasonsAction,
};
