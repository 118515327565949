import { useFormatMessage } from '../../../../intl/intl';
import { useSaturationStateText } from '../../shared/hooks';

import type { SaturationStateProp } from '../../shared/models';

export function useSaturationChipLang(props: Props) {
  const { variant } = props;
  const { status: legend } = useSaturationStateText({ variant });
  const tooltip = `highDemand.saturationChip.tooltip.${variant}`;
  return {
    legend,
    tooltip: useFormatMessage({ id: tooltip }),
  };
}

interface Props extends SaturationStateProp {}
