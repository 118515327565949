const address = {
  id: 'customStoreBox.addressText',
};

const diagnosticLinkText = {
  id: 'customStoreBox.diagnosticLinkText',
};

const phone = {
  id: 'customStoreBox.phoneText',
};

const showScheduleButtonText = {
  id: 'customStoreBox.showScheduleButtonText',
};

const textIds = {
  address,
  diagnosticLinkText,
  phone,
  showScheduleButtonText,
};

export default textIds;
