// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => ({
  childrenContainer: {
    overflow: 'auto',
    flex: '1 1 1px',
  },
  container: {
    // Avoids dialog paper desconfiguration after printing
    height: '100% !important',
  },
  footerRoot: {
    borderTop: `1px solid ${neutral.D10}`,
  },
  root: {
    '@media print': {
      display: 'none !important',
    },
  },
  paper: {
    height: '100%',
    margin: 0,
    maxHeight: 'none',
    maxWidth: 'none',
    width: '100%',
    borderRadius: 0,
    [theme.breakpoints.up('md')]: {
      height: 608,
      maxHeight: '100%',
      maxWidth: '100%',
      width: 904,
      overflowY: 'hidden',
    },
  },
}));
