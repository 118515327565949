// React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Components
import OrdersKanbanPage from './OrdersKanbanPage'

const mapStateToProps = (
  {
    appReducer: { isOffline, snackBar },
    ordersReducer: { orders },
    partnerReducer: { isPendingTurnPartnerOnBySchedule, profile },
  },
  { history, intl: { formatMessage } },
) => {
  const title = formatMessage({ id: 'ordersKanbanPage.title' })
  return { title }
}

const mapDispatchToProps = {}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(OrdersKanbanPage),
)
