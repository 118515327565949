import { SATURATION_INPUT_DTO, SATURATION_OUTPUT_DTO } from '../core';

import type { SaturationDTO } from '../core';
import type { Saturation } from '../../domain';

export const highDemandAdapter = {
  toModelSaturation,
  toSaturationDTO,
} as const;

function toModelSaturation(dto: SaturationDTO): Saturation | undefined {
  return SATURATION_INPUT_DTO[dto];
}

function toSaturationDTO(severity: Saturation): SaturationDTO {
  return SATURATION_OUTPUT_DTO[severity] as SaturationDTO;
}
