// External libs
import { createSelector } from 'reselect';
// Interfaces
import { State } from '../interfaces/state';

const selectSlice: (state: any) => State = (state: any) => state.supportDialog;

const selectOpen = createSelector<any, State, boolean>(
  selectSlice,
  reducer => reducer.open,
);

const selectors = {
  selectOpen,
};

export default selectors;
