// primary libraries (third party library)
import React from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery, useTheme } from '@material-ui/core';

// our components and custom hooks
import { hooks } from './hooks';
import { DropdownButton } from './dropdown-button';

// systems to manage global state
import PartnerActions from '../../../redux/actions/partners-actions';
import EventActions from '../../../redux/actions/event-actions/event.actions';

// Constants
import { amplitudPartnerType as amplitudPT } from '../constants';

// +++++ StoreStatusDrop
export function StoreStatusDrop(): JSX.Element {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));

  const partnerId = hooks.usePartnerId();
  const list = hooks.useStoresStatusNotification();
  const isPartnerStateLoading = hooks.useIsPartnerStateLoading();
  const { isSingleStore, primaryState } = hooks.useGetIsSingleAndState({
    list,
  });
  const hasActivatedAllStores = hooks.useGetHasActivatedAllStores({ list });
  const hasDeactivatedAllStores = hooks.useGetHasDeactivatedAllStores({ list });

  const handleGetSingleStoreDetails = () => {
    if (isSingleStore && partnerId) {
      dispatch(PartnerActions.getState());
      dispatch(PartnerActions.getStoreStatusDetail());
    }
    EventActions.onClickStoreStatusDrop({
      partnerId,
      typePartner: isSingleStore ? amplitudPT.single : amplitudPT.many,
    });
  };

  return (
    <DropdownButton
      isLarge={isLarge}
      primaryState={primaryState}
      isSingleStore={isSingleStore}
      handleOnClick={handleGetSingleStoreDetails}
      isPartnerStateLoading={isPartnerStateLoading}
      hasActivatedAllStores={hasActivatedAllStores}
      hasDeactivatedAllStores={hasDeactivatedAllStores}
    />
  );
}
// ----- StoreStatusDrop
