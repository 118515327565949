import { clsx } from 'clsx';

import CustomButton from '../common/buttons/custom-button';

import useStyles from './OrderListChipBox.styles';

function OrderListChipBox(props) {
  const {
    classes = {},
    canceledOrdersTitle,
    onClickBase = () => {},
    newOrdersTitle,
    showCanceledOrdersButton,
    showNewOrdersButton,
    onClick = () => {},
  } = props;

  const _classes = useStyles();

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      {showNewOrdersButton && (
        <CustomButton
          classes={{
            submitButton: clsx(_classes.buttonRoot, _classes.buttonNewOrders),
          }}
          color='primary'
          fullWidth={false}
          onClick={() => {
            onClickBase(0);
            if (!!onClick) {
              onClick();
            }
          }}
          variant='contained'
        >
          {newOrdersTitle}
        </CustomButton>
      )}
      {showCanceledOrdersButton && (
        <CustomButton
          classes={{
            submitButton: clsx(
              _classes.buttonRoot,
              _classes.buttonCanceledOrders,
            ),
          }}
          color='secondary'
          fullWidth={false}
          onClick={() => {
            onClickBase(0);
            if (!!onClick) {
              onClick();
            }
          }}
          variant='contained'
        >
          {canceledOrdersTitle}
        </CustomButton>
      )}
    </div>
  );
}

export default OrderListChipBox;
