const startTextLabel = {
  id: 'onBoardingDialog.startTextLabel',
}
const backTextLabel = {
  id: 'onBoardingDialog.backTextLabel',
}
const nextTextLabel = {
  id: 'onBoardingDialog.nextTextLabel',
}
const finishTextLabel = {
  id: 'onBoardingDialog.finishTextLabel',
}
const textIds = {
  startTextLabel,
  backTextLabel,
  nextTextLabel,
  finishTextLabel,
}

export default textIds
