import { createAction } from '@reduxjs/toolkit';

const closeAction = createAction('deactivatedProductsDialog/close');

const clearAction = createAction('deactivatedProductsDialog/clear');

const openAction = createAction<{ partnerId: any }>(
  'deactivatedProductsDialog/open',
);

const clear = () => (dispatch: any) => {
  dispatch(clearAction());
};

const close = () => (dispatch: any) => {
  dispatch(closeAction());
};

const open = (partnerId: any) => (dispatch: any) => {
  dispatch(openAction({ partnerId }));
};

const actions = {
  clear,
  clearAction,
  close,
  closeAction,
  open,
  openAction,
};

export default actions;
