import {
  ConnectivityIssue,
  ConnectivityIssueRequest,
  ConnectivityIssueDetail,
  ConnectivityIssueDetailRequest,
} from './interfaces'

const parseConnectivityDetailIssueToRequest = (
  issues: ConnectivityIssueDetail,
): ConnectivityIssueDetailRequest => {
  return {
    origin: issues.origin,
    device_timestamp: issues.deviceTimestamp,
  }
}

const parseConnectivityDetailIssuesToRequests = (
  issues: ConnectivityIssueDetail[],
) => issues.map(parseConnectivityDetailIssueToRequest)

const parseConnectivityIssueToRequest = (
  connectivityIssue: ConnectivityIssue,
): ConnectivityIssueRequest => {
  return {
    partner_id: connectivityIssue.partnerId,
    device_id: connectivityIssue.deviceId,
    device_date: connectivityIssue.deviceDate,
    platform: connectivityIssue.platform,
    version: connectivityIssue.version,
    issues: parseConnectivityDetailIssuesToRequests(connectivityIssue.issues),
  }
}

const utils = { parseConnectivityIssueToRequest }

export default utils
