import { useFormatMessage } from '../../../../intl/intl';

import makeStyles from './ExCarouselLogin.styles';

export function ExCarouselLogin() {
  const whereAre = useFormatMessage({ id: 'login.slider.firstText' });
  const partner = useFormatMessage({ id: 'login.slider.secondText' });
  const kitchen = useFormatMessage({ id: 'login.slider.thirdText' });

  const classes = makeStyles();

  return (
    <aside className={classes.container}>
      <p className={classes.text}>
        {whereAre}&nbsp;
        <span className={classes.textAccent}>{partner} </span>
        {kitchen}
      </p>
    </aside>
  );
}
