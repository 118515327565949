// External libs
import { createSelector } from 'reselect'
// Consts
import { textIds } from '../consts'
// Utils
import { formatMessage } from '../../../../../intl/intl'
// Selectors
import ordersSelectors from '../../../../../redux/selectors/orders-selectors'

const selectAlertText = createSelector<
  any,
  number,
  number,
  number,
  string | null
>(
  ordersSelectors.selectCTToTakeOrderInOrderDetailDialog,
  ordersSelectors.selectOrderDefaultPredictiveCTConfigInOrderDetailDialog,
  ordersSelectors.selectOrderStoreDefaultCTInOrderDetailDialog,
  (ctToTakeOrder, orderDefaultPredictiveCT, orderStoreDefaultCT) => {
    if (!!orderDefaultPredictiveCT) {
      if (ctToTakeOrder > orderDefaultPredictiveCT)
        return formatMessage(textIds.highPickingTimeWarning)

      if (orderDefaultPredictiveCT === ctToTakeOrder)
        return formatMessage(textIds.defaultHighPickingTimeWarning)

      return orderDefaultPredictiveCT === ctToTakeOrder
        ? formatMessage(textIds.defaultPredictiveCTMessage)
        : null
    }

    if (ctToTakeOrder > orderStoreDefaultCT) {
      return formatMessage(textIds.highPickingTimeWarning)
    }

    if (orderStoreDefaultCT === ctToTakeOrder)
      return formatMessage(textIds.defaultHighPickingTimeWarning)

    return orderStoreDefaultCT === ctToTakeOrder
      ? formatMessage(textIds.defaultStoreCTMessage)
      : null
  },
)
const selectors = { selectAlertText }

export default selectors
