import { clsx } from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';

import useStyles from './EmptyRtAvatar.styles';

function EmptyRtAvatar({ classes = {} }) {
  const _classes = useStyles();
  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <CircularProgress classes={{ colorPrimary: _classes.colorPrimary }} />
    </div>
  );
}

export default EmptyRtAvatar;
