// React
import React from 'react'
import { SvgIcon } from '@material-ui/core'
// Constant
import Colors from '../../../constants/Colors'

const MustacheIcon = ({ className }) => {
  return (
    <SvgIcon
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='112'
      height='112'
      viewBox='0 0 182 112'
    >
      <path
        fill={Colors.SECONDARY_COLOR}
        fillRule='nonzero'
        stroke={Colors.SECONDARY_COLOR}
        strokeWidth='4'
        d='M87.818 16.323l.03-.023c.486-.39.956-.77 1.69-1.368l.401-.325c4.387-3.565 6.502-5.177 9.246-6.897 6.465-4.054 13.559-4.848 20.35-2.076 3.345 1.363 6.342 3.273 10.7 6.553 1.8 1.355 3.17 2.51 6.18 5.145l.008.007c2.862 2.505 4.146 3.59 5.795 4.842 8.168 6.205 14.583 5.858 22.373-1.18l-.017.016a586.91 586.91 0 0 1 2.092-1.885l4.3-3.852-.996 5.687c-1.284 7.336-4.054 14.05-8.283 20.224-5.41 7.9-12.485 13.44-21.183 16.473-19.35 6.75-43.113-1.642-52.686-18.132-9.573 16.49-33.337 24.882-52.685 18.132-8.699-3.033-15.774-8.574-21.184-16.473-4.229-6.174-6.998-12.888-8.283-20.224l-.995-5.687 4.3 3.852c.582.522 1.047.941 2.091 1.885 7.774 7.022 14.188 7.369 22.357 1.164 1.649-1.251 2.933-2.337 5.795-4.842l.008-.007c3.01-2.635 4.379-3.79 6.18-5.145 4.357-3.28 7.354-5.19 10.698-6.552 6.793-2.774 13.893-1.978 20.352 2.075 2.743 1.72 4.858 3.332 9.245 6.897l.4.325a363.11 363.11 0 0 0 1.721 1.391z'
        opacity='.526'
      />
    </SvgIcon>
  )
}

MustacheIcon.defaultProps = {}

export default MustacheIcon
