// React
import React from 'react'
// Components
import CustomToggleSwitchOptions from '../common/custom-toggle-switch-options'

const MenuToppingDeactivationOptions = ({
  isAvailable,
  options,
  partnerId,
  topping,
  deactivatedItemId,
  setDeactivatedItemId,
}) => {
  return (
    <CustomToggleSwitchOptions
      active={isAvailable}
      item={topping}
      isProduct={false}
      options={options}
      partnerId={partnerId}
      deactivatedItemId={deactivatedItemId}
      setDeactivatedItemId={setDeactivatedItemId}
    />
  )
}

MenuToppingDeactivationOptions.defaultProps = {
  isAvailable: false,
  options: [],
}

export default MenuToppingDeactivationOptions
