import { Box, Typography } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import { KanbanSuccessCardStyle } from './KanbanSuccessCard.style';
import { useKanbanOMLang } from '../hooks';

export function KanbanSuccessCard() {
  const style = KanbanSuccessCardStyle();
  const { acceptedByClientText } = useKanbanOMLang();

  return (
    <Box display='flex' alignItems='flex-start'>
      <Typography align='right' className={style.message}>
        {acceptedByClientText}
      </Typography>
      <CheckCircleRoundedIcon />
    </Box>
  );
}
