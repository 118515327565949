// External libs
import { createSelector } from 'reselect';

const selectSuccesfulProxySessionDialogReducer = state =>
  state.successfulProxySessionDialogReducer;

const selectOrderId = createSelector(
  selectSuccesfulProxySessionDialogReducer,
  successfulProxySessionDialogReducer =>
    successfulProxySessionDialogReducer.orderId,
);

const SuccessfulProxySessionDialogSelectors = { selectOrderId };

export default SuccessfulProxySessionDialogSelectors;
