import { stateOMType } from '../../../../../business/order-mgmt';
import { useFormatMessage } from '../../../../../intl/intl';

export function useKanbanOMLang(state?: stateOMType) {
  const messageCategory = 'orderManagement';

  const pendingChangesText = useFormatMessage({
    id: `${messageCategory}.pendingChanges`,
  });
  const acceptedByClientText = useFormatMessage({
    id: `${messageCategory}.acceptedByClient`,
  });
  const rejectedByTimeoutText = useFormatMessage({
    id: `${messageCategory}.rejectedByTimeout`,
  });
  const rejectedByTimeoutRemainingText = useFormatMessage({
    id: `${messageCategory}.rejectedByTimeoutRemaining`,
  });
  const getDisclaimerText =
    state === 'to_wait' ? pendingChangesText : rejectedByTimeoutText;

  return {
    acceptedByClientText,
    rejectedByTimeoutRemainingText,
    disclaimerText: getDisclaimerText,
  };
}
