const title = {
  id: 'schedulePMFlowDialog.title',
}
const subTitle = {
  id: 'schedulePMFlowDialog.subTitle',
}
const backText = {
  id: 'schedulePMFlowDialog.backText',
}
const submitText = {
  id: 'schedulePMFlowDialog.submitText',
}

const textIds = {
  title,
  subTitle,
  backText,
  submitText,
}

export default textIds
