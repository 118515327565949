import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../../shared/styles/colors';

export default makeStyles({
  icon: ({ disabled = false }) => ({
    backgroundColor: disabled ? neutral['20'] : neutral['90'],
    border: 'none',
    borderRadius: '50%',
    color: disabled ? neutral['20'] : neutral.white,
    height: 20,
    width: 20,
  }),
  label: {
    paddingRight: 4,
    paddingLeft: 4,
  },
});
