// Material UI
import { makeStyles } from '@material-ui/core';
import Colors from '../../../../constants/Colors';
import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {
    backgroundColor: neutral.D1,
    color: Colors.INFO_LIGHT_COMMENT_FONT_COLOR,
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridRowGap: 10,
    padding: '20px 16px',
  },
  LabelText: {
    fontSize: 13,
  },
  productSuggestText: {
    fontSize: 13,
  },
  LabelComment: {
    color: neutral.D15,
    fontSize: 12,
  },
}));
