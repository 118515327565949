import { useState, useEffect, useCallback } from 'react';

import CustomButton, {
  Colors,
  Variants,
} from '../common/buttons/custom-button';
import CustomDrawer from '../common/custom-drawer';
import CustomCheckbox from '../common/custom-checkbox';
import { ToppingBoxesList } from '../topping-boxes-list';

import { useFormatMessage } from '../../intl/intl';
import EventActions from '../../redux/actions/event-actions/event.actions';

import { textIds } from './consts';
import { selectorTypes } from '../topping-box';
import { analitycsEvent, GAActions, GACategories } from '../../utils/ga-utils';

import useStyles from './styles';

import type { BaseProps } from '../../interfaces';

const determineConfirmButtonDisabled = (foundToppings: any) => {
  const selectedToppings = foundToppings.filter(
    (topping: any) => topping.selected,
  );
  return selectedToppings.length === 0;
};

const selectedAllToppings = (foundToppings: any) => {
  const toppingsSelected = foundToppings.filter(
    (topping: any) => topping.selected,
  );
  return foundToppings.length === toppingsSelected.length;
};

function ToppingsMassiveDeactivationDrawer(props: Props) {
  const {
    deactivatedToppingNamesList,
    deactivationType,
    deactivationDate,
    onClose = () => {},
    onConfirm = () => {},
    open,
    partnerId,
    storeId,
    suggestedToppings,
  } = props;

  const title = useFormatMessage(textIds.title);
  const cancelButtonText = useFormatMessage(textIds.cancelButtonText);
  const confirmButtonText = useFormatMessage(textIds.confirmButtonText);
  const selectAll = useFormatMessage(textIds.selectAll);
  const description = useFormatMessage(textIds.description, {
    x: deactivatedToppingNamesList,
  });
  const [foundToppings, setFoundToppings] = useState<any>(suggestedToppings);
  const componentClasses = useStyles();

  const handleSelectTopping = useCallback(
    changingTopping => {
      const updatedTopping = {
        ...changingTopping,
        selected: !changingTopping.selected,
      };
      const toppingIndex = foundToppings.findIndex(
        (topping: any) => topping.id === updatedTopping.id,
      );
      foundToppings[toppingIndex] = updatedTopping;
      setFoundToppings([...foundToppings]);
    },
    [foundToppings],
  );

  const onHandleConfirm = useCallback(() => {
    analitycsEvent(
      GACategories.MENU_MANAGEMENT,
      GAActions.DEACTIVATE_MULTIPLE_TOPPINGS,
      `partner_id=${partnerId}`,
    );
    const selectedToppings = foundToppings.filter(
      (topping: any) => topping.selected,
    );
    onConfirm(storeId, selectedToppings, deactivationType, deactivationDate);
  }, [
    deactivationType,
    foundToppings,
    onConfirm,
    partnerId,
    storeId,
    deactivationDate,
  ]);

  const onHandleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSelectAllToppings = useCallback(
    event => {
      const newToppingArray = foundToppings.map((topping: any) => {
        topping.selected = event.target.checked;
        return topping;
      });
      setFoundToppings(newToppingArray);
    },
    [foundToppings],
  );

  const isConfirmButtonDisabled = determineConfirmButtonDisabled(foundToppings);

  const isAllChecked = selectedAllToppings(foundToppings);

  useEffect(() => {
    if (open) {
      EventActions.onOpenMassiveDeactivationToppingsDrawer(storeId);
    }
  }, [open, storeId]);

  useEffect(() => {
    setFoundToppings(suggestedToppings);
  }, [open]);

  return (
    <CustomDrawer
      onClose={onClose}
      open={open}
      showBackButton={false}
      title={title}
    >
      <div className={componentClasses.content}>
        <div className={componentClasses.description}>
          <span>{description}</span>
        </div>
        <div className={componentClasses.checkbox}>
          <span>{selectAll}</span>
          <CustomCheckbox
            onChange={handleSelectAllToppings}
            checked={isAllChecked}
            value={isAllChecked}
          />
        </div>
        <ToppingBoxesList
          classes={{
            root: componentClasses.toppingBoxesListRoot,
            titleContainer: componentClasses.toppingBoxesListTitleContainer,
          }}
          onChange={handleSelectTopping}
          selectorPropertyName={'selected'}
          selectorType={selectorTypes.CHECKBOX}
          showTitle={false}
          toppings={foundToppings}
        />
      </div>
      <div className={componentClasses.actionsContainer}>
        <CustomButton
          classes={{
            root: componentClasses.button,
            submitButton: componentClasses.cancelButton,
          }}
          fullWidth={false}
          onClick={onHandleClose}
          text={cancelButtonText}
        ></CustomButton>
        <CustomButton
          classes={{
            root: componentClasses.button,
          }}
          disabled={isConfirmButtonDisabled}
          color={Colors.PRIMARY}
          variant={Variants.CONTAINED}
          fullWidth={false}
          onClick={onHandleConfirm}
          text={confirmButtonText}
        ></CustomButton>
      </div>
    </CustomDrawer>
  );
}

export default ToppingsMassiveDeactivationDrawer;

interface Props extends BaseProps {
  deactivationType: any;
  deactivationDate: any;
  open: boolean;
  onClose?: () => void | Promise<void>;
  onConfirm?: (
    storeId: number,
    selectedToppings: any,
    deactivationType: any,
    deactivationDate: any,
  ) => void | Promise<void>;
  storeId: number;
  suggestedToppings: any;
  deactivatedToppingNamesList: string;
  partnerId: number;
}
