// External libs
import { handleActions } from 'redux-actions';
// Actions
import CustomerPhoneProxySessionDialogActions from '../actions/customerPhoneProxySessionDialog.actions';

const defaultOrderId = 0;
const initialState = {
  orderId: defaultOrderId,
  open: false,
  phoneNumber: null,
  messageError: null,
  isOM: false,
};

export default handleActions(
  {
    [CustomerPhoneProxySessionDialogActions.clearCustomerPhoneProxySessionDialogAction]: state =>
      clearDialog(state),
    [CustomerPhoneProxySessionDialogActions.closeCustomerPhoneProxySessionDialogAction]: state =>
      closeDialog(state),
    [CustomerPhoneProxySessionDialogActions.openCustomerPhoneProxySessionDialogAction]: (
      state,
      { payload: { orderId, isOM } },
    ) => openDialog(state, orderId, isOM),
    [CustomerPhoneProxySessionDialogActions.setPhoneNumberAction]: (
      state,
      { payload: { phoneNumber } },
    ) => setPhoneNumber(state, phoneNumber),
    [CustomerPhoneProxySessionDialogActions.setErrorTextAction]: (
      state,
      { payload: { errorCode, messageError } },
    ) => setErrorText(state, errorCode, messageError),
    [CustomerPhoneProxySessionDialogActions.clearErrorTextAction]: state =>
      setErrorText(state, null, null),
  },
  initialState,
);

const clearDialog = state => {
  return {
    ...initialState,
  };
};

const closeDialog = state => {
  return {
    ...state,
    open: false,
  };
};

const openDialog = (state, orderId, isOM) => {
  return {
    ...state,
    open: true,
    orderId: orderId || state.orderId,
    isOM,
  };
};

const setPhoneNumber = (state, phoneNumber) => {
  return {
    ...state,
    phoneNumber: phoneNumber,
  };
};

const setErrorText = (state, errorCode, messageError) => {
  return {
    ...state,
    errorCode: errorCode,
    messageError: messageError,
  };
};
