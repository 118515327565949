// Material UI
import { makeStyles } from '@material-ui/core'
import Colors from '../../constants/Colors'

export default makeStyles(theme => ({
  root: {
    paddingTop: 36,
  },
  body: {},
  headerRoot: {},
  paper: {},
  buttonSubmit: {},
  rejectButton: {},
  startBanner: {},
  nameTextFieldRoot: {
    background: Colors.CORNFLOWER_BLUE,
  },
  input: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  description: {
    paddingBottom: 24,
    textAlign: 'left',
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
    },
  },
}))
