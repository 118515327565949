import { useFormatMessage } from '../../../../intl/intl';

import type { TTLOption } from '../../shared/models';

export function useDemandTTLOptionTexts({ ttl = 0 }: { ttl: TTLOption }) {
  const [isCustom, isNumber] = [ttl === 'custom', Number.isInteger(ttl)];
  const BASE_ID = 'highDemand.ttlOption';
  const titleID = `${BASE_ID}.title.${isNumber ? 'minutes' : ttl}`;
  const legendID = `${BASE_ID}.description.custom`;
  return {
    title: useFormatMessage({ id: titleID }, { ttl }),
    legend: isCustom ? useFormatMessage({ id: legendID }) : '',
  };
}
