import { CheckoutRTStatusType, HandshakeCodeType } from '../types';

export const checkoutRTHelper = {
  showSection: (code: HandshakeCodeType) => Boolean(code.length),
  isInitial: (s: CheckoutRTStatusType) => s === 'idle',
  updateInProgress: (s: CheckoutRTStatusType) => s === 'isUpdateLoading',
  isUpdated: (s: CheckoutRTStatusType) => s === 'updated',
  isUpdateDenied: (s: CheckoutRTStatusType) => s === 'updateDenied',
  getInProgress: (s: CheckoutRTStatusType) => s === 'isGetLoading',
  isGetLoaded: (s: CheckoutRTStatusType) => s === 'loaded',
  isNotReadyToSetData: (s: CheckoutRTStatusType) =>
    s === 'idle' ||
    s === 'isGetLoading' ||
    s === 'isUpdateLoading' ||
    s === 'updated',
  isNotReadyToUpdateData: (s: CheckoutRTStatusType) =>
    s === 'idle' ||
    s === 'isGetLoading' ||
    s === 'loaded' ||
    s === 'loadDenied',
};
