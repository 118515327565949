// React
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// Actions
import OrdersKanbanAction from './../../redux/actions/ordersKanban.actions';
// Components
import OrdersKanbanLayout from './OrdersKanbanLayout';

const mapStateToProps = ({ ordersKanbanReducer: { index } }, props) => {
  return { index };
};

const mapDispatchToProps = {
  onChangeIndex: OrdersKanbanAction.setKanbanIndex,
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(OrdersKanbanLayout),
);
