import { createSelector } from 'reselect';

import PartnerOrderStateType from '../../../constants/PartnerOrderStateType';

import type { BundleId } from '../../../business/shared/domain';
import type { OrderResponseFromStatusV2 } from '../../../business/shared/infra/core';

type OrderResponse = OrderResponseFromStatusV2;

function selectOrdersInKanban(state: any) {
  return state.ordersKanbanReducer;
}

const pendingOrders = createSelector<any, any, OrderResponse[]>(
  selectOrdersInKanban,
  kanbans => kanbans.orders[PartnerOrderStateType.NEWORDER],
);
const canceledOrders = createSelector<any, any, OrderResponse[]>(
  selectOrdersInKanban,
  kanbans => kanbans.orders[PartnerOrderStateType.CANCEL_APP],
);
const inCooking = createSelector<any, any, OrderResponse[]>(
  selectOrdersInKanban,
  kanbans => kanbans.orders[PartnerOrderStateType.COOKING],
);

const amountInCooking = createSelector<any, OrderResponse[], number>(
  inCooking,
  orders => orders.length,
);

const inToDeliver = createSelector<any, any, OrderResponse[]>(
  selectOrdersInKanban,
  kanbas => kanbas.orders[PartnerOrderStateType.READYANDPACKED],
);

const amountInToDeliver = createSelector<any, OrderResponse[], number>(
  inToDeliver,
  orders => orders.length,
);

const inToDeliverOnlyOnePerBundle = createSelector<
  any,
  OrderResponse[],
  OrderResponse[]
>(inToDeliver, function (ordersToDeliver) {
  const bundleIds: BundleId[] = [];
  return ordersToDeliver.filter(order => {
    if (bundleIds.includes(order.bundle_id)) return false;
    if (!!order.bundle_id) bundleIds.push(order.bundle_id);
    return true;
  });
});

export const ordersKanbanSelector = {
  pendingOrders,
  canceledOrders,
  inCooking,
  amountInCooking,
  inToDeliver,
  amountInToDeliver,
  inToDeliverOnlyOnePerBundle,
};
