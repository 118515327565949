import { useSelector } from 'react-redux';

import Selectors from '../selectors';

const useMessage = () => {
  return useSelector(Selectors.selectMessage);
};

const Hooks = {
  useMessage,
};
export default Hooks;
