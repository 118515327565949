import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  SATURATION,
  highDemandAnalytics,
} from '../../../../../business/highDemand';
import AppActions from '../../../../../redux/actions/app-actions/app.actions';
import partnerActions from '../../../../../redux/actions/partners-actions/partners.actions';
import { useDemandTimer } from '../../../shared/hooks';
import { useFetchStoreStatus } from './useFetchStoreStatus';
import { useFetchUpdateDemand } from './useFetchUpdateDemand';
import { useHighDemandDialogTexts } from './useHighDemandDialogTexts';
import { useMaxCounts } from './useMaxCounts';

import { useNavigate } from 'react-router-dom';
import type { Saturation } from '../../../../../business/highDemand';
import { STORE_STATUS_INPUT_DTO } from '../../../../../business/highDemand/infra/core';
import type { PartnerId, StoreId } from '../../../../../business/shared/domain';
import Paths from '../../../../../constants/Paths';

const STEPS_INIT_STATE = {
  storeList: false,
  statusChange: false,
  messageAfterApply: false,
  emptyStoreStatus: false,
};

interface BtnState {
  isLoading?: boolean;
  onClick?: () => void;
}
const BTN_INIT_STATE: BtnState = {
  isLoading: false,
  onClick: undefined,
};

export function useHighDemandDialog(props: Props) {
  const { onCloseDialog, isSingleStore, partnerId } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [storeSelected, setStoreSelected] = useState<any>(null);
  const [goBackBtn, setGoBackBtn] = useState(BTN_INIT_STATE);
  const [primaryBtn, setPrimaryBtn] = useState(BTN_INIT_STATE);
  const [processStep, setProcessStep] = useState(STEPS_INIT_STATE);
  const [isActiveStore, setIsActiveStore] = useState<boolean>(true);

  const counts = useMaxCounts();
  const updateDemand = useFetchUpdateDemand();
  const texts = useHighDemandDialogTexts({ processStep, isActiveStore });
  const { setNewDemandTimer } = useDemandTimer({ isSingleStore });
  const { stores, severityTimes, ...saturationStores } = useFetchStoreStatus({
    partnerId,
  });

  const goToFirstStep = () => {
    setGoBackBtn(BTN_INIT_STATE);
    setPrimaryBtn(BTN_INIT_STATE);
    setProcessStep(() => ({ ...STEPS_INIT_STATE, storeList: true }));
  };

  const goToSecondStep = (store: any, withoutGoBack: boolean = false) => {
    setStoreSelected(store);
    const _isActiveStore = store.status === STORE_STATUS_INPUT_DTO.ACTIVE;
    setIsActiveStore(_isActiveStore);
    if (!withoutGoBack) setGoBackBtn({ onClick: goToFirstStep });
    setProcessStep(() => ({
      ...STEPS_INIT_STATE,
      emptyStoreStatus: !_isActiveStore,
      statusChange: _isActiveStore,
    }));
  };

  const goToDemandView = (storeId: StoreId) => {
    goToSecondStep(stores.find(item => item.store_id === storeId));
  };

  const afterUpdateDemandError = () => {
    dispatch(
      AppActions.openSnackBarError(
        texts.errorApply.generic.msg,
        texts.errorApply.generic.title,
      ),
    );
    onCloseDialog();
  };

  const afterUpdateDemandSuccess = () => {
    dispatch(partnerActions.getState());
    dispatch(
      AppActions.openSnackBarSuccess(
        texts.successApply.msg,
        texts.successApply.title,
      ),
    );
    onCloseDialog();
  };

  const onSelectDemandState = (params: OnSelectDemandStateParams) => {
    const { severity, ttl = 0 } = params;
    const { saturated, isEligibleForFastOperation } = storeSelected;

    const FROM_REGULAR_DEMAND_TO_FAST_OPERATION =
      saturated === SATURATION.regular && severity === SATURATION.fast;
    const FROM_FAST_OPERATION_TO_REGULAR_DEMAND =
      saturated === SATURATION.fast && severity === SATURATION.regular;
    const FROM_LOW_OR_HIGH_OR_SUPERHIGH_DEMAND_TO_FAST_OPERATION =
      (saturated === SATURATION.low ||
        saturated === SATURATION.high ||
        saturated === SATURATION.superHigh) &&
      severity === SATURATION.fast;

    const FROM_FAST_OPERATION_TO_LOW_OR_HIGH_OR_SUPER_HIGH_DEMAND =
      (severity === SATURATION.low ||
        severity === SATURATION.high ||
        severity === SATURATION.superHigh) &&
      saturated === SATURATION.fast;

    const CHANGE_DEMAND_WITHOUT_TTL =
      severity === 'deactivated' ||
      (severity !== 'regular' && severity !== 'fast' && ttl === 0) ||
      (severity === 'regular' &&
        storeSelected.isEligibleForFastOperation &&
        ttl === 0);

    const IS_FAST_OPERATION_ENABLED =
      severity === SATURATION.fast && saturated === SATURATION.fast;

    if (CHANGE_DEMAND_WITHOUT_TTL || IS_FAST_OPERATION_ENABLED) {
      setPrimaryBtn(prev => ({ ...prev, onClick: undefined }));
      return;
    }

    if (FROM_REGULAR_DEMAND_TO_FAST_OPERATION) {
      setPrimaryBtn(prev => ({
        ...prev,
        onClick: () => {
          const storeId = storeSelected.storeId;
          updateDemand.fnFetchEnabledFastOperation({
            payload: { storeId, enabledFastOperation: true },
            onSuccess: () => {
              highDemandAnalytics.whenSubmit({
                storeId,
                partnerId,
                timeOption: ttl,
                demandOption: severity,
                additionalCT: severityTimes[severity],
                hasHoldLowCT: isEligibleForFastOperation,
              });
            },
          });
        },
      }));
      return;
    }

    if (FROM_FAST_OPERATION_TO_REGULAR_DEMAND) {
      setPrimaryBtn(prev => ({
        ...prev,
        onClick: () => {
          const storeId = storeSelected.storeId;
          updateDemand.fnFetchEnabledFastOperation({
            payload: { storeId, enabledFastOperation: false, ttl },
            onSuccess: () => {
              counts.setNewCountByStore({ storeId });
              setNewDemandTimer({ severity, ttl });
              highDemandAnalytics.whenSubmit({
                storeId,
                partnerId,
                timeOption: ttl,
                demandOption: severity,
                additionalCT: severityTimes[severity],
                hasHoldLowCT: isEligibleForFastOperation,
              });
            },
          });
        },
      }));
      return;
    }

    if (FROM_LOW_OR_HIGH_OR_SUPERHIGH_DEMAND_TO_FAST_OPERATION) {
      setPrimaryBtn(prev => ({
        ...prev,
        onClick: () => {
          const storeId = storeSelected.storeId;
          updateDemand.fnFetch({
            payload: {
              storeId,
              ttl,
              severity: {
                next: SATURATION.regular,
                current: storeSelected.saturated,
              },
            },
            onSuccess: () => {
              updateDemand.fnFetchEnabledFastOperation({
                payload: { storeId, enabledFastOperation: true },
                onSuccess: () => {
                  highDemandAnalytics.whenSubmit({
                    storeId,
                    partnerId,
                    timeOption: ttl,
                    demandOption: severity,
                    additionalCT: severityTimes[severity],
                    hasHoldLowCT: isEligibleForFastOperation,
                  });
                },
              });
            },
          });
        },
      }));
      return;
    }

    if (FROM_FAST_OPERATION_TO_LOW_OR_HIGH_OR_SUPER_HIGH_DEMAND) {
      setPrimaryBtn(prev => ({
        ...prev,
        onClick: () => {
          const storeId = storeSelected.storeId;
          updateDemand.fnFetchEnabledFastOperation({
            payload: { storeId, enabledFastOperation: false, ttl },
            onSuccess: () => {
              updateDemand.fnFetch({
                payload: {
                  storeId,
                  ttl,
                  severity: { next: severity, current: SATURATION.regular },
                },
                onSuccess: () => {
                  counts.setNewCountByStore({ storeId });
                  setNewDemandTimer({ severity, ttl });
                  highDemandAnalytics.whenSubmit({
                    storeId,
                    partnerId,
                    timeOption: ttl,
                    demandOption: severity,
                    additionalCT: severityTimes[severity],
                    hasHoldLowCT: isEligibleForFastOperation,
                  });
                },
              });
            },
          });
        },
      }));
      return;
    }

    setPrimaryBtn(prev => ({
      ...prev,
      onClick: () => {
        const storeId = storeSelected.storeId;
        updateDemand.fnFetch({
          payload: {
            storeId,
            ttl,
            severity: { next: severity, current: storeSelected.saturated },
          },
          onSuccess: () => {
            if (
              severity === 'low' ||
              severity === 'high' ||
              severity === 'superHigh' ||
              (severity === 'regular' &&
                storeSelected?.isEligibleForFastOperation)
            ) {
              counts.setNewCountByStore({ storeId });
            }
            setNewDemandTimer({ severity, ttl });
            highDemandAnalytics.whenSubmit({
              storeId,
              partnerId,
              timeOption: ttl,
              demandOption: severity,
              additionalCT: severityTimes[severity],
              hasHoldLowCT: isEligibleForFastOperation,
            });
            if (
              !isEligibleForFastOperation ||
              (severity === 'regular' && isEligibleForFastOperation)
            )
              return;
            updateDemand.fnFetchEnabledFastOperation({
              payload: { storeId, enabledFastOperation: false, ttl },
            });
          },
        });
      },
    }));
  };

  useEffect(() => {
    goToFirstStep();
    saturationStores.fnFetch();
  }, []);

  useEffect(() => {
    if (stores.length === 1) goToSecondStep(stores[0], true);
  }, [stores]);

  useEffect(() => {
    if (updateDemand.isError) afterUpdateDemandError();
    if (updateDemand.isSuccess) afterUpdateDemandSuccess();
    if (updateDemand.isLoading) {
      setPrimaryBtn(prev => ({ ...prev, isLoading: true }));
    }
  }, [updateDemand.isError, updateDemand.isSuccess, updateDemand.isLoading]);

  const activeStores = stores.filter(
    ({ status }) => status === STORE_STATUS_INPUT_DTO.ACTIVE,
  );
  const onHoldStores = stores.filter(
    ({ status }) => status === STORE_STATUS_INPUT_DTO.ON_HOLD,
  );
  const inactiveStores = stores.filter(
    ({ status }) => status === STORE_STATUS_INPUT_DTO.INACTIVE,
  );
  const suspededStores = stores.filter(
    ({ status }) => status === STORE_STATUS_INPUT_DTO.SUSPENDED,
  );

  function handleNavigate() {
    navigate(Paths.STORE_MANAGEMENT);
  }

  return {
    texts,
    goBackBtn,
    primaryBtn,
    stores,
    severityTimes,
    storeSelected,
    processStep,
    goToDemandView,
    onSelectDemandState,
    isLoadingStoreList: saturationStores.isLoading,
    activeStores,
    onHoldStores,
    inactiveStores,
    suspededStores,
    handleNavigate,
    isActiveStore,
  };
}

interface Props {
  onCloseDialog: () => void;
  isSingleStore: boolean;
  partnerId: PartnerId;
}

interface OnSelectDemandStateParams {
  ttl: number; // time to live in minutes
  severity: Saturation;
}
