import { clsx } from 'clsx';
import { useState } from 'react';

import ToppingBox from '../topping-box/ToppingBox';

import useStyles from './MenuToppingsList.styles';

const getToppingBoxes = (
  classes,
  toppings,
  deactivatedItemId,
  setDeactivatedItemId,
) => {
  if (!toppings) {
    return null;
  }
  return toppings.map((topping, index) => {
    return (
      <ToppingBox
        key={`t-${index}-${topping.id}`}
        classes={classes}
        topping={topping}
        deactivatedItemId={deactivatedItemId}
        setDeactivatedItemId={setDeactivatedItemId}
      />
    );
  });
};

const getToppingCategoriesComponent = (
  toppingBoxClasses,
  classes,
  toppingCategories,
  deactivatedItemId,
  setDeactivatedItemId,
) => {
  if (!toppingCategories || toppingCategories.length === 0) {
    return null;
  }
  return toppingCategories.map((toppingCategory, index) => {
    const toppingBoxes = getToppingBoxes(
      toppingBoxClasses,
      toppingCategory.toppings,
      deactivatedItemId,
      setDeactivatedItemId,
    );
    return (
      <div
        className={classes.toppingCategoryContainer}
        key={`tc-${toppingCategory.id}-${index}`}
      >
        <div className={classes.toppingCategoryDescription}>
          <span>{toppingCategory.description}</span>
        </div>
        <div className={classes.toppingCategoryToppingsContainer}>
          {toppingBoxes}
        </div>
      </div>
    );
  });
};

function MenuToppingsList({ title, toppingCategories = [], classes = {} }) {
  const [deactivatedItemId, setDeactivatedItemId] = useState(null);
  const _classes = useStyles();

  const toppingBoxClasses = { root: clsx(classes?.toppingBoxRoot) };

  const toppingCategoriesComponentClasses = {
    toppingCategoryContainer: clsx(
      _classes.toppingCategoryContainer,
      classes?.toppingCategoryContainer,
    ),
    toppingCategoryDescription: clsx(
      _classes.toppingCategoryDescription,
      classes?.toppingCategoryDescription,
    ),
    toppingCategoryToppingsContainer: clsx(
      _classes.toppingCategoryToppingsContainer,
      classes?.toppingCategoryToppingsContainer,
    ),
  };
  const toppingCategoriesComponent = getToppingCategoriesComponent(
    toppingBoxClasses,
    toppingCategoriesComponentClasses,
    toppingCategories,
    deactivatedItemId,
    setDeactivatedItemId,
  );

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <div className={clsx(_classes.toppingsTitle, classes.toppingsTitle)}>
        <span>{title}</span>
      </div>
      {toppingCategoriesComponent}
    </div>
  );
}

export default MenuToppingsList;
