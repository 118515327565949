import { Chip } from '@material-ui/core';

import { useRtArrivesOrWaitingTime } from './useRtArrivesOrWaitingTime';

import makeClasses from './RtArrivesOrWaitingTime.styles';

export function RtArrivesOrWaitingTime(props: Props) {
  const { orderId } = props;

  const { theTime, rtStatus } = useRtArrivesOrWaitingTime({ orderId });

  const style = makeClasses({ rtStatus });

  if (isNaN(theTime)) return null;
  return (
    <Chip
      label={`${theTime} min`}
      classes={{ root: style.chip, label: style.label }}
    />
  );
}

interface Props {
  orderId: number;
}

// Origin logic to show -> rtWaitingSince using showCounter and zoneId
// previous at Revamped iconography and RT actions [RA-806]
// into DeliveryMethodLabel component
