import { useEffect } from 'react';

export function useDemandStatusOption({
  variant,
  saturated,
  onSelect,
  isChecked,
  setChecked,
  isEligibleForFastOperation,
}) {
  const isFastOperation = variant === 'fast';
  const showTooltip =
    (isChecked && !isFastOperation) ||
    (!isEligibleForFastOperation && isFastOperation);

  const handleClick = () => {
    if (isFastOperation) {
      setChecked(checked => !checked);
    }
    onSelect?.(variant);
  };

  useEffect(() => {
    if (!isChecked) {
      onSelect?.('deactivated');
    }
  }, [isChecked]);

  useEffect(() => {
    setChecked(saturated === 'fast');
  }, [saturated]);

  return {
    handleClick,
    isFastOperation,
    showTooltip,
  };
}
