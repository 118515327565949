import {
  CheckCircle as CheckCircleIcon,
  Info as InfoIcon,
} from '@material-ui/icons';

import useStyles from './styles';

export function DiagnosticRule({ label = '', value = false }: Props) {
  const componentClasses = useStyles();
  return (
    <div className={componentClasses.root}>
      {value ? (
        <CheckCircleIcon color='primary' />
      ) : (
        <InfoIcon className={componentClasses.infoIcon} />
      )}
      <span>{label}</span>
    </div>
  );
}

interface Props {
  label?: string;
  value?: boolean;
}
