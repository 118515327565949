import { makeStyles } from '@material-ui/core/styles';

import { neutral, purple } from '../../../../../shared/styles/colors';

import type { ButtonAppearance } from './MainButton';

export default makeStyles({
  AddRTBtn: ({ appearance }: Props) => ({
    padding: appearance === 'primary' ? '0.25rem 0.5rem' : '0.5rem',
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontWeight: appearance === 'primary' ? 'normal' : 600,
    lineHeight: appearance === 'primary' ? '1.25rem' : '1.125rem',
    borderRadius: '0.5rem',
    color: neutral['10'],
    backgroundColor: purple['50'],
    '&:hover': {
      color: neutral.white,
      backgroundColor: purple['70'],
    },
    '&[disabled]': {
      color: neutral['10'],
      backgroundColor: purple['20'],
    },
  }),
  AddRTBtnSpanContent: {
    columnGap: '0.25rem',
  },
  AddRTBtnEndIcon: {
    margin: 0,
  },
});

interface Props {
  appearance: ButtonAppearance;
}
