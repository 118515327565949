import { makeStyles } from '@material-ui/core';

import { FontWeight } from '../../common/typography';
import { CtaUiModes } from '../constants';

import { neutral } from '../../../shared/styles/colors';
import type { TpCtaUiModes } from '../types';

export default makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    padding: '1.5rem 1.5rem 1rem',
    borderRadius: '0.5rem',
    backgroundColor: neutral.white,
    boxShadow: '0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.1)',
  },
  cardHeader: { padding: 0 },
  cardContent: { padding: '1rem 0 1.5rem', color: neutral.D20 },
  storeName: (props: makeStylesProps) => ({
    fontSize: '1.125rem',
    lineHeight: 1.45,
    fontWeight: FontWeight.SemiBold,
    ...(props.growUp && {
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.5rem',
        lineHeight: 1.34,
      },
    }),
  }),
  divider: { margin: '0.5rem 0 1rem' },
  messages: { fontSize: '0.875rem', lineHeight: 1.7 },
  msgTextHide: { color: neutral.white },
  cardActions: { padding: '0' },
  cta: (props: makeStylesProps) => ({
    padding: '0.5rem 1rem',
    '& span p': {
      fontWeight: FontWeight.Bold,
    },
    ...(props.ctaUiMode &&
      {
        [CtaUiModes.Left]: { width: 'fitContent', margin: 'none' },
        [CtaUiModes.Right]: { width: 'fitContent', margin: '0 0 0 auto' },
        [CtaUiModes.Center]: { width: 'fitContent', margin: 'auto' },
        [CtaUiModes.FullWidth]: { width: '100%', margin: 'auto' },
        [CtaUiModes.CenterPlus]: { width: '60%', margin: 'auto' },
      }[props.ctaUiMode]),
  }),
}));

interface makeStylesProps {
  growUp?: boolean;
  ctaUiMode?: TpCtaUiModes;
}
