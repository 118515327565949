import { useSelector } from 'react-redux';

import { selectors as itemsDeactivationStockOutIssueDialogSelectors } from '../../../redux/items-deactivation-stock-out-issue-dialog';
import ordersSelector from '../../../redux/selectors/orders-selectors';
import selectors from '../selectors';

const useOpen = () =>
  useSelector(itemsDeactivationStockOutIssueDialogSelectors.selectOpen);

const useProducts = () =>
  useSelector(ordersSelector.selectProductsInOrderDetailDialog);

const useSelectedProducts = () =>
  useSelector(
    itemsDeactivationStockOutIssueDialogSelectors.selectSelectedProducts,
  );

const useSelectedToppings = () =>
  useSelector(
    itemsDeactivationStockOutIssueDialogSelectors.selectSelectedToppings,
  );

const useTitle = () => {
  return useSelector(selectors.selectTitle);
};

const hooks = {
  useOpen,
  useProducts,
  useSelectedProducts,
  useSelectedToppings,
  useTitle,
};

export default hooks;
