import React from 'react';
// Constant
import { neutral } from '../../../shared/styles/colors';

const CustomHelpCenterIcon = ({ height, stroke, width }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          stroke={neutral.D15}
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M20 14.742H5V4.195h15l-2.83 5.273L20 14.742zM5 3v18'
        />
      </g>
    </svg>
  );
};

CustomHelpCenterIcon.defaultProps = {
  height: '24',
  stroke: neutral.D20,
  width: '24',
};

export default CustomHelpCenterIcon;
