import { Box, Typography } from '@material-ui/core';
import { CountdownToEditStyle } from './CountdownToEdit.style';
import { COUNTDOWN_ORDER_IN_SEG } from '../../../../business/order-mgmt';
import { ErrorOutline } from '@material-ui/icons';
import { CountdownClock } from './components/CountdownClock';
import { useCountdownToEdit } from './hooks';

export function CountdownToEdit({
  title,
  description,
  cta,
}: {
  title?: string;
  description?: string;
  cta?: { label: string; action: any } | null;
}) {
  const styles = CountdownToEditStyle();

  const { createdAt } = useCountdownToEdit();

  return (
    <Box className={styles.container}>
      <Box
        flex={1}
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        padding='1rem'
        gridGap='0.25rem'
      >
        <Box
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          gridGap='0.5rem'
        >
          <ErrorOutline className={styles.icon} />
          <Box display='flex' flexDirection='column'>
            {title && (
              <Typography
                className={styles.primaryText}
                dangerouslySetInnerHTML={{
                  __html: title || '',
                }}
              />
            )}
            {description && (
              <Typography
                className={styles.secondaryText}
                dangerouslySetInnerHTML={{
                  __html: description || '',
                }}
              />
            )}
            {cta && (
              <Typography
                className={styles.cta}
                onClick={cta.action}
                dangerouslySetInnerHTML={{
                  __html: cta.label || '',
                }}
              />
            )}
          </Box>
        </Box>
        {createdAt && (
          <CountdownClock
            createdAt={createdAt}
            countDownTime={COUNTDOWN_ORDER_IN_SEG}
          />
        )}
      </Box>
    </Box>
  );
}
