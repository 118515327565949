// React
import React from 'react';
// Material UI
// Components
import CustomDialog from '../common/custom-dialog';
import OrderRejectionReasons from '../order-rejection-reasons';
// Styles
import useStyles from './OrderRejectionReasonsDialog.styles';

const OrderRejectionReasonsDialog = ({
  onBack,
  onClickReason,
  onClose,
  open,
  title,
}) => {
  const componentClasses = useStyles();
  return (
    <CustomDialog
      classes={{
        headerRoot: componentClasses.headerRoot,
        paper: componentClasses.paper,
      }}
      onClickBackButton={onBack}
      onClose={onClose}
      open={open}
      showBackButton
      showCloseButton
      title={title}
    >
      <div className={componentClasses.body}>
        <OrderRejectionReasons
          classes={{
            root: componentClasses.orderRejectionReasonsRoot,
            title: componentClasses.orderRejectionReasonsTitle,
          }}
          onClickReason={reason => onClickReason(reason)}
        />
      </div>
    </CustomDialog>
  );
};

export default OrderRejectionReasonsDialog;
