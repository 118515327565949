import { useSelector } from 'react-redux';

import { selectors as schedulePMFlowDialogSelectors } from '../../redux/schedule-PM-flow-dialog';

const useOpen = () => useSelector(schedulePMFlowDialogSelectors.selectOpen);

const useFormId = () => useSelector(schedulePMFlowDialogSelectors.selectFormId);

const useStoreSchedule = () =>
  useSelector(schedulePMFlowDialogSelectors.selectStoreSchedule);

const hooks = {
  useOpen,
  useFormId,
  useStoreSchedule,
};

export default hooks;
