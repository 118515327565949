import { Box, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

import RTGeoqueueIcon from '../../../../../assets/images/icons/RTGeoqueue.webp';
import { useGeoqueueRTSectionLang } from '../hooks';
import { geoqueuesRTSectionStyles } from './geoqueueRTSection.styles';

export function GeoqueueRTSection({ showDisclaimer, showStatusAction }: Props) {
  const styles = geoqueuesRTSectionStyles();
  const { title, actionText, disclaimerText } = useGeoqueueRTSectionLang();

  return (
    <Box display='flex' flexDirection='column' gridGap='1rem'>
      <Box className={styles.root}>
        <img
          alt='status RT icon'
          src={RTGeoqueueIcon}
          className={styles.icon}
        />
        <Typography className={styles.message}>
          <span className={styles.messageStrong}>{title}</span>
          &nbsp;
          {showStatusAction && <span>{actionText}</span>}
        </Typography>
      </Box>
      {showDisclaimer && (
        <Box
          className={styles.disclaimerWrapper}
          display='flex'
          gridGap='1rem'
          padding='1rem'
        >
          <InfoOutlined style={{ fontSize: '1.5rem' }} />
          <Typography
            component='span'
            className={styles.disclaimerText}
            dangerouslySetInnerHTML={{
              __html: disclaimerText,
            }}
          ></Typography>
        </Box>
      )}
    </Box>
  );
}

type Props = {
  showDisclaimer?: boolean;
  showStatusAction?: boolean;
};
