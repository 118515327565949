const chatSupportTitle = {
  id: 'chatSupport.header',
};
const typificationsTitle = {
  id: 'chatSupport.typificationTitle',
};

const textIds = {
  chatSupportTitle,
  typificationsTitle,
};

export default textIds;
