import { AddRTBehavior } from '../../domain';
import {
  actionValues,
  coverageValues,
  optionValues,
  propertiesNames,
  typeValues,
} from './Analytics.const';

export interface AnalyticProperties {
  partnerId: number;
  option: optionValues;
  action: actionValues;
  coverage?: coverageValues;
  orderId?: number;
  cargoType?: typeValues;
}

export function handleProperties(props: AnalyticProperties) {
  return {
    [propertiesNames.partnerId]: props.partnerId,
    [propertiesNames.option]: props.option,
    [propertiesNames.action]: props.action,
    [propertiesNames.coverage]: props?.coverage,
    [propertiesNames.orderId]: props?.orderId,
    [propertiesNames.cargoType]: props?.cargoType,
  };
}
export type cargoBehaviorType = AddRTBehavior | 'header_kanban';
export type CargoSources = { [key in cargoBehaviorType]: typeValues };
