import { useFormatMessage } from '../../../../intl/intl';

import Typography, { FontWeight, Variants } from '../../../common/typography';

import textIds from './textIds';
import useStyles from './styles';

export function EmptyInterval() {
  const componentClasses = useStyles();
  const noSchedule = useFormatMessage(textIds.noSchedule);
  return (
    <Typography
      classes={{
        root: componentClasses.emptyLabel,
      }}
      fontWeight={FontWeight.Regular}
      variant={Variants.Caption2}
    >
      {noSchedule}
    </Typography>
  );
}
