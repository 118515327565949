const RefuseReasons = {
  BADLY_CONFIGURED_PRODUCT: 'badly_configured_product',
  NO_LONGER_HANDLE_PRODUCT: 'no_longer_handle_product',
  PARTNER_WITHOUT_MOTOBOY: 'partner_without_motoboy',
  RAN_OUT_PRODUCTS: 'ran_out_products',
  SATURATED_STORE: 'saturated_store',
};

const ranOutProductsReasonDescription = 'Producto agotado temporalmente';

export const ranOutProductsReason = {
  description: ranOutProductsReasonDescription,
  id: 7,
  index: 2,
  isSelected: false,
  is_active: true,
  is_marketplace: false,
  refuse_reason_enum: RefuseReasons.RAN_OUT_PRODUCTS,
  shutdown: false,
};

export default RefuseReasons;
