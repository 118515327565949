import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

import { usePrintedChecklist } from './usePrintedChecklist';

import {
  printedChecklistClasses,
  printedChecklistStyles,
} from './printedChecklist.style';
import logo from '../../../../assets/images/logo.png';

export function PrintedChecklist(props: Props) {
  const { products, orderId, storeName, isActivatedComanda, userName } = props;

  const {
    checkProductsLabel,
    commentsLabel,
    defectRateLabel,
    forRTLabel,
  } = usePrintedChecklist({ userName, orderId });

  const classes = printedChecklistClasses();
  const styles = printedChecklistStyles({ isActivatedComanda });

  return (
    <section
      aria-label='printed checklist'
      className={classes.section}
      style={styles.section}
    >
      <header style={styles.header}>
        <img alt='logo' src={logo} style={styles.logo} />
        <h1 style={styles.title}>{checkProductsLabel}</h1>
        <p style={styles.orderId}>{`ID: ${orderId}`}</p>
        <p style={styles.storeName}>{storeName}</p>
      </header>

      <main>
        {products.map((product: any) => (
          <article key={`product-${product.id}`} style={styles.product}>
            <header
              aria-label='product description + checkbox + quantity'
              style={styles.productHeader}
            >
              <span>&#9634;</span>
              <p style={styles.productDescription}>
                {product.quantityText}&nbsp;&nbsp;{product.name}
              </p>
            </header>
            {product?.has_defect_rate_report && (
              <aside
                aria-label='has defect rate report'
                style={styles.productHasDefect}
              >
                <ErrorRoundedIcon style={styles.productHasDefectIcon} />
                <p style={styles.productHasDefectText}>{defectRateLabel}</p>
              </aside>
            )}
            {product.toppings.length > 0 && (
              <ul aria-label='description of toppings' style={styles.toppings}>
                {product.toppings.map(
                  ({ price, units, description }, idx: number) => (
                    <li key={`checklist-topping-${idx}`} style={styles.topping}>
                      {price > 0 ? '$' : <>&nbsp;&nbsp;</>}&#9634;
                      {units > 1 && <>&nbsp;&nbsp;{units}x</>}
                      &nbsp;{description}
                    </li>
                  ),
                )}
              </ul>
            )}
            {Boolean(product.comments) && (
              <footer aria-label='comments by product' style={styles.comments}>
                <span style={styles.commentsCheckbox}>&#9634;&nbsp;</span>
                <div>
                  <p style={styles.commentsLabel}>{commentsLabel}:</p>
                  <p style={styles.commentsText}>{product.comments}</p>
                </div>
              </footer>
            )}
          </article>
        ))}
      </main>

      <footer style={styles.forRT}>
        <h2 style={styles.forRTTitle}>{forRTLabel.title}</h2>
        <ul style={styles.forRTList}>
          <li style={styles.forRTItem}>
            <span>&#9634;</span>
            {forRTLabel.item1}
          </li>
          <li style={styles.forRTItem}>
            <span>&#9634;</span>
            <span dangerouslySetInnerHTML={{ __html: forRTLabel.item2 }} />
          </li>
        </ul>
      </footer>
    </section>
  );
}

interface Props {
  products: any;
  orderId: number;
  storeName: string;
  isActivatedComanda: boolean;
  userName: string;
}
