const PaymentMethodType = {
  CASH: 'cash',
  PAY_PAL: 'paypal',
  CREDIT_CARD: 'cc',
  DEBIT_CARD: 'dc',
  SODEXO: 'sodexo',
  TICKET_R: 'ticket_r',
  VALE_R: 'vale_r',
  ALELO: 'alelo',
  ELO: 'elo',
  RAPPI_PAY: 'rappi_pay',
  RAPPI_CREDIT: 'rappi_credit',
  GOOGLE_PAY: 'google_pay',
};

export default PaymentMethodType;
