// External libs
import { createSelector } from 'reselect'
// Interfaces
import {
  State,
  StoresDiagnosticsCache,
  StoreDiagnostic,
  DiagnosticRule,
} from '../interfaces'
import { State as GlobalState } from '../../state'

const selectSlice = (state: GlobalState) => state.storeDiagnosticsDialog

const selectOpen = createSelector<GlobalState, State, boolean>(
  selectSlice,
  reducer => reducer.open,
)

const selectSelectedStoreId = createSelector<GlobalState, State, number>(
  selectSlice,
  reducer => reducer.selectedStoreId,
)

const selectStoresDiagnosticsCache = createSelector<
  GlobalState,
  State,
  StoresDiagnosticsCache
>(selectSlice, reducer => reducer.storesDiagnosticsCache)

const selectSelectedStoreDiagnostic = createSelector<
  GlobalState,
  number,
  StoresDiagnosticsCache,
  StoreDiagnostic | undefined
>(
  selectSelectedStoreId,
  selectStoresDiagnosticsCache,
  (selectedStoreId, storesDiagnosticsCache) =>
    storesDiagnosticsCache[selectedStoreId],
)

const selectSelectedStoreDiagnosticAlertMessage = createSelector<
  GlobalState,
  StoreDiagnostic | undefined,
  string
>(
  selectSelectedStoreDiagnostic,
  storeDiagnositc => storeDiagnositc?.alertMessage || '',
)

const selectSelectedStoreDiagnosticRules = createSelector<
  GlobalState,
  StoreDiagnostic | undefined,
  DiagnosticRule[]
>(
  selectSelectedStoreDiagnostic,
  storeDiagnositc => storeDiagnositc?.diagnostics || [],
)

const selectors = {
  selectOpen,
  selectSelectedStoreId,
  selectStoresDiagnosticsCache,
  selectSelectedStoreDiagnostic,
  selectSelectedStoreDiagnosticAlertMessage,
  selectSelectedStoreDiagnosticRules,
}

export default selectors
