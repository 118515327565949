// Material UI
import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateAreas: `"inputContainer buttonContainer"`,
    columnGap: 23,
    [theme.breakpoints.up('md')]: {
      columnGap: 23,
      gridTemplateColumns: 'min-content max-content',
    },
  },
  inputContainer: {
    gridArea: 'inputContainer',
    display: 'grid',
    gridTemplateAreas: `"inputStart divider inputEnd"`,
    columnGap: 6,
  },
  buttonContainer: {
    display: 'grid',
    gridArea: 'buttonContainer',
    alignSelf: 'center',
    gridTemplateAreas: `"delete check"`,
    justifySelf: 'right',
    columnGap: 8,
    gridTemplateColumns: 'min-content min-content',
  },
  divider: {
    gridArea: 'divider',
    alignSelf: 'center',
  },
  inputReadOnlyStart: {
    gridArea: 'inputStart',
  },
  inputReadOnlyEnd: {
    gridArea: 'inputEnd',
  },
  checkButton: {
    gridArea: 'check',
    width: 20,
    height: 20,
  },
  deleteButton: {
    gridArea: 'delete',
    width: 20,
    height: 20,
  },
  icon: {
    width: 20,
    height: 20,
  },
}))
