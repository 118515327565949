import { makeStyles } from '@material-ui/core';

import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: '100svh',
  },
  mainContainer: {
    minHeight: 'inherit',
    display: 'grid',
    gridTemplateColumns: 'auto',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  loginFormContainer: {
    maxWidth: '100%',
    background: neutral.white,
  },
}));
