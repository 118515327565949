import { clsx } from 'clsx';
import { IconButton } from '@material-ui/core';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';

import useStyles from './CustomHeadphonesButton.styles';

export function CustomHeadphonesButton({ onClick, classes = {} }) {
  const _classes = useStyles();
  return (
    <IconButton
      className={clsx(_classes.button, classes?.button)}
      aria-label='Back'
      onClick={onClick}
    >
      <HeadsetMicIcon className={clsx(_classes.icon, classes?.icon)} />
    </IconButton>
  );
}
