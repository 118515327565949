import CustomButton from '../common/buttons/custom-button';

import { useShowPhoneButton } from './useShowPhoneButton';

import useStyles from './ShowPhoneButton.styles';

export function ShowPhoneButton() {
  const { lang, handleOnClick } = useShowPhoneButton();
  const _classes = useStyles();
  return (
    <CustomButton
      classes={{ root: _classes.root, submitButton: _classes.submitButton }}
      fullWidth={false}
      color='primary'
      text={lang.callUser}
      onClick={handleOnClick}
      variant='text'
    />
  );
}
