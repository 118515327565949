import { useFormatMessage } from '../../../../../intl/intl';

export const useOrderMgmtAcceptRemoveChangesDialogLang = () => {
  const messageCategory = 'orderManagement';
  const messageSubcategory = 'acceptRemoveChangesDialog';

  const acceptRemoveChangesTitleText = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.title`,
  });

  const acceptRemoveChangesDescriptionText = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.description`,
  });

  const acceptRemoveChangesCancelButtonText = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.cancelButton`,
  });

  const acceptRemoveChangesConfirmButtonText = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.confirmButton`,
  });

  return {
    acceptRemoveChangesTitleText,
    acceptRemoveChangesDescriptionText,
    acceptRemoveChangesCancelButtonText,
    acceptRemoveChangesConfirmButtonText,
  };
};
