import MainLayout from '../main-layout';
import NewSpecialDayDialog from '../new-special-day-dialog';
import CustomBlock from '../../components/common/custom-block';
import CustomAlert from '../../components/common/custom-alert';
import DeleteSpecialDayDialog from '../delete-special-day-dialog';
import HolidayStoreScheduleTable from '../holiday-store-schedule-table';
import RegularStoreScheduleTable from '../regular-store-schedule-table';
import SpecialDayStoreScheduleTable from '../special-day-store-schedule-table';
import StoresSchedulesAdvancedFilterToolbar from '../stores-schedules-advanced-filter-toolbar';

import { useStoresSchedulesPage } from './hooks';

import makeClasses from './StoresSchedulesPage.styles';

export function StoresSchedulesPage({ classes = {} }) {
  const { lang } = useStoresSchedulesPage();

  const _classes = makeClasses();

  return (
    <MainLayout title={lang.layout.title}>
      <main className={classes.root}>
        <StoresSchedulesAdvancedFilterToolbar />
        <div className={_classes.customBlocksContainer}>
          <CustomBlock title={lang.page.regular.title}>
            <p className={_classes.description}>
              {lang.page.regular.description}
            </p>
            <CustomAlert
              classes={{ root: classes.customAlertRoot }}
              text={lang.page.regular.info}
            />
            <RegularStoreScheduleTable
              classes={{
                root: _classes.regularStoreScheduleTableRoot,
              }}
            />
          </CustomBlock>
        </div>
        <div className={_classes.customBlocksContainer}>
          <CustomBlock title={lang.page.holiday.title}>
            <p className={_classes.description}>
              {lang.page.holiday.description}
            </p>
            <CustomAlert
              classes={{ root: classes.customAlertRoot }}
              text={lang.page.holiday.info}
            />
            <HolidayStoreScheduleTable
              classes={{
                root: _classes.regularStoreScheduleTableRoot,
              }}
            />
          </CustomBlock>
        </div>
        <div className={_classes.customBlocksContainer}>
          <CustomBlock title={lang.page.specialDay.title}>
            <p className={_classes.description}>
              {lang.page.specialDay.description}
            </p>
            <CustomAlert
              classes={{ root: classes.customAlertRoot }}
              text={lang.page.specialDay.info}
            />
            <SpecialDayStoreScheduleTable
              classes={{
                root: _classes.regularStoreScheduleTableRoot,
              }}
            />
          </CustomBlock>
        </div>
        <NewSpecialDayDialog />
        <DeleteSpecialDayDialog />
      </main>
    </MainLayout>
  );
}
