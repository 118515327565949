import { makeStyles } from '@material-ui/core';

import Colors from '../../constants/Colors';
import { neutral, purple, red, teal, yellow } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  paper: {
    [theme.breakpoints.up('md')]: {
      width: '29.25rem',
      height: '29rem',
    },
    '& header': {
      padding: '0 0.5rem',
    },
  },
  root: {
    background: neutral.white,
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  header: {
    border: 'none',
  },
  infoUserDisclaimer: {
    backgroundColor: neutral['20'],
  },
  infoUserTitle: {
    fontWeigth: '0.875rem',
  },
  infoUserText: {
    color: yellow['80'],
    fontSize: '0.875rem',
  },
  editOptionsContainer: (props: any) => ({
    cursor: props?.isDisabled ? 'not-allowed' : 'pointer',
  }),
  editOptions: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: '0.5rem',
    height: '100%',
  },
  editTextOption: (props: any) => ({
    fontSize: '0.875rem',
    color: props?.isDisabled ? neutral['50'] : neutral['90'],
  }),
  editDescriptionOption: (props: any) => ({
    fontSize: '0.75rem',
    color: props?.isDisabled ? neutral['50'] : neutral['90'],
  }),
  editRadioOption: {
    margin: 0,
    maxWidth: '2.625rem',
  },
  editLabelOption: {
    display: 'none',
  },
  deactivatedItemsDisclaimer: {
    fontSize: '0.875rem',
    color: yellow['80'],
  },
  deactivatedItemsContainer: {
    borderLeft: `1px solid ${yellow['70']}`,
    height: '11.875rem',
    overflow: 'auto',
  },
  deactivatedItem: {
    fontSize: '0.875rem',
  },
  deactivatedProduct: {
    border: `1px solid ${neutral['20']}`,
  },
  toppingCategory: {
    fontSize: '0.625rem',
    color: neutral['50'],
  },
  itemCheckbox: {
    padding: 0,
  },
  chatDisclaimerContainer: {
    background: `linear-gradient(180deg, ${Colors.CYAN} 0%, ${Colors.CYAN_SHADE} 100%)`,
    borderRadius: '0.5rem',
    minHeight: '5rem',
    color: neutral.white,
    textShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
    zIndex: 1,
  },
  chatDisclaimer: {
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  chatDisclaimerIconContainer: {
    borderRadius: '50%',
    backgroundColor: teal['40'],
    margin: 0,
    position: 'relative',
  },
  chatDisclaimerIcon: {
    cursor: 'pointer',
  },
  corridorName: {
    display: 'none',
  },
  totalManagement: {
    borderTop: `1px solid ${neutral.D11}`,
    borderBottom: `1px solid ${neutral.D11}`,
  },
  totalLabel: {
    textTransform: 'uppercase',
    alignSelf: 'flex-start',
    width: '100%',
  },
  customButtonSecondaryRoot: {
    textDecoration: 'underline',
  },
  resumeText: {
    fontWeight: 600,
    fontSize: 18,
    color: neutral['80'],
  },
  quantity: {
    color: neutral['90'],
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  name: {
    fontSize: '1rem',
    paddingTop: '0.5rem',
  },
  price: {
    color: neutral['90'],
    fontSize: '1rem',
    fontWeight: 600,
  },
  orderMgmtResumeItemSuggestions: {
    backgroundColor: yellow['10'],
    borderLeft: `3px solid ${yellow['40']}`,
  },
  userDisclaimer: {
    fontWeight: 600,
    fontSize: '1rem',
    marginBottom: '1rem',
    color: yellow['80'],
  },
  item: {
    color: neutral['70'],
  },
  itemUnits: {
    fontSize: '0.875rem',
    fontWeight: 600,
  },
  itemName: {
    fontSize: '0.875rem',
    fontWeight: 400,
    color: neutral['70'],
  },
  itemPrice: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  insteadOf: {
    fontWeight: 600,
    fontSize: '1rem',
    margin: '1rem 0',
    color: yellow['80'],
  },
  productDisclaimer: {
    color: neutral['80'],
  },
  disclaimerText: {
    fontWeight: 400,
    fontSize: '0.875rem',
  },
  undoChangesContainer: {
    cursor: 'pointer',
    color: red['70'],
  },
  undoChanges: {
    fontWeight: 600,
    fontSize: '0.75rem',
  },
  backArrow: {
    cursor: 'pointer',
    fill: neutral['90'],
  },
  chipOrderDisclaimer: ({ type }: any) => ({
    backgroundColor: ChipTypes[type]?.background || Colors.WARNING_CHIP_COLOR,
    borderRadius: '0.5rem',
  }),
  chipOrderText: {
    fontSize: '0.75rem',
    color: yellow['80'],
  },
  chipOrderIcon: {
    fontSize: '1rem',
    color: yellow['80'],
  },
  resumeItemContainer: {
    border: `1px solid ${neutral.D11}`,
    borderRadius: '0.5rem',
    padding: '0 1.25rem 1.25rem',
  },
  resumeContainer: {
    height: 'calc(100vh - 26.5rem)',
    overflow: 'auto',
  },
  resumeDisclaimerIcon: {
    color: yellow['60'],
  },
  resumeDisclaimerText: {
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  disclaimerContent: {
    background: Colors.WARNING_LIGHT_4,
    borderLeft: `3px solid ${yellow['40']}`,
  },
  disclaimerIcon: {
    alignSelf: 'flex-start',
    margin: '0.25rem 0.5rem 0',
  },
  disclaimerTitle: {
    color: neutral['80'],
    fontSize: '0.875rem',
    fontWeight: 600,
    marginLeft: '0.5rem',
    paddingBottom: '0.5rem',
    borderBottom: `1px solid ${neutral['20']}`,
  },
  disclaimerDescription: {
    fontSize: '0.75rem',
    padding: '0',
  },
  tooltip: {
    fontSize: '0.75rem',
    backgroundColor: neutral.black,
    padding: '0.5rem',
    fontWeight: 700,
    width: '14.6875rem',
  },
}));

const ChipTypes = {
  ['warning']: {
    background: yellow['20'],
  },
  ['info']: {
    background: purple['10'],
  },
};
