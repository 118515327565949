// External libs
import { handleActions } from 'redux-actions';
import PartnersActions from '../actions/partners-actions';
import { partnerStateType } from '../../constants/PartnerStateType';

export const initialState = {
  isFirstLogin: false,
  isClosedPartnerStateDialogOpened: false,
  isOpenPartnerStateDialogOpened: false,
  isPendingTurnPartnerOnBySchedule: false,
  profile: {
    partnerDeactivationReason: null,
    isInBlacklistHandShake: true,
    isPartnerActivationReminderDialogOpened: false,
    state: false,
    stores: [],
    saturated: '',
    partnerState: partnerStateType.IDLE,
  },
  isChatV2Available: false,
  isAutoReadyForPickupMarketplaceAvailable: false,
  notifications: [],
  suspendedStores: [],
  notificationsUnseen: 0,
  simpleStoreStatusNotification: [],
  salesSummary: [],
  typifications: [],
  suspendedStoresReasons: [{ reason: '', dateFrom: '', id: 0 }],
  scheduledPMAutomationForm: null,
  storeStatusDetail: [],
  isStoreStatusDetailLoading: false,
  deactivatedProducts: null, // any[]
  isDeactivatedProductsLoading: false,
  isSwitchStoreStatusLoading: false,
  isPartnerStateLoading: false,
  hasBeenUpdatedStoreStatusNotification: false,
  isKanbanUserAppChatEnabled: false,
  isFastOperation: false
};

export default handleActions(
  {
    [PartnersActions?.setPartnerStateAction]: (
      state,
      { payload: { partnerState } },
    ) => setPartnerState(state, partnerState),
    [PartnersActions?.cleanPartnerDeactivationReasonAction]: state =>
      setPartnerDeactivationReasonAction(null, state),
    [PartnersActions?.closeClosedPartnerStateDialogAction]: state =>
      toggleClosedPartnerStateDialogAction(state, false),
    [PartnersActions?.closeDeactivatePartnerDialogAction]: state =>
      toggleDeactivatePartnerDialog(false, state),
    [PartnersActions?.closeOpenPartnerStateDialogAction]: state =>
      toggleOpenPartnerStateDialogAction(state, false),
    [PartnersActions?.closePartnerActivationReminderDialogAction]: state =>
      togglePartnerActivationReminderDialog(false, state),
    [PartnersActions?.getIfPartnerIsInBlacklistHandShakeAction]: (
      state,
      { payload: { isInBlacklistHandShake } },
    ) =>
      getIfPartnerIsInBlacklistHandShakeAction(isInBlacklistHandShake, state),
    [PartnersActions?.getPartnerStateAction]: (
      state,
      { payload: { state: partnerState } },
    ) => getPartnerState(state, partnerState),
    [PartnersActions?.getNotificationsAction]: (
      state,
      { payload: { notifications } },
    ) => getNotifications(state, notifications),
    [PartnersActions?.getSalesSummaryAction]: (
      state,
      { payload: { summary } },
    ) => getSalesSummary(state, summary),
    [PartnersActions?.getNotificationsUnseenAction]: (
      state,
      { payload: { unseen } },
    ) => getNotificationsUnseen(state, unseen),
    [PartnersActions?.getPartnerTypificationsAction]: (
      state,
      { payload: { typifications } },
    ) => getPartnerTypifications(typifications, state),
    [PartnersActions?.getProfileAction]: (state, { payload: { profile } }) =>
      getProfile(profile, state),
    [PartnersActions?.setIsNotPendingTurnPartnerOnByScheduleAction]: state =>
      toggleIsPendingTurnPartnerOnBySchedule(false, state),
    [PartnersActions?.setIsPendingTurnPartnerOnByScheduleAction]: state =>
      toggleIsPendingTurnPartnerOnBySchedule(true, state),
    [PartnersActions?.openClosedPartnerStateDialogAction]: state =>
      toggleClosedPartnerStateDialogAction(state, true),
    [PartnersActions?.openDeactivatePartnerDialogAction]: state =>
      toggleDeactivatePartnerDialog(true, state),
    [PartnersActions?.openOpenPartnerStateDialogAction]: state =>
      toggleOpenPartnerStateDialogAction(state, true),
    [PartnersActions?.openPartnerActivationReminderDialogAction]: state =>
      togglePartnerActivationReminderDialog(true, state),
    [PartnersActions?.getSuspendedStoresAction]: (
      state,
      { payload: { storesReasons } },
    ) => getSuspendenStoresReasons(storesReasons, state),
    [PartnersActions?.setFirstLoginActions]: (
      state,
      { payload: { isFirstLogin } },
    ) => setFirstLogin(isFirstLogin, state),
    [PartnersActions?.getStoreStatusDetailAction]: (
      state,
      {
        payload: {
          storeStatusDetail: { stores },
        },
      },
    ) => getStoreStatusDetail(stores, state),
    [PartnersActions?.getDeactivatedProductsAction]: (
      state,
      { payload: { deactivatedProducts } },
    ) => getDeactivatedProducts(deactivatedProducts, state),
    [PartnersActions?.setDeactivatedProductsLoadingAction]: (
      state,
      { payload: { isLoading } },
    ) => setDeactivatedProductsLoading(state, isLoading),
    [PartnersActions?.setSimpleStoreStatusNotificationAction]: (
      state,
      { payload: { list } },
    ) => setSimpleStoreStatusNotification(list, state),
    [PartnersActions?.setStoreStatusDetailLoadingAction]: (
      state,
      { payload: { isLoading } },
    ) => setStoreStatusDetailLoading(state, isLoading),
    [PartnersActions?.setSwitchStoreStatusLoadingAction]: (
      state,
      { payload: { isLoading } },
    ) => setSwitchStoreStatusLoading(state, isLoading),
    [PartnersActions?.setPartnerStateLoadingAction]: (
      state,
      { payload: { isLoading } },
    ) => setPartnerStateLoading(state, isLoading),
    [PartnersActions?.setHasBeenUpdatedStoreStatusNotificationAction]: (
      state,
      { payload: { updated } },
    ) => setHasBeenUpdatedStoreStatusNotification(state, updated),
  },
  initialState,
);

const getIfPartnerIsInBlacklistHandShakeAction = (
  isInBlacklistHandShake,
  state,
) => {
  return {
    ...state,
    profile: {
      ...state.profile,
      isInBlacklistHandShake,
    },
  };
};

const getNotifications = (state, notifications) => {
  return {
    ...state,
    notifications,
  };
};

const getSalesSummary = (state, salesSummary) => {
  return {
    ...state,
    salesSummary: salesSummary,
  };
};

const getNotificationsUnseen = (state, unseen) => {
  return {
    ...state,
    notificationsUnseen: unseen,
  };
};

const getPartnerState = (state, partnerState) => {
  const notificationsUnseen =
    (partnerState &&
      partnerState.unseen_has_action &&
      partnerState.unseen_has_action.id) ||
    0;

  const shallowClonedProfile = {
    ...state.profile,
    saturated: partnerState?.saturation_state ?? '',
    partnerState: partnerState.partner_state,
  };

  let isChatV2Available =
    process.env.REACT_APP_ENVIRONMENT === 'staging'
      ? true
      : partnerState.is_chat_v2_available;

  const isKanbanUserAppChatEnabled = partnerState?.kanban_user_app_chat_enabled;

  const suspendedStores =
    partnerState?.suspended_stores?.map(s => ({
      storeId: s.store_id,
      createdAt: s.created_at,
      suspendedReason: s.suspended_reason,
      suspended: s.suspended,
    })) ?? [];

  return {
    ...state,
    notificationsUnseen,
    saturated: partnerState?.saturation_state ?? '',
    isChatV2Available,
    isAutoReadyForPickupMarketplaceAvailable:
      partnerState.is_auto_ready_for_pickup_marketplace_available,
    profile: shallowClonedProfile,
    scheduledPMAutomationForm: partnerState.scheduled_pm_automation_form,
    isKanbanUserAppChatEnabled,
    suspendedStores,
    isFastOperation: partnerState?.is_fast_operation
  };
};

const getPartnerTypifications = (typifications, state) => {
  return {
    ...state,
    typifications,
  };
};

const getProfile = (profile, state) => {
  const cookingTimeStep =
    (profile && profile.cooking_time_step) ||
    +process.env.REACT_APP_INTERVAL_CT;

  const shallowClonedProfile = {
    ...state.profile,
    ...profile,
    cookingTimeStep,
  };
  return {
    ...state,
    profile: shallowClonedProfile,
  };
};

const setPartnerDeactivationReasonAction = (
  partnerDeactivationReason,
  state,
) => {
  const shallowClonedProfile = {
    ...state.profile,
    partnerDeactivationReason,
  };

  return {
    ...state,
    profile: shallowClonedProfile,
  };
};

const toggleClosedPartnerStateDialogAction = (
  state,
  isClosedPartnerStateDialogOpened,
) => {
  return {
    ...state,
    isClosedPartnerStateDialogOpened,
  };
};

const toggleOpenPartnerStateDialogAction = (
  state,
  isOpenPartnerStateDialogOpened,
) => {
  return {
    ...state,
    isOpenPartnerStateDialogOpened,
  };
};

const toggleDeactivatePartnerDialog = (isDeactivateDialogOpened, state) => {
  const shallowClonedProfile = {
    ...state.profile,
    isDeactivateDialogOpened,
  };

  return {
    ...state,
    profile: shallowClonedProfile,
  };
};

const toggleIsPendingTurnPartnerOnBySchedule = (
  isPendingTurnPartnerOnBySchedule,
  state,
) => {
  return {
    ...state,
    isPendingTurnPartnerOnBySchedule,
  };
};

const togglePartnerActivationReminderDialog = (
  isPartnerActivationReminderDialogOpened,
  state,
) => {
  // If the profile state is true, the dialog must be closed always.
  const shallowClonedProfile = {
    ...state.profile,
    isPartnerActivationReminderDialogOpened: isPartnerActivationReminderDialogOpened,
  };
  return {
    ...state,
    profile: shallowClonedProfile,
  };
};

const setPartnerState = (state, partnerState) => {
  const shallowCloneProfile = { ...state.profile, partnerState };
  return { ...state, profile: shallowCloneProfile };
};

const getSuspendenStoresReasons = (suspendedStoresReasons, state) => {
  return {
    ...state,
    suspendedStoresReasons,
  };
};

const setFirstLogin = (isFirstLogin, state) => {
  return { ...state, isFirstLogin };
};

const getStoreStatusDetail = (storeStatusDetail, state) => {
  return { ...state, storeStatusDetail };
};

const getDeactivatedProducts = (deactivatedProducts, state) => {
  return { ...state, deactivatedProducts };
};

const setSimpleStoreStatusNotification = (list, state) => {
  return { ...state, simpleStoreStatusNotification: list };
};

const setStoreStatusDetailLoading = (state, isLoading) => {
  return { ...state, isStoreStatusDetailLoading: isLoading };
};

const setDeactivatedProductsLoading = (state, isLoading) => {
  return { ...state, isDeactivatedProductsLoading: isLoading };
};

const setSwitchStoreStatusLoading = (state, isLoading) => {
  return { ...state, isSwitchStoreStatusLoading: isLoading };
};

const setPartnerStateLoading = (state, isLoading) => {
  return { ...state, isPartnerStateLoading: isLoading };
};

const setHasBeenUpdatedStoreStatusNotification = (state, updated) => {
  return { ...state, hasBeenUpdatedStoreStatusNotification: updated };
};
