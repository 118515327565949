// React
import { connect } from 'react-redux';
// Actions
import OrderActions from '../../redux/actions/orders-actions';
// Components
import OrderSearchTextField from './OrderSearchTextField';

const mapStateToProps = ({ ordersReducer: { searchFieldText: value } }) => {
  return {
    value,
  };
};

const mapDispatchToProps = {
  onChange: OrderActions.setSearchFieldText,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderSearchTextField);
