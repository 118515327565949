// External libs
import { createAction } from '@reduxjs/toolkit';
import { get } from 'lodash';
import PMAutomationService from '../../../services/PM-automation-service';
import AppActions from '../../actions/app-actions/app.actions';
import appStore from '../../stores/app.store';

const closeAction = createAction('endPMFlowDialog/close');

const clearAction = createAction('endPMFlowDialog/clear');

const maximizeAction = createAction('endPMFlowDialog/maximize');

const openAction = createAction<{
  formId: number;
  storeId: number;
}>('endPMFlowDialog/open');

const clear = () => (dispatch: any) => {
  dispatch(clearAction());
};

const close = () => (dispatch: any) => {
  dispatch(closeAction());
};

const maximize = () => (dispatch: any) => dispatch(maximizeAction());

const open = (formId: number) => async (dispatch: any) => {
  try {
    const schedulePMAForm = appStore.getScheduledPMAutomationForm();
    const storeId = get(schedulePMAForm, 'store_id', null);
    dispatch(openAction({ formId, storeId }));
  } catch (ex) {
    dispatch(AppActions.handleCatch(ex, false));
  }
};

const sendCompletePMFlow = (formId: number) => async (dispatch: any) => {
  try {
    await PMAutomationService.sendPMAutomationStoreCompleteClose(formId);
  } catch (ex) {
    dispatch(AppActions.handleCatch(ex, false));
  }
};
const sendSkipCloseForm = (formId: number) => async (dispatch: any) => {
  try {
    await PMAutomationService.sendPMAutomationStoreClose(formId);
  } catch (ex) {
    dispatch(AppActions.handleCatch(ex, false));
  }
};
const actions = {
  clear,
  clearAction,
  close,
  closeAction,
  maximize,
  maximizeAction,
  open,
  openAction,
  sendCompletePMFlow,
  sendSkipCloseForm,
};

export default actions;
