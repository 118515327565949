// React
import { connect } from 'react-redux'
import { get } from 'lodash'
// External lib
import { orderBy } from 'lodash'
// Actions
import OrdersActions from '../../redux/actions/orders-actions'
// Components
import SupportChat from './SupportChat'
import OrderActions from '../../redux/actions/orders-actions'

const mapStateToProps = ({
  orderDetailDialogReducer: { orderId },
  ordersReducer: { orders },
  partnerReducer,
}) => {
  const order = orders.find(order => order.id === +orderId)
  const orderDetail =
    order && order.detail
      ? order.detail
      : {
          id: +orderId,
          products: {},
        }

  const supporNotification =
    get(orderDetail, 'supportNotificationsMessages', []) || []
  let messages = supporNotification.concat(orderDetail.supportChatMessages)
  messages = orderBy(messages, ['timestamp'], ['asc'])

  return {
    messages,
    orderId: get(order, 'id', 0),
    partnerId: get(partnerReducer, 'profile.id', 0),
    partnerName: `${partnerReducer.profile.first_name} ${partnerReducer.profile.last_name}`,
  }
}
const mapDispatchToProps = {
  onEnterKeyPress: OrdersActions.sendSupportChatMessage,
  onConfirmCalification: OrderActions.sendSupportCalification,
}

export default connect(mapStateToProps, mapDispatchToProps)(SupportChat)
