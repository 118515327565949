import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useOrderMgmtLang } from '../../../../hooks';
import { OrderMgmtTopping } from '../../../../../../business/order-mgmt';
import OrderMgmgCardStyles from '../../../../components/order-mgmt-card/OrderMgmtCard.style';
import { Suggestions } from './suggestions';
import { Disclaimer } from './Disclaimer';

export const ToppingCard = ({ topping, onDelete }: Props) => {
  const componentClasses = OrderMgmgCardStyles();
  const { soldOutToppingText } = useOrderMgmtLang();
  const hasSuggestions = !!topping.suggestions?.length;

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      <Typography classes={{ root: componentClasses.toppingCategory }}>
        {topping.categoryName}
      </Typography>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography classes={{ root: componentClasses.toppingDescription }}>
          {topping.units > 1 && <>{topping.units}x&ensp;</>}
          {topping.name}
        </Typography>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          width='100%'
        >
          {hasSuggestions && (
            <Disclaimer
              enabledToDelete={hasSuggestions && topping.isRemovable}
              soldoutText={soldOutToppingText}
              classDelete={componentClasses.deleteItem}
              item={topping}
              onDelete={onDelete}
            />
          )}
        </Box>
      </Box>
      {hasSuggestions && (
        <Suggestions suggestions={topping.suggestions} units={topping.units} />
      )}
    </Box>
  );
};

interface Props {
  topping: OrderMgmtTopping;
  onDelete: (orderId, toppingId) => void;
}
