// React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Actions
import AppActions from './../../redux/actions/app-actions'
import OrderActions from '../../redux/actions/orders-actions'
// Components
import OrdersListFilterDrawer from './OrdersListFilterDrawer'

const mapStateToProps = (
  { ordersReducer, partnerReducer },
  { intl: { formatMessage } },
) => {
  const title = formatMessage({ id: 'globals.filterStores' })
  return {
    currentSelectedStoresIds: ordersReducer.filter.selectedStoresIds,
    open: ordersReducer.filter.open,
    stores: partnerReducer.profile.stores,
    title,
  }
}
const mapDispatchToProps = {
  applyOrdersListFilter: OrderActions.applyOrdersListFilter,
  onClearFilter: OrderActions.clearOrdersListFilter,
  onClose: OrderActions.closeOrdersListFilterDrawer,
  openSnackBarError: AppActions.openSnackBarError,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(OrdersListFilterDrawer),
)
