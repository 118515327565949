// React
import React from 'react';
// Components
import ProductBoxesList from '../product-boxes-list';

const MenuSearchProductList = ({ classes, products }) => {
  return <ProductBoxesList products={products} />;
};

MenuSearchProductList.defaultProps = {
  classes: {},
  title: '',
};

export default MenuSearchProductList;
