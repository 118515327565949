// React
import React, { useState } from 'react'
// Material UI
import CustomSwipeableDialog from '../common/custom-swipeable-dialog'
// Components
import CustomDatePicker from '../common/custom-date-picker'
import CustomTextField from '../common/custom-text-field'
import { analitycsEvent, GACategories, GAActions } from './../../utils/ga-utils'
// Styles
import useStyles from './NewSpecialDayDialog.styles'

const NewSpecialDayDialog = ({
  onConfirm,
  cancel,
  confirm,
  defaultDate,
  maxDate,
  minDate,
  minLengthErrorHelperTextForName,
  specialDayDatePickerLabel,
  specialDayNameLabel,
  placeHolder,
  selectedStoreId,
  open,
  onClose,
  titleSpecialDay,
  partnerId,
}) => {
  const componentClasses = useStyles()

  const [date, setDate] = useState(defaultDate)
  const [errorName, setErrorName] = useState(false)
  const [helperTextForName, setHelperTextForName] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState()

  const emptyStates = () => {
    setDate(defaultDate)
    setErrorName('')
    setHelperTextForName('')
    setIsLoading(false)
    setName('')
  }

  const handleClose = () => {
    onClose()
    emptyStates()
  }

  return (
    <CustomSwipeableDialog
      buttons={[
        {
          onClick: handleClose,
          label: cancel,
          variant: 'outlined',
          color: 'tertiary',
        },
        {
          color: 'primary',
          isLoading: isLoading,
          label: confirm,
          onClick: async () => {
            analitycsEvent(
              GACategories.SCHEDULES,
              GAActions.CREATE_SPECIALDAY,
              `partner_id=${partnerId}`,
            )
            if (!name || name.length < 3) {
              setHelperTextForName(minLengthErrorHelperTextForName)
              setErrorName(true)
              return
            }
            setIsLoading(true)
            await onConfirm(date, name)
            emptyStates()
            setIsLoading(false)
          },
          variant: 'contained',
        },
      ]}
      classes={{
        dialogPaper: componentClasses.dialogPaper,
      }}
      onClose={handleClose}
      open={open}
      title={titleSpecialDay}
    >
      <div className={componentClasses.bodyContainer}>
        <div className={componentClasses.formItemContainer}>
          <div>{specialDayNameLabel}</div>
          <CustomTextField
            error={errorName}
            helperText={helperTextForName}
            minLength={3}
            maxLength={30}
            onChange={e => {
              setName(e.currentTarget.value)
            }}
            placeholder={placeHolder}
            required
            value={name}
          />
        </div>
        <div className={componentClasses.formItemContainer}>
          <div>{specialDayDatePickerLabel}</div>
          <CustomDatePicker
            maxDate={maxDate}
            minDate={minDate}
            onChange={newDate => {
              setDate(newDate)
            }}
            value={date}
          />
        </div>
      </div>
    </CustomSwipeableDialog>
  )
}

export default NewSpecialDayDialog
