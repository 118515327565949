import { clsx } from 'clsx';

import useStyles from './StoreScheduleTableHeader.styles';

function StoreScheduleTableHeader(props) {
  const { day, open, close, classes = {} } = props;

  const _classes = useStyles();

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <div className={clsx(_classes.cell, _classes?.dayCell)}>{day}</div>
      <div className={clsx(_classes.cell, _classes?.openCell)}>{open}</div>
      <div className={_classes.cell}>{close}</div>
    </div>
  );
}

export default StoreScheduleTableHeader;
