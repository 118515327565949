import { useDialog } from '../../../../../hooks';
import { useDropdownContentLang } from './useDropdownContentLang';
import { CargoAnalytics } from '../../../../../../../business/cargo/analytics';

import {
  CargoUICase,
  HOW_WORKS_CARGO_URL,
} from '../../../../../../../business/cargo';

export function useDropdownContent() {
  const { openDialog } = useDialog();

  const { setStatus, partnerId } = CargoUICase();

  const lang = useDropdownContentLang();

  const handleOpenReqRT = () => {
    openDialog();
    setStatus('form');
    CargoAnalytics.whenClickOption(partnerId, 'request_rt');
  };

  const handleOpenOrderList = () => {
    openDialog();
    setStatus('list');
    CargoAnalytics.whenClickOption(partnerId, 'orders');
  };

  const handleOpenHowWorks = () => {
    CargoAnalytics.whenClickOption(partnerId, 'how_cargo_works');
    window.open(HOW_WORKS_CARGO_URL, '_blank');
  };

  return {
    lang,
    handleOpenReqRT,
    handleOpenOrderList,
    handleOpenHowWorks,
  };
}
