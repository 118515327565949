// Consts
import urls from '../constants/Urls';
// Utils
import UrlUtils from '../utils/url-utils/UrlUtils';
// Services
import http from './HttpService';
import LocalStorageService from './local-storage-service';

const getIfPartnerIsInBlacklistHandShake = partnerId => {
  const microserviceUrl = LocalStorageService.getBaseUrl();

  const replacedUrl = UrlUtils.replaceUrlParams(urls.BLACKLIST_HANDSHAKE, {
    partnerId,
  });

  return http.get(`${microserviceUrl}${replacedUrl}`);
};

const getOrderIdByPickupCode = (code, partnerId) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();

  const replacedUrl = UrlUtils.replaceUrlParams(
    urls.GET_ORDER_ID_BY_PICKUP_CODE,
    {
      code,
      partnerId,
    },
  );

  return http.get(`${microserviceUrl}${replacedUrl}`);
};

const getWhetherOrderIsValidated = orderId => {
  const microserviceUrl = LocalStorageService.getBaseUrl();

  const replacedUrl = UrlUtils.replaceUrlParams(
    urls.GET_WHETHER_ORDER_IS_VALIDATED,
    {
      orderId,
    },
  );

  return http.get(`${microserviceUrl}${replacedUrl}`);
};

export default {
  getIfPartnerIsInBlacklistHandShake,
  getOrderIdByPickupCode,
  getWhetherOrderIsValidated,
};
