// External libs
import { createReducer } from '@reduxjs/toolkit'
// Interfaces
import { State } from '../interfaces'
// Actions
import actions from '../actions'

export const initialState: State = {
  open: false,
  storeName: '',
  formId: 0,
  storeId: 0,
}

export default createReducer(initialState, {
  [actions.clearAction.type]: state => clear(state),
  [actions.closeAction.type]: state => close(state),
  [actions.maximizeAction.type]: state => setOpen(state, true),
  [actions.openAction.type]: (
    state,
    { payload: { storeName, formId, storeId } },
  ) => open(state, storeName, formId, storeId),
})

const clear = (state: State) => {
  return initialState
}

const close = (state: State) => {
  state.open = false
  return state
}

const open = (
  state: State,
  storeName: string = state.storeName,
  formId: number = state.formId,
  storeId: number = state.storeId,
) => {
  state.open = true
  state.storeName = storeName
  state.formId = formId
  state.storeId = storeId
  return state
}

const setOpen = (state: State, open: boolean) => {
  state.open = open
  return state
}
