// Material UI
import { makeStyles } from '@material-ui/styles';
// Consts
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  actionsContainer: {
    borderTop: `1px solid ${neutral.D10}`,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px 16px',
  },
  button: {
    marginLeft: '20px',
    minWidth: '110px',
  },
  cancelButton: {
    color: neutral.D15,
    fontSize: '13px !important',
    lineHeight: 'normal',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Poppins',
    height: '100%',
    width: '100%',
  },
  description: {
    fontSize: 15,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    margin: '24px 22px 0px 24px',
  },
  toppingBoxesListRoot: {
    height: '100%',
    overflow: 'auto',
  },
  toppingBoxesListTitleContainer: {
    marginTop: '0 !important',
  },
  checkbox: {
    border: `1px solid ${neutral.D10}`,
    display: 'flex',
    padding: '5px 4px 5px 16px',
    alignItems: 'center',
    whiteSpace: 'pre-wrap',
    justifyContent: 'space-between',
    marginLeft: 16,
    marginRight: 16,
    marginTop: 24,
  },
}));
