enum FontWeight {
  ExtraLight = 100,
  Light = 200,
  Regular = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 700,
}

export default FontWeight
