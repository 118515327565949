import { useFormatMessage } from '../../../../../intl/intl';

export function useCodeListLang() {
  return {
    title: useFormatMessage({ id: 'checkinCodeRT.labels.multi' }),
    p1: useFormatMessage({ id: 'checkinCodeRT.codeList.p1' }),
    p2: useFormatMessage({ id: 'checkinCodeRT.codeList.p2' }),
    code: useFormatMessage({ id: 'globals.code' }),
    store: useFormatMessage({ id: 'globals.store' }),
    expiration: useFormatMessage({ id: 'globals.expiration' }),
  };
}
