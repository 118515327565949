import { highDemandBtnAdapter } from './adapters';
import { partnerState } from './store';

import type { HighDemandBtnHandler } from '../domain';
import { SATURATION } from '../domain';

export const highDemandBtnHandler = {
  get,
} as const;

function get(): ReturnType<HighDemandBtnHandler['get']> {
  const { saturation, stores, isFastOperation } = partnerState();
  return {
    storesListByStatus: highDemandBtnAdapter.toStoresStatusList(stores),
    saturation: isFastOperation
      ? SATURATION.fast
      : highDemandBtnAdapter.toModelSaturation(saturation),
  };
}
