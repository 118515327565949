// External libs
import { handleActions } from 'redux-actions'
// Actions
import OrderDetailDialogActions from '../actions/orderDetailDialog.actions'

const defaultOrderId = 0

export const initialState = {
  isLoading: false,
  orderId: defaultOrderId,
  open: false,
  error: {
    is: false,
    message: null,
    title: null,
  },
}

export default handleActions(
  {
    [OrderDetailDialogActions.clearOrderDetailDialogAction]: state =>
      clearDialog(state),
    [OrderDetailDialogActions.closeOrderDetailDialogAction]: state =>
      closeDialog(state),
    [OrderDetailDialogActions.openOrderDetailDialogAction]: (
      state,
      { payload: { orderId } },
    ) => openDialog(state, orderId),
    [OrderDetailDialogActions.setErrorOrderDetailDialogAction]: (
      state,
      { payload: { isError, message, title } },
    ) => setError(state, isError, message, title),
    [OrderDetailDialogActions.setLoadingOrderDetailDialogAction]: (
      state,
      { payload: { isLoading } },
    ) => setLoading(state, isLoading),
  },
  initialState,
)

const clearDialog = state => {
  return {
    ...initialState,
  }
}

const closeDialog = state => {
  return {
    ...state,
    isLoading: false,
    open: false,
  }
}

const openDialog = (state, orderId) => {
  return {
    ...state,
    open: true,
    orderId: orderId || state.orderId,
  }
}

const setError = (state, isError, message, title) => {
  const error = {
    is: isError,
    message,
    title,
  }

  return { ...state, error }
}

const setLoading = (state, isLoading) => {
  return { ...state, isLoading }
}
