import { makeStyles } from '@material-ui/core';

import Colors from '../../constants/Colors';
import { neutral } from '../../shared/styles/colors';

export const orderDetailCardStyles = makeStyles(theme => ({
  root: {
    marginTop: '1rem',
    color: neutral.D20,
    [theme.breakpoints.up('md')]: {
      border: `1px solid ${Colors.PRIMARY_COLOR}`,
    },
  },
  corridor: {
    fontSize: '17px',
    fontFamily: 'Poppins Bold',
  },
  wrapper: {
    display: 'flex',
    background: Colors.WHITE,
    borderRight: `1px solid ${neutral.D10}`,
  },
  defectRateBorder: {
    borderLeft: `3px solid ${Colors.WARNING}`,
  },
  defectRate: {
    alignItems: 'center',
    backgroundColor: Colors.WARNING_LIGHT_2,
    display: 'flex',
    background: Colors.WHITE,
    borderRight: `1px solid ${neutral.D10}`,
    padding: '1rem',
  },
  defectRateIcon: {
    marginRight: '1rem',
    color: Colors.WARNING_FONT_COLOR,
  },
  defectRateText: {
    fontSize: '0.75rem',
    color: neutral.D18,
  },
  container: {
    width: '100%',
    [theme.breakpoints.up('md')]: {},
    fontSize: '17px',
    fontFamily: 'Poppins',
  },
  quantity: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '54px',
    minWidth: '54px',
    fontSize: '1.5rem',
    color: neutral.D20,
  },
  detailHeader: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '0.5rem 0',
  },
  headerDivider: {
    borderRight: `1px solid ${neutral.D10}`,
    height: 64,
    left: 54,
    position: 'absolute',
  },
  detailContainer: {
    borderTop: `1px solid ${neutral.D10}`,
    padding: '0 16px',
    [theme.breakpoints.up('md')]: {
      padding: '0 1.25rem 0 0.9375rem',
    },
  },
  titleWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '3rem',
    padding: '0 1rem',
    width: '100%',
  },
  notes: {
    color: neutral.D15,
    marginBottom: '3px',
  },
  description: {
    margin: '1rem 1.5rem 1rem 0',
  },
  productTitle: {},
  productPrice: {
    display: 'flex',
  },
  toppings: {
    paddingLeft: '16px',
    borderLeft: `1px solid ${neutral.D10}`,
    marginBottom: '20px',
  },
  toppingCategory: {
    marginTop: '20px',
    color: neutral.D15,
  },
  toppingDescription: {},
  toppingDetail: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  toppingOptions: {
    alignItems: 'center',
    display: 'flex',
    gap: '0.5rem',
  },
  commentWrapper: {
    marginBottom: '20px',
  },
  comments: {
    color: Colors.SECONDARY_COLOR,
  },
  detailSubtotal: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: neutral.white,
    padding: '20px 16px',
    fontSize: '17px',
    [theme.breakpoints.up('md')]: {
      padding: '0 24px 20px 24px',
    },
  },
  subtotalVal: {
    fontWeight: 600,
  },
  alertMessage: {
    margin: '20px 0',
  },
  addValue: {
    fontSize: '13px',
    fontFamily: 'Poppins Bold',
    color: Colors.PRIMARY_COLOR,
    marginTop: '14px',
    textTransform: 'capitalize',
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  detailSubtotalKanban: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: `1px solid ${neutral.D10}`,
    fontSize: '17px',
    [theme.breakpoints.up('md')]: {},
  },
  subtotalValKanban: {
    fontWeight: 600,
  },
  deleteProduct: {
    paddingTop: '0.25rem',
    paddingLeft: '0.5rem',
    borderLeft: `1px solid ${neutral.D11}`,
    cursor: 'pointer',
    marginLeft: '0.5rem',
  },
  deleteProductsDisabled: {
    cursor: 'not-allowed',
  },
  checkProduct: {
    padding: 0,
  },
  checkTopping: {
    padding: 0,
  },
}));
