// External libs
import { handleActions } from 'redux-actions';
// Actions
import SuccessfulProxySessionDialogActions from '../actions/successfulProxySessionDialog.actions';

const defaultOrderId = 0;

const initialState = {
  orderId: defaultOrderId,
  open: false,
  callFrom: '',
  callTo: '',
};

export default handleActions(
  {
    [SuccessfulProxySessionDialogActions.clearSuccessfulProxySessionDialogAction]: state =>
      clearDialog(state),
    [SuccessfulProxySessionDialogActions.closeSuccessfulProxySessionDialogAction]: state =>
      closeDialog(state),
    [SuccessfulProxySessionDialogActions.openSuccessfulProxySessionDialogAction]: (
      state,
      { payload: { orderId, callFrom, callTo } },
    ) => openDialog(state, orderId, callFrom, callTo),
  },
  initialState,
);

const clearDialog = state => {
  return {
    ...initialState,
  };
};

const closeDialog = state => {
  return {
    ...state,
    open: false,
  };
};

const openDialog = (state, orderId, callFrom, callTo) => {
  return {
    ...state,
    open: true,
    orderId: orderId || state.orderId,
    callFrom: callFrom,
    callTo: callTo,
  };
};
