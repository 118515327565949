import { useFormatMessage } from '../../../../../intl/intl';

export function useKanbanLang({ lastUpdate }: Props = {}) {
  const messageCategory = 'cargo';
  const messageOrderListSubcategory = 'orderList';
  const messageOrderCardSubcategory = 'orderCard';

  const orderListFilterText = useFormatMessage({
    id: `${messageCategory}.${messageOrderListSubcategory}.filter`,
  });

  const orderListIdPlaceholderText = useFormatMessage({
    id: `${messageCategory}.${messageOrderListSubcategory}.orderIdPlaceholder`,
  });

  const orderListSearchStorePlaceholderText = useFormatMessage({
    id: `${messageCategory}.${messageOrderListSubcategory}.searchStorePlaceholder`,
  });

  const orderListEmptyResultTitleText = useFormatMessage({
    id: `${messageCategory}.${messageOrderListSubcategory}.emptyResultTitle`,
  });

  const orderListEmptyResultDescriptionText = useFormatMessage({
    id: `${messageCategory}.${messageOrderListSubcategory}.emptyResultDescription`,
  });

  const orderListDataZeroTitleText = useFormatMessage({
    id: `${messageCategory}.${messageOrderListSubcategory}.dataZeroTitle`,
  });

  const orderListDataZeroDescriptionText = useFormatMessage({
    id: `${messageCategory}.${messageOrderListSubcategory}.dataZeroDescription`,
  });

  const ordersLastUpdateText = useFormatMessage(
    { id: `${messageCategory}.${messageOrderListSubcategory}.lastUpdate` },
    { lastUpdate },
  );

  const ordersLastUpdateTooltipText = useFormatMessage({
    id: `${messageCategory}.${messageOrderListSubcategory}.lastUpdateTooltip`,
  });

  const orderCardCancelButtonText = useFormatMessage({
    id: `${messageCategory}.${messageOrderCardSubcategory}.cancel`,
  });

  const orderCardTrackingLinkButtonText = useFormatMessage({
    id: `${messageCategory}.${messageOrderCardSubcategory}.trackingLink`,
  });

  const orderCardFindingRTText = useFormatMessage({
    id: `${messageCategory}.${messageOrderCardSubcategory}.findingRT`,
  });

  const orderCardInProgressText = useFormatMessage({
    id: `${messageCategory}.${messageOrderCardSubcategory}.inProgress`,
  });

  const orderCardRTInStoreText = useFormatMessage({
    id: `${messageCategory}.${messageOrderCardSubcategory}.rtInStore`,
  });

  const orderCardRTOnTheWayText = useFormatMessage({
    id: `${messageCategory}.${messageOrderCardSubcategory}.rtOnTheWay`,
  });

  const orderCardRTArrivedText = useFormatMessage({
    id: `${messageCategory}.${messageOrderCardSubcategory}.rtArrived`,
  });

  const orderCardDeliveredToUserText = useFormatMessage({
    id: `${messageCategory}.${messageOrderCardSubcategory}.deliveredToUser`,
  });

  return {
    orderListFilterText,
    orderListIdPlaceholderText,
    orderListSearchStorePlaceholderText,
    orderListEmptyResultTitleText,
    orderListEmptyResultDescriptionText,
    orderListDataZeroTitleText,
    orderListDataZeroDescriptionText,
    ordersLastUpdateText,
    ordersLastUpdateTooltipText,
    orderCardCancelButtonText,
    orderCardTrackingLinkButtonText,
    orderCardFindingRTText,
    orderCardInProgressText,
    orderCardRTInStoreText,
    orderCardRTOnTheWayText,
    orderCardRTArrivedText,
    orderCardDeliveredToUserText,
  };
}

interface Props {
  lastUpdate?: string;
}
