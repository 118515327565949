// Material UI
import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(theme => ({
  storeStatusHeader: {
    marginBottom: '1.5rem',
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  storeStatusSearch: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '32%',
    },
  },
  storeStatusSearchInput: {
    border: `1px solid ${neutral.D13}`,
    borderRadius: 8,
  },
  storeStatusFilters: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '64%',
    },
  },
  badgesContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '1rem',
    overflowX: 'scroll',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
      flexWrap: 'nowrap',
      overflowX: 'unset',
      width: '100%',
    },
  },
  badgeFilterText: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    color: neutral.D20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'initial',
    },
  },
  badgeFiltersContent: {
    display: 'flex',
    gap: '1rem',
    overflowX: 'scroll',
    [theme.breakpoints.up('md')]: {
      overflowX: 'unset',
    },
    '&::-webkit-scrollbar': {
      background: 'transparent',
    },
  },
  toggleStoresBtnContainer: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'initial',
      textAlign: 'right',
    },
  },
}));
