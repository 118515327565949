import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';

import { useOrderMgmtSearchLang } from '../hooks';
import { SuggestionItem } from './SuggestionItem';
import { ItemSkeleton } from './ItemSkeleton';
import { MAX_NUMBER_OF_ITEM_SUGGESTIONS } from '../../../../../../business/order-mgmt';

import makeClasses from './SuggestionItemsSection.style';
import dislike from '../../../../../../assets/images/dislike.svg';
import emptyBox from '../../../../../../assets/images/emptyBox.svg';

import type { Dispatch, SetStateAction } from 'react';
import type { OrderMgmtItem } from '../../../../../../business/order-mgmt';
import LocalStorageService from '../../../../../../services/local-storage-service';
import CountryUtils from '../../../../../../utils/country-utils/CountryUtils';

export function SuggestionItemsSection(props: SuggestionItemsSectionProps) {
  const {
    loading = false,
    empty = false,
    success = false,
    error = false,
    items = [],
    setSuggestionItems,
    suggestionItems,
  } = props;

  const classes = makeClasses();

  const {
    rejectedSearchText,
    emptyResultsTitleText,
    emptyResultsDescriptionText,
    productWithToppingsText,
  } = useOrderMgmtSearchLang();

  const { list, handleOnClick } = useSuggestionItemsSection({
    items,
    setSuggestionItems,
    suggestionItems,
  });

  return (
    <Box component='section' className={classes.container}>
      {loading && (
        <>
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
        </>
      )}

      {success &&
        list.map(({ data, check, disabled }) => (
          <SuggestionItem
            key={`suggestion-item-${data.id}`}
            img={data.img}
            name={data.name}
            price={data.price}
            checked={check}
            disabled={disabled}
            onClick={() => handleOnClick(data)}
            hasToppings={data.hasToppings}
            disclaimer={productWithToppingsText}
          />
        ))}

      {empty && (
        <Box component='article' className={classes.empty}>
          <img src={emptyBox} alt='empty-results' />
          <Typography>{emptyResultsTitleText}</Typography>
          <Typography>{emptyResultsDescriptionText}</Typography>
        </Box>
      )}

      {error && (
        <Box className={classes.error} display='flex'>
          <figure className={classes.errorFigure}>
            <img src={dislike} alt='rejected-search' />
          </figure>
          <Typography>{rejectedSearchText}</Typography>
        </Box>
      )}
    </Box>
  );
}

function useSuggestionItemsSection({
  items,
  setSuggestionItems,
  suggestionItems,
}) {
  const [list, setList] = useState(fnInitList(items, suggestionItems));
  const updateList = (selectedItems: OrderMgmtItem[]) => {
    const selectedIds = selectedItems.map(({ id }) => id);
    const checkDisabled = selectedIds.length >= MAX_NUMBER_OF_ITEM_SUGGESTIONS;
    setList(prevList =>
      prevList.map(item => ({
        ...item,
        check: selectedIds.includes(item.data.id),
        disabled: checkDisabled,
      })),
    );
  };
  const handleOnClick = (itemClicked: OrderMgmtItem) => {
    setSuggestionItems(prevState => {
      const skip = prevState.length >= MAX_NUMBER_OF_ITEM_SUGGESTIONS;
      const hasItem = prevState.some(item => item.id === itemClicked.id);
      if (skip && !hasItem) return prevState;
      const nextState = hasItem
        ? prevState.filter(item => item.id !== itemClicked.id)
        : [...prevState, itemClicked];
      updateList(nextState);
      return nextState;
    });
  };
  useEffect(() => {
    setList(fnInitList(items, suggestionItems));
  }, [items]);
  return { list, handleOnClick };
}

function fnInitList(
  items: OrderMgmtItem[],
  suggestionItems: OrderMgmtItem[] = [],
) {
  const countryCode = LocalStorageService.getCountryCode();
  const suggestionItemsIds = suggestionItems.map(item => item?.id);
  const checkDisabled =
    suggestionItemsIds.length >= MAX_NUMBER_OF_ITEM_SUGGESTIONS;
  return items?.map(
    item =>
      ({
        data: {
          ...item,
          img: (item.img ?? '').toUpperCase().includes('NO-IMAGE')
            ? ''
            : `${CountryUtils.getCountryImageUrl(countryCode)}/products/${
                item?.img
              }`,
        },
        check: suggestionItemsIds.includes(item?.id),
        disabled: checkDisabled,
      } ?? []),
  );
}

interface SuggestionItemsSectionProps {
  empty?: boolean;
  error?: boolean;
  loading?: boolean;
  success: boolean;
  items?: OrderMgmtItem[];
  setSuggestionItems: Dispatch<SetStateAction<OrderMgmtItem[]>>;
  suggestionItems?: OrderMgmtItem[];
}
