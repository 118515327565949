import { useEffect } from 'react';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import RappiLogo from '../icons/rappi-logo';

import { formatCurrency } from '../../utils/CurrencyUtils';

import './PrintPage.css';
import makeClasses from './SummarySalesBill.style';

function SummarySalesBill(props) {
  const {
    cancelOrders,
    canceledTitle,
    date,
    finishedTitle,
    finishOrders,
    onPrint,
    ordersTitle,
    pendingToPrint,
    selectedDate,
    selectedStore,
    showCancelOrderDiscount,
    showFinishOrderDiscount,
    showTotalCancelOrderWithDiscount,
    showTotalFinishOrderWithDiscount,
    storeName,
    SummarySales,
    totalAccumulated,
    totalDiscount,
    totalWithDiscount,
  } = props;

  const _classes = makeClasses();

  useEffect(() => {
    if (!pendingToPrint) {
      return () => {};
    }
    window.print();
    onPrint();
    // eslint-disable-next-line
  }, [pendingToPrint]);

  return (
    <div className={_classes.root}>
      <div className={_classes.container}>
        <span>
          <RappiLogo />
        </span>
      </div>
      <div className={_classes.blackLine}>{SummarySales}</div>
      <span>
        {storeName} : {selectedStore.name}
      </span>
      <br></br>
      <span>
        {date}: {selectedDate}
      </span>
      <div className={_classes.container}>
        <div className={_classes.blackLine}>{finishedTitle}</div>
        <div>
          {ordersTitle}:&nbsp;
          {finishOrders?.number_of_finished_orders}
        </div>
        <div className={_classes.container}>
          <div>
            {totalAccumulated}:&nbsp;
            {formatCurrency(finishOrders?.total_accumulated)}
          </div>
          {showFinishOrderDiscount && (
            <div>
              {totalDiscount}:&nbsp;
              {formatCurrency(finishOrders?.discounts)}
            </div>
          )}
          {showTotalFinishOrderWithDiscount && (
            <div>
              {totalWithDiscount}:&nbsp;
              {formatCurrency(finishOrders?.total_with_discounts)}
            </div>
          )}
        </div>
      </div>
      <div className={_classes.container}>
        <div className={_classes.blackLine}>{canceledTitle}</div>
        <div>
          {ordersTitle}:&nbsp;
          {cancelOrders?.number_of_finished_orders}
        </div>
        <div className={_classes.container}>
          <div>
            {totalAccumulated}:&nbsp;
            {formatCurrency(cancelOrders?.total_accumulated)}
          </div>
          {showCancelOrderDiscount && (
            <div>
              {totalDiscount}:&nbsp;
              {formatCurrency(cancelOrders?.discounts)}
            </div>
          )}
          {showTotalCancelOrderWithDiscount && (
            <div>
              {totalWithDiscount}:&nbsp;
              {formatCurrency(cancelOrders?.total_with_discounts)}
            </div>
          )}
        </div>
        <div className={_classes.blackLine}>&nbsp;</div>
      </div>
      <h1>
        <div className={_classes.blackLine}>
          <span className={_classes.left}>
            <KeyboardArrowRight />
          </span>
          <span className={_classes.right}>
            <KeyboardArrowLeft />
          </span>
        </div>
      </h1>
    </div>
  );
}

export default SummarySalesBill;
