import { makeStyles } from '@material-ui/core';
// Constants
import Colors from '../../../../constants/Colors';
import { neutral } from '../../../../shared/styles/colors';

export const useStyleRules = makeStyles({
  disabledSubmitButton: ({ styleRules }: { styleRules?: any }) => ({
    ...styleRules?.disabledSubmitButton,
  }),
  root: ({ styleRules }: { styleRules?: any }) => ({
    ...styleRules?.root,
  }),
  submitButton: ({ styleRules }: { styleRules?: any }) => ({
    ...styleRules?.submitButton,
  }),
});

export default makeStyles({
  root: ({ fullWidth }: { fullWidth?: boolean }) => ({
    position: 'relative',
    width: fullWidth ? '100%' : 'auto',
  }),
  submitButton: {
    boxShadow: 'none',
    padding: '15px 16px',
    borderRadius: 8,
    '&$disabledSubmitButton': {
      backgroundColor: neutral.D10,
      boxShadow: 'none',
      color: neutral.white,
    },
  },
  infoSubmitButton: {
    backgroundColor: Colors.INFO_FONT_COLOR,
    color: neutral.white,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: Colors.INFO_DARK_COLOR,
      '@media (hover: none)': {
        backgroundColor: Colors.INFO_FONT_COLOR,
      },
    },
  },
  warningSubmitButton: {
    backgroundColor: Colors.WARNING_FONT_COLOR,
    color: neutral.white,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: Colors.WARNING_DARK_COLOR,
      '@media (hover: none)': {
        backgroundColor: Colors.WARNING_FONT_COLOR,
      },
    },
  },
  tertiarySubmitButton: {
    backgroundColor: neutral.white,
    color: neutral.D15,
    borderRadius: 8,
    borderColor: neutral.D10,
    '&:hover': {
      backgroundColor: neutral.D10,
      '@media (hover: none)': {
        backgroundColor: neutral.D10,
      },
    },
  },
});
