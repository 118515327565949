import { makeStyles } from '@material-ui/styles';
// Constant
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  reason: {
    padding: '24px',
    borderBottom: `1px solid ${neutral.D10}`,
  },
  rtRoot: {
    fontSize: '17px',
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    borderBottom: `1px solid ${neutral.D10}`,
    padding: '24px 0',
  },
  root: {
    width: '80px',
    height: '80px',
    fontSize: '64px',
    marginBottom: '20px',
  },
}));
