import { OrderResponse, SpecialOrderTypes } from '../../../../shared/infra';

export const turboHelper = {
  isTurboqueue: (specialOrder: SpecialOrderTypes[]) =>
    specialOrder?.includes('turbo') ?? false,

  sortV2Callback: (a: OrderResponse, b: OrderResponse) => {
    if (a?.special_order?.includes('turbo')) {
      return -1;
    }
    if (b?.special_order?.includes('turbo')) {
      return 1;
    }
    return 0;
  },
  filterV2: (orders) => {
    return [
      ...orders.filter(order => order?.special_order?.includes('turbo')),
      ...orders.filter(order => !order?.special_order?.includes('turbo'))
    ]
  }
};
