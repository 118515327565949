// React
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// Components
import MenuSearchProductList from './MenuSearchProductList';

const mapStateToProps = (
  { menuReducer: { searchedProducts } },
  { intl: { formatMessage } },
) => {
  const title = formatMessage({ id: 'globals.products' });

  return { products: searchedProducts, title };
};

const mapDispatchToProps = {};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MenuSearchProductList),
);
