import MainLayout from '../main-layout/MainLayoutContainer';
import CustomButton from '../common/buttons/custom-button/CustomButton';

import { analitycsEvent, GAActions, GACategories } from '../../utils/ga-utils';

import useStyles from './MarketingPage.styles';

function MarketingPage(props) {
  const {
    campaginTitle,
    title,
    downloadApp,
    downloadAppContent,
    donwloadAppButton,
    goPortalButton,
    donwloadAppGrowth,
    goToPortalPartners,
    partnerId,
  } = props;

  const classes = useStyles();

  return (
    <MainLayout title={title}>
      <div className={classes.root}>
        <div className={classes.containerBox}>
          <div className={classes.titles}>{campaginTitle}</div>
          <div className={classes.Subtitles}>{downloadApp}</div>
          <div className={classes.SubContent}>{downloadAppContent}</div>
          <div className={classes.buttonContainer}>
            <CustomButton
              color='secondary'
              variant='contained'
              className={classes.downLoadApp}
              fullWidth={false}
              text={donwloadAppButton}
              onClick={() => {
                analitycsEvent(
                  GACategories.EXTERNAL_SITES,
                  GAActions.CTA_RAPPI_GROWTH_APP_ANDROID,
                  `partner_id=${partnerId}`,
                );
                window.open(donwloadAppGrowth, '_blank');
              }}
            ></CustomButton>
            <CustomButton
              color='secondary'
              variant='contained'
              className={classes.goPortal}
              fullWidth={false}
              text={goPortalButton}
              onClick={() => {
                analitycsEvent(
                  GACategories.EXTERNAL_SITES,
                  GAActions.CTA_RAPPI_GROWTH_WEB,
                  `partner_id=${partnerId}`,
                );
                window.open(goToPortalPartners, '_blank');
              }}
            ></CustomButton>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default MarketingPage;
