// External libs
import { createSelector } from 'reselect';
// Consts
import { textIds } from '../consts';
import { SupportSuggestion } from '../../../interfaces';
// Tools
import { formatMessage } from '../../../intl/intl';
// Selectors
import { selectors as supportReasonsDialogSelectors } from '../../../redux/support-reasons-dialog';
import { Option, utils } from '../../common/boxes/custom-clickable-box-list';
// Store
import appStore from '../../../redux/stores/app.store';
// Transpilers
import supportSuggestionTranspiler from '../../../transpilers/support-suggestion-transpiler';
// Actions
import EventActions from '../../../redux/actions/event-actions';

const selectOptions = createSelector<
  number,
  any,
  SupportSuggestion[],
  Option[]
>(
  supportReasonsDialogSelectors.selectOrderId,
  supportReasonsDialogSelectors.selectSupportSuggestions,
  (orderId, supportSuggestions) => {
    const dispatch = appStore.getDispatch();
    return supportSuggestions.map((suggestion) => {
      const action = supportSuggestionTranspiler.getSupportReasonAction(
        suggestion.id,
      );
      const onClick = async () => {
        const suggestionId = suggestion.id;
        EventActions.onClickSelectedReasonList(orderId, suggestionId);
        await dispatch(action());
      };
      return utils.buildOption(
        suggestion.description,
        onClick,
        suggestion.title,
      );
    });
  },
);

const selectTitle = createSelector<any, number, string>(
  supportReasonsDialogSelectors.selectOrderId,
  (orderId) => formatMessage(textIds.title, { orderId }),
);

const selectors = { selectOptions, selectTitle };

export default selectors;
