import { useFormatMessage } from '../../../../intl/intl';

import {
  storeStatusDropdownTextIds as ssDdBtnTextIds,
  storeStatusStatusOpts as sssOpts,
} from '../../constants';

import type { TpStoreStatusStatus } from '../../types';

function useTexts(props: useTextIdsRootProps) {
  const { status, isSingleStore = false } = props;
  const baseId = sssOpts[status]?.textId;
  const multiId = baseId ? ssDdBtnTextIds.multiStore : ssDdBtnTextIds.error;
  const ToMulti = useFormatMessage({ id: multiId });
  const toSingle = [
    useFormatMessage({ id: ssDdBtnTextIds.storeName }),
    useFormatMessage({ id: baseId || ssDdBtnTextIds.error })?.toLowerCase(),
  ];
  const correctText = isSingleStore ? toSingle.join(' ') : ToMulti;
  return {
    drawerCloseBtn: 'Cerrar',
    dropdownBtn: baseId ? correctText : ToMulti,
  };
}

export const hooks = {
  useTexts,
};

interface useTextIdsRootProps {
  isSingleStore: boolean;
  status: TpStoreStatusStatus;
}
