import { clsx } from 'clsx';
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';

import useStyles from './styles';

import type { BaseProps } from '../../../../../interfaces';

export function BaseCustomAlert({ classes, text }: Props) {
  const _classes = useStyles();
  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <InfoOutlinedIcon className={_classes.icon} />
      <div
        className={_classes.content}
        dangerouslySetInnerHTML={{
          __html: text || '',
        }}
      />
    </div>
  );
}

interface Props extends BaseProps {
  text?: string | null;
}
