import { clsx } from 'clsx';
import PrintIcon from '@material-ui/icons/Print';
import { IconButton } from '@material-ui/core';

import useStyles from './CustomPrintButton.styles';

export function CustomPrintButton({ onClick, classes = {} }) {
  const _classes = useStyles();
  return (
    <IconButton
      className={clsx(classes?.button)}
      aria-label='Print'
      onClick={onClick}
    >
      <PrintIcon className={clsx(_classes.icon, classes?.icon)} />
    </IconButton>
  );
}
