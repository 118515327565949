// Material UI
import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  dialogPaper: {
    width: '488px',
  },
  inputCode: {
    fontSize: '17px',
    lineHeight: 'normal',
    textAlign: 'center',
  },
  inputsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    marginTop: '40px',
    '& > div': {
      maxWidth: '60px',
      '&:not(:first-child)': {
        marginLeft: '20px',
      },
    },
  },
  root: {},
}));
