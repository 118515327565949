import { useFormatMessage } from '../../../../intl/intl';

import type { SaturationStateProp } from '../models';

export function useSaturationStateText(props: Props) {
  const { variant, isSingleStore = true } = props;
  const isVariantInPlural = variant === 'deactivated' && !isSingleStore;
  const text = useFormatMessage({ id: `highDemand.state.${variant}` });
  const allDayText = useFormatMessage({ id: 'highDemand.ttlOption.title.day' })
  return  {
    allDayText,
    status: isVariantInPlural
      ? text
          .split(' ')
          .map(v => v + 's')
          .join(' ')
      : text
  }
}

interface Props extends SaturationStateProp {
  isSingleStore?: boolean;
}
