import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import appStore from '../../../../redux/stores/app.store';
import EventActions from '../../../../redux/actions/event-actions/event.actions';
import { selectors as helpCenterSelectors } from '../../../../redux/help-center-page';

import Paths from '../../../../constants/Paths';

import type { Article, Section } from '../../../../redux/help-center-page';

export function useCategoryDetail() {
  const navigate = useNavigate();

  const partnerId = appStore.getPartnerId();
  const category = useSelector(helpCenterSelectors.selectSelectedCategory);

  const categoryId = category?.id ?? '';
  const categoryName = category?.name ?? '';
  const subCategories = category?.sections ?? [];

  const goToArticle = ({ articleId }: { articleId: Article['id'] }) => {
    navigate(Paths.goToHelpCenterWithCategoryAndArticle(categoryId, articleId));
  };

  const handleGoToArticle = (params: HandleGoToArticleParams) => () => {
    const { subcategory, article } = params;
    EventActions.onSuccessfulArticle(article.id);
    EventActions.onSuccessSelectedArticle(
      partnerId,
      categoryName,
      subcategory.name,
      article.name,
    );
    goToArticle({ articleId: article.id });
  };

  return {
    categoryName,
    subCategories,
    handleGoToArticle,
  };
}

interface HandleGoToArticleParams {
  article: Article;
  subcategory: Section;
}
