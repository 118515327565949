import { createSelector } from 'reselect';
import { get, find } from 'lodash';

import type { ArticleDetail, Category, State, Section } from '../interfaces';

const selectSlice: (state: any) => State = (state: any) => state.helpCenterPage;

const selectIsArticleLoading = createSelector<any, State, boolean>(
  selectSlice,
  reducer => reducer.isArticleLoading,
);

const selectOpen = createSelector<any, State, boolean>(
  selectSlice,
  reducer => reducer.open,
);

const selectSelectedArticle = createSelector<
  any,
  State,
  ArticleDetail | null | undefined
>(selectSlice, reducer => reducer.selectedArticle);

const selectSelectedArticleId = createSelector<
  any,
  ArticleDetail | null | undefined,
  string | null | undefined
>(selectSelectedArticle, article => get(article, 'id'));

const selectData = createSelector<any, State, Category[]>(
  selectSlice,
  reducer => reducer.categories,
);

const selectedCategoryId = createSelector<any, State, string>(
  selectSlice,
  reducer => reducer.selectedCategoryId,
);
const selectedSubCategoryId = createSelector<any, State, string>(
  selectSlice,
  reducer => reducer.selectedSubCategoryId,
);

const selectSelectedCategory = createSelector<
  any,
  Category[],
  string,
  Category
>(
  selectData,
  selectedCategoryId,
  (categories: Category[], categoryId: string) => {
    return (
      find(categories, ['id', categoryId]) || {
        id: '',
        name: '',
        sections: [],
        subType: '',
        type: '',
      }
    );
  },
);
const selectSelectedSubCategory = createSelector<
  any,
  Category,
  string,
  Section
>(
  selectSelectedCategory,
  selectedSubCategoryId,
  (selectedCategory, selectedSubCategoryId) => {
    return (
      selectedCategory.sections.find(s => s.id === selectedSubCategoryId) || {
        id: '',
        name: '',
        articles: [],
        subType: '',
        type: '',
      }
    );
  },
);
const selectedSubCategoryName = createSelector<any, Section, string>(
  selectSelectedSubCategory,
  selectedSubCategory => selectedSubCategory.name,
);

const selectors = {
  selectData,
  selectIsArticleLoading,
  selectOpen,
  selectSelectedArticle,
  selectSelectedArticleId,
  selectSelectedCategory,
  selectedCategoryId,
  selectedSubCategoryName,
  selectedSubCategoryId,
};

export default selectors;
