import { useCodeListLang } from './useCodeListLang';
import { useExpiredAtFormatted } from '../../../shared/hooks';

export function useCodeList() {
  const lang = useCodeListLang();
  return {
    lang,
    useExpiredAtFormatted,
  };
}
