// React
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// Actions
import menuActions from '../../redux/actions/menu.actions';
// Components
import MenuSearchField from './MenuSearchField';

const mapStateToProps = (
  { menuReducer: { filter }, partnerReducer },
  { intl: { formatMessage } },
) => {
  const placeHolder = formatMessage({ id: 'menuSearchField.placeHolder' });
  return {
    placeHolder,
    searchText: filter.searchText,
    partnerId: partnerReducer.profile.id,
  };
};

const mapDispatchToProps = {
  onChange: menuActions.setSearchText,
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MenuSearchField),
);
