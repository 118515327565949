// React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Components
import RegularStoreScheduleTable from './RegularStoreScheduleTable'

const mapStateToProps = (
  {
    storesSchedulesReducer: {
      areStoresSchedulesLoading: isLoading,
      filter: { selectedStoreId },
      storesSchedules,
    },
  },
  { intl: { formatMessage } },
) => {
  const day = formatMessage({
    id: 'storesSchedulePage.day',
  })

  const storeSchedules = storesSchedules[selectedStoreId]

  const schedules = storeSchedules && storeSchedules.schedules.normal

  return { day, isLoading, schedules }
}

const mapDispatchToProps = {}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(RegularStoreScheduleTable),
)
