import { defaultValueHandoffDetail } from '../../../domain/consts/CheckoutRT.const';
import { CheckoutRTState } from '../../../domain/models';

export const CHECKOUT_RT_SLICE_NAME = 'orderCheckoutRT';

export const INIT_STATE: CheckoutRTState = {
  status: 'idle',
  currentData: {
    ...defaultValueHandoffDetail,
  },
};
