import { useSelector } from 'react-redux';

import orderDetailDialogSelectors from '../../../../../redux/selectors/order-detail-dialog-selectors';
import ordersSelector from '../../../../../redux/selectors/orders-selectors';

const useOrderId = () => useSelector(orderDetailDialogSelectors.selectOrderId);

const useValue = () =>
  useSelector(ordersSelector.selectCTToTakeOrderInOrderDetailDialog);

const hooks = {
  useOrderId,
  useValue,
};
export default hooks;
