import { makeStyles } from '@material-ui/core'

export default makeStyles({
  root: {
    minWidth: 'max-content',
    borderRadius: '0.5rem',
  },
  label: {
    fontWeight: 600,
  },
})
