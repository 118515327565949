const contains = (text, searchedText) => {
  if (!text || !searchedText) {
    return false;
  }
  return text.indexOf(searchedText) !== -1;
};

const containsAnyString = (text, searchedTexts) => {
  for (const searchedText of searchedTexts) {
    const isContained = contains(text, searchedText);

    if (isContained) {
      return true;
    }
  }

  return false;
};

function normalizeString(text = '') {
  if (!text) {
    return '';
  }
  return text
    .toLowerCase()
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

const normalizeStrings = texts => {
  return texts.map(t => normalizeString(t));
};

const doubleQuotes = text => {
  return `"${text}"`;
};

const doubleQuotesConcat = (texts, propertyName = null) => {
  if (!texts || texts.length === 0) {
    return null;
  }
  return texts.map((t, i) => {
    const doubleQoutedtext = !!propertyName
      ? doubleQuotes(t[propertyName])
      : doubleQuotes(t);
    return i < texts.length - 1 ? `${doubleQoutedtext}, ` : doubleQoutedtext;
  });
};

const isNullOrEmpty = s => {
  return !s || (typeof s === 'string' && s.trim() === '');
};

const isNumber = s => {
  return s === 0 || (!isNullOrEmpty(s) && !isNaN(s));
};

const stringToHex = str => {
  var arr1 = [];
  for (var n = 0, l = str.length; n < l; n++) {
    var hex = Number(str.charCodeAt(n)).toString(16);
    arr1.push(hex);
  }
  return arr1.join('');
};

export function snakeToCamel(str) {
  return str.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

export function objKeysFromSnakeToCamel(obj) {
  const newObj = {};
  for (const key in obj) newObj[snakeToCamel(key)] = obj[key];
  return newObj;
}

export default {
  contains,
  containsAnyString,
  normalizeString,
  normalizeStrings,
  doubleQuotes,
  doubleQuotesConcat,
  isNullOrEmpty,
  isNumber,
  stringToHex,
};
