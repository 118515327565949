// React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Consts
import PartnerTypes from '../../constants/PartnerTypes'
// Components
import SettingsOptionsAndExtensions from './SettingsOptionsAndExtensions'

const mapStateToProps = (
  { partnerReducer: { profile } },
  { intl: { formatMessage } },
) => {
  const title = formatMessage({
    id: 'settings.title',
  })
  const showPrintSettings = profile.type !== PartnerTypes.PARTNERS_INTEGRADO
  return {
    profile: profile,
    showPrintSettings,
    title,
  }
}
const mapDispatchToProps = {}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SettingsOptionsAndExtensions),
)
