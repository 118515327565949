import { clsx } from 'clsx';
import { SvgIcon } from '@material-ui/core';

import { neutral, purple } from '../../../shared/styles/colors';

import useStyles from './ErrorStateIcon.styles';

export function ErrorStateIcon({ classes = {} }) {
  const _classes = useStyles();
  return (
    <SvgIcon
      classes={{ root: clsx(_classes.root, classes?.root) }}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 135 61'
    >
      <g fill='none' fillRule='evenodd' transform='translate(.5 1)'>
        <path
          fill={purple['10']}
          d='M73.608 12.371c2.392 0 4.33 1.939 4.33 4.33s-1.938 4.33-4.33 4.33h39.588c2.391 0 4.33 1.938 4.33 4.33 0 2.391-1.939 4.33-4.33 4.33h13.608c2.391 0 4.33 1.938 4.33 4.33 0 2.391-1.939 4.33-4.33 4.33h-11.752c-2.392 0-4.33 1.938-4.33 4.33 0 2.39 1.938 4.33 4.33 4.33h3.71c2.392 0 4.33 1.938 4.33 4.33 0 2.39-1.938 4.33-4.33 4.33H28.455c-2.392 0-4.33-1.94-4.33-4.33 0-2.392 1.938-4.33 4.33-4.33H4.33C1.939 47.01 0 45.07 0 42.68c0-2.392 1.939-4.33 4.33-4.33h24.742c2.392 0 4.33-1.939 4.33-4.33 0-2.392-1.938-4.33-4.33-4.33H13.608c-2.391 0-4.33-1.939-4.33-4.33 0-2.392 1.939-4.33 4.33-4.33h24.743c-2.392 0-4.33-1.939-4.33-4.33s1.938-4.33 4.33-4.33h35.257zm55.67 34.64c2.392 0 4.33 1.938 4.33 4.33 0 2.39-1.938 4.33-4.33 4.33-2.391 0-4.33-1.94-4.33-4.33 0-2.392 1.939-4.33 4.33-4.33z'
        />
        <path
          fill={neutral.white}
          stroke={purple['50']}
          strokeLinecap='round'
          strokeWidth='.75'
          d='M46.392 59.381c-7.516 0-13.608-5.954-13.608-13.299 0-7.344 6.092-13.298 13.608-13.298.32 0 .636.01.95.031-.218-1.214-.332-2.465-.332-3.743 0-11.615 9.416-21.03 21.031-21.03 9.273 0 17.144 6 19.942 14.33.662-.068 1.336-.104 2.017-.104 10.42 0 18.866 8.308 18.866 18.557 0 9.736-7.624 17.77-17.32 18.543v.013H56.603m-2.791 0h-4.308'
        />
        <circle
          cx='68.351'
          cy='47.32'
          r='3.402'
          stroke={purple['50']}
          strokeWidth='.75'
        />
        <path
          stroke={purple['50']}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='.75'
          d='M58.763 40.206L63.093 36.808 58.763 33.574'
        />
        <path
          stroke={purple['50']}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='.75'
          d='M73.608 40.206L77.938 36.808 73.608 33.574'
          transform='matrix(-1 0 0 1 151.546 0)'
        />
        <path
          stroke={purple['50']}
          strokeLinecap='round'
          strokeWidth='.75'
          d='M74.227 14.227c4.026 1.065 7.205 4.214 8.313 8.222'
        />
        <circle
          cx='87.216'
          cy='1.856'
          r='1.856'
          stroke={purple['50']}
          strokeWidth='.75'
        />
        <circle cx='106.392' cy='11.753' r='1.856' fill={purple['50']} />
        <path
          stroke={purple['50']}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='.75'
          d='M97.263 8.51l-5.17 5.17m-.078-5.17l5.17 5.17M37.363 19.544l-3.711 3.711m0-3.711l3.711 3.711'
        />
        <circle cx='42.062' cy='13.608' r='1.856' fill={purple['50']} />
      </g>
    </SvgIcon>
  );
}
