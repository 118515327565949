import { makeStyles } from '@material-ui/core';
import { CoverageStatus } from '../../../../business/cargo';
import Colors from '../../../../constants/Colors';
import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(() => ({
  addressLabel: {
    fontSize: '0.625rem',
    fontWeight: 400,
    color: neutral.D16,
    marginBottom: '0.5rem',
  },
  fieldsRequired: {
    color: neutral.D15,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    alignSelf: 'flex-end',
  },
  disclaimerContainer: ({
    coverageStatus,
  }: {
    coverageStatus?: CoverageStatus;
  }) =>
    ({
      hasCoverage: {
        borderLeft: `3px solid ${Colors.INFO_COLOR_2}`,
        backgroundColor: Colors.CORNFLOWER_BLUE,
      },
      noCoverage: {
        borderLeft: `3px solid ${Colors.ANZAC}`,
        backgroundColor: Colors.WARNING_COLOR,
      },
      notAvailable: {
        borderLeft: `3px solid ${Colors.SECONDARY_COLOR_DARK}`,
        backgroundColor: Colors.DANGER_LIGHT_COLOR,
      },
    }[coverageStatus ?? 'hasCoverage']),
  disclaimerIcon: {
    alignSelf: 'flex-start',
    paddingTop: '0.25rem',
  },
  disclaimerTitle: {
    fontSize: '0.875rem',
    fontWeight: 600,
  },
  disclaimerDescription: {
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  disabled: {
    backgroundColor: neutral.D11,
  },
  imgMarkerContainer: {
    margin: '-4.75rem -1.96875rem',
  },
  mapDisclaimer: {
    fontSize: '0.875rem',
    fontWeight: 400,
    marginTop: '1rem',
  },
  restaurantIcon: {
    height: '6rem',
    width: '5.1875rem',
  },
}));
