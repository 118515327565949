import { useState } from 'react';
import { useMediaQuery } from '@material-ui/core';

import { useCargoMainBtnLang } from './useCargoMainBtnLang';

import type { Theme } from '@material-ui/core';

export function useCargoMainBtn() {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const lang = useCargoMainBtnLang();

  return {
    lang,
    isOpenDropdown,
    isLarge: useMediaQuery(({ breakpoints }: Theme) => breakpoints.up('md')),
    handleOpenDropDown: () => setIsOpenDropdown(true),
    handleCloseDropDown: () => setIsOpenDropdown(false),
  };
}
