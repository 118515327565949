import { formatCurrency } from '../../../../utils/CurrencyUtils';
import {
  OrderMgmtDisclaimer,
  OrderMgmtProduct,
  OrderMgmtTopping,
} from '../../domain/models';
import { OMProduct, OMToppingDTO } from '../core/responses';

const toProductsAccepted = (data: OMProduct[]): OrderMgmtDisclaimer[] => {
  return data.map(product => {
    const replacement = product.replacement_product_options.find(
      r => r.accepted,
    );
    return {
      id: product.product_id,
      name: product.name,
      replacementName: replacement?.added_product_name ?? '',
      replacementPrice: formatCurrency(replacement?.added_product_price),
      isTopping: false,
    };
  });
};
const toToppingsAccepted = (data: OMToppingDTO[]): OrderMgmtDisclaimer[] => {
  return data.map(product => {
    const replacement = product.replacement_topping_options.find(
      r => r.accepted,
    );
    return {
      id: product.topping_id,
      name: product.topping_description,
      replacementName: replacement?.added_topping_description ?? '',
      replacementPrice: formatCurrency(replacement?.added_topping_price),
      isTopping: true,
    };
  });
};

const toOrderMgmtProduct = (product: OMProduct): OrderMgmtProduct => ({
  id: `${product?.order_product_id ?? ''}`,
  name: product.name,
  price: product.price,
  units: product.units,
  description: product.description,
  subtotal: product.total,
  hasSuggestions: !!product?.replacement_product_options?.length,
  suggestions: [...(product?.replacement_product_options || [])].map(r => ({
    id: `${r.added_product_id}`,
    name: r.added_product_name,
    price: r.added_product_price,
    productId: r.added_product_id,
    description: r.added_product_description,
    img: r.added_product_image,
    units: r.added_product_units,
    total: r.added_product_total,
  })),
  isSelected: false,
});

const toOrderMgmtTopping = (dto: OMToppingDTO): OrderMgmtTopping => ({
  id: `${dto?.topping_id ?? ''}`,
  productId: dto.order_product_id,
  name: dto.topping_description,
  price: dto.price,
  units: dto.units,
  description: dto.topping_description,
  subtotal: dto.price,
  isRemovable: dto.is_removable_topping,
  hasSuggestions: !!dto?.replacement_topping_options?.length,
  suggestions: [...(dto?.replacement_topping_options || [])].map(r => ({
    id: `${r.added_topping_id}`,
    name: r.added_topping_description,
    price: r.added_topping_price,
    toppingId: r.added_topping_id,
    description: r.added_topping_description,
    img: r.added_topping_image,
    units: r.added_topping_units,
    total: r.added_topping_total,
  })),
  isSelected: false,
});

export const OrderMgmtDisclaimerAdapter = {
  toProductsAccepted,
  toToppingsAccepted,
  toOrderMgmtProduct,
  toOrderMgmtTopping,
};
