// External libs
import { createSelector } from 'reselect';
import { get } from 'lodash';
// Interfaces
import { State } from '../interfaces/state';

const selectSlice: (state: any) => State = (state: any) =>
  state.orderRejectionByMotoboyLackDialog;

const selectOpen = createSelector<any, State, boolean>(
  selectSlice,
  reducer => reducer.open,
);

const selectOrderId = createSelector<any, State, number>(
  selectSlice,
  reducer => reducer.orderId,
);

const selectRefuseReason = createSelector<any, State, any>(
  selectSlice,
  reducer => reducer.refuseReason,
);

const selectRefuseReasonDescription = createSelector<any, any, string>(
  selectRefuseReason,
  refuseReason => get(refuseReason, 'description', null),
);

const selectors = { selectOpen, selectOrderId, selectRefuseReasonDescription };

export default selectors;
