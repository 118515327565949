import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../shared/styles/colors';

export default makeStyles({
  label: {
    color: neutral['90'],
    fontFamily: 'Poppins',
    fontSize: '1rem',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    marginLeft: '0.5rem',
  },
});
