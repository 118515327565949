import { createActions } from 'redux-actions';
// Services
import PartnersService from '../../services/partners-service';
// Actions
import appActions from './app-actions';

const {
  applyFilterAction,
  clearFilterAction,
  closeFilterDrawerAction,
  confirmSalesSummaryToPrintAction,
  getSalesSummaryAction,
  openFilterDrawerAction,
  sendSalesSummaryToPrintAction,
  setSalesSummaryIsNotLoadingAction,
  setSalesSummaryIsLoadingAction,
} = createActions({
  APPLY_FILTER_ACTION: (selectedStoreId, selectedDate) => ({
    selectedStoreId,
    selectedDate,
  }),
  CLOSE_FILTER_DRAWER_ACTION: () => ({}),
  CLEAR_FILTER_ACTION: () => ({}),
  CONFIRM_SALES_SUMMARY_TO_PRINT_ACTION: () => ({}),
  GET_SALES_SUMMARY_ACTION: summary => ({ summary }),
  OPEN_FILTER_DRAWER_ACTION: () => ({}),
  SEND_SALES_SUMMARY_TO_PRINT_ACTION: () => ({}),
  SET_SALES_SUMMARY_IS_NOT_LOADING_ACTION: () => ({}),
  SET_SALES_SUMMARY_IS_LOADING_ACTION: () => ({}),
});

const applyFilter = (selectedStoreId, selectedDate) => async dispatch => {
  dispatch(applyFilterAction(selectedStoreId, selectedDate));
  await getSalesSummary(selectedStoreId, selectedDate)(dispatch);
  closeFilterDrawer()(dispatch);
};

const sendSalesSummaryToPrint = () => dispatch => {
  return dispatch(sendSalesSummaryToPrintAction());
};

const confirmSalesSummaryToPrint = () => dispatch => {
  return dispatch(confirmSalesSummaryToPrintAction());
};

const clearFilter = () => dispatch => {
  dispatch(clearFilterAction());
  dispatch(getSalesSummaryAction([]));
  closeFilterDrawer()(dispatch);
};

const closeFilterDrawer = () => dispatch => {
  return dispatch(closeFilterDrawerAction());
};

const getSalesSummary = (storeId, date) => async dispatch => {
  try {
    setSalesSummaryIsLoading()(dispatch);
    const response = await PartnersService.getSalesSummary(storeId, date);
    dispatch(getSalesSummaryAction(response.data));
    setSalesSummaryIsNotLoading()(dispatch);
  } catch (ex) {
    setSalesSummaryIsNotLoading()(dispatch);
    await appActions.handleCatch(ex)(dispatch);
  }
};

const openFilterDrawer = () => dispatch => {
  return dispatch(openFilterDrawerAction());
};

const setSalesSummaryIsNotLoading = () => dispatch => {
  dispatch(setSalesSummaryIsNotLoadingAction());
};

const setSalesSummaryIsLoading = () => dispatch => {
  dispatch(setSalesSummaryIsLoadingAction());
};

export default {
  applyFilter,
  applyFilterAction,
  clearFilter,
  clearFilterAction,
  closeFilterDrawer,
  closeFilterDrawerAction,
  confirmSalesSummaryToPrintAction,
  confirmSalesSummaryToPrint,
  getSalesSummary,
  getSalesSummaryAction,
  openFilterDrawer,
  openFilterDrawerAction,
  sendSalesSummaryToPrintAction,
  sendSalesSummaryToPrint,
  setSalesSummaryIsNotLoading,
  setSalesSummaryIsNotLoadingAction,
  setSalesSummaryIsLoading,
  setSalesSummaryIsLoadingAction,
};
