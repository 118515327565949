import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme, useMediaQuery } from '@material-ui/core';

import CustomDialog from '../common/custom-dialog/CustomDialog';
import CustomButton, {
  Colors,
  Variants,
} from '../common/buttons/custom-button';

import hooks from './hooks';
import { useFormatMessage } from '../../intl/intl';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';

import erroIconImg from '../../assets/images/errorIcon.png';
import { textIds } from './consts';
import useStyles from './styles';

export function ErrorSuggestingProductDialog() {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const closeButtonText = useFormatMessage(textIds.closeButtonText);
  const title = useFormatMessage(textIds.title);
  const subTitle = useFormatMessage(textIds.subTitle);
  const description = useFormatMessage(textIds.description);
  const open = hooks.useOpen();
  const componentClasses = useStyles();

  const dispatch = useDispatch();
  const handleClose = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  return (
    <CustomDialog
      actions={
        isMdUp
          ? [
              {
                text: closeButtonText,
                onClick: handleClose,
                fullWidth: false,
                disabled: false,
                color: 'primary',
                variant: 'contained',
              },
            ]
          : []
      }
      classes={{
        childrenContainer: componentClasses.body,
        headerRoot: componentClasses.headerRoot,
        paper: componentClasses.paper,
      }}
      CustomDialogHeaderChildren={''}
      onClose={handleClose}
      open={open}
      showBackButton={false}
      showCloseButton={false}
      title={title}
    >
      <div className={componentClasses.container}>
        <img
          src={erroIconImg}
          alt='error-img'
          className={componentClasses.errorIconImg}
        />
      </div>
      <div className={componentClasses.productName}>
        <div className={componentClasses.subTitle}>{subTitle}</div>
        <div className={componentClasses.description}>{description}</div>
      </div>
      {!isMdUp && (
        <div className={componentClasses.containerFooterButtons}>
          <CustomButton
            classes={{ submitButton: componentClasses.rejectButtonFooter }}
            color={Colors.PRIMARY}
            fullWidth={true}
            onClick={handleClose}
            variant={Variants.CONTAINED}
          >
            {closeButtonText}
          </CustomButton>
        </div>
      )}
    </CustomDialog>
  );
}
