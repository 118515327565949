// Material UI
import { makeStyles } from '@material-ui/core';
// Constant
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
  },
  titleStyles: {
    padding: '24px',
    background: neutral.white,
    borderBottom: `1px solid ${neutral.D10}`,
    marginTop: '24px',
    [theme.breakpoints.up('md')]: {
      padding: '24px',
      background: neutral.white,
      marginBottom: '0',
      borderBottom: `1px solid ${neutral.D10}`,
    },
  },
  container: {
    background: neutral.white,
    [theme.breakpoints.up('md')]: {
      margin: 24,
    },
  },
}));
