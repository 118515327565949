import { useMemo } from 'react';
import { produce } from 'immer';

import CustomLoader from '../../../custom-loader';

import { textIds } from './consts';
import UrlUtils from '../../../../../utils/url-utils';
import StringUtils from '../../../../../utils/StringUtils';
import { useFormatMessage } from '../../../../../intl/intl';

import useStyles from './styles';

import type { AvailableCountry } from '../../../../../interfaces';

export function RenderValue({ value, isLoading = false }: RenderValueProps) {
  const classes = useStyles();

  const placeholder = useFormatMessage(textIds.placeholder);

  const formattedValue = useMemo(() => {
    if (!value) {
      return null;
    }
    return produce(value, valueDraft => {
      valueDraft.flag = UrlUtils.ensureHttps(valueDraft.flag);
    });
  }, [value]);

  return (
    <div className={classes.root}>
      <CustomLoader open={isLoading} invisible size={32} />
      {!!formattedValue ? (
        <>
          {!StringUtils.isNullOrEmpty(formattedValue.flag) && (
            <img
              className={classes.flag}
              alt='flag'
              src={formattedValue.flag}
            />
          )}
          <span className={classes.name}>{formattedValue.name}</span>
        </>
      ) : (
        <span className={classes.placeHolder}>{placeholder}</span>
      )}
    </div>
  );
}

export interface RenderValueProps {
  isLoading: boolean;
  value: AvailableCountry | null;
}
