// External libs
import { createReducer } from '@reduxjs/toolkit';
// Interfaces
import { State } from '../interfaces';
import { SupportSuggestion } from '../../../interfaces';
// Actions
import actions from '../actions';

const defaultOrderId: number = 0;

const initialState: State = {
  description: '',
  orderId: defaultOrderId,
  supportSuggestions: [],
};

export default createReducer(initialState, {
  [actions.setAction.type]: (
    state,
    { payload: { description, orderId, supportSuggestions } },
  ) => set(state, description, orderId, supportSuggestions),
});

const set = (
  state: State,
  description: string,
  orderId: number,
  supportSuggestions: SupportSuggestion[],
) => {
  state.description = description;
  state.orderId = orderId;
  state.supportSuggestions = supportSuggestions;
  return state;
};
