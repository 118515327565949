import { clsx } from 'clsx';
import { Box, Typography } from '@material-ui/core';

import CustomButton, { Colors } from '../../../common/buttons/custom-button';
import { COUNTDOWN_TIMEOUT_ORDER_IN_SEG } from '../../../../business/order-mgmt';
import { CountdownClock } from '../../components/countdown-edit/components/CountdownClock';
import { disclaimerStyles } from './disclaimer.styles';
import { useTimeoutDisclaimer, useTimeoutDisclaimerLang } from './hooks';

export function TimeoutOrderDisclaimer({ updateAt }: Props) {
  const styles = disclaimerStyles();
  const {
    handleRejectedBtn,
    isCountdownComplete,
    showCountdownTime,
    hasToppingWithSuggestions,
  } = useTimeoutDisclaimer();
  const {
    disclaimerBodyText,
    toppingDisclaimerText,
    rejectButtonText,
  } = useTimeoutDisclaimerLang({
    isCountdownComplete,
    showCountdownTime,
  });
  const disclaimerText = hasToppingWithSuggestions
    ? toppingDisclaimerText
    : disclaimerBodyText;

  return (
    <Box
      className={clsx(styles.content, styles.container)}
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      gridGap='2rem'
      padding='0.5rem 1rem'
    >
      <Typography
        className={styles.primaryText}
        dangerouslySetInnerHTML={{
          __html: disclaimerText || '',
        }}
      />
      {showCountdownTime && (
        <CountdownClock
          createdAt={updateAt}
          countDownTime={COUNTDOWN_TIMEOUT_ORDER_IN_SEG}
          isTimeoutOrder
        />
      )}
      {isCountdownComplete && (
        <CustomButton
          classes={{ submitButton: styles.btnSecondary }}
          color={Colors.SECONDARY}
          onClick={handleRejectedBtn}
          fullWidth={false}
        >
          {rejectButtonText}
        </CustomButton>
      )}
    </Box>
  );
}

interface Props {
  updateAt: string;
}
