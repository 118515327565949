enum DialogType {
  CANCEL_BY_STOCK_OUT_SUGGESTION_CONFIRMATION = 'CANCEL_BY_STOCK_OUT_SUGGESTION_CONFIRMATION',
  COOKED_ORDER_CONFIRMATION_ISSUE = 'COOKED_ORDER_CONFIRMATION_ISSUE',
  CUSTOMER_PHONE_PROXY_SESSION = 'CUSTOMER_PHONE_PROXY_SESSION',
  FAILED_PROXY_SESSION = 'FAILED_PROXY_SESSION',
  ITEMS_DEACTIVATION_STOCK_OUT_ISSUE = 'ITEMS_DEACTIVATION_STOCK_OUT_ISSUE',
  JUST_COOKED_ORDER_ISSUE = 'JUST_COOKED_ORDER_ISSUE',
  LOG_OUT = 'LOG_OUT',
  LOG_OUT_REASONS = 'LOG_OUT_REASONS',
  ORDER_DETAIL = 'ORDER_DETAIL',
  ORDER_MANAGEMENT = 'ORDER_MANAGEMENT',
  ORDER_MANAGEMENT_ITEM_SEARCH = 'ORDER_MANAGEMENT_ITEM_SEARCH',
  ORDER_MANAGEMENT_UNDO_CHANGES_DIALOG = 'ORDER_MANAGEMENT_UNDO_CHANGES_DIALOG',
  ORDER_MANAGEMENT_UNDO_SUGGESTION_DIALOG = 'ORDER_MANAGEMENT_UNDO_SUGGESTION_DIALOG',
  ORDER_MANAGEMENT_REPLACE_PRODUCT = 'ORDER_MANAGEMENT_REPLACE_PRODUCT',
  ORDER_REJECTION_BY_MOTOBOY_LACK = 'ORDER_REJECTION_BY_MOTOBOY_LACK',
  ORDER_REJECTION_BY_SATURATED_STORE = 'ORDER_REJECTION_BY_SATURATED_STORE',
  ORDER_REJECTION_BY_STOCK_OUT = 'ORDER_REJECTION_BY_STOCK_OUT',
  ORDER_REJECTION_REASONS = 'ORDER_REJECTION_REASONS',
  ORDER_WHIM = 'ORDER_WHIM',
  RT_ASSIGNED_ISSUE = 'RT_ASSIGNED_ISSUE',
  RT_LACK_ISSUE = 'RT_LACK_ISSUE',
  RT = 'RT',
  RT_RELEASE_CONFIRMATION = 'RT_RELEASE_CONFIRMATION',
  RT_REPORT_REASONS = 'RT_REPORT_REASONS',
  STOCK_OUT_ISSUE_SUGGESTION = 'STOCK_OUT_ISSUE_SUGGESTION',
  STORE_DIAGNOSTICS_DIALOG = 'STORE_DIAGNOSTICS_DIALOG',
  SUGGEST_PRODUCT_DIALOG = 'SUGGEST_PRODUCT_DIALOG',
  PRODUCT_SUGGESTION_AVAILABILITY_DIALOG = 'PRODUCT_SUGGESTION_AVAILABILITY_DIALOG',
  SUCCESSFUL_PROXY_SESSION = 'SUCCESSFUL_PROXY_SESSION',
  SUCCESSFUL_SUGGESTING_PRODUCT_DIALOG = 'SUCCESSFUL_SUGGESTING_PRODUCT_DIALOG',
  ERROR_SUGGESTING_PRODUCT_DIALOG = 'ERROR_SUGGESTING_PRODUCT_DIALOG',
  SUPPORT_REASONS = 'SUPPORT_REASONS',
  CHECK_ERROR_SERVICE_DIALOG = 'CHECK_ERROR_SERVICE_DIALOG',
  ONBOARDING_TIPS_DIALOG = 'ONBOARDING_TIPS_DIALOG',
  ONBOARDING_TOUR_DIALOG = 'ONBOARDING_TOUR_DIALOG',
  ONBOARDING_INFO_DIALOG = 'ONBOARDING_INFO_DIALOG',
  ONBOARDING_DIALOG = 'ONBOARDING_DIALOG',
  START_PM_FLOW_DIALOG = 'START_PM_FLOW_DIALOG',
  ERROR_PM_FLOW_DIALOG = 'ERROR_PM_FLOW_DIALOG',
  END_PM_FLOW_DIALOG = 'END_PM_FLOW_DIALOG',
  PHONE_PM_FLOW_DIALOG = 'PHONE_PM_FLOW_DIALOG',
  SCHEDULE_PM_FLOW_DIALOG = 'SCHEDULE_PM_FLOW_DIALOG',
  MULTI_STORE_DROP_DIALOG = 'MULTI_STORE_DROP_DIALOG',
  SINGLE_STORE_DROP_DIALOG = 'SINGLE_STORE_DROP_DIALOG',
  EXTEND_CLOSING_HOURS = 'EXTEND_CLOSING_HOURS',
  DEACTIVATED_PRODUCTS = 'DEACTIVATED_PRODUCTS',
  ORDER_MANAGEMENT_ACCEPT_ORDER = 'ORDER_MANAGEMENT_ACCEPT_ORDER',
  ORDER_MANAGEMENT_ACCEPT_ORDER_REMOVE_CHANGES = 'ORDER_MANAGEMENT_ACCEPT_ORDER_REMOVE_CHANGES',
}
export default DialogType;
