// External libs
import { createReducer } from '@reduxjs/toolkit'
// Interfaces
import { ArticleDetail, Category, State } from '../interfaces'
// Actions
import actions from '../actions'

const initialState: State = {
  categories: [],
  selectedCategoryId: '',
  isArticleLoading: false,
  selectedArticle: null,
  open: false,
  selectedSubCategoryId: '',
}

export default createReducer(initialState, {
  [actions.closeAction.type]: state => close(state),
  [actions.loadSelectedArticleAction.type]: (state, { payload: { article } }) =>
    loadSelectedArticle(state, article),
  [actions.loadDataAction.type]: (state, { payload: categories }) =>
    loadData(state, categories),

  [actions.openAction.type]: state => open(state),

  [actions.setIsArticleLoadingAction.type]: (
    state,
    { payload: { isLoading } },
  ) => setIsArticleLoading(state, isLoading),

  [actions.setSelectedCategoryIdAction.type]: (
    state,
    { payload: categoryId },
  ) => setSelectedCategoryId(state, categoryId),
  [actions.setSelectedSubCategoryIdAction.type]: (
    state,
    { payload: subCategoryId },
  ) => setSelectedSubCategoryId(state, subCategoryId),
})

const close = (state: State) => {
  state.open = false
  return state
}

const loadSelectedArticle = (state: State, selectedArticle: ArticleDetail) => {
  state.selectedArticle = selectedArticle
  return state
}

const loadData = (state: State, categories: Category[]) => {
  state.categories = categories
  return state
}

const open = (state: State) => {
  state.open = true
  return state
}

const setIsArticleLoading = (state: State, isLoading: boolean) => {
  state.isArticleLoading = isLoading
  return state
}

const setSelectedCategoryId = (state: State, categoryId: string) => {
  state.selectedCategoryId = categoryId
  state.selectedArticle = initialState.selectedArticle
  state.selectedSubCategoryId = initialState.selectedSubCategoryId
  return state
}
const setSelectedSubCategoryId = (
  state: State,
  setSelectedSubCategoryId: string,
) => {
  state.selectedSubCategoryId = setSelectedSubCategoryId
  state.selectedArticle = initialState.selectedArticle

  return state
}
