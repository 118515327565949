const cancelButtonText = {
  id: 'cancel',
}
const confirmButtonText = {
  id: 'logoutDialog.confirm',
}
const message = {
  id: 'logoutDialog.message',
}
const title = {
  id: 'logoutDialog.title',
}

const textIds = {
  cancelButtonText,
  confirmButtonText,
  message,
  title,
}

export default textIds
