import { useSelector } from 'react-redux';

import { selectors as partnerSelectors } from '../../../../../redux/partner';

const useIsPartnerIntegrated = () =>
  useSelector(partnerSelectors.selectIsPartnerIntegrated);

const hooks = {
  useIsPartnerIntegrated,
};

export default hooks;
