// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => {
  return {
    root: {
      alignItems: 'center',
      color: neutral.D15,
      display: 'flex',
      fontFamily: 'Poppins',
      fontSize: '0.875rem',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      height: '100%',
      padding: '0 1.5rem',
      textAlign: 'center',
      [theme.breakpoints.up('lg')]: {
        fontSize: '1rem',
      },
      animationName: '$fadeIn',
      animationDuration: '1s',
      animationFillMode: 'both',
    },
    '@keyframes fadeIn': {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
  };
});
