import ChromeExtensionService from '../../../../services/ChromeExtensionService';
import StringUtils from '../../../../utils/StringUtils';
import {
  AcceptedOrderRequest,
  AdditionalInfoDTO,
  RemoveToppingListDTO,
} from '../core/requests';
import { OrderDetailResponse } from '../core/responses';

export const orderDetailAdapter = {
  toAcceptTimeoutRequest,
};

async function toAcceptTimeoutRequest(
  orderDetail: OrderDetailResponse,
  products: number[],
  toppings: RemoveToppingListDTO[],
): Promise<AcceptedOrderRequest> {
  const additional_info: AdditionalInfoDTO = {
    cooking_time: {
      predictor: true,
      min: orderDetail?.predict_cooking_time?.min_cooking_time ?? 0,
      default: orderDetail?.predict_cooking_time?.default_cooking_time ?? 0,
      max: orderDetail?.predict_cooking_time?.max_cooking_time ?? 0,
    },
  };
  if (!!(await ChromeExtensionService.isAvailable())) {
    const version = await ChromeExtensionService.getVersion();
    if (!StringUtils.isNullOrEmpty(version)) {
      additional_info.chrome_extension_version = version;
    }
  }
  const payload: AcceptedOrderRequest = {
    remove_product_list: products ?? [],
    remove_topping_list: toppings,
    cooking_time:
      orderDetail?.cookingTimeToTakeOrder ||
      additional_info.cooking_time.default,
    additional_info,
  };
  return payload;
}
