import { useEffect, useState } from 'react';
import {
  orderCreationTimeStatus,
  orderTurboCase,
} from '../../../business/order/geoqueue';
import { OrderResponseFromStatusV2 } from '../../../business/shared/infra';

export function useTurboOrderMainCard(props: Props) {
  const { order } = props;
  const { isTurboqueue, createdAtStatus } = orderTurboCase({ order });
  const [orderCreationStatus, setOrderCreationStatus] = useState<
    orderCreationTimeStatus
  >(createdAtStatus);

  useEffect(() => {
    setOrderCreationStatus(createdAtStatus);
  }, [createdAtStatus]);

  return {
    isTurboqueue,
    orderCreationStatus,
  };
}

interface Props {
  order: OrderResponseFromStatusV2;
}
