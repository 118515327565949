// External libs
import { handleActions } from 'redux-actions';
// Actions
import FailedProxySessionDialogActions from '../actions/failedProxySessionDialog.actions';

const defaultOrderId = 0;

const initialState = {
  orderId: defaultOrderId,
  open: false,
};

export default handleActions(
  {
    [FailedProxySessionDialogActions.clearFailedProxySessionDialogAction]: state =>
      clearDialog(state),
    [FailedProxySessionDialogActions.closeFailedProxySessionDialogAction]: state =>
      closeDialog(state),
    [FailedProxySessionDialogActions.openFailedProxySessionDialogAction]: (
      state,
      { payload: { orderId } },
    ) => openDialog(state, orderId),
  },
  initialState,
);

const clearDialog = state => {
  return {
    ...initialState,
  };
};

const closeDialog = state => {
  return {
    ...state,
    open: false,
  };
};

const openDialog = (state, orderId) => {
  return {
    ...state,
    open: true,
    orderId: orderId || state.orderId,
  };
};
