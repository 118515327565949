import { clsx } from 'clsx';
import { useMediaQuery } from '@material-ui/core';

import { CustomKanbanColumnEmpty } from '../custom-kanban-column-empty';

import useStyles from './CustomKanbanColumn.styles';

export function CustomKanbanColumn(props) {
  const {
    classes = {},
    children = '',
    Chip = null,
    count,
    showEmpty = true,
    emptyText,
    title,
  } = props;

  const _classes = useStyles();
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  const showChip = !isMdUp && !!Chip;

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <div className={clsx(_classes.column, classes?.column)}>
        {isMdUp && (
          <div className={_classes.header}>
            <div className={_classes.title}>{title}</div>
            <div className={_classes.badgeContainer}>
              <div className={_classes.badge}>{count}</div>
            </div>
          </div>
        )}
        <div className={_classes.body}>
          {showChip && (
            <div
              className={clsx(_classes.chipContainer, classes?.chipContainer)}
            >
              {Chip}
            </div>
          )}
          {!showEmpty && (
            <div className={_classes.childrenContainer}>{children}</div>
          )}
          {showEmpty && <CustomKanbanColumnEmpty text={emptyText} />}
        </div>
      </div>
    </div>
  );
}
