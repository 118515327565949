// Consts
import urls from '../../constants/Urls';
// Utils
import UrlUtils from '../../utils/url-utils/UrlUtils';
// Services
import http from '../HttpService';
import LocalStorageService from '../local-storage-service';
// Interfaces
import { StoreScheduleResponse } from './interfaces';

const setAsSeen = (formId: number) => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(
    urls.PM_AUTOMATION_SET_AS_SEEN,
    {
      formId,
    },
  );
  return http.post<any>(`${baseUrl}${replacedUrl}`);
};
const sendPMAutomationStoreClose = (formId: number) => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(
    urls.PM_AUTOMATION_STORE_SKIP_CLOSE,
    {
      formId,
    },
  );
  return http.post<any>(`${baseUrl}${replacedUrl}`);
};
const sendPMAutomationStoreCompleteClose = (formId: number) => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(
    urls.PM_AUTOMATION_STORE_COMPLETE_CLOSE,
    {
      formId,
    },
  );
  return http.post<any>(`${baseUrl}${replacedUrl}`);
};

const setStoreErrorPMForm = (
  formId: number,
  errorCode: string,
  errorMessage: string,
) => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(
    urls.PM_AUTOMATION_STORE_ERROR_CLOSE,
    {
      formId,
    },
  );
  const payload = {
    error_code: errorCode,
    message: errorMessage,
  };
  return http.post<any>(`${baseUrl}${replacedUrl}`, payload);
};

const sendPMAutomationStorePhone = (formId: number, phone: string) => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(
    urls.PM_AUTOMATION_STORE_PHONE_CONTACT,
    {
      formId,
    },
  );
  const payload = {
    phone: phone,
  };
  return http.put<any>(`${baseUrl}${replacedUrl}`, payload);
};

const getPMAutomationStorePhone = (formId: number) => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(
    urls.GET_PM_AUTOMATION_STORE_PHONE_CONTACT,
    {
      formId,
    },
  );
  return http.get<any>(`${baseUrl}${replacedUrl}`);
};

const getPMAutomationStoreSchedule = (formId: number) => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(
    urls.GET_PM_AUTOMATION_STORE_SCHEDULE,
    {
      formId,
    },
  );
  return http.get<StoreScheduleResponse>(`${baseUrl}${replacedUrl}`);
};
const deleteDaySchedule = (formId: number, intervalId: number) => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(
    urls.DELETE_DAY_STORE_SCHEDULE,
    {
      formId,
      intervalId,
    },
  );
  return http.delete<any>(`${baseUrl}${replacedUrl}`);
};
const updateDaySchedule = (
  intervalId: number,
  formId: number,
  startsTime: string,
  endsTime: string,
) => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(
    urls.UPDATE_DAYS_STORE_SCHEDULE,
    {
      intervalId,
      formId,
    },
  );
  const payload = {
    starts_time: `${startsTime}`,
    ends_time: `${endsTime}`,
  };
  return http.put<any>(`${baseUrl}${replacedUrl}`, payload);
};
const createDaySchedule = (
  formId: number,
  day: string,
  startsTime: string,
  endsTime: string,
) => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(
    urls.CREATE_DAYS_STORE_SCHEDULE,
    {
      formId,
    },
  );
  const payload = {
    day: day,
    starts_time: `${startsTime}`,
    ends_time: `${endsTime}`,
  };
  return http.post<any>(`${baseUrl}${replacedUrl}`, payload);
};

const PMAutomationService = {
  setAsSeen,
  sendPMAutomationStoreClose,
  sendPMAutomationStoreCompleteClose,
  setStoreErrorPMForm,
  sendPMAutomationStorePhone,
  getPMAutomationStorePhone,
  getPMAutomationStoreSchedule,
  deleteDaySchedule,
  updateDaySchedule,
  createDaySchedule,
};

export default PMAutomationService;
