import type { OrderResponseFromStatusV2 } from '../../../business/shared/infra/core';

interface OrdersWithSameBundleIdAndStoreParams {
  order: OrderResponseFromStatusV2;
  allOrders: OrderResponseFromStatusV2[];
}

export function ordersWithSameBundleIdAndStore(
  params: OrdersWithSameBundleIdAndStoreParams,
): OrderResponseFromStatusV2[] {
  const { order, allOrders = [] } = params;
  if (!order) return [];
  return (
    allOrders.filter(
      item =>
        Boolean(item?.bundle_id) &&
        item.bundle_id === order.bundle_id &&
        item.store_id === order.store_id &&
        item.id !== order.id,
    ) ?? []
  );
}
