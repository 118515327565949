import { IDrawerButtonProps, IKeyboardDatePickerProps } from './types'

export const NO_IMAGE = 'NO-IMAGE'
export const MAX_DAYS_TO_DEACTIVATE_ITEMS = 30

export const KEYBOARD_DATE_PICKER_PROPS: IKeyboardDatePickerProps = {
  variant: 'dialog',
  format: 'dd/MM/yyyy',
  margin: 'normal',
  id: 'date-picker-inline',
  label: '',
  KeyboardButtonProps: {
    'aria-label': 'change date',
    style: {
      padding: '0px',
    },
  },
  TextFieldType: 'hidden',
}

export const CANCEL_BUTTON_PROPS: IDrawerButtonProps = {
  variant: 'outlined',
}

export const TURNOFF_BUTTON_PROPS: IDrawerButtonProps = {
  variant: 'contained',
}

export const RADIO_GROUP_NAME = 'deactivation-options-group'
export const CUSTOM_OPTION_INDEX = 1
