import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../../../../shared/styles/colors';

export default makeStyles(theme => ({
  detailButtonRoot: {
    justifyContent: 'flex-end',
    padding: '8px 16px',
  },
  detailHeader: {
    alignItems: 'center',
    display: 'grid',
    gridAutoFlow: 'column',
    columnGap: '0.25rem',
    justifyContent: 'end',
  },
  detailHeaderIconRoot: {
    height: 18,
    width: 18,
  },
  detailHeaderLabel: {
    color: neutral['50'],
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  detailsListContainer: {
    display: 'grid',
    padding: '12px 16px 18px 16px',
    gridRowGap: 10,
    maxHeight: 250,
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {
      maxHeight: 230,
    },
  },
  root: {
    boxShadow: `0 -1px 0 0 ${neutral['20']}`,
    color: neutral['90'],
    lineHeight: 'normal',
  },
  totalContainer: ({ hasDetail }: { hasDetail: boolean }) => ({
    paddingTop: hasDetail ? '2px' : '1.25rem',
    paddingRight: '1rem',
    paddingBottom: '1.25rem',
    paddingLeft: '1rem',
  }),
}));
