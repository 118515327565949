import { makeStyles } from '@material-ui/core';
import Colors from '../../../../constants/Colors';
import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(() => ({
  root: {
    alignItems: 'center',
    background: Colors.WHITE,
    boxShadow: `0 -1px 00 ${neutral.D10}`,
    display: 'flex',
    gap: '1rem',
    justifyContent: 'flex-end',
  },
  newChipContainer: {
    borderRadius: '0.875rem',
    backgroundColor: Colors.DODGER_BLUE,
    color: Colors.WHITE,
    fontWeight: 600,
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    position: 'absolute',
    top: '-0.75rem',
    zIndex: 1,
  },
}));
