// Consts
import urls from '../../constants/Urls';
// Services
import http from '../HttpService';
import LocalStorageService from '../local-storage-service';

const createProxySession = (orderId, phone, deliveryMethod) => {
  const baseUrl = LocalStorageService.getBaseUrl();

  const payload = {
    order_id: orderId,
    source_phone: phone,
    order_type: deliveryMethod,
  };

  return http.post(
    `${baseUrl}${urls.SUPPORT_ACTIONS_PHONE_PROXY_SESSION}`,
    payload,
  );
};

export default {
  createProxySession,
};
