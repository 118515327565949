import {
  addRTSliceConfigCombineReducer,
  cargoSliceName,
  cargoSliceReducer,
} from './index';

export const CARGO_SLICE_COMBINE = {
  cargo: {
    name: cargoSliceName,
    reducer: cargoSliceReducer,
  },
  addRT: addRTSliceConfigCombineReducer,
} as const;
