import { clsx } from 'clsx';
import { Box, Typography } from '@material-ui/core';

import { orderDetailDisclaimer } from '../orderDetailDisclaimer.styles';
import { useOrderDetailDisclaimerLang } from '../hooks';

export function ToWaitDisclaimer() {
  const styles = orderDetailDisclaimer();
  const {
    userIsCheckingSuggestionChangesText,
  } = useOrderDetailDisclaimerLang();
  return (
    <Box className={clsx(styles.content, styles.container)}>
      <Box
        flex={1}
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        padding='0.5rem 1rem'
      >
        <Typography
          className={styles.primaryText}
          dangerouslySetInnerHTML={{
            __html: userIsCheckingSuggestionChangesText || '',
          }}
        />
      </Box>
    </Box>
  );
}
