import Urls from '../../../../constants/Urls';
import HttpService from '../../../../services/HttpService';
import type { StoreStatusDetailResponse } from '../../../../services/partners-service';
import { PartnerId } from '../../../shared/domain';
import { generateURLRappiServices } from '../../../shared/infra';
import { AdditionalStoreInfo } from '../../domain';
import {
  ADDITIONAL_STORES_INFO,
  ALEATORY_DAY_IN_PAST,
  HIGHDEMAND_PATH,
  LAST_UPDATE_ADD_STORES_INFO,
} from '../core';
import type { ProfileExtendedResponse } from '../core/responses';

function getStoreStatusDetail(partnerId: PartnerId) {
  const _url = generateURLRappiServices({
    url: Urls.GET_STORE_STATUS_DETAIL,
    pathParams: { partnerId },
  });
  return HttpService.get<StoreStatusDetailResponse>(_url);
}

function getProfileBackground(partnerId: PartnerId, partnerStoreIds: number[]) {
  const _url = generateURLRappiServices({
    url: HIGHDEMAND_PATH.PROFILE_BACKGROUND,
    pathParams: { partnerId },
  });
  return HttpService.post<ProfileExtendedResponse>(_url, {
    store_ids: partnerStoreIds,
  });
}

function getLastUpdate(): string {
  return (
    localStorage.getItem(LAST_UPDATE_ADD_STORES_INFO) || ALEATORY_DAY_IN_PAST
  );
}
function setLastUpdateByNow() {
  localStorage.setItem(LAST_UPDATE_ADD_STORES_INFO, new Date().toISOString());
}
function getProfileBackgroundLocal() {
  const parsedItem =
    JSON.parse(localStorage.getItem(ADDITIONAL_STORES_INFO) || '') ?? [];
  return parsedItem as AdditionalStoreInfo[];
}
function setProfileBackgroundLocal(data: AdditionalStoreInfo[]) {
  localStorage.setItem(ADDITIONAL_STORES_INFO, JSON.stringify(data));
}
export const operationalStateTurboRepository = {
  getStoreStatusDetail,
  getProfileBackground,
  getLastUpdate,
  setLastUpdateByNow,
  getProfileBackgroundLocal,
  setProfileBackgroundLocal,
};
