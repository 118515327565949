import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';

import { useLang } from './useLang';
import { PWEXUtils } from '../../../../../exceptions';
import StringUtils from '../../../../../utils/StringUtils';
import AppActions from '../../../../../redux/actions/app-actions';
import rootActions from '../../../../../redux/actions/root.actions';
import CountriesService from '../../../../../services/CountriesService';
import EventActions from '../../../../../redux/actions/event-actions/event.actions';
import LocalStorageService from '../../../../../services/local-storage-service/LocalStorageService';

import type { AvailableCountry } from '../../../../../interfaces';

export function useForm({ handleShowForm }: Params) {
  const [availableCountries, setAvailableCountries] = useState<
    AvailableCountry[]
  >([]);
  const [didFetchingFail, setDidFetchingFail] = useState<boolean>(false);
  const [email, setEmail] = useState<string | null>(null);
  const [helperText, setHelperText] = useState<string | null>(null);
  const [isCountrySelectorLoading, setIsCountrySelectorLoading] = useState<
    boolean
  >(false);
  const [password, setPassword] = useState<string | null>(null);
  const [remember, setRemember] = useState<boolean>(false);
  const [
    selectedCountry,
    setSelectedCountry,
  ] = useState<AvailableCountry | null>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const dispatch = useDispatch();

  const lang = useLang();

  const getAllAvailableCountries = async () => {
    setIsCountrySelectorLoading(true);
    try {
      const countries = await CountriesService.getAllAvailable();
      setAvailableCountries(countries);
    } catch (ex) {
      dispatch(AppActions.handleCatch(ex, true, false));
      const exMessage = PWEXUtils.getMessage(ex);
      setDidFetchingFail(true);
      setHelperText(exMessage);
    }
    setIsCountrySelectorLoading(false);
  };

  const resolveCountry = async (countryToResolve: any) => {
    // TODO: I don't understand why this is necessary, this code is not clear
    // CountryToResolve cannot be used from useState, due to resolveCountry(useCallback) => handleCountrySelectorChange(useCallback)
    setIsCountrySelectorLoading(true);
    try {
      const lat = countryToResolve?.lat ?? null;
      const lng = countryToResolve?.lng ?? null;
      if (!lat || !lng) {
        return;
      }
      const response = await CountriesService.resolveCountry(lat, lng);
      LocalStorageService.setCountry(response.data);
      LocalStorageService.setLatAndLng(lat, lng);
      setDidFetchingFail(false);
      setHelperText(null);
    } catch (ex) {
      dispatch(AppActions.handleCatch(ex, true, false));
      const exMessage = PWEXUtils.getMessage(ex);
      setDidFetchingFail(true);
      setHelperText(exMessage);
    }
    setIsCountrySelectorLoading(false);
  };

  const mustInputsBeDisabled = useMemo(() => {
    return didFetchingFail || isCountrySelectorLoading || !selectedCountry;
  }, [didFetchingFail, isCountrySelectorLoading, selectedCountry]);

  const mustSubmitButtonBeDisabled = useMemo(() => {
    return (
      mustInputsBeDisabled ||
      StringUtils.isNullOrEmpty(email) ||
      StringUtils.isNullOrEmpty(password)
    );
  }, [mustInputsBeDisabled, email, password]);

  const handleCountrySelectorChange = async (event: any) => {
    const selectedCode = event.target.value;
    const foundCountry = availableCountries.find(
      availableCountry => availableCountry.code === selectedCode,
    );
    if (!foundCountry) {
      return;
    }
    setSelectedCountry(foundCountry);
    setEmail(null);
    setPassword(null);
    await resolveCountry(foundCountry);
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleEmailChange = (event: any) => {
    setEmail(event.currentTarget.value);
  };

  const handlePasswordChange = (event: any) => {
    setPassword(event.currentTarget.value);
  };

  const handleRemember = () => setRemember(!remember);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    try {
      dispatch(rootActions.login(email, password));
    } catch (ex) {
      dispatch(AppActions.handleCatch(ex, true, false));
    }
  };

  const handleClickShowPasswordPath = () => handleShowForm(false);

  const handleCreateNowAccount = () => {
    EventActions.onLoginScreenSelection('self_onboarding');
    window.open(process.env.REACT_APP_NEW_ACCOUNT, '_blank');
  };

  useEffect(() => {
    getAllAvailableCountries().then();
  }, []);

  return {
    lang,
    availableCountries,
    didFetchingFail,
    email,
    helperText,
    isCountrySelectorLoading,
    mustInputsBeDisabled,
    mustSubmitButtonBeDisabled,
    password,
    remember,
    selectedCountry,
    showPassword,
    handleCountrySelectorChange,
    handleClickShowPassword,
    handleEmailChange,
    handlePasswordChange,
    handleRemember,
    handleSubmit,
    handleClickShowPasswordPath,
    handleCreateNowAccount,
  };
}

interface Params {
  handleShowForm: (show: boolean) => void;
}
