import { subscribeEvent, publishEvent, unsubscribeEvent } from '../../libs';

function dialog() {
  const EVENT_NAME = 'BROWSER_INSTALL_DIALOG';
  let theListener: ListenerFN = _ => undefined;
  return {
    dispatch: () => publishEvent(EVENT_NAME),
    remove: () => unsubscribeEvent(EVENT_NAME, theListener),
    add: (lt: ListenerFN) => {
      theListener = lt;
      subscribeEvent(EVENT_NAME, theListener);
    },
  };
}

export const browserDialog = {
  call: dialog(),
};

type ListenerFN = (event: EventStoreSuspended) => void;

type EventStoreSuspended = CustomEvent;
