import { useSelector } from 'react-redux';

import { turboHelper } from '../../../business/order/geoqueue';
import { ordersKanbanSelector } from '../../../redux/selectors/orders-kanban';
import { usePendingToDeliverColumnLang } from './usePendingToDeliverColumnLang';

export function usePendingToDeliverColumn() {
  const amountInToDeliver = useSelector(ordersKanbanSelector.amountInToDeliver);
  const _ordersToDeliverOnePerBundle = useSelector(
    ordersKanbanSelector.inToDeliverOnlyOnePerBundle,
  );

  const showEmptyTextOfOrders = amountInToDeliver === 0;

  const text = usePendingToDeliverColumnLang();

  const ordersToDeliverOnePerBundle = turboHelper.filterV2([..._ordersToDeliverOnePerBundle])
  
  return {
    text,
    ordersToDeliverOnePerBundle,
    showEmptyTextOfOrders,
    numberOfOrdersInToDeliver: amountInToDeliver,
  };
}
