import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../../shared/styles/colors';
import { VARIANT } from '../../shared/consts';

import type { SaturationStateProp } from '../../shared/models';

export default makeStyles({
  chip: ({ variant }: SaturationStateProp) => ({
    padding: '2px 0.5rem',
    fontWeight: 600,
    fontSize: '0.75rem',
    lineHeight: 1.7,
    borderRadius: '0.5rem',
    color: VARIANT[variant].color,
    backgroundColor: VARIANT[variant].bgColor,
    cursor: 'inherit',
    '& > span': {
      padding: 0,
    },
  }),
  tooltip: {
    width: 'fit-content',
    padding: '0.5rem',
    fontFamily: '"Poppins", Georgia, sans-serif',
    fontSize: '0.75rem',
    fontWeight: 'normal',
    lineHeight: '1.25rem',
    borderRadius: '0.5rem',
    color: neutral.white,
    backgroundColor: neutral['90'],
  },
});
