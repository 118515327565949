import { useState } from 'react';

import Urls from '../../../constants/Urls';
import http from '../../../services/HttpService';
import UrlUtils from '../../../utils/url-utils/UrlUtils';
import LocalStorageService from '../../../services/local-storage-service/LocalStorageService';

import type { StoreId } from '../models';

export function useFetchDesuspend() {
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const clearState = () => {
    setIsError(false);
    setIsSuccess(false);
  };

  const liftingSuspension = ({ storeId = NaN }: StoreId) => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
    const baseUrl = LocalStorageService.getBaseUrl();
    const uri = UrlUtils.replaceUrlParams(
      Urls.LIFTING_SUSPENSION_STATUS_BY_STORE,
      { storeId },
    );
    setTimeout(() => {
      let localIsSuccess = false;
      http
        .post(`${baseUrl}${uri}`)
        .then(() => (localIsSuccess = true))
        .catch(() => (localIsSuccess = false))
        .finally(() =>
          setTimeout(() => {
            setIsLoading(false);
            if (localIsSuccess) setIsSuccess(true);
            else setIsError(true);
          }, 4_000),
        );
    }, 300);
  };

  return {
    isError,
    isSuccess,
    isLoading,
    clearState,
    liftingSuspension,
  };
}
