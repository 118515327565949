// React
import React from 'react'
import { PropTypes } from 'prop-types'
// Components
import CustomInAppDialog from '../common/custom-in-app-dialog'
// Const
import { Serverities } from '../../constants/Severities'

const CheckNetworkDialog = ({
  buttonText,
  description,
  onClickAccept,
  onClose,
  open,
  title,
}) => {
  return (
    <CustomInAppDialog
      buttonText={buttonText}
      description={description}
      onClick={onClickAccept}
      onClose={onClose}
      open={open}
      title={title}
      type={Serverities.ERROR}
      showCloseButton
      showMainButton
    />
  )
}

CheckNetworkDialog.defaultProps = {
  buttonText: '',
  onClickAccept: () => {},
  onClose: () => {},
  open: false,
}

CheckNetworkDialog.propTypes = {
  buttonText: PropTypes.string,
  onClickAccept: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
}

export default CheckNetworkDialog
