// External libs
import { get } from 'lodash';
// Services
import AudioPlayerService from './AudioPlayerService';
// Redux
import appStore from '../redux/stores/app.store';
// Interface
import { partnerStateType } from '../constants/PartnerStateType';
import { getCacheSomeStoreIsNotAlerted } from '../components/store-suspension-alert';

// Chrome extentions actions types.
const GET_MANIFEST = 'GET_MANIFEST';
const NEW_ORDER_ACTION = 'NEW_ORDER_ACTION';
const PAUSE_AUDIO_ACTION = 'PAUSE_AUDIO_ACTION';
const PING_ACTION = 'PING_ACTION';
const PLAY_CANCELED_ORDER_AUDIO_ACTION = 'PLAY_CANCELED_ORDER_AUDIO_ACTION';
const PLAY_NEW_ORDER_AUDIO_ACTION = 'PLAY_NEW_ORDER_AUDIO_ACTION';
const PLAY_OFFLINE_AUDIO_ACTION = 'PLAY_OFFLINE_AUDIO_ACTION';
const SET_AMPLITUDE_USER = 'SET_AMPLITUDE_USER';
const SET_AMPLITUDE_ANONYMOUS_USER = 'SET_AMPLITUDE_ANONYMOUS_USER';

const extensionId = process.env.REACT_APP_CHROME_EXTENSION_ID;

const newOrderAction = (
  newOrdersTooltip,
  newOrdersTotal,
  orderId,
  partnerId,
) => ({
  type: NEW_ORDER_ACTION,
  newOrdersTooltip,
  newOrdersTotal,
  orderId,
  partnerId,
});
const getManifestAction = {
  type: GET_MANIFEST,
};
const pauseAudioAction = {
  type: PAUSE_AUDIO_ACTION,
};
const pingAction = {
  type: PING_ACTION,
};
const playCanceledOrderAudioAction = {
  type: PLAY_CANCELED_ORDER_AUDIO_ACTION,
};
const playNewOrderAudioAction = {
  type: PLAY_NEW_ORDER_AUDIO_ACTION,
};
const playOfflineAudioAction = partnerId => ({
  type: PLAY_OFFLINE_AUDIO_ACTION,
  partnerId,
});

const setAmplitudeAnonymousUserAction = {
  type: SET_AMPLITUDE_ANONYMOUS_USER,
};

const setAmplitudeUserAction = (countryCode, partnerId) => ({
  type: SET_AMPLITUDE_USER,
  countryCode,
  partnerId,
});

const getManifest = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const isCEAvailable = await isAvailable();
      if (!isCEAvailable) {
        resolve(null);
        return;
      }
      window.chrome.runtime.sendMessage(
        extensionId,
        getManifestAction,
        response => {
          if (!response) {
            console.error(
              `Get manifest action hasn't returned a response`,
              response,
            );
            resolve(null);
            return;
          }
          if (!response.success) {
            console.error(`Get manifest action returned "${response.result}"`);
            resolve(null);
            return;
          }
          resolve(response.result);
        },
      );
    } catch (ex) {
      console.error('Something went wrong getting manifest', ex);

      resolve(null);
      return;
    }
  });
};

const getVersion = async () => {
  const manifest = await getManifest();
  return get(manifest, 'version', null);
};

const isAvailable = () => {
  return new Promise((resolve, reject) => {
    try {
      if (
        !window.chrome ||
        !window.chrome.runtime ||
        !window.chrome.runtime.sendMessage
      ) {
        // Log this controlled error as log to avoid noise.
        console.log('No chrome utility was found in window object.');
        resolve(false);
        return;
      }

      if (!extensionId || extensionId === '') {
        // Log this controlled error as log to avoid noise.
        console.log('No extension Id was specified');
        resolve(false);
        return;
      }
      window.chrome.runtime.sendMessage(extensionId, pingAction, response => {
        if (window.chrome.runtime.lastError) {
          // Log this controlled error as log to avoid noise.
          console.log('Ping failed', window.chrome.runtime.lastError);
          resolve(false);
          return;
        }
        if (!response) {
          // Log this controlled error as log to avoid noise.
          console.log(`Ping hasn't returned a response`, response);
          resolve(false);
          return;
        }
        if (!response.success) {
          // Log this controlled error as log to avoid noise.
          console.log(`Ping returned "${response.result}"`);
          resolve(response.success);
          return;
        }
        resolve(response.success);
        return;
      });
    } catch (ex) {
      if (
        !!ex.message &&
        ex.message.indexOf(`Invalid extension id: '${extensionId}'`) > -1
      ) {
        console.log(`Invalid extension id: '${extensionId}'`);
        resolve(false);
        return;
      }
      // Log this controlled error as log to avoid noise.
      console.log('Something went wrong checking availability', ex);
      resolve(false);
      return;
    }
  });
};

const newOrder = async (newOrdersTooltip, newOrdersTotal, orderId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const isCEAvailable = await isAvailable();
      const isOrderMgmtDialogOpened = appStore.isOrderMgmtDialogOpened();

      if (!isCEAvailable) {
        // If CE is not available, play the audio locally.
        if (newOrdersTotal > 0 && !isOrderMgmtDialogOpened)
          AudioPlayerService.playNewOrderAudio();
        resolve();
        return;
      }
      const partnerId = appStore.getPartnerId();
      const action = newOrderAction(
        newOrdersTooltip,
        newOrdersTotal,
        orderId,
        partnerId,
      );
      window.chrome.runtime.sendMessage(extensionId, action, response => {
        if (!response) {
          console.error(
            `New order action hasn't returned a response`,
            response,
          );
          resolve();
          return;
        }
        if (!response.success) {
          console.error(`New order returned "${response.result}"`);
          resolve();
          return;
        }
        resolve();
      });
    } catch (ex) {
      console.error('Something went wrong executing new order', ex);
      // If something goes wrong, play the audio locally.
      if (newOrdersTotal > 0) AudioPlayerService.playNewOrderAudio();
      // Just resolve, don't throw exceptions
      resolve();
    }
  });
};

const pauseAudio = async () => {
  return new Promise(async (resolve, reject) => {
    console.log('Pausing Audio.');
    // By security, always pause the audio player.
    AudioPlayerService.pauseAudio();
    try {
      const isCEAvailable = await isAvailable();
      if (!isCEAvailable) {
        resolve();
        return;
      }
      window.chrome.runtime.sendMessage(
        extensionId,
        pauseAudioAction,
        response => {
          if (!response) {
            console.error(
              `Pause audio action hasn't returned a response`,
              response,
            );
            resolve();
            return;
          }
          if (!response.success) {
            console.error(`Pause audio returned "${response.result}"`);
            resolve();
            return;
          }
          resolve();
        },
      );
    } catch (ex) {
      console.error('Something went wrong pausing audio', ex);
      // Just resolve, don't throw exceptions
      resolve();
    }
  });
};

const playCanceledOrderAudio = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const isCEAvailable = await isAvailable();
      if (!isCEAvailable) {
        // If CE is not available, play the audio locally.
        AudioPlayerService.playCanceledOrderAudio();
        resolve();
        return;
      }
      window.chrome.runtime.sendMessage(
        extensionId,
        playCanceledOrderAudioAction,
        response => {
          if (!response) {
            console.error(
              `Play canceled audio action hasn't returned a response`,
              response,
            );
            resolve();
            return;
          }
          if (!response.success) {
            console.error(`Play canceled audio returned "${response.result}"`);
            resolve();
            return;
          }
          resolve();
        },
      );
    } catch (ex) {
      console.error('Something went wrong playing canceled audio', ex);
      AudioPlayerService.playCanceledOrderAudio();
      resolve();
      return;
    }
  });
};

const playNewOrderAudio = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const isCEAvailable = await isAvailable();
      if (!isCEAvailable) {
        // If CE is not available, play the audio locally.
        AudioPlayerService.playNewOrderAudio();
        resolve();
        return;
      }
      window.chrome.runtime.sendMessage(
        extensionId,
        playNewOrderAudioAction,
        response => {
          if (!response) {
            console.error(
              `Play new order audio action hasn't returned a response`,
              response,
            );
            resolve();
            return;
          }
          if (!response.success) {
            console.error(`Play new order audio returned "${response.result}"`);
            resolve();
            return;
          }
          resolve();
        },
      );
    } catch (ex) {
      console.error('Something went wrong playing new order audio', ex);
      AudioPlayerService.playNewOrderAudio();
      resolve();
      return;
    }
  });
};

const playOfflineAudio = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const isCEAvailable = await isAvailable();
      if (!isCEAvailable) {
        // If CE is not available, play the audio locally.
        AudioPlayerService.playOfflineAudio();
        resolve();
        return;
      }
      const partnerId = appStore.getPartnerId();
      const playOfflineAudio = playOfflineAudioAction(partnerId);
      window.chrome.runtime.sendMessage(
        extensionId,
        playOfflineAudio,
        response => {
          if (!response) {
            console.error(
              `Play offline audio action hasn't returned a response`,
              response,
            );
            resolve();
            return;
          }
          if (!response.success) {
            console.error(`Play offline audio returned "${response.result}"`);
            resolve();
            return;
          }
          resolve();
        },
      );
    } catch (ex) {
      console.error('Something went wrong playing offline audio', ex);
      AudioPlayerService.playOfflineAudio();
      resolve();
      return;
    }
  });
};

const playNotificationRTSound = () => {
  AudioPlayerService.playNotificationRTSound();
};

const setAmplitudeAnonymousUser = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const isCEAvailable = await isAvailable();
      if (!isCEAvailable) {
        resolve();
        return;
      }
      window.chrome.runtime.sendMessage(
        extensionId,
        setAmplitudeAnonymousUserAction,
        response => {
          if (!response) {
            console.error(
              `Set amplitude anonymous user action hasn't returned a response`,
              response,
            );
            resolve();
            return;
          }
          if (!response.success) {
            console.error(
              `Set amplitude anonymous user action returned "${response.result}"`,
            );
            resolve();
            return;
          }
          resolve();
        },
      );
    } catch (ex) {
      console.error(
        'Something went wrong settting amplitude anonymous user',
        ex,
      );
      resolve();
      return;
    }
  });
};

const setAmplitudeUser = (countryCode, partnerId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const isCEAvailable = await isAvailable();
      if (!isCEAvailable) {
        resolve();
        return;
      }
      window.chrome.runtime.sendMessage(
        extensionId,
        setAmplitudeUserAction(countryCode, partnerId),
        response => {
          if (!response) {
            console.error(
              `Set amplitude user action hasn't returned a response`,
              response,
            );
            resolve();
            return;
          }
          if (!response.success) {
            console.error(
              `Set amplitude user action returned "${response.result}"`,
            );
            resolve();
            return;
          }
          resolve();
        },
      );
    } catch (ex) {
      console.error('Something went wrong settting amplitude user', ex);
      resolve();
      return;
    }
  });
};

async function playNotifySuspendedStore() {
  return await playOfflineAudio();
}

const updateAudio = async () => {
  const isOffline = appStore.getIsOffline();
  const partnerState = appStore.getPartnerState();
  const isOrderMgmtDialogOpened = appStore.isOrderMgmtDialogOpened();

  if (isOffline && partnerState !== partnerStateType.INACTIVE) {
    // If the app is offline, play offline audio;
    console.log('Playing Offline Audio.');
    await playOfflineAudio();
    return;
  }
  const dynInAppShouldSound = appStore.getDynInAppDialogShouldSound();
  if (dynInAppShouldSound) {
    console.log('Playing Offline Audio because of partner deactivation.');
    await playOfflineAudio();
    return;
  }

  const isPartnerActivationReminderDialogOpened = appStore.getIsPartnerActivationReminderDialogOpened();
  if (isPartnerActivationReminderDialogOpened) {
    console.log(
      'Playing new-order audio because it is time to activate the partner.',
    );
    await playNewOrderAudio();
    return;
  }

  const newOrders = appStore
    .getBeingManagedNewOrders()
    .filter(order => !order?.state_order_management);
  console.log(
    `Total new orders before determine if new order audio must be played: ${newOrders.length}`,
  );
  if (newOrders.length > 0 && !isOrderMgmtDialogOpened) {
    console.log(
      `Playing new-order audio. Total new orders: ${newOrders.length}`,
    );
    await playNewOrderAudio();
    return;
  }

  const beingManagedCanceledOrders = appStore.getBeingManagedCanceledOrders();
  if (beingManagedCanceledOrders.length > 0) {
    console.log(
      `Playing canceled audio. Total new orders: ${beingManagedCanceledOrders.length}`,
    );
    // If there are being managed orders, play canceled audio.
    await playCanceledOrderAudio();
    return;
  }

  if (getCacheSomeStoreIsNotAlerted()) return;

  // If no conditions were fullfiled, by default and by security pause the audio.
  await pauseAudio();
};

const playOrderMgmtNewOrderSound = () => {
  AudioPlayerService.playOrderMgmtNewOrderSound();
};

export default {
  getVersion,
  isAvailable,
  newOrder,
  playCanceledOrderAudio,
  playNewOrderAudio,
  playNotificationRTSound,
  playOfflineAudio,
  pauseAudio,
  setAmplitudeAnonymousUser,
  setAmplitudeUser,
  playNotifySuspendedStore,
  updateAudio,
  playOrderMgmtNewOrderSound,
};
