import { useState, useCallback, useEffect } from 'react';

import CustomTextField from '../common/custom-text-field/CustomTextField';
import CustomDialog from '../common/custom-dialog';

import useStyles from './OrderWhimDialog.style';
import StringUtils from '../../utils/StringUtils';

function OrderWhimDialog(props) {
  const {
    clearErrorText,
    comment,
    confirm,
    nameInputLabel,
    inputErrorName,
    onBack,
    onClose,
    onConfirm,
    open,
    priceError,
    priceInputLabel,
    productName,
    title,
  } = props;

  const minNameLength = 3;

  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [isNameValid, setIsNameValid] = useState(false);
  const [hasInputChanged, setHasInputChanged] = useState(false);

  const componentClasses = useStyles();

  const handleClickConfirm = useCallback(async () => {
    await onConfirm(Number(price), `${productName}: ${name}`);
  }, [onConfirm, price, productName, name]);

  const handleNameValidation = useCallback(
    name => {
      const isValid =
        !StringUtils.isNullOrEmpty(name) && name.length >= minNameLength;
      setIsNameValid(isValid);
      if (!isValid) {
        clearErrorText();
      }
    },
    [clearErrorText],
  );

  useEffect(() => {
    handleNameValidation(name);
  }, [name, handleNameValidation]);

  useEffect(() => {
    setHasInputChanged(false);
    setName(null);
    setPrice(null);
  }, [open]);

  const handleBack = useCallback(() => {
    setName(null);
    setPrice(null);
    onBack();
  }, [onBack, setName, setPrice]);

  const handleClose = useCallback(() => {
    setName(null);
    setPrice(null);
    onClose();
  }, [onClose, setName, setPrice]);

  return (
    <CustomDialog
      classes={{
        headerRoot: componentClasses.headerRoot,
        footerRoot: componentClasses.footerRoot,
        paper: componentClasses.paper,
      }}
      actions={[
        {
          text: confirm,
          onClick: handleClickConfirm,
          color: 'primary',
          variant: 'contained',
          disabled: !price || !isNameValid,
          fullWidth: false,
        },
      ]}
      onClickBackButton={handleBack}
      onClose={handleClose}
      open={open}
      showBackButton
      showCloseButton
      title={title}
    >
      <div className={componentClasses.Container}>
        <div className={componentClasses.increseReasonText}>
          {nameInputLabel}
        </div>
        <CustomTextField
          placeholder={comment}
          classes={{ textFieldRoot: componentClasses.textarea }}
          name='input'
          minLength={3}
          maxLength={40}
          helperText={hasInputChanged && !isNameValid && inputErrorName}
          error={hasInputChanged && !isNameValid}
          onChange={event => {
            const eventValue = event?.currentTarget.value;
            const replacedEventValue = eventValue.replace(
              /[0-9]|,|-|_|ñ|´|' '|\+|\.|\{|\(|\)|\}|\?|¿|'|!|#|\$|°|"|\/|%|&|=|á|é|ú|ó|í|\*/gi,
              '',
            );
            setHasInputChanged(true);
            setName(replacedEventValue);
          }}
          value={name}
        />
        <div className={componentClasses.priceReasonText}>
          {priceInputLabel}
        </div>
        <CustomTextField
          value={price}
          maxLength='6'
          helperText={priceError}
          error={!!priceError}
          onChange={event => {
            const eventValue = event.currentTarget.value;
            const replacedEventValue = eventValue.replace(
              /[a-z]|,|-|_|ñ|´|' '|\+|\.|\{|\(|\)| |\}|\?|¿|'|!|#|\$|\/|°|"|%|&|=|á|é|ú|ó|í|\*/gi,
              '',
            );
            setPrice(replacedEventValue);
          }}
          startAdornment={
            <span className={componentClasses.dollarIcon}>$</span>
          }
        />
      </div>
    </CustomDialog>
  );
}

export default OrderWhimDialog;
