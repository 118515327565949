// React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Actions
import settingsActions from '../../redux/actions/settings.actions'
// Components
import AutomaticCookingTimeSetting from './AutomaticCookingTimeSetting'

const mapStateToProps = (
  { settingsReducer: { isAutoAcceptActive: isActive } },
  { intl: { formatMessage } },
) => {
  const description = formatMessage({
    id: 'automaticCookingTimeSetting.description',
  })
  const title = formatMessage({
    id: 'automaticCookingTimeSetting.title',
  })

  return {
    description,
    isActive,
    title,
  }
}
const mapDispatchToProps = {
  onChangeSwitch: settingsActions.setAutoAccept,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(AutomaticCookingTimeSetting),
)
