// External libs
import { remove } from 'lodash';
import { handleActions } from 'redux-actions';
// Consts
import { StoreSchedulesType } from '../../constants/StoreScheduleType';
// Utils
import StoreSchedulesUtils from '../../utils/StoreSchedulesUtils';
// Actions
import StoresSchedulesActions from '../actions/storesSchedules.actions';

const initialState = {
  areStoresSchedulesLoading: false,
  beingEditedScheduleId: null,
  filter: { selectedStoreId: '0' },
  dayIdBeingAddingSchedule: null,
  storesSchedules: {},
};

export default handleActions(
  {
    [StoresSchedulesActions.applyStoresSchedulesFilterAction]: (
      state,
      { payload: { selectedStoreId } },
    ) => applyFilter(state, selectedStoreId),
    [StoresSchedulesActions.setAreStoresSchedulesLoadingAction]: (
      state,
      { payload: { areStoresSchedulesLoading } },
    ) => setAreStoresSchedulesLoading(state, areStoresSchedulesLoading),
    [StoresSchedulesActions.closeStoresSchedulesFilterDrawerAction]: state =>
      toggleStoresSchedulesFilterDrawer(state, false),
    [StoresSchedulesActions.deleteHolidayScheduleAction]: (
      state,
      { payload: { dayId, scheduleId } },
    ) => deleteSchedule(state, dayId, scheduleId, StoreSchedulesType.HOLIDAYS),
    [StoresSchedulesActions.deleteSpecialScheduleAction]: (
      state,
      { payload: { dayId, scheduleId } },
    ) => deleteSchedule(state, dayId, scheduleId, StoreSchedulesType.SPECIALS),
    [StoresSchedulesActions.getStoreSchedulesAction]: (
      state,
      { payload: { schedules } },
    ) => getStoreSchedules(state, schedules),
    [StoresSchedulesActions.openStoresSchedulesFilterDrawerAction]: state =>
      toggleStoresSchedulesFilterDrawer(state, true),
    [StoresSchedulesActions.cleanBeingEditedScheduleAction]: state =>
      setBeingEditedSchedule(state, null),
    [StoresSchedulesActions.cleanDayIdBeingAddingScheduleAction]: state =>
      setDayIdBeingAddingSchedule(state, null),
    [StoresSchedulesActions.setBeingEditedScheduleAction]: (
      state,
      { payload: { scheduleId } },
    ) => setBeingEditedSchedule(state, scheduleId),
    [StoresSchedulesActions.setDayIdBeingAddingScheduleAction]: (
      state,
      { payload: { dayId } },
    ) => setDayIdBeingAddingSchedule(state, dayId),
  },
  initialState,
);

const applyFilter = (state, selectedStoreId) => {
  if (!selectedStoreId) {
    return { ...state };
  }

  const shallowClonedFilter = {
    ...state.filter,
    selectedStoreId,
  };

  return {
    ...state,
    filter: shallowClonedFilter,
  };
};

const deleteSchedule = (state, dayId, scheduleId, scheduleType) => {
  const selectedStoreId = state.filter.selectedStoreId;
  const shallowClonedStoresSchedules = { ...state.storesSchedules };
  const shallowClonedStore = {
    ...shallowClonedStoresSchedules[selectedStoreId],
  };

  const shallowClonedStoreSchedules = { ...shallowClonedStore.schedules };

  const shallowClonedScheduleType = [
    ...shallowClonedStoreSchedules[scheduleType],
  ];

  const dayIndex = shallowClonedScheduleType.findIndex(day => day.id === dayId);

  const shallowClonedDay = { ...shallowClonedScheduleType[dayIndex] };

  let shallowClonedDaySchedules = [...shallowClonedDay.schedules];

  remove(shallowClonedDaySchedules, s => s.id === +scheduleId);

  shallowClonedDay.schedules = shallowClonedDaySchedules;

  shallowClonedScheduleType[dayIndex] = shallowClonedDay;
  shallowClonedStoreSchedules[scheduleType] = shallowClonedScheduleType;
  shallowClonedStore.schedules = shallowClonedStoreSchedules;
  shallowClonedStoresSchedules[selectedStoreId] = shallowClonedStore;

  return { ...state, storesSchedules: shallowClonedStoresSchedules };
};

const getStoreSchedules = (state, schedules) => {
  if (!schedules) {
    return { ...state };
  }

  const selectedStoreId = state.filter.selectedStoreId;

  const storeGroupedSchedules = StoreSchedulesUtils.groupSchedules(schedules);

  const shallowClonedStoresSchedules = { ...state.storesSchedules };

  shallowClonedStoresSchedules[selectedStoreId] = {
    id: selectedStoreId,
    schedules: storeGroupedSchedules,
  };

  return {
    ...state,
    storesSchedules: shallowClonedStoresSchedules,
  };
};

const setAreStoresSchedulesLoading = (state, areStoresSchedulesLoading) => {
  return { ...state, areStoresSchedulesLoading };
};

const setBeingEditedSchedule = (state, beingEditedScheduleId) => {
  return { ...state, beingEditedScheduleId };
};

const setDayIdBeingAddingSchedule = (state, dayIdBeingAddingSchedule) => {
  return { ...state, dayIdBeingAddingSchedule };
};

const toggleStoresSchedulesFilterDrawer = (state, open) => {
  return { ...state, filter: { ...state.filter, open } };
};
