import { clsx } from 'clsx';

import useStyles from './OrderRejectionReasons.style';

import { CustomClickableBox } from '../common/boxes/custom-clickable-box';

function OrderRejectionReasons(props) {
  const { classes = {}, onClickReason, rejectionReasons } = props;

  const _classes = useStyles();

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <div
        className={clsx(
          _classes.rejectionReasonsContainer,
          classes?.rejectionReasonsContainer,
        )}
      >
        {rejectionReasons.map(reason => {
          return (
            <CustomClickableBox
              classes={{ root: _classes.rejectionReasonItem }}
              title={reason.description}
              key={reason.id}
              onClick={() => {
                onClickReason(reason);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default OrderRejectionReasons;
