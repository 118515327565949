// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {
    color: neutral.D20,
    display: 'grid',
    gridColumnGap: 14,
    gridTemplateColumns: '70px 70px',
    fontSize: 13,
    letterSpacing: 0,
    [theme.breakpoints.up('md')]: {
      gridColumnGap: 90,
      gridTemplateColumns: '100px 100px',
    },
  },
  boxOpen: {
    height: 26,
    padding: '4px 0px 4px 10px',
    border: `solid 1px ${neutral.D10}`,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100px',
    },
  },
  boxClose: {
    border: `solid 1px ${neutral.D10}`,
    height: 26,
    width: '100%',
    padding: '4px 0px 4px 10px',
    [theme.breakpoints.up('md')]: {
      width: '100px',
    },
  },
}));
