// React
import { connect } from 'react-redux'
// Components
import SupportChatDrawer from './SupportChatDrawer'
// Actions
import OrderActions from './../../redux/actions/orders-actions'

const mapStateToProps = ({
  orderDetailDialogReducer: { open, orderId },
  ordersReducer,
  partnerReducer,
}) => {
  const order = ordersReducer.orders.find(order => order.id === +orderId)
  const orderDetail =
    order && order.detail
      ? order.detail
      : {
          id: orderId,
          products: {},
          supportNotificationsMessages: [],
        }

  const initialView =
    orderDetail.supportNotificationsMessages &&
    orderDetail.supportNotificationsMessages.length > 0
      ? 1
      : 0

  return {
    initialView,
    order,
    orderDetail,
    open: orderDetail.isSupportChatDrawerOpened,
    partnerId: partnerReducer.profile.id,
    partnerName: `${partnerReducer.profile.first_name} ${partnerReducer.profile.last_name}`,
    typifications: partnerReducer.typifications,
  }
}
const mapDispatchToProps = {
  close: OrderActions.closeSupportChatDrawer,
  onTypificationClick: OrderActions.postRealTimeNotification,
}

export default connect(mapStateToProps, mapDispatchToProps)(SupportChatDrawer)
