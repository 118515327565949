import { useFormatMessage } from '../../../../../intl/intl';

export const useOrderMgmtUndoSuggestionDialogLang = (isTopping: boolean) => {
  const messageCategory = 'orderManagement';
  const messageSubcategory = 'undoSuggestionDialog';

  const undoSuggestionTitleText = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.titleText`,
  });

  const undoSuggestionDescriptionText = useFormatMessage(
    { id: `${messageCategory}.${messageSubcategory}.descriptionText` },
    { item: isTopping ? 'topping' : 'producto' },
  );

  const undoSuggestionCancelText = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.cancelText`,
  });

  const undoSuggestionOkText = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.okText`,
  });

  const undoSuggestionDisclaimerText = useFormatMessage(
    { id: `${messageCategory}.${messageSubcategory}.disclaimer1Text` },
    { item: isTopping ? 'topping' : 'producto' },
  );

  return {
    undoSuggestionTitleText,
    undoSuggestionDescriptionText,
    undoSuggestionOkText,
    undoSuggestionCancelText,
    undoSuggestionDisclaimerText,
  };
};
