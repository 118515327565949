import { ImagePlaceholder } from '../../../../image-placeholder';
import { useTexts } from '../../hooks';

import useStyles from './CustomItemInformation.styles';

import type { ICustomItemInformation } from '../../types';

function CustomItemInformation(props: ICustomItemInformation) {
  const { itemName = '', itemImage = '', isProduct = true } = props;

  const _classes = useStyles();
  const { toppingTitle } = useTexts();

  return (
    <div className={_classes.itemImageContainer}>
      {!isProduct && <h5 className={_classes.itemTitle}>{toppingTitle}</h5>}
      {isProduct && (
        <ImagePlaceholder
          src={itemImage}
          classes={{ img: _classes.imageSmall }}
        />
      )}
      <span className={_classes.itemName}>{itemName}</span>
    </div>
  );
}

export default CustomItemInformation;
