// React
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// Components
import PrintingAgentDownloadSetting from './PrintingAgentDownloadSetting';
//actions
import AppActions from '../../redux/actions/app-actions';

const mapStateToProps = (
  { ordersReducer, partnerReducer },
  { intl: { formatMessage } },
) => {
  const agentButton = formatMessage({
    id: 'settings.agentButton',
  });
  const descriptionAgent = formatMessage({
    id: 'settings.agentDescription',
  });
  const titleAgent = formatMessage({
    id: 'settings.agentTitle',
  });

  return {
    agentButton,
    descriptionAgent,
    partnerId: partnerReducer.profile.id,
    titleAgent,
  };
};
const mapDispatchToProps = {
  openSnackBarSuccess: AppActions.openSnackBarSuccess,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(PrintingAgentDownloadSetting),
);
