// External libs
import { get } from 'lodash';
import { handleActions } from 'redux-actions';
// Actions
import SettingsActions from '../actions/settings.actions';
import StoreSettingType from '../../constants/StoreSettingType';

const initialState = {
  isAutoAcceptActive: false,
  isAutoPrintActive: false,
};

export default handleActions(
  {
    [SettingsActions.getSettingsAction]: (state, { payload: { settings } }) =>
      getSettings(state, settings),
    [SettingsActions.loadSettingsFromLocalStorageAction]: (
      state,
      { payload: { isAutoPrintActive } },
    ) => loadSettingsFromLocalStorage(state, isAutoPrintActive),
    [SettingsActions.setAutoAcceptAction]: (
      state,
      { payload: { isAutoAcceptActive } },
    ) => setAutoAccept(state, isAutoAcceptActive),
    [SettingsActions.setAutoPrintAction]: (
      state,
      { payload: { isAutoPrintActive } },
    ) => setAutoPrint(state, isAutoPrintActive),
  },
  initialState,
);

const getSettings = (state, settings) => {
  if (!settings) {
    return state;
  }
  let isAutoAcceptActive = false;
  for (const storeId of Object.keys(settings)) {
    const storeSettings = settings[storeId];

    if (!storeSettings || !storeSettings.length) {
      continue;
    }

    const storeAutoAcceptSetting = storeSettings.find(
      storeSetting => storeSetting.day === StoreSettingType.AUTO_ACCEPT,
    );

    if (!get(storeAutoAcceptSetting, 'default_cooking_time')) {
      continue;
    }

    isAutoAcceptActive = true;
    break;
  }

  return { ...state, isAutoAcceptActive };
};

const loadSettingsFromLocalStorage = (state, isAutoPrintActive) => ({
  ...state,
  isAutoPrintActive,
});

const setAutoAccept = (state, isAutoAcceptActive) => ({
  ...state,
  isAutoAcceptActive,
});

const setAutoPrint = (state, isAutoPrintActive) => ({
  ...state,
  isAutoPrintActive,
});
