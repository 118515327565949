import { makeStyles } from '@material-ui/core';

import { neutral } from '../../shared/styles/colors';

export default makeStyles(() => {
  return {
    root: {
      border: `1px solid ${neutral['20']}`,
      borderRadius: '0.5rem',
    },
    title: {
      fontSize: '0.75rem',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: neutral['50'],
      paddingTop: '0.75rem',
    },
    number: {
      fontSize: '1rem',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: neutral['90'],
      paddingTop: '0.5rem',
      paddingBottom: '0.75rem',
    },
  };
});
