import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';

import CustomItemsFilterDrawer from './../common/custom-items-filter-drawer';

import useStyles from './SalesSummaryFilterDrawer.styles';

function SalesSummaryFilterDrawer(props) {
  const {
    applyFilter,
    classes = {},
    currentFilter,
    dateFilterTitle,
    onClearFilter,
    onClose,
    open,
    stores,
  } = props;

  const _classes = useStyles();

  const [date, setDate] = useState(currentFilter.selectedDate);

  const [selectedStoreId, setSelectedStoreId] = useState(
    currentFilter.selectedStoreId,
  );

  useEffect(() => {
    setDate(currentFilter.selectedDate);
    setSelectedStoreId(currentFilter.selectedStoreId);
  }, [currentFilter.selectedStoreId, currentFilter.selectedDate]);

  const handleApplyFilter = () => {
    if (!selectedStoreId) {
      return;
    }
    applyFilter(selectedStoreId, date);
  };

  const handleItemsSelectionChange = selectedItems => {
    setSelectedStoreId(selectedItems[0] || 0);
  };

  return (
    <CustomItemsFilterDrawer
      items={stores}
      onApplyFilter={handleApplyFilter}
      onClearFilter={onClearFilter}
      onClose={onClose}
      onItemsSelectionChange={handleItemsSelectionChange}
      open={open}
      selectedItems={[selectedStoreId]}
      unique
    >
      <div className={_classes.dateSelectorRoot}>
        <h5
          className={clsx(_classes.dateFilterTitle, classes?.dateFilterTitle)}
        >
          {dateFilterTitle}
        </h5>
        <KeyboardDatePicker
          inputVariant={'outlined'}
          value={date}
          onChange={e => {
            setDate(e);
          }}
          format='dd/MM/yyyy'
        />
      </div>
    </CustomItemsFilterDrawer>
  );
}

export default SalesSummaryFilterDrawer;
