import { browserStyles } from './browser.style';
import { Box, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import logoRappi from '../../../assets/images/logos/logo_rappi_square.png';
import CustomButton from '../../../components/common/buttons/custom-button/CustomButton';
import { useBanner, useLang } from './hooks';

export function BrowserBanner() {
  const styles = browserStyles();
  const { bannerText, btnPrimaryText } = useLang();
  const { redirect2Install, bannerClose } = useBanner();

  return (
    <Box
      className={styles.banner}
      display='flex'
      minHeight='2.8rem'
      alignItems='center'
      gridGap='.5rem'
    >
      <img className={styles.bannerImg} src={logoRappi} alt='logo-rappi' />
      <Typography
        component='span'
        className={styles.bannerText}
        color='inherit'
        dangerouslySetInnerHTML={{
          __html: bannerText,
        }}
      ></Typography>
      <CustomButton
        className={styles.bannerBtn}
        fullWidth={false}
        onClick={redirect2Install}
      >
        {btnPrimaryText}
      </CustomButton>
      <IconButton size='small' aria-label='Close' onClick={bannerClose}>
        <CloseIcon className={styles.bannerClose} />
      </IconButton>
    </Box>
  );
}
