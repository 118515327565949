import { useCallback, useEffect } from 'react';
import {
  CargoKanbanCase,
  CargoUICase,
  PULL_STORES_INTERVAL_TIME,
} from '../../../business/cargo';
import { CargoAnalytics } from '../../../business/cargo/analytics';
import { useDialogLang } from '../components/dialog/hooks';
import { useRequestLang } from '../components/request/hooks';

export function useCargoAlerts() {
  const {
    isOrderCreated,
    showSuccessfulDialog,
    showErrorDialog,
    storeIdSelected,
    partnerId,
    isOrderRejected,
    isCoverageRejected,
    hasPartnerStores,
    fetchStores,
  } = CargoUICase();
  const { isCancelOrderSuccessful, isCancelOrderRejected } = CargoKanbanCase();
  const {
    orderSuccessfullyCreatedTitleText,
    orderSuccessfullyCreatedDescriptionText,
    orderRejectedCreatedTitleText,
    orderRejectedCreatedDescriptionText,
    coverageRejectedTitleText,
    coverageRejectedDescriptionText,
  } = useRequestLang({ orderId: storeIdSelected });
  const {
    orderSuccessfullyCancelledTitleText,
    orderSuccessfullyCancelledDescriptionText,
    orderRejectedCancelledTitleText,
    orderRejectedCancelledDescriptionText,
  } = useDialogLang({ orderId: storeIdSelected });

  const successfulDialog = useCallback((title: string, message: string) => {
    showSuccessfulDialog(title, message);
  }, []);

  const errorDialog = useCallback((title: string, message: string) => {
    showErrorDialog(title, message);
  }, []);

  useEffect(() => {
    if (hasPartnerStores) fetchStores();
    const interval = setInterval(fetchStores, PULL_STORES_INTERVAL_TIME);
    return () => clearInterval(interval);
  }, [hasPartnerStores]);

  useEffect(() => {
    isOrderCreated &&
      successfulDialog(
        orderSuccessfullyCreatedTitleText,
        orderSuccessfullyCreatedDescriptionText,
      );
    isOrderCreated &&
      CargoAnalytics.whenSendRequest(partnerId, 'header_kanban');
  }, [isOrderCreated]);

  useEffect(() => {
    isOrderRejected &&
      errorDialog(
        orderRejectedCreatedTitleText,
        orderRejectedCreatedDescriptionText,
      );
  }, [isOrderRejected]);

  useEffect(() => {
    isCancelOrderSuccessful &&
      successfulDialog(
        orderSuccessfullyCancelledTitleText,
        orderSuccessfullyCancelledDescriptionText,
      );
    isCancelOrderSuccessful &&
      CargoAnalytics.whenCancelOrder(partnerId, storeIdSelected);
  }, [isCancelOrderSuccessful]);

  useEffect(() => {
    isCancelOrderRejected &&
      errorDialog(
        orderRejectedCancelledTitleText,
        orderRejectedCancelledDescriptionText,
      );
  }, [isCancelOrderRejected]);

  useEffect(() => {
    isCoverageRejected &&
      errorDialog(coverageRejectedTitleText, coverageRejectedDescriptionText);
  }, [isCoverageRejected]);
}
