import { IStoreManager, IStoreStatusBadge } from './interfaces'

export const getStoresStatusBadges = (stores: IStoreManager[]) => {
  return [
    ...Array.from(new Set(stores.map((store: IStoreManager) => store.status))),
  ].reduce((acc: IStoreStatusBadge[], status) => {
    acc.push({
      status,
      quantity: stores.filter((store: IStoreManager) => store.status === status)
        .length,
    })
    return acc
  }, [])
}
