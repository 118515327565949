import Box from '@material-ui/core/Box';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { ChipOrderDisclaimer } from '../../../../components/order-mgmt-dialog/components';
import {
  OrderMgmtProduct,
  OrderMgmtTopping,
} from '../../../../../../business/order-mgmt';

export function Disclaimer({ onDelete, item, ...props }: Props) {
  return (
    <Box display='flex'>
      <ChipOrderDisclaimer type='warning' disclaimer={props.soldoutText} />
      {props.enabledToDelete && (
        <Box
          display='flex'
          justifyContent='center'
          onClick={() => onDelete(item)}
        >
          <Box
            className={props.classDelete}
            display='flex'
            alignItems='center'
            justifyContent='center'
            fontSize='0.625rem'
            fontWeight={600}
            gridGap={10}
          >
            <DeleteOutlineIcon />
          </Box>
        </Box>
      )}
    </Box>
  );
}

type Props = {
  enabledToDelete: boolean | undefined;
  soldoutText: string;
  classDelete: string;
  item: OrderMgmtProduct | OrderMgmtTopping;
  onDelete: (orderId, toppingId?) => void;
};
