import { useDispatch, useSelector } from 'react-redux';

import { useMainKanbanCardLang } from './useMainKanbanCardLang';

import orderActions from '../../../redux/actions/orders-actions/orders.actions';
import { selectors as partnerSelectors } from '../../../redux/partner';
import OrdersSelector from '../../../redux/selectors/orders-selectors';
import { ordersWithSameBundleIdAndStore } from '../../../shared/utils/orders';
import { toCapitalizeCaseWithSeparator } from '../../../shared/utils/strings';
import ordersUtils from '../../../utils/OrdersUtils';

import { useState, type MouseEvent } from 'react';
import { geoqueueHelper } from '../../../business/order/geoqueue';
import type { OrderResponseFromStatusV2 } from '../../../business/shared/infra/core';

export function useMainKanbanCard(props: Props) {
  const [isOpenOrderDeliveryDetails, setIsOpenOrderDeliveryDetails] = useState(true)
  const { order } = props;
  const reduxDispatch = useDispatch();
  const storesByProfile = useSelector(partnerSelectors.selectStores) ?? [];
  const allOrders: OrderResponseFromStatusV2[] = useSelector(
    OrdersSelector.selectOrders,
  );

  const orderId = order.id;
  const storeId = order.store_id
  const cookingTime = order.cooking_time ?? 0;
  const cookingTimeStartedAt = order.cooking_time_started_at;
  const deliveryMethod = order.delivery_method;
  const partnerOrderState = order.partner_order_state;
  const isMarketplace = ordersUtils.isMarketPlace(deliveryMethod);
  const isInCooking = ordersUtils.isBeingCooking(order.partner_order_state);
  const isGeoqueue = geoqueueHelper.showSection(order);
  const productConfirmationCode =
    order?.qr_information?.product_confirmation_code ?? '';
  const isMandatory = order?.qr_information?.is_mandatory
  const hasOrderDeliveryDetailsUpdated = order?.is_bags_quantity_set
  const hasOrderDeliveryDetailMessage = order?.has_input_mandatory_message;

  const isRtWaitingMuch = order.rt_waiting_time_exceeded;
  const { etaStepMsg, rtStep } = etaStepMsgWithRTName(order);
  const storeName = storesByProfile.length > 1 ? order.store_name : null;

  const isRTInStore = ordersUtils.isRTInStore(order);
  const isPickup = ordersUtils.isPickup(order.delivery_method);
  const isRTNotifyAvailable = order.show_button_rt_order_ready;
  const showRTInfo =
    ordersUtils.isBeingManaged(order) &&
    (ordersUtils.isRTOnTheWay(order) || isRTInStore);

  const allOrdersFromSameBundle = ordersWithSameBundleIdAndStore({
    order,
    allOrders,
  });
  const showChipOfBundle = allOrdersFromSameBundle.length > 0;
  const showOrderDeliveryDetails = !hasOrderDeliveryDetailsUpdated && !isInCooking && isMandatory
  const showRtWaitingMessage = !hasOrderDeliveryDetailsUpdated && hasOrderDeliveryDetailMessage

  const orderIds: { id: number; productConfirmationBundleCode: string }[] = [
    { id: orderId, productConfirmationBundleCode: productConfirmationCode },
  ];
  const notReadyOrderIds: number[] = [];
  if (!isInCooking) {
    orderIds.push(
      ...allOrdersFromSameBundle
        .filter(o => ordersUtils.isReadyAndPacked(o.partner_order_state))
        .map(o => ({
          id: o.id,
          productConfirmationBundleCode:
            o?.qr_information?.product_confirmation_code || '',
        })),
    );
    notReadyOrderIds.push(
      ...allOrdersFromSameBundle
        .filter(o => !ordersUtils.isReadyAndPacked(o.partner_order_state))
        .map(o => o.id),
    );
  }
  const hasMoreOrdersReady = orderIds.length > 1;

  const texts = useMainKanbanCardLang({
    isInCooking,
    isMarketplace,
    showRTInfo,
    isRTNotifyAvailable,
    isPickup,
    hasMoreOrdersReady,
    numberOfOrdersNotReady: notReadyOrderIds.length,
  });

  function handleOrderCTABtn(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    if (!isInCooking) {
      reduxDispatch(
        hasMoreOrdersReady
          ? orderActions.deliverBundle(orderIds)
          : orderActions.deliverOrder(orderId),
      );
      return;
    }
    reduxDispatch(orderActions.executeOrderIsReady(order));
  }

  function handleRTBtn(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    let rtAction = orderActions.openRTDialog;
    if (isRTNotifyAvailable) rtAction = orderActions.sendNotificationToRT;
    reduxDispatch(rtAction(orderId));
  }

  function handleOpenOrderDetail(event: MouseEvent<HTMLElement>) {
    event.stopPropagation();
    const dataOrderId = event.currentTarget.getAttribute('data-order-id') ?? '';
    reduxDispatch(orderActions.openOrderDetailDialog(parseInt(dataOrderId) || orderId));
  }

  function onToggleOpenOrderDeliveryDetails() {
    setIsOpenOrderDeliveryDetails(isOpen => !isOpen)
  }

  return {
    orderId,
    storeId,
    storeName,
    hasMoreOrdersReady,
    orderIds,
    notReadyOrderIds,
    showChipOfBundle,
    isMarketplace,
    cookingTime,
    cookingTimeStartedAt,
    partnerOrderState,
    deliveryMethod,
    rtStep,
    isRtWaitingMuch,
    showTimeChip: isMarketplace || isInCooking,
    texts: { ...texts, etaStepMsg },
    handleOpenOrderDetail,
    handleOrderCTABtn,
    handleRTBtn,
    isGeoqueue,
    productConfirmationCode,
    isInCooking,
    isMandatory,
    hasOrderDeliveryDetailsUpdated,
    isOpenOrderDeliveryDetails,
    onToggleOpenOrderDeliveryDetails,
    isRTInStore,
    showOrderDeliveryDetails,
    hasOrderDeliveryDetailMessage,
    showRtWaitingMessage
  };
}

interface Props {
  order: OrderResponseFromStatusV2;
}

function etaStepMsgWithRTName(order: OrderResponseFromStatusV2) {
  const [WORD_ES, WORD_PT] = ['RT', 'Entregador'];
  const rtStep = order.eta_steps?.active_step ?? 1;
  let coreMsg = order.eta_steps?.step_message ?? '';
  const firstName = order.store_keeper_info?.first_name;
  const lastName = order.store_keeper_info?.last_name;
  const rtFirstName = maxNWords(firstName, !!lastName ? 1 : 2);
  const rtLastName = maxNWords(lastName, !!firstName ? 1 : 2);
  let rtName = [rtFirstName, rtLastName].join(' ').trim();
  const wordToReplace = coreMsg.includes(WORD_ES) ? WORD_ES : WORD_PT;
  rtName = !Boolean(rtName)
    ? wordToReplace
    : toCapitalizeCaseWithSeparator({ str: rtName }).split(' ', 2).join(' ');
  let isStep2Plus = false;
  if (rtStep <= 2 && !!order.store_keeper_info?.id) {
    isStep2Plus = true;
    coreMsg =
      wordToReplace === WORD_PT
        ? `${WORD_PT} é o entregador`
        : `${WORD_ES} es el repartidor`;
  }
  const result = [rtName, coreMsg.replace(wordToReplace, '')];
  return {
    rtStep,
    etaStepMsg: rtStep < 3 && !isStep2Plus ? result.reverse() : result,
  };
}

function maxNWords(str: string = '', n: number = 1): string {
  return str.trim().split(' ', n).join(' ');
}
