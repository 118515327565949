import { useFormatMessage } from '../../intl/intl';

export function useRTMapLang() {
  return {
    releaseRT: {
      btn: useFormatMessage({ id: 'rtReleaseButton.text' }),
    },
    error: {
      loadingMap: {
        title: useFormatMessage({ id: 'customSnackbar.errorTitle' }),
        msg: useFormatMessage({ id: 'customSnackbar.errorMessage' }),
      },
    },
  };
}
