import { useSelector } from 'react-redux';

import { selectors as partnerSelectors } from '../../../redux/partner';
import { useStoresSchedulesPageLang } from './useStoresSchedulesPageLang';

export function useStoresSchedulesPage() {
  const supportPhone = useSelector(partnerSelectors.selectSupportPhone);

  const lang = useStoresSchedulesPageLang({ supportPhone });

  return {
    lang,
  };
}
