import { useSelector } from 'react-redux';

import ordersSelectors from '../../../../../redux/selectors/orders-selectors';

const useCTToTakeOrder = () =>
  useSelector(ordersSelectors.selectCTToTakeOrderInOrderDetailDialog);

const useMaxValue = () =>
  useSelector(
    ordersSelectors.selectNonPredictiveMaxCTInputConfigInOrderDetailDialog,
  );

const useMinValue = () =>
  useSelector(
    ordersSelectors.selectNonPredictiveMinCTInputConfigInOrderDetailDialog,
  );

const hooks = {
  useCTToTakeOrder,
  useMaxValue,
  useMinValue,
};

export default hooks;
