import { useSelector } from 'react-redux';

import { selectors as errorSuggestingProductDialog } from '../../redux/error-suggesting-product-dialog';

const useOpen = () => useSelector(errorSuggestingProductDialog.selectOpen);

const hooks = {
  useOpen,
};

export default hooks;
