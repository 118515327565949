import { useEffect, useState } from 'react';
import type { StoreStatusDetail } from '../../../business/highDemand';
import { operationalStateTurboHandler } from '../../../business/highDemand/infra';

export function useOperationalStateTurbo() {
  const [storeStatusDetail, setStoreStatusDetail] = useState<
    StoreStatusDetail[]
  >([]);
  const [hasActiveTurboStores, setHasActiveTurboStores] = useState(false);
  const { getStoreStatusDetail, partnerId } = operationalStateTurboHandler();

  const _fetchStoreDetail = async () => {
    try {
      const data = await getStoreStatusDetail();
      setStoreStatusDetail(data);
    } catch (ex) {
      setStoreStatusDetail([]);
    }
  };

  useEffect(() => {
    if (partnerId) {
      _fetchStoreDetail();
    }
  }, [partnerId]);

  useEffect(() => {
    const data = storeStatusDetail.some(item => item.isTurbo);
    setHasActiveTurboStores(data);
  }, [storeStatusDetail]);

  return {
    hasActiveTurboStores,
    partnerId,
  };
}
