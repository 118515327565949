import { useCallback } from 'react';
import { Box, Typography, Button, Link } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import EventActions from '../../../../redux/actions/event-actions/event.actions';

import { getSteps, getTexts } from './hooks/useChangePasswordPath';
import { changePasswordPathStyles } from './ChangePasswordPath.styles';

export function ChangePasswordPath({ handleShowForm }) {
  const styles = changePasswordPathStyles();

  const handleForgotPassword = useCallback(() => {
    EventActions.onLoginScreenSelection('forgot_password');
    window.open(process.env.REACT_APP_MY_ACCOUNT, '_blank');
  }, []);

  const handleBackButton = (e: any) => {
    handleShowForm(true);
  };

  const steps = getSteps();
  const { title, btnLabel } = getTexts();

  return (
    <Box className={styles.root}>
      <Box className={styles.header}>
        <ArrowBackIcon
          onClick={handleBackButton}
          style={{ cursor: 'pointer' }}
        />
        <Typography className={styles.title}>{title}</Typography>
      </Box>
      {steps.map(step => {
        return (
          <Box key={step.text[0]} className={styles.stepHolder}>
            <Box className={styles.imageHolder}>
              <img className={styles.image} src={step.imgURL} />
            </Box>

            <Box>
              <Typography>{step.text}</Typography>
              {step.link && (
                <Link className={styles.link} href={step.link}>
                  {step.link}
                </Link>
              )}
              {step.boldText && (
                <Typography className={styles.textBold}>
                  {step.boldText}
                </Typography>
              )}
            </Box>
          </Box>
        );
      })}
      <Button className={styles.button} onClick={handleForgotPassword}>
        {btnLabel}
      </Button>
    </Box>
  );
}
