// Material UI
import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  customMoreVertButton: { marginLeft: 'auto' },
  paper: {
    position: 'relative',
    '@media print': {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      height: '100vh',
      width: '100%',
      maxWidth: '67.5rem',
      padding: '1rem',
      overflowY: 'hidden',
      '@media print': {
        display: 'none',
      },
    },
  },
}));
