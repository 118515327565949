// External libs
import produce from 'immer';
import { handleActions } from 'redux-actions';
// Actions
import NotificationsDrawerActions from '../../actions/notifications-drawer-actions';

const initialState = {
  open: false,
};

export default handleActions(
  {
    [NotificationsDrawerActions.closeNotificationsDrawerAction]: state =>
      toggleOpen(state, false),
    [NotificationsDrawerActions.openNotificationsDrawerAction]: state =>
      toggleOpen(state, true),
  },
  initialState,
);

const toggleOpen = (state, open) => {
  return produce(state, stateDraft => {
    stateDraft.open = open;
  });
};
