// Consts
import urls from '../../constants/Urls'
// Interfaces
import { StoreDiagnosticResponse } from './interfaces'
// Utils
import UrlUtils from '../../utils/url-utils'
// Services
import http from '../HttpService'
import LocalStorageService from '../local-storage-service'

const getDiagnostics = (storeId: number) => {
  const microserviceUrl = LocalStorageService.getBaseUrl()

  const replacedUrl = UrlUtils.replaceUrlParams(urls.STORE_DIAGNOSTICS, {
    storeId,
  })

  return http.get<StoreDiagnosticResponse>(`${microserviceUrl}${replacedUrl}`)
}

const storeService = {
  getDiagnostics,
}

export default storeService
