// React
import React from 'react'
// Components
import CustomButton, { Colors, Variants } from '../buttons/custom-button'
// Styles
import useStyles from './style'
// Interfaces
import { Props } from './props'

const CustomMobileDialogFooter: React.FC<Props> = ({
  classes,
  onConfirm,
  onBack,
  submitText,
  cancelText,
}) => {
  const componentClasses = useStyles()

  return (
    <div className={componentClasses.containerButtons}>
      <CustomButton
        className={componentClasses.footerButtonSubmit}
        color={Colors.PRIMARY}
        fullWidth
        onClick={onConfirm}
        variant={Variants.CONTAINED}
      >
        {submitText}
      </CustomButton>
      <CustomButton
        className={componentClasses.footerButtonSubmit}
        color={Colors.PRIMARY}
        fullWidth
        onClick={onBack}
        variant={Variants.OUTLINED}
      >
        {cancelText}
      </CustomButton>
    </div>
  )
}

export default CustomMobileDialogFooter
