import { AxiosResponse } from 'axios';

import http from '../../../../services/HttpService';
import LocalStorageService from '../../../../services/local-storage-service';

import { LS_KEY_RECENTLY_SEARCHED_QUERIES as LS_KEY_RECENTLY } from '../../domain/consts';

import type {
  IItemTypeToReplace,
  IRecentlySearchedQueries,
} from '../../domain/models';
import { urls } from '../core/consts';
import type { ItemSearchRequest } from '../core/requests';
import type { ItemSearchResponse } from '../core/responses';

const getBy = (
  payload: ItemSearchRequest,
): Promise<AxiosResponse<ItemSearchResponse>> => {
  const baseUrl = LocalStorageService.getBaseUrl();
  return http.put(`${baseUrl}${urls.SEARCH_ORDER_MGMT_ITEM}`, payload);
};

function getRecentlySearchedQueries(params: IItemTypeToReplace) {
  const { itemType = 'product' } = params;
  const queriesInLS = localStorage.getItem(LS_KEY_RECENTLY[itemType]) ?? '';
  return queriesInLS !== '' ? queriesInLS.split(',') : [];
}

function setRecentlySearchedQueries(params: IRecentlySearchedQueries) {
  const { query = '', itemType = 'product' } = params;
  let queries = getRecentlySearchedQueries({ itemType });
  if (query !== '') {
    const queryLow = query.toLowerCase();
    queries = [queryLow, ...queries.filter(q => q !== queryLow).slice(0, 4)];
    localStorage.setItem(LS_KEY_RECENTLY[itemType], queries.toString());
  }
  return queries;
}

export const searchRepository = {
  getBy,
  getRecentlySearchedQueries,
  setRecentlySearchedQueries,
} as const;
