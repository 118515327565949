export enum SupportSuggestionTypes {
  CANCEL = 'order.cancel',
  CONTACT_SUPPORT = 'order.contact-support',
  NO_RT = 'order.no-rt',
  NO_RT_ORDER_READY = 'step.no-rt.order-ready',
  NO_RT_SUPPORT = 'step.no-rt.support',
  NO_RT_WAIT = 'step.no-rt.wait',
  ORDER_READY = 'order.order-ready',
  NO_PRODUCT = 'order.no-product',
  PROXY_SESSION = 'order.proxy-session',
  RELEASE_RT = 'order.liberate-rt',
  REPORT_RT = 'order.report-rt',
  WITH_RT = 'order.with-rt',
}
