import { useFormatMessage } from '../../../../../intl/intl'

export function useTexts(date: string) {
  const transformDateToDDMMYYYY = (date: string) => {
    if (date) {
      return `${date.split('-').reverse().join('/')}`
    }
  }
  const DDMMYYYYDate = transformDateToDDMMYYYY(date)

  return {
    initial: useFormatMessage({
      id: 'productAndToppingsDeactivationTypes.custom.pickDateButton',
    }),
    onSelected: useFormatMessage(
      {
        id: 'productAndToppingsDeactivationTypes.custom.selectedDateButton',
      },
      { DDMMYYYYDate: DDMMYYYYDate },
    ),
  }
}
