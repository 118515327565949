// External services
import { filter, find, get, sortBy, union, unionBy } from 'lodash';
// Utils
import { formatMessage } from '../intl/intl';
import StringUtils from './StringUtils';
import DeactivationType from '../constants/DeactivationType';
import RefuseReasons from '../constants/RefuseReasons';

const filterByCorridorId = (corridorId, products) => {
  if (!corridorId) {
    return [];
  }
  return filter(products, p => {
    return p.corridorIds.indexOf(+corridorId) !== -1;
  });
};
const filterByProductId = (productId, toppingCategories) => {
  if (!productId) {
    return [];
  }

  return filter(toppingCategories, tc => {
    return tc.productIds.indexOf(productId) !== -1;
  });
};

const filterProductsOfToppings = (products, toppings) => {
  let productsResult = [];
  for (const topping of toppings) {
    const toppingProducts = filterProductsByIds(products, topping.productIds);
    productsResult = unionBy(productsResult, toppingProducts, 'id');
  }

  return productsResult;
};

const filterProductsByIds = (products, productIds) => {
  return filter(products, p => {
    return productIds.indexOf(+p.id) !== -1;
  });
};

const filterToppingsByIds = (toppings, toppingIds) => {
  return filter(toppings, t => {
    return toppingIds.indexOf(+t.id) !== -1;
  });
};

const findProductById = (productId, products) => {
  return find(products, {
    id: productId,
  });
};

const findToppingById = (toppings, toppingId) => {
  if (!toppings || toppings.length === 0) {
    return null;
  }
  return toppings.find(t => t.id === +toppingId);
};

const findToppingCategoryById = (toppingCategories, toppingCategoryId) => {
  if (!toppingCategories || toppingCategories.length === 0) {
    return null;
  }
  return toppingCategories.find(tc => tc.id === +toppingCategoryId);
};

const findToppingByCategoryAndToppingId = (
  toppingCategories,
  toppingCategoryId,
  toppingId,
) => {
  const toppingCategory = findToppingCategoryById(
    toppingCategories,
    toppingCategoryId,
  );
  return findToppingById(toppingCategory.toppings, toppingId);
};

const formatProducts = products => {
  const productsResult = [];
  for (const product of products) {
    const existingProduct = findProductById(product.id, productsResult);

    if (!existingProduct) {
      product.corridorIds = [product.corridor_id];
      // IMPORTANT: This property must be deleted due to the DTO returned from backend.
      delete product.corridor_id;
      productsResult.push(product);
      continue;
    }

    existingProduct.corridorIds = union(existingProduct.corridorIds, [
      product.corridor_id,
    ]);

    // IMPORTANT: This property must be deleted due to the DTO returned from backend.
    delete existingProduct.corridor_id;
  }

  return productsResult;
};

const formatToppingCategories = toppingCategories => {
  const toppingCategoriesResult = [];

  for (const toppingCategory of toppingCategories) {
    toppingCategory.toppings = formatToppings([toppingCategory]);
    toppingCategory.toppingIds = toppingCategory.toppings.map(t => t.id);

    const existingToppingCategory = findToppingCategoryById(
      toppingCategoriesResult,
      toppingCategory.id,
    );

    if (!existingToppingCategory) {
      //if the topping doesn't exist yet, initialize the product ids and add the topping category to the resulting list.
      toppingCategory.productIds = [toppingCategory.product_id];
      // IMPORTANT: This property must be deleted due to the DTO returned from backend.
      delete toppingCategory.product_id;
      toppingCategoriesResult.push(toppingCategory);
      continue;
    }

    // Update the toppings for the existing category.
    existingToppingCategory.toppings = toppingCategory.toppings;

    existingToppingCategory.productIds = union(
      existingToppingCategory.productIds,
      [toppingCategory.product_id],
    );

    existingToppingCategory.toppingIds = union(
      existingToppingCategory.toppingIds,
      toppingCategory.toppingIds,
    );

    // IMPORTANT: This property must be deleted due to the DTO returned from backend.
    delete toppingCategory.product_id;
  }
  return toppingCategoriesResult;
};

const formatToppings = toppingCategories => {
  const toppingsResult = [];
  for (const toppingCategory of toppingCategories) {
    const toppingCategoryProductIds = toppingCategory.productIds || [
      toppingCategory.product_id,
    ];

    for (const topping of toppingCategory.toppings) {
      const existingTopping = findToppingById(toppingsResult, topping.id);
      if (!existingTopping) {
        //if the topping doesn't exist yet
        topping.productIds = toppingCategoryProductIds;

        topping.toppingCategoryIds = [toppingCategory.id];
        toppingsResult.push(topping);
        continue;
      }

      existingTopping.productIds = union(existingTopping.productIds, [
        toppingCategoryProductIds,
      ]);

      existingTopping.toppingCategoryIds = union(
        existingTopping.toppingCategoryIds,
        [toppingCategory.id],
      );
    }
  }
  return toppingsResult;
};

const formatProductsOutOfStock = productsOutOfStockWithoutFlat => {
  const formattedProductOutOfStock = [];
  for (const productOutOfStockWithoutFlat of productsOutOfStockWithoutFlat) {
    for (const productOutOfStock of productOutOfStockWithoutFlat.products_out_of_stock) {
      productOutOfStock.corridor_id = productOutOfStockWithoutFlat.corridor_id;
      productOutOfStock.corridorName =
        productOutOfStockWithoutFlat.corridor_name;
      formattedProductOutOfStock.push(productOutOfStock);
    }
  }

  return formatProducts(formattedProductOutOfStock);
};

const formatToppingsOutOfStock = (
  toppings,
  toppingsOutOfStock,
  products = [],
  toppingCategories = [],
) => {
  if (!toppings || toppings.length === 0) {
    return toppingsOutOfStock;
  }
  const toppingsResult = [];
  for (const toppingOutOfStock of toppingsOutOfStock) {
    const toppingInMenu = findToppingById(toppings, toppingOutOfStock.id);
    if (toppingInMenu) {
      toppingInMenu.title = getToppingTitle(
        products,
        toppingInMenu,
        toppingCategories,
      );
    }
    const toppingToAdd =
      { ...toppingInMenu, ...toppingOutOfStock } || toppingOutOfStock;

    toppingToAdd.activated = false;

    const existingTopping = findToppingById(toppingsResult, toppingToAdd.id);
    if (existingTopping) {
      continue;
    }
    toppingsResult.push(toppingToAdd);
  }
  return toppingsResult;
};

const sortCorridors = corridors => {
  return sortBy(corridors, ['index', 'corridor_name']);
};

const formatCorridors = corridors => {
  return sortCorridors(corridors);
};

const formatMenu = menu => {
  menu.corridors = formatCorridors(menu.corridors);
  menu.products = formatProducts(menu.products);
  menu.toppingCategories = formatToppingCategories(menu.toppings);
  menu.toppings = formatToppings(menu.toppingCategories);

  // Delete wrong data from topping categories tree.
  menu.toppingCategories.map(tc => {
    delete tc.toppings;
    return tc;
  });

  return menu;
};

const getProductsByNameDescriptionContainsString = (searchText, products) => {
  const selectedProducts = [];
  if (
    !products ||
    products.length === 0 ||
    !searchText ||
    searchText.trim() === ''
  ) {
    return selectedProducts;
  }
  const normalizedSearchText = StringUtils.normalizeString(searchText);

  for (const product of products) {
    const normalizedProductName = StringUtils.normalizeString(product.name);

    if (normalizedProductName.indexOf(normalizedSearchText) > -1) {
      selectedProducts.push(product);
      continue;
    }

    const normalizedProductDescription = StringUtils.normalizeString(
      product.description,
    );

    if (normalizedProductDescription.indexOf(normalizedSearchText) > -1) {
      selectedProducts.push(product);
      continue;
    }
  }
  return selectedProducts;
};

const sortToppingCategoryTrees = toppingCategories => {
  toppingCategories = sortBy(toppingCategories, ['index', 'description']);

  for (const toppingCategory of toppingCategories) {
    toppingCategory.toppings = sortBy(toppingCategory.toppings, [
      'index',
      'description',
    ]);
  }

  return toppingCategories;
};

const getToppingCategoryTrees = (productId, toppingCategories, toppings) => {
  const productToppingCategories = filterByProductId(
    productId,
    toppingCategories,
  );
  for (const toppingCategory of productToppingCategories) {
    toppingCategory.toppings = filterToppingsByIds(
      toppings,
      toppingCategory.toppingIds,
    );
  }

  return sortToppingCategoryTrees(productToppingCategories);
};

const getToppingsByDescriptionContainsStrings = (
  searchTexts,
  toppings,
  activated = null,
) => {
  const selectedToppings = [];
  if (
    !toppings ||
    toppings.length === 0 ||
    !searchTexts ||
    searchTexts.length === 0
  ) {
    return selectedToppings;
  }

  const normalizedSearchTexts = StringUtils.normalizeStrings(searchTexts);

  for (const topping of toppings) {
    if (activated !== null && topping.activated !== activated) {
      continue;
    }
    const normalizedToppingDescription = StringUtils.normalizeString(
      topping.description,
    );

    const isContained = StringUtils.containsAnyString(
      normalizedToppingDescription,
      normalizedSearchTexts,
    );

    if (isContained) {
      selectedToppings.push(topping);
    }
  }
  return selectedToppings;
};

const getFirstExistingProduct = (productIds, products) => {
  let foundProduct = null;
  for (const productId of productIds) {
    foundProduct = findProductById(productId, products);
    if (!!foundProduct) {
      // If the product exists, stop searching.
      break;
    }
  }
  // return found product.
  return foundProduct;
};

const getToppingTitle = (products, topping, toppingCategories) => {
  if (!topping || !topping.productIds) {
    return null;
  }
  const totalProducts = topping.productIds.length;
  if (!products || products.length === 0 || totalProducts === 0) {
    return null;
  }
  const firstProduct = getFirstExistingProduct(topping.productIds, products);

  const firstProductName = get(firstProduct, 'name', '');
  if (totalProducts > 1) {
    const productsLabel = formatMessage({
      id: 'globals.products',
    }).toLowerCase();

    return `${firstProductName} (+${totalProducts - 1} ${productsLabel})`;
  }
  const totalToppingCategories = topping.toppingCategoryIds.length;
  if (totalToppingCategories > 1) {
    const toppingCategoryLabel = formatMessage({
      id: 'globals.categories',
    }).toLowerCase();

    return `${firstProductName} (+${totalToppingCategories} ${toppingCategoryLabel})`;
  }

  if (!toppingCategories || toppingCategories.length === 0) {
    return `${firstProductName}`;
  }

  const firstToppingCategory = findToppingCategoryById(
    toppingCategories,
    topping.toppingCategoryIds[0],
  );
  return `${firstProductName} - ${firstToppingCategory.description}`;
};

const getDeactivationTypeFromRefuseReason = refuseReason => {
  if (!refuseReason) return DeactivationType.DAILY;

  switch (refuseReason.refuse_reason_enum) {
    case RefuseReasons.NO_LONGER_HANDLE_PRODUCT:
      return DeactivationType.PERMANENT;
    default:
      return DeactivationType.DAILY;
  }
};

export default {
  filterByCorridorId,
  filterByProductId,
  filterProductsOfToppings,
  filterToppingsByIds,
  findProductById,
  findToppingByCategoryAndToppingId,
  findToppingById,
  formatCorridors,
  formatProductsOutOfStock,
  formatToppingCategories,
  formatToppings,
  formatToppingsOutOfStock,
  formatMenu,
  getProductsByNameDescriptionContainsString,
  getToppingCategoryTrees,
  getToppingTitle,
  getToppingsByDescriptionContainsStrings,
  getDeactivationTypeFromRefuseReason,
};
