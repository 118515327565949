import CustomButton from '../common/buttons/custom-button';
import { CustomDeleteButton } from '../common/buttons/custom-delete-button';
import { CustomTimePickerInterval } from '../common/custom-time-picker-interval';

import useStyles from './StoreScheduleIntervals.styles';

function StoreScheduleIntervals(props) {
  const {
    dayIdBeingAddingSchedule,
    addIntervalButtonText,
    beingEditedScheduleId,
    dayId,
    onClickAddSchedule,
    onClickConfirm,
    onClickEditingScheduleCancel,
    onClickEditSchedule,
    onClickDeleteDay,
    onClickDeleteSchedule,
    onClickShowAddSchedule,
    showDeleteButton = false,
    schedules = [],
  } = props;
  const componentClasses = useStyles();
  const showAddSchedule = schedules.length < 3;
  return (
    <div className={componentClasses.root}>
      <div className={componentClasses.intervalsContainer}>
        {schedules.map((interval, index) => (
          <CustomTimePickerInterval
            beingEdited={interval.id === beingEditedScheduleId}
            classes={{
              customTimePickerInputPropsInput:
                componentClasses.customTimePickerInputPropsInput,
              customTimePickerInputPropsNotchedOutline:
                componentClasses.customTimePickerInputPropsNotchedOutline,
              customTimePickerInputPropsRoot:
                componentClasses.customTimePickerInputPropsRoot,
              minusButtonButton:
                componentClasses.customTimePickerIntervalMinusButtonButton,
              minusButtonIcon:
                componentClasses.customTimePickerIntervalMinusButtonIcon,
              root: componentClasses.customTimePickerIntervalRoot,
            }}
            key={`i-${index}`}
            endsTime={interval.ends_time}
            onClickConfirm={onClickConfirm}
            onClickEditingScheduleCancel={() =>
              onClickEditingScheduleCancel(null)
            }
            onClickEditSchedule={() => onClickEditSchedule(interval.id)}
            onClickDeleteSchedule={() => onClickDeleteSchedule(interval.id)}
            startsTime={interval.starts_time}
          />
        ))}
        {showAddSchedule && (
          <>
            {dayId !== dayIdBeingAddingSchedule && (
              <CustomButton
                classes={{
                  submitButton: componentClasses.addIntervalButtonRoot,
                }}
                fullWidth={false}
                onClick={() => {
                  onClickShowAddSchedule(dayId);
                }}
              >
                {addIntervalButtonText}
              </CustomButton>
            )}
            {dayId === dayIdBeingAddingSchedule && (
              <CustomTimePickerInterval
                beingEdited={dayId === dayIdBeingAddingSchedule}
                classes={{
                  customTimePickerInputPropsInput:
                    componentClasses.customTimePickerInputPropsInput,
                  customTimePickerInputPropsNotchedOutline:
                    componentClasses.customTimePickerInputPropsNotchedOutline,
                  customTimePickerInputPropsRoot:
                    componentClasses.customTimePickerInputPropsRoot,
                  minusButtonButton:
                    componentClasses.customTimePickerIntervalMinusButtonButton,
                  minusButtonIcon:
                    componentClasses.customTimePickerIntervalMinusButtonIcon,
                  root: componentClasses.customTimePickerIntervalRoot,
                }}
                endsTime={'00:00'}
                onClickConfirm={(endsTime, startsTime) => {
                  onClickAddSchedule(dayId, endsTime, startsTime);
                }}
                onClickEditingScheduleCancel={() =>
                  onClickEditingScheduleCancel(null)
                }
                startsTime={'00:00'}
              />
            )}
          </>
        )}
      </div>
      {showDeleteButton && (
        <div className={componentClasses.interalsActionsContainer}>
          <CustomDeleteButton
            classes={{
              button: componentClasses.deleteButtonButton,
              icon: componentClasses.deleteButtonIcon,
            }}
            onClick={onClickDeleteDay}
          />
        </div>
      )}
    </div>
  );
}

export default StoreScheduleIntervals;
