// React
import React from 'react';
// Material UI
import { KeyboardDatePicker } from '@material-ui/pickers';

const CustomDatePicker = ({
  format,
  inputVariant,
  maxDate,
  minDate,
  onChange,
  value,
}) => {
  return (
    <KeyboardDatePicker
      format={format}
      inputVariant={inputVariant}
      maxDate={maxDate}
      minDate={minDate}
      onChange={onChange}
      value={value}
    />
  );
};

CustomDatePicker.defaultProps = {
  ampm: false,
  classes: {},
  format: 'yyyy/MM/dd',
  inputVariant: 'outlined',
  margin: 'none',
  onChange: () => {},
  value: new Date(),
};

export default CustomDatePicker;
