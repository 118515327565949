import React, { Component } from 'react';
import MenuIcon from './MenuIcon';
// Constant
import { neutral } from '../../../shared/styles/colors';

class FilterMenuIcon extends Component {
  d2 =
    'M16.498 18.43V21v-2.57zM3 4.285h10.686H3zm15.469 0h2.248-2.248zM13.686 3v2.57V3zM3 12h5.06H3zm9.844 0h7.873-7.873zM8.06 10.715v2.57-2.57zm-5.061 9h13.498H3zm18 0h-.283H21z';

  render() {
    const { selected } = this.props;

    return (
      <MenuIcon
        d2={this.d2}
        gFill={'none'}
        selected={selected}
        stroke={neutral.D20}
      />
    );
  }
}

export default FilterMenuIcon;
