import { useOutStockText } from '../hook';
import { ProductOutstock } from '../product-outstock';

import useStyles from './ProductOutstockList.styles';
import useRootStyles from '../MenuOutOfStockView.styles';

export function ProductOutstockList({ products = [] }) {
  const _classes = useStyles();
  const rootClasses = useRootStyles();
  const title = useOutStockText({ ctaName: 'productTitle' });
  return (
    <div className={_classes.root}>
      <div className={_classes.titleContainer}>
        <span className={rootClasses.title}>{title}</span>
      </div>
      <div>
        {products.map((product, index) => {
          return (
            <ProductOutstock
              key={`t-${index}-${product.id}`}
              product={product}
              showDetails={true}
            />
          );
        })}
      </div>
    </div>
  );
}
