import React, { Component } from 'react';
import MenuIcon from './MenuIcon';

class RegulrScheduleIcon extends Component {
  d2 =
    'M3 21h18V4.688H3zM6.937 3v3.349M12 3v3.349M17.063 3v3.349M7.944 11.364h8.112M7.944 15.583h8.112';
  render() {
    const { selected } = this.props;

    return <MenuIcon d2={this.d2} selected={selected} />;
  }
}

export default RegulrScheduleIcon;
