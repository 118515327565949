import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../shared/styles/colors';

export default makeStyles({
  root: {
    maxWidth: '22rem',
    padding: '0.75rem 0.5rem',
    gap: '0.5rem',
    fontSize: '0.75rem',
    fontWeight: '600',
    lineHeight: '1.25rem',
    fontFamily: 'Poppins',
    color: neutral['90'],
    background: neutral.white,
  },
  deleteIconChip: {
    width: '1rem',
    margin: 0,
    color: neutral['50'],
  },
  label: {
    padding: 0,
  },
  icon: {},
});
