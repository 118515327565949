import React from 'react';
import { Box, Button, Checkbox } from '@material-ui/core';
import Typography from '../../../../common/typography';
import OrderMgmtStyles from '../../../OrderManagement.style';
import { OrderMgmtProduct } from '../../../../../business/order-mgmt';
import { Variants } from '../../../../common/buttons/custom-button';

function DeactivatedTopping({
  toppingId,
  categoryName,
  name,
  productId,
  isSelected,
  handleSelectTopping,
}) {
  const componentClasses = OrderMgmtStyles();
  return (
    <Box
      display='flex'
      flexDirection='column'
      gridGap='0.25rem'
      paddingTop='0.75rem'
    >
      <Typography classes={{ root: componentClasses.toppingCategory }}>
        {categoryName}
      </Typography>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography classes={{ root: componentClasses.deactivatedItem }}>
          {name}
        </Typography>
        <Checkbox
          color='primary'
          checked={isSelected}
          classes={{ root: componentClasses.itemCheckbox }}
          onChange={() => handleSelectTopping(productId, toppingId)}
        />
      </Box>
    </Box>
  );
}

function DeactivatedProduct({
  id: productId,
  name,
  toppings,
  isSelected: isProductSelected,
  handleSelectProduct,
  handleSelectTopping,
  deactivateToppingIds,
}) {
  const componentClasses = OrderMgmtStyles();
  return (
    <Box
      className={componentClasses.deactivatedProduct}
      display='flex'
      padding='0.75rem 1.25rem'
      flexDirection='column'
    >
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography classes={{ root: componentClasses.deactivatedItem }}>
          {name}
        </Typography>
        <Checkbox
          color='primary'
          checked={isProductSelected}
          classes={{ root: componentClasses.itemCheckbox }}
          onChange={() => handleSelectProduct(productId)}
        />
      </Box>
      {toppings?.map(
        ({
          id: toppingId,
          categoryName,
          name,
          isSelected: isToppingSelected,
        }) =>
          !deactivateToppingIds.includes(+toppingId) && (
            <DeactivatedTopping
              key={`topping-${toppingId}`}
              productId={productId}
              toppingId={toppingId}
              categoryName={categoryName}
              name={name}
              isSelected={isToppingSelected}
              handleSelectTopping={handleSelectTopping}
            />
          ),
      )}
    </Box>
  );
}

export function OrderMgmtDeactivatedItems({
  products,
  deactivatedItemsText,
  continueButtonText,
  handleSelectProduct,
  handleSelectTopping,
  isOfferReplaceItemsEnabled,
  handleDeactivateProductsAndToppings,
  canSendChangesByTime,
  deactivateProductIds,
  deactivateToppingIds,
}: Props) {
  const componentClasses = OrderMgmtStyles();
  return (
    <>
      <Box className={componentClasses.deactivatedItemsDisclaimer}>
        <Typography>{deactivatedItemsText}</Typography>
      </Box>
      <Box className={componentClasses.deactivatedItemsContainer}>
        {products?.map(
          ({ id, name, toppings, isSelected }) =>
            !deactivateProductIds.includes(+id) && (
              <DeactivatedProduct
                key={`product-${id}`}
                id={id}
                name={name}
                toppings={toppings}
                isSelected={isSelected}
                handleSelectProduct={handleSelectProduct}
                handleSelectTopping={handleSelectTopping}
                deactivateToppingIds={deactivateToppingIds}
              />
            ),
        )}
      </Box>
      <Box display='flex' justifyContent='flex-end' flex={1} marginTop='1rem'>
        <Button
          onClick={handleDeactivateProductsAndToppings}
          color='primary'
          variant={Variants.TEXT}
          fullWidth={false}
          disabled={!isOfferReplaceItemsEnabled || !canSendChangesByTime}
        >
          {continueButtonText}
        </Button>
      </Box>
    </>
  );
}

interface Props {
  products: Array<OrderMgmtProduct>;
  deactivatedItemsText: string;
  continueButtonText: string;
  handleSelectProduct: any;
  handleSelectTopping: any;
  isOfferReplaceItemsEnabled: boolean;
  handleDeactivateProductsAndToppings: any;
  canSendChangesByTime: boolean;
  deactivateProductIds: Array<number>;
  deactivateToppingIds: Array<number>;
}
