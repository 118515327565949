import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectors as partnerSelectors } from '../../../redux/partner';

import { storeStatusStatusOpts as SSSOpts } from '../constants';

import type {
  ItfStoreStatusDetail,
  MultiStoreNotificationItemProps,
  TpStoreStatusStatus,
} from '../types';

function useStoresStatusNotification(): MultiStoreNotificationItemProps[] {
  return useSelector(partnerSelectors.selectSimpleStoreStatusNotification);
}

function useStoresStatusDetails(): ItfStoreStatusDetail | null {
  return useSelector(partnerSelectors.selectStoreStatusDetail)[0] ?? null;
}

function usePartnerId(): number {
  return useSelector(partnerSelectors.selectProfile)?.id || 0;
}

function useGetIsSingleAndState(props: {
  list: MultiStoreNotificationItemProps[];
}): { isSingleStore: boolean; primaryState: TpStoreStatusStatus } {
  const { list = [] } = props;
  const [isSingleStore, setIsSingleStore] = useState(false);
  const [primaryState, setPrimaryState] = useState<unknown>(
    SSSOpts.SUSPENDED.status,
  );
  useEffect(() => {
    if (list.length) setPrimaryState(list[0].state);
    else setPrimaryState('UNKNOWN');
    if (list.length === 1 && list[0].total === 1) setIsSingleStore(true);
    else setIsSingleStore(false);
  }, [list]);
  // @ts-ignore
  return { isSingleStore, primaryState };
}

function useIsStoreStatusDetailLoading() {
  return useSelector(partnerSelectors.selectIsStoreStatusDetailLoading);
}

function useIsPartnerStateLoading() {
  return useSelector(partnerSelectors.selectIsPartnerStateLoading);
}

function useGetHasActivatedAllStores(props: {
  list: MultiStoreNotificationItemProps[];
}) {
  const { list } = props;
  return list.every(store => store?.state === 'ACTIVE');
}

function useGetHasDeactivatedAllStores(props: {
  list: MultiStoreNotificationItemProps[];
}) {
  const { list } = props;
  return list.every(store => store.state !== 'ACTIVE');
}

export const hooks = {
  usePartnerId,
  useStoresStatusDetails,
  useStoresStatusNotification,
  useGetIsSingleAndState,
  useIsStoreStatusDetailLoading,
  useIsPartnerStateLoading,
  useGetHasActivatedAllStores,
  useGetHasDeactivatedAllStores,
};
