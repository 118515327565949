import { OrderBill } from './../order-bill';
import { SummarySalesBill } from './../sales-summary-bill';

function PrinterManagment({ someOrdersPendingToPrint }) {
  return (
    <div>
      {someOrdersPendingToPrint && <OrderBill />}
      {!someOrdersPendingToPrint && <SummarySalesBill />}
    </div>
  );
}

export default PrinterManagment;
