import React, { ChangeEvent, useEffect, useState } from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const DECIMAL_PLACES = 2; // e.g: 12.34

interface CurrencyTextFieldProps extends Omit<TextFieldProps, 'onChange'> {
  value: string | number;
  onChange: (value: number) => void;
  currencySymbol?: string;
  inputProps?: React.HTMLAttributes<HTMLInputElement>;
  InputProps?: Partial<TextFieldProps['InputProps']>;
}

/**
 * Format the number to a currency format.
 *
 * @param num The number to format
 * @param padZero Whether to pad the decimal part with zeros
 * @returns The formatted number as a string
 */
const formatNumber = (num: string | number, padZero = false): string => {
  if (!num) {
    return padZero ? `0.${'0'.repeat(DECIMAL_PLACES)}` : '';
  }
  const [integerPart, decimalPart] = num.toString().split('.');
  const formattedInt = new Intl.NumberFormat().format(Number(integerPart));

  if (!padZero && decimalPart === undefined) {
    return formattedInt; // e.g: 1,234
  }

  let decimals = (decimalPart || '').substring(0, DECIMAL_PLACES);
  if (padZero) {
    decimals = decimals.padEnd(DECIMAL_PLACES, '0');
  }

  return `${formattedInt}.${decimals}`;
};

export const CurrencyTextField: React.FC<CurrencyTextFieldProps> = ({
  value,
  onChange,
  inputProps,
  InputProps,
  currencySymbol = '$',
  ...props
}) => {
  const [displayValue, setDisplayValue] = useState('');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/,/g, '');

    // Invalid input
    if (rawValue.match(/[^0-9.]/)) {
      return;
    }

    // If has more than DECIMAL_PLACES digits after dot, don't trigger onChange
    const dpRegex = new RegExp(`\\.[0-9]{${DECIMAL_PLACES + 1}}$`);
    if (rawValue.match(dpRegex)) {
      return;
    }

    const numericValue = parseFloat(rawValue || '0');

    // When we remove a '0' after dot or input a dot: float value does not change
    // So we update the input display value
    if (numericValue === value) {
      setDisplayValue(formatNumber(rawValue));
      return;
    }

    onChange(numericValue);
  };

  // On blur, we add the decimals if they are not present
  const handleBlur = () => {
    if (value !== '') {
      setDisplayValue(formatNumber(value, true));
    }
  };

  useEffect(() => {
    setDisplayValue(formatNumber(value));
  }, [value]);

  return (
    <TextField
      {...props}
      value={displayValue}
      onBlur={handleBlur}
      onChange={handleInputChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>{currencySymbol}</InputAdornment>
        ),
        ...InputProps,
      }}
      inputProps={inputProps}
    />
  );
};
