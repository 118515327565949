const title = {
  id: 'suggestProductDialog.title',
};
const subTitle = {
  id: 'suggestProductDialog.subTitle',
};
const description = {
  id: 'suggestProductDialog.description',
};
const infoText = {
  id: 'suggestProductDialog.infoText',
};
const charLimitText = {
  id: 'suggestProductDialog.charLimitText',
};
const charDefaultText = {
  id: 'suggestProductDialog.charDefaultText',
};
const replaceWithText = {
  id: 'suggestProductDialog.replaceWithText',
};
const nothaveProductText = {
  id: 'suggestProductDialog.notHaveProduct',
};
const rejectOrder = {
  id: 'suggestProductDialog.rejectOrder',
};

const productPlaceHolder = { id: 'suggestProductDialog.placerHolder' };

const confirm = { id: 'suggestProductDialog.send' };

const textIds = {
  charLimitText,
  description,
  infoText,
  nothaveProductText,
  rejectOrder,
  replaceWithText,
  subTitle,
  title,
  productPlaceHolder,
  confirm,
  charDefaultText,
};

export default textIds;
