import { ActionTypes } from './consts'
import { Action } from './interfaces'
// Actions
import {
  actions as dynAlertSnackbarActions,
  Button,
} from '../../redux/dyn-alert-snackbar'
import { actions as dynInAppDialogActions } from '../../redux/dyn-in-app-dialog'
import { get } from 'lodash'
import { Element } from './interfaces'
import { StyleRules } from '../../components/common/custom-alert-snackbar/components/custom-base-alert-snackbar/interfaces/styleRules'
import { HashMap } from '../../interfaces'
import NotificationsDrawerActions from '../../redux/actions/notifications-drawer-actions'
import history from '../../history'
import appActions from '../../redux/actions/app-actions'
import { PWEX, PWEXCodes } from '../../exceptions'
import AppActions from '../../redux/actions/app-actions'
import ChromeExtensionService from '../../services/ChromeExtensionService'
import { Serverities } from '../../constants/Severities'
import partnerActions from '../../redux/actions/partners-actions/partners.actions'

export const executeAction = (action: Action) => async (dispatch: any) => {
  try {
    switch (action.type) {
      case ActionTypes.NAVIGATE_TO:
        const deepLink = get(action, 'payload.deeplink', null)
        if (!!deepLink) {
          history.push(deepLink)
        }
        return
      case ActionTypes.OPEN_ALERT_SNACKBAR:
        dispatch(openAlertSnackbar(action))
        return
      case ActionTypes.OPEN_NOTIFICATIONS_DRAWER:
        dispatch(NotificationsDrawerActions.open())
        return
      case ActionTypes.OPEN_IN_APP_DIALOG:
        dispatch(openInApp(action))
        return
      case ActionTypes.CLOSE_IN_APP_DIALOG:
        return
      case ActionTypes.TURN_PARTNER_ON:
        return dispatch(partnerActions.activatePartner())
      default:
        const PWEXCode = PWEXCodes.PWEX14
        const ex = new PWEX(PWEXCode, { id: PWEXCode }, { action })
        await AppActions.handleCatch(ex, false)(dispatch)
        return
    }
  } catch (ex) {
    await appActions.handleCatch(ex)(dispatch)
    return
  }
}

const openAlertSnackbar = (action: Action) => (dispatch: any) => {
  const id = get(action, ['payload', 'component', 'id'], null)
  const children: Element[] =
    get(action, ['payload', 'component', 'children'], []) || []
  const styles: HashMap<string> =
    get(action, ['payload', 'component', 'style'], {}) || {}

  const messageConfig = children.find(child => child.id === 'description')
  const message = messageConfig?.textContent
  const messageStyle = messageConfig?.style

  const buttonsConfigs = children.filter(
    child =>
      child.id === 'main-button' ||
      child.id === 'secondary-button' ||
      child.id === 'third-button',
  )

  const closeButtonConfig = children.find(child => child.id === 'close-button')

  const closeButtonStyle = get(closeButtonConfig, 'style')

  const buttons = buttonsConfigs.map<Button>(buttonConfig => {
    const buttonStyles = buttonConfig.style || {}
    const backgroundColor = buttonStyles['background-color']
    const color = buttonStyles['color']
    const fontSize = buttonStyles['font-size']

    return {
      styleRules: {
        disabledSubmitButton: {},
        root: {},
        submitButton: {
          backgroundColor,
          color,
          fontSize: fontSize && `${fontSize} !important`,
          '&:hover': {
            backgroundColor,
            '@media (hover: none)': {
              backgroundColor,
            },
          },
        },
      },
      onClick: buttonConfig.events?.onclick,
      textContent: buttonConfig.textContent,
    }
  })

  const styleRules: StyleRules = {
    cancelButton: {},
    root: {
      'background-color': styles['background-color'],
    },
    messageContainer: {},
  }

  if (!!closeButtonStyle) {
    styleRules.cancelButton = closeButtonStyle
  }
  if (!!messageStyle) {
    styleRules.messageContainer = messageStyle
  }

  dispatch(dynAlertSnackbarActions.open(id, buttons, styleRules, message))
}

const openInApp = (action: Action) => async (dispatch: any) => {
  const id = get(action, ['payload', 'component', 'id'], null)
  const children: Element[] =
    get(action, ['payload', 'component', 'children'], []) || []
  const messageIdTitle = children.find(child => child.id === 'title')
  const title = messageIdTitle?.textContent || ''
  const messageIdDescription = children.find(
    child => child.id === 'description',
  )
  const description = messageIdDescription?.textContent || ''
  const mainButtonConfig = children.find(child => child.id === 'main-button')
  const mainButton = {
    textContent: mainButtonConfig?.textContent || '',
    onClick: mainButtonConfig?.events?.onclick,
  }
  const closeButtonConfig = children.find(child => child.id === 'close-button')
  const closeButton = {
    textContent: closeButtonConfig?.textContent || '',
    onClick: closeButtonConfig?.events?.onclick,
  }

  dispatch(
    dynInAppDialogActions.open(
      id,
      title,
      description,
      mainButton,
      closeButton,
      Serverities.ERROR,
      true,
    ),
  )
  await ChromeExtensionService.updateAudio()
}
