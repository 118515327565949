import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';
import RightIcon from '@material-ui/icons/ChevronRightRounded';

import { OrderTypeChip } from '../order-type-chip';
import { KanbanCardFooter } from '../kanban-card-footer';
import { CookingProgressTimeChip } from './components';
import { DeliveryMethodLabel } from '../delivery-method-label';
import { TurboTag } from '../../features/order/geoqueues/components';
import { RtArrivesOrWaitingTime } from '../rt-arrives-or-waiting-time';
import { OrderInfoKanbanCardSection } from '../order-info-kanban-card-section';
import { CheckCode } from './components/check-code';

import { useMainKanbanCard, useTurboOrderMainCard } from './hooks';

import makeClasses from './MainKanbanCard.style';

import type { OrderResponseFromStatusV2 } from '../../business/shared/infra';
import { ErrorOutline } from '@material-ui/icons';

export function MainKanbanCard({ order }: Props) {
  const {
    orderId,
    storeId,
    storeName,
    hasMoreOrdersReady,
    orderIds,
    notReadyOrderIds,
    showChipOfBundle,
    isMarketplace,
    cookingTime,
    cookingTimeStartedAt,
    partnerOrderState,
    deliveryMethod,
    rtStep,
    isRtWaitingMuch,
    showTimeChip,
    texts,
    handleOpenOrderDetail,
    handleOrderCTABtn,
    handleRTBtn,
    isGeoqueue,
    productConfirmationCode,
    isInCooking,
    hasOrderDeliveryDetailsUpdated,
    isOpenOrderDeliveryDetails,
    onToggleOpenOrderDeliveryDetails,
    showOrderDeliveryDetails,
    hasOrderDeliveryDetailMessage,
    showRtWaitingMessage,
    isMandatory
  } = useMainKanbanCard({ order });

  const { isTurboqueue, orderCreationStatus } = useTurboOrderMainCard({
    order,
  });

  const style = makeClasses({ orderCreationStatus });

  return (
    <article
      data-order-id={orderId}
      className={clsx(style.article, style.bounceIn)}
    >
      {isTurboqueue && (
        <TurboTag
          parentClass={style.turboTag}
          orderCreationStatus={orderCreationStatus}
        />
      )}
      <header hidden={!showChipOfBundle}>
        <OrderTypeChip orderType={showChipOfBundle ? 'bundle' : 'normal'} />
      </header>
      <main onClick={hasMoreOrdersReady ? undefined : handleOpenOrderDetail}>
        <section>
          {orderIds.map(
            ({ id: readyOrderId, productConfirmationBundleCode }) => (
              <div
                key={`go-to-order-id:${readyOrderId}`}
                data-order-id={readyOrderId}
                onClick={handleOpenOrderDetail}
                className={style.orderInfoById}
              >
                <OrderInfoKanbanCardSection
                  orderId={readyOrderId}
                  storeName={storeName}
                  orderCreationStatus={orderCreationStatus}
                />
                {showTimeChip && (
                  <CookingProgressTimeChip
                    isMarketplace={isMarketplace}
                    cookingTime={cookingTime}
                    cookingTimeStartedAt={cookingTimeStartedAt}
                  />
                )}
                {hasMoreOrdersReady && !hasOrderDeliveryDetailMessage && (
                  <Box display='flex' alignItems='center' justifyContent='center' gridGap='0.25rem'>
                    {!!productConfirmationCode && (
                      <CheckCode 
                        label={texts.productConfirmationCode} 
                        code={productConfirmationBundleCode}
                      />
                    )}
                    <RightIcon color='primary' />
                  </Box>
                )}
                {showRtWaitingMessage && (
                  <Box display='flex' alignItems='center' gridGap='0.25rem'>
                    <Typography className={style.orderUpdateDetailAlertText}>
                      {texts.mandatoryInput.rtWaitingDetails}
                    </Typography>
                    <ErrorOutline className={style.orderUpdateDetailAlertIcon} />
                  </Box>
                )}
              </div>
            ),
          )}
        </section>
        <section className={style.rtSection}>
          <DeliveryMethodLabel
            isGeoqueue={isGeoqueue}
            rtStep={rtStep}
            deliveryMethod={deliveryMethod}
            isRtWaitingMuch={isRtWaitingMuch}
            partnerOrderState={partnerOrderState}
            rtStepMessage={
              <Typography className={style.etaStepMsg}>
                <span className={style.etaStepMsgStrong}>
                  {texts.etaStepMsg[0]}
                </span>
                {texts.etaStepMsg[1]}
              </Typography>
            }
          />
          <RtArrivesOrWaitingTime orderId={orderId} />
        </section>
      </main>
      <footer
        hidden={
          !(
            texts.waitingFor ||
            texts.orderCTABtn ||
            texts.rtBtn ||
            productConfirmationCode
          )
        }
      >
        {!!texts.waitingFor && (
          <Typography variant='subtitle1' className={style.remainingOrders}>
            <label>{texts.waitingFor}&nbsp;</label>
            <span>
              {notReadyOrderIds.map((id, idx) => (
                <span key={`waiting-order-id-${id}`}>
                  {idx !== 0 && ' / '}
                  <span>{id}</span>
                </span>
              ))}
            </span>
          </Typography>
        )}
        <KanbanCardFooter
          onClick={handleOrderCTABtn}
          buttonText={texts.orderCTABtn}
          secondaryButtonText={texts.rtBtn}
          onClickSecondaryButton={handleRTBtn}
          productConfirmationCode={productConfirmationCode}
          productConfirmationCodeLabel={texts.productConfirmationCode}
          isInCooking={isInCooking}
          hasMoreOrdersReady={hasMoreOrdersReady}
          orderId={orderId}
          storeId={storeId}
          orderState={partnerOrderState}
          hasOrderDeliveryDetailsUpdated={hasOrderDeliveryDetailsUpdated}
          isOpenOrderDeliveryDetails={isOpenOrderDeliveryDetails}
          onToggleOpenOrderDeliveryDetails={onToggleOpenOrderDeliveryDetails}
          completeOrderDetailText={texts.mandatoryInput.completeOrderDetailText}
          showOrderDeliveryDetails={showOrderDeliveryDetails}
          hasOrderDeliveryDetailMessage={hasOrderDeliveryDetailMessage}
          isMandatory={isMandatory}
        />
      </footer>
    </article>
  );
}

interface Props {
  order: OrderResponseFromStatusV2;
}
