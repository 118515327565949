import { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';

import { StyleProps, progressCircularStyle } from './OrderProgress.style';
import { useOrderProgress } from './useOrderProgress';

export function OrderProgress({
  createdAt,
  countDownTime,
  position = 'vertical',
  hasWarningTime = false,
}: Props) {
  const defaultStyleProps: StyleProps = {
    isHorizontal: position === 'horizontal',
    hasWarningTime: false,
  };
  const [styleProps, setStyleProps] = useState(defaultStyleProps);
  const { percent, remainingTime, isEnabledWarningTime } = useOrderProgress({
    createdAt,
    countDownTime,
  });
  const styles = progressCircularStyle(styleProps);

  useEffect(() => {
    setStyleProps(prev => ({
      ...prev,
      hasWarningTime: hasWarningTime && isEnabledWarningTime,
    }));
  }, [isEnabledWarningTime]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      minWidth='5.7rem'
      className={styles.wrapper}
    >
      <Box
        position='relative'
        display='flex'
        alignItems='center'
        order={styleProps.isHorizontal ? 2 : 1}
      >
        <CircularProgress
          variant='static'
          className={styles.bottom}
          size={70}
          thickness={5}
          value={100}
        />
        <CircularProgress
          variant='static'
          className={styles.top}
          classes={{
            circle: styles.circle,
          }}
          size={70}
          thickness={5}
          value={percent}
        />
        <Typography className={styles.progressLabel}>
          {`${remainingTime.minutes}`.length === 1
            ? `0${remainingTime.minutes}`
            : remainingTime.minutes}
          :
          {`${remainingTime.seconds}`.length === 1
            ? `0${remainingTime.seconds}`
            : remainingTime.seconds}
        </Typography>
      </Box>
    </Box>
  );
}

interface Props {
  createdAt: string;
  position?: 'vertical' | 'horizontal';
  countDownTime: number;
  hasWarningTime?: boolean;
}
