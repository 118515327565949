// Redux
import { createActions } from 'redux-actions';

const {
  clearErrorTextAction,
  clearOrderWhimDialogAction,
  closeOrderWhimDialogAction,
  openOrderWhimDialogAction,
  setErrorTextAction,
} = createActions({
  CLOSE_ORDER_WHIM_DIALOG_ACTION: () => {},
  CLEAR_ORDER_WHIM_DIALOG_ACTION: () => {},
  OPEN_ORDER_WHIM_DIALOG_ACTION: (productName, productId, orderId) => ({
    productName,
    productId,
    orderId,
  }),
  SET_ERROR_TEXT_ACTION: (errorCode, messageError) => ({
    errorCode,
    messageError,
  }),
  CLEAR_ERROR_TEXT_ACTION: () => {},
});

const clearOrderWhimDialog = () => dispatch => {
  dispatch(clearOrderWhimDialogAction());
};

const closeOrderWhimDialog = () => dispatch => {
  dispatch(closeOrderWhimDialogAction());
};

const openOrderWhimDialog = (productName, productId, orderId) => dispatch => {
  dispatch(openOrderWhimDialogAction(productName, productId, orderId));
};
const setErrorText = (errorCode, messageError) => dispatch => {
  dispatch(setErrorTextAction(errorCode, messageError));
};
const clearErrorText = () => dispatch => {
  dispatch(clearErrorTextAction());
};

export default {
  clearErrorText,
  clearErrorTextAction,
  clearOrderWhimDialog,
  clearOrderWhimDialogAction,
  closeOrderWhimDialog,
  closeOrderWhimDialogAction,
  openOrderWhimDialog,
  openOrderWhimDialogAction,
  setErrorText,
  setErrorTextAction,
};
