// React
import { connect } from 'react-redux'
// Components
import PrinterManagment from './PrinterManagment'

import { injectIntl } from 'react-intl'

const mapStateToProps = (
  { ordersReducer: { orders } },
  { intl: { formatMessage } },
) => {
  const someOrdersPendingToPrint = orders.some(order => order.pendingToPrint)

  return {
    someOrdersPendingToPrint,
  }
}
const mapDispatchToProps = {}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(PrinterManagment),
)
