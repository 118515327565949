// External libs
import { createSelector } from 'reselect';

const selectReducer = state => state.rtDialogReducer;

const selectOpen = createSelector(
  selectReducer,
  reducer => reducer.open,
);

const selectOrderId = createSelector(
  selectReducer,
  reducer => reducer.orderId,
);

const RTDialogSelectors = { selectOpen, selectOrderId };

export default RTDialogSelectors;
