// React
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
// Redux
import storesSchedulesActions from '../../redux/actions/storesSchedules.actions'
// Components
import StoreScheduleIntervals from './StoreScheduleIntervals'

const mapStateToProps = (
  {
    storesSchedulesReducer: { beingEditedScheduleId, dayIdBeingAddingSchedule },
  },
  { intl: { formatMessage } },
) => {
  const addIntervalButtonText = formatMessage({
    id: 'storesScheduleInterval.addHour',
  })
  return {
    addIntervalButtonText,
    beingEditedScheduleId,
    dayIdBeingAddingSchedule,
  }
}

const mapDispatchToProps = {
  onClickConfirm: storesSchedulesActions.confirmBeingEditingSchedule,
  onClickEditSchedule: storesSchedulesActions.setBeingEditedSchedule,
  onClickEditingScheduleCancel: storesSchedulesActions.setBeingEditedSchedule,
  onClickShowAddSchedule: storesSchedulesActions.setDayIdBeingAddingSchedule,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(StoreScheduleIntervals),
)
