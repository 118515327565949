// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => ({
  iconRoot: {
    position: 'relative',
    height: '2rem',
    width: '2rem',
  },
  message: {
    display: '-webkit-box',
    overflow: 'hidden',
    whiteSpace: 'pre-wrap',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    lineHeight: 1.5,
    color: neutral.D20,
  },
  root: {
    color: neutral.D20,
    display: 'grid',
    alignItems: 'center',
    gridColumnGap: 6,
    gridAutoFlow: 'column',
    gridAutoColumns: 'min-content auto',
    fontSize: '0.75rem',
    lineHeight: 'normal',
  },
  tooltip: { margin: '2px 0px' },
}));
