import React from 'react';
import CustomDialog from '../../../common/custom-dialog/CustomDialog';
import CustomButton, {
  Types,
  Variants,
} from '../../../common/buttons/custom-button';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { OrderMgmtAcceptChangesDialogStyles } from './OrderMgmtAcceptRemoveChangesDialog.style';

export function OrderMgmtAcceptRemoveChangesDialog({
  isOpen,
  onClose,
  onAccept,
  removedProducts,
  acceptRemoveChangesTitleText,
  acceptRemoveChangesDescriptionText,
  acceptRemoveChangesCancelButtonText,
  acceptRemoveChangesConfirmButtonText,
}: Props) {
  const componentClasses = OrderMgmtAcceptChangesDialogStyles();
  const theme = useTheme<any>();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <CustomDialog
      classes={{
        root: componentClasses.root,
        paper: componentClasses.paper,
        headerRoot: componentClasses.header,
      }}
      onClose={onClose}
      open={isOpen}
      showBackButton={!isMdUp}
      showCloseButton={isMdUp}
    >
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        padding='1rem'
        width='100%'
        height='100%'
      >
        <Typography className={componentClasses.title}>
          {acceptRemoveChangesTitleText}
        </Typography>
        <Typography className={componentClasses.description}>
          {acceptRemoveChangesDescriptionText}
        </Typography>
        <ul className={componentClasses.items}>
          {removedProducts.map(product => (
            <li key={`rp-${product.id}`} className={componentClasses.item}>
              {product.name}
            </li>
          ))}
        </ul>
        <Box
          display='flex'
          flex={1}
          alignItems='center'
          justifyContent='flex-end'
          gridGap='1rem'
          width='100%'
        >
          <CustomButton
            onClick={onClose}
            type={Types.RESET}
            variant={Variants.TEXT}
            fullWidth={false}
          >
            {acceptRemoveChangesCancelButtonText}
          </CustomButton>
          <CustomButton
            onClick={onAccept}
            type={Types.BUTTON}
            variant={Variants.CONTAINED}
            fullWidth={false}
          >
            {acceptRemoveChangesConfirmButtonText}
          </CustomButton>
        </Box>
      </Box>
    </CustomDialog>
  );
}

interface Props {
  isOpen: boolean;
  onClose: any;
  onAccept: any;
  removedProducts: any;
  acceptRemoveChangesTitleText: string;
  acceptRemoveChangesDescriptionText: string;
  acceptRemoveChangesCancelButtonText: string;
  acceptRemoveChangesConfirmButtonText: string;
}
