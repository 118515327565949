import { useDispatch } from 'react-redux';

import { browserDialogActions } from './browser.slice';
import { ContentType } from '../../domain';

export function BrowserActions() {
  const dispatch = useDispatch();

  const onClose = () => dispatch(browserDialogActions.close());
  const onOutside = () => dispatch(browserDialogActions.outside());
  const onOpen = () => dispatch(browserDialogActions.dialogOpen());
  const onSeen = () => dispatch(browserDialogActions.dialogSeen());
  const onContent = (value: ContentType) =>
    dispatch(browserDialogActions.content(value));

  return {
    onClose,
    onOpen,
    onSeen,
    onContent,
    onOutside,
  };
}
