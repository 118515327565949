// Material UI
import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  advancedFilterToolbarRoot: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '16px',
      marginRight: '24px',
    },
  },
}));
