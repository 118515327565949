// React
import React, { useEffect } from 'react';
// Components
import OrderDetailDialogMainSection from '../order-detail-dialog-main-section';
import OrderDetailDialogProductsSection from '../order-detail-dialog-products-section';
import OrderManagementActionsSection from './components/order-management-actions-section/OrderManagementActionsSection';
import { TotalDetailSection } from './components/total-detail-section';
import { OrderDetailDisclaimer } from '../order-management/shared';

// Styles
import useStyles from './OrderDetailDialogBody.styles';
import { Box } from '@material-ui/core';
import {
  orderDetailApp,
  orderMgmtState,
  showOrderDetailTimeout,
} from '../../business/order-mgmt';
import { OrderMgmtProducts } from '../order-management/components/order-mgmt-products/OrderMgmtProducts';
import { OrderMgmtAnalytics } from '../../business/order-mgmt/analitycs';
import PartnerOrderStateType from '../../constants/PartnerOrderStateType';
import { CountdownToEdit } from '../order-management/components/countdown-edit';
import { TimeoutOrderActions } from '../order-management/timeout';
import { orderMgmtDetailFacade } from '../../business/order-mgmt/infra/store/order-detail';

const OrderDetailDialogBody = ({
  showActionBox,
  orderDetail,
  editTitle,
  editDescription,
}) => {
  const componentClasses = useStyles();
  const {
    selectIsDisabled,
    selectProducts,
    onClearState,
    onDisabledByState,
    selectOrderState,
  } = orderMgmtDetailFacade();
  const {
    get: getOrderDetail,
    handle: handleOrderDetail,
    isTimeoutOrder,
  } = orderDetailApp();
  const isNewOrder =
    orderDetail?.partner_order_state === PartnerOrderStateType.NEWORDER;
  const {
    hasOrderManagement,
    showProductsWithOM,
    orderManagement,
    orderId,
    storeId,
    isEnabledToEdit,
  } = getOrderDetail();
  const isInEditionMode =
    selectOrderState === orderMgmtState.SendUserApproval ||
    selectOrderState === orderMgmtState.NoUserResponse;

  const showTimeoutActions = showOrderDetailTimeout(
    orderDetail?.state_order_management?.state,
    orderDetail?.partner_order_state,
  );

  useEffect(() => {
    if (hasOrderManagement) {
      onDisabledByState(
        orderManagement?.state,
        orderDetail?.partner_order_state,
      );
      handleOrderDetail();
    }
    return () => onClearState();
  }, []);

  useEffect(() => {
    if (!isNewOrder) return;

    OrderMgmtAnalytics.whenOpenOrderStatus(
      { orderId, storeId },
      { isEnabledToEdit },
    );
  }, []);

  return (
    <Box className={componentClasses.container}>
      {isInEditionMode && (
        <CountdownToEdit title={editTitle} description={editDescription} />
      )}
      {hasOrderManagement && <OrderDetailDisclaimer order={orderManagement} />}
      <div className={componentClasses.root}>
        {selectIsDisabled && (
          <Box className={componentClasses.blockContent}></Box>
        )}
        {/* sidebar */}
        <OrderDetailDialogMainSection
          classes={{ root: componentClasses.mainSectionRoot }}
        />
        {/* product list */}
        <Box className={componentClasses.productsSectionRoot}>
          {showProductsWithOM && (
            <OrderMgmtProducts
              products={selectProducts}
              orderId={orderDetail.id}
              isTimeoutState={isTimeoutOrder}
              isEnabled={false}
            />
          )}
          {!showProductsWithOM && <OrderDetailDialogProductsSection />}
        </Box>

        {/* Valor total (detail) footer */}
        <TotalDetailSection
          classes={{ root: componentClasses.totalDetailSectionRoot }}
        />
        {/* action buttons */}
        {showTimeoutActions ? (
          <TimeoutOrderActions />
        ) : (
          showActionBox && (
            <OrderManagementActionsSection
              classes={{ root: componentClasses.orderManagementActionsRoot }}
              isDisabled={selectIsDisabled}
            />
          )
        )}
      </div>
    </Box>
  );
};

export default OrderDetailDialogBody;
