// External libs
import { createAction } from '@reduxjs/toolkit'

const closeAction = createAction('errorPMFlowDialog/close')

const clearAction = createAction('errorPMFlowDialog/clear')

const maximizeAction = createAction('errorPMFlowDialog/maximize')

const openAction = createAction('errorPMFlowDialog/open')

const clear = () => (dispatch: any) => {
  dispatch(clearAction())
}

const close = () => (dispatch: any) => {
  dispatch(closeAction())
}

const maximize = () => (dispatch: any) => dispatch(maximizeAction())

const open = () => (dispatch: any) => {
  dispatch(openAction())
}
const actions = {
  clear,
  clearAction,
  close,
  closeAction,
  maximize,
  maximizeAction,
  open,
  openAction,
}

export default actions
