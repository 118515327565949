// Material UI
import { makeStyles } from '@material-ui/core';
// Constant
import { neutral } from '../../../shared/styles/colors';

export default makeStyles({
  label: {
    fontFamily: 'Poppins',
    color: neutral.D20,
    fontSize: '0.811em',
    marginLeft: '8px',
  },
  control: {
    color: neutral.D15,
    margin: '0px',
    padding: '0px',
  },
});
