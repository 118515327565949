import { useState, useCallback, useEffect } from 'react';

import StringUtils from '../../utils/StringUtils';

import CustomDialog from '../common/custom-dialog';
import { CustomReadOnlyPrefixPhone } from '../common/custom-read-only-prefix-phone';
import CustomTextField from '../common/custom-text-field';

import useStyles from './CustomerPhoneProxySessionDialog.styles';

function CustomerPhoneProxySessionDialog(props) {
  const {
    clearErrorText,
    confirmButtonText,
    countryFlag,
    countryPrefixCode,
    description,
    helperText,
    onBack,
    onClickConfirm,
    onClose,
    open,
    phoneNumber,
    phonePrefixInputPlaceHolder,
    title,
    isOM,
  } = props;

  const MIN_PHONE_NUMBER_LENGTH = 5;

  const [localPhoneNumber, setLocalPhoneNumber] = useState(phoneNumber);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);

  const componentClasses = useStyles();

  const handleKeyUp = useCallback(
    event => {
      if (!event) {
        return;
      }
      const keyValue = event?.key;
      const isNumber = StringUtils.isNumber(keyValue);
      if (!isNumber) {
        event.preventDefault();
        return;
      }
      clearErrorText();
    },
    [clearErrorText],
  );

  const validatePhone = useCallback(phoneNumber => {
    const isValid =
      StringUtils.isNumber(phoneNumber) &&
      phoneNumber.length > MIN_PHONE_NUMBER_LENGTH;
    setIsPhoneNumberValid(isValid);
  }, []);

  const handleBack = useCallback(() => {
    // To avoid unnecesary actions dispatching on input phone number change, local state is not fully connected to redux state.
    // So, it is mandatory to clean local phone number state on back.
    setLocalPhoneNumber(null);
    onBack();
  }, [onBack, setLocalPhoneNumber]);

  const handleClose = useCallback(() => {
    // To avoid unnecesary actions dispatching input phone number change, local state is not fully connected to redux state.
    // So, it is mandatory to clean local phone number state on close.
    setLocalPhoneNumber(null);
    onClose();
  }, [onClose, setLocalPhoneNumber]);

  const localOnClickConfirm = useCallback(async () => {
    await onClickConfirm(localPhoneNumber, isOM);
  }, [onClickConfirm, localPhoneNumber]);

  useEffect(() => {
    setLocalPhoneNumber(phoneNumber);
  }, [phoneNumber, validatePhone]);

  useEffect(() => {
    validatePhone(localPhoneNumber);
  }, [localPhoneNumber, validatePhone]);

  return (
    <CustomDialog
      classes={{
        headerRoot: componentClasses.headerRoot,
        footerRoot: componentClasses.footerRoot,
        paper: componentClasses.paper,
      }}
      actions={[
        {
          text: confirmButtonText,
          onClick: localOnClickConfirm,
          color: 'primary',
          variant: 'contained',
          disabled: !isPhoneNumberValid,
          fullWidth: false,
        },
      ]}
      onClickBackButton={handleBack}
      onClose={handleClose}
      open={open}
      showBackButton
      showCloseButton
      title={title}
    >
      <div className={componentClasses.body}>
        <span className={componentClasses.description}>{description}</span>
        <div className={componentClasses.inputBox}>
          <CustomReadOnlyPrefixPhone
            classes={{
              root: componentClasses.prefix,
            }}
            flag={countryFlag}
            prefixNumber={countryPrefixCode}
          />
          <CustomTextField
            classes={{
              textFieldRoot: componentClasses.phoneInput,
              notchedOutlineInput: componentClasses.inputLabel,
            }}
            name='input'
            maxLength={20}
            helperText={helperText}
            error={!!helperText}
            required
            onKeyPress={handleKeyUp}
            placeholder={phonePrefixInputPlaceHolder}
            useExternalLabel
            onChange={event => {
              const eventValue = event.currentTarget.value;
              const replacedEventValue = eventValue.replace(
                /[a-z]|,|-|_|ñ|´|' '|\+|\?|=|á|é|ú|ó|í|\*/gi,
                '',
              );
              setLocalPhoneNumber(replacedEventValue);
            }}
            value={localPhoneNumber}
          />
        </div>
      </div>
    </CustomDialog>
  );
}

export default CustomerPhoneProxySessionDialog;
