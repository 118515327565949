import Box from '@material-ui/core/Box';
import CustomDialog from '../../../../../common/custom-dialog/CustomDialog';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CustomButton, {
  Colors,
  Types,
  Variants,
} from '../../../../../common/buttons/custom-button';
import confirmDialogStyles from './confirmAccept.styles';
import { useConfirmDialog } from './useConfirmAccept';

export function ConfirmAccept() {
  const styles = confirmDialogStyles();
  const theme = useTheme<any>();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const {
    closeDialog,
    isOpenDialog,
    confirmAccept,
    itemsListToRemove,
    ...texts
  } = useConfirmDialog();

  return (
    <CustomDialog
      classes={{
        paper: styles.paper,
        headerRoot: styles.header,
      }}
      onClickBackButton={closeDialog}
      onClose={closeDialog}
      open={isOpenDialog}
      showBackButton={!isMdUp}
      showCloseButton={isMdUp}
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        height='100%'
      >
        <Box display='flex' flexDirection='column' className={styles.body}>
          <Typography className={styles.title}>{texts.summaryText}</Typography>
          <Typography className={styles.subtitle}>
            {texts.disclaimerText}
          </Typography>
          <ul className={styles.items}>
            {itemsListToRemove.map(item => (
              <li key={`item-${item.id}`}>{item.name}</li>
            ))}
          </ul>
        </Box>

        <Box display='flex' justifyContent='flex-end' gridGap='1rem'>
          <CustomButton
            onClick={closeDialog}
            type={Types.RESET}
            variant={Variants.TEXT}
            fullWidth={false}
            color={Colors.PRIMARY}
          >
            {texts.cancelbtnText}
          </CustomButton>
          <CustomButton
            onClick={confirmAccept}
            type={Types.BUTTON}
            variant={Variants.CONTAINED}
            fullWidth={false}
            color={Colors.PRIMARY}
          >
            {texts.confirmBtnText}
          </CustomButton>
        </Box>
      </Box>
    </CustomDialog>
  );
}
