import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';
import hooks from './hooks';

import CustomAvatar from '../common/custom-avatar/CustomAvatar';
import CustomClickableBoxList from '../common/boxes/custom-clickable-box-list';
import CustomDialog from '../common/custom-dialog/CustomDialog';

import useStyles from './styles';

export function RTAssignedIssueSuggestionDialog() {
  const componentClasses = useStyles();
  const open = hooks.useOpen();
  const options = hooks.useOptions();
  const title = hooks.useTitle();
  const description = hooks.useDescription();

  const storekeeper = hooks.useStoreKeeper();
  const storeKeeperImage = storekeeper?.profile_pic;
  const storeKeeperFirstName = storekeeper?.first_name;
  const storeKeeperFullName = storekeeper?.full_name;
  const storeKeeperId = storekeeper?.id;
  const storeKeeperPhone = storekeeper?.phone;

  const dispatch = useDispatch();

  const handleBack = useCallback(() => {
    dispatch(dialogsManagerActions.back());
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  return (
    <CustomDialog
      classes={{
        headerRoot: componentClasses.headerRoot,
        paper: componentClasses.paper,
        childrenContainer: componentClasses.container,
      }}
      onClickBackButton={handleBack}
      onClose={handleClose}
      open={open}
      showBackButton
      showCloseButton
      title={title}
    >
      <CustomClickableBoxList
        classes={{ label: componentClasses.label }}
        options={options}
        label={description}
      >
        <div className={componentClasses.RTInfo}>
          <CustomAvatar
            alt='rappitendero'
            letter={storeKeeperFirstName && storeKeeperFirstName.charAt(0)}
            src={storeKeeperImage}
          />
          <span className={componentClasses.listRTName}>
            {storeKeeperFullName}
          </span>
          <span className={componentClasses.listPhone}>
            Tel: {storeKeeperPhone}
          </span>
          <span className={componentClasses.listID}>ID: {storeKeeperId}</span>
        </div>
      </CustomClickableBoxList>
    </CustomDialog>
  );
}
