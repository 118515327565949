// React
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
// External service.
// Consts
// Components
import NoDeactivatedToppingDrawer from './NoDeactivatedToppingDrawer'
import menuActions from '../../redux/actions/menu.actions'

const mapStateToProps = (
  {
    ordersReducer: { orders },
    partnerReducer,
    menuReducer: {
      noDeactivatedToppingDrawer: { open, noDeactivatedToppings },
    },
  },

  { intl: { formatMessage } },
) => {
  const cancelButtonText = formatMessage({
    id: 'noDeactivatedToppingDrawer.cancel',
  })
  const confirmButtonText = formatMessage({
    id: 'noDeactivatedToppingDrawer.accept',
  })
  const explanationText = formatMessage({
    id: 'noDeactivatedToppingDrawer.explanation',
  })
  const toppingsListDescription = formatMessage({
    id: 'noDeactivatedToppingDrawer.toppingsListDescription',
  })
  const title = formatMessage({
    id: 'noDeactivatedToppingDrawer.title',
  })
  const toppingsList = noDeactivatedToppings.map(t =>
    formatMessage(
      { id: 'noDeactivatedToppingDrawer.toppingItem' },
      {
        toppingName: t.description,
        toppingTitle: t.title,
      },
    ),
  )

  return {
    cancelButtonText,
    confirmButtonText,
    explanationText,
    open,
    title,
    toppingsList,
    toppingsListDescription,
    partnerId: partnerReducer.profile.id,
  }
}

const mapDispatchToProps = {
  onClose: menuActions.closeNoDeactivatedToppingDrawer,
}
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(NoDeactivatedToppingDrawer),
)
