import { cargoUIHandler } from '../../infra';
import { AUTO_HIDE_TIME_DURATION, cargoUIHelper } from '../../domain';

import type { CargoUIStatus, IUICase } from '../../domain';

export function CargoUICase(): IUICase {
  const { dispatch, get } = cargoUIHandler();
  const {
    data,
    isDisplayed,
    stores,
    stateStatus,
    storeIdSelected,
    partnerId,
    partnerStores,
    shippingCost,
  } = get();

  const {
    onClear,
    onClearUI,
    onClearNewOrder,
    onSetOpenDialog,
    onSetStatusDialog,
    onSuccessfulDialog,
    onErrorDialog,
    onGetCargoStores,
  } = dispatch();

  const { status, isOpen } = data;
  const isEnabledHeaderBtn = cargoUIHelper.getIsEnabledHeaderBtn(stores);
  const isVisibleHeaderBtn = isDisplayed;
  const isOpenDialog = isOpen && status !== 'idle';
  const isOrderCreated = stateStatus === 'orderCreated';
  const hasPartnerStores = !!partnerStores.length;
  const isOrderRejected = stateStatus === 'orderRejected';
  const isCoverageVerified = stateStatus === 'coverageVerified';
  const isCoverageRejected = stateStatus === 'coverageRejected';

  function fetchStores() {
    const cargoStoresIds = partnerStores.map(store => store.id);
    onGetCargoStores(cargoStoresIds);
  }

  const setStatus = (value: CargoUIStatus) => onSetStatusDialog(value);

  const clearUI = () => onClearUI();

  const clear = () => onClear();

  const showSuccessfulDialog = (
    title = '',
    message = '',
    autoHideDuration = AUTO_HIDE_TIME_DURATION,
  ) => {
    onSuccessfulDialog(title, message, autoHideDuration);
  };

  const showErrorDialog = (
    title = '',
    message = '',
    autoHideDuration = AUTO_HIDE_TIME_DURATION,
  ) => {
    onErrorDialog(title, message, autoHideDuration);
  };

  return {
    isEnabledHeaderBtn,
    isVisibleHeaderBtn,
    status,
    isOrderCreated,
    storeIdSelected,
    partnerId,
    shippingCost,
    fetchStores,
    setStatus,
    clear,
    clearUI,
    clearNewOrder: onClearNewOrder,
    showSuccessfulDialog,
    showErrorDialog,
    hasPartnerStores,
    isOrderRejected,
    isCoverageVerified,
    isCoverageRejected,
    isDisplayed,
    isOpenDialog,
    onSetOpenDialog,
  };
}
