import { clsx } from 'clsx';

import { CustomClickableBox } from '../custom-clickable-box';

import StringUtils from '../../../../utils/StringUtils';
import useStyles from './styles';

import type { Props } from './interfaces';

function CustomClickableBoxList(props: Props) {
  const { classes = {}, options, children, label } = props;

  const _classes = useStyles();

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      {!StringUtils.isNullOrEmpty(label) && (
        <div className={clsx(_classes.label, classes?.label)}>{label}</div>
      )}
      {!!children && (
        <div className={clsx(_classes.children, classes?.children)}>
          {children}
        </div>
      )}
      {options.length > 0 && (
        <div className={clsx(_classes.options, classes?.options)}>
          {options.map((option, index) => (
            <CustomClickableBox
              classes={{ title: _classes.title }}
              description={option.description}
              title={option.title}
              key={index}
              onClick={option.onClick}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default CustomClickableBoxList;
