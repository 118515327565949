import { makeStyles } from '@material-ui/core';

import { neutral } from '../../shared/styles/colors';

export default makeStyles({
  root: {
    height: '100%',
    margin: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
  },
  alert: {
    marginBottom: '1rem',
  },
  orderHistoricalTableContainer: {
    flex: '1 1 1px',
    overflow: 'auto',
  },
  pagination: {
    backgroundColor: neutral.white,
  },
});
