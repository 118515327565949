import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';
import hooks from './hooks/hooks';

import CustomClickableBoxList from '../common/boxes/custom-clickable-box-list';
import CustomDialog from '../common/custom-dialog';

import useStyles from './styles';

export function CookedOrderConfirmationIssueDialog() {
  const componentClasses = useStyles();
  const title = hooks.useTitle();
  const open = hooks.useOpen();

  const options = hooks.useSupportSuggestions();
  const description = hooks.useDescription();

  const dispatch = useDispatch();

  const handleBack = useCallback(() => {
    dispatch(dialogsManagerActions.back());
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  return (
    <CustomDialog
      classes={{
        headerRoot: componentClasses.headerRoot,
        paper: componentClasses.paper,
      }}
      onClickBackButton={handleBack}
      onClose={handleClose}
      open={open}
      showBackButton
      showCloseButton
      title={title}
    >
      <div className={componentClasses.body}>
        <CustomClickableBoxList label={description} options={options} />
      </div>
    </CustomDialog>
  );
}
