import { Drawer } from '@material-ui/core';

import SideMenuItems from './components/side-menu-items';

import useStyles from './SideMenu.styles';

function SideMenu({ open = false, onClose = () => {} }) {
  const _classes = useStyles();
  return (
    <Drawer
      anchor='left'
      classes={{ paper: _classes.drawerPaper }}
      onClose={onClose}
      open={open}
    >
      <SideMenuItems onClose={onClose} />
    </Drawer>
  );
}

export default SideMenu;
