import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';

import { selectors as helpCenterSelectors } from '../../../../redux/help-center-page';

import mobileImgBanner from '../../../../assets/images/banner/mobile-banner.png';
import tabletImgBanner from '../../../../assets/images/banner/tablet-banner.png';
import desktopImgBanner from '../../../../assets/images/banner/desktop-banner.png';

const useSelectedArticle = () =>
  useSelector(helpCenterSelectors.selectSelectedArticle);

const useSelectedCategoryId = () =>
  useSelector(helpCenterSelectors.selectedCategoryId);

const useMediaQueryCode = (theme: any) => {
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isXlUp = useMediaQuery(theme.breakpoints.up('xl'));
  if (isXlUp) {
    return 'xl';
  }
  if (isLgUp) {
    return 'lg';
  }
  if (isMdUp) {
    return 'md';
  }
  if (isSmUp) {
    return 'sm';
  }
  return 'xs';
};

const useImageUrl = (theme: any) => {
  const mediaQueryCode = useMediaQueryCode(theme);
  switch (mediaQueryCode) {
    case 'xs':
      return mobileImgBanner;
    case 'sm':
      return tabletImgBanner;
    case 'md':
      return tabletImgBanner;
    default:
      return desktopImgBanner;
  }
};

const hooks = {
  useSelectedArticle,
  useSelectedCategoryId,
  useImageUrl,
};

export default hooks;
