import { makeStyles } from '@material-ui/styles';

import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  regularStoreScheduleTableRoot: {
    marginTop: '1rem',
  },
  description: {
    margin: '0 0 1rem',
    fontSize: '0.875rem',
    color: neutral.D20,
    letterSpacing: 0,
  },
  customBlocksContainer: {
    [theme.breakpoints.up('md')]: {
      padding: '0 1rem',
    },
  },
}));
