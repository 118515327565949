// External libs
import { get } from 'lodash'

const buildParams = (params, internalParams) => {
  const areParamsValid = !!params && typeof params === 'object'
  const areInternalParamsValid =
    !!internalParams && typeof internalParams === 'object'

  if (!areParamsValid) {
    // If params is invalid and internal params valid, return internalParams.
    // If both params and internal params are invalid, return null.
    return areInternalParamsValid ? { ...internalParams } : null
  }
  // If both params and internal params are valid,
  // merge both and return it to keep both params.
  // If params is valid but internal params isn't, return params only.
  return areInternalParamsValid
    ? { ...params, ...internalParams }
    : { ...params }
}

class PWEX {
  constructor(PWEXCode, formatMessageObj, params = {}, internalEx = {}) {
    const internalExMessage = get(internalEx, 'message', null)
    const internalExParams = get(internalEx, 'params', null)
    const internalExStack = get(internalEx, 'stack', null)
    this.PWEXCode = PWEXCode

    this.formatMessageObj = formatMessageObj
    this.message = internalExMessage
    this.params = buildParams(params, internalExParams)
    this.stack = internalExStack
  }
}

export default PWEX
