import { clsx } from 'clsx';
import { useCallback, useMemo, cloneElement } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import appStore from '../../../../redux/stores/app.store';
import EventActions from '../../../../redux/actions/event-actions/event.actions';

import useStyles from './SideMenuItem.styles';

export function SideMenuItem({ item = {} }) {
  const _classes = useStyles();

  const path = item?.path;
  const action = item?.action;
  const navigate = useNavigate();
  let location = useLocation();
  const pathname = location?.pathname;
  const isSelected = useMemo(() => {
    return pathname?.indexOf(path) > -1;
  }, [pathname, path]);

  const handleClick = useCallback(() => {
    if (!!path && path.trim() !== '') {
      const partnerId = appStore.getPartnerId();
      EventActions.onSibeBarMenuSelection(partnerId, path);
      navigate(path);
      return;
    }
    if (!!action) {
      action();
    }
  }, [navigate, path, action]);

  const listItemRootClasses = useMemo(() => {
    if (!isSelected) {
      return _classes.listItemRoot;
    }
    return clsx(_classes.listItemRoot, _classes.selectedListItemRoot);
  }, [_classes.listItemRoot, _classes.selectedListItemRoot, isSelected]);

  const listItemTextPrimaryClasses = useMemo(() => {
    if (!isSelected) {
      return _classes.listItemTextPrimary;
    }
    return clsx(
      _classes.listItemTextPrimary,
      _classes.selectedListItemTextPrimary,
    );
  }, [
    _classes.listItemTextPrimary,
    _classes.selectedListItemTextPrimary,
    isSelected,
  ]);

  return (
    <ListItem
      button
      classes={{ root: listItemRootClasses }}
      onClick={handleClick}
    >
      <ListItemIcon classes={{ root: _classes.listItemIconRoot }}>
        {cloneElement(item.icon, { selected: isSelected })}
      </ListItemIcon>
      <ListItemText
        primary={item.label}
        classes={{
          root: _classes.listItemTextRoot,
          primary: listItemTextPrimaryClasses,
        }}
      />
    </ListItem>
  );
}
