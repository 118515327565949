const OrderStateType = {
  INPROGRESS: 'in_progress',
  INSTORE: 'in_store',
  CANCELORDERBYPARTNER: 'canceled_partner_order_refused',
  CANCELED_PARTNER_AFTER_TAKE: 'canceled_partner_after_take',
  CANCELED_BY_PARTNER_INACTIVITY: 'canceled_by_partner_inactivity',
  CREATED: 'created',
  READYANDPACKED: 'ready_and_packed',
  DELIVEREDTORT: 'delivered_to_rt',
  NEW: 'new_order',
  ONHOLD: 'on_hold',
  PENDING_REVIEW: 'pending_review',
  FINISHED: 'finished',
  ON_THE_ROUTE: 'on_the_route',
  ARRIVE: 'arrive',
  SCHEDULED: 'scheduled',
};

export default OrderStateType;
