import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { textIds } from './consts';
import { useFormatMessage } from '../../intl/intl';
import hooks from './hooks';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';
import OrderActions from '../../redux/actions/orders-actions/orders.actions';
import EventActions from '../../redux/actions/event-actions/event.actions';
import appStore from '../../redux/stores/app.store';

import CustomDialog from '../common/custom-dialog/CustomDialog';
import CustomButton, {
  Variants,
  Colors,
} from '../common/buttons/custom-button';

import useStyles from './styles';
import stockOutsuggestImg from '../../assets/images/stock-out-suggestion-image.png';

export function ProductSuggestionAvailabilityDialog() {
  const theme = useTheme();

  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const rejectOrder = useFormatMessage(textIds.rejectOrder);
  const title = useFormatMessage(textIds.title);
  const subTitle = useFormatMessage(textIds.subTitle);
  const description = useFormatMessage(textIds.description);
  const confirm = useFormatMessage(textIds.confirm);
  const selectProductId = hooks.useProductId();
  const selectedProduct = hooks.useSelectedProduct();
  const open = hooks.useOpen();
  const refuseReason = hooks.useRefuseReason();
  const refuseReasonDescription = hooks.useRefuseReasonsDescription();
  const productName = hooks.useProductName();
  const componentClasses = useStyles();
  const partnerId = appStore.getPartnerId();
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  const handleBack = useCallback(() => {
    EventActions.onStockOutReplacementSelection(partnerId, 'back');
    dispatch(dialogsManagerActions.back());
  }, [dispatch, partnerId]);

  const onConfirm = useCallback(() => {
    EventActions.onStockOutReplacementSelection(partnerId, 'continue');
    dispatch(
      dialogsManagerActions.openSuggestProductDialog(
        selectProductId,
        productName,
        selectedProduct,
        refuseReason,
        refuseReasonDescription,
      ),
    );
  }, [
    dispatch,
    selectProductId,
    productName,
    selectedProduct,
    refuseReason,
    refuseReasonDescription,
    partnerId,
  ]);

  const onRefuse = useCallback(() => {
    EventActions.onStockOutReplacementSelection(partnerId, 'reject');
    dispatch(
      OrderActions.sendSuggestProduct(
        '',
        selectedProduct,
        refuseReason,
        refuseReasonDescription,
        productName,
      ),
    );
    dispatch(dialogsManagerActions.close());
  }, [
    dispatch,
    refuseReasonDescription,
    selectedProduct,
    refuseReason,
    productName,
    partnerId,
  ]);

  return (
    <CustomDialog
      actions={
        isMdUp
          ? [
              {
                text: rejectOrder,
                onClick: onRefuse,
                fullWidth: false,
                disabled: false,
                variant: 'outlined',
                color: 'tertiary',
                classes: { submitButton: componentClasses.rejectButton },
              },
              {
                text: confirm,
                onClick: onConfirm,
                fullWidth: false,
                color: 'primary',
                variant: 'contained',
                classes: { submitButton: componentClasses.buttonSubmit },
              },
            ]
          : []
      }
      classes={{
        childrenContainer: componentClasses.body,
        headerRoot: componentClasses.headerRoot,
        paper: componentClasses.paper,
      }}
      CustomDialogHeaderChildren={''}
      onClose={handleClose}
      onClickBackButton={handleBack}
      open={open}
      showBackButton
      showCloseButton
      title={title}
    >
      <div className={componentClasses.root}>
        <div className={componentClasses.container}>
          <div className={componentClasses.stockOutsuggestImg}>
            <img
              src={stockOutsuggestImg}
              alt='suggestion-img'
              className={componentClasses.stockOutsuggestImg}
            />
          </div>
          <div className={componentClasses.title}>{subTitle}</div>
          <div
            className={componentClasses.description}
            dangerouslySetInnerHTML={{
              __html: description || '',
            }}
          ></div>
        </div>
        {!isMdUp && (
          <div className={componentClasses.containerFooterButtons}>
            <CustomButton
              classes={{ submitButton: componentClasses.confirmButtonFooter }}
              color={Colors.PRIMARY}
              fullWidth={true}
              onClick={onConfirm}
              variant={Variants.CONTAINED}
            >
              {confirm}
            </CustomButton>
            <CustomButton
              classes={{ submitButton: componentClasses.rejectButtonFooter }}
              color={Colors.PRIMARY}
              fullWidth={true}
              onClick={onRefuse}
              variant={Variants.OUTLINED}
            >
              {rejectOrder}
            </CustomButton>
          </div>
        )}
      </div>
    </CustomDialog>
  );
}
