// Models
import IndexedDBOrder from '../models/indexedDBOrder';
// Utils
import DateTimeUtils from './DateTimeUtils';

const getOrderToSave = (order, printedDate, beingManaged = true) => {
  return new IndexedDBOrder(
    order.id,
    beingManaged,
    order.partner_order_state,
    printedDate,
    order.state,
  );
};

const getPrintedDate = tz => {
  const now = DateTimeUtils.getMomentNow(tz);
  return now.toISOString();
};

const getPrintedOrderToSave = (order, tz) => {
  if (!order) {
    return null;
  }

  const printedDate = getPrintedDate(tz);

  return getOrderToSave(order, printedDate);
};

const getPrintedOrdersToSave = (orders, tz) => {
  if (!orders) {
    return null;
  }
  const printedDate = getPrintedDate(tz);

  return getOrdersToSave(orders, printedDate);
};

const getOrdersToSave = (orders, printedDate) => {
  if (!orders) {
    return null;
  }
  return orders.map(order => getOrderToSave(order, printedDate));
};

const filterOrdersToDelete = orders => {
  return orders.filter(order => mustOrderBeDeleted(order));
};

const filterOrderIdsToDelete = orders => {
  const filteredOrders = filterOrdersToDelete(orders);
  return filteredOrders.map(order => order.id);
};

const mustOrderBeDeleted = order => {
  if (!order || !order.printedDate) {
    return false;
  }
  // Time is always saved as utc so tz must be null
  const minutesDiff = DateTimeUtils.getDiffFromNow(
    order.printedDate,
    'minutes',
    null,
    true,
  );
  const maximumAliveHours = 12;
  const maximumAliveMinutes = maximumAliveHours * 60;
  return minutesDiff >= maximumAliveMinutes;
};

export default {
  filterOrderIdsToDelete,
  filterOrdersToDelete,
  getOrderToSave,
  getOrdersToSave,
  getPrintedOrdersToSave,
  getPrintedOrderToSave,
  mustOrderBeDeleted,
};
