import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import NotificationPermissionsType from '../../constants/NotificationPermissionsType';
import { CustomAllowNotifications } from '../common/custom-allow-notifications';

import FirebaseService from '../../firebase/firebase';

import useStyles from './AllowNotificationsDialog.styles';

function AllowNotificationsDialog(props) {
  const {
    logout,
    intl: { formatMessage },
    children = null,
  } = props;

  const isGranted =
    Notification.permission === NotificationPermissionsType.GRANTED;

  const [isNotificationAllowed, setIsNotificationAllowed] = useState(isGranted);
  const [openAllowOrders, setOpenAllowOrders] = useState(false);
  const [isNotificationDenied, setIsNotificationDenied] = useState(
    Notification.permission === 'denied',
  );

  const classes = useStyles();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  // Allow Notifications
  const textAllow = formatMessage({ id: 'globals.notifications.allow' });
  const textNotif = formatMessage({
    id: 'globals.notifications.notifications',
  });
  const textCancel = formatMessage({ id: 'globals.notifications.cancel' });
  const allowText = formatMessage({ id: 'globals.notifications.allow.text' });

  // Allow Orders
  const textAllowOrders = formatMessage({
    id: 'globals.notifications.allow.orders',
  });
  const textAllowOrdersVal = formatMessage({
    id: 'globals.notifications.allow.orders.notifications',
  });
  const allowTextOrders = formatMessage({
    id: 'globals.notifications.allow.orders.text',
  });
  const textCancelOrder = formatMessage({
    id: 'globals.notifications.orders.cancel',
  });

  // Notification Warning
  const textDenied = formatMessage({
    id: 'globals.notifications.denied.orders',
  });

  const textUnderstood = formatMessage({
    id: 'globals.notifications.denied.understood',
  });

  const textWarning = formatMessage({
    id: 'globals.notifications.allow.warning',
  });

  const textNotifWarning = isMdUp
    ? formatMessage({
        id: 'globals.notifications.allow.warning.title',
      })
    : textNotif;

  const requestPermission = () => {
    FirebaseService.getPermission().then(() => {
      setIsNotificationDenied(
        Notification.permission === NotificationPermissionsType.DENIED,
      );
      setIsNotificationAllowed(
        window.Notification.permission === NotificationPermissionsType.GRANTED,
      );
    });
  };

  if (isGranted || isNotificationAllowed) return children;
  return (
    <div className={classes.root}>
      {!openAllowOrders && !isNotificationDenied && (
        <CustomAllowNotifications
          textAllow={textAllow}
          textNotif={textNotif}
          allowText={allowText}
          acceptLabel={textAllow}
          cancelLabel={textCancel}
          onAllow={requestPermission}
          onCancel={() => setOpenAllowOrders(true)}
          showIcon
        />
      )}
      {openAllowOrders && !isNotificationDenied && (
        <CustomAllowNotifications
          textAllow={textAllowOrders}
          textNotif={textAllowOrdersVal}
          allowText={allowTextOrders}
          acceptLabel={textAllow}
          cancelLabel={textCancelOrder}
          onAllow={requestPermission}
          onCancel={logout}
          showIcon
        />
      )}
      {isNotificationDenied && (
        <CustomAllowNotifications
          textAllow={textDenied}
          textNotif={textNotifWarning}
          allowText={textWarning}
          acceptLabel={textUnderstood}
          onAcknowledge={logout}
          showIcon={false}
        />
      )}
    </div>
  );
}

export default injectIntl(AllowNotificationsDialog);
