import { DeliveryAddress, RTRequest } from '../../../domain';

export const initialDeliverAddress: DeliveryAddress = {
  city: '',
  completeAddress: '',
  coordinates: { lat: 0, lng: 0 },
  parseAddress: '',
};

export const initialRTRequest: Partial<RTRequest> = {
  store: undefined,
  clientName: '',
  phoneNumber: '',
  deliveryAddress: initialDeliverAddress,
  deliveryReferences: '',
  totalValue: 0,
};
