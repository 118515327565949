// External libs
import { createSelector } from 'reselect'
// Interfaces
import { State, Step } from '../interfaces'
import { OnBoardingDialogType } from '../../../constants/OnBoardingDialogType'

const EMPTY_STEP = {
  code: '',
  index: 0,
  type: OnBoardingDialogType.Info,
  title: '',
  description: '',
  image_xs: '',
  image_sm: '',
  image_md: '',
  image_lg: '',
  image_xl: '',
}

const selectSlice: (state: any) => State = state => state.onBoardingDialog

const selectOpen = createSelector<any, State, boolean>(
  selectSlice,
  reducer => reducer.open,
)

const selectSteps = createSelector<any, State, Step[]>(selectSlice, reducer => {
  return reducer.steps || [EMPTY_STEP]
})
const selectCurrentStepIndex = createSelector<any, State, number>(
  selectSlice,
  reducer => reducer.currentStepIndex,
)
const selectCurrentStep = createSelector<any, Step[], number, Step>(
  selectSteps,
  selectCurrentStepIndex,
  (steps, currentStepIndex) => {
    if (
      steps.length === 0 ||
      currentStepIndex < 0 ||
      currentStepIndex > steps.length - 1
    ) {
      console.error(
        `selectCurrentStep - Error at selecting currentStep - Steps`,
        steps,
        currentStepIndex,
      )
      return EMPTY_STEP
    }
    return steps[currentStepIndex] || EMPTY_STEP
  },
)
const selectSource = createSelector<any, State, string>(
  selectSlice,
  reducer => {
    return reducer.source
  },
)
const selectors = {
  selectOpen,
  selectCurrentStepIndex,
  selectCurrentStep,
  selectSteps,
  selectSource,
}

export default selectors
