import React, { useState } from 'react';
import { Box, Button, RadioGroup, Tooltip } from '@material-ui/core';
import Typography, { FontWeight } from '../../../../common/typography';
import { CustomRadio } from '../../../../common/custom-radio';
import OrderMgmtStyles from '../../../OrderManagement.style';
import { orderMgmtState } from '../../../../../business/order-mgmt';
import { Variants } from '../../../../common/buttons/custom-button';

export function OrderMgmtMainOptions({
  infoUserTitleText,
  infoUserDescriptionText,
  callUserText,
  startChatText,
  optionsTexts,
  userName = '',
  continueButtonText,
  handleOrderOption,
  isOrderEnabledToEdit,
  showSupportWebChatButton,
  handleOpenSupportWebChat,
  handleOpenCustomerPhoneSessionDialog,
  canSendChangesByTime,
  isOrderBeingCooked,
  tooltipOptionDisclaimerText,
}: Props) {
  const [orderMgmtOption, setOrderMgmtOption] = useState<orderMgmtState>(
    optionsTexts[0].option,
  );
  const componentClasses = OrderMgmtStyles();
  return (
    <>
      <Box
        className={componentClasses.infoUserDisclaimer}
        padding='0.875rem 1rem'
        display='flex'
        flexDirection='column'
        gridGap='0.5rem'
        position='relative'
      >
        <Typography classes={{ root: componentClasses.infoUserTitle }}>
          {infoUserTitleText}
        </Typography>
        <Box display='flex' justifyContent='space-between'>
          <Typography fontWeight={FontWeight.Bold}>{userName}</Typography>
          <Box display='flex' alignItems='center' gridGap='1rem'>
            <Button
              onClick={handleOpenCustomerPhoneSessionDialog}
              color='primary'
              variant={Variants.TEXT}
              fullWidth={false}
            >
              {callUserText}
            </Button>
            {showSupportWebChatButton && (
              <Button
                onClick={handleOpenSupportWebChat}
                color='primary'
                variant={Variants.CONTAINED}
                fullWidth={false}
              >
                {startChatText}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Typography classes={{ root: componentClasses.infoUserText }}>
        {infoUserDescriptionText}
      </Typography>
      <RadioGroup
        value={orderMgmtOption}
        className={componentClasses.editOptions}
      >
        {optionsTexts.map(({ title, description, option }) => (
          <OrderMgmtOption
            key={`opt-${option}`}
            title={title}
            description={description}
            option={option}
            setOrderMgmtOption={setOrderMgmtOption}
            isDisabled={
              (!isOrderEnabledToEdit &&
                option !== orderMgmtState.DeactivateItems) ||
              (isOrderBeingCooked && option !== orderMgmtState.DeactivateItems)
            }
            isOrderBeingCooked={isOrderBeingCooked}
            tooltipOptionDisclaimerText={tooltipOptionDisclaimerText}
          />
        ))}
      </RadioGroup>
      <Box display='flex' justifyContent='flex-end' flex={1} marginTop='1rem'>
        <Button
          onClick={() => handleOrderOption(orderMgmtOption)}
          color='primary'
          variant={Variants.TEXT}
          fullWidth={false}
          disabled={!orderMgmtOption || !canSendChangesByTime}
        >
          {continueButtonText}
        </Button>
      </Box>
    </>
  );
}

interface Props {
  infoUserTitleText: string;
  infoUserDescriptionText: string;
  callUserText: string;
  startChatText: string;
  optionsTexts: Array<{
    title: string;
    description: string;
    option: orderMgmtState;
  }>;
  userName?: string;
  continueButtonText: string;
  handleOrderOption: any;
  isOrderEnabledToEdit: boolean;
  showSupportWebChatButton: boolean;
  handleOpenSupportWebChat: any;
  handleOpenCustomerPhoneSessionDialog: any;
  canSendChangesByTime: boolean;
  isOrderBeingCooked: boolean;
  tooltipOptionDisclaimerText: string;
}

function OptionTooltipWrapper({ condition, wrapper, children }) {
  return condition ? wrapper(children) : children;
}

function OrderMgmtOption({
  title,
  description,
  option,
  isDisabled,
  setOrderMgmtOption,
  isOrderBeingCooked,
  tooltipOptionDisclaimerText,
}) {
  const componentClasses = OrderMgmtStyles({ isDisabled });
  return (
    <OptionTooltipWrapper
      condition={
        isOrderBeingCooked && option !== orderMgmtState.DeactivateItems
      }
      wrapper={children => (
        <Tooltip
          classes={{ tooltip: componentClasses.tooltip }}
          title={tooltipOptionDisclaimerText}
          placement='left-start'
          arrow
        >
          {children}
        </Tooltip>
      )}
    >
      <Box
        className={componentClasses.editOptionsContainer}
        display='flex'
        justifyContent='flex-start'
        alignItems='center'
        onClick={() => (isDisabled ? {} : setOrderMgmtOption(option))}
      >
        <CustomRadio
          classes={{
            root: componentClasses.editRadioOption,
            label: componentClasses.editLabelOption,
          }}
          color='primary'
          value={option}
          onChange={() => setOrderMgmtOption(option)}
          disabled={isDisabled}
        />
        <Box display='flex' flexDirection='column' justifyContent='center'>
          <Typography
            classes={{ root: componentClasses.editTextOption }}
            fontWeight={FontWeight.Bold}
          >
            {title}
          </Typography>
          <Typography
            classes={{ root: componentClasses.editDescriptionOption }}
          >
            {description}
          </Typography>
        </Box>
      </Box>
    </OptionTooltipWrapper>
  );
}
