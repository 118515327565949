import { useEffect, useState } from 'react';

import { norm2digit } from '../../../../shared/utils/numbers';
import {
  useDemandTimer,
  useMergeSuperWithHigh,
  useSaturationStateText,
} from '../../shared/hooks';

import type { Saturation } from '../../../../business/highDemand';

export function useHighDemandBtn(props: Props) {
  const { pureVariant, isSingleStore } = props;

  const [timer, setTimer] = useState(0);

  const variant = useMergeSuperWithHigh(pureVariant);
  const { status, allDayText } = useSaturationStateText({
    variant,
    isSingleStore,
  });

  const { getDemandTimer } = useDemandTimer({ isSingleStore });

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    setTimer(getDemandTimer({ variant }));
    timeout = setInterval(() => setTimer(v => v - 1), 1_000);
    if (pureVariant === 'fast') clearInterval(timeout);
    return () => {
      clearInterval(timeout);
    };
  }, [pureVariant]);

  return {
    variant,
    text: {
      status,
      demandTimer: formatTimer(timer, allDayText),
    },
  };
}

function formatTimer(demandTimer: number, allDayText: string): string {
  if (demandTimer <= 0) return '';
  const minutes = Math.floor(demandTimer / 60);
  return `(${minutes <= 60 ? minutes + '’' : allDayText})`;
}

interface Props {
  isSingleStore: boolean;
  pureVariant: Saturation;
}
