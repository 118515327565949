// React
import React, { useEffect, useCallback } from 'react';
// Redux
import { useDispatch } from 'react-redux';
// Tools
import { useFormatMessage } from '../../intl/intl';
// Hooks
import Hooks from './hooks';
// Components
import CustomButton from '../common/buttons/custom-button/CustomButton';
import CustomDrawer from '../common/custom-drawer';
import CustomNotification from '../common/custom-notificacion';
// Styles
import useStyles from './CustomNotificationDrawer.styles';
import NotificationsDrawerActions from '../../redux/actions/notifications-drawer-actions/notificationsDrawer.actions';

export default function CustomNotificationDrawer({
  getNotifications,
  lastSeenCreatedAtDate,
  notifications,
  notificationPutSeenAndReload,
  notificationPutSeenAllAndReload,
  page,
  perPage,
}) {
  const classes = useStyles();

  const markAsRead = useFormatMessage({
    id: 'notification.panel.markAsRead',
  });

  const title = useFormatMessage({
    id: 'notification.panel.title',
  });

  const dispatch = useDispatch();

  const open = Hooks.useOpen();

  const handleClose = useCallback(() => {
    NotificationsDrawerActions.close()(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (!open) {
      return;
    }
    getNotifications(page, perPage);
  }, [open]);

  return (
    <CustomDrawer open={open} title={title} onClose={handleClose}>
      <div className={classes.container}>
        {notifications.map((notification, index) => {
          return (
            <CustomNotification
              key={`notifications-${index}`}
              notification={notification}
              onSelect={notificationId =>
                notificationPutSeenAndReload(
                  lastSeenCreatedAtDate,
                  notificationId,
                  page,
                  perPage,
                )
              }
            />
          );
        })}
      </div>
      <div className={classes.linkContainer}>
        <CustomButton
          classes={{ submitButton: classes.deliverToUserButton }}
          fullWidth={false}
          onClick={() =>
            notificationPutSeenAllAndReload(
              lastSeenCreatedAtDate,
              page,
              perPage,
            )
          }
          color='primary'
          text={markAsRead}
          variant='outlined'
        />
      </div>
    </CustomDrawer>
  );
}
