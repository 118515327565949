//react
import React from 'react'
//components
import CustomButton from '../common/buttons/custom-button/CustomButton'
import { analitycsEvent, GACategories, GAActions } from '../../utils/ga-utils'
//style
import useStyles from './ChromeExtensionDownloadSetting.styles'

const ChromeExtensionDownloadSetting = ({
  chromeExtensionTitle,
  chromeExtensionDescription,
  chromeExtensionButton,
  partnerId,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.mainContainer}>
      <div className={classes.flexContainer}>
        <div className={classes.primaryDescriptionFonts}>
          {chromeExtensionTitle}
          <div className={classes.secondaryDescriptionFonts}>
            {chromeExtensionDescription}
          </div>
        </div>
        <div>
          <CustomButton
            className={classes.buttonContent}
            color='primary'
            text={chromeExtensionButton}
            variant='contained'
            onClick={() => {
              analitycsEvent(
                GACategories.CONFIGURATIONS,
                GAActions.INSTALL_PLUG_IN,
                `install_google_chrome_extension#partner_id=${partnerId}`,
              )
              window.open(process.env.REACT_APP_CHROME_EXTENSION_URL, '_blank')
            }}
          />
        </div>
      </div>
    </div>
  )
}

ChromeExtensionDownloadSetting.defaultProps = {
  classes: {},
}
export default ChromeExtensionDownloadSetting
