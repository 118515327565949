export const CARGO_ORDER_STATUS = {
  cooking: 'inProgress',
  created: 'findingRT',
  finding_RT: 'findingRT',
  finding_courier: 'findingRT',
  in_progress: 'inProgress',
  in_store: 'RTinStore',
  on_the_route: 'RTOnTheWay',
  arrive: 'RTArrived',
  delivered_to_user: 'DeliveredToUser',
} as const;

export type CargoOrderStatus = typeof CARGO_ORDER_STATUS[keyof typeof CARGO_ORDER_STATUS];

export const COVERAGE_STATUS = {
  hasCoverage: 'hasCoverage',
  noCoverage: 'noCoverage',
  notAvailable: 'notAvailable',
  exception: 'exception',
  ['']: '',
};

export type CoverageStatus = keyof typeof COVERAGE_STATUS;

export const CARGO_STATUS = {
  idle: 'idle',
  isLoading: 'isLoading',
  rejected: 'rejected',
  storesLoaded: 'storesLoaded',
  coverageVerified: 'coverageVerified',
  coverageRejected: 'coverageRejected',
  orderCreated: 'orderCreated',
  orderCanceled: 'orderCanceled',
  orderRejected: 'orderRejected',
};

export type CargoStatus = keyof typeof CARGO_STATUS;

export const CARGO_KANBAN_STATUS = {
  idle: 'idle',
  isLoading: 'isLoading',
  refresh: 'refresh',
  empty: 'empty',
  noResults: 'noResults',
  rejected: 'rejected',
  successful: 'successful',
};

export type CargoKanbanStatus = keyof typeof CARGO_KANBAN_STATUS;

export type CancelCostStatus = 'idle' | 'isLoading' | 'successful' | 'rejected';

export type CancelOrderStatus =
  | 'idle'
  | 'isLoading'
  | 'successful'
  | 'rejected';
