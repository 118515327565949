import { createReducer } from '@reduxjs/toolkit';
import actions from '../actions';

const initialState: any = {
  partnerId: 0,
  open: false,
};

export default createReducer(initialState, {
  [actions.clearAction.type]: () => clear(),
  [actions.closeAction.type]: state => close(state),
  [actions.openAction.type]: (state, { payload: { partnerId } }) =>
    open(state, partnerId),
});

const clear = () => {
  return initialState;
};

const close = (state: any) => {
  return { ...state, open: false };
};

const open = (state: any, partnerId: number = state.partnerId) => {
  return { ...actions, open: true, partnerId };
};
