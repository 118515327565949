import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { OrderMgmtItem } from '../../../../../../../business/order-mgmt';
import cardStyles from './suggestions.styles';
import { formatCurrency } from '../../../../../../../utils/CurrencyUtils';
import { useProductSuggestionsLang } from './useSuggestionsLang';
import warningIcon from '../../../../../../../assets/images/icons/warningIcon.svg';

export const Suggestions = ({
  units = 0,
  suggestions,
  productName = '',
  enabledDisclaimer,
}: Props) => {
  const styles = cardStyles();
  const {
    userCanChooseText,
    deactivateItemsDescriptionText,
  } = useProductSuggestionsLang({
    productName,
  });

  return (
    <Box display='flex' flexDirection='column' gridGap='0.5rem'>
      <Box
        className={styles.suggestionsContainer}
        display='flex'
        flexDirection='column'
        padding='1rem'
        gridGap='0.5rem'
      >
        <Typography classes={{ root: styles.suggestionDisclaimer }}>
          {userCanChooseText}
        </Typography>
        {suggestions?.map((item: OrderMgmtItem) => (
          <Box
            key={`s-${item.id}-${item.name}`}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            <Box display='flex' gridGap='0.25rem'>
              {units > 1 && (
                <Typography
                  classes={{ root: styles.suggestionUnits }}
                >{`${units}x`}</Typography>
              )}
              <Typography classes={{ root: styles.suggestionName }}>
                {item.name}
              </Typography>
            </Box>
            <Typography classes={{ root: styles.suggestionPrice }}>
              {formatCurrency(item.price)}
            </Typography>
          </Box>
        ))}
        {enabledDisclaimer && (
          <Box
            className={styles.disclaimerContent}
            display='flex'
            flexDirection='column'
            gridGap='0.5rem'
            width='100%'
          >
            <Box display='flex' alignItems='center' gridGap='0.5rem'>
              <img
                className={styles.disclaimerIcon}
                width={18}
                height={18}
                alt='warning-icon'
                src={warningIcon}
              />
              <Typography
                className={styles.disclaimerDescription}
                dangerouslySetInnerHTML={{
                  __html: deactivateItemsDescriptionText,
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

type Props = {
  productName?: string;
  suggestions: Array<OrderMgmtItem> | undefined;
  units: number;
  enabledDisclaimer?: boolean;
};
