import { cargoRequestHandler } from '../../infra';

import type { IRequestRtCase } from '../../domain';
import type { CoverageVerification, RTRequest } from '../../domain';

export function CargoRequestRtCase(): IRequestRtCase {
  const {
    get,
    setNewOrder,
    requestVerifyCoverage,
    createOrder,
  } = cargoRequestHandler();

  const {
    newOrder,
    coverageStatus,
    status,
    stores,
    countryCode,
    partnerId,
  } = get();

  const verifyCoverage = (data: Partial<RTRequest>) => {
    const request: CoverageVerification = data as CoverageVerification;
    requestVerifyCoverage(request);
  };

  return {
    status,
    newOrder,
    coverageStatus,
    stores,
    countryCode,
    partnerId,
    setNewOrder,
    verifyCoverage,
    createOrder,
  };
}
