import { useCheckInCodeRTGlobal } from './hooks';

import { CheckInCodeRT } from './CheckInCodeRT';
import { CheckInCodeRTRoot, CodeList } from './comps';

export function CheckInCodeRTGlobal() {
  const {
    open,
    withoutStores,
    isSingleStore,
    storesWithCode,
    handleOpen,
    handleClose,
  } = useCheckInCodeRTGlobal();

  if (withoutStores) return null;
  return (
    <>
      {isSingleStore && (
        <CheckInCodeRT
          globalMode={true}
          expiredAt={storesWithCode[0].expiredAt}
          checkinCode={storesWithCode[0].checkinCode}
        />
      )}
      {!isSingleStore && (
        <>
          <CheckInCodeRTRoot globalMode onClick={handleOpen} />
          <CodeList open={open} onClose={handleClose} stores={storesWithCode} />
        </>
      )}
    </>
  );
}
