import { CargoMainBtn, MainDialog } from './components';

import { useCargo } from './hooks';

export function Cargo() {
  const {
    isEnabledHeaderBtn,
    isVisibleHeaderBtn,
    isEnabledMainDialog,
  } = useCargo();

  return (
    <>
      {isVisibleHeaderBtn && <CargoMainBtn isEnabled={isEnabledHeaderBtn} />}
      {isEnabledMainDialog && <MainDialog />}
    </>
  );
}
