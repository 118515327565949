import React, { useCallback, useEffect, useRef, ReactNode } from 'react';
import useStyles from './SwipeableViews.style';

export interface SwipeableViewsProps {
  index: number;
  onChangeIndex: (index: number, target: HTMLDivElement) => void;
}

export function SwipeableViews(
  props: SwipeableViewsProps & React.HTMLProps<HTMLDivElement>,
) {
  const {
    className = '',
    index,
    onChangeIndex,
    onScroll,
    ...rootProps
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const scrollTimeout = useRef<NodeJS.Timeout>();
  const styles = useStyles();
  const startX = useRef<number>(0);
  const startTime = useRef<number>(0);
  const isScrolling = useRef(false);

  // when index change from parent, we scroll to that child
  useEffect(() => {
    if (!containerRef.current || isScrolling.current) return;

    const { scrollWidth, children } = containerRef.current;

    const pageWidth = scrollWidth / children.length;

    containerRef.current.scrollTo({
      behavior: 'smooth',
      left: index * pageWidth,
      top: 0,
    });
  }, [index]);

  // recalculate index with a debounce
  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      onScroll?.(event);
      const { currentTarget } = event;
      if (scrollTimeout.current) clearTimeout(scrollTimeout.current);

      const { scrollWidth, children, scrollLeft } = currentTarget;
      scrollTimeout.current = setTimeout(() => {
        const pageWidth = scrollWidth / children.length;
        const currentIndex = Math.round(scrollLeft / pageWidth);
        onChangeIndex(currentIndex, currentTarget);
      }, 100);
    },
    [onChangeIndex, onScroll],
  );

  const handleTouchStart = useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      isScrolling.current = true;
      startX.current = event.currentTarget.scrollLeft;
      startTime.current = Date.now();
    },
    [],
  );

  const handleTouchEnd = useCallback(
    event => {
      const { currentTarget } = event;
      const { children, scrollLeft } = currentTarget;

      // Time and distance spent by the user swiping
      const delta = {
        time: Date.now() - startTime.current,
        x: scrollLeft - startX.current,
      };

      const swipeSpeed = Math.abs(delta.x) / delta.time;

      if (swipeSpeed > 0.3) {
        const lastIndex = index;
        const nextIndex = lastIndex + 1 * Math.sign(delta.x);

        // If it's a valid direction, we update the index and stop scrolling event
        if (nextIndex >= 0 && nextIndex < children.length) {
          if (scrollTimeout.current) clearTimeout(scrollTimeout.current);
          onChangeIndex(nextIndex, currentTarget);
          setTimeout(() => {
            isScrolling.current = false;
          }, 100);
        }
      }
    },
    [onChangeIndex, index],
  );

  return (
    <div
      {...rootProps}
      ref={containerRef}
      className={`${styles.root} ${className}`}
      onScroll={handleScroll}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    />
  );
}
