import { useMemo } from 'react';
import { Box } from '@material-ui/core';

import hooks from './hooks';
import OrderDetailDialogCookingTimeSectionStyles from './styles';
import { OrderDeliveryMethodType } from '../../constants/OrderDeliveryMethodType';

import { FullServiceSection } from './components/full-service-section';
import { MarketplaceSection } from './components/marketplace-section';

export function OrderDetailDialogCookingTimeSection(props: Props) {
  const { isDisabled = false, isFastOperation = false } = props;

  const styles = OrderDetailDialogCookingTimeSectionStyles();
  const deliveryMethod = hooks.useDeliveryMethod();

  return (
    <Box position='relative'>
      {isDisabled && <Box className={styles.blockContent}></Box>}
      {deliveryMethod === OrderDeliveryMethodType.MARKETPLACE 
        ? <MarketplaceSection isFastOperation={isFastOperation} /> 
        : <FullServiceSection isFastOperation={isFastOperation} />
      }
    </Box>
  );
}

interface Props {
  isDisabled: boolean;
  isFastOperation?: boolean;
}
