// External libs
import { createAction } from '@reduxjs/toolkit'

const closeAction = createAction('errorSuggestingProductDialog/close')

const clearAction = createAction('errorSuggestingProductDialog/clear')

const maximizeAction = createAction('errorSuggestingProductDialog/maximize')

const openAction = createAction<{ orderId: number }>(
  'errorSuggestingProductDialog/open',
)

const clear = () => (dispatch: any) => {
  dispatch(clearAction())
}

const close = () => (dispatch: any) => {
  dispatch(closeAction())
}

const maximize = () => (dispatch: any) => dispatch(maximizeAction())

const open = (orderId: number) => (dispatch: any) => {
  dispatch(openAction({ orderId }))
}

const actions = {
  clear,
  clearAction,
  close,
  closeAction,
  maximize,
  maximizeAction,
  open,
  openAction,
}

export default actions
