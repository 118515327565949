// External libs
import { handleActions } from 'redux-actions';
// Actions
import OrderRejectionByStockOutDialogActions from '../actions/orderRejectionByStockOutDialog.actions';

const defaultOrderId = 0;

const initialState = {
  orderId: defaultOrderId,
  open: false,
  refuseReason: {},
};

export default handleActions(
  {
    [OrderRejectionByStockOutDialogActions.clearOrderRejectionByStockOutDialogAction]: state =>
      clearDialog(state),
    [OrderRejectionByStockOutDialogActions.closeOrderRejectionByStockOutDialogAction]: state =>
      closeDialog(state),
    [OrderRejectionByStockOutDialogActions.openOrderRejectionByStockOutDialogAction]: (
      state,
      { payload: { orderId, refuseReason } },
    ) => openDialog(state, orderId, refuseReason),
  },
  initialState,
);

const clearDialog = state => {
  return {
    ...initialState,
  };
};

const closeDialog = state => {
  return {
    ...state,
    open: false,
  };
};

const openDialog = (
  state,
  orderId = state.orderId,
  refuseReason = state.refuseReason,
) => {
  return {
    ...state,
    open: true,
    orderId: orderId,
    refuseReason: refuseReason,
  };
};
