const PartnerOrderStateType = {
  NEWORDER: 'new_order',
  DELAYED: 'delayed',
  COOKING: 'cooking',
  CONTINUE_ORDER: 'continue_order',
  NULL: null,
  READYANDPACKED: 'ready_and_packed',
  DELIVEREDTORT: 'delivered_to_rt',
  CANCEL_APP: 'canceled_by_user_app_with_charge',
  CANCEL_CMS: 'canceled_from_cms',
  ON_HOLD: 'on_hold',
};

export default PartnerOrderStateType;
