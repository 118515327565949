import { highDemandAdapter } from '../infra/adapters/highDemand.adapter';
import { AnalyticsActions } from './core/Analytics.actions';
import { Events } from './core/Analytics.const';

import type { HighDemandApplyProps } from './core/Analytics.const';

export const highDemandAnalytics = {
  whenSubmit,
} as const;

/**
 * Log event when click in "Apply change" button
 *
 * @param {HighDemandApplyProps} [params] - HighDemandButton Event properties types
 * @param {number} params.storeId - ID of selected store
 * @param {number} params.partnerId - ID of current partner logged in
 * @param {string} params.demandOptions - severity option selected
 * `values`:
 * ```
 * 'REGULAR_DEMAND' | 'LOW_DEMAND' | 'HIGH_DEMAND' | 'SUPER_HIGH_DEMAND'
 * ```
 * @param {number} params.additionalCT - represent extra minutes of severity time
 * @param {number} params.timeOptions - TTL selected in minutes
 */
function whenSubmit(params: HighDemandApplyProps) {
  const demandOption = highDemandAdapter.toSaturationDTO(params.demandOption);
  const args = {
    [Events.HDB.props.storeId]: params.storeId,
    [Events.HDB.props.partnerId]: params.partnerId,
    [Events.HDB.props.demandOption]: demandOption,
    [Events.HDB.props.additionalCT]: params.additionalCT,
    [Events.HDB.props.timeOption]: params.timeOption,
    [Events.HDB.props.hasHoldLowCT]: params.hasHoldLowCT,
  };
  AnalyticsActions.logHighDemandApply(args);
}
