import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import hooks from './hooks';

import { Serverities } from '../../constants/Severities';
import CustomInAppDialog from '../common/custom-in-app-dialog';
import { executeAction } from '../../transpilers/dsl-transpiler/dslDispatch';
import { actions as dynInAppDialogActions } from '../../redux/dyn-in-app-dialog';
import ChromeExtensionService from '../../services/ChromeExtensionService';

export function DynInAppDialog() {
  const open = hooks.useOpen();
  const title = hooks.useTitle();
  const description = hooks.useDescription();
  const mainButton = hooks.useMainButton();
  const closeButton = hooks.useCloseButton();
  const color = hooks.useColor();
  const shouldSound = hooks.useShouldSound();
  const dispatch = useDispatch();

  useEffect(() => {
    ChromeExtensionService.updateAudio();
  }, [shouldSound]);

  return (
    <CustomInAppDialog
      buttonText={mainButton?.textContent}
      description={description}
      showCloseButton={!!closeButton}
      showMainButton={!!mainButton}
      onClick={() => {
        !!mainButton?.onClick && dispatch(executeAction(mainButton?.onClick));
        dispatch(dynInAppDialogActions.close());
      }}
      onClose={() => {
        !!closeButton?.onClick && dispatch(executeAction(closeButton?.onClick));
        dispatch(dynInAppDialogActions.close());
      }}
      open={open}
      title={title}
      type={color as Serverities}
    />
  );
}
