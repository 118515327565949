// React
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
// Redux
import { useDispatch } from 'react-redux';
// Material UI
import { useTheme } from '@material-ui/styles';
import { List, ListItem, useMediaQuery } from '@material-ui/core';
// Consts
import { textIds } from './consts';
// Tools
import { useFormatMessage } from '../../../../intl/intl';
// Actions
import { actions as helpCenterActions } from '../../../../redux/help-center-page';
// Hooks
import hooks from './hooks';
// Components
import CustomDrawer, { Variants } from '../../../common/custom-drawer';
// Styles
import useStyles from './styles';
import Paths from '../../../../constants/Paths';
import EventActions from '../../../../redux/actions/event-actions/event.actions';

function Drawer() {
  const componentClasses = useStyles();

  const theme = useTheme<any>();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const title = useFormatMessage(textIds.title);

  const data = hooks.useData() || [];
  const open = hooks.useOpen();
  const categoryId = hooks.useSelecteCategoryId();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(helpCenterActions.close());
  }, [dispatch]);

  return (
    <CustomDrawer
      classes={{
        paper: componentClasses.paper,
        root: componentClasses.root,
      }}
      onBack={handleClose}
      onClose={handleClose}
      open={open}
      showBackButton={false}
      title={title}
      variant={isMdUp ? Variants.PERMANENT : Variants.TEMPORARY}
    >
      <List>
        {data.map((category, index: number) => {
          return (
            <ListItem
              selected={category.id === categoryId}
              key={`category-${index}`}
              onClick={() => {
                EventActions.onSuccessfulCategory(category.id);
                navigate(Paths.getHelpcenterCategoryPath(category.id));
                dispatch(helpCenterActions.setSelectedCategoryId(category.id));
              }}
            >
              {category.name}
            </ListItem>
          );
        })}
      </List>
    </CustomDrawer>
  );
}

export default Drawer;
