import { MobileStepper } from '@material-ui/core';

export function Bullets({ activeStep = 0, steps = 1 }: Props) {
  return (
    <MobileStepper
      variant='dots'
      steps={steps}
      position='static'
      activeStep={activeStep}
      nextButton={<></>}
      backButton={<></>}
    />
  );
}

interface Props {
  activeStep: number;
  steps: number;
}
