import { makeStyles } from '@material-ui/core';

import Colors from '../../../../constants/Colors';
import { neutral } from '../../../../shared/styles/colors';

export const useStyles = makeStyles(theme => ({
  storeBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '0.5rem',
    border: '1px solid',
    borderColor: Colors.SUCCESS_LIGHT_COLOR,
    borderRadius: '8px',
    padding: '1rem',
    margin: '0.5rem',
    [theme.breakpoints.up('md')]: {
      maxWidth: '280px',
    },
  },

  title: {
    position: 'relative',
    fontSize: '1.13rem',
    fontWeight: 600,
    lineHeight: '1.6rem',
    maxHeight: 'calc(1.6rem * 2)',
    overflow: 'hidden',
  },

  listItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },

  deactivatedQuantity: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '0.25rem 0.5rem',
    width: '2.5rem',
    height: '2.1rem',
    backgroundColor: Colors.SUCCESS_FONT_COLOR,
    borderRadius: '8px',
    fontWeight: 600,
    fontSize: '1.13rem',
    lineHeight: '1.63rem',
    color: neutral.white,
  },

  deactivatedType: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '0.8rem',
    color: neutral.D16,
  },
}));
