import React from 'react';
import CustomDrawer from '../common/custom-drawer/CustomDrawer';
import useStyles from './HistoricalQualifyNegativeDrawer.styles';
import CustomAvatar from '../common/custom-avatar';

export default function HistoricalQualifyNegativeDrawer({
  orderId,
  partnerId,
  open,
  storekeeperId,
  fullName,
  profilePic,
  setQualifyNegOpen,
  qualifyNegativeReasons,
  qualifyNegativeRT,
}) {
  const classes = useStyles();

  return (
    <CustomDrawer open={open} onClose={() => setQualifyNegOpen(false)}>
      <div className={classes.rtRoot}>
        <div>ID: {storekeeperId}</div>
        <div>RT: {fullName}</div>
        <CustomAvatar
          classes={classes}
          alt='Remy Sharp'
          letter={fullName && fullName.charAt(0)}
          src={profilePic}
        />
      </div>
      {qualifyNegativeReasons.map(reason => {
        return (
          <div
            className={classes.reason}
            key={reason.id}
            onClick={async () => {
              await qualifyNegativeRT(
                reason.rule_description,
                orderId,
                partnerId,
                reason.rule_id,
                storekeeperId,
              );
              setQualifyNegOpen(false);
            }}
          >
            {reason.rule_description}
          </div>
        );
      })}
    </CustomDrawer>
  );
}
