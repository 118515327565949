import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useDialogDeleteWhimFromOrder } from './hooks';

import type { WhimId } from '../../../../business/shared/domain';

export function DialogDeleteWhimFromOrder(props: Props) {
  const { open, whimId, onClose } = props;

  const { lang, handleConfirmDeletion } = useDialogDeleteWhimFromOrder({
    whimId,
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <Box display='flex' justifyContent='space-between'>
        <DialogTitle>{lang.title}</DialogTitle>
        <IconButton aria-label='close' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <DialogContentText style={{ color: 'black' }}>
          {lang.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          variant='contained'
          onClick={handleConfirmDeletion}
        >
          {lang.confirmBtn}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface Props {
  open: boolean;
  whimId: WhimId;
  onClose: () => void;
}
