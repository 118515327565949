import { createStyles, makeStyles } from '@material-ui/core';
import Colors from '../../../../constants/Colors';
import { neutral } from '../../../../shared/styles/colors';

export const orderDetailDisclaimer = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: Colors.WARNING_LIGHT_2,
      gridArea: 'disclaimer',
      borderLeft: `3px solid ${Colors.WARNING}`,
    },
    content: {
      color: neutral.D18,
    },
    primaryText: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    secondaryText: {
      fontSize: '0.75rem',
      fontWeight: 'bold',
    },
    tertiaryText: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    price: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    warningIcon: {
      color: Colors.WARNING_FONT_COLOR,
    },
    btnSecondary: {
      minWidth: '9.125rem',
      color: Colors.SECONDARY_COLOR_DARK,
    },
    collapseIcon: (props?: StyleProps) => ({
      transform: `rotate(${props?.isContentCollapsed ? 180 : 0}deg)`,
      transition: '.25s ease-in-out',
      cursor: 'pointer',
      position: 'absolute',
      right: 16,
      top: 16,
    }),
  }),
);

type StyleProps = {
  isContentCollapsed?: boolean;
};
