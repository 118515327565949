import { clsx } from 'clsx';
import { Box } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';

import { RestaurantIcon } from '../icons/restaurant-icon';
import CustomMarker from './../common/custom-marker/CustomMarker';
import CustomButton from './../common/buttons/custom-button';
import { RTIcon } from '../icons/rt-icon';

import { useRTMapLang } from './useRTMapLang';
import { getCenterAndZoomToMap } from './RTMap.util';

import { DEFAULT_ZOOM } from './RTMap.const';
import useStyles from './RTMap.styles';

function RTMap(props) {
  const {
    classes = {},
    RT,
    store,
    orderId,
    openDialogReleaseRT,
    showReleaseRT = false,
  } = props;

  const existStoreId = store?.store_id;
  const storeLat = store?.lat ?? 0;
  const storeLng = store?.lng ?? 0;
  const RTLat = RT?.lat ?? 0;
  const RTLng = RT?.lng ?? 0;

  const DEFAULT_CENTER = {
    lat: storeLat ?? 4.678695,
    lng: storeLng ?? -74.055456,
  };

  const mapping = getCenterAndZoomToMap(storeLat, storeLng, RTLat, RTLng);

  const lang = useRTMapLang();

  const _classes = useStyles();

  return (
    <>
      {!existStoreId && (
        <Box flexDirection='column'>
          <h2>{lang.error.loadingMap.title}</h2>
          <p>{lang.error.loadingMap.msg}</p>
        </Box>
      )}
      {existStoreId && (
        <div className={clsx(_classes.root, classes?.root)}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
            defaultCenter={DEFAULT_CENTER}
            defaultZoom={DEFAULT_ZOOM}
            zoom={mapping.zoom}
            center={mapping.center}
          >
            <CustomMarker
              className={_classes.restaurantImgContainer}
              lat={storeLat}
              lng={storeLng}
            >
              <RestaurantIcon className={_classes.restaurantImg} />
            </CustomMarker>
            {RT.id && RT.lat && RT.lng && (
              <CustomMarker
                className={_classes.RTImgContainer}
                lat={RT.lat}
                lng={RT.lng}
              >
                <RTIcon direction={RT.bearing} />
              </CustomMarker>
            )}
          </GoogleMapReact>
          {showReleaseRT && (
            <CustomButton
              lat={storeLat}
              lng={storeLng}
              classes={{ root: _classes.releaseRtButton }}
              color='primary'
              disabled={false}
              onClick={() => {
                openDialogReleaseRT(orderId);
              }}
              text={lang.releaseRT.btn}
              variant='contained'
            />
          )}
        </div>
      )}
    </>
  );
}

export default RTMap;
