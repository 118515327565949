// Material UI
import { makeStyles } from '@material-ui/core';
// Constant
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  body: {
    height: '100%',
    padding: '40px 24px 40px 24px',
    [theme.breakpoints.up('md')]: {
      padding: '40px 24px 40px 24px',
    },
  },
  text: {
    fontFamily: 'Poppins',
    fontSize: '17px',
    letterSpacing: 0,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    color: neutral.D20,
  },
  headerRoot: {
    [theme.breakpoints.up('md')]: {
      padding: 12,
    },
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      height: 217,
      width: 557,
      overflowY: 'hidden',
    },
  },
}));
