import { dispatchAction } from '../../../../shared/infra';
import { addRTSelector, addRTAction, cargoStoresSelector } from '../../store';

import type { ValidateAddRTRequest } from '../../core/requests';

export function addRTMainHandler() {
  function validateAddRT(params: ValidateAddRTRequest) {
    dispatchAction(addRTAction.validate(params));
  }

  return {
    // TODO: cargoStoresSelector should be imported from cargo.selector.ts but after its refactoring
    cargoStores: cargoStoresSelector(),
    addRT: {
      status: addRTSelector.status(),
      selectedOrderId: addRTSelector.selectedOrderId(),
    },
    validateAddRT,
  };
}
