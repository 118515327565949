import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  mainSectionRoot: {
    [theme.breakpoints.up('md')]: {
      overflow: 'auto',
      gridArea: 'main',
    },
  },
  productsSectionRoot: {
    marginTop: 16,
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      overflow: 'auto',
      display: 'grid',
      gridTemplateRows: 'auto min-content',
      gridArea: 'products',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  root: {
    height: '100%',
    paddingTop: 24,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      columnGap: '1rem',
      gridTemplateAreas: `
                         "products main"
                         "footer footer"
                         "management management"`,
      gridTemplateColumns: '1fr 21.5rem',
      gridTemplateRows: 'auto 0fr 0fr',
      overflow: 'hidden',
      paddingTop: 0,
    },
  },
  totalDetailSectionRoot: {
    position: 'sticky',
    gridArea: 'footer',
  },
  orderManagementActionsRoot: {
    position: 'sticky',
    gridArea: 'management',
    padding: '1rem',
  },
  blockContent: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 1,
    backgroundColor: 'rgb(255 255 255 / 50%)',
  },
}));
