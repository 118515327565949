// External libs
import { createReducer } from '@reduxjs/toolkit';
// Interfaces
import { State } from '../interfaces';
// Actions
import actions from '../actions';

const defaultOrderId: number = 0;

const initialState: State = {
  orderId: defaultOrderId,
  description: '',
  open: false,
  supportSuggestions: [],
};

export default createReducer(initialState, {
  [actions.clearAction.type]: (state) => clear(state),
  [actions.closeAction.type]: (state) => close(state),
  [actions.openAction.type]: (
    state,
    { payload: { orderId, description, supportSuggestions } },
  ) => open(state, orderId, description, supportSuggestions),
});

const clear = (state: State) => {
  return initialState;
};

const close = (state: State) => {
  state.open = false;
  return state;
};

const open = (
  state: State,
  orderId = state.orderId,
  description = state.description,
  supportSuggestions = state.supportSuggestions,
) => {
  state.open = true;
  state.orderId = orderId;
  state.description = description;
  state.supportSuggestions = supportSuggestions;
  return state;
};
