import { useFormatMessage } from '../../../../../intl/intl';

import step1 from '../../../../../assets/images/change-password/step_01.png';
import step2 from '../../../../../assets/images/change-password/step_02.png';
import step3 from '../../../../../assets/images/change-password/step_03.png';

import { CHANGE_PASSWORD_PATH } from '../const/const';
import { PARTNERS_URL } from '../const/const';

export function getSteps() {
  const {
    step1Text,
    step2Text,
    step2BoldText,
    step3Text,
    step3BoldText,
  } = getTexts();
  return [
    {
      imgURL: step1,
      text: step1Text,
      link: PARTNERS_URL,
    },
    {
      imgURL: step2,
      text: step2Text,
      boldText: step2BoldText,
    },
    {
      imgURL: step3,
      text: step3Text,
      boldText: step3BoldText,
    },
  ];
}

export function getTexts() {
  const title = useFormatMessage({
    id: `${CHANGE_PASSWORD_PATH}.title`,
  });
  const btnLabel = useFormatMessage({
    id: `${CHANGE_PASSWORD_PATH}.btnLabel`,
  });
  const step1Text = useFormatMessage({
    id: `${CHANGE_PASSWORD_PATH}.step1Text`,
  });

  const step2Text = useFormatMessage({
    id: `${CHANGE_PASSWORD_PATH}.step2Text`,
  });
  const step2BoldText = useFormatMessage({
    id: `${CHANGE_PASSWORD_PATH}.step2BoldText`,
  });
  const step3Text = useFormatMessage({
    id: `${CHANGE_PASSWORD_PATH}.step3Text`,
  });
  const step3BoldText = useFormatMessage({
    id: `${CHANGE_PASSWORD_PATH}.step3BoldText`,
  });
  return {
    title,
    btnLabel,
    step1Text,
    step2Text,
    step2BoldText,
    step3Text,
    step3BoldText,
  };
}
