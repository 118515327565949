import { IconButton, Typography } from '@material-ui/core';

import { ReactComponent as CloseIcon } from '../../../../assets/images/icons/close_icon.svg';
import { ReactComponent as BackArrowIcon } from '../../../../assets/images/icons/backArrow.svg';

import type { ReactNode } from 'react';

export function Header(props?: Props) {
  const { onClose, onGoBack, classes = {}, children = null } = props ?? {};

  const isString = typeof children === 'string';

  return (
    <header className={classes?.root}>
      {onGoBack && (
        <IconButton aria-label='back' onClick={onGoBack}>
          <BackArrowIcon />
        </IconButton>
      )}

      <div className={classes?.titleContainer}>
        {!isString && children}
        {isString && (
          <Typography
            component='h1'
            variant='body1'
            className={classes?.titleH1}
          >
            {children}
          </Typography>
        )}
      </div>

      <IconButton aria-label='close' onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </header>
  );
}

interface Props {
  onClose: () => void;
  onGoBack?: () => void;
  classes?: {
    root?: string;
    titleContainer?: string;
    titleH1?: string;
  };
  children?: ReactNode;
}
