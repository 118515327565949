import { CargoSelectors, cargoActions } from '../store';
import appStore from '../../../../redux/stores/app.store';

import type { ICargoUI } from '../core/contracts';

export const cargoUIHandler = () => {
  return {
    get,
    dispatch,
  };
};

function get(): ICargoUI {
  const {
    selectUI,
    selectIsDisplayed,
    selectStores,
    selectStatus,
    selectOrderId,
    selectPartnerStores,
    selectShippingCost,
  } = CargoSelectors();

  const partnerId = appStore.getPartnerId();

  return {
    data: selectUI,
    stateStatus: selectStatus,
    isDisplayed: selectIsDisplayed,
    stores: selectStores,
    storeIdSelected: selectOrderId, // TODO: WTFood <- Nandi!
    partnerId,
    partnerStores: selectPartnerStores,
    shippingCost: selectShippingCost,
  };
}

function dispatch() {
  const {
    onClear,
    onClearUI,
    onClearNewOrder,
    onSetOpenDialog,
    onSetStatusDialog,
    onSuccessfulDialog,
    onErrorDialog,
    onGetCargoStores,
  } = cargoActions();
  return {
    onClear,
    onClearUI,
    onClearNewOrder,
    onSetOpenDialog,
    onSetStatusDialog,
    onSuccessfulDialog,
    onErrorDialog,
    onGetCargoStores,
  };
}
