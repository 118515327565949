// Material UI
import { makeStyles } from '@material-ui/core'
// Constant
import Colors from '../../constants/Colors'

export default makeStyles(theme => ({
  root: { alignItems: 'center', display: 'flex', justifyContent: 'center' },
  icon: {
    color: Colors.PRIMARY_COLOR,
    border: `1px solid ${Colors.PRIMARY_COLOR}`,
  },
  submitButton: {
    padding: 0,
    fontFamily: 'Poppins Bold',
    fontSize: '13px !important',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    fontWeight: 'normal',
  },
}))
