// React
import React from 'react'
// Material UI
import { SvgIcon } from '@material-ui/core'

const BackArrowIcon = () => {
  return (
    <SvgIcon>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        fill='none'
        viewBox='0 0 24 24'
      >
        <path
          fill='#332927'
          fillRule='evenodd'
          d='M20 11H8.83l4.88-4.88c.39-.39.39-1.03 0-1.42-.39-.39-1.02-.39-1.41 0l-6.59 6.59c-.39.39-.39 1.02 0 1.41l6.59 6.59c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L8.83 13H20c.55 0 1-.45 1-1s-.45-1-1-1z'
          clipRule='evenodd'
        />
      </svg>
    </SvgIcon>
  )
}

export default BackArrowIcon
