import { useDispatch, useSelector } from 'react-redux';

import { printerActions } from './printerGlobalState.slice';

import type { StartupNotificationsGlobalState } from '../../infra/startupNotificationsGlobalState.model';

interface GS extends StartupNotificationsGlobalState {}

export function printerIsNotifiedFirstService() {
  const dispatch = useDispatch();

  const isNotifiedFirstMode = useSelector(
    (s: GS) => s.startupNotifications.printer.isNotified.firstMode,
  );

  function setIsNotifiedFirstMode() {
    dispatch(printerActions.setIsNotifiedFirstMode());
  }

  return {
    isNotifiedFirstMode,
    setIsNotifiedFirstMode,
  };
}
