import Box from '@material-ui/core/Box';
import Typography, { FontWeight } from '../../../../../common/typography';

export function Footer({ ...props }: Props) {
  return (
    <Box
      className={props.classWrapper}
      display='flex'
      justifyContent='space-between'
      fontSize='1.0625rem'
    >
      <Typography fontWeight={FontWeight.SemiBold}>
        {props.footerText}
      </Typography>
      <Typography
        classes={{ root: props.classBody }}
        fontWeight={FontWeight.SemiBold}
      >
        {props.subtotal}
      </Typography>
    </Box>
  );
}

type Props = {
  classWrapper: string;
  classBody: string;
  footerText: string;
  subtotal: number | undefined;
};
