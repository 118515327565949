import React from 'react';
import { Box, Typography } from '@material-ui/core';
import {
  COUNTDOWN_ORDER_IN_SEG,
  OrderMgmtProduct,
  orderMgmtState,
} from '../../../../../business/order-mgmt';
import { OrderMgmtMainOptions } from './OrderMgmtMainOptions';
import { OrderMgmtDeactivatedItems } from './OrderMgmtDeactivatedItems';
import { CountdownClock } from '../../countdown-edit/components/CountdownClock';
import OrderMgmtStyles from '../../../OrderManagement.style';

export const OrderMgmtMainContainer = ({
  title,
  products,
  optionsTexts,
  infoUserTitleText,
  infoUserDescriptionText,
  deactivatedItemsText,
  callUserText,
  startChatText,
  userName,
  continueButtonText,
  isDeactivatingItemsState,
  handleOrderOption,
  isOrderEnabledToEdit,
  handleSelectProduct,
  handleSelectTopping,
  isOfferReplaceItemsEnabled,
  handleDeactivateProductsAndToppings,
  showSupportWebChatButton,
  handleOpenSupportWebChat,
  handleOpenCustomerPhoneSessionDialog,
  canSendChangesByTime,
  isOrderBeingCooked,
  tooltipOptionDisclaimerText,
  deactivateProductIds,
  deactivateToppingIds,
  startedAt
}: Props) => {
  const componentClasses = OrderMgmtStyles();
  return (
    <Box
      padding='0 1.5rem 1.5rem'
      display='flex'
      flexDirection='column'
      gridGap='1rem'
      height='100%'
    >
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography className={componentClasses.title}>{title}</Typography>
        {!isOrderBeingCooked && startedAt && (
          <CountdownClock
            createdAt={startedAt}
            countDownTime={COUNTDOWN_ORDER_IN_SEG}
          />)
        }
      </Box>
      {isDeactivatingItemsState ? (
        <OrderMgmtDeactivatedItems
          products={products}
          deactivatedItemsText={deactivatedItemsText}
          continueButtonText={continueButtonText}
          handleSelectProduct={handleSelectProduct}
          handleSelectTopping={handleSelectTopping}
          isOfferReplaceItemsEnabled={isOfferReplaceItemsEnabled}
          handleDeactivateProductsAndToppings={
            handleDeactivateProductsAndToppings
          }
          canSendChangesByTime={canSendChangesByTime}
          deactivateProductIds={deactivateProductIds}
          deactivateToppingIds={deactivateToppingIds}
        />
      ) : (
        <OrderMgmtMainOptions
          infoUserTitleText={infoUserTitleText}
          infoUserDescriptionText={infoUserDescriptionText}
          callUserText={callUserText}
          startChatText={startChatText}
          optionsTexts={optionsTexts}
          userName={userName}
          continueButtonText={continueButtonText}
          handleOrderOption={handleOrderOption}
          isOrderEnabledToEdit={isOrderEnabledToEdit}
          showSupportWebChatButton={showSupportWebChatButton}
          handleOpenSupportWebChat={handleOpenSupportWebChat}
          handleOpenCustomerPhoneSessionDialog={
            handleOpenCustomerPhoneSessionDialog
          }
          canSendChangesByTime={canSendChangesByTime}
          isOrderBeingCooked={isOrderBeingCooked}
          tooltipOptionDisclaimerText={tooltipOptionDisclaimerText}
        />
      )}
    </Box>
  );
};

interface Props {
  title: string;
  products: Array<OrderMgmtProduct>;
  orderId: number;
  infoUserTitleText: string;
  infoUserDescriptionText: string;
  optionsTexts: Array<{
    title: string;
    description: string;
    option: orderMgmtState;
  }>;
  deactivatedItemsText: string;
  callUserText: string;
  startChatText: string;
  userName: string;
  continueButtonText: string;
  isDeactivatingItemsState: boolean;
  handleOrderOption: any;
  isOrderEnabledToEdit: boolean;
  handleSelectProduct: any;
  handleSelectTopping: any;
  isOfferReplaceItemsEnabled: boolean;
  handleDeactivateProductsAndToppings: any;
  showSupportWebChatButton: boolean;
  handleOpenSupportWebChat: any;
  handleOpenCustomerPhoneSessionDialog: any;
  canSendChangesByTime: boolean;
  isOrderBeingCooked: boolean;
  tooltipOptionDisclaimerText: string;
  deactivateProductIds: Array<number>;
  deactivateToppingIds: Array<number>;
  startedAt?: string
}
