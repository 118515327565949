// Material UI
import { makeStyles } from '@material-ui/core';
import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {
    display: 'grid',
  },
  weekdaysLabel: {
    borderBottom: `1px solid ${neutral.D10}`,
  },
  weekendsLabel: {
    borderBottom: `1px solid ${neutral.D10}`,
    paddingTop: 40,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 40,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 10,
    },
  },
  weekdaysContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
      paddingBottom: 20,
    },
  },
  weekendsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      paddingBottom: 60,
    },
  },
}));
