import { clsx } from 'clsx';
import { useDispatch } from 'react-redux';

import { useErrorText } from './hooks';
import { useFormatMessage } from '../../../../../../intl/intl';
import orderActions from '../../../../../../redux/actions/orders-actions';

import CustomButton, {
  Colors,
  Variants,
} from '../../../../../common/buttons/custom-button';

import useStyles from './styles';

import type { BaseProps } from '../../../../../../interfaces';

const retryButtonTextId = {
  id: 'orderDetailDialogTotalDetailFailedSection.retryButtonText',
};

export function FailedSection({ classes }: BaseProps) {
  const _classes = useStyles();

  const retryButtonText = useFormatMessage(retryButtonTextId);

  const errorText = useErrorText();

  const dispatch = useDispatch();

  const handleClickButton = () => {
    dispatch(orderActions.retryLodingOrderTotals());
  };

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <div>{errorText}</div>
      <CustomButton
        classes={{
          submitButton: clsx(_classes.submitButton, classes?.submitButton),
        }}
        color={Colors.SECONDARY}
        onClick={handleClickButton}
        variant={Variants.CONTAINED}
      >
        {retryButtonText}
      </CustomButton>
    </div>
  );
}
