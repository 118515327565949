// Material UI
import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  root: {
    width: 489,
    height: 'auto',
  },
  container: {
    padding: '60px 20px 60px 20px',
    fontSize: 16,
  },
  title: {
    fontSize: 18,
  },
  titleBox: {
    padding: 20,
  },
}))
