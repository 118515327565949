import { clsx } from 'clsx';

import { CustomPrintButton } from '../custom-print-button';

import useStyles from './CustomOutlinePrintButton.styles';

export function CustomOutlinePrintButton({ onClick, classes = {} }) {
  const _classes = useStyles();
  return (
    <CustomPrintButton
      classes={{
        button: clsx(_classes.button, classes?.button),
        icon: clsx(_classes.icon, classes?.icon),
      }}
      onClick={onClick}
    />
  );
}
