export function toCapitalizeCase(str: string = '') {
  return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
}

export function toCapitalizeCaseWithSeparator(params: toCapitalizeParams) {
  const { str = '', separator = ' ' } = params;
  return str.split(separator).map(toCapitalizeCase).join(separator);
}

interface toCapitalizeParams {
  str: string;
  separator?: string;
}
