// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import Colors from '../../constants/Colors';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  avatarRoot: {
    width: '3.25rem',
    height: '3.25rem',
    backgroundColor: 'lightgray',
  },
  infoContainer: {
    columnGap: '1rem',
  },
  root: {
    color: neutral.D20,
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    rowGap: '1rem',
    padding: 12,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      alignSelf: 'start',
      textAlign: 'left',
      padding: '12px 16px',
      width: 248,
    },
  },
  name: {
    fontSize: '1.125rem',
    fontWeight: 600,
  },
  secondaryInfo: {
    color: neutral.D15,
  },
  rtStateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '1rem',
    padding: '1rem',
    backgroundColor: Colors.BACKGROUND_COLOR_RT_STATE,
  },
  rtStateIcon: {},
  rtState: {
    justifyContent: 'center',
  },
  rtStateBtn: {
    padding: '0.5rem',
    fontSize: '0.75rem',
    boxShadow: 'none',
    borderRadius: '0.5rem',
    backgroundColor: Colors.MALACHITE,
  },
}));
