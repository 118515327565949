// Material UI
import { makeStyles } from '@material-ui/core';
import { neutral } from '../../../../../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {
    alignItems: 'center',
    color: neutral.D20,
    display: 'grid',
    fontFamily: 'Poppins Bold',
    fontSize: 16,
    fontWeight: 700,
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
    lineHeight: 'normal',
    padding: '20px 16px',
    [theme.breakpoints.up('md')]: {
      padding: '20px 24px',
    },
  },
  submitButton: {
    fontSize: '13px !important',
    padding: '6px 16px',
  },
}));
