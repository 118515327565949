// External libs
import { handleActions } from 'redux-actions'
// Consts
import DeactivationType from '../../constants/DeactivationType'
// Utils
import MenuUtils from '../../utils/MenuUtils'
// State management
import MenuActions from '../actions/menu.actions'
import { get, sortBy, remove, union } from 'lodash'

const initialState = {
  areCorridorsLoading: false,
  areCorridorProductsLoading: false,
  areProductToppingsLoading: false,
  toppings: [],
  toppingsMassiveDeactivationDrawer: {
    storeId: null,
    toppings: [],
    suggestedToppings: [],
    deactivationType: DeactivationType.DAILY,
    deactivationDate: '',
    open: false,
  },
  noDeactivatedToppingDrawer: {
    storeId: null,
    noDeactivatedToppings: [],
    open: false,
  },
  menus: {},
  productsOutOfStock: [],
  searchedProducts: [],
  searchedToppings: [],
  selectedCorridorId: null,
  selectedProductId: null,
  selectedCorridorProducts: [],
  selectedProductToppingCategories: [],
  toppingsOutOfStock: [],
  displayOutOfStock: false,
  filter: { selectedStoreId: '0', searchText: '' },
}

export default handleActions(
  {
    [MenuActions.applyMenuFilterAction]: (
      state,
      { payload: { selectedStoreId } },
    ) => applyMenuFilter(selectedStoreId, state),
    [MenuActions.closeMenuFilterDrawerAction]: state =>
      toggleMenuFilterDrawer(false, state),
    [MenuActions.activateProductAction]: (
      state,
      { payload: { productId, storeId } },
    ) => setProductAvailability(state, true, productId, storeId),
    [MenuActions.deactivateProductAction]: (
      state,
      { payload: { productId, storeId } },
    ) => setProductAvailability(state, false, productId, storeId),
    [MenuActions.activateToppingAction]: (state, { payload: { toppingId } }) =>
      setToppingAvailability(state, true, toppingId),
    [MenuActions.deactivateToppingAction]: (
      state,
      { payload: { toppingId } },
    ) => setToppingAvailability(state, false, toppingId),
    [MenuActions.getCorridorsAction]: (
      state,
      { payload: { corridors, storeId } },
    ) => getCorridors(state, corridors, storeId),
    [MenuActions.setCorridorsAreNotLoadingAction]: state =>
      setCorridorsLoading(state, false),
    [MenuActions.setCorridorsAreLoadingAction]: state =>
      setCorridorsLoading(state, true),
    [MenuActions.setCorridorProductsAreNotLoadingAction]: state =>
      setCorridorProductsLoading(state, false),
    [MenuActions.setCorridorProductsAreLoadingAction]: state =>
      setCorridorProductsLoading(state, true),
    [MenuActions.setProductToppingsAreNotLoadingAction]: state =>
      setProductToppingsLoading(state, false),
    [MenuActions.setProductToppingsAreLoadingAction]: state =>
      setProductToppingsLoading(state, true),
    [MenuActions.getCorridorProductsAction]: (
      state,
      { payload: { products, storeId } },
    ) => getCorridorProducts(products, storeId, state),
    [MenuActions.getMenuByStoreAction]: (
      state,
      { payload: { menu, storeId } },
    ) => getMenuByStore(state, menu, storeId),
    [MenuActions.getProductToppingsAction]: (
      state,
      { payload: { storeId, toppings } },
    ) => getProductToppings(storeId, toppings, state),
    [MenuActions.selectCorridorAction]: (state, { payload: { corridorId } }) =>
      selectCorridor(state, corridorId),
    [MenuActions.selectProductAction]: (state, { payload: { productId } }) =>
      selectProduct(productId, state),
    [MenuActions.displayOutOfStockAction]: state =>
      toggleDisplayOutOfStock(true, state),
    [MenuActions.hideOutOfStockAction]: state =>
      toggleDisplayOutOfStock(false, state),
    [MenuActions.setLastToppingsDeactivatedAction]: (
      state,
      { payload: { storeId, toppingsId, deactivationType, deactivationDate } },
    ) =>
      setLastToppingsDeactivated(
        state,
        storeId,
        toppingsId,
        deactivationType,
        deactivationDate,
      ),
    [MenuActions.openMenuFilterDrawerAction]: state =>
      toggleMenuFilterDrawer(true, state),
    [MenuActions.closeToppingsMassiveDeactivationDrawerAction]: state =>
      toggleToppingsMassiveDeactivationDrawer(state, false),
    [MenuActions.openToppingsMassiveDeactivationDrawerAction]: state =>
      toggleToppingsMassiveDeactivationDrawer(state, true),
    [MenuActions.closeNoDeactivatedToppingDrawerAction]: state =>
      closeNoDeactivatedToppingDrawer(state),
    [MenuActions.openNoDeactivatedToppingDrawerAction]: (
      state,
      { payload: { noDeactivatedToppingIds, storeId } },
    ) =>
      openNoDeactivatedToppingDrawer(state, noDeactivatedToppingIds, storeId),
    [MenuActions.setSearchTextAction]: (state, { payload: { text } }) =>
      setSearchText(state, text),
    [MenuActions.searchProductsAction]: state => searchProducts(state),
    [MenuActions.searchToppingsAction]: state => searchToppings(state),
    [MenuActions.setProductsOutOfStockAction]: (
      state,
      { payload: { productsOutOfStock } },
    ) => setProductsOutOfStock(state, productsOutOfStock),
    [MenuActions.setToppingsOutOfStockAction]: (
      state,
      { payload: { toppingsOutOfStock } },
    ) => setToppingsOutOfStock(state, toppingsOutOfStock),
    [MenuActions.syncProductsAvailabilityAction]: (
      state,
      { payload: { storeId } },
    ) => syncProductsAvailability(state, storeId),
    [MenuActions.syncToppingsAvailabilityAction]: (
      state,
      { payload: { storeId } },
    ) => syncToppingsAvailability(state, storeId),
  },
  initialState,
)

const applyMenuFilter = (selectedStoreId, state) => {
  return {
    ...state,
    filter: {
      ...state.filter,
      searchText: '',
      selectedStoreId: selectedStoreId && selectedStoreId.toString(),
    },
    selectedCorridorId: null,
    selectedProductId: null,
  }
}

const getCorridors = (state, corridors, storeId) => {
  const storeMenu = state.menus[storeId]

  const formattedCorridors = MenuUtils.formatCorridors(corridors)

  if (!storeMenu) {
    const newStoreMenu = {
      corridors: formattedCorridors,
      products: [],
      toppingCategories: [],
      toppings: [],
      wasCompletelyDownloaded: false,
    }
    const shallowClonedMenus = { ...state.menus, [storeId]: newStoreMenu }
    return { ...state, menus: shallowClonedMenus }
  }

  const shallowClonedMenu = {
    ...storeMenu,
    corridors: formattedCorridors,
  }
  const shallowClonedMenus = { ...state.menus, [storeId]: shallowClonedMenu }

  return {
    ...state,
    menus: shallowClonedMenus,
  }
}

const getCorridorProducts = (products, storeId, state) => {
  // Get store's menu.
  const storeMenu = state.menus[storeId]
  if (!storeMenu) {
    // If the store's menu doesn't exist yet, create a new one.
    const newStoreMenu = {
      corridors: [],
      products: products,
      toppings: [],
      wasCompletelyDownloaded: false,
    }
    // Save the new menú.
    const shallowClonedMenus = { ...state.menus, [storeId]: newStoreMenu }
    return {
      ...state,
      menus: shallowClonedMenus,
    }
  }
  let shallowClonedProducts = [...storeMenu.products]

  for (const productToUpdate of products) {
    const productInStoreIndex = shallowClonedProducts.findIndex(
      product => product.id === productToUpdate.id,
    )
    if (productInStoreIndex === -1) {
      productToUpdate.corridorIds = [productToUpdate.corridor_id]
      delete productToUpdate.corridor_id
      shallowClonedProducts.push(productToUpdate)
      continue
    }
    productToUpdate.corridorIds = union(
      shallowClonedProducts[productInStoreIndex].corridorIds,
      [productToUpdate.corridor_id],
    )
    shallowClonedProducts[productInStoreIndex] = {
      ...shallowClonedProducts[productInStoreIndex],
      ...productToUpdate,
    }
  }

  const shallowClonedMenu = {
    ...storeMenu,
    products: shallowClonedProducts,
  }
  const shallowClonedMenus = { ...state.menus, [storeId]: shallowClonedMenu }

  return {
    ...state,
    menus: shallowClonedMenus,
  }
}

const getMenuByStore = (state, menu, storeId) => {
  const formmatedMenu = MenuUtils.formatMenu(menu)

  const storeMenu = state.menus[storeId]
  if (!storeMenu) {
    const shallowClonedMenus = {
      ...state.menus,
      [storeId]: {
        ...formmatedMenu,
        wasCompletelyDownloaded: true,
      },
    }
    return { ...state, menus: shallowClonedMenus }
  }

  const shallowClonedMenus = {
    ...state.menus,
    [storeId]: {
      ...formmatedMenu,
      wasCompletelyDownloaded: true,
    },
  }

  const shallowClonedState = { ...state, menus: shallowClonedMenus }

  const formattedToppingsOutOfStock = MenuUtils.formatToppingsOutOfStock(
    formmatedMenu.toppings,
    shallowClonedState.toppingsOutOfStock,
    formmatedMenu.products,
    formmatedMenu.toppingCategories,
  )
  shallowClonedState.toppingsOutOfStock = formattedToppingsOutOfStock
  return shallowClonedState
}

const getProductToppings = (storeId, toppingCategories, state) => {
  const formattedToppingCategories = MenuUtils.formatToppingCategories(
    toppingCategories,
  )

  const formattedToppings = MenuUtils.formatToppings(toppingCategories)

  const storeMenu = state.menus[storeId]
  if (!storeMenu) {
    const newStoreMenu = {
      corridors: [],
      products: [],
      toppingCategories: formattedToppingCategories,
      toppings: formattedToppings,
      wasCompletelyDownloaded: false,
    }
    const shallowClonedMenus = { ...state.menus, [storeId]: newStoreMenu }
    return { ...state, menus: shallowClonedMenus }
  }

  let shallowClonedToppingCategories = [...storeMenu.toppingCategories]

  for (const toppingCategoryToUpdate of formattedToppingCategories) {
    const toppingCategoryInStoreIndex = shallowClonedToppingCategories.findIndex(
      tc => tc.id === toppingCategoryToUpdate.id,
    )
    if (toppingCategoryInStoreIndex === -1) {
      shallowClonedToppingCategories.push(toppingCategoryToUpdate)
      continue
    }

    toppingCategoryToUpdate.productIds = union(
      shallowClonedToppingCategories[toppingCategoryInStoreIndex].productIds,
      toppingCategoryToUpdate.productIds,
    )

    shallowClonedToppingCategories[toppingCategoryInStoreIndex] = {
      ...shallowClonedToppingCategories[toppingCategoryInStoreIndex],
      ...toppingCategoryToUpdate,
    }
  }
  let shallowClonedToppings = [...storeMenu.toppings]

  for (const toppingToUpdate of formattedToppings) {
    const toppingInStoreIndex = shallowClonedToppings.findIndex(
      t => t.id === toppingToUpdate.id,
    )

    if (toppingInStoreIndex === -1) {
      shallowClonedToppings.push(toppingToUpdate)
      continue
    }
    toppingToUpdate.productIds = union(
      shallowClonedToppings[toppingInStoreIndex].productIds,
      toppingToUpdate.productIds,
    )

    shallowClonedToppings[toppingInStoreIndex] = {
      ...shallowClonedToppings[toppingInStoreIndex],
      ...toppingToUpdate,
    }
  }

  const shallowClonedMenu = {
    ...storeMenu,
    toppingCategories: shallowClonedToppingCategories,
    toppings: shallowClonedToppings,
  }
  const shallowClonedMenus = { ...state.menus, [storeId]: shallowClonedMenu }

  return {
    ...state,
    menus: shallowClonedMenus,
  }
}

const selectCorridor = (state, selectedCorridorId) => {
  if (!selectedCorridorId) {
    return { ...state, selectedCorridorId, selectedCorridorProducts: [] }
  }

  const { filter, menus } = state
  // Get selected store.
  const selectedStoreId = +filter.selectedStoreId
  const storeMenu = menus[selectedStoreId]
  let selectedCorridorProducts = MenuUtils.filterByCorridorId(
    selectedCorridorId,
    storeMenu.products,
  )
  selectedCorridorProducts = sortBy(selectedCorridorProducts, [
    'product_index',
    'name',
  ])
  return { ...state, selectedCorridorId, selectedCorridorProducts }
}

const selectProduct = (selectedProductId, state) => {
  if (!selectedProductId) {
    return {
      ...state,
      selectedProductId,
      selectedProductToppingCategories: [],
    }
  }

  const { filter, menus } = state
  // Get selected store.
  const selectedStoreId = +filter.selectedStoreId
  const storeMenu = menus[selectedStoreId]
  const selectedProductToppingCategories = MenuUtils.getToppingCategoryTrees(
    selectedProductId,
    storeMenu.toppingCategories,
    storeMenu.toppings,
  )
  return { ...state, selectedProductId, selectedProductToppingCategories }
}

const searchProducts = state => {
  const { filter, menus } = state
  // Get selected store.
  const selectedStoreId = +filter.selectedStoreId
  const allCorridors = get(menus, [selectedStoreId, 'corridors'], [])
  const allProducts = get(menus, [selectedStoreId, 'products'], [])
  // Get the text to search by.
  const searchText = filter.searchText
  // Get all selected store's products.
  // Search products.
  const searchedProducts = MenuUtils.getProductsByNameDescriptionContainsString(
    searchText,
    allProducts,
  )

  for (const product of searchedProducts) {
    const productCorridor = allCorridors.find(
      c => c.corridor_id === product.corridorIds[0],
    )

    if (!productCorridor) {
      console.error(
        `Corridor ${product.corridorIds[0]} not found for product ${product.id}`,
      )
      continue
    }

    product.corridorName = productCorridor.corridor_name
  }

  // Update the state.
  return { ...state, searchedProducts }
}

const searchToppings = state => {
  const { filter, menus } = state
  // Get selected store.
  const selectedStoreId = +filter.selectedStoreId
  // Get the text to search by.
  const searchText = filter.searchText
  // Get all selected store's topping categories.
  const allProducts = get(menus, [selectedStoreId, 'products'], [])
  const allToppingCategories = get(
    menus,
    [selectedStoreId, 'toppingCategories'],
    [],
  )
  const toppings = get(menus, [selectedStoreId, 'toppings'], [])
  // Search toppings
  const searchedToppings = MenuUtils.getToppingsByDescriptionContainsStrings(
    [searchText],
    toppings,
  )

  for (const searchedTopping of searchedToppings) {
    searchedTopping.title = MenuUtils.getToppingTitle(
      allProducts,
      searchedTopping,
      allToppingCategories,
    )
  }

  // Update the state.
  return { ...state, searchedToppings }
}

const setSearchText = (state, text) => {
  return {
    ...state,
    filter: {
      ...state.filter,
      searchText: text,
    },
  }
}

const setProductAvailability = (state, isAvailable, productId) => {
  const storeId = +state.filter.selectedStoreId
  const shallowClonedMenus = { ...state.menus }
  const shallowClonedStoreMenu = { ...shallowClonedMenus[storeId] }
  const shallowClonedProducts = [...shallowClonedStoreMenu.products]

  const productIndex = shallowClonedProducts.findIndex(p => p.id === +productId)

  const shallowClonedProduct = {
    ...shallowClonedProducts[productIndex],
    is_available: isAvailable,
  }

  shallowClonedProducts[productIndex] = shallowClonedProduct

  shallowClonedStoreMenu.products = shallowClonedProducts

  shallowClonedMenus[storeId] = shallowClonedStoreMenu

  const shallowClonedState = { ...state, menus: shallowClonedMenus }

  const shallowClonedSelectedCorridorProducts = [
    ...state.selectedCorridorProducts,
  ]
  if (shallowClonedSelectedCorridorProducts.length > 0) {
    const selectedCorridorProductIndex = shallowClonedSelectedCorridorProducts.findIndex(
      p => p.id === +productId,
    )

    const shallowClonedSelectedCorridorProduct = {
      ...shallowClonedSelectedCorridorProducts[selectedCorridorProductIndex],
      is_available: isAvailable,
    }

    shallowClonedSelectedCorridorProducts[
      selectedCorridorProductIndex
    ] = shallowClonedSelectedCorridorProduct
    shallowClonedState.selectedCorridorProducts = shallowClonedSelectedCorridorProducts
  }

  const searchText = state.filter.searchText

  if (!searchText || searchText.trim() === '') {
    return shallowClonedState
  }

  const shallowClonedSearchedProducts = [...state.searchedProducts]

  const searchedProductIndex = shallowClonedSearchedProducts.findIndex(
    sp => sp.id === +productId,
  )

  if (searchedProductIndex === -1) {
    return shallowClonedState
  }

  const shallowClonedSearchedProduct = {
    ...shallowClonedSearchedProducts[searchedProductIndex],
    is_available: isAvailable,
  }

  shallowClonedSearchedProducts[
    searchedProductIndex
  ] = shallowClonedSearchedProduct

  shallowClonedState.searchedProducts = shallowClonedSearchedProducts
  return shallowClonedState
}

const setToppingAvailability = (state, activated, toppingId) => {
  if (!toppingId) {
    return state
  }
  const storeId = +state.filter.selectedStoreId
  const shallowClonedMenus = { ...state.menus }
  const shallowClonedStoreMenu = { ...shallowClonedMenus[storeId] }
  const shallowClonedToppings = [...shallowClonedStoreMenu.toppings]

  const toppingIndex = shallowClonedToppings.findIndex(t => t.id === +toppingId)

  const shallowClonedTopping = {
    ...shallowClonedToppings[toppingIndex],
    activated,
  }

  shallowClonedToppings[toppingIndex] = shallowClonedTopping

  shallowClonedStoreMenu.toppings = shallowClonedToppings

  shallowClonedMenus[storeId] = shallowClonedStoreMenu

  const shallowClonedState = { ...state, menus: shallowClonedMenus }

  const shallowClonedSelectedProductToppingCategories = [
    ...state.selectedProductToppingCategories,
  ]

  if (shallowClonedSelectedProductToppingCategories.length > 0) {
    for (
      let i = 0;
      i < shallowClonedSelectedProductToppingCategories.length;
      i++
    ) {
      const shallowClonedToppings = [
        ...shallowClonedSelectedProductToppingCategories[i].toppings,
      ]

      const toppingToUpdateIndex = shallowClonedToppings.findIndex(
        t => t.id === toppingId,
      )

      if (toppingToUpdateIndex === -1) {
        continue
      }

      const shallowClonedTopping = {
        ...shallowClonedToppings[toppingToUpdateIndex],
        activated,
      }

      shallowClonedToppings[toppingToUpdateIndex] = shallowClonedTopping
      shallowClonedSelectedProductToppingCategories[
        i
      ].toppings = shallowClonedToppings
    }

    shallowClonedState.selectedProductToppingCategories = shallowClonedSelectedProductToppingCategories
  }

  const searchText = state.filter.searchText

  if (!searchText || searchText.trim() === '') {
    return shallowClonedState
  }

  const shallowClonedSearchedToppings = [...state.searchedToppings]

  const searchedToppingIndex = shallowClonedSearchedToppings.findIndex(
    st => st.id === +toppingId,
  )

  if (searchedToppingIndex === -1) {
    return shallowClonedState
  }

  const shallowClonedSearchedTopping = {
    ...shallowClonedSearchedToppings[searchedToppingIndex],
    activated,
  }

  shallowClonedSearchedToppings[
    searchedToppingIndex
  ] = shallowClonedSearchedTopping

  shallowClonedState.searchedToppings = shallowClonedSearchedToppings
  return shallowClonedState
}

const setLastToppingsDeactivated = (
  state,
  storeId,
  toppingIds,
  deactivationType,
  deactivationDate,
) => {
  const { menus, toppingsMassiveDeactivationDrawer } = state
  const products = get(menus, [storeId, 'products'], [])
  const toppingCategories = get(menus, [storeId, 'toppingCategories'], [])
  const toppings = get(menus, [storeId, 'toppings'], [])

  const deactivatedToppings = MenuUtils.filterToppingsByIds(
    toppings,
    toppingIds,
  )

  const deactivatedToppingNames = deactivatedToppings.map(t => t.description)

  let filteredToppings = MenuUtils.getToppingsByDescriptionContainsStrings(
    deactivatedToppingNames,
    toppings,
    true,
  )

  remove(filteredToppings, t => toppingIds.indexOf(+t.id) !== -1)

  const shallowClonedToppingsMassiveDeactivationDrawer = {
    ...toppingsMassiveDeactivationDrawer,
    storeId: +storeId,
    toppings: deactivatedToppings,
  }

  if (filteredToppings.length === 0) {
    shallowClonedToppingsMassiveDeactivationDrawer.open = false
    return {
      ...state,
      toppingsMassiveDeactivationDrawer: shallowClonedToppingsMassiveDeactivationDrawer,
    }
  }

  for (const filteredTopping of filteredToppings) {
    filteredTopping.title = MenuUtils.getToppingTitle(
      products,
      filteredTopping,
      toppingCategories,
    )
  }

  shallowClonedToppingsMassiveDeactivationDrawer.open = true
  shallowClonedToppingsMassiveDeactivationDrawer.deactivationType = deactivationType
  shallowClonedToppingsMassiveDeactivationDrawer.deactivationDate = deactivationDate
  shallowClonedToppingsMassiveDeactivationDrawer.suggestedToppings = filteredToppings

  return {
    ...state,
    toppingsMassiveDeactivationDrawer: shallowClonedToppingsMassiveDeactivationDrawer,
  }
}

const setProductsOutOfStock = (state, productsOutOfStock) => {
  const formattedProductsOutOfStock = MenuUtils.formatProductsOutOfStock(
    productsOutOfStock,
  )
  return { ...state, productsOutOfStock: formattedProductsOutOfStock }
}

const setToppingsOutOfStock = (state, toppingsOutOfStock) => {
  const storeId = +state.filter.selectedStoreId
  const products = get(state.menus, [storeId, 'products'], [])
  const toppingCategories = get(state.menus, [storeId, 'toppingCategories'], [])
  const toppings = get(state.menus, [storeId, 'toppings'], [])
  const formattedToppingsOutOfStock = MenuUtils.formatToppingsOutOfStock(
    toppings,
    toppingsOutOfStock,
    products,
    toppingCategories,
  )
  return { ...state, toppingsOutOfStock: formattedToppingsOutOfStock }
}

const toggleDisplayOutOfStock = (displayOutOfStock, state) => {
  return { ...state, displayOutOfStock }
}

const toggleMenuFilterDrawer = (open, state) => {
  return { ...state, filter: { ...state.filter, open } }
}

const toggleToppingsMassiveDeactivationDrawer = (state, open) => {
  const shallowClonedToppingsMassiveDeactivationDrawer = {
    ...state.toppingsMassiveDeactivationDrawer,
    open,
  }
  if (!shallowClonedToppingsMassiveDeactivationDrawer.open) {
    shallowClonedToppingsMassiveDeactivationDrawer.storeId = null
    shallowClonedToppingsMassiveDeactivationDrawer.toppings = []
    shallowClonedToppingsMassiveDeactivationDrawer.suggestedToppings = []
  }
  return {
    ...state,
    toppingsMassiveDeactivationDrawer: shallowClonedToppingsMassiveDeactivationDrawer,
  }
}

const openNoDeactivatedToppingDrawer = (
  state,
  noDeactivatedToppingIds,
  storeId,
) => {
  const { menus } = state
  const allProducts = get(menus, [storeId, 'products'], [])
  const allToppingCategories = get(menus, [storeId, 'toppingCategories'], [])
  const allToppings = get(menus, [storeId, 'toppings'], [])
  const noDeactivatedToppings = MenuUtils.filterToppingsByIds(
    allToppings,
    noDeactivatedToppingIds,
  )

  for (const noDeactivatedTopping of noDeactivatedToppings) {
    noDeactivatedTopping.title = MenuUtils.getToppingTitle(
      allProducts,
      noDeactivatedTopping,
      allToppingCategories,
    )
  }

  const shallowClonedNoDeactivatedToppingDrawer = {
    ...state.NoDeactivatedToppingDrawer,
    noDeactivatedToppings,
    open: true,
    storeId,
  }

  return {
    ...state,
    noDeactivatedToppingDrawer: shallowClonedNoDeactivatedToppingDrawer,
  }
}

const closeNoDeactivatedToppingDrawer = state => {
  const shallowClonedNoDeactivatedToppingDrawer = {
    ...state.NoDeactivatedToppingDrawer,
    noDeactivatedToppings: [],
    open: false,
    storeId: null,
  }

  return {
    ...state,
    noDeactivatedToppingDrawer: shallowClonedNoDeactivatedToppingDrawer,
  }
}

const setCorridorsLoading = (state, areCorridorsLoading) => {
  return { ...state, areCorridorsLoading }
}

const setCorridorProductsLoading = (state, areCorridorProductsLoading) => {
  return { ...state, areCorridorProductsLoading }
}

const setProductToppingsLoading = (state, areProductToppingsLoading) => {
  return { ...state, areProductToppingsLoading }
}

const syncProductsAvailability = (state, storeId) => {
  const { menus, productsOutOfStock } = state
  const shallowClonedMenus = { ...menus }
  const storeMenu = shallowClonedMenus[storeId]
  const shallowClonedState = { ...state }

  if (!storeMenu) {
    return shallowClonedState
  }

  const shallowClonedMenu = { ...storeMenu }

  const shallowClonedProducts = [...shallowClonedMenu.products]
  for (let i = 0; i < shallowClonedProducts.length; i++) {
    const shallowClonedProduct = { ...shallowClonedProducts[i] }

    const productOutOfStock = MenuUtils.findToppingById(
      productsOutOfStock,
      shallowClonedProduct.id,
    )

    shallowClonedProduct.activated = !productOutOfStock
    shallowClonedProducts[i] = shallowClonedProduct
  }

  shallowClonedMenu.products = shallowClonedProducts

  shallowClonedMenus[storeId] = shallowClonedMenu

  shallowClonedState.menus = shallowClonedMenus

  if (
    shallowClonedState.searchedProducts &&
    shallowClonedState.searchedProducts.length > 0
  ) {
    const shallowClonedSearchedProducts = [
      ...shallowClonedState.searchedProducts,
    ]

    for (let i = 0; i < shallowClonedSearchedProducts.length; i++) {
      const shallowClonedSearchedProduct = {
        ...shallowClonedSearchedProducts[i],
      }

      const productOutOfStock = MenuUtils.findToppingById(
        productsOutOfStock,
        shallowClonedSearchedProduct.id,
      )

      shallowClonedSearchedProduct.activated = !productOutOfStock
      shallowClonedSearchedProducts[i] = shallowClonedSearchedProduct
    }

    shallowClonedState.searchedProducts = shallowClonedSearchedProducts
  }

  if (
    shallowClonedState.selectedCorridorProducts &&
    shallowClonedState.selectedCorridorProducts.length > 0
  ) {
    const shallowClonedSelectedCorridorProducts = [
      ...shallowClonedState.selectedCorridorProducts,
    ]

    for (let i = 0; i < shallowClonedSelectedCorridorProducts.length; i++) {
      const shallowClonedSelectedCorridorProduct = {
        ...shallowClonedSelectedCorridorProducts[i],
      }

      const productOutOfStock = MenuUtils.findToppingById(
        productsOutOfStock,
        shallowClonedSelectedCorridorProduct.id,
      )

      shallowClonedSelectedCorridorProduct.activated = !productOutOfStock
      shallowClonedSelectedCorridorProducts[
        i
      ] = shallowClonedSelectedCorridorProduct
    }

    shallowClonedState.selectedCorridorProducts = shallowClonedSelectedCorridorProducts
  }

  return shallowClonedState
}
const syncToppingsAvailability = (state, storeId) => {
  const { menus, toppingsOutOfStock } = state
  const shallowClonedMenus = { ...menus }
  const storeMenu = shallowClonedMenus[storeId]
  const shallowClonedState = { ...state }
  if (!storeMenu) {
    return shallowClonedState
  }

  const shallowClonedMenu = { ...storeMenu }
  const shallowClonedToppings = [...shallowClonedMenu.toppings]
  for (let i = 0; i < shallowClonedToppings.length; i++) {
    const shallowClonedTopping = { ...shallowClonedToppings[i] }

    const toppingOutOfStock = MenuUtils.findToppingById(
      toppingsOutOfStock,
      shallowClonedTopping.id,
    )

    shallowClonedTopping.activated = !toppingOutOfStock
    shallowClonedToppings[i] = shallowClonedTopping
  }

  shallowClonedMenu.toppings = shallowClonedToppings

  shallowClonedMenus[storeId] = shallowClonedMenu

  shallowClonedState.menus = shallowClonedMenus

  if (
    shallowClonedState.searchedToppings &&
    shallowClonedState.searchedToppings.length > 0
  ) {
    const shallowClonedSearchedToppings = [
      ...shallowClonedState.searchedToppings,
    ]

    for (let i = 0; i < shallowClonedSearchedToppings.length; i++) {
      const shallowClonedSearchedTopping = {
        ...shallowClonedSearchedToppings[i],
      }

      const toppingOutOfStock = MenuUtils.findToppingById(
        toppingsOutOfStock,
        shallowClonedSearchedTopping.id,
      )

      shallowClonedSearchedTopping.activated = !toppingOutOfStock
      shallowClonedSearchedToppings[i] = shallowClonedSearchedTopping
    }

    shallowClonedState.searchedToppings = shallowClonedSearchedToppings
  }

  if (
    shallowClonedState.selectedProductToppingCategories &&
    shallowClonedState.selectedProductToppingCategories.length > 0
  ) {
    const shallowClonedSelectedProductToppingCategories = [
      ...shallowClonedState.selectedProductToppingCategories,
    ]

    for (
      let tcIndex = 0;
      tcIndex < shallowClonedSelectedProductToppingCategories;
      tcIndex++
    ) {
      const shallowClonedToppingsInTree = [
        ...shallowClonedSelectedProductToppingCategories.toppings,
      ]
      for (let tIndex; tIndex < shallowClonedToppingsInTree; tIndex++) {
        const shallowClonedToppingInTree = {
          ...shallowClonedToppingsInTree[tIndex],
        }

        const toppingOutOfStock = MenuUtils.findToppingById(
          toppingsOutOfStock,
          shallowClonedToppingInTree.id,
        )

        shallowClonedToppingInTree.activated = !toppingOutOfStock

        shallowClonedToppingsInTree[tIndex] = shallowClonedToppingInTree
      }

      shallowClonedSelectedProductToppingCategories.toppings = shallowClonedToppingsInTree
    }

    shallowClonedState.selectedProductToppingCategories = shallowClonedSelectedProductToppingCategories
  }

  return shallowClonedState
}
