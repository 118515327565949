import { useState } from 'react';

import { formatCurrency } from '../../../utils/CurrencyUtils';
import { useOrderDetailWhimLang } from './useOrderDetailWhimLang';

import type { OrderDetailWhimProps } from '../orderDetailWhim.model';

export function useOrderDetailWhim(props: Props) {
  const { price } = props;

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  return {
    isOpenDialog,
    lang: useOrderDetailWhimLang(),
    formattedPrice: formatCurrency(price),
    handleOpenDialog: () => setIsOpenDialog(true),
    handleCloseDialog: () => setIsOpenDialog(false),
  };
}

type Props = Pick<OrderDetailWhimProps, 'price'>;
