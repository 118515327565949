import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { textIds } from './consts';
import { useFormatMessage } from '../../intl/intl';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';
import OrderActions from '../../redux/actions/orders-actions';
import hooks from './hooks';

import CustomDialog from '../common/custom-dialog';
import { CustomNumberSelectorBlock } from '../common/custom-number-selector-block';

import useStyles from './styles';

export function OrderRejectionBySaturatedStoreDialog() {
  const componentClasses = useStyles();

  const confirmButtonText = useFormatMessage(textIds.confirmButtonText);
  const description = useFormatMessage(textIds.description);
  const label = useFormatMessage(textIds.label);
  const title = useFormatMessage(textIds.title);

  const [minutes, setMinutes] = useState(5);

  const open = hooks.useOpen();

  const dispatch = useDispatch();

  const handleBack = useCallback(() => {
    dispatch(dialogsManagerActions.back());
  }, [dispatch]);

  const handleChange = useCallback(newValue => setMinutes(newValue), []);

  const handleClose = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  const handleConfirm = useCallback(() => {
    dispatch(OrderActions.rejectOrderBySaturatedStoreFromDialog(minutes));
  }, [dispatch, minutes]);

  return (
    <CustomDialog
      classes={{
        headerRoot: componentClasses.headerRoot,
        paper: componentClasses.paper,
      }}
      actions={[
        {
          text: confirmButtonText,
          onClick: handleConfirm,
          color: 'primary',
          variant: 'contained',
          fullWidth: false,
        },
      ]}
      CustomDialogHeaderChildren={''}
      onClickBackButton={handleBack}
      onClose={handleClose}
      open={open}
      showBackButton
      showCloseButton
      title={title}
    >
      <div className={componentClasses.body}>
        <CustomNumberSelectorBlock
          classes={{
            root: componentClasses.customNumberSelectorBlockRoot,
            title: componentClasses.customNumberSelectorBlockTitle,
          }}
          description={description}
          label={label}
          onChange={handleChange}
          incrementedBy={5}
          max={20}
          min={5}
          value={minutes}
        />
      </div>
    </CustomDialog>
  );
}
