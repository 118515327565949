// Material UI
import { makeStyles } from '@material-ui/core'
// Constant
import Colors from '../../constants/Colors'

export default makeStyles(theme => ({
  root: {
    background: Colors.WHITE,
    height: '318px',
    padding: '10px',
    [theme.breakpoints.up('md')]: {
      padding: '19px 24px',
    },
  },
  restaurantImg: {
    height: '96px',

    width: '83px',
  },
  releaseRtButton: {
    float: 'left',
    width: '104px',
    marginLeft: '22px',
    marginTop: '-56px',
  },
  restaurantImgContainer: {
    margin: '-76px -31.5px',
  },
  RTImgContainer: {
    margin: '-16px -6.5px',
  },
}))
