import React from 'react';
import PropTypes from 'prop-types';
import {
  Chip,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from '@material-ui/core';

import useStyles from './MenuCorridorsList.styles';
import CustomLoader from '../common/custom-loader/CustomLoader';
import Colors from '../../constants/Colors';
import { useOutStockText } from '../menu-out-of-stock-view/hook';
import { useOutStockMenuText } from './hook';
import { neutral } from '../../shared/styles/colors';

const MenuCorridorsList = ({
  corridors,
  displayOutOfStock,
  isLoading,
  onDisplayOutOfStock,
  onSelectCorridor,
  outstockProducts,
  outstockToppings,
  selectedCorridorId,
}) => {
  const classes = useStyles();
  const arrow = selected => (
    <ListItemIcon
      classes={{
        root: classes.corridorsListIconRoot,
      }}
    >
      <SvgIcon>
        <path
          fill={selected ? Colors.PRIMARY_COLOR : neutral.D15}
          d='M9 8.167L10.135 7 15 12l-4.865 5L9 15.833 12.73 12z'
        />
      </SvgIcon>
    </ListItemIcon>
  );
  const hasOutstock = outstockProducts + outstockToppings > 0;
  const outstockTitle = useOutStockText({ ctaName: 'productToppingOff' });
  const { quantityLabel, descriptionLabel } = useOutStockMenuText({
    hasOutstock,
    products: outstockProducts,
    toppings: outstockToppings,
  });

  return (
    <div className={classes.corridorsListRoot}>
      <CustomLoader open={isLoading} />
      <List component='div' disablePadding>
        {hasOutstock && (
          <ListItem
            onClick={onDisplayOutOfStock}
            selected={displayOutOfStock}
            classes={{
              root: classes.outstockContainer,
              selected: classes.selected,
            }}
          >
            <ListItemText
              className={classes.outstockItem}
              primary={
                <Chip className={classes.outstockChip} label={outstockTitle} />
              }
              secondary={
                <Typography>
                  <Typography
                    component='span'
                    className={classes.outstockSecondaryHighlight}
                  >
                    {quantityLabel}
                  </Typography>
                  <Typography
                    component='span'
                    className={classes.outstockSecondary}
                  >
                    {descriptionLabel}
                  </Typography>
                </Typography>
              }
            />
            {arrow(displayOutOfStock)}
          </ListItem>
        )}
        {corridors.map((corridor, index) => {
          return (
            <ListItem
              key={'corridor' + index}
              onClick={() => onSelectCorridor(corridor['corridor_id'])}
              selected={selectedCorridorId === corridor['corridor_id']}
              disableGutters
              classes={{
                root: classes.listItemRoot,
                selected: classes.selected,
              }}
            >
              <ListItemText
                primary={corridor['corridor_name']}
                classes={{
                  root: classes.listTextRoot,
                  primary: classes.listTextPrimary,
                }}
              />
              {arrow(corridor.selected)}
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

MenuCorridorsList.propTypes = {
  corridors: PropTypes.array,
  selectedCorridorId: PropTypes.number,
  onSelectCorridor: PropTypes.func,
  outstockProducts: PropTypes.number,
  outstockToppings: PropTypes.number,
  onDisplayOutOfStock: PropTypes.func,
};

export default MenuCorridorsList;
