// React
import React, { useEffect } from 'react';
// Material UI
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
// Consts
import Paths from '../../constants/Paths';
// Styles
import useStyles from './StoresSchedulesAdvancedFilterToolbar.styles';
// Components
import CustomAdvancedFilterToolbar from './../common/custom-advanced-filter-toolbar';
import StoresSchedulesFilterDrawer from './../stores-schedules-filter-drawer';
import { useLocation } from 'react-router-dom';

const StoresSchedulesAdvancedFilterToolbar = ({
  classes,
  firstStoreId,
  onClick,
  onStoreQueryParamChange,
  selectedFilterOptions,
}) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const { search } = useLocation();

  const navigate = useNavigate();

  const queryParamStoreId = new URLSearchParams(search).get('store_id');

  useEffect(() => {
    if (queryParamStoreId) {
      onStoreQueryParamChange(queryParamStoreId);
    } else {
      firstStoreId &&
        navigate(Paths.getStoresSchedulesQueryParamsPath(firstStoreId));
    }
  }, [firstStoreId, queryParamStoreId]);

  const componentClasses = useStyles();

  return (
    <div>
      <CustomAdvancedFilterToolbar
        classes={{ root: componentClasses.advancedFilterToolbarRoot }}
        onClick={onClick}
        selectedFilterOptions={selectedFilterOptions}
        showAdvancedToolBar={!isMdUp}
      />
      <StoresSchedulesFilterDrawer />
    </div>
  );
};

StoresSchedulesAdvancedFilterToolbar.defaultProps = {
  classes: {},
  selectedFilterOptions: [],
};

export default StoresSchedulesAdvancedFilterToolbar;
