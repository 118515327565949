// External libs
import { createSelector } from 'reselect';
import { get } from 'lodash';

const selectSlice: (state: any) => any = (state: any) =>
  state.orderRejectionByStockOutDialogReducer;

const selectOpen = createSelector<any, any, boolean>(
  selectSlice,
  reducer => reducer.open,
);

const selectRefuseReason = createSelector<any, any, any>(
  selectSlice,
  reducer => reducer.refuseReason,
);

const selectRefuseReasonDescription = createSelector<any, any, string>(
  selectRefuseReason,
  refuseReason => get(refuseReason, 'description', null),
);

const selectRefuseReasonEnum = createSelector<any, any, string>(
  selectRefuseReason,
  refuseReason => get(refuseReason, 'refuse_reason_enum', null),
);

const selectors = {
  selectOpen,
  selectRefuseReasonDescription,
  selectRefuseReasonEnum,
};

export default selectors;
