// React
import React from 'react';
// Material UI
import { IconButton, SvgIcon } from '@material-ui/core';
import Colors from '../../../constants/Colors';
import { neutral } from '../../../shared/styles/colors';

const CustomEyeSwitch = ({ active, onClick }) => {
  const icon = active ? (
    <SvgIcon>
      <path
        fill={Colors.PRIMARY_COLOR}
        fillRule='evenodd'
        d='M12.572 6.72c1.976 0 3.757.556 5.344 1.669 1.588 1.112 2.731 2.556 3.431 4.331-.7 1.775-1.843 3.219-3.43 4.331-1.588 1.113-3.37 1.669-5.345 1.669-1.975 0-3.762-.556-5.362-1.669-1.6-1.112-2.75-2.556-3.45-4.331.7-1.775 1.85-3.219 3.45-4.331 1.6-1.113 3.387-1.669 5.362-1.669zm0 10.013c1.1 0 2.038-.394 2.813-1.182.775-.787 1.162-1.731 1.162-2.831 0-1.1-.387-2.044-1.162-2.831-.775-.788-1.712-1.181-2.813-1.181-1.1 0-2.043.393-2.83 1.18-.788.788-1.182 1.732-1.182 2.832 0 1.1.394 2.044 1.181 2.831.788.788 1.731 1.182 2.832 1.182zm-.018-6.413a2.36 2.36 0 0 1 1.706.694c.475.462.713 1.031.713 1.706s-.238 1.244-.713 1.706a2.36 2.36 0 0 1-1.706.694 2.297 2.297 0 0 1-1.688-.694c-.462-.462-.694-1.031-.694-1.706s.232-1.244.694-1.706a2.297 2.297 0 0 1 1.688-.694z'
      />
    </SvgIcon>
  ) : (
    <SvgIcon>
      <path
        fill={neutral.D15}
        fillRule='evenodd'
        d='M12.18 7.32a3.89 3.89 0 0 0-1.524.313L8.86 5.836a9.48 9.48 0 0 1 3.282-.586c2.057 0 3.919.58 5.586 1.738 1.666 1.16 2.864 2.663 3.593 4.512a9.35 9.35 0 0 1-2.851 3.945l-2.422-2.422a4.374 4.374 0 0 0 .273-1.523c0-1.146-.403-2.129-1.21-2.95-.808-.82-1.784-1.23-2.93-1.23zM3.82 5.055L4.875 4 19.68 18.766l-1.055 1.054-2.46-2.422-.352-.351a9.696 9.696 0 0 1-3.633.703c-2.058 0-3.92-.58-5.586-1.738C4.927 14.852 3.729 13.349 3 11.5a9.441 9.441 0 0 1 3.125-4.14L3.82 5.054zm4.61 4.61A4.213 4.213 0 0 0 8 11.5c0 1.146.41 2.129 1.23 2.95.82.82 1.804 1.23 2.95 1.23.625 0 1.237-.157 1.836-.47l-1.29-1.288c-.208.052-.39.078-.546.078-.703 0-1.296-.24-1.778-.723-.481-.481-.722-1.074-.722-1.777 0-.182.013-.365.039-.547l-1.29-1.289zM12.023 9h.157c.677 0 1.263.247 1.758.742.494.495.742 1.08.742 1.758l-.04.156L12.024 9z'
      />
    </SvgIcon>
  );

  return <IconButton onClick={onClick}>{icon}</IconButton>;
};

export default CustomEyeSwitch;
