// Material UI
import { makeStyles } from '@material-ui/core'
// Constant
import Colors from '../../constants/Colors'

export default makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  buttonRoot: {
    borderRadius: 18,
    fontFamily: 'Poppins',
    fontSize: '15px !important',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    padding: '9px 14px',
    '&:hover': {
      color: Colors.WHITE,
    },
  },
  buttonCanceledOrders: {
    color: Colors.SECONDARY_COLOR,
    background: Colors.DANGER_LIGHT_COLOR,
    '&:hover': {
      color: Colors.WHITE,
      backgroundColor: Colors.SECONDARY_COLOR,
      '@media (hover: none)': {
        color: Colors.SECONDARY_COLOR,
        backgroundColor: Colors.DANGER_LIGHT_COLOR,
      },
    },
  },
  buttonNewOrders: {
    color: Colors.PRIMARY_COLOR,
    backgroundColor: Colors.SUCCESS_LIGHT_COLOR,
    '&:hover': {
      color: Colors.WHITE,
      backgroundColor: Colors.PRIMARY_COLOR,
      '@media (hover: none)': {
        color: Colors.PRIMARY_COLOR,
        backgroundColor: Colors.SUCCESS_LIGHT_COLOR,
      },
    },
  },
}))
