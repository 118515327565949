import { get } from 'lodash';
import { createActions } from 'redux-actions';

import delay from '../../../utils/Delay';
import Amplitude from '../../../tools/amplitude';
import StringUtils from '../../../utils/StringUtils';
import { PWEX, PWEXCodes } from '../../../exceptions';
import AppActions from './../app-actions';
import appStore from './../../stores/app.store';
import BlindService from '../../../services/BlindService';
import PartnersService from '../../../services/partners-service';
import NotificationService from '../../../services/NotificationService';
import ChromeExtensionService from '../../../services/ChromeExtensionService';
import SupportActionsManagerService from '../../../services/help-center-service';

import utils from '../../partner/utils';
import { formatMessage } from '../../../intl/intl';
import EventActions from '../event-actions/event.actions';
import dialogsManagerActions from '../dialogsManager.actions';
import LocalStorageService from '../../../services/local-storage-service';
import { actions as dynInAppDialogActions } from '../../dyn-in-app-dialog';
import { executeAction } from '../../../transpilers/dsl-transpiler/dslDispatch';

import { APP_VERSION } from '../../../constants/commons';
import { Serverities } from '../../../constants/Severities';
import { partnerStateType } from '../../../constants/PartnerStateType';
import { ActionTypes } from '../../../transpilers/dsl-transpiler/consts';
import { PartnerDeactivationReasonType } from '../../../constants/PartnerDeactivationReasonType';

const PARTNER_DEACTIVATION_LIMITED_TIME = 'partner_deactivation_limited_time';
const PARTNER_DEACTIVATION_REST_OF_DAY = 'partner_deactivation_rest_of_day';
const PARTNER_DEACTIVATION_UNKNOWN_REASON =
  'partner_deactivation_unknown_reason';
const {
  cleanPartnerDeactivationReasonAction,
  closeClosedPartnerStateDialogAction,
  closeDeactivatePartnerDialogAction,
  closeOpenPartnerStateDialogAction,
  closePartnerActivationReminderDialogAction,
  deactivateIntegratedStoreAction,
  deactivatePartnerAction,
  getIfPartnerIsInBlacklistHandShakeAction,
  getNotificationsAction,
  getNotificationsUnseenAction,
  getProfileAction,
  getPartnerStateAction,
  getPartnerTypificationsAction,
  getSalesSummaryAction,
  openClosedPartnerStateDialogAction,
  openDeactivatePartnerDialogAction,
  openOpenPartnerStateDialogAction,
  openPartnerActivationReminderDialogAction,
  setIsNotPendingTurnPartnerOnByScheduleAction,
  setIsPendingTurnPartnerOnByScheduleAction,
  getSuspendedStoresAction,
  setPartnerStateAction,
  setFirstLoginActions,
  getStoreStatusDetailAction,
  getDeactivatedProductsAction,
  setSimpleStoreStatusNotificationAction,
  setStoreStatusDetailLoadingAction,
  setDeactivatedProductsLoadingAction,
  setSwitchStoreStatusLoadingAction,
  setPartnerStateLoadingAction,
  setHasBeenUpdatedStoreStatusNotificationAction,
} = createActions({
  SET_PARTNER_STATE_ACTION: partnerState => ({ partnerState }),
  CLEAN_PARTNER_DEACTIVATION_REASON_ACTION: () => ({}),
  CLOSE_CLOSED_PARTNER_STATE_DIALOG_ACTION: () => ({}),
  CLOSE_DEACTIVATE_PARTNER_DIALOG_ACTION: () => ({}),
  CLOSE_OPEN_PARTNER_STATE_DIALOG_ACTION: () => ({}),
  CLOSE_PARTNER_ACTIVATION_REMINDER_DIALOG_ACTION: () => ({}),
  CLOSE_PARTNER_DEACTIVATION_LIMITED_TIME_REASON_DIALOG_ACTION: () => ({}),
  CLOSE_PARTNER_DEACTIVATION_REST_OF_DAY_REASON_DIALOG_ACTION: () => ({}),
  CLOSE_PARTNER_DEACTIVATION_UNKNOWN_REASON_DIALOG_ACTION: () => ({}),
  CLOSE_PARTNER_DEACTIVATION_BY_CBPI_DIALOG_ACTION: () => ({}),
  CLOSE_PARTNER_DEACTIVATION_BY_CBPR_DIALOG_ACTION: () => ({}),
  DEACTIVATE_PARTNER_ACTION: () => ({}),
  GET_IF_PARTNER_IS_IN_BLACKLIST_HAND_SHAKE_ACTION: isInBlacklistHandShake => ({
    isInBlacklistHandShake,
  }),
  GET_NOTIFICATIONS_ACTION: notifications => ({ notifications }),
  GET_NOTIFICATIONS_UNSEEN_ACTION: unseen => ({ unseen }),
  GET_PROFILE_ACTION: profile => ({
    profile,
  }),
  getSalesSummaryAction: summary => ({ summary }),
  DEACTIVATE_INTEGRATED_STORE_ACTION: () => ({}),
  GET_PARTNER_STATE_ACTION: state => ({
    state,
  }),
  GET_PARTNER_TYPIFICATIONS_ACTION: typifications => ({ typifications }),
  OPEN_CLOSED_PARTNER_STATE_DIALOG_ACTION: () => ({}),
  OPEN_DEACTIVATE_PARTNER_DIALOG_ACTION: () => ({}),
  OPEN_OPEN_PARTNER_STATE_DIALOG_ACTION: () => ({}),
  OPEN_PARTNER_ACTIVATION_REMINDER_DIALOG_ACTION: () => ({}),
  OPEN_PARTNER_DEACTIVATION_LIMITED_TIME_REASON_DIALOG_ACTION: () => ({}),
  OPEN_PARTNER_DEACTIVATION_REST_OF_DAY_REASON_DIALOG_ACTION: () => ({}),
  OPEN_PARTNER_DEACTIVATION_UNKNOWN_REASON_DIALOG_ACTION: () => ({}),
  SET_IS_NOT_PENDING_TURN_PARTNER_ON_BY_SCHEDULE_ACTION: () => ({}),
  SET_IS_PENDING_TURN_PARTNER_ON_BY_SCHEDULE_ACTION: () => ({}),
  SET_PARTNER_DEACTIVATION_REASON_TO_LIMITED_TIME_ACTION: () => ({}),
  SET_PARTNER_DEACTIVATION_REASON_TO_REST_OF_DAY_ACTION: () => ({}),
  SET_PARTNER_DEACTIVATION_REASON_TO_UNKNOWN_ACTION: () => ({}),
  GET_SUSPENDED_STORES_ACTION: storesReasons => ({ storesReasons }),
  SET_FIRST_LOGIN_ACTIONS: isFirstLogin => ({ isFirstLogin }),
  GET_STORE_STATUS_DETAIL_ACTION: storeStatusDetail => ({ storeStatusDetail }),
  GET_DEACTIVATED_PRODUCTS_ACTION: deactivatedProducts => ({
    deactivatedProducts,
  }),
  SET_SIMPLE_STORE_STATUS_NOTIFICATION_ACTION: list => ({ list }),
  SET_STORE_STATUS_DETAIL_LOADING_ACTION: isLoading => ({ isLoading }),
  SET_DEACTIVATED_PRODUCTS_LOADING_ACTION: isLoading => ({ isLoading }),
  SET_SWITCH_STORE_STATUS_LOADING_ACTION: isLoading => ({ isLoading }),
  SET_PARTNER_STATE_LOADING_ACTION: isLoading => ({ isLoading }),
  SET_HAS_BEEN_UPDATED_STORE_STATUS_NOTIFICATION_ACTION: updated => ({
    updated,
  }),
});

const TIMEOUT_TO_RELOAD = 3000; // seconds for waiting kafka event refresh

const activatePartner = () => async dispatch => {
  try {
    const partnerId = appStore.getPartnerId();
    await PartnersService.activatePartner();
    const hasExtension = !!(await ChromeExtensionService.isAvailable());
    EventActions.onConnectionPartnerVoluntary(
      partnerId,
      hasExtension,
      APP_VERSION,
    );
    setTimeout(async () => {
      await setHasBeenUpdatedStoreStatusNotification(true)(dispatch);
      await getProfileAndState()(dispatch);
      await getStoreStatusDetail()(dispatch);
    }, TIMEOUT_TO_RELOAD);
  } catch (ex) {
    AppActions.handleCatch(ex)(dispatch);
  }
};

const acceptPartnerActivationReminderDialog = () => async dispatch => {
  await activatePartner()(dispatch);
  closePartnerActivationReminderDialog()(dispatch);
};

const getIfPartnerIsInBlacklistHandShake = () => async dispatch => {
  try {
    const profile = appStore.getProfile();
    const { data } = await BlindService.getIfPartnerIsInBlacklistHandShake(
      profile.id,
    );
    dispatch(getIfPartnerIsInBlacklistHandShakeAction(data));
  } catch (ex) {
    if (ex && ex.response && ex.response.status && ex.response.status === 404) {
      console.error(ex, ex.response);
      return;
    }
    AppActions.handleCatch(ex)(dispatch);
  }
};

const closeClosedPartnerStateDialog = () => dispatch => {
  dispatch(closeClosedPartnerStateDialogAction());
};

const closeDeactivatePartnerDialog = () => dispatch => {
  return dispatch(closeDeactivatePartnerDialogAction());
};

const closeOpenPartnerStateDialog = () => dispatch => {
  dispatch(closeOpenPartnerStateDialogAction());
};

const closePartnerActivationReminderDialog = () => async dispatch => {
  dispatch(closePartnerActivationReminderDialogAction());
  await ChromeExtensionService.updateAudio();
  return;
};

const deactivatePartner = deactivateMinutes => async dispatch => {
  try {
    await PartnersService.deactivatePartner(deactivateMinutes);
    setTimeout(async () => {
      await setHasBeenUpdatedStoreStatusNotification(true)(dispatch);
      await getState()(dispatch);
      await getStoreStatusDetail()(dispatch);
    }, TIMEOUT_TO_RELOAD);
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
  }
};

const deactivateIntegratedStore = (storeId, isEnabled) => dispatch => {
  return PartnersService.setStatusIntegratedStore(storeId, isEnabled)
    .then(() => {
      dispatch(deactivateIntegratedStoreAction());
      return getProfileAndState()(dispatch);
    })
    .catch(ex => AppActions.handleCatch(ex)(dispatch));
};

const consumePartnerStateValidate = () => async dispatch => {
  try {
    const { data } = await PartnersService.consumePartnerStateValidate();

    if (!data.success) {
      const PWEXCode = PWEXCodes.PWEX6;
      const formatMessageObj = { id: PWEXCode };
      const ex = new PWEX(PWEXCode, formatMessageObj);
      throw ex;
    }

    if (data.should_show_deactivation_dialog) {
      openDeactivatePartnerDialogfromOpenPartnerState()(dispatch);
      return;
    }

    await getState()(dispatch);
    closeOpenPartnerStateDialog()(dispatch);
    setPartnerDeactivationReasonToRestOfDay()(dispatch);
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
    return;
  }
};

const confirmPartnerDeactivation = (
  deactivateMinutes,
  partnerDeactivationReason,
) => async dispatch => {
  try {
    await deactivatePartner(deactivateMinutes)(dispatch);

    closeDeactivatePartnerDialog()(dispatch);

    switch (partnerDeactivationReason) {
      case PartnerDeactivationReasonType.LIMITED_TIME:
        setPartnerDeactivationReasonToLimitedTime()(dispatch);

        break;
      case PartnerDeactivationReasonType.REST_OF_DAY:
        setPartnerDeactivationReasonToRestOfDay()(dispatch);
        break;
      default:
        break;
    }
    const partnerId = appStore.getPartnerId();
    const hasExtension = !!(await ChromeExtensionService.isAvailable());
    EventActions.onDisconnectionPartnerVoluntary(
      partnerId,
      hasExtension,
      APP_VERSION,
    );
    dispatch(setPartnerStateAction(partnerStateType.DEACTIVATED));
  } catch (ex) {
    AppActions.handleCatch(ex)(dispatch);
  }
};

const getNotifications = (page, perPage) => dispatch => {
  return NotificationService.getNotifications(page, perPage)
    .then(response => {
      return dispatch(getNotificationsAction(response.data));
    })
    .catch(ex => AppActions.handleCatch(ex)(dispatch));
};

const getNotificationsUnseen = () => async dispatch => {
  try {
    const response = await NotificationService.getLastNotification();
    if (!response || !response.data) {
      const PWEXCode = PWEXCodes.PWEX7;
      const formatMessageObj = {
        id: PWEXCode,
      };
      const ex = new PWEX(PWEXCode, formatMessageObj);
      throw ex;
    }

    const notificationAction = response.data;
    await executeNotificationAction(notificationAction)(dispatch);
    const notificationId = get(notificationAction, 'id', null);
    const notificationCreatedAt = get(notificationAction, 'created_at', null);

    if (StringUtils.isNullOrEmpty(notificationId)) {
      await notificationPutSeenAllActions(notificationCreatedAt)(dispatch);
    } else {
      await notificationPutSeen(
        notificationId,
        notificationCreatedAt,
      )(dispatch);
    }
    return;
  } catch (ex) {
    AppActions.handleCatch(ex)(dispatch);
  }
};

const executeNotificationAction = notificationAction => async dispatch => {
  if (!notificationAction) {
    return;
  }

  const action = get(notificationAction, 'action', null);
  if (!action) {
    const PWEXCode = PWEXCodes.PWEX9;
    const ex = new PWEX(PWEXCode, { id: PWEXCode }, { notificationAction });
    await AppActions.handleCatch(ex, false)(dispatch);
    return;
  }
  await dispatch(executeAction(action));
};
const getPartnerTypifications = () => dispatch => {
  return SupportActionsManagerService.getPartnerTypifications()
    .then(response => {
      return dispatch(getPartnerTypificationsAction(response.data.data));
    })
    .catch(ex => AppActions.handleCatch(ex)(dispatch));
};

const getProfile = () => async dispatch => {
  const { data: profile } = await PartnersService.getProfile();
  LocalStorageService.setPartnerId(get(profile, 'id', 0));
  dispatch(getProfileAction(profile));
};

/**
 * Get the profile iteratevly in case of error.
 * @function getProfileBackOff
 * @param {number = 10000} delayDuration - Delay in ms before calling itself again in case of error. Default 10 seconds
 */
const getProfileBackOff = (delayDuration = 10000) => async dispatch => {
  try {
    // try to get the profile.
    await getProfile()(dispatch);
    await Amplitude.setUser();
    return;
  } catch (internalEx) {
    const PWEXCode = PWEXCodes.PWEX12;
    const formatMessageObj = { id: PWEXCode };
    const ex = new PWEX(PWEXCode, formatMessageObj, null, internalEx);
    // show the error synchronously.
    await AppActions.handleCatch(ex)(dispatch);
    // open a thread, and wait the delay duration.
    delay(delayDuration).then(() => {
      // call yourself with the double of the current delay duration set.
      getProfileBackOff(delayDuration * 2)(dispatch);
    });
    // let the thread on execution and return.
    // Don´t re-throw the exception.
    return;
  }
};

/**
 * Get the partner state iteratevly in case of error.
 * @function getStateBackOff
 * @param {number = 10000} delayDuration - Delay in ms before calling itself again in case of error. Default 10 seconds
 */
const getStateBackOff = (delayDuration = 10000) => async dispatch => {
  try {
    // try to get the profile.
    await getState()(dispatch);
    return;
  } catch (ex) {
    // show the error synchronously.
    await AppActions.handleCatch(ex)(dispatch);
    // open a thread, and wait the delay duration.
    delay(delayDuration).then(() => {
      // call yourself with the double of the current delay duration set.
      getStateBackOff(delayDuration * 2)(dispatch);
    });
    // let the thread on execution and return.
    // Don´t re-throw the exception.
    return;
  }
};

const getProfileAndState = () => async dispatch => {
  try {
    await getProfileBackOff()(dispatch);
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
    // Allow to continue the execution.
  }
  try {
    await getStateBackOff()(dispatch);
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
    // Allow to continue the execution.
  }
};

const getSalesSummary = (storeId, date) => async dispatch => {
  const response = await PartnersService.getSalesSummary(
    storeId,
    date,
  )(dispatch);
  await getSalesSummaryAction(response.data)(dispatch);
};

const verifyPartnerStateChange = (
  previousState,
  currentState,
) => async dispatch => {
  const previousStoreStatusNotification = JSON.stringify(previousState);
  const currentStoreStatusNotification = JSON.stringify(currentState);

  if (previousStoreStatusNotification === currentStoreStatusNotification) {
    const changePartnerStateSoonMessage = formatMessage({
      id: 'changePartnerStateDialog.soon',
    });
    await AppActions.openSnackBarSuccess(changePartnerStateSoonMessage)(
      dispatch,
    );
  }

  await setHasBeenUpdatedStoreStatusNotification(false)(dispatch);
};

const getState = () => async dispatch => {
  // Get the partner state.
  setPartnerStateLoading(true)(dispatch);
  const { data: currentState } = await PartnersService.getState();
  const previousState = appStore.getPartnerState();

  // Verify Partner State Change
  const previousStoreStatusNotification = appStore?.getSimpleStoreStatusNotification();
  const currentStoreStatusNotification = currentState?.store_status;
  const hasBeenUpdatedStoreStatusNotification = appStore.getHasBeenUpdatedStoreStatusNotification();

  if (hasBeenUpdatedStoreStatusNotification) {
    verifyPartnerStateChange(
      previousStoreStatusNotification,
      currentStoreStatusNotification,
    )(dispatch);
  }

  const isDynInAppOpen = appStore.getDynInAppDialogOpenDialog();
  const isScheduledPMAvailable =
    !!currentState.scheduled_pm_automation_form &&
    !!currentState.scheduled_pm_automation_form.form_id &&
    !!currentState.scheduled_pm_automation_form.store_id;

  // Update partner state in redux.
  dispatch(getPartnerStateAction(currentState));

  dispatch(
    setSimpleStoreStatusNotificationAction(currentState?.store_status || []),
  );

  if (!!currentState?.unseen_has_action?.has_action) {
    partnerActions.getNotificationsUnseen()(dispatch);
  } else {
    switch (currentState.partner_state) {
      case partnerStateType.CLOSED:
        if (isDynInAppOpen) {
          dispatch(dynInAppDialogActions.close());
        }
        break;

      case partnerStateType.OPEN:
        if (isDynInAppOpen) {
          dispatch(dynInAppDialogActions.close());
        }
        partnerActions.setIsNotPendingTurnPartnerOnBySchedule()(dispatch);
        break;

      case partnerStateType.DEACTIVATED:
        const isActivatedStoreStatus = currentState?.store_status?.some(
          val => val.state === 'ACTIVE',
        );
        const isOpen =
          previousState === partnerStateType.OPEN ||
          previousState === partnerStateType.IDLE;
        const isPartnerDeactivated =
          previousState === partnerStateType.DEACTIVATED &&
          isActivatedStoreStatus;

        if ((isOpen || isPartnerDeactivated) && !isDynInAppOpen) {
          await partnerActions.setPartnerDeactivationReasonToUnknown()(
            dispatch,
          );
        }
        break;
      default:
        break;
    }
  }

  dispatch(executeSchedulePMForm(isScheduledPMAvailable));
  setPartnerStateLoading(false)(dispatch);
};

const setPartnerStateLoading = isLoading => dispatch => {
  dispatch(setPartnerStateLoadingAction(isLoading));
};

const setHasBeenUpdatedStoreStatusNotification = updated => async dispatch => {
  dispatch(setHasBeenUpdatedStoreStatusNotificationAction(updated));
};

const executeSchedulePMForm = isScheduledPMAvailable => dispatch => {
  if (!isScheduledPMAvailable) {
    return;
  }
  const isDialogOpen = appStore.getCurrentDialogCode();
  if (isDialogOpen) {
    return;
  }
  const isAnyNewOrder = appStore.isAnyNewOrderBeingManaged();
  if (isAnyNewOrder) {
    return;
  }
  dispatch(dialogsManagerActions.openStartPMFlowDialog());
};

const cleanPartnerDeactivationReason = () => async dispatch => {
  dispatch(cleanPartnerDeactivationReasonAction());
};

const notificationPutSeenAndReload = (
  lastSeenCreatedAtDate,
  notificationId,
  page,
  perPage,
) => async dispatch => {
  await notificationPutSeen(notificationId, lastSeenCreatedAtDate)(dispatch);
  await getNotifications(page, perPage)(dispatch);
  await getState()(dispatch);
};

const notificationPutSeen = (
  notificationId,
  lastSeenCreatedAtDate,
) => async dispatch => {
  await NotificationService.notificationPutSeen(
    notificationId,
    lastSeenCreatedAtDate,
  );
};

const notificationPutSeenAllAndReload = (
  lastSeenCreatedAtDate,
  page,
  perPage,
) => async dispatch => {
  await notificationPutSeenAllNotifications(lastSeenCreatedAtDate)(dispatch);
  await getNotifications(page, perPage)(dispatch);
  await getState()(dispatch);
};

const notificationPutSeenAllActions = lastSeenCreatedAtDate => async dispatch => {
  await NotificationService.notificationPutSeenAllActions(
    lastSeenCreatedAtDate,
  );
};
const notificationPutSeenAllNotifications = lastSeenCreatedAtDate => async dispatch => {
  await NotificationService.notificationPutSeenAllNotifications(
    lastSeenCreatedAtDate,
  );
};

const openDeactivatePartnerDialog = () => dispatch => {
  return dispatch(openDeactivatePartnerDialogAction());
};

const openPartnerActivationReminderDialog = () => async dispatch => {
  dispatch(openPartnerActivationReminderDialogAction());
  await ChromeExtensionService.updateAudio();
};

const openClosedPartnerStateDialog = () => dispatch => {
  dispatch(openClosedPartnerStateDialogAction());
};

const openDeactivatePartnerDialogfromOpenPartnerState = () => dispatch => {
  closeOpenPartnerStateDialog()(dispatch);
  openDeactivatePartnerDialog()(dispatch);
};

const setIsPendingTurnPartnerOnBySchedule = () => dispatch => {
  dispatch(setIsPendingTurnPartnerOnByScheduleAction());
  openPartnerActivationReminderDialog()(dispatch);
};

const openOpenPartnerStateDialog = () => dispatch => {
  dispatch(openOpenPartnerStateDialogAction());
};

const setPartnerDeactivationReasonToLimitedTime = () => async dispatch => {
  const title = formatMessage({
    id: 'partnerDeactivationLimitedTimeReasonDialog.title',
  });
  const description = formatMessage({
    id: 'partnerDeactivationLimitedTimeReasonDialog.description',
  });
  const activePartnerTextButton = formatMessage({
    id: 'partnerDeactivationLimitedTimeReasonDialog.buttonText',
  });
  dispatch(
    dynInAppDialogActions.open(
      PARTNER_DEACTIVATION_LIMITED_TIME,
      title,
      description,
      {
        textContent: activePartnerTextButton,
        onClick: { type: ActionTypes.TURN_PARTNER_ON, payload: null },
      },
      {
        textContent: null,
        onClick: { type: ActionTypes.CLOSE_IN_APP_DIALOG, payload: null },
      },
      Serverities.ERROR,
      false,
    ),
  );
  await ChromeExtensionService.updateAudio();
};

const setIsNotPendingTurnPartnerOnBySchedule = () => dispatch => {
  dispatch(setIsNotPendingTurnPartnerOnByScheduleAction());
};

const setPartnerDeactivationReasonToRestOfDay = () => async dispatch => {
  const title = formatMessage({
    id: 'partnerDeactivationRestOfDayReasonDialog.title',
  });
  const description = formatMessage({
    id: 'partnerDeactivationRestOfDayReasonDialog.description',
  });
  const activePartnerTextButton = formatMessage({
    id: 'partnerDeactivationRestOfDayReasonDialog.buttonText',
  });
  dispatch(
    dynInAppDialogActions.open(
      PARTNER_DEACTIVATION_REST_OF_DAY,
      title,
      description,
      {
        textContent: activePartnerTextButton,
        onClick: { type: ActionTypes.TURN_PARTNER_ON, payload: null },
      },
      {
        textContent: null,
        onClick: { type: ActionTypes.CLOSE_IN_APP_DIALOG, payload: null },
      },
      Serverities.ERROR,
      false,
    ),
  );
  await ChromeExtensionService.updateAudio();
};
const setPartnerDeactivationReasonToUnknown = () => async dispatch => {
  const title = formatMessage({
    id: 'partnerDeactivationUnknownReasonDialog.title',
  });
  const description = formatMessage({
    id: 'partnerDeactivationUnknownReasonDialog.description',
  });
  const activePartnerTextButton = formatMessage({
    id: 'partnerDeactivationUnknownReasonDialog.buttonText',
  });
  dispatch(
    dynInAppDialogActions.open(
      PARTNER_DEACTIVATION_UNKNOWN_REASON,
      title,
      description,
      {
        textContent: activePartnerTextButton,
        onClick: { type: ActionTypes.TURN_PARTNER_ON, payload: null },
      },
      {
        textContent: null,
        onClick: { type: ActionTypes.CLOSE_IN_APP_DIALOG, payload: null },
      },
      Serverities.ERROR,
      true,
    ),
  );
  await ChromeExtensionService.updateAudio();
};

const getSuspendedStores = () => async dispatch => {
  try {
    const { data } = await PartnersService.getSupendedStores();
    dispatch(getSuspendedStoresAction(utils.parseToSuspendeStores(data)));
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
  }
};

const setPartnerState = partnerState => dispatch => {
  dispatch(setPartnerStateAction(partnerState));
};
const setIsFirstLogin = isFirstLogin => dispatch => {
  dispatch(setFirstLoginActions(isFirstLogin));
};

const getStoreStatusDetail = () => async dispatch => {
  const profile = appStore.getProfile();
  try {
    setStoreStatusDetailLoading(true)(dispatch);
    const { data } = await PartnersService.getStoreStatusDetail(profile.id);
    dispatch(getStoreStatusDetailAction(data));
  } catch (err) {
    await AppActions.handleCatch(err)(dispatch);
  }
  setStoreStatusDetailLoading(false)(dispatch);
};

const setStoreStatusDetailLoading = isLoading => dispatch => {
  dispatch(setStoreStatusDetailLoadingAction(isLoading));
};

const switchPartnerStoresStatus = payload => async dispatch => {
  let isOk = false;
  try {
    setSwitchStoreStatusLoading(true)(dispatch);
    await PartnersService.switchPartnerStoresStatus(payload);
    isOk = true;
  } catch (err) {
    await AppActions.handleCatch(err)(dispatch);
    setSwitchStoreStatusLoading(false)(dispatch);
  } finally {
    if (isOk) {
      setTimeout(async () => {
        await setHasBeenUpdatedStoreStatusNotification(true)(dispatch);
        await getState()(dispatch);
        await getStoreStatusDetail()(dispatch);
        setSwitchStoreStatusLoading(false)(dispatch);
      }, TIMEOUT_TO_RELOAD);
    }
  }
};

const setSwitchStoreStatusLoading = isLoading => dispatch => {
  dispatch(setSwitchStoreStatusLoadingAction(isLoading));
};

const getDeactivatedProducts = () => async dispatch => {
  const partnerId = appStore.getPartnerId();
  try {
    setDeactivatedProductsLoading(true)(dispatch);
    const { data } = await PartnersService.getDeactivatedProducts(partnerId);
    const filteredData = data.filter(
      element => element.products || element.toppings,
    );
    dispatch(getDeactivatedProductsAction(filteredData));
  } catch (err) {
    //TODO: Se colocó condición por despliegues progresivos en el backend
    if (err?.response?.status !== 404 && err?.response?.status !== 400) {
      await AppActions.handleCatch(err)(dispatch);
    }
  }
  setDeactivatedProductsLoading(false)(dispatch);
};

const setDeactivatedProductsLoading = isLoading => dispatch => {
  dispatch(setDeactivatedProductsLoadingAction(isLoading));
};

const partnerActions = {
  getDeactivatedProducts,
  getDeactivatedProductsAction,
  setDeactivatedProductsLoading,
  setDeactivatedProductsLoadingAction,
  acceptPartnerActivationReminderDialog,
  cleanPartnerDeactivationReason,
  cleanPartnerDeactivationReasonAction,
  closeClosedPartnerStateDialog,
  closeClosedPartnerStateDialogAction,
  closeDeactivatePartnerDialogAction,
  closeOpenPartnerStateDialog,
  closeOpenPartnerStateDialogAction,
  closePartnerActivationReminderDialogAction,
  closePartnerActivationReminderDialog,
  getIfPartnerIsInBlacklistHandShake,
  getIfPartnerIsInBlacklistHandShakeAction,
  getNotifications,
  getNotificationsAction,
  getNotificationsUnseen,
  getNotificationsUnseenAction,
  getProfileAction,
  getPartnerStateAction,
  openDeactivatePartnerDialogAction,
  deactivateIntegratedStoreAction,
  activatePartner,
  consumePartnerStateValidate,
  deactivatePartner,
  deactivatePartnerAction,
  confirmPartnerDeactivation,
  closeDeactivatePartnerDialog,
  getPartnerTypificationsAction,
  getPartnerTypifications,
  getProfile,
  getProfileAndState,
  getState,
  getSalesSummary,
  getSalesSummaryAction,
  notificationPutSeen,
  notificationPutSeenAllActions,
  notificationPutSeenAllNotifications,
  notificationPutSeenAndReload,
  notificationPutSeenAllAndReload,
  openClosedPartnerStateDialog,
  openClosedPartnerStateDialogAction,
  openDeactivatePartnerDialog,
  openOpenPartnerStateDialog,
  openOpenPartnerStateDialogAction,
  openPartnerActivationReminderDialogAction,
  openPartnerActivationReminderDialog,
  deactivateIntegratedStore,
  setIsNotPendingTurnPartnerOnByScheduleAction,
  setIsNotPendingTurnPartnerOnBySchedule,
  setIsPendingTurnPartnerOnBySchedule,
  setIsPendingTurnPartnerOnByScheduleAction,
  setPartnerDeactivationReasonToLimitedTime,
  setPartnerDeactivationReasonToRestOfDay,
  setPartnerDeactivationReasonToUnknown,
  setPartnerStateAction,
  setPartnerState,
  getSuspendedStoresAction,
  getSuspendedStores,
  setFirstLoginActions,
  setIsFirstLogin,
  getStoreStatusDetailAction,
  getStoreStatusDetail,
  setSimpleStoreStatusNotificationAction,
  setStoreStatusDetailLoadingAction,
  setStoreStatusDetailLoading,
  switchPartnerStoresStatus,
  setSwitchStoreStatusLoadingAction,
  setSwitchStoreStatusLoading,
  setPartnerStateLoadingAction,
  setPartnerStateLoading,
  setHasBeenUpdatedStoreStatusNotificationAction,
  setHasBeenUpdatedStoreStatusNotification,
};

export default partnerActions;
