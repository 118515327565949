import React, { Component } from 'react';
import MenuIcon from './MenuIcon';

class MyAccountIcon extends Component {
  d2 =
    'M19 21H4c0-4.22 3.358-7.642 7.5-7.642 4.142 0 7.5 3.422 7.5 7.642zm-7.5-7.67c-2.215 0-4.028-1.846-4.028-4.104V7.104C7.472 4.846 9.285 3 11.5 3c2.216 0 4.028 1.846 4.028 4.104v2.122c0 2.258-1.812 4.104-4.028 4.104zm.833 2.746v-1.697a.841.841 0 0 0-.833-.849c-.46 0-.833.38-.833.85v1.696c0 .469.373.849.833.849.46 0 .833-.38.833-.85z';

  render() {
    const { selected } = this.props;

    return <MenuIcon d2={this.d2} selected={selected} />;
  }
}

export default MyAccountIcon;
