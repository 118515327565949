import type { StoreStatusDetailResponse } from '../../../../services/partners-service';
import type { AdditionalStoreInfo, StoreStatusDetail } from '../../domain';
import type { ProfileExtendedResponse } from '../core/responses';

export const operationalStateTurboAdapter = {
  toStoresStatusDetail,
  toAdditionalStoreInfo,
  toStoreDetailAdditionalInfo,
} as const;

function toStoresStatusDetail(
  response: StoreStatusDetailResponse,
): StoreStatusDetail[] {
  return response.stores.map(item => ({
    id: item.store_id,
    name: item.store_name,
    status: item.status,
    firstMessage: item.first_message,
    secondMessage: item.second_message,
    ctaType: item.cta_type,
  }));
}

function toAdditionalStoreInfo(
  response: ProfileExtendedResponse,
): AdditionalStoreInfo[] {
  return response.extra_stores_information.map(item => ({
    id: item.store_id,
    isTurbo: item.is_turbo_store,
  }));
}

function toStoreDetailAdditionalInfo(
  storesDetail: StoreStatusDetail[],
  additionalInfo: AdditionalStoreInfo[],
) {
  const dataMap = new Map(additionalInfo.map(item => [item.id, item.isTurbo]));

  return storesDetail.map(store => ({
    ...store,
    isTurbo: dataMap.has(store.id) ? dataMap.get(store.id) : false,
  }));
}
