import { clsx } from 'clsx';

import { useFormatMessage } from '../../intl/intl';
import { CustomSearchTextField } from '../common/custom-search-text-field';

import useStyles from './OrderSearchTextField.styles';

function OrderSearchTextField(props) {
  const { fullWidth = true, onChange, value, classes = {} } = props;

  const _classes = useStyles();
  const placeholder = useFormatMessage({
    id: 'orderSearchTextField.placeHolder',
  });

  return (
    <CustomSearchTextField
      classes={{
        input: clsx(_classes.input, classes?.input),
        root: clsx(_classes.root, classes?.root),
        adornedStart: clsx(_classes?.adornedStart),
      }}
      placeholder={placeholder}
      onChange={event => {
        const text = event.target.value.trim();
        onChange(text);
      }}
      onClickCancelButton={() => {
        onChange('');
      }}
      fullWidth={fullWidth}
      value={value}
    />
  );
}

export default OrderSearchTextField;
