import { orderMgmtTimeoutCase } from '../../../../../../business/order-mgmt';
import { useFormatMessage } from '../../../../../../intl/intl';

export function useConfirmDialog() {
  const {
    isOpenDialog,
    setOpenDialog,
    acceptOrder,
    itemsToRemove,
  } = orderMgmtTimeoutCase();

  const summaryText = useFormatMessage({
    id: 'orderManagement.orderSummaryText',
  });
  const confirmBtnText = useFormatMessage({
    id: 'globals.confirm',
  });
  const cancelbtnText = useFormatMessage({
    id: 'cancel',
  });
  const disclaimerText = useFormatMessage({
    id: 'orderManagement.timeout.deleteDisclaimer',
  });
  const closeDialog = () => setOpenDialog(false);

  const confirmAccept = () => {
    acceptOrder({ action: 'continue' });
    setOpenDialog(false);
  };
  const itemsListToRemove = [
    ...(itemsToRemove?.products?.map(({ id, name }) => ({ id, name })) ?? []),
    ...(itemsToRemove?.toppings?.map(({ id, name }) => ({ id, name })) ?? []),
  ];

  return {
    summaryText,
    confirmBtnText,
    cancelbtnText,
    disclaimerText,
    itemsListToRemove,
    closeDialog,
    isOpenDialog,
    confirmAccept,
  };
}
