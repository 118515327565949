// React
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// External libs
import { find, get } from 'lodash';
// Utils
import OrdersUtils from '../../utils/OrdersUtils';
import PartnerUtils from '../../utils/PartnerUtils';
// Actions
import orderActions from '../../redux/actions/orders-actions';
// Components
import OrderDetailDialogActionsBox from './OrderDetailDialogActionsBox';

const mapStateToProps = (
  {
    orderDetailDialogReducer: { orderId },
    ordersReducer: { orders },
    partnerReducer: { profile },
  },
  { intl: { formatMessage } },
) => {
  const cancelButtonText = formatMessage({
    id: 'orderDetailDialogActionsBox.cancelButtonText',
  });
  const deliveredToRTText = formatMessage({
    id: 'orderDetailDialogActionsBox.deliveredToRTText',
  });
  const notDeliveredToRTText = formatMessage({
    id: 'orderDetailDialogActionsBox.notDeliveredToRTText',
  });
  const orderReadyButtonText = formatMessage({
    id: 'orderDetailDialogActionsBox.orderReadyButtonText',
  });
  const pendingToDeliverButtonText = formatMessage({
    id: 'orderDetailDialogActionsBox.pendingToDeliverButtonText.single',
  });
  const packageDeliveredButtonText = formatMessage({
    id: 'orderDetailDialogActionsBox.packageDeliverButtonText',
  });
  const sentToDeliverButtonText = formatMessage({
    id: 'orderDetailDialogActionsBox.sendToDeliveryButtonText',
  });
  const rejectButtonText = formatMessage({
    id: 'orderDetailDialogActionsBox.rejectButtonText',
  });
  const canceledMarketplaceOrderButtonText = formatMessage({
    id: 'orderDetailDialogActionsBox.canceledMarketplaceOrderButton',
  });
  const canceledPickupOrderButtonText = formatMessage({
    id: 'orderDetailDialogActionsBox.canceledPickupOrderButtonText',
  });

  const substitutionButtonText = formatMessage({
    id: 'orderDetailDialogActionsBox.substitutionButtonText',
  });

  const isPartnerIntegrated = PartnerUtils.isIntegratedPartner(profile);

  const orderDetail = get(find(orders, { id: +orderId }), ['detail'], {
    id: +orderId,
    storekeeper: { id: 0 },
  });

  const isOrderEnabledToEdit = !!orderDetail?.is_enabled;

  const storeId =
    orderDetail.stores &&
    orderDetail.stores[0] &&
    orderDetail.stores[0].store_id;

  const isInStore = OrdersUtils.isInStore(orderDetail.state);
  const isFullDelivery = OrdersUtils.isFullDelivery(
    orderDetail.delivery_method,
  );
  const isMarketPlace = OrdersUtils.isMarketPlace(orderDetail.delivery_method);
  const isPickup = OrdersUtils.isPickup(orderDetail.delivery_method);

  const showCookingOrderActions =
    isInStore && OrdersUtils.isBeingCooking(orderDetail.partner_order_state);

  const showNewOrderActions =
    !isPartnerIntegrated &&
    isInStore &&
    OrdersUtils.isNew(orderDetail.partner_order_state);

  const showPendingToDeliverOrderActions =
    isInStore && OrdersUtils.isReadyAndPacked(orderDetail.partner_order_state);

  const showCanceledOrderActions = OrdersUtils.isCanceled(orderDetail.state);
  const showCanceledFullDeliveryOrderActions =
    showCanceledOrderActions && isFullDelivery;
  const showCanceledMarketplaceOrderActions =
    showCanceledOrderActions && isMarketPlace;

  const showCanceledPickupOrderActions = showCanceledOrderActions && isPickup;

  const showSubstitutionFlowButtonAction =
    isInStore &&
    !orderDetail?.products.some(
      ({ replacement_suggestion }) => !!replacement_suggestion,
    );

  return {
    cancelButtonText,
    canceledMarketplaceOrderButtonText,
    canceledPickupOrderButtonText,
    deliveredToRTText,
    notDeliveredToRTText,
    orderReadyButtonText,
    order: orderDetail,
    partnerId: profile.id,
    pendingToDeliverButtonText,
    showCanceledOrderActions,
    showCanceledFullDeliveryOrderActions,
    showCanceledMarketplaceOrderActions,
    showCanceledPickupOrderActions,
    showCookingOrderActions,
    showNewOrderActions,
    showPendingToDeliverOrderActions,
    storeId,
    storekeeperId: orderDetail.storekeeper && orderDetail.storekeeper.id,
    packageDeliveredButtonText,
    sentToDeliverButtonText,
    substitutionButtonText,
    showSubstitutionFlowButtonAction,
    rejectButtonText,
    isOrderEnabledToEdit,
  };
};

const mapDispatchToProps = {
  onClickDeliveredToRT: orderActions.setDeliveredToRTBeforeCanceledFromDialog,
  onClickNotDeliveredToRT:
    orderActions.setNotDeliveredToRTBeforeCanceledFromDialog,
  onClickOrderReadyButton: orderActions.executeOrderIsReadyFromDialog,
  onClickDeliverButton: orderActions.deliverOrderFromDialog,
  onClickRejectButton: orderActions.openOrderRejectionDialog,
  onClickCanceledMarketplaceOrderButton:
    orderActions.acknowledgeCanceledOrderFromDialog,
  onClickCanceledPickupOrderButton:
    orderActions.acknowledgeCanceledOrderFromDialog,
  onClickSubstitutionFlowButton: orderActions.executeSubstitutionFlowDialog,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(OrderDetailDialogActionsBox),
);
