import { clsx } from 'clsx';
import { useMediaQuery } from '@material-ui/core';

import useStyles from './MenuSearch.styles';

import MenuSearchField from '../menu-search-field';
import MenuSearchProductList from '../menu-search-product-list';
import MenuSearchToppingList from '../menu-search-topping-list';
import { MenuSearchStateMessage } from '../menu-search-state-message';

function MenuSearch(props) {
  const {
    classes = {},
    showMenuSearchStateMessage,
    showProductsList,
    showToppingsList,
    title = '',
  } = props;

  const _classes = useStyles();
  const showSearchField = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <div className={clsx(_classes.header, classes?.header)}>
        <span className={clsx(classes?.title)}>{title}</span>
        {showSearchField && (
          <MenuSearchField
            autoFocus={true}
            classes={{
              root: clsx(_classes.searchInputRoot, classes?.searchInputRoot),
            }}
          />
        )}
      </div>
      <div className={clsx(_classes.resultContainer, classes?.resultContainer)}>
        {showProductsList && <MenuSearchProductList />}
        {showToppingsList && <MenuSearchToppingList />}
        {showMenuSearchStateMessage && <MenuSearchStateMessage />}
      </div>
    </div>
  );
}

export default MenuSearch;
