import { formatMessage } from '../../intl/intl'
import { END_TIME_DEFAULT_HOUR, START_TIME_DEFAULT_HOUR } from './constants'
import { IGetSpecialDay, ISchedule, ISpecialDay } from './types'

export const getFormatDay = (day: number) =>
  ({
    1: formatMessage({ id: 'globals.monday' }),
    2: formatMessage({ id: 'globals.tuesday' }),
    3: formatMessage({ id: 'globals.wednesday' }),
    4: formatMessage({ id: 'globals.thursday' }),
    5: formatMessage({ id: 'globals.friday' }),
    6: formatMessage({ id: 'globals.saturday' }),
    7: formatMessage({ id: 'globals.sunday' }),
  }[day || 1])

export const getSpecialDay = ({
  storeSchedules,
  currentDay,
  currentMonth,
}: IGetSpecialDay) => {
  const specialDay = storeSchedules?.specials.filter(
    ({ day, month }: ISpecialDay) => {
      return day === currentDay && month === currentMonth
    },
  )[0]
  return specialDay
}

export const getDayByNumber = (numberDay: number) => {
  return {
    1: 'mon',
    2: 'tue',
    3: 'wed',
    4: 'thu',
    5: 'fri',
    6: 'sat',
    7: 'sun',
  }[numberDay ?? 1]
}

export const formatStoreExtendSchedulesNextStartTime = (
  schedules: ISchedule[],
) => {
  return schedules.map(
    (schedule: ISchedule, idx: number, schedules: ISchedule[]) => ({
      ...schedule,
      next_starts_time:
        schedules[idx + 1]?.starts_time || END_TIME_DEFAULT_HOUR,
    }),
  )
}

export const formatStoreExtendSchedulesPreviousEndTime = (
  schedules: ISchedule[],
) => {
  return schedules.map(
    (schedule: ISchedule, idx: number, schedules: ISchedule[]) => ({
      ...schedule,
      previous_end_time:
        schedules[idx - 1]?.ends_time || START_TIME_DEFAULT_HOUR,
    }),
  )
}

export const formatStoreExtendSchedulesSlotEnable = (schedules: any) => {
  const time = getCurrentTime()

  const { newSchedules } = schedules.reduce(
    (acc: any, schedule: any) => {
      const { starts_time, ends_time, next_starts_time } = schedule
      const isSlotEnabled = time < ends_time && !acc.isSlotEnabled
      const isStartsTimeEDisabled = isSlotEnabled ? time > starts_time : true
      const isEndsTimeDisabled =
        next_starts_time === END_TIME_DEFAULT_HOUR && !acc.isSlotEnabled
          ? false
          : !isSlotEnabled
      acc.newSchedules.push({
        ...schedule,
        is_starts_time_disabled: isStartsTimeEDisabled,
        is_ends_time_disabled: isEndsTimeDisabled,
      })
      if (!acc.isSlotEnabled) acc.isSlotEnabled = isSlotEnabled
      return acc
    },
    { isSlotEnabled: false, newSchedules: [] },
  )

  return newSchedules
}

export const formatHolidayOrNormalDaySlotSchedules = (
  schedules: ISchedule[],
  { startTime, endTime, slotId }: any,
) => {
  const storeSchedules = schedules.map(({ starts_time, ends_time }) => ({
    starts_time,
    ends_time,
  }))
  storeSchedules[slotId].starts_time = startTime
  storeSchedules[slotId].ends_time = endTime
  return [...storeSchedules]
}

export const getCurrentTime = () => {
  const [hours, minutes] = new Date().toTimeString().split(':')
  const time = `${hours}:${minutes}`
  return time
}
