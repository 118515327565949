import { clsx } from 'clsx';
import { useState } from 'react';

import ProductBox from '../product-box';

import useStyles from './ProductBoxesList.styles';

function ProductBoxesList(props) {
  const { title = 'Productos', products = [], classes = {} } = props;
  const [deactivatedItemId, setDeactivatedItemId] = useState(null);
  const _classes = useStyles();
  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <div className={clsx(_classes.titleContainer, classes?.titleContainer)}>
        <span className={clsx(classes?.title)}>{title}</span>
      </div>
      <div
        className={clsx(_classes.productsContainer, classes.productsContainer)}
      >
        {products.map((product, index) => {
          return (
            <ProductBox
              classes={{
                imageRoot: clsx(
                  _classes.productBoxImageRoot,
                  classes?.productBoxImageRoot,
                ),
                productInfoContainer: clsx(
                  _classes.productBoxProductInfoContainer,
                  classes?.productBoxProductInfoContainer,
                ),
                root: clsx(_classes.productBoxRoot, classes?.productBoxRoot),
              }}
              key={`t-${index}-${product.id}`}
              product={product}
              showDetails={true}
              deactivatedItemId={deactivatedItemId}
              setDeactivatedItemId={setDeactivatedItemId}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ProductBoxesList;
