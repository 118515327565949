import { Typography } from '@material-ui/core';

import {
  RadioGroupRowBoolean,
  SwitchModeIOS,
} from '../../../../shared/components';

import { useOrderPrintingConfig } from './hooks';

import makeClasses from './OrderPrintingConfig.style';

export function OrderPrintingConfig() {
  const {
    lang,
    isCommandPrinting,
    isAutoPrinting,
    isChecklist,
    handleOnCheckCommandPrinting,
    handleOnChangeAutoPrinting,
    handleOnChangeChecklist,
  } = useOrderPrintingConfig();

  const _classes = makeClasses();

  return (
    <article className={_classes.container}>
      <header className={_classes.header}>
        <div>
          <Typography variant='h3' className={_classes.title}>
            {lang.title}
          </Typography>
          <Typography className={_classes.description}>
            {lang.description}
          </Typography>
        </div>
        <SwitchModeIOS
          id='command-printing-opt'
          checked={isCommandPrinting}
          onCheck={handleOnCheckCommandPrinting}
        />
      </header>

      {isCommandPrinting && (
        <>
          <section>
            <Typography variant='h4' className={_classes.optionTitle}>
              {lang.opts.auto.title}
            </Typography>
            <RadioGroupRowBoolean
              name='auto-printing'
              firstLabel={lang.opts.auto.labels.yes}
              secondLabel={lang.opts.auto.labels.no}
              value={isAutoPrinting}
              onChange={handleOnChangeAutoPrinting}
            />
          </section>
          <section>
            <Typography variant='h4' className={_classes.optionTitle}>
              {lang.opts.checklist.title}
            </Typography>
            <RadioGroupRowBoolean
              name='checklist-printing'
              firstLabel={lang.opts.checklist.labels.yes}
              secondLabel={lang.opts.checklist.labels.no}
              value={isChecklist}
              onChange={handleOnChangeChecklist}
            />
          </section>
        </>
      )}
    </article>
  );
}
