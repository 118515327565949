import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { textIds } from './consts';
import { useFormatMessage } from '../../intl/intl';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';
import orderActions from '../../redux/actions/orders-actions';
import hooks from './hooks';

import CustomDialog from '../common/custom-dialog';

import useStyles from './styles';

export function CancelByStockOutSuggestionConfirmationDialog() {
  const componentClasses = useStyles();

  const confirmButtonText = useFormatMessage(textIds.confirmButtonText);
  const description = useFormatMessage(textIds.description);

  const open = hooks.useOpen();
  const title = hooks.useTitle();

  const dispatch = useDispatch();

  const handleBack = useCallback(() => {
    dispatch(dialogsManagerActions.back());
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  const handleConfirm = useCallback(async () => {
    await dispatch(orderActions.cancelByStockOutAsSupport());
  }, [dispatch]);

  return (
    <CustomDialog
      classes={{
        childrenContainer: componentClasses.body,
        headerRoot: componentClasses.headerRoot,
        paper: componentClasses.paper,
      }}
      actions={[
        {
          text: confirmButtonText,
          onClick: handleConfirm,
          color: 'primary',
          variant: 'contained',
          fullWidth: false,
        },
      ]}
      onClickBackButton={handleBack}
      onClose={handleClose}
      open={open}
      showBackButton
      showCloseButton
      title={title}
    >
      {description}
    </CustomDialog>
  );
}
