const confirmButtonText = {
  id: 'globals.confirm',
};

const description = {
  id: 'orderRejectionBySaturatedStoreDialog.description',
};

const label = {
  id: 'globals.minutes',
};

const title = {
  id: 'orderRejectionBySaturatedStoreDialog.title',
};

const textIds = {
  confirmButtonText,
  description,
  label,
  title,
};

export default textIds;
