// Interfaces
import { Interval, Day, ScheduleDay } from './interfaces'
import {
  StoreScheduleResponse,
  IntervalResponse,
} from '../../services/PM-automation-service'
import { formatMessage } from '../../intl/intl'

// // singular Intervals
const parseToInterval = (
  intervalResponse: IntervalResponse,
  day: string,
): Interval => {
  return {
    day: day || '',
    id: intervalResponse.id || 0,
    startsTime: intervalResponse.starts_time?.substring(5, 0) || '',
    endsTime: intervalResponse.ends_time?.substring(5, 0) || '',
  }
}

const parseToIntervals = (
  intervals: IntervalResponse[],
  day: string,
): Interval[] =>
  intervals?.map(interval => {
    return parseToInterval(interval, day)
  }) || []

const getIntervals = (response: StoreScheduleResponse): Interval[] => {
  const daysResponse = response.normal || []
  let intervals: Interval[] = []
  for (const dayResponse of daysResponse) {
    if (dayResponse.schedules === null || dayResponse.schedules === undefined) {
      console.error(
        'SchedulePMFlowDialog - utils - getIntervals - wrong schedule',
        dayResponse,
      )
      continue
    }
    if (
      dayResponse.day === null ||
      dayResponse.day === undefined ||
      dayResponse.day === ''
    ) {
      console.error(
        'SchedulePMFlowDialog- utils - getIntervals - wrong day or empty',
        dayResponse,
      )
      continue
    }
    const parsedIntervals = parseToIntervals(
      dayResponse.schedules,
      dayResponse.day,
    )
    intervals = intervals.concat(parsedIntervals)
  }

  return intervals
}

const getDays = (): Day[] => {
  return [
    {
      code: 'mon',
      dayName: formatMessage({ id: 'globals.monday' }),
    },
    {
      code: 'tue',
      dayName: formatMessage({ id: 'globals.tuesday' }),
    },
    {
      code: 'wed',
      dayName: formatMessage({ id: 'globals.wednesday' }),
    },
    {
      code: 'thu',
      dayName: formatMessage({ id: 'globals.thursday' }),
    },
    {
      code: 'fri',
      dayName: formatMessage({ id: 'globals.friday' }),
    },
    {
      code: 'sat',
      dayName: formatMessage({ id: 'globals.saturday' }),
    },
    {
      code: 'sun',
      dayName: formatMessage({ id: 'globals.sunday' }),
    },
  ]
}

const filterWeekdays = (scheduleDays: ScheduleDay[]): ScheduleDay[] => {
  return scheduleDays.filter(i =>
    ['mon', 'tue', 'wed', 'thu', 'fri'].includes(i.code),
  )
}
const filterWeekendsDays = (scheduleDays: ScheduleDay[]): ScheduleDay[] => {
  return scheduleDays.filter(i => ['sat', 'sun'].includes(i.code))
}

const partionByDayType = (scheduleDays: ScheduleDay[]) => {
  const weekdays = filterWeekdays(scheduleDays)
  const weekends = filterWeekendsDays(scheduleDays)
  return [weekdays, weekends]
}

const utils = {
  getDays,
  getIntervals,
  partionByDayType,
}

export default utils
