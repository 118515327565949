import { OnUpdateHandoffDetail } from '../../domain/contracts';
import { onUpdateHandoffDetailRequest } from '../core/requests';

const toUpdateHandoffDetail = (
  params: OnUpdateHandoffDetail,
): onUpdateHandoffDetailRequest => ({
  order_id: params.orderId,
  store_id: params.storeId,
  drinks_outside: params.hasDrinksOutside,
  bags_number: params.quantityOfPackages,
});

export const requestAdapter = {
  toUpdateHandoffDetail,
} as const;
