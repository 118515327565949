import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../../shared/styles/colors';

export default makeStyles({
  button: {
    backgroundColor: neutral['20'],
    color: neutral['50'],
    padding: '6px',
  },
  icon: {
    color: neutral['80'],
  },
});
