// External libs
import { find, get } from 'lodash'
import { createSelector } from 'reselect'
import moment from 'moment/moment'
// Consts
import { OrderDeliveryMethodType } from '../../../constants/OrderDeliveryMethodType'
// Utils
import OrdersUtils from '../../../utils/OrdersUtils'
import { formatCurrency } from '../../../utils/CurrencyUtils'
import RTUtils from '../../../utils/RTUtils'
import StringUtils from '../../../utils/StringUtils'
// Selectors
import OrderDetailDialogSelectors from '../order-detail-dialog-selectors'
import { selectors as PartnerSelectors } from '../../../redux/partner'
import RTReleaseConfirmationDialogSelectors from '../rt-release-confirmation-dialog-selectors'

const selectOrdersReducer: (state: any) => any = state => state.ordersReducer

const selectOrders = createSelector<any, any, any[]>(
  selectOrdersReducer,
  ordersReducer => ordersReducer.orders,
)

const selectOrderInOrderDetailDialog = createSelector(
  [selectOrders, OrderDetailDialogSelectors.selectOrderId],
  (orders, orderId) => {
    if (!orders || !orderId) {
      return null
    }
    return orders.find(order => order.id === +orderId)
  },
)

const selectOrderDetailInOrderDetailDialog = createSelector(
  selectOrderInOrderDetailDialog,
  order => get(order, 'detail', null),
)

const selectOrderCTStartedAtInOrderDetailDialog = createSelector<
  any,
  any,
  string | null
>(selectOrderDetailInOrderDetailDialog, detail =>
  get(detail, 'cooking_time_started_at', null),
)

const selectOrderCTInOrderDetailDialog = createSelector<any, any, number>(
  selectOrderDetailInOrderDetailDialog,
  detail => get(detail, 'cooking_time', 0),
)

const selectOrderAcceptanceMomentInOrderDetailDialog = createSelector<
  any,
  string | null,
  moment.Moment | null
>(selectOrderCTStartedAtInOrderDetailDialog, cooking_time_started_at => {
  if (StringUtils.isNullOrEmpty(cooking_time_started_at)) {
    return null
  }
  const momentResult = moment(cooking_time_started_at || undefined)

  return momentResult
})

const selectOrderDeliveryMomentInOrderDetailDialog = createSelector<
  any,
  moment.Moment | null,
  number,
  moment.Moment | null
>(
  selectOrderAcceptanceMomentInOrderDetailDialog,
  selectOrderCTInOrderDetailDialog,
  (acceptanceMoment, orderCT) => {
    if (!acceptanceMoment || !acceptanceMoment.isValid()) {
      return null
    }
    return acceptanceMoment.add(orderCT, 'minutes')
  },
)

const selectIsOrderCTExpiredInOrderDetailDialog = createSelector<
  any,
  moment.Moment | null,
  boolean
>(selectOrderDeliveryMomentInOrderDetailDialog, deliveryMoment => {
  if (!deliveryMoment || !deliveryMoment.isValid()) {
    return false
  }
  return deliveryMoment.diff(moment(), 'seconds', true) < 0
})

const selectCTToTakeOrderInOrderDetailDialog = createSelector<any, any, number>(
  selectOrderDetailInOrderDetailDialog,
  detail => get(detail, 'cookingTimeToTakeOrder', 5),
)

const selectOrderInRTReleaseConfirmationDialog = createSelector(
  [selectOrders, RTReleaseConfirmationDialogSelectors.selectOrderId],
  (orders, orderId) => {
    if (!orders || !orderId) {
      return null
    }
    return orders.find(order => order.id === +orderId)
  },
)

const selectIsIntegratedOrderInOrderDetailDialog = createSelector(
  selectOrderDetailInOrderDetailDialog,
  orderDetail => OrdersUtils.isOrderIntegrated(orderDetail),
)

const selectIsNewOrderInOrderDetailDialog = createSelector(
  selectOrderDetailInOrderDetailDialog,
  orderDetail =>
    OrdersUtils.isNew(get(orderDetail, 'partner_order_state', null)),
)

const selectIsNonIntegratedNewOrderInOrderDetailDialog = createSelector(
  selectIsIntegratedOrderInOrderDetailDialog,
  selectIsNewOrderInOrderDetailDialog,
  (isIntegrated, isNew) => {
    return !isIntegrated && isNew
  },
)

const selectRTStateInOrderDetailDialog = createSelector(
  selectOrderDetailInOrderDetailDialog,
  orderDetail => get(orderDetail, 'rt_state', null),
)

const selectETAStepsInOrderDetailDialog = createSelector(
  selectRTStateInOrderDetailDialog,
  rtState => get(rtState, 'eta_steps', []),
)

const selectRTStateActiveStepInOrderDetailDialog = createSelector(
  selectETAStepsInOrderDetailDialog,
  etaSteps => RTUtils.getActiveStep(etaSteps),
)

const selectRTStateActiveStepIndexInOrderDetailDialog = createSelector(
  selectRTStateActiveStepInOrderDetailDialog,
  activeStep => get(activeStep, 'index', null),
)

const selectIsRTOnTheWayInOrderDetailDialog = createSelector(
  selectRTStateActiveStepIndexInOrderDetailDialog,
  activeStepIndex => RTUtils.isRTOnTheWay(activeStepIndex),
)

const selectOrderDetailInRTReleaseConfirmationDialog = createSelector(
  selectOrderInRTReleaseConfirmationDialog,
  order => get(order, 'detail', null),
)

const selectOrderDetailPaymentMethodInOrderDetailDialog = createSelector(
  selectOrderDetailInOrderDetailDialog,
  orderDetail => get(orderDetail, 'payment_method', null),
)

const selectOrderDetailTotalChargeInOrderDetailDialog = createSelector(
  selectOrderDetailInOrderDetailDialog,
  orderDetail => get(orderDetail, 'total_charge', 0),
)

const selectOrderDeliveryMethodInOrderDetailDialog = createSelector<
  any,
  any,
  string
>(selectOrderDetailInOrderDetailDialog, orderDetail =>
  get(orderDetail, 'delivery_method', OrderDeliveryMethodType.DELIVERY),
)

const selectIsMarketplaceInOrderDetailDialog = createSelector(
  selectOrderDeliveryMethodInOrderDetailDialog,
  deliveryMethod => OrdersUtils.isMarketPlace(deliveryMethod),
)

const selectIsPickupInOrderDetailDialog = createSelector(
  selectOrderDeliveryMethodInOrderDetailDialog,
  deliveryMethod => OrdersUtils.isPickup(deliveryMethod),
)

const selectOrderPredictiveCTConfigInOrderDetailDialog = createSelector(
  selectOrderDetailInOrderDetailDialog,
  orderDetail => {
    return get(orderDetail, 'predict_cooking_time', null)
  },
)

const selectOrderDefaultPredictiveCTConfigInOrderDetailDialog = createSelector(
  selectOrderPredictiveCTConfigInOrderDetailDialog,
  predictiveCTConfig => {
    return get(predictiveCTConfig, 'default_cooking_time', null)
  },
)

const selectOrderMaxPredictiveCTConfigInOrderDetailDialog = createSelector(
  selectOrderPredictiveCTConfigInOrderDetailDialog,
  predictiveCTConfig => {
    return get(predictiveCTConfig, 'max_cooking_time', null)
  },
)
const selectOrderMinPredictiveCTConfigInOrderDetailDialog = createSelector(
  selectOrderPredictiveCTConfigInOrderDetailDialog,
  predictiveCTConfig => {
    return get(predictiveCTConfig, 'min_cooking_time', null)
  },
)

const selectOrderStoreIdInOrderDetailDialog = createSelector<any, any, number>(
  selectOrderDetailInOrderDetailDialog,
  orderDetail => {
    return get(orderDetail, 'stores[0].store_id', 0)
  },
)

const selectOrderStoreInOrderDetailDialog = createSelector(
  selectOrderStoreIdInOrderDetailDialog,
  PartnerSelectors.selectStores,
  (orderStoreId, stores) => {
    if (!orderStoreId || !stores || !stores.length) {
      return null
    }
    return find(stores, { id: orderStoreId })
  },
)

const selectOrderStoreDefaultCTInOrderDetailDialog = createSelector(
  selectOrderStoreInOrderDetailDialog,
  orderStore => get(orderStore, 'default_cooking_time', 0) || 0,
)

const selectOrderStoreMaxCTInOrderDetailDialog = createSelector(
  selectOrderStoreInOrderDetailDialog,
  orderStore => get(orderStore, 'max_cooking_time', 0) || 0,
)

const selectOrderStoreMinCTInOrderDetailDialog = createSelector(
  selectOrderStoreInOrderDetailDialog,
  orderStore => get(orderStore, 'min_cooking_time', 0) || 0,
)

const selectNonPredictiveMaxCTInputConfigInOrderDetailDialog = createSelector<
  any,
  number,
  number
>(selectOrderStoreMaxCTInOrderDetailDialog, orderStoreMaxCT => {
  return orderStoreMaxCT || +get(process, 'env.REACT_APP_DEFAULT_MAX_CT', 40)
})

const selectNonPredictiveMinCTInputConfigInOrderDetailDialog = createSelector<
  any,
  number,
  number
>(selectOrderStoreMinCTInOrderDetailDialog, orderStoreMinCT => {
  return orderStoreMinCT || +get(process, 'env.REACT_APP_DEFAULT_MIN_CT', 5)
})

const selectMaxCTFSConfigInOrderDetailDialog = createSelector<
  any,
  number,
  number,
  number
>(
  selectOrderMaxPredictiveCTConfigInOrderDetailDialog,
  selectNonPredictiveMaxCTInputConfigInOrderDetailDialog,
  (orderMaxPredictiveCT, nonPredictiveMaxCT) => {
    return orderMaxPredictiveCT || nonPredictiveMaxCT
  },
)

const selectMinCTFSConfigInOrderDetailDialog = createSelector<
  any,
  number,
  number,
  number
>(
  selectOrderMinPredictiveCTConfigInOrderDetailDialog,
  selectNonPredictiveMinCTInputConfigInOrderDetailDialog,
  (orderMinPredictiveCT, nonPredictiveMinCT) => {
    return orderMinPredictiveCT || nonPredictiveMinCT
  },
)

const selectOrderTotalsObjectInOrderDetailDialog = createSelector(
  selectOrderDetailInOrderDetailDialog,
  detail => get(detail, 'order_totals', null),
)

const selectOrderTotalDetailsInOrderDetailDialog = createSelector<
  any,
  any,
  any[]
>(
  selectOrderTotalsObjectInOrderDetailDialog,
  totalsObject => get(totalsObject, 'details', []) || [],
)

const selectOrderTotalInOrderDetailDialog = createSelector(
  selectOrderTotalsObjectInOrderDetailDialog,
  totalsObject => get(totalsObject, 'total', null),
)

const selectOrderTotalLabelInOrderDetailDialog = createSelector<
  any,
  any,
  string
>(selectOrderTotalInOrderDetailDialog, total => get(total, 'text', null))

const selectOrderTotalValueInOrderDetailDialog = createSelector<
  any,
  any,
  number
>(selectOrderTotalInOrderDetailDialog, total => get(total, 'value', 0) || 0)

const selectFormattedOrderTotalDetailsInOrderDetailDialog = createSelector(
  selectOrderTotalDetailsInOrderDetailDialog,
  details => {
    if (!details) {
      return []
    }
    return details.map(detail => ({
      ...detail,
      value: formatCurrency(detail.value),
    }))
  },
)

const selectFormattedOrderTotalValueInOrderDetailDialog = createSelector<
  any,
  any,
  string
>(selectOrderTotalValueInOrderDetailDialog, total =>
  formatCurrency(total, false),
)

const selectOrderTotalsErrorInOrderDetailDialog = createSelector(
  selectOrderTotalsObjectInOrderDetailDialog,
  totalsObject => get(totalsObject, 'errors', null),
)

const selectOrderTotalsErrorIdInOrderDetailDialog = createSelector(
  selectOrderTotalsErrorInOrderDetailDialog,
  error => get(error, 'id', null),
)

const selectOrderTotalsErrorMessageInOrderDetailDialog = createSelector(
  selectOrderTotalsErrorInOrderDetailDialog,
  error => get(error, 'message', null),
)

const selectIsOrderTotalsSuccessfulInOrderDetailDialog = createSelector(
  selectOrderTotalsObjectInOrderDetailDialog,
  totalsObject => OrdersUtils.isOrderTotalsSuccessful(totalsObject),
)

const selectInitialCTToTakeOrderInOrderDetailDialog = createSelector(
  selectIsMarketplaceInOrderDetailDialog,
  selectOrderDefaultPredictiveCTConfigInOrderDetailDialog,
  selectOrderStoreDefaultCTInOrderDetailDialog,
  selectOrderStoreMinCTInOrderDetailDialog,
  (
    isMarketplace,
    orderDefaultPredictiveCTConfig,
    orderStoreDefaultCT,
    orderStoreMinCT,
  ) => {
    if (!isMarketplace && !!orderDefaultPredictiveCTConfig) {
      return orderDefaultPredictiveCTConfig
    }
    if (!!orderStoreDefaultCT) {
      return orderStoreDefaultCT
    }
    if (!!orderStoreMinCT) {
      return orderStoreMinCT
    }

    return +get(process, 'env.REACT_APP_DEFAULT_MIN_CT', 5)
  },
)

const selectOrderStateInOrderDetailDialog = createSelector(
  selectOrderDetailInOrderDetailDialog,
  detail => get(detail, 'state', null),
)

const selectIsOrderInStoreInOrderDetailDialog = createSelector(
  selectOrderStateInOrderDetailDialog,
  orderState => OrdersUtils.isInStore(orderState),
)

const selectProductsInOrderDetailDialog = createSelector<any, any, any[]>(
  selectOrderDetailInOrderDetailDialog,
  detail => get(detail, 'products', []),
)

const selectStoreIdInRTReleaseConfirmationDialog = createSelector(
  selectOrderInRTReleaseConfirmationDialog,
  order => get(order, 'store_id', null),
)

const selectStorekeeperIdInRTReleaseConfirmationDialog = createSelector(
  selectOrderDetailInRTReleaseConfirmationDialog,
  orderDetail => get(orderDetail, 'storekeeper.id', null),
)

const selectStorekeeperInOrderDetailDialog = createSelector<
  any,
  any,
  any | null
>(selectOrderDetailInOrderDetailDialog, detail =>
  get(detail, 'storekeeper', null),
)

const selectSupportNotificationsMessagesInOrderDetailDialog = createSelector<
  any,
  any,
  any[]
>(
  selectOrderDetailInOrderDetailDialog,
  detail => get(detail, 'supportNotificationsMessages', []) || [],
)

const selectTz = createSelector<any, any, string>(
  selectOrdersReducer,
  slice => get(slice, 'zoneId', '') || '',
)

const selectSupportScore = createSelector<any, any, any[]>(
  selectOrderDetailInOrderDetailDialog,
  detail => get(detail, 'supportScore', {}) || {},
)

const OrdersSelector = {
  selectCTToTakeOrderInOrderDetailDialog,
  selectFormattedOrderTotalValueInOrderDetailDialog,
  selectFormattedOrderTotalDetailsInOrderDetailDialog,
  selectInitialCTToTakeOrderInOrderDetailDialog,
  selectIsMarketplaceInOrderDetailDialog,
  selectIsNewOrderInOrderDetailDialog,
  selectIsNonIntegratedNewOrderInOrderDetailDialog,
  selectIsOrderCTExpiredInOrderDetailDialog,
  selectIsOrderInStoreInOrderDetailDialog,
  selectIsOrderTotalsSuccessfulInOrderDetailDialog,
  selectIsPickupInOrderDetailDialog,
  selectIsRTOnTheWayInOrderDetailDialog,
  selectMaxCTFSConfigInOrderDetailDialog,
  selectMinCTFSConfigInOrderDetailDialog,
  selectNonPredictiveMaxCTInputConfigInOrderDetailDialog,
  selectNonPredictiveMinCTInputConfigInOrderDetailDialog,
  selectOrderCTStartedAtInOrderDetailDialog,
  selectOrderCTInOrderDetailDialog,
  selectOrderDefaultPredictiveCTConfigInOrderDetailDialog,
  selectOrderDeliveryMethodInOrderDetailDialog,
  selectOrderDeliveryMomentInOrderDetailDialog,
  selectOrderDetailInOrderDetailDialog,
  selectOrderDetailInRTReleaseConfirmationDialog,
  selectOrderDetailPaymentMethodInOrderDetailDialog,
  selectOrderInOrderDetailDialog,
  selectOrderMaxPredictiveCTConfigInOrderDetailDialog,
  selectOrderMinPredictiveCTConfigInOrderDetailDialog,
  selectOrderPredictiveCTConfigInOrderDetailDialog,
  selectOrders,
  selectOrderStoreIdInOrderDetailDialog,
  selectOrderStoreDefaultCTInOrderDetailDialog,
  selectOrderStoreInOrderDetailDialog,
  selectOrderStoreMaxCTInOrderDetailDialog,
  selectOrderStoreMinCTInOrderDetailDialog,
  selectOrderTotalLabelInOrderDetailDialog,
  selectOrderTotalsErrorIdInOrderDetailDialog,
  selectOrderTotalsErrorMessageInOrderDetailDialog,
  selectProductsInOrderDetailDialog,
  selectStoreIdInRTReleaseConfirmationDialog,
  selectStorekeeperIdInRTReleaseConfirmationDialog,
  selectStorekeeperInOrderDetailDialog,
  selectSupportNotificationsMessagesInOrderDetailDialog,
  selectTz,
  selectSupportScore,
  selectOrderDetailTotalChargeInOrderDetailDialog,
}

export default OrdersSelector
