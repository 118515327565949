import React, { useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import useStyles from './CustomNotification.styles';
import { Button } from '@material-ui/core';
import { CustomLink } from '../custom-link';
import { useNavigate } from 'react-router-dom';
import NotificationsDrawerActions from '../../../redux/actions/notifications-drawer-actions/notificationsDrawer.actions';
import { useDispatch } from 'react-redux';
import appStore from '../../../redux/stores/app.store';
import EventActions from '../../../redux/actions/event-actions';

const NotificationLink = ({ link, id }) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    NotificationsDrawerActions.close()(dispatch);
  }, [dispatch]);

  const handleNotificationDeepLinkClick = useCallback(() => {
    EventActions.onClickNotificationLink(
      appStore.getPartnerId(),
      link.text,
      link.deep_link,
      id,
    );
    navigate(link.deep_link);
    handleClose();
  }, [navigate, link.deep_link, link.text, id, handleClose]);

  const handleNotificationLinkClick = useCallback(() => {
    EventActions.onClickNotificationLink(
      appStore.getPartnerId(),
      link.text,
      link.url,
      id,
    );
    window.open(link.url, '_blank');
    handleClose();
  }, [link.url, link.text, id, handleClose]);

  return link.url ? (
    <CustomLink
      classes={{ root: classes.link }}
      color='primary'
      onClick={handleNotificationLinkClick}
    >
      {link.text}
    </CustomLink>
  ) : (
    <CustomLink
      classes={{ root: classes.link }}
      color='primary'
      onClick={handleNotificationDeepLinkClick}
    >
      {link.text}
    </CustomLink>
  );
};

export default function CustomNotification({ notification, onSelect }) {
  const classes = useStyles();

  return (
    <Button className={classes.root} onClick={() => onSelect(notification.id)}>
      <div>
        {!notification.seen && <div className={classes.noSeen} />}
        {notification.seen && <div className={classes.seen} />}
      </div>
      <div className={classes.content}>
        <div className={classes.title}>{notification.title}</div>
        <div className={classes.text}>
          <ReactMarkdown
            renderers={{
              link: props => (
                <CustomLink color='primary' href={props.href} target='_blank'>
                  {props.children}
                </CustomLink>
              ),
            }}
          >
            {notification.text}
          </ReactMarkdown>
          <div className={classes.containerLinks}>
            {notification.links &&
              notification.links.map((link, index) => {
                return (
                  <NotificationLink
                    key={`notificationLink-${index}`}
                    link={link}
                    id={notification.id}
                  />
                );
              })}
          </div>
        </div>
        <div className={classes.since}>{notification.sinceMessage}</div>
      </div>
    </Button>
  );
}
