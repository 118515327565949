// Assets
import icon from '../assets/images/bigote.png';
import delay from '../utils/Delay';
import history from '../history';
import { formatMessage } from '../intl/intl';
// Services
import RouterService from './RouterService';

const spawnNotification = (body, title, urlToNavigate) => {
  var options = {
    body,
    icon: icon,
  };
  var n = new Notification(title, options);

  n.onclick = x => {
    if (urlToNavigate) {
      history.replace(urlToNavigate);
    }
    window.focus();
  };
};

const bulkSpawnNotification = async notifications => {
  for (const notification of notifications) {
    spawnNotification(
      notification.body,
      notification.title,
      notification.urlToNavigate,
    );
    await delay(3000);
  }
};

const spawnNewOrdersNotification = async totalNewOrders => {
  if (!totalNewOrders) {
    return;
  }

  const title =
    totalNewOrders === 1
      ? formatMessage({
          id: 'webNotification.newOrder.singleTitle',
        })
      : formatMessage(
          {
            id: 'webNotification.newOrder.pluralTitle',
          },
          { x: totalNewOrders },
        );

  const body =
    totalNewOrders === 1
      ? formatMessage({
          id: 'webNotification.newOrder.singleBody',
        })
      : formatMessage(
          {
            id: 'webNotification.newOrder.pluralBody',
          },
          { x: totalNewOrders },
        );

  const urlToNavigate = RouterService.getOrdersPath();

  await spawnNotification(body, title, urlToNavigate);
};

export default {
  spawnNewOrdersNotification,
  bulkSpawnNotification,
  spawnNotification,
};
