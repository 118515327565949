import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';

import dialogsManagerActions from '../../../../redux/actions/dialogsManager.actions';

import useStyles from './styles';

import type { BaseProps } from '../../../../interfaces';

export function ProductComment(props: Props) {
  const {
    textLabel,
    comments,
    showButton,
    buttonLabel,
    productName,
    productId,
  } = props;

  const componentClasses = useStyles();
  const dispatch = useDispatch();

  const onOpenWhimDialog = useCallback(() => {
    dispatch(dialogsManagerActions.openOrderWhimDialog(productName, productId));
  }, [dispatch, productName, productId]);

  return (
    <div className={componentClasses.commentWrapper}>
      <div className={componentClasses.notes}>{textLabel}:</div>
      <div className={componentClasses.comments}>{comments}</div>
      {showButton && (
        <Button
          className={componentClasses.addValue}
          onClick={() => {
            onOpenWhimDialog();
          }}
        >
          {buttonLabel}
        </Button>
      )}
    </div>
  );
}

interface Props extends BaseProps {
  textLabel: string;
  comments: string;
  showButton: boolean;
  buttonLabel: string;
  productName: string;
  productId: number;
}
