// Material UI
import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  root: {
    fontFamily: 'Poppins',
    fontSize: 13,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    textTransform: 'none',
  },
}))
