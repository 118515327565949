// External libs
import { createSelector } from 'reselect'

const selectSlice = (state: any) => state.startPMFlowDialog

const selectOpen = createSelector(selectSlice, reducer => reducer.open)
const selectStoreName = createSelector(
  selectSlice,
  reducer => reducer.storeName,
)
const selectFormId = createSelector(selectSlice, reducer => reducer.formId)
const selectStoreId = createSelector(selectSlice, reducer => reducer.storeId)

const selectors = {
  selectOpen,
  selectStoreName,
  selectFormId,
  selectStoreId,
}

export default selectors
