import { clsx } from 'clsx';
import { Chip } from '@material-ui/core';

import useStyles from './CustomChip.styles';

const CustomChip = ({ classes = {}, label, onDelete, icon }) => {
  const _classes = useStyles();
  return (
    <Chip
      classes={{
        root: clsx(_classes.root, classes?.root),
        label: clsx(_classes.label, classes?.label),
        icon: clsx(_classes.icon, classes?.icon),
        deleteIcon: clsx(_classes.deleteIconChip, classes?.deleteIconChip),
      }}
      label={label}
      icon={icon}
      onDelete={onDelete}
    />
  );
};

export default CustomChip;
