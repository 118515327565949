// Material UI
import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  storeStatusContainer: {
    width: '100%',
    display: 'grid',
    gap: '1.25rem',
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
  storeStatusContent: {
    width: '100%',
  },
}))
