import { useSelector } from 'react-redux';

import { selectors } from '../../redux/store-diagnostics-dialog';

const useAlertMessage = () =>
  useSelector(selectors.selectSelectedStoreDiagnosticAlertMessage);

const useDiagnostics = () =>
  useSelector(selectors.selectSelectedStoreDiagnosticRules);

const useOpen = () => useSelector(selectors.selectOpen);

const hooks = { useAlertMessage, useDiagnostics, useOpen };

export default hooks;
