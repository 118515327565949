// External libs
import { get, find, maxBy, minBy } from 'lodash';
import moment from 'moment';
// Consts
import urls from '../../constants/Urls';
// Utils
import { analitycsEvent } from '../../utils/ga-utils';
import StringUtils from '../../utils/StringUtils';
import UrlUtils from '../../utils/url-utils/UrlUtils';
// Services
import http from '../HttpService';
import LocalStorageService from '../local-storage-service';
// Interface
import {
  SuspendedStoresResponse,
  LogOutReasonsResponse,
  StoreStatusDetailResponse,
} from './interfaces';

const activatePartner = () => {
  return setStatusPartner(0, true);
};

const checkAppVersion = () => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const appVersion = process.env.REACT_APP_APP_VERSION;
  const replacedUrl = UrlUtils.replaceUrlParams(urls.CHECK_APP_VERSION, {
    appVersion,
  });
  return http.get(`${baseUrl}${replacedUrl}`);
};

const consumePartnerStateValidate = () => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const payload = {
    state: false,
  };
  return http.put(`${baseUrl}${urls.PARTNER_STATE_VALIDATE}`, payload);
};

const deactivatePartner = (deactivateMinutes: any) => {
  return setStatusPartner(deactivateMinutes, false);
};

function getProfile() {
  const baseUrl = LocalStorageService.getBaseUrl();
  return http.get(`${baseUrl}${urls.PROFILE}`);
}

const getState = () => {
  const baseUrl = LocalStorageService.getBaseUrl();
  return http.get(`${baseUrl}${urls.PARTNER_STATE}`);
};

const registerFCMToken = (token: any) => {
  const baseUrl = LocalStorageService.getBaseUrl();

  const payload = {
    device_id: LocalStorageService.getDeviceId(),
    token,
  };

  return http.post(`${baseUrl}${urls.REGISTER_FCM_TOKEN}`, payload);
};

const getSalesSummary = (storeId: number, date: any) => {
  let formatedDate;
  if (date) {
    formatedDate = moment(date).format('YYYY-MM-DD');
  }
  const baseUrl = LocalStorageService.getBaseUrl();

  const replacedUrl = UrlUtils.replaceUrlParams(urls.SALES_SUMMARY, {
    storeId,
    date: formatedDate,
  });

  return http.get(`${baseUrl}${replacedUrl}`);
};

const getSelectedStores = (stores: any[], storeIds = []) => {
  const ensuredStoreIds = storeIds.map(storeId => +storeId);
  return stores.filter(store => ensuredStoreIds.indexOf(store.id) > -1);
};

const setStatusPartner = (deactivateMinutes: any, state: any) => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const payload = {
    deactivate_minutes: deactivateMinutes,
    state,
  };
  return http.put(`${baseUrl}${urls.PARTNER_STATUS}`, payload);
};

const getStoreName = (stores: any[], storeId: number) => {
  return stores ? get(find(stores, { id: storeId }), ['name']) : null;
};

const setStatusIntegratedStores = (storeIds: any[], isEnabled: boolean) => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const payload = {
    store_ids: storeIds,
    enabled: isEnabled,
  };
  return http.put(`${baseUrl}${urls.INTEGRATED_STORE_STATUS}`, payload);
};

const setStatusIntegratedStore = (storeId: number, isEnabled: boolean) => {
  return setStatusIntegratedStores([storeId], isEnabled);
};

const getMinCookingTime = (stores: any[]) => {
  if (!stores || stores.length === 0) {
    return 5;
  }
  const min = minBy(stores, store => store.min_cooking_time).min_cooking_time;

  return min <= 0 ? 5 : min;
};
const getMaxCookingTime = (stores: any[]) => {
  if (!stores || stores.length === 0) {
    return 40;
  }
  const max = maxBy(stores, store => store.max_cooking_time).max_cooking_time;
  return max <= 0 ? 40 : max;
};

const getSupendedStores = () => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.REASONS_STORES);

  return http.get<SuspendedStoresResponse[]>(`${baseUrl}${replacedUrl}`);
};

const getLogOutReasons = () => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.LOG_OUT_REASONS);
  return http.get<LogOutReasonsResponse[]>(`${baseUrl}${replacedUrl}`);
};
const sendLogOutReason = (
  reasonId: string,
  reasonText?: string | null | undefined,
  deviceId?: string | null | undefined,
) => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.SEND_LOG_OUT_REASON);
  const payload = {
    deactivate_minutes: 0,
    reason_id: reasonId,
    device_id: deviceId,
    reason_text: reasonText,
  };
  return http.post(`${baseUrl}${replacedUrl}`, payload);
};

const getStoreStatusDetail = (partnerId: string) => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.GET_STORE_STATUS_DETAIL, {
    partnerId,
  });

  return http.get<StoreStatusDetailResponse>(`${baseUrl}${replacedUrl}`);
};

const getDeactivatedProducts = (partnerId: string) => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.GET_DEACTIVATED_PRODUCTS, {
    partnerId,
  });

  return http.get<any>(`${baseUrl}${replacedUrl}`);
};

const switchPartnerStoresStatus = ({
  storeIds,
  enabled,
  deactivateMinutes,
}: any) => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const payload = {
    ...(Number.isFinite(deactivateMinutes) && {
      deactive_minutes: deactivateMinutes,
    }),
    store_ids: [...storeIds],
    enabled,
  };
  return http.put(`${baseUrl}${urls.SWITCH_PARTNER_STORES_STATUS}`, payload);
};

const partnersService = {
  activatePartner,
  checkAppVersion,
  consumePartnerStateValidate,
  deactivatePartner,
  getMaxCookingTime,
  getMinCookingTime,
  getProfile,
  getState,
  getSalesSummary,
  getSelectedStores,
  getStoreName,
  setStatusIntegratedStores,
  setStatusIntegratedStore,
  registerFCMToken,
  getSupendedStores,
  getLogOutReasons,
  sendLogOutReason,
  getStoreStatusDetail,
  switchPartnerStoresStatus,
  getDeactivatedProducts,
};

export default partnersService;
