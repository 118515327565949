import { ReactComponent as CloseIcon } from '../../shared/assets/imgs/closeIcon.svg';
import { ReactComponent as FastIcon } from '../../shared/assets/imgs/fastDemandIcon.svg';
import { ReactComponent as HighIcon } from '../../shared/assets/imgs/highDemandIcon.svg';
import { ReactComponent as LowIcon } from '../../shared/assets/imgs/lowDemandIcon.svg';
import { ReactComponent as RegularIcon } from '../../shared/assets/imgs/regularDemandIcon.svg';
import { ReactComponent as SuperHighIcon } from '../../shared/assets/imgs/superHighDemandIcon.svg';

import {
  blue,
  green,
  neutral,
  orange,
  yellow,
} from '../../../../shared/styles/colors';

import type { MapSaturationState, OptionsByVariant } from '../models';

export const VARIANT: MapSaturationState<OptionsByVariant> = {
  deactivated: {
    icon: CloseIcon,
    color: neutral['50'],
    bgColor: neutral['10'],
  },
  low: {
    icon: LowIcon,
    color: blue.D7,
    bgColor: blue['10'],
  },
  regular: {
    icon: RegularIcon,
    color: green['60'],
    bgColor: green['10'],
  },
  high: {
    icon: HighIcon,
    color: orange.D4,
    bgColor: yellow['10'],
  },
  superHigh: {
    icon: SuperHighIcon,
    color: orange.D4,
    bgColor: yellow['10'],
  },
  fast: {
    icon: FastIcon,
    color: blue.D7,
    bgColor: blue['10'],
  },
};

export const BOX_SHADOW_OPT_1 = `0px 0.5rem 0.875rem ${neutral['20']}`;
export const BOX_SHADOW_OPT_2 =
  '0px 3px 1px -2px rgba(0,0,0,0.2),' +
  '0px 2px 2px 0px rgba(0,0,0,0.14),' +
  '0px 1px 5px 0px rgba(0,0,0,0.12)';
