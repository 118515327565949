import { Box, Typography } from '@material-ui/core';

import { useMarketplaceDetailCard } from './hooks';

import makeClasses from './MarketplaceDetailCard.style';

import type { ReactNode } from 'react';

export function MarketplaceDetailCard(props: { children: ReactNode }) {
  const { children } = props;

  const { lang } = useMarketplaceDetailCard();

  const _classes = makeClasses();

  return (
    <Box component='aside' className={_classes.card}>
      <Typography variant='h6' className={_classes.title}>
        {lang.title}
      </Typography>
      <Typography className={_classes.description}>
        {lang.description}
      </Typography>
      {children}
    </Box>
  );
}
