// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  body: {
    height: '100%',
    padding: '40px 24px 40px 24px',
    display: 'grid',
    gridGap: 40,
    gridTemplateRows: 'min-content',

    [theme.breakpoints.up('md')]: {
      padding: '40px 24px 40px 24px',
      display: 'grid',
      gridGap: 40,
    },
  },
  description: {
    fontFamily: 'Poppins',
    fontSize: '17px',
    letterSpacing: 0,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    color: neutral.D20,
    whiteSpace: 'pre-line',
  },
  headerRoot: {
    [theme.breakpoints.up('md')]: {
      padding: 12,
    },
  },
  footerRoot: {
    borderTop: `1px solid ${neutral.D10}`,
  },
  inputLabel: {
    borderRadius: 8,
    height: 56,
  },
  prefix: {
    gridArea: 'prefix',
    height: 56,
  },
  phoneInput: {
    gridArea: 'phoneInput',
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  inputBox: {
    display: 'grid',
    gridTemplateAreas: `"prefix phoneInputt"`,
    gridTemplateColumns: '110px 1fr',
    gridTemplateRows: 52,
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      height: 456,
      width: 557,
      overflowY: 'hidden',
    },
  },
}));
