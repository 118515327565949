// React
import React, { useEffect, useState } from 'react';
// Components
import CustomItemsFilterDrawer from './../common/custom-items-filter-drawer';

const OrdersHistoricalFilterDrawer = ({
  applyOrdersHistoricalFilter,
  onClearFilter,
  onClose,
  open,
  currentSelectedStoreId,
  stores,
}) => {
  const [selectedStoreId, setSelectedStoreId] = useState(
    currentSelectedStoreId,
  );

  useEffect(() => {
    setSelectedStoreId(currentSelectedStoreId);
  }, [currentSelectedStoreId]);

  const handleApplyFilter = () => {
    applyOrdersHistoricalFilter(selectedStoreId);
  };

  const handleItemsSelectionChange = selectedItems => {
    setSelectedStoreId(selectedItems[0] || 0);
  };

  return (
    <CustomItemsFilterDrawer
      items={stores}
      onApplyFilter={handleApplyFilter}
      onClearFilter={onClearFilter}
      onClose={onClose}
      onItemsSelectionChange={handleItemsSelectionChange}
      open={open}
      selectedItems={[selectedStoreId]}
      unique
    ></CustomItemsFilterDrawer>
  );
};

OrdersHistoricalFilterDrawer.defaultProps = {
  currentSelectedStoresIds: 0,
};

export default OrdersHistoricalFilterDrawer;
