// Material UI
import { makeStyles } from '@material-ui/core'
// Constant
import Colors from '../../constants/Colors'

export default makeStyles(theme => {
  return {
    root: { backgroundColor: Colors.WHITE },
  }
})
