import { makeStyles } from '@material-ui/core';

export const KanbanSuccessCardStyle = makeStyles(() => ({
  message: {
    fontSize: '0.625rem',
    fontWeight: 'bold',
    paddingRight: '1rem',
    userSelect: 'none',
    lineHeight: 1.5,
    inlineSize: '5.25rem',
  },
}));
