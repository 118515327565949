import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import { clsx } from 'clsx';

import { CustomDeactivationDatePicker } from '../custom-deactivation-date-picker';

import { CUSTOM_OPTION_INDEX, RADIO_GROUP_NAME } from '../../constants';
import useStyles from './CustomDeactivationOptionsRadioGroup.styles';

import type {
  ICustomDeactivationOption,
  ICustomDeactivationOptionsRadioGroup,
} from '../../types';

export function CustomDeactivationOptionsRadioGroup(props: Props) {
  const {
    handleDeactivationDate = () => {},
    deactivationDate = '',
    selectedOption = '',
    handleChange = () => {},
    options = [{ id: '', label: '', description: '' }],
  } = props;

  const _classes = useStyles();

  return (
    <FormControl>
      <RadioGroup
        value={selectedOption}
        name={RADIO_GROUP_NAME}
        onChange={handleChange}
        className={_classes.optionsContainer}
      >
        {options?.map((option: ICustomDeactivationOption, index: number) => {
          return (
            <FormControlLabel
              key={index}
              value={option.id}
              className={clsx(
                _classes.optionContainer,
                option.id === selectedOption && _classes.optionContainerActive,
              )}
              control={<Radio classes={{ root: _classes.radio }} />}
              label={
                <>
                  <p className={_classes.optionTitle}>{option.label}</p>
                  <p className={_classes.optionDescription}>
                    {option.description}
                  </p>
                  {index === CUSTOM_OPTION_INDEX && (
                    <CustomDeactivationDatePicker
                      deactivationDate={deactivationDate}
                      handleDeactivationDate={handleDeactivationDate}
                      handleRadioChange={handleChange}
                    />
                  )}
                </>
              }
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

type Props = ICustomDeactivationOptionsRadioGroup;
