import { useSelector } from 'react-redux';

import { selectors as OrderRejectionByStockOutDialogSelectors } from '../../../redux/order-rejection-by-stock-out-dialog';
import ordersSelector from '../../../redux/selectors/orders-selectors';
import selectors from '../selectors';

const useLabel = () => useSelector(selectors.selectLabel);

const useOpen = () =>
  useSelector(OrderRejectionByStockOutDialogSelectors.selectOpen);

const useRefuseReason = () =>
  useSelector(OrderRejectionByStockOutDialogSelectors.selectRefuseReasonEnum);

const useRefuseReasonDescription = () =>
  useSelector(
    OrderRejectionByStockOutDialogSelectors.selectRefuseReasonDescription,
  );

const useProducts = () =>
  useSelector(ordersSelector.selectProductsInOrderDetailDialog);

const useTitle = () =>
  useSelector(
    OrderRejectionByStockOutDialogSelectors.selectRefuseReasonDescription,
  );

const hooks = {
  useLabel,
  useOpen,
  useProducts,
  useTitle,
  useRefuseReason,
  useRefuseReasonDescription,
};

export default hooks;
