import { createSelector } from 'reselect';

const selectSlice: (state: any) => any = (state: any) =>
  state.deactivatedProductsDialog;

const selectOpen = createSelector<any, any, boolean>(
  selectSlice,
  reducer => reducer.open,
);

const selectors = { selectOpen };

export default selectors;
