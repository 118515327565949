import { makeStyles } from '@material-ui/core';

import { neutral } from '../../shared/styles/colors';

export default makeStyles({
  textFieldLabel: {
    color: neutral['50'],
    fontSize: '0.75rem',
    fontWeight: 'normal',
    marginTop: 0,
    transform: 'none',
  },
  textFieldInput: {
    border: `solid 1px ${neutral['20']}`,
    color: neutral['90'],
    fontSize: '1rem',
    padding: '14px 10px',
    '&::placeholder': {
      color: neutral['50'],
      opacity: 1,
    },
    '&:-ms-input-placeholder': {
      color: neutral['50'],
    },
    '&::-ms-input-placeholder': {
      color: neutral['50'],
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  inputWithLabel: {
    marginTop: '0.5rem',
  },
  textAreaRoot: {
    padding: 0,
    '& > div': {
      display: 'inline-flex',
    },
  },
});
