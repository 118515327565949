// React
import React, { useState } from 'react';
// Material UI
import CustomSwipeableDialog from '../common/custom-swipeable-dialog';
// Styles
import useStyles from './DeleteSpecialDayDialog.styles';

const DeleteSpecialDayDialog = ({
  onConfirm,
  cancel,
  classes,
  confirm,
  titleSpecialDay,
  descriptionSpecialDay,
  open,
  onClose,
}) => {
  const componentClasses = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const closeDialog = () => {
    onClose();
  };
  return (
    <CustomSwipeableDialog
      buttons={[
        {
          onClick: onClose,
          label: cancel,
        },
        {
          color: 'primary',
          isLoading: isLoading,
          label: confirm,
          onClick: async () => {
            setIsLoading(true);
            await onConfirm();
            closeDialog();
            setIsLoading(false);
          },
          variant: 'contained',
        },
      ]}
      classes={{
        dialogPaper: componentClasses.dialogPaper,
      }}
      message={descriptionSpecialDay}
      onClose={onClose}
      open={open}
      title={titleSpecialDay}
    />
  );
};

export default DeleteSpecialDayDialog;
