// React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Actions
import AppActions from './../../redux/actions/app-actions'
import SalesSummaryActions from '../../redux/actions/salesSummary.actions'
// Components
import SalesSummaryFilterDrawer from './SalesSummaryFilterDrawer'

const mapStateToProps = (
  { salesSummaryReducer, partnerReducer },
  { intl: { formatMessage } },
) => {
  const dateFilterTitle = formatMessage({
    id: 'salesSummaryFilterDrawer.selectDate',
  })

  return {
    currentFilter: salesSummaryReducer.filter,
    dateFilterTitle,
    open: salesSummaryReducer.filter.open,
    stores: partnerReducer.profile.stores,
  }
}
const mapDispatchToProps = {
  applyFilter: SalesSummaryActions.applyFilter,
  onClearFilter: SalesSummaryActions.clearFilter,
  onClose: SalesSummaryActions.closeFilterDrawer,
  openSnackBarError: AppActions.openSnackBarError,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SalesSummaryFilterDrawer),
)
