import ChromeExtensionService from '../../../../services/ChromeExtensionService';
import appStore from '../../../../redux/stores/app.store';
import {
  checkPWAInstallStatus,
  COMPONENT_TYPE,
  CONTENT_TYPE,
  DIALOG_STATE,
  DOWNLOAD_GOOGLE_CHROME,
  HELP_CENTER_URI,
} from '../domain';
import { browserHandler } from '../infra';
import { BrowserAnalytics } from '../analytics';
import LocalStorageService from '../../../../services/local-storage-service';

import type { PartnerId } from '../../../shared/domain';

export const BrowserService = {
  stateName: browserHandler().stataName,
  reducer: browserHandler().reducer,
} as const;

export function BrowserApp() {
  const { contentType, dialogState, component } = browserHandler().get();
  const {
    onOpen,
    onSeen,
    onContent,
    onClose,
    onOutside,
  } = browserHandler().dispatch();

  const _isIdle = dialogState === DIALOG_STATE.idle;
  const _isBannerClosed = dialogState === DIALOG_STATE.closed;
  const _isBannerDisplayed = component === COMPONENT_TYPE.banner;

  const partnerId = appStore.getPartnerId();
  const isOpen = dialogState === DIALOG_STATE.isOpen;
  const hasSeen = dialogState === DIALOG_STATE.seen;
  const clickOutside = dialogState === DIALOG_STATE.outside;
  const showDialog = !!contentType.length && _isIdle;
  const showBanner = !!contentType.length && _isBannerDisplayed;
  const countryCode = LocalStorageService.getCountryCode() || '';

  const setup = async () => {
    const isChromeBrowser = isChromeWithoutExclusions();
    const hasExtension = await hasChromeExtension();
    if (_isBannerClosed) {
      return;
    }
    if (!isChromeBrowser) {
      onContent(CONTENT_TYPE.browser);
    } else if (!hasExtension) {
      onContent(CONTENT_TYPE.extension);
    }
  };

  const openDialog = showCloseButton => {
    const test = showCloseButton ? 'a' : 'b';
    onOpen();
    BrowserAnalytics.whenOpenDialog(partnerId, contentType, test);
  };

  const closeDialog = () => {
    onSeen();
    BrowserAnalytics.whenClose(partnerId, COMPONENT_TYPE.dialog, contentType);
  };

  const closeOutside = () => {
    onOutside();
    BrowserAnalytics.whenCloseOutside(
      partnerId,
      COMPONENT_TYPE.dialog,
      contentType,
    );
  };

  const closeBanner = () => {
    onClose();
    BrowserAnalytics.whenClose(partnerId, COMPONENT_TYPE.banner, contentType);
  };

  const redirect2Help = () => {
    onSeen();
    BrowserAnalytics.whenNeedHelp(partnerId, component, contentType);
    return HELP_CENTER_URI;
  };

  const redirect2Install = () => {
    if (contentType === CONTENT_TYPE.browser) {
      window.open(DOWNLOAD_GOOGLE_CHROME, '_blank');
    } else if (contentType === CONTENT_TYPE.extension) {
      window.open(process.env.REACT_APP_CHROME_EXTENSION_URL, '_blank');
    }
    BrowserAnalytics.whenInstall(partnerId, component, contentType);
  };

  return {
    setup,
    showDialog,
    showBanner,
    isOpen,
    hasSeen,
    partnerId,
    countryCode,
    clickOutside,
    contentType,
    openDialog,
    closeDialog,
    closeBanner,
    closeOutside,
    redirect2Help,
    redirect2Install,
  };
}

export async function firstLoadWebApp({ partnerId }: { partnerId: PartnerId }) {
  const isInstalled = await hasChromeExtension();
  const pwaInstallStatus = await checkPWAInstallStatus();
  BrowserAnalytics.whenFirstLoadWebApp({
    partnerId,
    isInstalled,
    pwaInstallStatus,
  });
  return {
    pwaInstallStatus,
  };
}

// TODO: Move to a helper file or similar location, or into a service of infra
export function isChromeWithoutExclusions(): boolean {
  const lowerCaseUserAgent: string = navigator.userAgent.toLowerCase();
  const containsChrome = lowerCaseUserAgent.includes('chrome');
  const containsEdg = lowerCaseUserAgent.includes('edg');
  const containsOPR = lowerCaseUserAgent.includes('opr');
  return containsChrome && !containsEdg && !containsOPR;
}

// TODO: Move to a helper file or similar location, or into a service of infra
export async function hasChromeExtension(): Promise<boolean> {
  return await ChromeExtensionService.isAvailable();
}
