// External libs
import { createSelector } from 'reselect';

const selectOrderDetailDialogReducer = state => state.orderDetailDialogReducer;

const selectOrderId = createSelector(
  selectOrderDetailDialogReducer,
  orderDetailDialogReducer => orderDetailDialogReducer.orderId,
);

const OrderDetailDialogSelectors = { selectOrderId };

export default OrderDetailDialogSelectors;
