import { clsx } from 'clsx';

import CustomAvatar from '../common/custom-avatar';
import EmptyRtAvatar from '../../components/empty-rt-avatar';

function RtAvatar({ rtName, rtPhoto, classes = {} }) {
  const showEmpty = !rtName;
  return (
    <div className={clsx(classes?.root)}>
      {showEmpty && <EmptyRtAvatar />}
      {!showEmpty && (
        <CustomAvatar
          classes={{ root: clsx(classes?.avatarRoot) }}
          letter={rtName && rtName.charAt(0)}
          src={rtPhoto}
        />
      )}
    </div>
  );
}

export default RtAvatar;
