import { useSelector } from 'react-redux';

import { selectors as partnerSelectors } from '../../../redux/partner';

const useProfile = () => {
  return useSelector(partnerSelectors.selectProfile);
};

const useIsChatV2Available = () => {
  return useSelector(partnerSelectors.selectIsChatV2Available);
};

const Hooks = { useProfile, useIsChatV2Available };

export default Hooks;
