const title = {
  id: 'globals.helpCenter',
}
const bannerTitle = {
  id: 'helpCenterPage.bannerTitle',
}
const bannerSubtitle = {
  id: 'helpCenterPage.subTitle',
}
const bannerDescription = {
  id: 'helpCenterPage.description',
}
const textIds = {
  title,
  bannerTitle,
  bannerSubtitle,
  bannerDescription,
}

export default textIds
