import { makeStyles } from '@material-ui/core';

import { indigo, neutral, red } from '../../../../shared/styles/colors';

export default makeStyles({
  startIcon: {
    width: '1.25rem',
    height: '1.25rem',
    fill: indigo.D4,
  },
  btn: {
    padding: '0.5rem 0.75rem',
    fontFamily: 'inherit',
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    borderRadius: '0.5rem',
    color: neutral['100'],
    border: `1px solid ${neutral['20']}`,
    backgroundColor: neutral.white,
  },
  btnEndIcon: ({ isOpenDropdown = false }: Props) => ({
    transform: isOpenDropdown ? 'rotateZ(270deg)' : 'rotateZ(0)',
  }),
  popoverPaper: {
    maxWidth: '24rem',
    borderRadius: '0.5rem',
    border: `1px solid ${neutral['20']}`,
  },
  drawerPaper: {
    backgroundColor: neutral.white,
  },
  drawerCloseBtn: {
    margin: '0 0 1rem',
    padding: '1.125rem',
    fontSize: '1rem',
    lineHeight: 1.25,
    color: red['40'],
    backgroundColor: neutral['10'],
  },
});

interface Props {
  isOpenDropdown: boolean;
}
