// External libs
import { createReducer } from '@reduxjs/toolkit'
import { remove } from 'lodash'
// Interfaces
import { State, Day, Interval } from '../interfaces'
// Actions
import actions from '../actions'

export const initialState: State = {
  open: false,
  formId: 0,
  days: [],
  intervals: [],
  beingEditedIntervalId: null,
}

export default createReducer(initialState, {
  [actions.clearAction.type]: state => clear(state),
  [actions.closeAction.type]: state => close(state),
  [actions.cancelEditionAction.type]: (state, { payload: { intervalId } }) =>
    cancelIntervalEditing(state, intervalId),
  [actions.setScheduleAction.type]: (state, { payload: { days, intervals } }) =>
    setSchedule(state, days, intervals),
  [actions.maximizeAction.type]: state => setOpen(state, true),
  [actions.openAction.type]: (
    state,
    { payload: { formId, days, intervals } },
  ) => open(state, formId, days, intervals),
  [actions.setBeingEditedIntervalIdAction.type]: (
    state,
    { payload: { beingEditedIntervalId } },
  ) => setBeingEditedIntervalId(state, beingEditedIntervalId),
  [actions.createNewIntervalAction.type]: (state, { payload: { day } }) =>
    setNewScheduleDay(state, day),
})

const clear = (state: State) => {
  return initialState
}

const close = (state: State) => {
  state.open = false
  return state
}

const open = (
  state: State,
  formId: number = state.formId,
  days: Day[] = state.days,
  intervals: Interval[] = state.intervals,
) => {
  state.open = true
  state.formId = formId
  state.days = days
  state.intervals = intervals
  return state
}
const setNewScheduleDay = (state: State, dayCode: string) => {
  state.intervals.push({
    id: 0,
    endsTime: '23:59',
    startsTime: '00:00',
    day: dayCode,
  })
  state.beingEditedIntervalId = 0
  return state
}

const setBeingEditedIntervalId = (
  state: State,
  beingEditedIntervalId: number | null = state.beingEditedIntervalId,
) => {
  state.beingEditedIntervalId = beingEditedIntervalId
  return state
}

const setOpen = (state: State, open: boolean) => {
  state.open = open
  return state
}

const setSchedule = (
  state: State,
  days: Day[] = state.days,
  intervals: Interval[] = state.intervals,
) => {
  state.days = days
  state.intervals = intervals
  return state
}
const cancelIntervalEditing = (state: State, intervalId: number) => {
  if (intervalId === 0) {
    remove(state.intervals, interval => {
      return interval.id === 0
    })
    state.beingEditedIntervalId = null
    return state
  }
  state.beingEditedIntervalId = null
  return state
}
