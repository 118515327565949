import { useSelector } from 'react-redux';

export function BrowserSelectors() {
  const selectDialog = useSelector(
    ({ browserDialog }: any) => browserDialog.dialog,
  );
  const selectContentType = useSelector(
    ({ browserDialog }: any) => browserDialog.contentType,
  );
  const selectComponentType = useSelector(
    ({ browserDialog }: any) => browserDialog.component,
  );

  return {
    selectDialog,
    selectContentType,
    selectComponentType,
  };
}
