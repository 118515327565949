import makeClasses from './CheckIcon.style';

export function CheckIcon({ disabled }) {
  const classes = makeClasses({ disabled });

  return (
    <svg
      className={classes.icon}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='16' height='16' rx='8' fill='white' />
      <path d='M6.4 12.8467C6.05334 12.8467 5.71334 12.7067 5.46 12.4533C5.42667 12.42 5.39334 12.38 5.36 12.34L2.36 9.38666C1.87334 8.86666 1.88 8.04 2.38667 7.52666C2.90667 7.00666 3.75334 7.00666 4.27334 7.52666L6.4 9.62666L12.3067 3.72666C12.8267 3.20666 13.6733 3.20666 14.1933 3.72666C14.4467 3.98 14.5867 4.31333 14.5867 4.66666C14.5867 5.02 14.4467 5.36 14.1933 5.60666L7.43334 12.3667C7.42667 12.38 7.39334 12.4133 7.36 12.4533C7.12667 12.6867 6.82 12.8267 6.49334 12.84C6.46 12.84 6.42667 12.8467 6.4 12.8467Z' />
    </svg>
  );
}
