export const HELP_CENTER_URI =
  '/help-center/5f4ff6cc0ad31b001a424834/article/62c8b45ef6a388c38dc5bc4a';

export const DOWNLOAD_GOOGLE_CHROME =
  'https://www.google.com/intl/es-419/chrome/';

export const PWA_INSTALL_STATUS = {
  yes: 'yes',
  veryLikely: 'veryLikely',
  possibly: 'possibly',
  unlikely: 'unlikely',
  not: 'not',
} as const;

export const WEB_PLATFORM_DEVICE = {
  desktop: 'desktop',
  possiblyDesktop: 'possibly_desktop',
  possiblyMobile: 'possibly_mobile',
  mobile: 'mobile',
  undefined: 'undefined',
} as const;
