import { useFormatMessage } from '../../../../../intl/intl';

export function useHighDemandDialogTexts({ processStep, isActiveStore }) {
  const emptyId = '';
  const successApply = 'highDemand.success.apply';
  const errorApplyGeneric = 'highDemand.error.apply.generic';

  const isStep1 = processStep.storeList;
  const title = 'highDemand.title.step.' + (isStep1 ? 'list' : 'statusChange');
  const subtitle = isStep1 ? 'highDemand.subtitle' : emptyId;
  const primaryBtn =
    isStep1 || !isActiveStore ? emptyId : 'highDemand.primaryBtn';

  return {
    title: useFormatMessage({ id: title }),
    subtitle: useFormatMessage({ id: subtitle }).trim(),
    primaryBtn: useFormatMessage({ id: primaryBtn }).trim(),
    successApply: {
      title: useFormatMessage({ id: `${successApply}.title` }),
      msg: useFormatMessage({ id: `${successApply}.msg` }),
    },
    errorApply: {
      generic: {
        title: useFormatMessage({ id: `${errorApplyGeneric}.title` }),
        msg: useFormatMessage({ id: `${errorApplyGeneric}.msg` }),
      },
    },
  };
}
