import { makeStyles } from '@material-ui/core';
import backgroundImg from '../../assets/images/desenfoque.png';

export default makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100vh',
    backgroundImage: `url(${backgroundImg})`,
    backgroundRepeat: 'round',
  },
}));
