import { clsx } from 'clsx';
import { useMemo } from 'react';

import CustomCheckbox from '../../../common/custom-checkbox';

import useStyles from './styles';

export function CheckerItem(props: CheckerItemProps) {
  const {
    product,
    selectedProducts,
    selectedToppings,
    onProductSelection,
    onToppingSelection,
  } = props;

  const _classes = useStyles();
  const { groupedToppings } = product;

  const isProductChecked = useMemo(
    () => selectedProducts.indexOf(product.id) >= 0,
    [product.id, selectedProducts],
  );

  return (
    <div className={_classes.root}>
      <div className={clsx(_classes.checkboxLine, _classes.productName)}>
        <div>{product.name}</div>
        <CustomCheckbox
          onChange={() => {
            const toggledItem = toggleItem(selectedProducts, product);
            onProductSelection(toggledItem);
          }}
          checked={isProductChecked}
          value={isProductChecked}
        />
      </div>
      {!!groupedToppings && !!groupedToppings.length && (
        <div className={_classes.toppings}>
          {groupedToppings.map(
            (toppingGroup: any, toppingGroupIndex: number) => {
              const { toppings } = toppingGroup;
              return (
                <div
                  key={toppingGroupIndex}
                  className={_classes.toppingGroupContainer}
                >
                  <div className={_classes.groupTitle}>{toppingGroup.name}</div>
                  <div className={_classes.toppingsContainer}>
                    {toppings.map((topping: any, toppingIndex: number) => {
                      const isToppingChecked =
                        selectedToppings.indexOf(topping.id) >= 0;
                      return (
                        <div
                          key={`t-${toppingIndex}`}
                          className={_classes.checkboxLine}
                        >
                          <div key={`t-${toppingIndex}`}>
                            {topping.description}
                          </div>
                          <CustomCheckbox
                            classes={{
                              root: _classes.toppingCheckboxRoot,
                            }}
                            checked={isToppingChecked}
                            onChange={() => {
                              const toggledItem = toggleItem(
                                selectedToppings,
                                topping,
                              );
                              onToppingSelection(toggledItem);
                            }}
                            value={isToppingChecked}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            },
          )}
        </div>
      )}
    </div>
  );
}

function toggleItem(items: number[], item: any): number[] {
  if (items.indexOf(item.id) > -1) {
    return items.filter(i => i !== item.id);
  }
  return [...items, item.id];
}

export interface CheckerItemProps {
  product: any;
  selectedProducts: number[];
  selectedToppings: number[];
  onProductSelection: (selectedProducts: number[]) => void;
  onToppingSelection: (selectedToppings: number[]) => void;
}
