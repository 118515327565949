import { clsx } from 'clsx';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme, useMediaQuery } from '@material-ui/core';

import hooks from './hooks';
import { useFormatMessage } from '../../intl/intl';
import appStore from '../../redux/stores/app.store';
import EventActions from '../../redux/actions/event-actions/event.actions';
import { OnBoardingDialogType } from '../../constants/OnBoardingDialogType';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';
import { actions as onBoardingDialogActions } from '../../redux/onboarding-dialog';

import CustomDialog from '../common/custom-dialog';
import { Footer } from './custom-components/footer';
import { MobileHeader } from './custom-components/mobile-header';
import OnBoardingTipsBody from './components/onboarding-tips-body';
import OnBoardingTourBody from './components/onboarding-tour-body';
import OnBoardingInfoBody from './components/onboarding-info-body';

import textIds from './consts/textIds';
import useStyles from './styles';

export function OnBoardingDialog() {
  const open = hooks.useOpen();
  const step = hooks.useStep();
  const stepList = hooks.useStepsList();
  const isFirstLogin = appStore.getIsFirstLogin();
  const source = hooks.useSource();

  const currentStepIndex = hooks.useCurrentStepIndex();
  const title = step?.title ?? '';
  const description = step?.description ?? '';
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const image = hooks.useImageUrl(theme, step);

  const type = step?.type ?? OnBoardingDialogType.Info;
  const isTour = type === OnBoardingDialogType.Tour;
  const _classes = useStyles({ isFirstLogin });

  const startTextLabel = useFormatMessage(textIds.startTextLabel);
  const backTextLabel = useFormatMessage(textIds.backTextLabel);
  const nextTextLabel = useFormatMessage(textIds.nextTextLabel);
  const finishTextLabel = useFormatMessage(textIds.finishTextLabel);
  const OnBoardingDialogBody = getOnBoardingDialogBody(type);
  const dispatch = useDispatch();

  const handleBack = useCallback(() => {
    dispatch(dialogsManagerActions.back());
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  const handlePreviousStep = useCallback(() => {
    dispatch(onBoardingDialogActions.backStep());
  }, [dispatch]);

  const handleNextStep = useCallback(() => {
    dispatch(onBoardingDialogActions.nextStep());
  }, [dispatch]);

  const handleLastStep = useCallback(() => {
    EventActions.onSuccessEndOnboarding(appStore.getPartnerId(), source);
    dispatch(dialogsManagerActions.close());
  }, [dispatch, source]);

  return (
    <CustomDialog
      classes={{
        backdrop: _classes.backdrop,
        childrenContainer: _classes.childrenContainer,
        headerRoot: clsx(
          _classes.headerRoot,
          isTour && _classes.headerRootTour,
        ),
        closeButton: _classes.headerCloseBtn,
        paper: clsx(
          _classes.paper,
          isTour && _classes.paperTour,
          !isTour && _classes.paperTip,
        ),
        title: _classes.title,
      }}
      open={open}
      onClickBackButton={handleBack}
      onClose={handleClose}
      showBackButton={false}
      showCloseButton={!isFirstLogin}
      disableBackdropClick
      disableEscapeKeyDown
      disableEnforceFocus
      title={isTour ? '' : title}
    >
      {!isMdUp && !isFirstLogin && <MobileHeader onClose={handleClose} />}
      <OnBoardingDialogBody
        title={title}
        description={description}
        image={image}
      />
      <Footer
        currentStep={currentStepIndex}
        totalSteps={stepList.length}
        onPreviousStep={handlePreviousStep}
        onNextStep={handleNextStep}
        onLastStep={handleLastStep}
        startText={startTextLabel}
        backText={backTextLabel}
        nextText={nextTextLabel}
        finalText={finishTextLabel}
      />
    </CustomDialog>
  );
}

function getOnBoardingDialogBody(type: OnBoardingDialogType) {
  switch (type) {
    case OnBoardingDialogType.Info:
      return OnBoardingInfoBody;
    case OnBoardingDialogType.Tip:
      return OnBoardingTipsBody;
    case OnBoardingDialogType.Tour:
      return OnBoardingTourBody;
    default:
      return OnBoardingInfoBody;
  }
}
