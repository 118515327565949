// React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Actions
import settingsActions from '../../redux/actions/settings.actions'
// Components
import SettingsProfile from './SettingsProfile'

const mapStateToProps = (
  { partnerReducer: { kanban: partnerKanbanConfig, profile } },
  { intl: { formatMessage } },
) => {
  const title = formatMessage({
    id: 'settings.title',
  })
  const titleBox = formatMessage({
    id: 'settings.titleHeader',
  })

  return {
    profile: profile,
    title,
    titleBox,
  }
}
const mapDispatchToProps = {
  getSettings: settingsActions.getSettings,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SettingsProfile),
)
