import { clsx } from 'clsx';

import useStyles from './styles';

export function OrderInfoKanbanCardSection(props) {
  const {
    classes = {},
    onClick = () => {},
    orderId = 0,
    showBullet = false,
    storeName = '',
    orderCreationStatus = '',
  } = props;

  const _classes = useStyles({ orderCreationStatus });

  return (
    <div className={clsx(_classes.root, classes?.root)} onClick={onClick}>
      {showBullet && (
        <div className={_classes.circleContainer}>
          <div className={_classes.circle}></div>
        </div>
      )}
      <div className={_classes.orderInfoContainer}>
        <div className={_classes.orderId}>{`ID: ${orderId}`}</div>
        {!!storeName && <div className={_classes.storeName}>{storeName}</div>}
      </div>
    </div>
  );
}
