import moment from 'moment';
// Consts
import urls from '../constants/Urls';
// Utils
import UrlUtils from '../utils/url-utils/UrlUtils';
// Services
import http from './HttpService';
import LocalStorageService from './local-storage-service';
// import { storeSchedule1 } from '../testing/mocks/storeSchedulesMocks';

const createHolidaySchedule = (holiday_id, schedules, store_id) => {
  const baseUrl = LocalStorageService.getBaseUrl();

  const payload = {
    holiday_id,
    schedules,
    store_id: +store_id,
  };

  return http.post(`${baseUrl}${urls.CREATE_HOLIDAY_SCHEDULE}`, payload);
};

const createSpecialDay = (day, month, name, schedules, store_id) => {
  const baseUrl = LocalStorageService.getBaseUrl();

  const payload = {
    day,
    month,
    name,
    schedules,
    store_id: +store_id,
  };

  return http.post(`${baseUrl}${urls.CREATE_SPECIAL_DAY}`, payload);
};

const createExtendClosingHoursDay = (day, month, name, schedules, store_id) => {
  const baseUrl = LocalStorageService.getBaseUrl();

  const payload = {
    day,
    month,
    name,
    schedules,
    store_id: +store_id,
  };

  return http.post(
    `${baseUrl}${urls.CREATE_EXTEND_CLOSING_HOURS_DAY}`,
    payload,
  );
};

const createSpecialDaySchedule = (schedules, special_id, store_id) => {
  const baseUrl = LocalStorageService.getBaseUrl();

  const payload = {
    schedules,
    special_id,
    store_id: +store_id,
  };

  return http.post(`${baseUrl}${urls.CREATE_SPECIAL_DAY_SCHEDULE}`, payload);
};

const createExtendClosingHoursSchedule = (schedules, special_id, store_id) => {
  const baseUrl = LocalStorageService.getBaseUrl();

  const payload = {
    schedules,
    special_id,
    store_id: +store_id,
  };

  return http.post(
    `${baseUrl}${urls.CREATE_EXTEND_CLOSING_HOURS_SCHEDULE}`,
    payload,
  );
};

const deleteSchedule = scheduleId => {
  const microserviceUrl = LocalStorageService.getBaseUrl();

  const replacedUrl = UrlUtils.replaceUrlParams(urls.DELETE_SCHEDULE, {
    scheduleId,
  });

  return http.delete(`${microserviceUrl}${replacedUrl}`);
};

const deleteSpecialDay = selectedDayId => {
  const microserviceUrl = LocalStorageService.getBaseUrl();

  const replacedUrl = UrlUtils.replaceUrlParams(urls.DELETE_SPECIAL_DAY, {
    selectedDayId,
  });

  return http.delete(`${microserviceUrl}${replacedUrl}`);
};

const getStoreSchedules = (cityId, storeId) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();

  const replacedUrl = UrlUtils.replaceUrlParams(urls.GET_STORE_SCHEDULES, {
    cityId,
    storeId,
  });

  return http.get(`${microserviceUrl}${replacedUrl}`);
};

const getStoreSchedulesSummary = (cityId, storeId) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();

  const date = moment().format('YYYY-MM-DD');

  const replacedUrl = UrlUtils.replaceUrlParams(
    urls.GET_STORE_SCHEDULES_SUMMARY,
    {
      cityId,
      date,
      storeId,
    },
  );

  return http.get(`${microserviceUrl}${replacedUrl}`);
};

const updateSchedule = (ends_time, scheduleId, starts_time) => {
  const baseUrl = LocalStorageService.getBaseUrl();

  const payload = {
    ends_time,
    id: +scheduleId,
    starts_time,
  };

  return http.put(`${baseUrl}${urls.UPDATE_SCHEDULE}`, payload);
};

export default {
  createHolidaySchedule,
  createSpecialDay,
  createExtendClosingHoursDay,
  createSpecialDaySchedule,
  createExtendClosingHoursSchedule,
  deleteSchedule,
  deleteSpecialDay,
  getStoreSchedules,
  getStoreSchedulesSummary,
  updateSchedule,
};
