import { useAdditionalRT } from './hooks';
import { Dialog, MainButton, MarketplaceDetailCard } from './components';

import type { CargoAdditionalRTProps } from './CargoAdditionalRT.props';

export function CargoAdditionalRT(props: CargoAdditionalRTProps) {
  const {
    behavior = 'orderHistorical',
    orderId = NaN,
    storeId = NaN,
    orderStatus = '',
    deliveryMethod = 'pickup',
  } = props;

  const {
    isDisplayed,
    isEnabled,
    isLoading,
    isDialogEnabled,
    handleGetAdditionalRT,
  } = useAdditionalRT({
    behavior,
    storeId,
    orderId,
    orderStatus,
    deliveryMethod,
  });

  return (
    <>
      {!isDisplayed && null}
      {isDisplayed && (
        <>
          {behavior === 'orderHistorical' && (
            <MainButton
              fullWidth={true}
              appearance='primary'
              isLoading={isLoading}
              disabled={!isEnabled}
              onClick={handleGetAdditionalRT}
            />
          )}

          {behavior === 'detailMarketplace' && (
            <MarketplaceDetailCard>
              <MainButton
                fullWidth={false}
                appearance='secondary'
                isLoading={isLoading}
                disabled={!isEnabled}
                onClick={handleGetAdditionalRT}
              />
            </MarketplaceDetailCard>
          )}

          {isDialogEnabled && <Dialog orderId={orderId} behavior={behavior} />}
        </>
      )}
    </>
  );
}
