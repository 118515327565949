// External libs
import { createSelector } from 'reselect';
// Interfaces
import { State } from '../interfaces/state';

const selectSlice: (state: any) => State = (state: any) =>
  state.itemsDeactivationStockOutIssueDialog;

const selectOpen = createSelector<any, State, boolean>(
  selectSlice,
  reducer => reducer.open,
);

const selectOrderId = createSelector<any, State, number>(
  selectSlice,
  reducer => reducer.orderId,
);

const selectSelectedProducts = createSelector<any, State, number[]>(
  selectSlice,
  reducer => reducer.selectedProducts,
);

const selectSelectedToppings = createSelector<any, any, number[]>(
  selectSlice,
  reducer => reducer.selectedToppings,
);

const selectors = {
  selectOpen,
  selectOrderId,
  selectSelectedProducts,
  selectSelectedToppings,
};

export default selectors;
