import { CONTENT_TYPE } from '../../../../../business/common/browser/domain';
import CustomDialog from '../../../../../components/common/custom-dialog/CustomDialog';
import { useBrowser } from '../../hooks';
import { useLang } from '../../hooks/useLang';
import { BrowserBody } from './components/BrowserBody';
import { ExtensionBody } from './components/ExtensionBody';
import { dialogStyles } from './dialog.style';

export function Dialog({ showCloseButton = true }: Props) {
  const {
    dialogClose,
    isOpen,
    go2Help,
    contentType,
    redirect2Install,
  } = useBrowser(showCloseButton);
  const { btn, btnPrimaryText, dialogTitle } = useLang();
  const styles = dialogStyles();

  return (
    <CustomDialog
      classes={{
        childrenContainer: styles.body,
        headerRoot: styles.headerRoot,
        paper: styles.paper,
        footerRoot: styles.footerRoot,
        title: styles.title,
        closeButton: styles.closeButton,
      }}
      open={isOpen}
      title={dialogTitle}
      showCloseButton={showCloseButton}
      onClose={dialogClose}
      actions={[
        showCloseButton && {
          text: btn.help,
          onClick: go2Help,
          variant: 'contained',
          fullWidth: false,
          classes: { submitButton: styles.cancelButton },
        },
        {
          text: btnPrimaryText,
          onClick: redirect2Install,
          color: 'primary',
          disabled: false,
          variant: 'contained',
          fullWidth: false,
          classes: { submitButton: styles.readyButton },
        },
      ]}
    >
      {contentType === CONTENT_TYPE.browser && <BrowserBody />}
      {contentType === CONTENT_TYPE.extension && <ExtensionBody />}
    </CustomDialog>
  );
}
interface Props {
  showCloseButton?: boolean;
}
