// External libs
import { createAction } from '@reduxjs/toolkit';
import PMAutomationService from '../../../services/PM-automation-service';
import { get } from 'lodash';
// Redux
import appActions from '../../actions/app-actions/app.actions';
import dialogsManagerActions from '../../actions/dialogsManager.actions';

const closeAction = createAction('phonePMFlowDialog/close');

const clearAction = createAction('phonePMFlowDialog/clear');

const maximizeAction = createAction('phonePMFlowDialog/maximize');

const openAction = createAction<{
  formId: number;
  phone: string;
}>('phonePMFlowDialog/open');

const errorMessageAction = createAction<{ errorMessage: string }>(
  'phonePMFlowDialog/errorMessage',
);
const skipClosePmFormAction = createAction<{ formId: number }>(
  'phonePMFlowDialog/SkipClosePMForm',
);

const clear = () => (dispatch: any) => {
  dispatch(clearAction());
};

const close = () => (dispatch: any) => {
  dispatch(closeAction());
};

const maximize = () => (dispatch: any) => dispatch(maximizeAction());

const open = (formId: number, phone: string) => (dispatch: any) => {
  dispatch(openAction({ formId, phone }));
};

const sendStorePhoneNumber = (formId: number, phone: string) => async (
  dispatch: any,
) => {
  try {
    await PMAutomationService.sendPMAutomationStorePhone(formId, phone);
    dispatch(dialogsManagerActions.openEndPMFlowDialog(formId));
  } catch (ex) {
    appActions.handleCatch(ex, false)(dispatch);
    const errorCode = get(ex, 'response.data.error.code', null);
    const errorMessage = get(ex, 'response.data.error.message', null);
    if (errorCode !== 'error.pm_automation.invalid_number') {
      dispatch(dialogsManagerActions.openErrorPMFlowDialog());
    } else {
      dispatch(errorMessageAction({ errorMessage }));
    }
  }
};

const sendSkipCloseForm = (formId: number) => async (dispatch: any) => {
  try {
    await PMAutomationService.sendPMAutomationStoreClose(formId);
    dispatch(skipClosePmFormAction({ formId }));
  } catch (ex) {
    appActions.handleCatch(ex, true)(dispatch);
  }
};
const actions = {
  clear,
  clearAction,
  close,
  closeAction,
  maximize,
  maximizeAction,
  open,
  openAction,
  sendStorePhoneNumber,
  errorMessageAction,
  sendSkipCloseForm,
};

export default actions;
