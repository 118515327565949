// Material UI
import { makeStyles } from '@material-ui/core';
import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => ({
  adornedEnd: {
    paddingRight: 0,
  },
  adornedStart: {
    paddingLeft: 10,
  },
  cancelButton: {
    padding: 10,
  },
  cancelButtonIcon: {
    color: neutral.D15,
    height: 16,
    width: 16,
  },
  input: {
    fontSize: 13,
    lineHeight: 'normal',
    padding: '11px 10px 10px 10px',
  },
  searchIcon: {
    color: neutral.D15,
    minHeight: 15,
    minWidth: 15,
  },
}));
