// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  cell: {
    paddingLeft: 16,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 90,
    },
  },
  dayCell: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 24,
    },
  },
  openCell: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 60,
    },
  },
  root: {
    backgroundColor: neutral.white,
    color: neutral.D20,
    display: 'grid',
    fontFamily: 'Poppins Bold',
    fontSize: 9,
    fontWeight: 'bold',
    gridTemplateColumns: '92px 86px auto',
    gridAutoRows: 'min-content',
    letterSpacing: 0,
    paddingBottom: 10,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '200px 160px auto',
    },
  },
}));
