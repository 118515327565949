// External libs
import { get } from 'lodash';
// Consts
import { SupportSuggestionTypes } from '../../constants/SupportSuggestionsTypes';
// Interfaces
import { SupportSuggestion } from '../../interfaces';
// Actions
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';
import OrderActions from '../../redux/actions/orders-actions/orders.actions';
import { actions as noRTSupportSuggestionsAction } from '../../redux/no-rt-support-suggestions';
import supportIssueSuggestionsService from '../../services/support-issue-suggestions-service';
import appStore from '../../redux/stores/app.store';

const getAssignedRTIssueAction = (id?: string | null) => {
  switch (id) {
    case SupportSuggestionTypes.CONTACT_SUPPORT:
      return OrderActions.openRTAssignedSupportChatDrawer;
    case SupportSuggestionTypes.REPORT_RT:
      return dialogsManagerActions.openRTReportReasonsDialog;
    case SupportSuggestionTypes.RELEASE_RT:
      return dialogsManagerActions.openRTReleaseConfirmationDialog;
    default:
      return () => () => {};
  }
};

const getJustCookedOrderIssueAction = (id?: string | null) => {
  switch (id) {
    case SupportSuggestionTypes.CONTACT_SUPPORT:
      return () => () => {};
    default:
      return () => () => {};
  }
};

const getNoRTIssueAction = (id?: string | null) => {
  switch (id) {
    case SupportSuggestionTypes.NO_RT_ORDER_READY:
      return dialogsManagerActions.openCookedOrderConfirmationIssueDialog;
    case SupportSuggestionTypes.NO_RT_SUPPORT:
      return dialogsManagerActions.openRTLackIssueSuggestionsDialog;
    case SupportSuggestionTypes.NO_RT_WAIT:
      return dialogsManagerActions.openJustCookedOrderIssueDialog;
    default:
      return () => () => {};
  }
};

const getNoRTAssignedIssueAction = (id?: string | null) => {
  switch (id) {
    case SupportSuggestionTypes.CONTACT_SUPPORT:
      return OrderActions.openNoRTAssignedSupportChatDrawer;
    default:
      return () => () => {};
  }
};

const getOrderReadyConfirmationIssueAction = (id?: string | null) => {
  switch (id) {
    case SupportSuggestionTypes.CONTACT_SUPPORT:
      return OrderActions.openNoRTAssignedSupportChatDrawer;
    case SupportSuggestionTypes.ORDER_READY:
      return OrderActions.executeOrderIsReadyFromDialog;
    default:
      return () => () => {};
  }
};

const getRTLackIssueAction = (id?: string | null) => {
  switch (id) {
    case SupportSuggestionTypes.CONTACT_SUPPORT:
      return OrderActions.openNoRTAssignedSupportChatDrawer;
    default:
      return () => () => {};
  }
};

const getStockOutIssueAction = (id?: string | null) => {
  switch (id) {
    case SupportSuggestionTypes.CANCEL:
      return dialogsManagerActions.openCancelByStockOutSuggestionConfirmationDialog;
    case SupportSuggestionTypes.CONTACT_SUPPORT:
      return OrderActions.openProductNotAvailableSupportChatDrawer;
    case SupportSuggestionTypes.PROXY_SESSION:
      return dialogsManagerActions.openCustomerPhoneProxySessionDialog;
    default:
      return () => () => {};
  }
};

const getSupportReasonAction = (id?: string | null) => {
  switch (id) {
    case SupportSuggestionTypes.CONTACT_SUPPORT:
      return OrderActions.openSupportReasonsDrawerIfOn;
    case SupportSuggestionTypes.NO_PRODUCT:
      return dialogsManagerActions.openItemsDeactivationStockOutIssueDialog;
    case SupportSuggestionTypes.NO_RT:
      return executeNoRTSupportSuggestionsMiddleware;
    case SupportSuggestionTypes.WITH_RT:
      return dialogsManagerActions.openRtAssignedIssueDialog;
    default:
      return () => () => {};
  }
};

const executeNoRTSupportSuggestionsMiddleware = () => async (dispatch: any) => {
  const orderId = appStore.getOrderIdInOrderDetailDialog();
  const response = await supportIssueSuggestionsService.getNoRTIssueSuggestions(
    orderId,
  );
  const suggestionId = get(response, 'data.id', null);
  const description = get(response, 'data.description', '');
  const nextSteps: SupportSuggestion[] = get(response, 'data.next_steps', []);
  noRTSupportSuggestionsAction.set(description, orderId, nextSteps)(dispatch);

  const action = getNoRTIssueAction(suggestionId);

  action()(dispatch);
};

const supportSuggestionTranspiler = {
  getAssignedRTIssueAction,
  getJustCookedOrderIssueAction,
  getNoRTIssueAction,
  getNoRTAssignedIssueAction,
  getRTLackIssueAction,
  getStockOutIssueAction,
  getSupportReasonAction,
  getOrderReadyConfirmationIssueAction,
};

export default supportSuggestionTranspiler;
