import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ExtendClosingHoursProps, IExtendSchedule } from './types'
import { ACTIVE } from './constants'
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions'
import hooks from './hooks'
import { Box, Typography } from '@material-ui/core'
import { ExtendClosingHourList } from './components/extend-closing-hour-list'
import CustomDialog from '../common/custom-dialog'
import CustomLoader from '../common/custom-loader/CustomLoader'
import useStyles from './ExtendClosingHoursDialog.styles'
import storesSchedulesActions from '../../redux/actions/storesSchedules.actions'
import { ExtendClosingHourCheckDate } from './components/extend-closing-hour-check-date'
import { formatHolidayOrNormalDaySlotSchedules } from './utils'

export const ExtendClosingHoursDialog: React.FC<ExtendClosingHoursProps> = () => {
  const [
    extendSchedules,
    setExtendSchedules,
  ] = useState<IExtendSchedule | null>(null)
  const componentClasses = useStyles()
  const open = hooks.useOpen()
  const { selectedStoreId } = hooks.useStoresSchedulesFilter()
  const status = hooks.useStoreStatus(selectedStoreId)
  const areStoresSchedulesLoading = hooks.useAreStoresSchedulesLoading()
  const storeDaySchedules = hooks.useStoreDaySchedules()
  const dispatch = useDispatch()
  const { todaySpecialDay } = hooks.useExtendScheduleSpecialDay()
  const hasHolidayOrNormalDaySlotsSchedules = hooks.useHasHolidayOrNormalDaySlotsSchedules()
  const {
    cancelButtonLabel,
    saveButtonLabel,
    openTitleLabel,
    extendTitleLabel,
    openDisclaimerLabel,
    extendDisclaimerLabel,
  } = hooks.useExtendClosingHoursDialogTexts()

  const isSubmitDisabled =
    !extendSchedules ||
    extendSchedules?.hasStartTimeError ||
    extendSchedules?.hasEndTimeError ||
    extendSchedules?.hasNextStartTimeError ||
    extendSchedules?.hasPreviousEndTimeError

  const handleClose = useCallback(() => {
    dispatch(dialogsManagerActions.close())
  }, [dispatch])

  const handleSubmit = async () => {
    dispatch(
      todaySpecialDay?.id
        ? storesSchedulesActions.updateSpecialExtendDaySchedule(
            extendSchedules?.scheduleId,
            extendSchedules?.startTime,
            extendSchedules?.endTime,
          )
        : storesSchedulesActions.addSpecialExtendDay(
            [
              ...(hasHolidayOrNormalDaySlotsSchedules
                ? formatHolidayOrNormalDaySlotSchedules(
                    [...storeDaySchedules],
                    extendSchedules,
                  )
                : [
                    {
                      starts_time: extendSchedules?.startTime,
                      ends_time: extendSchedules?.endTime,
                    },
                  ]),
            ],
            selectedStoreId,
          ),
    )
    handleClose()
  }

  return (
    <CustomDialog
      classes={{
        childrenContainer: componentClasses.body,
        headerRoot: componentClasses.headerRoot,
        paper: componentClasses.paper,
        footerRoot: componentClasses.footerRoot,
      }}
      actions={[
        {
          text: cancelButtonLabel,
          onClick: handleClose,
          variant: 'contained',
          fullWidth: false,
          classes: { submitButton: componentClasses.cancelButton },
        },
        {
          text: saveButtonLabel,
          onClick: handleSubmit,
          color: 'primary',
          disabled: isSubmitDisabled,
          variant: 'contained',
          fullWidth: false,
          classes: { submitButton: componentClasses.submitButton },
        },
      ]}
      onClose={handleClose}
      open={open}
      showCloseButton
      title={status === ACTIVE ? extendTitleLabel : openTitleLabel}
    >
      <CustomLoader invisible open={areStoresSchedulesLoading} />
      {!areStoresSchedulesLoading && (
        <Box className={componentClasses.extendClosingHoursDialog}>
          <Typography className={componentClasses.extendClosingHoursDisclaimer}>
            {status === ACTIVE ? extendDisclaimerLabel : openDisclaimerLabel}
          </Typography>
          <ExtendClosingHourCheckDate />
          <ExtendClosingHourList
            storeDaySchedules={storeDaySchedules}
            setExtendSchedules={setExtendSchedules}
          />
        </Box>
      )}
    </CustomDialog>
  )
}
