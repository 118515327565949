export function getDiffFromNow(value: string) {
  const providedDate = new Date(value);
  const currentDate = new Date();
  const diffInMilliseconds = currentDate.getTime() - providedDate.getTime();

  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));

  return {
    min: diffInMinutes,
    sec: diffInSeconds,
    hours: diffInHours,
  };
}
