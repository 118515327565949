// Material UI
import { makeStyles } from '@material-ui/core';
// const
import Colors from '../../constants/Colors';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  body: {
    display: 'grid',
    margin: '60px 37px',
    gridGap: 12,
    gridTemplateRows: 'min-content min-content min-content',
    [theme.breakpoints.up('md')]: {
      margin: '60px 54px',
      overflow: 'hidden',
      gridGap: 0,
      gridTemplateRows: 'min-content min-content min-content',
    },
  },
  headerRoot: {},
  paper: {
    [theme.breakpoints.up('md')]: {
      height: 576,
      width: 582,
    },
  },
  icon: {
    color: neutral.D15,
  },
  root: {
    display: 'grid',
    gridTemplateAreas: `"container"
                        "..."
                        "..."
                        "containerFooterButtons"`,
    gridGap: 10,
    [theme.breakpoints.up('sm')]: {
      gridGap: 60,
    },
    [theme.breakpoints.up('md')]: {},
  },
  container: {
    gridArea: 'container',
    display: 'grid',
    gridTemplateAreas: `"img"
                        "title"
                        "description"`,
    textAlign: 'center',
    justifyItems: 'center',
    gridGap: 40,
  },
  input: {
    padding: '20px',
  },
  inputLabelOutlined: {
    transform: 'translate(20px, 20px) scale(1)',
  },
  inputLabel: {
    borderRadius: 15,
  },
  productSuggestions: {},
  productName: {
    [theme.breakpoints.up('md')]: {},
  },
  imgContainer: {
    alignSelf: 'center',
  },
  stockOutsuggestImg: {
    gridArea: 'img',
    width: 180,
    [theme.breakpoints.up('md')]: {
      width: 220,
    },
  },
  title: {
    gridArea: 'title',
    fontWeight: 'bold',
    fontSize: 16,
  },
  description: {
    gridArea: 'description',
  },
  buttonSubmit: {
    color: neutral.white,
    fontSize: 14,
  },
  rejectButton: {
    backgroundColor: neutral.white,
    color: Colors.PRIMARY_COLOR,
    borderColor: Colors.PRIMARY_COLOR,
    fontSize: 14,
  },
  containerBoxes: {},
  containerProductInfo: {},
  containerFooterButtons: {
    gridArea: 'containerFooterButtons',
    display: 'grid',
    gridTemplateAreas: `"confirmButton"
                        "rejectButton"`,
    gridGap: 20,
  },
  confirmButtonFooter: {
    gridArea: 'confirmButton',
  },
  rejectButtonFooter: {
    gridArea: 'rejectButton',
  },
}));
