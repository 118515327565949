import CustomReadOnlyHourInterval from './../custom-read-only-hour-interval';

import useStyles from './CustomReadOnlyHourIntervalList.styles';

function CustomReadOnlyHourIntervalList({ intervals = [] }) {
  const _classes = useStyles();
  return (
    <div className={_classes.root}>
      {intervals.length === 0 && <CustomReadOnlyHourInterval />}
      {intervals.length > 0 && (
        <div className={_classes.intervalsContainer}>
          {intervals.map((interval, index) => (
            <CustomReadOnlyHourInterval
              key={`intervals-${index}`}
              open={interval.starts_time}
              close={interval.ends_time}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default CustomReadOnlyHourIntervalList;
