import { useDispatch, useSelector } from 'react-redux';

import AppActions from '../../../../../redux/actions/app-actions/app.actions';
import dialogsManagerActions from '../../../../../redux/actions/dialogsManager.actions';
import orderDetailDialogActions from '../../../../../redux/actions/orderDetailDialog.actions';
import OrderActions from '../../../../../redux/actions/orders-actions/orders.actions';
import { selectors as partnerSelectors } from '../../../../../redux/partner';
import ChromeExtensionService from '../../../../../services/ChromeExtensionService';
import OrdersUtils from '../../../../../utils/OrdersUtils';
import { orderDetailApp } from '../../../application';
import {
  OrderMgmtItem,
  OrderMgmtProduct,
  orderMgmtCurrentScreen,
  orderMgmtState,
} from '../../../domain/models';
import { orderMgmtAdapter } from '../../adapters/orderMgmt.adapter';
import { OM_STATE_NAME } from '../../core/consts';
import { orderMgmtFacade } from '../../facades/order-mgmt.facade';
import { OM_ModificationState, OM_ORDER_MODIFICATION_SLICE_NAME } from './core';
import { orderModificationThunks } from './orderModification.thunks';
import { orderMgmtDialogActions } from './ordermgmt-dialog.slice';

const _getState = (s: any): OM_ModificationState =>
  s[OM_STATE_NAME][OM_ORDER_MODIFICATION_SLICE_NAME];

export function ordermgmtDialogFacade() {
  const dispatch = useDispatch();
  const { selectOrders } = orderMgmtFacade();
  const { disabledAutoTakeInOrder } = orderDetailApp();

  const selectProfile = useSelector(partnerSelectors.selectProfile);

  const selectPartnerId = selectProfile?.id;

  const selectIsOpen = useSelector((s: any) => _getState(s).isOpen);
  const selectCanSendChanges = useSelector(
    (s: any) => _getState(s).canSendChangesByTimeout,
  );

  const selectProducts = useSelector((s: any) => _getState(s).products);

  const selectCurrentScreen = useSelector(
    (s: any) => _getState(s).currentScreen,
  );

  const selectStatus = useSelector((s: any) => _getState(s).status);

  const selectErrorCode = useSelector((s: any) => _getState(s).errorCode);

  const selectHasAnyProductHadSuggestions = selectProducts.some(product =>
    Boolean(product?.suggestions),
  );

  const selectHasAnyToppingHadSuggestions = selectProducts.some(product =>
    product?.toppings?.some(topping => Boolean(topping?.suggestions)),
  );

  const selectUndoChangesDialogIsOpen = useSelector(
    (s: any) => _getState(s).undoChangesDialogIsOpen,
  );

  const selectUndoSuggestionDialogIsOpen = useSelector(
    (s: any) => _getState(s).undoSuggestionDialog.isOpen,
  );

  const selectUndoSuggestionDialogProductId = useSelector(
    (s: any) => _getState(s).undoSuggestionDialog.productId,
  );

  const selectUndoSuggestionDialogToppingId = useSelector(
    (s: any) => _getState(s).undoSuggestionDialog.toppingId,
  );

  const selectReplaceProductDialogIsOpen = useSelector(
    (s: any) => _getState(s).replaceProductDialog.isOpen,
  );

  const selectReplaceProductIdDialog = useSelector(
    (s: any) => _getState(s).replaceProductDialog.productId,
  );

  const selectOrderMgmtAcceptOrderDialogIsOpen = useSelector(
    (s: any) => _getState(s).isAcceptOrderDialogOpen,
  );

  const selectCurrentOrderState = useSelector(
    (s: any) => _getState(s).currentOrderState,
  );

  const selectIsOpenBy = useSelector((s: any) => _getState(s).isOpenBy);

  const selectEditType = useSelector((s: any) => _getState(s).editType);

  const selectAcceptRemoveChangesDialogIsOpen = useSelector(
    (s: any) => _getState(s).acceptRemoveChangesDialogIsOpen,
  );

  const onRejectionButton = async () => {
    await dispatch(OrderActions.openOrderRejectionDialog());
  };

  const onDisableOrderAutoTake = () => {
    const isAutoTakeDisabled = true;
    disabledAutoTakeInOrder(isAutoTakeDisabled);
  };

  const onConfirmUndoChangesButton = () => {
    const cleanOM = true;
    closeDialog(cleanOM);
  };

  const onOpenOrderDetail = (orderId?: number) => {
    dispatch(OrderActions.openOrderDetailDialog(orderId));
  };

  const onCloseOrderDetail = () => {
    dispatch(orderDetailDialogActions.closeOrderDetailDialog());
  };

  const closeDialog = async (
    cleanOM: boolean = false,
    openOrderDetail: boolean = true,
    newOrderSound: boolean = true,
  ) => {
    if (cleanOM) {
      onDisableOrderAutoTake();
      onCleanOrderMgmtDialog();
    }
    await dispatch(dialogsManagerActions.closeOrderManagementDialog());
    if (newOrderSound) {
      const newOrders = OrdersUtils.filterNew(selectOrders).filter(
        order => !order?.state_order_management,
      );
      if (newOrders.length > 0) ChromeExtensionService.playNewOrderAudio();
    }
    if (openOrderDetail) setTimeout(() => onOpenOrderDetail(), 500);
  };

  const onSetOrder = (detail: any) => {
    if (!!detail && !!detail?.products) {
      const payload: any = orderMgmtAdapter.toOrderMgmtDetail(detail);
      dispatch(orderMgmtDialogActions.setOrder(payload));
    }
  };

  const onViewDidLeave = () => {
    onCleanOrderMgmtDialog();
  };

  const onSetSubstitutionProductItems = (
    item: OrderMgmtItem,
    substitutionItems: Array<OrderMgmtItem>,
  ) => {
    const payload = orderMgmtAdapter.toSubstitutionProductItems(
      item,
      substitutionItems,
    );
    dispatch(orderMgmtDialogActions.setSubstitutionProductItems(payload));
  };

  const onSetSubstitutionToppingItems = (
    item: OrderMgmtItem,
    substitutionItems: Array<OrderMgmtItem>,
    itemCategoryIndex: number,
    itemCategoryDescription: string,
  ) => {
    const payload = orderMgmtAdapter.toSubstitutionToppingItems(
      item,
      substitutionItems,
      itemCategoryIndex,
      itemCategoryDescription,
    );
    dispatch(orderMgmtDialogActions.setSubstitutionToppingItems(payload));
  };

  const onOpenItemSearchDialog = () => {
    dispatch(dialogsManagerActions.openOrderManagementItemSearchDialog());
    onCloseOrderDetail();
  };

  const onDisabledSendChanges = () => {
    dispatch(orderMgmtDialogActions.disabledSendChanges());
  };

  const onSetCurrentScreen = (screen: orderMgmtCurrentScreen) => {
    dispatch(orderMgmtDialogActions.setCurrentScreen(screen));
  };

  const onUndoProductSuggestions = (productId: number) => {
    dispatch(orderMgmtDialogActions.undoProductSuggestions(productId));
  };

  const onSendProductSubstitutionItemsRequest = async (
    products: Array<OrderMgmtProduct>,
    orderId: number,
    partnerId: number,
    storeId: number,
  ) => {
    const payloadRequest = orderMgmtAdapter.toProductSubstitutionItemsRequest(
      products,
      orderId,
      partnerId,
      storeId,
    );
    await dispatch(
      orderModificationThunks.sendOrderModification(payloadRequest),
    );
    await dispatch(OrderActions.getOrders());
  };

  const onUndoToppingSuggestions = (productId: number, toppingId: number) => {
    dispatch(
      orderMgmtDialogActions.undoToppingSuggestions({ productId, toppingId }),
    );
  };

  const onCleanOrderMgmtDialog = () => {
    dispatch(orderMgmtDialogActions.clear());
  };

  const onRejectOrderModification = (message = '') => {
    dispatch(AppActions.openSnackBarError(message));
  };

  const onOpenUndoChangesDialog = () =>
    dispatch(dialogsManagerActions.openUndoChangesDialog());

  const onCloseUndoChangesDialog = () =>
    dispatch(orderMgmtDialogActions.closeUndoChangesDialog());

  const onOpenUndoSuggestionDialog = (productId: string, toppingId: string) =>
    dispatch(
      dialogsManagerActions.openUndoSuggestionDialog(productId, toppingId),
    );

  const onCloseUndoSuggestionDialog = () =>
    dispatch(orderMgmtDialogActions.closeUndoSuggestionDialog());

  const onConfirmUndoSuggestionButton = (
    productId: number,
    toppingId: number,
  ) => {
    !!toppingId
      ? onUndoToppingSuggestions(productId, toppingId)
      : onUndoProductSuggestions(productId);
    onCloseUndoSuggestionDialog();
  };

  const onCloseReplaceProductDialog = () =>
    dispatch(orderMgmtDialogActions.closeReplaceProductDialog());

  const onOpenReplaceProductDialog = (productId: string) =>
    dispatch(dialogsManagerActions.openReplaceProductDialog(productId));

  const onUncheckProductToppings = (productId: number) => {
    dispatch(orderMgmtDialogActions.uncheckProductToppings(productId));
  };

  const onOpenOrderMgmtAcceptOrderDialog = () =>
    dispatch(dialogsManagerActions.openOrderMgmtAcceptOrderDialog());

  const onCloseOrderMgmtAcceptOrderDialog = () =>
    dispatch(orderMgmtDialogActions.closeOrderMgmtAcceptOrderDialog());

  const onAcceptOrderMgmtButton = () => {};

  const onSetCurrentOrderState = (state: orderMgmtState) => {
    dispatch(orderMgmtDialogActions.setCurrentOrderState(state));
  };

  const onSetSelectProduct = (productId: number) => {
    dispatch(orderMgmtDialogActions.setSelectProduct(`${productId}`));
  };

  const onSetSelectTopping = (productId: number, toppingId: number) => {
    dispatch(
      orderMgmtDialogActions.setSelectTopping({
        productId: `${productId}`,
        toppingId: `${toppingId}`,
      }),
    );
  };

  const onSetRemoveProduct = (productId: number) => {
    dispatch(orderMgmtDialogActions.setRemoveProduct(`${productId}`));
  };

  const onUndoRemoveProductsChanges = () => {
    dispatch(orderMgmtDialogActions.undoRemoveProductsChanges());
  };

  const onDeactivateProductsAndToppingsRequest = (
    products: Array<OrderMgmtProduct>,
    storeId: number,
    orderId: number,
  ) => {
    const payloadRequest = orderMgmtAdapter.toDeactivateProductsAndToppingsRequest(
      products,
      storeId,
      orderId,
    );
    dispatch(
      orderModificationThunks.deactivateProductAndToppings(payloadRequest),
    );
  };

  const onOpenAcceptRemoveChangesDialog = () => {
    dispatch(dialogsManagerActions.openAcceptRemoveChangesDialog());
  };

  const onCloseAcceptRemoveChangesDialog = () =>
    dispatch(orderMgmtDialogActions.closeAcceptRemoveChangesDialog());

  const onRemoveProductsFromOrderRequest = async ({
    order,
    storeId,
    products,
  }) => {
    const payloadRequest = orderMgmtAdapter.toRemoveProductsFromOrderAdapter(
      order,
      storeId,
      products,
    );
    await dispatch(
      orderModificationThunks.removeProductsFromOrder({
        payload: payloadRequest,
        orderId: order.id,
      }),
    );
    await dispatch(OrderActions.getOrders());
  };

  const numberOfProductSuggestions = selectProducts.filter(
    product => !!product?.suggestions,
  ).length;

  const numberOfToppingSuggestions = selectProducts.reduce(
    (acc, product) => (
      (acc += (product?.toppings ?? []).filter(
        topping => !!topping?.suggestions,
      ).length),
      acc
    ),
    0,
  );

  const totalNumberOfItemSuggestions =
    numberOfProductSuggestions + numberOfToppingSuggestions;

  const DialogError = () => {
    dispatch(AppActions.openSnackBarError());
  };

  return {
    selectProfile,
    selectPartnerId,
    selectIsOpen,
    selectCanSendChanges,
    selectProducts,
    selectCurrentScreen,
    selectStatus,
    selectHasAnyProductHadSuggestions,
    selectHasAnyToppingHadSuggestions,
    selectUndoChangesDialogIsOpen,
    selectUndoSuggestionDialogIsOpen,
    selectUndoSuggestionDialogProductId,
    selectUndoSuggestionDialogToppingId,
    selectReplaceProductDialogIsOpen,
    selectReplaceProductIdDialog,
    selectOrderMgmtAcceptOrderDialogIsOpen,
    selectErrorCode,
    selectCurrentOrderState,
    selectIsOpenBy,
    selectEditType,
    selectAcceptRemoveChangesDialogIsOpen,
    onSetOrder,
    onRejectionButton,
    onConfirmUndoChangesButton,
    closeDialog,
    onSetSubstitutionProductItems,
    onSetSubstitutionToppingItems,
    onViewDidLeave,
    onOpenItemSearchDialog,
    onSetCurrentScreen,
    onUndoProductSuggestions,
    onSendProductSubstitutionItemsRequest,
    onUndoToppingSuggestions,
    onCleanOrderMgmtDialog,
    onRejectOrderModification,
    onOpenUndoChangesDialog,
    onCloseUndoChangesDialog,
    onOpenUndoSuggestionDialog,
    onCloseUndoSuggestionDialog,
    onConfirmUndoSuggestionButton,
    onOpenOrderDetail,
    onCloseReplaceProductDialog,
    onOpenReplaceProductDialog,
    onUncheckProductToppings,
    numberOfProductSuggestions,
    numberOfToppingSuggestions,
    totalNumberOfItemSuggestions,
    DialogError,
    onDisabledSendChanges,
    onOpenOrderMgmtAcceptOrderDialog,
    onCloseOrderMgmtAcceptOrderDialog,
    onAcceptOrderMgmtButton,
    onSetCurrentOrderState,
    onSetSelectProduct,
    onSetSelectTopping,
    onDeactivateProductsAndToppingsRequest,
    onDisableOrderAutoTake,
    onSetRemoveProduct,
    onUndoRemoveProductsChanges,
    onOpenAcceptRemoveChangesDialog,
    onCloseAcceptRemoveChangesDialog,
    onRemoveProductsFromOrderRequest,
    onCloseOrderDetail,
  };
}
