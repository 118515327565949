// External libs
import { createReducer } from '@reduxjs/toolkit'
// Interfaces
import { State } from '../interfaces'
// Actions
import actions from '../actions'

export const initialState: State = {
  open: false,
  formId: 0,
  phone: '',
  errorMessage: '',
}

export default createReducer(initialState, {
  [actions.clearAction.type]: state => clear(state),
  [actions.closeAction.type]: state => close(state),
  [actions.maximizeAction.type]: state => setOpen(state, true),
  [actions.openAction.type]: (state, { payload: { formId, phone } }) =>
    open(state, formId, phone),
  [actions.errorMessageAction.type]: (state, { payload: { errorMessage } }) =>
    setErrorMessage(state, errorMessage),
})

const clear = (state: State) => {
  return initialState
}

const close = (state: State) => {
  state.open = false
  return state
}

const open = (
  state: State,
  formId: number = state.formId,
  phone: string = state.phone,
) => {
  state.open = true
  state.formId = formId
  state.phone = phone
  return state
}
const setErrorMessage = (
  state: State,
  errorMessage: string = state.errorMessage,
) => {
  state.errorMessage = errorMessage
  return state
}

const setOpen = (state: State, open: boolean) => {
  state.open = open
  return state
}
