import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';

import { useFormatMessage } from '../../intl/intl';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';
import { ReactComponent as PadLockOpenIcon } from '../../assets/images/icons/padlockOpen.svg';

import useStyles from './RTReleaseButton.styles';

export function RTReleaseButton(props: Props) {
  const {
    orderId = NaN,
    storeId = NaN,
    classes = {},
    setBG = false,
    showCaption = false,
  } = props;

  const dispatch = useDispatch();
  const styles = useStyles({ setBG });

  const ctText = useFormatMessage({ id: 'rtReleaseButton.text' });
  const captionText = useFormatMessage({ id: 'rtReleaseButton.captionText' });

  const handleClick = () => {
    dispatch(
      dialogsManagerActions.openRTReleaseConfirmationDialog({
        orderId,
        storeId,
      }),
    );
  };

  return (
    <Button
      classes={classes}
      onClick={handleClick}
      className={styles.ctBtn}
      endIcon={<ArrowForwardIos />}
    >
      <Box className={styles.btnContent}>
        <PadLockOpenIcon className={styles.startIcon} />
        <Typography className={styles.ctText}>{ctText}</Typography>
        {showCaption && (
          <Typography className={styles.captionText}>{captionText}</Typography>
        )}
      </Box>
    </Button>
  );
}

interface Props {
  orderId: number;
  storeId: number;
  classes?: any;
  setBG?: boolean;
  showCaption?: boolean;
}
