import { createSlice } from '@reduxjs/toolkit';
import {
  handleOrderFilters,
  CancelCostStatus,
  CancelOrderStatus,
  CargoKanbanStatus,
  CargoOrder,
  CargoStatus,
  CargoStoreList,
  CargoUI,
  CoverageStatus,
  CargoOrderFilters,
  RTRequest,
} from '../../../domain';
import {
  TOTAL_RETRY_CREATE_ORDER,
  initialStateCargoUI,
  initialRTRequest,
} from '../../core/consts';
import {
  createOrder,
  getVerifationCoverage,
  getCargoStores,
  getCargoOrderList,
  getCancelCostOrder,
  cancelCargoOrder,
} from './cargo.thunks';

export interface CargoStateRedux {
  ui: CargoUI; //TODO: move to context
  status: CargoStatus;
  isDisplayed: boolean;
  coverageStatus: CoverageStatus;
  newOrder: Partial<RTRequest>;
  cancelCost: number;
  selectOrderId: number; //TODO: move to context
  stores: CargoStoreList;
  shippingCost: number;
  orders: Array<CargoOrder>;
  filteredOrders: Array<CargoOrder>;
  filters: CargoOrderFilters;
  message: string;
  kanbanStatus: CargoKanbanStatus;
  cancelCostStatus: CancelCostStatus;
  cancelOrderStatus: CancelOrderStatus;
  lastUpdate: string;
  retryCount: number;
}

export const initialState: CargoStateRedux = {
  ui: initialStateCargoUI,
  status: 'idle',
  isDisplayed: true,
  coverageStatus: '',
  newOrder: initialRTRequest,
  cancelCost: 0,
  selectOrderId: 0,
  shippingCost: 0,
  stores: [],
  filteredOrders: [],
  filters: { storeId: 0, orderId: 0 },
  orders: [],
  message: '',
  kanbanStatus: 'idle',
  cancelCostStatus: 'idle',
  cancelOrderStatus: 'idle',
  lastUpdate: '',
  retryCount: 0,
};

export const CargoSlice = createSlice({
  name: 'cargo',
  initialState,
  reducers: {
    clear: state => ({
      ...initialState,
      stores: state.stores,
      isDisplayed: state.isDisplayed,
    }),
    clearUI: state => {
      state.ui = initialStateCargoUI;
    },
    setOpenDialog: (state, { payload }) => {
      state.ui.isOpen = payload;
    },
    setStatusDialog: (state, { payload }) => {
      state.ui.status = payload;
    },
    stores: (state, { payload }) => {
      state.stores = payload;
    },
    setNewOrder: (state, { payload }: { payload: Partial<RTRequest> }) => {
      state.newOrder = { ...state.newOrder, ...payload };
    },
    clearNewOrder: state => {
      state.newOrder = { ...initialRTRequest };
    },
    selectOrderId: (state, { payload }) => {
      state.selectOrderId = payload;
    },
    cancelCost: (state, { payload }) => {
      state.cancelCost = payload;
    },
    orders: (state, { payload }) => {
      state.orders = payload;
    },
    setFilterOrders: (state, { payload }) => {
      const [filter, value] = Object.entries(payload)[0];
      state.filters[filter] = value;
      state.filteredOrders = handleOrderFilters(state.orders, state.filters);
      state.kanbanStatus =
        (state.filteredOrders || []).length === 0 ? 'empty' : 'successful';
    },
    setCancelOrderStatus: (state, { payload }) => {
      state.cancelOrderStatus = payload;
    },
    setLastUpdate: (state, { payload }) => {
      state.lastUpdate = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getCargoStores.pending, state => {
      state.status = 'isLoading';
    });
    builder.addCase(getCargoStores.fulfilled, (state, { payload }) => {
      state.status = 'storesLoaded';
      state.stores = payload;
      state.isDisplayed = true; // always display feature but blocked (RA-2677)
    });
    builder.addCase(getCargoStores.rejected, (state, { payload }) => {
      state.status = 'rejected';
      state.message = `${payload}`;
      state.isDisplayed = false;
    });
    builder.addCase(getVerifationCoverage.pending, state => {
      state.status = 'isLoading';
      state.retryCount = 0;
    });
    builder.addCase(getVerifationCoverage.fulfilled, (state, { payload }) => {
      state.status =
        payload?.status === 'exception'
          ? 'coverageRejected'
          : 'coverageVerified';
      state.coverageStatus = payload?.status;
      state.shippingCost = payload?.price;
      state.newOrder.shippingCost = payload?.price;
      state.ui = {
        ...state.ui,
        status: payload?.status === 'exception' ? 'form' : 'coverage',
      };
    });
    builder.addCase(getVerifationCoverage.rejected, (state, { payload }) => {
      state.status = 'coverageRejected';
      state.message = `${payload}`;
    });
    builder.addCase(createOrder.pending, state => {
      state.status = 'isLoading';
    });
    builder.addCase(createOrder.fulfilled, (state, { payload }) => {
      state.status = 'orderCreated';
      state.selectOrderId = payload?.orderId;
      state.ui = {
        ...state.ui,
        status: 'list',
      };
    });
    builder.addCase(createOrder.rejected, (state, { payload }) => {
      state.status = 'orderRejected';
      state.message = `${payload}`;
      state.retryCount = state.retryCount + 1;
      state.ui = {
        ...state.ui,
        status:
          state.retryCount === TOTAL_RETRY_CREATE_ORDER ? 'list' : 'coverage',
      };
    });
    builder.addCase(getCargoOrderList.pending, state => {
      state.kanbanStatus = state.orders.length ? 'refresh' : 'isLoading';
    });
    builder.addCase(getCargoOrderList.fulfilled, (state, { payload }) => {
      state.orders = payload.data;
      state.filteredOrders = handleOrderFilters([...state.orders], {
        ...state.filters,
      });
      state.kanbanStatus =
        (state.filteredOrders || []).length === 0 ? 'empty' : 'successful';
    });
    builder.addCase(getCargoOrderList.rejected, state => {
      state.kanbanStatus = 'rejected';
    });
    builder.addCase(getCancelCostOrder.pending, state => {
      state.cancelCostStatus = 'isLoading';
    });
    builder.addCase(getCancelCostOrder.fulfilled, (state, { payload }) => {
      state.cancelCost = payload;
      state.cancelCostStatus = 'successful';
    });
    builder.addCase(getCancelCostOrder.rejected, state => {
      state.cancelCostStatus = 'rejected';
    });
    builder.addCase(cancelCargoOrder.pending, state => {
      state.cancelOrderStatus = 'isLoading';
    });
    builder.addCase(cancelCargoOrder.fulfilled, (state, { payload }) => {
      state.cancelOrderStatus = 'successful';
      state.selectOrderId = payload.orderId;
      state.filteredOrders = state.filteredOrders.filter(
        order => order.id !== payload?.orderId,
      );
      state.orders = state.orders.filter(
        order => order.id !== payload?.orderId,
      );
      state.kanbanStatus = !!state.filteredOrders.length
        ? 'successful'
        : 'empty';
    });
    builder.addCase(cancelCargoOrder.rejected, state => {
      state.cancelOrderStatus = 'rejected';
    });
  },
});

export const cargoSliceName = CargoSlice.name;
export const cargoSliceReducer = CargoSlice.reducer;

export const cargoSliceActions = CargoSlice.actions;
