import { makeStyles } from '@material-ui/core';

import { neutral, teal } from '../../styles/colors';

export default makeStyles({
  container: {
    height: 'calc(1.5rem + 2px)',
  },
  label: ({ checked = false }: { checked?: boolean }) => ({
    position: 'relative',
    width: '2.5rem',
    height: 'calc(1.5rem + 2px)',
    display: 'inline-block',
    cursor: 'pointer',
    borderRadius: '1rem',
    border: `1px solid ${teal['50']}`,
    backgroundColor: checked ? teal['50'] : neutral['50'],
    transition: 'background-color 0.2s',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '2px',
      left: '2px',
      width: '1.25rem',
      height: '1.25rem',
      borderRadius: '50%',
      backgroundColor: neutral['10'],
      transition: 'transform 0.2s',
      transform: checked ? 'translateX(0.875rem)' : 'none',
    },
  }),
  checked: {
    display: 'none',
  },
});
