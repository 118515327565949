import OrdersUtils from '../../../../utils/OrdersUtils';
import {
  AnalyticsActions,
  BaseProps,
  Events,
  handOffBagsAndDrinksInputProps,
} from './core';

function whenHandOffBagsAndDrinksInput(
  { orderId }: BaseProps,
  params: handOffBagsAndDrinksInputProps,
) {
  const isOrderNew = OrdersUtils.isNew(params.partnerOrderState);
  const isOrderBeingCooking = OrdersUtils.isBeingCooking(
    params.partnerOrderState,
  );
  const orderState = isOrderNew
    ? 'to_be_accepted'
    : isOrderBeingCooking
    ? 'in_preparation'
    : 'to_deliver';
  const args = {
    [Events.handOffBagsAndDrinksInput.props.orderId]: orderId,
    [Events.handOffBagsAndDrinksInput.props.numberOfBags]: params.numberOfBags,
    [Events.handOffBagsAndDrinksInput.props.outsideDrink]: params.outsideDrink,
    [Events.handOffBagsAndDrinksInput.props.orderState]: orderState,
    [Events.handOffBagsAndDrinksInput.props.isMandatory]: params.isMandatory,
    [Events.handOffBagsAndDrinksInput.props.rtWaiting]: params.rtWaiting,
  };
  AnalyticsActions.logHandOffBagsAndDrinksInput(args);
}

export const OrderDeliveryDetailAnalytics = {
  whenHandOffBagsAndDrinksInput,
};
