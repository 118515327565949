import { secondsToMinutes } from 'date-fns';
import { useEffect, useState } from 'react';

import appStore from '../../../../redux/stores/app.store';
import DateTimeUtils from '../../../../utils/DateTimeUtils';
import { ordermgmtDialogFacade } from '../../../../business/order-mgmt';

export function useOrderProgress({ createdAt, countDownTime }: Props) {
  const zoneId = appStore.getTz();
  const currentDate = new Date(createdAt).getTime();
  const defaultRemainingTime = {
    seconds: 0,
    minutes: 0,
  };

  const getDiffSeconds = () =>
    DateTimeUtils.getDiffFromNow(currentDate, 'seconds', zoneId);
  const setWarningTime = (min = 0, sec = 0) => {
    const hasWarningTime = min === 0 && sec <= 59;
    setIsEnabledWarningTime(hasWarningTime);
  };

  const defaultDiff = countDownTime - getDiffSeconds();
  const [countdown, setCountdown] = useState(defaultDiff);
  const [percent, setPercent] = useState(0);
  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);
  const [isEnabledWarningTime, setIsEnabledWarningTime] = useState(false);

  const { onDisabledSendChanges } = ordermgmtDialogFacade();

  useEffect(() => {
    const timer = setInterval(() => {
      updateRemainingTime();
    }, 1000);
    if (countdown < 0) {
      clearInterval(timer);
      setPercent(100);
      setWarningTime();
      onDisabledSendChanges();
      return;
    }
    return () => clearInterval(timer);
  }, [countdown]);

  const updateRemainingTime = () => {
    const diff = countDownTime - getDiffSeconds();
    setCountdown(diff);
    const sec = countdown % 60;
    const min = secondsToMinutes(countdown);
    setRemainingTime(prev => ({
      ...prev,
      seconds: sec,
      minutes: min,
    }));
    setPercent(Math.trunc((getDiffSeconds() / countDownTime) * 100));
    setWarningTime(min, sec);
  };

  return {
    remainingTime,
    percent,
    isEnabledWarningTime,
  };
}
interface Props {
  createdAt: string;
  countDownTime: number;
}
