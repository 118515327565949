import { useFormatMessage } from '../../../../intl/intl'

const useText = () => {
  return {
    supportWebChatText: useFormatMessage({
      id: 'orderDetailDialogCustomerBox.supportWebChatText',
    }),
  }
}

export const hooks = {
  useText,
}
