// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { FontWeight } from '../../typography';
import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(theme => ({
  description: {
    fontSize: 12,
  },
  icon: {
    color: neutral.D20,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
    fontFamily: 'Poppins',
    fontWeight: FontWeight.Regular,
    lineHeight: 'normal',
    justifyContent: 'space-between',
    padding: '14px 16px 14px 16px',
    position: 'relative',
    border: `1px solid ${neutral.D10}`,
    textAlign: 'left',
    textTransform: 'none',
    width: '100%',
  },
}));
