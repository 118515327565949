// External libs
import { createReducer } from '@reduxjs/toolkit';
// Interfaces
import { State, Button } from '../interfaces';
// Actions
import actions from '../actions';
import { AlertSnackbar } from '../interfaces/alertSnackbar';
import { StyleRules } from '../../../components/common/custom-alert-snackbar/components/custom-base-alert-snackbar/interfaces/styleRules';

const initialState: State = {
  open: false,
  stack: [],
};

export default createReducer(initialState, {
  [actions.closeAction.type]: (state) => close(state),
  [actions.closeAllAction.type]: () => closeAll(),
  [actions.hideAction.type]: (state) => hide(state),
  [actions.openAction.type]: (
    state,
    { payload: { id, buttons, message, stylesRules } },
  ) => open(state, id, buttons, message, stylesRules),
  [actions.openCurrentAction.type]: (state) => openCurrent(state),
});

const close = (state: State) => {
  state.open = false;
  state.stack.pop();
  return state;
};

const closeAll = () => {
  return initialState;
};

const hide = (state: State) => {
  state.open = false;
  return state;
};

const open = (
  state: State,
  id: string | null,
  buttons: Button[],
  message: string | null,
  stylesRules: StyleRules,
) => {
  state.open = true;
  const alertSnackbar: AlertSnackbar = {
    id,
    buttons,
    closeButton: {},
    message,
    onClose: null,
    stylesRules,
  };
  state.stack.push(alertSnackbar);

  return state;
};

const openCurrent = (state: State) => {
  if (state.stack.length > 0) {
    state.open = true;
  }
  return state;
};
