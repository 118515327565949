import { Box, Typography } from '@material-ui/core';
import { OrderMgmtItem } from '../../../../../business/order-mgmt';
import { useOrderMgmtLang } from '../../../hooks';
import OrderMgmtCardStyle from '../OrderMgmtCard.style';
import { formatCurrency } from '../../../../../utils/CurrencyUtils';

export const SuggestionsSection = ({ itemSuggestions, units = 0 }) => {
  const componentClasses = OrderMgmtCardStyle();
  const { userCanChooseText } = useOrderMgmtLang();

  return (
    <Box
      className={componentClasses.suggestionsContainer}
      display='flex'
      flexDirection='column'
      padding='1rem'
      gridGap='0.5rem'
    >
      <Typography classes={{ root: componentClasses.suggestionDisclaimer }}>
        {userCanChooseText}
      </Typography>
      {itemSuggestions.map((item: OrderMgmtItem) => (
        <Box
          key={`s-${item.id}-${item.name}`}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box display='flex' gridGap='0.25rem'>
            {units > 1 && (
              <Typography
                classes={{ root: componentClasses.suggestionUnits }}
              >{`${units}x`}</Typography>
            )}
            <Typography classes={{ root: componentClasses.suggestionName }}>
              {item.name}
            </Typography>
          </Box>
          <Typography classes={{ root: componentClasses.suggestionPrice }}>
            {formatCurrency(item.price)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
