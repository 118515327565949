import React from 'react';
import { Box } from '@material-ui/core';
import { CardLoader } from './CardLoader';

export function ListLoader() {
  return (
    <Box
      display='flex'
      flexDirection='column'
      gridGap='1rem'
      margin='1rem 0'
      width='100%'
    >
      <CardLoader />
      <CardLoader />
      <CardLoader />
      <CardLoader />
    </Box>
  );
}
