// Material UI
import { makeStyles } from '@material-ui/core';
import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateAreas: `"intervalContainer buttonContainer"`,
    gridTemplateColumns: 'auto min-content',
    columnGap: 23,
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'initial',
    },
  },
  intervalContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr max-content 1fr',
    alignItems: 'center',
    columnGap: 8,
  },
  buttonContainer: {
    display: 'grid',
    gridArea: 'buttonContainer',
    alignSelf: 'center',
    gridTemplateAreas: `"edit delete"`,
    columnGap: 8,
  },
  divider: {
    alignSelf: 'center',
  },
  timeBox: {
    border: `1px solid ${neutral.D10} `,
    borderRadius: 8,
    padding: '10px 0px',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '15px 60px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '7px 10px',
    },
  },
  inputReadOnlyEnd: {
    gridArea: 'inputEnd',
    width: 64,
  },
  editButton: {
    gridArea: 'edit',
    width: 20,
    height: 20,
  },
  deleteButton: {
    gridArea: 'delete',
    width: 20,
    height: 20,
  },
  icon: {
    width: 20,
    height: 20,
  },
}));
