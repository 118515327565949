import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  body: {
    display: 'grid',
    gridRowGap: 24,
    gridAutoRows: 'min-content',
    height: '100%',
    paddingTop: 24,
    [theme.breakpoints.up('md')]: {
      gridAutoColumns: 'min-content auto',
      gridAutoFlow: 'column',
      gridAutoRows: 'auto',
      gridColumnGap: 24,
      gridRowGap: 0,
      padding: 24,
    },
  },
  buttonsContainer: {
    alignSelf: 'end',
    display: 'grid',
    gridRowGap: '0.5rem',
    padding: '0px 24px 24px 24px',
    [theme.breakpoints.up('md')]: {
      gridArea: '2 / 1 / 3 / 2',
      padding: 0,
    },
  },
  headerRoot: {
    [theme.breakpoints.up('md')]: {
      padding: 12,
    },
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      height: 608,
      width: 904,
      overflowY: 'hidden',
    },
  },
  RTMapRoot: {
    height: 199,
    padding: 0,
    [theme.breakpoints.up('md')]: {
      gridArea: '1 / 2 / 3 / 3',
      height: '100%',
      width: '100%',
    },
  },
  rtDetailContainer: {
    alignContent: 'space-between',
    display: 'grid',
    gridAutoFlow: 'row',
    gridAutoRows: 'min-content',
  },
  releaseRTButton: {
    width: '100%',
  },
}));
