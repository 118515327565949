import { makeStyles } from '@material-ui/styles';
// Constant
import Colors from '../../constants/Colors';
import { neutral } from '../../shared/styles/colors';

export default makeStyles({
  container: {
    height: '100%',
    overflow: 'inherit',
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '32px 24px',
    borderTop: `1px solid ${neutral.D10}`,
  },
  link: {
    color: Colors.SECONDARY_COLOR,
    textDecoration: 'none',
    fontSize: '13px',
    fontFamily: 'Poppins Bold',
    cursor: 'pointer',
  },
  deliverToUserButton: {
    fontFamily: 'Poppins',
    fontSize: '13px !important',
    fontWeight: '600',
    minWidth: '110px',
    padding: 11,
  },
});
