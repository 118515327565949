import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import CustomDialog from '../common/custom-dialog';

import hooks from './hooks/hooks';
import { useFormatMessage } from '../../intl/intl';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';

import support from '../../assets/images/support.png';
import { textIds } from './consts';
import useStyles from './styles';

export function SupportDialog() {
  const componentClasses = useStyles();
  const title = useFormatMessage(textIds.title);
  const confirmButtonText = useFormatMessage(textIds.confirmButtonText);
  const profile = hooks.useProfile();
  const description = useFormatMessage(textIds.description);
  const phone = profile?.support_phone ?? '';
  const open = hooks.useOpen();
  const dispatch = useDispatch();

  const handleBack = useCallback(() => {
    dispatch(dialogsManagerActions.back());
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  const handleConfirm = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  return (
    <CustomDialog
      classes={{
        headerRoot: componentClasses.headerRoot,
        paper: componentClasses.paper,
      }}
      actions={[
        {
          text: confirmButtonText,
          onClick: handleConfirm,
          color: 'primary',
          variant: 'contained',
          fullWidth: false,
        },
      ]}
      CustomDialogHeaderChildren={''}
      onClickBackButton={handleBack}
      onClose={handleClose}
      open={open}
      showBackButton={false}
      showCloseButton
      title={title}
    >
      <div className={componentClasses.description}>
        <p className={componentClasses.descriptionText}>
          {description}:
          <span className={componentClasses.descriptionPhone}> {phone}</span>.
        </p>
        <img alt='' className={componentClasses.descriptionImg} src={support} />
      </div>
    </CustomDialog>
  );
}
