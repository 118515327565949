import { SplitClient, SplitTreatments } from '@splitsoftware/splitio-react';
import CustomLoader from '../common/custom-loader/CustomLoader';
import appStore from '../../redux/stores/app.store';

export default function ComponentWithTreatments({
  featureName,
  attributes = {},
  componentOn,
  componentOff,
}) {
  const splitKey = appStore.getPartnerEmail();

  return (
    <SplitClient splitKey={splitKey}>
      <SplitTreatments names={[featureName]} attributes={{ ...attributes }}>
        {({ treatments, isReady }: any) => {
          const { treatment } = treatments[featureName];

          if (!isReady) return <CustomLoader invisible open={!isReady} />;

          if (treatment === 'on') return componentOn;
          if (treatment === 'off') return componentOff;
        }}
      </SplitTreatments>
    </SplitClient>
  );
}
