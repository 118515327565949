// External libs
import { createSelector } from 'reselect';

const selectDeleteOrderWhimDialogReducer = state =>
  state.deleteOrderWhimDialogReducer;

const selectOrderId = createSelector(
  selectDeleteOrderWhimDialogReducer,
  deleteOrderWhimDialogReducer => deleteOrderWhimDialogReducer.orderId,
);

const DeleteORderWhimDialogSelectors = { selectOrderId };

export default DeleteORderWhimDialogSelectors;
