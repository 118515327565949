import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import {
  IconButton,
  Slide,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import SuccessIcon from '../../icons/sucess-icon';
import FailureIcon from '../../icons/failure-icon';
import { useFormatMessage } from '../../../intl/intl';

import useStyles from './CustomSnackbar.styles';

const TransitionLeft = props => {
  return <Slide {...props} direction='left' />;
};

export const snackBarVariants = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
  NEW_UPDATE_ON_RELOAD: 'NEW_UPDATE_ON_RELOAD',
};

const variantIcon = {
  success: SuccessIcon,
  warning: WarningIcon,
  error: FailureIcon,
  info: InfoIcon,
  NEW_UPDATE_ON_RELOAD: InfoIcon,
};

const getDefaultMessage = variant => {
  let message = '';
  const mError = useFormatMessage({ id: 'customSnackbar.errorMessage' });
  const mSuccess = useFormatMessage({ id: 'customSnackbar.successMessage' });
  const mNU = useFormatMessage({
    id: 'customSnackbar.newUpdateOnReloadMessage',
  });
  switch (variant) {
    case snackBarVariants.ERROR:
      message = mError;
      break;
    case snackBarVariants.NEW_UPDATE_ON_RELOAD:
      message = mNU;
      break;
    case snackBarVariants.SUCCESS:
      message = mSuccess;
      break;
    default:
      return '';
  }
  return message;
};

function getDefaultTitle(variant) {
  let title = '';
  const tError = useFormatMessage({ id: 'customSnackbar.errorTitle' });
  const tSuccess = useFormatMessage({ id: 'customSnackbar.successTitle' });
  const tNU = useFormatMessage({ id: 'customSnackbar.newUpdateOnReloadTitle' });
  switch (variant) {
    case snackBarVariants.ERROR:
      title = tError;
      break;
    case snackBarVariants.NEW_UPDATE_ON_RELOAD:
      title = tNU;
      break;
    case snackBarVariants.SUCCESS:
      title = tSuccess;
      break;
    default:
      return '';
  }
  return title;
}

const getIconClasses = (classes, variant) => {
  let iconClasses = clsx(classes.icon);
  switch (variant) {
    case snackBarVariants.NEW_UPDATE_ON_RELOAD:
      iconClasses = clsx(classes.icon, classes.newUpdateOnReloadIcon);
      break;
    default:
      break;
  }
  return iconClasses;
};

/**
 * React component for a snackbar.
 */
function CustomSnackbar(props) {
  const {
    className,
    message,
    onClose,
    open,
    title,
    variant,
    autoHideDuration,
  } = props;
  const _classes = useStyles();

  // Get Icon component and its classes depending on variant.
  const Icon = variantIcon[variant];
  const iconClasses = getIconClasses(_classes, variant);
  const defaultMessage = getDefaultMessage(variant);
  const messageToShow = message?.trim() || defaultMessage;
  const defaultTitle = getDefaultTitle(variant);
  const titleToShow = title?.trim() || defaultTitle;

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={autoHideDuration}
      classes={{
        root: _classes.root,
      }}
      open={open}
      onClose={onClose}
      TransitionComponent={TransitionLeft}
    >
      <SnackbarContent
        className={clsx(
          _classes.content,
          _classes[variant],
          _classes.multiline,
          className,
        )}
        classes={{
          message: _classes.snackBarContentMessage,
        }}
        aria-describedby='snackbar-content'
        message={
          <div id='snackbar-content' className={_classes.messageRoot}>
            <Icon className={iconClasses} />
            <div className={_classes.textContainer}>
              {!!titleToShow && (
                <span className={_classes.title}>{titleToShow}</span>
              )}
              {!!messageToShow && (
                <span className={_classes.message}>{messageToShow}</span>
              )}
            </div>
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              classes={{ root: _classes.closeButtonRoot }}
              onClick={onClose}
            >
              <CloseIcon className={_classes.closeIcon} />
            </IconButton>
          </div>
        }
      />
    </Snackbar>
  );
}

CustomSnackbar.defaultProps = {
  classes: {},
  open: false,
  title: null,
  variant: snackBarVariants.INFO,
};

CustomSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf([
    snackBarVariants.ERROR,
    snackBarVariants.INFO,
    snackBarVariants.NEW_UPDATE_ON_RELOAD,
    snackBarVariants.WARNING,
    snackBarVariants.SUCCESS,
  ]),
};

export default CustomSnackbar;
