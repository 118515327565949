import { clsx } from 'clsx';
import { TransferWithinAStation as PickupIcon } from '@material-ui/icons';

import { CustomStateLabel } from '../common/custom-state-label';

import { useFormatMessage } from '../../intl/intl';
import { textIds } from './const';

import useStyles from './PickupLabel.styles';

export function PickupLabel({ classes, ...props }) {
  const _classes = useStyles();
  const pickUpLabelText = useFormatMessage(textIds.pickUpLabelText);
  return (
    <CustomStateLabel
      classes={{
        root: clsx(_classes.root, classes?.root),
        iconRoot: clsx(_classes.iconRoot, classes?.iconRoot),
      }}
      Icon={PickupIcon}
      {...props}
      stateMessage={pickUpLabelText}
    />
  );
}
