import { clsx } from 'clsx';
import { FormControlLabel } from '@material-ui/core';

import useStyles from './CustomCheckBoxLabel.styles';

import CustomCheckbox from '../custom-checkbox/CustomCheckbox';

export function CustomCheckBoxLabel(props) {
  const { checked, label, onChange, value, classes = {} } = props;
  const _classes = useStyles();
  return (
    <FormControlLabel
      classes={{
        root: clsx(classes?.root),
        label: clsx(_classes.label, classes?.label),
      }}
      control={
        <CustomCheckbox checked={checked} onChange={onChange} value={value} />
      }
      label={label}
    />
  );
}
