import { createSelector } from 'reselect'
import { State } from '../interfaces/state'

const selectSlice: (state: any) => State = (state: any) =>
  state.extendClosingHoursDialog

const selectOpen = createSelector<any, State, boolean>(
  selectSlice,
  reducer => reducer.open,
)

const selectors = { selectOpen }

export default selectors
