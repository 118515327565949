import { useSelector } from 'react-redux';

import { formatMessage } from '../../../../../intl/intl';
import ordersSelectors from '../../../../../redux/selectors/orders-selectors';
import selectors from '../selectors';

const useAlertText = () => useSelector(selectors.selectAlertText);

const useMaxValue = () =>
  useSelector(ordersSelectors.selectMaxCTFSConfigInOrderDetailDialog);

const useMinValue = () =>
  useSelector(ordersSelectors.selectMinCTFSConfigInOrderDetailDialog);

const useTitle = () => {
  return formatMessage({
    id:
      'orderDetailDialogCookingTimeSelectorSection.fullServiceSection.pickingTitle',
  });
};
const useFastOperationText = () => {
  return formatMessage({
    id: 'orderDetailDialogCookingTimeSelectorSection.marketplaceSection.fastOperation'
  })
  
}

const hooks = {
  useAlertText,
  useMaxValue,
  useMinValue,
  useTitle,
  useFastOperationText
};
export default hooks;
