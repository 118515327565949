import { connect } from 'react-redux';
import HistoricalQualifyNegativeDrawer from './HistoricalQualifyNegativeDrawer';
import orderActions from '../../redux/actions/orders-actions';

const mapStateToProps = ({ ordersReducer }) => {
  return {
    qualifyNegativeReasons: ordersReducer.qualifyNegativeReasons,
  };
};

const mapDispatchToProps = {
  qualifyNegativeRT: orderActions.qualifyNegativeRT,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HistoricalQualifyNegativeDrawer);
