import { RemoveToppingListDTO } from '../../infra/core/requests';
import { AllTimeoutStates, stateOMType } from '../../infra/core/responses';
import { OrderMgmtProduct, OrderMgmtTopping, orderMgmtState } from '../models';

export const getIsTimeoutState = (state: orderMgmtState): boolean =>
  AllTimeoutStates.includes(state);

export const showOrderDetailTimeout = (
  state: orderMgmtState,
  partnerOrderState: string,
): boolean =>
  AllTimeoutStates.includes(state) && partnerOrderState === 'on_hold';

export const isEnabledCountDown = (
  products: OrderMgmtProduct[],
  isCountdownComplete: boolean,
  orderState: stateOMType,
) => {
  const _productsWithSuggestions = products
    .filter(p => p.suggestions)
    .map(x => x.id);
  return (
    products.length === 1 &&
    Boolean(_productsWithSuggestions.length) &&
    !isCountdownComplete &&
    orderState !== 'timeout_rejection_toppings_rule'
  );
};

export const mapToppingsWithProduct = (
  toppings: OrderMgmtTopping[],
): RemoveToppingListDTO[] => {
  const productIdList: any = [...new Set(toppings.map(x => x.productId))];
  return productIdList.map(productId => ({
    product_id: parseInt(productId),
    topping_list: toppings
      .filter(topping => topping.productId === productId)
      .map(x => parseInt(x.id)),
  }));
};
