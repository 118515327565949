import { Box, Typography } from '@material-ui/core';

import logoRappi from '../../../../../../assets/images/logos/logo_rappi_square.png';
import { componentsStyle } from './components.style';
import { useLang } from '../../../hooks/useLang';

export const ExtensionBody = () => {
  const styles = componentsStyle();
  const { extension } = useLang();

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <img className={styles.logo} src={logoRappi} alt='logo-rappi' />
      <Typography className={styles.description}>
        {extension.description}
      </Typography>
    </Box>
  );
};
