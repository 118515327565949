import { Box, Chip, Typography } from '@material-ui/core';
import { clsx } from 'clsx';

import logoChrome from '../../../../../../assets/images/logos/logo_google_chrome.png';
import logoAliados from '../../../../../../assets/images/logos/logo_rappi_aliados.png';
import logoRappi from '../../../../../../assets/images/logos/logo_rappi_square.png';
import { componentsStyle } from './components.style';
import { useLang } from '../../../hooks';

export const BrowserBody = () => {
  const style = componentsStyle();
  const { browserDialog } = useLang();
  return (
    <Box display='flex' flexDirection='column' gridGap='1.5rem'>
      <Typography
        className={style.description}
        dangerouslySetInnerHTML={{
          __html: browserDialog.description,
        }}
      ></Typography>
      <Box display='flex' gridGap='0.5rem'>
        <Step
          img={logoChrome}
          imgStyle={style.logoChrome}
          step={1}
          highlight={browserDialog.highlight_1}
          text={browserDialog.stepText_1}
        />
        <Step
          img={logoAliados}
          step={2}
          highlight={browserDialog.highlight_2}
          text={browserDialog.stepText_2}
        />
        <Step
          img={logoRappi}
          imgStyle={style.logo}
          step={3}
          highlight={browserDialog.highlight_3}
          text={browserDialog.stepText_3}
        />
      </Box>
    </Box>
  );
};

export const Step = ({ img, imgStyle, step, highlight, text }: StepProps) => {
  const style = componentsStyle();
  return (
    <Box
      className={style.stepWrapper}
      display='flex'
      flexDirection='column'
      alignItems='center'
      padding='1rem'
      gridGap='1.25rem'
    >
      <img className={imgStyle} src={img} alt='logo-rappi' />
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        gridGap='0.5rem'
      >
        <Chip className={style.step} label={step} />
        <Typography align='center'>
          <span className={clsx(style.stepText, style.stepHighlight)}>
            {highlight}{' '}
          </span>
          <span className={style.stepText}>{text}</span>
        </Typography>
      </Box>
    </Box>
  );
};

interface StepProps {
  img: string;
  imgStyle?: string;
  step: number;
  highlight: string;
  text: string;
}
