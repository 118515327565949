import { Box, Typography } from '@material-ui/core';
import makeClasses from './TurboTag.style';

export function TurboTag(props: TurboTagProps) {
  const classes = makeClasses(props);

  return (
    <Box className={classes.container}>
      <Typography className={classes.text}>Turbo</Typography>
    </Box>
  );
}

export type TurboTagProps = {
  variant: 'header' | 'dialog';
};
