import { createSelector } from 'reselect'

const selectAppStoreReducer = (state: any) => state.appReducer

const selectLanguage = createSelector(
  selectAppStoreReducer,
  appReducer => appReducer.language,
)

export const AppStoreSelectors = { selectLanguage }
