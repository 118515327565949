import { useSelector } from 'react-redux';

import { selectors as errorPMFlowDialogSelectors } from '../../redux/error-PM-flow-dialog';

const useOpen = () => useSelector(errorPMFlowDialogSelectors.selectOpen);

const hooks = {
  useOpen,
};

export default hooks;
