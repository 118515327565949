const tryParse = (str: string) => {
  try {
    return JSON.parse(str)
  } catch (ex) {
    console.error(ex)
    return null
  }
}

const tryStringify = (obj: any) => {
  try {
    return JSON.stringify(obj)
  } catch (ex) {
    console.error(ex)
    return null
  }
}

const JSONUtils = { tryParse, tryStringify }

export default JSONUtils
