import history from '../../history';
import { createActions } from 'redux-actions';
// Consts
import Paths from '../../constants/Paths';
// Services
import menuService from '../../services/MenuService';
import appStore from '../stores/app.store';
// Mocks
import AppActions from './app-actions';
import { formatMessage } from '../../intl/intl';
import DeactivationType from '../../constants/DeactivationType';
// Actions
import EventActions from '../actions/event-actions';

const {
  applyMenuFilterAction,
  closeMenuFilterDrawerAction,
  closeToppingsMassiveDeactivationDrawerAction,
  closeNoDeactivatedToppingDrawerAction,
  getCorridorsAction,
  getMenuByStoreAction,
  getCorridorProductsAction,
  getProductToppingsAction,
  activateProductAction,
  deactivateProductAction,
  activateToppingAction,
  deactivateToppingAction,
  selectCorridorAction,
  selectProductAction,
  displayOutOfStockAction,
  hideOutOfStockAction,
  openMenuFilterDrawerAction,
  openToppingsMassiveDeactivationDrawerAction,
  openNoDeactivatedToppingDrawerAction,
  searchProductsAction,
  searchToppingsAction,
  setCorridorsAreNotLoadingAction,
  setCorridorsAreLoadingAction,
  setCorridorProductsAreNotLoadingAction,
  setCorridorProductsAreLoadingAction,
  setProductToppingsAreNotLoadingAction,
  setProductToppingsAreLoadingAction,
  setLastToppingsDeactivatedAction,
  setProductsOutOfStockAction,
  setSearchTextAction,
  setToppingsOutOfStockAction,
  syncProductsAvailabilityAction,
  syncToppingsAvailabilityAction,
} = createActions({
  APPLY_MENU_FILTER_ACTION: selectedStoreId => ({
    selectedStoreId,
  }),
  CLEAR_MENU_FILTER_ACTION: () => ({}),
  CLOSE_MENU_FILTER_DRAWER_ACTION: () => ({}),
  CLOSE_TOPPINGS_MASSIVE_DEACTIVATION_DRAWER_ACTION: () => ({}),
  CLOSE_NO_DEACTIVATED_TOPPING_DRAWER_ACTION: () => ({}),
  GET_CORRIDORS_ACTION: (corridors, storeId) => ({
    corridors,
    storeId,
  }),
  GET_CORRIDOR_PRODUCTS_ACTION: (products, storeId) => ({ products, storeId }),
  GET_MENU_BY_STORE_ACTION: (menu, storeId) => ({ menu, storeId }),
  GET_PRODUCT_TOPPINGS_ACTION: (storeId, toppings) => ({ storeId, toppings }),
  SELECT_CORRIDOR_ACTION: corridorId => ({ corridorId }),
  SELECT_PRODUCT_ACTION: productId => ({ productId }),
  ACTIVATE_PRODUCT_ACTION: (productId, storeId) => ({
    productId,
    storeId,
  }),
  DEACTIVATE_PRODUCT_ACTION: (productId, storeId) => ({
    productId,
    storeId,
  }),
  ACTIVATE_TOPPING_ACTION: (storeId, toppingCategoryId, toppingId) => ({
    storeId,
    toppingCategoryId,
    toppingId,
  }),
  DEACTIVATE_TOPPING_ACTION: (storeId, toppingId) => ({
    storeId,
    toppingId,
  }),
  DISPLAY_OUT_OF_STOCK_ACTION: () => {},
  HIDE_OUT_OF_STOCK_ACTION: () => {},
  OPEN_MENU_FILTER_DRAWER_ACTION: () => ({}),
  OPEN_TOPPINGS_MASSIVE_DEACTIVATION_DRAWER_ACTION: () => ({}),
  OPEN_NO_DEACTIVATED_TOPPING_DRAWER_ACTION: (
    noDeactivatedToppingIds,
    storeId,
  ) => ({
    noDeactivatedToppingIds,
    storeId,
  }),
  SEARCH_PRODUCTS_ACTION: () => ({}),
  SEARCH_TOPPINGS_ACTION: () => ({}),
  SET_CORRIDORS_ARE_NOT_LOADING_ACTION: () => ({}),
  SET_CORRIDORS_ARE_LOADING_ACTION: () => ({}),
  SET_CORRIDOR_PRODUCTS_ARE_NOT_LOADING_ACTION: () => ({}),
  SET_CORRIDOR_PRODUCTS_ARE_LOADING_ACTION: () => ({}),
  SET_PRODUCT_TOPPINGS_ARE_NOT_LOADING_ACTION: () => ({}),
  SET_PRODUCT_TOPPINGS_ARE_LOADING_ACTION: () => ({}),
  SET_SEARCH_TEXT_ACTION: text => ({ text }),
  SET_LAST_TOPPINGS_DEACTIVATED_ACTION: (
    storeId,
    toppingsId,
    deactivationType,
    deactivationDate,
  ) => ({
    storeId,
    toppingsId,
    deactivationType,
    deactivationDate,
  }),
  SET_PRODUCTS_OUT_OF_STOCK_ACTION: productsOutOfStock => ({
    productsOutOfStock,
  }),
  SET_TOPPINGS_OUT_OF_STOCK_ACTION: toppingsOutOfStock => ({
    toppingsOutOfStock,
  }),
  SYNC_PRODUCTS_AVAILABILITY_ACTION: storeId => ({ storeId }),
  SYNC_TOPPINGS_AVAILABILITY_ACTION: storeId => ({ storeId }),
});

const deactivateToppings = (
  storeId,
  toppings,
  deactivationType,
  deactivationDate,
) => async dispatch => {
  try {
    const toppingIds = toppings.map(t => t.id);
    const response = await menuService.deactivateToppings(
      storeId,
      toppingIds,
      deactivationType,
      deactivationDate,
    );
    EventActions.onSuccessfulMassiveDeactivationToppings(
      storeId,
      toppingIds.length,
    );
    await getCorridors(storeId)(dispatch);
    closeToppingsMassiveDeactivationDrawer()(dispatch);
    if (!response.data) {
      return;
    }

    const sendedToppings = response.data;

    let nonDeactivatedToppings = sendedToppings.filter(st => st.code !== 'OK');

    if (nonDeactivatedToppings.length === 0) {
      return;
    }

    nonDeactivatedToppings = nonDeactivatedToppings.map(t => t.topping_id);

    openNoDeactivatedToppingDrawer(nonDeactivatedToppings, storeId)(dispatch);
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
  }
};

const getCorridors = storeId => async dispatch => {
  try {
    storeId = storeId || appStore.getMenuSelectedStoreId();

    setCorridorsAreLoading()(dispatch);
    const response = await menuService.getCorridors(storeId);

    const corridors = response.data.corridors;

    dispatch(getCorridorsAction(corridors, storeId));

    dispatch(setProductsOutOfStockAction(response.data.product_out_of_stock));

    dispatch(setToppingsOutOfStockAction(response.data.topping_out_of_stock));
    syncProductsAvailability(storeId)(dispatch);
    syncToppingsAvailability(storeId)(dispatch);

    setCorridorsAreNotLoading()(dispatch);
  } catch (ex) {
    setCorridorsAreNotLoading()(dispatch);
    await AppActions.handleCatch(ex)(dispatch);
  }
};

const getMenuByStore = storeId => async dispatch => {
  const wasMenuCompletelyDownloaded = appStore.getIfMenuWasCompletelyDownloaded(
    storeId,
  );

  if (wasMenuCompletelyDownloaded) {
    // If the store's menu was completely downloaded, don't download it again.
    return true;
  }

  try {
    const response = await menuService.getMenuByStore(storeId);
    if (!response || !response.data || !response.data.corridors) {
      return false;
    }
    dispatch(getMenuByStoreAction(response.data, storeId));
    return true;
  } catch (ex) {
    AppActions.handleCatch(ex)(dispatch);
    return false;
  }
};

const getCorridorProducts = corridorId => async dispatch => {
  try {
    setCorridorProductsAreLoading()(dispatch);
    const filter = appStore.getMenuFilter();
    const storeId = filter.selectedStoreId;
    const response = await menuService.getCorridorProducts(storeId, corridorId);
    dispatch(getCorridorProductsAction(response.data, storeId));
    setCorridorProductsAreNotLoading()(dispatch);
  } catch (ex) {
    setCorridorProductsAreNotLoading()(dispatch);
    await AppActions.handleCatch(ex)(dispatch);
  }
};

const getProductToppings = productId => async dispatch => {
  try {
    setProductToppingsAreLoading()(dispatch);
    const filter = appStore.getMenuFilter();
    const storeId = filter.selectedStoreId;
    const response = await menuService.getProductToppings(storeId, productId);
    dispatch(getProductToppingsAction(storeId, response.data));
    setProductToppingsAreNotLoading()(dispatch);
  } catch (ex) {
    setProductToppingsAreNotLoading()(dispatch);
    await AppActions.handleCatch(ex)(dispatch);
  }
};

const selectCorridor = corridorId => async dispatch => {
  dispatch(selectCorridorAction(corridorId));
  if (corridorId) await getCorridorProducts(corridorId)(dispatch);
  dispatch(selectCorridorAction(corridorId));
  await selectProduct(null)(dispatch);
  hideOutOfStock()(dispatch);
};

const selectProduct = productId => async dispatch => {
  dispatch(selectProductAction(productId));
  if (productId) await getProductToppings(productId)(dispatch);
  dispatch(selectProductAction(productId));
};

const activateProduct = productId => async dispatch => {
  try {
    const filter = appStore.getMenuFilter();
    const storeId = filter.selectedStoreId;
    await menuService.activateProduct(storeId, productId);
    dispatch(activateProductAction(productId, storeId));
    await getCorridors()(dispatch);
    const activateProduct = formatMessage({
      id: 'productActivationTypes.activateProduct',
    });
    const activateProductDescription = formatMessage({
      id: 'productActivationTypes.activateProductDescription',
    });
    AppActions.openSnackBarSuccess(
      activateProductDescription,
      activateProduct,
    )(dispatch);
  } catch (ex) {
    AppActions.handleCatch(ex)(dispatch);
  }
};

const deactivateProduct = (
  productId,
  selectedDeactivationType,
  deactivationDate = null,
) => async dispatch => {
  try {
    const filter = appStore.getMenuFilter();
    const storeId = filter.selectedStoreId;
    await menuService.deactivateProduct(
      storeId,
      productId,
      selectedDeactivationType,
      deactivationDate,
    );
    dispatch(deactivateProductAction(productId, storeId));
    await getCorridors()(dispatch);
    showProductDeactivationSuccessSnackBar(selectedDeactivationType)(dispatch);
  } catch (ex) {
    AppActions.handleCatch(ex)(dispatch);
  }
};
const showProductDeactivationSuccessSnackBar = selectedDeactivationType => dispatch => {
  const deactivateProduct = formatMessage({
    id: 'productDeactivationTypes.deactivateProduct',
  });
  let textId = 'productDeactivationTypes.deactivateDescriptionPermanent';
  switch (selectedDeactivationType) {
    case DeactivationType.DAILY:
      textId = 'productDeactivationTypes.deactivateDescriptionDaily';
      break;
    case DeactivationType.WEEKLY:
      textId = 'productDeactivationTypes.deactivateDescriptionWeekly';
      break;
    case DeactivationType.PERMANENT:
    default:
      break;
  }
  let message = formatMessage({ id: textId });
  AppActions.openSnackBarSuccess(message, deactivateProduct)(dispatch);
};
const setProductAvailability = (
  productId,
  availability,
  deactivationType = '',
  deactivationDate = '',
) => async dispatch => {
  if (!availability) EventActions.onChangeTurnoffProduct({ deactivationType });
  try {
    availability
      ? await activateProduct(productId)(dispatch)
      : await deactivateProduct(
          productId,
          deactivationType,
          deactivationDate,
        )(dispatch);
  } catch (ex) {
    AppActions.handleCatch(ex)(dispatch);
  }
};

const activateTopping = (toppingCategoryId, toppingId) => async dispatch => {
  try {
    const filter = appStore.getMenuFilter();
    const storeId = filter.selectedStoreId;
    await menuService.activateTopping(storeId, toppingId);
    dispatch(activateToppingAction(storeId, toppingCategoryId, toppingId));
    await getCorridors()(dispatch);
    const activateTopping = formatMessage({
      id: 'toppingActivationTypes.activateTopping',
    });
    const activateDescription = formatMessage({
      id: 'toppingActivationTypes.activateToppingDescription',
    });
    AppActions.openSnackBarSuccess(
      activateDescription,
      activateTopping,
    )(dispatch);
  } catch (ex) {
    AppActions.handleCatch(ex)(dispatch);
  }
};

const applyMenuFilter = selectedStoreId => async dispatch => {
  if (!selectedStoreId) {
    return;
  }
  dispatch(applyMenuFilterAction(selectedStoreId));

  await getCorridors()(dispatch);
};

const clearMenuFilter = () => dispatch => {
  closeMenuFilterDrawer()(dispatch);
  history.push(`${Paths.MENU}`);
};

const closeMenuFilterDrawer = () => dispatch => {
  return dispatch(closeMenuFilterDrawerAction());
};

const closeToppingsMassiveDeactivationDrawer = () => dispatch => {
  return dispatch(closeToppingsMassiveDeactivationDrawerAction());
};
const openToppingsMassiveDeactivationDrawer = () => dispatch => {
  return dispatch(openToppingsMassiveDeactivationDrawerAction());
};

const closeNoDeactivatedToppingDrawer = () => dispatch => {
  return dispatch(closeNoDeactivatedToppingDrawerAction());
};

const openNoDeactivatedToppingDrawer = (
  noDeactivatedToppingIds,
  storeId,
) => dispatch => {
  return dispatch(
    openNoDeactivatedToppingDrawerAction(noDeactivatedToppingIds, storeId),
  );
};

const deactivateTopping = (
  toppingCategoryId,
  toppingId,
  deactivationType,
  deactivationDate,
) => async dispatch => {
  try {
    // Get the current filter.
    const filter = appStore.getMenuFilter();
    const storeId = filter.selectedStoreId;
    await menuService.deactivateTopping(
      storeId,
      toppingId,
      deactivationType,
      deactivationDate,
    );
    dispatch(deactivateToppingAction(storeId, toppingId));
    await getCorridors()(dispatch);
    const deactivateTopping = formatMessage({
      id: 'toppingDeactivationTypes.deactivateTopping',
    });
    const deactivateDescription = formatMessage({
      id: 'toppingDeactivationTypes.deactivateToppingDescription',
    });
    AppActions.openSnackBarSuccess(
      deactivateDescription,
      deactivateTopping,
    )(dispatch);

    await setLastToppingsDeactivated(
      storeId,
      [toppingId],
      deactivationType,
      deactivationDate,
    )(dispatch);
    showToppingDeactivationSuccessSnackBar(deactivationType)(dispatch);
  } catch (ex) {
    AppActions.handleCatch(ex)(dispatch);
  }
};
const showToppingDeactivationSuccessSnackBar = selectedDeactivationType => dispatch => {
  const deactivateProduct = formatMessage({
    id: 'toppingDeactivationTypes.deactivateTopping',
  });
  let textId = 'toppingDeactivationTypes.deactivateDescriptionPermanent';
  switch (selectedDeactivationType) {
    case DeactivationType.DAILY:
      textId = 'toppingDeactivationTypes.deactivateDescriptionDaily';
      break;
    case DeactivationType.WEEKLY:
      textId = 'toppingDeactivationTypes.deactivateDescriptionWeekly';
      break;
    case DeactivationType.PERMANENT:
    default:
      break;
  }
  let message = formatMessage({ id: textId });
  AppActions.openSnackBarSuccess(message, deactivateProduct)(dispatch);
};
const setToppingAvailability = (
  availability,
  toppingCategoryId,
  toppingId,
  deactivationType = '',
  deactivationDate = '',
) => async dispatch => {
  try {
    availability
      ? await activateTopping(toppingCategoryId, toppingId)(dispatch)
      : await deactivateTopping(
          toppingCategoryId,
          toppingId,
          deactivationType,
          deactivationDate,
        )(dispatch);
  } catch (ex) {
    AppActions.handleCatch(ex)(dispatch);
  }
};

const displayOutOfStock = () => async dispatch => {
  await selectCorridor(null)(dispatch);
  await selectProduct(null)(dispatch);
  await getCorridors()(dispatch);
  return dispatch(displayOutOfStockAction());
};

const hideOutOfStock = () => dispatch => {
  return dispatch(hideOutOfStockAction());
};

const openMenuFilterDrawer = () => dispatch => {
  return dispatch(openMenuFilterDrawerAction());
};

const redirectMenuFilter = selectedStoreId => dispatch => {
  history.push(`${Paths.getMenuQueryParamsPath(selectedStoreId)}`);
  closeMenuFilterDrawer()(dispatch);
};

const setCorridorsAreNotLoading = () => dispatch => {
  dispatch(setCorridorsAreNotLoadingAction());
};

const setCorridorsAreLoading = () => dispatch => {
  dispatch(setCorridorsAreLoadingAction());
};

const setCorridorProductsAreNotLoading = () => dispatch => {
  dispatch(setCorridorProductsAreNotLoadingAction());
};

const setCorridorProductsAreLoading = () => dispatch => {
  dispatch(setCorridorProductsAreLoadingAction());
};

const setProductToppingsAreNotLoading = () => dispatch => {
  dispatch(setProductToppingsAreNotLoadingAction());
};

const setProductToppingsAreLoading = () => dispatch => {
  dispatch(setProductToppingsAreLoadingAction());
};

const setLastToppingsDeactivated = (
  storeId,
  toppingIds,
  deactivationType,
  deactivationDate,
) => async dispatch => {
  const isPartnerIntegrated = appStore.getIfPartnerIsIntegrated();

  if (isPartnerIntegrated) {
    return;
  }

  const wasMenuCompletelyDownloaded = await getMenuByStore(storeId)(dispatch);
  if (!wasMenuCompletelyDownloaded) {
    return;
  }
  dispatch(
    setLastToppingsDeactivatedAction(
      storeId,
      toppingIds,
      deactivationType,
      deactivationDate,
    ),
  );
};

const setSearchText = text => async dispatch => {
  dispatch(setSearchTextAction(text));
  const filter = appStore.getMenuFilter();
  const storeId = filter.selectedStoreId;
  const wasMenuCompletelyDownloaded = await getMenuByStore(storeId)(dispatch);
  if (!wasMenuCompletelyDownloaded) {
    return;
  }
  dispatch(searchProductsAction());
  dispatch(searchToppingsAction());
};

const syncProductsAvailability = storeId => dispatch => {
  dispatch(syncProductsAvailabilityAction(storeId));
};

const syncToppingsAvailability = storeId => dispatch => {
  dispatch(syncToppingsAvailabilityAction(storeId));
};

export default {
  applyMenuFilter,
  applyMenuFilterAction,
  clearMenuFilter,
  closeMenuFilterDrawer,
  closeMenuFilterDrawerAction,
  closeToppingsMassiveDeactivationDrawer,
  closeToppingsMassiveDeactivationDrawerAction,
  getCorridors,
  getCorridorsAction,
  getCorridorProducts,
  getCorridorProductsAction,
  getMenuByStore,
  getMenuByStoreAction,
  getProductToppings,
  getProductToppingsAction,
  selectCorridor,
  selectCorridorAction,
  selectProduct,
  selectProductAction,
  activateProduct,
  activateProductAction,
  deactivateProduct,
  deactivateProductAction,
  activateTopping,
  activateToppingAction,
  deactivateTopping,
  deactivateToppingAction,
  deactivateToppings,
  displayOutOfStock,
  displayOutOfStockAction,
  hideOutOfStock,
  hideOutOfStockAction,
  openMenuFilterDrawer,
  openMenuFilterDrawerAction,
  openToppingsMassiveDeactivationDrawer,
  openToppingsMassiveDeactivationDrawerAction,
  closeNoDeactivatedToppingDrawer,
  closeNoDeactivatedToppingDrawerAction,
  openNoDeactivatedToppingDrawer,
  openNoDeactivatedToppingDrawerAction,
  redirectMenuFilter,
  searchProductsAction,
  searchToppingsAction,
  setCorridorsAreNotLoading,
  setCorridorsAreNotLoadingAction,
  setCorridorsAreLoading,
  setCorridorsAreLoadingAction,
  setCorridorProductsAreNotLoading,
  setCorridorProductsAreNotLoadingAction,
  setCorridorProductsAreLoading,
  setCorridorProductsAreLoadingAction,
  setProductToppingsAreNotLoading,
  setProductToppingsAreNotLoadingAction,
  setProductToppingsAreLoading,
  setProductToppingsAreLoadingAction,
  setProductAvailability,
  setLastToppingsDeactivated,
  setLastToppingsDeactivatedAction,
  setSearchText,
  setSearchTextAction,
  setToppingAvailability,
  setProductsOutOfStockAction,
  setToppingsOutOfStockAction,
  syncProductsAvailability,
  syncProductsAvailabilityAction,
  syncToppingsAvailability,
  syncToppingsAvailabilityAction,
};
