import { get } from 'lodash';

import appStore from '../../stores/app.store';
import Amplitude from '../../../tools/amplitude';

import DeactivationType from '../../../constants/DeactivationType';
import { amplitudPartnerType } from '../../../components/store-status/constants';

const onClickDynActionCTA = (type, dynId, CTAIndex) => {
  Amplitude.logClickDynActionCTA(type, dynId, CTAIndex);
};

const onCloseDynActionCTA = (type, dynId) => {
  Amplitude.logCloseDynActionCTA(type, dynId);
};

const onClickOrderTotalsRetryButton = (orderId, errorType) => {
  Amplitude.logClickOrderTotalsRetryButton(orderId, errorType);
};

const onFailedRTRelease = (orderId, storekeeperId) => {
  Amplitude.logFailedRTRelease(orderId, storekeeperId);
};

const onLogIn = loginResult => {
  Amplitude.logLogIn(loginResult);
};

const onLogOut = () => {
  Amplitude.logLogOut();
};

const onNewOrdersRawReception = rawNewOrders => {
  if (!rawNewOrders || !rawNewOrders.length) {
    return;
  }

  Amplitude.logNewOrdersRawReception(rawNewOrders);
};

const onNewOrdersToConfirmReception = (
  newOrders,
  hasExtension,
  extensionVersion,
) => {
  if (!newOrders) {
    return;
  }

  Amplitude.logNewOrdersReception(newOrders, hasExtension, extensionVersion);
};

const onOrderDelivered = (
  orderId,
  deliverMethod,
  hasExtension,
  extensionVersion,
) => {
  if (!orderId) {
    return;
  }

  let order = appStore.getOrderById(orderId);

  if (!order) {
    // if no order found in status,
    // at least try to log the order Id
    order = { id: orderId };
  }

  Amplitude.logOrderDelivered(
    order,
    deliverMethod,
    hasExtension,
    extensionVersion,
  );
};

const onClickOpenDetailFromOrderCard = orderId => {
  if (!orderId) {
    return;
  }

  let order = appStore.getOrderById(orderId);

  if (!order) {
    // if no order found in status,
    // at least try to log the order Id
    order = { id: orderId };
  }

  Amplitude.logClickOpenDetailFromOrderCard(order);
};

const onOrderTaken = (
  orderId,
  selectedCookingTime,
  hasExtension,
  extensionVersion,
) => {
  if (!orderId) {
    return;
  }

  let order = appStore.getOrderById(orderId);
  if (!order) {
    // if no order found in status,
    // at least try to log the order Id
    order = { id: orderId };
  }

  Amplitude.logOrderTaken(
    order,
    selectedCookingTime,
    hasExtension,
    extensionVersion,
  );
};

const onReadyForPickupExecution = (
  orderId,
  success,
  hasExtension,
  extensionVersion,
  triggerType,
) => {
  if (!orderId) {
    return;
  }

  let order = appStore.getOrderById(orderId);

  if (!order) {
    // if no order found in status,
    // at least try to log the order Id
    order = { id: orderId };
  }

  Amplitude.logReadyForPickup(
    order,
    success,
    hasExtension,
    extensionVersion,
    triggerType,
  );
};

const onClickShowPhoneButton = ({ isOM }) => {
  const orderDetail = appStore.getOrderDetailInOrderDetailDialog();
  const orderId = get(orderDetail, 'id', null);
  const partnerOrderState = get(orderDetail, 'partner_order_state', null);
  const orderState = get(orderDetail, 'state', null);

  Amplitude.logShowPhoneButton(orderId, partnerOrderState, orderState, isOM);
};
const onSuccessfulProxySessionCreation = ({ isOM = false } = {}) => {
  const orderId = appStore.getOrderIdInCustomerPhoneProxySessionDialog();
  Amplitude.logSuccessfulProxySessionCreation(orderId, isOM);
};
const onProxySessionInvalidPhone = () => {
  const orderId = appStore.getOrderIdInCustomerPhoneProxySessionDialog();
  Amplitude.logProxySessionInvalidPhone(orderId);
};
const onFailedProxySessionCreation = () => {
  const orderId = appStore.getOrderIdInCustomerPhoneProxySessionDialog();
  Amplitude.logFailedProxySessionCreation(orderId);
};

const onSuccessfulRTRelease = (orderId, storekeeperId) => {
  Amplitude.logSuccessfulRTRelease(orderId, storekeeperId);
};
const onSuccessfulWhimCreation = () => {
  const orderId = appStore.getOrderIdInOrderWhimDialog();
  Amplitude.logSuccessFulWhimCreation(orderId);
};
const onSuccessfulWhimDelete = (orderId, whimId) => {
  Amplitude.logSuccessfulWhimDelete(orderId, whimId);
};
const onFailedWhimCreation = () => {
  const orderId = appStore.getOrderIdInOrderWhimDialog();
  Amplitude.logFailedWhimCreation(orderId);
};

const onClickAddWhimButton = orderId => {
  Amplitude.logOnClickAddWhimButton(orderId);
};

const onOpenSupportReasonsDialog = orderId => {
  Amplitude.logOnOpenSupportReasonsDialog(orderId);
};

const onClickSelectedReasonList = (orderId, selectReason) => {
  Amplitude.logOnSelectedSupportReasonList(orderId, selectReason);
};

const onClickSelectedSuggestion = (orderId, selectedSolution) => {
  Amplitude.logOnClickSelectedSuggestion(orderId, selectedSolution);
};

const onSuccessfulArticle = articleId => {
  Amplitude.logSuccessfulArticle(articleId);
};

const onSuccessfulCategory = sectionId => {
  Amplitude.logSuccessfulCategory(sectionId);
};
const onSuccessfulMassiveDeactivationToppings = (storeId, ToppingsLength) => {
  Amplitude.logSuccessfulMassiveToppingDeactivaction(storeId, ToppingsLength);
};
const onOpenMassiveDeactivationToppingsDrawer = storeId => {
  Amplitude.logOnOpenMassiveToppingDeactivactionDrawer(storeId);
};
const onOpenStoreDiagnosticDialog = storeId => {
  Amplitude.logOnOpenStoreDiagnosticDialog(storeId);
};
const onDisconnectionPartnerVoluntary = (
  partnerId,
  hasExtension,
  appVersion,
) => {
  Amplitude.logOnDisconnectionPartnerVoluntary(
    partnerId,
    hasExtension,
    appVersion,
  );
};
const onConnectionPartnerVoluntary = (partnerId, hasExtension, appVersion) => {
  Amplitude.logOnConnectionPartnerVoluntary(
    partnerId,
    hasExtension,
    appVersion,
  );
};
const onNetworkError = (partnerId, hasExtension, appVersion) => {
  Amplitude.logNetworkError(partnerId, hasExtension, appVersion);
};
const onConnectionEstablished = (partnerId, hasExtension, appVersion) => {
  Amplitude.logOnConnectionEstablished(partnerId, hasExtension, appVersion);
};
const onOpenNotificationCenter = partnerId => {
  Amplitude.logOpenNotificationCenter(partnerId);
};
const onClickNotificationLink = (partnerId, label, url, id) => {
  Amplitude.logOnClickNotificationLink(partnerId, label, url, id);
};

const onClickNotifyRT = (partnerId, orderId) => {
  Amplitude.logOnClickNotifyRT(partnerId, orderId);
};

const onSuccessSendSuggestProduct = (
  orderId,
  partnerId,
  suggestion,
  refuseReason,
) => {
  Amplitude.logSendSuggestProduct(orderId, partnerId, suggestion, refuseReason);
};

const onSuccessRejectOrder = (orderId, partnerId) => {
  Amplitude.logRejectOrder(orderId, partnerId);
};

const onSuccessStartOnboarding = (partnerId, source) => {
  Amplitude.logStartOnboarding(partnerId, source);
};
const onSuccessEndOnboarding = (partnerId, source) => {
  Amplitude.logEndOnboarding(partnerId, source);
};
const onSuccessSelectedArticle = (
  partnerId,
  categoryName,
  subCategoryName,
  articleName,
) => {
  Amplitude.logSelectedArticle(
    partnerId,
    categoryName,
    subCategoryName,
    articleName,
  );
};
const onSuccessDownloadChromeExtension = partnerId => {
  Amplitude.logDownloadChromeExtension(partnerId);
};
const onStockOutReplacementSelection = (partnerId, status) => {
  Amplitude.logStockOutReplacementSelection(partnerId, status);
};
const onOpenPMDialogIntro = (partnerId, storeId) => {
  Amplitude.logOpenPMDialogIntro(partnerId, storeId);
};
const onSendCompletePMDialog = (partnerId, storeId) => {
  Amplitude.logSendCompletePMDialog(partnerId, storeId);
};

const onSibeBarMenuSelection = (partnerId = 0, option = '') => {
  const fixOpt = option.toLowerCase().replace('/', '').replace('-', '_');
  Amplitude.logSelectedSideBarOption(partnerId, fixOpt);
};

const onLoginScreenSelection = selectedOption => {
  Amplitude.logLoginScreenSelection(selectedOption);
};
const onLoginLogErrors = (email, httpStatus, httpResponseBody) => {
  Amplitude.logErrorOnLogin(email, httpStatus, httpResponseBody);
};

const onClickStoreStatusDrop = ({
  partnerId = 0, // number
  typePartner = amplitudPartnerType.single, // 'single' | 'many'
}) => {
  Amplitude.logEvent(Amplitude.EventCodes.CLICK_SUMMARY, {
    PARTNER_ID: partnerId,
    TYPE_PARTNER: typePartner,
  });
};

const onClickStoreStatusDropGoToStoreAdminPage = ({
  partnerId = 0, // number
  typePartner = amplitudPartnerType.single, // 'single' | 'many'
}) => {
  Amplitude.logEvent(Amplitude.EventCodes.CLICK_SUMMARY_CTA, {
    PARTNER_ID: partnerId,
    TYPE_PARTNER: typePartner,
    CTA_TYPE: 'store_management',
  });
};

const onClickStoreStatusCardCTA = ({
  partnerId = 0, // number
  storeId = 0, // number
  ctaType = '', // TpStoreStatusCtaToAmplitud | ''
  onStoreAdminPage = false, // boolean
}) => {
  const codeOnPage = Amplitude.EventCodes.CLICK_CTA;
  const codeNotOnPage = Amplitude.EventCodes.CLICK_SUMMARY_CTA;
  Amplitude.logEvent(onStoreAdminPage ? codeOnPage : codeNotOnPage, {
    PARTNER_ID: partnerId,
    STORE_ID: storeId,
    CTA_TYPE: ctaType,
  });
};

const onClickFilterOnStoreAdminPage = ({
  partnerId = 0, // number
  filterType = '', // TpStoreStatusAmplitudFilterType | ''
}) => {
  Amplitude.logEvent(Amplitude.EventCodes.CLICK_FILTER, {
    PARTNER_ID: partnerId,
    FILTER_TYPE: filterType,
  });
};

const onToggleAllStoreStates = ({
  partnerId = 0, // number
  storeIds = [], // number[]
  action = '', // 'deactivate' | 'activate' | ''
}) => {
  Amplitude.logEvent(Amplitude.EventCodes.TOGGLE_ALL, {
    PARTNER_ID: partnerId,
    STORE_ID: storeIds,
    ACTION: action,
  });
};

const onOpenChatFromOrderDetail = ({ orderId, storeId, createdAt, userId }) => {
  Amplitude.logEvent(Amplitude.EventCodes.EVENT_ORDER_CHAT_USER_FLOW, {
    ORDER_ID: orderId,
    STORE_ID: storeId,
    CREATED_AT: createdAt,
    USER_ID: userId,
  });
};

const onExtendedSchedules = ({ storeId = 0, partnerId = 0 }) => {
  Amplitude.logEvent(Amplitude.EventCodes.CLICK_CTA, {
    PARTNER_ID: partnerId,
    STORE_ID: storeId,
    CTA_TYPE: 'EXTENDED_SCHEDULES',
  });
};

const onClickImReady = (partnerId, country) => {
  Amplitude.logEvent(Amplitude.EventCodes.EVENT_ORDER_IM_READY, {
    PARTNER_ID: partnerId,
    COUNTRY: country,
  });
};

const onClickDoLater = (partnerId, country) => {
  Amplitude.logEvent(Amplitude.EventCodes.EVENT_ORDER_DO_LATER, {
    PARTNER_ID: partnerId,
    COUNTRY: country,
  });
};

const onClickManageMenu = (partnerId, country) => {
  Amplitude.logEvent(Amplitude.EventCodes.EVENT_ORDER_MANAGE_MENU, {
    PARTNER_ID: partnerId,
    COUNTRY: country,
  });
};

const onClickManageProducts = (partnerId, country) => {
  Amplitude.logEvent(Amplitude.EventCodes.EVENT_ORDER_MANAGE_PRODUCTS, {
    PARTNER_ID: partnerId,
    COUNTRY: country,
  });
};

function onChangeTurnoffProduct({ deactivationType = '' }) {
  const storeId = appStore.getMenuSelectedStoreId();
  Amplitude.logEvent(Amplitude.EventCodes.SWITCH_OFF_PRODUCT, {
    STORE_ID: storeId,
    CONFIRMATION_TYPE: (() => {
      switch (deactivationType) {
        case DeactivationType.PERMANENT:
          return 'UNAVAILBLE_INDEFINITELY';
        case DeactivationType.CUSTOM:
          return 'UNAVAILABLE_SPECIFIC_DATE';
        default:
          return 'UNAVAILABLE_TODAY';
      }
    })(),
  });
}

function onClickOrderFindRT({ orderId = NaN }) {
  Amplitude.logEvent(Amplitude.EventCodes.EVENT_ORDER_FIND_RT, {
    ORDER_ID: orderId,
    STORE_ID: appStore.getOrderById(orderId)?.store_id ?? NaN,
  });
}

function onClickOrderFreeupRT({ orderId = NaN, storeId = NaN }) {
  Amplitude.logEvent(Amplitude.EventCodes.CLICK_FREEUP_RT, {
    ORDER_ID: orderId,
    STORE_ID: storeId,
  });
}

function onClickOrderReportRT({ orderId = NaN, storeId = NaN }) {
  Amplitude.logEvent(Amplitude.EventCodes.CLICK_REPORT_RT, {
    ORDER_ID: orderId,
    STORE_ID: storeId,
  });
}

function onChangeAutoAcceptOrders({ partnerId = NaN }) {
  Amplitude.logEvent(Amplitude.EventCodes.SWITCH_ON_AUTOACCEPT_ORDERS, {
    PARTNER_ID: partnerId,
  });
}

function onClickSuspensionAlert(params) {
  const { storeId = NaN, options = 'CLOSE' } = params;
  Amplitude.logEvent(Amplitude.EventCodes.EVENT_SUSPENSION_LIFTING, {
    STORE_ID: storeId,
    OPTIONS: options,
  });
}

const EventActions = {
  onClickAddWhimButton,
  onClickDynActionCTA,
  onClickOpenDetailFromOrderCard,
  onClickOrderTotalsRetryButton,
  onClickSelectedReasonList,
  onClickSelectedSuggestion,
  onClickShowPhoneButton,
  onClickNotificationLink,
  onConnectionPartnerVoluntary,
  onConnectionEstablished,
  onDisconnectionPartnerVoluntary,
  onCloseDynActionCTA,
  onFailedProxySessionCreation,
  onFailedRTRelease,
  onFailedWhimCreation,
  onLogIn,
  onLogOut,
  onNetworkError,
  onNewOrdersRawReception,
  onNewOrdersToConfirmReception,
  onOpenChatFromOrderDetail,
  onOpenStoreDiagnosticDialog,
  onOpenMassiveDeactivationToppingsDrawer,
  onOpenSupportReasonsDialog,
  onOpenNotificationCenter,
  onOrderDelivered,
  onOrderTaken,
  onProxySessionInvalidPhone,
  onReadyForPickupExecution,
  onSuccessfulArticle,
  onSuccessfulCategory,
  onSuccessfulMassiveDeactivationToppings,
  onSuccessfulProxySessionCreation,
  onSuccessfulRTRelease,
  onSuccessfulWhimCreation,
  onSuccessfulWhimDelete,
  onClickNotifyRT,
  onSuccessSendSuggestProduct,
  onSuccessRejectOrder,
  onSuccessEndOnboarding,
  onSuccessStartOnboarding,
  onSuccessSelectedArticle,
  onSuccessDownloadChromeExtension,
  onStockOutReplacementSelection,
  onOpenPMDialogIntro,
  onSendCompletePMDialog,
  onLoginScreenSelection,
  onSibeBarMenuSelection,
  onLoginLogErrors,
  onClickStoreStatusDrop,
  onClickStoreStatusCardCTA,
  onClickStoreStatusDropGoToStoreAdminPage,
  onClickFilterOnStoreAdminPage,
  onToggleAllStoreStates,
  onExtendedSchedules,
  onClickImReady,
  onClickDoLater,
  onClickManageMenu,
  onClickManageProducts,
  onChangeTurnoffProduct,
  onClickOrderFindRT,
  onClickOrderFreeupRT,
  onClickOrderReportRT,
  onChangeAutoAcceptOrders,
  onClickSuspensionAlert,
};

export default EventActions;
