import { makeStyles } from '@material-ui/core';

import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  bold: {
    fontWeight: 'bold',
  },
  body1: {
    fontSize: '0.875rem',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
  },
  storeName: {
    marginTop: '0.5rem',
    fontSize: '0.875rem',
    fontWeight: 900,
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.125rem',
      marginTop: '1rem',
    },
  },
  reason: {
    width: 'fit-content',
    padding: '1rem',
    margin: '0.25rem auto 1.5rem',
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'center',
    borderRadius: '0.5rem',
    backgroundColor: neutral.D10,
    [theme.breakpoints.up('sm')]: {
      margin: '0.5rem auto 2rem',
      fontSize: '1.25rem',
    },
  },
  body2: {
    fontSize: '0.75rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.875rem',
    },
  },
  standardError: {
    width: 'fit-content',
    padding: '0 0.5rem',
    margin: '0 auto 1.5rem',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: neutral.D18,
  },
}));
