import { Alert } from '@material-ui/lab';
import { Box, Button, Typography } from '@material-ui/core';

import { DemandTTLOption } from '../../../demand-ttl-option';
import { DemandStatusOption } from '../../../demand-status-option';
import { StoreSaturationInfo } from '../../../store-saturation-info';
import { useChangeDemandState } from './useChangeDemandState';
import makeClasses from './ChangeDemandState.style';
import clockIcon from '../../../../shared/assets/imgs/clockIcon.svg';
import pauseIcon from '../../../../shared/assets/imgs/pauseIcon.svg';

import type { MouseEvent } from 'react';
import type { Saturation } from '../../../../../../business/highDemand';

export function ChangeDemandState(props) {
  const {
    store,
    severityTimes,
    onSelect,
    isActiveStore,
    partnerId,
    onClose,
  } = props;
  const {
    texts,
    showTTLs,
    ttlSelected,
    demandSelected,
    hasMaxUse,
    ttlOptions,
    demandStatusOptions,
    onSelectDemand,
    onSelectTTL,
    onOpenExtendClosingHoursDialog,
    onDeactivateStore,
    isFastOperationChecked,
    setIsFastOperationChecked,
  } = useChangeDemandState({ onSelect, store, partnerId, onClose });

  const classes = makeClasses({ demandSelected });

  function getIsSelected(dSelected: Saturation, v: Saturation) {
    return dSelected === v || (dSelected === 'superHigh' && v === 'high');
  }

  function handleClickHigh(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    onSelectDemand('high');
  }

  function handleClickSuperHigh(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    onSelectDemand('superHigh');
  }

  return (
    <section className={classes.container}>
      <StoreSaturationInfo
        key={`store-${store.store_id}`}
        storeName={store.name}
        variant={store.saturated}
        storeAddress={store.address}
        isActiveStore={isActiveStore}
        isTurbo={store.isTurbo}
      />
      {hasMaxUse && (
        <Alert severity='error' classes={{ root: classes.alertMaxUses }}>
          <span className='bold'>{texts.alerts.maxUse.msg.p1}</span>
          {' ' + texts.alerts.maxUse.msg.p2}
        </Alert>
      )}

      <header>
        <Typography component='h2' className={classes.title}>
          {texts.demand.title}
        </Typography>
      </header>
      <main className={classes.main}>
        {!store.isTurbo &&
          demandStatusOptions.map(variant => (
            <DemandStatusOption
              key={`variant-opt-${variant}`}
              variant={variant}
              onSelect={onSelectDemand}
              extraTime={severityTimes[variant]}
              selected={getIsSelected(demandSelected, variant)}
              disabled={
                (hasMaxUse &&
                  !store?.isEligibleForFastOperation &&
                  variant !== 'regular') ||
                (hasMaxUse &&
                  store?.isEligibleForFastOperation &&
                  variant !== 'fast') ||
                (variant === 'fast' && !store?.isEligibleForFastOperation) ||
                (isFastOperationChecked && variant !== 'fast')
              }
              isEligibleForFastOperation={store?.isEligibleForFastOperation}
              saturated={store?.saturated}
              isFastOperationChecked={isFastOperationChecked}
              setIsFastOperationChecked={setIsFastOperationChecked}
            >
              {variant === 'high' && getIsSelected(demandSelected, variant) && (
                <section className={classes.sectionMergeSuperAndHigh}>
                  <Typography
                    component='h2'
                    variant='subtitle2'
                    className={classes.superAndHighSectionTitle}
                  >
                    {texts.demand.mergeSuperHigh.title}
                  </Typography>
                  <div className={classes.containerSuperAndHigh}>
                    {(['high', 'superHigh'] as Saturation[]).map(vHigh => (
                      <Button
                        key={`internal-variant-${vHigh}`}
                        role='option'
                        variant='contained'
                        aria-selected={vHigh === demandSelected}
                        classes={{ root: classes.btnOptionSuperAndHigh }}
                        onClick={
                          vHigh === 'high'
                            ? handleClickHigh
                            : handleClickSuperHigh
                        }
                      >
                        +{severityTimes[vHigh]} minutos
                      </Button>
                    ))}
                  </div>
                </section>
              )}
              {showTTLs && getIsSelected(demandSelected, variant) && (
                <Box component='section' className={classes.sectionTTL}>
                  <Typography
                    component='h3'
                    variant='subtitle2'
                    className={classes.ttlSectionTitle}
                  >
                    {texts.ttl.title}
                  </Typography>
                  <Box className={classes.containerTTLOpts}>
                    {ttlOptions.map(ttl => (
                      <DemandTTLOption
                        key={`ttl-opt-${ttl}`}
                        timeToLife={ttl}
                        onSelect={onSelectTTL}
                        selected={ttlSelected === ttl}
                        demandSelected={demandSelected}
                      />
                    ))}
                  </Box>
                </Box>
              )}
            </DemandStatusOption>
          ))}
        <Button
          classes={{
            root: classes.actionButton,
            label: classes.actionButtonLabel,
          }}
          variant='text'
          color='primary'
          onClick={onOpenExtendClosingHoursDialog}
        >
          <Box
            display='inline-flex'
            flexDirection='flex-start'
            gridGap='0.5rem'
          >
            <img src={clockIcon} alt='clock' />
            <Typography className={classes.extendedText}>
              {texts.extendSchedule}
            </Typography>
          </Box>
        </Button>
        <Button
          classes={{
            root: classes.actionButton,
            label: classes.actionButtonLabel,
          }}
          variant='text'
          color='primary'
          onClick={onDeactivateStore}
        >
          <Box
            display='inline-flex'
            flexDirection='flex-start'
            gridGap='0.5rem'
          >
            <img src={pauseIcon} alt='pause' />
            <Typography className={classes.deactivateText}>
              {texts.pauseStore}
            </Typography>
          </Box>
        </Button>
      </main>
    </section>
  );
}
