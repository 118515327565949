import { ordermgmtDialogFacade } from '../../../../../business/order-mgmt';

export const useOrderMgmtAcceptRemoveChangesDialog = () => {
  const {
    selectAcceptRemoveChangesDialogIsOpen,
    onCloseAcceptRemoveChangesDialog,
  } = ordermgmtDialogFacade();

  return {
    isOpen: selectAcceptRemoveChangesDialogIsOpen,
    onClose: onCloseAcceptRemoveChangesDialog,
  };
};
