import { useFormatMessage } from '../../../../../../../../../../../intl/intl';

import type { OrderId } from '../../../../../../../../../../../business/shared/domain';

export function useCancelModalLang({ orderId }: Props) {
  return {
    title: useFormatMessage({ id: 'cargo.cancelDialog.title' }),
    description: useFormatMessage(
      { id: 'cargo.cancelDialog.description' },
      { orderId },
    ),
    disclaimer: useFormatMessage({ id: 'cargo.cancelDialog.disclaimer' }),
    backBtn: useFormatMessage({ id: 'cargo.cancelDialog.backBtn' }),
    mainBtn: useFormatMessage({ id: 'cargo.cancelDialog.mainBtn' }),
  };
}

interface Props {
  orderId: OrderId;
}
