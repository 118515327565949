// Material UI
import { makeStyles } from '@material-ui/core';
import Colors from '../../../../constants/Colors';
import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {
    display: 'grid',
    borderBottom: `1px solid ${neutral.D10}`,
    gridTemplateAreas: `"dayNameContainer"
                        "detailContainer"`,
    [theme.breakpoints.up('sm')]: {
      rowGap: 10,
    },
    [theme.breakpoints.up('md')]: {
      borderBottom: 'none',
      gridTemplateRows: 'max-content',
    },
  },
  detailContainer: {
    display: 'grid',
    gridArea: 'detailContainer',
    gridTemplateAreas: `"intervalsContainer"
    "addButtonMobile"`,
    [theme.breakpoints.up('md')]: {
      gridTemplateAreas: `"intervalsContainer addButton"`,
      gridTemplateColumns: 'repeat(2, 1fr)',
      columnGap: 6,
      alignContent: 'flex-start',
    },
  },
  intervalsContainer: {
    gridArea: 'intervalsContainer',
    display: 'grid',
    rowGap: 13,
  },
  dayNameContainer: {
    gridArea: 'dayNameContainer',
    alignSelf: 'center',
    paddingTop: 10,
    paddingBottom: 10,
  },
  icon: {
    backgroundColor: Colors.PRIMARY_COLOR,
  },
  button: {
    gridArea: 'addButton',
    width: 20,
    height: 20,
    marginTop: 7,
  },
  iconMobile: {
    backgroundColor: Colors.PRIMARY_COLOR,
  },
  buttonMobile: {
    gridArea: 'addButtonMobile',
    color: Colors.PRIMARY_COLOR,
    fontSize: 12,
    fontWeight: 'bold',
    justifySelf: 'left',
    paddingLeft: 0,
    paddingTop: 20,
    paddingBottom: 20,
  },
}));
