import { useFormatMessage } from '../../../../intl/intl';
import StringUtils from '../../../../utils/StringUtils';

export function useOrderBillProduct(globalOffers) {
  const globalOfferTagPromo =
    globalOffers.at(0)?.conditions.at(0)?.tag_promo || '';

  const getGlobalOfferText = () => {
    return useFormatMessage(
      { id: 'globalOffer.text' },
      {
        promo: globalOfferTagPromo,
      },
    );
  };
  const hasGlobalOffer = !StringUtils.isNullOrEmpty(globalOfferTagPromo);

  return {
    getGlobalOfferText,
    hasGlobalOffer,
  };
}
