const phone = {
  id: 'PMStepper.phone',
}
const schedule = {
  id: 'PMStepper.schedule',
}

const textIds = {
  phone,
  schedule,
}

export default textIds
