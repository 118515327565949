import { useFormatMessage } from '../../intl/intl';
import Hooks from './hooks';

import CustomAlert from '../common/custom-alert';
import OrderDetailDialogMainSectionItem from '../order-detail-dialog-main-section-item';

import { Serverities } from '../../constants/Severities';
import useStyles from './styles';

export function OrderDetailDialogCancellationBox() {
  const componentClasses = useStyles();

  const title = useFormatMessage({
    id: 'orderDetailDialogCancellationBox.title',
  });

  const message = Hooks.useMessage();

  return (
    <OrderDetailDialogMainSectionItem
      classes={{ body: componentClasses.root }}
      title={title}
    >
      <CustomAlert
        classes={{ root: componentClasses.customAlertRoot }}
        text={message}
        type={Serverities.WARNING}
      />
    </OrderDetailDialogMainSectionItem>
  );
}
