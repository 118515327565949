import React from 'react';
import { Box, Typography } from '@material-ui/core';
import useListStyles from '../list.styles';

import { useKanbanLang } from '../hooks/useKanbanLang';

export function EmptyResult() {
  const styles = useListStyles();
  const {
    orderListEmptyResultTitleText,
    orderListEmptyResultDescriptionText,
  } = useKanbanLang();

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      textAlign='center'
      gridGap='1rem'
      width='100%'
      height='75vh'
    >
      <Typography className={styles.emptyTitle}>
        {orderListEmptyResultTitleText}
      </Typography>
      <Typography
        className={styles.emptyDescription}
        dangerouslySetInnerHTML={{
          __html: orderListEmptyResultDescriptionText || '',
        }}
      />
    </Box>
  );
}
