import { clsx } from 'clsx';
import { DirectionsBike as MarketplaceIcon } from '@material-ui/icons';

import { useFormatMessage } from '../../intl/intl';
import { CustomStateLabel } from '../common/custom-state-label';

import { textIds } from './const';
import ColumnState from '../../constants/ColumnState';
import useStyles from './MarketplaceLabel.styles';

export function MarketplaceLabel({ classes, partnerOrderState, ...props }) {
  const _classes = useStyles();
  const marketplaceCookingLabelText = useFormatMessage(
    textIds.marketplaceLabelText,
  );
  const marketplaceDeliverLabelText = useFormatMessage(
    textIds.marketplaceDomicilaryOnDeliver,
  );
  const label =
    partnerOrderState !== ColumnState.READY_AND_PACKED
      ? marketplaceCookingLabelText
      : marketplaceDeliverLabelText;
  return (
    <CustomStateLabel
      classes={{
        root: clsx(_classes.root, classes?.root),
        iconRoot: clsx(_classes.iconRoot, classes?.iconRoot),
      }}
      Icon={MarketplaceIcon}
      {...props}
      stateMessage={label}
    />
  );
}
