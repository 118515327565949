import { useFormatMessage } from '../../../../../intl/intl';

export const useOrderMgmtReplaceProductDialogLang = () => {
  const messageCategory = 'orderManagement';
  const messageSubcategory = 'replaceProductDialog';

  const replaceProductTitleText = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.titleText`,
  });

  const replaceProductDisclaimerText = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.disclaimerText`,
  });

  const replaceProductCancelText = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.cancelText`,
  });

  const replaceProductContinueText = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.continueText`,
  });

  return {
    replaceProductTitleText,
    replaceProductDisclaimerText,
    replaceProductCancelText,
    replaceProductContinueText,
  };
};
