import { makeStyles } from '@material-ui/core';
import { neutral } from '../../../../../../shared/styles/colors';

export default makeStyles(() => ({
  paper: {
    height: '25rem',
    width: '30rem',
    padding: '0 1.5rem 1.5rem',
    position: 'relative',
    borderRadius: '0.5rem',
  },
  header: {
    padding: 0,
    borderBottom: 'transparent',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '2',
  },
  subtitle: {
    color: neutral.D16,
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
  },
  items: {
    color: neutral.D16,
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
    marginTop: '1rem',
  },
  body: {
    overflow: 'scroll',
    maxHeight: '16rem',
  },
}));
