// Consts
import urls from '../../constants/Urls';
// Interfaces
import {
  StockOutReplacementResponse,
  ProductSubstitutionResponse,
} from './interfaces';
// Utils
import UrlUtils from '../../utils/url-utils';
// Services
import http from '../HttpService';
import LocalStorageService from '../local-storage-service';

const sendStockOutReplacement = (
  orderId: number,
  selecteProducts: number[],
  selectedToppings: number[],
  refuseReason: string,
  refuseReasonsDescription: string,
) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(
    urls.STOCK_OUT_SUGGEST_PRODUCT,
    { orderId },
  );
  const payload = {
    stockout_products: selecteProducts,
    stockout_toppings: selectedToppings,
    refuse_reason_enum: refuseReason,
    refuse_reason_comment: refuseReasonsDescription,
  };

  return http.post<StockOutReplacementResponse>(
    `${microserviceUrl}${replacedUrl}`,
    payload,
  );
};

const sendProductSuggestion = (
  orderId: number,
  suggestion: string,
  selectedProduct: number,
  refuseReason: string,
  refuseReasonsDescription: string,
) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(
    urls.SUGGESTION_PRODUCT_REPLACEMENT,
    { orderId },
  );
  const payload = {
    partner_suggestion: suggestion,
    product_id_to_replace: selectedProduct,
    refuse_reason_enum: refuseReason,
    refuse_reason_comment: refuseReasonsDescription,
  };

  return http.post<ProductSubstitutionResponse>(
    `${microserviceUrl}${replacedUrl}`,
    payload,
  );
};
const stockOutService = {
  sendStockOutReplacement,
  sendProductSuggestion,
};

export default stockOutService;
