// React
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import AppActions from '../../redux/actions/app-actions';
import RootActions from '../../redux/actions/root.actions';
// Components
import Routing from './Routing';

const mapStateToProps = ({ appReducer, ordersReducer }) => {
  return {
    failsV2Count: ordersReducer.failsV2Count,
    snackBar: appReducer.snackBar,
  };
};

const mapDispatchToProps = {
  startAppUp: RootActions.startAppUp,
  closeSnackBar: AppActions.closeSnackBar,
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Routing),
);
