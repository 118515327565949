import { useMemo } from 'react';

import { DangerCustomAlert } from './components/danger-custom-alert';
import { InfoCustomAlert } from './components/info-custom-alert';
import { SuccessCustomAlert } from './components/success-custom-alert';
import { WarningCustomAlert } from './components/warning-custom-alert';

import { Serverities } from '../../../constants/Severities';

import type { BaseProps } from '../../../interfaces';

function CustomAlert(props: Props) {
  const { type = Serverities.INFO, ...extraProps } = props;
  const __Alert = useMemo(() => {
    return {
      [Serverities.ERROR]: DangerCustomAlert,
      [Serverities.SUCCESS]: SuccessCustomAlert,
      [Serverities.WARNING]: WarningCustomAlert,
      [Serverities.INFO]: InfoCustomAlert,
    }[type];
  }, [type]);
  return <__Alert {...extraProps} />;
}

export default CustomAlert;

interface Props extends BaseProps {
  text?: string | null;
  type?: keyof typeof Serverities;
}
