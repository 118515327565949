// Redux
import { createAction } from '@reduxjs/toolkit';
// Interfaces
import { SupportSuggestion } from '../../../interfaces';

const setAction = createAction<{
  description: string;
  orderId: number;
  supportSuggestions: SupportSuggestion[];
}>('NoRTSupportSuggestions/set');

const set = (
  description: string,
  orderId: number,
  supportSuggestions: SupportSuggestion[],
) => (dispatch: any) => {
  dispatch(setAction({ description, orderId, supportSuggestions }));
};

const actions = {
  set,
  setAction,
};

export default actions;
