import CustomDialog from '../common/custom-dialog/CustomDialog';

import useStyles from './FailedProxySessionDialog.styles';

function FailedProxySessionDialog(props) {
  const { onBack, onClose, open, title, description } = props;

  const componentClasses = useStyles();

  return (
    <CustomDialog
      classes={{
        headerRoot: componentClasses.headerRoot,
        paper: componentClasses.paper,
      }}
      onClickBackButton={onBack}
      onClose={onClose}
      open={open}
      showBackButton
      showCloseButton
      title={title}
    >
      <div className={componentClasses.body}>
        <span className={componentClasses.text}>{description}</span>
      </div>
    </CustomDialog>
  );
}

export default FailedProxySessionDialog;
