// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  messageContainer: { textAlign: 'center' },
  expresionContainer: {},
  headerContainer: {
    justifySelf: 'center',
  },
  root: {
    alignContent: 'center',
    display: 'grid',
    gridRowGap: 33,
    justifyContent: 'center',
    justifyItems: 'center',
    padding: '0px 24px',
    margin: 'auto',
    maxWidth: 600,
    height: '100%',
  },
  infoContainer: {
    alignContent: 'center',
    color: neutral.D15,
    fontSize: 16,
    lineHeight: 'normal',
    display: 'grid',
    gridRowGap: 8,
    justifyContent: 'center',
  },
  titleContainer: {
    color: neutral.D20,
    fontSize: 48,
    fontFamily: 'Poppins Bold',
    textAlign: 'center',
  },
}));
