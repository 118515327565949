import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import SalesSummaryActions from '../../redux/actions/salesSummary.actions';

import SalesSummary from './SalesSummary';

const mapStateToProps = (
  {
    partnerReducer: { profile },
    salesSummaryReducer: {
      filter,
      isSalesSummaryLoading: isLoading,
      salesSummary,
    },
  },
  { intl: { formatMessage } },
) => {
  const canceledTitle = formatMessage({ id: 'store.summary.canceled' });
  const finishedTitle = formatMessage({ id: 'store.summary.finished' });
  const ordersTitle = formatMessage({ id: 'store.summary.orders' });
  const title = formatMessage({ id: 'store.summary.title' });
  const totalAccumulated = formatMessage({
    id: 'store.summary.totalAccumulated',
  });
  const totalWithDiscount = formatMessage({
    id: 'store.summary.totalWithDiscount',
  });
  const totalDiscount = formatMessage({ id: 'store.summary.totalDiscount' });
  const cancelOrders = salesSummary?.canceled_orders ?? {};
  const finishOrders = salesSummary?.finished_orders ?? {};

  const finishOrderDiscount = finishOrders?.discounts ?? null;
  const showFinishOrderDiscount = Boolean(finishOrderDiscount);
  const cancelOrderDiscount = cancelOrders?.discounts ?? null;
  const showCancelOrderDiscount = Boolean(cancelOrderDiscount);
  const finishOrderWithDiscount = finishOrders?.total_with_discounts ?? null;
  const showTotalFinishOrderWithDiscount = Boolean(finishOrderWithDiscount);
  const cancelOrderWithDiscount = cancelOrders?.total_with_discounts ?? null;
  const showTotalCancelOrderWithDiscount = Boolean(cancelOrderWithDiscount);

  const selectedStore = profile.stores.find(
    store => store.id === +filter.selectedStoreId,
  );

  return {
    canceledTitle,
    cancelOrders,
    currentFilter: filter,
    finishedTitle,
    finishOrders,
    isLoading,
    ordersTitle,
    salesSummary: salesSummary,
    selectedStore,
    showCancelOrderDiscount,
    showFinishOrderDiscount,
    showTotalCancelOrderWithDiscount,
    showTotalFinishOrderWithDiscount,
    title,
    totalAccumulated,
    totalDiscount,
    totalWithDiscount,
  };
};
const mapDispatchToProps = {
  getSalesSummary: SalesSummaryActions.getSalesSummary,
  onPrint: SalesSummaryActions.sendSalesSummaryToPrint,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SalesSummary),
);
