import { makeStyles } from '@material-ui/styles';
// Constants
import Colors from '../../constants/Colors';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  body: {
    display: 'grid',
    backgroundColor: Colors.WHITE,
    gridColumn: 1,
    gridTemplateColumns: 'repeat(1, 1fr)',
    // gridGap: 10,
    gridAutoRows: 'minmax(100px, auto)',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Poppins',
    fontSize: '17px',
    height: '100%',
    width: '100%',
  },
  salesSummaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '24px 0px 8px 0px',
    overflow: 'auto',

    [theme.breakpoints.up('md')]: {
      padding: '24px 8px 8px 8px',
    },
  },
  salesSummaryBodyContainer: {
    display: 'grid',
  },
  count: {},
  total: {
    backgroundColor: neutral.white,
    padding: '20px 16px',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 24px 24px 24px',
    backgroundColor: neutral.white,
    borderBottom: `1px solid ${neutral.D10}`,
  },
  finishOrderBox: {
    padding: '24px 24px 24px 24px',
    border: `1px solid ${neutral.D10}`,
    marginTop: 24,
    marginBottom: 0,
    marginRight: 24,
    marginLeft: 24,
    [theme.breakpoints.up('md')]: {
      marginTop: 24,
      marginBottom: 24,
      marginRight: 12,
      marginLeft: 24,
    },
  },
  totalCount: {
    marginTop: 24,
    fontWeight: 'bold',
    fontSize: 17,
  },
  totalCountFinishedOrder: {
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: Colors.PRIMARY_COLOR,
  },
  cancelOrderBox: {
    padding: '24px 24px 24px 24px',
    border: `1px solid ${neutral.D10}`,
    marginTop: 24,
    marginBottom: 24,
    marginRight: 24,
    marginLeft: 24,
    [theme.breakpoints.up('md')]: {
      marginTop: 24,
      marginBottom: 24,
      marginRight: 24,
      marginLeft: 12,
    },
  },
  totalCountCancelOrder: {
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: Colors.SECONDARY_COLOR,
  },
  text: {
    textAlign: 'inherit',
    paddingBottom: 24,
    fontSize: 17,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: neutral.D20,
  },
}));
