import CustomLoader from '../common/custom-loader';
import { ToppingBoxesList } from './topping-outstock-list';
import { ProductOutstockList } from './product-outstock-list';

import useStyles from './MenuOutOfStockView.styles';

function MenuOutOfStockView(props) {
  const { isLoading, productsOutOfStock = [], toppingsOutOfStock = [] } = props;

  const _classes = useStyles();

  return (
    <div className={_classes.root}>
      <CustomLoader open={isLoading} />
      {productsOutOfStock.length > 0 && (
        <ProductOutstockList products={productsOutOfStock} />
      )}
      {toppingsOutOfStock.length > 0 && (
        <ToppingBoxesList toppings={toppingsOutOfStock} />
      )}
    </div>
  );
}

export default MenuOutOfStockView;
