import { requestAdapter } from '../adapters';
import {
  CancelCostOrderRequest,
  CancelOrderRequest,
  OrdersRequest,
} from '../core/requests';
import { CargoSelectors, cargoActions } from '../store';

export const cargoKanbanHandler = () => {
  const {
    onGetCargoOrderList,
    onGetCancelCostOrder,
    onCancelCargoOrder,
  } = cargoActions();

  const requestCargoOrderList = (model: OrdersRequest) => {
    const payload = requestAdapter.toOrdersList(model);
    onGetCargoOrderList(payload);
  };

  const requestCancelOrderCost = (model: CancelCostOrderRequest) => {
    const payload = requestAdapter.toCancelOrderCost(model);
    onGetCancelCostOrder(payload);
  };

  const requestCancelOrder = (model: CancelOrderRequest) => {
    const payload = requestAdapter.toCancelOrder(model);
    onCancelCargoOrder(payload);
  };

  return {
    getOrders,
    getKanbanStatus,
    getCancelCostOrder,
    getCancelCostOrderStatus,
    getCancelCargoOrderStatus,
    dispatch,
    requestCargoOrderList,
    requestCancelOrderCost,
    requestCancelOrder,
    getLastUpdate,
    getOrderFilters,
  };
};

function getOrders() {
  const { selectFilteredOrders } = CargoSelectors();
  return selectFilteredOrders;
}

function getKanbanStatus() {
  const { selectKanbanStatus } = CargoSelectors();
  return selectKanbanStatus;
}

function getCancelCostOrder() {
  const { selectCancelCostOrder } = CargoSelectors();
  return selectCancelCostOrder;
}

function getCancelCostOrderStatus() {
  const { selectCancelCostOrderStatus } = CargoSelectors();
  return selectCancelCostOrderStatus;
}

function getCancelCargoOrderStatus() {
  const { selectCancelCargoOrderStatus } = CargoSelectors();
  return selectCancelCargoOrderStatus;
}

function getLastUpdate() {
  const { selectLastUpdate } = CargoSelectors();
  return selectLastUpdate;
}

function getOrderFilters() {
  const { selectFilters } = CargoSelectors();
  return selectFilters;
}

function dispatch() {
  const {
    onSetCancelOrderStatus,
    onSetLastUpdate,
    onSetFilterOrders,
  } = cargoActions();

  return {
    onSetCancelOrderStatus,
    onSetLastUpdate,
    onSetFilterOrders,
  };
}
