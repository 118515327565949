import { clsx } from 'clsx';

import { CustomStateLabel } from '../../../common/custom-state-label';
import RTWaitingIcon from '../../../../assets/images/icons/rtWaitingIcon.webp';

import useStyles from './InStoreRTStateLabel.styles';

export function InStoreRTStateLabel({ classes, ...extraProps }) {
  const _classes = useStyles();
  return (
    <CustomStateLabel
      classes={{
        root: clsx(_classes.root, classes?.root),
        iconRoot: clsx(_classes.iconRoot, classes?.iconRoot),
      }}
      Icon={RTWaitingIcon}
      {...extraProps}
    />
  );
}
