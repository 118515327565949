import { makeStyles } from '@material-ui/core';
import Colors from '../../../../constants/Colors';

export const changePasswordPathStyles = makeStyles(theme => {
  return {
    header: {
      display: 'flex',
      gap: '1.7rem',
      marginBottom: '2.75rem',
    },
    link: {
      paddingLeft: '0.63rem',
      color: Colors.DODGER_BLUE,
      fontWeight: 600,
    },
    root: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'flex-start',
      height: '100%',
      gap: '1rem',
      padding: '36px 24px 30px 24px',
      [theme.breakpoints.up('md')]: {
        padding: '76px 36px 52px 36px',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '126px 100px 100px 100px',
      },
    },
    textBold: {
      fontWeight: 600,
    },
    title: {
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },
    imageHolder: {
      width: '10rem',
      height: '10rem',
      backgroundColor: Colors.WHITE,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '50%',
    },
    stepHolder: {
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
        justifyContent: 'center',
        textAlign: 'center',
      },
    },
    image: {
      objectFit: 'scale-down',
      width: '10rem',
      height: '10rem',
    },
    button: {
      padding: '0.63rem',
      maxWidth: '18.3rem',
      height: '2.88rem',
      backgroundColor: Colors.DODGER_BLUE,
      borderRadius: '16px',
      color: Colors.WHITE,
      alignSelf: 'center',
      marginTop: '2rem',
      '&:hover': {
        backgroundColor: Colors.DODGER_BLUE_INTENSE,
      },
    },
  };
});
