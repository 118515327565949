// React
import React from 'react'
// Material UI
import { SvgIcon } from '@material-ui/core'
// Interfaces
import { Props } from './props'

const StoreIcon: React.FC<Props> = ({ color }) => {
  return (
    <SvgIcon>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.417 6.5h15.167c.596 0 1.083-.488 1.083-1.084 0-.595-.488-1.083-1.083-1.083H5.417c-.596 0-1.083.488-1.083 1.083 0 .596.487 1.084 1.083 1.084zM21.84 8.45a1.08 1.08 0 0 0-1.061-.867H5.222c-.52 0-.964.368-1.062.867l-1.083 5.416c-.13.672.38 1.3 1.062 1.3h.195v5.417c0 .596.487 1.083 1.083 1.083h8.667c.596 0 1.083-.487 1.083-1.083v-5.417H19.5v5.417c0 .596.488 1.083 1.084 1.083.596 0 1.083-.487 1.083-1.083v-5.417h.195c.683 0 1.192-.628 1.062-1.3L21.84 8.45zM13 19.5H6.5v-4.334H13V19.5z'
        fill={color}
      />
    </SvgIcon>
  )
}

export default StoreIcon
