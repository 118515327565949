import makeClasses from './SaturationIcon.style';

import { VARIANT } from '../../shared/consts';

import type { SaturationIconProps } from './SaturationIcon.model';

export function SaturationIcon(props: SaturationIconProps) {
  const { variant, size = 'medium', color = '' } = props;
  const TheIcon = VARIANT[variant].icon;
  const classes = makeClasses({ variant, size, color });
  return <TheIcon className={classes.icon} />;
}
