import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import CustomSwipeableDialog from '../common/custom-swipeable-dialog';

import hooks from './hooks';
import { useFormatMessage } from '../../intl/intl';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';

import { textIds } from './consts';
import useStyles from './styles';

export function LogOutDialog() {
  const open = hooks.useOpen();

  const componentClasses = useStyles();

  const cancelButtonText = useFormatMessage(textIds.cancelButtonText);
  const confirmButtonText = useFormatMessage(textIds.confirmButtonText);
  const message = useFormatMessage(textIds.message);
  const title = useFormatMessage(textIds.title);

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  const handleConfirm = useCallback(() => {
    dispatch(dialogsManagerActions.openLogOutDialogReasons());
  }, [dispatch]);

  return (
    <CustomSwipeableDialog
      classes={{
        dialogPaper: componentClasses.root,
        contentContainer: componentClasses.container,
        title: componentClasses.title,
        titleContainer: componentClasses.titleBox,
      }}
      open={open}
      title={title}
      message={message}
      buttons={[
        {
          variant: 'outlined',
          onClick: handleClose,
          label: cancelButtonText,
          color: 'tertiary',
        },
        {
          color: 'primary',
          label: confirmButtonText,
          onClick: handleConfirm,
          variant: 'contained',
        },
      ]}
    />
  );
}
