import { clsx } from 'clsx';

import CustomButton from '../buttons/custom-button';
import { EmptyStateIcon } from '../../icons/empty-state-icon';

import StringUtils from '../../../utils/StringUtils';
import useStyles from './CustomStateBlock.styles';

export function CustomStateBlock(props) {
  const {
    text = '',
    actionText = null,
    showAction = false,
    classes = {},
    Icon = EmptyStateIcon,
    onActionClick = () => {},
  } = props;

  const _classes = useStyles();

  return (
    <div className={_classes.root}>
      <Icon classes={{ root: clsx(classes?.iconRoot) }} />
      {!StringUtils.isNullOrEmpty(text) && (
        <span className={clsx(_classes.text)}>{text}</span>
      )}
      {showAction && (
        <CustomButton
          classes={{
            submitButton: clsx(
              _classes.actionSubmitButton,
              classes?.actionSubmitButton,
            ),
          }}
          fullWidth={false}
          onClick={onActionClick}
          variant='contained'
        >
          {actionText}
        </CustomButton>
      )}
    </div>
  );
}
