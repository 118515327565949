export function subscribeEvent(eventName: string, listener: any) {
  document.addEventListener(eventName, listener);
}

export function unsubscribeEvent(eventName: string, listener: any) {
  document.removeEventListener(eventName, listener);
}

export function publishEvent<D = any | undefined>(eventName: string, data?: D) {
  const event = new CustomEvent(eventName, { detail: data });
  return document.dispatchEvent(event);
}
