// React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Components
import CustomReadOnlyHourIntervalList from './CustomReadOnlyHourIntervalList'

const mapStateToProps = (
  { partnerReducer: { profile } },
  { intl: { formatMessage } },
) => {
  return {}
}

const mapDispatchToProps = {}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(CustomReadOnlyHourIntervalList),
)
