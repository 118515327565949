// Material UI
import { makeStyles } from '@material-ui/core';
// Constants
import { neutral } from '../../../shared/styles/colors';

export default makeStyles({
  actionSubmitButton: {
    fontSize: '13px !important',
    lineHeight: 'normal',
    padding: '6px 16px',
  },
  root: {
    alignContent: 'center',
    alignItems: 'center',
    color: neutral.D20,
    display: 'grid',
    fontFamily: 'Poppins',
    justifyItems: 'center',
    gridAutoRows: 'min-content',
    gridRowGap: 30,
    height: '100%',
    width: '100%',
  },
  text: {
    fontSize: 13,
    lineHeight: 'normal',
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
});
