import { clsx } from 'clsx';
import { IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

export function CustomMoreVertButton({ onClick, classes = {} }) {
  return (
    <div>
      <IconButton
        className={clsx(classes?.button)}
        aria-label='more'
        onClick={onClick}
      >
        <MoreVert className={clsx(classes?.icon)} />
      </IconButton>
    </div>
  );
}
