// primary libraries (third party library)
import React from 'react'
import { Chip } from '@material-ui/core'

// our components and custom hooks
import { useFormatMessage } from '../../../intl/intl'

// utils

// constants and styles
import { storeStatusStatusOpts as SSSOpts } from '../constants'

// Types
import type { StoreStatusBadgeProps } from '../types'

// +++++ StoreStatusBadge
export function StoreStatusBadge(props: StoreStatusBadgeProps): JSX.Element {
  const { status, extraText = '', size, onClick, onDelete } = props

  const label = useFormatMessage({ id: SSSOpts[status].textId })
  const backgroundColor = SSSOpts[status].bgColor || SSSOpts.INACTIVE.bgColor

  return (
    <Chip
      role='mark'
      size={size || 'small'}
      color='primary'
      label={label + ' ' + extraText}
      aria-label='current status of store'
      style={{ fontSize: '0.75rem', minWidth: '6.5rem', backgroundColor }}
      {...{ onClick, onDelete }}
    />
  )
}
// ----- StoreStatusBadge
