import { useSelector } from 'react-redux';

import { selectors } from '../../../redux/order-rejection-by-motoboy-lack-dialog';

const useOpen = () => {
  return useSelector(selectors.selectOpen);
};

const useTitle = () => {
  return useSelector(selectors.selectRefuseReasonDescription);
};

const hooks = { useOpen, useTitle };

export default hooks;
