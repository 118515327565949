import { connect } from 'react-redux';
import AllowNotificationsDialog from './AllowNotificationsDialog';
import RootActions from '../../redux/actions/root.actions';

const mapDispatchToProps = {
  logout: RootActions.logout,
};

export default connect(
  null,
  mapDispatchToProps,
)(AllowNotificationsDialog);
