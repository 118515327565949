// React
import React from 'react'
import { PropTypes } from 'prop-types'
// Components
import CustomInAppDialog from '../common/custom-in-app-dialog'
import { analitycsEvent, GACategories, GAActions } from '../../utils/ga-utils'
// Const
import { Serverities } from '../../constants/Severities'

const PartnerActivationReminderDialog = ({
  buttonText,
  description,
  onClickAccept,
  onClose,
  open,
  title,
  partnerId,
}) => {
  return (
    <CustomInAppDialog
      buttonText={buttonText}
      description={description}
      onClick={() => {
        analitycsEvent(
          GACategories.PARTNER_ACTIONS,
          GAActions.CTA_INAPPDIALOG,
          `message_type=${'activationReminder'}#partner_id=${partnerId}`,
        )
        onClickAccept()
      }}
      onClose={() => {
        analitycsEvent(
          GACategories.PARTNER_ACTIONS,
          GAActions.CLOSE_INAPPDIALOG,
          `message_type=${'activationReminder'}#partner_id=${partnerId}`,
        )
        onClose()
      }}
      open={open}
      title={title}
      type={Serverities.SUCCESS}
      showCloseButton
      showMainButton
    />
  )
}

PartnerActivationReminderDialog.defaultProps = {
  buttonText: '',
  onClickAccept: () => {},
  onClose: () => {},
  open: false,
}

PartnerActivationReminderDialog.propTypes = {
  buttonText: PropTypes.string,
  onClickAccept: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
}

export default PartnerActivationReminderDialog
