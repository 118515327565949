// React
import React from 'react'
import { PropTypes } from 'prop-types'
// Material UI
import { SvgIcon } from '@material-ui/core'
// Color
import Colors from '../../../constants/Colors'

/**
 * Funcional react component for a custom alert icon.
 * @function
 * @param {object} props - React props.
 * @return {JSX.Element} - Rendered Component
 */
const CustomAlertIcon = ({ className }) => {
  return (
    <SvgIcon
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      viewBox='0 0 60 60'
    >
      <path
        fill={Colors.WHITE}
        fillRule='nonzero'
        d='M30 0C13.431 0 0 13.431 0 30c0 16.569 13.431 30 30 30 16.569 0 30-13.431 30-30C60 13.431 46.569 0 30 0zm-2.727 32.727V16.364h5.454v16.363h-5.454zm5.454 10.91h-5.454v-5.455h5.454v5.454z'
      />
    </SvgIcon>
  )
}

CustomAlertIcon.defaultProps = {
  className: '',
}

CustomAlertIcon.propTypes = {
  className: PropTypes.string,
}

export default CustomAlertIcon
