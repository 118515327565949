import moment from 'moment/moment';

function formatTimeInSeconds(intl, sec) {
  const { formatMessage } = intl;

  const time = moment().startOf('day').seconds(sec);
  const hours = time.hours();
  const minutes = time.minutes();
  const seconds = time.seconds();
  let formattedTime = '';

  if (hours === 0 && minutes === 0 && seconds === 0) {
    formattedTime = 0;
  } else {
    if (hours > 0)
      formattedTime += hours + ' ' + formatMessage({ id: 'hoursAbbr' });
    if (hours > 0 && (minutes > 0 || seconds > 0)) formattedTime += ' ';
    if (minutes > 0)
      formattedTime += minutes + ' ' + formatMessage({ id: 'minutesAbbr' });
    if (minutes > 0 && seconds > 0) formattedTime += ' ';
    if (seconds > 0)
      formattedTime += seconds + ' ' + formatMessage({ id: 'secondsAbbr' });
  }

  return formattedTime;
}

function formatTimeInMinutes(intl, min) {
  return formatTimeInSeconds(intl, min * 60);
}

function formatTimeInHours(intl, hour) {
  return formatTimeInSeconds(intl, hour * 3600);
}

const formatCurrentDateTime = () => {
  return formatDateTime();
};

const formatDateTime = (date = undefined) => {
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
};

function formatCurrentDate() {
  return moment().format('YYYY-MM-DD');
}

function formatCurrentTime(date = undefined) {
  return moment(date).format('HH:mm:ss');
}

export default {
  formatCurrentDate,
  formatCurrentDateTime,
  formatDateTime,
  formatTimeInSeconds,
  formatTimeInMinutes,
  formatTimeInHours,
  formatCurrentTime,
};
