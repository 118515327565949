import { makeStyles } from '@material-ui/core';

import { VARIANTS } from './constants';

import { neutral } from '../../../shared/styles/colors';
import type { VariantCustomDialog02 } from './models';

export default makeStyles(theme => ({
  dialog: {
    maxWidth: '50rem',
    margin: '1rem 0',
  },
  header: ({ variant }: { variant: VariantCustomDialog02 }) => ({
    padding: '0 1rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: neutral.white,
    backgroundColor: VARIANTS[variant].color,
  }),
  headerTop: {
    marginRight: '-1rem',
    alignSelf: 'end',
    lineHeight: 1,
    '& svg': {
      fill: neutral.white,
    },
  },
  headerImage: {
    maxWidth: '3rem',
    maxHeight: '3rem',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '7.5rem',
      maxHeight: '7.5rem',
    },
  },
  title: {
    marginTop: '0.75rem',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    lineHeight: 1.4,
    textAlign: 'center',
    color: 'inherit',
    [theme.breakpoints.up('sm')]: {
      marginTop: '1rem',
      fontSize: '2.5rem',
    },
  },
  main: {
    padding: '1.5rem',
  },
  actions: {
    padding: '1.5rem',
    columnGap: '1rem',
    borderTop: `1px solid ${neutral.D11}`,
  },
  actionsSecondaryBtn: ({ variant }: { variant: VariantCustomDialog02 }) => ({
    padding: '0.5rem 1rem',
    fontSize: '0.75rem',
    textDecoration: 'underline',
    color: VARIANTS[variant].color,
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.875rem',
    },
  }),
  actionsPrimaryButton: ({ variant }: { variant: VariantCustomDialog02 }) => ({
    padding: '0.5rem 1rem',
    fontSize: '0.75rem',
    backgroundColor: VARIANTS[variant].color,
    '&:hover': {
      backgroundColor: `${VARIANTS[variant].color}CE`,
    },
    '&.Mui-disabled': {
      color: neutral.D15,
      backgroundColor: neutral.D10,
    },
    '& svg.MuiCircularProgress-svg': {
      color: VARIANTS[variant].color,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.875rem',
    },
  }),
}));
