// React
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// Components
import OrdersKanbanAdvancedFiltersToolbar from './OrdersKanbanAdvancedFiltersToolbar';

const mapStateToProps = (
  {
    partnerReducer: {
      profile: { stores },
    },
  },
  { intl: { formatMessage } },
) => {
  const totalStores = stores.length;
  const showStoresFilterButton = totalStores > 1;

  return { showStoresFilterButton, totalStores };
};

const mapDispatchToProps = {};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(OrdersKanbanAdvancedFiltersToolbar),
);
