// Consts
import urls from '../../constants/Urls';
// Consts
import { OrderDeliveryMethodType } from '../../constants/OrderDeliveryMethodType';
// Utils
import UrlUtils from '../../utils/url-utils';
// Services
import http from '../HttpService';
import LocalStorageService from '../local-storage-service';

const getNoRTIssueSuggestions = (orderId: number) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(
    urls.GET_NO_RT_ISSUE_SUGGESTIONS,
    {
      orderId,
    },
  );
  return http.get(`${microserviceUrl}${replacedUrl}`);
};

const getStockOutIssueSuggestions = (
  deliveryMethod: OrderDeliveryMethodType,
) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(
    urls.GET_STOCK_OUT_ISSUE_SUGGESTIONS,
    {
      deliveryMethod,
    },
  );
  return http.get(`${microserviceUrl}${replacedUrl}`);
};

const getSupportReasons = async (
  isStorekeeperFinal: boolean,
  deliveryMethod: any,
) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.GET_SUPPORT_REASONS, {
    isStorekeeperFinal,
    deliveryMethod,
  });
  return http.get(`${microserviceUrl}${replacedUrl}`);
};

const getWithRTIssueSuggestions = () => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  return http.get(`${microserviceUrl}${urls.GET_WITH_RT_ISSUE_SUGGESTIONS}`);
};

const supportIssueSuggestionsService = {
  getNoRTIssueSuggestions,
  getStockOutIssueSuggestions,
  getSupportReasons,
  getWithRTIssueSuggestions,
};

export default supportIssueSuggestionsService;
