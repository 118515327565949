import Amplitude from '../../../../tools/amplitude/amplitude';
import { Events } from './Analytics.events';

const logOpenOrderStatus = params =>
  Amplitude.logEvent(Events.openOrderStatus.name, params);
const logOpenChat = params => Amplitude.logEvent(Events.openChat.name, params);
const logEditOrder = params =>
  Amplitude.logEvent(Events.editOrder.name, params);
const logOrderActions = params =>
  Amplitude.logEvent(Events.orderActions.name, params);
const logSearchItem = params =>
  Amplitude.logEvent(Events.addSearchItem.name, params);
const logContinueSummary = params =>
  Amplitude.logEvent(Events.continueSummary.name, params);
const logRemoveChanges = params =>
  Amplitude.logEvent(Events.removeChanges.name, params);
const logSendSubstitutionRequest = params =>
  Amplitude.logEvent(Events.sendSubstitutionRequest.name, params);
const logAcceptOrder = params =>
  Amplitude.logEvent(Events.acceptOrder.name, params);
const logInactivityActions = params =>
  Amplitude.logEvent(Events.inactivityActions.name, params);
const logSelectOutOfStockItem = params =>
  Amplitude.logEvent(Events.selectOutOfStockItem.name, params);
const logClickAcceptOrderByReplaceWithoutEditing = params =>
  Amplitude.logEvent(
    Events.clickAcceptOrderByReplaceWithoutEditing.name,
    params,
  );

export const AnalyticActions = {
  logOpenOrderStatus,
  logOpenChat,
  logEditOrder,
  logOrderActions,
  logSearchItem,
  logContinueSummary,
  logRemoveChanges,
  logSendSubstitutionRequest,
  logAcceptOrder,
  logInactivityActions,
  logSelectOutOfStockItem,
  logClickAcceptOrderByReplaceWithoutEditing,
};
