import { useFormatMessage } from '../../../intl/intl';

export function useLang({ paymentMethod = '' }: { paymentMethod: string }) {
  const titleLang = 'orderDetailDialogMarketplaceBox.title';
  const pmLang = 'orderDetailDialogMarketplaceBox.paymentMethod';
  const descriptionLang = 'orderDetailDialogMarketplaceBox.description';
  const paymentMethodMsg = useFormatMessage({ id: pmLang }, { paymentMethod });
  return {
    title: useFormatMessage({ id: titleLang }),
    description: useFormatMessage({ id: descriptionLang }),
    paymentMethodMsg,
  };
}
