// Material UI
import { makeStyles } from '@material-ui/core';
// Constants
import Colors from '../../../constants/Colors';
import { neutral } from '../../../shared/styles/colors';

export default makeStyles({
  countryOutlinedInput: {
    padding: '13px 0px 13px 10px !important',
  },
  icon: {
    top: 'calc(50% - 12px)',
    color: neutral.D15,
    right: 0,
    position: 'absolute',
    pointerEvents: 'none',
    marginRight: '8px',
  },
  flag: {
    height: '20px',
    marginRight: 16,
    width: '28px',
  },
  helperText: {
    margin: '8px 0px 0px 2px',
  },
  root: {
    width: '100%',
  },
  rootInput: {
    margin: 0,
    position: 'relative',
    width: '100%',
    // Names depend on the classes that appear on the element that is needed to be modified.
    '& $notchedOutlineInput': {
      borderColor: `${neutral.D10} !important`,
    },
    '&:hover $notchedOutlineInput': {
      borderColor: `${Colors.PRIMARY_COLOR} !important`,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        borderColor: `${Colors.PRIMARY_COLOR} !important`,
      },
    },
    '&$focusedInput $notchedOutlineInput': {
      borderColor: `${Colors.PRIMARY_COLOR} !important`,
      borderWidth: 2,
    },
    '&$disabledInput $notchedOutlineInput': {
      borderColor: `${neutral.D10} !important`,
    },
    '&$error $notchedOutlineInput': {
      borderColor: `${Colors.DANGER_COLOR} !important`,
    },
  },
  /* Styles applied to the root element if `disabled={true}`. Don't delete*/
  disabledInput: {},
  /* Styles applied to the `error` element. Don't delete */
  error: {},
  /* Styles applied to the root element if the component is focused. Don't delete*/
  focusedInput: {},
  /* Styles applied to the `NotchedOutline` element. Don't delete*/
  notchedOutlineInput: {},
});
