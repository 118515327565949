import { useSelector } from 'react-redux';

import { selectors as partnerSelectors } from '../../redux/partner';

const useStores = () => useSelector(partnerSelectors.selectStoresWithSupended);

const useProfile = () => useSelector(partnerSelectors.selectProfile);

const Hooks = { useStores, useProfile };

export default Hooks;
