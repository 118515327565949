// External libs
import { createSelector } from 'reselect'
// Interfaces
import { State } from '../interfaces'
import { Button } from '../interfaces/button'

const selectSlice: (state: any) => State = (state: any) => state.dynInAppDialog

const selectOpen = createSelector<any, State, boolean>(
  selectSlice,
  reducer => reducer.open,
)
const selectTitle = createSelector<any, State, string>(
  selectSlice,
  reducer => reducer.title,
)
const selectDescription = createSelector<any, State, string>(
  selectSlice,
  reducer => reducer.description,
)
const selectMainButton = createSelector<any, State, Button | null | undefined>(
  selectSlice,
  reducer => reducer.mainButton,
)
const selectCloseButton = createSelector<any, State, Button | null | undefined>(
  selectSlice,
  reducer => reducer.closeButton,
)
const selectColor = createSelector<any, State, string>(
  selectSlice,
  reducer => reducer.color,
)
const selectShouldSound = createSelector<any, State, boolean>(
  selectSlice,
  reducer => reducer.shouldSound,
)

const selectors = {
  selectOpen,
  selectTitle,
  selectDescription,
  selectMainButton,
  selectCloseButton,
  selectColor,
  selectShouldSound,
}

export default selectors
