import React from 'react';
import { Search, Close, ErrorOutline } from '@material-ui/icons';
import { Box, Typography } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { PreviousQueries, SuggestionItemsSection } from './components';
import backArrow from '../../../../../assets/images/icons/backArrow.svg';

import makeClasses from './OrderManagementItemSearch.style';
import {
  ItemSuggestion,
  MAX_LENGTH_SEARCH_CHARACTERS,
  OrderMgmtItem,
} from '../../../../../business/order-mgmt';

export function OrderManagementItemSearch({
  searcherInputText,
  soldOutProductText,
  replaceWithText,
  chooseOptionsText,
  item,
  results,
  searchedQuery,
  getRecentlySearchedQueries,
  suggestionItems,
  isLoading,
  isEmptyResults,
  isSearchRejected,
  isSuccessfulWithData,
  isSearcherDisabled,
  setSuggestionItems,
  handleClear,
  handleChange,
  handleSelectPreviousQuery,
  handlePrevItem,
  showBackButton,
  currentIndexItem,
  totalItemsToReplace,
}: Props) {
  const classes = makeClasses();

  return (
    <>
      <Box
        component='header'
        display='flex'
        flexDirection='column'
        gridGap='1rem'
        marginBottom='0.5rem'
      >
        <Box display='flex' alignItems='center' gridGap='0.5rem'>
          {showBackButton && (
            <img
              className={classes.backIcon}
              width={24}
              height={24}
              onClick={handlePrevItem}
              src={backArrow}
              alt='back-arrow'
            />
          )}
          <Typography className={classes.title}>
            {`${soldOutProductText} ${currentIndexItem} / ${totalItemsToReplace}`}
          </Typography>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-start'
          className={classes.itemToReplace}
        >
          <Box
            width={60}
            height={60}
            padding='1rem'
            className={classes.itemUnits}
          >{`${item?.units}X`}</Box>
          <Typography className={classes.itemName}>{item?.name}</Typography>
        </Box>
        <Box display='flex' justifyContent='space-between' padding='0.5rem 0'>
          <Typography className={classes.replaceDisclaimer}>
            {replaceWithText}
          </Typography>
          <Box
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            gridGap='0.25rem'
          >
            <ErrorOutline className={classes.replaceIcon} />
            <Typography className={classes.replaceText}>
              {chooseOptionsText}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box component='main' gridArea='main'>
        <Box position='relative'>
          <TextField
            autoFocus
            fullWidth
            role='search'
            variant='outlined'
            value={searchedQuery}
            onChange={handleChange}
            disabled={isSearcherDisabled}
            placeholder={searcherInputText}
            classes={{ root: classes.searcher }}
            InputProps={{
              startAdornment: <Search className={classes.searcherStartIcon} />,
              inputProps: {
                maxLength: MAX_LENGTH_SEARCH_CHARACTERS,
              },
              endAdornment: (
                <Close
                  onClick={handleClear}
                  className={classes.searcherEndIcon}
                  visibility={Boolean(searchedQuery) ? 'visible' : 'hidden'}
                />
              ),
            }}
          />
          <PreviousQueries
            show={!Boolean(searchedQuery)}
            previousQueries={getRecentlySearchedQueries({
              itemType: item?.isProduct ? 'product' : 'topping',
            })}
            onClick={handleSelectPreviousQuery}
          />
        </Box>
        <SuggestionItemsSection
          items={results}
          setSuggestionItems={setSuggestionItems}
          loading={isLoading}
          empty={isEmptyResults}
          error={isSearchRejected}
          success={isSuccessfulWithData}
          suggestionItems={suggestionItems}
        />
      </Box>
    </>
  );
}

interface Props {
  searcherInputText: string;
  searcherDisclaimerText: string;
  soldOutProductText: string;
  replaceWithText: string;
  searcherDisclaimerCtaText: string;
  chooseOptionsText: string;
  editCountdownText: string;
  item: ItemSuggestion;
  results: OrderMgmtItem[];
  searchedQuery: string;
  getRecentlySearchedQueries: any;
  suggestionItems: OrderMgmtItem[];
  isLoading: boolean;
  isEmptyResults: boolean;
  isSearchRejected: boolean;
  isSuccessfulWithData: boolean;
  isSearcherDisabled: boolean;
  setSuggestionItems: any;
  handleClear: any;
  handleChange: any;
  handleSelectPreviousQuery: any;
  handlePrevItem: any;
  showBackButton: boolean;
  currentIndexItem: number;
  totalItemsToReplace: number;
}
