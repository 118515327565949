// Material UI
import { makeStyles } from '@material-ui/core'
import Colors from '../../../../constants/Colors'

export default makeStyles(theme => ({
  root: {
    width: 'auto',
    height: 'auto',
    backgroundColor: Colors.WHITE,
    borderRadius: 11,
  },
  categoryContainer: {
    gridRowGap: 66,
    padding: 32,
    display: 'grid',
    gridTemplateAreas: `"title search"
                       "subCategoriesContainer subCategoriesContainer"`,
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    gridArea: 'title',
  },
  search: { gridArea: 'search' },
  subCategory: {
    width: 470,
  },
  categoryTitle: {
    paddingBottom: 14,
    fontSize: 18,
    fontWeight: 600,
  },
  icon: {},
  article: {
    cursor: 'pointer',
    display: 'flex',
    gridGap: 13,
    paddingBottom: 14,
    fontSize: 12,
    alignItems: 'center',
  },
  subCategoriesContainer: {
    gridArea: 'subCategoriesContainer',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: 24,
  },
  arrow: {
    width: 20,
    height: 20,
  },
}))
