// Material UI
import { makeStyles } from '@material-ui/styles'
// Consts
import Colors from '../../../../../constants/Colors'

export default makeStyles(theme => ({
  root: {
    backgroundColor: Colors.DANGER_LIGHT_COLOR,
    color: Colors.RED_ORANGE,
  },
}))
