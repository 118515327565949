import { useFormatMessage } from '../../../../../../../intl/intl';

export function useProductSuggestionsLang({ productName }: Props) {
  const userCanChooseText = useFormatMessage({
    id: `orderManagement.userCanChooseText`,
  });
  const deactivateItemsDescriptionText = useFormatMessage(
    { id: 'orderManagement.deactivateItems.disclaimerDescription' },
    {
      item: useFormatMessage({ id: `orderManagement.product` }),
      itemName: productName,
    },
  );
  return {
    userCanChooseText,
    deactivateItemsDescriptionText,
  };
}
type Props = {
  productName: string;
};
