// Redux
import { useSelector } from 'react-redux'
import OrdersSelector from '../../../redux/selectors/orders-selectors/orders.selectors'
// Selectors
import Selectors from '../selectors'

const useShowMarketplaceBox = () =>
  useSelector(Selectors.selectShowMarketplaceBox)

const useShowPickupBox = () => useSelector(Selectors.selectShowPickupBox)

const useDeliveryMethod = () =>
  useSelector(OrdersSelector.selectOrderDeliveryMethodInOrderDetailDialog)

const Hooks = {
  useShowMarketplaceBox,
  useShowPickupBox,
  useDeliveryMethod,
}

export default Hooks
