import { useFormatMessage } from '../../../intl/intl'
import DeactivationType from '../../../constants/DeactivationType'

export function useTexts() {
  return {
    radioButtonOptions: [
      {
        id: DeactivationType.DAILY, //today
        label: useFormatMessage({
          id: 'productAndToppingsDeactivationTypes.today.label',
        }),
        description: useFormatMessage({
          id: 'productAndToppingsDeactivationTypes.today.description',
        }),
      },
      {
        id: DeactivationType.CUSTOM, //custom
        label: useFormatMessage({
          id: 'productAndToppingsDeactivationTypes.custom.label',
        }),
        description: useFormatMessage({
          id: 'productAndToppingsDeactivationTypes.custom.description',
        }),
      },
      {
        id: DeactivationType.PERMANENT, //permanent
        label: useFormatMessage({
          id: 'productAndToppingsDeactivationTypes.permanent.label',
        }),
        description: useFormatMessage({
          id: 'productAndToppingsDeactivationTypes.permanent.description',
        }),
      },
    ],
    button: {
      cancel: useFormatMessage({
        id: 'productAndToppingsDeactivationDrawer.cancel.button',
      }),
      turnoff: useFormatMessage({
        id: 'productAndToppingsDeactivationDrawer.turnoff.button',
      }),
    },
    title: useFormatMessage({
      id: 'productAndToppingsDeactivationDrawer.title',
    }),
    toppingTitle: useFormatMessage({
      id: 'productAndToppingsDeactivationDrawer.topping.title',
    }),
  }
}
