// React
import { useCallback } from 'react';
// Consts
import { textIds } from './consts';
// Tools
import { useFormatMessage } from '../../intl/intl';
// Hooks
import hooks from './hooks';
// Components
import CustomDialog from '../common/custom-dialog';
// Styles
import useStyles from './styles';
// Material UI
import { useDispatch } from 'react-redux';
import { useTheme, useMediaQuery } from '@material-ui/core';
// Actions
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';
// Image
import { CustomLink } from '../common/custom-link';
import CustomButton, {
  Colors,
  Variants,
} from '../common/buttons/custom-button';

export function ErrorPMFlowDialog() {
  const dispatch = useDispatch();
  const componentClasses = useStyles();
  const open = hooks.useOpen();
  const title = useFormatMessage(textIds.titleError);
  const info = useFormatMessage(textIds.info);
  const submitText = useFormatMessage(textIds.submitButton);
  const description = useFormatMessage(textIds.description);
  const partnerPortal = useFormatMessage(textIds.partnerPortal);
  const theme = useTheme<any>();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const handleClose = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  const onConfirm = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  const handlePartnerPortalURLClick = useCallback(() => {
    window.open(process.env.REACT_APP_PARTNER_PORTAL_MY_STORES_URL, '_blank');
  }, []);

  return (
    <CustomDialog
      classes={{
        childrenContainer: componentClasses.body,
        headerRoot: componentClasses.headerRoot,
        paper: componentClasses.paper,
      }}
      actions={
        isMdUp
          ? [
              {
                text: submitText,
                onClick: onConfirm,
                color: 'primary',
                variant: 'contained',
                fullWidth: false,
              },
            ]
          : []
      }
      CustomDialogHeaderChildren={''}
      onClose={handleClose}
      open={open}
      showBackButton={false}
      showCloseButton
      title={title}
      disableBackdropClick
      disableEnforceFocus
      disableEscapeKeyDown
    >
      <div className={componentClasses.root}>
        <div className={componentClasses.icon}>
          <img
            className={componentClasses.iconImage}
            src={process.env.REACT_APP_PM_FLOW_INFO_ICON}
            alt='info-icon'
          />
        </div>
        <div className={componentClasses.text}>{info}</div>
        <div className={componentClasses.info}>
          {description}
          <CustomLink color='primary' onClick={handlePartnerPortalURLClick}>
            {partnerPortal}
          </CustomLink>
        </div>
      </div>
      {!isMdUp && (
        <div className={componentClasses.containerButtons}>
          <CustomButton
            className={componentClasses.footerButtonSubmit}
            color={Colors.PRIMARY}
            fullWidth
            onClick={onConfirm}
            variant={Variants.CONTAINED}
          >
            {submitText}
          </CustomButton>
        </div>
      )}
    </CustomDialog>
  );
}
