// React
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
// Utils
import DateTimeUtils from '../../utils/DateTimeUtils'
// Actions
import AppActions from '../../redux/actions/app-actions'
// Components
import NewSpecialDayDialog from './NewSpecialDayDialog'
import newSpecialDayDialogActions from '../../redux/actions/newSpecialDayDialog.actions'
import storesSchedulesActions from '../../redux/actions/storesSchedules.actions'

const mapStateToProps = (
  {
    partnerReducer: { profile },
    storesSchedulesReducer: {
      filter: { selectedStoreId },
      storesSchedules,
    },
    newSpecialDayDialogReducer: { open },
  },
  { intl: { formatMessage } },
) => {
  const specialDayDatePickerLabel = formatMessage({
    id: 'newSpecialDayDialog.datePickerLabel',
  })
  const minLengthErrorHelperTextForName = formatMessage({
    id: 'newSpecialDayDialog.minLengthErrorHelperTextForName',
  })
  const specialDayNameLabel = formatMessage({
    id: 'newSpecialDayDialog.nameLabel',
  })
  const placeHolder = formatMessage({
    id: 'newSpecialDayDialog.placeHolder',
  })
  const titleSpecialDay = formatMessage({
    id: 'newSpecialDayDialog.title',
  })
  const cancel = formatMessage({
    id: 'cancel',
  })
  const confirm = formatMessage({
    id: 'globals.confirm',
  })

  const defaultDate = new Date()
  const partnerId = profile.id
  const maxDate = DateTimeUtils.addDays(defaultDate, 60)
  const minDate = defaultDate

  return {
    confirm,
    cancel,
    placeHolder,
    defaultDate,
    maxDate,
    minDate,
    minLengthErrorHelperTextForName,
    specialDayNameLabel,
    specialDayDatePickerLabel,
    open,
    titleSpecialDay,
    selectedStoreId,
    partnerId,
  }
}

const mapDispatchToProps = {
  openSnackBarError: AppActions.openSnackBarError,
  onClose: newSpecialDayDialogActions.closeNewSpecialDayDialog,
  onConfirm: storesSchedulesActions.addSpecialDay,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(NewSpecialDayDialog),
)
