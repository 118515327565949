import { useFormatMessage } from '../../../../../../intl/intl';

import type { OrderId } from '../../../../../../business/shared/domain';

export function useDialogLang(props: UseDialogLangProps) {
  const { deliveryCost = NaN, createdOrderId = NaN } = props;

  return {
    title: useFormatMessage({ id: 'cargo.coverage.title' }),
    banner: {
      title: useFormatMessage({ id: 'cargo.disclaimer.hasCoverage.title' }),
      description: useFormatMessage(
        { id: 'cargo.disclaimer.hasCoverage.description' },
        { amount: deliveryCost },
      ),
    },
    form: {
      clientName: useFormatMessage({ id: 'cargo.form.userName' }),
      clientAddress: useFormatMessage({ id: 'cargo.form.deliveryAddress' }),
      clientAddressComplement: useFormatMessage({
        id: 'cargo.form.addressDetail',
      }),
      partialValue: useFormatMessage({
        id: 'cargo.addRT.form.partialValue.label',
      }),
      partialValueDetail: useFormatMessage({
        id: 'cargo.addRT.form.partialValue.detail',
      }),
      error: {
        required: useFormatMessage({ id: 'cargo.form.validation.required' }),
      },
    },
    action: {
      cancel: useFormatMessage({
        id: 'cargo.coverage.notAvailable.backButton',
      }),
      create: useFormatMessage({ id: 'cargo.coverage.hasCoverage.nextButton' }),
    },
    success: {
      title: useFormatMessage({
        id: 'cargo.coverage.orderSuccessfullyCreatedTitle',
      }),
      description: useFormatMessage(
        { id: 'cargo.coverage.orderSuccessfullyCreatedDescription' },
        { orderId: createdOrderId },
      ),
    },
    error: {
      title: useFormatMessage({
        id: 'cargo.coverage.orderRejectedCreatedTitle',
      }),
      description: useFormatMessage({
        id: 'cargo.coverage.orderRejectedCreatedDescription',
      }),
    },
  };
}

interface UseDialogLangProps {
  // TODO: deliveryCost should be from of 'Cargo' domain ??
  deliveryCost: number | undefined;
  createdOrderId: OrderId | null;
}
