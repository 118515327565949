import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import OrdersUtils from '../../../utils/OrdersUtils';
import OrdersSelectors from '../../../redux/selectors/orders-selectors';
import RTDialogSelectors from '../../../redux/selectors/rt-dialog-selectors';
import DialogsManagerActions from '../../../redux/actions/dialogsManager.actions';

const useHandleBack = dispatch => {
  return useCallback(async () => {
    DialogsManagerActions.back()(dispatch);
  }, [dispatch]);
};

const useOpen = () => useSelector(RTDialogSelectors.selectOpen);

const useShowMoreBtns = () => {
  const orderId = useSelector(RTDialogSelectors.selectOrderId);
  const orders = useSelector(OrdersSelectors.selectOrders);
  const order = orders.find(o => o.id === orderId);
  const isBeingManaged = OrdersUtils.isBeingManaged(order);
  const isRTVisible = order?.detail?.storekeeper?.visible ?? false;
  return isBeingManaged && isRTVisible;
};

function useSimplifiedOrderInfo() {
  const orderId = useSelector(RTDialogSelectors.selectOrderId);
  const orders = useSelector(OrdersSelectors.selectOrders);
  const order = orders.find(o => o.id === orderId);
  return {
    orderId: orderId ?? NaN,
    storeId: order?.store_id ?? NaN,
  };
}

const Hooks = {
  useHandleBack,
  useOpen,
  useShowMoreBtns,
  useSimplifiedOrderInfo,
};

export default Hooks;
