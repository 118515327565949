// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import Colors from './../../../constants/Colors';
import MessageOriginType from '../../../constants/MessageOriginType';
import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => ({
  textMessage: ({ originType }) => ({
    color: neutral.D20,
    fontSize: '15px',
  }),
  timeDiff: {
    color: neutral.D15,
    fontSize: '13px',
  },
  textMessageContainer: ({ originType }) => ({
    alignSelf:
      originType === MessageOriginType.SENDER ? 'flex-start' : 'flex-end',
    backgroundColor:
      originType === MessageOriginType.SENDER ? Colors.CHABLIS : Colors.ICEBERG,
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Poppins',
    lineHeight: 'normal',
    letterSpacing: '0',
    marginBottom: '30px',
    marginLeft: originType === MessageOriginType.SENDER ? '0px' : '30px',
    marginRight: originType === MessageOriginType.SENDER ? '30px' : '0px',
    padding: '10px',
    wordBreak: 'break-word',
  }),
}));
