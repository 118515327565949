// React
import { connect } from 'react-redux'
// Actions
import AppActions from '../../redux/actions/app-actions'
import DialogsManagerActions from '../../redux/actions/dialogsManager.actions'
// Components
import RTMap from './RTMap'

const mapStateToProps = ({ rtDialogReducer: { orderId }, ordersReducer }) => {
  const order = ordersReducer.orders.find(order => order.id === +orderId)
  const orderDetail =
    order && order.detail
      ? order.detail
      : {
          id: 0,
          products: {},
        }

  const RT = orderDetail.storekeeper || {}
  const store =
    orderDetail.stores && orderDetail.stores.length ? orderDetail.stores[0] : {}

  return {
    RT,
    store,
    orderId: orderDetail.id,
  }
}

const mapDispatchToProps = {
  openSnackBarError: AppActions.openSnackBarError,
  openDialogReleaseRT: DialogsManagerActions.openRTReleaseConfirmationDialog,
}

export default connect(mapStateToProps, mapDispatchToProps)(RTMap)
