// React
import React from 'react'
// Interfaces
import { Props } from './props'
// External libs
import ReactMarkdown from 'react-markdown'
// Styles
import { useTheme, useMediaQuery } from '@material-ui/core'
import useStyles from './styles'
import supportImg from '../../../../assets/images/onboarding/img-profile.png'

const OnBoardingTourBody: React.FC<Props> = ({
  description = '',
  image = '',
}) => {
  const componentClasses = useStyles()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <div className={componentClasses.container}>
      <div className={componentClasses.banner}>
        <img
          className={componentClasses.img}
          src={image}
          alt='onboarding-tour-img'
        />
      </div>
      {isMdUp && (
        <div className={componentClasses.supportIcon}>
          <img
            className={componentClasses.imgSupport}
            src={supportImg}
            alt='support'
          />
        </div>
      )}
      <div className={componentClasses.description}>
        <ReactMarkdown className={componentClasses.markDownDescription}>
          {description}
        </ReactMarkdown>
      </div>
    </div>
  )
}

export default OnBoardingTourBody
