import {
  AnalyticActions,
  AnalyticProperties,
  CargoSources,
  cargoBehaviorType,
  coverageValues,
  handleProperties,
  optionValues,
} from './core';

export const CargoAnalytics = {
  whenClickOption,
  whenVerifyCoverage,
  whenSendRequest,
  whenTrackingByLink,
  whenCancelOrder,
} as const;

function whenClickOption(partnerId: number, option: optionValues) {
  const props: AnalyticProperties = {
    partnerId,
    option,
    action: 'menu',
  };
  AnalyticActions.logCargo(handleProperties(props));
}

function whenVerifyCoverage(partnerId: number, coverage: coverageValues) {
  const props: AnalyticProperties = {
    partnerId,
    option: 'request_rt',
    action: 'verify_coverage',
    coverage,
  };
  AnalyticActions.logCargo(handleProperties(props));
}

function whenSendRequest(partnerId: number, behavior: cargoBehaviorType) {
  const cargoSources: CargoSources = {
    detailMarketplace: 'marketplace',
    orderHistorical: 'defect_switch',
    header_kanban: 'open_market',
  };

  const props: AnalyticProperties = {
    partnerId,
    option: 'request_rt',
    action: 'send_request',
    coverage: 'inside',
    cargoType: cargoSources[behavior] || '',
  };
  AnalyticActions.logCargo(handleProperties(props));
}

function whenTrackingByLink(partnerId: number, orderId: number) {
  const props: AnalyticProperties = {
    partnerId,
    option: 'orders',
    action: 'tracking_link',
    orderId,
  };
  AnalyticActions.logCargo(handleProperties(props));
}

function whenCancelOrder(partnerId: number, orderId: number) {
  const props: AnalyticProperties = {
    partnerId,
    option: 'orders',
    action: 'cancel_order',
    orderId,
  };
  AnalyticActions.logCargo(handleProperties(props));
}
