// External libs
import { createSelector } from 'reselect';
import { groupBy } from 'lodash';
import { ScheduleDay, Interval, Day } from './interfaces';

const selectSlice = (state: any) => state.schedulePMFlowDialog;

const selectOpen = createSelector(selectSlice, reducer => reducer.open);
const selectFormId = createSelector(selectSlice, reducer => reducer.formId);

const selectIntervals = createSelector(
  selectSlice,
  reducer => reducer.intervals,
);
const selectDays = createSelector(selectSlice, reducer => reducer.days);

const selectStoreSchedule = createSelector(
  selectDays,
  selectIntervals,
  (days: Day[], intervals: Interval[]): ScheduleDay[] => {
    const indexedIntervals = groupBy<Interval>(intervals, 'day');
    const scheduleDays: ScheduleDay[] = [];
    for (const day of days) {
      const dayIntervals: Interval[] | undefined = indexedIntervals[day.code];
      const scheduleDay: ScheduleDay = {
        code: day.code,
        dayName: day.dayName,
        intervals: dayIntervals || [],
      };
      scheduleDays.push(scheduleDay);
    }

    return scheduleDays;
  },
);
const selectBeingEditedIntervalId = createSelector(
  selectSlice,
  reducer => reducer.beingEditedIntervalId,
);

const selectors = {
  selectOpen,
  selectFormId,
  selectStoreSchedule,
  selectBeingEditedIntervalId,
};

export default selectors;
