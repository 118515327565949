import { useEffect } from 'react';

import MainLayout from '../main-layout';
import SettingsOptionsAndExtensions from '../settings-options-and-extensions';

import useStyles from './SettingsProfile.style';

function SettingsProfile({ title, titleBox, getSettings = () => {} }) {
  const componentClasses = useStyles();

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <MainLayout title={title}>
      <div className={componentClasses.container}>
        <div className={componentClasses.titleStyles}>{titleBox}</div>
        <div className={componentClasses.root}>
          <SettingsOptionsAndExtensions />
        </div>
      </div>
    </MainLayout>
  );
}

export default SettingsProfile;
