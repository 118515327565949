import { clsx } from 'clsx';
import { get, find } from 'lodash';
import { useTheme, useMediaQuery } from '@material-ui/core';

import hooks from './hooks';

import CustomDialog from '../custom-dialog/CustomDialog';
import { CustomBackButton } from '../buttons/custom-back-button';
import { CustomCloseButton } from '../buttons/custom-close-button';
import CustomButton, { Colors, Variants } from '../buttons/custom-button';

import useStyles from './styles';

import type { Props } from './interfaces';

export function CustomBannerDialog(props: Props) {
  const {
    classes = {},
    onClickBackButton = () => {},
    onClose = () => {},
    onRefuse = () => {},
    onConfirm = () => {},
    open = false,
    showBackButton = false,
    showCloseButton = false,
    disableBackdropClick = false,
    disableEnforceFocus = false,
    disableEscapeKeyDown = false,
    bannerImage,
    cancelButtonText = '',
    submitButtonText = '',
    bannerDescription = '',
    children,
  } = props;

  const theme = useTheme<any>();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const mediaQueryCode = hooks.useMediaQueryCode(theme);
  const imageSelected = find(bannerImage, { breakpoint: mediaQueryCode });
  const image = get(imageSelected, 'url', '');

  const _classes = useStyles({ image });

  return (
    <CustomDialog
      classes={{
        container: clsx(_classes.container, classes?.container),
        paper: clsx(_classes.paper, classes?.paper),
        root: clsx(_classes.root, classes?.root),
      }}
      actions={
        isMdUp
          ? [
              {
                text: cancelButtonText,
                onClick: onRefuse,
                fullWidth: false,
                disabled: false,
                variant: 'outlined',
                color: 'tertiary',
              },
              {
                text: submitButtonText,
                onClick: onConfirm,
                fullWidth: false,
                color: 'primary',
                variant: 'contained',
              },
            ]
          : []
      }
      onClose={onClose}
      open={open}
      disableBackdropClick={disableBackdropClick}
      disableEnforceFocus={disableEnforceFocus}
      disableEscapeKeyDown={disableEscapeKeyDown}
      showDialogHeader={false}
    >
      <>
        <div className={clsx(_classes.box, classes?.box)}>
          <div className={clsx(_classes.header, classes?.header)}>
            {showCloseButton && (
              <div className={clsx(_classes.closeButton, classes?.closeButton)}>
                <CustomCloseButton onClick={onClose} />
              </div>
            )}
            {showBackButton && (
              <div className={clsx(_classes.backButton, classes?.backButton)}>
                <CustomBackButton onClick={onClickBackButton} />
              </div>
            )}
          </div>
          <div
            className={clsx(
              _classes.bannerImageDescription,
              classes?.bannerImageDescription,
            )}
          >
            {bannerDescription}
          </div>
        </div>
        <div
          className={clsx(
            _classes.childrenContainer,
            classes?.childrenContainer,
          )}
        >
          {children}
        </div>
        {!isMdUp && (
          <div
            className={clsx(
              _classes.containerButtons,
              classes?.containerButtons,
            )}
          >
            <CustomButton
              className={clsx(
                _classes.footerButtonSubmit,
                classes?.footerButtonSubmit,
              )}
              color={Colors.PRIMARY}
              fullWidth
              onClick={onConfirm}
              variant={Variants.CONTAINED}
            >
              {submitButtonText}
            </CustomButton>

            <CustomButton
              className={clsx(
                _classes.footerRejectButton,
                classes?.footerRejectButton,
              )}
              color={Colors.PRIMARY}
              fullWidth
              onClick={onRefuse}
              variant={Variants.OUTLINED}
            >
              {cancelButtonText}
            </CustomButton>
          </div>
        )}
      </>
    </CustomDialog>
  );
}
