import { useState } from 'react';
import { Box, Typography } from '@material-ui/core';

import { CancelModal } from './comps';
import CustomButton from '../../../../../../../../components/common/buttons/custom-button/CustomButton';
import { SearchRTStateLabel } from '../../../../../../../../components/rt-state-label/components/searching-rt-state-label';
import { InStoreRTStateLabel } from '../../../../../../../../components/rt-state-label/components/in-store-rt-state-label';
import { OnTheWayRTStateLabel } from '../../../../../../../../components/rt-state-label/components/on-the-way-rt-state-label';

import { useKanbanLang } from '../../../../hooks/useKanbanLang';
import { CargoAnalytics } from '../../../../../../../../business/cargo/analytics';

import {
  Colors,
  Variants,
} from '../../../../../../../../components/common/buttons/custom-button';
import useListStyles from '../../../../list.styles';

import type {
  CargoOrder,
  CargoOrderStatus as OrderStatusType,
} from '../../../../../../../../business/cargo';

export function OrderCard({ order, partnerId }: OrderCardProps) {
  const styles = useListStyles();
  const {
    id,
    storeName,
    clientName,
    phoneNumber,
    status,
    followUpLink,
  } = order;

  return (
    <Box
      className={styles.orderCardContainer}
      width='100%'
      borderRadius='0.5rem'
    >
      <Box
        display='flex'
        alignItems='stretch'
        flexDirection='column'
        padding='1rem'
        width='100%'
      >
        <OrderInfo id={id} storeName={storeName} />
        <Divider />
        <OrderMain
          clientName={clientName}
          phoneNumber={phoneNumber}
          status={status}
        />
        <OrderActions
          followUpLink={followUpLink}
          orderId={id}
          partnerId={partnerId}
        />
      </Box>
    </Box>
  );
}

function OrderInfo({ id, storeName }: OrderInfoProps) {
  const styles = useListStyles();

  return (
    <Box
      display='grid'
      gridAutoFlow='row'
      gridAutoRows='auto'
      fontWeight='bold'
      paddingBottom='0.5rem'
    >
      <Typography className={styles.orderId}>{`ID: ${id}`}</Typography>
      <Typography className={styles.storeName}>{storeName}</Typography>
    </Box>
  );
}

function OrderMain({ clientName, phoneNumber, status }: OrderMainProps) {
  const styles = useListStyles();

  return (
    <Box display='flex' justifyContent='space-between' margin='0.5rem 0'>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='flex-start'
      >
        <Typography className={styles.userName}>{clientName}</Typography>
        <Typography
          className={styles.phoneNumber}
        >{`Tel: ${phoneNumber}`}</Typography>
      </Box>
      <OrderStatus status={status} />
    </Box>
  );
}

function OrderStatus({ status }: OrderStatusProps) {
  const {
    orderCardFindingRTText,
    orderCardInProgressText,
    orderCardRTInStoreText,
    orderCardRTOnTheWayText,
    orderCardRTArrivedText,
    orderCardDeliveredToUserText,
  } = useKanbanLang();

  return (
    <Box paddingTop='0.5rem'>
      {status === 'inProgress' && (
        <InStoreRTStateLabel
          classes={{}}
          stateMessage={orderCardInProgressText}
        />
      )}
      {status === 'findingRT' && (
        <SearchRTStateLabel
          classes={{}}
          stateMessage={orderCardFindingRTText}
        />
      )}
      {status === 'RTinStore' && (
        <InStoreRTStateLabel
          classes={{}}
          stateMessage={orderCardRTInStoreText}
        />
      )}
      {status === 'RTOnTheWay' && (
        <OnTheWayRTStateLabel
          classes={{}}
          stateMessage={orderCardRTOnTheWayText}
        />
      )}
      {status === 'RTArrived' && (
        <InStoreRTStateLabel
          classes={{}}
          stateMessage={orderCardRTArrivedText}
        />
      )}
      {status === 'DeliveredToUser' && (
        <InStoreRTStateLabel
          classes={{}}
          stateMessage={orderCardDeliveredToUserText}
        />
      )}
    </Box>
  );
}

function Divider() {
  const styles = useListStyles();

  return <Box className={styles.divider}></Box>;
}

function OrderActions({ followUpLink, orderId, partnerId }: OrderActionsProps) {
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);

  const {
    orderCardCancelButtonText,
    orderCardTrackingLinkButtonText,
  } = useKanbanLang();

  const styles = useListStyles();

  return (
    <Box
      display='flex'
      justifyContent='flex-end'
      alignItems='center'
      gridGap='0.5rem'
    >
      <CustomButton
        className={styles.cardButton}
        variant={Variants.TEXT}
        color={Colors.SECONDARY}
        onClick={() => setIsOpenCancelModal(true)}
        fullWidth={false}
      >
        {orderCardCancelButtonText}
      </CustomButton>
      <CustomButton
        className={styles.cardButton}
        variant={Variants.CONTAINED}
        color={Colors.PRIMARY}
        onClick={() => {
          window.open(followUpLink, '_blank');
          CargoAnalytics.whenTrackingByLink(partnerId, orderId);
        }}
        fullWidth={false}
      >
        {orderCardTrackingLinkButtonText}
      </CustomButton>

      {isOpenCancelModal && (
        <CancelModal
          orderId={orderId}
          onClose={() => setIsOpenCancelModal(false)}
        />
      )}
    </Box>
  );
}

interface OrderCardProps {
  order: CargoOrder;
  partnerId: number;
}

interface OrderInfoProps {
  id: number;
  storeName: string;
}

interface OrderMainProps {
  clientName: string;
  phoneNumber: string;
  status: OrderStatusType;
}

interface OrderStatusProps {
  status: OrderStatusType;
}

interface OrderActionsProps {
  followUpLink: string;
  orderId: number;
  partnerId: number;
}
