// React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Constants
import { StoreSchedulesType } from '../../constants/StoreScheduleType'
// Redux
import storesSchedulesActions from '../../redux/actions/storesSchedules.actions'
// Components
import HolidayStoreScheduleTable from './HolidayStoreScheduleTable'

const mapStateToProps = (
  {
    partnerReducer: { profile },
    appReducer: { language },
    storesSchedulesReducer: {
      areStoresSchedulesLoading: isLoading,
      filter: { selectedStoreId },
      storesSchedules,
    },
  },
  { intl: { formatMessage } },
) => {
  const day = formatMessage({
    id: 'holidayStoreScheduleTable.day',
  })
  const partnerId = profile.id
  const storeSchedules = storesSchedules[selectedStoreId]

  const schedules =
    storeSchedules && storeSchedules.schedules[StoreSchedulesType.HOLIDAYS]

  return { day, isLoading, language, schedules, partnerId }
}

const mapDispatchToProps = {
  onClickAddSchedule: storesSchedulesActions.addHolidaySchedule,
  onClickDeleteSchedule: storesSchedulesActions.deleteHolidaySchedule,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(HolidayStoreScheduleTable),
)
