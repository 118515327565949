import { formatCurrency } from '../../../../utils/CurrencyUtils';
import {
  ItemSuggestion,
  ItemToReplace,
  OrderMgmtItem,
} from '../../domain/models';
import { OrderMgmtProduct } from '../../domain/models/orderMgmt-product.model';
import { OrderDetailResponse, ProductResponse } from '../core/responses';

const toSubstitutionProductItems = (
  item: OrderMgmtItem,
  substitutionItems: Array<OrderMgmtItem>,
) => ({
  itemId: item.id,
  productId: item?.productId,
  suggestions: substitutionItems,
});

const toSubstitutionToppingItems = (
  item: OrderMgmtItem,
  substitutionItems: Array<OrderMgmtItem>,
  itemCategoryIndex: number,
  itemCategoryDescription: string,
) => ({
  itemId: item.id,
  productId: item?.productId,
  suggestions: substitutionItems,
  toppingCategoryIndex: itemCategoryIndex,
  toppingCategoryDescription: itemCategoryDescription,
});

const toOrderMgmtDetail = (detail: OrderDetailResponse) => ({
  orderId: detail?.id ?? 0,
  products: detail?.products.map(product => toOrderMgmtProduct(product)) ?? [],
  subtotal: detail?.total_price ?? 0,
});

const toOrderMgmtProduct = (product: ProductResponse): OrderMgmtProduct => ({
  id: `${product?.id ?? ''}`,
  name: product?.name ?? '',
  description: product?.description ?? '',
  img: product?.image && product?.image !== 'NO-IMAGE' ? product?.image : '',
  units: product?.units ?? 0,
  price: product?.unit_price_product ?? 0,
  subtotal: formatCurrency(product?.total_real_price ?? 0),
  pivot: product?.pivot ?? 0,
  toppings: product?.toppings.map(p => ({
    id: `${p?.id ?? ''}`,
    productId: product.order_product_id,
    name: p?.description ?? '',
    categoryName: p?.topping_category ?? '',
    price: p?.price ?? 0,
    units: p?.units ?? 0,
    subtotal: p?.total_real_price ?? 0,
    isSelected: false,
    isRemovable: false,
  })),
  isSelected: false,
  isRemoved: false,
});

const toOrderMgmtItemToReplace = (
  item: ItemToReplace,
  orderId: number,
): ItemSuggestion => ({
  id: item?.id,
  productId: item?.productId,
  name: item?.name,
  price: item?.total_price ?? item?.price,
  isProduct: item?.isProduct,
  units: item?.units,
  orderId,
});

const toProductSubstitutionItemsRequest = (
  products: Array<OrderMgmtProduct>,
  orderId: number,
  partnerId: number,
  storeId: number,
) => {
  return {
    order_id: orderId,
    partner_id: partnerId,
    store_id: storeId,
    initial_modification: {
      order_id: orderId,
      partner_id: partnerId,
      state: 'to_wait',
      products: products
        .map(product => {
          return (
            product?.suggestions && {
              ...{
                product_id: +product?.id ?? 0,
                order_product_id: product?.pivot ?? 0,
                name: product?.name ?? '',
                description: product?.description ?? '',
                image: product?.img ?? '',
                units: product?.units ?? 0,
                price: product?.price ?? 0,
                total: product?.price * product?.units ?? 0,
                type: 'substitution',
                replacement_product_options: (product?.suggestions || []).map(
                  productSuggestion => ({
                    added_product_id: productSuggestion?.productId ?? 0,
                    added_product_name: productSuggestion?.name ?? '',
                    added_product_description:
                      productSuggestion?.description ?? '',
                    added_product_image: productSuggestion?.img ?? '',
                    added_product_units: product?.units ?? 0,
                    added_product_price: productSuggestion?.realPrice ?? 0,
                    added_product_total:
                      (productSuggestion?.realPrice ?? 0) * product?.units,
                    accepted: false,
                  }),
                ),
              },
            }
          );
        })
        .filter(product => !!product),
      toppings: products
        .map(product =>
          (product?.toppings || [])
            .map(topping => {
              return (
                topping?.suggestions && {
                  ...{
                    topping_id: +topping?.id ?? 0,
                    product_id: +product?.id ?? 0,
                    order_product_id: product?.pivot ?? 0,
                    product_name: product?.name ?? '',
                    topping_description: topping?.name ?? '',
                    units: topping?.units ?? 0,
                    price: topping?.price ?? 0,
                    type: 'substitution',
                    image: topping?.img ?? '',
                    topping_category_id:
                      (topping?.suggestions || [])[0]?.categoryId ?? 0,
                    topping_category_index: topping?.categoryIndex ?? 0,
                    topping_category_description:
                      topping.categoryDescription ?? '',
                    replacement_topping_options: (
                      topping?.suggestions || []
                    ).map(toppingSuggestion => ({
                      added_topping_id:
                        toSubstitutionItemId(toppingSuggestion?.id + '') ?? 0,
                      added_topping_index:
                        toppingSuggestion?.categoryIndex ?? 0,
                      added_topping_age_restriction: !!toppingSuggestion?.ageRestriction,
                      added_topping_description:
                        toppingSuggestion?.description ?? '',
                      added_topping_image: toppingSuggestion?.img ?? '',
                      added_topping_units: topping?.units ?? 0,
                      added_topping_price: toppingSuggestion?.realPrice ?? 0,
                      added_topping_total:
                        (toppingSuggestion?.realPrice ?? 0) * topping?.units,
                      accepted: false,
                    })),
                    is_removable_topping: true,
                  },
                }
              );
            })
            .filter(
              topping => topping && topping.replacement_topping_options.length,
            ),
        )
        .flat(),
    },
  };
};

const toSubstitutionItemId = (id = '') => {
  if (!id || !id.includes('_')) return +id;
  const [firstIdPart, secondIdPart] = id.split('_');
  return +`${firstIdPart}${secondIdPart}`;
};

const toDeactivateProductsAndToppingsRequest = (
  products: Array<OrderMgmtProduct>,
  storeId: number,
  orderId: number,
) => {
  return {
    store_id: storeId,
    order_id: orderId,
    product_ids: [
      ...products
        .filter(product => product?.isSelected)
        .map(product => +product?.id),
    ],
    topping_ids: [
      ...products
        .map(product =>
          (product?.toppings || [])
            .filter(topping => topping?.isSelected)
            .map(topping => +topping?.id),
        )
        .flat(),
    ],
  };
};

const toRemoveProductsFromOrderAdapter = (
  order: OrderDetailResponse,
  storeId: number,
  products: Array<OrderMgmtProduct>,
) => {
  return {
    store_id: storeId,
    remove_product_list: {
      order_product_ids: products.map(product => product.pivot),
      product_ids: products.map(product => +product.id),
    },
    cooking_time:
      order?.cookingTimeToTakeOrder ||
      order?.predict_cooking_time?.default_cooking_time,
    additional_info: {
      cooking_time: {
        predictor: true,
        min: order?.predict_cooking_time?.min_cooking_time ?? 0,
        default: order?.predict_cooking_time?.default_cooking_time ?? 0,
        max: order?.predict_cooking_time?.max_cooking_time ?? 0,
      },
    },
  };
};

export const orderMgmtAdapter = {
  toSubstitutionProductItems,
  toSubstitutionToppingItems,
  toOrderMgmtDetail,
  toOrderMgmtItemToReplace,
  toProductSubstitutionItemsRequest,
  toOrderMgmtProduct,
  toDeactivateProductsAndToppingsRequest,
  toRemoveProductsFromOrderAdapter,
};
