// React
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
// Actions
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';
// Components
import CustomerPhoneProxySessionDialog from './CustomerPhoneProxySessionDialog';
import customerPhoneProxySessionDialogActions from '../../redux/actions/customerPhoneProxySessionDialog.actions';

const mapStateToProps = (
  {
    customerPhoneProxySessionDialogReducer: {
      open,
      orderId,
      phoneNumber,
      messageError,
      isOM,
    },
    appReducer: { countryFlag },
  },
  { intl: { formatMessage } },
) => {
  const title = formatMessage({ id: 'customerPhoneProxySessionDialog.title' });
  const description = formatMessage({
    id: 'customerPhoneProxySessionDialog.dialogDescription',
  });
  const phonePrefixInputPlaceHolder = formatMessage({
    id: 'customerPhoneProxySessionDialog.inputPlaceHolder',
  });
  const confirmButtonText = formatMessage({
    id: 'globals.confirm',
  });
  const countryPrefixCode = localStorage.getItem('phonePrefix');
  return {
    open,
    title,
    description,
    confirmButtonText,
    countryFlag,
    countryPrefixCode,
    orderId,
    phonePrefixInputPlaceHolder,
    phoneNumber,
    helperText: messageError,
    isOM,
  };
};

const mapDispatchToProps = {
  onBack: dialogsManagerActions.back,
  onClose: dialogsManagerActions.closeCurrent,
  onClickConfirm: dialogsManagerActions.confirmProxySession,
  clearErrorText: customerPhoneProxySessionDialogActions.clearErrorText,
};
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(CustomerPhoneProxySessionDialog),
);
