import { Box, Grid, Typography } from '@material-ui/core';
import { clsx } from 'clsx';

import CustomCheckbox from '../../common/custom-checkbox';
import CustomEyeSwitch from '../../common/custom-eye-switch';
import MenuToppingDeactivationOptions from '../../menu-topping-deactivation-options';
import { useOutStockText } from '../hook';
import getRootStyles from '../MenuOutOfStockView.styles';
import makeClasses from './ToppingOutStock.styles';
import { formatOutOfStockDate } from '../utils';

export const selectorTypes = {
  CHECKBOX: 'CHECKBOX',
  DEACTIVATION_OPTIONS: 'DEACTIVATION_OPTIONS',
  EYE: 'EYE',
};

const getSelector = (
  isAvailable,
  onChange,
  selectorType,
  toppingCategoryId,
  toppingId,
  topping,
  deactivatedItemId,
  setDeactivatedItemId,
) => {
  switch (selectorType) {
    case selectorTypes.CHECKBOX:
      return (
        <CustomCheckbox
          checked={isAvailable}
          onChange={onChange}
          value={isAvailable}
        />
      );
    case selectorTypes.DEACTIVATION_OPTIONS:
      return (
        <MenuToppingDeactivationOptions
          toppingCategoryId={toppingCategoryId}
          toppingId={toppingId}
          isAvailable={isAvailable}
          topping={topping}
          deactivatedItemId={deactivatedItemId}
          setDeactivatedItemId={setDeactivatedItemId}
        />
      );
    case selectorTypes.EYE:
    default:
      return <CustomEyeSwitch active={isAvailable} onClick={onChange} />;
  }
};

export function ToppingOutStock(props) {
  const {
    onChange = () => {},
    selectorPropertyName = 'activated',
    selectorType = selectorTypes.DEACTIVATION_OPTIONS,
    topping = {},
    deactivatedItemId,
    setDeactivatedItemId,
  } = props;

  const _classes = makeClasses();
  const rootStyles = getRootStyles();
  const offFromText = useOutStockText({ ctaName: 'offFrom' });
  const availableFromText = useOutStockText({ ctaName: 'availableFrom' });
  const turnOnText = useOutStockText({ ctaName: 'turnOn' });

  const selector = getSelector(
    topping[selectorPropertyName],
    onChange,
    selectorType,
    topping.toppingCategoryId,
    topping.id,
    topping,
    deactivatedItemId,
    setDeactivatedItemId,
  );

  const {
    description,
    out_of_stock_creation_date,
    out_of_stock_activation_date,
  } = topping;

  return (
    <Box className={rootStyles.itemContainer}>
      <Grid container spacing={1} className={_classes.productContainer}>
        <Grid
          item
          sm={6}
          xs={10}
          className={clsx(_classes.infoContainer, _classes.generalOrder)}
        >
          <Typography className={rootStyles.itemName}>{description}</Typography>
          <Typography className={rootStyles.itemName}></Typography>
        </Grid>
        <Grid
          item
          sm={2}
          xs={4}
          className={clsx(rootStyles.columnContainer, _classes.customOrder)}
        >
          <Typography className={rootStyles.sectionTitle}>
            {offFromText}
          </Typography>
          <Box className={rootStyles.date}>
            <Typography className={rootStyles.dateText}>
              {formatOutOfStockDate(out_of_stock_creation_date)}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          sm={2}
          xs={4}
          className={clsx(rootStyles.columnContainer, _classes.customOrder)}
        >
          <Typography className={rootStyles.sectionTitle}>
            {availableFromText}
          </Typography>
          <Box className={rootStyles.date}>
            <Typography className={rootStyles.dateText}>
              {formatOutOfStockDate(out_of_stock_activation_date)}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          sm={2}
          xs={2}
          className={clsx(
            rootStyles.columnContainer,
            _classes.generalOrder,
            _classes.toggleSwitch,
          )}
        >
          <div>
            <Typography className={rootStyles.sectionTitle}>
              {turnOnText}
            </Typography>
            {selector}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
