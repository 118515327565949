// Consts
import { countryCodes } from './../constants/CountryCodes';
// Services
import LocalStorageService from '../services/local-storage-service';

export const countryCodesWithoutDecimals = [
  countryCodes.COLOMBIA,
  countryCodes.DEV,
];

export const formatCurrency = (value, hideZero = true) => {
  const countryCode = LocalStorageService.getCountryCode();
  const countryCurrencyPrefix =
    LocalStorageService.getCountryCurrencyPrefix() || '$';

  if (value === undefined || value === null || isNaN(value) || !countryCode) {
    // If the value is not a number or the country code is not set, return the input value.
    return value;
  }

  if ((value === 0 || value === '0') && hideZero) {
    return '-';
  }

  const absValue = Math.abs(value);

  const floorValue =
    countryCodesWithoutDecimals
      .map(code => code.toLowerCase())
      .indexOf(countryCode.toLowerCase()) !== -1
      ? Math.floor(absValue)
      : absValue;

  const lessIcon = value < 0 ? '-' : '';

  return `${lessIcon}${countryCurrencyPrefix}${floorValue.toLocaleString(
    'de-DE',
  )}`;
};

export const unFormatCurrency = value => {
  if (!value) return;
  if (!value.includes('$')) return +value;
  return +value.slice(1);
};
