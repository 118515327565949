import { Box, Typography } from '@material-ui/core';

import { useFormatMessage } from '../../intl/intl';
import StoresFilterButton from '../stores-filter-button';
import OrderSearchTextField from '../order-search-text-field';

import { textIds } from './const';
import useStyles from './OrdersKanbanAdvancedFiltersToolbar.styles';

function OrdersKanbanAdvancedFiltersToolbar(props) {
  const { showStoresFilterButton, title } = props;
  const _classes = useStyles();
  const advancedFiltersText = useFormatMessage(textIds.advancedFiltersText);
  return (
    <Box className={_classes.root}>
      <Typography className={_classes.seccionTitle}>{title}</Typography>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        gridGap='0.5rem'
      >
        <Typography className={_classes.advancedFiltersText}>
          {advancedFiltersText}
        </Typography>
        <OrderSearchTextField
          fullWidth={true}
          classes={{
            root: _classes.orderSearchTextFieldRoot,
            input: _classes.input,
          }}
        />
        {showStoresFilterButton && <StoresFilterButton />}
      </Box>
    </Box>
  );
}

export default OrdersKanbanAdvancedFiltersToolbar;
