// External libs
import { createSelector } from 'reselect';
// Consts
import { textIds } from '../consts';
import { SupportSuggestion } from '../../../interfaces';
// Tools
import { formatMessage } from '../../../intl/intl';
// Selectors
import { selectors as cookedOrderConfirmationIssueSelectors } from '../../../redux/cooked-order-confirmation-issue-dialog';
// Utils
import { Option, utils } from '../../common/boxes/custom-clickable-box-list';
// Store
import appStore from '../../../redux/stores/app.store';
// Transpilers
import supportSuggestionTranspiler from '../../../transpilers/support-suggestion-transpiler';

const selectOptions = createSelector<any, SupportSuggestion[], Option[]>(
  cookedOrderConfirmationIssueSelectors.selectSupportSuggestions,
  (supportSuggestions) => {
    const dispatch = appStore.getDispatch();
    return supportSuggestions.map((suggestion) => {
      const action = supportSuggestionTranspiler.getOrderReadyConfirmationIssueAction(
        suggestion.id,
      );
      const onClick = async () => {
        await dispatch(action());
      };
      return utils.buildOption(
        suggestion.description,
        onClick,
        suggestion.title,
      );
    });
  },
);

const selectTitle = createSelector<any, number, string>(
  cookedOrderConfirmationIssueSelectors.selectOrderId,
  (orderId) => formatMessage(textIds.title, { orderId }),
);

const selectors = { selectOptions, selectTitle };

export default selectors;
