// React
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// Actions
import MenuActions from './../../redux/actions/menu.actions';
// Components
import MenuOutOfStockView from './MenuOutOfStockView';

const mapStateToProps = ({
  menuReducer: {
    areCorridorsLoading: isLoading,
    productsOutOfStock,
    toppingsOutOfStock,
  },
}) => {
  return { isLoading, productsOutOfStock, toppingsOutOfStock };
};

const mapDispatchToProps = {
  onChangeProductAvailability: MenuActions.setProductAvailability,
  onChangeToppingAvailability: MenuActions.setToppingAvailability,
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MenuOutOfStockView),
);
