import { useHighDemand, useOperationalStateTurbo } from './hooks';
import { HighDemandDialog, HighDemandBtn } from './components';

export function HighDemand() {
  const {
    open,
    isHidden,
    saturation,
    isSingleStore,
    handleOpen,
    handleClose,
    everyIsActive,
  } = useHighDemand();
  const { hasActiveTurboStores, partnerId } = useOperationalStateTurbo();

  if (isHidden) return null;

  return (
    <>
      <HighDemandBtn
        variant={saturation}
        onClick={handleOpen}
        isSingleStore={isSingleStore}
        everyIsActive={everyIsActive}
        hasActiveTurboStores={hasActiveTurboStores}
      />

      {open && (
        <HighDemandDialog
          open={open}
          onClose={handleClose}
          isSingleStore={isSingleStore}
          partnerId={partnerId}
        />
      )}
    </>
  );
}
