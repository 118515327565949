// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(theme => ({
  label: {
    alignItems: 'center',
    backgroundColor: neutral.white,
    color: neutral.D20,
    display: 'flex',
    fontSize: 13,
    justifyContent: 'space-between',
    lineHeight: 'normal',
    padding: '19px 16px',
    textTransform: 'none',
  },
  categoryRoot: {
    backgroundColor: neutral.white,
    width: 'auto',
    height: 60,
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: 11,
  },
  listItemTextRoot: {},
  arrow: {
    width: 20,
    height: 20,
  },
  listItemRoot: {
    justifyContent: 'space-between',
  },
  listItemIconRoot: {
    minWidth: 'auto',
  },
  root: {
    display: 'grid',
    gridRowGap: 16,
  },
}));
