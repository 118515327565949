import { clsx } from 'clsx';

import useStyles from './ToppingBox.styles';
import { formatCurrency } from '../../utils/CurrencyUtils';

import CustomCheckbox from '../common/custom-checkbox';
import MenuToppingDeactivationOptions from '../menu-topping-deactivation-options';
import CustomEyeSwitch from '../common/custom-eye-switch';

export const selectorTypes = {
  CHECKBOX: 'CHECKBOX',
  DEACTIVATION_OPTIONS: 'DEACTIVATION_OPTIONS',
  EYE: 'EYE',
};

const getSelector = (
  isAvailable,
  onChange,
  selectorType,
  toppingCategoryId,
  toppingId,
  topping,
  deactivatedItemId,
  setDeactivatedItemId,
) => {
  switch (selectorType) {
    case selectorTypes.CHECKBOX:
      return (
        <CustomCheckbox
          checked={isAvailable}
          onChange={onChange}
          value={isAvailable}
        />
      );
    case selectorTypes.DEACTIVATION_OPTIONS:
      return (
        <MenuToppingDeactivationOptions
          toppingCategoryId={toppingCategoryId}
          toppingId={toppingId}
          isAvailable={isAvailable}
          topping={topping}
          deactivatedItemId={deactivatedItemId}
          setDeactivatedItemId={setDeactivatedItemId}
        />
      );
    case selectorTypes.EYE:
    default:
      return <CustomEyeSwitch active={isAvailable} onClick={onChange} />;
  }
};

function ToppingBox(props) {
  const {
    classes = {},
    onChange = () => {},
    selectorPropertyName = 'activated',
    selectorType = selectorTypes.DEACTIVATION_OPTIONS,
    showTitle = false,
    topping = {},
    deactivatedItemId,
    setDeactivatedItemId,
  } = props;

  const hasToppingTitle = !!topping?.title?.trim();
  const hasToppingDescription = !!topping?.description?.trim();

  const _classes = useStyles();

  const selector = getSelector(
    topping[selectorPropertyName],
    onChange,
    selectorType,
    topping.toppingCategoryId,
    topping.id,
    topping,
    deactivatedItemId,
    setDeactivatedItemId,
  );

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      {showTitle && hasToppingTitle && (
        <div className={clsx(_classes.titleContainer, classes?.titleContainer)}>
          <span className={clsx(_classes.title, classes?.title)}>
            {topping.title}
          </span>
        </div>
      )}
      <div className={clsx(_classes.box, classes?.box)}>
        {hasToppingDescription && (
          <span className={clsx(_classes.name, classes?.name)}>
            {topping.description}
          </span>
        )}
        {!!topping.price && (
          <span
            className={clsx(_classes.price, classes?.price)}
          >{`${formatCurrency(topping.price)}`}</span>
        )}
        {selector}
      </div>
    </div>
  );
}

export default ToppingBox;
