import React from 'react';
import useListStyles from './list.styles';
import { Box, LinearProgress, Tooltip, Typography } from '@material-ui/core';
import { useKanbanLang } from './hooks/useKanbanLang';
import {
  CardList,
  EmptyResult,
  DataZero,
  ListLoader,
  ListHeader,
} from './components';
import { useCargoKanban } from './hooks';

export function OrderList() {
  const styles = useListStyles();
  const {
    stores,
    orders,
    orderId,
    storeId,
    setFilterOrders,
    isKanbanLoading,
    isRefreshData,
    isKanbanEmpty,
    hasKanbanNoResults,
    hasKanbanResults,
    partnerId,
    lastUpdate,
  } = useCargoKanban();
  const { ordersLastUpdateText, ordersLastUpdateTooltipText } = useKanbanLang({
    lastUpdate,
  });

  return (
    <Box
      display='flex'
      flexDirection='column'
      gridGap='0.5rem'
      position='relative'
      height='100%'
    >
      <ListHeader
        stores={stores}
        orderId={orderId}
        storeId={storeId}
        setFilterOrders={setFilterOrders}
      />
      <Box display='flex' overflow='auto' marginBottom='1.5rem'>
        {hasKanbanNoResults && <EmptyResult />}
        {isKanbanEmpty && <DataZero />}
        {isKanbanLoading && <ListLoader />}
        {hasKanbanResults && <CardList orders={orders} partnerId={partnerId} />}
      </Box>
      <Box position='absolute' bottom={0} right={0} left={0}>
        <Tooltip
          classes={{ tooltip: styles.tooltip }}
          title={ordersLastUpdateTooltipText}
          placement='left'
          arrow
        >
          <Typography className={styles.lastUpdate}>
            {ordersLastUpdateText}
          </Typography>
        </Tooltip>
        {isRefreshData && <LinearProgress />}
      </Box>
    </Box>
  );
}
