import { clsx } from 'clsx';
import { Box, Grid, Typography } from '@material-ui/core';

import { ImagePlaceholder } from '../../image-placeholder';
import MenuProductDeactivationOptions from '../../menu-product-deactivation-options';

import { useOutStockText } from '../hook';
import { formatOutOfStockDate } from '../utils';
import { ensureHttps } from '../../../utils/url-utils/UrlUtils';

import getRootStyles from '../MenuOutOfStockView.styles';
import getComponentStyles from './ProductOutstock.styles';

export function ProductOutstock({ product, showDetails = false }) {
  const styles = getComponentStyles();
  const rootStyles = getRootStyles();
  const offFromText = useOutStockText({ ctaName: 'offFrom' });
  const availableFromText = useOutStockText({ ctaName: 'availableFrom' });
  const turnOnText = useOutStockText({ ctaName: 'turnOn' });
  const {
    description,
    id,
    image,
    is_available,
    name,
    out_of_stock_creation_date,
    out_of_stock_activation_date,
  } = product;

  const imageHttps = ensureHttps(image);

  return (
    <Box className={rootStyles.itemContainer}>
      <Grid container spacing={1} className={styles.productContainer}>
        <Grid
          item
          sm={6}
          xs={10}
          className={clsx(styles.infoContainer, styles.generalOrder)}
        >
          <ImagePlaceholder
            src={imageHttps}
            classes={{ root: styles.imageRoot, img: styles.image }}
          />
          <div className={styles.containerInfo}>
            <Typography className={rootStyles.itemName}>{name}</Typography>
            {showDetails && (
              <Typography className={styles.description}>
                {description}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid
          item
          sm={2}
          xs={4}
          className={clsx(rootStyles.columnContainer, styles.customOrder)}
        >
          <div>
            <Typography className={rootStyles.sectionTitle}>
              {offFromText}
            </Typography>
            <Box className={rootStyles.date}>
              <Typography className={rootStyles.dateText}>
                {formatOutOfStockDate(out_of_stock_creation_date)}
              </Typography>
            </Box>
          </div>
        </Grid>
        <Grid
          item
          sm={2}
          xs={4}
          className={clsx(rootStyles.columnContainer, styles.customOrder)}
        >
          <div>
            <Typography className={rootStyles.sectionTitle}>
              {availableFromText}
            </Typography>
            <Box className={rootStyles.date}>
              <Typography className={rootStyles.dateText}>
                {formatOutOfStockDate(out_of_stock_activation_date)}
              </Typography>
            </Box>
          </div>
        </Grid>
        <Grid
          item
          sm={2}
          xs={2}
          className={clsx(
            rootStyles.columnContainer,
            styles.generalOrder,
            styles.toggleSwitch,
          )}
        >
          <div>
            <Typography className={rootStyles.sectionTitle}>
              {turnOnText}
            </Typography>
            <MenuProductDeactivationOptions
              product={product}
              productId={id}
              isAvailable={is_available}
            />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
