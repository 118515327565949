import { makeStyles } from '@material-ui/core/styles';

import loginImg from '../../../../assets/images/carousel/imagen_login.png';
import { neutral, red } from '../../../../shared/styles/colors';

export default makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    backgroundImage: `url(${loginImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  text: {
    margin: '2rem',
    zIndex: 2,
    fontSize: '5.0vw',
    lineHeight: 1.25,
    letterSpacing: 0,
    fontFamily: 'Poppins Bold',
    color: neutral.white,
  },
  textAccent: {
    color: red['50'],
  },
});
