import React, { useEffect } from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import LocalStorageService from '../../services/local-storage-service';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';
import useInputPlacesStyles from './InputPlacesAutocomplete.styles';

export function InputPlacesAutocomplete({
  defaultValue = '',
  placeholder = '',
  onChange,
  error = false,
  disabled,
  label,
  required = false,
  variant = 'outlined',
  fullWidth = true,
  helperText,
  isTouched = false,
  onBlur,
}: Props) {
  const country =
    LocalStorageService.getCountryCode()?.toLocaleLowerCase() || '';
  const language = LocalStorageService.getLanguage() ?? '';
  const styles = useInputPlacesStyles({ disabled });
  const {
    ready,
    value,
    suggestions,
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: [country] },
      offset: 4,
    },
    debounce: 300,
  });

  useEffect(() => {
    if (!defaultValue) {
      setValue('');
    }
  }, [defaultValue]);

  useEffect(() => {
    if (disabled) {
      setValue(defaultValue);
    }
  }, []);

  useEffect(() => {
    if (!value && !disabled && isTouched) onChange(null);
  }, [value]);

  function handleInput(e) {
    if (!e) return;
    const {
      target: { value },
    } = e;
    setValue(value);
  }

  function handleSelect({ description }) {
    clearSuggestions();
    getGeocode({ address: description }).then(results => {
      const political = results[0].address_components.filter(c =>
        c.types.includes('political'),
      );
      const city =
        results[0].address_components.find(
          c =>
            c.types.includes('locality') ||
            c.types.includes('administrative_area_level_1'),
        )?.long_name ?? '';
      const parseAddress: any = (description as string)
        .split(',')
        .filter(d =>
          political.find(
            c =>
              c.long_name.localeCompare(d.trim(), language, {
                sensitivity: 'base',
              }) === 0,
          ),
        )
        .join(' ');
      setValue(description, false);
      const { lat, lng } = getLatLng(results[0]);
      onChange?.({
        completeAddress: description,
        parseAddress,
        coordinates: { lat, lng },
        city,
      });
    });
  }

  function renderSuggestions() {
    if (disabled) return null;

    return (suggestions?.data || []).map(suggestion => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      return (
        <ListItem
          className={styles.placeSuggestion}
          button
          key={place_id}
          onClick={() => handleSelect(suggestion)}
        >
          <ListItemText primary={main_text} secondary={secondary_text} />
        </ListItem>
      );
    });
  }

  if (!ready) return null;

  return (
    <Box position='relative'>
      <TextField
        classes={{ root: styles.disabled }}
        value={value}
        onChange={handleInput}
        placeholder={placeholder}
        disabled={disabled || !ready}
        error={error}
        label={label}
        required={required}
        variant={variant}
        fullWidth={fullWidth}
        helperText={helperText}
        onBlur={onBlur}
      />
      {suggestions?.status === 'OK' && (
        <List className={styles.placesSuggestions}>{renderSuggestions()}</List>
      )}
    </Box>
  );
}

interface Props {
  defaultValue?: string;
  placeholder?: string;
  onChange: any;
  error?: boolean;
  disabled?: boolean;
  label: string;
  required?: boolean;
  variant: 'outlined' | 'filled' | 'standard';
  fullWidth?: boolean;
  helperText?: any;
  isTouched?: boolean;
  onBlur?: any;
}
