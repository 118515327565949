import { OrderMgmtDisclaimerAdapter } from '../adapters/orderMgmt-disclaimer.adapter';
import {
  OMProduct,
  OMToppingDTO,
  OrderManagementResponse,
} from '../core/responses';

export function OrderMgmtDisclaimerFacade() {
  const getAcceptedData = (order: OrderManagementResponse) => {
    const acceptedToppings =
      (order?.toppings || []).filter(x =>
        x.replacement_topping_options?.find(y => !!y.accepted),
      ) ?? ([] as OMToppingDTO[]);
    const acceptedProducts =
      (order?.products || []).filter(x =>
        x.replacement_product_options?.find(y => !!y.accepted),
      ) ?? ([] as OMProduct[]);

    const products = OrderMgmtDisclaimerAdapter.toProductsAccepted(
      acceptedProducts,
    );
    const toppings = OrderMgmtDisclaimerAdapter.toToppingsAccepted(
      acceptedToppings,
    );
    return {
      items: [...products, ...toppings],
      hasToppings: !!toppings.length,
    };
  };

  return {
    getAcceptedData,
  };
}
