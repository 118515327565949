// Material UI
import { makeStyles } from '@material-ui/core';
// Constant
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {},
  flexContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    borderBottom: `1px solid ${neutral.D10}`,
    padding: '24px',
    flexDirection: 'row',
  },
  flexContainerTwo: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  fontRtinfo: {
    paddingLeft: '21px',
    fontSize: '13px',
    fontFamily: 'Poppins',
    color: neutral.D15,
  },
  marginPhone: {
    marginLeft: '18px',
  },
  rtName: {
    fontSize: '15px',
    fontFamily: 'Poppins',
    color: neutral.D20,
    letterSpacing: '0',
    paddingLeft: '20px',
  },
  avatarBox: {
    position: 'absolute',
  },
}));
