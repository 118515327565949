import { generateURL } from './url.util';

import { RAPPI_SERVICES_BASE_URL } from '../../core';

import type { GenerateURLParams } from './url.util';

export function generateURLRappiServices(
  params: Omit<GenerateURLParams, 'base'>,
) {
  return generateURL({ ...params, base: RAPPI_SERVICES_BASE_URL });
}
