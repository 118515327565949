import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useOrderPrintingConfigLang } from './useOrderPrintingConfigLang';

import type { PartnerId } from 'business/shared/domain';
import { printerAnalytics } from 'features/startup-notifications/infra/analytics';
import orderActions from '../../../../../redux/actions/orders-actions';
import lsService from '../../../../../services/local-storage-service/LocalStorageService';

export function useOrderPrintingConfig() {
  const [isCommandPrinting, setIsCommandPrinting] = useState(false);
  const [isAutoPrinting, setIsAutoPrinting] = useState(false);
  const [isChecklist, setIsChecklist] = useState(false);

  const dpRedux = useDispatch();

  const lang = useOrderPrintingConfigLang();
  const partnerId: PartnerId = useSelector(
    ({ partnerReducer }: any) => partnerReducer?.profile?.id,
  );

  const handleOnChangeAutoPrinting = (isChecked: boolean, canLog = true) => {
    setIsAutoPrinting(isChecked);
    dpRedux(orderActions.setAutoPrint(isChecked)); // TODO: see note at end (1)
    canLog &&
      printerAnalytics.logPrinterSetup({
        partnerId,
        printerStatus: isCommandPrinting,
        automaticPrinterTicket: isChecked,
        printsChecklist: isChecklist,
      });
  };

  const handleOnChangeChecklist = (isChecked: boolean, canLog = true) => {
    setIsChecklist(isChecked);
    lsService.setIsActivatedPrinterChecklist(isChecked ? '1' : '0');
    canLog &&
      printerAnalytics.logPrinterSetup({
        partnerId,
        printerStatus: isCommandPrinting,
        automaticPrinterTicket: isAutoPrinting,
        printsChecklist: isChecked,
      });
  };

  const handleOnCheckCommandPrinting = (isChecked: boolean, canLog = true) => {
    handleOnChangeAutoPrinting(false, false);
    handleOnChangeChecklist(isChecked, false);
    setIsCommandPrinting(isChecked);
    lsService.setIsActivatedComanda(isChecked ? '1' : '0');
    canLog &&
      printerAnalytics.logPrinterSetup({
        partnerId,
        printerStatus: isChecked,
        automaticPrinterTicket: false,
        printsChecklist: isChecked,
      });
  };

  useEffect(() => {
    const isActivatedCommand = lsService.getIsActivatedComanda();
    if (isActivatedCommand) {
      setIsCommandPrinting(isActivatedCommand);
      setIsAutoPrinting(lsService.getAutoPrintEnabled());
      setIsChecklist(lsService.getIsActivatedPrinterChecklist());
    } else {
      handleOnCheckCommandPrinting(false, false);
    }
  }, []);

  return {
    lang,
    isCommandPrinting,
    isAutoPrinting,
    isChecklist,
    handleOnCheckCommandPrinting,
    handleOnChangeAutoPrinting,
    handleOnChangeChecklist,
  };
}

// NOTE(1):
// The redux action will be maintained for the functionality of
// hot updating the current orders that should be printed, avoiding
// reloading the page manually by the 'aliado'. Additionally, below it
// activates the local storage, and in case of removal, the code
// associated with that manual action must also be removed without
// affecting the other 'appSetup' flows.
// lsService.setAutoPrintEnabled(isChecked);
