// External libs
import { handleActions } from 'redux-actions';

import MainLayoutActions from '../actions/mainLayout.actions';

const initialState = {};

export default handleActions(
  {
    [MainLayoutActions.closePickupCodeDialogAction]: state =>
      togglePickupCodeDialog(false, state),
    [MainLayoutActions.openPickupCodeDialogAction]: state =>
      togglePickupCodeDialog(true, state),
  },
  initialState,
);

const togglePickupCodeDialog = (isPickupCodeDialogOpened, state) => {
  return {
    ...state,
    isPickupCodeDialogOpened,
  };
};
