import { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

import { orderDetailDisclaimer } from '../orderDetailDisclaimer.styles';
import { OrderMgmtDisclaimer } from '../../../../../business/order-mgmt';
import { useOrderDetailDisclaimerLang } from '../hooks';
import collapseIcon from '../../../../../../src/assets/images/icons/collapse.svg';

export function ToAcceptedDisclaimer({
  orderChanges: orders,
  hasToppings,
}: Props) {
  const [isContentCollapsed, setIsContentCollapsed] = useState(false);
  const styles = orderDetailDisclaimer({ isContentCollapsed });
  const showCollapseIcon = orders.length > 1;

  function handleContentCollapse() {
    setIsContentCollapsed(collapse => !collapse);
  }
  const { disclaimerAcceptedToppingText } = useOrderDetailDisclaimerLang();

  return (
    <>
      <Box
        className={styles.container}
        display='flex'
        flexDirection='column'
        position='relative'
        padding='0.5rem 1rem'
      >
        {showCollapseIcon && (
          <img
            className={styles.collapseIcon}
            src={collapseIcon}
            alt='collapse-icon'
            onClick={handleContentCollapse}
          />
        )}
        <Box display='flex' flexDirection='column' gridGap='0.75rem'>
          {[...(isContentCollapsed ? orders.slice(0, 1) : orders)].map(
            changed => (
              <ChangedProduct
                changedProduct={changed}
                key={`om-changed-${changed.id}`}
              />
            ),
          )}
        </Box>
      </Box>
      {hasToppings && (
        <Box
          className={styles.container}
          display='flex'
          flexDirection='column'
          position='relative'
          mt={2}
          padding='0.5rem 1rem'
        >
          <Box className={styles.content}>
            <Box display='flex' gridGap='0.5rem'>
              <ErrorRoundedIcon className={styles.warningIcon} />
              <Box>
                <Typography
                  style={{ fontSize: '.875rem' }}
                  className={styles.tertiaryText}
                >
                  {disclaimerAcceptedToppingText}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

interface Props {
  orderChanges: OrderMgmtDisclaimer[];
  hasToppings: boolean;
}

function ChangedProduct({ changedProduct }) {
  const styles = orderDetailDisclaimer();
  const {
    acceptedUserSuggestionChangesText,
    thisProductWasTurnedOffAutomaticallyText,
    thisProductIsNotAvailableTodayText,
  } = useOrderDetailDisclaimerLang(changedProduct);

  return (
    <Box className={styles.content}>
      <Box
        flex={1}
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        marginRight='2rem'
      >
        <Typography className={styles.primaryText}>
          {acceptedUserSuggestionChangesText}
        </Typography>
      </Box>
      <Box display='flex' gridGap='0.5rem' marginTop={1} alignItems='center'>
        <ErrorRoundedIcon className={styles.warningIcon} />
        <Box>
          <Typography className={styles.secondaryText}>
            {thisProductWasTurnedOffAutomaticallyText}
          </Typography>
          <Typography className={styles.tertiaryText}>
            {thisProductIsNotAvailableTodayText}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
