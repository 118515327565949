// Material UI
import { makeStyles } from '@material-ui/core';

export default makeStyles({
  flag: {
    height: '20px',
    width: '28px',
  },
  name: {
    marginLeft: '16px',
  },
  placeHolder: {
    color: '#d5dbe2',
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    minHeight: '20px',
  },
});
