// External libs
import { createSelector } from 'reselect';
// Const
import { OrderDeliveryMethodType } from '../../../constants/OrderDeliveryMethodType';
// Utils
import { formatMessage } from '../../../intl/intl';
// Selectors
import OrdersSelectors from '../../../redux/selectors/orders-selectors';

const selectMessage = createSelector(
  OrdersSelectors.selectOrderDeliveryMethodInOrderDetailDialog,
  deliveryMethod => {
    let textId = null;
    switch (deliveryMethod) {
      case OrderDeliveryMethodType.DELIVERY:
        textId = 'orderDetailDialogCancellationBox.fullServiceMessage';
        break;
      case OrderDeliveryMethodType.PICKUP:
        textId = 'orderDetailDialogCancellationBox.pickupMessage';
        break;
      default:
        textId = 'orderDetailDialogCancellationBox.marketplaceMessage';
        break;
    }

    return formatMessage({ id: textId });
  },
);

const Selectors = { selectMessage };

export default Selectors;
