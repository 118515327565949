// External libs
import { handleActions } from 'redux-actions';
// Actions
import RTReportReasonsDialogActions from '../actions/RTReportReasonsDialog.actions';

const defaultOrderId = 0;

const initialState = {
  isLoading: false,
  open: false,
  orderId: defaultOrderId,
  reasons: [],
};

export default handleActions(
  {
    [RTReportReasonsDialogActions.clearRTReportReasonsDialogAction]: state =>
      clearDialog(state),
    [RTReportReasonsDialogActions.closeRTReportReasonsDialogAction]: state =>
      closeDialog(state),
    [RTReportReasonsDialogActions.openRTReportReasonsDialogAction]: (
      state,
      { payload: { orderId } },
    ) => openDialog(state, orderId),
    [RTReportReasonsDialogActions.setRTReportReasonsDialogIsLoadingAction]: state =>
      setLoading(state, true),
    [RTReportReasonsDialogActions.setRTReportReasonsDialogIsNotLoadingAction]: state =>
      setLoading(state, false),
    [RTReportReasonsDialogActions.setRTReportReasonsDialogReasonsAction]: (
      state,
      { payload: { reasons } },
    ) => setReasons(state, reasons),
  },
  initialState,
);

const clearDialog = state => {
  return {
    ...initialState,
  };
};

const closeDialog = state => {
  return {
    ...state,
    open: false,
  };
};

const openDialog = (state, orderId = state.orderId) => {
  return {
    ...state,
    open: true,
    orderId,
  };
};

const setLoading = (state, isLoading) => {
  return { ...state, isLoading };
};

const setReasons = (state, reasons) => {
  return { ...state, reasons };
};
