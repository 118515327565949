import { useDispatch } from 'react-redux';
import { cargoSliceActions } from './cargo.slice';
import {
  CancelOrderStatus,
  CargoOrderFilters,
  CargoUIStatus,
  RTRequest,
} from '../../../domain';
import {
  cancelCargoOrder,
  createOrder,
  getCancelCostOrder,
  getCargoOrderList,
  getCargoStores,
  getVerifationCoverage,
} from './cargo.thunks';
import {
  CoverageValidateRequest,
  RTCargoRequest,
  OrdersListRequest,
  CancelOrderCostRequest,
  CancelCargoOrderRequest,
} from '../../core/requests';

import AppActions from '../../../../../redux/actions/app-actions/app.actions';

export function cargoActions() {
  const dispatch = useDispatch();

  const onClear = () => dispatch(cargoSliceActions.clear());

  const onClearUI = () => dispatch(cargoSliceActions.clearUI());

  const onClearNewOrder = () => dispatch(cargoSliceActions.clearNewOrder());

  const onSetOpenDialog = (value: boolean) =>
    dispatch(cargoSliceActions.setOpenDialog(value));

  const onSetStatusDialog = (value: CargoUIStatus) =>
    dispatch(cargoSliceActions.setStatusDialog(value));

  const onGetCargoStores = (payload: Array<number>) => {
    dispatch(getCargoStores(payload));
  };

  const onSetNewOrder = (value: Partial<RTRequest>) => {
    dispatch(cargoSliceActions.setNewOrder(value));
  };

  const onVerifyCoverage = (payload: CoverageValidateRequest) => {
    dispatch(getVerifationCoverage(payload));
  };

  const onCreateOrder = (payload: RTCargoRequest) => {
    dispatch(createOrder(payload));
  };

  const onSuccessfulDialog = (
    title: string,
    message: string,
    autoHideDuration: number,
  ) => {
    dispatch(AppActions.openSnackBarSuccess(message, title, autoHideDuration));
  };

  const onErrorDialog = (
    title: string,
    message: string,
    autoHideDuration: number,
  ) => {
    dispatch(AppActions.openSnackBarError(message, title, autoHideDuration));
  };

  const onGetCargoOrderList = (value: OrdersListRequest) => {
    dispatch(getCargoOrderList(value));
  };

  const onSetFilterOrders = (value: CargoOrderFilters) => {
    dispatch(cargoSliceActions.setFilterOrders(value));
  };

  const onGetCancelCostOrder = (value: CancelOrderCostRequest) => {
    dispatch(getCancelCostOrder(value));
  };

  const onCancelCargoOrder = (value: CancelCargoOrderRequest) => {
    dispatch(cancelCargoOrder(value));
  };

  const onSetCancelOrderStatus = (value: CancelOrderStatus) => {
    dispatch(cargoSliceActions.setCancelOrderStatus(value));
  };

  const onSetLastUpdate = (value: string) => {
    dispatch(cargoSliceActions.setLastUpdate(value));
  };

  return {
    onClear,
    onClearUI,
    onClearNewOrder,
    onSetOpenDialog,
    onSetStatusDialog,
    onGetCargoStores,
    onSetNewOrder,
    onVerifyCoverage,
    onCreateOrder,
    onSuccessfulDialog,
    onGetCargoOrderList,
    onSetFilterOrders,
    onGetCancelCostOrder,
    onCancelCargoOrder,
    onSetCancelOrderStatus,
    onErrorDialog,
    onSetLastUpdate,
  };
}
