// React
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
// External service.
import { get, find } from 'lodash'
// Actions
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions'
import storeKeeperActions from '../../redux/actions/StoreKeeper.actions'
// Components
import RTReportReasonsDialog from './RTReportReasonsDialog'

const mapStateToProps = (
  {
    rtReportReasonsDialogReducer: { isLoading, open, orderId, reasons },
    ordersReducer: { orders },
  },
  { intl: { formatMessage } },
) => {
  const order = find(orders, { id: +orderId }) || {}

  const rt = get(order, 'detail.storekeeper', null)

  const rtFirstName = get(rt, 'first_name', '')

  const description = formatMessage({
    id: 'rtReportReasonsDialog.description',
  })

  const sectionTitle = formatMessage(
    {
      id: 'rtReportReasonsDialog.sectionTitle',
    },
    {
      rtFirstName,
    },
  )

  const title = formatMessage({ id: 'rtReportReasonsDialog.title' })

  return { description, isLoading, open, reasons, sectionTitle, title }
}

const mapDispatchToProps = {
  onBack: dialogsManagerActions.back,
  onClose: dialogsManagerActions.close,
  onClickReason: storeKeeperActions.reportRTFromDialog,
}
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(RTReportReasonsDialog),
)
