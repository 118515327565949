// React
import React, { Component } from 'react';
import { RawIntlProvider } from 'react-intl';
// Redux
import { Provider } from 'react-redux';
// Material UI
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import { StylesProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { SplitFactory } from '@splitsoftware/splitio-react';

// External modules
import ReactGA from 'react-ga';
// Assets
import '../../assets/styles/styles.css';
import '../../assets/styles/fonts.css';
import messages_es from '../../assets/i18n/es.json';
import messages_pt from '../../assets/i18n/pt.json';
import 'moment/locale/es';
// Consts
import Colors from '../../constants/Colors';
// Store
import appStore from '../../redux/stores/app.store';
// Services
import LocalStorageService from '../../services/local-storage-service';
// Actions
import AppActions from '../../redux/actions/app-actions';
// Routes
import Routing from '../routing';
import { initializeIntl } from '../../intl/intl';
// Components
import { muiTypographyProps, muiThemeTypography } from '../common/typography';
// Styles
import { BlockNotifyMobileDevices } from '../../features/block-notify-mobile-devices';
import { neutral } from '../../shared/styles/colors';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
  debug: process.env.REACT_APP_GA_DEBUG_ENABLED === 'true',
  titleCase: false,
});

const messages = {
  es: messages_es,
  pt: messages_pt,
};

const defaultLanguage = 'es';
const storedLanguage = LocalStorageService.getLanguage();
const navigatorLanguage = navigator.language.substring(0, 2);

let language = defaultLanguage;

if (storedLanguage) {
  language = storedLanguage;
} else if (messages.hasOwnProperty(navigatorLanguage)) {
  language = navigatorLanguage;
}
appStore.store.dispatch(AppActions.setLanguageAction(language));
LocalStorageService.setLanguage(language);

const intl = initializeIntl(language, messages);

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    text: {
      primary: neutral.black,
      secondary: neutral.D15,
    },
    primary: {
      contrastText: neutral.white,
      main: Colors.PRIMARY_COLOR,
    },
    secondary: {
      contrastText: neutral.white,
      main: Colors.SECONDARY_COLOR,
    },
    background: {
      default: neutral.white,
    },
    tertiary: {
      contrastText: neutral.white,
      main: neutral.D10,
    },
  },
  typography: muiThemeTypography,
  props: {
    MuiTypography: muiTypographyProps,
  },
});

const sdkSplitConfig = {
  core: {
    authorizationKey: process.env.REACT_APP_SPLIT_IO_API_KEY,
    key: appStore.getPartnerEmail() || '',
  },
};

class App extends Component {
  render() {
    return (
      <SplitFactory config={sdkSplitConfig}>
        <Provider store={appStore.store}>
          <RawIntlProvider value={intl}>
            <CssBaseline>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ThemeProvider theme={theme}>
                  <StylesProvider injectFirst>
                    <BlockNotifyMobileDevices>
                      <Routing />
                    </BlockNotifyMobileDevices>
                  </StylesProvider>
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </CssBaseline>
          </RawIntlProvider>
        </Provider>
      </SplitFactory>
    );
  }
}

export default App;
