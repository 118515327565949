import { makeStyles } from '@material-ui/core';

import { teal } from '../../shared/styles/colors';

export default makeStyles({
  root: {
    alignSelf: 'center',
  },
  submitButton: {
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '1rem',
    background: 'transparent',
    color: teal['50'],
  },
});
