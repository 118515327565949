import moment from 'moment-timezone';

import { ALEATORY_DAY_IN_PAST } from './consts';
import { APP_VERSION } from '../../constants/commons';
import { maxTooltipTimesShown } from '../../constants/CookingTimeTooltipConsts';

import JSONUtils from '../../utils/json-utils';
import CountryUtils from '../../utils/country-utils';
import UrlUtils from '../../utils/url-utils/UrlUtils';
import DateTimeUtils from '../../utils/DateTimeUtils';

// TODO: all keys should be into shared/infra and not access directly
import { LOCAL_STORAGE_KEY } from '../../business/shared/infra/core/consts/localStorage.const';

const ACCESS_TOKEN_KEY = 'accessToken';
const COUNTRY_CODE = LOCAL_STORAGE_KEY.country.code;
const COUNTRY_SERVICES = LOCAL_STORAGE_KEY.country.services;
const COUNTRY_CURRENCY_PREFIX = LOCAL_STORAGE_KEY.country.currency.prefix;
const COUNTRY_URL_MICROSERVICE_KEY = 'countryUrlMicroservice';
const FCM_TOKEN = 'FCM_TOKEN';
const LANGUAGE = 'language';
const REFRESH_TOKEN_KEY = 'refreshToken';
const COUNTRY_FLAG = 'countryFlag';
const NETWORK_ERROR_EVENT = 'network_error_event';
const PARTNER_ID = 'partnerId';
const LAST_SHOWN = 'lastShown';
const WAS_ACTIVATED_PRINTED_CHECKLIST_RA1503 =
  'was_activated_printed_checklist_by_RA1503';
const IS_ACTIVATED_PRINTER_CHECKLIST = 'is_activated_printer_checklist';
const IS_ACTIVATED_COMANDA = 'is_activated_comanda';

const getNetworkErrorEvent = tz => {
  const event = localStorage.getItem(NETWORK_ERROR_EVENT);
  const parsedEvent = JSONUtils.tryParse(event);

  if (!parsedEvent) {
    return null;
  }

  const momentDateTime = moment(parsedEvent.date_time);

  return {
    appVersion: parsedEvent.app_version,
    hasExtension: parsedEvent.has_extension || false,
    dateTime: momentDateTime.isValid()
      ? momentDateTime
      : DateTimeUtils.getMomentNow(tz),
  };
};

const setNetworkErrorEvent = (hasExtension, tz) => {
  const lastNetworkErrorEvent = getNetworkErrorEvent();

  if (!!lastNetworkErrorEvent) {
    return;
  }

  const eventToSave = {
    app_version: APP_VERSION,
    has_extension: hasExtension,
    date_time: DateTimeUtils.getISONow(tz),
  };

  localStorage.setItem(
    NETWORK_ERROR_EVENT,
    JSONUtils.tryStringify(eventToSave),
  );
};

const removeNetworkErrorEvent = () =>
  localStorage.removeItem(NETWORK_ERROR_EVENT);

const clear = () => {
  // get the device Id and use kanban to avoid to delete it.
  const currentDeviceId = getDeviceId();
  const isActiveChecklist = getIsActivatedPrinterChecklist();
  const lastShown = localStorage.getItem(LAST_SHOWN);
  // Clear localStorage
  localStorage.clear();
  // Set again the device ID.
  if (lastShown) localStorage.setItem(LAST_SHOWN, lastShown);
  setDeviceId(currentDeviceId);
  setIsActivatedPrinterChecklist(isActiveChecklist ? '1' : '0');
};

const isAuthenticated = () => {
  const currentAccessToken = getAccessToken();
  const currentRefreshToken = getRefreshToken();

  return currentAccessToken && currentRefreshToken;
};

function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

function getBaseUrl() {
  return localStorage.getItem(COUNTRY_SERVICES);
}

function getCountry() {
  const pushString = localStorage.getItem('countryPush');
  const push = pushString ? JSON.parse(pushString) : null;

  return {
    cdn: localStorage.getItem('countryCdn'),
    centralized_server_host: localStorage.getItem(
      'countryCentralizedServerHost',
    ),
    code: localStorage.getItem(COUNTRY_CODE),
    currency_prefix: localStorage.getItem(COUNTRY_CURRENCY_PREFIX),
    images: localStorage.getItem('countryImages'),
    images_s3: localStorage.getItem('countryImagesS3'),
    name: localStorage.getItem('countryName'),
    push,
    phone_prefix: localStorage.getItem('phonePrefix'),
    server: localStorage.getItem('countryServer'),
    services: localStorage.getItem(COUNTRY_SERVICES),
    services_intern: localStorage.getItem('countryServicesIntern'),
    socket: localStorage.getItem('countrySocket'),
    url_microservice: localStorage.getItem(COUNTRY_URL_MICROSERVICE_KEY),
    url_server: getBaseUrl(),
    web_site: localStorage.getItem('countryWebSite'),
  };
}

const getCountryCode = () => {
  return localStorage.getItem(COUNTRY_CODE);
};
const getRestaurantsImage = logo => {
  const country = getCountryCode();
  return CountryUtils.formatLogoURL(country, logo);
};
const getCountryCurrencyPrefix = () => {
  return localStorage.getItem(COUNTRY_CURRENCY_PREFIX);
};

const getCountryName = () => {
  return localStorage.getItem('countryName');
};

function getCountryMicroserviceUrl() {
  return localStorage.getItem(COUNTRY_URL_MICROSERVICE_KEY);
}

const getCountryServicesUrl = () => {
  return localStorage.getItem(COUNTRY_SERVICES);
};

const getDeviceId = () => {
  return localStorage.getItem('deviceId');
};

const getFCMToken = () => {
  return localStorage.getItem(FCM_TOKEN);
};

const getLanguage = () => {
  return localStorage.getItem(LANGUAGE);
};

const getLat = () => {
  return localStorage.getItem('lat');
};

const getLng = () => {
  return localStorage.getItem('lng');
};

function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
}

function getAutoPrintEnabled() {
  return localStorage.getItem('autoPrintEnabled') === 'true';
}

function getTimesCookingClockTooltipHasBeenShown() {
  const times = localStorage.getItem('timesCookingClockTooltipHasBeenShown');
  return times === undefined || times === null ? 0 : times;
}
function increaseTimesCookingClockTooltipHasBeenShown() {
  let times = getTimesCookingClockTooltipHasBeenShown();
  if (times > maxTooltipTimesShown) {
    return;
  }
  times = times ? ++times : 1;
  setTimesCookingClockTooltipHasBeenShown(times);
}

const isBrazil = () => {
  const countryCode = getCountryCode();
  return CountryUtils.isBrazil(countryCode);
};

const isUruguay = () => {
  const countryCode = getCountryCode();
  return CountryUtils.isUruguay(countryCode);
};

const isPeru = () => {
  const countryCode = getCountryCode();
  return CountryUtils.isPeru(countryCode);
};
const isChile = () => {
  const countryCode = getCountryCode();
  return CountryUtils.isChile(countryCode);
};

const isEcuador = () => {
  const countryCode = getCountryCode();
  return CountryUtils.isEcuador(countryCode);
};
function setTimesCookingClockTooltipHasBeenShown(times) {
  localStorage.setItem('timesCookingClockTooltipHasBeenShown', times);
}

function removeAccessToken() {
  return localStorage.removeItem(ACCESS_TOKEN_KEY);
}
function removeRefreshToken() {
  return localStorage.removeItem(REFRESH_TOKEN_KEY);
}

function removeTokens() {
  removeAccessToken();
  removeRefreshToken();
}

function setAccessToken(accessToken) {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
}

function setRefreshToken(refreshToken) {
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
}

function setAutoPrintEnabled(value) {
  localStorage.setItem('autoPrintEnabled', value);
}

function setCountry(country) {
  localStorage.setItem('countryCdn', country.cdn);
  localStorage.setItem(
    'countryCentralizedServerHost',
    country.centralized_server_host,
  );
  localStorage.setItem(COUNTRY_CODE, country.code);
  localStorage.setItem(COUNTRY_CURRENCY_PREFIX, country.currency_prefix);
  localStorage.setItem('countryImages', country.images);
  localStorage.setItem('countryImagesS3', country.images_s3);
  localStorage.setItem('countryName', country.name);
  localStorage.setItem('phonePrefix', country.phone_prefix);
  localStorage.setItem('countryPush', JSON.stringify(country.push));
  localStorage.setItem('countryServer', country.server);
  localStorage.setItem('countryServicesIntern', country.services_intern);
  localStorage.setItem('countrySocket', country.socket);

  // First, we need to ensure that the url
  const countryUrlMicroservice = UrlUtils.ensureUrlFormat(
    country.url_microservice,
  );

  localStorage.setItem('countryUrlMicroservice', countryUrlMicroservice);

  const countryServices = UrlUtils.ensureUrlFormat(country.services);
  setCountryServices(countryServices);
  // First, we need to ensure that the url
  const countryUrlServer = UrlUtils.ensureUrlFormat(country.url_server);

  localStorage.setItem('countryUrlServer', countryUrlServer);
  localStorage.setItem('countryWebSite', country.web_site);
}

const setCountryServices = countryServices => {
  localStorage.setItem(COUNTRY_SERVICES, countryServices);
};

const setDeviceId = deviceId => {
  localStorage.setItem('deviceId', deviceId);
};

const setFCMToken = token => {
  localStorage.setItem(FCM_TOKEN, token);
};

const setLanguage = language => {
  localStorage.setItem(LANGUAGE, language);
};

const setLat = lat => {
  localStorage.setItem('lat', lat);
};

const setLng = lng => {
  localStorage.setItem('lng', lng);
};

const setLatAndLng = (lat, lng) => {
  setLat(lat);
  setLng(lng);
};

function setTokens(accessToken, refreshToken) {
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
}
const getFlag = () => {
  return localStorage.getItem(COUNTRY_FLAG);
};

const setFlag = countryFlag => {
  localStorage.setItem(COUNTRY_FLAG, countryFlag);
};

const setPartnerId = partnerId => {
  localStorage.setItem(PARTNER_ID, partnerId);
};
const getPartnerId = () => {
  return localStorage.getItem(PARTNER_ID);
};

const setDeactivatedProductsDialogLastShown = time => {
  localStorage.setItem(LAST_SHOWN, time);
};

const getDeactivatedProductsDialogLastShown = () => {
  return localStorage.getItem(LAST_SHOWN) || new Date(ALEATORY_DAY_IN_PAST);
};

const getIsActivatedPrinterChecklist = () => {
  const forcedVTrue = 'true';
  const forced = localStorage.getItem(WAS_ACTIVATED_PRINTED_CHECKLIST_RA1503);
  const storageValue = localStorage.getItem(IS_ACTIVATED_PRINTER_CHECKLIST);
  if (storageValue === null || forced !== forcedVTrue) {
    setIsActivatedPrinterChecklist('1');
    localStorage.setItem(WAS_ACTIVATED_PRINTED_CHECKLIST_RA1503, forcedVTrue);
    return true;
  }
  return storageValue === '1';
};
const setIsActivatedPrinterChecklist = isChecked =>
  localStorage.setItem(IS_ACTIVATED_PRINTER_CHECKLIST, isChecked);

const getIsActivatedComanda = () => {
  const storageValue = localStorage.getItem(IS_ACTIVATED_COMANDA);
  if (storageValue === null) {
    setIsActivatedComanda('0');
    return true;
  }
  return storageValue === '1';
};
const setIsActivatedComanda = isChecked =>
  localStorage.setItem(IS_ACTIVATED_COMANDA, isChecked);

export default {
  setDeactivatedProductsDialogLastShown,
  getDeactivatedProductsDialogLastShown,
  getNetworkErrorEvent,
  setNetworkErrorEvent,
  removeNetworkErrorEvent,
  clear,
  getAccessToken,
  getAutoPrintEnabled,
  getBaseUrl,
  getCountry,
  getCountryCode,
  getCountryCurrencyPrefix,
  getCountryMicroserviceUrl,
  getCountryName,
  getDeviceId,
  getFCMToken,
  getLanguage,
  getLat,
  getLng,
  getRefreshToken,
  getRestaurantsImage,
  getCountryServicesUrl,
  getTimesCookingClockTooltipHasBeenShown,
  isAuthenticated,
  increaseTimesCookingClockTooltipHasBeenShown,
  isBrazil,
  isUruguay,
  isEcuador,
  isPeru,
  isChile,
  removeTokens,
  setAccessToken,
  setAutoPrintEnabled,
  setCountry,
  setCountryServices,
  setDeviceId,
  setFCMToken,
  setLanguage,
  setLatAndLng,
  setRefreshToken,
  setTokens,
  setFlag,
  getFlag,
  setPartnerId,
  getPartnerId,
  getIsActivatedPrinterChecklist,
  setIsActivatedPrinterChecklist,
  getIsActivatedComanda,
  setIsActivatedComanda,
};
