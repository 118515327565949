const confirmButtonText = {
  id: 'globals.confirm',
};

const description = {
  id: 'orderRejectionByMotoboyLackDialog.description',
};

const label = {
  id: 'globals.minutes',
};

const textIds = {
  confirmButtonText,
  description,
  label,
};

export default textIds;
