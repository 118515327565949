import { useSelector } from 'react-redux';

import { selectors as helpCenterSelectors } from '../../../../../redux/help-center-page';

const useData = () => useSelector(helpCenterSelectors.selectData);

const useSelecteCategoryId = () =>
  useSelector(helpCenterSelectors.selectedCategoryId);

const useOpen = () => useSelector(helpCenterSelectors.selectOpen);

const useSelected = () => {};

const hooks = {
  useData,
  useOpen,
  useSelected,
  useSelecteCategoryId,
};

export default hooks;
