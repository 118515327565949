import { makeStyles } from '@material-ui/styles';

import { neutral } from '../../../../../shared/styles/colors';

export default makeStyles({
  content: {
    fontSize: '0.75rem',
    color: neutral['80'],
  },
  icon: {
    fontSize: '1.25rem',
    alignSelf: 'start',
  },
  root: {
    width: '100%',
    padding: '0.75rem 1rem',
    display: 'grid',
    gridTemplateColumns: 'min-content auto',
    columnGap: '0.5rem',
    fontSize: '0.75rem',
    fontWeight: 'normal',
    lineHeight: '1.25rem',
    alignItems: 'center',
    whiteSpace: 'pre-line',
    borderRadius: '0.5rem',
  },
});
