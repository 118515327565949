import { OrderPrintingConfig } from './components';
import AutomaticCookingTimeSetting from '../automatic-cooking-time-setting';
import PrintingAgentDownloadSetting from '../printing-agent-download-setting';
import ChromeExtensionDownloadSetting from '../chrome-extension-download-setting';

import makeClasses from './SettingsOptionsAndExtensions.styles';

function SettingsOptionsAndExtensions({ showPrintSettings }) {
  const _classes = makeClasses();
  return (
    <div className={_classes.root}>
      {showPrintSettings && (
        <>
          <AutomaticCookingTimeSetting
            classes={{ root: _classes.automaticCookingTimeSettingRoot }}
          />
          <div className={_classes.automaticCookingTimeSettingRoot}>
            <OrderPrintingConfig />
          </div>
          <PrintingAgentDownloadSetting />
          <ChromeExtensionDownloadSetting />
        </>
      )}
    </div>
  );
}

export default SettingsOptionsAndExtensions;
