// Material UI
import { makeStyles } from '@material-ui/core';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  body: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 43,
      paddingRight: 43,
    },
  },
  headerRoot: {
    gridTemplateColumns: 'auto auto min-content',
    padding: 4,
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'min-content auto min-content',
      borderBottom: 'none',
      padding: '0px 0px 0px 43px',
    },
  },
  dialogTitle: {
    fontSize: 18,
    padding: '12px 0px 12px 12px',
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      width: 926,
      height: 666,
    },
  },
  stepper: {
    gridArea: 'stepper',
  },
  title: {
    gridArea: 'title',
    [theme.breakpoints.up('md')]: {
      paddingTop: 42,
    },
  },
  iconImage: {
    gridArea: 'icon',
    width: 64,
    height: 64,
  },
  description: {
    gridArea: 'description',
  },

  descriptionBox: {
    display: 'grid',
    gridArea: 'descriptionBox',
    gridTemplateAreas: `"description"`,
    gridColumnGap: 16,
    alignItems: 'center',
    padding: '30px 12px 0px 0px',
    [theme.breakpoints.up('sm')]: {
      padding: '4px 0px 0px 0px',
      gridTemplateAreas: `"description"`,
      gridTemplateColumns: 'auto',
      placeContent: 'center',
      placeItems: 'center',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateAreas: `"icon description"`,
      padding: 0,
      justifySelf: 'left',
      columnGap: 18,
    },
  },
  root: {
    display: 'grid',
    gridRowGap: 50,
    gridTemplateAreas: `"stepper"
                        "descriptionBox"
                        "inputContainer"`,
    padding: '34px 4px 34px 16px',
    [theme.breakpoints.up('sm')]: {
      padding: '50px 22px',
      gridRowGap: 60,
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateAreas: `"stepper"
                        "title"
                        "descriptionBox"
                        "inputContainer"`,
      padding: 0,
      gridRowGap: 20,
    },
  },
  footerButtonSubmit: {
    fontSize: 16,
    gridArea: 'footerButtonSubmit',
    justifySelf: 'end',
  },
  containerButtons: {
    backgroundColor: neutral.white,
    fontSize: 16,
    borderTop: `1px solid ${neutral.D10}`,
    bottom: 0,
    width: '100%',
    gap: 14,
    padding: 16,
    display: 'grid',
    gridTemplateAreas: `"footerButtonSubmit"
                        "footerRejectButton"`,
  },
}));
