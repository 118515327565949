import { connect } from 'react-redux';
import EmptyRtAvatar from './EmptyRtAvatar';
import { injectIntl } from 'react-intl';

const mapStateToProps = (state, { intl: { formatMessage } }) => {
  const canceledTitle = formatMessage({ id: 'store.summary.canceled' });

  return {
    canceledTitle,
  };
};
const mapDispatchToProps = {};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EmptyRtAvatar),
);
