import { useState } from 'react';
import { Hidden } from '@material-ui/core';

import { Form } from './components/form';
import RappiLogoAppBar from '../common/rappi-logo-app-bar';
import { ExCarouselLogin } from './components/ex-carousel-login';
import { ChangePasswordPath } from './components/change-password-path/ChangePasswordPath';

import useStyles from './styles';

export function LoginPage() {
  const [showForm, setShowForm] = useState(true);

  const handleShowForm = (value: any) => setShowForm(value);

  const _classes = useStyles();

  return (
    <div className={_classes.root}>
      <RappiLogoAppBar />
      <div className={_classes.mainContainer}>
        <Hidden smDown>
          <ExCarouselLogin />
        </Hidden>
        <main className={_classes.loginFormContainer}>
          {showForm && <Form handleShowForm={handleShowForm} />}
          {!showForm && <ChangePasswordPath handleShowForm={handleShowForm} />}
        </main>
      </div>
    </div>
  );
}
