import { createStyles, makeStyles } from '@material-ui/core';
import Colors from '../../../../../constants/Colors';

export const KanbanTimeoutCardStyle = makeStyles(theme =>
  createStyles({
    top: {
      color: Colors.DANGER_LIGHT_1,
    },
    circle: {
      strokeLinecap: 'round',
    },
  }),
);
