// Material UI
import { makeStyles } from '@material-ui/core'
// Consts
import Colors from '../../../../constants/Colors'

export default makeStyles(theme => ({
  infoIcon: {
    color: Colors.DANGER_COLOR,
  },
  root: {
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: 'min-content auto',
    gridColumnGap: 16,
  },
}))
