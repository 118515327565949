// React
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// Actions
import OrdersKanbanAction from './../../redux/actions/ordersKanban.actions';
// Components
import OrdersKanbanTabs from './OrdersKanbanTabs';
import PartnerOrderStateType from '../../constants/PartnerOrderStateType';

const mapStateToProps = (
  {
    ordersKanbanReducer: {
      index,
      orders: {
        [PartnerOrderStateType.NEWORDER]: pendingToAcceptOrders,
        [PartnerOrderStateType.CANCEL_APP]: canceledOrders,
        [PartnerOrderStateType.READYANDPACKED]: pendingToDeliverOrders,
        [PartnerOrderStateType.COOKING]: cookingOrders,
      },
    },
  },
  { intl: { formatMessage } },
) => {
  const cookingText = formatMessage({ id: 'ordersKanbanTabs.cooking' });
  const cookingMobileText = formatMessage({
    id: 'ordersKanbanTabs.cooking.mobile',
  });
  const pendingToAcceptText = formatMessage({
    id: 'ordersKanbanTabs.pendingToAccept',
  });
  const pendingToAcceptMobileText = formatMessage({
    id: 'ordersKanbanTabs.pendingToAccept.mobile',
  });
  const pendingToDeliverText = formatMessage({
    id: 'ordersKanbanTabs.pendingToDeliver',
  });
  const pendingToDeliverMobileText = formatMessage({
    id: 'ordersKanbanTabs.pendingToDeliver.mobile',
  });

  const totalCookingOrders = cookingOrders.length;

  const totalPendingToAcceptOrders =
    canceledOrders.length + pendingToAcceptOrders.length;

  const totalPendingToDeliverOrders = pendingToDeliverOrders.length;

  return {
    index,
    cookingText,
    cookingMobileText,
    pendingToAcceptText,
    pendingToAcceptMobileText,
    pendingToDeliverText,
    pendingToDeliverMobileText,
    totalCookingOrders,
    totalPendingToAcceptOrders,
    totalPendingToDeliverOrders,
  };
};

const mapDispatchToProps = {
  onChange: OrdersKanbanAction.setKanbanIndex,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(OrdersKanbanTabs),
);
