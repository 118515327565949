// Interfaces
import { LogOutReasons } from './interfaces'
import { LogOutReasonsResponse } from '../../services/partners-service'

const parseToLogOutReason = (reason: LogOutReasonsResponse): LogOutReasons => {
  return {
    reasonId: reason.code || '',
    reasonText: reason.reason || '',
  }
}
const parseToLogOutReasons = (
  reasons: LogOutReasonsResponse[] | null | undefined,
): LogOutReasons[] => reasons?.map(parseToLogOutReason) || []

const utils = { parseToLogOutReasons }

export default utils
