import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  childrenContainer: {
    paddingBottom: 16,
    display: 'grid',
    flex: 'none',
    [theme.breakpoints.up('md')]: {
      padding: 0,
      gridTemplateRows: 'auto min-content',
      overflow: 'hidden',
    },
  },
  backdrop: {
    backdropFilter: 'blur(4px)',
    backgroundColor: 'rgb(255 255 255 / 0%) !important',
  },
  headerRoot: {
    gridTemplateColumns: 'auto auto min-content',
    display: 'none',
    borderBottom: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'grid',
    },
  },
  headerRootTour: ({ isFirstLogin }: { isFirstLogin: boolean }) => ({
    visibility: isFirstLogin ? 'hidden' : 'initial',
    padding: '0 1rem',
  }),
  headerCloseBtn: {
    padding: '0.25rem',
  },
  paper: {
    borderRadius: 8,
  },
  paperTour: {
    height: '100%',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      width: 791,
    },
  },
  paperTip: {
    height: '100%',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      width: 737,
    },
  },
  title: {
    fontSize: 18,
    [theme.breakpoints.up('md')]: {
      padding: '0px 0px 0px 12px',
    },
  },
}));
