import { orderSelectors } from './store';

export function orderhandler() {
  return {
    allOrders: orderSelectors.getAll(),
    currentOrderId: orderSelectors.orderId(),
    currentOrder: orderSelectors.order(),
    detail: orderSelectors.detail(),
  };
}
