import { makeStyles } from '@material-ui/core';

import { blue, neutral } from '../../../../shared/styles/colors';

export default makeStyles({
  alert: {
    padding: '0.5rem 1rem',
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: 'min-content auto max-content min-content',
    alignItems: 'center',
    color: blue['60'],
    backgroundColor: blue['10'],
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem',
  },
  msg: {
    width: '100%',
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
  },
  ctaBtn: {
    padding: '0.25rem 0.75rem',
    borderRadius: '0.5rem',
    color: blue['10'],
    backgroundColor: blue['60'],
    '&:hover': {
      color: neutral.white,
      backgroundColor: blue['70'],
    },
  },
  ctaLabel: {
    fontWeight: 'bold',
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
  },
});
