// Redux
import { createAction } from '@reduxjs/toolkit';

const closeAction = createAction('supportDialog/close');

const clearAction = createAction('supportDialog/clear');

const openAction = createAction('supportDialog/open');

const clear = () => (dispatch: any) => {
  dispatch(clearAction());
};

const close = () => (dispatch: any) => {
  dispatch(closeAction());
};

const open = () => (dispatch: any) => {
  dispatch(openAction());
};

const actions = {
  clear,
  clearAction,
  close,
  closeAction,
  open,
  openAction,
};

export default actions;
