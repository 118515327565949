import { cargoDialog } from '../../../shared/services';
import { CargoUICase } from '../../../business/cargo';

export function useDialog() {
  const { clear, onSetOpenDialog } = CargoUICase();

  const _dialogHandler = (isOpen: boolean) => {
    cargoDialog.callMainDialog.add(() => onSetOpenDialog(isOpen));
    cargoDialog.callMainDialog.dispatch();
  };

  const openDialog = () => _dialogHandler(true);

  const closeDialog = () => {
    clear();
    _dialogHandler(false);
  };

  return {
    openDialog,
    closeDialog,
  };
}
