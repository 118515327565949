// React
import React from 'react';
// Material UI
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RadioButtonCheckedOutlined from '@material-ui/icons/RadioButtonCheckedOutlined';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
// Styles
import useStyles from './CustomRadioCheck.styles';

const CustomRadioCheck = ({ className, disabled, label, onChange, value }) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      className={className}
      classes={{
        label: classes.label,
      }}
      control={
        <Checkbox
          classes={{ root: classes.control }}
          disabled={disabled}
          icon={<RadioButtonUnchecked />}
          checkedIcon={<RadioButtonCheckedOutlined />}
          color='primary'
        />
      }
      label={label}
      onChange={onChange}
      value={value}
    />
  );
};

export default CustomRadioCheck;
