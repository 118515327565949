import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => ({
  childrenContainer: {
    gridArea: 'childrenContainer',
    overflow: 'auto',
    flex: '1 1 1px',
    padding: ' 0px 16px 0px 16px',
    [theme.breakpoints.up('sm')]: {
      padding: ' 0px 22px 0px 22px',
    },
    [theme.breakpoints.up('md')]: {
      padding: ' 0px 43px 0px 43px',
    },
  },
  closeButton: {
    gridArea: 'closeButton',
    justifySelf: 'end',
  },
  backButton: {
    gridArea: 'backButton',
    justifySelf: 'start',
  },
  header: {
    justifyContent: 'space-between',
    display: 'grid',
    padding: 4,
    gridTemplateAreas: `"backButton closeButton"`,
  },
  container: {
    // Avoids dialog paper desconfiguration after printing
    height: '100% !important',
  },
  footerRoot: {
    borderTop: `1px solid ${neutral['20']}`,
  },
  root: {
    '@media print': {
      display: 'none !important',
    },
  },
  paper: {
    height: '100%',
    margin: 0,
    maxHeight: 'none',
    maxWidth: 'none',
    width: '100%',
    borderRadius: 8,
    [theme.breakpoints.up('md')]: {
      height: 526,
      maxHeight: '100%',
      maxWidth: '100%',
      width: 576,
      overflowY: 'hidden',
    },
  },
  footerButtonSubmit: {
    gridArea: 'footerButtonSubmit',
    justifySelf: 'end',
  },
  footerRejectButton: {
    gridArea: 'footerRejectButton',
    justifySelf: 'end',
  },
  containerButtons: {
    borderTop: `1px solid ${neutral['20']}`,
    position: 'fixed',
    bottom: 0,
    width: '100%',
    gap: 14,
    padding: 16,
    gridArea: 'mobileButton',
    display: 'grid',
    gridTemplateAreas: `"footerButtonSubmit"
                        "footerRejectButton"`,
  },
  bannerImageDescription: {
    color: neutral.white,
    fontSize: 14,
    fontWeight: 600,
    padding: '36px 20px 62px 154px',
    textAlign: 'left',
    lineHeight: 1.36,
    letterSpacing: 'normal',
    [theme.breakpoints.up('sm')]: {
      fontSize: 22,
      fontWeight: 600,
      textAlign: 'left',
      padding: '142px 54px 256px 46.5%',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
      fontWeight: 600,
      textAlign: 'left',
      padding: '35px 44px 54px 256px',
    },
  },
  box: ({ image }: { image: any }) => ({
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    display: 'grid',
    gridAutoFlow: 'row',
  }),
}));
