// External libs
import { createReducer } from '@reduxjs/toolkit'
import { Serverities } from '../../../constants/Severities'
import actions from '../actions'
// Interfaces
import { State } from '../interfaces'
import { Button } from '../interfaces/button'

const initialState: State = {
  open: false,
  id: '',
  title: '',
  description: '',
  mainButtonText: '',
  mainButton: null,
  closeButton: null,
  color: Serverities.ERROR,
  shouldSound: false,
}

export default createReducer(initialState, {
  [actions.closeAction.type]: state => close(state),
  [actions.openAction.type]: (
    state,
    {
      payload: {
        id,
        title,
        description,
        mainButtonText,
        mainButton,
        closeButton,
        color,
        shouldSound,
      },
    },
  ) =>
    open(
      state,
      id,
      title,
      description,
      mainButtonText,
      mainButton,
      closeButton,
      color,
      shouldSound,
    ),
})

const close = (state: State) => {
  state.open = false
  state.shouldSound = false
  return state
}

const open = (
  state: State,
  id: string,
  title: string,
  description: string,
  mainButtonText: string,
  mainButton: Button | null,
  closeButton: Button | null,
  color: Serverities,
  shouldSound: boolean,
) => {
  state.open = true
  state.id = id
  state.title = title
  state.description = description
  state.mainButtonText = mainButtonText
  state.mainButton = mainButton
  state.color = color
  state.closeButton = closeButton
  state.shouldSound = shouldSound
  return state
}
