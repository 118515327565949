const title = {
  id: 'successfulSuggestingProductDialog.title',
};
const subTitle = {
  id: 'suggestProductDialog.subTitle',
};
const description = {
  id: 'successfulSuggestingProductDialog.description',
};
const infoText = {
  id: 'suggestProductDialog.infoText',
};
const replaceWithText = {
  id: 'suggestProductDialog.replaceWithText',
};
const nothaveProductText = {
  id: 'suggestProductDialog.notHaveProduct',
};
const closeButtonText = {
  id: 'successfulSuggestingProductDialog.closeButtonText',
};

const placeHolderNotHaveProduct = {
  id: 'successfulSuggestingProductDialog.placeHolderNotHaveProduct',
};
const productPlaceHolder = { id: 'suggestProductDialog.placerHolder' };

const textIds = {
  description,
  infoText,
  nothaveProductText,
  closeButtonText,
  replaceWithText,
  subTitle,
  title,
  productPlaceHolder,
  placeHolderNotHaveProduct,
};

export default textIds;
