export const DELAY_DEBOUNCE_TIME = 800;

export const MIN_NUMBER_OF_ITEM_SUGGESTIONS = 1;
export const MAX_NUMBER_OF_ITEM_SUGGESTIONS = 2;

export const MAX_NUMBER_OF_SEARCH_ATTEMPTS = 3;

export const WAITING_TO_WAIT_IN_SEG = 300;
export const COUNTDOWN_ORDER_IN_SEG = 420;
export const COUNTDOWN_TIMEOUT_ORDER_IN_SEG = 180;
export const MAX_LENGTH_SEARCH_CHARACTERS = 50;

export const LS_KEY_RECENTLY_SEARCHED_QUERIES = {
  product: 'orderMgmtSearchedQueriesForProducts',
  topping: 'orderMgmtSearchedQueriesForToppings',
};
