import { useTheme, useMediaQuery } from '@material-ui/core';

import CustomButton from '../buttons/custom-button/CustomButton';
import AllowNotificationsIcon from '../../icons/allow-notifications-icon/AllowNotificationsIcon';
import NotificationTutorialImg from '../../icons/notification-tutorial-img/NotificationTutorialImg';

import useStyles from './CustomAllowNotifications.styles';

export function CustomAllowNotifications(props) {
  const {
    textAllow,
    textNotif,
    allowText,
    acceptLabel,
    cancelLabel,
    onAcknowledge,
    onAllow,
    onCancel,
    showIcon,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <div className={classes.box}>
      <div className={classes.root}>
        {showIcon && (
          <div className={classes.icon}>
            <AllowNotificationsIcon />
          </div>
        )}
        {isMdUp && (
          <div className={classes.tutorialImg}>
            <NotificationTutorialImg />
          </div>
        )}
        <div className={classes.group}>
          <div className={classes.first}>{textAllow}</div>
          <div className={classes.second}>{textNotif}</div>
          <div className={classes.text}>{allowText}</div>
        </div>
      </div>
      <div className={classes.cta}>
        {!!onAllow && (
          <CustomButton
            text={acceptLabel}
            variant='contained'
            color='primary'
            onClick={onAllow}
            classes={{ submitButton: classes.primary }}
          />
        )}
        {!!onAcknowledge && !isMdUp && (
          <CustomButton
            text={acceptLabel}
            variant='contained'
            color='secondary'
            onClick={onAcknowledge}
          />
        )}
        {!!onCancel && (
          <CustomButton
            text={cancelLabel}
            classes={{ submitButton: classes.cancelBtn }}
            onClick={onCancel}
          />
        )}
      </div>
    </div>
  );
}
