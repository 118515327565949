import { CustomDialog02 } from '../../components/common/custom-dialog-02';
import { useBlockNotifyMobileDevices } from './useBlockNotifyMobileDevices';

import type { ReactNode } from 'react';

export function BlockNotifyMobileDevices({ children }: Props) {
  const {
    open,
    texts,
    linkAppAndroid,
    handleClose,
    handleGoToGooglePlayStore,
  } = useBlockNotifyMobileDevices();

  return (
    <>
      {!open && children}
      <CustomDialog02
        open={open}
        variant={'info'}
        title={texts.title}
        style={{ zIndex: 2_000 }}
        onCloseByExitIcon={handleClose}
        primaryBtnText={texts.linkAppAndroid.btn}
        primaryBtnOnClick={handleGoToGooglePlayStore}
      >
        <p>{texts.paragraph.first}</p>
        <p>{texts.paragraph.second}</p>
        <p>{texts.paragraph.third}</p>
        <p style={{ fontSize: '1.5rem', textAlign: 'center' }}>
          <a rel='help' target='_self' href={linkAppAndroid}>
            {texts.linkAppAndroid.main}
          </a>
        </p>
      </CustomDialog02>
    </>
  );
}

interface Props {
  children?: ReactNode;
}
