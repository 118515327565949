import { clsx } from 'clsx';
import { useState, useEffect } from 'react';

import { defaults } from './consts';
import StringUtils from '../../../utils/StringUtils';

import useStyles from './styles';

import type moment from 'moment';
import type { BaseProps } from '../../../interfaces';

export function CustomTimeChip(props: Props) {
  const { classes, helperText, isDanger, value } = props;

  const _classes = useStyles();

  const [displayValue, setDisplayValue] = useState<string>(
    defaults.defaultDisplayValue,
  );

  useEffect(() => {
    const newDisplayValue =
      !value || !value.isValid()
        ? defaults.defaultDisplayValue
        : value.format('HH:mm');

    setDisplayValue(newDisplayValue);
  }, [value]);

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <div
        className={clsx(
          _classes.value,
          isDanger && _classes.dangerValue,
          classes?.value,
          isDanger && classes?.dangerValue,
        )}
      >
        {displayValue}
      </div>
      {!StringUtils.isNullOrEmpty(helperText) && (
        <div className={clsx(_classes.helperText, classes?.helperText)}>
          {helperText}
        </div>
      )}
    </div>
  );
}

interface Props extends BaseProps {
  helperText?: string | null;
  isDanger?: boolean | null;
  value?: moment.Moment | null;
}
