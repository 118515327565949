import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// Actions
import orderActions from '../../redux/actions/orders-actions';
// Components
import CancelKanbanCard from './CancelKanbanCard';

const mapStateToProps = (
  {
    partnerReducer: {
      profile: { stores },
    },
  },
  { intl: { formatMessage } },
) => {
  const title = formatMessage({ id: 'cancelKanbanCard.title' });
  const totalStores = stores.length;
  return {
    title,
    totalStores,
  };
};
const mapDispatchToProps = {
  onClickCard: orderActions.openOrderDetailDialog,
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CancelKanbanCard),
);
