// External libs
import { createAction } from '@reduxjs/toolkit'
// Utils
import utils from '../utils'
// Services
import storeService from '../../../services/store-service'
// Redux
import appStore from '../../stores/app.store'
import appActions from '../../actions/app-actions/app.actions'
import { LoadActionPayload } from '../interfaces'
import DateTimeUtils from '../../../utils/DateTimeUtils'

const closeAction = createAction('StoreDiagnosticsDialog/close')

const clearAction = createAction('StoreDiagnosticsDialog/clear')

const maximizeAction = createAction('StoreDiagnosticsDialog/maximize')

const loadAction = createAction<LoadActionPayload>(
  'StoreDiagnosticsDialog/load',
)

const openAction = createAction<number>('StoreDiagnosticsDialog/open')

const clear = () => (dispatch: any) => {
  dispatch(clearAction())
}

const close = () => (dispatch: any) => {
  dispatch(closeAction())
}

const maximize = () => (dispatch: any) => dispatch(maximizeAction())

const open = (selectedStoreId: number) => async (dispatch: any) => {
  const tz = appStore.getTz()

  const cache = appStore.getStoresDiagnosticsCache()

  const lastUpdated = cache[selectedStoreId]?.lastUpdated

  const shouldUpdate =
    !lastUpdated ||
    DateTimeUtils.getDiffFromNow(lastUpdated, 'minutes', tz, true) > 5

  if (!shouldUpdate) {
    dispatch(openAction(selectedStoreId))
    return
  }

  try {
    const { data } = await storeService.getDiagnostics(selectedStoreId)

    const storeDiagnosticsDialog = utils.parseToLoadDiagnosticActionPayload(
      selectedStoreId,
      data,
      tz,
    )

    dispatch(loadAction(storeDiagnosticsDialog))
  } catch (ex) {
    appActions.handleCatch(ex)(dispatch)
  }
}

const actions = {
  clear,
  clearAction,
  close,
  closeAction,
  maximize,
  maximizeAction,
  loadAction,
  open,
  openAction,
}

export default actions
