// Material UI
import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  body: {
    display: 'grid',
    margin: '36px 30px',
    gridGap: 12,
    gridTemplateAreas: `"img"
                        "productName"
                        "ContainerProduct"
                        "containerFooter"`,
    [theme.breakpoints.up('md')]: {
      margin: '60px 75px',
      overflow: 'hidden',
      gridGap: 0,
      gridTemplateAreas: `"img"
                        "productName"
                        "ContainerProduct"`,
    },
  },
  headerRoot: {},
  paper: {
    [theme.breakpoints.up('md')]: {
      height: 582,
      width: 576,
    },
  },
  input: {
    padding: '20px',
  },
  inputLabelOutlined: {
    transform: 'translate(20px, 20px) scale(1)',
  },
  inputLabel: {
    borderRadius: 15,
  },
  productInput: {
    gridArea: 'productInput',
  },
  replaceInput: {
    gridArea: 'replaceInput',
  },
  title: {
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 600,
    padding: '0px 30px 0px 30px',
    [theme.breakpoints.up('md')]: {
      padding: '0px 90px 0px 90px',
    },
  },
  productName: {
    gridArea: 'productName',
    height: 0,
    padding: '24px 0px 36px 0px',
    justifySelf: 'center',
    [theme.breakpoints.up('md')]: {},
  },
  successIconImg: {
    gridArea: 'img',
    width: 118,
    [theme.breakpoints.up('md')]: {
      width: 118,
    },
  },
  buttonSubmit: {},
  container: {
    justifySelf: 'center',
  },
  containerProduct: {
    gridArea: 'ContainerProduct',
    display: 'grid',
    gridTemplateAreas: `"productInput"
                        "replaceInput"`,
    gridGap: 10,
  },
  containerFooterButtons: {
    gridArea: 'containerFooter',
    display: 'grid',
    gridTemplateAreas: `"confirmButton"
                        "rejectButton"`,
    gridGap: 0,
  },
  confirmButtonFooter: {},
  rejectButtonFooter: {},
}))
