// Redux
import { createAction } from '@reduxjs/toolkit';
import { Serverities } from '../../../constants/Severities';
import { Button } from '../interfaces/button';

const closeAction = createAction('dynInAppDialog/close');

const openAction = createAction<{
  id: string | null;
  title: string | null;
  description: string | null;
  mainButton: Button | null;
  closeButton: Button | null;
  color: Serverities;
  shouldSound: boolean;
}>('dynInAppDialog/open');

const close = () => (dispatch: any) => {
  dispatch(closeAction());
};

const open = (
  id: string | null,
  title: string | null,
  description: string | null,
  mainButton: Button | null,
  closeButton: Button | null,
  color: Serverities,
  shouldSound: boolean,
) => (dispatch: any) => {
  dispatch(
    openAction({
      id,
      title,
      description,
      mainButton,
      closeButton,
      color,
      shouldSound,
    }),
  );
};

const actions = {
  close,
  closeAction,
  open,
  openAction,
};

export default actions;
