import React, { Component } from 'react';
import MenuIcon from './MenuIcon';

class MarketingIcon extends Component {
  d2 =
    'M7.286 14.773H4.503C3.673 14.773 3 14.1 3 13.271V10.73c0-.83.673-1.503 1.503-1.503h2.783v5.546zM21 21L7.286 14.773V9.227L21 3v18zM7.845 15.027L7.499 16.9a1.55 1.55 0 0 0 .84 1.67l2.778 1.368a1.55 1.55 0 0 0 1.958-.509l1.068-1.543';

  render() {
    const { selected } = this.props;

    return <MenuIcon d2={this.d2} selected={selected} />;
  }
}

export default MarketingIcon;
