// React
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
// External service.
import { get, find } from 'lodash';
// Actions
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';
import orderActions from '../../redux/actions/orders-actions';
// Components
import OrderRejectionReasonsDialog from './OrderRejectionReasonsDialog';

const mapStateToProps = (
  {
    orderRejectionReasonsDialogReducer: { open, orderId },
    ordersReducer: { orders },
  },
  { intl: { formatMessage } },
) => {
  const orderDetail = get(find(orders, { id: +orderId }), ['detail'], {
    id: +orderId,
  });

  const titleId = orderDetail.cooking_time
    ? 'orderRejectionReasonsDialog.cancellationTitle'
    : 'orderRejectionReasonsDialog.rejectionReasonTitle';

  const title = formatMessage({ id: titleId });

  return { open, title };
};

const mapDispatchToProps = {
  onBack: dialogsManagerActions.back,
  onClose: dialogsManagerActions.close,
  onClickReason: orderActions.executeRejectionReasonFromDialog,
};
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(OrderRejectionReasonsDialog),
);
