// External libs
import { createActions } from 'redux-actions';
import { get } from 'lodash';
// Utils
import { formatMessage } from '../../intl/intl';
// Services
import FirebaseService from '../../firebase/firebase';
import OrderService from '../../services/order-service';
import StorekeepersService from '../../services/StorekeepersService';
// Actions
import appStore from '../stores/app.store';
import appActions from './app-actions';
import dialogsManagerActions from './dialogsManager.actions';
import rtReportReasonsDialogActions from './RTReportReasonsDialog.actions';

const {
  getStorekeeperChannelAction,
  listenRtPositionAction: listenRTPositionAction,
} = createActions({
  GET_STOREKEEPER_CHANNEL_ACTION: (channel, database, orderId) => ({
    channel,
    database,
    orderId,
  }),
  LISTEN_RT_POSITION_ACTION: (bearing, lat, lng, precision, orderId) => ({
    bearing,
    lat,
    lng,
    precision,
    orderId,
  }),
});

const getChannel = (orderId, storeKeeperId) => dispatch => {
  if (!storeKeeperId) {
    return new Promise((resolve, reject) => resolve());
  }
  return StorekeepersService.getChannel(storeKeeperId).then(response => {
    dispatch(
      getStorekeeperChannelAction(
        response.data.channel,
        response.data.database,
        orderId,
      ),
    );
    return listenPosition(
      response.data.channel,
      response.data.database,
      orderId,
    )(dispatch);
  });
};

const listenPosition = (channel, database, orderId) => dispatch => {
  FirebaseService.startListeningRTDatabase(channel, database, snapshot => {
    const payload = snapshot.val();

    if (!payload) {
      return;
    }

    dispatch(
      listenRTPositionAction(
        payload.bearing,
        payload.lat,
        payload.lng,
        payload.precision,
        orderId,
      ),
    );
  });
};

const reportRTFromDialog = reason => async dispatch => {
  try {
    if (!reason) {
      return {
        hasFailed: false,
      };
    }

    const reasonRuleId = get(reason, 'rule_id', null);
    if (!reasonRuleId) {
      return {
        hasFailed: false,
      };
    }

    const reasonRuleDescription = get(reason, 'rule_description', null);
    if (!reasonRuleDescription) {
      return {
        hasFailed: false,
      };
    }

    const partnerId = appStore.getPartnerId();
    if (!partnerId) {
      return {
        hasFailed: false,
      };
    }

    const orderDetail = appStore.getOrderDetailInRTReportReasonsDialog();
    if (!orderDetail) {
      return {
        hasFailed: false,
      };
    }

    const orderId = get(orderDetail, 'id', null);
    if (!orderId) {
      return {
        hasFailed: false,
      };
    }

    const storekeeperId = get(orderDetail, 'storekeeper.id', null);
    if (!storekeeperId) {
      return {
        hasFailed: false,
      };
    }

    rtReportReasonsDialogActions.setRTReportReasonsDialogIsLoading()(dispatch);
    await OrderService.qualifyNegativeRT(
      reasonRuleDescription,
      orderId,
      partnerId,
      reasonRuleId,
      storekeeperId,
    );

    rtReportReasonsDialogActions.setRTReportReasonsDialogIsNotLoading()(
      dispatch,
    );

    const message = formatMessage({ id: 'reportRT.message.success' });
    const title = formatMessage({ id: 'reportRT.title.success' });
    appActions.openSnackBarSuccess(message, title)(dispatch);

    dialogsManagerActions.close()(dispatch);

    return {
      hasFailed: false,
    };
  } catch (ex) {
    rtReportReasonsDialogActions.setRTReportReasonsDialogIsNotLoading()(
      dispatch,
    );

    appActions.handleCatch(ex)(dispatch);

    return {
      hasFailed: true,
      ex,
    };
  }
};

export default {
  getChannel,
  getStorekeeperChannelAction,
  listenPosition,
  listenRTPositionAction,
  reportRTFromDialog,
};
