// Material UI
import { makeStyles } from '@material-ui/core';

const getZIndex = (theme, zIndexType = null) => {
  return !!zIndexType ? theme.zIndex[zIndexType] : 0;
};

export default makeStyles(theme => {
  return {
    backdropRoot: ({ zIndexType = null }) => ({
      position: 'absolute',
      zIndex: getZIndex(theme, zIndexType) + 1,
    }),
    customCircularProgressRoot: ({ size = 0, zIndexType = null }) => ({
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: size / -2,
      marginLeft: size / -2,
      zIndex: getZIndex(theme, zIndexType) + 1,
    }),
  };
});
