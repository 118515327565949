import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => {
  return {
    badge: {
      color: neutral['80'],
      fontSize: '1rem',
      lineHeight: 1,
    },
    badgeContainer: {
      borderRadius: '0.25rem',
      border: `1px solid ${neutral['20']}`,
      padding: '0.25rem 0.5rem',
      textAlign: 'center',
    },
    body: {
      height: '100%',
      overflow: 'auto',
      padding: '0px 20px 20px 20px',
      position: 'relative',
    },
    chipContainer: {
      left: '50%',
      marginTop: 24,
      position: 'absolute',
      transform: 'translateX(-50%)',
      '-webkit-transform': 'translateX(-50%)',
      width: '100%',
      zIndex: 10,
    },
    column: {
      borderRadius: '0.5rem',
      backgroundColor: neutral.white,
      border: `solid 1px ${neutral[20]}`,
      flexDirection: 'column',
      display: 'flex',
      height: '100%',
    },
    header: {
      alignItems: 'center',
      borderBottom: `solid 1px ${neutral['20']}`,
      color: neutral['90'],
      display: 'flex',
      fontFamily: 'Poppins',
      fontSize: '1.5rem',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontWeight: 'bold',
      justifyContent: 'flex-start',
      gap: '1rem',
      letterSpacing: 'normal',
      lineHeight: 'normal',
      padding: '0.75rem',
    },
    root: {
      height: '100%',
      width: '100%',
    },
    title: {
      fontSize: '0.75rem',
      textTransform: 'uppercase',
      fontWeight: 600,
      color: neutral['70'],
    },
    childrenContainer: {
      marginTop: '1.5rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
    },
  };
});
