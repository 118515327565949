import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { useCategoryDetail } from './useCategoryDetail';

import useStyles from './style';

export function CategoryDetail() {
  const {
    categoryName,
    subCategories,
    handleGoToArticle,
  } = useCategoryDetail();

  const _classes = useStyles();

  return (
    <div className={_classes.root}>
      <div className={_classes.categoryContainer}>
        <div className={_classes.title}>{categoryName}</div>
        <div className={_classes.subCategoriesContainer}>
          {subCategories.map((subcategory, index: number) => {
            return (
              <div
                key={`subcategory-${index}`}
                className={_classes.subCategory}
              >
                <div className={_classes.categoryTitle}>{subcategory.name}</div>
                {subcategory.articles.map((article, index: number) => {
                  return (
                    <div
                      key={`article-${index}`}
                      className={_classes.article}
                      onClick={handleGoToArticle({ subcategory, article })}
                    >
                      <KeyboardArrowRightIcon className={_classes.arrow} />
                      {article.name}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
