import { useEffect } from 'react';

import { PartnerId } from 'business/shared/domain';
import { useSelector } from 'react-redux';
import Amplitude from '../../../tools/amplitude';
import { runAttemptsToEnablePrinting } from './orderBillPrinting.helper';

export function useOrderBill(props: Props) {
  const {
    onPrint,
    orderId,
    storeId,
    createdAt,
    isActivatedChecklist,
    isActivatedComanda,
    hasOrderQrCode,
  } = props;
  const partnerId: PartnerId = useSelector(
    ({ partnerReducer }: any) => partnerReducer?.profile?.id,
  );

  const printedEnabled = () => {
    if (isActivatedChecklist && isActivatedComanda) return 'all';
    else if (!isActivatedChecklist && isActivatedComanda) return 'comanda';
    else if (isActivatedChecklist && !isActivatedComanda) return 'checklist';
    return '';
  };

  const eventParams = {
    ORDER_ID: orderId,
    STORE_ID: storeId,
    CREATED_TIME: createdAt,
    PRINTED_ENABLED: printedEnabled(),
    QR_CODE: hasOrderQrCode,
  };

  useEffect(() => {
    runAttemptsToEnablePrinting(isActivatedComanda, partnerId);
  }, []);

  useEffect(() => {
    if (orderId) {
      onPrint(orderId);
      Amplitude.logPrintedOrder(eventParams);
    }
  }, [orderId]);

  return {
    showCommand: !['checklist'].includes(printedEnabled()),
    showChecklist: !['comanda'].includes(printedEnabled()),
  };
}

interface Props {
  orderId: number;
  storeId: number;
  createdAt: string;
  onPrint: any;
  isActivatedChecklist: boolean;
  isActivatedComanda: boolean;
  hasOrderQrCode: boolean;
}
