import { SATURATION, STORE_STATUS } from '../../../domain';

export const SATURATION_INPUT_DTO = {
  DEACTIVATED: SATURATION.deactivated,
  LOW_DEMAND: SATURATION.low,
  REGULAR_DEMAND: SATURATION.regular,
  HIGH_DEMAND: SATURATION.high,
  SUPER_HIGH_DEMAND: SATURATION.superHigh,
  FAST_OPERATION: SATURATION.fast
} as const;

export const SATURATION_OUTPUT_DTO = Object.fromEntries(
  Object.entries(SATURATION_INPUT_DTO).map(([dto, saturation]) => [
    saturation,
    dto,
  ]),
);

export const STORE_STATUS_INPUT_DTO = {
  ACTIVE: STORE_STATUS.active,
  INACTIVE: STORE_STATUS.inactive,
  ON_HOLD: STORE_STATUS.onHold,
  SUSPENDED: STORE_STATUS.suspended,
} as const;
