const bannerTitle = {
  id: 'startPMFlowDialog.title.banner',
}
const cancelButton = {
  id: 'startPMFlowDialog.cancelButton',
}
const submitButton = {
  id: 'startPMFlowDialog.submitButton',
}
const description = {
  id: 'startPMFlowDialog.description',
}
const textIds = {
  bannerTitle,
  submitButton,
  cancelButton,
  description,
}

export default textIds
