import { makeStyles } from '@material-ui/core';

import Colors from '../../constants/Colors';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => {
  return {
    container: {
      width: 'max-content',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      color: Colors.PRIMARY_COLOR,
    },
    icon: {
      height: 18,
      width: 18,
    },
    selectedStoresTotal: {
      color: Colors.PRIMARY_COLOR,
    },
    rootOfSubmitButton: {
      height: '100%',
    },
    submitButton: {
      height: '100%',
      padding: '0.45rem 0',
      backgroundColor: neutral.white,
      color: neutral.D15,
      border: `1px solid ${neutral[20]}`,
      minWidth: '2rem',
      [theme.breakpoints.up('md')]: {
        color: neutral.D20,
      },
    },
  };
});
