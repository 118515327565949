import { clsx } from 'clsx';
import { useState } from 'react';
import { Tooltip } from '@material-ui/core';

import useStyles from './CustomTooltip.styles';

export function CustomTooltip(props) {
  const {
    title = null,
    isArrowShown = false,
    classes = {},
    ...extraTooltipProps
  } = props;

  const _classes = useStyles();
  const [arrowRef, setArrowRef] = useState(null);

  return (
    <Tooltip
      classes={{
        arrow: clsx(_classes.arrow, classes?.arrow),
        popper: clsx(_classes.popper, classes?.popper),
        tooltip: clsx(_classes.tooltip, classes?.tooltip),
      }}
      PopperProps={{
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: Boolean(arrowRef),
              element: arrowRef,
            },
          },
        },
      }}
      {...extraTooltipProps}
      title={
        <>
          {title}
          {isArrowShown && <span className={arrow} ref={setArrowRef} />}
        </>
      }
    />
  );
}
