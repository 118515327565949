import { createActions } from 'redux-actions';
import history from '../../../history';
// External libs
import { get, find } from 'lodash';
// Intl
import { formatMessage } from '../../../intl/intl';
// Consts
import PartnerOrderStateType from './../../../constants/PartnerOrderStateType';
import Paths from '../../../constants/Paths';
import ReadyForPickupTriggerTypes from '../../../constants/ReadyForPickupTriggerTypes';
import RefuseReasons, {
  ranOutProductsReason,
} from './../../../constants/RefuseReasons';
import { SupportChatTypifications } from '../../../constants/SupportChatTypifications';
import { OrderDeliveryMethodType } from '../../../constants/OrderDeliveryMethodType';
import { PartnerDeactivationReasonType } from '../../../constants/PartnerDeactivationReasonType';
import EventCodes from '../../../utils/braze-utils/consts/eventCodes';
// Utils
import DateTimeUtils from '../../../utils/DateTimeUtils';
import delay from '../../../utils/Delay';
import OrdersUtils from '../../../utils/OrdersUtils';
import RTUtils from '../../../utils/RTUtils';
import { logEvent } from '../../../utils/braze-utils/BrazeUtils';
import MenuUtils from '../../../utils/MenuUtils';
// Tools
import { PWEXCodes, PWEXUtils, PWEX } from '../../../exceptions';
// Store
import appStore from './../../stores/app.store';
// Services
import blindService from '../../../services/BlindService';
import ChromeExtensionService from '../../../services/ChromeExtensionService';
import firebaseService from '../../../firebase/firebase';
import indexedDBService from '../../../services/IndexedDBService';
import notificationsService from '../../../services/NotificationsService';
import orderService from '../../../services/order-service';
import realTimeService from '../../../services/RealTimeService';
import RouterService from '../../../services/RouterService';
// Actions
import AppActions from '../app-actions';
import dialogsManagerActions from '../dialogsManager.actions';
import EventActions from '../event-actions';
import MainLayoutActions from '../mainLayout.actions';
import orderDetailDialogActions from '../orderDetailDialog.actions';
import ordersKanbanActions from '../ordersKanban.actions';
import PartnersActions from '../partners-actions';
import settingsActions from '../settings.actions';
import StoreKeeperActions from '../StoreKeeper.actions';
import { actions as checkErrorServiceDialogAction } from '../../check-error-service-dialog';
import supportIssueSuggestionsService from '../../../services/support-issue-suggestions-service/SupportIssueSuggestionsService';
import supportRateService from '../../../services/support-rate-service';
import { partnerStateType } from '../../../constants/PartnerStateType';
import stockOutService from '../../../services/stock-out-replacement-service';
import menuActions from '../menu.actions';

const {
  acknowledgeCanceledOrderAction,
  addToOrderDetailPollingBlacklistAction,
  applyOrdersHistoricalFilterAction,
  applyOrdersListFilterAction,
  clearNetworkErrorCountAction,
  clearOrdersHistoricalAction,
  clearOrdersListFilterAction,
  closeCheckNetworkDialogAction,
  closeDialogExtraCtAction: closeDialogExtraCTAction,
  closeOrdersHistoricalFilterDrawerAction,
  closeOrdersListFilterDrawerAction,
  closeSupportChatDrawerAction,
  confirmPrintAction,
  confirmPrintBulkAction,
  confirmReceptionAction,
  decreaseCookingTimeToTakeOrderAction,
  getOrderDetailAction,
  getOrdersAction,
  getOrdersHistoricalAction,
  getRejectionReasonsAction,
  getQualifyNegativeReasonsAction,
  hideGettingOrdersHistoricalFailedAction,
  increaseCookingTimeToTakeOrderAction,
  increaseNetworkErrorCountAction,
  listenOrderSupportChatAction,
  listenOrderSupportNotificationsAction,
  markRTqualificationAction,
  openCheckNetworkDialogAction,
  openDialogExtraCtAction: openDialogExtraCTAction,
  openOrdersHistoricalFilterDrawerAction,
  openOrdersListFilterDrawerAction,
  openSupportChatDrawerAction,
  rejectNewOrderAction,
  rejectTookOrderAction,
  sendSupportChatMessageAction,
  sendSupportCalificationAction,
  sendToPrintAction,
  sendToPrintBulkAction,
  setDeliveredToRtBeforeCanceledAction: setDeliveredToRTBeforeCanceledAction,
  setFirebaseStatusAction,
  setInitialCTToTakeOrderAction,
  setOrdersHistoricalIsNotLoadingAction,
  setOrdersHistoricalIsLoadingAction,
  setNotDeliveredToRtBeforeCanceledAction: setNotDeliveredToRTBeforeCanceledAction,
  setRTCountersAction,
  setSearchFieldTextAction,
  setStoreInOrdersHistoricalFilterAction,
  showGettingOrdersHistoricalFailedAction,
  increaseFailServiceErrorCountAction,
  clearFailServiceErrorCountAction,
} = createActions({
  ACKNOWLEDGE_CANCELED_ORDER_ACTION: orderId => ({ orderId }),
  ADD_TO_ORDER_DETAIL_POLLING_BLACKLIST_ACTION: orderId => ({ orderId }),
  APPLY_ORDERS_HISTORICAL_FILTER_ACTION: selectedStoreId => ({
    selectedStoreId,
  }),
  APPLY_ORDERS_LIST_FILTER_ACTION: selectedStoresIds => ({
    selectedStoresIds,
  }),
  CLEAR_NETWORK_ERROR_COUNT_ACTION: () => {},
  CLEAR_ORDERS_HISTORICAL_ACTION: () => {},
  CLEAR_ORDERS_LIST_FILTER_ACTION: () => {},
  CLOSE_CHECK_NETWORK_DIALOG_ACTION: () => ({}),
  CLOSE_ORDERS_HISTORICAL_FILTER_DRAWER_ACTION: () => ({}),
  CLOSE_ORDERS_LIST_FILTER_DRAWER_ACTION: () => ({}),
  CLOSE_SUPPORT_CHAT_DRAWER_ACTION: orderId => ({
    orderId,
  }),
  CLOSE_DIALOG_EXTRA_CT_ACTION: orderId => ({
    orderId,
  }),
  CLOSE_DIALOG_ORDER_WHIM_ACTION: (orderId, productId) => ({
    orderId,
    productId,
  }),
  CONFIRM_PRINT_ACTION: orderId => ({
    orderId,
  }),
  CONFIRM_PRINT_BULK_ACTION: orderIds => ({
    orderIds,
  }),
  CONFIRM_RECEPTION_ACTION: orderIds => ({
    orderIds,
  }),
  DECREASE_COOKING_TIME_TO_TAKE_ORDER_ACTION: (
    minCookingTime,
    orderId,
    cookingTimeStep,
  ) => ({
    minCookingTime,
    orderId,
    cookingTimeStep,
  }),
  GET_ORDER_DETAIL_ACTION: orderDetail => ({
    orderDetail,
  }),
  GET_ORDERS_HISTORICAL_ACTION: (ordersHistorical, page) => ({
    ordersHistorical,
    page,
  }),
  GET_REJECTION_REASONS_ACTION: rejectionReasons => ({ rejectionReasons }),
  GET_ORDERS_ACTION: (orders, zoneId) => ({
    orders,
    zoneId,
  }),
  GET_QUALIFY_NEGATIVE_REASONS_ACTION: data => ({ data }),
  HIDE_GETTING_ORDERS_HISTORICAL_FAILED_ACTION: () => ({}),
  INCREASE_COOKING_TIME_TO_TAKE_ORDER_ACTION: (
    maxCookingTime,
    orderId,
    cookingTimeStep,
  ) => ({
    maxCookingTime,
    orderId,
    cookingTimeStep,
  }),
  INCREASE_NETWORK_ERROR_COUNT_ACTION: () => {},
  LISTEN_ORDER_SUPPORT_CHAT_ACTION: (orderId, supportChatMessages) => ({
    orderId,
    supportChatMessages,
  }),
  LISTEN_ORDER_SUPPORT_NOTIFICATIONS_ACTION: (
    orderId,
    supportNotificationsMessages,
  ) => ({
    orderId,
    supportNotificationsMessages,
  }),
  REMOVE_STORE_FROM_ORDERS_LIST_FILTER_ACTION: storeId => ({
    storeId,
  }),
  OPEN_CHECK_NETWORK_DIALOG_ACTION: () => ({}),
  OPEN_DIALOG_EXTRA_CT_ACTION: orderId => ({
    orderId,
  }),
  OPEN_DIALOG_ORDER_WHIM_ACTION: (orderId, productId) => ({
    orderId,
    productId,
  }),
  OPEN_ORDERS_HISTORICAL_FILTER_DRAWER_ACTION: () => ({}),
  OPEN_ORDERS_LIST_FILTER_DRAWER_ACTION: () => ({}),
  OPEN_SUPPORT_CHAT_DRAWER_ACTION: (orderId, supportScore) => ({
    orderId,
    supportScore,
  }),
  MARK_R_TQUALIFICATION_ACTION: qualification => ({ qualification }),
  REJECT_NEW_ORDER_ACTION: () => {},
  REJECT_TOOK_ORDER_ACTION: () => {},
  SEND_TO_PRINT_ACTION: orderId => ({
    orderId,
  }),
  SEND_TO_PRINT_BULK_ACTION: orderIds => ({
    orderIds,
  }),
  SEND_SUPPORT_CHAT_MESSAGE_ACTION: () => {},
  SEND_SUPPORT_CALIFICATION_ACTION: () => {},
  SET_DELIVERED_TO_RT_BEFORE_CANCELED_ACTION: orderId => ({
    orderId,
  }),
  SET_FIREBASE_STATUS_ACTION: (orderId, realTimeDatabase, realTimeStatus) => ({
    orderId,
    realTimeDatabase,
    realTimeStatus,
  }),
  SET_INITIAL_C_T_TO_TAKE_ORDER_ACTION: (orderId, initialCTToTakeOrder) => ({
    orderId,
    initialCTToTakeOrder,
  }),
  SET_ORDERS_HISTORICAL_IS_NOT_LOADING_ACTION: () => ({}),
  SET_ORDERS_HISTORICAL_IS_LOADING_ACTION: () => ({}),
  SET_NOT_DELIVERED_TO_RT_BEFORE_CANCELED_ACTION: orderId => ({
    orderId,
  }),
  SET_R_T_COUNTERS_ACTION: (rtsInStoreTotal, rtsOnTheWayTotal) => ({
    rtsInStoreTotal,
    rtsOnTheWayTotal,
  }),
  SET_SEARCH_FIELD_TEXT_ACTION: text => ({ text }),
  SET_STORE_IN_ORDERS_HISTORICAL_FILTER_ACTION: storeId => ({ storeId }),
  SHOW_GETTING_ORDERS_HISTORICAL_FAILED_ACTION: exceptionMessage => ({
    exceptionMessage,
  }),
  INCREASE_FAIL_SERVICE_ERROR_COUNT_ACTION: () => ({}),
  CLEAR_FAIL_SERVICE_ERROR_COUNT_ACTION: () => ({}),
});

const rejectOrder = (orderDetail, selectedReason) => async dispatch => {
  try {
    const isMarketPlace = OrdersUtils.isMarketPlace(
      orderDetail.delivery_method,
    );
    const partnerId = appStore.getPartnerId();
    if (orderDetail.cooking_time) {
      await rejectTookOrder(
        orderDetail.id,
        selectedReason.refuse_reason_enum,
        selectedReason.description,
        isMarketPlace,
      )(dispatch);
    } else {
      await rejectNewOrder(
        orderDetail.id,
        selectedReason.refuse_reason_enum,
        selectedReason.description,
        isMarketPlace,
      )(dispatch);
    }
    EventActions.onSuccessRejectOrder(orderDetail.id, partnerId);
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
    throw ex;
  }
};

const acknowledgeCanceledOrder = orderId => dispatch => {
  dispatch(acknowledgeCanceledOrderAction(orderId));
  return indexedDBService.deleteOrderById(orderId).then(() => {
    if (history.location.pathname.indexOf(Paths.ORDERS_MANAGEMENT) !== -1) {
      RouterService.replaceToOrders();
    }
  });
};

const acknowledgeCanceledOrderFromDialog = orderId => async dispatch => {
  try {
    await acknowledgeCanceledOrder(orderId)(dispatch);
    await getOrders()(dispatch);
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
  }

  dialogsManagerActions.close()(dispatch);
};

const addMoreCookingTime = (extraCookingTime, orderId, storeId) => dispatch => {
  return orderService
    .addMoreCookingTime(extraCookingTime, orderId, storeId)
    .then(() => {
      return closeDialogExtraCT(orderId)(dispatch);
    })
    .catch(ex => AppActions.handleCatch(ex)(dispatch));
};

const redirectOrdersHistoricalFilter = selectedStoreId => dispatch => {
  history.push(`${Paths.getOrdersHistoricalQueryParamsPath(selectedStoreId)}`);
  closeOrdersHistoricalFilterDrawer()(dispatch);
};

const applyOrdersListFilter = selectedStoresIds => dispatch => {
  dispatch(applyOrdersListFilterAction(selectedStoresIds));
  ordersKanbanActions.setOrders()(dispatch);
  closeOrdersListFilterDrawer()(dispatch);
};

const cancelByStockOutAsSupport = () => async dispatch => {
  const orderId = appStore.getOrderIdInOrderDetailDialog();
  const productIds = appStore.getSelectedProductsInItemsDeactivationStockOutIssueDialog();
  const toppingIds = appStore.getSelectedToppingsInItemsDeactivationStockOutIssueDialog();
  try {
    await orderService.cancelByStockOutAsSupport(
      orderId,
      productIds,
      toppingIds,
    );
    await getOrders()(dispatch);
    dialogsManagerActions.close()(dispatch);
  } catch (ex) {
    AppActions.handleCatch(ex)(dispatch);
    return;
  }
};

const clearOrdersHistorical = () => dispatch => {
  dispatch(clearOrdersHistoricalAction());
};

const clearOrdersHistoricalFilter = () => dispatch => {
  closeOrdersHistoricalFilterDrawer()(dispatch);
  history.push(`${Paths.ORDERS_HISTORY}`);
};

const clearOrdersListFilter = () => dispatch => {
  dispatch(clearOrdersListFilterAction());
  ordersKanbanActions.setOrders()(dispatch);
  closeOrdersListFilterDrawer()(dispatch);
};

const confirmPrint = orderId => async dispatch => {
  if (!orderId) {
    return {
      hasFailed: false,
    };
  }

  try {
    window.print();
    dispatch(confirmPrintAction(orderId));
    const order = appStore.getOrderById(orderId);
    const tz = appStore.getTz();
    await indexedDBService.savePrintedOrder(order, tz);

    return {
      hasFailed: false,
    };
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
    return {
      hasFailed: true,
      ex,
    };
  }
};

const confirmPrintBulk = orderIds => dispatch => {
  return dispatch(confirmPrintBulkAction(orderIds));
};

const sendToPrint = orderId => dispatch => {
  return dispatch(sendToPrintAction(orderId));
};

const sendToPrintBulk = orderIds => async dispatch => {
  for (const orderId of orderIds) {
    const getResult = await getOrderDetailAndStoreKeeperChannel(orderId)(
      dispatch,
    );
    if (get(getResult, 'hasFailed', true)) {
      continue;
    }
    sendToPrint(orderId)(dispatch);
  }
};

const releaseRTnow = (storekeeperId, orderId) => async dispatch => {
  try {
    await orderService.releaseRtnow(storekeeperId, orderId);
    EventActions.onSuccessfulRTRelease(orderId, storekeeperId);
    const message = formatMessage({
      id: 'releaseRT.successSnackbar.message',
    });
    const title = formatMessage({
      id: 'releaseRT.successSnackbar.title',
    });
    await AppActions.openSnackBarSuccess(message, title)(dispatch);
    dialogsManagerActions.close()(dispatch);
  } catch (ex) {
    EventActions.onFailedRTRelease(orderId, storekeeperId);
    await AppActions.handleCatch(ex)(dispatch);
  }
};

/**
 * Close the check network dialog and clear the fail v2 count.
 * @function closeCheckNetworkDialog
 */
const closeCheckNetworkDialog = () => async dispatch => {
  dispatch(closeCheckNetworkDialogAction());
  OrderActions.clearNetworkErrorCount()(dispatch);
  await ChromeExtensionService.pauseAudio();
};

const closeDialogExtraCT = orderId => dispatch => {
  return dispatch(closeDialogExtraCTAction(orderId));
};

const closeOrdersHistoricalFilterDrawer = () => dispatch => {
  return dispatch(closeOrdersHistoricalFilterDrawerAction());
};

const closeOrdersListFilterDrawer = () => dispatch => {
  return dispatch(closeOrdersListFilterDrawerAction());
};

const closeSupportChatDrawer = orderId => dispatch => {
  return dispatch(closeSupportChatDrawerAction(orderId));
};

const confirmOrderReception = () => async dispatch => {
  const orders = appStore.getOrders();
  // Get the orders not confirmed yet
  const ordersNotConfirmedYet = orders.filter(
    order =>
      !order.confirmedReception &&
      order.beingManaged &&
      order.partner_order_state !== PartnerOrderStateType.CANCEL_APP,
  );

  if (!ordersNotConfirmedYet || ordersNotConfirmedYet.length === 0) {
    // If there isn't any order to confirm, return.
    return;
  }

  // Try getting the partner id from the store, or from the first order.
  const profile = appStore.getProfile();
  const partnerId = profile.id || ordersNotConfirmedYet[0].partner_id;

  // get all ids from the orders not confirmed yet
  const ordersNotConfirmedYetIds = ordersNotConfirmedYet.map(order => order.id);

  if (!partnerId) {
    const PWEXCode = PWEXCodes.PWEX11;
    const formatMessageObj = { id: PWEXCode };

    const params = {
      orderIds: ordersNotConfirmedYetIds,
      partnerId,
    };

    const ex = new PWEX(PWEXCode, formatMessageObj, params);

    await AppActions.handleCatch(ex, false)(dispatch);

    return;
  }
  try {
    const newOrdersNotConfirmedYet = OrdersUtils.filterNew(
      ordersNotConfirmedYet,
    );
    const hasExtension = !!(await ChromeExtensionService.isAvailable());
    const extensionVersion = await ChromeExtensionService.getVersion();
    EventActions.onNewOrdersToConfirmReception(
      newOrdersNotConfirmedYet,
      hasExtension,
      extensionVersion,
    );
  } catch (ex) {
    AppActions.handleCatch(ex, false)(dispatch);
    // Continue execution.
  }

  try {
    await orderService.confirmReception(ordersNotConfirmedYetIds, partnerId);
    return dispatch(confirmReceptionAction(ordersNotConfirmedYetIds));
  } catch (ex) {
    AppActions.handleCatch(ex)(dispatch);
  }
};

const deactivateProductsToppingsFromStockOutIssue = (
  selectedProducts,
  selectedToppings,
) => async dispatch => {
  const storeId = appStore.getOrderStoreIdInOrderDetailDialog();
  try {
    await orderService.deactivateProductsToppings(
      storeId,
      selectedProducts,
      selectedToppings,
    );
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
  }
  await dispatch(dialogsManagerActions.openStockOutIssueSuggestionsDialog());
};

const decreaseCookingTimeToTakeOrder = (
  minCookingTime,
  orderId,
) => dispatch => {
  const cookingTimeStep = appStore.getCookingTimeStep();
  dispatch(
    decreaseCookingTimeToTakeOrderAction(
      minCookingTime,
      orderId,
      cookingTimeStep,
    ),
  );
};

const deliverToRappiTendero = orderId => async dispatch => {
  try {
    await orderService.deliverToRappiTendero(orderId);
    await dispatch(getOrders());
    await dispatch(getOrderDetailAndStoreKeeperChannel(orderId));

    if (history.location.pathname.indexOf(Paths.ORDERS_MANAGEMENT) !== -1) {
      RouterService.replaceToOrders();
    }
  } catch (ex) {
    await dispatch(AppActions.handleCatch(ex));
  }
};

const closePickupOrder = orderId => async dispatch => {
  try {
    await orderService.closePickupOrder(orderId);
    await dispatch(getOrders());
    await dispatch(getOrderDetailAndStoreKeeperChannel(orderId));

    if (history.location.pathname.indexOf(Paths.ORDERS_MANAGEMENT) !== -1) {
      RouterService.replaceToOrders();
    }
  } catch (ex) {
    await dispatch(AppActions.handleCatch(ex));
  }
};

const deliverOrder = orderId => async dispatch => {
  const order = appStore.getOrderById(orderId);

  switch (order.delivery_method) {
    case OrderDeliveryMethodType.DELIVERY:
      await dispatch(deliverToRappiTendero(order.id));
      break;
    case OrderDeliveryMethodType.MARKETPLACE:
      await dispatch(orderOnTheWay(order.id));
      break;
    case OrderDeliveryMethodType.PICKUP:
      await dispatch(closePickupOrder(order.id));
      break;
    default:
      break;
  }

  try {
    const hasExtension = !!(await ChromeExtensionService.isAvailable());
    const extensionVersion = await ChromeExtensionService.getVersion();
    EventActions.onOrderDelivered(
      orderId,
      order.delivery_method,
      hasExtension,
      extensionVersion,
    );
  } catch (ex) {
    await dispatch(AppActions.handleCatch(ex, false));
    // Continue the execution.
  }
};

const sendNotificationToRT = orderId => async dispatch => {
  try {
    const partnerId = appStore.getPartnerId();
    const { data } = await orderService.sendNotificationToRT(orderId);
    await AppActions.openSnackBarSuccess(data.message, data.title)(dispatch);
    EventActions.onClickNotifyRT(partnerId, orderId);
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
  }
};

const deliverBundle = orderIds => async dispatch => {
  if (!orderIds) {
    return;
  }
  for (const orderId of orderIds) {
    await deliverOrder(orderId)(dispatch);
  }
};

const executeOrderIsReady = order => async dispatch => {
  switch (order.delivery_method) {
    case OrderDeliveryMethodType.DELIVERY:
      const storeId =
        order.store_id ||
        (order.stores && order.stores[0] && order.stores[0].store_id);
      await searchForRTNow(
        order.id,
        order.cooking_time,
        !!order.deliveryboy_id || !!get(order, 'storekeeper.id', null),
        order.partner_order_state,
        storeId,
      )(dispatch);
      return;
    case OrderDeliveryMethodType.MARKETPLACE:
      await readyForPickUp(order.id, order.partner_order_state)(dispatch);
      return;
    case OrderDeliveryMethodType.PICKUP:
    default:
      await readyForPickUp(order.id, order.partner_order_state)(dispatch);
      return;
  }
};

const getOrderDetail = orderId => async dispatch => {
  if (!orderId) {
    return;
  }
  try {
    const response = await orderService.getOrderDetail(orderId);
    return dispatch(getOrderDetailAction(response.data));
  } catch (ex) {
    orderDetailDialogActions.setErrorOrderDetailDialog(true)(dispatch);

    const httpStatus = ex.response && ex.response.status;
    console.error(orderId, httpStatus);
    if (httpStatus === 403 || httpStatus === 404) {
      return dispatch(addToOrderDetailPollingBlacklistAction(orderId));
    }

    throw ex;
  }
};

const getOrderDetailAndStoreKeeperChannel = selectedOrderId => async dispatch => {
  if (!selectedOrderId) {
    return {
      hasFailed: false,
    };
  }
  try {
    const action = await getOrderDetail(selectedOrderId)(dispatch);

    if (
      !action ||
      !action.payload ||
      !action.payload.orderDetail ||
      !action.payload.orderDetail.id ||
      !action.payload.orderDetail.storekeeper ||
      !action.payload.orderDetail.storekeeper.id
    ) {
      return {
        hasFailed: false,
      };
    }
    await StoreKeeperActions.getChannel(
      action.payload.orderDetail.id,
      action.payload.orderDetail.storekeeper.id,
    )(dispatch);

    return {
      hasFailed: false,
    };
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
    return {
      hasFailed: true,
      ex,
    };
  }
};

const pollOrderDetailAndStoreKeeperChannel = selectedOrderId => async dispatch => {
  const isBeingManaged = appStore.isOrderBeingManaged(selectedOrderId);
  if (!isBeingManaged) {
    return;
  }

  const isOrderInOrderDetailPollingBlacklist = appStore.isOrderInOrderDetailPollingBlacklist(
    selectedOrderId,
  );

  if (isOrderInOrderDetailPollingBlacklist) {
    return;
  }

  await getOrderDetailAndStoreKeeperChannel(selectedOrderId)(dispatch);
};

const getOrderIdByPickupCode = (
  code,
  history,
  partnerId,
  selectedOrderId,
) => dispatch => {
  return blindService
    .getOrderIdByPickupCode(code, partnerId)
    .then(async response => {
      if (!selectedOrderId || +selectedOrderId !== +response.data.order_id) {
        // If no order is selected or the order selected is different to the one found by the code,
        // it means the user is looking for the order from the pickup code.
        // So, it is necesary to redirect the user to the order detail.
        RouterService.redirectToOrders(response.data.order_id);
        return MainLayoutActions.closePickupCodeDialog()(dispatch);
      }
      return MainLayoutActions.closePickupCodeDialog()(dispatch);
    })
    .catch(ex => AppActions.handleCatch(ex)(dispatch));
};

const clearNetworkErrorCount = () => dispatch => {
  dispatch(clearNetworkErrorCountAction());
};

const increaseNetworkErrorCount = () => async dispatch => {
  // Increase the fail v2 count.
  dispatch(increaseNetworkErrorCountAction());
  const failsV2Count = appStore.getFailsV2Count();
  if (failsV2Count < +process.env.REACT_APP_V2_FAILS_COUNT) {
    // If the fails v2 qouta hasn't been filled, return.
    return false;
  }
  // If the fails v2 quota has been filled,
  // ensure that the app state is set to offline,
  // corresponding dialog is opened,
  // and the corresponding sound is being played.
  await AppActions.setOffline()(dispatch);
  // PartnersActions.setPartnerState(partnerStateType.INACTIVE)(dispatch);
  await OrderActions.openCheckNetworkDialog()(dispatch);
  return true;
};

const clearFailServiceErrorCount = () => dispatch => {
  dispatch(clearFailServiceErrorCountAction());
};

const increaseFailServiceErrorCount = () => async dispatch => {
  dispatch(increaseFailServiceErrorCountAction());
  const failServiceErrorCount = appStore.getFailsServiceErrorCount();

  if (
    failServiceErrorCount < +process.env.REACT_APP_SERVICE_ERROR_FAILS_COUNT
  ) {
    return false;
  }
  await AppActions.setOffline()(dispatch);
  await OrderActions.openCheckErrorServiceDialog()(dispatch);
  return true;
};

const openCheckNetworkDialog = () => async dispatch => {
  dispatch(openCheckNetworkDialogAction());
  await ChromeExtensionService.updateAudio();
};
const openCheckErrorServiceDialog = () => async dispatch => {
  dispatch(checkErrorServiceDialogAction.open());
  await ChromeExtensionService.updateAudio();
};
const closeCheckErrorServiceDialog = () => async dispatch => {
  dispatch(checkErrorServiceDialogAction.close());
  OrderActions.clearFailServiceErrorCount()(dispatch);
  await ChromeExtensionService.pauseAudio();
};

// const logNewOrdersRawReception = rawOrders => dispatch => {
//   try {
//     if (!rawOrders) {
//       return;
//     }

//     const rawNewOrders = OrdersUtils.filterNew(rawOrders);

//     if (!rawNewOrders || !rawNewOrders.length) {
//       return;
//     }

//     EventActions.onNewOrdersRawReception(rawNewOrders);
//   } catch (ex) {
//     AppActions.handleCatch(ex, false, false);
//   }
// };

const getOrders = () => async dispatch => {
  try {
    let response;
    try {
      response = await orderService.getOrders();
    } catch (internalEx) {
      const internalExMessage = get(internalEx, 'message', null);
      const PWEXCode = PWEXCodes.PWEX13;
      const formatMessageObj = { id: PWEXCode };
      const getOrdersPWEX = new PWEX(
        PWEXCode,
        formatMessageObj,
        null,
        internalEx,
      );

      if (internalExMessage === 'Network Error') {
        await OrderActions.increaseNetworkErrorCount()(dispatch);
      } else {
        // if service error return and open checkErrorServiceDialog
        await OrderActions.increaseFailServiceErrorCount()(dispatch);
      }
      await AppActions.handleCatch(getOrdersPWEX, false, false)(dispatch);
      return;
    }

    const orderStatusList = get(response, 'data.orders', []);

    // Clean the fail v2 count in order to start to count again.
    OrderActions.clearNetworkErrorCount()(dispatch);
    OrderActions.clearFailServiceErrorCount()(dispatch);
    await OrderActions.closeCheckErrorServiceDialog()(dispatch);

    // Ensure the check network dialog is closed and the fail v2 count is cleared.
    await OrderActions.closeCheckNetworkDialog()(dispatch);
    // Ensure the app state is online
    await AppActions.setOnline()(dispatch);
    const zoneId = get(response, 'data.zone_id', null);

    let orders = orderStatusList;
    try {
      orders = await orderService.concatCancelledOrders(orders);
    } catch (concatCancelledOrdersEx) {
      await AppActions.handleCatch(concatCancelledOrdersEx, false)(dispatch);
    }

    dispatch(getOrdersAction(orders, zoneId));
    const couriersStoreInfo = get(response, 'data.couriers_store_info') || [];

    if (!!couriersStoreInfo) {
      setRTCounters(couriersStoreInfo)(dispatch);
    }

    ordersKanbanActions.setOrders()(dispatch);

    dialogsManagerActions.closeIfOrderDoesNotExist()(dispatch);

    const newOrders = OrdersUtils.filterNew(orders);

    if (newOrders.length > 0) {
      //let it run in different thread. Don't await it.
      notificationsService.spawnNewOrdersNotification(newOrders.length);
      const title =
        newOrders.length === 1
          ? formatMessage({
              id: 'webNotification.newOrder.singleTitle',
            })
          : formatMessage({
              id: 'webNotification.newOrder.pluralTitle',
            });

      document.title = `(${newOrders.length}) ${title}`;
    } else {
      document.title = `Rappi Aliados`;
    }
    //It must be called to notify the chrome extension the quantity of new orders, and the texts must be displayed.
    const orderIdForGA =
      newOrders.length > 0 ? newOrders[newOrders.length - 1].id : null;
    await ChromeExtensionService.newOrder(
      document.title,
      newOrders.length,
      orderIdForGA,
    );
    await ChromeExtensionService.updateAudio();
    if (history.location.pathname.indexOf(Paths.ORDERS_KANBAN) !== -1) {
      await autoReadyAndPack()(dispatch);
    }
    await executeAutoPrint()(dispatch);
    await confirmOrderReception()(dispatch);

    if (OrdersUtils.emitNotificationRTSound([...orders])) {
      ChromeExtensionService.playNotificationRTSound();
    }
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
    throw ex;
  }
};

const executeAutoPrint = () => async dispatch => {
  try {
    const isAutoPrintActive = appStore.isAutoPrintActive();
    if (!isAutoPrintActive) {
      return {
        hasErrored: false,
      };
    }
    const orders = appStore.getBeingManagedCookingOrders();
    const getPrintedOrderIdsResult = await indexedDBService.getPrintedOrderIds();

    if (get(getPrintedOrderIdsResult, 'hasFailed', true)) {
      await AppActions.handleCatch(
        dispatch,
        get(getPrintedOrderIdsResult, 'ex', null),
      );
      return getPrintedOrderIdsResult;
    }

    const printedOrderIds = get(getPrintedOrderIdsResult, 'data', []);

    const [printedOrders, nonPrintedOrders] = OrdersUtils.separateOrdersById(
      orders,
      printedOrderIds,
    );

    if (isAutoPrintActive && !!nonPrintedOrders && !!nonPrintedOrders.length) {
      const nonPrintedOrderIds = nonPrintedOrders.map(order => order.id);

      await sendToPrintBulk(nonPrintedOrderIds)(dispatch);
    }

    if (!!printedOrders && !!printedOrders.length) {
      const printedOrderIds = printedOrders.map(order => order.id);
      confirmPrintBulk(printedOrderIds)(dispatch);
    }

    return {
      hasFailed: false,
    };
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
    return {
      hasFailed: true,
      ex,
    };
  }
};

const autoReadyAndPack = () => async dispatch => {
  try {
    const isAutoReadyAndPackAvailable = appStore.getIsAutoReadyForPickupMarketplaceAvailable();

    const cookingOrdersToSetAsReady = appStore.getBeingCookingWithExpiredCT();

    if (!cookingOrdersToSetAsReady || !cookingOrdersToSetAsReady.length) {
      return;
    }

    let totalOrdersSetAsReady = 0;
    for (const order of cookingOrdersToSetAsReady) {
      const isMarketPlace = OrdersUtils.isMarketPlace(order.delivery_method);

      if (isMarketPlace && !isAutoReadyAndPackAvailable) {
        continue;
      }

      await readyForPickUp(
        order.id,
        order.partner_order_state,
        false,
        ReadyForPickupTriggerTypes.AUTOMATIC,
      )(dispatch);
      totalOrdersSetAsReady++;
    }

    if (totalOrdersSetAsReady === 0) {
      return;
    }

    await getOrders()(dispatch);

    const selectedOrderId = appStore.getOrderIdInOrderDetailDialog();

    if (selectedOrderId) {
      await getOrderDetailAndStoreKeeperChannel(selectedOrderId)(dispatch);
    }
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
    return;
  }
};

const getRejectionReasons = isMarketPlace => dispatch => {
  return orderService.getRejectionReasons(isMarketPlace).then(response => {
    return dispatch(getRejectionReasonsAction(response.data));
  });
};

const getProfileWithOrders = () => async dispatch => {
  try {
    await PartnersActions.getProfileAndState()(dispatch);

    await getOrders()(dispatch);
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
    return;
  }
};

const increaseCookingTimeToTakeOrder = (
  maxCookingTime,
  orderId,
) => dispatch => {
  const cookingTimeStep = appStore.getCookingTimeStep();

  dispatch(
    increaseCookingTimeToTakeOrderAction(
      maxCookingTime,
      orderId,
      cookingTimeStep,
    ),
  );
};

const openSupport = () => async dispatch => {
  let description;
  let supportSuggestions;
  try {
    const orderDetail = appStore.getOrderDetailInOrderDetailDialog();
    const storekeeper = get(orderDetail, 'storekeeper', null);
    const isStorekeeperFinal = get(storekeeper, 'visible', false);

    const deliveryMethod = get(orderDetail, 'delivery_method', null);

    const response = await supportIssueSuggestionsService.getSupportReasons(
      isStorekeeperFinal,
      deliveryMethod,
    );
    description = get(response, 'data.description', '');
    supportSuggestions = get(response, 'data.next_steps', []);
  } catch (ex) {
    // Continue execution
  }

  const areSupportSuggestionsAvailable =
    !!supportSuggestions && supportSuggestions.length > 0;

  try {
    await startFirebaseChat()(dispatch);
  } catch (ex) {
    if (!areSupportSuggestionsAvailable) {
      const PWEXCode = PWEXCodes.PWEX10;
      const formatMessageObj = { id: PWEXCode };
      const customEx = new PWEX(PWEXCode, formatMessageObj, null, ex);
      await AppActions.handleCatch(customEx)(dispatch);
      return;
    }
  }
  await delay(1000);
  const supportNotificationsMessages = appStore.getSupportNotificationsMessagesInOrderDetailDialog();

  if (
    !!supportNotificationsMessages &&
    supportNotificationsMessages.length > 0
  ) {
    await openSupportChatDrawer()(dispatch);
    return;
  }

  if (!areSupportSuggestionsAvailable) {
    await openSupportReasonsDrawer()(dispatch);
    return;
  }

  dialogsManagerActions.openSupportReasonsDialog(
    description,
    supportSuggestions,
  )(dispatch);
};

const startFirebaseChat = () => async dispatch => {
  const response = await realTimeService.getFirebaseStatus();
  const database = get(response, 'data.realtime-database', null);
  const databaseStatus = get(response, 'data.realtime-status', null);

  const orderId = appStore.getOrderIdInOrderDetailDialog();
  dispatch(setFirebaseStatusAction(orderId, database, databaseStatus));

  if (databaseStatus !== 'ON') {
    throw new Error(
      `It isn't possible to open support chat drawer because database status is not ON.`,
    );
  }

  const tz = appStore.getTz();

  await firebaseService.startListeningOrderSupportNotifications(
    database,
    orderId,
    snapshot => {
      const messages = snapshot.val();

      if (!messages) {
        return;
      }

      // Convert messages from an object of n properties to an array to make data structure easier
      const supportNotificationsMessages = [];
      for (const key in messages) {
        if (!messages.hasOwnProperty(key)) {
          return;
        }

        if (
          messages[key].receiver_type !== 'tablet' &&
          messages[key].sender_type !== 'tablet'
        ) {
          return;
        }

        messages[
          key
        ].timeDiffLabel = DateTimeUtils.getDiffLabelFromMilliseconds(
          messages[key].timestamp,
          tz,
        );
        supportNotificationsMessages.push(messages[key]);
      }

      dispatch(
        listenOrderSupportNotificationsAction(
          orderId,
          supportNotificationsMessages,
        ),
      );
    },
  );

  await firebaseService.startListeningOrderSupportChat(
    database,
    orderId,
    snapshot => {
      const messages = snapshot.val();

      if (!messages) {
        return;
      }

      // convert messages from an object of n properties to an array to make data structure easier
      const supportChatMessages = [];
      for (const key in messages) {
        if (!messages.hasOwnProperty(key)) {
          return;
        }

        if (
          messages[key].receiver_type !== 'tablet' &&
          messages[key].sender_type !== 'tablet'
        ) {
          return;
        }

        messages[
          key
        ].timeDiffLabel = DateTimeUtils.getDiffLabelFromMilliseconds(
          messages[key].timestamp,
          tz,
        );
        supportChatMessages.push(messages[key]);
      }

      const notViewedMessages = supportChatMessages.filter(
        message => !message.viewed && message.sender_type !== 'tablet',
      );

      realTimeService.sendBulkSupportChatMessageAck(notViewedMessages);
      dispatch(listenOrderSupportChatAction(orderId, supportChatMessages));
    },
  );
};

const openPreloadedSupportChatDrawer = typification => async dispatch => {
  try {
    dispatch(await startFirebaseChat());
  } catch (ex) {
    const PWEXCode = PWEXCodes.PWEX10;
    const formatMessageObj = { id: PWEXCode };
    const customEx = new PWEX(PWEXCode, formatMessageObj, null, ex);
    await AppActions.handleCatch(customEx)(dispatch);
    return;
  }

  await PartnersActions.getPartnerTypifications()(dispatch);
  const availableTypifications = appStore.getSupportTypifications();

  const productNotAvailableTypification = find(availableTypifications, {
    typification,
  });

  if (!productNotAvailableTypification) {
    await openSupportChatDrawer()(dispatch);
    return;
  }

  const orderId = appStore.getOrderIdInOrderDetailDialog();
  const partnerId = appStore.getPartnerId();
  const partnerName = appStore.getPartnerName();

  try {
    await dispatch(
      postRealTimeNotification(
        orderId,
        partnerId,
        partnerName,
        productNotAvailableTypification.typification,
        productNotAvailableTypification.description,
      ),
    );
  } catch (ex) {
    console.error(ex);
    // Continue operation
  }

  dispatch(dialogsManagerActions.close());
  dispatch(dialogsManagerActions.openOrderDetailDialog(orderId));
  await dispatch(openSupportChatDrawer());
};

const openNoRTAssignedSupportChatDrawer = () => async dispatch => {
  await dispatch(
    openPreloadedSupportChatDrawer(
      SupportChatTypifications.PARTNER_ORDER_WITHOUT_SK,
    ),
  );
};
const openProductNotAvailableSupportChatDrawer = () => async dispatch => {
  await dispatch(
    openPreloadedSupportChatDrawer(
      SupportChatTypifications.PARTNER_PRODUCT_TOPPING_NOT_AVAILABLE,
    ),
  );
};
const openRTAssignedSupportChatDrawer = () => async dispatch => {
  await dispatch(
    openPreloadedSupportChatDrawer(SupportChatTypifications.PARTNER_SK_DELAY),
  );
};

const openSupportReasonsDrawerIfOn = () => async dispatch => {
  try {
    await startFirebaseChat()(dispatch);
    await openSupportReasonsDrawer()(dispatch);
  } catch (ex) {
    const PWEXCode = PWEXCodes.PWEX10;
    const formatMessageObj = { id: PWEXCode };
    const customEx = new PWEX(PWEXCode, formatMessageObj, null, ex);
    await AppActions.handleCatch(customEx)(dispatch);
    return;
  }
};

const openSupportReasonsDrawer = () => async dispatch => {
  await PartnersActions.getPartnerTypifications()(dispatch);
  await openSupportChatDrawer()(dispatch);
};

const openSupportChatDrawer = () => async dispatch => {
  let supportScore;
  const orderId = appStore.getOrderIdInOrderDetailDialog();
  try {
    const { data, status } = await supportRateService.getSupportCalification(
      orderId,
    );
    if (status === 204) {
      supportScore = null;
    } else {
      supportScore = data;
    }
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
  }
  try {
    logEvent(EventCodes.OPEN_SUPPORT_CHAT);
    dispatch(openSupportChatDrawerAction(orderId, supportScore));
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
  }
};

const getOrdersHistorical = (storeId, page = 1) => async dispatch => {
  if (!storeId) {
    dispatch(getOrdersHistoricalAction([], 1));
    return;
  }
  try {
    OrderActions.setOrdersHistoricalIsLoading()(dispatch);

    const response = await orderService.getOrdersHistorical(storeId, page);

    const ordersHistorical = get(response, 'data', []);

    dispatch(getOrdersHistoricalAction(ordersHistorical, page));

    OrderActions.hideGettingOrdersHistoricalFailed()(dispatch);

    OrderActions.setOrdersHistoricalIsNotLoading()(dispatch);
  } catch (ex) {
    const exceptionMessage = PWEXUtils.getMessage(ex);

    OrderActions.showGettingOrdersHistoricalFailed(exceptionMessage)(dispatch);

    OrderActions.setOrdersHistoricalIsNotLoading()(dispatch);

    const ordersHistorical = appStore.getOrdersHistorical();

    const showErrorSnackbar = !!ordersHistorical && !!ordersHistorical.length;

    await AppActions.handleCatch(ex, showErrorSnackbar)(dispatch);
  }
};

const hideGettingOrdersHistoricalFailed = () => dispatch => {
  const isGettingOrdersHistoricalFailedBeingShown = appStore.isGettingOrdersHistoricalFailedBeingShown();

  if (!isGettingOrdersHistoricalFailedBeingShown) {
    return;
  }

  dispatch(hideGettingOrdersHistoricalFailedAction());
};

const showGettingOrdersHistoricalFailed = exceptionMessage => dispatch => {
  const isGettingOrdersHistoricalFailedBeingShown = appStore.isGettingOrdersHistoricalFailedBeingShown();

  if (isGettingOrdersHistoricalFailedBeingShown) {
    return;
  }

  const ordersHistorical = appStore.getOrdersHistorical();

  if (!!ordersHistorical && ordersHistorical.length > 1) {
    return;
  }

  dispatch(showGettingOrdersHistoricalFailedAction(exceptionMessage));
};

const openDialogExtraCT = orderId => dispatch => {
  return dispatch(openDialogExtraCTAction(orderId));
};

const openOrdersHistoricalFilterDrawer = () => dispatch => {
  return dispatch(openOrdersHistoricalFilterDrawerAction());
};

const openOrdersListFilterDrawer = () => dispatch => {
  return dispatch(openOrdersListFilterDrawerAction());
};

const getQualifyNegativeReasons = () => dispatch => {
  return orderService.getQualifyNegativeReasons().then(response => {
    dispatch(getQualifyNegativeReasonsAction(response.data));
  });
};

const qualifyPositiveRT = (orderId, partnerId, storekeeperId) => dispatch => {
  return orderService
    .qualifyPositiveRT(orderId, partnerId, storekeeperId)
    .then(response => {
      const data = { orderId, value: true };
      dispatch(markRTqualificationAction(data));
    });
};

const qualifyNegativeRT = (
  incidentComment,
  orderId,
  partnerId,
  rule_id,
  storekeeperId,
) => dispatch => {
  return orderService
    .qualifyNegativeRT(
      incidentComment,
      orderId,
      partnerId,
      rule_id,
      storekeeperId,
    )
    .then(response => {
      const data = { orderId, value: false };
      dispatch(markRTqualificationAction(data));
    });
};

const rejectNewOrder = (
  orderId,
  reasonId,
  reasonDescription,
  isMarketPlace,
) => async dispatch => {
  try {
    await orderService.rejectNewOrder(
      orderId,
      reasonId,
      reasonDescription,
      isMarketPlace,
    );

    await getOrders()(dispatch);
    if (history.location.pathname.indexOf(Paths.ORDERS_MANAGEMENT) !== -1) {
      RouterService.replaceToOrders();
    }
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
    throw ex;
  }
};

const rejectTookOrder = (
  orderId,
  reasonId,
  reasonDescription,
  isMarketPlace,
) => async dispatch => {
  try {
    await orderService.rejectTookOrder(
      orderId,
      reasonId,
      reasonDescription,
      isMarketPlace,
    );

    await getOrders()(dispatch);
    if (history.location.pathname.indexOf(Paths.ORDERS_MANAGEMENT) !== -1) {
      RouterService.replaceToOrders();
    }
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
    throw ex;
  }
};

const orderOnTheWay = (orderId, partnerOrderState) => async dispatch => {
  try {
    await orderService.handToDomiciliary(orderId, partnerOrderState);
  } catch (ex) {
    AppActions.handleCatch(ex)(dispatch);
    return;
  }

  await getOrders()(dispatch);
  if (history.location.pathname.indexOf(Paths.ORDERS_MANAGEMENT) !== -1) {
    RouterService.replaceToOrders();
  }
};

const postRealTimeNotification = (
  orderId,
  partnerId,
  partnerName,
  typification,
  typificationDescription,
) => async dispatch => {
  await realTimeService.postNotification(
    orderId,
    partnerId,
    partnerName,
    typification,
    typificationDescription,
  );
};

const searchForRTNow = (
  orderId,
  cookingTime,
  isRTAssigned,
  partnerOrderState,
  storeId,
) => dispatch => {
  if (isRTAssigned) {
    return readyForPickUp(
      orderId,
      partnerOrderState,
    )(dispatch).catch(ex => AppActions.handleCatch(ex)(dispatch));
  }

  return orderService
    .searchForRTNow(orderId, cookingTime, partnerOrderState, storeId)
    .then(() => {
      return readyForPickUp(orderId, partnerOrderState)(dispatch);
    })
    .catch(ex => AppActions.handleCatch(ex)(dispatch));
};

const sendSupportChatMessage = (
  message,
  orderId,
  partnerId,
  partnerName,
) => async dispatch => {
  await realTimeService.sendSupportChatMessage(
    message,
    orderId,
    partnerId,
    partnerName,
  );
  dispatch(sendSupportChatMessageAction());
};

const sendSupportCalification = (rate, comment) => async dispatch => {
  const partnerId = appStore.getPartnerId();
  const storeId = appStore.getOrderStoreIdInOrderDetailDialog();
  const orderId = appStore.getOrderIdInOrderDetailDialog();

  await supportRateService.sendSupportCalification(
    partnerId,
    storeId,
    orderId,
    comment,
    rate,
  );
  dispatch(sendSupportCalificationAction());
};

const readyForPickUp = (
  orderId,
  partnerOrderState,
  updateData = true,
  triggerType = ReadyForPickupTriggerTypes.MANUAL,
) => async dispatch => {
  try {
    const {
      data: { message, title },
    } = await orderService.readyForPickUp(orderId, partnerOrderState);

    AppActions.openSnackBarSuccess(message, title)(dispatch);

    await logReadyForPickUpEvent(orderId, triggerType, true)(dispatch);

    if (updateData) {
      await getOrders()(dispatch);
      await getOrderDetailAndStoreKeeperChannel(orderId)(dispatch);
    }
  } catch (ex) {
    await logReadyForPickUpEvent(orderId, triggerType, false)(dispatch);
    await AppActions.handleCatch(ex)(dispatch);
    throw ex;
  }
};

const logReadyForPickUpEvent = (
  orderId,
  triggerType,
  success,
) => async dispatch => {
  try {
    const hasExtension = await ChromeExtensionService.isAvailable();
    const extensionVersion = await ChromeExtensionService.getVersion();

    EventActions.onReadyForPickupExecution(
      orderId,
      success,
      hasExtension,
      extensionVersion,
      triggerType,
    );
  } catch (eventEx) {
    await AppActions.handleCatch(eventEx, false)(dispatch);
  }
};

const setDeliveredToRTBeforeCanceled = (
  orderId,
  partnerId,
  storekeeperId,
  storeId,
) => async dispatch => {
  try {
    await orderService.setDeliveredToRTBeforeCancelled(
      orderId,
      partnerId,
      storekeeperId,
      storeId,
    );
    dispatch(setDeliveredToRTBeforeCanceledAction(orderId));
    indexedDBService.deleteOrderById(orderId);
    await getOrders()(dispatch);
  } catch (ex) {
    throw ex;
  }
};

const setDeliveredToRTBeforeCanceledFromDetail = (
  orderId,
  partnerId,
  storekeeperId,
  storeId,
) => async dispatch => {
  try {
    await setDeliveredToRTBeforeCanceled(
      orderId,
      partnerId,
      storekeeperId,
      storeId,
    )(dispatch);
    if (history.location.pathname.indexOf(Paths.ORDERS_MANAGEMENT) !== -1) {
      RouterService.replaceToOrders();
    }
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
  }
};

const setOrdersHistoricalIsNotLoading = () => dispatch => {
  dispatch(setOrdersHistoricalIsNotLoadingAction());
};
const setOrdersHistoricalIsLoading = () => dispatch => {
  dispatch(setOrdersHistoricalIsLoadingAction());
};

const setNotDeliveredToRTBeforeCanceled = (
  orderId,
  partnerId,
  storekeeperId,
  storeId,
) => async dispatch => {
  try {
    await orderService.setNotDeliveredToRTBeforeCancelled(
      orderId,
      partnerId,
      storekeeperId,
      storeId,
    );

    dispatch(setNotDeliveredToRTBeforeCanceledAction(orderId));
    indexedDBService.deleteOrderById(orderId);
    await getOrders()(dispatch);
  } catch (ex) {
    throw ex;
  }
};

const setNotDeliveredToRTBeforeCanceledFromDetail = (
  orderId,
  partnerId,
  storekeeperId,
  storeId,
) => async dispatch => {
  try {
    await setNotDeliveredToRTBeforeCanceled(
      orderId,
      partnerId,
      storekeeperId,
      storeId,
    )(dispatch);

    if (history.location.pathname.indexOf(Paths.ORDERS_MANAGEMENT) !== -1) {
      RouterService.replaceToOrders();
    }
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
    return;
  }
};

const setSearchFieldText = text => dispatch => {
  dispatch(setSearchFieldTextAction(text));
  ordersKanbanActions.setOrders()(dispatch);
};

const setStoreInOrdersHistoricalFilter = storeId => async dispatch => {
  dispatch(setStoreInOrdersHistoricalFilterAction(storeId));
  if (storeId) {
    await getOrdersHistorical(storeId, 1)(dispatch);
  } else {
    clearOrdersHistorical()(dispatch);
  }
};

const takeOrder = (
  orderId,
  cookingTime,
  predictor,
  minCT,
  maxCT,
  defaultCT,
) => async dispatch => {
  try {
    await orderService.takeOrder(
      orderId,
      cookingTime,
      predictor,
      minCT,
      maxCT,
      defaultCT,
    );

    try {
      const hasExtension = !!(await ChromeExtensionService.isAvailable());
      const extensionVersion = await ChromeExtensionService.getVersion();
      EventActions.onOrderTaken(
        orderId,
        cookingTime,
        hasExtension,
        extensionVersion,
      );
    } catch (ex) {
      await AppActions.handleCatch(ex, false)(dispatch);
      // Continue the execution.
    }

    await getOrders()(dispatch);
    await getOrderDetailAndStoreKeeperChannel(orderId)(dispatch);
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
    throw ex;
  }
};

const deliverOrderFromDialog = orderId => async dispatch => {
  await deliverOrder(orderId)(dispatch);
  dialogsManagerActions.close()(dispatch);
};

const loadOrderDetailDialog = () => async dispatch => {
  const orderId = appStore.getOrderIdInOrderDetailDialog();

  orderDetailDialogActions.setLoadingOrderDetailDialog(true)(dispatch);

  await getOrderDetailAndStoreKeeperChannel(orderId)(dispatch);

  OrderActions.setInitialCTToTakeOrder()(dispatch);

  orderDetailDialogActions.setIsOrderBeingIntegrated()(dispatch);

  orderDetailDialogActions.setLoadingOrderDetailDialog(false)(dispatch);
};

const retryLodingOrderTotals = () => async dispatch => {
  const orderId = appStore.getOrderIdInOrderDetailDialog();

  const errorType = appStore.getOrderTotalsErrorIdInOrderDetailDialog();

  EventActions.onClickOrderTotalsRetryButton(orderId, errorType);

  await loadOrderDetailDialog()(dispatch);
};

const openOrderDetailDialog = orderId => async dispatch => {
  try {
    EventActions.onClickOpenDetailFromOrderCard(orderId);
  } catch (eventEx) {
    await AppActions.handleCatch(eventEx, false)(dispatch);
  }

  dialogsManagerActions.openOrderDetailDialog(orderId)(dispatch);

  await OrderActions.loadOrderDetailDialog()(dispatch);
};

const openOrderRejectionDialog = () => async dispatch => {
  const order = appStore.getOrderDetailInOrderDetailDialog();
  const isMarketPlace = OrdersUtils.isMarketPlace(order.delivery_method);
  await getRejectionReasons(isMarketPlace)(dispatch);
  dialogsManagerActions.closeCurrent()(dispatch);
  dialogsManagerActions.openOrderRejectionDialog(order.id)(dispatch);
};

const openRTDialog = (orderId, byBtnFindRT = true) => async dispatch => {
  dialogsManagerActions.closeCurrent()(dispatch);
  if (byBtnFindRT) EventActions.onClickOrderFindRT({ orderId });
  await dialogsManagerActions.openRTDialog(orderId)(dispatch);
  await getOrderDetailAndStoreKeeperChannel(orderId)(dispatch);
};

const takeOrderFromDialog = (
  orderId,
  cookingTimeToTakeOrder,
) => async dispatch => {
  try {
    // CTpredictor
    const predictiveMaxCT = appStore.getOrderPredictiveCTMaxInOrderDetailDialog();
    const predictiveMinCT = appStore.getOrderPredictiveCTMinInOrderDetailDialog();
    const predictorDefaultCT = appStore.getOrderPredictiveCTDefaultInOrderDetailDialog();
    // CTprofile
    const profileDefault = appStore.getOrderStoreDefaultCTInOrderDetailDialog();
    const profileMaxCT = appStore.getOrderStoreMaxCTInOrderDetailDialog();
    const profileMinCT = appStore.getOrderStoreMinCTInOrderDetailDialog();
    let predictor =
      !!predictiveMaxCT || !!predictiveMinCT || !!predictorDefaultCT;
    let minCT = predictor ? predictiveMinCT : profileMinCT;
    let maxCT = predictor ? predictiveMaxCT : profileMaxCT;
    let defaultCT = predictor ? predictorDefaultCT : profileDefault;
    await takeOrder(
      orderId,
      cookingTimeToTakeOrder,
      predictor,
      minCT,
      maxCT,
      defaultCT,
    )(dispatch);
    dialogsManagerActions.close()(dispatch);
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
  }
};

const executeOrderIsReadyFromDialog = () => async dispatch => {
  try {
    const order = appStore.getOrderDetailInOrderDetailDialog();
    await executeOrderIsReady(order)(dispatch);
    dialogsManagerActions.close()(dispatch);
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
  }
};

const setAutoPrint = isActive => async dispatch => {
  try {
    const result = await settingsActions.setAutoPrint(isActive)(dispatch);
    if (get(result, 'hasFailed', true)) return result;
    const beingManagedCookingOrders = appStore.getBeingManagedCookingOrders();
    const tz = appStore.getTz();
    await indexedDBService.setBulkPrintedOrders(beingManagedCookingOrders, tz);
    return {
      hasFailed: false,
    };
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
    return {
      hasFailed: true,
      ex,
    };
  }
};

const setNotDeliveredToRTBeforeCanceledFromDialog = (
  orderId,
  partnerId,
  storekeeperId,
  storeId,
) => async dispatch => {
  try {
    await setNotDeliveredToRTBeforeCanceled(
      orderId,
      partnerId,
      storekeeperId,
      storeId,
    )(dispatch);
    dialogsManagerActions.close()(dispatch);
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
  }
};

const setDeliveredToRTBeforeCanceledFromDialog = (
  orderId,
  partnerId,
  storekeeperId,
  storeId,
) => async dispatch => {
  try {
    await setDeliveredToRTBeforeCanceled(
      orderId,
      partnerId,
      storekeeperId,
      storeId,
    )(dispatch);
    dialogsManagerActions.close()(dispatch);
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
  }
};

const executeRejectionReasonFromDialog = reason => async dispatch => {
  switch (reason.refuse_reason_enum) {
    case RefuseReasons.BADLY_CONFIGURED_PRODUCT:
    case RefuseReasons.NO_LONGER_HANDLE_PRODUCT:
      dialogsManagerActions.openOrderRejectionByStockOutDialog(reason)(
        dispatch,
      );
      return;
    case RefuseReasons.PARTNER_WITHOUT_MOTOBOY:
      dialogsManagerActions.openOrderRejectionByMotoboyLackDialog(reason)(
        dispatch,
      );
      return;
    case RefuseReasons.RAN_OUT_PRODUCTS:
      dialogsManagerActions.openOrderRejectionByStockOutDialog(reason)(
        dispatch,
      );
      return;
    case RefuseReasons.SATURATED_STORE:
      dialogsManagerActions.openOrderRejectionBySaturatedStoreDialog(reason)(
        dispatch,
      );
      return;
    default:
      await rejectOrderFromDialog(reason)(dispatch);
  }
};

const rejectOrderFromDialog = reason => async dispatch => {
  try {
    const order = appStore.getOrderDetailInOrderRejectionDialog();
    await rejectOrder(order, reason)(dispatch);
  } catch (ex) {}
  dialogsManagerActions.close()(dispatch);
};

const rejectOrderByMotoboyLackFromDialog = deactivateMinutes => async dispatch => {
  const selectedRefuseReason = appStore.getSelectedRefuseReasonFromOrderRejectionByMotoboyLackDialog();
  try {
    await PartnersActions.confirmPartnerDeactivation(
      deactivateMinutes,
      PartnerDeactivationReasonType.LIMITED_TIME,
    )(dispatch);

    await rejectOrderFromDialog(selectedRefuseReason)(dispatch);
  } catch (ex) {
    dialogsManagerActions.close()(dispatch);
  }
};

const selectSupportReasonDialog = () => async dispatch => {
  // const selectedSupportReason = appStore.getSelectedSupportReasonskDialog();
  try {
    // dont know nothing
  } catch (ex) {
    dialogsManagerActions.close()(dispatch);
  }
};

const selectRtAssignedIssueDialog = () => async dispatch => {
  // const selectedSupportReason = appStore.getSelectedSupportReasonskDialog();
  try {
    // dont know nothing
  } catch (ex) {
    dialogsManagerActions.close()(dispatch);
  }
};

const selectNoRTAssignedIssueDialog = () => async dispatch => {
  // const selectedSupportReason = appStore.getSelectedSupportReasonskDialog();
  try {
    // dont know nothing
  } catch (ex) {
    dialogsManagerActions.close()(dispatch);
  }
};

const selectJustCookedOrderIssueDialog = () => async dispatch => {
  // const selectedSupportReason = appStore.getSelectedSupportReasonskDialog();
  try {
    // dont know nothing
  } catch (ex) {
    dialogsManagerActions.close()(dispatch);
  }
};
const selectCookedOrderConfirmationIssueDialog = () => async dispatch => {
  // const selectedSupportReason = appStore.getSelectedSupportReasonskDialog();
  try {
    // dont know nothing
  } catch (ex) {
    dialogsManagerActions.close()(dispatch);
  }
};

const rejectOrderBySaturatedStoreFromDialog = deactivateMinutes => async dispatch => {
  const selectedRefuseReason = appStore.getSelectedRefuseReasonFromOrderRejectionBySaturatedStoreDialog();
  try {
    await PartnersActions.confirmPartnerDeactivation(
      deactivateMinutes,
      PartnerDeactivationReasonType.LIMITED_TIME,
    )(dispatch);

    await rejectOrderFromDialog(selectedRefuseReason)(dispatch);
  } catch (ex) {
    dialogsManagerActions.close()(dispatch);
  }
};

const setInitialCTToTakeOrder = () => dispatch => {
  const isNonIntegratedNewOrder = appStore.getIsNonIntegratedNewOrderInOrderDetailDialog();

  if (!isNonIntegratedNewOrder) {
    // If the order is an integrated order or isn't a new order, just return;
    return;
  }

  const orderId = appStore.getOrderIdInOrderDetailDialog();
  const initialCTToTakeOrder = appStore.getInitialCTToTakeOrderInOrderDetailDialog();

  dispatch(setInitialCTToTakeOrderAction(orderId, initialCTToTakeOrder));
};

const setRTCounters = couriersStoreInfo => dispatch => {
  if (!couriersStoreInfo || !couriersStoreInfo.length) {
    return;
  }
  const selectedStoreIds = appStore.getSelectedStoreIdsForOrders();

  if (!selectedStoreIds || !selectedStoreIds.length) {
    const rtsInStoreTotal = RTUtils.getRTsInStoreTotalFromCourriesStoreInfo(
      couriersStoreInfo,
    );
    const rtsOnTheWayTotal = RTUtils.getRTsOnTheWayTotalFromCourriesStoreInfo(
      couriersStoreInfo,
    );
    dispatch(setRTCountersAction(rtsInStoreTotal, rtsOnTheWayTotal));
    return;
  }

  const filteredCourriers = couriersStoreInfo.filter(courrierInfo => {
    if (!courrierInfo.store_id) {
      return false;
    }
    return selectedStoreIds.indexOf(courrierInfo.store_id.toString()) >= 0;
  });

  const rtsInStoreTotal = RTUtils.getRTsInStoreTotalFromCourriesStoreInfo(
    filteredCourriers,
  );
  const rtsOnTheWayTotal = RTUtils.getRTsOnTheWayTotalFromCourriesStoreInfo(
    filteredCourriers,
  );
  dispatch(setRTCountersAction(rtsInStoreTotal, rtsOnTheWayTotal));
};

const rejectByStockOut = (
  products,
  selectedProducts,
  selectedToppings,
  refuseReason,
  refuseReasonsDescription,
) => async dispatch => {
  try {
    const orderId = appStore.getOrderIdInOrderDetailDialog();
    const storeId = appStore.getOrderStoreIdInOrderDetailDialog();
    const { data } = await stockOutService.sendStockOutReplacement(
      orderId,
      selectedProducts,
      selectedToppings,
      refuseReason,
      refuseReasonsDescription,
    );
    const showSuggestProductDialog = get(
      data,
      'is_product_substitution_available',
      false,
    );
    const product = products.find(item => item.id === +selectedProducts);
    const productName = get(product, 'name', '');
    const productId = get(product, 'id', 0);

    if (showSuggestProductDialog) {
      dispatch(
        dialogsManagerActions.openProductSuggestionAvailabilityDialog(
          productId,
          productName,
          selectedProducts,
          refuseReason,
          refuseReasonsDescription,
        ),
      );
    } else {
      await getOrders()(dispatch);
      const successTitle = formatMessage({ id: 'customSnackbar.successTitle' });
      const successDescription = formatMessage({
        id: 'customSnackbar.successMessage',
      });
      await dispatch(
        AppActions.openSnackBarSuccess(successDescription, successTitle),
      );
      dispatch(dialogsManagerActions.close());
      const deactivationType = MenuUtils.getDeactivationTypeFromRefuseReason(
        refuseReason,
      );
      await dispatch(
        menuActions.setLastToppingsDeactivated(
          storeId,
          selectedToppings,
          deactivationType,
        ),
      );
    }
  } catch (ex) {
    await dispatch(AppActions.handleCatch(ex));
    dispatch(dialogsManagerActions.close());
  }
};

const sendSuggestProduct = (
  suggestion,
  selectedProduct,
  refuseReason,
  refuseReasonDescription,
  productName,
) => async dispatch => {
  try {
    const orderId = appStore.getOrderIdInOrderDetailDialog();
    const partnerId = appStore.getPartnerId();

    await stockOutService.sendProductSuggestion(
      orderId,
      suggestion,
      selectedProduct,
      refuseReason,
      refuseReasonDescription,
    );
    EventActions.onSuccessSendSuggestProduct(
      orderId,
      partnerId,
      suggestion,
      refuseReason,
    );
    if (suggestion === '') {
      return dispatch(dialogsManagerActions.close());
    }
    await dispatch(
      dialogsManagerActions.openSuccessfulSuggestingProductDialog(
        productName,
        suggestion,
      ),
    );
    await dispatch(getOrders());
  } catch (ex) {
    await dispatch(dialogsManagerActions.openErrorSuggestingProductDialog());
    await dispatch(AppActions.handleCatch(ex));
  }
};

const executeSubstitutionFlowDialog = orderId => async dispatch => {
  const reason = ranOutProductsReason;
  dialogsManagerActions.openSubstitutionFlowDialog(orderId, reason)(dispatch);
  return;
};

const OrderActions = {
  acknowledgeCanceledOrder,
  acknowledgeCanceledOrderAction,
  acknowledgeCanceledOrderFromDialog,
  addMoreCookingTime,
  addToOrderDetailPollingBlacklistAction,
  applyOrdersHistoricalFilterAction,
  applyOrdersListFilter,
  applyOrdersListFilterAction,
  cancelByStockOutAsSupport,
  clearNetworkErrorCount,
  clearNetworkErrorCountAction,
  clearFailServiceErrorCount,
  clearFailServiceErrorCountAction,
  clearOrdersHistorical,
  clearOrdersHistoricalAction,
  clearOrdersHistoricalFilter,
  clearOrdersListFilter,
  clearOrdersListFilterAction,
  closeCheckNetworkDialog,
  closeCheckNetworkDialogAction,
  closeDialogExtraCT,
  closeDialogExtraCTAction,
  closeOrdersHistoricalFilterDrawer,
  closeOrdersHistoricalFilterDrawerAction,
  closeOrdersListFilterDrawer,
  closeOrdersListFilterDrawerAction,
  closeSupportChatDrawer,
  closeSupportChatDrawerAction,
  confirmPrint,
  confirmPrintAction,
  confirmPrintBulk,
  confirmPrintBulkAction,
  confirmReceptionAction,
  deactivateProductsToppingsFromStockOutIssue,
  decreaseCookingTimeToTakeOrder,
  decreaseCookingTimeToTakeOrderAction,
  deliverBundle,
  deliverOrder,
  deliverOrderFromDialog,
  deliverToRappiTendero,
  executeOrderIsReady,
  executeOrderIsReadyFromDialog,
  executeRejectionReasonFromDialog,
  executeSubstitutionFlowDialog,
  getOrderDetail,
  getOrderDetailAction,
  getOrderDetailAndStoreKeeperChannel,
  getOrderIdByPickupCode,
  getOrders,
  getOrdersAction,
  getOrdersHistorical,
  getOrdersHistoricalAction,
  getProfileWithOrders,
  getQualifyNegativeReasons,
  getQualifyNegativeReasonsAction,
  getRejectionReasons,
  getRejectionReasonsAction,
  hideGettingOrdersHistoricalFailed,
  hideGettingOrdersHistoricalFailedAction,
  increaseCookingTimeToTakeOrder,
  increaseCookingTimeToTakeOrderAction,
  increaseNetworkErrorCount,
  increaseNetworkErrorCountAction,
  increaseFailServiceErrorCount,
  increaseFailServiceErrorCountAction,
  listenOrderSupportChatAction,
  listenOrderSupportNotificationsAction,
  loadOrderDetailDialog,
  markRTqualificationAction,
  openCheckNetworkDialog,
  openCheckNetworkDialogAction,
  openCheckErrorServiceDialog,
  closeCheckErrorServiceDialog,
  openDialogExtraCT,
  openDialogExtraCTAction,
  openNoRTAssignedSupportChatDrawer,
  openOrderDetailDialog,
  openOrderRejectionDialog,
  openOrdersHistoricalFilterDrawer,
  openOrdersHistoricalFilterDrawerAction,
  openOrdersListFilterDrawer,
  openOrdersListFilterDrawerAction,
  openProductNotAvailableSupportChatDrawer,
  openRTAssignedSupportChatDrawer,
  openRTDialog,
  openSupport,
  openSupportChatDrawer,
  openSupportChatDrawerAction,
  openSupportReasonsDrawerIfOn,
  orderOnTheWay,
  pollOrderDetailAndStoreKeeperChannel,
  postRealTimeNotification,
  qualifyNegativeRT,
  qualifyPositiveRT,
  redirectOrdersHistoricalFilter,
  rejectNewOrder,
  rejectNewOrderAction,
  rejectOrder,
  rejectOrderByMotoboyLackFromDialog,
  rejectOrderBySaturatedStoreFromDialog,
  rejectOrderFromDialog,
  rejectTookOrder,
  rejectTookOrderAction,
  releaseRTnow,
  retryLodingOrderTotals,
  searchForRTNow,
  selectCookedOrderConfirmationIssueDialog,
  selectJustCookedOrderIssueDialog,
  selectNoRTAssignedIssueDialog,
  selectRtAssignedIssueDialog,
  selectSupportReasonDialog,
  sendSupportChatMessage,
  sendSupportCalification,
  sendToPrint,
  sendToPrintAction,
  sendToPrintBulk,
  sendToPrintBulkAction,
  setAutoPrint,
  setDeliveredToRTBeforeCanceled,
  setDeliveredToRTBeforeCanceledAction,
  setDeliveredToRTBeforeCanceledFromDetail,
  setDeliveredToRTBeforeCanceledFromDialog,
  setFirebaseStatusAction,
  setInitialCTToTakeOrder,
  setInitialCTToTakeOrderAction,
  setNotDeliveredToRTBeforeCanceled,
  setNotDeliveredToRTBeforeCanceledAction,
  setNotDeliveredToRTBeforeCanceledFromDetail,
  setNotDeliveredToRTBeforeCanceledFromDialog,
  setOrdersHistoricalIsLoading,
  setOrdersHistoricalIsLoadingAction,
  setOrdersHistoricalIsNotLoading,
  setOrdersHistoricalIsNotLoadingAction,
  setRTCountersAction,
  setSearchFieldText,
  setSearchFieldTextAction,
  setStoreInOrdersHistoricalFilter,
  setStoreInOrdersHistoricalFilterAction,
  showGettingOrdersHistoricalFailed,
  showGettingOrdersHistoricalFailedAction,
  takeOrder,
  takeOrderFromDialog,
  rejectByStockOut,
  sendSuggestProduct,
  sendNotificationToRT,
};

export default OrderActions;
