import React from 'react';
import { Box, Typography, Menu, MenuItem } from '@material-ui/core';
import { formatCurrency } from '../../../../../utils/CurrencyUtils';
import { CustomMoreVertButton } from '../../../../common/buttons/custom-more-vert-button';
import { PAPER_PROPS_ITEM_STYLE } from '../const';
import { ChipOrderDisclaimer } from '../../order-mgmt-dialog/components';
import { SuggestionsSection } from './SuggestionsSection';
import OrderMgmgCardStyles from '../OrderMgmtCard.style';
import { useOrderMgmtLang } from '../../../hooks';
import replaceIcon from '../../../../../assets/images/icons/replaceIcon.svg';
import deleteIcon from '../../../../../assets/images/icons/deleteIcon.svg';
import { OrderMgmtTopping } from '../../../../../business/order-mgmt';
import { useOrderMgmtCard } from '../hooks';
import { UndoSuggestionChangesChip } from '../../order-mgmt-undo-suggestion-changes-chip';

export const ToppingDetail = ({
  topping,
  productId,
  isToppingEnabledToEdit,
}: Props) => {
  const componentClasses = OrderMgmgCardStyles();
  const {
    replaceToppingText,
    deleteToppingText,
    soldOutToppingText,
  } = useOrderMgmtLang();
  const {
    anchorToppingMenu,
    openToppingMenu,
    handleClickToppingManageMenuOptions,
    handleCloseToppingMenu,
    handleReplaceTopping,
    handleDeleteTopping,
  } = useOrderMgmtCard({ productId });

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      <Typography classes={{ root: componentClasses.toppingCategory }}>
        {topping.categoryName}
      </Typography>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography classes={{ root: componentClasses.toppingDescription }}>
          {topping.units > 1 && <>{topping.units}x&ensp;</>}
          {topping.name}
        </Typography>
        <Box display='flex' alignItems='center' gridGap='0.5rem'>
          <Typography>{formatCurrency(topping.price)}</Typography>
          {isToppingEnabledToEdit && (
            <CustomMoreVertButton
              onClick={handleClickToppingManageMenuOptions}
            />
          )}
          <Menu
            id='manage-topping-menu'
            anchorEl={anchorToppingMenu}
            open={openToppingMenu}
            onClose={handleCloseToppingMenu}
            PaperProps={{ style: PAPER_PROPS_ITEM_STYLE }}
          >
            <MenuItem onClick={() => handleReplaceTopping(topping)}>
              <Box
                className={componentClasses.replaceItem}
                display='flex'
                alignItems='center'
                justifyContent='center'
                fontSize='0.625rem'
                fontWeight={600}
                gridGap={10}
              >
                <img alt='replace-icon' src={replaceIcon} />
                <span>{replaceToppingText}</span>
              </Box>
            </MenuItem>
            {false && (
              <MenuItem onClick={handleDeleteTopping}>
                <Box className={componentClasses.deleteItem}>
                  <img alt='delete-icon' src={deleteIcon} />
                  <span>{deleteToppingText}</span>
                </Box>
              </MenuItem>
            )}
          </Menu>
        </Box>
      </Box>
      {topping?.suggestions && (
        <>
          <ChipOrderDisclaimer
            type='warning'
            disclaimer={soldOutToppingText}
            hasIcon
          />
          <Box display='flex' flexDirection='column' gridGap='0.5rem'>
            <SuggestionsSection itemSuggestions={topping.suggestions} />
            {isToppingEnabledToEdit && (
              <UndoSuggestionChangesChip
                productId={productId}
                toppingId={topping?.id}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

interface Props {
  topping: OrderMgmtTopping;
  productId: string;
  isToppingEnabledToEdit: boolean;
}
