import { makeStyles } from '@material-ui/core';

import { neutral, red, yellow } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  chipsContainer: {
    width: '100%',
    padding: '0.5rem 0',
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'stretch',
    justifyContent: 'flex-end',
  },
  deliverToUserButton: {
    width: 'fit-content',
    maxWidth: 'fit-content',
    padding: '0.625rem 0.75rem',
    fontSize: '0.875rem',
    fontFamily: 'Poppins',
    borderRadius: '0.5rem',
    color: neutral.white,
    backgroundColor: yellow['50'],
    '&:hover': { backgroundColor: yellow['50'] },
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1,
    '@media print': {
      display: 'none',
    },
  },
  showFiltersButton: {
    borderRadius: '50px',
    fontFamily: 'Poppins',
    fontSize: '13px !important',
    marginLeft: '16px',
    maxWidth: '117px',
    padding: '10px 12px',
  },
  appBarRoot: {
    backgroundColor: red['50'],
    boxShadow: 'none',
    height: 32,
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 9,
    width: 44,
  },
  content: {
    backgroundColor: neutral['20'],
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  toolbarTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  mobileToolbarRoot: () => ({
    minHeight: 'auto',
    paddingRight: '0.25rem',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: neutral.white,
    borderBottom: `1px solid ${neutral['20']}`,
    [theme.breakpoints.up('md')]: {
      paddingRight: '1.4rem',
    },
  }),
  menuButton: {
    marginLeft: 4,
    [theme.breakpoints.up('md')]: {
      marginLeft: 12,
      marginRight: 20,
    },
  },
  notificationsIconButton: {
    padding: 0,
  },
  sectionHeader: {
    backgroundColor: neutral.white,
    boxShadow: `0 1px 0 0 ${neutral['20']}`,
    minHeight: 64,
    position: 'relative',
  },
  sectionContent: {
    backgroundColor: neutral[10],
    flex: '0 1 100%',
    overflow: 'auto',
  },
  sectionLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  storeInfo: {
    color: neutral['90'],
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    margin: 'auto 24px',
  },
  toolbarButtons: {
    marginLeft: 'auto',
  },
  toolbarLvl2Root: {
    minHeight: 'auto',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: neutral.white,
  },
  avatar: {
    height: '34px',
    margin: '10px 24px 10px 10px',
    width: '34px',
  },
  footer: {
    padding: '0.5rem 0.75rem',
    display: 'flex',
    gap: '0.5rem 1rem',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    backgroundColor: neutral['10'],
  },
  footerOurFns: {
    display: 'flex',
    gap: '0.5rem',
  },
  footerSWC: {
    display: 'flex',
    gap: '0.5rem',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > label': {
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: '1.5rem',
      color: neutral['60'],
    },
    '& > button': {
      padding: '0.5rem 0.75rem',
      fontFamily: 'Poppins',
      lineHeight: '1.5rem',
      fontWeight: 600,
      zIndex: 10,
      // pointerEvents: 'none',
      border: `1px solid ${neutral['20']}`,
      borderRadius: '0.5rem',
      color: neutral['100'],
      backgroundColor: neutral.white,
      '& .MuiButton-startIcon': {
        marginLeft: 0,
        marginRight: '0.25rem',
      },
    },
    // the attribute data-testid is into WebChat component, this is external
    '& > support-web-chat > main[data-testid="mainPlugChat"]:first-child > button': {
      right: '8.5rem',
    },
    // the attribute data-testid is into WebChat component, this is external
    '& > support-web-chat > main[data-testid="mainPlugChat"] > button:first-of-type': {
      width: '6rem',
      height: '2.5rem',
      bottom: '0.5rem',
      zIndex: 20,
      border: 'none',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      '& > *:not(span)': {
        display: 'none',
      },
    },
  },
}));
