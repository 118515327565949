import { clsx } from 'clsx';
import { useState } from 'react';

import { useOutStockText } from '../hook';
import { ToppingOutStock } from '../topping-outstock';

import useStyles from './ToppingBoxesList.style';
import useRootStyles from '../MenuOutOfStockView.styles';

export function ToppingBoxesList(props) {
  const {
    classes = {},
    onChange,
    // products,
    selectorPropertyName,
    selectorType,
    // toppingCategories,
    toppings,
  } = props;

  const [deactivatedItemId, setDeactivatedItemId] = useState(null);
  const _classes = useStyles();
  const rootClasses = useRootStyles();
  const title = useOutStockText({ ctaName: 'toppingTitle' });

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <div className={clsx(_classes.titleContainer, classes?.titleContainer)}>
        <span className={rootClasses.title}>{title}</span>
      </div>
      <div className={_classes.toppingsContainer}>
        {toppings.map((topping, index) => {
          return (
            <ToppingOutStock
              key={`t-${index}-${topping.id}`}
              // classes={{ root: clsx(_classes.toppingBoxRoot, classes?.toppingBoxRoot) }}
              onChange={() => {
                onChange(topping);
              }}
              // products={products}
              selectorPropertyName={selectorPropertyName}
              selectorType={selectorType}
              // toppingCategories={toppingCategories}
              topping={topping}
              // showTitle={true}
              deactivatedItemId={deactivatedItemId}
              setDeactivatedItemId={setDeactivatedItemId}
            />
          );
        })}
      </div>
    </div>
  );
}
