import { makeStyles } from '@material-ui/core';
import Colors from '../../../../constants/Colors';
import { neutral } from '../../../../shared/styles/colors';

export const OrderMgmtUndoChangesDialogStyles = makeStyles(() => ({
  paper: {
    height: '26.875rem',
    width: '45.6875rem',
    padding: '1rem',
    position: 'relative',
  },
  undoChangesDialogHeader: {
    height: '1rem',
    borderBottomColor: 'transparent',
  },
  undoChangesDialogHeaderTitle: {
    fontWeight: 600,
    fontSize: '1.5rem',
    color: neutral.D20,
  },
  undoChangesDialogHeaderDescription: {
    fontWeight: 400,
    fontSize: '1rem',
    color: neutral.D18,
  },
  undoChangesDialogDisclaimerContent: {
    background: Colors.DANGER_LIGHT_COLOR_2,
    borderLeft: `3px solid ${Colors.DANGER_LIGHT_1}`,
  },
  undoChangesDialogDisclaimerItems: {
    display: 'flex',
    gap: '0.5rem',
    fontSize: '0.875rem',
    flexDirection: 'column',
    padding: '0 1rem',
  },
  undoChangesDialogDisclaimerIcon: {
    alignSelf: 'flex-start',
    margin: '1rem 0.5rem 0',
  },
  disclaimerItem: {
    padding: 0,
  },
  undoChangesDialogCancel: {
    color: Colors.DANGER_LIGHT_1,
  },
  undoChangesDialogOk: {
    backgroundColor: Colors.DANGER_LIGHT_1,
    '&:hover': {
      backgroundColor: Colors.DANGER_LIGHT_1,
    },
  },
}));
