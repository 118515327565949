import thumbUp from '../../../../../assets/images/thumb-up.svg';
import thumbDown from '../../../../../assets/images/thumb-down.svg';
import thumbUpGreen from '../../../../../assets/images/thumb-up-green.svg';
import thumbDownRed from '../../../../../assets/images/thumb-down-red.svg';

import { useFormatMessage } from '../../../../../intl/intl';

import useStyles from './CustomIncident.styles';

export function CustomIncident(props) {
  const { enabled, incident, onPositive, onNegative } = props;

  const classes = useStyles();

  const notAvailable = useFormatMessage({
    id: 'orders.historical.nonAvailable',
  });

  const handleOnPositive = event => {
    event.stopPropagation();
    onPositive();
  };

  const handleOnNegative = event => {
    event.stopPropagation();
    onNegative();
  };

  return (
    <div className={classes.thumbAction}>
      {!enabled && <div>{notAvailable}</div>}
      {enabled && (
        <>
          {incident === null && (
            <>
              <div className={classes.icon} onClick={handleOnPositive}>
                <img src={thumbUp} alt='icon' />
              </div>
              <div className={classes.icon} onClick={handleOnNegative}>
                <img src={thumbDown} alt='icon' />
              </div>
            </>
          )}
          {incident !== null && !!incident && (
            <div className={classes.icon}>
              <img src={thumbUpGreen} alt='icon' />
            </div>
          )}
          {incident !== null && !incident && (
            <div className={classes.icon}>
              <img src={thumbDownRed} alt='icon' />
            </div>
          )}
        </>
      )}
    </div>
  );
}
