import BackArrowIcon from '../../../icons/back-arrow-icon/BackArrowIcon';

import { useArticleDetail } from './useArticleDetail';

import makeClasses from './style';

export function ArticleDetail() {
  const {
    articleName,
    articleBody,
    subcategoryName,
    handleGoBack,
  } = useArticleDetail();

  const _classes = makeClasses();

  return (
    <div className={_classes.root}>
      <div className={_classes.title}>
        <div className={_classes.arrowIcon} onClick={handleGoBack}>
          <BackArrowIcon />
        </div>
        {subcategoryName}
      </div>
      <div className={_classes.secondaryTitle}>{articleName}</div>
      <div
        className={_classes.article}
        dangerouslySetInnerHTML={{ __html: articleBody }}
      />
    </div>
  );
}
