import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useKanbanOMLang } from '../hooks';
import { OMCardStyle } from '../kanbanOMCard.style';

import { KanbanTimeoutCardStyle } from './KanbanTimeoutCard.style';

export function KanbanTimeoutCard() {
  const circularStyle = KanbanTimeoutCardStyle();
  const cardStyle = OMCardStyle();
  const { rejectedByTimeoutRemainingText } = useKanbanOMLang();

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      minWidth='5.7rem'
    >
      <Box position='relative' display='flex' alignItems='center'>
        <CircularProgress
          variant='static'
          className={circularStyle.top}
          classes={{
            circle: circularStyle.circle,
          }}
          size={35}
          thickness={5}
          value={100}
        />
      </Box>
      <Typography className={cardStyle.progressLabel}>
        {rejectedByTimeoutRemainingText}
      </Typography>
    </Box>
  );
}
