import { clsx } from 'clsx';

import OrderDetailDialogActionsBox from '../order-detail-dialog-actions-box';
import { OrderDetailDialogCancellationBox } from '../order-detail-dialog-cancellation-box';
import { OrderDetailDialogCookingTimeSection } from '../order-detail-dialog-cooking-time-section';
import OrderDetailDialogCustomerBox from '../order-detail-dialog-customer-box';
import { OrderDetailDialogMarketplaceBox } from '../order-detail-dialog-marketplace-box';
import { OrderDetailDialogPickupBox } from '../order-detail-dialog-pickup-box';
import { OrderDetailDialogRTBox } from '../order-detail-dialog-rt-box';
import { CargoAdditionalRT } from '../../features/cargo';
import { OrderDeliveryDetail } from '../../features/order/delivery-detail';

import Hooks from './hooks';
import { orderDetailApp } from '../../business/order-mgmt';

import useStyles from './OrderDetailDialogMainSection.styles';

function OrderDetailDialogMainSection(props) {
  const {
    classes = {},
    showCancellationBox = false,
    showCookingTimeInputBox,
    showActionBox,
    showRTBox = false,
    orderId,
    storeId,
    orderStatus,
    deliveryMethod,
    isCookingTimeDisabled,
    showOrderDetailBox,
    productConfirmationCode,
    orderState,
    canEditOrderDetailBox,
    isFastOperation,
    isMandatory,
    isBagQuantitySet,
    hasDeliveryDetailMessage
  } = props;

  const _clases = useStyles();
  const showMarketplaceBox = Hooks.useShowMarketplaceBox();
  const showPickupBox = Hooks.useShowPickupBox();
  const { isTimeoutOrder } = orderDetailApp();
  const showCookingTime = showCookingTimeInputBox || isTimeoutOrder;
  const isDisabledCookingTime = isCookingTimeDisabled;

  return (
    <div className={clsx(_clases.root, classes?.root)}>
      <div className={clsx(_clases.itemsContainer, classes?.itemsContainer)}>
        <OrderDetailDialogCustomerBox />
        {showCookingTime && (
          <OrderDetailDialogCookingTimeSection
            isDisabled={isDisabledCookingTime}
            isFastOperation={isFastOperation}
          />
        )}
        {showOrderDetailBox && (
          <OrderDeliveryDetail
            code={productConfirmationCode}
            orderId={orderId}
            storeId={storeId}
            orderState={orderState}
            canEditOrderDetailBox={canEditOrderDetailBox}
            isMandatory={isMandatory}
            isBagQuantitySet={isBagQuantitySet}
            hasDeliveryDetailMessage={hasDeliveryDetailMessage}
          />
        )}
        {showRTBox && <OrderDetailDialogRTBox />}
        {showCancellationBox && <OrderDetailDialogCancellationBox />}
        <CargoAdditionalRT
          behavior='detailMarketplace'
          storeId={storeId}
          orderId={orderId}
          orderStatus={orderStatus}
          deliveryMethod={deliveryMethod}
        />
        {showMarketplaceBox && <OrderDetailDialogMarketplaceBox />}
        {showPickupBox && <OrderDetailDialogPickupBox />}
      </div>
      {showActionBox && (
        <OrderDetailDialogActionsBox
          classes={{ root: _clases.actionsBoxRoot }}
        />
      )}
    </div>
  );
}

export default OrderDetailDialogMainSection;
