import React from 'react';
import { Box, Typography } from '@material-ui/core';
import OrderMgmtCardStyles from '../../../OrderManagement.style';
import { ResumeItemSuggestions } from './ResumeItemSuggestions';
import { OrderMgmtProduct } from '../../../../../business/order-mgmt';
import { ChipOrderDisclaimer } from '../../order-mgmt-dialog/components';
import { UndoSuggestionChangesChip } from '../../order-mgmt-undo-suggestion-changes-chip';

export const OrderMgmtResumeCard = ({ product, soldOutProductText }: Props) => {
  const componentClasses = OrderMgmtCardStyles();

  if (
    !product?.suggestions &&
    !product?.toppings?.some(topping => topping?.suggestions)
  )
    return null;

  return (
    <Box className={componentClasses.resumeItemContainer}>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        padding='1rem'
        marginBottom='1rem'
      >
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          width='100%'
        >
          <Box display='flex' gridGap='1rem'>
            <Typography className={componentClasses.quantity}>
              {`${product.units}x`}
            </Typography>
            <Typography className={componentClasses.name}>
              {product.name}
            </Typography>
          </Box>
          {product?.suggestions && (
            <ChipOrderDisclaimer
              type='warning'
              disclaimer={soldOutProductText}
            />
          )}
        </Box>
      </Box>
      {product?.suggestions && (
        <Box
          display='flex'
          flexDirection='column'
          marginBottom='0.5rem'
          gridGap='0.5rem'
        >
          <ResumeItemSuggestions item={product} />
          <UndoSuggestionChangesChip productId={product.id} />
        </Box>
      )}
      {product?.toppings &&
        product?.toppings.map(
          topping =>
            topping?.suggestions && (
              <Box
                display='flex'
                flexDirection='column'
                marginBottom='0.5rem'
                gridGap='0.5rem'
                key={`ts-${topping?.id}`}
              >
                <ResumeItemSuggestions item={topping} isTopping />
                <UndoSuggestionChangesChip
                  productId={product.id}
                  toppingId={topping.id}
                />
              </Box>
            ),
        )}
    </Box>
  );
};

interface Props {
  product: OrderMgmtProduct;
  soldOutProductText?: string;
}
