import successImage from '../../../assets/images/success002.png';
import alertImage from '../../../assets/images/alert001.png';
import Colors from '../../../constants/Colors';

export const VARIANTS = {
  error: {
    color: Colors.DANGER_COLOR,
    image: alertImage,
  },
  info: {
    color: Colors.PROPIO_FONT_COLOR,
    image: successImage,
  },
  success: {
    color: Colors.SUCCESS_COLOR,
    image: successImage,
  },
  warning: {
    color: Colors.WARNING_FONT_COLOR,
    image: alertImage,
  },
};
