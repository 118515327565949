// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(theme => ({
  customAlertRoot: {},
  description: {
    color: neutral.D20,
    fontSize: 12,
    lineHeight: 'normal',
  },
  root: {
    display: 'grid',
    gridRowGap: 10,
    justifyItems: 'center',
  },
  secondaryAlert: {
    backgroundColor: neutral['20'],
    color: neutral['80'],
    '& svg': {
      fill: {
        color: neutral['80'],
      },
    },
  },
}));
