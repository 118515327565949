// Material UI
import { makeStyles } from '@material-ui/core';
// Constant
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  body: {
    height: '100%',
    paddingTop: 24,
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
      overflowY: 'hidden',
    },
  },
  headerRoot: {
    [theme.breakpoints.up('md')]: {
      padding: 12,
    },
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      height: 557,
    },
  },
  root: {},
  description: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      paddingTop: 40,
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 17,
    paddingBottom: 12,
  },
  content: {
    fontSize: 12,
  },
  RTInfo: {
    '& div': {
      display: '-webkit-inline-box',
      width: 60,
      height: 60,
    },
    paddingBottom: 40,
    textAlign: 'center',
  },
  listRTName: {
    display: 'block',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: neutral.D20,
  },
  listPhone: {
    display: 'block',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: neutral.D15,
  },
  listID: {
    display: 'block',
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: neutral.D15,
  },
  rtBox: {
    padding: '24px 24px 40px 24px',
    [theme.breakpoints.up('md')]: {
      display: 'grid',
    },
  },
  container: {
    paddingBottom: 16,
    [theme.breakpoints.up('md')]: {
      paddingBottom: 40,
    },
  },
  label: {
    textAlign: 'center',
    padding: '40px 24px 24px 20px',
  },
}));
