import { ItemSuggestion } from '../../../../../../business/order-mgmt';
import { useFormatMessage } from '../../../../../../intl/intl';

export function useOrderMgmtSearchLang({
  item,
  user,
}: { item?: ItemSuggestion; user?: string } = {}) {
  const messageCategory = 'orderManagement';
  const messageSubcategory = 'searcher';

  return {
    searcherCtaText: useFormatMessage({
      id: `${messageCategory}.${messageSubcategory}.ctaText`,
    }),
    searcherInputText: useFormatMessage(
      { id: `${messageCategory}.${messageSubcategory}.inputText` },
      { type: item?.isProduct ? 'producto' : 'topping' },
    ),
    searcherTitleText: useFormatMessage({
      id: `${messageCategory}.${messageSubcategory}.titleText`,
    }),
    emptyResultsTitleText: useFormatMessage({
      id: `${messageCategory}.${messageSubcategory}.emptyResultsTitleText`,
    }),
    emptyResultsDescriptionText: useFormatMessage({
      id: `${messageCategory}.${messageSubcategory}.emptyResultsDescriptionText`,
    }),
    searchQueryErrorText: useFormatMessage({
      id: `${messageCategory}.${messageSubcategory}.searchQueryErrorText`,
    }),
    rejectedSearchText: useFormatMessage({
      id: `${messageCategory}.${messageSubcategory}.rejectedSearchText`,
    }),
    searcherDisclaimerText: useFormatMessage({
      id: `${messageCategory}.${messageSubcategory}.disclaimerText`,
    }),
    searcherDisclaimerCtaText: useFormatMessage(
      { id: `${messageCategory}.${messageSubcategory}.disclaimerCta` },
      { user },
    ),
    searcherCancelText: useFormatMessage(
      { id: `${messageCategory}.${messageSubcategory}.cancelText` },
      { user },
    ),
    searcherItemHasToppingsDisclaimerText: useFormatMessage({
      id: `${messageCategory}.${messageSubcategory}.itemHasToppingsDisclaimerText`,
    }),
    productWithToppingsText: useFormatMessage({
      id: `${messageCategory}.${messageSubcategory}.productWithToppingsText`,
    }),
    replaceWithText: useFormatMessage({
      id: `${messageCategory}.${messageSubcategory}.replaceWith`,
    }),
    chooseOptionsText: useFormatMessage({
      id: `${messageCategory}.${messageSubcategory}.chooseOptions`,
    }),
    searcherBackText: useFormatMessage({
      id: `${messageCategory}.${messageSubcategory}.backText`,
    }),
  };
}
