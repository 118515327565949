import Hooks from './hooks';

import type { BaseProps } from '../../../interfaces';
import type { Button } from '../buttons/custom-button';
import type { Serverities } from '../../../constants/Severities';
import type { StyleRules } from './components/custom-base-alert-snackbar';

export function CustomAlertSnackbar({ severity, ...props }: Props) {
  const AlertSnackbar = Hooks.useAlertSnackbar(severity);
  return <AlertSnackbar {...props} />;
}

interface Props extends BaseProps {
  buttons?: Button[];
  message?: string | null;
  open: boolean;
  severity?: Serverities;
  onClose?: () => void | Promise<void>;
  styleRules?: StyleRules;
}
