//React
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// utils
import DateTimeUtils from '../../utils/DateTimeUtils';
// Components
import PartnerNextInterval, { intervalTypes } from './PartnerNextInterval';

const mapStateToProps = (
  {
    appReducer: { language },
    partnerReducer: {
      profile: {
        nextInterval: { day, maxTime, minTime },
      },
    },
  },
  { intl: { formatMessage }, type },
) => {
  let nextInterval;
  switch (type) {
    case intervalTypes.TODAY:
      nextInterval = formatMessage(
        { id: 'partnerNextInterval.today' },
        { initialTime: minTime, endTime: maxTime },
      );
      break;
    case intervalTypes.NEXT:
    default:
      nextInterval =
        DateTimeUtils.getLocaleDateString(day, 'ddd DD MMM', language).replace(
          /\./g,
          '',
        ) + `, ${minTime}`;
      break;
  }

  return { nextInterval };
};

const mapDispatchToProps = {};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PartnerNextInterval),
);
