import { Typography } from '@material-ui/core';

import type { MainDrawerProps } from '../MainDrawer.types';

export function Subtitle(props: { subtitle: MainDrawerProps['subtitle'] }) {
  const { subtitle } = props ?? {};
  if (typeof subtitle !== 'string') return <>{subtitle}</>;
  return (
    <Typography component='h2' aria-label='default'>
      {subtitle}
    </Typography>
  );
}
