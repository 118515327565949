import { addRTHelper } from '../../../domain';
import { addRTCreateHandler } from '../../../infra';

import type { IAddRTCreateCaseParams, IAddRTCreateCase } from '../../../domain';

export function cargoAddRTCreateCase(
  params: IAddRTCreateCaseParams,
): IAddRTCreateCase {
  const { orderId } = params;

  const { addRT, closeDialog, createAddRT } = addRTCreateHandler();
  const {
    status,
    selectedOrderId,
    validatedData: dataToCreate,
    createdOrderId,
  } = addRT;

  const isOrderIdSame = orderId === selectedOrderId;
  const isInProgress = addRTHelper.isCreationInProgress(status);

  const openDialog =
    isOrderIdSame && addRTHelper.isDialogEnabledByAddRTStatus(status);
  const isLoading = isOrderIdSame && isInProgress;
  const isCreationDenied = addRTHelper.isCreationDenied(status);
  const isCreationAccepted = addRTHelper.isCreationAccepted(status);

  return {
    openDialog,
    dataToCreate,
    isLoading,
    isCreationDenied,
    isCreationAccepted,
    createdOrderId,
    onCreateOrder: createAddRT,
    onCloseDialog: closeDialog,
  };
}
