import { useState, useCallback } from 'react';

import CustomAlert from '../../components/common/custom-alert';
import CustomButton from '../common/buttons/custom-button';
import { ShowPhoneButton } from '../show-phone-button';
import { SupportWebChatButton } from './components/SupportWebChatButton';

import { useOrderManagement } from '../order-management/hooks';
import { ChipOrderDisclaimer } from '../order-management/components/order-mgmt-dialog/components';

import useStyles from './OrderDetailDialogCustomerBox.styles';

const onClickUserAddress = (lat, lng) => {
  window.open(`https://www.google.com/maps?q=${lat},${lng}`, '_blank');
};

function OrderDetailDialogCustomerBox(props) {
  const {
    alertMessage,
    billTypeLabel,
    client,
    emailLabel,
    highValueUserLabel,
    propioDetailText,
    propioLabel,
    showAddress,
    showAddressDescription,
    showBillAlert,
    showBillType,
    showEmail,
    showMapButton,
    showMapButtonText,
    showPhoneBtn,
    showPropioType,
    showTypeDescription,
    showTypeOwner,
    showZipCode,
    showNeighborhood,
    user = {},
    userTypeLabel,
    zipCodeLabel,
    neighborhoodLabel,
    newUserLabel,
    newUserDescription,
    showSupportWebChatButton,
    callUserDisclaimer,
    tryACallDisclaimer,
  } = props;

  const showHighValueUserType = user?.high_value_user ?? false;
  const showIsNewUser = user?.is_new_user ?? false;

  const [beingHoveredVIPChip, setBeingHoveredVIPChip] = useState(false);
  const [beingHoveredNewUserChip, setBeingHoveredNewUserChip] = useState(false);
  const [beingHoveredPropioChip, setBeingHoveredPropioChip] = useState(false);

  const {
    onOpenSupportWebChat,
    hasNoUserResponse,
    isOrderWaiting,
    isOrderTimingOut,
  } = useOrderManagement();

  const _classes = useStyles();

  const handleMouseOverOnVIPChip = useCallback(
    e => {
      e.preventDefault();
      setBeingHoveredVIPChip(true);
    },
    [setBeingHoveredVIPChip],
  );

  const handleMouseOverOnNewUserChip = useCallback(
    e => {
      e.preventDefault();
      setBeingHoveredNewUserChip(true);
    },
    [setBeingHoveredNewUserChip],
  );

  const handleMouseLeaveOnNewUserChip = useCallback(
    e => {
      e.preventDefault();
      setBeingHoveredNewUserChip(false);
    },
    [setBeingHoveredNewUserChip],
  );

  const handleMouseLeaveOnVIPChip = useCallback(
    e => {
      e.preventDefault();
      setBeingHoveredVIPChip(false);
    },
    [setBeingHoveredVIPChip],
  );

  const handleMouseOverOnPropioChip = useCallback(
    e => {
      e.preventDefault();
      setBeingHoveredPropioChip(true);
    },
    [setBeingHoveredPropioChip],
  );

  const handleMouseLeaveOnPropioChip = useCallback(
    e => {
      e.preventDefault();
      setBeingHoveredPropioChip(false);
    },
    [setBeingHoveredPropioChip],
  );

  return (
    <div className={_classes.root}>
      <div className={_classes.title}>{client}</div>
      <div className={_classes.box}>
        <div className={_classes.userInfoContainer}>
          <div className={_classes.userInfo}>
            <div className={_classes.userName}>
              <div>
                {user?.first_name}
                {user?.last_name}
              </div>
            </div>
            <div className={_classes.userPhone}>
              {!!showPhoneBtn ? <ShowPhoneButton /> : `Tel: ${user?.phone}`}
            </div>
          </div>
          {showBillAlert && (
            <CustomAlert
              classes={{ root: _classes.customAlertRoot }}
              type={'WARNING'}
              text={alertMessage}
            />
          )}
          {showEmail && (
            <div className={_classes.email}>
              {emailLabel} {user?.email}
            </div>
          )}
          {showBillType && (
            <>
              <div className={_classes.userInfoRuc}>
                {billTypeLabel} {user?.bill_type}
              </div>
              {showTypeOwner && (
                <div className={_classes.userInfoRuc}>
                  {user?.document_type_owner_label}:&ensp;
                  {user?.document_type_owner_value}
                </div>
              )}
              {showTypeDescription && (
                <div className={_classes.userInfoRuc}>
                  {user?.document_type_description_label}:&ensp;
                  {user?.document_type_description_value}
                </div>
              )}
            </>
          )}
          {showAddress && (
            <div className={_classes.addressContainer}>
              <div className={_classes.addressDetailContainer}>
                <div>{user?.address}</div>
                {showAddressDescription && (
                  <div>{user?.address_description}</div>
                )}
                {showZipCode && (
                  <div>{`${zipCodeLabel}: ${user?.zip_code}`}</div>
                )}
                {showNeighborhood && (
                  <div>{`${neighborhoodLabel}: ${user?.neighborhood}`}</div>
                )}
              </div>
              {showMapButton && (
                <CustomButton
                  classes={{
                    root: _classes.showMapButtonRoot,
                    submitButton: _classes.showMapButton,
                  }}
                  fullWidth={false}
                  onClick={() => {
                    onClickUserAddress(user?.address_lat, user?.address_lng);
                  }}
                >
                  {showMapButtonText}
                </CustomButton>
              )}
            </div>
          )}
          <div className={_classes.badgeContainer}>
            {showIsNewUser && (
              <div
                className={_classes.newValueChip}
                onMouseOver={handleMouseOverOnNewUserChip}
                onMouseLeave={handleMouseLeaveOnNewUserChip}
              >
                {newUserLabel}
              </div>
            )}
            {showHighValueUserType && (
              <div
                className={_classes.VIPTypeChip}
                onMouseOver={handleMouseOverOnVIPChip}
                onMouseLeave={handleMouseLeaveOnVIPChip}
              >
                {highValueUserLabel}
              </div>
            )}
            {showPropioType && (
              <div
                className={_classes.propioTypeChip}
                onMouseOver={handleMouseOverOnPropioChip}
                onMouseLeave={handleMouseLeaveOnPropioChip}
              >
                {propioLabel}
              </div>
            )}
          </div>
          {beingHoveredVIPChip && (
            <CustomAlert
              classes={{ root: _classes.userTypeInfoBox }}
              text={userTypeLabel}
            />
          )}
          {beingHoveredNewUserChip && (
            <CustomAlert
              classes={{ root: _classes.userTypeInfoBox }}
              text={newUserDescription}
            />
          )}
          {beingHoveredPropioChip && (
            <CustomAlert
              classes={{ root: _classes.userTypeInfoBox }}
              text={propioDetailText}
            />
          )}
          {hasNoUserResponse ||
            isOrderWaiting ||
            (isOrderTimingOut && (
              <ChipOrderDisclaimer
                styles={{
                  disclaimer: _classes.callUserDisclaimer,
                  icon: _classes.callUserDisclaimerIcon,
                  text: _classes.callUserDisclaimerText,
                }}
                type='warning'
                disclaimer={
                  isOrderTimingOut ? tryACallDisclaimer : callUserDisclaimer
                }
                hasIcon
              />
            ))}
          {showSupportWebChatButton && (
            <SupportWebChatButton onClick={() => onOpenSupportWebChat()} />
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderDetailDialogCustomerBox;
