import { useState } from 'react';

import { highDemandBtnService } from '../../../business/highDemand';

export function useHighDemand() {
  const [open, setOpen] = useState(false);

  const {
    isHidden,
    saturation,
    isSingleStore,
    everyIsActive,
  } = highDemandBtnService.get();

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return {
    open,
    isHidden,
    saturation,
    isSingleStore,
    handleOpen,
    handleClose,
    everyIsActive,
  };
}
