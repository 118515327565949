import { editTypeProps, isOpenByProps } from '../../../../analitycs/core';
import {
  OrderMgmtProduct,
  orderMgmtCurrentScreen,
  orderMgmtState,
  orderMgmtStatus,
} from '../../../../domain';

interface ReplaceProductDialogState {
  isOpen: boolean;
  productId: string;
}

interface UndoSuggestionDialogState {
  isOpen: boolean;
  productId: number;
  toppingId: number;
}

export interface OM_ModificationState {
  orderId: number;
  subtotal: number;
  isOpen: boolean;
  canSendChangesByTimeout: boolean;
  products: Array<OrderMgmtProduct>;
  currentScreen: orderMgmtCurrentScreen;
  status: orderMgmtStatus;
  errorCode: string;
  undoChangesDialogIsOpen: boolean;
  undoSuggestionDialog: UndoSuggestionDialogState;
  replaceProductDialog: ReplaceProductDialogState;
  isAcceptOrderDialogOpen: boolean;
  currentOrderState: orderMgmtState;
  isOpenBy: isOpenByProps | null;
  editType: editTypeProps | null;
  acceptRemoveChangesDialogIsOpen: boolean;
}

export const INITIAL_STATE: OM_ModificationState = {
  orderId: 0,
  products: [],
  currentScreen: orderMgmtCurrentScreen.Idle,
  subtotal: 0,
  status: orderMgmtStatus.Idle,
  errorCode: '',
  isOpen: false,
  canSendChangesByTimeout: true,
  undoChangesDialogIsOpen: false,
  undoSuggestionDialog: { isOpen: false, productId: 0, toppingId: 0 },
  replaceProductDialog: { isOpen: false, productId: '' },
  isAcceptOrderDialogOpen: false,
  currentOrderState: orderMgmtState.Idle,
  isOpenBy: null,
  editType: null,
  acceptRemoveChangesDialogIsOpen: false,
};
