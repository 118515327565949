// Audios
import offlineOrderAudio from '../assets/sounds/deactivated_alarm.mp3';
import newOrderAudio from '../assets/sounds/audio2.mp3';
import canceledOrderAudio from '../assets/sounds/the-calling.mp3';
import rtNotificationSound from '../assets/sounds/RtSound.mp3';
import orderMgmtNewOrderAudio from '../assets/sounds/rappitonecorto.mp3';

// Audio types.
const CANCELED_ORDER_AUDIO = 'CANCELED_ORDER_AUDIO';
const OFFLINE_AUDIO = 'OFFLINE_AUDIO';
const NEW_ORDER_AUDIO = 'NEW_ORDER_AUDIO';
const RT_NOTIFICATION_SOUND = 'RT_NOTIFICATION_SOUND';
const ORDER_MGMT_NEW_ORDER = 'ORDER_MGMT_NEW_ORDER';

/**
 * It is the audio instance.
 */
let audio;
/**
 *  Represents the audio currently being played.
 */
let audioBeingPlayed;

const getAudioBeingPlayed = () => {
  return audioBeingPlayed;
};

const playAudio = () => {
  try {
    audio.play();
  } catch (ex) {
    console.error(ex.message);
  }
};

const playOfflineAudio = () => {
  if (audio) {
    // If there is an audio being playing, pause it.
    audio.pause();
  }
  audioBeingPlayed = OFFLINE_AUDIO;
  audio = new Audio(offlineOrderAudio);
  audio.loop = true;
  playAudio();
};

const playCanceledOrderAudio = () => {
  if (audio) {
    // If there is an audio being playing, pause it.
    audio.pause();
  }
  audioBeingPlayed = CANCELED_ORDER_AUDIO;
  audio = new Audio(canceledOrderAudio);
  audio.loop = true;
  playAudio();
};

const playNewOrderAudio = () => {
  if (audio) {
    audio.pause();
  }
  audioBeingPlayed = NEW_ORDER_AUDIO;
  audio = new Audio(newOrderAudio);
  audio.loop = true;
  playAudio();
};

const playNotificationRTSound = () => {
  if (audio) {
    audio.pause;
  }

  audioBeingPlayed = RT_NOTIFICATION_SOUND;
  audio = new Audio(rtNotificationSound);
  audio.loop = false;
  playAudio();
};

const pauseAudio = () => {
  if (!audio) {
    return;
  }
  audioBeingPlayed = null;
  audio.pause();
  audio = null;
};

const playOrderMgmtNewOrderSound = () => {
  if (audio) {
    audio.pause;
  }

  audioBeingPlayed = ORDER_MGMT_NEW_ORDER;
  audio = new Audio(orderMgmtNewOrderAudio);
  audio.loop = false;
  playAudio();
};

export default {
  getAudioBeingPlayed,
  playCanceledOrderAudio,
  playNewOrderAudio,
  playNotificationRTSound,
  playOfflineAudio,
  pauseAudio,
  playOrderMgmtNewOrderSound,
};
