import { makeStyles } from '@material-ui/core';
import Colors from '../../../../constants/Colors';

export default makeStyles(() => ({
  supportWebChatButton: {
    alignItems: 'center',
    borderRadius: '0.5rem',
    cursor: 'pointer',
    display: 'flex',
    gap: '1rem',
    justifyContent: 'center',
    padding: '0.5rem 1rem',
    width: '100%',
    height: '100%',
    minHeight: '2.625rem',
    border: `1px solid ${Colors.PRIMARY_COLOR}`,
  },
  supportWebChatText: {
    color: Colors.PRIMARY_COLOR,
    fontSize: '0.875rem',
    fontWeight: 600,
    textAlign: 'center',
  },
}));
