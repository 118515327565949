// Material UI
import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
  },
}))
