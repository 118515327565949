import { makeStyles } from '@material-ui/core';

export default makeStyles({
  dialogPaper: {
    width: '488px',
  },
  timeSelectorsContainer: {
    display: 'flex',
    marginBottom: '24px',
    marginTop: '20px',
  },
  unitOfTimeSelect: {
    marginLeft: '10px',
  },
});
