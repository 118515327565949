import { clsx } from 'clsx';
import { Badge, IconButton } from '@material-ui/core';

import NotificationsIcon from '../../../icons/notifications-icon/NotificationsIcon';

import useStyles from './CustomBellButton.styles';

export function CustomBellButton({ badgeContent, onClick, classes = {} }) {
  const _classes = useStyles();
  return (
    <IconButton
      className={clsx(_classes.button, classes?.button)}
      onClick={onClick}
    >
      <Badge
        classes={{ badge: _classes.badge }}
        color='secondary'
        badgeContent={badgeContent}
      >
        <NotificationsIcon />
      </Badge>
    </IconButton>
  );
}
