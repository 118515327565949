import { Box, Typography } from '@material-ui/core';
import { StoreSaturationInfo } from '../../../store-saturation-info';

import makeClasses from './StoreStatusList.style';

export function StoreStatusList({
  title,
  stores,
  onClickStore,
  isActive = false,
  isInactive = false,
  isSuspended = false,
}) {
  const classes = makeClasses();

  return (
    <Box display='flex' flexDirection='column' gridGap='0.25rem'>
      <Typography className={classes.title}>{title}</Typography>
      {stores.map(store => (
        <StoreSaturationInfo
          key={`store-${store.store_id}`}
          storeName={store.name}
          variant={store.saturated}
          storeAddress={store.address}
          onClick={() => onClickStore(store.store_id)}
          isActiveStore={isActive}
          isInactive={isInactive}
          isSuspended={isSuspended}
          isTurbo={store.isTurbo}
        />
      ))}
    </Box>
  );
}
