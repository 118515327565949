import React from 'react';
import { OrderMgmtDialog } from './components/order-mgmt-dialog';
import { useOrderManagement, useOrderMgmtLang } from './hooks';
import {
  useOrderMgmtSearch,
  useOrderMgmtSearchLang,
} from './item-substitution/components/order-management-item-search/hooks';
import { OrderManagementItemSubstitution } from './item-substitution';
import { OrderMgmtReplaceProductDialog } from './components/order-mgmt-replace-product-dialog';
import {
  useOrderMgmtReplaceProductDialog,
  useOrderMgmtReplaceProductDialogLang,
} from './components/order-mgmt-replace-product-dialog/hooks';
import { OrderMgmtAcceptOrderDialog } from './components/order-mgmt-accept-order-dialog';
import {
  useOrderMgmtAcceptOrderDialog,
  useOrderMgmtAcceptOrderDialogLang,
} from './components/order-mgmt-accept-order-dialog/hooks';
import { OrderMgmtUndoChangesDialog } from './components/order-mgmt-undo-changes-dialog';
import {
  OrderMgmtUndoSuggestionDialog,
  useOrderMgmtUndoSuggestionDialog,
  useOrderMgmtUndoSuggestionDialogLang,
} from './components/order-mgmt-undo-suggestion-dialog';
import {
  useOrderMgmtUndoChangesDialog,
  useOrderMgmtUndoChangesDialogLang,
} from './components/order-mgmt-undo-changes-dialog/hooks';
import {
  useOrderMgmtAcceptRemoveChangesDialog,
  useOrderMgmtAcceptRemoveChangesDialogLang,
} from './components/order-mgmt-accept-remove-changes-dialog/hooks';
import { OrderMgmtAcceptRemoveChangesDialog } from './components/order-mgmt-accept-remove-changes-dialog';

export const OrderManagement = () => {
  const {
    isOpen: isOpenMainDialog,
    orderId,
    products,
    userName,
    isResumeScreen,
    handleContinueProductChanges,
    handleReplaceProductDialogClose,
    isDeactivatingItemsState,
    handleOrderOption,
    handleSendItemSubstitutions,
    handleConfirmUndoChanges,
    handleUndoChangesDialogClose,
    closeDialog,
    storeId,
    isSendingUserApproval,
    hasNoUserResponse,
    createdAt,
    isOrderEnabledToEdit,
    handleSelectProduct,
    handleSelectTopping,
    isOfferReplaceItemsEnabled,
    handleDeactivateProductsAndToppings,
    showSupportWebChatButton,
    handleOpenSupportWebChat,
    handleOpenCustomerPhoneSessionDialog,
    canSendChangesByTime,
    isOrderBeingCooked,
    deactivateProductIds,
    deactivateToppingIds,
    removedProducts,
    handleRemoveProductsFromOrder,
    startedAt,
  } = useOrderManagement();
  const {
    continueButtonText,
    soldOutProductText,
    infoUserDescriptionText,
    infoUserTitleText,
    optionsTexts,
    deactivatedItemsText,
    orderSummaryText,
    replaceDisclaimerText,
    callUserText,
    editCountdownText,
    startChatText,
    tooltipOptionDisclaimerText,
  } = useOrderMgmtLang({ orderId, user: userName });
  const {
    isOpen: isOpenSearchDialog,
    item,
    results,
    searchedQuery,
    suggestionItems,
    isLoading,
    isEmptyResults,
    isSearchRejected,
    isSuccessfulWithData,
    isCtaBtnDisabled,
    isSearcherDisabled,
    setSuggestionItems,
    handleClear,
    handleClose,
    handleChange,
    handleSelectPreviousQuery,
    handlePrevItem,
    handleNextItem,
    showBackButton,
    currentIndexItem,
    totalItemsToReplace,
    getRecentlySearchedQueries,
  } = useOrderMgmtSearch();
  const {
    searcherCtaText,
    searcherInputText,
    searcherTitleText,
    searcherDisclaimerText,
    searcherBackText,
    replaceWithText,
    chooseOptionsText,
    searcherDisclaimerCtaText,
  } = useOrderMgmtSearchLang({ item, user: userName });
  const {
    isOpen: isOpenReplaceProductDialog,
    replaceProductId,
  } = useOrderMgmtReplaceProductDialog();
  const {
    replaceProductCancelText,
    replaceProductContinueText,
    replaceProductDisclaimerText,
    replaceProductTitleText,
  } = useOrderMgmtReplaceProductDialogLang();
  const {
    isOpen: isOpenOrderMgmtAcceptOrderDialog,
    onClose: onCloseOrderMgmtAcceptOrderDialog,
    onAcceptOrder,
  } = useOrderMgmtAcceptOrderDialog();
  const {
    cancelButtonText,
    confirmButtonText,
    acceptOrderDescriptionText,
    acceptOrderDisclaimerText,
    acceptOrderTitleText,
  } = useOrderMgmtAcceptOrderDialogLang();
  const {
    isOpen: isOpenOrderMgmtUndoChangesDialog,
  } = useOrderMgmtUndoChangesDialog();
  const {
    undoChangesTitleText,
    undoChangesDescriptionText,
    undoChangesCancelText,
    undoChangesOkText,
    undoChangesDisclaimerTexts,
  } = useOrderMgmtUndoChangesDialogLang();
  const {
    productId,
    toppingId,
    isOpen: isOpenUndoSuggestionDialog,
    onClose: onCloseUndoSuggestionDialog,
    onConfirm: onConfirmUndoSuggestion,
  } = useOrderMgmtUndoSuggestionDialog();
  const {
    undoSuggestionTitleText,
    undoSuggestionDescriptionText,
    undoSuggestionOkText,
    undoSuggestionCancelText,
    undoSuggestionDisclaimerText,
  } = useOrderMgmtUndoSuggestionDialogLang(!!toppingId);
  const {
    isOpen: isOpenAcceptRemoveChangesDialog,
    onClose: onCloseAcceptRemoveChangesDialog,
  } = useOrderMgmtAcceptRemoveChangesDialog();
  const {
    acceptRemoveChangesTitleText,
    acceptRemoveChangesDescriptionText,
    acceptRemoveChangesCancelButtonText,
    acceptRemoveChangesConfirmButtonText,
  } = useOrderMgmtAcceptRemoveChangesDialogLang();

  return (
    <>
      {isOpenMainDialog && (
        <OrderMgmtDialog
          isOpen={isOpenMainDialog}
          orderId={orderId}
          products={products}
          closeDialog={closeDialog}
          continueButtonText={continueButtonText}
          soldOutProductText={soldOutProductText}
          infoUserDescriptionText={infoUserDescriptionText}
          infoUserTitleText={infoUserTitleText}
          optionsTexts={optionsTexts}
          deactivatedItemsText={deactivatedItemsText}
          callUserText={callUserText}
          startChatText={startChatText}
          userName={userName}
          isDeactivatingItemsState={isDeactivatingItemsState}
          handleOrderOption={handleOrderOption}
          isOrderEnabledToEdit={isOrderEnabledToEdit}
          handleSelectProduct={handleSelectProduct}
          handleSelectTopping={handleSelectTopping}
          isOfferReplaceItemsEnabled={isOfferReplaceItemsEnabled}
          handleDeactivateProductsAndToppings={
            handleDeactivateProductsAndToppings
          }
          showSupportWebChatButton={showSupportWebChatButton}
          handleOpenSupportWebChat={handleOpenSupportWebChat}
          handleOpenCustomerPhoneSessionDialog={
            handleOpenCustomerPhoneSessionDialog
          }
          canSendChangesByTime={canSendChangesByTime}
          isOrderBeingCooked={isOrderBeingCooked}
          tooltipOptionDisclaimerText={tooltipOptionDisclaimerText}
          deactivateProductIds={deactivateProductIds}
          deactivateToppingIds={deactivateToppingIds}
          startedAt={startedAt}
        />
      )}
      {isOpenSearchDialog && (
        <OrderManagementItemSubstitution
          searcherCtaText={searcherCtaText}
          searcherInputText={searcherInputText}
          searcherTitleText={searcherTitleText}
          searcherDisclaimerText={searcherDisclaimerText}
          searcherDisclaimerCtaText={searcherDisclaimerCtaText}
          searcherBackText={searcherBackText}
          soldOutProductText={soldOutProductText}
          replaceWithText={replaceWithText}
          chooseOptionsText={chooseOptionsText}
          orderSummaryText={orderSummaryText}
          replaceDisclaimerText={replaceDisclaimerText}
          editCountdownText={editCountdownText}
          isOpen={isOpenSearchDialog}
          item={item}
          results={results}
          searchedQuery={searchedQuery}
          getRecentlySearchedQueries={getRecentlySearchedQueries}
          suggestionItems={suggestionItems}
          isLoading={isLoading}
          isEmptyResults={isEmptyResults}
          isSearchRejected={isSearchRejected}
          isSuccessfulWithData={isSuccessfulWithData}
          isCtaBtnDisabled={isCtaBtnDisabled}
          isSearcherDisabled={isSearcherDisabled}
          setSuggestionItems={setSuggestionItems}
          handleClear={handleClear}
          handleClose={handleClose}
          handleChange={handleChange}
          handleSelectPreviousQuery={handleSelectPreviousQuery}
          products={products}
          isResumeScreen={isResumeScreen}
          handleNextItem={handleNextItem}
          handlePrevItem={handlePrevItem}
          handleSendItemSubstitutions={handleSendItemSubstitutions}
          showBackButton={showBackButton}
          currentIndexItem={currentIndexItem}
          totalItemsToReplace={totalItemsToReplace}
          isSendingUserApproval={isSendingUserApproval}
          hasNoUserResponse={hasNoUserResponse}
          handleOpenCustomerPhoneSessionDialog={
            handleOpenCustomerPhoneSessionDialog
          }
          canSendChangesByTime={canSendChangesByTime}
        />
      )}
      {isOpenReplaceProductDialog && (
        <OrderMgmtReplaceProductDialog
          isOpen={isOpenReplaceProductDialog}
          replaceProductCancelText={replaceProductCancelText}
          replaceProductContinueText={replaceProductContinueText}
          replaceProductDisclaimerText={replaceProductDisclaimerText}
          replaceProductTitleText={replaceProductTitleText}
          handleContinueProductChanges={handleContinueProductChanges}
          handleReplaceProductDialogClose={handleReplaceProductDialogClose}
          replaceProductId={replaceProductId}
        />
      )}
      {isOpenOrderMgmtAcceptOrderDialog && (
        <OrderMgmtAcceptOrderDialog
          products={products}
          isOpen={isOpenOrderMgmtAcceptOrderDialog}
          onClose={onCloseOrderMgmtAcceptOrderDialog}
          onAcceptOrder={onAcceptOrder}
          cancelButtonText={cancelButtonText}
          confirmButtonText={confirmButtonText}
          acceptOrderDescriptionText={acceptOrderDescriptionText}
          acceptOrderDisclaimerText={acceptOrderDisclaimerText}
          acceptOrderTitleText={acceptOrderTitleText}
        />
      )}
      {isOpenOrderMgmtUndoChangesDialog && (
        <OrderMgmtUndoChangesDialog
          isOpen={isOpenOrderMgmtUndoChangesDialog}
          undoChangesTitleText={undoChangesTitleText}
          undoChangesDescriptionText={undoChangesDescriptionText}
          undoChangesCancelText={undoChangesCancelText}
          undoChangesOkText={undoChangesOkText}
          undoChangesDisclaimerTexts={undoChangesDisclaimerTexts}
          handleConfirmUndoChanges={handleConfirmUndoChanges}
          handleUndoChangesDialogClose={handleUndoChangesDialogClose}
        />
      )}
      {isOpenUndoSuggestionDialog && (
        <OrderMgmtUndoSuggestionDialog
          orderId={orderId}
          storeId={storeId}
          productId={productId}
          toppingId={toppingId}
          isOpen={isOpenUndoSuggestionDialog}
          onClose={onCloseUndoSuggestionDialog}
          onConfirm={onConfirmUndoSuggestion}
          undoSuggestionTitleText={undoSuggestionTitleText}
          undoSuggestionDescriptionText={undoSuggestionDescriptionText}
          undoSuggestionOkText={undoSuggestionOkText}
          undoSuggestionCancelText={undoSuggestionCancelText}
          undoSuggestionDisclaimerText={undoSuggestionDisclaimerText}
        />
      )}
      {isOpenAcceptRemoveChangesDialog && (
        <OrderMgmtAcceptRemoveChangesDialog
          isOpen={isOpenAcceptRemoveChangesDialog}
          onClose={onCloseAcceptRemoveChangesDialog}
          onAccept={handleRemoveProductsFromOrder}
          removedProducts={removedProducts}
          acceptRemoveChangesTitleText={acceptRemoveChangesTitleText}
          acceptRemoveChangesDescriptionText={
            acceptRemoveChangesDescriptionText
          }
          acceptRemoveChangesCancelButtonText={
            acceptRemoveChangesCancelButtonText
          }
          acceptRemoveChangesConfirmButtonText={
            acceptRemoveChangesConfirmButtonText
          }
        />
      )}
    </>
  );
};
