import { createAsyncThunk } from '@reduxjs/toolkit';
import { OnUpdateHandoffDetail } from '../../../domain/contracts';
import { requestAdapter, responseAdapter } from '../../adapters';
import { onGetHandoffDetailRequest } from '../../core/requests';
import { checkoutRTRepository } from '../../repositories';
import { CHECKOUT_RT_SLICE_NAME } from './checkoutRT.const';

const update = createAsyncThunk(
  `${CHECKOUT_RT_SLICE_NAME}/updateHandoffDetail`,
  async (params: OnUpdateHandoffDetail, { rejectWithValue }) => {
    try {
      const payload = requestAdapter.toUpdateHandoffDetail(params);
      const response = await checkoutRTRepository.updateHandoff(payload);
      return response.data;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

const get = createAsyncThunk(
  `${CHECKOUT_RT_SLICE_NAME}/getHandoffDetail`,
  async (request: onGetHandoffDetailRequest, { rejectWithValue }) => {
    try {
      const response = await checkoutRTRepository.getHandoffDetail(request);
      return responseAdapter.toGetHandoffDetail(response.data);
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

export const checkoutRTThunks = {
  update,
  get,
} as const;
