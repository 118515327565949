import { useTLang } from '../../../../../intl/intl';

export function useOrderPrintingConfigLang() {
  return {
    title: useTLang({ id: 'settingsPage.printing.title' }),
    description: useTLang({ id: 'settingsPage.printing.description' }),
    opts: {
      auto: {
        title: useTLang({ id: 'settingsPage.printing.opts.auto.title' }),
        labels: {
          yes: useTLang({ id: 'settingsPage.printing.opts.auto.labels.yes' }),
          no: useTLang({ id: 'settingsPage.printing.opts.auto.labels.no' }),
        },
      },
      checklist: {
        title: useTLang({ id: 'settingsPage.printing.opts.checklist.title' }),
        labels: {
          yes: useTLang({
            id: 'settingsPage.printing.opts.checklist.labels.yes',
          }),
          no: useTLang({
            id: 'settingsPage.printing.opts.checklist.labels.no',
          }),
        },
      },
    },
  };
}
