import React from 'react';
import { Box, Typography } from '@material-ui/core';
import useListStyles from '../list.styles';
import { useKanbanLang } from '../hooks/useKanbanLang';

export function DataZero() {
  const styles = useListStyles();
  const {
    orderListDataZeroTitleText,
    orderListDataZeroDescriptionText,
  } = useKanbanLang();

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      textAlign='center'
      gridGap='1rem'
      width='100%'
      height='75vh'
    >
      <Typography className={styles.dataZeroTitle}>
        {orderListDataZeroTitleText}
      </Typography>
      <Typography
        className={styles.dataZeroDescription}
        dangerouslySetInnerHTML={{
          __html: orderListDataZeroDescriptionText || '',
        }}
      />
    </Box>
  );
}
