// Material UI
import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  customAlertRoot: {
    [theme.breakpoints.up('md')]: {
      gridArea: '1 / 1 / 2 / 3',
    },
  },
  emailTextFieldRoot: {},
  generalTextFieldsContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridRowGap: 24,
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridTemplateRows: 'repeat(2, 1fr)',
      gridColumnGap: 48,
    },
  },
  idTextFieldRoot: {},
  nameTextFieldRoot: {},
  root: {
    height: '100%',
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {
      padding: '0px 24px',
    },
  },
  storesContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridRowGap: 24,
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridColumnGap: 48,
    },
  },
}))
