import { makeStyles } from '@material-ui/core';

import Colors from '../../constants/Colors';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(() => ({
  ctBtn: ({ setBG }: { setBG: boolean }) => ({
    width: '100%',
    justifyContent: 'space-between',
    color: Colors.PRIMARY_COLOR,
    ...(setBG && { backgroundColor: neutral.D3 }),
  }),
  btnContent: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
    alignItems: 'center',
    textAlign: 'start',
  },
  startIcon: {
    color: Colors.PRIMARY_COLOR,
    fill: Colors.PRIMARY_COLOR,
  },
  ctText: {
    fontSize: '0.625rem',
    fontWeight: 600,
    lineHeight: 1.8,
  },
  captionText: {
    fontSize: '0.625rem',
    fontWeight: 600,
    lineHeight: 1.8,
    color: neutral.D20,
  },
}));
