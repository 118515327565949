import { useFormatMessage } from '../../../../../intl/intl';

export function useTimeoutDisclaimerLang({
  isCountdownComplete,
  showCountdownTime,
}: Props) {
  const disclaimerDetailText = useFormatMessage({
    id: 'orderManagement.expiredTimeText',
  });
  const rejectButtonText = useFormatMessage({
    id: 'orderManagement.rejectButtonText',
  });
  const countDownText = useFormatMessage({
    id: 'orderManagement.timeout.countdownDisclaimer',
  });
  const toppingDisclaimerText = useFormatMessage({
    id: 'orderManagement.timeout.toppingDisclaimer',
  });

  const getDisclaimerText = () => {
    if (showCountdownTime && !isCountdownComplete) {
      return countDownText;
    }
    return disclaimerDetailText;
  };
  const disclaimerBodyText = getDisclaimerText();
  return {
    disclaimerBodyText,
    toppingDisclaimerText,
    rejectButtonText,
  };
}

interface Props {
  isCountdownComplete: boolean;
  showCountdownTime: boolean;
}
