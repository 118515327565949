// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => ({
  filterTitle: {
    color: neutral.D20,
    fontFamily: 'Poppins',
    fontSize: 17,
    fontWeight: 'normal',
    lineHeight: 'normal',
    marginBottom: 24,
    marginTop: 0,
  },
  listTitle: {
    color: neutral.D15,
    fontFamily: 'Poppins',
    fontSize: 13,
    fontWeight: 'normal',
    lineHeight: 'normal',
    marginBottom: 4,
    marginTop: 24,
  },
  searchIcon: {
    color: neutral.D15,
  },
}));
