import { createSlice } from '@reduxjs/toolkit';
import { CHECKOUT_RT_SLICE_NAME, INIT_STATE } from './checkoutRT.const';
import { checkoutRTThunks } from './checkoutRT.thunk';

const slice = createSlice({
  name: CHECKOUT_RT_SLICE_NAME,
  initialState: INIT_STATE,
  reducers: {
    clear: () => ({ ...INIT_STATE }),
  },
  extraReducers(builder) {
    builder
      .addCase(checkoutRTThunks.update.pending, state => {
        state.status = 'isUpdateLoading';
      })
      .addCase(checkoutRTThunks.update.fulfilled, state => {
        state.status = 'updated';
      })
      .addCase(checkoutRTThunks.update.rejected, state => {
        state.status = 'updateDenied';
      })
      .addCase(checkoutRTThunks.get.pending, state => {
        state.status = 'isGetLoading';
      })
      .addCase(checkoutRTThunks.get.fulfilled, (state, { payload }) => {
        state.currentData = {
          hasDrinksOutside: payload.hasDrinksOutside,
          quantityOfPackages: payload.quantityOfPackages,
        };
        state.status = 'loaded';
      })
      .addCase(checkoutRTThunks.get.rejected, state => {
        state.status = 'loadDenied';
      });
  },
});

export const checkoutRTReducer = slice.reducer;

export const checkoutRTActions = {
  ...slice.actions,
  ...checkoutRTThunks,
};
