import Amplitude from '../../../../../tools/amplitude/amplitude';

import { EVENT_NAME_HAS_EXTENSION, EVENT_NAME } from './Analytics.const';

import type { AnalyticHasChromeExtensionProperties } from './Analytics.model';

const logEventChrome = params => Amplitude.logEvent(EVENT_NAME, params);

function logEventHasChromeExtension(
  params: AnalyticHasChromeExtensionProperties,
) {
  const { partnerId, isInstalled, pwaInstallStatus } = params;
  Amplitude.logEvent(EVENT_NAME_HAS_EXTENSION, {
    PARTNER_ID: partnerId,
    IS_INSTALLED: isInstalled,
    PWA_INSTALL_STATUS: pwaInstallStatus,
  });
}

export const AnalyticActions = {
  logEventChrome,
  logEventHasChromeExtension,
};
