export function SuccessIcon() {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='outline/check_circle'>
        <path
          id='stroke (Stroke)'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.77295 2.66691C5.82719 2.66691 3.43962 5.05448 3.43962 8.00024C3.43962 10.946 5.82719 13.3336 8.77295 13.3336C11.7187 13.3336 14.1063 10.946 14.1063 8.00024C14.1063 5.05448 11.7187 2.66691 8.77295 2.66691ZM1.77295 8.00024C1.77295 4.13401 4.90671 1.00024 8.77295 1.00024C12.6392 1.00024 15.7729 4.13401 15.7729 8.00024C15.7729 11.8665 12.6392 15.0002 8.77295 15.0002C4.90671 15.0002 1.77295 11.8665 1.77295 8.00024Z'
          fill='#29D884'
        />
        <path
          id='line (Stroke)'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.675 5.65743C12.0115 5.97144 12.0297 6.49876 11.7157 6.83524L8.60503 10.1686C8.44744 10.3374 8.22684 10.4333 7.99586 10.4334C7.76489 10.4334 7.54426 10.3375 7.38664 10.1687L5.83065 8.50204C5.51657 8.16562 5.53468 7.6383 5.8711 7.32422C6.20751 7.01014 6.73484 7.02825 7.04891 7.36467L7.99565 8.37874L10.4972 5.69813C10.8112 5.36165 11.3385 5.34343 11.675 5.65743Z'
          fill='#29D884'
        />
      </g>
    </svg>
  );
}
