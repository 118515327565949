import { useMemo } from 'react';

import { CustomStepper } from '../common/custom-stepper';

import { useFormatMessage } from '../../intl/intl';

import { textIds } from './consts';

import type { BaseProps } from '../../interfaces';

export function PMStepper({ classes, activeStep }: Props) {
  const phone = useFormatMessage(textIds.phone);
  const schedule = useFormatMessage(textIds.schedule);
  const steps = useMemo(() => {
    return [
      {
        index: 0,
        message: `${phone}`,
        name: `${phone}`,
      },
      {
        index: 1,
        message: `${schedule}`,
        name: `${schedule}`,
      },
    ];
  }, [phone, schedule]);
  return (
    <CustomStepper
      classes={classes}
      activeStep={activeStep}
      steps={steps}
      activeColor={'#29d884'}
    />
  );
}

interface Props extends BaseProps {
  activeStep: number;
}
