// Material UI
import { makeStyles } from '@material-ui/core';
// Constant
import Colors from '../../../constants/Colors';
import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: '328px',
    padding: '0px',
    width: '100%',
  },

  error: {
    borderLeft: `3px solid ${Colors.DANGER_FONT_COLOR}`,
  },
  info: {
    borderLeft: `3px solid ${Colors.INFO_FONT_COLOR}`,
  },
  NEW_UPDATE_ON_RELOAD: {
    borderLeft: `3px solid ${Colors.INFO_FONT_COLOR}`,
  },
  success: {
    borderLeft: `3px solid ${Colors.SUCCESS_FONT_COLOR}`,
  },
  warning: {
    borderLeft: `3px solid ${Colors.WARNING_FONT_COLOR}`,
  },
  multiline: {
    alignItems: 'flex-start',
    backgroundColor: neutral.white,
    boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.2)',
    color: neutral.D20,
  },
  root: {
    left: 16,
    right: 16,
    top: 100,
    '@media print': {
      display: 'none !important',
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Poppins',
    fontSize: 13,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    margin: '10px 0px 10px 14px',
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    padding: '0px 0px 6px 0px',
  },
  message: {
    fontSize: 11,
  },
  snackBarContentMessage: {
    padding: '0px',
    width: '100%',
  },
  icon: {
    fontSize: 40,
    margin: '7px 0px 7px 14px',
    minWidth: '54px',
  },
  newUpdateOnReloadIcon: {
    color: Colors.INFO_FONT_COLOR,
  },
  closeButtonRoot: {
    alignSelf: 'flex-start',
    padding: '10px',
  },
  closeIcon: {
    color: neutral.D15,
    fontSize: 13.8,
  },
  messageRoot: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  newUpdateOnReloadContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  newUpdateOnReloadDescription: {
    fontFamily: 'Poppins',
  },
  newUpdateOnReloadTitle: {
    fontFamily: 'Poppins Bold',
    fontSize: '16px',
  },
}));
