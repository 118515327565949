import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import MenuActions from './../../redux/actions/menu.actions';

import MenuCorridorsList from './MenuCorridorsList';

const mapStateToProps = ({
  menuReducer: {
    areCorridorsLoading: isLoading,
    displayOutOfStock,
    filter,
    menus,
    productsOutOfStock,
    selectedCorridorId,
    toppingsOutOfStock,
  },
}) => {
  const selectedStoreId = +filter.selectedStoreId;
  const corridors = menus?.[selectedStoreId]?.corridors ?? [];

  const outstockProducts = productsOutOfStock.length;
  const outstockToppings = toppingsOutOfStock.length;

  return {
    corridors,
    displayOutOfStock,
    isLoading,
    outstockProducts,
    outstockToppings,
    selectedCorridorId,
  };
};

const mapDispatchToProps = {
  onDisplayOutOfStock: MenuActions.displayOutOfStock,
  onSelectCorridor: MenuActions.selectCorridor,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(MenuCorridorsList),
);
