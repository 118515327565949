import React from 'react'
import { IExtendClosingHourCheckDate } from '../../types'
import { Box, Typography } from '@material-ui/core'
import useStyles from '../../ExtendClosingHoursDialog.styles'
import checkDate from '../../../../assets/images/icons/check_date.svg'
import hooks from '../../hooks'

export const ExtendClosingHourCheckDate: React.FC<IExtendClosingHourCheckDate> = () => {
  const componentClasses = useStyles()
  const { todayDay, todayDate } = hooks.useExtendSchedulesDates()

  return (
    <Box className={componentClasses.extendClosingHoursDate}>
      <img alt='check-date' src={checkDate} />
      <Typography className={componentClasses.checkDate}>
        <span>{todayDay}</span> {todayDate}
      </Typography>
    </Box>
  )
}
