const title = {
  id: 'phonePMFlowDialog.title',
}
const cancelButton = {
  id: 'phonePMFlowDialog.cancelButton',
}
const submitButton = {
  id: 'phonePMFlowDialog.submitButton',
}
const description = {
  id: 'phonePMFlowDialog.description',
}
const placeHolder = {
  id: 'phonePMFlowDialog.placeHolder',
}
const textIds = {
  title,
  submitButton,
  cancelButton,
  description,
  placeHolder,
}

export default textIds
