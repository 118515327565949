import { clsx } from 'clsx';
import { MenuItem, Select } from '@material-ui/core';

import useStyles from './styles';

import type { Props } from './interfaces';

export function CustomSelect(props: Props) {
  const { classes = {}, options = [], value = 0, onChange = () => {} } = props;
  const _classes = useStyles();
  return (
    <Select
      classes={{
        root: clsx(_classes.root, classes?.root),
        select: clsx(_classes.select, classes?.select),
        icon: clsx(_classes.icon, classes?.icon),
      }}
      onChange={onChange}
      value={value}
      disableUnderline
    >
      {options &&
        options.map((option, index) => (
          <MenuItem key={`select-${index}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </Select>
  );
}
