import React from 'react'
import { StoreStatusBadge } from '../store-status-badge'
import { IStoreStatusBadgeFilter } from '../types'

const StoreStatusBadgeFilter = ({
  badge,
  isFiltering,
  setFilter,
}: IStoreStatusBadgeFilter) => {
  const { status, quantity } = badge

  const handleOnClick = () => {
    setFilter(badge.status)
  }

  const handleDelete = () => {
    setFilter('')
  }

  const handleStoreStatusBadgeFilterActions = {
    onClick: handleOnClick,
    ...(isFiltering && { onDelete: handleDelete }),
  }

  return (
    <StoreStatusBadge
      size='medium'
      extraText={`(${quantity})`}
      {...{ status }}
      {...handleStoreStatusBadgeFilterActions}
    />
  )
}

export default StoreStatusBadgeFilter
