// External libs
import { createActions } from 'redux-actions';

const {
  openNewSpecialDayDialogAction,
  closeNewSpecialDayDialogAction,
} = createActions({
  OPEN_NEW_SPECIAL_DAY_DIALOG_ACTION: () => ({}),
  CLOSE_NEW_SPECIAL_DAY_DIALOG_ACTION: () => ({}),
});

const openNewSpecialDayDialog = () => dispatch => {
  dispatch(openNewSpecialDayDialogAction());
};
const closeNewSpecialDayDialog = () => dispatch => {
  dispatch(closeNewSpecialDayDialogAction());
};

export default {
  openNewSpecialDayDialog,
  openNewSpecialDayDialogAction,
  closeNewSpecialDayDialog,
  closeNewSpecialDayDialogAction,
};
