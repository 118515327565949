import { useFormatMessage } from '../../intl/intl';

export function useBlockNotifyMobileDevicesLang() {
  const titleId = 'blockNotifyMobileDevices.title';
  const paragraphIds = {
    1: 'blockNotifyMobileDevices.paragraph.first',
    2: 'blockNotifyMobileDevices.paragraph.second',
    3: 'blockNotifyMobileDevices.paragraph.third',
  };
  const linkId = {
    main: 'blockNotifyMobileDevices.linkAppAndroid.main',
    btn: 'blockNotifyMobileDevices.linkAppAndroid.btn',
  };

  return {
    title: useFormatMessage({ id: titleId }),
    paragraph: {
      first: useFormatMessage({ id: paragraphIds['1'] }),
      second: useFormatMessage({ id: paragraphIds['2'] }),
      third: useFormatMessage({ id: paragraphIds['3'] }),
    },
    linkAppAndroid: {
      main: useFormatMessage({ id: linkId.main }),
      btn: useFormatMessage({ id: linkId.btn }),
    },
  };
}
