import { makeStyles } from '@material-ui/styles'
// Constant
import Colors from '../../constants/Colors'

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 40,
    minHeight: 40,
    backgroundColor: Colors.WHITE,
  },
}))
