import React from 'react';
import { Box, Button, useMediaQuery, useTheme } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { OrderManagementItemSearch } from './components';
import OrderMgmgtItemSubstitutonStyles from './OrderManagmentItemSubstitution.style';
import {
  ItemSuggestion,
  OrderMgmtItem,
  OrderMgmtProduct,
} from '../../../business/order-mgmt';
import CustomDialog from '../../common/custom-dialog/CustomDialog';
import { OrderMgmtResume } from '../components/order-mgmt-resume/OrderMgmtResume';
import { CountdownToEdit } from '../components/countdown-edit';

export function OrderManagementItemSubstitution({
  searcherCtaText,
  searcherInputText,
  searcherTitleText,
  searcherDisclaimerText,
  searcherBackText,
  soldOutProductText,
  replaceWithText,
  chooseOptionsText,
  orderSummaryText,
  replaceDisclaimerText,
  searcherDisclaimerCtaText,
  editCountdownText,
  isOpen,
  item,
  results,
  searchedQuery,
  getRecentlySearchedQueries,
  suggestionItems,
  isLoading,
  isEmptyResults,
  isSearchRejected,
  isSuccessfulWithData,
  isCtaBtnDisabled,
  isSearcherDisabled,
  setSuggestionItems,
  handleClear,
  handleClose,
  handleChange,
  handleSelectPreviousQuery,
  products,
  isResumeScreen,
  handlePrevItem,
  handleNextItem,
  handleSendItemSubstitutions,
  showBackButton,
  currentIndexItem,
  totalItemsToReplace,
  isSendingUserApproval,
  hasNoUserResponse,
  handleOpenCustomerPhoneSessionDialog,
  canSendChangesByTime,
}: Props) {
  const ARIA_LABEL_BASE = 'OM search replacement items';
  const theme = useTheme<any>();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const styles = OrderMgmgtItemSubstitutonStyles();
  const countdownTitle = isSendingUserApproval ? editCountdownText : '';
  const countdownDescription = hasNoUserResponse ? searcherDisclaimerText : '';
  const disclaimerCta = hasNoUserResponse
    ? {
        label: searcherDisclaimerCtaText,
        action: handleOpenCustomerPhoneSessionDialog,
      }
    : null;

  return (
    <CustomDialog
      open={isOpen}
      onClose={handleClose}
      showBackButton={!isMdUp}
      showCloseButton={isMdUp}
      classes={{ paper: styles.dialog }}
      aria-label={ARIA_LABEL_BASE}
      title={searcherTitleText}
    >
      <Box padding='0 1.5rem'>
        <CountdownToEdit
          title={countdownTitle}
          description={countdownDescription}
          cta={disclaimerCta}
        />
        {isResumeScreen ? (
          <OrderMgmtResume
            orderSummaryText={orderSummaryText}
            soldOutProductText={soldOutProductText}
            products={products}
            replaceDisclaimerText={replaceDisclaimerText}
          />
        ) : (
          <OrderManagementItemSearch
            searcherInputText={searcherInputText}
            searcherDisclaimerText={searcherDisclaimerText}
            soldOutProductText={soldOutProductText}
            replaceWithText={replaceWithText}
            searcherDisclaimerCtaText={searcherDisclaimerCtaText}
            chooseOptionsText={chooseOptionsText}
            editCountdownText={editCountdownText}
            item={item}
            results={results}
            searchedQuery={searchedQuery}
            getRecentlySearchedQueries={getRecentlySearchedQueries}
            suggestionItems={suggestionItems}
            isLoading={isLoading}
            isEmptyResults={isEmptyResults}
            isSearchRejected={isSearchRejected}
            isSuccessfulWithData={isSuccessfulWithData}
            isSearcherDisabled={isSearcherDisabled}
            setSuggestionItems={setSuggestionItems}
            handleClear={handleClear}
            handleChange={handleChange}
            handleSelectPreviousQuery={handleSelectPreviousQuery}
            handlePrevItem={handlePrevItem}
            showBackButton={showBackButton}
            currentIndexItem={currentIndexItem}
            totalItemsToReplace={totalItemsToReplace}
          />
        )}
        <DialogActions aria-label={`${ARIA_LABEL_BASE} - actions`}>
          {isResumeScreen ? (
            <>
              <Button
                color='primary'
                onClick={handlePrevItem}
                classes={{ text: styles.actionBtn }}
                disabled={!canSendChangesByTime}
              >
                {searcherBackText}
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={handleSendItemSubstitutions}
                classes={{ containedPrimary: styles.actionBtn }}
                disabled={!canSendChangesByTime}
              >
                {searcherCtaText}
              </Button>
            </>
          ) : (
            <Button
              variant='contained'
              color='primary'
              onClick={handleNextItem}
              disabled={isCtaBtnDisabled || !canSendChangesByTime}
              classes={{ containedPrimary: styles.actionBtn }}
            >
              {searcherCtaText}
            </Button>
          )}
        </DialogActions>
      </Box>
    </CustomDialog>
  );
}

interface Props {
  searcherCtaText: string;
  searcherInputText: string;
  searcherTitleText: string;
  searcherDisclaimerText: string;
  searcherBackText: string;
  soldOutProductText: string;
  replaceWithText: string;
  chooseOptionsText: string;
  orderSummaryText: string;
  replaceDisclaimerText: string;
  searcherDisclaimerCtaText: string;
  editCountdownText: string;
  isOpen: boolean;
  item: ItemSuggestion;
  results: OrderMgmtItem[];
  searchedQuery: string;
  getRecentlySearchedQueries: any;
  suggestionItems: OrderMgmtItem[];
  isLoading: boolean;
  isEmptyResults: boolean;
  isSearchRejected: boolean;
  isSuccessfulWithData: boolean;
  isCtaBtnDisabled: boolean;
  isSearcherDisabled: boolean;
  setSuggestionItems: any;
  handleClear: any;
  handleClose: any;
  handleChange: any;
  handleSelectPreviousQuery: any;
  products: Array<OrderMgmtProduct>;
  isResumeScreen: boolean;
  handlePrevItem: any;
  handleNextItem: any;
  handleSendItemSubstitutions: any;
  showBackButton: boolean;
  currentIndexItem: number;
  totalItemsToReplace: number;
  isSendingUserApproval: boolean;
  hasNoUserResponse: boolean;
  handleOpenCustomerPhoneSessionDialog: any;
  canSendChangesByTime: boolean;
}
