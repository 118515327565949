import { useMemo } from 'react';

import { CustomBaseAlertSnackbar } from '../components/custom-base-alert-snackbar';
import { CustomWarningAlertSnackbar } from '../components/custom-warning-alert-snackbar';

import { Serverities } from '../../../../constants/Severities';

const useAlertSnackbar = severity =>
  useMemo(() => {
    switch (severity) {
      case Serverities.ERROR:
        return CustomWarningAlertSnackbar;
      case Serverities.INFO:
        return CustomWarningAlertSnackbar;
      case Serverities.SUCCESS:
        return CustomWarningAlertSnackbar;
      case Serverities.WARNING:
        return CustomWarningAlertSnackbar;
      default:
        return CustomBaseAlertSnackbar;
    }
  }, [severity]);

const Hooks = { useAlertSnackbar };

export default Hooks;
