import { CARGO_PATH } from '../core/consts';
import http from '../../../../services/HttpService';
import UrlUtils from '../../../../utils/url-utils/UrlUtils';
import LocalStorageService from '../../../../services/local-storage-service';

import type { AxiosResponse } from 'axios';
import type {
  CancelCargoOrderRequest,
  CancelOrderCostRequest,
  CoverageValidateRequest,
  OrdersListRequest,
  RTCargoRequest,
} from '../core/requests';
import type {
  CargoOrdersResponse,
  CoverageValidateResponse,
  RTCargoResponse,
  CargoStoreListResponse,
} from '../core/responses';

const _baseUrl = LocalStorageService.getBaseUrl();

const _errorHandler = (err: any) => {
  return Promise.reject(err?.message);
};

const getCountryCode = () =>
  LocalStorageService.getCountryCode()?.toLocaleLowerCase() || '';

const getCargoStores = (
  payload: Array<number>,
): Promise<AxiosResponse<CargoStoreListResponse>> => {
  return http.put(`${_baseUrl}${CARGO_PATH.HAS_CARGO}`, payload);
};

const validateCoverage = (
  payload: CoverageValidateRequest,
): Promise<AxiosResponse<CoverageValidateResponse>> => {
  return http
    .put(`${_baseUrl}${CARGO_PATH.VALIDATE_COVERAGE}`, payload)
    .catch(err => _errorHandler(err));
};

const createOrder = (
  payload: RTCargoRequest,
): Promise<AxiosResponse<RTCargoResponse>> => {
  return http
    .put(`${_baseUrl}${CARGO_PATH.CREATE_ORDER}`, payload)
    .catch(err => _errorHandler(err));
};

const getOrderList = (
  payload: OrdersListRequest,
): Promise<AxiosResponse<CargoOrdersResponse>> => {
  return http.post(`${_baseUrl}${CARGO_PATH.ORDER_LIST}`, payload);
};

const requestCancelCost = ({
  rappi_order_id,
}: CancelOrderCostRequest): Promise<AxiosResponse<object>> => {
  const url = UrlUtils.replaceUrlParams(CARGO_PATH.ORDER_CANCEL_COST, {
    orderId: rappi_order_id,
  });
  return http.get(`${_baseUrl}${url}`);
};

const cancelOrder = ({
  rappi_order_id,
}: CancelCargoOrderRequest): Promise<AxiosResponse<boolean>> => {
  const url = UrlUtils.replaceUrlParams(CARGO_PATH.CANCEL_ORDER, {
    orderId: rappi_order_id,
  });
  return http.delete(`${_baseUrl}${url}`);
};

export const CargoRepository = {
  getCountryCode,
  getCargoStores,
  validateCoverage,
  createOrder,
  getOrderList,
  requestCancelCost,
  cancelOrder,
} as const;
