import { makeStyles } from '@material-ui/core';

export default makeStyles({
  badge: {
    fontSize: '0.625rem',
    height: '0.875rem',
    minWidth: '0.875rem',
    position: 'absolute',
    right: '0.75rem',
    top: '0.625rem',
    width: '0.875rem',
  },
  button: {
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});
