const title = {
  id: 'checkErrorServiceDialog.title',
}
const description = {
  id: 'checkErrorServiceDialog.description',
}
const accept = {
  id: 'checkNetworkDialog.buttonText',
}

const textIds = {
  description,
  title,
  accept,
}

export default textIds
