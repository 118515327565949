// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import Colors from '../../constants/Colors';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {
    color: neutral.D15,
  },
  iconRoot: {
    color: Colors.SECONDARY_COLOR,
  },
}));
