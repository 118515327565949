// React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Constants
import { StoreSchedulesType } from '../../constants/StoreScheduleType'
// Redux
import storesSchedulesActions from '../../redux/actions/storesSchedules.actions'
// Components
import deleteSpecialDayDialogActions from '../../redux/actions/deleteSpecialDayDialog.actions'
import SpecialDayStoreScheduleTable from './SpecialDayStoreScheduleTable'

const mapStateToProps = (
  {
    partnerReducer: { profile },
    appReducer: { language },
    storesSchedulesReducer: {
      areStoresSchedulesLoading: isLoading,
      filter: { selectedStoreId },
      storesSchedules,
    },
  },
  { intl: { formatMessage } },
) => {
  const day = formatMessage({
    id: 'newSpecialStoreScheduleTable.day',
  })
  const open = formatMessage({
    id: 'storesSchedulePage.open',
  })
  const close = formatMessage({
    id: 'storesSchedulePage.close',
  })
  const partnerId = profile.id
  const storeSchedules = storesSchedules[selectedStoreId]

  const schedules =
    (storeSchedules && storeSchedules.schedules[StoreSchedulesType.SPECIALS]) ||
    []

  const showAddButton =
    schedules.length < +process.env.REACT_APP_MAX_SPECIAL_DAYS

  return {
    day,
    open,
    close,
    isLoading,
    language,
    partnerId,
    schedules,
    showAddButton,
  }
}

const mapDispatchToProps = {
  onClickAddSchedule: storesSchedulesActions.addSpecialDaySchedule,
  onClickDeleteSchedule: storesSchedulesActions.deleteSpecialSchedule,
  onClickDeleteDay: deleteSpecialDayDialogActions.openDeleteSpecialDayDialog,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SpecialDayStoreScheduleTable),
)
