// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  addIntervalButtonRoot: {
    padding: '5px 0px',
    width: 'auto',
    fontFamily: 'Poppins Bold',
    fontSize: '13px !important',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
  },
  customTimePickerInputPropsInput: {
    fontSize: 13,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    minHeight: 18,
    padding: '4px 0px 4px 10px',
  },
  customTimePickerInputPropsRoot: {
    paddingRight: 0,
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
    },
    '& .MuiIconButton-root': {
      padding: 5,
    },
    '& .MuiSvgIcon-root': {
      height: 12,
      width: 12,
    },
  },
  customTimePickerInputPropsNotchedOutline: {
    border: `solid 1px ${neutral.D10}`,
    borderRadius: 0,
  },
  customTimePickerIntervalRoot: {
    gridColumnGap: 5,
    [theme.breakpoints.up('md')]: {
      gridColumnGap: 12,
    },
  },
  deleteButtonIcon: {
    color: neutral.D15,
  },
  interalsActionsContainer: {
    display: 'grid',
    gridAutoRows: 'min-content',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, min-content)',
      justifyContent: 'space-between',
    },
  },
  intervalsContainer: {
    display: 'grid',
    gridAutoRows: 'min-content',
    gridRowGap: 10,
    justifyItems: 'start',
  },
  customTimePickerIntervalMinusButtonButton: {
    color: neutral.white,
    padding: 5,
  },
  customTimePickerIntervalMinusButtonIcon: {
    backgroundColor: neutral.D15,
    color: neutral.white,
    width: '14px',
    height: '14px',
  },
  deleteButtonButton: {
    padding: '5px 0px',
  },
  root: {
    alignItems: 'center',
    display: 'grid',
    gridAutoRows: 'min-content',
    gridTemplateColumns: 'auto min-content',
    [theme.breakpoints.up('sm')]: {
      gridColumnGap: 90,
      gridTemplateColumns: 'min-content auto',
      justifyContent: 'space-between',
    },
  },
}));
