import { clsx } from 'clsx';
import { useEffect, useState } from 'react';

import { CustomCancelButton } from '../buttons/custom-cancel-button';
import { CustomCheckCircleButton } from '../buttons/custom-check-circle-button';
import { CustomEditButton } from '../buttons/custom-edit-button';
import { CustomMinusButton } from '../buttons/custom-minus-button';
import CustomReadyOnlyHourInterval from '../custom-read-only-hour-interval';
import { CustomTimePicker } from '../custom-time-picker';

import DateTimeUtils from '../../../utils/DateTimeUtils';
import useStyles from './CustomTimePickerInterval.styles';

export function CustomTimePickerInterval(props) {
  const {
    onClickConfirm,
    onClickDeleteSchedule,
    onClickEditingScheduleCancel,
    onClickEditSchedule,
    beingEdited = false,
    endsTime = new Date(),
    startsTime = new Date(),
    classes = {},
  } = props;

  const _classes = useStyles();
  // eslint-disable-next-line
  const [editedEndsTime, setEditedEndsTime] = useState(endsTime);
  // eslint-disable-next-line
  const [editedStartsTime, setEditedStartsTime] = useState(startsTime);

  const cleanStates = () => {
    setEditedEndsTime(endsTime);
    setEditedStartsTime(startsTime);
  };

  useEffect(() => {
    cleanStates();
  }, [beingEdited, endsTime, startsTime]);

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      {!beingEdited && (
        <div
          className={clsx(
            _classes.notBeingEditedContainer,
            classes?.notBeingEditedContainer,
          )}
        >
          <CustomReadyOnlyHourInterval close={endsTime} open={startsTime} />
          <CustomEditButton
            classes={{
              button: clsx(
                _classes.editButtonButton,
                classes?.editButtonButton,
              ),
              icon: clsx(_classes.editButtonIcon, classes?.editButtonIcon),
            }}
            onClick={onClickEditSchedule}
          />
          <CustomMinusButton
            classes={{
              button: clsx(classes?.minusButtonButton),
              icon: clsx(classes.minusButtonIcon),
            }}
            onClick={onClickDeleteSchedule}
          />
        </div>
      )}
      {beingEdited && (
        <div
          className={clsx(
            _classes.beingEditedContainer,
            classes?.beingEditedContainer,
          )}
        >
          <div
            className={clsx(
              _classes.pickersContainer,
              classes?.pickersContainer,
            )}
          >
            <CustomTimePicker
              classes={{
                inputPropsInput: clsx(classes?.customTimePickerInputPropsInput),
                inputPropsNotchedOutline: clsx(
                  classes?.customTimePickerInputPropsNotchedOutline,
                ),
                inputPropsRoot: clsx(classes.customTimePickerInputPropsRoot),
              }}
              onChange={newDate => {
                const hourMinutes = DateTimeUtils.getHourMinutes(newDate);
                setEditedStartsTime(hourMinutes);
              }}
              value={new Date(`2020-01-05T${editedStartsTime}`)}
            />
            <CustomTimePicker
              classes={{
                inputPropsInput: clsx(classes?.customTimePickerInputPropsInput),
                inputPropsNotchedOutline: clsx(
                  classes?.customTimePickerInputPropsNotchedOutline,
                ),
                inputPropsRoot: clsx(classes?.customTimePickerInputPropsRoot),
              }}
              onChange={newDate => {
                const hourMinutes = DateTimeUtils.getHourMinutes(newDate);
                setEditedEndsTime(hourMinutes);
              }}
              value={new Date(`2020-01-05T${editedEndsTime}`)}
            />
          </div>

          <CustomCheckCircleButton
            classes={{
              button: clsx(
                _classes.checkButtonButton,
                classes?.checkButtonButton,
              ),
              icon: clsx(_classes.checkButtonIcon, classes?.checkButtonIcon),
            }}
            color='primary'
            onClick={() => onClickConfirm(editedEndsTime, editedStartsTime)}
          />
          <CustomCancelButton
            classes={{
              button: clsx(
                _classes.cancelButtonButton,
                classes?.cancelButtonButton,
              ),
              icon: clsx(_classes.cancelButtonIcon, classes?.cancelButtonIcon),
            }}
            color='secondary'
            onClick={() => {
              onClickEditingScheduleCancel();
              cleanStates();
            }}
          />
        </div>
      )}
    </div>
  );
}
