// External libs
import { createSelector } from 'reselect'
// Selectors
import OrdersSelectors from '../../../redux/selectors/orders-selectors'

const selectShowMarketplaceBox = createSelector(
  OrdersSelectors.selectIsMarketplaceInOrderDetailDialog,
  isMarketplace => {
    return isMarketplace
  },
)

const selectShowPickupBox = createSelector(
  OrdersSelectors.selectIsPickupInOrderDetailDialog,
  isPickup => {
    return isPickup
  },
)

const selectDeliveryMethod = createSelector(
  OrdersSelectors.selectOrderDeliveryMethodInOrderDetailDialog,
  deliveryMethod => {
    return deliveryMethod
  },
)

const selectTotalCharge = createSelector(
  OrdersSelectors.selectOrderDetailTotalChargeInOrderDetailDialog,
  totalCharge => {
    return totalCharge
  },
)

const Selectors = {
  selectShowMarketplaceBox,
  selectShowPickupBox,
  selectDeliveryMethod,
  selectTotalCharge,
}

export default Selectors
