// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateAreas: `"label"
                         "children"
                         "options"`,
    gridTemplateRows: 'min-content min-content auto',
    height: '100%',
    padding: '0px 16px',
    [theme.breakpoints.up('md')]: {
      padding: '0px 24px',
    },
  },
  arrow: {},
  children: {
    gridArea: 'children',
  },
  label: {
    color: neutral.D20,
    fontSize: 16,
    gridArea: 'label',
    lineHeight: 'normal',
    padding: '40px 0px',
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: 17,
    paddingBottom: 12,
  },
  options: {
    display: 'grid',
    gridArea: 'options',
    gridAutoFlow: 'row',
    gridAutoRows: 'min-content',
    gridRowGap: 24,
    overflowY: 'auto',
  },
}));
