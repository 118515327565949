import makeClasses from './SwitchModeIOS.style';

export function SwitchModeIOS(props: Props) {
  const { id, name, checked, onCheck } = props;

  const _classes = makeClasses({ checked });

  return (
    <div className={_classes.container}>
      <input
        id={id}
        name={name}
        type='checkbox'
        checked={checked}
        onChange={event => onCheck?.(event.target.checked)}
        className={_classes.checked}
      />
      <label htmlFor={id} className={_classes.label} />
    </div>
  );
}

interface Props {
  id: string;
  name?: string;
  checked?: boolean;
  onCheck?: (checked: boolean) => void;
}
