import { clsx } from 'clsx';
import { Typography as MuiTypography } from '@material-ui/core';

import { FontWeight, muiVariantsMap, Variants } from './consts';

import useStyles from './styles';

import type { Props } from './interfaces';

function Typography({
  classes,
  children,
  fontWeight = FontWeight.Regular,
  variant = Variants.Body,
  component,
}: Props) {
  const _classes = useStyles({ fontWeight });
  return (
    <MuiTypography
      classes={{ root: clsx(_classes.root, classes?.root) }}
      variant={muiVariantsMap[variant]}
      component={component}
    >
      {children}
    </MuiTypography>
  );
}

export default Typography;
