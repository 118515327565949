import http from './HttpService';
import urls from '../constants/Urls';
import UrlUtils from '../utils/url-utils/UrlUtils';
import LocalStorageService from './local-storage-service';
import TimeFormatUtilities from '../utils/TimeFormatUtilities';

const getNotifications = (page, perPage) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  let urlReplaced = UrlUtils.replaceUrlParams(urls.NOTIFICATIONS, {
    page,
    perPage,
  });
  return http.get(`${microserviceUrl}${urlReplaced}`);
};

// not used
// const getNotificationsUnseen = () => {
//   const microserviceUrl = LocalStorageService.getBaseUrl();
//   return http.get(`${microserviceUrl}${urls.NOTIFICATIONS_UNSEEN}`);
// };

const getLastNotification = () => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  return http.get(`${microserviceUrl}${urls.LAST_NOTIFICATION}`);
};

const notificationPutSeen = (id, lastSeenCreatedAtDate) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  let urlReplaced = UrlUtils.replaceUrlParams(urls.NOTIFICATIONS_PUT_SEEN, {
    notificationId: id,
  });
  const formattedNow = TimeFormatUtilities.formatCurrentDateTime();
  const formattedLastSeenCreatedAtDate = TimeFormatUtilities.formatDateTime(
    lastSeenCreatedAtDate,
  );
  const payload = {
    seen_at: formattedNow,
    last_seen_created_at: formattedLastSeenCreatedAtDate,
  };
  return http.put(`${microserviceUrl}${urlReplaced}`, payload);
};

const notificationPutSeenAllActions = lastSeenCreatedAtDate => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const formattedNow = TimeFormatUtilities.formatCurrentDateTime();
  const formattedLastSeenCreatedAtDate = TimeFormatUtilities.formatDateTime(
    lastSeenCreatedAtDate,
  );
  const payload = {
    seen_at: formattedNow,
    last_seen_created_at: formattedLastSeenCreatedAtDate,
  };
  return http.put(
    `${microserviceUrl}${urls.NOTIFICATIONS_PUT_SEEN_ALL_ACTIONS}`,
    payload,
  );
};

const notificationPutSeenAllNotifications = lastSeenCreatedAtDate => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const formattedNow = TimeFormatUtilities.formatCurrentDateTime();
  const formattedLastSeenCreatedAtDate = TimeFormatUtilities.formatDateTime(
    lastSeenCreatedAtDate,
  );
  const payload = {
    seen_at: formattedNow,
    last_seen_created_at: formattedLastSeenCreatedAtDate,
  };
  return http.put(
    `${microserviceUrl}${urls.NOTIFICATIONS_PUT_SEEN_ALL_NOTIFICATIONS}`,
    payload,
  );
};

export default {
  getNotifications,
  // getNotificationsUnseen,
  getLastNotification,
  notificationPutSeen,
  notificationPutSeenAllActions,
  notificationPutSeenAllNotifications,
};
