import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import OrderDetailDialog from './OrderDetailDialog';

import orderActions from '../../redux/actions/orders-actions';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';
import { ordersWithSameBundleIdAndStore } from '../../shared/utils/orders';

const mapStateToProps = (
  {
    orderDetailDialogReducer: {
      error: { is: isError },
      isLoading,
      open,
      orderId,
    },
    ordersReducer: { orders },
  },
  { intl: { formatMessage } },
) => {
  const title = formatMessage(
    { id: 'orderDetailDialog.title' },
    { id: orderId },
  );

  const order = orders.find(item => item.id === orderId) ?? null;
  const ordersSameBundle = ordersWithSameBundleIdAndStore({
    order,
    allOrders: orders,
  }).map(({ id, store_id, bundle_id }) => ({ id, bundle_id, store_id }));

  const showDetailBody = !isError && !isLoading;

  const showError = isError && !isLoading;

  return {
    isError,
    isLoading,
    open,
    totalProducts: order?.detail?.products?.length,
    showDetailBody,
    showError,
    title,
    orderId,
    ordersSameBundle,
  };
};

const mapDispatchToProps = {
  onClickBackButton: dialogsManagerActions.close,
  onClose: dialogsManagerActions.close,
  onPrint: orderActions.sendToPrint,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(OrderDetailDialog),
);
