// Material UI
import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  root: {
    width: 500,
    height: 'auto',
  },
  container: {
    padding: 16,
    fontSize: 16,
    display: 'grid',
    [theme.breakpoints.up('md')]: {
      padding: 20,
    },
  },
  title: {
    fontSize: 18,
  },
  titleBox: {
    padding: 20,
  },
  contentBox: {
    width: '100%',
    height: 'auto',
    marginTop: 10,
    marginBottom: 10,
  },
}))
