import { makeStyles } from '@material-ui/styles';
// Constant
import Colors from '../../../constants/Colors';
import { neutral } from '../../../shared/styles/colors';

export default makeStyles({
  root: {
    width: '100%',
    fontFamily: 'Poppins',
    fontSize: '12px',
    display: 'flex',
    padding: '14px 16px 14px 14px',
    borderBottom: `1px solid ${neutral.D10}`,
    textTransform: 'none',
    justifyContent: 'start',
    alignItems: 'end',
    textAlign: 'left',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
  },
  label: {
    justifyContent: 'baseline',
  },
  noSeen: {
    border: `5px solid ${Colors.SECONDARY_COLOR}`,
    borderRadius: '50px',
    margin: '5px 10px',
  },
  seen: {
    border: `5px solid ${neutral.D10}`,
    borderRadius: '50px',
    margin: '5px 10px',
  },
  since: {
    color: neutral.D15,
    textAlign: 'initial',
    marginTop: 4,
  },
  text: {},
  title: {
    fontSize: '15px',
    fontWeight: 'bold',
  },
  link: {},
  containerLinks: {
    display: 'flex',
    flexFlow: 'row wrap',
    gap: '18px',
  },
});
