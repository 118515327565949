import { makeStyles } from '@material-ui/core';
import { neutral } from '../../../../../../shared/styles/colors';

export default makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  main: {
    display: 'flex',
    flex: 1,
  },
  title: {
    color: neutral.D20,
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: 600,
    marginBottom: '0.25rem',
  },
  subTitle: {
    color: neutral.D18,
    textAlign: 'center',
    fontSize: '0.875rem',
    fontWeight: 400,
    marginBottom: '1rem',
  },
  cta: {
    width: '11.5rem',
  },
  loader: {
    marginLeft: '0.5rem',
  },
});
