// Consts
import MuiVariants from './MuiVariants'
import Variants from './variants'

const muiVariantsMap = {
  [Variants.H1]: MuiVariants.H1,
  [Variants.H2]: MuiVariants.H2,
  [Variants.Headline]: MuiVariants.H3,
  [Variants.Subheadline]: MuiVariants.H4,
  [Variants.Body]: MuiVariants.Body1,
  [Variants.Caption1]: MuiVariants.Subtitle1,
  [Variants.Caption2]: MuiVariants.Subtitle2,
  [Variants.Label]: MuiVariants.Caption,
  [Variants.Sublabel]: MuiVariants.Overline,
}

export default muiVariantsMap
