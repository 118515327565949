import HttpService from '../../../../../services/HttpService';
import { generateURLRappiServices } from '../../../../shared/infra';
import { RT_DELIVERY_PATH } from '../core/consts';
import {
  onGetHandoffDetailRequest,
  onUpdateHandoffDetailRequest,
} from '../core/requests';
import { HandoffDetailResponse } from '../core/responses/checkoutRT.response';

function updateHandoff(params: onUpdateHandoffDetailRequest) {
  const _url = generateURLRappiServices({
    url: RT_DELIVERY_PATH.UPDATE_HANDOFF_DETAIL,
  });
  return HttpService.post<boolean>(_url, params);
}

function getHandoffDetail({ orderId }: onGetHandoffDetailRequest) {
  const _url = generateURLRappiServices({
    url: RT_DELIVERY_PATH.GET_HANDOFF_DETAIL,
    pathParams: { orderId },
  });
  return HttpService.get<HandoffDetailResponse>(_url);
}

export const checkoutRTRepository = {
  updateHandoff,
  getHandoffDetail,
} as const;
