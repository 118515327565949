// External libs
import { handleActions } from 'redux-actions';
// Actions
import OrderWhimDialogActions from '../actions/orderWhimDialog.actions';
import orderWhimDialogActions from '../actions/orderWhimDialog.actions';

const defaultOrderId = 0;
const defaultProductId = 0;
const initialState = {
  orderId: defaultOrderId,
  open: false,
  productId: defaultProductId,
  productName: null,
  messageError: null,
};

export default handleActions(
  {
    [OrderWhimDialogActions.clearOrderWhimDialogAction]: state =>
      clearDialog(state),
    [OrderWhimDialogActions.closeOrderWhimDialogAction]: state =>
      closeDialog(state),
    [OrderWhimDialogActions.openOrderWhimDialogAction]: (
      state,
      { payload: { productName, productId, orderId } },
    ) => openDialog(state, productName, productId, orderId),
    [orderWhimDialogActions.setErrorTextAction]: (
      state,
      { payload: { errorCode, messageError } },
    ) => setErrorText(state, errorCode, messageError),
    [OrderWhimDialogActions.clearErrorTextAction]: state =>
      setErrorText(state, null, null),
  },
  initialState,
);

const clearDialog = state => {
  return {
    ...initialState,
  };
};

const closeDialog = state => {
  return {
    ...state,
    open: false,
  };
};

const openDialog = (state, productName, productId, orderId) => {
  return {
    ...state,
    open: true,
    productName: productName,
    productId: productId,
    orderId: orderId || state.orderId,
  };
};
const setErrorText = (state, errorCode, messageError) => {
  return {
    ...state,
    errorCode: errorCode,
    messageError: messageError,
  };
};
