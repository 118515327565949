import { clsx } from 'clsx';
import { Button } from '@material-ui/core';
import { useState, useEffect, useRef } from 'react';
import { KeyboardArrowRight } from '@material-ui/icons';

import CustomLoader from '../../custom-loader';

import { ZIndexType } from '../../../../constants/ZIndexType';
import StringUtils from '../../../../utils/StringUtils';

import useStyles from './styles';

export function CustomClickableBox(props) {
  const {
    classes = {},
    description = '',
    isLoading = false,
    title = '',
    onClick = () => {},
  } = props;

  const _classes = useStyles();

  const [isLoadingLocal, setIsLoadingLocal] = useState(isLoading);
  useEffect(() => {
    setIsLoadingLocal(isLoading);
  }, [isLoading]);

  const _isMounted = useRef(true);
  useEffect(() => {
    return () => {
      // ComponentWillUnmount
      _isMounted.current = false;
    };
  }, []);

  return (
    <Button
      className={clsx(_classes.root, classes?.root)}
      onClick={async e => {
        try {
          setIsLoadingLocal(true);
          await onClick(e);
        } catch (ex) {
          console.error(ex);
        }

        if (_isMounted.current) {
          setIsLoadingLocal(false);
        }
      }}
    >
      <CustomLoader
        invisible
        open={isLoadingLocal}
        zIndexType={ZIndexType.DRAWER}
      />
      <div>
        <div className={clsx(classes?.title)}>{title}</div>
        {!StringUtils.isNullOrEmpty(description) && (
          <div className={clsx(_classes.description, classes?.description)}>
            {description}
          </div>
        )}
      </div>
      <KeyboardArrowRight className={clsx(_classes.icon, classes?.icon)} />
    </Button>
  );
}
