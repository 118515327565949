import { Link as RouterLink } from 'react-router-dom';
import { clsx } from 'clsx';
import { Breadcrumbs, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import useStyles from './CustomBreadcrumb.styles';

export function CustomBreadcrumb({ links = [], classes = {} }) {
  const lastLinkIndex = links.length - 1;
  const _classes = useStyles();
  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <Breadcrumbs
        classes={{
          li: _classes.text,
          separator: _classes.separator,
        }}
        separator={<NavigateNextIcon fontSize='inherit' />}
        aria-label='Breadcrumb'
      >
        {links.map((link, linkIndex) => {
          return (
            <div key={`breadcrumb-${linkIndex}`}>
              {linkIndex < lastLinkIndex && (
                <>
                  {!!link.to && (
                    <Link
                      classes={{ root: _classes.text }}
                      component={RouterLink}
                      color={link.color}
                      to={link.to}
                      onClick={() => {}}
                    >
                      {link.label}
                    </Link>
                  )}
                  {!!link.action && (
                    <Typography
                      classes={{ root: _classes.text }}
                      color={link.color}
                      onClick={link.action}
                    >
                      {link.label}
                    </Typography>
                  )}
                </>
              )}
              {linkIndex === lastLinkIndex && (
                <Typography
                  classes={{ root: _classes.text }}
                  color='textPrimary'
                >
                  {link.label}
                </Typography>
              )}
            </div>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}
