import React from 'react';
import { welcomeBoxStyles } from './WelcomeBox.styles';

import { useDeactivatedProductsDialogTexts } from '../../hooks';

export function WelcomeBox() {
  const { welcomeText } = useDeactivatedProductsDialogTexts();
  const styles = welcomeBoxStyles();
  return (
    <div className={styles.container}>
      <div className={styles.confeti}>
        <p className={styles.title}>{welcomeText}</p>
      </div>
    </div>
  );
}
