import { get } from 'lodash';
import ReduxThunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';

import { rootReducer } from '../reducers/rootReducer';

import DialogType from '../../constants/DialogType';

import OrdersUtils from '../../utils/OrdersUtils';
import PartnerUtils from '../../utils/PartnerUtils';

import CustomerPhoneProxySessionDialogSelectors from '../selectors/customer-phone-proxy-session-dialog-selectors';
import DeleteORderWhimDialogSelectors from '../selectors/delete-order-whim-dialog-selectors';
import FailedProxySessionDialogSelectors from '../selectors/failed-proxy-session-dialog-selectors';
import OrderDetailDialogSelectors from '../selectors/order-detail-dialog-selectors';
import OrderRefuseReasonFromOrderRejectionBySaturatedStoreDialogSelectors from '../order-rejection-by-saturated-store-dialog/selectors';
import OrderRejectionByMotoboyLackDialogSelectors from '../order-rejection-by-motoboy-lack-dialog/selectors';
import OrdersSelector from '../selectors/orders-selectors';
import RTReleaseConfirmationDialogSelectors from '../selectors/rt-release-confirmation-dialog-selectors';
import SuccessfulProxySessionDialogSelectors from '../selectors/successful-proxy-session-dialog-selectors';
import { selectors as cancelByStockOutSuggestionConfirmationDialogSelectors } from '../cancel-by-stock-out-suggestion-confirmation-dialog';
import { selectors as cookedOrderConfirmationIssueDialogSelectors } from '../cooked-order-confirmation-issue-dialog';
import { selectors as helpCenterPageSelectors } from '../help-center-page';
import { selectors as itemsDeactivationStockOutIssueDialogSelectors } from '../items-deactivation-stock-out-issue-dialog';
import { selectors as JustCookedOrderIssueDialogSelectors } from '../just-cooked-order-issue-dialog';
import { selectors as partnersSelector } from '../partner';
import { selectors as RTAssignedIssueSuggestionsDialogSelectors } from '../rt-assigned-issue-suggestions-dialog';
import { selectors as RTDialogRTLackIssueSuggestionsDialogSelectors } from '../rt-lack-issue-suggestions-dialog';
import { selectors as stockOutIssueSuggestionsDialogSelectors } from '../stock-out-issue-suggestions-dialog';
import { selectors as storeDiagnosticsDialogSelectors } from '../store-diagnostics-dialog';
import { selectors as supportReasonDialogSelectors } from '../support-reasons-dialog';
import { selectors as dynInAppDialogSelectors } from '../dyn-in-app-dialog';
import { selectors as productSuggestionAvailabilitySelectors } from '../product-suggestion-availability-dialog';
import { selectors as suggestProductDialogSelectors } from '../suggest-product-dialog';
import { orderMgmtState } from '../../business/order-mgmt';
import { OM_STATE_NAME } from '../../business/order-mgmt/infra';
import { OM_ORDER_MODIFICATION_SLICE_NAME } from '../../business/order-mgmt/infra/store/order-modification';

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

let store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(ReduxThunk)),
);

export const getStore = () => {
  return store;
};

export const setStore = storeToReplace => {
  store = storeToReplace;
};

const getDispatch = () => store.dispatch;

const getBeingEditingSchedule = () => {
  const {
    storesSchedulesReducer: { beingEditedScheduleId },
  } = store.getState();

  return beingEditedScheduleId;
};

const getBeingManagedCanceledOrders = () => {
  const orders = getOrders();
  return OrdersUtils.filterBeingManagedCanceled(orders);
};

const doesOrderExists = orderId => {
  if (!orderId) {
    return false;
  }
  const orders = getOrders();

  if (!orders || !orders.length) {
    return false;
  }
  return orders.some(order => order.id === +orderId);
};

const getBeingCookingWithExpiredCT = () => {
  const orders = getBeingManagedOrders();
  const tz = getTz();
  return OrdersUtils.filterBeingCookingWithExpiredCT(orders, tz);
};

const getCurrentDialogCode = () => {
  const stack = getDialogsStack();
  return stack.length > 0 ? stack[stack.length - 1] : null;
};

const getDialogsStack = () => {
  const {
    dialogsManagerReducer: { stack },
  } = store.getState();
  return stack;
};

/**
 * Get current times v2 service has failed continuously.
 * @function getFailsV2Count
 * @returns {number} - current fails v2 count.
 */
const getFailsV2Count = () => {
  const {
    ordersReducer: { failsV2Count },
  } = store.getState();

  return failsV2Count;
};
const getFailsServiceErrorCount = () => {
  const {
    ordersReducer: { failsErrorServiceCount },
  } = store.getState();

  return failsErrorServiceCount;
};
const getSelectedStoreIdsForOrders = () => {
  const {
    ordersReducer: {
      filter: { selectedStoresIds },
    },
  } = store.getState();

  return selectedStoresIds;
};

const getSelectedStoreIdInStoreDiagnosticDialog = () => {
  const state = store.getState();
  return storeDiagnosticsDialogSelectors.selectSelectedStoreId(state);
};

const getStoresDiagnosticsCache = () => {
  const state = store.getState();
  return storeDiagnosticsDialogSelectors.selectStoresDiagnosticsCache(state);
};

const getSelectedStoreDiagnostic = () => {
  const state = store.getState();
  return storeDiagnosticsDialogSelectors.selectSelectedStoreDiagnostic(state);
};

const getIsFirstLogin = () => {
  const state = store.getState();
  return partnersSelector.selectIsFirstLogin(state);
};

const getFilteredBeingManagedOrdersBySelectedStores = () => {
  const beingManagedOrders = getBeingManagedOrders();
  const selectedStoreIds = getSelectedStoreIdsForOrders();

  return selectedStoreIds.length === 0
    ? beingManagedOrders
    : OrdersUtils.filterByStores(beingManagedOrders, selectedStoreIds);
};

const getInitialCTToTakeOrderInOrderDetailDialog = () => {
  const state = store.getState();
  return OrdersSelector.selectInitialCTToTakeOrderInOrderDetailDialog(state);
};

const getIsNonIntegratedNewOrderInOrderDetailDialog = () => {
  const state = store.getState();
  return OrdersSelector.selectIsNonIntegratedNewOrderInOrderDetailDialog(state);
};

const getIsOffline = () => {
  const {
    appReducer: { isOffline },
  } = store.getState();
  return isOffline;
};

const getIsPartnerActivationReminderDialogOpened = () => {
  const {
    partnerReducer: {
      profile: { isPartnerActivationReminderDialogOpened },
    },
  } = store.getState();

  return isPartnerActivationReminderDialogOpened;
};

const getIsPartnerDeactivationUnknownReasonDialogOpened = () => {
  const {
    partnerReducer: { isPartnerDeactivationUnknownReasonDialogOpened },
  } = store.getState();

  return isPartnerDeactivationUnknownReasonDialogOpened;
};
const getIsPartnerDeactivationByCbprDialogOpened = () => {
  const {
    partnerReducer: { isPartnerDeactivationByCbprDialogOpened },
  } = store.getState();

  return isPartnerDeactivationByCbprDialogOpened;
};
const getIsPartnerDeactivationByCbpiDialogOpened = () => {
  const {
    partnerReducer: { isPartnerDeactivationByCbpiDialogOpened },
  } = store.getState();
  return isPartnerDeactivationByCbpiDialogOpened;
};
const getIsPartnerDeactivationLimitedTimeReasonDialogOpened = () => {
  const {
    partnerReducer: { isPartnerDeactivationLimitedTimeReasonDialogOpened },
  } = store.getState();
  return isPartnerDeactivationLimitedTimeReasonDialogOpened;
};
const getIsPartnerDeactivationRestOfDayReasonDialogOpened = () => {
  const {
    partnerReducer: { isPartnerDeactivationRestOfDayReasonDialogOpened },
  } = store.getState();
  return isPartnerDeactivationRestOfDayReasonDialogOpened;
};

const getIsStoreDiagnosticDialogOpened = () => {
  const state = store.getState();
  return storeDiagnosticsDialogSelectors.selectOpen(state);
};

const getMenuFilter = () => {
  const state = store.getState();
  return state.menuReducer.filter;
};

const getMenuReducer = () => {
  const state = store.getState();
  return state.menuReducer;
};

const getMenus = () => {
  const menuReducer = getMenuReducer();
  return menuReducer.menus;
};

const getMenuByStore = storeId => {
  const menus = getMenus();
  return menus[storeId];
};

const getMenuSelectedStoreId = () => {
  const filter = getMenuFilter();
  return filter.selectedStoreId;
};

const getMenuSelectedCorridorId = () => {
  const state = store.getState();
  return state.menuReducer.selectedCorridorId;
};

const getMenuSelectedProductId = () => {
  const state = store.getState();
  return state.menuReducer.selectedProductId;
};

const getBeingManagedCookingOrders = () => {
  const orders = getBeingManagedOrders();
  return OrdersUtils.filterCooking(orders);
};

const getBeingManagedNewOrders = () => {
  const orders = getBeingManagedOrders();
  return OrdersUtils.filterNew(orders);
};
const isAnyNewOrderBeingManaged = () => {
  const newOrders = getBeingManagedNewOrders();
  return !!newOrders && newOrders.length > 0;
};

const getIfPartnerIsIntegrated = () => {
  const profile = getProfile();

  return PartnerUtils.isIntegratedPartner(profile);
};

const getOrderById = orderId => {
  const orders = getOrders();
  return orders.find(order => order.id === +orderId);
};

const getCTToTakeOrderInOrderDetailDialog = () => {
  const state = store.getState();
  return OrdersSelector.selectCTToTakeOrderInOrderDetailDialog(state);
};

const getOrderDetail = orderId => {
  const order = getOrderById(orderId);
  return get(order, 'detail', null);
};

const getOrderIdInOrderDetailDialog = () => {
  const state = store.getState();
  return OrderDetailDialogSelectors.selectOrderId(state);
};

const getOrderIdInCustomerPhoneProxySessionDialog = () => {
  const state = store.getState();
  return CustomerPhoneProxySessionDialogSelectors.selectOrderId(state);
};

const getOrderIdInFailedProxySessionDialog = () => {
  const state = store.getState();
  return FailedProxySessionDialogSelectors.selectOrderId(state);
};

const getOrderIdInSuccessfulProxySessionDialog = () => {
  const state = store.getState();
  return SuccessfulProxySessionDialogSelectors.selectOrderId(state);
};

const getOrderDetailInCustomerPhoneProxySessionDialog = () => {
  const orderId = getOrderIdInCustomerPhoneProxySessionDialog();
  return getOrderDetail(orderId);
};

const getOrderIdInOrderWhimDialog = () => {
  const state = store.getState();
  return state.orderWhimDialogReducer.orderId;
};

const getOrderIdInDeleteOrderWhimDialog = () => {
  const state = store.getState();
  return DeleteORderWhimDialogSelectors.selectOrderId(state);
};

const getOrderIdInRTDialog = () => {
  const state = store.getState();
  return state.rtDialogReducer.orderId;
};

const getOrderIdInOrderRejectionByStockOutDialog = () => {
  const {
    orderRejectionByStockOutDialogReducer: { orderId },
  } = store.getState();
  return orderId;
};

const getOrderIdInOrderRejectionDialog = () => {
  const state = store.getState();
  return state.orderRejectionReasonsDialogReducer.orderId;
};

const getOrderIdInRTReportReasonsDialog = () => {
  const state = store.getState();
  return state.rtReportReasonsDialogReducer.orderId;
};

const getOrderDetailInOrderDetailDialog = () => {
  const state = store.getState();
  return OrdersSelector.selectOrderDetailInOrderDetailDialog(state);
};

const getOrderDetailInOrderRejectionByStockOutDialog = () => {
  const orderId = getOrderIdInOrderRejectionByStockOutDialog();
  return getOrderDetail(orderId);
};

const getOrderDetailInOrderRejectionDialog = () => {
  const orderId = getOrderIdInOrderRejectionDialog();
  return getOrderDetail(orderId);
};
const getOrderDetailInRTReportReasonsDialog = () => {
  const orderId = getOrderIdInRTReportReasonsDialog();
  return getOrderDetail(orderId);
};

const getOrders = () => {
  const state = store.getState();
  return OrdersSelector.selectOrders(state);
};

const getOrdersHistorical = () => {
  const state = store.getState();
  return state.ordersReducer.ordersHistorical;
};

const getOrdersHistoricalPage = () => {
  const state = store.getState();
  return state.ordersReducer.ordersHistoricalPage;
};

const getOrderTotalsErrorIdInOrderDetailDialog = () => {
  const state = store.getState();
  return OrdersSelector.selectOrderTotalsErrorIdInOrderDetailDialog(state);
};

const getBeingManagedOrders = () => {
  const orders = getOrders();
  return OrdersUtils.filterBeingManaged(orders);
};

const getHelpCenterPageData = () => {
  const state = store.getState();
  return helpCenterPageSelectors.selectData(state);
};

const getIfMenuWasCompletelyDownloaded = storeId => {
  const storeMenu = getMenuByStore(storeId);
  if (!storeMenu) {
    return false;
  }
  return storeMenu.wasCompletelyDownloaded;
};

const getIfOrderIdInOrderDetailDialogExists = () => {
  const orderId = getOrderIdInOrderDetailDialog();
  return doesOrderExists(orderId);
};

const getIfOrderIdInRTDialogExists = () => {
  const orderId = getOrderIdInRTDialog();
  return doesOrderExists(orderId);
};

const getPartnerDeactivationReason = () => {
  const state = store.getState();
  return state.partnerReducer.profile.partnerDeactivationReason;
};

const getPartnerState = () => {
  const state = store.getState();
  return state.partnerReducer.profile.partnerState;
};

const getSimpleStoreStatusNotification = () => {
  const state = store.getState();
  return state.partnerReducer.simpleStoreStatusNotification;
};

const getIsChatV2Available = () => {
  const state = store.getState();
  return state.partnerReducer.isChatV2Available;
};

const getScheduledPMAutomationForm = () => {
  const state = store.getState();
  return state.partnerReducer.scheduledPMAutomationForm;
};

const getIsAutoReadyForPickupMarketplaceAvailable = () => {
  const state = store.getState();
  return state.partnerReducer.isAutoReadyForPickupMarketplaceAvailable;
};

const getPartnerStore = id => {
  const profile = getProfile();
  if (!profile.stores) {
    return null;
  }
  return profile.stores.find(store => store.id === +id);
};

const getGettingOrdersHistoricalFailedMessage = () => {
  const state = store.getState();
  return state.ordersReducer.gettingOrdersHistoricalFailedMessage;
};

const isGettingOrdersHistoricalFailedBeingShown = () => {
  const state = store.getState();
  return state.ordersReducer.isGettingOrdersHistoricalFailedBeingShown;
};

const getWhetherIsPartnerStateOpen = () => {
  const partnerState = getPartnerState();
  return PartnerUtils.isOpen(partnerState);
};

const getProfile = () => {
  const state = store.getState();
  return state.partnerReducer.profile;
};

const getPartnerId = () => {
  const profile = getProfile();
  return profile && profile.id;
};

const getCookingTimeStep = () => {
  const state = store.getState();
  return partnersSelector.selectCookingTimeStep(state);
};

const getStores = () => {
  const profile = getProfile();
  return profile && get(profile, 'stores', []);
};

const getPartnerName = () => {
  const profile = getProfile();
  return profile && `${profile.first_name} ${profile.last_name}`;
};
const getSupportPhone = () => {
  const profile = getProfile();
  return profile && profile.support_phone;
};

const getPartnerEmail = () => {
  const profile = getProfile();
  return profile && profile?.email;
};

const getSearchFieldText = () => {
  const {
    ordersReducer: { searchFieldText },
  } = store.getState();
  return searchFieldText;
};

const getSelectedProductsInItemsDeactivationStockOutIssueDialog = () => {
  const state = store.getState();
  return itemsDeactivationStockOutIssueDialogSelectors.selectSelectedProducts(
    state,
  );
};

const getSelectedToppingsInItemsDeactivationStockOutIssueDialog = () => {
  const state = store.getState();
  return itemsDeactivationStockOutIssueDialogSelectors.selectSelectedToppings(
    state,
  );
};

const getSelectedRefuseReasonFromOrderRejectionByMotoboyLackDialog = () => {
  const {
    orderRejectionByMotoboyLackDialog: { refuseReason },
  } = store.getState();
  return refuseReason;
};

const getOrderIdInOrderRejectionByMotoboyLackDialog = () => {
  const state = store.getState();
  return OrderRejectionByMotoboyLackDialogSelectors.selectOrderId(state);
};

const getOrderIdInOrderRejectionBySaturatedStoreDialog = () => {
  const state = store.getState();
  return OrderRefuseReasonFromOrderRejectionBySaturatedStoreDialogSelectors.selectOrderId(
    state,
  );
};

const getOrderIdInRTReleaseConfirmDialog = () => {
  const state = store.getState();
  return RTReleaseConfirmationDialogSelectors.selectOrderId(state);
};

const getSelectedRefuseReasonFromOrderRejectionBySaturatedStoreDialog = () => {
  const {
    orderRejectionBySaturatedStoreDialog: { refuseReason },
  } = store.getState();
  return refuseReason;
};

const getSelectedSupportReasonskDialog = () => {
  const {
    supportReasonsDialog: { refuseReason },
  } = store.getState();
  return refuseReason;
};

const getSelectedRefuseReasonFromOrderRejectionByStockOutDialog = () => {
  const {
    orderRejectionByStockOutDialogReducer: { refuseReason },
  } = store.getState();
  return refuseReason;
};

const getSelectedHolidayInAddHolidayDialog = () => {
  const {
    addHolidayScheduleDialogReducer: { holiday },
  } = store.getState();

  return holiday;
};

const getSelectedStoreIdInStoreSchedules = () => {
  const {
    storesSchedulesReducer: {
      filter: { selectedStoreId },
    },
  } = store.getState();

  return selectedStoreId;
};

const getSelectedDayIdInDeleteSpecialDayDialog = () => {
  const {
    deleteSpecialDayDialogReducer: { selectedDayId },
  } = store.getState();

  return selectedDayId;
};

const getSelectedStoreInStoreSchedules = () => {
  const selectedStoreId = getSelectedStoreIdInStoreSchedules();

  return getPartnerStore(selectedStoreId);
};

const getOrderDetailPollingBlacklist = () => {
  const {
    ordersReducer: { orderDetailPollingBlackList },
  } = store.getState();

  return orderDetailPollingBlackList;
};

const getTz = () => {
  const state = store.getState();
  return OrdersSelector.selectTz(state);
};

const doesUserHavePendingOrders = () => {
  const managedOrders = getBeingManagedOrders();
  return OrdersUtils.somePendingOrders(managedOrders);
};

const isAutoPrintActive = () => {
  const state = store.getState();
  return state.settingsReducer.isAutoPrintActive;
};

const isCheckNetworkDialogOpened = () => {
  const state = store.getState();
  return state.ordersReducer.isCheckNetworkDialogOpened;
};

const isOrderBeingManaged = orderId => {
  const order = getOrderById(orderId);

  if (!order) {
    return false;
  }

  return OrdersUtils.isBeingManaged(order);
};

const isOrderDetailDialogOpened = () => {
  const state = store.getState();
  return state.orderDetailDialogReducer.open;
};

const isOrderInOrderDetailPollingBlacklist = orderId => {
  const blacklist = getOrderDetailPollingBlacklist();

  return blacklist.some(id => +id === +orderId);
};

const getOrderPredictiveCTConfigInOrderDetailDialog = () => {
  const state = store.getState();
  return OrdersSelector.selectOrderPredictiveCTConfigInOrderDetailDialog(state);
};

const getOrderPredictiveCTDefaultInOrderDetailDialog = () => {
  const state = store.getState();
  return OrdersSelector.selectOrderDefaultPredictiveCTConfigInOrderDetailDialog(
    state,
  );
};

const getOrderPredictiveCTMinInOrderDetailDialog = () => {
  const state = store.getState();
  return OrdersSelector.selectOrderMinPredictiveCTConfigInOrderDetailDialog(
    state,
  );
};

const getOrderPredictiveCTMaxInOrderDetailDialog = () => {
  const state = store.getState();
  return OrdersSelector.selectOrderMaxPredictiveCTConfigInOrderDetailDialog(
    state,
  );
};

const getOrderStoreDefaultCTInOrderDetailDialog = () => {
  const state = store.getState();
  return OrdersSelector.selectOrderStoreDefaultCTInOrderDetailDialog(state);
};

const getOrderStoreIdInOrderDetailDialog = () => {
  const state = store.getState();
  return OrdersSelector.selectOrderStoreIdInOrderDetailDialog(state);
};

const getOrderIdInCancelByStockOutSuggestionsConfirmationlDialog = () => {
  const state = store.getState();
  return cancelByStockOutSuggestionConfirmationDialogSelectors.selectOrderId(
    state,
  );
};
const getDynInAppDialogOpenDialog = () => {
  const state = store.getState();
  return dynInAppDialogSelectors.selectOpen(state);
};

const getDynInAppDialogShouldSound = () => {
  const state = store.getState();
  return dynInAppDialogSelectors.selectShouldSound(state);
};
const getOrderIdInCookedOrderConfirmationIssueDialog = () => {
  const state = store.getState();
  return cookedOrderConfirmationIssueDialogSelectors.selectOrderId(state);
};

const getOrderIdInItemsDeactivationStockOutIssueDialog = () => {
  const state = store.getState();
  return itemsDeactivationStockOutIssueDialogSelectors.selectOrderId(state);
};

const getOrderIdInJustCookedOrderIssueDialog = () => {
  const state = store.getState();
  return JustCookedOrderIssueDialogSelectors.selectOrderId(state);
};

const getOrderIdInRTAssignedIssueSuggestionsDialog = () => {
  const state = store.getState();
  return RTAssignedIssueSuggestionsDialogSelectors.selectOrderId(state);
};

const getOrderIdInRTDialogRTLackIssueSuggestionsDialog = () => {
  const state = store.getState();
  return RTDialogRTLackIssueSuggestionsDialogSelectors.selectOrderId(state);
};

const getOrderIdInSupportReasonsDialog = () => {
  const state = store.getState();
  return supportReasonDialogSelectors.selectOrderId(state);
};

const getOrderIdInProductSuggestionAvailabilityDialog = () => {
  const state = store.getState();
  return productSuggestionAvailabilitySelectors.selectOrderId(state);
};
const getOrderIdInSuggestProductDialog = () => {
  const state = store.getState();
  return suggestProductDialogSelectors.selectOrderId(state);
};

const getOrderIdInStockOutIssueSuggestionsDialog = () => {
  const state = store.getState();
  return stockOutIssueSuggestionsDialogSelectors.selectOrderId(state);
};

const getOrderStoreMaxCTInOrderDetailDialog = () => {
  const state = store.getState();
  return OrdersSelector.selectOrderStoreMaxCTInOrderDetailDialog(state);
};

const getOrderStoreMinCTInOrderDetailDialog = () => {
  const state = store.getState();
  return OrdersSelector.selectOrderStoreMinCTInOrderDetailDialog(state);
};

const getSupportNotificationsMessagesInOrderDetailDialog = () => {
  const state = store.getState();
  return OrdersSelector.selectSupportNotificationsMessagesInOrderDetailDialog(
    state,
  );
};

const getSupportTypifications = () => {
  const state = store.getState();
  return partnersSelector.selectSupportTypifications(state);
};

const getOrderIdByDialogCode = dialogCode => {
  switch (dialogCode) {
    case DialogType.CANCEL_BY_STOCK_OUT_SUGGESTION_CONFIRMATION:
      return getOrderIdInCancelByStockOutSuggestionsConfirmationlDialog();
    case DialogType.COOKED_ORDER_CONFIRMATION_ISSUE:
      return getOrderIdInCookedOrderConfirmationIssueDialog();
    case DialogType.CUSTOMER_PHONE_PROXY_SESSION:
      return getOrderIdInCustomerPhoneProxySessionDialog();
    case DialogType.DELETE_ORDER_WHIM:
      return getOrderIdInDeleteOrderWhimDialog();
    case DialogType.FAILED_PROXY_SESSION:
      return getOrderIdInFailedProxySessionDialog();
    case DialogType.ITEMS_DEACTIVATION_STOCK_OUT_ISSUE:
      return getOrderIdInItemsDeactivationStockOutIssueDialog();
    case DialogType.JUST_COOKED_ORDER_ISSUE:
      return getOrderIdInJustCookedOrderIssueDialog();
    case DialogType.LOG_OUT:
      return undefined;
    case DialogType.ORDER_DETAIL:
      return getOrderIdInOrderDetailDialog();
    case DialogType.ORDER_REJECTION_BY_MOTOBOY_LACK:
      return getOrderIdInOrderRejectionByMotoboyLackDialog();
    case DialogType.ORDER_REJECTION_BY_SATURATED_STORE:
      return getOrderIdInOrderRejectionBySaturatedStoreDialog();
    case DialogType.ORDER_REJECTION_BY_STOCK_OUT:
      return getOrderIdInOrderRejectionByStockOutDialog();
    case DialogType.ORDER_REJECTION_REASONS:
      return getOrderIdInOrderRejectionDialog();
    case DialogType.ORDER_WHIM:
      return getOrderIdInOrderWhimDialog();
    case DialogType.RT_ASSIGNED_ISSUE:
      return getOrderIdInRTAssignedIssueSuggestionsDialog();
    case DialogType.RT_LACK_ISSUE:
      return getOrderIdInRTDialogRTLackIssueSuggestionsDialog();
    case DialogType.RT:
      return getOrderIdInRTDialog();
    case DialogType.RT_RELEASE_CONFIRMATION_DIALOG:
      return getOrderIdInRTReleaseConfirmDialog();
    case DialogType.RT_REPORT_REASONS:
      return getOrderIdInRTReportReasonsDialog();
    case DialogType.STOCK_OUT_ISSUE_SUGGESTION:
      return getOrderIdInStockOutIssueSuggestionsDialog();
    case DialogType.SUCCESSFUL_PROXY_SESSION:
      return getOrderIdInSuccessfulProxySessionDialog();
    case DialogType.SUPPORT_MENU_DIALOG:
      return undefined;
    case DialogType.SUPPORT_REASONS:
      return getOrderIdInSupportReasonsDialog();
    case DialogType.PRODUCT_SUGGESTION_AVAILABILITY_DIALOG:
      return getOrderIdInProductSuggestionAvailabilityDialog();
    case DialogType.SUGGEST_PRODUCT_DIALOG:
      return getOrderIdInSuggestProductDialog();
    case DialogType.SUCCESSFUL_SUGGESTING_PRODUCT_DIALOG:
      return undefined;
    case DialogType.ERROR_SUGGESTING_PRODUCT_DIALOG:
      return undefined;
    default:
      return undefined;
  }
};

const getHasBeenUpdatedStoreStatusNotification = () => {
  const state = store.getState();
  return partnersSelector.selectHasBeenUpdatedStoreStatusNotification(state);
};

//todo: move to new dialog handler
const isOrderMgmtDialogOpened = () => {
  const state = store.getState();
  return (
    state[OM_STATE_NAME][OM_ORDER_MODIFICATION_SLICE_NAME].isOpen ||
    state.orderManagementItemSearchDialog.isOpen ||
    state[OM_STATE_NAME][OM_ORDER_MODIFICATION_SLICE_NAME].currentOrderState !==
      orderMgmtState.Idle
  );
};

export default {
  doesOrderExists,
  doesUserHavePendingOrders,
  getBeingCookingWithExpiredCT,
  getBeingEditingSchedule,
  getBeingManagedCanceledOrders,
  getBeingManagedCookingOrders,
  getBeingManagedNewOrders,
  getBeingManagedOrders,
  getCookingTimeStep,
  getCTToTakeOrderInOrderDetailDialog,
  getCurrentDialogCode,
  getDialogsStack,
  getDispatch,
  getFailsV2Count,
  getFilteredBeingManagedOrdersBySelectedStores,
  getGettingOrdersHistoricalFailedMessage,
  getHelpCenterPageData,
  getIfMenuWasCompletelyDownloaded,
  getIfOrderIdInOrderDetailDialogExists,
  getIfOrderIdInRTDialogExists,
  getIfPartnerIsIntegrated,
  getInitialCTToTakeOrderInOrderDetailDialog,
  getIsNonIntegratedNewOrderInOrderDetailDialog,
  getIsOffline,
  getIsPartnerActivationReminderDialogOpened,
  getIsPartnerDeactivationByCbpiDialogOpened,
  getIsPartnerDeactivationByCbprDialogOpened,
  getIsPartnerDeactivationLimitedTimeReasonDialogOpened,
  getIsPartnerDeactivationRestOfDayReasonDialogOpened,
  getIsPartnerDeactivationUnknownReasonDialogOpened,
  getIsStoreDiagnosticDialogOpened,
  getMenuFilter,
  getMenuSelectedCorridorId,
  getMenuSelectedProductId,
  getMenuSelectedStoreId,
  getOrderById,
  getOrderDetail,
  getOrderDetailInCustomerPhoneProxySessionDialog,
  getOrderDetailInOrderDetailDialog,
  getOrderDetailInOrderRejectionByStockOutDialog,
  getOrderDetailInOrderRejectionDialog,
  getOrderDetailInRTReportReasonsDialog,
  getOrderIdByDialogCode,
  getOrderIdInCustomerPhoneProxySessionDialog,
  getOrderIdInOrderDetailDialog,
  getOrderIdInOrderWhimDialog,
  getOrderPredictiveCTConfigInOrderDetailDialog,
  getOrderPredictiveCTDefaultInOrderDetailDialog,
  getOrderPredictiveCTMaxInOrderDetailDialog,
  getOrderPredictiveCTMinInOrderDetailDialog,
  getOrders,
  getOrdersHistorical,
  getOrdersHistoricalPage,
  getOrderStoreDefaultCTInOrderDetailDialog,
  getOrderStoreIdInOrderDetailDialog,
  getOrderStoreMaxCTInOrderDetailDialog,
  getOrderStoreMinCTInOrderDetailDialog,
  getOrderTotalsErrorIdInOrderDetailDialog,
  getPartnerDeactivationReason,
  getPartnerId,
  getPartnerName,
  getPartnerEmail,
  getPartnerState,
  getPartnerStore,
  getProfile,
  getSearchFieldText,
  getSelectedDayIdInDeleteSpecialDayDialog,
  getSelectedHolidayInAddHolidayDialog,
  getSelectedProductsInItemsDeactivationStockOutIssueDialog,
  getSelectedRefuseReasonFromOrderRejectionByMotoboyLackDialog,
  getSelectedRefuseReasonFromOrderRejectionBySaturatedStoreDialog,
  getSelectedRefuseReasonFromOrderRejectionByStockOutDialog,
  getStoresDiagnosticsCache,
  getSelectedStoreDiagnostic,
  getSelectedStoreIdInStoreSchedules,
  getSelectedStoreIdsForOrders,
  getSelectedStoreIdInStoreDiagnosticDialog,
  getSelectedStoreInStoreSchedules,
  getSelectedSupportReasonskDialog,
  getSelectedToppingsInItemsDeactivationStockOutIssueDialog,
  getStore,
  getStores,
  getSupportNotificationsMessagesInOrderDetailDialog,
  getSupportPhone,
  getSupportTypifications,
  getTz,
  getWhetherIsPartnerStateOpen,
  isAutoPrintActive,
  isCheckNetworkDialogOpened,
  isGettingOrdersHistoricalFailedBeingShown,
  isOrderBeingManaged,
  isOrderDetailDialogOpened,
  isOrderInOrderDetailPollingBlacklist,
  setStore,
  store,
  getFailsServiceErrorCount,
  getIsFirstLogin,
  getDynInAppDialogShouldSound,
  getDynInAppDialogOpenDialog,
  getIsChatV2Available,
  getIsAutoReadyForPickupMarketplaceAvailable,
  getScheduledPMAutomationForm,
  isAnyNewOrderBeingManaged,
  getSimpleStoreStatusNotification,
  getHasBeenUpdatedStoreStatusNotification,
  isOrderMgmtDialogOpened,
};
