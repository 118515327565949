// React
import React from 'react'
// Components
import CustomToggleSwitchOptions from '../common/custom-toggle-switch-options'

const MenuProductDeactivationOptions = ({
  isAvailable,
  options,
  product,
  partnerId,
  deactivatedItemId,
  setDeactivatedItemId,
}) => {
  return (
    <CustomToggleSwitchOptions
      active={isAvailable}
      item={product}
      isProduct={true}
      options={options}
      partnerId={partnerId}
      deactivatedItemId={deactivatedItemId}
      setDeactivatedItemId={setDeactivatedItemId}
    />
  )
}

MenuProductDeactivationOptions.defaultProps = {
  isAvailable: false,
  options: [],
  deactivatedItemId: null,
  setDeactivatedItemId: () => {},
}

export default MenuProductDeactivationOptions
