// React
import React from 'react'
// Const
import Colors from '../../../constants/Colors'

const DollarSignIcon = ({ color = Colors.WHITE }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='8'
      height='13'
      viewBox='0 0 8 13'
    >
      <g fill='none' fillRule='evenodd' opacity='.93'>
        <g fill={color}>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        d='M4 0c.552 0 1 .291 1 .65v.41c.332.05.656.128.968.236.591.205 1.097.536 1.449.943.34.376.54.818.581 1.277.031.352-.31.657-.76.682-.45.024-.841-.242-.872-.594l-.017-.112c-.038-.185-.131-.363-.279-.527-.19-.22-.455-.394-.764-.5-.1-.035-.201-.065-.305-.09v3.718c.69.22 1.332.54 1.91.96.652.541 1.023 1.253 1.039 1.977.03.492-.12.98-.427 1.393-.316.45-.78.827-1.338 1.088-.371.177-.774.308-1.184.387L5 12.349c0 .36-.448.651-1 .651s-1-.291-1-.65v-.406c-.33-.047-.654-.123-.968-.228-.591-.205-1.097-.536-1.449-.944-.34-.376-.54-.818-.581-1.277-.031-.352.31-.657.76-.681.45-.024.841.242.872.594l.017.112c.038.185.131.363.279.526.19.22.455.394.757.499.102.034.206.063.313.088V6.915c-.685-.223-1.321-.543-1.89-.958C.453 5.419.075 4.71.05 3.987c-.035-.488.109-.973.405-1.384.312-.46.776-.845 1.343-1.115.377-.18.786-.311 1.202-.39V.651C3 .29 3.448 0 4 0zm1.001 7.516v3.037c.116-.038.228-.083.337-.135.315-.148.574-.358.76-.622.158-.213.233-.457.217-.723-.01-.426-.222-.835-.555-1.114-.233-.168-.488-.316-.759-.443zM3 2.44c-.12.039-.237.085-.349.138-.317.152-.576.367-.76.637-.153.213-.225.455-.206.718.014.426.231.833.574 1.115.228.166.477.313.74.439z'
                        transform='translate(-716 -367) translate(61 38) translate(0 23) translate(607 51) translate(24 185) translate(12 64) translate(12 6)'
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default DollarSignIcon
