import { Box, Button, Typography } from '@material-ui/core';

import RtAvatar from '../rt-avatar';
import RTStateLabel from '../rt-state-label';
import { RTReportButton } from '../rt-report-button';
import { RTReleaseButton } from '../rt-release-button';
import { RtArrivesOrWaitingTime } from '../rt-arrives-or-waiting-time';
import OrderDetailDialogMainSectionItem from '../order-detail-dialog-main-section-item';

import useStyles from './OrderDetailDialogRTBox.styles';
import { GeoqueueRTSection } from '../../features/order/geoqueues';
import { useOrderDetailDialogRTBox } from './useOrderDetailDialogRTBox';

export const OrderDetailDialogRTBox = () => {
  const {
    activeStepIndex,
    alertText,
    isRTNotifyAvailable,
    handleRTDialog,
    handleNotificationToRT,
    orderId,
    rt = {},
    rtStateText,
    showRTInfo,
    rtBtnTextCT,
    showMoreButton,
    isRtWaitingMuch = false,
    storeId,
    showGeoqueueSection,
  } = useOrderDetailDialogRTBox();
  const componentClasses = useStyles({ showRTInfo });
  const title = 'RAPPITENDERO';

  const onClickRTBtnCT = event => {
    event.stopPropagation();
    if (isRTNotifyAvailable) handleNotificationToRT();
    else handleRTDialog();
  };

  if (showGeoqueueSection) {
    return (
      <OrderDetailDialogMainSectionItem
        classes={{ root: componentClasses.root, body: componentClasses.body }}
        title={title}
      >
        <GeoqueueRTSection showDisclaimer />
      </OrderDetailDialogMainSectionItem>
    );
  }

  return (
    <OrderDetailDialogMainSectionItem
      classes={{ root: componentClasses.root, body: componentClasses.body }}
      title={title}
    >
      <aside className={componentClasses.rtInfoContainer}>
        <RtAvatar
          classes={{
            root: componentClasses.rtAvatarRoot,
            avatarRoot: componentClasses.avatarRoot,
          }}
          rtName={rt.first_name}
          rtPhoto={rt.profile_pic}
        />
        <div className={componentClasses.rtInfo}>
          <Typography variant='body2' className={componentClasses.rtName}>
            {rt.first_name || ''}
          </Typography>
          {!!rt.phone && (
            <Typography
              variant='body2'
              className={componentClasses.secondaryInfo}
            >
              {`Tel: ${rt.phone || ''}`}{' '}
            </Typography>
          )}
        </div>
      </aside>

      <section className={componentClasses.rtStateContainer}>
        <RTStateLabel
          rtStep={activeStepIndex}
          rtStepMessage={rtStateText}
          isRtWaitingMuch={isRtWaitingMuch}
        />
        <RtArrivesOrWaitingTime orderId={orderId} />
      </section>

      <Button
        size='large'
        color='primary'
        variant='outlined'
        onClick={onClickRTBtnCT}
        className={componentClasses.rtBtnCT}
      >
        {rtBtnTextCT}
      </Button>

      {alertText && (
        <Typography
          variant='body2'
          classes={{ root: componentClasses.extraTextRoot }}
        >
          {alertText}
        </Typography>
      )}

      {showMoreButton && (
        <Box>
          <RTReleaseButton orderId={orderId} storeId={storeId} />
          <RTReportButton orderId={orderId} storeId={storeId} />
        </Box>
      )}
    </OrderDetailDialogMainSectionItem>
  );
};
