import { ZIndexType } from '../../constants/ZIndexType';

import { CustomClickableBox } from '../common/boxes/custom-clickable-box';
import CustomDialog from '../common/custom-dialog';
import CustomLoader from '../common/custom-loader';

import useStyles from './RTReportReasonsDialog.styles';

function RTReportReasonsDialog(props) {
  const {
    description,
    isLoading = false,
    onBack = () => {},
    onClickReason = () => {},
    onClose = () => {},
    open = false,
    reasons = [],
    sectionTitle,
    title,
  } = props;
  const _classes = useStyles();
  return (
    <CustomDialog
      classes={{
        childrenContainer: _classes.childrenContainer,
        headerRoot: _classes.headerRoot,
        paper: _classes.paper,
      }}
      onClickBackButton={onBack}
      onClose={onClose}
      open={open}
      showBackButton
      showCloseButton
      title={title}
    >
      <CustomLoader invisible open={isLoading} zIndexType={ZIndexType.DRAWER} />
      <div className={_classes.body}>
        <div className={_classes.sectionLabelsContainer}>
          <div className={_classes.sectionTitle}>{sectionTitle}</div>
          <div className={_classes.description}>{description}</div>
        </div>
        <div className={_classes.reasonsContainer}>
          {reasons.map(reason => {
            return (
              <CustomClickableBox
                title={reason.rule_description}
                key={reason.id}
                onClick={() => {
                  onClickReason(reason);
                }}
              />
            );
          })}
        </div>
      </div>
    </CustomDialog>
  );
}

export default RTReportReasonsDialog;
