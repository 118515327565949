import {
  AnalyticActions,
  BaseProps,
  Events,
  addSearchItemProps,
  editOrderProps,
  inactivityActionProps,
  openChatProps,
  openOrderStatusProps,
  orderActionProps,
  removeChangesProps,
  sendSubstitutionProps,
} from './core';

function whenOpenOrderStatus(
  { orderId, storeId }: BaseProps,
  params: openOrderStatusProps,
) {
  const args = {
    [Events.openOrderStatus.props.orderId]: orderId,
    [Events.openOrderStatus.props.storeId]: storeId,
    [Events.openOrderStatus.props.isEnabledToEdit]: params.isEnabledToEdit,
  };
  AnalyticActions.logOpenOrderStatus(args);
}

function whenOpenChat({ orderId, storeId }: BaseProps, params: openChatProps) {
  const args = {
    [Events.openChat.props.orderId]: orderId,
    [Events.openChat.props.storeId]: storeId,
    [Events.openChat.props.isOM]: params.isOM,
  };
  AnalyticActions.logOpenChat(args);
}
function whenEditOrder(
  { orderId, storeId }: BaseProps,
  params: editOrderProps,
) {
  const args = {
    [Events.editOrder.props.orderId]: orderId,
    [Events.editOrder.props.storeId]: storeId,
    [Events.editOrder.props.isOpenBy]: params.isOpenBy,
    [Events.editOrder.props.editType]: params.editType,
    [Events.editOrder.props.optionType]: params.optionType,
  };
  AnalyticActions.logEditOrder(args);
}
function whenOrderActions(
  { orderId, storeId }: BaseProps,
  params: orderActionProps,
) {
  const args = {
    [Events.orderActions.props.storeId]: orderId,
    [Events.orderActions.props.orderId]: storeId,
    [Events.orderActions.props.actionType]: params.action,
    [Events.orderActions.props.itemType]: params.item,
  };
  AnalyticActions.logOrderActions(args);
}
function whenAddItemBySearch(
  { orderId, storeId }: BaseProps,
  params: addSearchItemProps,
) {
  const args = {
    [Events.addSearchItem.props.storeId]: storeId,
    [Events.addSearchItem.props.orderId]: orderId,
    [Events.addSearchItem.props.actionType]: params.action,
    [Events.addSearchItem.props.itemType]: params.item,
  };
  AnalyticActions.logSearchItem(args);
}
function whenContinueWithSummary({ orderId, storeId }: BaseProps) {
  const args = {
    [Events.continueSummary.props.orderId]: orderId,
    [Events.continueSummary.props.storeId]: storeId,
  };
  AnalyticActions.logContinueSummary(args);
}
function whenRemoveChanges(
  { orderId, storeId }: BaseProps,
  params: removeChangesProps,
) {
  const args = {
    [Events.removeChanges.props.orderId]: orderId,
    [Events.removeChanges.props.storeId]: storeId,
    [Events.removeChanges.props.actionType]: params.action,
  };
  AnalyticActions.logRemoveChanges(args);
}
function whenSendSubstitution(
  { orderId, storeId }: BaseProps,
  params: sendSubstitutionProps,
) {
  const args = {
    [Events.sendSubstitutionRequest.props.storeId]: storeId,
    [Events.sendSubstitutionRequest.props.orderId]: orderId,
    [Events.sendSubstitutionRequest.props.itemType]: params.item,
    [Events.sendSubstitutionRequest.props.optionType]: params.optionType,
  };
  AnalyticActions.logSendSubstitutionRequest(args);
}
function whenAcceptOrder({ orderId, storeId }: BaseProps) {
  const args = {
    [Events.acceptOrder.props.orderId]: orderId,
    [Events.acceptOrder.props.storeId]: storeId,
  };
  AnalyticActions.logAcceptOrder(args);
}
function whenInactivityAction(
  { orderId, storeId }: BaseProps,
  params: inactivityActionProps,
) {
  const args = {
    [Events.inactivityActions.props.orderId]: orderId,
    [Events.inactivityActions.props.storeId]: storeId,
    [Events.inactivityActions.props.action]: params.action,
  };
  AnalyticActions.logInactivityActions(args);
}
function whenSelectOutOfStockItem(
  { orderId, storeId }: BaseProps,
  params: any,
) {
  const args = {
    [Events.selectOutOfStockItem.props.orderId]: orderId,
    [Events.selectOutOfStockItem.props.storeId]: storeId,
    [Events.selectOutOfStockItem.props.isOpenBy]: params.isOpenBy,
  };
  AnalyticActions.logSelectOutOfStockItem(args);
}

function whenClickAcceptOrderByReplaceWithoutEditing({
  orderId,
  storeId,
}: BaseProps) {
  const args = {
    [Events.clickAcceptOrderByReplaceWithoutEditing.props.orderId]: orderId,
    [Events.clickAcceptOrderByReplaceWithoutEditing.props.storeId]: storeId,
  };
  AnalyticActions.logClickAcceptOrderByReplaceWithoutEditing(args);
}

export const OrderMgmtAnalytics = {
  whenOpenOrderStatus,
  whenOpenChat,
  whenEditOrder,
  whenOrderActions,
  whenAddItemBySearch,
  whenContinueWithSummary,
  whenRemoveChanges,
  whenSendSubstitution,
  whenAcceptOrder,
  whenInactivityAction,
  whenSelectOutOfStockItem,
  whenClickAcceptOrderByReplaceWithoutEditing,
};
