import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../shared/styles/colors';

export const dialogStyles = makeStyles(theme => ({
  customCloseButton: { justifySelf: 'end' },
  root: {
    alignItems: 'center',
    borderBottom: `1px solid ${neutral.D10}`,
    display: 'grid',
    gridTemplateColumns: 'min-content auto min-content',
  },
  title: {
    color: neutral.D20,
    justifySelf: 'start',
    fontFamily: 'Poppins Bold',
    fontSize: 20,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    padding: '0px 0px 0px 20px',
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
      padding: '0px 0px 0px 12px',
      whiteSpace: 'nowrap',
    },
  },
}));
