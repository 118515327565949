import { Box, Typography } from "@material-ui/core";
import { StartIcon } from '../../shared/assets/icons/start-icon';
import { Saturation } from "../../../../business/highDemand";
import makeClasses from './ChipDisclaimer.style'

export function ChipDisclaimer(props: ChipProps) {
  const { disclaimer, variant = 'fast', disabled = false } = props
  const classes = makeClasses({ variant, disabled });

  return (
    <Box
      padding='0.25rem 0.375rem'
      display='flex'
      alignItems='center'
      gridGap='0.25rem'
      className={classes.chip}
      aria-disabled={disabled}
    >
      <StartIcon variant={variant} disabled={disabled}/>
      <Typography aria-disabled={disabled} className={classes.disclaimer}>
        {disclaimer}
      </Typography>
    </Box>
  )
}

interface ChipProps {
  disclaimer: string;
  variant: Saturation;
  disabled?: boolean
}