const cancelButtonText = {
  id: 'toppingsMassiveDeactivationDrawer.cancel',
}
const confirmButtonText = {
  id: 'toppingsMassiveDeactivationDrawer.confirm',
}

const title = {
  id: 'toppingsMassiveDeactivationDrawer.title',
}
const selectAll = {
  id: 'toppingsMassiveDeactivationDrawer.selectAll',
}

const description = {
  id: 'toppingsMassiveDeactivationDrawer.description',
}

const textIds = {
  title,
  cancelButtonText,
  confirmButtonText,
  selectAll,
  description,
}

export default textIds
