// React
import { useCallback } from 'react';
// Consts
import { textIds } from './consts';
// Tools
import { useFormatMessage } from '../../intl/intl';
// Hooks
import hooks from './hooks';
// Components
import { CustomBannerDialog } from '../common/custom-banner-dialog';
import Typography, {
  Variants,
  FontWeight,
} from '../../components/common/typography';
import { StoreNameAlert } from '../store-name-alert';
// Styles
import useStyles from './styles';
// Material UI
import { useDispatch } from 'react-redux';
// Actions
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';
// Images'
import PMAutomationService from '../../services/PM-automation-service';

const IMAGE = [
  { breakpoint: 'md', url: process.env.REACT_APP_PM_FLOW_START_BANNER_MD },
  { breakpoint: 'lg', url: process.env.REACT_APP_PM_FLOW_START_BANNER_MD },
  { breakpoint: 'xl', url: process.env.REACT_APP_PM_FLOW_START_BANNER_MD },
  { breakpoint: 'xs', url: process.env.REACT_APP_PM_FLOW_START_BANNER_XS },
  { breakpoint: 'sm', url: process.env.REACT_APP_PM_FLOW_START_BANNER_SM },
];

export function StartPMFlowDialog() {
  const dispatch = useDispatch();
  const componentClasses = useStyles();
  const open = hooks.useOpen();
  const storeName = hooks.useStoreName();
  const formId = hooks.useFormId();
  const title = '';

  const handleClose = useCallback(async () => {
    dispatch(dialogsManagerActions.close());
    await PMAutomationService.sendPMAutomationStoreClose(formId);
  }, [dispatch, formId]);

  const handleBack = useCallback(() => {
    dispatch(dialogsManagerActions.back());
  }, [dispatch]);

  const onConfirm = useCallback(() => {
    dispatch(dialogsManagerActions.openPhonePMFlowDialog(formId));
  }, [dispatch, formId]);

  const bannerTitle = useFormatMessage(textIds.bannerTitle);
  const description = useFormatMessage(textIds.description);
  const submitButton = useFormatMessage(textIds.submitButton);
  const cancelButton = useFormatMessage(textIds.cancelButton);

  return (
    <CustomBannerDialog
      classes={{
        childrenContainer: componentClasses.body,
        headerRoot: componentClasses.headerRoot,
        paper: componentClasses.paper,
      }}
      CustomDialogHeaderChildren={''}
      onClose={handleClose}
      onClickBackButton={handleBack}
      open={open}
      showBackButton={false}
      showCloseButton
      title={title}
      onConfirm={onConfirm}
      onRefuse={handleClose}
      disableBackdropClick
      disableEnforceFocus
      disableEscapeKeyDown
      bannerImage={IMAGE}
      cancelButtonText={cancelButton}
      submitButtonText={submitButton}
      bannerDescription={bannerTitle}
    >
      <div className={componentClasses.root}>
        <Typography
          classes={{ root: componentClasses.description }}
          fontWeight={FontWeight.Regular}
          variant={Variants.Body}
        >
          {description}
        </Typography>
        <StoreNameAlert storeName={storeName} />
      </div>
    </CustomBannerDialog>
  );
}
