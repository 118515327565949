// External libs
import { createSelector } from 'reselect'
// Selectors
import OrdersSelectors from '../../../redux/selectors/orders-selectors'

const selectTotalCharge = createSelector(
  OrdersSelectors.selectOrderDetailTotalChargeInOrderDetailDialog,
  totalCharge => {
    return totalCharge
  },
)

const Selectors = {
  selectTotalCharge,
}

export default Selectors
