// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  body: {
    height: '100%',
    paddingTop: 24,
    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
  },
  headerRoot: {
    [theme.breakpoints.up('md')]: {
      padding: 12,
    },
  },
  orderRejectionByStockOutRoot: {
    borderTop: `1px solid ${neutral.D10}`,
    [theme.breakpoints.up('md')]: {
      border: 'none',
    },
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      height: 608,
      width: 620,
    },
  },
  root: {},
}));
