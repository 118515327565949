import { useSelector } from 'react-redux';

import { selectors as logOutDialog } from '../../redux/log-out-dialog';

const useOpen = () => useSelector(logOutDialog.selectOpen);

const hooks = {
  useOpen,
};

export default hooks;
