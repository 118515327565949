const BaseProps = {
  orderId: 'ORDER_ID',
};

const handOffBagsAndDrinksInput = {
  name: 'HANDOFF_INPUT',
  props: {
    ...BaseProps,
    numberOfBags: 'NUMBER_OF_BAGS',
    outsideDrink: 'DRINK',
    orderState: 'ORDER_STATE',
    isMandatory: 'IS_MANDATORY',
    rtWaiting: 'RT_WAITING',
  },
};

export const Events = {
  handOffBagsAndDrinksInput,
};
