import { makeStyles } from '@material-ui/core/styles';

import { neutral } from '../../shared/styles/colors';

export default makeStyles({
  root: {
    marginTop: '1.5rem',
    fontFamily: 'Poppins',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
  },
  toppingCategoryContainer: {
    marginTop: 22,
  },
  toppingCategoryDescription: {
    color: neutral['50'],
    fontSize: '0.75rem',
  },
  toppingCategoryToppingsContainer: {
    marginTop: 10,
  },
  toppingsTitle: {
    color: neutral['90'],
    fontSize: '1rem',
  },
});
