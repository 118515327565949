import { useMemo } from 'react';
import { createIntl, createIntlCache, useIntl } from 'react-intl';
import messages_es from '../assets/i18n/es.json';
import messages_pt from '../assets/i18n/pt.json';

let intl;

const defaultLanguage = 'es';
const defaultMessages = {
  es: messages_es,
  pt: messages_pt,
};

export const initializeIntl = (
  locale = defaultLanguage,
  messages = defaultMessages,
) => {
  // This is optional but highly recommended
  // since it prevents memory leak
  const cache = createIntlCache();

  intl = createIntl(
    {
      locale,
      messages: messages[locale],
    },
    cache,
  );
  return intl;
};

export const getIntl = () => {
  return intl;
};

export const formatMessage = (messageObject, params) => {
  if (!intl) {
    console.error('intl not initialized');
    return null;
  }

  return intl.formatMessage(messageObject, params);
};

export const tLang = formatMessage;

export const useFormatMessage = (messageObject, params = {}) => {
  const { formatMessage } = useIntl();
  const isEmpty = !Boolean(messageObject.id);
  return useMemo(() => (isEmpty ? '' : formatMessage(messageObject, params)), [
    isEmpty ? '' : formatMessage,
    messageObject,
    params,
  ]);
};

export const useTLang = useFormatMessage;

const IntlTools = {
  defaultLanguage,
  defaultMessages,
  formatMessage,
  tLang,
  getIntl,
  initializeIntl,
  useFormatMessage,
  useTLang,
};

export default IntlTools;
