import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Hooks from './hooks';
import { useFormatMessage } from '../../intl/intl';
import PartnerActions from '../../redux/actions/partners-actions';

import MainLayout from '../main-layout';
import StoreBox from './components/store-box';
import CustomAlert from '../common/custom-alert';
import CustomBlock from '../common/custom-block';
import CustomTextField from '../common/custom-text-field';

import { textIds } from './consts';

import useStyles from './styles';

export function AccountProfile() {
  const _classes = useStyles();

  const { pathname: currentPathName } = useLocation();
  const stores = Hooks.useStores();
  const profile = Hooks.useProfile();
  const partnerId = profile?.id ?? 0;
  const firstName = profile?.first_name ?? '';
  const lastName = profile?.last_name ?? '';
  const email = profile?.email ?? '';

  const emailTextFieldLabel = useFormatMessage(textIds.emailTextFieldLabel);
  const generalInformationTitle = useFormatMessage(
    textIds.generalInformationTitle,
  );
  const idTextFieldLabel = useFormatMessage(textIds.idTextFieldLabel);
  const nameTextFieldLabel = useFormatMessage(textIds.nameTextFieldLabel);
  const title = useFormatMessage(textIds.title);
  const storesTitle = useFormatMessage(textIds.storesTitle);
  const storesRecordatoryText = useFormatMessage(
    textIds.storesRecordatoryText,
    {
      phone: profile?.support_phone ?? '',
    },
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(PartnerActions.getProfile());
  }, []);

  useEffect(() => {
    if (currentPathName) {
      dispatch(PartnerActions.getSuspendedStores());
    }
  }, [dispatch, currentPathName]);

  return (
    <MainLayout title={title}>
      <div className={_classes.root}>
        <CustomBlock title={generalInformationTitle}>
          <div className={_classes.generalTextFieldsContainer}>
            <CustomTextField
              classes={{ root: _classes.nameTextFieldRoot }}
              label={nameTextFieldLabel}
              name='name'
              placeholder='name'
              useExternalLabel
              value={`${firstName} ${lastName}`}
            />
            <CustomTextField
              classes={{ root: _classes.emailTextFieldRoot }}
              label={emailTextFieldLabel}
              name='email'
              placeholder='email'
              useExternalLabel
              value={email}
            />
            <CustomTextField
              classes={{ root: _classes.idTextFieldRoot }}
              label={idTextFieldLabel}
              name='partner ID'
              placeholder='partner ID'
              useExternalLabel
              value={partnerId}
            />
          </div>
        </CustomBlock>
        <CustomBlock title={storesTitle}>
          <div className={_classes.storesContainer}>
            <CustomAlert
              classes={{ root: _classes.customAlertRoot }}
              text={storesRecordatoryText}
            />
            {stores.map(partnerStore => (
              <StoreBox key={partnerStore.id} partnerStore={partnerStore} />
            ))}
          </div>
        </CustomBlock>
      </div>
    </MainLayout>
  );
}
