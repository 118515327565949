// Redux
import { createActions } from 'redux-actions';

const {
  closeSuccessfulProxySessionDialogAction,
  clearSuccessfulProxySessionDialogAction,
  openSuccessfulProxySessionDialogAction,
} = createActions({
  CLOSE_SUCCESSFUL_PROXY_SESSION_DIALOG_ACTION: () => {},
  CLEAR_SUCCESSFUL_PROXY_SESSION_DIALOG_ACTION: () => {},
  OPEN_SUCCESSFUL_PROXY_SESSION_DIALOG_ACTION: (orderId, callFrom, callTo) => ({
    orderId,
    callFrom,
    callTo,
  }),
});

const clearSuccessfulProxySessionDialog = () => dispatch => {
  dispatch(clearSuccessfulProxySessionDialogAction());
};

const closeSuccessfulProxySessionDialog = () => dispatch => {
  dispatch(closeSuccessfulProxySessionDialogAction());
};

const openSuccessfulProxySessionDialog = (
  orderId,
  callFrom,
  callTo,
) => dispatch => {
  dispatch(openSuccessfulProxySessionDialogAction(orderId, callFrom, callTo));
};

export default {
  clearSuccessfulProxySessionDialog,
  clearSuccessfulProxySessionDialogAction,
  closeSuccessfulProxySessionDialog,
  closeSuccessfulProxySessionDialogAction,
  openSuccessfulProxySessionDialog,
  openSuccessfulProxySessionDialogAction,
};
