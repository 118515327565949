import { ordermgmtDialogFacade } from '../../../../../business/order-mgmt';

export const useOrderMgmtReplaceProductDialog = () => {
  const {
    selectReplaceProductDialogIsOpen,
    selectReplaceProductIdDialog,
    onCloseReplaceProductDialog,
    onUncheckProductToppings,
  } = ordermgmtDialogFacade();

  return {
    isOpen: selectReplaceProductDialogIsOpen,
    replaceProductId: selectReplaceProductIdDialog,
    onClose: onCloseReplaceProductDialog,
    onContinue: onUncheckProductToppings,
  };
};
