import { orderMgmtTimeoutCase } from '../../../../../business/order-mgmt';

export function useOrderActions() {
  const {
    hasChangesOrder,
    undoChanges,
    isOpenDialog,
    setOpenDialog,
    acceptOrder,
  } = orderMgmtTimeoutCase();

  const handleConfirm = () => {
    if (hasChangesOrder) {
      setOpenDialog(true);
      return;
    }
    acceptOrder({ action: 'accepted' });
  };

  return {
    showUndoChanges: hasChangesOrder,
    undoChanges,
    isOpenDialog,
    handleConfirm,
  };
}
