import { clsx } from 'clsx';

import CustomActionKanbanCard from '../../components/common/custom-action-kanban-card';

import useStyles from './CancelKanbanCard.styles';

function CancelKanbanCard(props) {
  const {
    classes = {},
    onClickCard,
    order: { id, store_name } = {},
    showChipOfBundle = false,
    totalStores,
  } = props;

  const _classes = useStyles();

  return (
    <CustomActionKanbanCard
      classes={{ root: clsx(_classes.root, classes?.root) }}
      onClick={() => {
        onClickCard(id);
      }}
      storeName={totalStores > 1 ? store_name : null}
      orderID={id}
      showChipOfBundle={showChipOfBundle}
    />
  );
}

export default CancelKanbanCard;
