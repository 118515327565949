import { clsx } from 'clsx';
import { Typography } from '@material-ui/core';

import useStyles from './OrderDetailDialogMainSectionItem.styles';

function OrderDetailDialogMainSectionItem(props) {
  const { children, title, classes = {} } = props;
  const _classes = useStyles();
  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <div className={_classes.titleWrapper}>
        <Typography className={_classes.title}>{title}</Typography>
      </div>
      <div className={clsx(_classes.body, classes?.body)}>{children}</div>
    </div>
  );
}

export default OrderDetailDialogMainSectionItem;
