import { Link as RouterLink } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import { useStartupPrinterBanner } from './hooks';

import imgSrcLogoRappi from '../../../../assets/images/bigote.png';
import makeClasses from './StartupPrinterBanner.style';

export function StartupPrinterBanner() {
  const {
    show,
    lang,
    goTo,
    handlePrimaryBtn,
    handleClose,
  } = useStartupPrinterBanner();

  const _classes = makeClasses();

  if (!show) return null;
  return (
    <div role='alert' aria-live='off' className={_classes.alert}>
      <img
        aria-hidden={true}
        alt='icon-logo-rappi'
        src={imgSrcLogoRappi}
        className={_classes.icon}
      />

      <Typography
        dangerouslySetInnerHTML={{ __html: lang.msg }}
        classes={{ root: _classes.msg }}
      />

      <Button
        component={RouterLink}
        to={goTo.cta}
        onClick={handlePrimaryBtn}
        color='primary'
        variant='contained'
        classes={{ contained: _classes.ctaBtn, label: _classes.ctaLabel }}
      >
        {lang.cta}
      </Button>

      <CloseRoundedIcon aria-label='close' onClick={handleClose} />
    </div>
  );
}
