import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core';

import BackArrowIcon from '../../../icons/back-arrow-icon/BackArrowIcon';

import hooks from './hooks';
import appStore from '../../../../redux/stores/app.store';
import EventActions from '../../../../redux/actions/event-actions/event.actions';
import { actions as helpCenterActions } from '../../../../redux/help-center-page';

import Paths from '../../../../constants/Paths';
import useStyles from './style';

export function SubCategoryMobile() {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedCategory = hooks.useSection();
  const selectedCategoryName = selectedCategory.name;
  const componentClasses = useStyles();
  const category = hooks.useSelectedCategory();
  const selectedSubCategory = hooks.useSelectedSubCategoryId();
  const categoryId = category.id ?? '';
  const partnerId = appStore.getPartnerId();
  const categoryName = category.name ?? '';
  const subCategories = category.sections ?? [];

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <>
      <div className={componentClasses.root}>
        <div className={componentClasses.header}>
          <div className={componentClasses.arrowIcon} onClick={handleBack}>
            <BackArrowIcon />
          </div>
          {selectedCategoryName}
        </div>
        <>
          {subCategories.map((subCategory, index: number) => {
            return (
              <List key={`subcategory-${index}`} component='nav'>
                <ListItem
                  button
                  onClick={() => {
                    handleClick();
                    dispatch(
                      helpCenterActions.setSelectedSubCategoryId(
                        subCategory.id,
                      ),
                    );
                  }}
                  selected={subCategory.id === selectedSubCategory}
                >
                  <ListItemText primary={subCategory.name} />
                  {open ? <ExpandMore /> : <ChevronRightIcon />}
                </ListItem>
                <Collapse in={open} timeout='auto' unmountOnExit>
                  {subCategory.articles.map((article, index: number) => {
                    return (
                      <List
                        component='div'
                        disablePadding
                        key={`article-${index}`}
                      >
                        <ListItem
                          button
                          className={componentClasses.nested}
                          onClick={() => {
                            EventActions.onSuccessfulArticle(article.id);
                            EventActions.onSuccessSelectedArticle(
                              partnerId,
                              categoryName,
                              subCategory.name,
                              article.name,
                            );
                            navigate(
                              Paths.goToHelpCenterWithCategoryAndArticle(
                                categoryId,
                                article.id,
                              ),
                            );
                            dispatch(
                              helpCenterActions.setSelectedSubCategoryId(
                                subCategory.id,
                              ),
                            );
                            dispatch(
                              helpCenterActions.loadSelectedArticle(article.id),
                            );
                          }}
                        >
                          <ListItemText primary={article.name} />
                        </ListItem>
                      </List>
                    );
                  })}
                </Collapse>
              </List>
            );
          })}
        </>
      </div>
    </>
  );
}
