import { makeStyles } from '@material-ui/core';

import { neutral } from '../../shared/styles/colors';

export const orderBillStyles = makeStyles(theme => {
  return {
    root: {
      display: 'none',
      '@media print': {
        display: 'block',
        fontFamily: 'Poppins',
        // fontFamily: 'Lucida Console',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '0.625rem',
        margin: '1rem',
        '-webkit-print-color-adjust': 'exact !important' /* Chrome, Safari */,
        'color-adjust': 'exact !important',
      },
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    orderId: {
      backgroundColor: `${neutral['100']} !important`,
      textAlign: 'center',
      fontSize: '14px',
      color: neutral.white,
    },
    orderIdBox: {
      backgroundColor: `${neutral.white} !important`,
      textAlign: 'center',
      fontSize: '22px',
      color: neutral['100'],
    },
    infoBox: {
      width: '100%',
      display: 'grid',
    },
    containerProducts: {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
    },
    containerTopping: {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
    },
    indentForTopping: {
      paddingLeft: '20px',
    },
    toppingFont: {
      paddingLeft: '27px',
      fontSize: '0.625rem',
      fontFamily: 'Poppins',
      // fontFamily: 'Lucida Console',
      fontStyle: 'normal',
      fontWeight: '400',
    },
    containerWhim: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
    },
    totalContainer: {
      backgroundColor: 'black',
      color: neutral.white,
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 700,
      fontFamily: 'Poppins',
      // fontFamily: 'Lucida Console Bold',
      padding: '2px 4px',
    },
    totalDetailsRoot: {
      borderTop: `1px solid ${neutral['100']}`,
      padding: '4px 0px',
    },
    discountContainer: {
      borderTop: `1px solid ${neutral['100']}`,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      color: neutral['100'],
      fontStyle: 'normal',
      fontWeight: '400',
      fontFamily: 'Poppins',
      // fontFamily: 'Lucida Console Bold',
    },
    fontText: {
      fontSize: '10px',
      fontFamily: 'Poppins',
      // fontFamily: 'Lucida Console',
      fontStyle: 'normal',
      fontWeight: '400',
    },
    fontTextComment: {
      fontSize: '0.625rem',
      fontFamily: 'Poppins',
      // fontFamily: 'Lucida Console',
      fontStyle: 'normal',
      fontWeight: '400',
      textAlign: 'center',
    },
    fontWhimTitle: {
      borderBottom: `1px solid ${neutral['100']}`,
      fontSize: '0.625rem',
      fontFamily: 'Poppins',
      // fontFamily: 'Lucida Console',
      fontStyle: 'normal',
      fontWeight: '400',
    },
    fontTextWhim: {
      fontSize: '0.625rem',
      fontFamily: 'Poppins',
      // fontFamily: 'Lucida Console',
      fontStyle: 'normal',
      fontWeight: '400',
      paddingLeft: '4px',
    },
    cutLine: {
      borderStyle: 'dotted',
      width: '100%',
    },
    left: {
      float: 'left',
      color: 'black',
    },
    right: {
      float: 'right',
      color: neutral['100'],
    },
    blackLine: {
      backgroundColor: `${neutral['100']} !important`,
      textAlign: 'center',
      color: neutral.white,
      borderTop: `1px solid ${neutral.white}`,
    },
    orderTotalsErrorMessageContainer: {
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: 700,
      fontFamily: 'Poppins',
      // fontFamily: 'Lucida Console Bold',
    },
    containerMostCharge: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
    },
    cutPage: {
      paddingTop: '1rem',
      borderBottom: `1px dashed ${neutral.black}`,
    },
    qrWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: neutral.white,
    },
    scanQr: {
      textAlign: 'center',
      fontSize: '12px',
      color: neutral['100'],
      marginTop: '0.5rem',
    },
    qrCodeContainer: {
      width: '112px',
      height: '112px',
      overflow: 'hidden',
      display: 'flex',
      margin: '0.5rem 0',
      justifyContent: 'center',
      alignItems: 'center',
    },
    qrCode: props => ({
      backgroundImage: `url(data:image/jpeg;base64,${props?.qrCode})`,
      backgroundSize: 'cover',
      margin: 0,
      padding: 0,
      width: '160px',
      height: '160px',
    }),
    exclusiveUse: {
      textAlign: 'center',
      fontSize: '0.5625rem',
      color: neutral['100'],
    },
  };
});
