// Material UI
import { makeStyles } from '@material-ui/core'
// Consts
import Colors from '../../constants/Colors'

export default makeStyles(theme => ({
  customAlertRoot: {},
  root: {
    border: `1px solid ${Colors.WARNING_FONT_COLOR}`,
    padding: 0,
  },
}))
