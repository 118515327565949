import { makeStyles } from '@material-ui/core';

export default makeStyles({
  nextInterval: {
    fontFamily: 'Poppins Bold',
    fontSize: '1rem',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    marginLeft: '0.75rem',
  },
  root: {
    display: 'flex',
    alignItems: 'center',
  },
});
