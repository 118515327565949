import React from 'react';

const CustomMarker = ({ className, children, lat, lng }) => {
  return (
    <div className={className} lat={lat} lng={lng}>
      {children}
    </div>
  );
};

export default CustomMarker;
