// Consts
import urls from '../constants/Urls';
// Services
import LocalStorageService from './local-storage-service';
import http from './HttpService';

const getSettings = async () => {
  const baseUrl = LocalStorageService.getBaseUrl();
  return http.get(`${baseUrl}${urls.GET_SETTINGS}`);
};

const setAutoAccept = async enable => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const payload = {
    enable,
  };
  return http.put(`${baseUrl}${urls.SET_AUTO_ACCEPT}`, payload);
};

export default {
  getSettings,
  setAutoAccept,
};
