import { clsx } from 'clsx';
import { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';

import CustomTextField from '../common/custom-text-field';

import useStyles from './MenuSearchField.styles';

// TODO: move to constants or variable states into the component
const timeOutValue = 500;
let searchTimeOut;

function MenuSearchField(props) {
  const {
    autoFocus = false,
    classes = {},
    onChange = () => {},
    placeHolder,
    searchText,
    // partnerId, // from container
  } = props;

  const _classes = useStyles();
  const [text, setText] = useState(searchText);

  return (
    <CustomTextField
      autoFocus={autoFocus}
      classes={{
        textFieldRoot: clsx(_classes.root, classes?.root),
        input: clsx(_classes.input, classes?.input),
      }}
      placeholder={placeHolder}
      onChange={e => {
        const value = e.currentTarget.value;
        setText(value);
        if (!!searchTimeOut) {
          clearTimeout(searchTimeOut);
        }
        searchTimeOut = setTimeout(() => {
          onChange(value);
        }, timeOutValue);
      }}
      fullWidth={true}
      endAdornment={
        <SearchIcon className={clsx(_classes.icon, classes?.icon)} />
      }
      value={text}
    />
  );
}

export default MenuSearchField;
