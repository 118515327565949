export const transformDateToString = (date: Date) => {
  const Month =
    date?.getMonth() + 1 < 10
      ? `0${date?.getMonth() + 1}`
      : date?.getMonth() + 1
  const Day = date?.getDate() < 10 ? `0${date?.getDate()}` : date?.getDate()
  return `${date?.getUTCFullYear()}-${Month}-${Day}`
}

export const transformStringToDate = (date: string) => {
  if (date) {
    const Year = Number(date.split('-')[0])
    const Month = Number(date.split('-')[1]) - 1
    const Day = Number(date.split('-')[2])
    const TransformedDate = new Date(Year, Month, Day)
    return TransformedDate
  }

  return null
}
