import { AddCircleTwoTone } from '@material-ui/icons';
import { Button, useMediaQuery } from '@material-ui/core';

import MainLayout from '../main-layout';
import CustomLoader from '../common/custom-loader';
import OrderHistoryCard from '../common/order-history-card';
import CustomAlert from '../common/custom-alert/CustomAlert';
import { CustomErrorStateBlock } from '../common/custom-error-state-block';
import { CustomStateBlock } from '../common/custom-state-block';
import OrdersHistoricalAdvancedFilterToolbar from './../orders-historical-advanced-filter-toolbar';
import { OrdersHistoricalHeader } from './components/orders-historical-header/OrdersHistoricalHeader';

import OrdersUtils from '../../utils/OrdersUtils';
import { useOrdersHistoricalLang } from './hooks';

import useStyles from './OrdersHistorical.styles';

function OrdersHistorical(props) {
  const {
    errorText = null,
    history,
    getOrdersHistorical,
    getQualifyNegativeReasons,
    getOrderDetail,
    currentPage,
    isLoading,
    selectedStoreId,
    ordersHistorical,
    profile,
    qualifyPositiveRT,
    qualifyNegativeRT,
    showEmptyState = true,
    showErrorState = false,
    showTable = false,
  } = props;

  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  const classes = useStyles();

  const texts = useOrdersHistoricalLang({ isMdUp });

  function handleGetNextOrderHistory() {
    getOrdersHistorical(selectedStoreId, currentPage + 1);
  }

  return (
    <MainLayout history={history} title={texts.title}>
      <main className={classes.root} data-tag={`${texts.title} (page)`}>
        <OrdersHistoricalAdvancedFilterToolbar />
        <section
          data-tag='order history table'
          className={classes.orderHistoricalTableContainer}
        >
          <CustomAlert text={texts.info} classes={{ root: classes.alert }} />
          <CustomLoader open={isLoading} />
          {showEmptyState && <CustomStateBlock text={texts.emptyState} />}
          {showErrorState && <CustomErrorStateBlock text={errorText} />}
          {showTable && (
            <>
              <OrdersHistoricalHeader />
              <div role='table' data-tag='order list'>
                {ordersHistorical.map((order, index) => {
                  const storekeeper = order?.storekeeper;
                  const full_name = storekeeper?.full_name;
                  const storekeeperId = storekeeper?.id;
                  const profile_pic = storekeeper?.profile_pic;
                  const showQualifyRT =
                    OrdersUtils.isFullDelivery(order.delivery_method) &&
                    !!storekeeper &&
                    !!storekeeperId;
                  return (
                    <OrderHistoryCard
                      key={`order-${order.id}-${index}`}
                      orderState={order.state}
                      orderId={order.id}
                      createdAt={order.createAtFormated}
                      price={order.total_real_price}
                      priceWithDiscount={order?.total_neto}
                      incident={order.incident}
                      deliveryMethod={order.delivery_method}
                      fullName={full_name}
                      profilePic={profile_pic}
                      history={history}
                      getOrderDetail={getOrderDetail}
                      getQualifyNegativeReasons={getQualifyNegativeReasons}
                      qualifyPositiveRT={qualifyPositiveRT}
                      qualifyNegativeRT={qualifyNegativeRT}
                      showQualifyRT={showQualifyRT}
                      storeId={order?.store_id ?? selectedStoreId}
                      storekeeperId={storekeeperId}
                      partnerId={profile.id}
                    />
                  );
                })}
              </div>
              <Button
                fullWidth
                size='large'
                variant='contained'
                className={classes.pagination}
                onClick={handleGetNextOrderHistory}
                startIcon={
                  <AddCircleTwoTone color='primary' fontSize='large' />
                }
              />
            </>
          )}
        </section>
      </main>
    </MainLayout>
  );
}

export default OrdersHistorical;
