import { clsx } from 'clsx';
import { useMediaQuery, MenuItem } from '@material-ui/core';
import { useMemo, useCallback, useState, useEffect } from 'react';

import CustomTextField from '../custom-text-field';
import { CustomTimePicker } from '../custom-time-picker';

import useStyles from './styles';

import type { ChangeEvent } from 'react';
import type { Theme } from '@material-ui/core';
import type { BaseProps } from '../../../interfaces';

export function CustomResponsiveTimePicker(props: Props) {
  const {
    value = '',
    listOfHours = [],
    classes = {},
    onHandleChange = () => {},
  } = props;

  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [localHourList, setLocalHourList] = useState(listOfHours);

  useEffect(() => {
    let newHourList = [...listOfHours];
    const isValueRepeat = listOfHours.some(h => h === value);
    if (!isValueRepeat) {
      newHourList.push(value);
      newHourList = newHourList.sort((a, b) => {
        let time1 = parseFloat(a.replace(':', '.').replace(/[^\d.-]/g, ''));
        let time2 = parseFloat(b.replace(':', '.').replace(/[^\d.-]/g, ''));
        if (time1 < time2) return -1;
        if (time1 > time2) return 1;
        return 0;
      });
    }
    setLocalHourList(newHourList);
  }, [value, listOfHours]);

  const mobileValueFormat = useMemo(() => new Date(`2021-01-01T${value}:00`), [
    value,
  ]);

  const _classes = useStyles();

  const onMDChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onHandleChange(event.target.value ?? '');
    },
    [onHandleChange],
  );

  const onMobileChange = useCallback(
    (date: Date) => {
      const formattedDate = date.toLocaleTimeString('default', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
      onHandleChange(formattedDate);
    },
    [onHandleChange],
  );

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      {isMdUp && (
        <CustomTextField
          classes={{
            root: _classes.timePickerBoxRoot,
            textFieldRoot: _classes.timePickerBox,
            input: _classes.timePickerInput,
          }}
          variant='outlined'
          select
          value={value}
          onChange={onMDChange}
          SelectProps={{ IconComponent: () => <div></div> }}
        >
          {localHourList.map((option: any[], index: number) => (
            <MenuItem
              classes={{
                root: _classes.menuItemRoot,
              }}
              key={`select-${index}`}
              value={option}
            >
              {option}
            </MenuItem>
          ))}
        </CustomTextField>
      )}
      {!isMdUp && (
        <CustomTimePicker
          fullWidth
          classes={{
            inputPropsInput: _classes.timePickerInput,
            inputPropsRoot: _classes.timePickerBoxRoot,
          }}
          onChange={onMobileChange}
          value={mobileValueFormat}
          label={''}
        />
      )}
    </div>
  );
}

interface Props extends BaseProps {
  value: string;
  listOfHours: any[];
  onHandleChange: (time: string) => void;
}
