// External libs
import { createActions } from 'redux-actions';

const {
  closeSuspendedStoreAlertSnackbarAction,
  openSuspendedStoreAlertSnackbarAction,
} = createActions({
  CLOSE_SUSPENDED_STORE_ALERT_SNACKBAR_ACTION: message => ({ message }),
  OPEN_SUSPENDED_STORE_ALERT_SNACKBAR_ACTION: message => ({ message }),
});

const close = () => dispatch => {
  dispatch(closeSuspendedStoreAlertSnackbarAction());
};

const open = message => dispatch => {
  dispatch(openSuspendedStoreAlertSnackbarAction(message));
};

const SuspendedStoreAlertSnackbarActions = {
  close,
  closeSuspendedStoreAlertSnackbarAction,
  open,
  openSuspendedStoreAlertSnackbarAction,
};

export default SuspendedStoreAlertSnackbarActions;
