import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  hasChromeExtension,
  isChromeWithoutExclusions,
} from '../../../../../business/common/browser';
import appStore from '../../../../../redux/stores/app.store';
import lsService from '../../../../../services/local-storage-service/LocalStorageService';
import { printerIsNotifiedFirstService } from '../../infra';
import { useStartupPrinterDialogLang } from './useStartupPrinterDialogLang';

import { PartnerId } from 'business/shared/domain';
import { printerAnalytics } from 'features/startup-notifications/infra/analytics';
import paths from '../../../../../constants/Paths';

export function useStartupPrinterDialog() {
  const [open, setOpen] = useState(false);
  const [hasChromeOpts, setHasChromeOpts] = useState(false);

  const {
    isNotifiedFirstMode,
    setIsNotifiedFirstMode,
  } = printerIsNotifiedFirstService();
  const partnerId: PartnerId = useSelector(
    ({ partnerReducer }: any) => partnerReducer?.profile?.id,
  );

  const lang = useStartupPrinterDialogLang();

  const _handleOnClose = () => {
    setIsNotifiedFirstMode();
    setOpen(false);
  };

  const handlePrimaryBtn = () => {
    _handleOnClose();
    printerAnalytics.logPrinterCampaign({
      partnerId,
      clickOptions: 'configure_printer',
      type: 'inapp',
    });
  };

  const handleSecondaryBtn = () => {
    _handleOnClose();
    printerAnalytics.logPrinterCampaign({
      partnerId,
      clickOptions: 'know_more',
      type: 'inapp',
    });
  };

  const handleCloseDialog = () => {
    _handleOnClose();
    printerAnalytics.logPrinterCampaign({
      partnerId,
      clickOptions: 'close',
      type: 'inapp',
    });
  };

  useEffect(() => {
    _hasChromeOptions().then(result => setHasChromeOpts(result));
  }, []);

  useEffect(() => {
    const isIntegratedPartner = appStore.getIfPartnerIsIntegrated();
    const isActivatedCommand = lsService.getIsActivatedComanda();
    const conditionByPartner = !isIntegratedPartner && hasChromeOpts;
    const conditionByPrinter = !isNotifiedFirstMode && !isActivatedCommand;
    if (conditionByPartner && conditionByPrinter) setOpen(true);
  }, [hasChromeOpts, isNotifiedFirstMode]);

  return {
    open,
    lang,
    anchorHref: {
      knowMore: paths.goToHelpCenterWithCategoryAndArticle(
        '5f20af6cefb229001249e6ff',
        '5f50188118dc5d001a78d0f5',
      ),
      configPrinter: paths.SETTINGS,
    },
    handlePrimaryBtn,
    handleSecondaryBtn,
    handleCloseDialog,
  };
}

// TODO: move to a common file o utility/helper file
async function _hasChromeOptions() {
  const isUsedChrome = isChromeWithoutExclusions();
  const hasExtension = await hasChromeExtension();
  return isUsedChrome && Boolean(hasExtension);
}
