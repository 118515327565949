// Material UI
import { makeStyles } from '@material-ui/core'
import Colors from '../../constants/Colors'

export default makeStyles(theme => ({
  icon: {
    gridArea: 'icon',
  },
  storeName: {
    gridArea: 'storeName',
    alignSelf: 'center',
  },
  root: {
    borderRadius: 8,
    padding: 16,
    background: Colors.CORNFLOWER_BLUE,
    display: 'grid',
    gridTemplateAreas: `"icon storeName"`,
    gridTemplateRows: 'min-content auto',
    columnGap: 16,
    gridTemplateColumns: 'min-content auto',
    fontSize: 16,
    fontWeight: 'bold',
  },
}))
