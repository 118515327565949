import { DEFAULT_ZOOM, MIN_ZOOM } from './RTMap.const';

function calcLinearDist(lat1, lng1, lat2, lng2) {
  return Math.sqrt((lat1 - lat2) ** 2 + (lng1 - lng2) ** 2);
}

function calcZoom(lat1, lng1, lat2, lng2) {
  const linearDist = calcLinearDist(lat1, lng1, lat2, lng2);
  const OUR_LIMIT = 0.02,
    OUR_STEP = 0.01;
  return DEFAULT_ZOOM - Math.ceil((linearDist - OUR_LIMIT) / OUR_STEP);
}

export function getCenterAndZoomToMap(lat1, lng1, lat2 = 0, lng2 = 0) {
  const center = {
    lat: (lat1 + lat2) / (lat2 !== 0 ? 2 : 1),
    lng: (lng1 + lng2) / (lng2 !== 0 ? 2 : 1),
  };
  const zoom = calcZoom(lat1, lng1, lat2, lng2);
  const isMinZoom = zoom < MIN_ZOOM;
  return {
    center: isMinZoom ? { lat: lat1, lng: lng1 } : center,
    zoom: isMinZoom ? DEFAULT_ZOOM : zoom,
  };
}
