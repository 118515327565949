import type { PartnerId } from 'business/shared/domain';
import { printerAnalytics } from 'features/startup-notifications/infra/analytics';
import lsService from '../../../services/local-storage-service/LocalStorageService';

function _getCurrentDateInSec() {
  return Math.trunc(Date.now() / 1_000);
}

export function runAttemptsToEnablePrinting(
  isActive: boolean,
  partnerId: PartnerId,
) {
  const WEEK_IN_SECONDS = 604_800;
  const AMOUNT_ATTEMPTS_TO_ALLOW = 2;
  const KEY = 'attempts_to_print_order';

  if (!isActive) {
    const INITIAL_ATTEMPTS = JSON.stringify({
      amount: 0,
      firstDate: _getCurrentDateInSec(),
    });
    const attempts = JSON.parse(localStorage.getItem(KEY) || INITIAL_ATTEMPTS);
    const diffTimeInSec = attempts.firstDate - _getCurrentDateInSec();
    const isWithinTimeLimit = diffTimeInSec < WEEK_IN_SECONDS;

    if (!isWithinTimeLimit) localStorage.setItem(KEY, INITIAL_ATTEMPTS);
    else if (isWithinTimeLimit && attempts.amount < AMOUNT_ATTEMPTS_TO_ALLOW) {
      localStorage.setItem(
        KEY,
        JSON.stringify({ ...attempts, amount: attempts.amount + 1 }),
      );
    } else {
      localStorage.removeItem(KEY);
      lsService.setIsActivatedComanda('1');
      lsService.setIsActivatedPrinterChecklist('1');
      lsService.setAutoPrintEnabled(false);
      printerAnalytics.logPrinterSetup({
        partnerId,
        printerStatus: true,
        automaticPrinterTicket: false,
        printsChecklist: true,
      });
    }
  }
}
