const badlyConfiguredProductLabel = {
  id: 'orderRejectionByStockOut.badlyConfiguredProduct',
};

const confirmButtonText = {
  id: 'globals.confirm',
};

const noLongerHandleProductLabel = {
  id: 'orderRejectionByStockOut.noLongerHandleProduct',
};

const ranOutProductsLabel = {
  id: 'orderRejectionByStockOut.ranOutProducts',
};

const textIds = {
  badlyConfiguredProductLabel,
  confirmButtonText,
  noLongerHandleProductLabel,
  ranOutProductsLabel,
};

export default textIds;
