// Material UI
import { makeStyles } from '@material-ui/core'
// Consts
import Colors from '../../../constants/Colors'

export default makeStyles({
  intervalsContainer: {
    display: 'grid',
    gridRowGap: 10,
  },
  root: {
    backgroundColor: Colors.WHITE,
  },
})
