import { storeCtaOpts } from '../../../../../../components/store-status/constants';
import type {
  TpStoreCtaOpts,
  TpStoreStatusStatus,
} from '../../../../../../components/store-status/types';
import { useFormatMessage } from '../../../../../../intl/intl';

export function useEmptyStoreStatusTexts(props: Props) {
  const { status, cta } = props;

  const title = `highDemand.state.${status}.title`;
  const subTitle = `highDemand.state.${status}.subTitle`;

  return {
    title: useFormatMessage({ id: title }),
    subTitle: useFormatMessage({ id: subTitle }),
    cta: useFormatMessage({ id: storeCtaOpts[cta].textId }),
  };
}

interface Props {
  status: TpStoreStatusStatus;
  cta: TpStoreCtaOpts;
}
