import { clsx } from 'clsx';

import { useFormatMessage } from '../../intl/intl';
import Hooks from './hooks';

import OrderDetailDialogMainSectionItem from '../order-detail-dialog-main-section-item';
import CustomAlert from '../common/custom-alert';
import DollarSignIcon from '.././icons/dollar-sign-icon';
import CustomChip from '../common/custom-chip';
import { PickupLabel } from '../pickup-label';

import useStyles from './styles';
import { Serverities } from '../../constants/Severities';
import Colors from '../../constants/Colors';

export function OrderDetailDialogPickupBox() {
  const totalCharge = Hooks.useTotalCharge();

  const payByRappi = useFormatMessage({
    id: 'orderDetailDialogPickupBox.payByRappi',
  });
  const payInCash = useFormatMessage({
    id: 'orderDetailDialogPickupBox.payInCash',
  });
  const description = useFormatMessage({
    id: 'orderDetailDialogPickupBox.description',
  });
  const title = useFormatMessage({
    id: 'orderDetailDialogPickupBox.title',
  });
  const showTotalCharge = totalCharge > 0;
  const _classes = useStyles();

  return (
    <OrderDetailDialogMainSectionItem
      classes={{ body: _classes.root }}
      title={title}
    >
      <PickupLabel
        classes={{
          root: _classes.pickUpChipContainer,
          iconRoot: _classes.iconRoot,
        }}
      />
      {showTotalCharge ? (
        <CustomChip
          classes={{
            root: clsx(_classes.chipRoot, _classes.payInCashChip),
            label: _classes.label,
          }}
          icon={<DollarSignIcon color={Colors.WHITE} />}
          label={payInCash}
        />
      ) : (
        <CustomChip
          classes={{
            root: _classes.chipRoot,
            label: _classes.label,
          }}
          icon={<DollarSignIcon color={Colors.BLACK} />}
          label={payByRappi}
        />
      )}
      <CustomAlert
        classes={{ root: _classes.description }}
        text={description}
        type={Serverities.INFO}
      />
    </OrderDetailDialogMainSectionItem>
  );
}
