import { clsx } from 'clsx';

import { CheckerItem } from './components/checker-item';

import StringUtils from '../../utils/StringUtils';
import useStyles from './styles';

import type { BaseProps } from '../../interfaces';
import type { CheckerItemProps } from './components/checker-item';

export function OrderProductsToppingsChecker(props: Props) {
  const {
    classes = {},
    label,
    products,
    selectedProducts,
    selectedToppings,
    onProductSelection,
    onToppingSelection,
  } = props;

  const _classes = useStyles();

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      {!StringUtils.isNullOrEmpty(label) && (
        <div className={clsx(_classes.label, classes?.label)}>{label}</div>
      )}
      {products.length > 0 && (
        <div className={_classes.productsContainer}>
          {products.map((product: any, index: number) => {
            return (
              <CheckerItem
                key={`p-${index}`}
                product={product}
                selectedProducts={selectedProducts}
                selectedToppings={selectedToppings}
                onProductSelection={onProductSelection}
                onToppingSelection={onToppingSelection}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

interface Props extends Omit<CheckerItemProps, 'product'>, BaseProps {
  label?: string | null;
  products: any[];
  selectedProducts: number[];
  selectedToppings: number[];
  onProductSelection: (selectedProducts: number[]) => void;
  onToppingSelection: (selectedToppings: number[]) => void;
}
