import type { AddRTGlobalState } from '../../../domain';

export const ADD_RT_SLICE_NAME: string = 'cargoAddRT';

export const INIT_ADD_RT_STATE: AddRTGlobalState = {
  selectedOrderId: null,
  status: 'idle',
  error: null,
  validatedData: null,
  createdAddRT: null,
};
