import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../shared/styles/colors';

export default makeStyles({
  root: {
    position: 'sticky',
    top: 0,
    backgroundColor: neutral[10],
  },
  chipsRoot: {},
  toolBarRoot: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${neutral['20']}`,
    fontFamily: 'Poppins',
    fontSize: '1rem',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    color: neutral['90'],
    backgroundColor: neutral.white,
  },
});
