import { useFormatMessage } from '../../../../../intl/intl';

export function useDialogDeleteWhimFromOrderLang() {
  return {
    title: useFormatMessage({ id: 'deleteOrderWhim.title' }),
    description: useFormatMessage({ id: 'deleteOrderWhim.description' }),
    confirmBtn: useFormatMessage({ id: 'globals.confirm' }),
    snackbar: {
      success: {
        title: useFormatMessage({
          id: 'orderDetailWhim.successSnackBar.title',
        }),
        msg: useFormatMessage({ id: 'orderDetailWhim.deleteSnackBar.message' }),
      },
    },
  };
}
