import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';

import RtAvatar from '../rt-avatar';

import useStyles from './RTDetailBox.styles';
import RTStateLabel from '../rt-state-label/RTStateLabel';
import { RtArrivesOrWaitingTime } from '../rt-arrives-or-waiting-time';

function RTDetailBox(props) {
  const {
    rt = {},
    rtStateText = '',
    activeStepIndex,
    rtStateBtnText,
    sendNotificationToRT,
    isRtWaitingMuch = false,
    orderId,
  } = props;

  const componentClasses = useStyles();
  return (
    <div className={componentClasses.root}>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        gridColumnGap='1rem'
      >
        <RtAvatar
          classes={{
            avatarRoot: componentClasses.avatarRoot,
            root: componentClasses.rtAvatarRoot,
          }}
          rtName={rt.first_name}
          rtPhoto={rt.profile_pic}
        />
        <div className={componentClasses.infoContainer}>
          <Typography classes={{ root: componentClasses.name }}>
            {rt.first_name}
          </Typography>
          {!!rt.phone && (
            <Typography className={componentClasses.secondaryInfo}>
              {`Tel: ${rt.phone}`}
            </Typography>
          )}
        </div>
      </Box>

      <Box className={componentClasses.rtStateContainer}>
        <RTStateLabel
          classes={{
            root: componentClasses.rtState,
            iconRoot: componentClasses.rtStateIcon,
          }}
          rtStep={activeStepIndex}
          rtStepMessage={rtStateText}
          isRtWaitingMuch={isRtWaitingMuch}
        />

        {rtStateBtnText && (
          <Button
            fullWidth
            color='primary'
            variant='contained'
            className={componentClasses.rtStateBtn}
            onClick={() => sendNotificationToRT(orderId)}
          >
            {rtStateBtnText}
          </Button>
        )}

        <RtArrivesOrWaitingTime orderId={orderId} />
      </Box>
    </div>
  );
}

export default RTDetailBox;
