// External libs
import _ from 'lodash';
// Consts
import OrderStepType from '../constants/OrderStepType';

const getActiveStep = steps => {
  if (!steps || !steps.length) {
    return null;
  }
  const index = getActiveStepIndex(steps);
  if (index === null || index === undefined) {
    return null;
  }
  return steps[index];
};

const getActiveStepIndex = steps => {
  if (!steps || !steps.length) {
    return null;
  }
  const onIndex = _.findLastIndex(
    steps,
    step => step.state === OrderStepType.ON,
  );
  const alertedIndex = _.findLastIndex(
    steps,
    step => step.state === OrderStepType.ALERTED,
  );

  return onIndex > alertedIndex ? onIndex : alertedIndex;
};

const getRTsInStoreTotalFromCourriesStoreInfo = couriersStoreInfo => {
  if (!couriersStoreInfo || !couriersStoreInfo.length) {
    return 0;
  }

  return _.reduce(
    couriersStoreInfo,
    (total, courrierInfo) => {
      return total + _.get(courrierInfo, 'couriers_info.in_store', 0);
    },
    0,
  );
};

const getRTsOnTheWayTotalFromCourriesStoreInfo = couriersStoreInfo => {
  if (!couriersStoreInfo || !couriersStoreInfo.length) {
    return 0;
  }

  return _.reduce(
    couriersStoreInfo,
    (total, courrierInfo) => {
      return total + _.get(courrierInfo, 'couriers_info.to_store', 0);
    },
    0,
  );
};

const isRTInStore = state => {
  return state === 4;
};

const isRTOnTheWay = state => {
  return state === 3;
};

const showRtInStoreChipCounter = rtsInStoreTotal => {
  return rtsInStoreTotal !== 0;
};
const showRtOnTheWayChipCounter = rtsOnTheWayTotal => {
  return rtsOnTheWayTotal !== 0;
};

export default {
  getActiveStep,
  getActiveStepIndex,
  getRTsInStoreTotalFromCourriesStoreInfo,
  getRTsOnTheWayTotalFromCourriesStoreInfo,
  isRTInStore,
  isRTOnTheWay,
  showRtInStoreChipCounter,
  showRtOnTheWayChipCounter,
};
