// React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Actions
import OrderActions from '../../redux/actions/orders-actions'
// Components
import StoresFilterButton from './StoresFilterButton'

const mapStateToProps = (
  {
    ordersReducer: {
      filter: { selectedStoresIds },
    },
  },
  { intl: { formatMessage } },
) => {
  const mobileText = formatMessage({ id: 'globals.filter' })
  const text = formatMessage({ id: 'globals.filterStores' })
  const totalSelectedStoreIds = selectedStoresIds.length

  return {
    mobileText,
    text,
    totalSelectedStoreIds,
  }
}

const mapDispatchToProps = {
  onClick: OrderActions.openOrdersListFilterDrawer,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(StoresFilterButton),
)
