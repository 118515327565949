// React
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
// Services
import OrdersAction from './../../redux/actions/orders-actions'
// Components
import CheckNetworkDialog from './CheckNetworkDialog'

const mapStateToProps = ({ ordersReducer }, { intl: { formatMessage } }) => {
  const buttonText = formatMessage({ id: 'checkNetworkDialog.buttonText' })
  const description = formatMessage({
    id: 'checkNetworkDialog.description',
  })
  const title = formatMessage({ id: 'checkNetworkDialog.title' })
  const open = ordersReducer.isCheckNetworkDialogOpened

  return {
    buttonText,
    description,
    open,
    title,
  }
}

const mapDispatchToProps = {
  onClickAccept: OrdersAction.closeCheckNetworkDialog,
  onClose: OrdersAction.closeCheckNetworkDialog,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(CheckNetworkDialog),
)
