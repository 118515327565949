import { makeStyles } from '@material-ui/core';
// Constants
import Colors from '../../constants/Colors';
import { neutral, red } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  corridorsListRoot: {
    minHeight: '100%',
    minWidth: 220,
    overflow: 'auto',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      borderRight: `1px solid ${neutral.D10}`,
      maxWidth: 250,
    },
  },
  corridorsListIconRoot: {
    marginLeft: 10,
    minWidth: 0,
  },
  listItemRoot: {
    borderBottom: `1px solid ${neutral.D10}`,
    height: 48,
    padding: '12px 16px',
    position: 'relative',
  },
  listTextPrimary: {
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  listTextRoot: {
    padding: '0 3px 0 0',
    '& span': {
      display: 'block',
    },
  },
  outOfStockCount: {
    backgroundColor: neutral.D15,
    borderRadius: 15,
    color: neutral.white,
    height: 30,
    position: 'absolute',
    right: 16,
    width: 30,
  },
  selected: {
    backgroundColor: neutral.D5,
    borderLeft: `2px solid ${Colors.PRIMARY_COLOR}`,
  },
  outstockContainer: {
    backgroundColor: red['10'],
    cursor: 'pointer',
    display: 'flex',
    color: neutral.D20,
    justifyContent: 'space-between',
    fontSize: 12,
    padding: '1rem',
  },
  outstockItem: {
    margin: 0,
  },
  outstockChip: {
    backgroundColor: Colors.DANGER_FONT_COLOR,
    color: 'white',
    fontSize: 10,
    fontWeight: 600,
    marginBottom: '.5rem',
  },
  outstockSecondary: {
    fontSize: 12,
    fontWeight: 400,
  },
  outstockSecondaryHighlight: {
    fontSize: 12,
    fontWeight: 700,
    marginRight: 4,
  },
}));
