import { useSelector } from 'react-redux';

import SuspendedStoreAlertSnackbarSelectors from '../../../redux/selectors/suspended-store-alert-snackbar-selectors';

const useMessage = () => {
  return useSelector(SuspendedStoreAlertSnackbarSelectors.selectMessage);
};

const useOpen = () => {
  return useSelector(SuspendedStoreAlertSnackbarSelectors.selectOpen);
};

const Hooks = { useMessage, useOpen };

export default Hooks;
