// Material UI
import { makeStyles } from '@material-ui/core'
// Consts
import Colors from '../../../../../constants/Colors'

export default makeStyles(theme => ({
  cancelButton: { color: Colors.WARNING_FONT_COLOR },
  messageContainer: { color: Colors.WARNING_FONT_COLOR },
  root: {
    backgroundColor: Colors.WARNING_COLOR,
  },
}))
