enum Variants {
  H1,
  H2,
  Headline,
  Subheadline,
  Body,
  Caption1,
  Caption2,
  Label,
  Sublabel,
}

export default Variants
