import { makeStyles } from '@material-ui/core/styles';

import { neutral, teal } from '../../../shared/styles/colors';

export default makeStyles({
  buttonsContainer: {
    display: 'flex',
    gap: '0.5rem',
    justifyContent: 'flex-end',
    marginTop: '32px',
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem',
    gap: '0.5rem',
  },
  itemDetailsContainer: {
    color: neutral['50'],
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'pre-wrap',
  },
  itemName: {
    minWidth: 160,
  },
  itemDeactivationOptionsContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '1rem',
    position: 'relative',
    borderRadius: '0.5rem',
    border: `1px solid ${neutral['20']}`,
  },
  itemDeactivationOptionDescription: {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
  },
  itemDeactivationOptionTitle: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
  },
  itemInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1rem',
    width: '100%',
  },
  palette: {
    primary: teal['50'],
  },
  radioButton: {
    margin: '8px 0px 0px 16px',
    '&:active, &:focus': {
      borderColor: teal['50'],
    },
  },
  root: {
    alignItems: 'center',
    color: neutral['90'],
    display: 'flex',
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    minHeight: 60,
    width: '100%',
  },
});
