// External libs
import { createSelector } from 'reselect';

const selectFailedProxySessionDialogReducer = state =>
  state.failedProxySessionDialogReducer;

const selectOrderId = createSelector(
  selectFailedProxySessionDialogReducer,
  failedProxySessionDialogReducer => failedProxySessionDialogReducer.orderId,
);

const FailedProxySessionDialogSelectors = { selectOrderId };

export default FailedProxySessionDialogSelectors;
