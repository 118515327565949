import { makeStyles } from '@material-ui/core';

import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => {
  return {
    Container: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    whimContent: {
      padding: '1rem',
      display: 'flex',
      gap: '0.5rem',
      justifyContent: 'space-between',
      fontSize: '1rem',
      fontFamily: 'Poppins',
      '& > *': {
        margin: 0,
      },
    },
    title: {
      margin: 0,
      padding: '1rem',
      fontSize: '1rem',
      fontWeight: 'normal',
      fontFamily: 'Poppins',
      borderBottom: `1px solid ${neutral.D5}`,
    },
    deleteWhim: {
      [theme.breakpoints.up('md')]: {
        borderTop: `1px solid ${neutral.D5}`,
        display: '-webkit-box',
      },
    },
    content: {
      backgroundColor: neutral.white,
      marginBottom: '12px',
      marginTop: '10px',
      margin: 24,
    },
    subTotalContent: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '24px',
      fontSize: '17px',
      fontFamily: 'Poppins',
      borderTop: `1px solid ${neutral.D5}`,
    },
    priceFont: {
      fontFamily: 'Poppins Bold',
    },
  };
});
