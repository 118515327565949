// Redux
import { createActions } from 'redux-actions';

const {
  closeFailedProxySessionDialogAction,
  clearFailedProxySessionDialogAction,
  openFailedProxySessionDialogAction,
} = createActions({
  CLOSE_FAILED_PROXY_SESSION_DIALOG_ACTION: () => {},
  CLEAR_FAILED_PROXY_SESSION_DIALOG_ACTION: () => {},
  OPEN_FAILED_PROXY_SESSION_DIALOG_ACTION: orderId => ({ orderId }),
});

const clearFailedProxySessionDialog = () => dispatch => {
  dispatch(clearFailedProxySessionDialogAction());
};

const closeFailedProxySessionDialog = () => dispatch => {
  dispatch(closeFailedProxySessionDialogAction());
};

const openFailedProxySessionDialog = orderId => dispatch => {
  dispatch(openFailedProxySessionDialogAction(orderId));
};

export default {
  clearFailedProxySessionDialog,
  clearFailedProxySessionDialogAction,
  closeFailedProxySessionDialog,
  closeFailedProxySessionDialogAction,
  openFailedProxySessionDialog,
  openFailedProxySessionDialogAction,
};
