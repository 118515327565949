// React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Actions
import MenuActions from './../../redux/actions/menu.actions'
// Components
import MenuPage from './MenuPage'

const mapStateToProps = (
  { menuReducer: { filter }, partnerReducer: { profile } },
  { intl: { formatMessage } },
) => {
  const title = formatMessage({ id: 'restaurantMenuPage.title' })

  const firstStoreId =
    profile.stores && profile.stores[0] && profile.stores[0].id

  const selectedStoreId = +filter.selectedStoreId

  const searchText = filter.searchText
  const showMenuBoard = !searchText
  const showMenuSearch = !!searchText

  return {
    firstStoreId,
    selectedStoreId,
    showMenuBoard,
    showMenuSearch,
    title,
  }
}

const mapDispatchToProps = {
  onStoreQueryParamChange: MenuActions.applyMenuFilter,
  getMenuByStore: MenuActions.getMenuByStore,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(MenuPage),
)
