import { ordermgmtDialogFacade } from '../../../../../business/order-mgmt';

export const useOrderMgmtUndoSuggestionDialog = () => {
  const {
    selectUndoSuggestionDialogIsOpen,
    selectUndoSuggestionDialogProductId,
    selectUndoSuggestionDialogToppingId,
    onCloseUndoSuggestionDialog,
    onConfirmUndoSuggestionButton,
  } = ordermgmtDialogFacade();

  return {
    isOpen: selectUndoSuggestionDialogIsOpen,
    onClose: onCloseUndoSuggestionDialog,
    onConfirm: onConfirmUndoSuggestionButton,
    productId: selectUndoSuggestionDialogProductId,
    toppingId: selectUndoSuggestionDialogToppingId,
  };
};
