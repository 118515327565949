import { clsx } from 'clsx';
import { ErrorOutline } from '@material-ui/icons';
import { Box, Typography } from '@material-ui/core';

import OrderMgmtStyle from '../../../OrderManagement.style';

export function ChipOrderDisclaimer(props: Props) {
  const { disclaimer, hasIcon = false, type, styles = {} } = props;

  const _classes = OrderMgmtStyle({ type });

  return (
    <Box
      className={clsx(styles?.container)}
      display='flex'
      alignItems='center'
      margin='0.5rem 0'
    >
      <Box
        className={clsx(_classes.chipOrderDisclaimer, styles?.disclaimer)}
        display='flex'
        alignItems='center'
        justifyContent='center'
        borderRadius='0.5rem'
        fontSize='0.75rem'
        gridGap='0.5rem'
        padding='0.5rem'
      >
        {hasIcon && (
          <ErrorOutline
            className={clsx(_classes.chipOrderIcon, styles?.icon)}
          />
        )}
        <Typography
          className={clsx(_classes.chipOrderText, styles?.text)}
          dangerouslySetInnerHTML={{
            __html: disclaimer || '',
          }}
        />
      </Box>
    </Box>
  );
}

interface Props {
  disclaimer?: string;
  hasIcon?: boolean;
  type?: any;
  styles?: any;
}
