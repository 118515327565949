import { clsx } from 'clsx';
import { TimePicker } from '@material-ui/pickers';

export function CustomTimePicker(props) {
  const {
    ampm = false,
    classes = {},
    inputVariant = 'outlined',
    label,
    margin = 'none',
    onChange = () => {},
    value = new Date(),
    fullWidth = false,
  } = props;
  return (
    <TimePicker
      fullWidth={fullWidth}
      ampm={ampm}
      classes={{}}
      InputProps={{
        classes: {
          input: clsx(classes?.inputPropsInput),
          notchedOutline: clsx(classes?.inputPropsNotchedOutline),
          root: clsx(classes?.inputPropsRoot),
        },
      }}
      inputVariant={inputVariant}
      margin={margin}
      label={label}
      value={value}
      onChange={onChange}
    />
  );
}
