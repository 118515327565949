import { makeStyles } from '@material-ui/core';

import { neutral, red } from '../../shared/styles/colors';
import { turboCreationAtColor } from '../../shared/styles/colors/feat';

export default makeStyles({
  circle: {
    backgroundColor: red['50'],
    borderRadius: '50%',
    height: 8,
    width: 8,
  },
  circleContainer: {
    padding: '5px 12px 0px 0px',
  },
  orderInfoContainer: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridAutoRows: 'auto',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  orderId: ({ orderCreationStatus }: Props) => ({
    margin: 0,
    lineHeight: 1.5,
    fontWeight: 'bold',
    wordBreak: 'break-all',
    color: turboCreationAtColor[orderCreationStatus] || neutral['90'],
  }),
  root: {
    padding: '12px 0px',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontSize: '1rem',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: neutral['90'],
    cursor: 'pointer',
  },
  storeName: {
    margin: 0,
    fontSize: '0.75rem',
    fontWeight: 'normal',
    lineHeight: 1.7,
    wordBreak: 'break-word',
    color: neutral['70'],
  },
});

interface Props {
  orderCreationStatus: string;
}
