import { makeStyles } from '@material-ui/core';

import Colors from '../../../../../constants/Colors';
import { neutral } from '../../../../../shared/styles/colors';

export default makeStyles(theme => ({
  title: {
    color: neutral.D18,
    fontSize: '1.125rem',
    fontWeight: 600,
  },
  alertMark: {
    padding: '1rem',
    color: Colors.BROWN,
    backgroundColor: Colors.WARNING_BG,
    borderRadius: 0,
    minHeight: '4.5rem',
  },
  alertMarkIcon: {
    color: Colors.BROWN,
  },
  alertMarkMsg: {
    fontSize: '1rem',
    lineHeight: 1.5,
    paddingTop: '0.25rem',
  },
  alertMarkText: {
    fontSize: '0.75rem',
  },
  searcher: {
    backgroundColor: neutral.D2,
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '1rem',
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '1rem',
    },
    '& .MuiInputBase-input': {
      height: 'inherit',
      padding: '1rem 0.5rem',
      fontSize: '1rem',
      lineHeight: 1.5,
    },
  },
  searcherStartIcon: {
    color: neutral.D15,
  },
  searcherEndIcon: {
    cursor: 'pointer',
    color: neutral.D17,
  },
  itemToReplace: {
    border: `1px solid ${neutral.D11}`,
    borderRadius: '0.5rem',
  },
  itemUnits: {
    fontSize: '1.5rem',
    fontWeight: 600,
    color: neutral.D20,
  },
  itemName: {
    fontSize: '1rem',
    fontWeight: 400,
    paddingTop: '0.25rem',
  },
  replaceIcon: {
    fontSize: '1rem',
  },
  replaceDisclaimer: {
    fontSize: '1.125rem',
    fontWeight: 600,
    color: neutral.D18,
  },
  replaceText: {
    fontSize: '0.625rem',
    fontWeight: 400,
  },
  backIcon: {
    cursor: 'pointer',
  },
}));
