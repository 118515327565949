import { addRTHelper } from '../../../domain';
import { addRTMainHandler } from '../../../infra';

import type { IAddRTMainCaseParams, IAddRTMainCase } from '../../../domain';

export function cargoAddRTCase(params: IAddRTMainCaseParams): IAddRTMainCase {
  const { behavior, storeId, orderId, orderStatus, deliveryMethod } = params;

  const { addRT, cargoStores, validateAddRT } = addRTMainHandler();
  const { status, selectedOrderId } = addRT;

  const isOrderIdSame = orderId === selectedOrderId;
  const cargoStore = cargoStores.find(s => s.id === storeId);
  const isCargoStore = Boolean(cargoStore);
  const isEnabledByCargo = Boolean(cargoStore?.isEnabled);

  const isDisplayedToOH = addRTHelper.isDisplayedToOrderHistorical({
    behavior,
    isCargoStore,
  });
  const isDisplayedToDMP = addRTHelper.isDisplayedToDetailMarketplace({
    behavior,
    deliveryMethod,
    isEnabledByCargo,
  });
  const isDisplayed = isDisplayedToOH || isDisplayedToDMP;

  const _isEnabledTo = { behavior, orderStatus, deliveryMethod };
  const isEnabledToOH = addRTHelper.isEnabledToOrderHistorical(_isEnabledTo);
  const isEnabledToDMP = addRTHelper.isEnabledToDetailMarketplace(_isEnabledTo);
  const isEnabledByMainRule = isEnabledToOH || isEnabledToDMP;
  const isEnabled = Boolean(orderId) && isEnabledByCargo && isEnabledByMainRule;

  const isInProgress = addRTHelper.isValidationInProgress(status);
  const isLoading = isOrderIdSame && isInProgress;

  const isDialogEnabled =
    isOrderIdSame && addRTHelper.isDialogEnabledByAddRTStatus(status);

  const isValidationDenied =
    isOrderIdSame && addRTHelper.isValidationDenied(status);

  return {
    isDisplayed,
    isEnabled,
    isLoading,
    isDialogEnabled,
    isValidationDenied,
    onValidateCargoAddRT: validateAddRT,
  };
}
