import { makeStyles } from '@material-ui/core';

import confeti from '../../../../assets/images/confeti.svg';
import Colors from '../../../../constants/Colors';

export const welcomeBoxStyles = makeStyles(theme => ({
  container: {
    background: `linear-gradient(180deg, ${Colors.CYAN} 0%, ${Colors.CYAN_SHADE} 100%)`,
    position: 'absolute',
    top: '0px',
    right: '0px',
    left: '0px',
    height: '9rem',
  },
  title: {
    display: 'flex',
    textAlign: 'center',
    fontSize: '40px',
    color: 'white',
    fontWeight: 600,
    paddingTop: '1.5rem',
  },
  confeti: {
    backgroundImage: `url(${confeti})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundPositionY: '20%',
    backgroundSize: '42%',
    display: 'flex',
    justifyContent: 'center',
  },
}));
