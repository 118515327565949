import React from 'react';
import PropTypes from 'prop-types';
// Utils
import { formatCurrency } from '../../utils/CurrencyUtils';
// Styles
import useStyles from './MenuProductDetail.styles';
// Utils
import { ensureHttps } from '../../utils/url-utils/UrlUtils';
import { ImageWithPlaceholder } from '../menu-page';
import MenuToppingsList from './../menu-toppings-list';
import CustomLoader from '../common/custom-loader';
import { CustomTextField } from '../menu-page';

const MenuProductDetail = ({
  intl: { formatMessage },
  isLoading,
  product,
  toppingsGroups,
}) => {
  const classes = useStyles();
  const productImageSrc = ensureHttps(product['image']);
  return (
    <div className={classes.root}>
      <CustomLoader open={isLoading} />
      <div className={classes.productImageContainer}>
        <ImageWithPlaceholder
          src={productImageSrc}
          size={60}
          customClass={classes.productImage}
        />
      </div>
      <CustomTextField
        label={formatMessage({
          id: 'restaurantMenuPage.productDetails.name.label',
        })}
        placeholder={formatMessage({
          id: 'restaurantMenuPage.productDetails.name.placeholder',
        })}
        value={product['name'] || ''}
        maxLength={40}
        fullWidth
        disabled
        className={classes.inputMarginBottom}
      />
      <CustomTextField
        label={formatMessage({
          id: 'restaurantMenuPage.productDetails.description.label',
        })}
        placeholder={formatMessage({
          id: 'restaurantMenuPage.productDetails.description.placeholder',
        })}
        value={product['description'] || ''}
        maxLength={400}
        fullWidth
        multiline
        disabled
        className={classes.inputMarginBottom}
      />
      <CustomTextField
        label={formatMessage({
          id: 'restaurantMenuPage.productDetails.price.label',
        })}
        value={
          product['price'] || product['price'] === 0
            ? formatCurrency(product['price'])
            : ''
        }
        maxLength={10}
        fullWidth
        disabled
        className={classes.priceInput}
      />
      {toppingsGroups && toppingsGroups.length > 0 && <MenuToppingsList />}
    </div>
  );
};

MenuProductDetail.propTypes = {
  product: PropTypes.object,
  toppingsGroups: PropTypes.array,
  onChangeToppingAvailability: PropTypes.func,
  toppingCategoriesErrors: PropTypes.array,
};

export default MenuProductDetail;
