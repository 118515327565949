// Redux
import { createActions } from 'redux-actions';

const {
  closeRTReleaseConfirmationDialogAction,
  clearRTReleaseConfirmationDialogAction,
  openRTReleaseConfirmationDialogAction,
} = createActions({
  CLOSE_R_T_RELEASE_CONFIRMATION_DIALOG_ACTION: () => {},
  CLEAR_R_T_RELEASE_CONFIRMATION_DIALOG_ACTION: () => {},
  OPEN_R_T_RELEASE_CONFIRMATION_DIALOG_ACTION: orderId => ({ orderId }),
});

const clear = () => dispatch => {
  dispatch(clearRTReleaseConfirmationDialogAction());
};

const close = () => dispatch => {
  dispatch(closeRTReleaseConfirmationDialogAction());
};

const open = orderId => dispatch => {
  dispatch(openRTReleaseConfirmationDialogAction(orderId));
};

const RTReleaseConfirmationDialogActions = {
  clear,
  clearRTReleaseConfirmationDialogAction,
  close,
  closeRTReleaseConfirmationDialogAction,
  open,
  openRTReleaseConfirmationDialogAction,
};

export default RTReleaseConfirmationDialogActions;
