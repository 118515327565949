import { clsx } from 'clsx';
import { Drawer } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';

import CustomButton from '../../../buttons/custom-button';
import { CustomCancelButton } from '../../../buttons/custom-cancel-button';

import useStyles, { useStyleRules } from './styles';

import type { Props } from './interfaces';

export function CustomBaseAlertSnackbar(props: Props) {
  const {
    styleRules,
    classes = {},
    message = '',
    open = false,
    buttons = [],
    onClose = () => {},
  } = props;
  const _classes = useStyles({ styleRules });
  const _styleRules = useStyleRules({ styleRules });
  return (
    <Drawer
      anchor='bottom'
      open={open}
      classes={{ paper: clsx(_classes.root, classes?.root, _styleRules?.root) }}
      onClose={onClose}
    >
      <div
        className={clsx(_classes.contentContainer, classes?.contentContainer)}
      >
        <ReactMarkdown
          className={clsx(
            _classes.messageContainer,
            classes?.messageContainer,
            _styleRules?.messageContainer,
          )}
        >
          {message || ''}
        </ReactMarkdown>
        {!!buttons && !!buttons.length && (
          <div
            className={clsx(
              _classes.buttonsContainer,
              classes?.buttonsContainer,
            )}
          >
            {buttons.map((button, index) => {
              return (
                <CustomButton
                  classes={{
                    root: clsx(classes?.buttonRoot, button?.classes?.root),
                    submitButton: clsx(
                      _classes.buttonSubmitButton,
                      classes?.buttonSubmitButton,
                      button?.classes?.submitButton,
                    ),
                  }}
                  color={button?.color ?? undefined}
                  key={`button-${index}`}
                  onClick={button?.onClick ?? (() => {})}
                  fullWidth={false}
                  styleRules={button.styleRules}
                  variant={button.variant}
                >
                  {button?.text}
                </CustomButton>
              );
            })}
          </div>
        )}
        <CustomCancelButton
          classes={{
            button: clsx(
              _classes.cancelButton,
              classes?.cancelButton,
              _styleRules?.cancelButton,
            ),
          }}
          onClick={onClose}
        />
      </div>
    </Drawer>
  );
}
