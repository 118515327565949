import { makeStyles } from '@material-ui/core';

import { neutral, red } from '../../../../shared/styles/colors';

export default makeStyles({
  listItemIconRoot: {
    fontSize: '1.5rem',
    minWidth: 'auto',
  },
  listItemRoot: {
    padding: '16px 24px',
    '&:hover': {
      backgroundColor: neutral['20'],
    },
  },
  listItemTextRoot: {
    margin: 0,
    padding: '0px 0px 0px 32px',
  },
  listItemTextPrimary: {
    color: neutral['90'],
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
  },
  selectedListItemRoot: {
    backgroundColor: neutral['20'],
  },
  selectedListItemTextPrimary: {
    color: red['50'],
    fontFamily: 'Poppins Bold',
  },
});
