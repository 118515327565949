import hooks from './hooks';

import { BaseSection } from '../base-section';

export function FullServiceSection({ isFastOperation = false }) {
  const title = hooks.useTitle();
  const alertText = hooks.useAlertText();
  const maxValue = hooks.useMaxValue();
  const minValue = hooks.useMinValue();
  const fastOperationText = hooks.useFastOperationText();
  
  return (
    <BaseSection
      alertText={isFastOperation ? fastOperationText : alertText }
      maxValue={maxValue}
      minValue={minValue}
      title={title}
      showSecondaryAlert={isFastOperation}
    />
  );
}
