// React
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
// Consts
import Paths from '../../constants/Paths'
// Actions
import AppActions from './../../redux/actions/app-actions'
import storesSchedulesActions from '../../redux/actions/storesSchedules.actions'
// Components
import StoresSchedulesAdvancedFilterToolbar from './StoresSchedulesAdvancedFilterToolbar'
import history from '../../history'

const mapStateToProps = ({
  partnerReducer: { profile },
  storesSchedulesReducer,
}) => {
  const selectedStore = profile.stores.find(
    store => store.id === +storesSchedulesReducer.filter.selectedStoreId,
  )

  const firstStoreId =
    profile.stores && profile.stores[0] && profile.stores[0].id

  const selectedFilterOptions = []

  if (selectedStore) {
    const selectedStoreFilterOption = {
      label: `${selectedStore.name}`,
      id: selectedStore.id,
      onDelete: () => {
        history.push(Paths.getStoresSchedulesQueryParamsPath(firstStoreId))
      },
    }

    selectedFilterOptions.push(selectedStoreFilterOption)
  }

  return { firstStoreId, selectedFilterOptions }
}

const mapDispatchToProps = {
  onClick: storesSchedulesActions.openStoresSchedulesFilterDrawer,
  onStoreQueryParamChange: storesSchedulesActions.applyStoresSchedulesFilter,
  openSnackBarError: AppActions.openSnackBarError,
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(StoresSchedulesAdvancedFilterToolbar),
)
