// Material UI
import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  root: {},
  timePicker: {},
  timePickerBoxRoot: {
    fontSize: 14,
    height: 44,
    [theme.breakpoints.up('sm')]: {
      height: 56,
    },
    [theme.breakpoints.up('md')]: {
      height: 34,
    },
  },
  timePickerBox: {
    fontSize: 14,
  },
  menuItemRoot: {
    fontSize: 14,
    top: '55%',
    maxHeight: 'calc(50% - 300px)',
  },
  menuItemSelected: {
    fontSize: 14,
  },
  paper: {
    display: 'none',
  },
  textField: {},
  timePickerInput: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      paddingTop: 7,
      paddingLeft: 10,
      paddingRight: '10px !important',
      paddingBottom: 10,
    },
  },
}))
