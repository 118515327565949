import { makeStyles } from '@material-ui/core';

import type { GlobalMode } from './shared/types';

interface Props {
  globalMode?: GlobalMode;
}

export default makeStyles(() => ({
  expiredAtTextLine: ({ globalMode = false }: Props) => ({
    gridColumnStart: globalMode ? 2 : 1,
    fontSize: globalMode ? '0.625rem' : '0.875rem',
    lineHeight: globalMode ? 1 : 1.5,
  }),
}));
