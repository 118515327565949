import type { Saturation } from '../../domain';

const highDemandApply = {
  name: 'CLICK_DEMAND_REQUEST',
  props: {
    storeId: 'STORE_ID',
    partnerId: 'PARTNER_ID',
    demandOption: 'DEMAND_OPTION',
    additionalCT: 'ADDITIONAL_CT',
    timeOption: 'TIME_OPTION',
    hasHoldLowCT: 'HAS_HOLD_LOW_CT'
  },
} as const;

export type HighDemandApplyProps = {
  storeId: number;
  partnerId: number;
  demandOption: Saturation;
  additionalCT: number;
  timeOption: number;
  hasHoldLowCT: boolean;
};

export const Events = { HDB: highDemandApply } as const;
