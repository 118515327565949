import { connect } from 'react-redux';
import RtAvatar from './RtAvatar';
import { injectIntl } from 'react-intl';

const mapStateToProps = (state, { intl: { formatMessage } }) => {
  return {};
};
const mapDispatchToProps = {};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RtAvatar),
);
