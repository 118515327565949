import { OrderMgmtAnalytics } from '../../analitycs';
import {
  isEnabledCountDown,
  mapToppingsWithProduct,
  OrderMgmtProduct,
  OrderMgmtTopping,
} from '../../domain';
import { orderDetailAdapter } from '../../infra/adapters';
import {
  RejectedOrderRequest,
  RemoveToppingListDTO,
} from '../../infra/core/requests';
import { orderMgmtDetailFacade } from '../../infra/store/order-detail';

export function orderMgmtTimeoutCase() {
  const {
    selectOrderId: orderId,
    selectStoreId: storeId,
    selectDetailProducts: products,
    selectIsCountdownComplete: isCountdownComplete,
    selectProductToRemove,
    selectToppingsToRemove,
    selectOrderDetail,
    selectItemsToRemove: itemsToRemove,
    selectOpenDialog,
    selectState: orderState,
    onRejectedOrder,
    onSetIsCountdownComplete: setIsCountdownComplete,
    onRemoveProductDetail,
    onRemoveToppingDetail,
    onResetDetailProducts: undoChanges,
    onAddProductToRemove,
    onAddToppingToRemove,
    onOpenDialog,
    closeDialog,
    onAcceptOrder,
  } = orderMgmtDetailFacade();

  const showCountdownTime = isEnabledCountDown(
    products,
    isCountdownComplete,
    orderState,
  );

  const hasToppingWithSuggestions = !!selectToppingsToRemove.length;

  const hasChangesOrder = !!Object.values(itemsToRemove).length;

  const isEnabledToDelete = !!(products.length > 1);

  const removeProduct = (product: OrderMgmtProduct) => {
    onRemoveProductDetail(parseInt(product.id));
    onAddProductToRemove(product);
  };
  const removeTopping = (topping: OrderMgmtTopping) => {
    onRemoveToppingDetail(topping.productId as number, parseInt(topping.id));
    onAddToppingToRemove(topping);
  };
  const acceptOrder = async ({ action }) => {
    const removableProducts: OrderMgmtProduct[] = itemsToRemove?.products ?? [];
    const removableToppings: OrderMgmtTopping[] = itemsToRemove?.toppings ?? [];
    const products = removableProducts
      ? itemsToRemove?.products?.map(x => parseInt(x.id))
      : [];
    let toppings: RemoveToppingListDTO[] = [];

    if (removableToppings) {
      toppings = mapToppingsWithProduct(removableToppings);
    }

    const payload = await orderDetailAdapter.toAcceptTimeoutRequest(
      selectOrderDetail,
      products as number[],
      toppings,
    );
    OrderMgmtAnalytics.whenInactivityAction({ orderId, storeId }, { action });
    onAcceptOrder(orderId, payload);
    closeDialog();
  };
  const rejectOrder = () => {
    const payload: RejectedOrderRequest = {
      refuse_reason_enum: 'ran_out_products',
      stockout_products: selectProductToRemove.map(x => parseInt(x)),
      stockout_toppings: selectToppingsToRemove
        .map(x => x?.topping_list)
        .flat(),
    };
    onRejectedOrder(orderId, payload);
    closeDialog();
  };

  return {
    orderId,
    storeId,
    products,
    showCountdownTime,
    hasChangesOrder,
    isCountdownComplete,
    hasToppingWithSuggestions,
    itemsToRemove,
    isEnabledToDelete,
    isOpenDialog: selectOpenDialog,
    setOpenDialog: onOpenDialog,
    setIsCountdownComplete,
    rejectOrder,
    removeProduct,
    removeTopping,
    undoChanges,
    acceptOrder,
  };
}
