import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(theme => ({
  dialogPaper: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      bottom: 0,
      height: '100vh',
      padding: '1rem',
      position: 'absolute',
      right: 0,
      top: 0,
      width: '42.5vw',
      overFlowY: 'hidden',
    },
  },
  root: {
    background: neutral.white,
    display: 'grid',
    gridTemplateAreas: `"header"
                        "main"
                        "actions"`,
    gridTemplateRows: '0fr auto 0fr',
  },
  dialogTitle: {
    fontSize: '1rem',
    fontWeight: 400,
    fontFamily: 'Poppins',
  },
}));
