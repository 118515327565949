import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import { useCodeList } from './hooks';

import makeClasses from './CodeList.style';

import type { UseCheckinCodeStoresData } from '../../shared/models';

export function CodeList(props: Props) {
  const { open = false, onClose = () => undefined, stores = [] } = props;
  const styles = makeClasses();
  const { lang, useExpiredAtFormatted } = useCodeList();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='show check-in code rt for multi-stores'
      classes={{ paperWidthSm: styles.dialog }}
    >
      <h2 className={styles.title}>{lang.title}</h2>
      <DialogContent>
        <Typography>{lang.p1}</Typography>
        <Typography classes={{ root: styles.p2 }}>{lang.p2}</Typography>
        <TableContainer component='main'>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow classes={{ root: styles.tableRowOnHead }}>
                <TableCell align='center'>{lang.store}</TableCell>
                <TableCell align='center'>{lang.code}</TableCell>
                <TableCell align='center'>{lang.expiration}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stores.map(store => (
                <TableRow
                  key={`cc-store-${store.id}`}
                  classes={{ root: styles.tableRowOnBody }}
                >
                  <TableCell align='center'>{store.name}</TableCell>
                  <TableCell align='center'>{store.checkinCode}</TableCell>
                  <TableCell align='center'>
                    {useExpiredAtFormatted(store.expiredAt ?? '')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
}

interface Props {
  open: boolean;
  onClose: () => void;
  stores: UseCheckinCodeStoresData[];
}
