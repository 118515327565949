// Material UI
import { makeStyles } from '@material-ui/core';
import Colors from '../../constants/Colors';

export default makeStyles(theme => ({
  root: {
    justifySelf: 'end',
    [theme.breakpoints.up('md')]: {},
  },
  button: {
    [theme.breakpoints.up('md')]: {},
  },
  closeEditionMode: {
    color: Colors.BROWN,
  },
  editionModeContainer: {
    backgroundColor: Colors.WARNING_BG,
    '&::before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: 0,
      right: 0,
      top: '50%',
      border: '0.3152rem solid transparent',
      borderRight: 0,
      borderLeft: `0.3152rem solid ${Colors.WARNING_BG}`,
      transform: 'translate(100%, -50%)',
    },
  },
  editionModeDisclaimer: {
    color: Colors.BROWN,
    fontSize: '0.75rem',
    fontWeight: 400,
  },
}));
