// External libs
import { createSelector } from 'reselect'
// Interfaces
import { State as GlobalState } from '../../state'

const selectSlice = (state: GlobalState) => state.CheckErrorServiceDialog

const selectOpen = createSelector(selectSlice, reducer => reducer.open)

const selectors = {
  selectOpen,
}

export default selectors
