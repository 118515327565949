import { clsx } from 'clsx';
import { Alert } from '@material-ui/lab';
import ErrorIcon from '@material-ui/icons/Error';
import { Box, CircularProgress, Typography } from '@material-ui/core';

import { CustomDialog02 } from '../common/custom-dialog-02';

import { useStoreSuspensionAlert } from './hooks';
import classes from './styles';

export function StoreSuspensionAlert() {
  const {
    open,
    handleOnClose,
    handleBtnPrimary,
    handleBtnSecondary,
    texts,
    desuspend,
    storeDetail,
    isCTADesuspended,
    isLoadingStatusDetail,
  } = useStoreSuspensionAlert();
  const styles = classes();

  return (
    <CustomDialog02
      open={open}
      title={texts.title}
      onCloseByExitIcon={handleOnClose}
      primaryBtnText={texts.btnPrimary}
      primaryBtnOnClick={handleBtnPrimary}
      primaryBtnLoading={desuspend.isLoading}
      secondaryBtnText={texts.btnSecondary}
      secondaryBtnOnClick={handleBtnSecondary}
    >
      {desuspend.isError && (
        <Alert
          severity='error'
          variant='standard'
          icon={<ErrorIcon />}
          classes={{ standardError: styles.standardError }}
        >
          {texts.alertError}
        </Alert>
      )}

      <Box style={{ opacity: desuspend.isError ? '40%' : '' }}>
        <Typography classes={{ root: styles.body1 }}>{texts.body1}:</Typography>

        {isLoadingStatusDetail && <CircularProgress />}
        {!isLoadingStatusDetail && (
          <>
            <Typography classes={{ root: styles.storeName }}>
              {storeDetail?.store_name}
            </Typography>
            <Typography classes={{ root: styles.reason }}>
              {storeDetail?.second_message}
            </Typography>
          </>
        )}

        <Typography classes={{ root: styles.body2 }}>
          {isCTADesuspended && texts.body2}
          {!isCTADesuspended && (
            <>
              {texts.body2.split('\b').map((value, idx) => (
                <span
                  key={`body2-idx-${idx}`}
                  className={clsx({ [styles.bold]: idx === 1 })}
                >
                  {value}
                </span>
              ))}
            </>
          )}
        </Typography>
      </Box>
    </CustomDialog02>
  );
}
