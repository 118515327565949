import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import CustomDialog from '../../../common/custom-dialog/CustomDialog';
import OrderMgmtStyles from '../../OrderManagement.style';
import {
  COUNTDOWN_ORDER_IN_SEG,
  OrderMgmtProduct,
  orderMgmtState,
} from '../../../../business/order-mgmt';
import { OrderMgmtMainContainer } from './components';

export const OrderMgmtDialog = ({
  isOpen,
  orderId,
  products,
  closeDialog,
  continueButtonText,
  soldOutProductText,
  infoUserDescriptionText,
  infoUserTitleText,
  optionsTexts,
  deactivatedItemsText,
  callUserText,
  startChatText,
  userName,
  isDeactivatingItemsState,
  handleOrderOption,
  isOrderEnabledToEdit,
  handleSelectProduct,
  handleSelectTopping,
  isOfferReplaceItemsEnabled,
  handleDeactivateProductsAndToppings,
  showSupportWebChatButton,
  handleOpenSupportWebChat,
  handleOpenCustomerPhoneSessionDialog,
  canSendChangesByTime,
  isOrderBeingCooked,
  tooltipOptionDisclaimerText,
  deactivateProductIds,
  deactivateToppingIds,
  startedAt
}: Props) => {
  const componentClasses = OrderMgmtStyles();
  const theme = useTheme<any>();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const cleanOM = true;
  const openOrderDetail = false;

  return (
    <CustomDialog
      classes={{ 
        paper: componentClasses.paper,
        headerRoot: componentClasses.header,
      }}
      onClose={() => closeDialog(cleanOM, openOrderDetail)}
      open={isOpen}
      showBackButton={!isMdUp}
      showCloseButton={isMdUp}
    >
      <OrderMgmtMainContainer
        title={soldOutProductText}
        products={products}
        orderId={orderId}
        infoUserTitleText={infoUserTitleText}
        infoUserDescriptionText={infoUserDescriptionText}
        optionsTexts={optionsTexts}
        deactivatedItemsText={deactivatedItemsText}
        callUserText={callUserText}
        startChatText={startChatText}
        userName={userName}
        continueButtonText={continueButtonText}
        isDeactivatingItemsState={isDeactivatingItemsState}
        handleOrderOption={handleOrderOption}
        isOrderEnabledToEdit={isOrderEnabledToEdit}
        handleSelectProduct={handleSelectProduct}
        handleSelectTopping={handleSelectTopping}
        isOfferReplaceItemsEnabled={isOfferReplaceItemsEnabled}
        handleDeactivateProductsAndToppings={
          handleDeactivateProductsAndToppings
        }
        showSupportWebChatButton={showSupportWebChatButton}
        handleOpenSupportWebChat={handleOpenSupportWebChat}
        handleOpenCustomerPhoneSessionDialog={
          handleOpenCustomerPhoneSessionDialog
        }
        canSendChangesByTime={canSendChangesByTime}
        isOrderBeingCooked={isOrderBeingCooked}
        tooltipOptionDisclaimerText={tooltipOptionDisclaimerText}
        deactivateProductIds={deactivateProductIds}
        deactivateToppingIds={deactivateToppingIds}
        startedAt={startedAt}
      />
    </CustomDialog>
  );
};

interface Props {
  isOpen: boolean;
  orderId: number;
  products: Array<OrderMgmtProduct>;
  closeDialog: any;
  continueButtonText: string;
  soldOutProductText: string;
  infoUserDescriptionText: string;
  infoUserTitleText: string;
  optionsTexts: Array<{
    title: string;
    description: string;
    option: orderMgmtState;
  }>;
  deactivatedItemsText: string;
  callUserText: string;
  startChatText: string;
  userName: string;
  isDeactivatingItemsState: boolean;
  handleOrderOption: any;
  isOrderEnabledToEdit: boolean;
  handleSelectProduct: any;
  handleSelectTopping: any;
  isOfferReplaceItemsEnabled: boolean;
  handleDeactivateProductsAndToppings: any;
  showSupportWebChatButton: boolean;
  handleOpenSupportWebChat: any;
  handleOpenCustomerPhoneSessionDialog: any;
  canSendChangesByTime: boolean;
  isOrderBeingCooked: boolean;
  tooltipOptionDisclaimerText: string;
  deactivateProductIds: Array<number>;
  deactivateToppingIds: Array<number>;
  startedAt?: string
}
