import { RadioGroup } from '@material-ui/core';

import { CustomRadio } from '../common/custom-radio';
import CustomTextField from '../common/custom-text-field';
import CustomSwipeableDialog from '../common/custom-swipeable-dialog';

import { useLogOutReasonsDialog } from './hooks';

import useStyles from './styles';

export function LogOutReasonsDialog() {
  const {
    lang,
    open,
    logOutReasons,
    selectedOpt,
    reasonText,
    handleClose,
    handleChangeReasonText,
    handleConfirm,
    handleReasonToLogOut,
  } = useLogOutReasonsDialog();

  const _classes = useStyles();

  return (
    <CustomSwipeableDialog
      classes={{
        dialogPaper: _classes.root,
        contentContainer: _classes.container,
        title: _classes.title,
        titleContainer: _classes.titleBox,
      }}
      open={open}
      title={lang.title}
      message={''}
      buttons={[
        {
          onClick: handleClose,
          label: lang.cancelBtn,
          variant: 'outlined',
          color: 'tertiary',
        },
        {
          color: 'primary',
          label: lang.confirmBtn,
          onClick: handleConfirm,
          variant: 'contained',
          disabled: !selectedOpt,
        },
      ]}
    >
      <RadioGroup value={selectedOpt}>
        {logOutReasons &&
          logOutReasons.map((reason, index) => {
            return (
              <div key={`reasons-index-${index}`}>
                <CustomRadio
                  key={`reasons-index-checkbox-${reason.reasonId}`}
                  color='primary'
                  label={reason.reasonText}
                  value={reason.reasonId}
                  onChange={handleReasonToLogOut}
                />
                <div>
                  {selectedOpt === reason.reasonId && (
                    <CustomTextField
                      classes={{ textFieldRoot: _classes.contentBox }}
                      fullWidth={false}
                      name='input'
                      multiline
                      label={lang.placeHolder}
                      rows={4}
                      variant='outlined'
                      value={reasonText}
                      onChange={handleChangeReasonText}
                      maxLength={200}
                    />
                  )}
                </div>
              </div>
            );
          })}
      </RadioGroup>
    </CustomSwipeableDialog>
  );
}
