import history from '../../history'
// External dialog
import { createActions } from 'redux-actions'
// Consts
import Paths from '../../constants/Paths'
// Services
import StoreSchedulesService from '../../services/StoreSchedulesService'
// translate
import { formatMessage } from '../../intl/intl'
// Redux
import appStore from '../stores/app.store'
import appActions from './app-actions'
import newSpecialDayDialogActions from './newSpecialDayDialog.actions'

const {
  applyStoresSchedulesFilterAction,
  cleanBeingEditedScheduleAction,
  cleanDayIdBeingAddingScheduleAction,
  closeStoresSchedulesFilterDrawerAction,
  deleteHolidayScheduleAction,
  deleteSpecialScheduleAction,
  deleteSpecialDayAction,
  getStoreSchedulesAction,
  openStoresSchedulesFilterDrawerAction,
  setAreStoresSchedulesLoadingAction,
  setBeingEditedScheduleAction,
  setDayIdBeingAddingScheduleAction,
} = createActions({
  APPLY_STORES_SCHEDULES_FILTER_ACTION: selectedStoreId => ({
    selectedStoreId,
  }),
  CLEAN_BEING_EDITED_SCHEDULE_ACTION: () => ({}),
  CLEAN_DAY_ID_BEING_ADDING_SCHEDULE_ACTION: () => ({}),
  CLOSE_STORES_SCHEDULES_FILTER_DRAWER_ACTION: () => ({}),
  DELETE_SPECIAL_DAY_ACTION: dayId => ({ dayId }),
  DELETE_HOLIDAY_SCHEDULE_ACTION: (dayId, scheduleId) => ({
    dayId,
    scheduleId,
  }),
  DELETE_SPECIAL_SCHEDULE_ACTION: (dayId, scheduleId) => ({
    dayId,
    scheduleId,
  }),
  GET_STORE_SCHEDULES_ACTION: schedules => ({ schedules }),
  OPEN_STORES_SCHEDULES_FILTER_DRAWER_ACTION: () => ({}),
  SET_ARE_STORES_SCHEDULES_LOADING_ACTION: areStoresSchedulesLoading => ({
    areStoresSchedulesLoading,
  }),
  SET_BEING_EDITED_SCHEDULE_ACTION: scheduleId => ({ scheduleId }),
  SET_DAY_ID_BEING_ADDING_SCHEDULE_ACTION: dayId => ({ dayId }),
})

const redirectStoresSchedulesFilter = selectedStoreId => dispatch => {
  history.push(`${Paths.getStoresSchedulesQueryParamsPath(selectedStoreId)}`)
  closeStoresSchedulesFilterDrawer()(dispatch)
}

const applyStoresSchedulesFilter = selectedStoreId => async dispatch => {
  if (!selectedStoreId) {
    return
  }

  dispatch(applyStoresSchedulesFilterAction(selectedStoreId))

  await getStoreSchedules()(dispatch)
}

const clearStoresSchedulesFilter = () => dispatch => {
  closeStoresSchedulesFilterDrawer()(dispatch)
  history.push(`${Paths.STORES_SCHEDULES}`)
}

const closeStoresSchedulesFilterDrawer = () => dispatch => {
  return dispatch(closeStoresSchedulesFilterDrawerAction())
}

const addHolidaySchedule = (
  holidayId,
  ends_time,
  starts_time,
) => async dispatch => {
  try {
    const selectedStoreId = appStore.getSelectedStoreIdInStoreSchedules()
    const newSchedules = [{ starts_time, ends_time }]
    await StoreSchedulesService.createHolidaySchedule(
      holidayId,
      newSchedules,
      selectedStoreId,
    )

    cleanBeingEditedSchedule()(dispatch)
    cleanDayIdBeingAddingSchedule()(dispatch)

    await getStoreSchedules()(dispatch)

    const title = formatMessage({
      id: 'customSnackbar.successMessage.addHolidaySchedule.title',
    })
    const description = formatMessage({
      id: 'customSnackbar.successMessage.addHolidaySchedule.description',
    })
    appActions.openSnackBarSuccess(description, title)(dispatch)
  } catch (ex) {
    await appActions.handleCatch(ex)(dispatch)
  }
}

const addSpecialExtendDay = (extendSchedules, storeId) => async dispatch => {
  try {
    const date = new Date()
    const day = date.getDate()
    const month = date.getMonth() + 1
    await StoreSchedulesService.createExtendClosingHoursDay(
      day,
      month,
      '',
      extendSchedules,
      storeId,
    )
    const title = formatMessage({
      id: 'customSnackbar.successMessage.addSpecialDayScheduleInterval.title',
    })
    const description = formatMessage({
      id:
        'customSnackbar.successMessage.addSpecialDayScheduleInterval.description',
    })
    appActions.openSnackBarSuccess(description, title)(dispatch)
  } catch (ex) {
    await appActions.handleCatch(ex)(dispatch)
  }
}

const addSpecialDay = (date, name) => async dispatch => {
  try {
    const day = date.getDate()
    // Get month return between 0 to 11, so it is necessary to add 1.
    const month = date.getMonth() + 1
    const selectedStoreId = appStore.getSelectedStoreIdInStoreSchedules()
    const newSchedules = [{ starts_time: '00:00', ends_time: '00:00' }]
    await StoreSchedulesService.createSpecialDay(
      day,
      month,
      name,
      newSchedules,
      selectedStoreId,
    )

    await getStoreSchedules()(dispatch)

    newSpecialDayDialogActions.closeNewSpecialDayDialog()(dispatch)
    const title = formatMessage({
      id: 'customSnackbar.successMessage.addSpecialDaySchedule.title',
    })
    const description = formatMessage({
      id: 'customSnackbar.successMessage.addSpecialDaySchedule.description',
    })
    appActions.openSnackBarSuccess(description, title)(dispatch)
  } catch (ex) {
    await appActions.handleCatch(ex)(dispatch)
  }
}

const addSpecialDaySchedule = (
  specialDayId,
  ends_time,
  starts_time,
) => async dispatch => {
  try {
    const selectedStoreId = appStore.getSelectedStoreIdInStoreSchedules()
    const schedules = [{ starts_time, ends_time }]
    await StoreSchedulesService.createSpecialDaySchedule(
      schedules,
      specialDayId,
      selectedStoreId,
    )

    cleanBeingEditedSchedule()(dispatch)
    cleanDayIdBeingAddingSchedule()(dispatch)

    await getStoreSchedules()(dispatch)
    const title = formatMessage({
      id: 'customSnackbar.successMessage.addSpecialDayScheduleInterval.title',
    })
    const description = formatMessage({
      id:
        'customSnackbar.successMessage.addSpecialDayScheduleInterval.description',
    })
    appActions.openSnackBarSuccess(description, title)(dispatch)
  } catch (ex) {
    appActions.handleCatch(ex)(dispatch)
  }
}

const addSpecialExtendDaySchedule = (
  specialDayId,
  ends_time,
  starts_time,
) => async dispatch => {
  try {
    const selectedStoreId = appStore.getSelectedStoreIdInStoreSchedules()
    const schedules = [{ starts_time, ends_time }]
    await StoreSchedulesService.createExtendClosingHoursSchedule(
      schedules,
      specialDayId,
      selectedStoreId,
    )

    cleanBeingEditedSchedule()(dispatch)
    cleanDayIdBeingAddingSchedule()(dispatch)

    await getStoreSchedules()(dispatch)
    const title = formatMessage({
      id: 'customSnackbar.successMessage.addSpecialDayScheduleInterval.title',
    })
    const description = formatMessage({
      id:
        'customSnackbar.successMessage.addSpecialDayScheduleInterval.description',
    })
    appActions.openSnackBarSuccess(description, title)(dispatch)
  } catch (ex) {
    appActions.handleCatch(ex)(dispatch)
  }
}

const updateSpecialExtendDaySchedule = (
  scheduleId,
  startTime,
  endTime,
) => async dispatch => {
  try {
    await StoreSchedulesService.updateSchedule(endTime, scheduleId, startTime)
    await getStoreSchedules()(dispatch)
    const title = formatMessage({
      id: 'customSnackbar.successMessage.addSpecialDayScheduleInterval.title',
    })
    const description = formatMessage({
      id:
        'customSnackbar.successMessage.addSpecialDayScheduleInterval.description',
    })
    appActions.openSnackBarSuccess(description, title)(dispatch)
  } catch (ex) {
    appActions.handleCatch(ex)(dispatch)
  }
}

const confirmBeingEditingSchedule = (
  endsTime,
  startsTime,
) => async dispatch => {
  try {
    const beingEditingSchedule = appStore.getBeingEditingSchedule()

    await StoreSchedulesService.updateSchedule(
      endsTime,
      beingEditingSchedule,
      startsTime,
    )

    await getStoreSchedules()(dispatch)

    const title = formatMessage({
      id: 'customSnackbar.successMessage.beingEditingSchedule.title',
    })
    const description = formatMessage({
      id: 'customSnackbar.successMessage.beingEditingSchedule.description',
    })
    appActions.openSnackBarSuccess(description, title)(dispatch)
    cleanBeingEditedSchedule()(dispatch)
  } catch (ex) {
    appActions.handleCatch(ex)(dispatch)
  }
}

const deleteHolidaySchedule = (dayId, scheduleId) => async dispatch => {
  try {
    cleanBeingEditedSchedule()(dispatch)
    cleanDayIdBeingAddingSchedule()(dispatch)

    await StoreSchedulesService.deleteSchedule(scheduleId)

    dispatch(deleteHolidayScheduleAction(dayId, scheduleId))
    const title = formatMessage({
      id: 'customSnackbar.successMessage.deleteHolidaySchedule.title',
    })
    const description = formatMessage({
      id: 'customSnackbar.successMessage.deleteHolidaySchedule.description',
    })
    appActions.openSnackBarSuccess(description, title)(dispatch)
  } catch (ex) {
    await appActions.handleCatch(ex)(dispatch)
  }
}
const deleteSpecialSchedule = (dayId, scheduleId) => async dispatch => {
  try {
    cleanBeingEditedSchedule()(dispatch)
    cleanDayIdBeingAddingSchedule()(dispatch)

    await StoreSchedulesService.deleteSchedule(scheduleId)

    dispatch(deleteSpecialScheduleAction(dayId, scheduleId))
    const title = formatMessage({
      id:
        'customSnackbar.successMessage.deleteSpecialDayScheduleInterval.title',
    })
    const description = formatMessage({
      id:
        'customSnackbar.successMessage.deleteSpecialDayScheduleInterval.description',
    })
    appActions.openSnackBarSuccess(description, title)(dispatch)
  } catch (ex) {
    await appActions.handleCatch(ex)(dispatch)
  }
}
const deleteSpecialDay = () => async dispatch => {
  try {
    const selectedDayId = appStore.getSelectedDayIdInDeleteSpecialDayDialog()
    await StoreSchedulesService.deleteSpecialDay(selectedDayId)

    dispatch(deleteSpecialDayAction(selectedDayId))
    await getStoreSchedules()(dispatch)
    const title = formatMessage({
      id: 'customSnackbar.successMessage.deleteSpecialDaySchedule.title',
    })
    const description = formatMessage({
      id: 'customSnackbar.successMessage.deleteSpecialDaySchedule.description',
    })
    appActions.openSnackBarSuccess(description, title)(dispatch)
  } catch (ex) {
    await appActions.handleCatch(ex)(dispatch)
  }
}

const getStoreSchedules = () => async dispatch => {
  try {
    const store = appStore.getSelectedStoreInStoreSchedules()
    if (!store) {
      return
    }

    setAreStoresSchedulesLoading(true)(dispatch)
    const {
      data: schedules,
    } = await StoreSchedulesService.getStoreSchedulesSummary(
      store.city_address_id,
      store.id,
    )

    dispatch(getStoreSchedulesAction(schedules))
    setAreStoresSchedulesLoading(false)(dispatch)
  } catch (ex) {
    await appActions.handleCatch(ex)(dispatch)
    setAreStoresSchedulesLoading(false)(dispatch)
  }
}

const openStoresSchedulesFilterDrawer = () => dispatch => {
  return dispatch(openStoresSchedulesFilterDrawerAction())
}

const cleanBeingEditedSchedule = () => dispatch => {
  dispatch(cleanBeingEditedScheduleAction())
}

const cleanDayIdBeingAddingSchedule = () => dispatch => {
  dispatch(cleanDayIdBeingAddingScheduleAction())
}

const setBeingEditedSchedule = scheduleId => dispatch => {
  cleanDayIdBeingAddingSchedule()(dispatch)
  return dispatch(setBeingEditedScheduleAction(scheduleId))
}

const setDayIdBeingAddingSchedule = dayId => dispatch => {
  cleanBeingEditedSchedule()(dispatch)
  dispatch(setDayIdBeingAddingScheduleAction(dayId))
}

const setAreStoresSchedulesLoading = areStoresSchedulesLoading => dispatch => {
  dispatch(setAreStoresSchedulesLoadingAction(areStoresSchedulesLoading))
}

export default {
  addHolidaySchedule,
  addSpecialDay,
  addSpecialExtendDay,
  addSpecialDaySchedule,
  addSpecialExtendDaySchedule,
  applyStoresSchedulesFilter,
  applyStoresSchedulesFilterAction,
  cleanBeingEditedSchedule,
  cleanBeingEditedScheduleAction,
  cleanDayIdBeingAddingSchedule,
  cleanDayIdBeingAddingScheduleAction,
  clearStoresSchedulesFilter,
  closeStoresSchedulesFilterDrawer,
  closeStoresSchedulesFilterDrawerAction,
  confirmBeingEditingSchedule,
  deleteSpecialDay,
  deleteHolidaySchedule,
  deleteHolidayScheduleAction,
  deleteSpecialSchedule,
  deleteSpecialScheduleAction,
  getStoreSchedules,
  getStoreSchedulesAction,
  openStoresSchedulesFilterDrawer,
  openStoresSchedulesFilterDrawerAction,
  redirectStoresSchedulesFilter,
  setAreStoresSchedulesLoading,
  setAreStoresSchedulesLoadingAction,
  setBeingEditedSchedule,
  setBeingEditedScheduleAction,
  setDayIdBeingAddingSchedule,
  setDayIdBeingAddingScheduleAction,
  updateSpecialExtendDaySchedule,
}
