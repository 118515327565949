// Material UI
import { makeStyles } from '@material-ui/core';
// Consts

export default makeStyles(() => ({
  blockContent: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 10,
    backgroundColor: 'rgb(255 255 255 / 50%)',
    cursor: 'not-allowed',
  },
}));
