// External libs
import { createSelector } from 'reselect';

const selectCustomerPhoneProxySessionDialogReducer = state =>
  state.customerPhoneProxySessionDialogReducer;

const selectOrderId = createSelector(
  selectCustomerPhoneProxySessionDialogReducer,
  customerPhoneProxySessionDialogReducer =>
    customerPhoneProxySessionDialogReducer.orderId,
);

const CustomerPhoneProxySessionDialogSelectors = { selectOrderId };

export default CustomerPhoneProxySessionDialogSelectors;
