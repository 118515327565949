// Material UI
import { makeStyles } from '@material-ui/core';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  actionsContainer: {
    borderTop: `1px solid ${neutral.D10}`,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px 16px',
  },
  button: {
    marginLeft: '20px',
    minWidth: '110px',
  },
  cancelButton: {
    color: neutral.D15,
    fontSize: '13px !important',
    lineHeight: 'normal',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Poppins',
    fontSize: 17,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: '100%',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    padding: '24px',
    width: '100%',
  },
  explanationText: {
    marginTop: '24px',
  },
  titleContainer: {
    marginBottom: '24px',
  },
  topping: {},
  toppingsList: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    overflow: 'auto',
  },
}));
