// Material UI
import { makeStyles } from '@material-ui/core/styles';
// Consts
import { neutral } from '../../../../../shared/styles/colors';

export default makeStyles(theme => ({
  itemImageContainer: {
    alignItems: 'center',
    backgroundColor: neutral.D5,
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 8,
    padding: 16,
    height: 'auto',
    position: 'relative',
    borderRadius: 8,
    borderBottom: `1px solid ${neutral.D10}`,
    flexWrap: 'wrap',
  },
  itemTitle: {
    fontWeight: 600,
    fontSize: 14,
    margin: 0,
    width: '100%',
  },
  imageSmall: {
    maxHeight: 40,
    maxWidth: 40,
  },
  itemName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
  },
}));
