import { useFormatMessage } from '../../../../../../intl/intl';

export function useListOfStoresText() {
  const activeStoresText = `highDemand.state.activeStores`;
  const inactiveAndCloseStoresText = `highDemand.state.inactiveAndCloseStores`;
  const suspededStores = `highDemand.state.suspededStores`;
  const manageStoresText = `highDemand.state.manageMyStores`;

  return {
    activeStoresText: useFormatMessage({ id: activeStoresText }),
    inactiveAndCloseStoresText: useFormatMessage({
      id: inactiveAndCloseStoresText,
    }),
    suspededStoresText: useFormatMessage({ id: suspededStores }),
    manageStoresText: useFormatMessage({ id: manageStoresText }),
  };
}
