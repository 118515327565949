import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Switch,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

import CustomAlert from '../../../common/custom-alert';
import { CheckInCodeRT } from '../../../../features/check-in-code-rt';

import hooks from './hooks';
import { useFormatMessage } from '../../../../intl/intl';
import partnersActions from '../../../../redux/actions/partners-actions';
import LocalStorageService from '../../../../services/local-storage-service';
import dialogsManagerActions from '../../../../redux/actions/dialogsManager.actions';

import useStyles from './styles';
import { textIds } from './consts';
import Paths from '../../../../constants/Paths';
import { Serverities } from '../../../../constants/Severities';

import type { Props } from './interfaces';
import type { CheckInCodeRTModel } from '../../../../features/check-in-code-rt';

function StoreBox(props: Props) {
  const { partnerStore = {} } = props;
  const {
    id,
    address,
    logo,
    name,
    phone,
    enabled,
    suspensionReason,
    checkin_code,
    expired_at,
  } = partnerStore;

  const isPartnerIntegrated = hooks.useIsPartnerIntegrated();

  const styles = useStyles();
  const navegate = useNavigate();
  const dispatch = useDispatch();

  const phoneText = useFormatMessage(textIds.phone);
  const addressText = useFormatMessage(textIds.address);
  const diagnosticText = useFormatMessage(textIds.diagnosticLinkText);
  const scheduleText = useFormatMessage(textIds.showScheduleButtonText);

  const handleSchedule = useCallback(() => {
    navegate(`${Paths.getStoresSchedulesQueryParamsPath(id)}`);
  }, [navegate, id]);

  const handleStateSwitchChange = useCallback(() => {
    dispatch(partnersActions.deactivateIntegratedStore(id, !enabled));
  }, [dispatch, id, enabled]);

  const handleDiagnostic = useCallback(async () => {
    await dialogsManagerActions.openStoreDiagnosticDialog(id)(dispatch);
  }, [dispatch, id]);

  const logoUrl = useMemo(() => {
    return LocalStorageService.getRestaurantsImage(logo);
  }, [logo]);

  return (
    <Card
      variant='outlined'
      component='article'
      classes={{ root: styles.cardRoot }}
    >
      <CardHeader
        component='header'
        title={name}
        titleTypographyProps={{ component: 'h1' }}
        avatar={<_LogoStore name={name} url={logoUrl} />}
        classes={{
          root: styles.headerRoot,
          title: styles.headerTitle,
          action: styles.headerAction,
        }}
        action={
          isPartnerIntegrated && (
            <Switch
              color='primary'
              checked={enabled}
              onClick={handleStateSwitchChange}
            />
          )
        }
      />
      <_CardContent
        idStore={id}
        phone={{ label: phoneText, value: phone }}
        address={{ label: addressText, value: address }}
        checkinCode={checkin_code}
        expiredAt={expired_at}
      />
      <footer>
        <_CardActions
          scheduleBtn={{ text: scheduleText, onClick: handleSchedule }}
          diagnosticBtn={{ text: diagnosticText, onClick: handleDiagnostic }}
        />
        {suspensionReason && (
          <CustomAlert text={suspensionReason} type={Serverities.WARNING} />
        )}
      </footer>
    </Card>
  );
}

function _LogoStore({ name, url }: { name: string; url: string }) {
  return (
    <Avatar src={url} alt={`logo-${name ?? 'avatar'}`}>
      {name?.[0] ?? 'I'}
    </Avatar>
  );
}

function _CardContent(props: _CardContentProps) {
  const { idStore = NaN, address, phone, checkinCode, expiredAt } = props;
  const styles = useStyles();
  return (
    <CardContent component='main' classes={{ root: styles.contentRoot }}>
      <CheckInCodeRT checkinCode={checkinCode} expiredAt={expiredAt} />
      <Box component='section' className={styles.infoBox}>
        <Typography classes={{ root: styles.infoTypoBody }}>
          <span className={styles.bold}>ID:</span> {idStore}
        </Typography>
        <Typography classes={{ root: styles.infoTypoBody }}>
          <span className={styles.bold}>{address.label}:</span> {address.value}
        </Typography>
        <Typography classes={{ root: styles.infoTypoBody }}>
          <span className={styles.bold}>{phone.label}:</span> {phone.value}
        </Typography>
      </Box>
    </CardContent>
  );
}

function _CardActions(props: _CardActionsProps) {
  const { scheduleBtn, diagnosticBtn } = props;
  const styles = useStyles();
  return (
    <CardActions classes={{ root: styles.actionsRoot }}>
      <Button
        variant='text'
        color='primary'
        style={{ padding: 0 }}
        onClick={scheduleBtn.onClick}
      >
        {scheduleBtn.text}
      </Button>
      <Button
        color='primary'
        variant='contained'
        onClick={diagnosticBtn.onClick}
        style={{ padding: '0.5rem', lineHeight: 1.5 }}
      >
        {diagnosticBtn.text}
      </Button>
    </CardActions>
  );
}

export default StoreBox;

// +++++ INTERFACE LOCALS +++++
interface _CardContentProps extends CheckInCodeRTModel {
  idStore: number;
  address: __LabelAndValue;
  phone: __LabelAndValue;
}

interface _CardActionsProps {
  scheduleBtn: __TextAndOnClick;
  diagnosticBtn: __TextAndOnClick;
}

interface __LabelAndValue {
  label: string;
  value?: string;
}
interface __TextAndOnClick {
  text: string;
  onClick: () => void;
}
