// React
import React from 'react'
// Material UI
import { StepConnector } from '@material-ui/core'
// Styles
import useStyles from './CustomStepConnector.styles'

const CustomStepConnector = ({
  active,
  alternativeLabel,
  activeColor,
  completed,
}) => {
  const classes = useStyles({ activeColor })
  return (
    <StepConnector
      alternativeLabel={alternativeLabel}
      active={active}
      completed={completed}
      classes={{
        active: classes.active,
        alternativeLabel: classes.alternativeLabel,
        completed: classes.completed,
        disabled: classes.disabled,
        line: classes.line,
      }}
    />
  )
}

export default CustomStepConnector
