import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectors as partnerSelectors } from '../../../redux/partner';
import AppActions from '../../../redux/actions/app-actions/app.actions';
import PartnerActions from '../../../redux/actions/partners-actions';

export function useStoreSuspensionAlertState() {
  const dispatch = useDispatch();
  return {
    ssn: useSelector(partnerSelectors.selectSuspendedStoresNotification),
    storesDetails: useSelector(partnerSelectors.selectStoreStatusDetail),
    isLoadingStatusDetail: useSelector(
      partnerSelectors.selectIsStoreStatusDetailLoading,
    ),
    getStateNotify: useCallback(function () {
      dispatch(PartnerActions.getState());
    }, []),
    getStoresDetails: useCallback(function () {
      dispatch(PartnerActions.getStoreStatusDetail());
    }, []),
    openSnackBarSuccess: useCallback(function (params: OpenSnackBarParams) {
      const { title = '', msg = '' } = params;
      dispatch(AppActions.openSnackBarSuccess(msg, title));
    }, []),
  };
}

interface OpenSnackBarParams {
  title: string;
  msg: string;
}
