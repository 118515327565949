// React
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// Components
import ProductBoxesList from './ProductBoxesList';

const mapStateToProps = (state, { intl: { formatMessage } }) => {
  const title = formatMessage({ id: 'globals.products' });

  return { title };
};

const mapDispatchToProps = {};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProductBoxesList),
);
