import { useSelector } from 'react-redux';

import { turboHelper } from '../../../business/order/geoqueue';
import { ordersKanbanSelector } from '../../../redux/selectors/orders-kanban';
import { useCookingColumnLang } from './useCookingColumnLang';

export function useCookingColumn() {
  const amountInCooking = useSelector(ordersKanbanSelector.amountInCooking);
  const _ordersInCooking = useSelector(ordersKanbanSelector.inCooking);

  const showEmptyTextOfOrders = amountInCooking === 0;

  const text = useCookingColumnLang();
  const ordersInCooking = turboHelper.filterV2([..._ordersInCooking])

  return {
    text,
    ordersInCooking,
    showEmptyTextOfOrders,
    numberOfOrdersInCooking: amountInCooking,
  };
}
