import { useFormatMessage } from '../../../../../intl/intl';

export function useGeoqueueRTSectionLang() {
  return {
    title: useFormatMessage({
      id: `order.geoqueue.kanbanTitle`,
    }),
    actionText: useFormatMessage({
      id: `order.geoqueue.kanbanAction`,
    }),
    disclaimerText: useFormatMessage({
      id: `order.geoqueue.detailDisclaimer`,
    }),
  };
}
