import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import {
  COUNTRY_PHONE_MAP,
  getFormattedValue,
} from './CustomPhoneField.helper';

export function CustomPhoneField({
  classes = {},
  country = '',
  value,
  onChange,
  ...props
}) {
  const [phoneValue, setPhoneValue] = useState(getFormattedValue(value));

  // Set initial value
  useEffect(() => {
    if (value) {
      return;
    }
    const country = COUNTRY_PHONE_MAP.find(c => c.countryCode === country);
    if (country) {
      setPhoneValue(`+${country.prefix}`);
    }
  }, []);

  const handleInputChange = e => {
    const formattedValue = getFormattedValue(e.target.value);

    setPhoneValue(formattedValue);
    onChange(formattedValue.replace(/\D/g, ''));
  };

  return (
    <TextField {...props} value={phoneValue} onChange={handleInputChange} />
  );
}

export default CustomPhoneField;
