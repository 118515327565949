// Consts
import urls from '../../constants/Urls';
import DeactivationType from '../../constants/DeactivationType';
import PartnerOrderStateType from './../../constants/PartnerOrderStateType';
// Tools
import { PWEX, PWEXCodes } from '../../exceptions';
// Utils
import UrlUtils from '../../utils/url-utils/UrlUtils';
import { analitycsEvent, GAActions, GACategories } from '../../utils/ga-utils';
// Services
import http from './../HttpService';
import IndexedDBService from './../IndexedDBService';
import LocalStorageService from './../local-storage-service';
import ChromeExtensionService from '../ChromeExtensionService';
import StringUtils from '../../utils/StringUtils';
import RefuseReasons from '../../constants/RefuseReasons';

const cancelByStockOutAsSupport = async (id, product_ids, topping_ids) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(
    urls.REJECT_ORDER_BY_STOCK_OUT_AS_SUPPORT,
    { orderId: id },
  );

  const payload = {
    refuse_reason_enum: RefuseReasons.RAN_OUT_PRODUCTS,
    product_ids,
    topping_ids,
  };
  await http.put(`${microserviceUrl}${replacedUrl}`, payload);
};

function cancelOrder(id) {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.CANCEL_ORDER, {
    orderId: id,
  });

  const payload = {};
  return http.put(`${microserviceUrl}${replacedUrl}`, payload);
}

const confirmReception = (order_ids, partner_id) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();

  const payload = {
    order_ids,
    partner_id,
    platform_type: process.env.REACT_APP_EVENT_ORIGIN,
  };
  return http.post(
    `${microserviceUrl}${urls.PARTNER_STORE_LOGS_ORDER_EVENT}`,
    payload,
  );
};

const deactivateProductsToppings = async (
  store_id,
  product_ids,
  topping_ids,
) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();

  const payload = {
    deactivation_type: DeactivationType.DAILY,
    product_ids,
    store_id,
    topping_ids,
  };

  return await http.put(
    `${microserviceUrl}${urls.DEACTIVATE_ORDER_PRODUCTS_TOPPINGS}`,
    payload,
  );
};

const deliverToRappiTendero = async id => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.DELIVER_TO_RAPPITENDERO, {
    orderId: id,
  });

  const payload = {};
  const response = await http.put(`${microserviceUrl}${replacedUrl}`, payload);
  analitycsEvent(
    GACategories.ORDER_MODIFICATIONS,
    GAActions.HAND_TO_DOMICILIARY,
    `order_id=${id}`,
  );
  return response;
};

const closePickupOrder = async id => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(
    urls.CLOSE_ORDER_BY_PARTNER_PICKUP,
    { orderId: id },
  );
  return await http.put(`${microserviceUrl}${replacedUrl}`);
};

const getProductReminder = (product, formatMessage, printing) => {
  const toppingTitle = printing
    ? formatMessage({
        id: 'order.detail.toppingMessage.title.print',
      })
    : formatMessage({
        id: 'order.detail.toppingMessage.title',
      });

  const toppingText = formatMessage({
    id: 'order.detail.toppingMessage.text',
  });

  let message = `${toppingTitle} ${product.units} "${product.name}" ${toppingText}: `;

  return message;
};

function getOrderDetail(orderId) {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.GET_ORDER_DETAIL, {
    orderId,
  });

  return http.get(`${microserviceUrl}${replacedUrl}`);
}

const getRTIncident = orderId => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.GET_ORDER_RT_INCIDENT, {
    orderId,
  });

  return http.get(`${microserviceUrl}${replacedUrl}`);
};

function getQualifyNegativeReasons() {
  const microserviceUrl = LocalStorageService.getBaseUrl();

  return http.get(`${microserviceUrl}${urls.GET_QUALIFY_NEGATIVE_REASONS}`);
}

function getOrdersHistorical(storeId, page = 1) {
  if (!storeId) {
    const PWEXCode = PWEXCodes.PWEX1;
    const formatMessageObj = { id: PWEXCode };
    const ex = new PWEX(PWEXCode, formatMessageObj);
    throw ex;
  }

  const microserviceUrl = LocalStorageService.getBaseUrl();

  const replacedUrl = UrlUtils.replaceUrlParams(urls.GET_ORDERS_HISTORICAL, {
    storeId,
    pageNumber: page,
  });

  return http.get(`${microserviceUrl}${replacedUrl}`);
}

function getOrders() {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  return http.get(`${microserviceUrl}${urls.GET_ORDER_STATUS}`);
}

const concatCancelledOrders = async v2Ordes => {
  const canceledOrders = await IndexedDBService.getCanceledOrders();

  if (!v2Ordes || !v2Ordes.length) {
    return canceledOrders;
  }

  const completeOrders = [];

  // Add incoming orders and overrides those canceled in indexed db.
  for (const incomingOrder of v2Ordes) {
    const canceledOrder = canceledOrders.find(
      order => order.id === incomingOrder.id,
    );

    if (!canceledOrder) {
      completeOrders.push(incomingOrder);
      continue;
    }
    incomingOrder.state = PartnerOrderStateType.CANCEL_APP;
    incomingOrder.partner_order_state = PartnerOrderStateType.CANCEL_APP;
    completeOrders.push(incomingOrder);
  }

  // Add canceled orders not existing in the orders
  for (const canceledOrder of canceledOrders) {
    const existingOrder = completeOrders.find(
      order => order.id === canceledOrder.id,
    );

    if (!existingOrder) {
      canceledOrder.state = PartnerOrderStateType.CANCEL_APP;
      canceledOrder.partner_order_state = PartnerOrderStateType.CANCEL_APP;
      completeOrders.push(canceledOrder);
      continue;
    }
  }

  return completeOrders;
};

function getRejectionReasons(isMarketPlace) {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.GET_REJECT_REASONS, {
    isMarketPlace,
  });

  return http.get(`${microserviceUrl}${replacedUrl}`);
}

function deactivateToppings(productObjs) {
  const baseUrl = LocalStorageService.getBaseUrl();
  const requests = [];

  productObjs.forEach(productObj => {
    productObj.toppings.forEach(topping => {
      const payload = {
        deactivation_type: DeactivationType.DAILY,
        store_id: productObj.storeId,
        topping_id: topping.id,
      };

      requests.push(http.put(`${baseUrl}${urls.DEACTIVATE_TOPPING}`, payload));
    });
  });

  return http.all(requests);
}

function deactivateProducts(productObjs) {
  const baseUrl = LocalStorageService.getBaseUrl();
  const requests = [];
  productObjs.forEach(productObj => {
    const payload = {
      deactivation_type: DeactivationType.DAILY,
      store_id: productObj.storeId,
      product_id: productObj.productId,
    };
    requests.push(http.put(`${baseUrl}${urls.DEACTIVATE_PRODUCTS}`, payload));
  });

  return http.all(requests);
}

const searchForRTNow = async (id, cookingTime, partnerOrderState, storeId) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.SEARCH_RT_NOW, {
    orderId: id,
  });

  const payload = {
    store_id: storeId,
    cooking_time: cookingTime,
    partner_order_state: partnerOrderState,
  };
  const response = await http.put(`${microserviceUrl}${replacedUrl}`, payload);
  analitycsEvent(
    GACategories.ORDER_MODIFICATIONS,
    GAActions.READY_FOR_PICK_UP,
    `order_id=${id}`,
  );
  return response;
};

const setDeliveredCancelled = (
  delivered,
  order_id,
  partner_id,
  storekeeper_id,
  store_id,
) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const payload = {
    store_id,
    order_id: +order_id,
    partner_id,
    storekeeper_id,
    delivered,
  };

  return http.post(
    `${microserviceUrl}${urls.PARTNER_STORE_LOGS_DELIVERED_CANCELED_ORDERS}`,
    payload,
  );
};

const setDeliveredToRTBeforeCancelled = (
  order_id,
  partner_id,
  storekeeper_id,
  store_id,
) => {
  return setDeliveredCancelled(
    true,
    order_id,
    partner_id,
    storekeeper_id,
    store_id,
  );
};
const setNotDeliveredToRTBeforeCancelled = (
  order_id,
  partner_id,
  storekeeper_id,
  store_id,
) => {
  return setDeliveredCancelled(
    false,
    order_id,
    partner_id,
    storekeeper_id,
    store_id,
  );
};

const addMoreCookingTime = async (extraCookingTime, id, storeId) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.ADD_MORE_COOKINGTIME, {
    orderId: id,
  });

  const payload = {
    store_id: storeId,
    order_id: id,
    extra_cooking_time: extraCookingTime,
  };
  const response = await http.put(`${microserviceUrl}${replacedUrl}`, payload);
  analitycsEvent(
    GACategories.ORDER_MODIFICATIONS,
    GAActions.PARTNER_ADD_COOKING_TIME,
    `order_id=${id}#extra_cooking_time=${extraCookingTime}`,
  );
  return response;
};

const handToDomiciliary = (id, partner_order_state) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.HAND_TO_DOMICILIARY, {
    orderId: id,
  });

  const payload = { partner_order_state };

  return http.put(`${microserviceUrl}${replacedUrl}`, payload);
};

const releaseRtnow = async (storekeeperId, id) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.RELEASE_RT_NOW, {
    orderId: id,
  });

  const payload = {
    storekeeper_id: storekeeperId,
  };
  const reponse = await http.put(`${microserviceUrl}${replacedUrl}`, payload);
  analitycsEvent(
    GACategories.ORDER_MODIFICATIONS,
    GAActions.RELEASE_STOREKEEPER_BY_PARTNER,
    `order_id=${id}`,
  );
  return reponse;
};

function readyForPickUp(id, partner_order_state) {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.READY_FOR_PICK_UP, {
    orderId: id,
  });

  const payload = { partner_order_state };
  return http.put(`${microserviceUrl}${replacedUrl}`, payload);
}

const rejectNewOrder = async (
  id,
  refuse_reason_enum,
  reasonDescription,
  isMarketPlace,
) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.REJECT_NEW_ORDER, {
    orderId: id,
  });

  const payload = {
    refuse_reason_enum,
    refuse_reason_comment: '',
    is_marketplace: isMarketPlace,
  };
  const response = await http.put(`${microserviceUrl}${replacedUrl}`, payload);
  analitycsEvent(
    GACategories.ORDER_MODIFICATIONS,
    GAActions.CANCELED_BY_PARTNER_PRE_TAKE,
    `order_id=${id}#canceled_reason=${reasonDescription}`,
  );
  return response;
};

const rejectTookOrder = async (
  id,
  refuse_reason_enum,
  reasonDescription,
  isMarketPlace,
) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.REJECT_TOOK_ORDER, {
    orderId: id,
  });

  const payload = {
    refuse_reason_enum,
    refuse_reason_comment: '',
    is_marketplace: isMarketPlace,
  };
  const response = await http.put(`${microserviceUrl}${replacedUrl}`, payload);
  analitycsEvent(
    GACategories.ORDER_MODIFICATIONS,
    GAActions.CANCELED_BY_PARTNER_AFTER_TAKE,
    `order_id=${id}#canceled_reason=${reasonDescription}`,
  );
  return response;
};

function addWhim(price, name, orderId) {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.ADD_WHIM, {
    orderId,
  });

  const payload = {
    price: price,
    name: name,
    With_charge: false,
    order_whim_type_id: 'partner',
  };
  return http.put(`${microserviceUrl}${replacedUrl}`, payload);
}

function removeWhim(whimId, orderId) {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.REMOVE_WHIM, {
    orderId,
  });

  const payload = {
    whim_id: whimId,
  };
  return http.put(`${microserviceUrl}${replacedUrl}`, payload);
}

function changeOrderState(orderId, orderState) {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const payload = {
    order_id: orderId,
    partner_order_state: orderState,
  };
  return http.put(`${microserviceUrl}${urls.CHANGE_ORDER_STATE}`, payload);
}

const qualifyPositiveRT = (orderId, partnerId, storekeeperId) => {
  const incidentComment = 'Calificación positiva';
  const rule_id = 0;

  return qualifyRT(
    true,
    incidentComment,
    orderId,
    partnerId,
    rule_id,
    storekeeperId,
  );
};

const qualifyNegativeRT = (
  incidentComment,
  orderId,
  partnerId,
  rule_id,
  storekeeperId,
) => {
  return qualifyRT(
    false,
    incidentComment,
    orderId,
    partnerId,
    rule_id,
    storekeeperId,
  );
};

const qualifyRT = (
  incident,
  incidentComment,
  orderId,
  partnerId,
  rule_id,
  storekeeperId,
) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const payload = {
    incident: incident,
    incident_comment: incidentComment,
    rule_id,
    order_id: orderId,
    partner_id: partnerId,
    storekeeper_id: storekeeperId,
  };

  return http.post(`${microserviceUrl}${urls.QUALIFY_RT}`, payload);
};

const sendNotificationToRT = orderId => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.SEND_NOTIFICATION_TO_RT, {
    orderId,
  });
  return http.post(`${microserviceUrl}${replacedUrl}`);
};

const takeOrder = async (
  id,
  cookingTime,
  predictor,
  minCT,
  maxCT,
  defaultCT,
) => {
  const microserviceUrl = LocalStorageService.getBaseUrl();
  const replacedUrl = UrlUtils.replaceUrlParams(urls.TAKE_ORDER, {
    orderId: id,
  });
  let additional_info = {
    cooking_time: {
      predictor: predictor,
      min: minCT,
      default: defaultCT,
      max: maxCT,
    },
  };

  try {
    const hasExtension = !!(await ChromeExtensionService.isAvailable());
    if (hasExtension) {
      const version = await ChromeExtensionService.getVersion();
      if (!StringUtils.isNullOrEmpty(version)) {
        additional_info['chrome_extension_version'] = version;
      }
    }
  } catch (ex) {
    // Exception action isn't necesary.
  }
  let payload = {
    cooking_time: cookingTime,
    additional_info,
  };

  const response = await http.put(`${microserviceUrl}${replacedUrl}`, payload);

  analitycsEvent(
    GACategories.ORDER_MODIFICATIONS,
    GAActions.TAKEN_VISIBLE_ORDER,
    `order_id=${id}`,
  );

  return response;
};

const OrderService = {
  addMoreCookingTime,
  addWhim,
  getOrders,
  getOrdersHistorical,
  cancelOrder,
  confirmReception,
  changeOrderState,
  deactivateToppings,
  deactivateProducts,
  deactivateProductsToppings,
  deliverToRappiTendero,
  closePickupOrder,
  getOrderDetail,
  concatCancelledOrders,
  getProductReminder,
  handToDomiciliary,
  getRejectionReasons,
  getRTIncident,
  getQualifyNegativeReasons,
  qualifyPositiveRT,
  qualifyNegativeRT,
  readyForPickUp,
  releaseRtnow,
  removeWhim,
  rejectNewOrder,
  cancelByStockOutAsSupport,
  rejectTookOrder,
  searchForRTNow,
  setDeliveredToRTBeforeCancelled,
  setNotDeliveredToRTBeforeCancelled,
  takeOrder,
  sendNotificationToRT,
};

export default OrderService;
