import { clsx } from 'clsx';

import useStyles from './PhoneBox.styles';

export function PhoneBox({ title, number, classes = {} }) {
  const _classes = useStyles();
  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <div className={_classes.title}>{title}</div>
      <div className={_classes.number}>{number}</div>
    </div>
  );
}
