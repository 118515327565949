// External libs
import { createSelector } from 'reselect';
// Consts
import { textIds } from '../consts';
// Tools
import { formatMessage } from '../../../intl/intl';
// Selectors
import { selectors as cancelByStockOutSuggestionConfirmationDialogSelectors } from '../../../redux/cancel-by-stock-out-suggestion-confirmation-dialog';

const selectTitle = createSelector<any, number, string>(
  cancelByStockOutSuggestionConfirmationDialogSelectors.selectOrderId,
  orderId => formatMessage(textIds.title, { orderId }),
);

const selectors = { selectTitle };

export default selectors;
