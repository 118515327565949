// Braze
import Braze from '../../braze/Braze';
import history from '../../history';
// Consts
import NotificationPermissionsType from '../../constants/NotificationPermissionsType';
// Tools
import Amplitude from '../../tools/amplitude';
// Services
import AuthService from '../../services/AuthService';
import ChromeExtensionService from '../../services/ChromeExtensionService';
import CountriesService from '../../services/CountriesService';
import FirebaseService from '../../firebase/firebase';
import IndexedDBService from '../../services/IndexedDBService';
import LocalStorageService from '../../services/local-storage-service';
import PartnersService from '../../services/partners-service';
import RouterService from '../../services/RouterService';
import appStore from '../stores/app.store';
import PartnerStoreLogsService from '../../services/partner-store-logs-service';
// Actions
import AppActions from './app-actions';
import EventActions from './event-actions';
import OrderActions from './orders-actions';
import PartnersActions from './partners-actions';
import PartnerUtils from '../../utils/PartnerUtils';
import { formatMessage } from '../../intl/intl';
import settingsActions from './settings.actions';
import dialogsManagerActions from './dialogsManager.actions';
import partnerActions from './partners-actions';
import partnersService from '../../services/partners-service';
import StringUtils from '../../utils/StringUtils';
import { firstLoadWebApp } from '../../business/common/browser';

const CHECK_APP_VERSION_ACTION = 'CHECK_APP_VERSION_ACTION';
const LOGIN_ACTION = 'LOGIN_ACTION';
const AUTH_EXTERNALLY_ACTION = 'AUTH_EXTERNALLY_ACTION';
const LOGOUT_ACTION = 'LOGOUT_ACTION';

const checkAppVersionAction = () => ({
  type: CHECK_APP_VERSION_ACTION,
});
const authExternallyAction = () => ({
  type: AUTH_EXTERNALLY_ACTION,
});
const loginAction = () => ({
  type: LOGIN_ACTION,
});
const logoutAction = () => ({
  type: LOGOUT_ACTION,
});

const checkAppVersion = () => async dispatch => {
  try {
    const response = await PartnersService.checkAppVersion();

    dispatch(checkAppVersionAction());
    if (response.data.min_version <= process.env.REACT_APP_APP_VERSION) {
      return true;
    }

    logout()(dispatch);
    return false;
  } catch (ex) {
    AppActions.handleCatch(ex)(dispatch);
    // If the check app version cannot be confirmed, return true by default;
    return true;
  }
};

const login = (email, password) => async dispatch => {
  let response;
  try {
    response = await AuthService.login(email, password);
  } catch (ex) {
    EventActions.onLoginLogErrors(email, ex.status, ex.data);
    EventActions.onLogIn(false);
    AppActions.handleCatch(ex)(dispatch);
    return;
  }

  try {
    dispatch(loginAction());

    // First initialize the DB
    await IndexedDBService.initializeDB();
    AuthService.setDeviceId();
    LocalStorageService.setTokens(
      response.data.access_token,
      response.data.refresh_token,
    );
    dispatch(partnerActions.setIsFirstLogin(response.data.first_login));

    RouterService.replaceToOrders();
    EventActions.onLogIn(true);
    // Temporaly
    window.location.reload(true);
  } catch (ex) {
    EventActions.onLogIn(false);
    dispatch(logoutAction());
    LocalStorageService.clear();
    AppActions.handleCatch(ex)(dispatch);
  }
};

const authExternally = (access, lat, lng, refresh) => async dispatch => {
  if (
    !lat ||
    lat === '' ||
    !lng ||
    lng === '' ||
    !access ||
    access === '' ||
    !refresh ||
    refresh === ''
  ) {
    LocalStorageService.clear();
    const errorMessage = formatMessage({
      id: 'root.actions.authExternally.errorMessage',
    });
    const errorTitle = formatMessage({
      id: 'root.actions.authExternally.errorTitle',
    });
    AppActions.openSnackBarError(errorMessage, errorTitle);
    history.replace('/login');
    return;
  }

  try {
    const response = await CountriesService.resolveCountry(lat, lng);

    LocalStorageService.setLatAndLng(lat, lng);

    LocalStorageService.setCountry(response.data);

    // First initialize the DB
    await IndexedDBService.initializeDB();
    AuthService.setDeviceId();
    LocalStorageService.setTokens(access, refresh);
    dispatch(authExternallyAction());
    await startAppUp()(dispatch);

    RouterService.replaceToOrders();
  } catch (ex) {
    LocalStorageService.clear();
    AppActions.handleCatch(ex)(dispatch);
    history.replace('/login');
  }
};

const logout = (currentValue, currentDescriptionReason) => async dispatch => {
  try {
    EventActions.onLogOut();
    const deviceId = LocalStorageService.getDeviceId();
    await partnersService.sendLogOutReason(
      StringUtils.isNullOrEmpty(currentValue) ? currentValue : '',
      currentDescriptionReason,
      deviceId,
    );

    // Unregistering the service worker is necessary to avoid duplications with firebase registration and push receivings.
    FirebaseService.unRegisterServiceWorker();
    await FirebaseService.logoutAll();

    LocalStorageService.clear();
    await IndexedDBService.deleteDB();
    await ChromeExtensionService.pauseAudio();

    await Amplitude.setAnonymousUser();
    dispatch(logoutAction());
    window.location.reload(true);
  } catch (ex) {
    console.error(ex);

    LocalStorageService.clear();
    await ChromeExtensionService.pauseAudio();
    await Amplitude.setAnonymousUser();
    window.location.reload(true);
    await AppActions.handleCatch(ex)(dispatch);
    dispatch(logoutAction());
  }
};

const showOnboarding = () => dispatch => {
  const isFirstLogin = appStore.getIsFirstLogin();
  if (isFirstLogin) {
    const source = 'first_login';
    dispatch(dialogsManagerActions.openOnBoardingDialog(source));
  }
};

const startAppUp = () => async dispatch => {
  if (Notification.permission !== NotificationPermissionsType.GRANTED) {
    dispatch(showOnboarding());
    return;
  }

  if (!AuthService.isAuthenticated()) {
    return;
  }

  await AppActions.loadCountryCodeFromLocalStorage()(dispatch);
  await AppActions.loadCountryFlagFromLocalStorage()(dispatch);
  await settingsActions.loadSettingsFromLocalStorage()(dispatch);

  FirebaseService.initialize();

  const isValid = await checkAppVersion()(dispatch);
  if (!isValid) {
    return;
  }

  await OrderActions.getProfileWithOrders()(dispatch);
  await PartnersActions.getIfPartnerIsInBlacklistHandShake()(dispatch);
  const profile = appStore.getProfile();
  const countryCode = LocalStorageService.getCountryCode();
  const isPartnerClosed = PartnerUtils.isClosed(profile.partnerState);
  const hasExtension = !!(await ChromeExtensionService.isAvailable());

  if (!isPartnerClosed) {
    // Only open the welcome dialog when the partner is not closed.
    // WIP -> A LA ESPERA DE LA CONFIRMACION DE PRODUCTO
    // AppActions.openWelcomeDialog()(dispatch)
  }

  const partnerId = profile?.id ?? 0;

  dispatch(showOnboarding());
  // Initialize braze
  try {
    Braze.initialize(countryCode, partnerId, hasExtension);
  } catch (ex) {
    console.error(ex);
    AppActions.openSnackBarError(`Braze couldn't be initialized`)(dispatch);
  }

  try {
    await IndexedDBService.clearPrintedOrders();
  } catch (ex) {
    await AppActions.handleCatch(ex)(dispatch);
  }

  const tz = appStore.getTz();

  PartnerStoreLogsService.tryCreateNetworkDisconnectionIssueFromLS(
    partnerId,
    tz,
  ).then();

  const firstLoad = await firstLoadWebApp({ partnerId });
  console.info('firstLoad:', firstLoad);
};

window.addEventListener('storage', e => {
  if (!e.key) {
    window.location.reload(true);
    return;
  }

  if (e.key === 'countryCode') {
    window.location.reload(true);
    return;
  }

  if (e.key === 'refreshToken') {
    if (e.oldValue && !e.newValue) {
      window.location.reload(true);
      return;
    }

    if (!e.oldValue && e.newValue) {
      window.location.reload(true);
      return;
    }
  }
});

export default {
  authExternally,
  LOGOUT_ACTION,
  checkAppVersion,
  login,
  logout,
  startAppUp,
};
