import { clsx } from 'clsx';

import DateTimeUtils from '../../utils/DateTimeUtils';
import {
  analitycsEvent,
  GACategories,
  GAActions,
} from './../../utils/ga-utils';

import CustomLoader from '../common/custom-loader';
import SpecialDayDateAddButton from '../special-day-add-button';
import { StoreScheduleTableRow } from '../store-schedule-table-row';
import StoreScheduleIntervals from '../store-schedule-intervals';
import StoreScheduleTableHeader from '../store-schedule-table-header';

import useStyles from './SpecialDayStoreScheduleTable.styles';

function SpecialDayStoreScheduleTable(props) {
  const {
    classes = {},
    day,
    isLoading,
    language,
    onClickAddSchedule,
    onClickDeleteSchedule,
    onClickDeleteDay,
    schedules = [],
    showAddButton,
    partnerId,
  } = props;
  const _classes = useStyles();
  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <CustomLoader open={isLoading} />
      <StoreScheduleTableHeader day={day} />
      {schedules.map((scheduleDay, index) => {
        return (
          <StoreScheduleTableRow
            key={`schedules-${index}`}
            subtitle={scheduleDay.name}
            title={DateTimeUtils.getFormattedNextDate(
              scheduleDay.month,
              scheduleDay.day,
              language,
              'ddd DD MMM',
            )}
          >
            <StoreScheduleIntervals
              dayId={scheduleDay.id}
              onClickAddSchedule={(dayId, endsTime, startsTime) => {
                analitycsEvent(
                  GACategories.SCHEDULES,
                  GAActions.SAVE_SPECIALDAY_SCHEDULE,
                  `partner_id=${partnerId}`,
                );
                onClickAddSchedule(dayId, endsTime, startsTime);
              }}
              showDeleteButton
              onClickDeleteSchedule={scheduleId => {
                analitycsEvent(
                  GACategories.SCHEDULES,
                  GAActions.DELETE_SPECIALDAY_SCHEDULE,
                  `partner_id=${partnerId}`,
                );
                onClickDeleteSchedule(scheduleDay.id, scheduleId);
              }}
              schedules={scheduleDay.schedules}
              onClickDeleteDay={() => {
                analitycsEvent(
                  GACategories.SCHEDULES,
                  GAActions.DELETE_SPECIALDAY,
                  `partner_id=${partnerId}`,
                );
                onClickDeleteDay(scheduleDay.id);
              }}
            />
          </StoreScheduleTableRow>
        );
      })}
      {showAddButton && (
        <StoreScheduleTableRow title={<SpecialDayDateAddButton />} />
      )}
    </div>
  );
}

export default SpecialDayStoreScheduleTable;
