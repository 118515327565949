import { clsx } from 'clsx';
import { useState, useEffect } from 'react';

import StringUtils from '../../../utils/StringUtils';

import SearchIcon from '../../icons/search-icon/SearchIcon';
import CustomTextField from '../custom-text-field/CustomTextField';
import { CustomCancelButton } from '../buttons/custom-cancel-button';

import useStyles from './CustomSearchTextField.styles';

export function CustomSearchTextField(props) {
  const {
    classes = {},
    fullWidth,
    onChange,
    onClickCancelButton = () => {},
    placeholder,
    value,
    disabled = false,
    error = false,
    helperText = '',
    isDisabledCancelButton = false,
  } = props;

  const _classes = useStyles();

  const [hightLight, setHighlight] = useState(false);

  const [showCancelButton, setShowCancelButton] = useState(false);

  useEffect(() => {
    const mustHighlight = !StringUtils.isNullOrEmpty(value);
    setHighlight(mustHighlight);
  }, [value]);

  useEffect(() => {
    const mustShowCancelButton = !StringUtils.isNullOrEmpty(value);
    setShowCancelButton(mustShowCancelButton);
  }, [value]);

  return (
    <CustomTextField
      classes={{
        adornedEnd: clsx(_classes.adornedEnd, classes?.adornedEnd),
        adornedStart: clsx(_classes.adornedStart, classes?.adornedStart),
        root: clsx(classes?.root),
        input: clsx(_classes.input, classes?.input),
      }}
      highlight={hightLight}
      placeholder={placeholder}
      onChange={onChange}
      fullWidth={fullWidth}
      startAdornment={<SearchIcon className={_classes.searchIcon} />}
      endAdornment={
        !isDisabledCancelButton &&
        showCancelButton && (
          <CustomCancelButton
            classes={{
              button: clsx(_classes.cancelButton, classes?.cancelButton),
              icon: clsx(_classes.cancelButtonIcon, classes?.cancelButtonIcon),
            }}
            onClick={onClickCancelButton}
          />
        )
      }
      value={value}
      disabled={disabled}
      error={error}
      helperText={helperText}
    />
  );
}
