import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import CustomDialog from '../../../common/custom-dialog/CustomDialog';
import warningIcon from '../../../../assets/images/icons/warningIcon.svg';
import CustomButton from '../../../common/buttons/custom-button/CustomButton';
import { Types, Variants } from '../../../common/buttons/custom-button';
import { OrderMgmtReplaceProductDialogStyles } from './OrderMgmtReplaceProductDialog.style';

export const OrderMgmtReplaceProductDialog = ({
  isOpen,
  replaceProductCancelText,
  replaceProductContinueText,
  replaceProductDisclaimerText,
  replaceProductTitleText,
  handleContinueProductChanges,
  handleReplaceProductDialogClose,
  replaceProductId,
}: Props) => {
  const componentClasses = OrderMgmtReplaceProductDialogStyles();
  const theme = useTheme<any>();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <CustomDialog
      classes={{
        paper: componentClasses.paper,
        headerRoot: componentClasses.replaceProductDialogHeader,
      }}
      onClose={handleReplaceProductDialogClose}
      open={isOpen}
      showBackButton={!isMdUp}
      showCloseButton={isMdUp}
    >
      <Box
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        justifyContent='center'
        padding='1rem'
        width='100%'
        height='100%'
      >
        <Typography
          className={componentClasses.replaceProductDialogHeaderTitle}
        >
          {replaceProductTitleText}
        </Typography>
        <Box
          className={componentClasses.replaceProductDisclaimerContent}
          display='flex'
          alignItems='center'
          justifyContent='center'
          gridGap='0.5rem'
          width='100%'
          marginBottom='1.5rem'
          marginTop='1rem'
          padding='0.5rem 1rem'
        >
          <img
            className={componentClasses.replaceProductDisclaimerIcon}
            width={20}
            height={20}
            alt='warning-icon'
            src={warningIcon}
          />
          <Typography
            className={componentClasses.replaceProductDisclaimer}
            dangerouslySetInnerHTML={{ __html: replaceProductDisclaimerText }}
          />
        </Box>
        <Box
          display='flex'
          justifyContent='flex-end'
          gridGap='1rem'
          width='100%'
        >
          <CustomButton
            onClick={handleReplaceProductDialogClose}
            type={Types.RESET}
            variant={Variants.TEXT}
            fullWidth={false}
          >
            {replaceProductCancelText}
          </CustomButton>
          <CustomButton
            onClick={() => handleContinueProductChanges(replaceProductId)}
            type={Types.BUTTON}
            variant={Variants.CONTAINED}
            fullWidth={false}
          >
            {replaceProductContinueText}
          </CustomButton>
        </Box>
      </Box>
    </CustomDialog>
  );
};

interface Props {
  isOpen: boolean;
  replaceProductCancelText: string;
  replaceProductContinueText: string;
  replaceProductDisclaimerText: string;
  replaceProductTitleText: string;
  handleContinueProductChanges: any;
  handleReplaceProductDialogClose: any;
  replaceProductId: string;
}
