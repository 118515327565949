import { useSelector } from 'react-redux';

import { selectors } from '../../../redux/support-dialog';
import { selectors as profileSelector } from '../../../redux/partner';

const useOpen = () => {
  return useSelector(selectors.selectOpen);
};

const useProfile = () => {
  return useSelector(profileSelector.selectProfile);
};
const hooks = {
  useOpen,
  useProfile,
};

export default hooks;
