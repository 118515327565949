// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  body: {
    padding: '16px 0px 16px 16px',
    [theme.breakpoints.up('md')]: {
      padding: '16px 35px 16px 60px',
    },
  },
  header: {
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: neutral.D2,
    borderRight: `1px solid ${neutral.D10}`,
    color: neutral.D20,
    display: 'grid',
    fontSize: 13,
    gridAutoRows: 'min-content',
    gridRowGap: 2,
    height: '100%',
    lineHeight: 'normal',
    padding: '13px 16px',
    wordBreak: 'break-word',
    [theme.breakpoints.up('md')]: {
      padding: '12px 24px',
    },
  },
  root: {
    alignItems: 'center',
    borderTop: `1px solid ${neutral.D10}`,
    borderRight: `1px solid ${neutral.D10}`,
    borderLeft: `1px solid ${neutral.D10}`,
    display: 'grid',
    gridTemplateColumns: '92px auto',
    gridAutoRows: 'min-content',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '200px auto',
    },
  },
  title: {
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
  subtitle: {
    color: neutral.D15,
  },
}));
