import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLang } from './useLang';
import rootActions from '../../../redux/actions/root.actions';
import dialogsManagerActions from '../../../redux/actions/dialogsManager.actions';
import { selectors as logOutReasonsDialog } from '../../../redux/log-out-reasons-dialog';

import type { ChangeEvent } from 'react';

export function useLogOutReasonsDialog() {
  const [selectedOpt, setSelectedOpt] = useState<string>('');
  const [reasonText, setReasonText] = useState<string>('');

  const dispatch = useDispatch();

  const open = useSelector(logOutReasonsDialog.selectOpen);
  const logOutReasons = useSelector(logOutReasonsDialog.selectLogOutReasons);

  const lang = useLang();

  const handleClose = () => {
    setSelectedOpt('');
    setReasonText('');
    dispatch(dialogsManagerActions.close());
  };

  const handleChangeReasonText = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setReasonText(event.currentTarget.value ?? '');
  };

  const handleConfirm = () => {
    dispatch(rootActions.logout(selectedOpt, reasonText));
    handleClose();
  };

  const handleReasonToLogOut = (event: ChangeEvent<HTMLInputElement>) => {
    const eventOpt = event.currentTarget.value ?? '';
    setSelectedOpt(eventOpt);
    if (eventOpt !== selectedOpt) setReasonText('');
  };

  return {
    lang,
    open,
    logOutReasons,
    selectedOpt,
    reasonText,
    handleClose,
    handleChangeReasonText,
    handleConfirm,
    handleReasonToLogOut,
  };
}
