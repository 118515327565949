import { clsx } from 'clsx';
import { Avatar } from '@material-ui/core';
import { useState, useEffect } from 'react';

import useStyles from './CustomAvatar.styles';

const getLetterToShow = (error, letter, src) => {
  if (!error && src) {
    return 'das';
  }
  if (!letter) {
    return '';
  }
  return letter.toUpperCase();
};

function CustomAvatar({ letter, src, alt = '', classes = {} }) {
  const _classes = useStyles();

  const [error, setError] = useState(false);
  useEffect(() => {
    setError(false);
  }, [src]);

  const letterToShow = getLetterToShow(error, letter, src);

  return (
    <div>
      <Avatar
        alt={alt}
        classes={{ root: clsx(_classes.root, classes?.root) }}
        imgProps={{
          onError: () => {
            setError(true);
          },
        }}
        src={(!error && src) || ''}
      >
        {letterToShow}
      </Avatar>
    </div>
  );
}

export default CustomAvatar;
