import { makeStyles } from '@material-ui/core';
import Colors from '../../../../constants/Colors';
import { neutral } from '../../../../shared/styles/colors';

export default makeStyles({
  CustomOutlineEditButton: (props: any) => ({
    backgroundColor: props?.isInEditionMode
      ? Colors.WARNING_BG
      : props?.isDisabled
      ? neutral.white
      : neutral.D6,
    borderRadius: '50%',
    cursor: props.isDisabled ? 'not-allowed' : 'pointer',
  }),
  tooltip: {
    fontSize: '0.75rem',
    backgroundColor: neutral.black,
    padding: '0.5rem',
    fontWeight: 700,
    width: '12.5rem',
  },
});
