import React, { Component } from 'react';
import MenuIcon from './MenuIcon';

class OrdersHistoricalIcon extends Component {
  d2 =
    'M5.813 10.216v-3.93h7.136L14.918 4H21v16M5.813 20L3 11.43h15.187L21 20H5.813z';

  render() {
    const { selected } = this.props;

    return <MenuIcon d2={this.d2} selected={selected} />;
  }
}

export default OrdersHistoricalIcon;
