// Redux
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';

import { selectors as endPMFlowDialogSelectors } from '../../redux/end-PM-flow-dialog';

const useOpen = () => useSelector(endPMFlowDialogSelectors.selectOpen);

const useFormId = () => useSelector(endPMFlowDialogSelectors.selectFormId);

const useStoreId = () => useSelector(endPMFlowDialogSelectors.selectStoreId);

const useMediaQueryCode = (theme: any) => {
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isXlUp = useMediaQuery(theme.breakpoints.up('xl'));
  if (isXlUp) {
    return 'xl';
  }
  if (isLgUp) {
    return 'lg';
  }
  if (isMdUp) {
    return 'md';
  }
  if (isSmUp) {
    return 'sm';
  }
  return 'xs';
};

const hooks = {
  useOpen,
  useMediaQueryCode,
  useFormId,
  useStoreId,
};

export default hooks;
