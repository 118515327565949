import { clsx } from 'clsx';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from './CustomCloseButton.styles';

export function CustomCloseButton({ onClick, classes = {} }) {
  const _classes = useStyles();
  return (
    <IconButton
      className={clsx(classes?.button)}
      aria-label='Close'
      onClick={onClick}
    >
      <CloseIcon className={clsx(_classes.icon, classes?.icon)} />
    </IconButton>
  );
}
