import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useOrderProgress } from '../../order-progress';
import { CountdownToEditStyle } from '../CountdownToEdit.style';
import { AccessTimeOutlined } from '@material-ui/icons';
import { orderMgmtTimeoutCase } from '../../../../../business/order-mgmt';

export function CountdownClock({
  createdAt = '',
  countDownTime = 0,
  isTimeoutOrder = false,
}: Props) {
  const styles = CountdownToEditStyle();
  const { remainingTime, percent } = useOrderProgress({
    createdAt,
    countDownTime,
  });
  const { setIsCountdownComplete } = orderMgmtTimeoutCase();

  useEffect(() => {
    if (isTimeoutOrder && percent === 100) {
      setIsCountdownComplete(true);
    }
  }, [percent]);

  return (
    <Box
      display='flex'
      alignItems='center'
      gridGap='0.5rem'
      padding='0.25rem 0.5rem'
      className={styles.countdownClock}
    >
      <AccessTimeOutlined className={styles.clock} />
      <Typography className={styles.time}>
        {`${remainingTime.minutes}`.length === 1
          ? `0${remainingTime.minutes}`
          : `${remainingTime.minutes}`}
        :
        {`${remainingTime.seconds}`.length === 1
          ? `0${remainingTime.seconds}`
          : `${remainingTime.seconds}`}
      </Typography>
    </Box>
  );
}

interface Props {
  createdAt?: string;
  countDownTime: number;
  isTimeoutOrder?: boolean;
}
