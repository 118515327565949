import { useDispatch, useSelector } from 'react-redux';

import { printerActions } from './printerGlobalState.slice';

import type { StartupNotificationsGlobalState } from '../../infra/startupNotificationsGlobalState.model';

interface GS extends StartupNotificationsGlobalState {}

export function printerIsNotifiedSecondService() {
  const dispatch = useDispatch();

  const isNotifiedSecondMode = useSelector(
    (s: GS) => s.startupNotifications.printer.isNotified.secondMode,
  );

  function setIsNotifiedSecondMode() {
    dispatch(printerActions.setIsNotifiedSecondMode());
  }

  return {
    isNotifiedSecondMode,
    setIsNotifiedSecondMode,
  };
}
