import { makeStyles } from '@material-ui/core';

import { blue, neutral } from '../../../../../../shared/styles/colors';

export default makeStyles({
  container: {
    backgroundColor: neutral.white,
  },
  title: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  description: {
    fontSize: '0.75rem',
    fontWeight: 400,
  },
  divider: {
    height: '1px',
    width: '100%',
    border: `1px solid ${neutral['30']}`,
  },
  banner: {
    fontSize: '0.75rem',
    borderRadius: '0.5rem',
    lineHeight: 1.7,
    color: blue['70'],
    backgroundColor: blue['10'],
    '& > .MuiAlert-icon': {
      color: blue['70'],
    },
    '& a': {
      fontWeight: 600,
    },
  },
});
