// External libs
import { handleActions } from 'redux-actions';
// Actions
import RTReleaseConfirmationDialogActions from '../../actions/rt-release-confirmation-dialog-actions';

const initialState = {
  open: false,
  orderId: null,
};

export default handleActions(
  {
    [RTReleaseConfirmationDialogActions.clearRTReleaseConfirmationDialogAction]: state =>
      clear(state),
    [RTReleaseConfirmationDialogActions.closeRTReleaseConfirmationDialogAction]: state =>
      close(state),
    [RTReleaseConfirmationDialogActions.openRTReleaseConfirmationDialogAction]: (
      state,
      { payload: { orderId } },
    ) => open(state, orderId),
  },
  initialState,
);

const clear = state => {
  return {
    ...initialState,
  };
};

const close = state => {
  return {
    ...state,
    open: false,
  };
};

const open = (state, orderId = state.orderId) => {
  return {
    ...state,
    open: true,
    orderId,
  };
};
