// Material UI
import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
    flex: '0 1 100%',
  },
  messagesContainer: {
    display: 'flex',
    padding: '50px',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
  },
}))
