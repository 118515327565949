import { clsx } from 'clsx';

import { OrderDetailWhim } from '../order-detail-whim';
import { OrderProductsByCorridor } from '../order-products-by-corridor';

import { useOrderDetailDialogProductsSection } from './useOrderDetailDialogProductsSection';

import makeClasses from './OrderDetailDialogProductsSection.styles';

function OrderDetailDialogProductsSection({ classes = {} }) {
  const {
    productsByCorridor,
    whims,
    isBeingManaged,
  } = useOrderDetailDialogProductsSection();

  const _classes = makeClasses();

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <div
        className={clsx(
          _classes.productsByCorridorContainer,
          classes?.productsByCorridorContainer,
        )}
      >
        {productsByCorridor &&
          Object.keys(productsByCorridor).map(corridorId => (
            <OrderProductsByCorridor
              classes={{ root: _classes.root, corridor: _classes.corridorName }}
              key={`d-${corridorId}`}
              corridor={productsByCorridor[corridorId]}
            />
          ))}
        {whims.map((whim, index) => (
          <OrderDetailWhim
            key={`whim-${whim.id}-${index}`}
            id={whim.id}
            name={whim.name}
            price={whim.price}
            showDeleteBtn={isBeingManaged}
          />
        ))}
      </div>
    </div>
  );
}

export default OrderDetailDialogProductsSection;
