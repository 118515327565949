import { clsx } from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogContentText from '@material-ui/core/DialogContentText';
import { SwipeableDrawer, FormHelperText } from '@material-ui/core';

import CustomButton from './../buttons/custom-button';

import useStyles from './CustomSwipeableDialog.styles';

function CustomDialogContainer(props) {
  const { buttons, children, message, subtitle, title, classes = {} } = props;
  const _classes = useStyles();
  return (
    <div className={clsx(classes?.dialogContainer)}>
      {(!!title || !!subtitle) && (
        <div className={clsx(_classes.titleContainer, classes?.titleContainer)}>
          {!!title && (
            <DialogTitle
              id='alert-dialog-title'
              disableTypography
              classes={{ root: clsx(_classes.title, classes?.title) }}
            >
              {title}
            </DialogTitle>
          )}
          {!!subtitle && (
            <FormHelperText
              classes={{ root: clsx(_classes.subtitle, classes?.subtitle) }}
            >
              {subtitle}
            </FormHelperText>
          )}
        </div>
      )}
      <DialogContent
        classes={{
          root: clsx(_classes.contentContainer, classes?.contentContainer),
        }}
      >
        {!!message && (
          <DialogContentText
            id='alert-dialog-description'
            classes={{
              root: clsx(
                _classes.contentTextContainer,
                classes?.contentTextContainer,
              ),
            }}
          >
            {message}
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      {buttons.length > 0 && (
        <DialogActions
          classes={{
            root: clsx(_classes.buttonsConatiner, classes?.dialogDelivery),
          }}
        >
          {buttons.map((button, index) => {
            const buttonClasses = button.color
              ? _classes.button + ' ' + button.className
              : _classes.button +
                ' ' +
                _classes.buttonLabel +
                ' ' +
                button.className;
            return (
              <CustomButton
                autoFocus={button.autoFocus}
                className={buttonClasses}
                classes={{
                  ...button.classes,
                  root: _classes.buttonRoot,
                }}
                color={button.color}
                fullWidth={false}
                disabled={button.disabled}
                isLoading={button.isLoading}
                key={index}
                onClick={button.onClick}
                text={button.label}
                variant={button.variant}
              />
            );
          })}
        </DialogActions>
      )}
    </div>
  );
}

function CustomSwipeableDialog(props) {
  const {
    classes = {},
    disableDiscovery = false,
    disableBackdropClick = false,
    disableEscapeKeyDown = false,
    open = false,
    onClose = () => {},
    title = '',
    message = '',
    children = <></>,
    buttons = [],
    subtitle = '',
    swipeAreaWidth = 0,
  } = props;
  const _classes = useStyles();
  const matches = useMediaQuery(theme => theme.breakpoints.up('md'));
  return (
    <div>
      {!matches && (
        <SwipeableDrawer
          classes={{ paper: clsx(_classes.drawerPaper, classes?.drawerPaper) }}
          className={clsx(_classes.drawer, classes?.drawer)}
          anchor={'bottom'}
          disableDiscovery={disableDiscovery}
          onOpen={() => {}}
          open={open}
          onClose={onClose}
          swipeAreaWidth={swipeAreaWidth}
        >
          <CustomDialogContainer
            buttons={buttons}
            classes={classes}
            children={children}
            message={message}
            subtitle={subtitle}
            title={title}
          />
        </SwipeableDrawer>
      )}
      {matches && (
        <Dialog
          classes={{
            root: clsx(classes?.dialogRoot),
            paper: clsx(_classes.dialogPaper, classes?.dialogPaper),
            container: clsx(_classes.container, classes?.container),
          }}
          disableBackdropClick={disableBackdropClick}
          disableEscapeKeyDown={disableEscapeKeyDown}
          open={open}
          onClose={onClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <CustomDialogContainer
            buttons={buttons}
            classes={classes}
            children={children}
            message={message}
            subtitle={subtitle}
            title={title}
          />
        </Dialog>
      )}
    </div>
  );
}

export default CustomSwipeableDialog;
