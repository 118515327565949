import { useSelector } from 'react-redux';

import selectors from '../selectors';
import { selectors as cookedOrderConfirmationIssueDialogSelectors } from '../../../redux/cooked-order-confirmation-issue-dialog';

const useOpen = () => {
  return useSelector(cookedOrderConfirmationIssueDialogSelectors.selectOpen);
};

const useOrderId = () => {
  return useSelector(cookedOrderConfirmationIssueDialogSelectors.selectOrderId);
};

const useDescription = () =>
  useSelector(cookedOrderConfirmationIssueDialogSelectors.selectDescription);

const useSupportSuggestions = () => useSelector(selectors.selectOptions);

const useTitle = () => {
  return useSelector(selectors.selectTitle);
};

const hooks = {
  useDescription,
  useOpen,
  useOrderId,
  useSupportSuggestions,
  useTitle,
};

export default hooks;
