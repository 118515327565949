// Consts
import { StoreSchedulesDayCode } from '../constants/StoreSchedulesDayCode';
// Consts
import { StoreSchedulesType } from '../constants/StoreScheduleType';
// Utils
import { formatMessage } from '../intl/intl';

const formatScheduleHour = hour => {
  return hour && hour.slice(0, hour.lastIndexOf(':'));
};

const groupSchedules = storeSchedules => {
  const groupedSchedules = {
    [StoreSchedulesType.NORMAL]: {},
    [StoreSchedulesType.HOLIDAYS]: {},
    [StoreSchedulesType.SPECIALS]: {},
  };

  groupedSchedules[StoreSchedulesType.NORMAL] = groupSchedulesByDays(
    storeSchedules[StoreSchedulesType.NORMAL],
  );
  groupedSchedules[StoreSchedulesType.HOLIDAYS] = groupSchedulesById(
    storeSchedules[StoreSchedulesType.HOLIDAYS],
  );
  groupedSchedules[StoreSchedulesType.SPECIALS] = groupSchedulesById(
    storeSchedules[StoreSchedulesType.SPECIALS],
  );

  return groupedSchedules;
};

const groupSchedulesByDays = (schedules = []) => {
  // Initialize the default grouped object.
  const groupedSchedules = {
    [StoreSchedulesDayCode.MONDAY]: {
      schedules: [],
      title: formatMessage({ id: 'globals.monday' }),
    },
    [StoreSchedulesDayCode.TUESDAY]: {
      schedules: [],
      title: formatMessage({ id: 'globals.tuesday' }),
    },
    [StoreSchedulesDayCode.WEDNESDAY]: {
      schedules: [],
      title: formatMessage({ id: 'globals.wednesday' }),
    },
    [StoreSchedulesDayCode.THURSDAY]: {
      schedules: [],
      title: formatMessage({ id: 'globals.thursday' }),
    },
    [StoreSchedulesDayCode.FRIDAY]: {
      schedules: [],
      title: formatMessage({ id: 'globals.friday' }),
    },
    [StoreSchedulesDayCode.SATURDAY]: {
      schedules: [],
      title: formatMessage({ id: 'globals.saturday' }),
    },
    [StoreSchedulesDayCode.SUNDAY]: {
      schedules: [],
      title: formatMessage({ id: 'globals.sunday' }),
    },
  };

  for (const dayToUpdate of schedules) {
    if (
      !dayToUpdate.day ||
      !dayToUpdate.schedules ||
      !dayToUpdate.schedules.length === 0 ||
      !groupedSchedules[dayToUpdate.day]
    ) {
      // If a day code not included in the initial grouped Schedule is recievied
      // or it doesn't have schedules set,
      // just ignore it.
      continue;
    }

    for (const scheduleToPush of dayToUpdate.schedules) {
      const ends_time = formatScheduleHour(scheduleToPush.ends_time);
      const starts_time = formatScheduleHour(scheduleToPush.starts_time);
      const interval = { ends_time, starts_time };
      // Push the interval to the corresponding day.
      groupedSchedules[dayToUpdate.day].schedules.push(interval);
    }

    sortByHourSchedule(groupedSchedules[dayToUpdate.day].schedules);
  }

  return groupedSchedules;
};

const groupSchedulesById = schedules => {
  const groupedSchedules = [];
  for (const schedule of schedules) {
    for (const interval of schedule.schedules) {
      interval.ends_time = formatScheduleHour(interval.ends_time);
      interval.starts_time = formatScheduleHour(interval.starts_time);
    }
    sortByHourSchedule(schedule.schedules);
    groupedSchedules.push(schedule);
  }

  return groupedSchedules;
};

const sortByHourSchedule = schedules => {
  if (!schedules) {
    return;
  }

  schedules.sort((scheduleA, scheduleB) => {
    const splitA = scheduleA.starts_time.split(':');
    if (!splitA || splitA.length < 2) {
      return 0;
    }

    const minutesA = splitA[0] * 60 + splitA[1];

    const splitB = scheduleB.starts_time.split(':');
    if (!splitB || splitB.length < 2) {
      return 0;
    }

    const minutesB = splitB[0] * 60 + splitB[1];

    const sortResult = minutesA - minutesB;

    if (sortResult !== 0) {
      return sortResult;
    }

    const endSplitA = scheduleA.ends_time.split(':');

    if (!endSplitA || endSplitA.length < 2) {
      return sortResult;
    }
    const endMinutesA = endSplitA[0] * 60 + splitA[1];

    const endSplitB = scheduleB.ends_time.split(':');

    if (!endSplitB || endSplitB.length < 2) {
      return sortResult;
    }

    const endMinutesB = endSplitB[0] * 60 + splitB[1];

    return endMinutesA - endMinutesB;
  });
};

export default { groupSchedules };
