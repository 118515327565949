import { clsx } from 'clsx';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';

import { CustomTimeChip } from '../common/custom-time-chip';

import { textIds } from './consts';
import StringUtils from '../../utils/StringUtils';
import { useFormatMessage } from '../../intl/intl';

import useStyles from './styles';

import type { BaseProps } from '../../interfaces';

export function OrderDeliveryTimeChip(props: Props) {
  const { classes = {}, cookingTime, cookingTimeStartedAt } = props;

  const _classes = useStyles();

  const [isExpired, setIsExpired] = useState(false);
  const [value, setValue] = useState<moment.Moment | null>(null);

  const helperText = useFormatMessage(textIds.helperText);

  useEffect(() => {
    if (StringUtils.isNullOrEmpty(cookingTimeStartedAt)) {
      setValue(null);
      return;
      // TODO: shouldn't use return here, this avoid the useEffect's return
    }
    const acceptanceMoment = moment(cookingTimeStartedAt || undefined);
    if (!acceptanceMoment.isValid()) {
      setValue(null);
      return;
      // TODO: shouldn't use return here, this avoid the useEffect's return
    }
    const deliveryMoment = acceptanceMoment.add(cookingTime, 'minutes');
    setValue(deliveryMoment);
    const updateIsExpired = () => {
      const newIsExpired =
        !!deliveryMoment && deliveryMoment.diff(moment(), 'seconds', true) < 0;
      setIsExpired(newIsExpired);
    };
    updateIsExpired();
    const isExpiredUpdateInterval = setInterval(() => updateIsExpired(), 5000);
    return () => {
      clearInterval(isExpiredUpdateInterval);
    };
  }, [cookingTime, cookingTimeStartedAt]);

  return (
    <CustomTimeChip
      classes={{ root: clsx(_classes.root, classes?.root) }}
      helperText={helperText}
      isDanger={isExpired}
      value={value}
    />
  );
}

interface Props extends BaseProps {
  cookingTime: number;
  cookingTimeStartedAt: string | null;
}
