import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import useStyles from '../delivery-detial.styles';

export function OrderDeliveryDetailLoader() {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Skeleton variant='text' width={150} />
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        margin='1rem 0'
      >
        <Skeleton variant='text' width={200} />
        <Skeleton variant='text' width={50} />
      </Box>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        margin='1rem 0'
      >
        <Skeleton variant='text' width={130} />
        <Box display='flex' gridGap='0.5rem'>
          <Skeleton variant='circle' width={20} />
          <Skeleton variant='text' width={20} />
          <Skeleton variant='circle' width={20} />
        </Box>
      </Box>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        margin='1rem 0'
      >
        <Skeleton variant='text' width={150} />
        <Box display='flex' gridGap='0.5rem'>
          <Skeleton variant='circle' width={20} />
          <Skeleton variant='circle' width={20} />
        </Box>
      </Box>
    </Box>
  );
}
