// Consts
import MuiVariants from './MuiVariants';

const muiThemeTypography = {
  fontFamily: ['Poppins', 'Georgia', 'sans-serif'].join(','),
  fontStyle: 'normal',
  letterSpacing: '-0.015em',
  [MuiVariants.H1]: {
    fontSize: 40,
    lineHeight: '56px',
  },
  [MuiVariants.H2]: {
    fontSize: 32,
    lineHeight: '48px',
  },
  [MuiVariants.H3]: {
    fontSize: 24,
    lineHeight: '32px',
  },
  [MuiVariants.H4]: {
    fontSize: 18,
    lineHeight: '26px',
  },
  [MuiVariants.Body1]: {
    fontSize: 16,
    lineHeight: 1.5,
  },
  [MuiVariants.Subtitle1]: {
    fontSize: 14,
    lineHeight: '24px',
  },
  [MuiVariants.Subtitle2]: {
    fontSize: 12,
    lineHeight: '20px',
  },
  [MuiVariants.Caption]: {
    fontSize: 10,
    lineHeight: '18px',
  },
  [MuiVariants.Overline]: {
    fontSize: 10,
    lineHeight: '16px',
    letterSpacing: '0.12em',
    textTransform: 'uppercase',
  },
  [MuiVariants.Button]: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: 'Poppins bold',
    lineHeight: '18px',
    textTransform: 'none',
  },
};

export default muiThemeTypography;
