import { orderMgmtStatus } from '../domain/models';
import { orderMgmtAdapter } from '../infra/adapters';
import { orderMgmtSearchFacade, ordermgmtDialogFacade } from '../infra/facades';

export function OrderDialogApp() {
  const {
    onCleanOrderMgmtDialog,
    onRejectOrderModification,
    selectStatus,
    selectProducts,
    selectPartnerId,
  } = ordermgmtDialogFacade();
  const {
    onClearDialog: onCleanOrderMgmtSearchDialog,
  } = orderMgmtSearchFacade();

  const isSuccessful = selectStatus === orderMgmtStatus.Successful;

  const hasError = selectStatus === orderMgmtStatus.Rejected;

  const rejectHandler = (message: string) => {
    onCleanOrderMgmtSearchDialog();
    onCleanOrderMgmtDialog();
    onRejectOrderModification(message);
  };

  const successfulHandler = (
    storeId: number,
    orderId: number,
    notCallChatSupport: boolean,
  ) => {
    onCleanOrderMgmtDialog();
    onCleanOrderMgmtSearchDialog();
    if (notCallChatSupport) return;
    const {
      initial_modification: substitutionData,
    } = orderMgmtAdapter.toProductSubstitutionItemsRequest(
      selectProducts,
      orderId,
      selectPartnerId,
      storeId,
    );
    const substitutionEvent = new CustomEvent('substitution', {
      detail: substitutionData,
    });
    document.dispatchEvent(substitutionEvent);
  };

  return {
    isSuccessful,
    hasError,
    rejectHandler,
    successfulHandler,
  };
}
