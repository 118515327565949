import { CargoOrderStatus } from '../types';

export interface CargoOrder {
  id: number;
  storeId: number;
  storeName: string;
  clientName: string;
  phoneNumber: string;
  status: CargoOrderStatus;
  followUpLink: string;
}

export interface CargoOrderFilters {
  storeId: number;
  orderId: number;
}

// TODO: should be moved to a helper file or domain file (or even a hook)?
export const handleOrderFilters = (
  data: Array<CargoOrder>,
  filters: CargoOrderFilters,
) => {
  let orders = [...data];
  if (filters.storeId)
    orders = orders.filter(({ storeId }) => storeId === filters.storeId);
  if (filters.orderId)
    orders = orders.filter(({ id }) =>
      id.toString().includes(filters.orderId.toString()),
    );
  return orders;
};
