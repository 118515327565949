import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AcceptedOrderRequest,
  DisabledAutoTakeRequest,
  RejectedOrderRequest,
} from '../../core/requests';
import { AutoTakeOrderResponse } from '../../core/responses/auto-take-order.response';
import { orderRepository } from '../../repositories';
import { OM_ORDER_DETAIL_SLICE_NAME } from './core';

const acceptOrder = createAsyncThunk(
  `${OM_ORDER_DETAIL_SLICE_NAME}/acceptOrderWithTimeout`,
  async (request: { orderId: number; payload: AcceptedOrderRequest }) => {
    await orderRepository.acceptWithTimeout(request.orderId, request.payload);
  },
);
const rejectedOrder = createAsyncThunk(
  `${OM_ORDER_DETAIL_SLICE_NAME}/rejectedOrderWithTimeout`,
  async (request: { orderId: number; payload: RejectedOrderRequest }) => {
    await orderRepository.rejectWithTimeout(request.orderId, request.payload);
  },
);

const disabledAutoTake = createAsyncThunk(
  `${OM_ORDER_DETAIL_SLICE_NAME}/disabledAutoTake`,
  async (request: DisabledAutoTakeRequest): Promise<AutoTakeOrderResponse> => {
    const response = await orderRepository.disabledAutoTake(request);
    return response.data;
  },
);

export const orderDetailThunks = {
  acceptOrder,
  rejectedOrder,
  disabledAutoTake,
} as const;
