import { Box } from '@material-ui/core';

import { OrderCard } from './comps';

import type { CargoOrder } from '../../../../../../business/cargo';
import type { PartnerId } from '../../../../../../business/shared/domain';

export function CardList({ orders, partnerId }: Props) {
  return (
    <Box
      display='flex'
      flex={1}
      flexDirection='column'
      gridGap='1rem'
      margin='1rem 0'
      overflow='auto'
    >
      {orders.map(order => (
        <OrderCard order={order} key={`o-${order.id}`} partnerId={partnerId} />
      ))}
    </Box>
  );
}

interface Props {
  orders: CargoOrder[];
  partnerId: PartnerId;
}
