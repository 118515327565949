import { IOrderCase } from '../../domain';
import { orderhandler } from '../../infra/order';

export function orderCase(): IOrderCase {
  const {
    allOrders,
    currentOrder: order,
    detail,
    currentOrderId,
  } = orderhandler();

  return {
    allOrders,
    orderId: currentOrderId,
    order,
    orderDetail: detail,
  };
}
