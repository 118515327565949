import { makeStyles } from '@material-ui/core';
import Colors from '../../../../../constants/Colors';
import { neutral } from '../../../../../shared/styles/colors';

export default makeStyles((theme: any) => ({
  cardContainer: {
    background: neutral.white,
    borderRight: `1px solid ${neutral.D10}`,
    color: neutral.D20,
    [theme.breakpoints.up('md')]: {
      border: `1px solid ${Colors.PRIMARY_COLOR}`,
    },
  },
  quantity: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '3.375rem',
    minWidth: '3.375rem',
    fontSize: '1.5rem',
    color: neutral.D20,
  },
  headerDivider: {
    borderRight: `1px solid ${neutral.D10}`,
  },
  replaceItem: {
    color: Colors.PRIMARY_COLOR,
  },
  deleteItem: {
    color: neutral.D18,
  },
  cardBody: {
    borderTop: `1px solid ${neutral.D10}`,
  },
  description: {
    margin: '1rem 1.5rem 1rem 0',
  },
  alertMessage: {
    margin: '1.25rem 0',
  },
  toppingCategory: {
    color: neutral.D15,
    fontSize: '0.75rem',
  },
  toppingDescription: {
    fontSize: '0.875rem',
    width: '100%',
  },
  cardFooter: {
    padding: '1.25rem 1rem',
  },
  subtotal: {
    fontWeight: 600,
  },
  corridorName: {
    color: Colors.PRIMARY_COLOR,
    fontSize: '0.75rem',
    fontWeight: 600,
    marginTop: '1rem',
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
}));
