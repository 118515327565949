import { useCallback } from 'react';

import CustomDrawer from '../common/custom-drawer';
import SupportChat from '../support-chat/SupportChatContainer';
import { CustomClickableBox } from '../common/boxes/custom-clickable-box';

import { textIds } from './consts';
import { useFormatMessage } from '../../intl/intl';

import useStyles from './styles';

function SupportChatDrawer(props: Props) {
  const {
    initialView,
    close,
    onTypificationClick,
    open,
    orderDetail,
    partnerId,
    partnerName,
    typifications,
  } = props;

  const componentClasses = useStyles();
  const chatSupportTitle = useFormatMessage(textIds.chatSupportTitle);
  const typificationsTitle = useFormatMessage(textIds.typificationsTitle);
  const handleOnClose = useCallback(() => {
    return close(orderDetail.id);
  }, [close, orderDetail.id]);

  return (
    <div>
      <CustomDrawer
        open={open}
        title={chatSupportTitle}
        onClose={handleOnClose}
      >
        {initialView === 0 && (
          <div className={componentClasses.root}>
            <div className={componentClasses.title}>{typificationsTitle}</div>
            {typifications.map((typification: any) => {
              return (
                <CustomClickableBox
                  title={typification.description}
                  key={typification.typification}
                  onClick={async () => {
                    await onTypificationClick(
                      orderDetail.id,
                      partnerId,
                      partnerName,
                      typification.typification,
                      typification.description,
                    );
                  }}
                />
              );
            })}
          </div>
        )}
        {initialView === 1 && <SupportChat />}
      </CustomDrawer>
    </div>
  );
}

export default SupportChatDrawer;

interface Props {
  initialView: number;
  close: any;
  onTypificationClick: any;
  open: boolean;
  orderDetail: any;
  partnerId: number;
  partnerName: string;
  typifications: any;
}
