import { clsx } from 'clsx';

import CustomButton from '../common/buttons/custom-button';

import ordersUtils from '../../utils/OrdersUtils';
import useStyles from './OrderDetailDialogActionsBox.styles';

function OrderDetailDialogActionsBox(props) {
  const {
    classes = {},
    cancelButtonText,
    canceledMarketplaceOrderButtonText,
    canceledPickupOrderButtonText,
    deliveredToRTText,
    notDeliveredToRTText,
    onClickCanceledMarketplaceOrderButton,
    onClickCanceledPickupOrderButton,
    onClickDeliveredToRT,
    onClickNotDeliveredToRT,
    onClickOrderReadyButton,
    onClickDeliverButton,
    onClickRejectButton,
    // onClickSubstitutionFlowButton,
    order,
    orderReadyButtonText,
    packageDeliveredButtonText,
    sentToDeliverButtonText,
    partnerId,
    pendingToDeliverButtonText,
    showCanceledFullDeliveryOrderActions,
    showCanceledMarketplaceOrderActions,
    showCanceledPickupOrderActions,
    showCookingOrderActions,
    showPendingToDeliverOrderActions,
    storekeeperId,
    storeId,
    // substitutionButtonText,
    // showSubstitutionFlowButtonAction,
    // showNewOrderActions,
    // rejectButtonText = '',
    // isOrderEnabledToEdit,
  } = props;

  const _clases = useStyles();
  const cookinglabelButton = ordersUtils.isMarketPlace(order.delivery_method)
    ? sentToDeliverButtonText
    : orderReadyButtonText;
  const pendingDeliverylabelButton = ordersUtils.isMarketPlace(
    order.delivery_method,
  )
    ? packageDeliveredButtonText
    : pendingToDeliverButtonText;

  return (
    <div className={clsx(_clases.root, classes?.root)}>
      {showCanceledFullDeliveryOrderActions && (
        <>
          <CustomButton
            classes={{ submitButton: _clases.customButtonSecondaryRoot }}
            onClick={async () => {
              await onClickNotDeliveredToRT(
                order.id,
                partnerId,
                storekeeperId,
                storeId,
              );
            }}
            variant='outlined'
          >
            {notDeliveredToRTText}
          </CustomButton>
          <CustomButton
            classes={{ submitButton: _clases.customButtonSecondaryRoot }}
            onClick={async () => {
              await onClickDeliveredToRT(
                order.id,
                partnerId,
                storekeeperId,
                storeId,
              );
            }}
            variant='outlined'
          >
            {deliveredToRTText}
          </CustomButton>
        </>
      )}
      {showCanceledMarketplaceOrderActions && (
        <CustomButton
          classes={{ submitButton: _clases.customButtonSecondaryRoot }}
          onClick={async () => {
            await onClickCanceledMarketplaceOrderButton(order.id);
          }}
          variant='outlined'
        >
          {canceledMarketplaceOrderButtonText}
        </CustomButton>
      )}
      {showCanceledPickupOrderActions && (
        <CustomButton
          classes={{ submitButton: _clases.customButtonSecondaryRoot }}
          onClick={async () => {
            await onClickCanceledPickupOrderButton(order.id);
          }}
          variant='outlined'
        >
          {canceledPickupOrderButtonText}
        </CustomButton>
      )}
      {/*{showNewOrderActions && !isOrderEnabledToEdit && (*/}
      {/*  <>*/}
      {/*    {showSubstitutionFlowButtonAction && (*/}
      {/*      <CustomButton*/}
      {/*        variant='outlined'*/}
      {/*        onClick={() => onClickSubstitutionFlowButton(order.id)}*/}
      {/*      >*/}
      {/*        <SyncAltIcon className={componentClases.substitutionButtonIcon} />*/}
      {/*        {substitutionButtonText}*/}
      {/*      </CustomButton>*/}
      {/*    )}*/}
      {/*    <CustomButton*/}
      {/*      classes={{*/}
      {/*        submitButton: componentClases.customButtonSecondaryRoot,*/}
      {/*      }}*/}
      {/*      color='secondary'*/}
      {/*      onClick={onClickRejectButton}*/}
      {/*    >*/}
      {/*      {rejectButtonText}*/}
      {/*    </CustomButton>*/}
      {/*  </>*/}
      {/*)}*/}
      {showCookingOrderActions && (
        <>
          <CustomButton
            classes={{ submitButton: _clases.customButtonSecondaryRoot }}
            color='secondary'
            onClick={onClickRejectButton}
          >
            {cancelButtonText}
          </CustomButton>
          <CustomButton
            onClick={async () => {
              await onClickOrderReadyButton(order);
            }}
            variant='contained'
          >
            {cookinglabelButton}
          </CustomButton>
        </>
      )}
      {showPendingToDeliverOrderActions && (
        <CustomButton
          onClick={async () => {
            await onClickDeliverButton(order.id);
          }}
          variant='contained'
        >
          {pendingDeliverylabelButton}
        </CustomButton>
      )}
    </div>
  );
}

export default OrderDetailDialogActionsBox;
