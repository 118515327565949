import { makeStyles } from '@material-ui/core';

import { red } from '../../../../../../../../../../shared/styles/colors';

export default makeStyles({
  cancelDialogPaper: {
    height: '20rem',
    width: '45.6875rem',
    paddingBottom: '0.5rem',
    position: 'relative',
  },
  cancelDialogHeader: {
    height: '3rem',
    borderBottom: 'transparent',
  },
  cancelDialogTitle: {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '2rem',
  },
  cancelDialogDescription: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
  },
  cancelDialogDisclaimer: {
    backgroundColor: red['10'],
    borderLeft: `3px solid ${red['60']}`,
    padding: '0.5rem 1rem',
  },
  cancelDisclaimerIcon: {
    alignSelf: 'flex-start',
    width: '1.25rem',
    height: '1.25rem',
  },
  cancelDisclaimerText: {
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
  },
});
