import { useEffect } from 'react';
import {
  CargoKanbanCase,
  CargoRequestRtCase,
  PULL_INTERVAL_TIME,
} from '../../../../../business/cargo';
import TimeFormatUtilities from '../../../../../utils/TimeFormatUtilities';

export function useCargoKanban() {
  const { stores, partnerId } = CargoRequestRtCase();
  const {
    status: kanbanStatus,
    orders,
    fetchOrders,
    setFilterOrders,
    setLastUpdate,
    lastUpdate,
    orderFilters,
  } = CargoKanbanCase();

  const isKanbanLoading = kanbanStatus === 'isLoading';
  const isRefreshData = kanbanStatus === 'refresh';
  const isKanbanEmpty = kanbanStatus === 'empty' && orderFilters.orderId === 0;
  const hasKanbanNoResults =
    kanbanStatus === 'empty' && orderFilters.orderId > 0;
  const hasKanbanResults = kanbanStatus === 'successful' || !!orders.length;

  useEffect(() => {
    const getOrders = () => {
      const orderListRequest = { storeIds: [...stores.map(({ id }) => id)] };
      fetchOrders(orderListRequest);
      setLastUpdate(TimeFormatUtilities.formatCurrentTime());
    };
    getOrders();
    const getOrdersInterval = setInterval(getOrders, PULL_INTERVAL_TIME);
    return () => clearInterval(getOrdersInterval);
  }, []);

  return {
    isKanbanLoading,
    isRefreshData,
    isKanbanEmpty,
    hasKanbanNoResults,
    hasKanbanResults,
    stores,
    orders,
    orderId: orderFilters.orderId,
    storeId: orderFilters.storeId,
    setFilterOrders,
    partnerId,
    lastUpdate,
  };
}
