// Material UI
import { makeStyles } from '@material-ui/core'
import Colors from '../../../constants/Colors'

export default makeStyles(theme => ({
  root: ({ backgroundImg }: { backgroundImg: string }) => ({
    cursor: 'pointer',
    borderRadius: 6,
    backgroundColor: Colors.CORNFLOWER_BLUE,
    fontSize: 17,
    fontWeight: 'bold',
    backgroundImage: `url(${backgroundImg})`,
    backgroundPosition: 'right',
    backgroundSize: 'auto 100%',
    backgroundRepeat: 'no-repeat',
  }),
  textContainer: {
    width: '60%',
    padding: '10px 0px 10px 15px',
    whiteSpace: 'pre-wrap',
    display: 'grid',
    gridAutoFlow: 'row',
    gridRowGap: 5,
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
  },
  description: {
    fontSize: 12,
    fontWeight: 'normal',
    [theme.breakpoints.up('md')]: {
      width: 344,
    },
  },
  text: {
    lineHeight: 1.17,
    letterSpacing: '-0.26px',
    fontSize: 17,
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 22,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 24,
    },
  },
  link: {
    fontSize: 11,
    color: Colors.DODGER_BLUE,
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 12,
    },
  },
}))
