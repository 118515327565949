import { requestAdapter } from '../adapters';
import { CargoRepository } from '../repositories';
import { CargoSelectors, cargoActions } from '../store';
import appStore from '../../../../redux/stores/app.store';

import type { ICargoRequest } from '../core/contracts';
import type { CoverageVerification, RTRequest } from '../../domain';

export function cargoRequestHandler() {
  const { onSetNewOrder, onVerifyCoverage, onCreateOrder } = cargoActions();

  const requestVerifyCoverage = (model: CoverageVerification) => {
    const payload = requestAdapter.toCoverage(model);
    onVerifyCoverage(payload);
  };

  const createOrder = (model: Partial<RTRequest>) => {
    const payload = requestAdapter.toCreateOrder(model);
    onCreateOrder(payload);
  };

  return {
    get,
    setNewOrder: onSetNewOrder,
    requestVerifyCoverage,
    createOrder,
    dispatch,
  };
}

function get(): ICargoRequest {
  const {
    selectStores,
    selectStatus,
    selectNewOrder,
    selectCoverageStatus,
  } = CargoSelectors();

  const partnerId = appStore.getPartnerId();

  return {
    countryCode: CargoRepository.getCountryCode(),
    stores: selectStores,
    status: selectStatus,
    newOrder: selectNewOrder,
    coverageStatus: selectCoverageStatus,
    partnerId,
  };
}

function dispatch() {
  const { onGetCargoStores } = cargoActions();
  return {
    onGetCargoStores,
  };
}
