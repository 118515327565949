import { Box } from '@material-ui/core';
import React from 'react';
import { CustomKeyValueBox } from '../../../../common/custom-key-value-box';
import { FontWeight, Variants } from '../../../../common/typography';
import OrderMgmtStyles from '../../../OrderManagement.style';
import { ChipOrderDisclaimer } from './ChipOrderDisclaimer';
import { useOrderMgmtLang } from '../../../hooks';

export const OrderMgmtSubtotal = ({ totalLabel, totalValue }: Props) => {
  const componentClasses = OrderMgmtStyles();
  const { finalPriceMayVaryText } = useOrderMgmtLang();

  return (
    <Box
      className={componentClasses.totalManagement}
      gridArea='total'
      padding='1rem 1rem 0'
      margin='1rem 0'
      display='flex'
      flexDirection='column'
      alignItems='flex-end'
    >
      <CustomKeyValueBox
        classes={{ root: componentClasses.totalLabel }}
        label={totalLabel}
        labelFontWeight={FontWeight.Bold}
        value={totalValue}
        valueVariant={Variants.Subheadline}
        valueFontWeight={FontWeight.Bold}
      />
      <ChipOrderDisclaimer
        type='info'
        disclaimer={finalPriceMayVaryText}
        hasIcon
      />
    </Box>
  );
};

interface Props {
  totalLabel: string;
  totalValue: string;
}
