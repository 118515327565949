import React from 'react';
import useListStyles from '../list.styles';
import { Box, MenuItem, Select, Typography } from '@material-ui/core';
import { CustomSearchTextField } from '../../../../../components/common/custom-search-text-field';
import closeIcon from '../../../../../assets/images/icons/close_icon.svg';
import { CargoStore } from '../../../../../business/cargo';
import { useKanbanLang } from '../hooks/useKanbanLang';

export function ListHeader({
  stores,
  orderId,
  storeId,
  setFilterOrders,
}: Props) {
  const {
    orderListFilterText,
    orderListIdPlaceholderText,
    orderListSearchStorePlaceholderText,
  } = useKanbanLang();
  const styles = useListStyles();

  function handleClear() {
    setFilterOrders({ orderId: 0 });
    setFilterOrders({ storeId: 0 });
  }

  return (
    <Box
      display='flex'
      justifyContent='flex-start'
      alignItems='center'
      gridGap='0.5rem'
      style={{ flexFlow: 'wrap' }}
    >
      <Typography className={styles.filterText}>
        {orderListFilterText}
      </Typography>
      <CustomSearchTextField
        classes={{
          root: styles.searchInputRoot,
          input: styles.searchInput,
          adornedStart: styles.searchAdorned,
        }}
        placeholder={orderListIdPlaceholderText}
        onChange={e => setFilterOrders({ orderId: +e.target.value })}
        value={orderId && Number.isInteger(orderId) ? orderId : ''}
        fullWidth={false}
        isDisabledCancelButton
      />
      <Select
        classes={{ root: styles.selectRoot }}
        onChange={e => setFilterOrders({ storeId: e.target.value })}
        value={storeId}
        disableUnderline
        displayEmpty
        renderValue={value =>
          !!value
            ? stores.find(({ id }) => id === value)?.name
            : orderListSearchStorePlaceholderText
        }
      >
        {stores.map(store => (
          <MenuItem key={`s-${store.id}`} value={store.id}>
            {store.name}
          </MenuItem>
        ))}
      </Select>
      <img
        className={styles.clearIcon}
        alt='reset-cargo-filters'
        src={closeIcon}
        onClick={handleClear}
      />
    </Box>
  );
}

interface Props {
  stores: CargoStore[];
  orderId: number;
  storeId: number;
  setFilterOrders: any;
}
