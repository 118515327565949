import { useFormatMessage } from '../../../intl/intl';

export function useLang() {
  const cancelBtn = useFormatMessage({ id: 'cancel' });
  const title = useFormatMessage({ id: 'logOutReasonsDialog.title' });
  const confirmBtn = useFormatMessage({ id: 'logOutReasonsDialog.confirm' });
  const placeHolder = useFormatMessage({
    id: 'logOutReasonsDialog.placeHolder',
  });

  return {
    title,
    cancelBtn,
    confirmBtn,
    placeHolder,
  };
}
