import { clsx } from 'clsx';

import Hooks from './hooks';

import { CustomBaseAlertSnackbar } from '../custom-base-alert-snackbar';

import useStyles from './styles';

export function CustomWarningAlertSnackbar(props) {
  const {
    open = false,
    message = '',
    buttons = [],
    classes = {},
    ...extraProps
  } = props;

  const _classes = useStyles();

  const localButtons = Hooks.useActions(buttons);

  return (
    <CustomBaseAlertSnackbar
      open={open}
      message={message}
      buttons={localButtons}
      classes={{
        cancelButton: clsx(_classes.cancelButton, classes?.cancelButton),
        messageContainer: clsx(
          _classes.messageContainer,
          classes?.messageContainer,
        ),
        root: clsx(_classes.root, classes?.root),
      }}
      {...extraProps}
    />
  );
}
