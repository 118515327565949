// React
import React, { Component } from 'react';
// Material UI
import { Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// Consts
import { neutral } from '../../../shared/styles/colors';

const styles = {
  root: {
    color: neutral.D15,
  },
  checked: {},
};

class CustomCheckbox extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Checkbox
        checked={this.props.checked}
        color={'primary'}
        onChange={this.props.onChange}
        value={this.props.value}
        disabled={this.props.disabled}
        classes={{
          root: classes.root,
          checked: classes.checked,
        }}
      />
    );
  }
}

export default withStyles(styles)(CustomCheckbox);
