import { clsx } from 'clsx';
import { useState, useEffect } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { FormGroup, RadioGroup } from '@material-ui/core';

import { CustomRadio } from '../custom-radio';
import CustomTextField from './../custom-text-field/CustomTextField';

import useStyles from './CustomRadioGroup.styles';

export function CustomRadioGroup(props) {
  const {
    filterTitle,
    listTitle,
    onChange,
    searchFieldPlaceHolder,
    items = [],
    classes = {},
    selectedItem = '',
  } = props;

  const _classes = useStyles();

  const [filteredItems, setFilteredItems] = useState(
    items.map(item => item.id.toString()),
  );

  useEffect(() => {
    setFilteredItems(items.map(item => item.id.toString()));
    return () => {
      setFilteredItems(items.map(item => item.id.toString()));
    };
  }, [items]);

  const onChangeItemFilter = event => {
    const text = event.target.value.trim();
    if (text === '') {
      setFilteredItems(items.map(item => item.id.toString()));
      return;
    }
    const filteredItemsResult = items
      .filter(item => {
        return (
          item.name && item.name.toLowerCase().search(text.toLowerCase()) !== -1
        );
      })
      .map(item => item.id.toString());
    setFilteredItems(filteredItemsResult);
  };

  return (
    <div className={clsx(classes?.root)}>
      {!!filterTitle && (
        <h4 className={clsx(_classes.filterTitle, classes?.filterTitle)}>
          {filterTitle}
        </h4>
      )}
      <CustomTextField
        id='stores_filter'
        placeholder={searchFieldPlaceHolder}
        onChange={onChangeItemFilter}
        fullWidth={true}
        endAdornment={<SearchIcon className={_classes.searchIcon} />}
      />
      {!!listTitle && (
        <h5 className={clsx(_classes.listTitle, classes?.listTitle)}>
          {listTitle}
        </h5>
      )}
      <FormGroup>
        <RadioGroup
          value={selectedItem}
          onChange={e => onChange(e.currentTarget.value.toString())}
        >
          {items.map((item, index) =>
            filteredItems.indexOf(item.id.toString()) > -1 ? (
              <CustomRadio
                key={`cRadio-${index}`}
                value={item.id.toString()}
                label={item.name}
                color='primary'
              />
            ) : null,
          )}
        </RadioGroup>
      </FormGroup>
    </div>
  );
}
