// Chats
import urls from '../constants/Urls';
import LocalStorageService from './local-storage-service';
import UrlUtils from '../utils/url-utils/UrlUtils';
import http from './HttpService';

const getChannel = id => {
  const microserviceUrl = LocalStorageService.getBaseUrl();

  const replacedUrl = UrlUtils.replaceUrlParams(urls.GET_STOREKEEPER_CHANNEL, {
    id,
  });

  return http.get(`${microserviceUrl}${replacedUrl}`);
};
export default { getChannel };
