import { AddCircle } from '@material-ui/icons';
import { Button, CircularProgress } from '@material-ui/core';

import { useMainButton } from './hooks';
import makeClasses from './MainButton.style';

import type { ButtonProps } from '@material-ui/core/Button';

export function MainButton(props: MainButtonProps) {
  const { appearance, disabled, isLoading, ...extraProps } = props;

  const { lang } = useMainButton({ appearance });

  const _classes = makeClasses({ appearance });

  return (
    <Button
      variant='contained'
      disabled={isLoading || disabled}
      endIcon={<EndIcon isLoading={isLoading} appearance={appearance} />}
      classes={{
        root: _classes.AddRTBtn,
        label: _classes.AddRTBtnSpanContent,
        endIcon: _classes.AddRTBtnEndIcon,
      }}
      {...extraProps}
    >
      {lang.addRTBtn}
    </Button>
  );
}

function EndIcon(props: Pick<MainButtonProps, 'isLoading' | 'appearance'>) {
  const { isLoading, appearance } = props;
  if (isLoading) return <CircularProgress color='primary' size='1rem' />;
  if (appearance === 'primary') {
    return <AddCircle style={{ fontSize: '1rem' }} />;
  }
  return null;
}

export type ButtonAppearance = 'primary' | 'secondary';

interface MainButtonProps extends Omit<ButtonProps, 'children'> {
  isLoading: boolean;
  appearance: ButtonAppearance;
}
