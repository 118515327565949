import { useSelector } from 'react-redux';

import { selectors } from '../../../redux/order-rejection-by-saturated-store-dialog';

const useOpen = () => {
  return useSelector(selectors.selectOpen);
};

const hooks = { useOpen };

export default hooks;
