// External libs
import { handleActions } from 'redux-actions';
import SalesSummaryActions from './../actions/salesSummary.actions';

const initialState = {
  filter: {
    selectedStoreId: '0',
    selectedDate: new Date(),
  },
  isSalesSummaryLoading: false,
  pendingToPrint: false,
  salesSummary: [],
};

export default handleActions(
  {
    [SalesSummaryActions.applyFilterAction]: (
      state,
      { payload: { selectedStoreId, selectedDate } },
    ) => applyFilter(selectedStoreId, selectedDate, state),
    [SalesSummaryActions.clearFilterAction]: state =>
      applyFilter('0', new Date(), state),
    [SalesSummaryActions.closeFilterDrawerAction]: state =>
      toggleFilterDrawer(false, state),
    [SalesSummaryActions.confirmSalesSummaryToPrintAction]: state =>
      setPendingToPrint(false, state),
    [SalesSummaryActions.getSalesSummaryAction]: (
      state,
      { payload: { summary } },
    ) => getSalesSummary(state, summary),
    [SalesSummaryActions.openFilterDrawerAction]: state =>
      toggleFilterDrawer(true, state),
    [SalesSummaryActions.sendSalesSummaryToPrintAction]: state =>
      setPendingToPrint(true, state),
    [SalesSummaryActions.setSalesSummaryIsNotLoadingAction]: state =>
      setSalesSummaryLoading(state, false),
    [SalesSummaryActions.setSalesSummaryIsLoadingAction]: state =>
      setSalesSummaryLoading(state, true),
  },
  initialState,
);

const applyFilter = (selectedStoreId, selectedDate, state) => {
  return {
    ...state,
    filter: { ...state.filter, selectedStoreId, selectedDate },
  };
};

const getSalesSummary = (state, salesSummary) => {
  return {
    ...state,
    salesSummary: salesSummary,
  };
};

const setSalesSummaryLoading = (state, isSalesSummaryLoading) => {
  return { ...state, isSalesSummaryLoading };
};

const setPendingToPrint = (pendingToPrint, state) => {
  return { ...state, pendingToPrint };
};

const toggleFilterDrawer = (open, state) => {
  return { ...state, filter: { ...state.filter, open } };
};
