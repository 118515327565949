import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import { MORE_INFO_FULL_URL } from '../constants';
import { useFetchDesuspend } from './useFetchDesuspend';
import { cache, eventsRemoteAnalytics } from '../utils';
import { dialogStoreSuspended } from '../../../shared/services';
import { useStoreSuspensionAlertTexts } from './useStoreSuspensionAlertTexts';
import { useStoreSuspensionAlertState } from './useStoreSuspensionAlertState';
import ChromeExtensionService from '../../../services/ChromeExtensionService';

import type { StoreStatusDetail } from '../../../services/partners-service';

export function useStoreSuspensionAlert() {
  const [open, setOpen] = useState<boolean>(false);
  const [storeId, setStoreId] = useState<number>(NaN);
  const [isFirstOpening, setIsFirstOpening] = useState<boolean>(false);
  const [isCTADesuspended, setIsCTADesuspended] = useState<boolean>(false);
  const [storeDetail, setStoreDetail] = useState<StoreStatusDetailNull>(null);

  const navigate = useNavigate();

  const {
    ssn,
    storesDetails,
    isLoadingStatusDetail,
    getStateNotify,
    getStoresDetails,
    openSnackBarSuccess,
  } = useStoreSuspensionAlertState();
  const desuspend = useFetchDesuspend();
  const texts = useStoreSuspensionAlertTexts({
    isCTADesuspended,
    isFirstOpening,
  });

  const handleOnOpen = useCallback(() => {
    getStoresDetails();
    setOpen(true);
  }, []);

  const handleOnClose = useCallback(
    (params?: { onlyClose: boolean }) => {
      const { onlyClose = true } = params ?? {};
      if (onlyClose) eventsRemoteAnalytics.close({ storeId });
      ChromeExtensionService.pauseAudio().then();
      cache.updateByStoreId({ storeId });
      setIsFirstOpening(false);
      desuspend.clearState();
      setStoreId(NaN);
      setOpen(false);
    },
    [storeId],
  );

  const handleBtnSecondary = () => {
    eventsRemoteAnalytics.modeInfo({ storeId });
    handleOnClose({ onlyClose: false });
    navigate(MORE_INFO_FULL_URL);
  };

  const handleBtnPrimary = () => {
    eventsRemoteAnalytics.activeStore({ storeId });
    desuspend.liftingSuspension({ storeId });
  };

  const onSuccessDesuspend = () => {
    handleOnClose({ onlyClose: false });
    getStateNotify();
    getStoresDetails();
    openSnackBarSuccess({
      msg: texts.successMsg,
      title: texts.successTitle,
    });
  };

  useEffect(() => {
    cache.initialize();
    dialogStoreSuspended.open.add(({ detail }) => {
      setStoreId(detail?.storeId ?? NaN);
      handleOnOpen();
    });
    return () => {
      ChromeExtensionService.pauseAudio().then();
      dialogStoreSuspended.open.remove();
    };
  }, []);

  useEffect(() => {
    if (ssn.length > 0 && Number.isNaN(storeId)) {
      const ssnNextCache = cache.updateAll(ssn);
      const idFound = ssnNextCache.find(s => !s.alerted)?.storeId ?? NaN;
      setStoreId(idFound);
      if (!Number.isNaN(idFound)) {
        handleOnOpen();
        setIsFirstOpening(true);
        ChromeExtensionService.playNotifySuspendedStore().then();
      }
    }
  }, [ssn]);

  useEffect(() => {
    if (!Number.isNaN(storeId) && Boolean(storesDetails)) {
      const sd = storesDetails.find(s => s.store_id === storeId) ?? null;
      const isCtaDs = sd?.cta_type === 'cta.desuspended';
      setStoreDetail(sd);
      setIsCTADesuspended(isCtaDs);
    } else if (Number.isNaN(storeId)) {
      setStoreDetail(null);
    }
  }, [storeId, storesDetails]);

  useEffect(() => {
    if (desuspend.isSuccess) onSuccessDesuspend();
  }, [desuspend.isSuccess]);

  return {
    open,
    handleOnClose,
    handleBtnPrimary,
    handleBtnSecondary,
    texts,
    desuspend,
    storeDetail,
    isCTADesuspended,
    isLoadingStatusDetail,
  };
}

type StoreStatusDetailNull = StoreStatusDetail | null;
