import { makeStyles } from '@material-ui/core';

import { green, neutral } from '../../../../shared/styles/colors';

export default makeStyles(theme => ({
  footer: {
    [theme.breakpoints.up('md')]: {
      borderTop: `1px solid ${neutral['20']}`,
    },
  },
  containerFooter: {
    display: 'grid',
    gridTemplateAreas: `"bullets bullets"
                        "containerButtons containerButtons"`,
    [theme.breakpoints.up('md')]: {
      padding: '0 1.5rem 1rem',
      gridTemplateAreas: `"bullets containerButtons"`,
    },
  },
  bullets: {
    gridArea: 'bullets',
    justifySelf: 'center',
    alignSelf: 'center',
    [theme.breakpoints.up('md')]: {
      justifySelf: 'initial',
    },
  },
  containerButtons: {
    gridArea: 'containerButtons',
    display: 'grid',
    gridRowGap: 6,
    gridTemplateAreas: `"submitButton"
                        "backButton"`,
    justifyItems: 'center',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      justifyItems: 'end',
      gridTemplateAreas: `"backButton submitButton"`,
      justifySelf: 'right',
      gridColumnGap: 14,
    },
  },
  rootBackButton: {},
  backButton: {
    gridArea: 'backButton',
    padding: 0,
    color: neutral['50'],
  },
  rootSubmitButton: {
    gridRow: 1,
    [theme.breakpoints.up('md')]: {
      gridRow: 'auto',
    },
  },
  submitButton: {
    width: 320,
    height: 45,
    gridArea: 'submitButton',
    padding: 0,
    backgroundColor: green['40'],
    borderRadius: 40,
    color: neutral.white,
    marginTop: 7,
    '&:hover': {
      color: neutral.white,
      backgroundColor: green['50'],
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      width: 132,
      height: 32,
    },
  },
}));
