import { createAsyncThunk } from '@reduxjs/toolkit';

import { requestAdapter, responseAdapter } from '../../adapters';
import { addRTRepository } from '../../repositories';
import { ADD_RT_SLICE_NAME } from './addRT.const';

import type { OnCreateAddRTParams } from '../../../domain';
import type { ValidateAddRTRequest } from '../../core/requests';

const validate = createAsyncThunk(
  `${ADD_RT_SLICE_NAME}/validate`,
  async function ({ orderId }: ValidateAddRTRequest, { rejectWithValue }) {
    try {
      const resp = await addRTRepository.validate({ orderId });
      return responseAdapter.toValidateAddRT(resp.data);
    } catch (ex) {
      return rejectWithValue(ex);
    }
  },
);

const create = createAsyncThunk(`${ADD_RT_SLICE_NAME}/create`, async function (
  { payload }: OnCreateAddRTParams,
  { rejectWithValue },
) {
  try {
    const resp = await addRTRepository.create({
      payload: requestAdapter.toCreateAddRT(payload),
    });
    const respData = responseAdapter.toCreateOrder(resp.data);
    if (!respData.isSuccessful) {
      return rejectWithValue(respData);
    }
    return respData;
  } catch (ex) {
    return rejectWithValue(ex);
  }
});

export const addRTFetching = {
  validate,
  create,
} as const;
