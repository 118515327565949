import { useFormatMessage } from '../../../intl/intl';

export function useOrdersHistoricalLang({ isMdUp = false }) {
  const titleId = isMdUp
    ? 'orders.historical.title'
    : 'orders.historical.title.responsive';
  return {
    title: useFormatMessage({ id: titleId }),
    info: useFormatMessage({ id: 'ordersHistorical.infoLabel' }),
    emptyState: useFormatMessage({ id: 'ordersHistorical.emptyStateText' }),
  };
}
