import { useState } from 'react';
import { useDispatch } from 'react-redux';

import dialogsManagerActions from '../../../../../../redux/actions/dialogsManager.actions';
import EventActions from '../../../../../../redux/actions/event-actions/event.actions';
import partnerActions from '../../../../../../redux/actions/partners-actions/partners.actions';
import storesSchedulesActions from '../../../../../../redux/actions/storesSchedules.actions';
import { useMaxCounts } from '../../hooks/useMaxCounts';
import { useChangeDemandStateTexts } from './useChangeDemandStateTexts';

import type { Saturation } from '../../../../../../business/highDemand';
import type { TTLOption } from '../../../../shared/models'; //TODO: move domain

const DEMAND_TTL_OPTIONS: TTLOption[] = [20, 40, 60, 'day'];
const DEMAND_STATUS_OPTIONS: Saturation[] = ['fast', 'low', 'regular', 'high'];

export function useChangeDemandState({ store, onSelect, partnerId, onClose }) {
  const [isFastOperationChecked, setIsFastOperationChecked] = useState(false);
  const dispatch = useDispatch();
  const isEligibleForFastOperation = store?.isEligibleForFastOperation;
  const storeId = store.store_id;
  const currentDemand = store.saturated;
  const demandStatusOptions = DEMAND_STATUS_OPTIONS.filter(
    d =>
      (currentDemand === 'fast'
        ? d !== 'low'
        : isEligibleForFastOperation
        ? d !== 'low' && d !== currentDemand
        : d !== currentDemand) &&
      !(currentDemand === 'superHigh' && d === 'high'),
  );
  const [showTTLs, setShowTTLs] = useState(false);
  const [ttlSelected, setTTLSelected] = useState<TTLOption>(0);
  const [demandSelected, setDemandSelected] = useState<Saturation>(
    'deactivated',
  );

  const counts = useMaxCounts();
  const maxUses = counts.getFlagsForMaxCountsByStore({ storeId });
  const hasMaxUse = maxUses.hasMaxPerWeek || maxUses.hasMaxPerDay;

  const texts = useChangeDemandStateTexts({
    hasMaxUsesPerDay: maxUses.hasMaxPerDay,
    hasMaxUsesPerWeek: maxUses.hasMaxPerWeek,
  });

  const onSelectDemand = (demand: Saturation) => {
    setShowTTLs(
      demand === 'high' ||
        demand === 'low' ||
        demand === 'superHigh' ||
        (demand === 'regular' && isEligibleForFastOperation),
    );
    setDemandSelected(demand);
    setTTLSelected(0);
    onSelect({ ttl: 0, severity: demand });
  };

  const onSelectTTL = (ttl: TTLOption) => {
    setTTLSelected(ttl);
    onSelect({
      severity: demandSelected,
      ttl: ttl === 'day' ? remainingMinutesForTheNextDay() : ttl,
    });
  };

  const onOpenExtendClosingHoursDialog = async () => {
    dispatch(dialogsManagerActions.openExtendClosingHoursDialog(storeId));
    await dispatch(storesSchedulesActions.applyStoresSchedulesFilter(storeId));
    EventActions.onExtendedSchedules({ storeId, partnerId });
    onClose();
  };

  const onDeactivateStore = async () => {
    const payload = { storeIds: [storeId], enabled: false };
    const deactivatedAction = partnerActions.switchPartnerStoresStatus(payload);
    await dispatch(deactivatedAction);
    onClose();
  };

  return {
    texts,
    hasMaxUse,
    showTTLs,
    ttlSelected,
    demandSelected,
    demandStatusOptions,
    ttlOptions: DEMAND_TTL_OPTIONS,
    onSelectDemand,
    onSelectTTL,
    onOpenExtendClosingHoursDialog,
    onDeactivateStore,
    isFastOperationChecked,
    setIsFastOperationChecked,
  };
}

function remainingMinutesForTheNextDay() {
  const [MS_IN_SEG, SEG_IN_MINUTE] = [1_000, 60];
  const now = new Date();
  const midnight = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1,
  ).getTime();
  const remainingTime: number = midnight - now.getTime();
  return Math.floor(remainingTime / (MS_IN_SEG * SEG_IN_MINUTE));
}
