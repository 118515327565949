// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  body: {
    height: '100%',
    paddingTop: 24,
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
    },
  },
  headerRoot: {
    [theme.breakpoints.up('md')]: {
      padding: 12,
    },
  },
  customNumberSelectorBlockRoot: {
    borderTop: `1px solid ${neutral.D10}`,
    [theme.breakpoints.up('md')]: {
      padding: '40px 40px 0px 40px',
      borderTop: `none`,
    },
  },
  customNumberSelectorBlockTitle: {
    margin: 0,
    padding: '0px 16px',
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      height: 530,
      width: 986,
    },
  },
  root: {},
  description: { textAlign: 'center', padding: '24px 24px 24px 24px' },
  title: {
    fontWeight: 'bold',
    fontSize: 17,
    paddingBottom: 12,
  },
  content: {
    fontSize: 12,
  },
}));
