import { connect } from 'react-redux';

import RtInfoCardSupportChat from './RtInfoCardSupportChat';

const mapStateToProps = ({
  orderDetailDialogReducer: { orderId },
  ordersReducer: { orders },
}) => {
  const orderDetail = orders.find(order => order.id === +orderId)?.detail ?? {};
  const storeKeeperId = orderDetail?.storekeeper?.id ?? 0;
  const storeKeeperName = orderDetail?.storekeeper?.first_name ?? '';
  const storeKeeperPhone = orderDetail?.storekeeper?.phone ?? 0;
  const storeKeeperLastName = orderDetail?.storekeeper?.last_name ?? '';
  const storeKeeperPhoto = orderDetail?.storekeeper?.profile_pic ?? '';
  return {
    orderDetail,
    storeKeeperId,
    storeKeeperName,
    storeKeeperPhone,
    storeKeeperLastName,
    storeKeeperPhoto,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RtInfoCardSupportChat);
