const GenericProps = {
  itemType: 'ITEM_TYPE',
  actionType: 'ACTION_TYPE',
};
const BaseProps = {
  storeId: 'STORE_ID',
  orderId: 'ORDER_ID',
};
const openOrderStatus = {
  name: 'OPEN_ORDER_STATUS',
  props: { ...BaseProps, isEnabledToEdit: 'IS_ENABLED_TO_EDIT' },
};

const openChat = {
  name: 'OPEN_CHAT_OM',
  props: { ...BaseProps, isOM: 'IS_OM' },
};
const editOrder = {
  name: 'CLICK_EDIT_ORDER',
  props: {
    ...BaseProps,
    isOpenBy: 'IS_OPEN_BY',
    editType: 'EDIT_TYPE',
    optionType: 'OPTION_TYPE',
  },
};
const continueSummary = {
  name: 'CONTINUE_WITH_SUMMARY',
  props: BaseProps,
};
const acceptOrder = {
  name: 'CLICK_ACCEPT_ORDER',
  props: BaseProps,
};

const orderActions = {
  name: 'CLICK_OM_ACTIONS',
  props: { ...BaseProps, ...GenericProps },
};
const addSearchItem = {
  name: 'ADD_ITEM_BY_SEARCH',
  props: { ...BaseProps, ...GenericProps },
};
const removeChanges = {
  name: 'REMOVE_CHANGES',
  props: { ...BaseProps, actionType: GenericProps.actionType },
};
const sendSubstitutionRequest = {
  name: 'SEND_SUBSTITUTION_REQUEST',
  props: {
    ...BaseProps,
    itemType: GenericProps.itemType,
    optionType: 'OPTION_TYPE',
  },
};
const inactivityActions = {
  name: 'CLICK_INACTIVITY_ACTION',
  props: { ...BaseProps, action: GenericProps.actionType },
};
const selectOutOfStockItem = {
  name: 'SELECT_OUT_OF_STOCK_ITEM',
  props: { ...BaseProps, isOpenBy: 'IS_OPEN_BY' },
};
const clickAcceptOrderByReplaceWithoutEditing = {
  name: 'CLICK_ACCEPT_ORDER_REPLACE_WITHOUT_EDITING',
  props: BaseProps,
};

export const Events = {
  openOrderStatus,
  openChat,
  editOrder,
  orderActions,
  continueSummary,
  acceptOrder,
  addSearchItem,
  removeChanges,
  sendSubstitutionRequest,
  inactivityActions,
  selectOutOfStockItem,
  clickAcceptOrderByReplaceWithoutEditing,
};
