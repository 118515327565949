import { useEffect } from 'react';

import { formatCurrency } from '../../utils/CurrencyUtils';

import MainLayout from '../main-layout';
import CustomLoader from '../common/custom-loader';
import { CustomPrintButton } from '../common/buttons/custom-print-button';
import SalesSummaryAdvancedFilterToolbar from './../sales-summary-advanced-filter-toolbar';

import useStyles from './SalesSummary.styles';

function SalesSummary(props) {
  const {
    canceledTitle,
    cancelOrders,
    currentFilter,
    finishedTitle,
    finishOrders,
    getSalesSummary,
    isLoading,
    onPrint,
    ordersTitle,
    selectedStore,
    showCancelOrderDiscount,
    showFinishOrderDiscount,
    showTotalCancelOrderWithDiscount,
    showTotalFinishOrderWithDiscount,
    title,
    totalAccumulated,
    totalDiscount,
    totalWithDiscount,
  } = props;

  const isSelectStore = !!selectedStore;

  const classes = useStyles();

  useEffect(() => {
    if (
      !currentFilter ||
      !currentFilter.selectedStoreId ||
      currentFilter.selectedStoreId === '0' ||
      !currentFilter.selectedDate
    ) {
      return;
    }
    getSalesSummary(currentFilter.selectedStoreId, currentFilter.selectedDate);
  }, []);

  return (
    <MainLayout title={title}>
      <div className={classes.root}>
        <SalesSummaryAdvancedFilterToolbar />
        <div className={classes.salesSummaryContainer}>
          <div className={classes.title}>
            <div>{title}</div>
            {isSelectStore && (
              <CustomPrintButton
                classes={{ button: classes.button }}
                onClick={onPrint}
              />
            )}
          </div>
          {isSelectStore && (
            <div className={classes.salesSummaryBodyContainer}>
              <CustomLoader open={isLoading} />
              <div className={classes.body}>
                <div className={classes.finishOrderBox}>
                  <div className={classes.text}>{finishedTitle}</div>
                  <div className={classes.count}>
                    {ordersTitle}:&nbsp;
                    {finishOrders?.number_of_finished_orders}
                  </div>
                  <div className={classes.count}>
                    {totalAccumulated}:&nbsp;
                    {formatCurrency(finishOrders?.total_accumulated)}
                  </div>
                  {showFinishOrderDiscount && (
                    <div className={classes.count}>
                      {totalDiscount}:&nbsp;
                      {formatCurrency(finishOrders?.discounts)}
                    </div>
                  )}

                  {showTotalFinishOrderWithDiscount && (
                    <div className={classes.totalCount}>
                      {totalWithDiscount}:&nbsp;
                      <span className={classes.totalCountFinishedOrder}>
                        {formatCurrency(finishOrders?.total_with_discounts)}
                      </span>
                    </div>
                  )}
                </div>
                <div className={classes.cancelOrderBox}>
                  <div className={classes.text}>{canceledTitle}</div>
                  <div className={classes.count}>
                    {ordersTitle}:&nbsp;
                    {cancelOrders?.number_of_finished_orders}
                  </div>
                  <div className={classes.count}>
                    {totalAccumulated}:&nbsp;
                    {formatCurrency(cancelOrders?.total_accumulated)}
                  </div>
                  {showCancelOrderDiscount && (
                    <div className={classes.count}>
                      {totalDiscount}:&nbsp;
                      {formatCurrency(cancelOrders?.discounts)}
                    </div>
                  )}
                  {showTotalCancelOrderWithDiscount && (
                    <div className={classes.totalCount}>
                      {totalWithDiscount}:&nbsp;
                      <span className={classes.totalCountCancelOrder}>
                        {formatCurrency(cancelOrders?.total_with_discounts)}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
}

export default SalesSummary;
