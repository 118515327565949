const marketplaceLabelText = { id: 'marketplaceLabel.textLabel' }
const marketplaceDomicilaryOnDeliver = {
  id: 'marketplaceLabel.domiciliaryInDeliver',
}
const textIds = {
  marketplaceLabelText,
  marketplaceDomicilaryOnDeliver,
}

export default textIds
