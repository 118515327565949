import { useSelector } from 'react-redux';

import { selectors as suggestProductDialog } from '../../redux/suggest-product-dialog';

const useOpen = () => useSelector(suggestProductDialog.selectOpen);

const useSelectedProduct = () =>
  useSelector(suggestProductDialog.selectedProduct);

const useProductId = () => useSelector(suggestProductDialog.selectProductId);

const useProductName = () =>
  useSelector(suggestProductDialog.selectProductName);

const useRefuseReason = () =>
  useSelector(suggestProductDialog.selectRefuseReason);

const useRefuseReasonsDescription = () =>
  useSelector(suggestProductDialog.selectRefuseReasonsDescription);

const hooks = {
  useOpen,
  useSelectedProduct,
  useProductId,
  useProductName,
  useRefuseReason,
  useRefuseReasonsDescription,
};

export default hooks;
