import { clsx } from 'clsx';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import hooks from './hooks/hooks';
import { useFormatMessage } from '../../intl/intl';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';

import CustomClickableBoxList from '../common/boxes/custom-clickable-box-list';
import CustomDialog from '../common/custom-dialog';

import { textIds } from './consts';
import useStyles from './styles';

export function JustCookedOrderIssueDialog() {
  const _classes = useStyles();

  const confirmButtonText = useFormatMessage(textIds.confirmButtonText);

  const description = hooks.useDescription();
  const open = hooks.useOpen();
  const options = hooks.useOptions();
  const title = hooks.useTitle();

  const hasOptions = options.length > 0;

  const dispatch = useDispatch();

  const handleBack = useCallback(() => {
    dispatch(dialogsManagerActions.back());
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  const handleConfirm = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  return (
    <CustomDialog
      classes={{
        childrenContainer: clsx(_classes.childrenContainer, {
          [_classes.childrenContainerWithoutOptions]: !hasOptions,
        }),
        headerRoot: _classes.headerRoot,
        paper: clsx(
          _classes.paper,
          !hasOptions && _classes.paperWithoutOptions,
        ),
      }}
      actions={
        hasOptions
          ? []
          : [
              {
                text: confirmButtonText,
                onClick: handleConfirm,
                color: 'primary',
                variant: 'contained',
                fullWidth: false,
              },
            ]
      }
      onClickBackButton={handleBack}
      onClose={handleClose}
      open={open}
      showBackButton
      showCloseButton
      title={title}
    >
      <CustomClickableBoxList label={description} options={options} />
    </CustomDialog>
  );
}
