import { useSelector } from 'react-redux';

import { selectors as dynInAppDialogSelectors } from '../../redux/dyn-in-app-dialog';

const useOpen = () => {
  return useSelector(dynInAppDialogSelectors.selectOpen);
};

const useTitle = () => {
  return useSelector(dynInAppDialogSelectors.selectTitle);
};

const useDescription = () => {
  return useSelector(dynInAppDialogSelectors.selectDescription);
};

const useMainButton = () => {
  return useSelector(dynInAppDialogSelectors.selectMainButton);
};

const useCloseButton = () => {
  return useSelector(dynInAppDialogSelectors.selectCloseButton);
};

const useColor = () => {
  return useSelector(dynInAppDialogSelectors.selectColor);
};

const useShouldSound = () => {
  return useSelector(dynInAppDialogSelectors.selectShouldSound);
};

const hooks = {
  useOpen,
  useTitle,
  useDescription,
  useMainButton,
  useCloseButton,
  useColor,
  useShouldSound,
};

export default hooks;
