import { makeStyles } from '@material-ui/core';
// Constants
import Colors from '../../constants/Colors';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  productImageSmall: {
    maxHeight: 40,
    maxWidth: 40,
  },
  productImageSmallContainer: {
    alignItems: 'center',
    backgroundColor: neutral.D5,
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    marginRight: 16,
    minWidth: 40,
  },
  productsListRoot: {
    minHeight: '100%',
    minWidth: 300,
    overflow: 'auto',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      borderRight: `1px solid ${neutral.D10}`,
      maxWidth: 320,
    },
  },
  productsListItemRoot: {
    borderBottom: `1px solid ${neutral.D10}`,
    cursor: 'pointer',
    padding: '0px 4px 0px 16px',
    position: 'relative',
  },
  productSwitch: {
    marginTop: -1,
    right: 12,
  },
  selected: {
    backgroundColor: neutral.D5,
    borderLeft: `2px solid ${Colors.PRIMARY_COLOR}`,
  },
}));
