// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  body: {
    flex: 'none',
    color: neutral.D20,
    fontSize: 16,
    height: '100%',
    lineHeight: 'normal',
    padding: '40px 16px',
  },
  headerRoot: {
    [theme.breakpoints.up('md')]: {
      padding: 12,
    },
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      width: 600,
    },
  },
  root: {},
}));
