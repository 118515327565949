import { makeStyles } from '@material-ui/core';

import { colorTheme } from '../../shared/styles/colors';

export default makeStyles({
  root: {
    height: 'auto',
    width: 'fit-content',
    padding: '0.125rem 0.5rem',
    gap: '0.25rem',
    color: colorTheme.orderType.bundle.primary.main,
    backgroundColor: colorTheme.orderType.bundle.primary.contrastText,
  },
  icon: {
    margin: 0,
  },
  label: {
    padding: 0,
    fontSize: '0.625rem',
    fontWeight: 600,
    lineHeight: '1.125rem',
  },
});
