// Material UI
import { makeStyles } from '@material-ui/core'
// Consts
import Colors from '../../../constants/Colors'

export default makeStyles(theme => ({
  alertIcon: {
    fontSize: '60px',
  },
  buttonDanger: {
    backgroundColor: Colors.SECONDARY_COLOR_DARK,
    '&:hover': {
      backgroundColor: Colors.TALL_POPPY,
    },
  },
  buttonRoot: {
    borderRadius: 0,
    color: Colors.WHITE,
    minHeight: '100px',
    fontFamily: 'Poppins',
    fontSize: '24px !important',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      minHeight: '130px',
    },
  },
  buttonSuccess: {
    backgroundColor: Colors.PRIMARY_COLOR_DARK,
  },
  closeButton: {
    color: Colors.WHITE,
  },
  description: {
    fontSize: '17px !important',
    fontFamily: 'Poppins',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    margin: '20px 0px 0px 0px',
    textAlign: 'center',
    whiteSpace: 'pre-line',
    [theme.breakpoints.up('md')]: {
      margin: '10px 0px 0px 0px',
    },
  },
  descriptionDanger: {
    color: Colors.TEMPTRESS,
  },
  descriptionSuccess: {
    color: Colors.SHERPA_BLUE,
  },
  dialogContentBody: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    margin: '24px 30px 0px 30px',
    placeContent: 'center',
    [theme.breakpoints.up('md')]: {
      margin: '4px 160px 0px 160px',
    },
  },
  dialogContentDescription: {},
  dialogContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  dialogContentDanger: {
    backgroundColor: Colors.SECONDARY_COLOR,
  },
  dialogContentHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '28px 4px 0px 0px',
    [theme.breakpoints.up('md')]: {
      margin: '8px 8px 0px 0px',
    },
  },
  dialogContentSuccess: {
    backgroundColor: Colors.PRIMARY_COLOR,
  },
  dialogPaper: {
    borderRadius: 8,
    height: '100%',
    margin: '0',
    maxHeight: '100%',
    maxWidth: '100%',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: '500px',
      width: '800px',
    },
  },
  dialogRoot: {
    zIndex: `${theme.zIndex.drawer + 1000} !important`,
    '@media print': {
      display: 'none !important',
    },
  },
  title: {
    color: Colors.WHITE,
    fontFamily: 'Poppins',
    fontSize: '50px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: 'normal',
    lineHeight: 1,
    margin: '20px 0px 0px 0px',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      lineHeight: '60px',
    },
  },
}))
