// Interfaces
import { Category, Section, Article } from './interfaces'
import {
  CategoriesResponse,
  SectionResponse,
  ArticleResponse,
} from '../../services/help-center-service'

const parseToArticle = (arcticle: ArticleResponse): Article => {
  return {
    name: arcticle.name || '',
    id: arcticle.id || '',
    title: arcticle.title || '',
  }
}
const parseToArticles = (
  arcticles: ArticleResponse[] | null | undefined,
): Article[] => arcticles?.map(parseToArticle) || []

const parseToSection = (section: SectionResponse): Section => {
  return {
    id: section.id || '',
    name: section.name || '',
    subType: section.subType || '',
    type: section.type || '',
    articles: parseToArticles(section.articles),
  }
}
const parseToSections = (
  sections: SectionResponse[] | null | undefined,
): Section[] => sections?.map(parseToSection) || []

const parseToCategory = (category: CategoriesResponse): Category => {
  return {
    id: category.id || '',
    name: category.name || '',
    subType: category.subType || '',
    type: category.type || '',
    sections: parseToSections(category.sections),
  }
}

const parseToCategoryArray = (
  categoryResponse: CategoriesResponse[],
): Category[] => {
  return categoryResponse.map(parseToCategory)
}
const utils = { parseToCategoryArray }

export default utils
