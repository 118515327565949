const defaultPredictiveCTMessage = {
  id:
    'orderDetailDialogCookingTimeSelectorSection.fullServiceSection.defaultPredictiveCTMessage',
}

const defaultStoreCTMessage = {
  id:
    'orderDetailDialogCookingTimeSelectorSection.fullServiceSection.defaultStoreCTMessage',
}
const highPickingTimeWarning = {
  id:
    'orderDetailDialogCookingTimeSelectorSection.fullServiceSection.highPickingTimeWarning',
}
const defaultHighPickingTimeWarning = {
  id:
    'orderDetailDialogCookingTimeSelectorSection.fullServiceSection.defaultHighPickingTimeWarning',
}

const textIds = {
  defaultPredictiveCTMessage,
  defaultStoreCTMessage,
  highPickingTimeWarning,
  defaultHighPickingTimeWarning,
}

export default textIds
