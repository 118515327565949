// React
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// Actions
import MenuActions from './../../redux/actions/menu.actions';
// Component
import MenuProductsList from './MenuProductsList';

const mapStateToProps = ({
  menuReducer: {
    areCorridorProductsLoading: isLoading,
    selectedCorridorProducts,
    selectedProductId,
  },
}) => {
  return {
    isLoading,
    products: selectedCorridorProducts,
    selectedProductId,
  };
};

const mapDispatchToProps = {
  onSelectProduct: MenuActions.selectProduct,
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MenuProductsList),
);
