// React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Actions
import AppActions from './../../redux/actions/app-actions'
import storesSchedulesActions from '../../redux/actions/storesSchedules.actions'
// Components
import StoresSchedulesFilterDrawer from './StoresSchedulesFilterDrawer'

const mapStateToProps = (
  {
    storesSchedulesReducer: { filter },
    partnerReducer: {
      profile: { stores },
    },
  },
  props,
) => {
  return {
    filter,
    stores,
  }
}
const mapDispatchToProps = {
  applyFilter: storesSchedulesActions.redirectStoresSchedulesFilter,
  onClearFilter: storesSchedulesActions.clearStoresSchedulesFilter,
  onClose: storesSchedulesActions.closeStoresSchedulesFilterDrawer,
  openSnackBarError: AppActions.openSnackBarError,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(StoresSchedulesFilterDrawer),
)
