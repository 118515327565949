import React from 'react';
// Constant
import Colors from '../../../constants/Colors';
import { neutral } from '../../../shared/styles/colors';

const NotificationTutorialImg = props => {
  return (
    <svg width={243} height={187} {...props}>
      <defs>
        <path id='prefix__a' d='M0 0h243v187H0z' />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='prefix__b' fill={neutral.white}>
          <use xlinkHref='#prefix__a' />
        </mask>
        <use fill={Colors.NAVAJO_WHITE} xlinkHref='#prefix__a' />
        <path
          fill={neutral.white}
          d='M21 37h201v150H21z'
          mask='url(#prefix__b)'
        />
        <g mask='url(#prefix__b)'>
          <path
            fill={neutral.D10}
            d='M31 68h165v6H31zm0 12h181v6H31zm0 12h79v6H31z'
          />
          <path fill={neutral.D15} d='M31 159h10v6H31zm0 16h10v6H31z' />
          <path fill={neutral.D10} d='M49 159h100v6H49zm0 16h100v6H49z' />
        </g>
        <text
          fill={Colors.JEWEL}
          fontFamily='Poppins'
          fontSize={9}
          mask='url(#prefix__b)'
        >
          <tspan x={31} y={55}>
            {'La conexi\xF3n es segura'}
          </tspan>
        </text>
        <text
          fill={neutral.D20}
          fontFamily='Poppins'
          fontSize={8}
          mask='url(#prefix__b)'
        >
          <tspan x={49} y={125}>
            {'Notificaciones'}
          </tspan>
        </text>
        <g mask='url(#prefix__b)'>
          <g transform='translate(110 113)'>
            <rect
              width={101}
              height={17}
              x={0.5}
              y={1}
              fill={Colors.WHITE}
              stroke={neutral.D10}
              rx={2}
            />
            <path fill={neutral.D20} d='M93.5 12L96 8h-5z' />
            <text fontFamily='Poppins' fontSize={8}>
              <tspan x={10} y={12} fill={neutral.D20}>
                {'Permitir'}
              </tspan>
            </text>
          </g>
        </g>
        <g mask='url(#prefix__b)'>
          <path
            fill={neutral.D20}
            fillRule='nonzero'
            d='M35 127c.55 0 1-.45 1-1h-2a1 1 0 001 1zm3-3v-2.5c0-1.535-.82-2.82-2.25-3.16V118a.749.749 0 10-1.5 0v.34c-1.435.34-2.25 1.62-2.25 3.16v2.5l-1 1v.5h8v-.5l-1-1z'
          />
          <path fill={neutral.D10} d='M21 142h201v1H21z' />
        </g>
        <g mask='url(#prefix__b)'>
          <g transform='translate(21 6)'>
            <rect width={30} height={25} fill={neutral.D15} rx={12.5} />
            <g
              stroke={neutral.white}
              strokeLinecap='round'
              strokeLinejoin='round'
            >
              <path d='M11.8 12.6V9.217A3.209 3.209 0 0115 6c1.767 0 3.2 1.44 3.2 3.217V12.6' />
              <path fill={neutral.white} d='M10 17.893h10v-6.2H10z' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default NotificationTutorialImg;
