// External libs
import { handleActions } from 'redux-actions';
// Actions
import RTDialogActions from '../actions/RTDialog.actions';

const initialState = {
  open: false,
  orderId: null,
};

export default handleActions(
  {
    [RTDialogActions.clearRTDialogAction]: state => clearDialog(state),
    [RTDialogActions.closeRTDialogAction]: state => closeDialog(state),
    [RTDialogActions.openRTDialogAction]: (state, { payload: { orderId } }) =>
      openDialog(state, orderId),
  },
  initialState,
);

const clearDialog = state => {
  return {
    ...initialState,
  };
};

const closeDialog = state => {
  return {
    ...state,
    open: false,
  };
};

const openDialog = (state, orderId = state.orderId) => {
  return {
    ...state,
    open: true,
    orderId,
  };
};
