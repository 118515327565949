import { clsx } from 'clsx';
import { CircularProgress, Typography } from '@material-ui/core';

import SearchRTIcon from '../../../assets/images/icons/searchRTIcon.webp';

import useStyles from './styles';

export function CustomStateLabel(props) {
  const { Icon, stateMessage = '', onClick = () => {}, classes = {} } = props;

  const _classes = useStyles();

  function DefaultIcon() {
    return (
      <div
        style={{ maxWidth: '2rem', maxHeight: '2rem', position: 'relative' }}
      >
        <img
          alt='status RT icon'
          src={SearchRTIcon}
          className={clsx(_classes.iconRoot, classes?.iconRoot)}
        />
        <CircularProgress
          size='2rem'
          style={{ position: 'absolute', left: 0, top: 0 }}
        />
      </div>
    );
  }

  return (
    <div className={clsx(_classes.root, classes?.root)} onClick={onClick}>
      {typeof Icon === 'string' && (
        <img
          alt='status RT icon'
          src={Icon}
          className={clsx(_classes.iconRoot, classes?.iconRoot)}
        />
      )}
      {typeof Icon !== 'string' &&
        (Boolean(Icon) ? (
          <Icon
            classes={{ root: clsx(_classes.iconRoot, classes?.iconRoot) }}
          />
        ) : (
          <DefaultIcon />
        ))}
      {typeof stateMessage !== 'string' && stateMessage}
      {typeof stateMessage === 'string' && (
        <Typography className={clsx(_classes.message, classes?.message)}>
          {stateMessage}
        </Typography>
      )}
    </div>
  );
}
