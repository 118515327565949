import { useFormatMessage } from '../../../intl/intl';

export function useMainKanbanCardLang(props: Props) {
  const {
    isInCooking,
    isMarketplace = false,
    showRTInfo = false,
    isRTNotifyAvailable = false,
    isPickup = false,
    hasMoreOrdersReady = false,
    numberOfOrdersNotReady = 0,
  } = props;

  const isOrderToDeliver = isMarketplace || isPickup || isRTNotifyAvailable;
  const hasMoreOrdersNotReady = numberOfOrdersNotReady !== 0;

  const waitingFor = useFormatMessage({
    id:
      numberOfOrdersNotReady > 1
        ? 'pendingToDeliverKanbanCard.toWaitFor.plural'
        : 'pendingToDeliverKanbanCard.toWaitFor.single',
  });

  const rtBtn = useFormatMessage({
    id: isRTNotifyAvailable
      ? 'ordersKanban.rtState.notify'
      : 'ordersKanban.rtState.locate',
  });

  const orderIsMarketplaceTextId = isInCooking
    ? 'cookingKanbanCardMarketplace.buttonText'
    : 'pendingToDeliverKanbanCard.marketplaceButtonText';
  const orderNotMarketplaceToDeliverTextId = hasMoreOrdersReady
    ? 'orderDetailDialogActionsBox.pendingToDeliverButtonText.multiple'
    : 'orderDetailDialogActionsBox.pendingToDeliverButtonText.single';
  const orderNotMarketplaceTextId = isInCooking
    ? 'cookingKanbanCard.buttonText'
    : orderNotMarketplaceToDeliverTextId;
  const orderCTABtn = useFormatMessage({
    id: isMarketplace ? orderIsMarketplaceTextId : orderNotMarketplaceTextId,
  });
  const showOrderCTABtn =
    isInCooking || (!hasMoreOrdersNotReady && isOrderToDeliver);

  const productConfirmationCode = useFormatMessage({
    id: 'orderDetailDialogActionsBox.productConfirmationCode',
  });

  const rtWaitingDetails = useFormatMessage({
    id: 'orderDetailDialogBagsDrinksBox.mandatoryInput.rtWaitingDetails'
  })

  const completeOrderDetailText = useFormatMessage({
    id: 'orderDetailDialogBagsDrinksBox.mandatoryInput.completeOrderDetails'
  })

  return {
    waitingFor: hasMoreOrdersNotReady ? waitingFor : '',
    rtBtn: !hasMoreOrdersNotReady && showRTInfo ? rtBtn : '',
    orderCTABtn: showOrderCTABtn ? orderCTABtn : '',
    productConfirmationCode,
    mandatoryInput: { rtWaitingDetails, completeOrderDetailText }
  };
}

interface Props {
  isInCooking: boolean;
  showRTInfo: boolean;
  isMarketplace: boolean;
  isRTNotifyAvailable: boolean;
  isPickup: boolean;
  hasMoreOrdersReady: boolean;
  numberOfOrdersNotReady: number;
}
