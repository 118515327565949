import { clsx } from 'clsx';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { CircularProgress, RadioGroup } from '@material-ui/core';

import { CustomRadio } from '../common/custom-radio';
import { CustomSelect } from '../common/custom-select';
import CustomSwipeableDialog from './../common/custom-swipeable-dialog';

import EventActions from '../../redux/actions/event-actions';
import { selectors as partnerSelectors } from '../../redux/partner';
import {
  analitycsEvent,
  GAActions,
  GACategories,
} from './../../utils/ga-utils';

import { PartnerDeactivationReasonType } from '../../constants/PartnerDeactivationReasonType';
import { storeCtaOpts } from '../store-status/constants';
import useStyles from './DeactivatePartnerDialog.styles';

const unitOfTimeEnum = {
  MINUTES: 0,
  HOURS: 1,
};

const turnOffOptionEnum = {
  LIMITED_TIME: '0',
  REST_OF_DAY: '1',
  FOREVER: '2',
};

const getNumbersOption = (start, end) => {
  const options = [];
  for (let i = start; i <= end; i++) {
    const option = {
      key: i,
      value: i,
      label: i.toString(),
    };

    options.push(option);
  }

  return options;
};

function DeactivatePartnerDialog(props) {
  const {
    cancelText,
    classes = {},
    confirmText,
    minutesText,
    profile,
    onClose,
    onConfirm,
    subtitle,
    title,
    turnOffByLimitedTimeText,
    turnOffByRestOfDay,
  } = props;

  const _classes = useStyles();

  const unitOfTimeOptions = [
    {
      // key: unitOfTimeEnum.MINUTES,
      value: unitOfTimeEnum.MINUTES,
      label: minutesText,
    },
  ];

  const [loading, setLoading] = useState(false);
  const [hoursOptions, setHoursOptions] = useState([]);
  const [minutesOptions, setMinutesOptions] = useState([]);
  const [selectedMinutesHours, setSelectedMinutesHours] = useState(1);
  const [radioSelectedOption, setRadioSelectedOption] = useState(
    turnOffOptionEnum.LIMITED_TIME,
  );
  const [unitOfTime, setUnitOfTime] = useState(0);

  const storeDetails = useSelector(partnerSelectors.selectStoreStatusDetail);

  const getValueOptions = () => {
    return unitOfTime === unitOfTimeEnum.MINUTES
      ? minutesOptions
      : hoursOptions;
  };

  const handleCancel = () => {
    if (!loading) onClose();
  };

  const handleOk = () => {
    let deactivateMinutes;
    let partnerDeactivationReason;
    setLoading(true);
    switch (radioSelectedOption) {
      case turnOffOptionEnum.LIMITED_TIME:
        deactivateMinutes =
          unitOfTime === unitOfTimeEnum.MINUTES
            ? selectedMinutesHours
            : selectedMinutesHours * 60;
        partnerDeactivationReason = PartnerDeactivationReasonType.LIMITED_TIME;
        break;
      case turnOffOptionEnum.REST_OF_DAY:
        deactivateMinutes = 1440;
        partnerDeactivationReason = PartnerDeactivationReasonType.REST_OF_DAY;
        break;
      default:
        deactivateMinutes = 0;
        partnerDeactivationReason = PartnerDeactivationReasonType.UNKNOWN;
        break;
    }
    analitycsEvent(
      GACategories.PARTNER_STATE,
      GAActions.DEACTIVATE_STORE,
      `deactivate_time=${deactivateMinutes}#partner_id=${profile.id}`,
    );
    const storesIdsToDeactivate = storeDetails
      .filter(item => item.cta_type === storeCtaOpts['cta.deactivate'].cta)
      .map(item => item.store_id);
    EventActions.onToggleAllStoreStates({
      action: storeCtaOpts['cta.deactivate'].amplitud.action,
      partnerId: profile.id,
      storeIds: storesIdsToDeactivate,
    });
    onConfirm(deactivateMinutes, partnerDeactivationReason);
  };

  useEffect(() => {
    const generatedMinutesOptions = getNumbersOption(1, 120);
    const generatedHoursOptions = getNumbersOption(1, 12);
    setMinutesOptions(generatedMinutesOptions);
    setHoursOptions(generatedHoursOptions);
  }, []);

  useEffect(() => {
    if (loading) setLoading(false);
  }, [storeDetails, loading]);

  return (
    <CustomSwipeableDialog
      buttons={[
        {
          variant: 'outlined',
          color: 'tertiary',
          onClick: handleCancel,
          label: cancelText,
        },
        {
          color: 'primary',
          onClick: handleOk,
          variant: 'contained',
          label: loading ? (
            <CircularProgress color='inherit' size='1.25rem' />
          ) : (
            confirmText
          ),
        },
      ]}
      classes={{ dialogPaper: _classes.dialogPaper }}
      onClose={handleCancel}
      open={profile.isDeactivateDialogOpened}
      subtitle={subtitle}
      title={title}
    >
      <RadioGroup
        className={_classes.group}
        value={radioSelectedOption}
        onChange={e => {
          setRadioSelectedOption(e.target.value);
        }}
      >
        <CustomRadio
          color='primary'
          label={turnOffByLimitedTimeText}
          value={turnOffOptionEnum.LIMITED_TIME}
        />
        {radioSelectedOption === turnOffOptionEnum.LIMITED_TIME && (
          <div
            className={clsx(
              _classes.timeSelectorsContainer,
              classes?.timeSelectorsContainer,
            )}
          >
            <CustomSelect
              value={selectedMinutesHours}
              onChange={e => {
                setSelectedMinutesHours(e.target.value);
              }}
              options={getValueOptions()}
            />
            <CustomSelect
              classes={{ root: _classes.unitOfTimeSelect }}
              onChange={e => {
                setSelectedMinutesHours(1);
                setUnitOfTime(e.target.value);
              }}
              value={unitOfTime}
              options={unitOfTimeOptions}
            />
          </div>
        )}
        <CustomRadio
          color='primary'
          label={turnOffByRestOfDay}
          value={turnOffOptionEnum.REST_OF_DAY}
        />
      </RadioGroup>
    </CustomSwipeableDialog>
  );
}

export default DeactivatePartnerDialog;
