export const urls = {
  SEARCH_ORDER_MGMT_ITEM:
    'api/rs-aliados-gateway/orders/partner/status/orders/search',
  SEND_ORDER_MGMT_MODIFICATION:
    'api/rs-aliados-gateway/orders/partner/status/orders/management/historical',
  ACCEPT_ORDER_WITH_TIMEOUT:
    'api/rs-aliados-gateway/orders/partner/status/orders/management/process-response-time-out/:orderId',
  REJECTED_ORDER_WITH_TIMEOUT:
    'api/rs-aliados-gateway/orders/partner/status/orders/management/process-stockout/:orderId',
  DISABLED_AUTO_TAKE:
    'api/rs-aliados-gateway/orders/partner/status/orders/management/disabled-auto-take',
  DEACTIVATED_PRODUCTS_AND_TOPPINGS:
    'api/rs-aliados-gateway/orders/partner/status/orders/management/products-toppings/deactivate',
  REMOVE_PRODUCTS_FROM_ORDER:
    'api/rs-aliados-gateway/orders/partner/status/orders/management/:orderId/product-removal',
} as const;
