import { makeStyles } from '@material-ui/core';
import Colors from '../../../../constants/Colors';
import { neutral } from '../../../../shared/styles/colors';

export const OrderMgmtReplaceProductDialogStyles = makeStyles(() => ({
  paper: {
    height: '20rem',
    width: '45.6875rem',
    padding: '1rem',
    position: 'relative',
  },
  replaceProductDialogHeader: {
    height: 0,
    borderBottom: 'transparent',
  },
  replaceProductDialogHeaderTitle: {
    fontWeight: 600,
    fontSize: '1.5rem',
    color: neutral.D20,
  },
  replaceProductDisclaimerContent: {
    background: Colors.WARNING_LIGHT_4,
    borderLeft: `3px solid ${Colors.WARNING}`,
  },
  replaceProductDisclaimerIcon: {
    alignSelf: 'flex-start',
    marginTop: '0.2rem',
  },
  replaceProductDisclaimer: {
    fontWeight: 400,
    fontSize: '0.875rem',
    color: neutral.D18,
  },
}));
