// TODO: To move the corresponding stuff to the business layer.
import { useState } from 'react';

import Urls from '../../../../../constants/Urls';
import http from '../../../../../services/HttpService';
import LocalStorageService from '../../../../../services/local-storage-service/LocalStorageService';
import UrlUtils from '../../../../../utils/url-utils/UrlUtils';

import { SATURATION } from '../../../../../business/highDemand';
import { highDemandAdapter } from '../../../../../business/highDemand/infra/adapters/highDemand.adapter';
import { STORE_STATUS_INPUT_DTO } from '../../../../../business/highDemand/infra/core';

import { operationalStateTurboHandler } from '../../../../../business/highDemand/infra';
import type { PartnerId } from '../../../../../business/shared/domain';

export function useFetchStoreStatus({ partnerId }: Props) {
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stores, setStores] = useState<any[]>([]);
  const [severityTimes, setSeverityTimes] = useState({});
  const clearState = () => {
    setIsError(false);
    setIsSuccess(false);
    setStores([]);
  };
  const { getStoreStatusDetail } = operationalStateTurboHandler();

  const fnFetch = () => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
    const baseUrl = LocalStorageService.getBaseUrl();
    const uriSaturationListByStoreWithSeverityTimes = UrlUtils.replaceUrlParams(
      Urls.SATURATION_LIST_BY_STORE_WITH_SEVERITY_TIMES,
    );
    const uriCheckStoreEligibilityForFastOperation = UrlUtils.replaceUrlParams(
      Urls.CHECK_STORE_ELIGIBILITY_FOR_FAST_OPERATION,
    );
    setTimeout(async () => {
      let localIsSuccess = false;
      try {
        localIsSuccess = true;
        const storesDetail = await getStoreStatusDetail();
        const { data } = await http.get(
          `${baseUrl}${uriSaturationListByStoreWithSeverityTimes}`,
        );
        const stores = data.stores.map(store => {
          const _s = storesDetail.find(item => item.id === store.store_id);
          return {
            ...store,
            status: STORE_STATUS_INPUT_DTO[_s!.status],
            cta: _s?.ctaType,
            isTurbo: _s?.isTurbo,
          };
        });
        setSeverityTimes(
          Object.fromEntries(
            Object.entries(data['severity_times']).map((item: any) => [
              highDemandAdapter.toModelSaturation(item[0]),
              item[1].extra_minutes,
            ]),
          ),
        );
        const storeIds = data.stores.map(({ store_id }) => store_id);
        const { data: storesForEligibilityFastOperation } = await http.post(
          `${baseUrl}${uriCheckStoreEligibilityForFastOperation}`,
          storeIds,
        );
        setStores(
          stores.map(item => ({
            ...item,
            storeId: item.store_id,
            saturated: item?.is_fast_operation
              ? SATURATION.fast
              : highDemandAdapter.toModelSaturation(item.saturated),
            isEligibleForFastOperation: storesForEligibilityFastOperation.find(
              _s => _s.store_id === item.store_id,
            )?.is_eligible_for_fast_operation,
          })),
        );
      } catch (err) {
        localIsSuccess = false;
      } finally {
        setIsLoading(false);
        localIsSuccess ? setIsSuccess(true) : setIsError(true);
      }
    }, 300);
  };
  return {
    isError,
    isSuccess,
    isLoading,
    fnFetch,
    stores,
    severityTimes,
    clearState,
  };
}

interface Props {
  partnerId: PartnerId;
}
