const getKeyByValue = <T>(
  enumObject: any,
  value?: string | number | null,
): T | undefined => {
  if (!value) {
    return undefined
  }

  const key = Object.keys(enumObject)[Object.values(enumObject).indexOf(value)]

  return enumObject[key]
}

const getKeyByValueOrDefault = <T>(
  enumObject: any,
  defaultValue: T,
  value?: string | number | null,
): T => {
  return getKeyByValue<T>(enumObject, value) || defaultValue
}

const utils = { getKeyByValue, getKeyByValueOrDefault }
export default utils
