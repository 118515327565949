import { clsx } from 'clsx';

import { CustomKeyValueBox } from '../custom-key-value-box';

import type { BaseProps } from '../../../interfaces';

export function CustomKeyValueListBox(props: Props) {
  const { list, labelPropertyName, valuePropertyName, classes = {} } = props;
  return (
    <div className={clsx(classes?.root)}>
      {!!list &&
        list.map((item, index) => (
          <CustomKeyValueBox
            classes={{ root: clsx(classes?.customKeyValueBoxRoot) }}
            key={`custom-key-value-box-${index}-${labelPropertyName}`}
            label={item[labelPropertyName]}
            value={item[valuePropertyName]}
          />
        ))}
    </div>
  );
}

interface Props extends BaseProps {
  list: any[];
  labelPropertyName: string;
  valuePropertyName: string;
}
