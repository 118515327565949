import React from 'react';
import Colors from '../../../constants/Colors';

export default function AlertIcon({ type }: { type: AlertTypeColor }) {
  const fillColor = {
    danger: Colors.SECONDARY_COLOR_DARK,
    warning: Colors.ANZAC,
    info: Colors.INFO_COLOR_2,
  }[type];

  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 8.99951C1.5 4.86026 4.86 1.49951 9 1.49951C13.1475 1.49951 16.5 4.86026 16.5 8.99951C16.5 13.1403 13.1475 16.4995 9 16.4995C4.86 16.4995 1.5 13.1403 1.5 8.99951ZM9.00368 7.09082C9.52145 7.09082 9.94118 6.67109 9.94118 6.15332C9.94118 5.63555 9.52145 5.21582 9.00368 5.21582H8.99618C8.47841 5.21582 8.05868 5.63555 8.05868 6.15332C8.05868 6.67109 8.47841 7.09082 8.99618 7.09082H9.00368ZM9.00368 12.7847C8.48592 12.7847 8.06618 12.3649 8.06618 11.8472L8.06619 8.53292C8.06619 8.01515 8.48592 7.59542 9.00369 7.59542C9.52145 7.59542 9.94119 8.01515 9.94119 8.53292L9.94118 11.8472C9.94118 12.3649 9.52145 12.7847 9.00368 12.7847Z'
        fill={fillColor}
      />
    </svg>
  );
}

export type AlertTypeColor = 'danger' | 'warning' | 'info';
