import { clsx } from 'clsx';
import { IconButton } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';

import { CustomTooltip } from '../../custom-tooltip';

export function CustomCheckCircleButton(props) {
  const {
    classes = {},
    color,
    disabled,
    onClick,
    isArrowShown = false,
    titleCheckToolTip = '',
    openToolTip = false,
    onOpenToolTip = e => {},
    onCloseToolTip = e => {},
  } = props;
  return (
    <CustomTooltip
      isArrowShown={isArrowShown}
      title={titleCheckToolTip}
      open={openToolTip}
      placement='top'
      onOpen={onOpenToolTip}
      onClose={onCloseToolTip}
    >
      <IconButton
        aria-label='CheckCircle'
        className={clsx(classes?.button)}
        color={color}
        disabled={disabled}
        onClick={onClick}
      >
        <CheckCircle className={clsx(classes?.icon)} />
      </IconButton>
    </CustomTooltip>
  );
}
