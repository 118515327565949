import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import { ReadOnlyInterval } from '../read-only-interval';
import { BeingEditedInterval } from '../being-edited-interval';

import hooks from './hooks';
import {
  actions as schedulePMFlowDialogActions,
  Interval,
} from '../../../../redux/schedule-PM-flow-dialog';

export function IntervalBox({ interval, hourList = [] }: Props) {
  const dispatch = useDispatch();
  const formId = hooks.useFormId();

  const beingEditedIntervalId = hooks.useBeingEditedIntervalId();

  const [beingEditedStartTime, setBeingEditedStartTime] = useState<string>(
    interval.startsTime,
  );
  const [beingEditedEndTime, setBeingEditedEndTime] = useState<string>(
    interval.endsTime,
  );
  useEffect(() => {
    setBeingEditedStartTime(interval.startsTime);
  }, [interval.startsTime]);

  useEffect(() => {
    setBeingEditedEndTime(interval.endsTime);
  }, [interval.endsTime]);

  const handleStartIntervalClick = useCallback(value => {
    setBeingEditedStartTime(value);
  }, []);

  const handleSecondIntervalClick = useCallback(value => {
    setBeingEditedEndTime(value);
  }, []);

  const handleClickEdit = useCallback(() => {
    dispatch(schedulePMFlowDialogActions.setBeingEditedIntervalId(interval.id));
  }, [dispatch, interval.id]);

  const handleUpdateInterval = useCallback(() => {
    dispatch(
      schedulePMFlowDialogActions.confirmIntervalEdition(
        interval.id,
        formId,
        beingEditedStartTime,
        beingEditedEndTime,
        interval.day,
      ),
    );
    setBeingEditedStartTime(interval.startsTime);
    setBeingEditedEndTime(interval.endsTime);
  }, [
    dispatch,
    formId,
    beingEditedStartTime,
    beingEditedEndTime,
    interval.startsTime,
    interval.endsTime,
    interval.day,
    interval.id,
  ]);

  const handleDeleteInterval = useCallback(() => {
    dispatch(schedulePMFlowDialogActions.deleteInterval(formId, interval.id));
  }, [dispatch, interval.id, formId]);

  const handleCancelEditionInterval = useCallback(() => {
    dispatch(schedulePMFlowDialogActions.cancelIntervalEdition(interval.id));
    setBeingEditedStartTime(interval.startsTime);
    setBeingEditedEndTime(interval.endsTime);
  }, [dispatch, interval.id, interval.startsTime, interval.endsTime]);

  return beingEditedIntervalId === interval.id ? (
    <BeingEditedInterval
      listOfHours={hourList}
      onClickCancelButton={handleCancelEditionInterval}
      onClickCheckButton={handleUpdateInterval}
      onChangeStartInterval={handleStartIntervalClick}
      onChangeEndInterval={handleSecondIntervalClick}
      startTime={beingEditedStartTime}
      endTime={beingEditedEndTime}
    />
  ) : (
    <ReadOnlyInterval
      onClickEdit={handleClickEdit}
      onClickDelete={handleDeleteInterval}
      interval={interval}
      disabledButtons={!!beingEditedIntervalId}
    />
  );
}

export interface Props {
  interval: Interval;
  hourList: string[];
}
