import { useSelector } from 'react-redux';

import { selectors as successfulSuggestingProductDialog } from '../../redux/successful-suggesting-product-dialog';

const useOpen = () => useSelector(successfulSuggestingProductDialog.selectOpen);

const useSelectedProduct = () =>
  useSelector(successfulSuggestingProductDialog.selectedProduct);

const useSuggestion = () =>
  useSelector(successfulSuggestingProductDialog.selectedSuggestion);

const hooks = {
  useOpen,
  useSelectedProduct,
  useSuggestion,
};

export default hooks;
