import { AxiosResponse } from 'axios';
import http from '../../../../services/HttpService';
import LocalStorageService from '../../../../services/local-storage-service/LocalStorageService';
import UrlUtils from '../../../../utils/url-utils/UrlUtils';
import { urls } from '../core/consts';
import {
  AcceptedOrderRequest,
  DeactivateProductAndToppingsRequest,
  DisabledAutoTakeRequest,
  OrderModificationRequest,
  RejectedOrderRequest,
  RemoveProductsFromOrderRequest,
} from '../core/requests';
import { AutoTakeOrderResponse } from '../core/responses/auto-take-order.response';

const _baseUrl = LocalStorageService.getBaseUrl();

const sendModification = (
  payload: OrderModificationRequest,
): Promise<AxiosResponse> => {
  return http
    .post(`${_baseUrl}${urls.SEND_ORDER_MGMT_MODIFICATION}`, payload)
    .catch(err => {
      if (err?.data != null) {
        const errorCode = err?.data?.error?.code ?? '';
        return Promise.reject(errorCode);
      }
      return Promise.reject(err);
    });
};
const acceptWithTimeout = (orderId: number, payload: AcceptedOrderRequest) => {
  const url = UrlUtils.replaceUrlParams(urls.ACCEPT_ORDER_WITH_TIMEOUT, {
    orderId,
  });
  return http.put(`${_baseUrl}${url}`, payload);
};
const rejectWithTimeout = (orderId: number, payload: RejectedOrderRequest) => {
  const url = UrlUtils.replaceUrlParams(urls.REJECTED_ORDER_WITH_TIMEOUT, {
    orderId,
  });
  return http.post(`${_baseUrl}${url}`, payload);
};

const disabledAutoTake = (
  payload: DisabledAutoTakeRequest,
): Promise<AxiosResponse<AutoTakeOrderResponse>> => {
  return http.put(`${_baseUrl}${urls.DISABLED_AUTO_TAKE}`, payload);
};

const deactivateItems = (payload: DeactivateProductAndToppingsRequest) => {
  return http.put(
    `${_baseUrl}${urls.DEACTIVATED_PRODUCTS_AND_TOPPINGS}`,
    payload,
  );
};

const removeProductsFromOrder = (
  payload: RemoveProductsFromOrderRequest,
  orderId: number,
) => {
  const url = UrlUtils.replaceUrlParams(urls.REMOVE_PRODUCTS_FROM_ORDER, {
    orderId,
  });
  return http.put(`${_baseUrl}${url}`, payload);
};

export const orderRepository = {
  sendModification,
  acceptWithTimeout,
  rejectWithTimeout,
  disabledAutoTake,
  deactivateItems,
  removeProductsFromOrder,
} as const;
