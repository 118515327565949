import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import PartnersService from '../../services/partners-service';

import MenuSearch from './MenuSearch';

const mapStateToProps = ({
  menuReducer: { filter, menus, searchedProducts, searchedToppings },
  partnerReducer: { profile },
}) => {
  const selectedStoreId = +filter.selectedStoreId;
  const stores = profile.stores;
  const storeMenu = menus[selectedStoreId];
  const title = PartnersService.getStoreName(stores, selectedStoreId);
  const showProductsList = searchedProducts && searchedProducts.length > 0;
  const showToppingsList = searchedToppings && searchedToppings.length > 0;
  const showMenuSearchStateMessage =
    !storeMenu ||
    !storeMenu.wasCompletelyDownloaded ||
    (!showProductsList && !showToppingsList);
  return {
    showMenuSearchStateMessage,
    showProductsList,
    showToppingsList,
    title,
  };
};

const mapDispatchToProps = {};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(MenuSearch),
);
