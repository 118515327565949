import _ from 'lodash';
import { clsx } from 'clsx';
import { Fragment } from 'react';
import { Icon, Box } from '@material-ui/core';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

import CustomAlert from '../common/custom-alert/CustomAlert';
import { ProductComment } from './components/product-comment';
import { SuggestedProductComment } from './components/suggested-product-comment';
import Typography, { Variants, FontWeight } from '../common/typography';
import CustomCheckbox from '../common/custom-checkbox/CustomCheckbox';
import { TrashIcon } from '../icons/trash-icon';
import {
  ChipOrderDisclaimer,
  OrderMgmtDisclaimer,
} from '../order-management/components/order-mgmt-dialog/components';

import { useFormatMessage } from '../../intl/intl';
import { formatCurrency } from '../../utils/CurrencyUtils';
import { useOrderManagement } from '../order-management/hooks';

import { Serverities } from '../../constants/Severities';
import StringUtils from '../../utils/StringUtils';
import { orderDetailCardStyles } from './OrderDetailCard.styles';

export function OrderDetailCard(props: Props) {
  const {
    classes,
    product,
    showAddWhimsButton,
    deactivateProductIds,
    deactivateToppingIds,
  } = props;

  const {
    isSendingUserApproval,
    hasNoUserResponse,
    handleCheckProductSelected,
    handleSelectTopping,
    checkIfProductIsSelected,
    checkIfToppingIsSelected,
    checkIfProductHasToppingsSelected,
    hasOrderOnlyOneProduct,
    handleRemoveProduct,
    handleCheckIfProductHasBeenRemoved,
    hasOrderProductsRemoved,
    hasOrderProductsSelected,
  } = useOrderManagement();

  const {
    comments,
    description,
    groupedToppings,
    id,
    name,
    quantityText,
    toppingMessage,
    total_real_price,
    replacement_suggestion,
    has_defect_rate_report,
    global_offers = [],
  } = product;

  const isProductDeactivated = deactivateProductIds.includes(id);
  const isProductSelected = checkIfProductIsSelected(id);
  const _classes = orderDetailCardStyles();
  const globalOfferTagPromo =
    global_offers[0]?.conditions?.[0]?.tag_promo ?? '';
  const defectRateTitle = useFormatMessage({ id: 'defectRate.title' });
  const defectRateCaption = useFormatMessage({ id: 'defectRate.caption' });
  const commentsText = useFormatMessage({
    id: 'orderStatusCookingDetail.comments',
  });
  const subtotal = useFormatMessage({
    id: 'orderStatusCookingDetail.subtotal',
  });
  const addValue = useFormatMessage({
    id: 'orderStatusCookingDetail.addValue',
  });

  const globalOfferText = useFormatMessage(
    { id: 'globalOffer.text' },
    {
      promo: globalOfferTagPromo,
    },
  );

  const globalOfferRemember = useFormatMessage({
    id: 'globalOffer.remember',
  });

  const globalOfferCaption = `<b>${globalOfferRemember}:</b> ${globalOfferText}`;

  const deactivateItemsDescriptionText = ({ item, itemName }) =>
    useFormatMessage(
      { id: 'orderManagement.deactivateItems.disclaimerDescription' },
      { item: useFormatMessage({ id: `orderManagement.${item}` }), itemName },
    );
  const deactivateItemsTitleText = useFormatMessage({
    id: 'orderManagement.deactivateItems.disclaimerTitle',
  });
  const soldOutProductText = useFormatMessage({
    id: 'orderManagement.deactivateItems.soldOutProduct',
  });
  const soldOutToppingText = useFormatMessage({
    id: 'orderManagement.deactivateItems.soldOutTopping',
  });

  const isReplacementSuggestionValid = !StringUtils.isNullOrEmpty(
    replacement_suggestion,
  );
  const hasGlobalOffer = !StringUtils.isNullOrEmpty(globalOfferTagPromo);
  const showDetail = !_.isEmpty(groupedToppings) || comments || description;

  return (
    !handleCheckIfProductHasBeenRemoved(id) && (
      <div className={clsx(_classes.root, classes?.root)}>
        {has_defect_rate_report && (
          <Box
            className={clsx(_classes.defectRate, _classes?.defectRateBorder)}
          >
            <Icon className={_classes.defectRateIcon}>
              <ErrorRoundedIcon />
            </Icon>
            <Box>
              <Typography
                fontWeight={FontWeight.Bold}
                classes={{ root: _classes.defectRateText }}
              >
                {defectRateTitle}
              </Typography>
              <Typography classes={{ root: _classes.defectRateText }}>
                {defectRateCaption}
              </Typography>
            </Box>
          </Box>
        )}
        <div
          className={clsx(_classes.wrapper, classes?.wrapper, {
            [_classes.defectRateBorder]: has_defect_rate_report,
          })}
        >
          <div className={_classes.container}>
            {/* Header */}
            <Box className={_classes.detailHeader}>
              <Typography
                classes={{ root: _classes.quantity }}
                fontWeight={FontWeight.SemiBold}
              >
                {quantityText}
              </Typography>
              <div className={_classes.headerDivider}></div>
              <Box className={_classes.titleWrapper}>
                <Typography classes={{ root: _classes.productTitle }}>
                  {name}
                </Typography>
                {isProductDeactivated && (
                  <ChipOrderDisclaimer
                    type='warning'
                    disclaimer={soldOutProductText}
                  />
                )}
                {(isSendingUserApproval || hasNoUserResponse) && (
                  <Box display='flex' alignItems='center'>
                    <CustomCheckbox
                      classes={{ root: _classes.checkProduct }}
                      checked={isProductSelected}
                      onChange={() => handleCheckProductSelected(id)}
                      value={isProductSelected}
                      disabled={hasOrderProductsRemoved}
                    />
                    {isSendingUserApproval && (
                      <Box
                        className={clsx(_classes.deleteProduct, {
                          [_classes.deleteProductsDisabled]:
                            isProductSelected ||
                            hasOrderOnlyOneProduct ||
                            hasOrderProductsSelected,
                        })}
                        onClick={() =>
                          isProductSelected ||
                          hasOrderOnlyOneProduct ||
                          hasOrderProductsSelected
                            ? null
                            : handleRemoveProduct(id)
                        }
                      >
                        <TrashIcon
                          type={
                            isProductSelected ||
                            hasOrderOnlyOneProduct ||
                            hasOrderProductsSelected
                              ? 'disabled'
                              : 'default'
                          }
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
            {/* order body */}
            {showDetail && (
              <div className={_classes.detailContainer}>
                <Typography
                  classes={{ root: _classes.description }}
                  variant={Variants.Caption2}
                >
                  {description}
                </Typography>
                {isProductDeactivated && (
                  <OrderMgmtDisclaimer
                    title={deactivateItemsTitleText}
                    description={deactivateItemsDescriptionText({
                      item: 'product',
                      itemName: name,
                    })}
                  />
                )}
                {hasGlobalOffer && <CustomAlert text={globalOfferCaption} />}
                {/* Topping custom alert */}
                {toppingMessage && (
                  <div className={_classes.alertMessage}>
                    <CustomAlert
                      text={toppingMessage}
                      type={Serverities.INFO}
                    />
                  </div>
                )}
                {groupedToppings && (
                  <div className={_classes.toppings}>
                    {groupedToppings.map(
                      (toppingCategory: any, index: number) => (
                        <Fragment key={index}>
                          <Typography
                            classes={{ root: _classes.toppingCategory }}
                            variant={Variants.Caption2}
                          >
                            {toppingCategory.name}
                          </Typography>
                          {toppingCategory.toppings.map((topping: any) => {
                            const isToppingDeactivated = deactivateToppingIds.includes(
                              topping.id,
                            );
                            return (
                              <Box
                                key={topping.id}
                                className={_classes.toppingDetail}
                                display='flex'
                                flexDirection='column'
                              >
                                <Box
                                  display='flex'
                                  width='100%'
                                  alignItems='center'
                                  justifyContent='space-between'
                                >
                                  <Typography
                                    classes={{
                                      root: _classes.toppingDescription,
                                    }}
                                  >
                                    {topping.units > 1 && (
                                      <>{topping.units}x&ensp;</>
                                    )}
                                    {topping.description}
                                  </Typography>
                                  <Box className={_classes.toppingOptions}>
                                    {isToppingDeactivated && (
                                      <ChipOrderDisclaimer
                                        type='warning'
                                        disclaimer={soldOutToppingText}
                                      />
                                    )}
                                    <Typography>
                                      {formatCurrency(topping.total_real_price)}
                                    </Typography>
                                    {(isSendingUserApproval ||
                                      hasNoUserResponse) && (
                                      <CustomCheckbox
                                        classes={{
                                          root: _classes.checkTopping,
                                        }}
                                        checked={checkIfToppingIsSelected(
                                          id,
                                          topping.id,
                                        )}
                                        onChange={() =>
                                          handleSelectTopping(id, topping.id)
                                        }
                                        value={checkIfToppingIsSelected(
                                          id,
                                          topping.id,
                                        )}
                                        disabled={
                                          isProductSelected ||
                                          checkIfProductHasToppingsSelected(
                                            id,
                                            topping.id,
                                          )
                                        }
                                      />
                                    )}
                                  </Box>
                                </Box>
                                {isToppingDeactivated && (
                                  <OrderMgmtDisclaimer
                                    title={deactivateItemsTitleText}
                                    description={deactivateItemsDescriptionText(
                                      {
                                        item: 'topping',
                                        itemName: topping.description,
                                      },
                                    )}
                                  />
                                )}
                              </Box>
                            );
                          })}
                        </Fragment>
                      ),
                    )}
                  </div>
                )}
                {!isReplacementSuggestionValid && comments && (
                  <ProductComment
                    textLabel={commentsText}
                    comments={comments}
                    showButton={showAddWhimsButton}
                    buttonLabel={addValue}
                    productName={name}
                    productId={id}
                  />
                )}
              </div>
            )}
            {isReplacementSuggestionValid && (
              <SuggestedProductComment
                productNameToBeReplaced={name}
                suggestedProduct={replacement_suggestion}
              />
            )}
          </div>
        </div>
        {/* subtotal */}
        <div className={_classes.detailSubtotal}>
          <Typography fontWeight={FontWeight.SemiBold}>{subtotal}</Typography>
          <Typography
            classes={{
              root: _classes.subtotalVal,
            }}
            fontWeight={FontWeight.SemiBold}
          >
            {formatCurrency(total_real_price)}
          </Typography>
        </div>
      </div>
    )
  );
}

interface Props {
  classes: any;
  intl: { formatMessage: any };
  product: any;
  showAddWhimsButton: boolean;
  deactivateProductIds: Array<number>;
  deactivateToppingIds: Array<number>;
}
