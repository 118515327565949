import { clsx } from 'clsx';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { hooks } from './hooks';
import { hooks as orderManagementHooks } from '../../hooks';
import { useOrderManagement } from '../../../order-management/hooks';
import OrderActions from '../../../../redux/actions/orders-actions/orders.actions';
import { OrderMgmtAnalytics } from '../../../../business/order-mgmt/analitycs';

import CustomButton, {
  Colors,
  Variants,
} from '../../../common/buttons/custom-button';
import starIcon from '../../../../../src/assets/images/icons/star.svg';

import useStyles from './styles';

function OrderManagementActionsSection({ classes, isDisabled = false }) {
  const _classes = useStyles();
  const {
    handleClickReplaceButton,
    isInEditionMode,
    isOfferReplaceItemsEnabled,
    onOpenItemSearchDialog,
    isDeactivatingItemsState,
    canSendChangesByTime,
    wasOrderItemsDeactivated,
    hasOrderProductsRemoved,
    onUndoRemoveProductsChanges,
    onOpenAcceptRemoveChangesDialog,
    showEditBtn,
  } = useOrderManagement();
  const showNewOrderActions = orderManagementHooks.useShowNewOrderActions();
  const orderDetail = orderManagementHooks.useOrderDetail();
  const orderId = orderManagementHooks.useOrderId();
  const storeId = orderManagementHooks.useStoreId();
  const {
    acceptButtonText,
    editOrderText,
    offerReplaceText,
    newFeatureText,
    undoChangesText,
    acceptWithChangesButtonText,
  } = hooks.useText();

  if (!showNewOrderActions) return null;

  return (
    <Box className={clsx(_classes.root, classes?.root)}>
      {isOfferReplaceItemsEnabled && (
        <OfferReplaceButton
          isDisabled={!isOfferReplaceItemsEnabled || !canSendChangesByTime}
          offerReplaceText={offerReplaceText}
          onOpenItemSearchDialog={onOpenItemSearchDialog}
        />
      )}
      {hasOrderProductsRemoved && (
        <UndoRemoveChangesButton
          undoChangesText={undoChangesText}
          onUndoRemoveProductsChanges={onUndoRemoveProductsChanges}
        />
      )}
      {showEditBtn && (
        <EditOrderButton
          isDisabled={
            isDisabled || isDeactivatingItemsState || wasOrderItemsDeactivated
          }
          handleClickReplaceButton={handleClickReplaceButton}
          editOrderText={editOrderText}
          newFeatureText={newFeatureText}
        />
      )}
      {!isOfferReplaceItemsEnabled && (
        <AcceptOrderButton
          isDisabled={
            isDisabled ||
            (isInEditionMode && !hasOrderProductsRemoved) ||
            !canSendChangesByTime
          }
          orderDetail={orderDetail}
          orderId={orderId}
          storeId={storeId}
          acceptButtonText={acceptButtonText}
          wasOrderItemsDeactivated={wasOrderItemsDeactivated}
          hasOrderProductsRemoved={hasOrderProductsRemoved}
          onOpenAcceptRemoveChangesDialog={onOpenAcceptRemoveChangesDialog}
          acceptWithChangesButtonText={acceptWithChangesButtonText}
        />
      )}
    </Box>
  );
}

export default OrderManagementActionsSection;

const NewFeatureChip = ({ chipText }: { chipText: string }) => {
  const _classes = useStyles();
  return (
    <Box
      padding='0.25rem 0.5rem'
      display='flex'
      alignItems='center'
      gridGap='0.5rem'
      className={_classes.newChipContainer}
    >
      <img src={starIcon} alt='star' />
      <span>{chipText}</span>
    </Box>
  );
};

const UndoRemoveChangesButton = ({
  undoChangesText,
  onUndoRemoveProductsChanges,
}: {
  undoChangesText: string;
  onUndoRemoveProductsChanges: any;
}) => {
  return (
    <CustomButton
      onClick={onUndoRemoveProductsChanges}
      variant={Variants.TEXT}
      fullWidth={false}
      color={Colors.SECONDARY}
    >
      {undoChangesText}
    </CustomButton>
  );
};

const OfferReplaceButton = ({
  isDisabled,
  offerReplaceText,
  onOpenItemSearchDialog,
}: {
  isDisabled: boolean;
  offerReplaceText: string;
  onOpenItemSearchDialog: any;
}) => {
  return (
    <CustomButton
      onClick={onOpenItemSearchDialog}
      variant={Variants.CONTAINED}
      fullWidth={false}
      disabled={isDisabled}
    >
      {offerReplaceText}
    </CustomButton>
  );
};

const EditOrderButton = ({
  isDisabled,
  handleClickReplaceButton,
  editOrderText,
  newFeatureText,
}: {
  isDisabled: boolean;
  handleClickReplaceButton: any;
  editOrderText: string;
  newFeatureText: string;
}) => {
  return (
    <Box position='relative' display='flex' justifyContent='center'>
      {!isDisabled && <NewFeatureChip chipText={newFeatureText} />}
      <CustomButton
        color={Colors.PRIMARY}
        onClick={() => handleClickReplaceButton({ editType: 'Button' })}
        fullWidth={false}
        disabled={isDisabled}
        variant={Variants.OUTLINED}
      >
        {editOrderText}
      </CustomButton>
    </Box>
  );
};

const AcceptOrderButton = ({
  isDisabled,
  orderDetail,
  orderId,
  storeId,
  acceptButtonText,
  wasOrderItemsDeactivated,
  hasOrderProductsRemoved,
  onOpenAcceptRemoveChangesDialog,
  acceptWithChangesButtonText,
}: {
  isDisabled: boolean;
  orderId: string;
  storeId: string;
  orderDetail: any;
  acceptButtonText: string;
  wasOrderItemsDeactivated: boolean;
  hasOrderProductsRemoved: boolean;
  onOpenAcceptRemoveChangesDialog: any;
  acceptWithChangesButtonText: string;
}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    hasOrderProductsRemoved
      ? onOpenAcceptRemoveChangesDialog()
      : onClickAcceptButton();
  };

  const onClickAcceptButton = () => {
    dispatch(
      OrderActions.takeOrderFromDialog(
        orderDetail?.id,
        orderDetail?.cookingTimeToTakeOrder,
      ),
    );

    if (orderDetail?.state_order_management?.state === 'accepted') {
      OrderMgmtAnalytics.whenAcceptOrder({ orderId, storeId });
    }

    if (wasOrderItemsDeactivated) {
      OrderMgmtAnalytics.whenClickAcceptOrderByReplaceWithoutEditing({
        orderId,
        storeId,
      });
    }

    return;
  };

  return (
    <CustomButton
      onClick={handleClick}
      variant={Variants.CONTAINED}
      fullWidth={false}
      disabled={isDisabled}
    >
      {hasOrderProductsRemoved ? acceptWithChangesButtonText : acceptButtonText}
    </CustomButton>
  );
};
