import { clsx } from 'clsx';

import { CustomStateLabel } from '../../../common/custom-state-label';
import RTWaitingMuchIcon from '../../../../assets/images/icons/rtWaitingMuchIcon.webp';

import useStyles from './styles';

import type { BaseProps } from '../../../../interfaces';

export function RtWaitingSinceLabel({ classes, Icon, ...extraProps }: Props) {
  const _classes = useStyles();
  return (
    <CustomStateLabel
      classes={{
        root: clsx(_classes.root, classes?.root),
        iconRoot: clsx(_classes.iconRoot, classes?.iconRoot),
      }}
      Icon={Icon ?? RTWaitingMuchIcon}
      {...extraProps}
    />
  );
}

interface Props extends BaseProps {
  Icon: any;
  zoneId: string;
  showCounter: boolean;
  rtWaitingSince: number;
}
