import { useState } from 'react';
import { clsx } from 'clsx';

import ToppingBox from '../topping-box';
import { useFormatMessage } from '../../intl/intl';

import useStyles from './styles';

function ToppingBoxesList(props) {
  const {
    classes = {},
    onChange,
    // products,
    selectorPropertyName,
    selectorType,
    showTitle = true,
    // toppingCategories,
    toppings,
  } = props;

  const [deactivatedItemId, setDeactivatedItemId] = useState(null);
  const _classes = useStyles();
  const title = useFormatMessage({ id: 'globals.toppings' });

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      {showTitle && (
        <div className={clsx(_classes.titleContainer, classes?.titleContainer)}>
          <span className={clsx(classes?.title)}>{title}</span>
        </div>
      )}
      <div
        className={clsx(_classes.toppingsContainer, classes?.toppingsContainer)}
      >
        {toppings.map((topping, index) => {
          return (
            <ToppingBox
              key={`t-${index}-${topping.id}`}
              classes={{
                root: clsx(_classes.toppingBoxRoot, classes?.toppingBoxRoot),
              }}
              onChange={() => {
                onChange(topping);
              }}
              // products={products}
              selectorPropertyName={selectorPropertyName}
              selectorType={selectorType}
              // toppingCategories={toppingCategories}
              topping={topping}
              showTitle={true}
              deactivatedItemId={deactivatedItemId}
              setDeactivatedItemId={setDeactivatedItemId}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ToppingBoxesList;
