import { Card, CardContent, CardHeader } from '@material-ui/core';

import { SaturationChip } from '../saturation-chip';
import { useMergeSuperWithHigh } from '../../shared/hooks';

import makeClasses from './StoreSaturationInfo.style';

import type { SaturationStateProp } from '../../shared/models';
import { Alert } from '@material-ui/lab';
import { TurboTag } from '../turbo-tag';

export function StoreSaturationInfo(props: Props) {
  const {
    storeName,
    storeAddress,
    onClick,
    isInactive,
    isSuspended,
    isActiveStore,
    isTurbo = false,
  } = props;
  const showCTA = Boolean(onClick);
  const variant = useMergeSuperWithHigh(props.variant);
  const classes = makeClasses({ variant, showCTA, isInactive, isSuspended });

  return (
    <Card
      component='article'
      onClick={!isSuspended && !isInactive ? onClick : () => {}}
      className={classes.article + (showCTA ? ' MuiButton-root ' : ' ')}
    >
      <CardHeader
        avatar={
          isSuspended ? (
            <Alert severity='error' classes={{ root: classes.danger }} />
          ) : null
        }
        component='header'
        title={storeName}
        subheader={storeAddress}
        titleTypographyProps={{ component: 'h2' }}
        subheaderTypographyProps={{ component: 'p' }}
        classes={{
          root: classes.header,
          title: classes.title,
          subheader: classes.subtitle,
          avatar: classes.avatar,
        }}
      />
      {isActiveStore && isTurbo && <TurboTag variant='dialog' />}
      {isActiveStore && !isTurbo && (
        <CardContent component='main' className={classes.main}>
          <SaturationChip variant={variant} />
        </CardContent>
      )}
    </Card>
  );
}

interface Props extends SaturationStateProp {
  storeName: string;
  storeAddress: string;
  onClick?: () => void;
  isInactive?: boolean;
  isSuspended?: boolean;
  isActiveStore?: boolean;
  isTurbo?: boolean;
}
