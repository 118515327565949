// External libs
import { createSelector } from 'reselect';
// Interfaces
import { State } from '../interfaces/state';
import { SupportSuggestion } from '../../../interfaces';

const selectSlice: (state: any) => State = (state: any) =>
  state.stockOutIssueSuggestionsDialog;

const selectDescription = createSelector<any, State, string>(
  selectSlice,
  reducer => reducer.description,
);

const selectOpen = createSelector<any, State, boolean>(
  selectSlice,
  reducer => reducer.open,
);

const selectOrderId = createSelector<any, State, number>(
  selectSlice,
  reducer => reducer.orderId,
);

const selectSupportSuggestions = createSelector<
  any,
  State,
  SupportSuggestion[]
>(
  selectSlice,
  reducer => reducer.supportSuggestions,
);

const selectors = {
  selectDescription,
  selectOpen,
  selectOrderId,
  selectSupportSuggestions,
};

export default selectors;
