// React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Components
import StoreScheduleTableHeader from './StoreScheduleTableHeader'

const mapStateToProps = (state, { intl: { formatMessage } }) => {
  const open = formatMessage({
    id: 'storesSchedulePage.open',
  })
  const close = formatMessage({
    id: 'storesSchedulePage.close',
  })

  return { open, close }
}

const mapDispatchToProps = {}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(StoreScheduleTableHeader),
)
