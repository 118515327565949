// External libs
import { createSelector } from 'reselect'
// Interfaces
import { State, LogOutReasons } from './interfaces'

const selectSlice = (state: any) => state.logOutReasonsDialog

const selectOpen = createSelector(selectSlice, reducer => reducer.open)

const selectLogOutReasons = createSelector<any, State, LogOutReasons[]>(
  selectSlice,
  reducer => reducer.reasons,
)

const selectors = {
  selectOpen,
  selectLogOutReasons,
}

export default selectors
