import { CustomCloseButton } from '../../../common/buttons/custom-close-button';

import useStyles from './styles';

export function MobileHeader({ onClose = () => {} }: { onClose: () => void }) {
  const componentClasses = useStyles();
  return (
    <div className={componentClasses.header}>
      <CustomCloseButton onClick={onClose} />
    </div>
  );
}
