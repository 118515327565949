import { makeStyles } from '@material-ui/core';

import { colorTheme, neutral } from '../../styles/colors';
import { PADDING, WIDTH } from './MainDrawer.const';

import type { MainDrawerWidth } from './MainDrawer.types';

export default makeStyles({
  drawer: ({ size = 'medium' }: Props) => ({
    minWidth: '20rem',
    width: '100%',
    maxWidth: WIDTH[size] ?? size,
    border: `1px solid ${neutral['20']}`,
  }),

  header: ({ size = 'medium' }: Props) => ({
    padding: `0 ${PADDING[size] ?? size}`,
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${neutral['20']}`,
    '& button[aria-label=back]': {
      marginLeft: '-0.75rem',
      fill: neutral['90'],
    },
    '& button[aria-label=close]': {
      marginRight: '-0.75rem',
      fill: neutral['90'],
    },
  }),
  headerTitle: {
    width: '100%',
    margin: 'auto 0',
  },
  headerTitleH1: {
    padding: '0.75rem 0',
    fontWeight: 'bold',
    color: neutral['90'],
  },

  main: ({ size = 'medium' }: Props) => ({
    height: '100%',
    padding: PADDING[size] ?? size,
    overflowY: 'scroll',
  }),
  subtitleSection: ({ size = 'medium' }: Props) => ({
    marginBottom:
      {
        medium: PADDING.small,
        large: PADDING.medium,
        xLarge: PADDING.large,
        big: PADDING.xLarge,
        small: PADDING.small,
      }[size] ?? size,
    '& h2[aria-label=default]': {
      fontWeight: 'bold',
      fontSize: '0.75rem',
      lineHeight: '1rem',
      letterSpacing: 1.12,
      color: neutral['70'],
    },
  }),

  footer: ({ size = 'medium' }: Props) => ({
    minHeight: '3.5rem',
    padding: PADDING[size] ?? size,
    display: 'flex',
    columnGap: '1rem',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${neutral['20']}`,
    '& button[aria-label=primary]': {
      padding: `${PADDING.small} ${PADDING.medium}`,
      fontSize: '0.75rem',
      lineHeight: '1.25rem',
      backgroundColor: colorTheme.brand.primary.main,
      '&[size=medium]': {
        padding: `${PADDING.medium} ${PADDING.large}`,
      },
      '&[size=large]': {
        padding: `${PADDING.large} ${PADDING.xLarge}`,
      },
      '&:disabled': {
        color: neutral['50'],
        backgroundColor: neutral['20'],
      },
    },
    '& button[aria-label=secondary]': {
      padding: `${PADDING.small} ${PADDING.medium}`,
      fontSize: '0.75rem',
      lineHeight: '1.25rem',
      borderRadius: '0.5rem',
      color: colorTheme.brand.primary.main,
      '&[size=medium]': {
        padding: `${PADDING.medium} ${PADDING.large}`,
      },
      '&[size=large]': {
        padding: `${PADDING.large} ${PADDING.xLarge}`,
      },
      '&:disabled': {
        color: neutral['50'],
        backgroundColor: neutral['10'],
      },
    },
  }),
});

interface Props {
  size?: MainDrawerWidth;
}
