import { createStyles, makeStyles } from '@material-ui/core';
import Colors from '../../../../constants/Colors';
import { neutral } from '../../../../shared/styles/colors';

export const disclaimerStyles = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: Colors.WARNING_LIGHT_2,
      gridArea: 'disclaimer',
    },
    content: {
      borderLeft: `3px solid ${Colors.WARNING}`,
      color: neutral.D18,
    },
    primaryText: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    btnSecondary: {
      minWidth: '9.125rem',
      color: Colors.SECONDARY_COLOR_DARK,
    },
  }),
);
