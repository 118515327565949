import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Poppins',
    fontSize: '1rem',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '0px 24px',
    },
  },
  productsContainer: {
    height: '100%',
    overflow: 'auto',
    flex: '1 1 1px',
    display: 'grid',
    gridAutoFlow: 'row',
    gridAutoRows: 'min-content',
    rowGap: '0.75rem',
  },
  label: {
    fontSize: '1rem',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    padding: '40px 16px',
    [theme.breakpoints.up('md')]: {
      padding: '40px 0px',
    },
  },
}));
