import { makeStyles } from '@material-ui/core';

import { neutral, purple, teal } from '../../../../shared/styles/colors';

import type { Theme } from '@material-ui/core';

export default makeStyles(({ breakpoints }: Theme) => ({
  dialogPaper: {
    margin: 0,
    padding: '1.5rem',
    gap: '0.75rem',
    color: neutral['100'],
    borderRadius: '0.75rem',
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  title: {
    fontWeight: 600,
    fontSize: '1rem',
    textAlign: 'center',
    lineHeight: '1.5rem',
  },
  content: {
    padding: '0.75rem',
    display: 'flex',
    gap: '2.5rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& img': {
      display: 'none',
      maxWidth: '11.25rem',
      [breakpoints.up('sm')]: {
        display: 'block',
      },
    },
  },
  contentSection: {
    '& > h2': {
      fontWeight: 600,
      fontSize: '0.625rem',
      lineHeight: '1rem',
      letterSpacing: '1.5px',
      color: purple['60'],
    },
    '& p': {
      marginTop: '0.75rem',
      fontSize: '0.75rem',
      lineHeight: '1.25rem',
    },
  },
  note: {
    fontSize: '0.625rem',
    lineHeight: '1.125rem',
    color: neutral['60'],
  },
  footer: {
    display: 'flex',
    gap: '1.0rem',
    justifyContent: 'flex-end',
  },
  link: {
    padding: '0.5rem 0.75rem',
    fontWeight: 600,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    color: teal['50'],
    borderRadius: '0.5rem',
  },
  lastLink: {
    color: neutral.white,
    backgroundColor: teal['50'],
  },
}));
