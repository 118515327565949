// Material UI
import { makeStyles } from '@material-ui/core'
// Colors
import Colors from '../../../../constants/Colors'

export default makeStyles(theme => ({
  contentContainer: {
    background: Colors.WHITE,
    borderRadius: 11,
  },
  root: {
    padding: '20px 16px',
    display: 'grid',
    gridRowGap: 20,
  },
}))
