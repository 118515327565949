// External libs
import { createSelector } from 'reselect';

const selectReducer = state => state.rtReleaseConfirmationDialogReducer;

const selectOpen = createSelector(
  selectReducer,
  reducer => reducer.open,
);

const selectOrderId = createSelector(
  selectReducer,
  reducer => reducer.orderId,
);

const RTReleaseConfirmationDialogSelectors = { selectOpen, selectOrderId };

export default RTReleaseConfirmationDialogSelectors;
