import { makeStyles } from '@material-ui/core/styles';

import { blue, neutral } from '../../../../../shared/styles/colors';

export default makeStyles({
  drawer: {
    width: '100%',
    maxWidth: '32rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: {
    padding: '1.5rem 1.5rem 1rem 1rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerTitle: {
    fontSize: '1rem',
    lineHeight: '1.25rem',
  },
  main: {
    height: '100%',
    padding: '1rem 1.5rem 1.5rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.75rem',
    border: `1px solid ${neutral['80']}24`,
  },
  alertBanner: {
    fontSize: '0.875rem',
    backgroundColor: blue['10'],
    borderLeft: `0.25rem solid ${blue['60']}`,
  },
  alertBannerTitle: {
    fontSize: '0.875rem',
    fontWeight: 600,
  },
  priceProductsToSend: {
    backgroundColor: neutral.white,
    border: `1px solid ${neutral['30']}`,
  },
  footer: {
    padding: '1rem 1.5rem 1.5rem 1rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionCreate: {
    padding: '0.5rem 1rem',
    lineHeight: '1.5rem',
    borderRadius: '0.5rem',
  },
});
