// React
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// Components
import RTStateLabel from './RTStateLabel';

const mapStateToProps = (
  {
    partnerReducer: {
      profile: { stores },
    },
  },
  { intl: { formatMessage } },
) => {
  return {};
};

const mapDispatchToProps = {};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RTStateLabel),
);
