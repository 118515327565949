// React
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
// Actions
import AppActions from './../../redux/actions/app-actions'
import MainLayoutActions from '../../redux/actions/mainLayout.actions'
import OrdersActions from '../../redux/actions/orders-actions'
// Components
import PickupCodeDialog from './PickupCodeDialog'

const mapStateToProps = (
  { mainLayoutReducer, ordersReducer, partnerReducer },
  { intl: { formatMessage } },
) => {
  const cancelText = formatMessage({ id: 'globals.cancel' })
  const confirmText = formatMessage({ id: 'globals.confirm' })
  const message = formatMessage({ id: 'pickupCodeDialog.message' })
  const title = formatMessage({ id: 'pickupCodeDialog.title' })

  const selectedOrder = { detail: {} }

  return {
    cancelText,
    confirmText,
    message,
    orderId: selectedOrder.id,
    open: mainLayoutReducer.isPickupCodeDialogOpened,
    partnerId: partnerReducer.profile.id,
    title,
  }
}

const mapDispatchToProps = {
  onClose: MainLayoutActions.closePickupCodeDialog,
  onConfirm: OrdersActions.getOrderIdByPickupCode,
  openSnackBarError: AppActions.openSnackBarError,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(PickupCodeDialog),
)
