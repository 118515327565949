import { useMediaQuery } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { CustomBreadcrumb } from '../common/custom-breadcrumb';
import { MenuCorridorsList } from '../menu-corridors-list';
import MenuOutOfStockView from '../menu-out-of-stock-view';
import MenuProductsList from '../menu-products-list';
import { MenuProductDetail } from '../menu-product-detail';
import MenuSearchField from '../menu-search-field';

import styles from './MenuBoard.style';

const mustMenuCorridorsListBeShown = (
  isMdUp,
  isMenuOutOfStockViewShown,
  isMenuProductDetailShown,
  isMenuProductsListShown,
) => {
  if (isMdUp) {
    // If it is rendered tablet up, show it.
    return true;
  }
  // If it is rendered on mobile,
  // show it only if neither the product list
  // nor the out of stock
  // nor the product detail view are not being shown
  return (
    !isMenuProductsListShown &&
    !isMenuOutOfStockViewShown &&
    !isMenuProductDetailShown
  );
};

const mustMenuOutOfStockViewBeShown = displayOutOfStock => {
  return displayOutOfStock;
};

const mustMenuProductDetailBeShown = selectedProduct => {
  return !!selectedProduct;
};

const mustMenuProductsListBeShown = (
  selectedCorridor,
  isMdUp,
  isMenuOutOfStockViewShown,
  isMenuProductDetailShown,
) => {
  if (!selectedCorridor) {
    // If there's not a selected corridor,
    // don't show the products list.
    return false;
  }
  if (isMdUp) {
    // If it is rendered tablet up, show it.
    return true;
  }
  // If it is rendered on mobile,
  // show it only if neither the out of stock
  // nor the product detail view are not being shown
  return !isMenuOutOfStockViewShown && !isMenuProductDetailShown;
};

function MenuBoard(props) {
  const {
    classes,
    currentCorridor,
    displayOutOfStock,
    links,
    selectedProductId,
    storeName,
  } = props;

  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  const title = isMdUp ? storeName : null;
  const showHeader = isMdUp;

  const isMenuProductDetailShown = mustMenuProductDetailBeShown(
    selectedProductId,
  );

  const isMenuOutOfStockViewShown = mustMenuOutOfStockViewBeShown(
    displayOutOfStock,
  );

  const isMenuProductsListShown = mustMenuProductsListBeShown(
    currentCorridor,
    isMdUp,
    isMenuOutOfStockViewShown,
    isMenuProductDetailShown,
  );

  const isMenuCorridorsListShown = mustMenuCorridorsListBeShown(
    isMdUp,
    isMenuOutOfStockViewShown,
    isMenuProductDetailShown,
    isMenuProductsListShown,
  );

  return (
    <div className={classes.root}>
      {!isMdUp && links.length > 0 && (
        <CustomBreadcrumb
          classes={{ root: classes.breadcrumbRoot }}
          links={links}
        />
      )}
      {showHeader && (
        <div className={classes.header}>
          <span className={classes.title}>{title}</span>
          <MenuSearchField classes={{ root: classes.searchInputRoot }} />
        </div>
      )}
      <div className={classes.mainContainer}>
        {isMenuCorridorsListShown && <MenuCorridorsList />}
        {isMenuOutOfStockViewShown && <MenuOutOfStockView />}
        {isMenuProductsListShown && <MenuProductsList />}
        {isMenuProductDetailShown && <MenuProductDetail />}
      </div>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(MenuBoard);
