// React
import React from 'react';
import { SvgIcon } from '@material-ui/core';
// Assets
// Styles

const PickupIcon = ({ className }) => {
  return (
    <SvgIcon
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='12'
      viewBox='0 0 12 12'
    >
      <path
        fill='#FA0'
        fillRule='nonzero'
        d='M.02 5.987c0-.884.713-1.6 1.594-1.6.691 0 1.279.44 1.5 1.056a3.539 3.539 0 0 0 1.998 1.374l.019.005a1.44 1.44 0 0 0-.288 2.011l-.008-.005a3.544 3.544 0 0 1-1.643-1.186L3.196 12H0l.02-6.001v-.012zM8.804 1.6c0 .884.714 1.6 1.595 1.6s1.595-.716 1.595-1.6c0-.884-.714-1.6-1.595-1.6-.88 0-1.595.716-1.595 1.6zM1.615 0c.88 0 1.595.716 1.595 1.6 0 .884-.715 1.6-1.596 1.6A1.598 1.598 0 0 1 .02 1.6C.02.717.733 0 1.614 0zm4.949 6.874c-.5.07-.886.5-.886 1.021a1.03 1.03 0 0 0 1.313.992l.048-.02a3.54 3.54 0 0 0 1.766-1.224V11.998H12l-.005-6.01c0-.885-.714-1.601-1.595-1.601-.673 0-1.25.418-1.483 1.01l-.009.01a3.534 3.534 0 0 1-2.293 1.466h-.051z'
      />
    </SvgIcon>
  );
};

PickupIcon.defaultProps = {};

export default PickupIcon;
