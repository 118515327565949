import { useSelector } from 'react-redux';

import { useFormatMessage } from '../../../intl/intl';
import { selectors as partnerSelectors } from '../../../redux/partner';
import { DEACTIVATED_PRODUCTS_NOTIFICATIONS } from '../constants';
import { selectors as deactivatedProductsDialogSelectors } from '../../../redux/deactivated-products-dialog';

export function useDeactivatedProductsDialogTexts() {
  const deactivatedProductsByStore = useGetDeactivatedProductsByStore();
  const totalDeactivatedProducts = deactivatedProductsByStore?.reduce(
    (acc, cur) => ({
      products: acc.products + cur.products,
      toppings: acc.toppings + cur.toppings,
    }),
    { products: 0, toppings: 0 },
  );

  const titleEmptyText = useFormatMessage({
    id: `${DEACTIVATED_PRODUCTS_NOTIFICATIONS}.title.emptyList`,
  });
  const titleFullText = useFormatMessage({
    id: `${DEACTIVATED_PRODUCTS_NOTIFICATIONS}.title.fullList`,
  });
  const disclaimerEmptyText = useFormatMessage({
    id: `${DEACTIVATED_PRODUCTS_NOTIFICATIONS}.disclaimer.emptyList`,
  });
  const disclaimerFullText = useFormatMessage(
    {
      id: `${DEACTIVATED_PRODUCTS_NOTIFICATIONS}.disclaimer.fullList`,
    },
    {
      products: totalDeactivatedProducts?.products,
      toppings: totalDeactivatedProducts?.toppings,
    },
  );
  const imReadyText = useFormatMessage({
    id: `${DEACTIVATED_PRODUCTS_NOTIFICATIONS}.button.imReady`,
  });
  const manageMenuText = useFormatMessage({
    id: `${DEACTIVATED_PRODUCTS_NOTIFICATIONS}.button.manageMenu`,
  });
  const manageProductsText = useFormatMessage({
    id: `${DEACTIVATED_PRODUCTS_NOTIFICATIONS}.button.manageProducts`,
  });
  const doLaterText = useFormatMessage({
    id: `${DEACTIVATED_PRODUCTS_NOTIFICATIONS}.button.doLater`,
  });
  const productsLabel = useFormatMessage({
    id: `${DEACTIVATED_PRODUCTS_NOTIFICATIONS}.productsLabel`,
  });
  const toppingsLabel = useFormatMessage({
    id: `${DEACTIVATED_PRODUCTS_NOTIFICATIONS}.toppingsLabel`,
  });
  const welcomeText = useFormatMessage({
    id: `${DEACTIVATED_PRODUCTS_NOTIFICATIONS}.welcomeText`,
  });

  const title = useHasNoProductsToShow() ? titleEmptyText : titleFullText;
  const disclaimer = useHasNoProductsToShow()
    ? disclaimerEmptyText
    : disclaimerFullText;
  const actionButton = useHasNoProductsToShow()
    ? imReadyText
    : manageProductsText;
  const cancelButton = useHasNoProductsToShow() ? manageMenuText : doLaterText;

  return {
    title,
    disclaimer,
    isOkToRender: Boolean(deactivatedProductsByStore),
    disclaimerNumberProducts: totalDeactivatedProducts?.products,
    disclaimerNumberToppings: totalDeactivatedProducts?.toppings,
    actionButton,
    cancelButton,
    productsLabel,
    toppingsLabel,
    welcomeText,
  };
}

export function useGetDeactivatedProductsByStore() {
  const deactivatedProductsByStore = useSelector(
    partnerSelectors.selectDeactivatedProducts,
  );
  const stores = useSelector(partnerSelectors.selectStores);
  deactivatedProductsByStore?.forEach(element => {
    element.storeName = stores.find(
      (store: any) => store.id === element.storeId,
    )?.name;
  });
  return deactivatedProductsByStore;
}

export function useHasMoreThanOneStore() {
  const storesNumber = useGetDeactivatedProductsByStore();
  return storesNumber?.length > 1;
}

export function useHasNoProductsToShow() {
  const deactivatedProductsByStore = useGetDeactivatedProductsByStore();
  return deactivatedProductsByStore?.length === 0;
}

export function useOpen() {
  return useSelector(deactivatedProductsDialogSelectors.selectOpen);
}
