import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import CustomDialog from '../../../common/custom-dialog/CustomDialog';
import dangerIcon from '../../../../assets/images/icons/dangerIcon.svg';
import CustomButton, {
  Types,
  Variants,
} from '../../../common/buttons/custom-button';
import { OrderMgmtUndoChangesDialogStyles } from './OrderMgmtUndoChangesDialog.style';

export const OrderMgmtUndoChangesDialog = ({
  isOpen,
  undoChangesTitleText,
  undoChangesDescriptionText,
  undoChangesCancelText,
  undoChangesOkText,
  undoChangesDisclaimerTexts,
  handleConfirmUndoChanges,
  handleUndoChangesDialogClose,
}: Props) => {
  const componentClasses = OrderMgmtUndoChangesDialogStyles();
  const theme = useTheme<any>();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <CustomDialog
      classes={{
        paper: componentClasses.paper,
        headerRoot: componentClasses.undoChangesDialogHeader,
      }}
      onClose={handleUndoChangesDialogClose}
      open={isOpen}
      showBackButton={!isMdUp}
      showCloseButton={isMdUp}
    >
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        padding='1rem'
        width='100%'
        height='100%'
      >
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          marginBottom='1.5rem'
          textAlign='center'
          gridGap='0.5rem'
        >
          <Typography className={componentClasses.undoChangesDialogHeaderTitle}>
            {undoChangesTitleText}
          </Typography>
          <Typography
            className={componentClasses.undoChangesDialogHeaderDescription}
            dangerouslySetInnerHTML={{ __html: undoChangesDescriptionText }}
          />
        </Box>
        <Box
          className={componentClasses.undoChangesDialogDisclaimerContent}
          display='flex'
          alignItems='center'
          gridGap='0.5rem'
          padding='0.5rem'
          marginBottom='1rem'
          width='100%'
        >
          <img
            className={componentClasses.undoChangesDialogDisclaimerIcon}
            width={20}
            height={20}
            alt='danger-icon'
            src={dangerIcon}
          />
          <ul className={componentClasses.undoChangesDialogDisclaimerItems}>
            {undoChangesDisclaimerTexts.map((disclaimer, idx) => (
              <li
                key={`dc-${idx}`}
                dangerouslySetInnerHTML={{ __html: disclaimer }}
              />
            ))}
          </ul>
        </Box>
        <Box
          display='flex'
          flex={1}
          alignItems='center'
          justifyContent='flex-end'
          gridGap='1rem'
          width='100%'
        >
          <CustomButton
            className={componentClasses.undoChangesDialogCancel}
            onClick={handleUndoChangesDialogClose}
            type={Types.RESET}
            variant={Variants.TEXT}
            fullWidth={false}
          >
            {undoChangesCancelText}
          </CustomButton>
          <CustomButton
            className={componentClasses.undoChangesDialogOk}
            onClick={handleConfirmUndoChanges}
            type={Types.BUTTON}
            variant={Variants.CONTAINED}
            fullWidth={false}
          >
            {undoChangesOkText}
          </CustomButton>
        </Box>
      </Box>
    </CustomDialog>
  );
};

interface Props {
  isOpen: boolean;
  undoChangesTitleText: string;
  undoChangesDescriptionText: string;
  undoChangesCancelText: string;
  undoChangesOkText: string;
  undoChangesDisclaimerTexts: Array<string>;
  handleConfirmUndoChanges: any;
  handleUndoChangesDialogClose: any;
}
