// External libs
import { createAction } from '@reduxjs/toolkit';

const closeAction = createAction('productSuggestionAvailabilityDialog/close');

const clearAction = createAction('productSuggestionAvailabilityDialog/clear');

const maximizeAction = createAction(
  'productSuggestionAvailabilityDialog/maximize',
);

const openAction = createAction<{
  orderId: number;
  productId: number;
  productName: string;
  selectedProduct: number[];
  refuseReason: string;
  refuseReasonsDescription: string;
}>('productSuggestionAvailabilityDialog/open');

const clear = () => (dispatch: any) => {
  dispatch(clearAction());
};

const close = () => (dispatch: any) => {
  dispatch(closeAction());
};

const maximize = () => (dispatch: any) => dispatch(maximizeAction());

const open = (
  orderId: number,
  productId: number,
  productName: string,
  selectedProduct: number[],
  refuseReason: string,
  refuseReasonsDescription: string,
) => (dispatch: any) => {
  dispatch(
    openAction({
      orderId,
      productId,
      productName,
      selectedProduct,
      refuseReason,
      refuseReasonsDescription,
    }),
  );
};
const actions = {
  clear,
  clearAction,
  close,
  closeAction,
  maximize,
  maximizeAction,
  open,
  openAction,
};

export default actions;
