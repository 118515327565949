// External libs
import { createSelector } from 'reselect';
// Selectors
import { Button, Variants } from '../../common/buttons/custom-button';
import { executeAction } from '../../../transpilers/dsl-transpiler/dslDispatch';
import { Action } from '../../../transpilers/dsl-transpiler';
import {
  Button as ButtonData,
  actions as dynAlertSnackbarActions,
  selectors as dynAlertSnackbarSelectors,
} from '../../../redux/dyn-alert-snackbar';
import appStore from '../../../redux/stores/app.store';
import eventActions from '../../../redux/actions/event-actions';

const selectButtons = createSelector<
  any,
  string | null,
  ButtonData[],
  Button[]
>(
  dynAlertSnackbarSelectors.selectId,
  dynAlertSnackbarSelectors.selectButtons,
  (id, buttons) => {
    const dispatch = appStore.getDispatch();
    return buttons.map<Button>((button, index) => {
      return {
        onClick: async () => {
          !!button.onClick && (await executeAction(button.onClick)(dispatch));
          eventActions.onClickDynActionCTA('ALERT_SNACKBAR', id, index);
          dynAlertSnackbarActions.close()(dispatch);
        },
        styleRules: button.styleRules,
        text: button.textContent,
        variant: Variants.CONTAINED,
      };
    });
  },
);

const selectOnClose = createSelector<
  any,
  string | null,
  Action | null,
  () => Promise<void>
>(
  dynAlertSnackbarSelectors.selectId,
  dynAlertSnackbarSelectors.selectOnClose,
  (id, onClose) => {
    const dispatch = appStore.getDispatch();
    return async () => {
      !!onClose && (await executeAction(onClose)(dispatch));
      eventActions.onCloseDynActionCTA('ALERT_SNACKBAR', id);
      dynAlertSnackbarActions.close()(dispatch);
    };
  },
);

const selectors = { selectButtons, selectOnClose };

export default selectors;
