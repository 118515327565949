import { useFormatMessage } from '../../../../../intl/intl';
import { hooks as orderManagementHooks } from '../../../hooks';

const useText = () => {
  const messageCategory = 'orderDetailDialogActionsBox';
  return {
    acceptButtonText: useFormatMessage({
      id: `${messageCategory}.acceptButtonText`,
    }),
    rejectButtonText: useFormatMessage({
      id: `${messageCategory}.rejectButtonText`,
    }),
    editOrderText: useFormatMessage({
      id: `${messageCategory}.editOrderText`,
    }),
    offerReplaceText: useFormatMessage({
      id: `${messageCategory}.offerReplaceText`,
    }),
    newFeatureText: useFormatMessage({
      id: `${messageCategory}.newFeatureText`,
    }),
    undoChangesText: useFormatMessage({
      id: `${messageCategory}.undoChangesText`,
    }),
    acceptWithChangesButtonText: useFormatMessage({
      id: `${messageCategory}.acceptWithChangesButtonText`,
    }),
  };
};

const useIsOrderEnabledToEdit = () => {
  const orderDetail = orderManagementHooks.useOrderDetail();
  return !!orderDetail?.is_enabled;
};

export const hooks = {
  useText,
  useIsOrderEnabledToEdit,
};
