import { clsx } from 'clsx';
import { Link } from '@material-ui/core';

import useStyles from './styles';

import type { LinkProps } from '@material-ui/core';
import type { BaseProps } from '../../../interfaces';

export function CustomLink({ classes = {}, ...extrsProps }: Props) {
  const _classes = useStyles();
  return (
    <Link
      classes={{ root: clsx(_classes.root, classes?.root) }}
      {...extrsProps}
    />
  );
}

type Props = BaseProps & LinkProps;
