import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../shared/styles/colors';

export default makeStyles({
  icon: {
    color: neutral['90'],
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
  },
  root: {
    width: '114px',
  },
  select: {
    border: `solid 1px ${neutral['20']}`,
    color: neutral['90'],
    fontSize: '1rem',
    padding: '14px 25px 14px 10px',
  },
});
