import { useForm, Controller } from 'react-hook-form';
import { Box, TextField, MenuItem, Typography } from '@material-ui/core';

import { CurrencyTextField } from '../../../../shared/components/currency-text-field';
import { InputPlacesAutocomplete } from '../../../../components/input-places-autocomplete';

import { useForm as useCargoForm, useRequestLang } from './hooks';

import makeClasses from './request.styles';
import { CustomPhoneField } from '../../../../shared/components';

export function Form() {
  const {
    data,
    countryCode,
    stores,
    setStore,
    setClientName,
    setPhoneNumber,
    setDeliveryAddress,
    setDeliveryReferences,
    setTotalValue,
    registerOptions,
  } = useCargoForm();

  const {
    store,
    clientName = '',
    phoneNumber,
    deliveryReferences = '',
    deliveryAddress,
    totalValue,
  } = data;

  const _classes = makeClasses();

  const {
    pickupPointLabelText,
    userNameLabelText,
    phoneNumberLabelText,
    deliveryAddressLabelText,
    addressDetailLabelText,
    totalValueLabelText,
    fieldsRequiredText,
  } = useRequestLang();

  /*
    TODO: consider removing individual 'set.*' handlers. Currently, we are
     using a mixed approach of controlled and uncontrolled forms, which may
     limit the effectiveness of react-hook-form. By individually handling each
     input, we lose some of the advantages that react-hook-form provides.
   */
  const {
    register,
    formState: { errors },
    control,
  } = useForm({ mode: 'all' });

  return (
    <Box
      component='form'
      display='flex'
      flexDirection='column'
      gridGap='0.725rem'
      fontSize='1rem'
    >
      <TextField
        {...register('pickupPoint', registerOptions.pickupPoint)}
        value={store ? store?.id : ''}
        label={pickupPointLabelText}
        variant='outlined'
        onChange={e => setStore(+e.target.value)}
        required
        select
        error={Boolean(errors?.pickupPoint)}
        helperText={errors?.pickupPoint?.message}
      >
        {stores.map(option => (
          <MenuItem key={`s-${option.id}`} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      {store?.address && (
        <Typography className={_classes.addressLabel}>
          {store?.address}
        </Typography>
      )}
      <TextField
        {...register('userName', registerOptions.userName)}
        disabled={false}
        label={userNameLabelText}
        onChange={e => setClientName(e.target.value)}
        type={'text'}
        value={clientName}
        variant='outlined'
        required
        error={Boolean(errors?.userName)}
        helperText={errors?.userName?.message}
      />
      <Controller
        name='phoneNumber'
        control={control}
        rules={registerOptions.phoneNumber}
        render={({ field: { onChange } }) => {
          const handleChange = value => {
            onChange(value);
            setPhoneNumber(value);
          };
          return (
            <CustomPhoneField
              label={phoneNumberLabelText}
              error={Boolean(errors?.phoneNumber)}
              helperText={errors?.phoneNumber?.message}
              country={countryCode}
              value={phoneNumber}
              onChange={handleChange}
              variant='outlined'
              required
            />
          );
        }}
      />
      <Controller
        name='deliveryAddress'
        control={control}
        rules={registerOptions.deliveryAddress}
        render={({
          field: { onChange, onBlur },
          fieldState: { isTouched },
        }) => {
          const handleChange = value => {
            onChange(value);
            setDeliveryAddress(value);
          };
          return (
            <InputPlacesAutocomplete
              defaultValue={deliveryAddress?.completeAddress}
              onChange={handleChange}
              // disabled={false}
              label={deliveryAddressLabelText}
              variant='outlined'
              required
              error={Boolean(errors?.deliveryAddress)}
              helperText={errors?.deliveryAddress?.message}
              onBlur={onBlur}
              isTouched={isTouched}
            />
          );
        }}
      />
      <TextField
        {...register('deliveryReferences', registerOptions.deliveryReferences)}
        disabled={false}
        label={addressDetailLabelText}
        onChange={e => setDeliveryReferences(e.target.value)}
        type={'text'}
        value={deliveryReferences}
        variant='outlined'
        error={Boolean(errors?.deliveryReferences)}
        helperText={errors?.deliveryReferences?.message}
      />
      <Controller
        name='totalValue'
        control={control}
        rules={registerOptions.totalValue}
        render={({ field: { onChange, onBlur } }) => {
          const handleChange = value => {
            onChange(value);
            setTotalValue(value);
          };
          return (
            <CurrencyTextField
              label={totalValueLabelText}
              variant='outlined'
              value={totalValue || ''}
              currencySymbol='$'
              onChange={handleChange}
              required
              error={Boolean(errors?.totalValue)}
              helperText={errors?.totalValue?.message}
              onBlur={onBlur}
            />
          );
        }}
      />
      <Typography className={_classes.fieldsRequired}>
        {fieldsRequiredText}
      </Typography>
    </Box>
  );
}
