// External libs
import produce from 'immer';
import { handleActions } from 'redux-actions';
// Consts
import { snackBarVariants } from '../../components/common/custom-snackbar';

import AppActions from '../actions/app-actions';
// Create a reducer with an empty initial state
const initialState = {
  isOffline: false,
  isWelcomeDialogOpened: false,
  language: 'es',
  newUpdateOnReload: false,
  countryFlag: null,
  countryCode: null,
  snackBar: {
    message: '',
    open: false,
    title: '',
    variant: snackBarVariants.INFO,
    autoHideDuration: null,
  },
};

export default handleActions(
  {
    [AppActions.closeSnackbarAction]: state => closeSnackBar(state),
    [AppActions.closeWelcomeDialogAction]: state =>
      toggleWelcomeDialog(false, state),
    [AppActions.loadCountryFlagFromLocalStorageAction]: (
      state,
      { payload: { countryFlag } },
    ) => setCountryFlag(state, countryFlag),
    [AppActions.openSnackbarErrorAction]: (
      state,
      { payload: { message, title, autoHideDuration } },
    ) => openSnackBarError(message, title, autoHideDuration, state),
    [AppActions.openSnackbarInfoAction]: (
      state,
      { payload: { message, title, autoHideDuration } },
    ) => openSnackBarInfo(message, title, autoHideDuration, state),
    [AppActions.openSnackbarSuccessAction]: (
      state,
      { payload: { message, title, autoHideDuration } },
    ) => openSnackBarSuccess(message, title, autoHideDuration, state),
    [AppActions.openSnackbarWarningAction]: (
      state,
      { payload: { message, title, autoHideDuration } },
    ) => openSnackBarWarning(message, title, autoHideDuration, state),
    [AppActions.setLanguageAction]: (state, { payload: { language } }) =>
      setLanguage(language, state),
    [AppActions.loadCountryCodeFromLocalStorageAction]: (
      state,
      { payload: { countryCode } },
    ) => setCountryCode(state, countryCode),
    [AppActions.setNewUpdateOnReloadAction]: state =>
      setNewUpdateOnReload(state),
    [AppActions.setOfflineAction]: state => toggleOffline(true, state),
    [AppActions.setOnlineAction]: state => toggleOffline(false, state),
    [AppActions.openWelcomeDialogAction]: state =>
      toggleWelcomeDialog(true, state),
  },
  initialState,
);

const closeSnackBar = state => {
  if (state.newUpdateOnReload) {
    return openSnackBarNewUpdateOnReload(state);
  }
  return {
    ...state,
    snackBar: {
      message: '',
      open: false,
      title: '',
      variant: snackBarVariants.INFO,
      autoHideDuration: null,
    },
  };
};

const openSnackBarError = (message, title, autoHideDuration, state) => {
  return openSnackBar(
    message,
    true,
    title,
    snackBarVariants.ERROR,
    autoHideDuration,
    state,
  );
};

const openSnackBarNewUpdateOnReload = state => {
  // The message is set on the component by default.
  return openSnackBar(
    null,
    true,
    null,
    snackBarVariants.NEW_UPDATE_ON_RELOAD,
    state,
  );
};

const openSnackBarInfo = (message, title, autoHideDuration, state) => {
  return openSnackBar(
    message,
    true,
    title,
    snackBarVariants.INFO,
    autoHideDuration,
    state,
  );
};

const openSnackBarSuccess = (message, title, autoHideDuration, state) => {
  return openSnackBar(
    message,
    true,
    title,
    snackBarVariants.SUCCESS,
    autoHideDuration,
    state,
  );
};

const openSnackBarWarning = (message, title, autoHideDuration, state) => {
  return openSnackBar(
    message,
    true,
    title,
    snackBarVariants.WARNING,
    autoHideDuration,
    state,
  );
};

const openSnackBar = (
  message,
  open,
  title,
  variant,
  autoHideDuration,
  state,
) => {
  return {
    ...state,
    snackBar: {
      message,
      open,
      title,
      variant,
      autoHideDuration,
    },
  };
};

const setLanguage = (language, state) => {
  return {
    ...state,
    language: language,
  };
};

const setCountryFlag = (state, countryFlag) => {
  return produce(state, stateDraft => {
    stateDraft.countryFlag = countryFlag;
  });
};

const setCountryCode = (state, countryCode) => {
  return produce(state, stateDraft => {
    stateDraft.countryCode = countryCode;
  });
};

/**
 * Sets the state to new update on reload, and shows the corresponding snackbar.
 * @function setNewUpdateOnReload
 * @param {object} state - current state.
 */
const setNewUpdateOnReload = state => {
  // Set the app state showing that a new update is available on reload.
  const newUpdateOnReloadState = {
    ...state,
    newUpdateOnReload: true,
  };

  // Update the state showing the corresponding snackbar.
  return openSnackBarNewUpdateOnReload(newUpdateOnReloadState);
};

/**
 * Sets whether the app is offline or not.
 * @param {bool} isOffline - offline state to set.
 * @param {object} state - Current state.
 */
const toggleOffline = (isOffline, state) => {
  return { ...state, isOffline };
};

/**
 * Sets whether the welcome dialog is opened or not.
 * @param {bool} isWelcomeDialogOpened - welcome dialog state to set.
 * @param {object} state - Current state.
 */
const toggleWelcomeDialog = (isWelcomeDialogOpened, state) => {
  return { ...state, isWelcomeDialogOpened };
};
