// Material UI
import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  description: {
    fontSize: 15,
  },
  label: {},
  root: {
    display: 'grid',
    fontFamily: 'Poppins',
    fontSize: 17,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    gridTemplateRows: 'min-content min-content',
    gridRowGap: 24,
    height: '100%',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    padding: '24px 22px 0px 24px',
  },
  timeSelectorContainer: {
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: 'min-content min-content',
    gridColumnGap: 24,
  },
}))
