import { useSelector } from 'react-redux';

import selectors from '../selectors';
import { selectors as stockOutIssueSuggestionsDialogSelectors } from '../../../redux/stock-out-issue-suggestions-dialog';

const useDescription = () => {
  return useSelector(stockOutIssueSuggestionsDialogSelectors.selectDescription);
};

const useOpen = () => {
  return useSelector(stockOutIssueSuggestionsDialogSelectors.selectOpen);
};

const useOptions = () => {
  return useSelector(selectors.selectOptions);
};

const useTitle = () => {
  return useSelector(selectors.selectTitle);
};

const hooks = {
  useDescription,
  useOpen,
  useOptions,
  useTitle,
};

export default hooks;
