import React from 'react'
import { Props } from './interfaces'

const SearchIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.226 3.24a5.563 5.563 0 0 0-3.96-1.64 5.563 5.563 0 0 0-3.96 1.64 5.563 5.563 0 0 0-1.64 3.96c0 1.495.583 2.902 1.64 3.96a5.563 5.563 0 0 0 3.96 1.64 5.563 5.563 0 0 0 3.96-1.64 5.564 5.564 0 0 0 1.64-3.96 5.563 5.563 0 0 0-1.64-3.96zm-3.96 7.96c-2.205 0-4-1.795-4-4 0-2.206 1.795-4 4-4 2.206 0 4 1.794 4 4 0 2.205-1.794 4-4 4zm6.975 1.861-2.577-2.565c-.203-.204-.75-.051-.954.152-.204.203-.349.754-.146.957l2.57 2.563c.243.232.838.354 1.158.053.297-.28.17-.921-.051-1.16z'
      fill='#969DA8'
    />
  </svg>
)

export default SearchIcon
