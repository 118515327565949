// React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Actions
import AppActions from './../../redux/actions/app-actions'
import menuActions from '../../redux/actions/menu.actions'
// Components
import MenuFilterDrawer from './MenuFilterDrawer'

const mapStateToProps = (
  { menuReducer, partnerReducer },
  { intl: { formatMessage } },
) => {
  return {
    currentFilter: menuReducer.filter,
    open: menuReducer.filter.open,
    stores: partnerReducer.profile.stores,
  }
}
const mapDispatchToProps = {
  applyFilter: menuActions.redirectMenuFilter,
  onClearFilter: menuActions.clearMenuFilter,
  onClose: menuActions.closeMenuFilterDrawer,
  openSnackBarError: AppActions.openSnackBarError,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(MenuFilterDrawer),
)
