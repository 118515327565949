import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';

import SuccessfulProxySessionDialog from './SuccessfulProxySessionDialog';

const mapStateToProps = (
  { successfulProxySessionDialogReducer: { open, orderId, callTo, callFrom } },
  { intl: { formatMessage } },
) => {
  const title = formatMessage({ id: 'successfulProxySessionDialog.title' });
  const description = formatMessage(
    {
      id: 'successfulProxySessionDialog.dialogDescription',
    },
    {
      order_id: orderId,
    },
  );
  const noteForUser = formatMessage({
    id: 'successfulProxySessionDialog.noteForUser',
  });
  const callToText = formatMessage({
    id: 'successfulProxySessionDialog.callTo',
  });
  const callFromText = formatMessage({
    id: 'successfulProxySessionDialog.callFrom',
  });

  return {
    open,
    title,
    description,
    orderId,
    noteForUser,
    callToText,
    callFromText,
    callTo,
    callFrom,
  };
};

const mapDispatchToProps = {
  onBack: dialogsManagerActions.back,
  onClose: dialogsManagerActions.closeSuccessfulProxySessionDialog,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SuccessfulProxySessionDialog),
);
