import { clsx } from 'clsx';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';

import { hooks } from './hooks';
import { StoreStatusBadge } from '../store-status-badge';

import { dialogStoreSuspended } from '../../../shared/services';
import EventActions from '../../../redux/actions/event-actions/event.actions';
import dialogsManagerActions from '../../../redux/actions/dialogsManager.actions';
import partnerActions from '../../../redux/actions/partners-actions/partners.actions';
import storesSchedulesActions from '../../../redux/actions/storesSchedules.actions';

import {
  editScheduleArticleId,
  editScheduleCategoryId,
  infoArticleId,
  infoCategoryId,
  storeCtaOpts,
  storeStatusStatusOpts as SSSOpts,
} from '../constants';
import Paths from '../../../constants/Paths';
import useCStyles from './StoreStatusCard.styles';

import type { StoreStatusCardProps as Props } from '../types';

// +++++ StoreStatusCard
export function StoreStatusCard(props: Props): JSX.Element {
  const {
    partnerId,
    storeId,
    storeName = '---',
    status = SSSOpts.INACTIVE.status,
    msg1,
    msg2,
    cta = storeCtaOpts['cta.deactivate'].cta,
    ctaUiMode,
    growUp = false,
    isSingleStore = false,
  } = props;

  const needsLoadingByCTA = [
    storeCtaOpts['cta.deactivate'].cta,
    storeCtaOpts['cta.activate'].cta,
  ].includes(cta);

  const texts = hooks.useTexts({ cta });
  const extendClosingHoursCTALabel = hooks.useExtendClosingHoursCTALabel(
    status,
  );
  const isLoading = hooks.useIsLoadingActiveOrDeactivate();
  const canExtendClosingHours = hooks.useCanExtendClosingHours(status);

  const classes = useCStyles({ growUp, ctaUiMode });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matchUrl = useMatch(Paths.STORE_MANAGEMENT);

  const handleCta = event => {
    event.preventDefault();
    if (cta === storeCtaOpts['cta.deactivate'].cta) {
      const payload = { storeIds: [storeId], enabled: false };
      const deactivatedAction = isSingleStore
        ? partnerActions.openDeactivatePartnerDialog()
        : partnerActions.switchPartnerStoresStatus(payload);
      dispatch(deactivatedAction);
    } else if (cta === storeCtaOpts['cta.activate'].cta) {
      const payload = { storeIds: [storeId], enabled: true };
      dispatch(partnerActions.switchPartnerStoresStatus(payload));
    } else if (cta === storeCtaOpts['cta.contact-support'].cta) {
      dispatch(dialogsManagerActions.openSupportDialog());
    } else if (cta === storeCtaOpts['cta.edit-schedules'].cta) {
      navigate(
        Paths.goToHelpCenterWithCategoryAndArticle(
          editScheduleCategoryId,
          editScheduleArticleId,
        ),
      );
    } else if (cta === storeCtaOpts['cta.info'].cta) {
      navigate(
        Paths.goToHelpCenterWithCategoryAndArticle(
          infoCategoryId,
          infoArticleId,
        ),
      );
    } else if (cta === storeCtaOpts['cta.desuspended'].cta) {
      dialogStoreSuspended.open.dispatch({ storeId });
    }
    if (storeCtaOpts[cta]?.amplitud?.code) {
      EventActions.onClickStoreStatusCardCTA({
        storeId,
        partnerId,
        onStoreAdminPage: !!matchUrl?.pathname,
        ctaType: storeCtaOpts[cta].amplitud?.code,
      });
    }
  };

  const handleOpenExtendClosingHoursDialog = async () => {
    dispatch(dialogsManagerActions.openExtendClosingHoursDialog(storeId));
    await dispatch(storesSchedulesActions.applyStoresSchedulesFilter(storeId));
    EventActions.onExtendedSchedules({ storeId, partnerId });
  };

  return (
    <Card
      variant='outlined'
      component='article'
      classes={{ root: classes.card }}
    >
      <CardHeader
        classes={{ root: classes.cardHeader }}
        avatar={<StoreStatusBadge status={status} />}
      />

      <CardContent classes={{ root: classes.cardContent }}>
        <Typography component={'h1'} classes={{ root: classes.storeName }}>
          {storeName}
        </Typography>
        <Typography role='paragraph' classes={{ root: classes.messages }}>
          {msg1}
        </Typography>
        <Divider classes={{ root: classes.divider }} />
        <Typography
          role='paragraph'
          classes={{
            root: clsx(classes.messages, { [classes.msgTextHide]: !msg2 }),
          }}
        >
          {msg2 || '-'}
        </Typography>
      </CardContent>

      <CardActions classes={{ root: classes.cardActions }}>
        <Button
          size='large'
          color='primary'
          variant='outlined'
          onClick={handleCta}
          disabled={isLoading}
          classes={{ root: classes.cta }}
          endIcon={
            isLoading && needsLoadingByCTA && <CircularProgress size='1rem' />
          }
        >
          <Typography>{texts.cta}</Typography>
        </Button>
        {canExtendClosingHours && (
          <Button
            size='large'
            color='primary'
            variant='outlined'
            onClick={handleOpenExtendClosingHoursDialog}
            disabled={isLoading}
            classes={{ root: classes.cta }}
          >
            <Typography>{extendClosingHoursCTALabel}</Typography>
          </Button>
        )}
      </CardActions>
    </Card>
  );
}
// ----- StoreStatusCard
