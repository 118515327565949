import { makeStyles } from '@material-ui/core';

import { neutral, red, teal } from '../../shared/styles/colors';
import { turboCreationAtColor } from '../../shared/styles/colors/feat';
import { ELEVATED } from '../../shared/styles/shadows';

export default makeStyles({
  article: ({ orderCreationStatus }: Props) => ({
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.5rem',
    borderRadius: '0.5rem',
    border: `1px solid ${teal['10']}`,
    borderColor: turboCreationAtColor[orderCreationStatus] || teal['10'],
    backgroundColor: 'white',
    boxShadow: ELEVATED.strong,
    cursor: 'pointer',
  }),
  bounceIn: {
    animationName: '$bounceIn',
    animationDuration: '1s',
    animationFillMode: 'both',
  },
  orderInfoById: {
    display: 'flex',
    columnGap: '0.5rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 'inherit',
    '&:first-child:not(:only-child)': {
      minHeight: '2.75rem',
    },
    '&:nth-child(n + 2)': {
      minHeight: '3.25rem',
      marginTop: '0.5rem',
      paddingTop: '0.5rem',
      borderTop: `1px solid ${teal['10']}`,
    },
  },
  rtSection: {
    margin: '0.5rem 0',
    padding: '0.5rem 0',
    display: 'flex',
    columnGap: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${neutral['20']}`,
    borderRight: 'none',
    borderLeft: 'none',
  },
  etaStepMsg: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    textWrap: 'balance',
    color: neutral['70'],
  },
  etaStepMsgStrong: {
    fontWeight: 600,
    color: neutral['80'],
  },
  remainingOrders: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    color: neutral['70'],
  },
  turboTag: {
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'translate(-1px, -1px)',
  },
  '@keyframes bounceIn': {
    from: {
      opacity: 0,
      transform: 'scale3d(.3, .3, .3)',
    },
    '20%': {
      transform: 'scale3d(1.1, 1.1, 1.1)',
    },
    '40%': {
      transform: 'scale3d(.9, .9, .9)',
    },
    '60%': {
      opacity: 1,
      transform: 'scale3d(1.03, 1.03, 1.03)',
    },
    '80%': {
      transform: 'scale3d(.97, .97, .97)',
    },
    to: {
      opacity: 1,
      transform: 'scale3d(1, 1, 1)',
    },
  },
  orderUpdateDetailAlertText: {
    color: red[60],
    fontSize: '0.75rem',
    fontWeight: 600,
  }, 
  orderUpdateDetailAlertIcon: {
    color: red[60]
  }
});

interface Props {
  orderCreationStatus: string;
}
