// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  body: {
    height: '100%',
    paddingTop: 24,
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
    },
  },
  headerRoot: {
    [theme.breakpoints.up('md')]: {
      padding: 12,
    },
  },
  customNumberSelectorBlockRoot: {
    borderTop: `1px solid ${neutral.D10}`,
    [theme.breakpoints.up('md')]: {
      padding: '40px 40px 0px 40px',
      borderTop: `none`,
    },
  },
  customNumberSelectorBlockTitle: {
    margin: 0,
    padding: '0px 16px',
  },
  paper: {
    height: 350,
    width: 600,
  },
  root: {},
}));
