import { clsx } from 'clsx';
import { SwipeableDrawer } from '@material-ui/core';

import { Variants } from './consts';
import { Anchors } from '../../../constants/Anchors';
import { ZIndexType } from '../../../constants/ZIndexType';

import { CustomBackButton } from '../buttons/custom-back-button';
import { CustomCloseButton } from '../buttons/custom-close-button';
import CustomLoader from '../custom-loader';

import useStyles from './styles';

import type { ReactNode } from 'react';
import type { BaseProps } from '../../../interfaces';

function CustomDrawer(props: Props) {
  const {
    anchor = Anchors.RIGHT,
    classes = {},
    children,
    disableDiscovery = true,
    isLoading = false,
    open = false,
    showBackButton = false,
    smWidth = '476px',
    swipeAreaWidth = 0,
    title,
    variant = Variants.TEMPORARY,
    xsWidth = '100%',
    onBack = () => {},
    onClose = () => {},
  } = props;

  const _classes = useStyles({ showBackButton, smWidth, xsWidth });

  return (
    <SwipeableDrawer
      classes={{
        paper: clsx(_classes.paper, classes?.paper),
        root: clsx(_classes.root, classes?.root),
      }}
      variant={variant}
      anchor={anchor}
      disableDiscovery={disableDiscovery}
      open={open}
      onOpen={() => {}}
      onClose={onClose}
      swipeAreaWidth={swipeAreaWidth}
    >
      {variant !== Variants.PERMANENT && (
        <div className={_classes.headerContainer}>
          <div className={_classes.titleContainer}>
            {showBackButton && <CustomBackButton onClick={onBack} />}
            <span className={_classes.title}>{title}</span>
          </div>
          <CustomCloseButton onClick={onClose} />
        </div>
      )}
      <div className={_classes.childrenContainer}>
        {children}
        <CustomLoader
          invisible
          open={isLoading}
          zIndexType={ZIndexType.DRAWER}
        />
      </div>
    </SwipeableDrawer>
  );
}

export default CustomDrawer;

interface Props extends BaseProps {
  anchor?: Anchors;
  disableDiscovery?: boolean;
  onBack?: () => void | Promise<void>;
  onClose: () => void | Promise<void>;
  open: boolean;
  isLoading?: boolean;
  smWidth?: string;
  swipeAreaWidth?: number;
  title?: string;
  variant?: Variants;
  xsWidth?: string;
  showBackButton?: boolean;
  children: ReactNode;
}
