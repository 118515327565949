import { MainKanbanCard } from '../main-kanban-card';
import OrderListChipBox from '../order-list-chip-box';
import { CustomKanbanColumn } from '../common/custom-kanban-column';

import { usePendingToDeliverColumn } from './hooks';

export function PendingToDeliverColumn() {
  const {
    text,
    ordersToDeliverOnePerBundle,
    numberOfOrdersInToDeliver,
    showEmptyTextOfOrders,
  } = usePendingToDeliverColumn();

  return (
    <CustomKanbanColumn
      Chip={<OrderListChipBox />}
      title={text.title}
      emptyText={text.noOrders}
      count={numberOfOrdersInToDeliver}
      showEmpty={showEmptyTextOfOrders}
    >
      {ordersToDeliverOnePerBundle.map(order => (
        <MainKanbanCard key={`to-deliver-${order.id}`} order={order} />
      ))}
    </CustomKanbanColumn>
  );
}
