// Redux
import { useSelector } from 'react-redux';
// Selectors
import NotificationsDrawerSelectors from '../../../redux/selectors/notifications-drawer-selectors';

const useOpen = () => {
  return useSelector(NotificationsDrawerSelectors.selectOpen);
};

const Hooks = { useOpen };

export default Hooks;
