import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Box, makeStyles } from '@material-ui/core';

export function ItemSkeleton() {
  const classes = makeClasses();
  return (
    <Box className={classes.skeleton}>
      <Skeleton variant='rect' width={42} height={42} />
      <Box flex={1}>
        <Skeleton variant='text' width={450} />
        <Skeleton variant='text' width={200} />
      </Box>
      <Skeleton variant='rect' width={17} height={17} />
    </Box>
  );
}

function makeClasses() {
  return makeStyles({
    skeleton: {
      width: '100%',
      padding: '1rem 1.5rem 1rem',
      display: 'flex',
      gap: '0.5rem',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })();
}
