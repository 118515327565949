// External libs
import { createActions } from 'redux-actions';

const {
  closePickupCodeDialogAction,
  openPickupCodeDialogAction,
} = createActions({
  CLOSE_PICKUP_CODE_DIALOG_ACTION: () => ({}),
  OPEN_PICKUP_CODE_DIALOG_ACTION: orderId => ({ orderId }),
});

const closePickupCodeDialog = () => dispatch => {
  return dispatch(closePickupCodeDialogAction());
};

const openPickupCodeDialog = orderId => dispatch => {
  return dispatch(openPickupCodeDialogAction(orderId));
};

export default {
  closePickupCodeDialogAction,
  closePickupCodeDialog,
  openPickupCodeDialogAction,
  openPickupCodeDialog,
};
