import { useSelector } from 'react-redux';

import { selectors as cancelByStockOutSuggestionConfirmationDialogSelectors } from '../../../redux/cancel-by-stock-out-suggestion-confirmation-dialog';
import selectors from '../selectors';

const useOpen = () => {
  return useSelector(
    cancelByStockOutSuggestionConfirmationDialogSelectors.selectOpen,
  );
};

const useTitle = () => {
  return useSelector(selectors.selectTitle);
};

const hooks = { useOpen, useTitle };

export default hooks;
