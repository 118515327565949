import history from '../history'

// Consts
import Paths from '../constants/Paths'

const getOrdersPath = () => {
  return Paths.ORDERS_KANBAN
}

const redirectToOrders = orderId => {
  const ordersPath = getOrdersPath(orderId)

  history.push(ordersPath)
}

const replaceToOrders = orderId => {
  const ordersPath = getOrdersPath(orderId)

  history.go(ordersPath)
}

export default { getOrdersPath, redirectToOrders, replaceToOrders }
