import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import makeClasses from './RadioGroupRowBoolean.style';

export function RadioGroupRowBoolean(props: Props) {
  const { name, firstLabel, secondLabel, value, onChange } = props;

  const _classes = makeClasses();

  return (
    <RadioGroup
      row
      aria-label={`option to ${name}`}
      name={`option-${name.replaceAll(' ', '-')}`}
      value={value?.toString()}
      onChange={event => onChange?.(event.target.value === 'true')}
      classes={{ row: _classes.radioGroup }}
    >
      <FormControlLabel
        value={'true'}
        label={firstLabel}
        labelPlacement='start'
        classes={{
          root: _classes.rootLabel,
          label: _classes.label,
        }}
        control={
          <Radio
            color='primary'
            classes={{
              root: _classes.radioRoot,
              colorPrimary: _classes.radioPrimaryColor,
            }}
          />
        }
      />
      <FormControlLabel
        value={'false'}
        label={secondLabel}
        labelPlacement='start'
        classes={{
          root: _classes.rootLabel,
          label: _classes.label,
        }}
        control={
          <Radio
            color='primary'
            classes={{
              root: _classes.radioRoot,
              colorPrimary: _classes.radioPrimaryColor,
            }}
          />
        }
      />
    </RadioGroup>
  );
}

interface Props {
  name: string;
  firstLabel: string;
  secondLabel: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
}
