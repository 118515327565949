// External libs
import { createSelector } from 'reselect';

const selectReducer = state => state.notificationsDrawerReducer;

const selectOpen = createSelector(
  selectReducer,
  reducer => reducer.open,
);

const NotificationsDrawerSelectors = { selectOpen };

export default NotificationsDrawerSelectors;
