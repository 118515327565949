import { Typography } from '@material-ui/core';

import { CheckInCodeRTRoot } from './comps';

import { useCheckInCodeRT } from './hooks';

import makeClasses from './CheckInCodeRT.styles';

import type { GlobalMode } from './shared/types';
import type { CheckInCodeRTModel } from './shared/models';

export function CheckInCodeRT(props?: Props) {
  const { checkinCode = '', expiredAt = '', globalMode = false } = props ?? {};
  const { lang, expiredAtFormatted } = useCheckInCodeRT({ expiredAt });
  const styles = makeClasses({ globalMode });
  return (
    <>
      {Boolean(checkinCode) && (
        <CheckInCodeRTRoot globalMode={globalMode} checkinCode={checkinCode}>
          <>
            {!Boolean(expiredAtFormatted) && null}
            {Boolean(expiredAtFormatted) && (
              <Typography classes={{ root: styles.expiredAtTextLine }}>
                {lang.expires}: {expiredAtFormatted}
              </Typography>
            )}
          </>
        </CheckInCodeRTRoot>
      )}
    </>
  );
}

interface Props extends CheckInCodeRTModel {
  globalMode?: GlobalMode;
}
