import { useDispatch, useSelector } from 'react-redux';

import dialogsManagerActions from '../../../../../redux/actions/dialogsManager.actions';
import OrderActions from '../../../../../redux/actions/orders-actions/orders.actions';
import OrderDetailDialogSelectors from '../../../../../redux/selectors/order-detail-dialog-selectors/orderDetailDialog.selectors';
import OrdersSelector from '../../../../../redux/selectors/orders-selectors/orders.selectors';
import { OrderMgmtProduct, OrderMgmtTopping } from '../../../domain/models';
import { OM_STATE_NAME } from '../../core/consts';
import {
  AcceptedOrderRequest,
  DisabledAutoTakeRequest,
  RejectedOrderRequest,
  RemoveToppingListDTO,
} from '../../core/requests';
import { stateOMType } from '../../core/responses';
import { OM_ORDER_MODIFICATION_SLICE_NAME } from '../order-modification';
import { OM_ORDER_DETAIL_SLICE_NAME, OM_OrderDetailState } from './core';
import { orderDetailThunks } from './orderDetail.thunks';
import { orderMgmtDetailActions } from './ordermgmt-detail.slice';

const _getState = (s: any): OM_OrderDetailState =>
  s[OM_STATE_NAME][OM_ORDER_DETAIL_SLICE_NAME];

export function orderMgmtDetailFacade() {
  const dispatch = useDispatch();

  const selectOrders = useSelector(OrdersSelector.selectOrders);
  const selectOrderId = useSelector(OrderDetailDialogSelectors.selectOrderId);
  const selectOrderDetail =
    {
      ...selectOrders.find(x => x?.id === selectOrderId)?.detail,
      storekeeper: {},
    } ?? {};
  const selectStoreId =
    selectOrderDetail?.stores && selectOrderDetail?.stores[0]?.store_id;

  const selectIsDisabled = useSelector((s: any) => _getState(s).isDisabled);

  const selectState = useSelector((s: any) => _getState(s).orderState);

  const selectProducts = useSelector((s: any) => _getState(s).products);
  const selectDetailProducts = useSelector(
    (s: any) => _getState(s).detailProducts,
  );

  const selectProductToRemove = useSelector(
    (s: any) => _getState(s).productIdList,
  );

  const selectToppingsToRemove = useSelector(
    (s: any) => _getState(s).toppingIdList,
  );

  const selectStatus = useSelector((s: any) => _getState(s).status);

  const selectOrderState = useSelector(
    (s: any) =>
      s[OM_STATE_NAME][OM_ORDER_MODIFICATION_SLICE_NAME].currentOrderState,
  );
  const selectIsCountdownComplete = useSelector(
    (s: any) => _getState(s).isCountdownComplete,
  );
  const selectItemsToRemove = useSelector(
    (s: any) => _getState(s).itemsToRemove,
  );
  const selectOpenDialog = useSelector((s: any) => _getState(s).isOpenDialog);

  const selectStartedAt = useSelector((s: any) => _getState(s).startedAt);

  const onDisabledByState = (
    orderState: stateOMType,
    partnerOrderState: string,
  ) => {
    const state = orderState === 'to_wait' || orderState === 'canceled';
    const isDisabled = state && partnerOrderState !== 'cooking';
    dispatch(orderMgmtDetailActions.disabled(isDisabled));
  };

  const onSetDisabled = (value: boolean) => {
    dispatch(orderMgmtDetailActions.disabled(value));
  };
  const onSetOrderState = (value: stateOMType) => {
    dispatch(orderMgmtDetailActions.setOrderState(value));
  };

  const onClearState = () => {
    dispatch(orderMgmtDetailActions.clear());
  };

  const onRemoveProducts = () => {
    dispatch(orderMgmtDetailActions.removeAllProducts());
  };

  const onRemoveProductsWithOM = () => {
    dispatch(orderMgmtDetailActions.removeProductsWithOM());
  };

  const onGetProductsWithOM = async () => {
    dispatch(orderMgmtDetailActions.getProductsWithOM());
  };

  const onAddProducts = (products: OrderMgmtProduct[]) => {
    dispatch(orderMgmtDetailActions.addProducts(products));
  };

  const onResetDetailProducts = () => {
    dispatch(orderMgmtDetailActions.resetDetailProducts());
  };

  const onAddToppingIdList = (toppings: RemoveToppingListDTO[]) => {
    dispatch(orderMgmtDetailActions.addToppingIdList(toppings));
  };

  const onSetOrderId = (orderId: number) => {
    dispatch(orderMgmtDetailActions.setOrderId(orderId));
  };

  const onAcceptOrder = async (
    orderId: number,
    payload: AcceptedOrderRequest,
  ) => {
    const request = { orderId, payload };
    dispatch(orderDetailThunks.acceptOrder(request));
    await onGetOrders();
  };

  const onRejectedOrder = async (
    orderId: number,
    payload: RejectedOrderRequest,
  ) => {
    const request = { orderId, payload };
    dispatch(orderDetailThunks.rejectedOrder(request));
    await onGetOrders();
  };

  const onGetOrders = async () => {
    await dispatch(OrderActions.getOrders());
  };
  const onDisabledAutoTake = async (payload: DisabledAutoTakeRequest) => {
    await dispatch(orderDetailThunks.disabledAutoTake(payload));
  };

  const onRemoveProductDetail = (productId: number) => {
    dispatch(orderMgmtDetailActions.removeProductDetail(productId));
  };
  const onRemoveToppingDetail = (productId: number, toppingId: number) => {
    dispatch(
      orderMgmtDetailActions.removeToppingDetail({ productId, toppingId }),
    );
  };
  const onSetIsCountdownComplete = (payload: boolean) => {
    dispatch(orderMgmtDetailActions.setIsCountdownComplete(payload));
  };
  const onAddProductToRemove = (payload: OrderMgmtProduct) => {
    dispatch(orderMgmtDetailActions.addProductToRemove(payload));
  };
  const onAddToppingToRemove = (payload: OrderMgmtTopping) => {
    dispatch(orderMgmtDetailActions.addToppingToRemove(payload));
  };

  const onOpenDialog = (value: boolean) => {
    dispatch(orderMgmtDetailActions.openDialog(value));
  };
  const closeDialog = () => dispatch(dialogsManagerActions.close());

  return {
    selectIsDisabled,
    selectState,
    selectProducts,
    selectOrderId,
    selectOrderDetail,
    selectStoreId,
    selectProductToRemove,
    selectToppingsToRemove,
    selectStatus,
    selectOrderState,
    selectIsCountdownComplete,
    selectDetailProducts,
    selectItemsToRemove,
    selectOpenDialog,
    selectStartedAt,
    onSetDisabled,
    onSetOrderState,
    onDisabledByState,
    onRemoveProducts,
    onRemoveProductsWithOM,
    onGetProductsWithOM,
    onClearState,
    onAcceptOrder,
    onRejectedOrder,
    onAddProducts,
    onSetOrderId,
    onAddToppingIdList,
    onDisabledAutoTake,
    onSetIsCountdownComplete,
    onRemoveProductDetail,
    onRemoveToppingDetail,
    onResetDetailProducts,
    onAddProductToRemove,
    onAddToppingToRemove,
    onOpenDialog,
    closeDialog,
  };
}
