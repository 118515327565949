import { CARGO_PATH } from '../core/consts';
import httpService from '../../../../services/HttpService';
import { generateURLRappiServices } from '../../../shared/infra';

import { CreateAddRTRequest, ValidateAddRTRequest } from '../core/requests';
import type {
  CreateAddRTResponse,
  ValidateAddRTResponse,
} from '../core/responses';

function validate(params: ValidateAddRTRequest) {
  const { orderId } = params;
  const fullUrl = generateURLRappiServices({
    url: CARGO_PATH.VALIDATE_ADD_RT,
    pathParams: { orderId },
  });
  return httpService.put<ValidateAddRTResponse>(fullUrl);
}

function create(params: CreateAddRTRequest) {
  const { payload } = params;
  const fullUrl = generateURLRappiServices({
    url: CARGO_PATH.CREATE_ORDER_ADD_RT,
  });
  return httpService.put<CreateAddRTResponse>(fullUrl, payload);
}

export const addRTRepository = {
  validate,
  create,
} as const;
