import { ordermgmtDialogFacade } from '../../../../../business/order-mgmt';

export const useOrderMgmtAcceptOrderDialog = () => {
  const {
    selectOrderMgmtAcceptOrderDialogIsOpen,
    onCloseOrderMgmtAcceptOrderDialog,
    onOpenOrderMgmtAcceptOrderDialog,
    onAcceptOrderMgmtButton,
  } = ordermgmtDialogFacade();

  return {
    isOpen: selectOrderMgmtAcceptOrderDialogIsOpen,
    onClose: onCloseOrderMgmtAcceptOrderDialog,
    onAcceptOrder: onAcceptOrderMgmtButton,
    onOpen: onOpenOrderMgmtAcceptOrderDialog,
  };
};
