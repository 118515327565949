//React
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
// Consts
import DeactivationType from '../../constants/DeactivationType'
// Actions
//import MenuActions from './../../redux/actions/menu.actions'
// Components
import MenuProductDeactivationOptions from './MenuProductDeactivationOptions'

const getProductDeactivationOptions = (
  dailyDeactivationLabel,
  permanentDeactivationLabel,
  profile,
  weeklyDeactivationLabel,
) => {
  const options = [
    {
      value: DeactivationType.DAILY,
      label: dailyDeactivationLabel,
    },
  ]
  options.push({
    value: DeactivationType.WEEKLY,
    label: weeklyDeactivationLabel,
  })
  options.push({
    value: DeactivationType.PERMANENT,
    label: permanentDeactivationLabel,
  })
  return options
}

const mapStateToProps = (
  { partnerReducer: { profile } },
  { intl: { formatMessage } },
) => {
  const dailyDeactivationLabel = formatMessage({
    id: 'productDeactivationTypes.daily.label',
  })
  const permanentDeactivationLabel = formatMessage({
    id: 'productDeactivationTypes.permanent.label',
  })
  const weeklyDeactivationLabel = formatMessage({
    id: 'productDeactivationTypes.weekly.label',
  })
  const options = getProductDeactivationOptions(
    dailyDeactivationLabel,
    permanentDeactivationLabel,
    profile,
    weeklyDeactivationLabel,
  )

  return { options, partnerId: profile.id }
}

const mapDispatchToProps = {}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(MenuProductDeactivationOptions),
)
