// Material UI
import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginBottom: 24,
    overflow: 'auto',
  },
  automaticCookingTimeSettingRoot: {
    margin: '16px 16px 0px 16px',
    [theme.breakpoints.up('md')]: {
      margin: '24px 24px 0px 24px',
    },
  },
}));
