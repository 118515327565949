// React
import { useMemo } from 'react';
// External libs
import produce from 'immer';
// Consts
import { Serverities } from '../../../../../../constants/Severities';

const useActions = (actions) =>
  useMemo(() => {
    if (!actions || !actions.length) {
      return [];
    }
    return produce(actions, (actionsDraft) => {
      for (const actionDraft of actionsDraft) {
        actionDraft.color = Serverities.WARNING;
      }
    });
  }, [actions]);

const Hooks = { useActions };

export default Hooks;
