import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../shared/styles/colors';

export default makeStyles({
  flag: {
    width: '2rem',
    gridArea: 'flag',
  },
  prefixNumber: {
    gridArea: 'prefix',
    fontSize: '1rem',
    fontWeight: 100,
  },
  root: {
    gridGap: '0.5rem',
    display: 'grid',
    placeContent: 'center',
    borderRadius: '0.5rem',
    padding: '1rem',
    border: `1px solid ${neutral['20']}`,
    gridTemplateAreas: `"flag prefix"`,
    cursor: 'not-allowed',
  },
});
