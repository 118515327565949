// Material UI
import { makeStyles } from '@material-ui/core';
// Consts

import { neutral } from '../../../../../shared/styles/colors';
import { StyleRules } from './interfaces';

export const useStyleRules = makeStyles({
  cancelButton: ({ styleRules }: { styleRules?: any }) => ({
    ...styleRules?.cancelButton,
  }),
  root: ({ styleRules }: { styleRules?: any }) => ({
    ...styleRules?.root,
  }),
  messageContainer: ({ styleRules }: { styleRules?: any }) => ({
    ...styleRules?.messageContainer,
  }),
});

export default makeStyles(theme => ({
  buttonsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gridArea: '2 / 1 / 3 / 3 ',
    '&>*': {
      marginTop: 8,
      '&:not(:last-child)': {
        paddingRight: 24,
      },
    },
    [theme.breakpoints.up('md')]: {
      alignContent: 'center',
      gridArea: 'auto',
      '&>*': {
        marginTop: 0,
      },
    },
  },
  buttonSubmitButton: {
    fontSize: '13px',
    lineHeight: 'normal',
    padding: '8px 16px',
  },
  cancelButton: {
    alignSelf: 'flex-start',
    gridArea: '1 / 2 / 2 / 3 ',
    [theme.breakpoints.up('md')]: {
      alignSelf: 'center',
      gridArea: 'auto',
    },
  },
  contentContainer: {
    display: 'grid',
    gridAutoColumns: 'min-content',
    gridTemplateColumns: 'auto min-content',
    padding: '4px 4px 16px 16px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      gridAutoFlow: 'column',
      gridColumnGap: 22,
      gridTemplateColumns: 'auto max-content min-content',
      padding: '16px 12px 16px 24px',
    },
  },
  messageContainer: {
    alignSelf: 'flex-end',
    [theme.breakpoints.up('md')]: {
      alignSelf: 'center',
    },
  },
  root: ({ styleRules }: { styleRules?: StyleRules }) => ({
    backgroundColor: neutral.white,
    color: neutral.D20,
    bottom: 0,
    left: 0,
    right: 0,
    transform: 'none',
    width: '100%',
    ...styleRules?.root,
  }),
}));
