import { makeStyles } from '@material-ui/core/styles';
// Constant
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
    position: 'relative',
    width: '100%',
    padding: '20px 16px 24px',
    [theme.breakpoints.up('md')]: {
      padding: 24,
    },
  },
  productImageContainer: {
    alignItems: 'center',
    backgroundColor: neutral.D5,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 24,
    maxHeight: 260,
    minHeight: 240,
    position: 'relative',
  },
  productImage: {
    maxHeight: 250,
    maxWidth: '100%',
  },
  inputMarginBottom: {
    marginBottom: 16,
  },
  priceInput: {
    '& input': {
      textAlign: 'center',
    },
  },
  divider: {
    borderTop: `1px solid ${neutral.D10}`,
    marginBottom: 24,
    marginTop: 24,
    width: '100%',
  },
  toppingsTitle: {
    color: neutral.D20,
    fontSize: 17,
    fontWeight: 'normal',
    marginBottom: 24,
    marginTop: 0,
  },
}));
