import { makeStyles } from '@material-ui/core/styles';

import Colors from '../../constants/Colors';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
  itemContainer: {
    border: `1px solid ${neutral.D10}`,
    margin: '1rem 1rem 0 1rem',
    padding: '1.5rem',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemName: {
    overflow: 'hidden',
    fontSize: '0.875rem',
    fontWeight: 600,
    whiteSpace: 'normal',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '7rem',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  sectionTitle: {
    fontSize: '0.625rem',
    fontWeight: 600,
    marginBottom: '0.5rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
  },
  date: {
    borderRadius: '0.5rem',
    backgroundColor: Colors.DANGER_LIGHT_COLOR,
    textAlign: 'center',
    padding: '2px 4px',
  },
  dateText: {
    fontSize: '0.625rem',
    maxWidth: '6.25rem',
    lineHeight: '2.0',
  },
}));
