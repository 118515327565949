import { clsx } from 'clsx';
import { Button } from '@material-ui/core';
import { useEffect, useMemo, useRef, useState } from 'react';

import CustomLoader from '../../custom-loader';

import { Colors, Variants } from './consts';
import { Serverities } from '../../../../constants/Severities';

import useStyles, { useStyleRules } from './styles';

import type { Button as ButtonProps } from './interfaces';

function CustomButton(props: ButtonProps) {
  const {
    type,
    className,
    text = '',
    children = '',
    classes = {},
    styleRules = {},
    loaderSize = 24,
    disabled = false,
    fullWidth = true,
    isLoading = false,
    color = 'primary',
    variant = Variants.TEXT,
    onClick = () => {},
  } = props;

  const _classes = useStyles({ fullWidth });
  const _rulesClasses = useStyleRules({ styleRules });

  const [isLoadingLocal, setIsLoadingLocal] = useState(isLoading);
  const [localColor, setLocalColor] = useState(Colors.DEFAULT);

  const _isMounted = useRef(true);

  const severitySubmitButtonClass = useMemo(() => {
    switch (color) {
      case Serverities.INFO:
        return _classes.infoSubmitButton;
      case Serverities.WARNING:
        return _classes.warningSubmitButton;
      case 'tertiary':
        return _classes.tertiarySubmitButton;
      default:
        return null;
    }
  }, [color, _classes]);

  useEffect(() => {
    const colorToSet =
      color === Colors.PRIMARY || color === Colors.SECONDARY
        ? color
        : Colors.DEFAULT;
    setLocalColor(colorToSet as Colors);
  }, [color]);

  useEffect(() => {
    setIsLoadingLocal(isLoading);
  }, [isLoading]);

  useEffect(() => {
    return () => {
      // ComponentWillUnmount
      _isMounted.current = false;
    };
  }, []);

  return (
    <div className={clsx(_classes.root, classes?.root, _rulesClasses.root)}>
      <Button
        className={className}
        type={type}
        disabled={disabled || isLoadingLocal}
        fullWidth={fullWidth}
        variant={variant}
        color={localColor}
        classes={{
          root: clsx(
            _classes.submitButton,
            severitySubmitButtonClass,
            classes?.submitButton,
            _rulesClasses.submitButton,
          ),
          disabled: clsx(
            classes?.disabledSubmitButton,
            _rulesClasses.disabledSubmitButton,
          ),
        }}
        onClick={async e => {
          try {
            setIsLoadingLocal(true);
            await onClick(e);
          } catch (ex) {
            console.error(ex);
          }

          if (_isMounted.current) {
            setIsLoadingLocal(false);
          }
        }}
      >
        {text || children}
      </Button>
      <CustomLoader invisible open={isLoadingLocal} size={loaderSize} />
    </div>
  );
}

export default CustomButton;
