// External libs
import { createActions } from 'redux-actions';

const {
  openAddHolidayScheduleDialogAction,
  closeAddHolidayScheduleDialogAction,
} = createActions({
  OPEN_ADD_HOLIDAY_SCHEDULE_DIALOG_ACTION: holiday => ({ holiday }),
  CLOSE_ADD_HOLIDAY_SCHEDULE_DIALOG_ACTION: () => ({}),
});

const openAddHolidayScheduleDialog = holiday => dispatch => {
  dispatch(openAddHolidayScheduleDialogAction(holiday));
};
const closeAddHolidayScheduleDialog = () => dispatch => {
  dispatch(closeAddHolidayScheduleDialogAction());
};

export default {
  openAddHolidayScheduleDialog,
  openAddHolidayScheduleDialogAction,
  closeAddHolidayScheduleDialog,
  closeAddHolidayScheduleDialogAction,
};
