import {
  analitycsEvent,
  GACategories,
  GAActions,
} from './../../utils/ga-utils';
import { intervalTypes } from '../partner-next-interval/PartnerNextInterval';
import PartnerNextInterval from '../partner-next-interval';

import CustomSwipeableDialog from '../common/custom-swipeable-dialog';

import useStyles from './OpenPartnerStateDialog.styles';

function OpenPartnerStateDialog(props) {
  const {
    confirmText,
    deactivateStoreText,
    listDescription,
    onClickDeactivate,
    onClose,
    open,
    title,
    partnerId,
    totalStores,
  } = props;
  const componentClasses = useStyles();
  return (
    <CustomSwipeableDialog
      buttons={[
        {
          variant: 'outlined',
          color: 'tertiary',
          onClick: () => {
            analitycsEvent(
              GACategories.PARTNER_STATE,
              GAActions.DEACTIVATE_DIALOG_STORE_OPEN,
              `partner_id=${partnerId}`,
            );
            onClickDeactivate();
          },
          label: deactivateStoreText,
        },
        {
          color: 'primary',
          label: confirmText,
          onClick: () => {
            analitycsEvent(
              GACategories.PARTNER_STATE,
              GAActions.OK_DIALOG_STORE_OPEN,
              `partner_id=${partnerId}`,
            );
            onClose();
          },
          variant: 'contained',
        },
      ]}
      classes={{
        dialogPaper: componentClasses.dialogPaper,
      }}
      onClose={onClose}
      open={open}
      title={title}
    >
      <div className={componentClasses.content}>
        <div className={componentClasses.listDescription}>
          <span>{listDescription}</span>
        </div>
        {totalStores === 1 && (
          <PartnerNextInterval
            classes={{ root: componentClasses.partnerNextInterval }}
            type={intervalTypes.TODAY}
          />
        )}
      </div>
    </CustomSwipeableDialog>
  );
}

export default OpenPartnerStateDialog;
