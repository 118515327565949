import { Box, Typography } from '@material-ui/core';

import {
  OrderResponseCard,
  WAITING_TO_WAIT_IN_SEG,
  getIsTimeoutState,
} from '../../../../business/order-mgmt';
import { KanbanOMCardStyle } from './kanbanOMCard.style';
import { KanbanSuccessCard } from './success-card';
import { useKanbanOMCard, useKanbanOMLang } from './hooks';
import { KanbanTimeoutCard } from './timeout-card';
import { OrderProgress } from '../../components/order-progress';
import { AccessTimeOutlined } from '@material-ui/icons';

export function KanbanOMCard({ order }: Props) {
  const {
    state_order_management: { state, createdAt },
    id,
  } = order;

  const { cardColors, openDetailDialog } = useKanbanOMCard(state, id);
  const style = KanbanOMCardStyle({ cardColors });
  const { disclaimerText } = useKanbanOMLang(state);
  const isTimeoutState = getIsTimeoutState(state);

  return (
    <Box className={style.root}>
      <Box
        className={style.childContainer}
        flex={1}
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        padding='0.75rem 1rem'
        minHeight='4.69rem'
        onClick={openDetailDialog}
      >
        <Box flex={1} display='flex' justifyContent='space-between'>
          <Box
            display='flex'
            justifyContent={state === 'to_wait' ? 'center' : 'space-between'}
            flexDirection={state === 'to_wait' ? 'column-reverse' : 'column'}
            gridGap={state === 'to_wait' ? '0.5rem' : '0'}
          >
            <Typography className={style.orderId}>{order.id}</Typography>
            {state !== 'accepted' && (
              <Box>
                <Typography className={style.disclaimerText}>
                  {state === 'to_wait' && (
                    <AccessTimeOutlined className={style.clock} />
                  )}
                  {disclaimerText}
                </Typography>
              </Box>
            )}
          </Box>
          {state === 'to_wait' && (
            <OrderProgress
              createdAt={createdAt}
              countDownTime={WAITING_TO_WAIT_IN_SEG}
              position='vertical'
            />
          )}
          {state === 'accepted' && <KanbanSuccessCard />}
          {isTimeoutState && <KanbanTimeoutCard />}
        </Box>
      </Box>
    </Box>
  );
}

interface Props {
  order: OrderResponseCard;
}
