import { useEffect, useState } from 'react';

import { checkWebPlatformDevice } from '../../business/common/browser/domain';
import { useBlockNotifyMobileDevicesLang } from './useBlockNotifyMobileDevicesLang';

export function useBlockNotifyMobileDevices() {
  const LINK_APP_ANDROID =
    'https://play.google.com/store/apps/details?id=com.rappi.restaurants&hl=es_419&gl=US';

  const [isMobile, setIsMobile] = useState(false);

  const texts = useBlockNotifyMobileDevicesLang();

  // this handle will be deleted approximately over 2023-11-07
  function handleClose() {
    setIsMobile(false);
  }

  function handleGoToGooglePlayStore() {
    window.open(LINK_APP_ANDROID, '_self');
  }

  useEffect(() => {
    const device = checkWebPlatformDevice();
    if (device === 'mobile' || device === 'possibly_mobile') setIsMobile(true);
  }, []);

  return {
    texts,
    open: isMobile,
    linkAppAndroid: LINK_APP_ANDROID,
    handleClose,
    handleGoToGooglePlayStore,
  };
}
