const titleError = {
  id: 'errorPMFlowDialog.title',
}
const info = {
  id: 'errorPMFlowDialog.info',
}
const submitButton = {
  id: 'errorPMFlowDialog.submitButton',
}
const description = {
  id: 'errorPMFlowDialog.description',
}
const partnerPortal = {
  id: 'errorPMFlowDialog.partnerPortal',
}
const textIds = {
  titleError,
  submitButton,
  info,
  description,
  partnerPortal,
}

export default textIds
