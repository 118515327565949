import { makeStyles } from '@material-ui/styles';

import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  switchRoot: {
    justifySelf: 'end',
  },
  textContainer: {
    display: 'grid',
    gridAutoColumns: 'auto',
    gridAutoFlow: 'row',
    gridAutoRows: 'min-content',
    gridRowGap: 6,
  },
  title: {
    color: neutral['90'],
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  root: {
    alignContent: 'center',
    backgroundColor: neutral.white,
    color: neutral['50'],
    display: 'grid',
    fontSize: '0.75rem',
    gridAutoFlow: 'row',
    gridAutoRows: 'min-content',
    gridRowGap: '1rem',
    lineHeight: 'normal',
    padding: '1.5rem',
    border: `1px solid ${neutral['20']}`,
    [theme.breakpoints.up('md')]: {
      border: `1px solid ${neutral['20']}`,
      gridColumnGap: '1.5rem',
      gridTemplateColumns: 'auto min-content',
      margin: '24px 24px 0px 24px',
    },
  },
}));
