import React from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';

import { hooks } from './hooks';

import useCStyles from './DropdownButton.styles';
import { storeStatusStatusOpts as SSSOpts } from '../../constants';

import type { DropdownButtonProps as Props } from './DropdownButton.types';
import { SuccessIcon } from '../../../../features/high-demand/shared/assets/icons/success-icon';
import { DangerIcon } from '../../../../features/high-demand/shared/assets/icons/danger-icon';
import { WarningIcon } from '../../../../features/high-demand/shared/assets/icons/warning-icon';

// +++++ DropdownButton
export function DropdownButton(props: Props): JSX.Element {
  const {
    primaryState,
    isSingleStore,
    isPartnerStateLoading = false,
    hasActivatedAllStores = true,
    hasDeactivatedAllStores = false,
  } = props;

  const isError = !SSSOpts[primaryState]?.status;
  const isErrorOrIsPartnerStateLoading = isError || isPartnerStateLoading;
  const dropdownBtnId = 'drop-down-button-store-status';

  const textIds = hooks.useTexts({ isSingleStore, status: primaryState });
  const classes = useCStyles({ status: primaryState, isSingleStore });

  const StartIcon = () => {
    if (isErrorOrIsPartnerStateLoading) return <CircularProgress size='1rem' />;
    if (isSingleStore) return <Box className={classes.status}></Box>;
    return hasActivatedAllStores ? (
      <SuccessIcon />
    ) : hasDeactivatedAllStores ? (
      <DangerIcon />
    ) : (
      <WarningIcon />
    );
  };

  return (
    <React.Fragment>
      <Button
        disableRipple
        id={dropdownBtnId}
        startIcon={<StartIcon />}
        classes={{
          root: classes.button,
          startIcon: classes.buttonStartIcon,
        }}
      >
        {textIds.dropdownBtn}
      </Button>
    </React.Fragment>
  );
}
// ----- DropdownButton
