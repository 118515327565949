import { makeStyles } from '@material-ui/core';

import { neutral, red, teal } from '../../../shared/styles/colors';

export default makeStyles({
  labelContainer: {
    color: neutral['50'],
    fontSize: 13,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    marginBottom: 10,
  },
  rootLabel: {
    color: neutral['20'],
    // the name must be exactly the same as the style applied below.
    '&$focusedLabel': {
      color: `${teal['50']}`,
    },
  },
  labelOutlined: {
    transform: 'translate(14px, 15px) scale(1)',
  },
  rootInput: {
    // Names depend on the classes that appear on the element that is needed to be modified.
    '& $notchedOutlineInput': {
      borderColor: neutral['20'],
    },
    '&:hover $notchedOutlineInput': {
      borderColor: `${teal['50']}`,
      borderWidth: 2,
      '@media (hover: none)': {},
    },
    '&$focusedInput $notchedOutlineInput': {
      borderWidth: 2,
    },
    '&$disabledInput $notchedOutlineInput': {
      borderColor: `${neutral['20']} !important`,
    },
    '&$error $notchedOutlineInput': {
      borderColor: `${red['50']} !important`,
    },
  },
  rootInputHiglighted: {
    // Names depend on the classes that appear on the element that is needed to be modified.
    '& $notchedOutlineInput': {
      borderColor: `${teal['50']}`,
    },
  },
  input: {
    color: neutral['90'],
    fontFamily: 'Poppins',
    fontSize: '0.948em',
    lineHeight: 'normal',
    padding: '14px 10px',
    '&::placeholder': {
      color: neutral['70'],
      fontWeight: 600,
    },
  },
  root: {
    backgroundColor: neutral.white,
    borderRadius: 4,
  },
});
