import { makeStyles } from '@material-ui/styles';

import { blue, neutral } from '../../../../shared/styles/colors';

export default makeStyles({
  root: {
    alignItems: 'center',
    background: neutral.white,
    boxShadow: `0 -1px 00 ${neutral['20']}`,
    display: 'flex',
    gap: '1rem',
    justifyContent: 'flex-end',
  },
  newChipContainer: {
    borderRadius: '0.875rem',
    backgroundColor: blue['50'],
    color: neutral.white,
    fontWeight: 600,
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    position: 'absolute',
    top: '-0.75rem',
    zIndex: 1,
  },
  orderManagementActionsRoot: {
    position: 'sticky',
    gridArea: 'management',
    padding: '1rem',
  },
});
