// Material UI
import { makeStyles } from '@material-ui/core/styles';
// Consts
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  actionContainer: {},
  corridorName: { marginTop: 4 },
  imageSmall: {
    maxHeight: 40,
    maxWidth: 40,
  },
  productNameContainer: { whiteSpace: 'pre-wrap' },
  productDescription: {
    marginTop: 4,
  },
  productDetailsContainer: {
    color: neutral.D15,
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'pre-wrap',
  },
  productName: {
    minWidth: 160,
  },
  productInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 16,
    width: '100%',
  },
  root: {
    alignItems: 'center',
    color: neutral.D20,
    display: 'flex',
    fontFamily: 'Poppins',
    fontSize: 13,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    minHeight: 60,
    width: '100%',
  },
}));
