// React
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
// External Libs
import { get, find } from 'lodash'
// Components
import OrderRejectionReasons from './OrderRejectionReasons'

const getTitle = (cookingTime, formatMessage) => {
  const titleId = cookingTime
    ? 'orderRejectionReasons.cancellationTitle'
    : 'orderRejectionReasons.rejectionTitle'

  return formatMessage({ id: titleId })
}

const mapStateToProps = (
  {
    ordersReducer: { orders, rejectionReasons },
    orderRejectionReasonsDialogReducer: { orderId },
  },
  { intl: { formatMessage } },
) => {
  const orderDetail = get(find(orders, { id: +orderId }), ['detail'], {
    id: +orderId,
  })

  const title = getTitle(orderDetail.cooking_time, formatMessage)

  return {
    rejectionReasons,
    title,
  }
}

const mapDispatchToProps = {}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(OrderRejectionReasons),
)
