// Material UI
import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  body: {
    display: 'grid',
    margin: '36px 30px',
    gridGap: 12,
    gridTemplateAreas: `"img"
                        "productName"
                        "ContainerProduct"
                        "containerFooter"`,
    [theme.breakpoints.up('md')]: {
      margin: '60px 75px',
      overflow: 'hidden',
      gridGap: 0,
      gridTemplateAreas: `"img"
                        "productName"
                        "ContainerProduct"`,
    },
  },
  headerRoot: {},
  paper: {
    [theme.breakpoints.up('md')]: {
      height: 582,
      width: 576,
    },
  },
  title: {
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 600,
    padding: '0px 30px 0px 30px',
    [theme.breakpoints.up('md')]: {
      padding: '0px 90px 0px 90px',
    },
  },
  subTitle: {
    gridArea: 'subTitle',
    fontWeight: 'bold',
    fontSize: 16,
  },
  description: {
    gridArea: 'description',
    fontWeight: 'bold',
    fontSize: 16,
  },
  productName: {
    textAlign: 'center',
    gridArea: 'productName',
    display: 'grid',
    gridGap: 12,
    gridTemplateAreas: `"subTitle"
                        "description"`,
    height: 0,
    justifySelf: 'center',
    [theme.breakpoints.up('md')]: {},
  },
  errorIconImg: {
    gridArea: 'img',
    width: 118,
    [theme.breakpoints.up('md')]: {
      width: 118,
    },
  },
  container: {
    justifySelf: 'center',
  },
  containerFooterButtons: {
    gridArea: 'containerFooter',
    display: 'grid',
    gridTemplateAreas: `"rejectButton"`,
    gridGap: 0,
  },
  rejectButtonFooter: {},
}))
