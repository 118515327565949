// External libs
import produce from 'immer';
import { handleActions } from 'redux-actions';
// Actions
import SuspendedStoreAlertSnackbarActions from '../../actions/suspended-store-alert-snackbar-actions';

const initialState = {
  message: null,
  open: false,
};

export default handleActions(
  {
    [SuspendedStoreAlertSnackbarActions.closeSuspendedStoreAlertSnackbarAction]: state =>
      toggleOpen(state, false),
    [SuspendedStoreAlertSnackbarActions.openSuspendedStoreAlertSnackbarAction]: (
      state,
      { payload: { message } },
    ) => toggleOpen(state, true, message),
  },
  initialState,
);

const toggleOpen = (state, open, message = null) => {
  return produce(state, stateDraft => {
    stateDraft.message = message;
    stateDraft.open = open;
  });
};
