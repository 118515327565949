import { clsx } from 'clsx';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

export function CustomDeleteButton({ disabled, onClick, classes = {} }) {
  return (
    <IconButton
      aria-label='Delete'
      className={clsx(classes?.button)}
      disabled={disabled}
      onClick={onClick}
    >
      <Delete className={clsx(classes?.icon)} />
    </IconButton>
  );
}
