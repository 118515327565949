// Material UI
import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  childrenContainer: {},
  childrenContainerWithoutOptions: {
    [theme.breakpoints.up('md')]: {
      flex: 'none',
    },
  },
  headerRoot: {
    [theme.breakpoints.up('md')]: {
      padding: 12,
    },
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      height: 445,
    },
  },
  paperWithoutOptions: {
    [theme.breakpoints.up('md')]: {
      height: 'auto',
    },
  },
  root: {},
}));
