// External libs
import { get } from 'lodash';
// Redux
import { createAction } from '@reduxjs/toolkit';
import history from '../../../history';
// Interfaces
import { ArticleDetail, Category } from '../interfaces';
// Services
import SupportActionsManagerService from '../../../services/help-center-service';
// Actions
import AppActions from '../../actions/app-actions/app.actions';
import EventActions from '../../actions/event-actions';
// Utils
import utils from '../utils';
import appStore from '../../stores/app.store';
import Paths from '../../../constants/Paths';
import StringUtils from '../../../utils/StringUtils';

const closeAction = createAction('HelpCenterPage/close');

const loadDataAction = createAction<Category[]>('HelpCenterPage/loadData');

const loadSelectedArticleAction = createAction<{ article: ArticleDetail }>(
  'HelpCenterPage/loadSelectedArticle',
);

const openAction = createAction('HelpCenterPage/open');

const setIsArticleLoadingAction = createAction<{ isLoading: boolean }>(
  'HelpCenterPage/setIsArticleLoading',
);

const setSelectedCategoryIdAction = createAction<string>(
  'HelpCenterPage/setSelectedCategoryId',
);

const setSelectedSubCategoryIdAction = createAction<string>(
  'HelpCenterPage/setSelectedSubCategoryId',
);

const loadSelectedArticle = (articleId: string) => async (dispatch: any) => {
  try {
    dispatch(setIsArticleLoading(true));
    const response = await SupportActionsManagerService.getArticle(articleId);
    EventActions.onSuccessfulArticle(articleId);
    const article: ArticleDetail = {
      id: get(response, 'data.id'),
      name: get(response, 'data.name'),
      body: get(response, 'data.body'),
    };

    dispatch(loadSelectedArticleAction({ article }));
  } catch (ex) {
    await dispatch(AppActions.handleCatch(ex));
  }
  dispatch(setIsArticleLoading(false));
};

const loadData = (
  categoryId?: string,
  articleId?: string,
  isMdUp?: boolean,
) => async (dispatch: any) => {
  let categories = appStore.getHelpCenterPageData();
  let defaultCategoryId;
  const isFirstLoad = categories.length === 0;
  if (isFirstLoad) {
    try {
      const response = await SupportActionsManagerService.getHelpCenterTree();
      const categoriesToSave = utils.parseToCategoryArray(
        get(response, 'data.categories', []),
      );
      dispatch(loadDataAction(categoriesToSave));
      dispatch(close());
    } catch (ex) {
      await dispatch(AppActions.handleCatch(ex));
    }
  }
  if (!categoryId && !articleId) {
    // if desktop view show default category
    if (isMdUp) {
      categories = appStore.getHelpCenterPageData();

      defaultCategoryId = categories[0]?.id;

      if (StringUtils.isNullOrEmpty(defaultCategoryId)) {
        return;
      }

      history.push(Paths.getHelpcenterCategoryPath(defaultCategoryId));
      dispatch(setSelectedCategoryId(defaultCategoryId));
    } else {
      dispatch(setSelectedCategoryId(''));
    }
    return;
  } else if (!categoryId && !!articleId) {
    categories = appStore.getHelpCenterPageData();
    const selectedCategory = categories.find(category => {
      return (
        category.sections.findIndex(section => {
          return (
            section.articles.findIndex(article => article.id === articleId) > -1
          );
        }) > -1
      );
    });
    if (!selectedCategory) {
      history.push(Paths.HELP_CENTER);
      return;
    }

    history.push(
      Paths.goToHelpCenterWithCategoryAndArticle(
        selectedCategory.id,
        articleId,
      ),
    );
    return;
  } else if (!!categoryId && !articleId) {
    return dispatch(setSelectedCategoryId(categoryId));
  } else {
    categories = appStore.getHelpCenterPageData();
    const selectedCategory = categories.find(
      category => category.id === categoryId,
    );
    if (!selectedCategory) {
      history.push(Paths.HELP_CENTER);
      return;
    }
    const selectedSubCategory = selectedCategory.sections.find(
      s => !!s.articles.find(a => a.id === articleId),
    );
    if (!selectedSubCategory) {
      return history.push(Paths.HELP_CENTER);
    }
    dispatch(setSelectedCategoryId(selectedCategory.id));
    dispatch(setSelectedSubCategoryId(selectedSubCategory.id));
    dispatch(loadSelectedArticle(articleId || ''));
    return Paths.goToHelpCenterWithCategoryAndArticle(categoryId, articleId);
  }
};

const close = () => (dispatch: any) => {
  dispatch(closeAction());
};

const open = () => (dispatch: any) => {
  dispatch(openAction());
};

const setIsArticleLoading = (isLoading: boolean) => (dispatch: any) => {
  dispatch(setIsArticleLoadingAction({ isLoading }));
};

const setSelectedCategoryId = (categoryId: string) => (dispatch: any) => {
  dispatch(setSelectedCategoryIdAction(categoryId));
};

const setSelectedSubCategoryId = (categoryId: string) => (dispatch: any) => {
  dispatch(setSelectedSubCategoryIdAction(categoryId));
};

const actions = {
  close,
  closeAction,
  loadData,
  loadDataAction,
  loadSelectedArticle,
  loadSelectedArticleAction,
  open,
  openAction,
  setIsArticleLoading,
  setIsArticleLoadingAction,
  setSelectedCategoryId,
  setSelectedCategoryIdAction,
  setSelectedSubCategoryId,
  setSelectedSubCategoryIdAction,
};

export default actions;
