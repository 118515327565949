import { useSelector } from 'react-redux';

import { selectors as startPMFlowDialogSelectors } from '../../redux/start-PM-flow-dialog';

const useOpen = () => useSelector(startPMFlowDialogSelectors.selectOpen);

const useStoreName = () =>
  useSelector(startPMFlowDialogSelectors.selectStoreName);

const useFormId = () => useSelector(startPMFlowDialogSelectors.selectFormId);

const useStoreId = () => useSelector(startPMFlowDialogSelectors.selectStoreId);

const hooks = {
  useOpen,
  useStoreName,
  useFormId,
  useStoreId,
};

export default hooks;
