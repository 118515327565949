import React from 'react';
import { Box, Menu, MenuItem } from '@material-ui/core';
import { CustomMoreVertButton } from '../../../common/buttons/custom-more-vert-button';
import OrderMgmtCardStyles from './OrderMgmtCard.style';
import Typography, { FontWeight, Variants } from '../../../common/typography';
import replaceIcon from '../../../../assets/images/icons/replaceIcon.svg';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { PAPER_PROPS_ITEM_STYLE } from './const';
import { useOrderMgmtLang } from '../../hooks';
import { SuggestionsSection, ToppingDetail } from './components';
import { OrderMgmtProduct } from '../../../../business/order-mgmt';
import { useOrderMgmtCard } from './hooks';
import { UndoSuggestionChangesChip } from '../order-mgmt-undo-suggestion-changes-chip';
import { ChipOrderDisclaimer } from '../order-mgmt-dialog/components';

export const OrderMgmtCard = ({ product, orderId, isEnabled }: Props) => {
  const {
    id,
    units,
    name,
    description,
    toppings,
    subtotal,
    suggestions,
    corridorName,
  } = product;
  const {
    replaceProductText,
    subtotalOrderText,
    soldOutProductText,
  } = useOrderMgmtLang({ orderId });
  const {
    anchorProductMenu,
    openProductMenu,
    hasProductItemSuggestions,
    handleReplaceProduct,
    handleCloseProductMenu,
    handleClickProductManageMenuOptions,
    handleDeleteProduct,
    hasProductItemToppingSuggestions,
  } = useOrderMgmtCard({ product });
  const componentClasses = OrderMgmtCardStyles();

  return (
    <Box display='flex' flexDirection='column' gridGap='1rem'>
      <Typography classes={{ root: componentClasses.corridorName }}>
        {corridorName}
      </Typography>
      <Box
        className={componentClasses.cardContainer}
        display='flex'
        width='100%'
        flexDirection='column'
      >
        <Box display='flex' alignItems='center' position='relative'>
          <Typography
            classes={{ root: componentClasses.quantity }}
            fontWeight={FontWeight.SemiBold}
          >
            {`${units}x`}
          </Typography>
          <Box
            className={componentClasses.headerDivider}
            height='4rem'
            left={54}
            position='absolute'
          />
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            minHeight='3rem'
            padding='0 1rem'
            width='100%'
          >
            <Typography>{name}</Typography>
            {hasProductItemSuggestions && (
              <Box display='flex'>
                <ChipOrderDisclaimer
                  type='warning'
                  disclaimer={soldOutProductText}
                />
              </Box>
            )}
            {isEnabled && (
              <>
                <CustomMoreVertButton
                  onClick={handleClickProductManageMenuOptions}
                />
                <Menu
                  id='manage-product-menu'
                  anchorEl={anchorProductMenu}
                  open={openProductMenu}
                  onClose={handleCloseProductMenu}
                  PaperProps={{ style: PAPER_PROPS_ITEM_STYLE }}
                >
                  <MenuItem onClick={handleReplaceProduct}>
                    <Box
                      className={componentClasses.replaceItem}
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      fontSize='0.625rem'
                      fontWeight={600}
                      gridGap={10}
                    >
                      <img alt='replace-icon' src={replaceIcon} />
                      <span>{replaceProductText}</span>
                    </Box>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Box>
        <Box className={componentClasses.cardBody} padding='0 1rem'>
          <Typography
            classes={{ root: componentClasses.description }}
            variant={Variants.Caption2}
          >
            {description}
          </Typography>
          {hasProductItemSuggestions && (
            <Box
              display='flex'
              flexDirection='column'
              gridGap='0.5rem'
              marginBottom='0.5rem'
            >
              <SuggestionsSection itemSuggestions={suggestions} units={units} />
              {isEnabled && <UndoSuggestionChangesChip productId={id} />}
            </Box>
          )}
          {toppings && !hasProductItemSuggestions && (
            <Box
              display='flex'
              flexDirection='column'
              gridGap='0.5rem'
              marginBottom='1.25rem'
              marginTop='0.5rem'
            >
              {toppings.map(topping => (
                <ToppingDetail
                  topping={topping}
                  productId={product?.id}
                  key={`t-${topping?.id}`}
                  isToppingEnabledToEdit={
                    !hasProductItemToppingSuggestions && isEnabled
                  }
                />
              ))}
            </Box>
          )}
        </Box>
        <Box
          className={componentClasses.cardFooter}
          display='flex'
          justifyContent='space-between'
          fontSize='1.0625rem'
        >
          <Typography fontWeight={FontWeight.SemiBold}>
            {subtotalOrderText}
          </Typography>
          <Typography
            classes={{ root: componentClasses.subtotal }}
            fontWeight={FontWeight.SemiBold}
          >
            {subtotal}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

interface Props {
  product: OrderMgmtProduct;
  orderId: number;
  isEnabled: boolean;
  hasDelete?: boolean;
}
