import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';

import CustomButton from '../buttons/custom-button';
import CustomAlertIcon from '../../icons/custom-alert-icon';
import { Serverities } from '../../../constants/Severities';
import { CustomCloseButton } from '../buttons/custom-close-button';

import useStyles from './CustomInAppDialog.styles';

const getDescriptionModifierClass = (classes, componentClasses, type) => {
  switch (type) {
    case Serverities.ERROR:
      return clsx(
        componentClasses.descriptionDanger,
        classes?.descriptionDanger,
      );
    default:
      return clsx(
        componentClasses.descriptionSuccess,
        classes?.descriptionSuccess,
      );
  }
};

const getDialogContentModifierClass = (classes, componentClasses, type) => {
  switch (type) {
    case Serverities.ERROR:
      return clsx(
        componentClasses.dialogContentDanger,
        classes.dialogContentDanger,
      );
    default:
      return clsx(
        componentClasses.dialogContentSuccess,
        classes.dialogContentSuccess,
      );
  }
};

const getButtonModifierClass = (classes, componentClasses, type) => {
  switch (type) {
    case Serverities.ERROR:
      return clsx(componentClasses.buttonDanger, classes?.buttonDanger);
    default:
      return clsx(componentClasses.buttonSuccess, classes?.buttonSuccess);
  }
};

/**
 * Funcional react component for in app dialogs.
 * @function
 * @param {object} props - React props.
 * @return {JSX.Element} - Rendered Component
 */
const CustomInAppDialog = ({
  buttonText,
  classes,
  description,
  disableBackdropClick,
  disableEnforceFocus,
  disableEscapeKeyDown,
  onClose,
  onClick,
  showCloseButton,
  showMainButton,
  open,
  title,
  type,
}) => {
  const _classes = useStyles();

  const descriptionModifierClass = getDescriptionModifierClass(
    classes,
    _classes,
    type,
  );

  const dialogContentModifierClass = getDialogContentModifierClass(
    classes,
    _classes,
    type,
  );

  const buttonModifierClass = getButtonModifierClass(classes, _classes, type);

  return (
    <Dialog
      classes={{
        root: clsx(_classes.dialogRoot, classes?.dialogRoot),
        paper: clsx(_classes.dialogPaper, classes?.dialogPaper),
      }}
      disableBackdropClick={disableBackdropClick}
      disableEnforceFocus={disableEnforceFocus}
      disableEscapeKeyDown={disableEscapeKeyDown}
      open={open}
      onClose={onClose}
    >
      <div
        className={clsx(
          _classes.dialogContentContainer,
          classes?.dialogContentContainer,
          dialogContentModifierClass,
        )}
      >
        {showCloseButton && (
          <div
            className={clsx(
              _classes.dialogContentHeader,
              classes?.dialogContentHeader,
            )}
          >
            <CustomCloseButton
              classes={{ button: _classes.closeButton }}
              onClick={onClose}
            />
          </div>
        )}
        <div
          className={clsx(
            _classes.dialogContentBody,
            classes?.dialogContentBody,
          )}
        >
          <CustomAlertIcon className={_classes.alertIcon} />
          <h1 className={_classes.title}>{title}</h1>
          <p
            className={clsx(
              _classes.description,
              classes?.description,
              descriptionModifierClass,
            )}
          >
            {description}
          </p>
        </div>
        {showMainButton && (
          <CustomButton
            classes={{
              submitButton: clsx(
                _classes.buttonRoot,
                classes?.buttonRoot,
                buttonModifierClass,
              ),
            }}
            onClick={onClick}
            text={buttonText}
            variant={'contained'}
          ></CustomButton>
        )}
      </div>
    </Dialog>
  );
};

CustomInAppDialog.defaultProps = {
  buttonText: '',
  classes: {},
  description: '',
  disableBackdropClick: false,
  disableEnforceFocus: true,
  disableEscapeKeyDown: true,
  onClick: () => {},
  onClose: () => {},
  showCloseButton: false,
  showMainButton: false,
  open: false,
  title: '',
  type: Serverities.ERROR,
};

CustomInAppDialog.propTypes = {
  buttonText: PropTypes.string,
  classes: PropTypes.shape({
    buttonDanger: PropTypes.string,
    buttonRoot: PropTypes.string,
    buttonSuccess: PropTypes.string,
    dialogRoot: PropTypes.string,
    dialogPaper: PropTypes.string,
  }),
  description: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.oneOf([Serverities.ERROR, Serverities.SUCCESS]),
};

export default CustomInAppDialog;
