import React from 'react';
import useListStyles from '../list.styles';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export function CardLoader() {
  const styles = useListStyles();

  return (
    <Box
      className={styles.skeleton}
      width='100%'
      padding='1rem 1.5rem 1rem'
      display='flex'
      flexDirection='column'
    >
      <Skeleton variant='text' width={250} />
      <Skeleton variant='text' width={160} />
      <Box className={styles.divider} margin='0.5rem 0' />
      <Box display='flex' justifyContent='space-between' marginBottom='0.5rem'>
        <Box>
          <Skeleton variant='text' width={210} />
          <Skeleton variant='text' width={100} />
        </Box>
        <Skeleton variant='rect' width={60} height={20} />
      </Box>
      <Box display='flex' gridGap='1rem' justifyContent='flex-end'>
        <Skeleton variant='rect' width={100} height={20} />
        <Skeleton variant='rect' width={100} height={20} />
      </Box>
    </Box>
  );
}
