import { useSelector } from 'react-redux';

import selectors from '../selectors';
import ordersSelector from '../../../redux/selectors/orders-selectors';
import { selectors as RTAssignedIssueSuggestionDialogSelectors } from '../../../redux/rt-assigned-issue-suggestions-dialog';

const useOpen = () => {
  return useSelector(RTAssignedIssueSuggestionDialogSelectors.selectOpen);
};

const useDescription = () => {
  return useSelector(
    RTAssignedIssueSuggestionDialogSelectors.selectDescription,
  );
};

const useOrderId = () => {
  return useSelector(RTAssignedIssueSuggestionDialogSelectors.selectOrderId);
};

const useOptions = () => {
  return useSelector(selectors.selectOptions);
};

const useStoreKeeper = () =>
  useSelector(ordersSelector.selectStorekeeperInOrderDetailDialog);

const useTitle = () => {
  return useSelector(selectors.selectTitle);
};

const hooks = {
  useDescription,
  useOpen,
  useOrderId,
  useOptions,
  useStoreKeeper,
  useTitle,
};

export default hooks;
