import { makeStyles } from '@material-ui/core';

import { neutral } from '../../shared/styles/colors';

export default makeStyles({
  sectionContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem 1.5rem 5rem',
    borderRadius: '0.5rem',
    backgroundColor: neutral[10],
  },
});
