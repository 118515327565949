import { createStyles, makeStyles } from '@material-ui/core';
import Colors from '../../../../constants/Colors';

export type StyleProps = {
  isHorizontal: boolean;
  hasWarningTime: boolean;
};
export const progressCircularStyle = makeStyles(() =>
  createStyles({
    bottom: (props: StyleProps) => ({
      color: props.hasWarningTime
        ? Colors.DANGER_LIGHT_3
        : Colors.WARNING_LIGHT_1,
    }),
    top: (props: StyleProps) => ({
      color: props.hasWarningTime ? Colors.DANGER_LIGHT_1 : Colors.WHITE,
      position: 'absolute',
      left: 0,
    }),
    circle: {
      strokeLinecap: 'round',
    },
    wrapper: (props: StyleProps) => ({
      flexDirection: props.isHorizontal ? 'row' : 'column',
      flexFlow: 'row wrap',
    }),
    progressLabel: (props: StyleProps) => ({
      position: 'absolute',
      marginLeft: 'auto',
      marginRight: 'auto',
      left: 0,
      right: 0,
      textTransform: 'uppercase',
      fontSize: '0.75rem',
      fontWeight: 'bold',
      userSelect: 'none',
      color: props.hasWarningTime ? Colors.DANGER_LIGHT_1 : Colors.WHITE,
      order: props.isHorizontal ? 1 : 2,
      textAlign: 'center',
      paddingRight: props.isHorizontal ? '0.5rem' : 0,
    }),
  }),
);
