import {
  CargoRequestRtCase,
  MAX_NUMBER_OF_CHARACTER_ALLOWED,
  MIN_NUMBER_OF_COORDINATES_ALLOWED,
  MIN_PRICE_ALLOWED,
} from '../../../../../business/cargo';
import { useRequestLang } from './useRequestLang';
import { cargoFormHelper } from '../../../../../business/cargo';

import type { StoreId } from '../../../../../business/shared/domain';
import type { DeliveryAddress } from '../../../../../business/cargo';

export function useForm() {
  const { newOrder, stores, countryCode, setNewOrder } = CargoRequestRtCase();

  const {
    maxCharactersValidationText,
    requiredValidationText,
    wrongAddressValidationText,
  } = useRequestLang();

  const setStore = (value: StoreId) =>
    setNewOrder({ store: stores.find(s => s.id === value) });

  const setDeliveryAddress = (value: DeliveryAddress) =>
    setNewOrder({ deliveryAddress: value });

  const setDeliveryReferences = (value: string) =>
    setNewOrder({ deliveryReferences: value });

  const setClientName = (value: string) => setNewOrder({ clientName: value });

  const setPhoneNumber = (value: string) => setNewOrder({ phoneNumber: value });

  const setTotalValue = (value: number) => setNewOrder({ totalValue: value });

  const registerOptions = {
    pickupPoint: { required: requiredValidationText },
    userName: {
      required: requiredValidationText,
      maxLength: {
        value: MAX_NUMBER_OF_CHARACTER_ALLOWED,
        message: maxCharactersValidationText,
      },
    },
    phoneNumber: { required: requiredValidationText },
    deliveryAddress: {
      required: requiredValidationText,
      validate: value =>
        (value?.city?.length < MAX_NUMBER_OF_CHARACTER_ALLOWED &&
          `${value?.coordinates?.lat}`?.length >
            MIN_NUMBER_OF_COORDINATES_ALLOWED &&
          `${value?.coordinates?.lat}`?.length >
            MIN_NUMBER_OF_COORDINATES_ALLOWED) ||
        wrongAddressValidationText,
    },
    deliveryReferences: {
      required: requiredValidationText,
      maxLength: {
        value: MAX_NUMBER_OF_CHARACTER_ALLOWED,
        message: maxCharactersValidationText,
      },
    },
    totalValue: {
      required: requiredValidationText,
      min: { value: MIN_PRICE_ALLOWED, message: requiredValidationText },
    },
  };

  return {
    data: newOrder,
    countryCode,
    // TODO: the helper should be used into its own 'case' ??
    stores: cargoFormHelper.getStoresEnabled(stores),
    setStore,
    setClientName,
    setPhoneNumber,
    setDeliveryAddress,
    setDeliveryReferences,
    setTotalValue,
    registerOptions,
  };
}
