import { Button, Typography } from '@material-ui/core';

import { useDemandTTLOptionTexts } from './useDemandTTLOptionTexts';

import makeClasses from './DemandTTLOption.style';

import type { MouseEvent } from 'react';
import type { TTLOption } from '../../shared/models';
import type { Saturation } from '../../../../business/highDemand';

export function DemandTTLOption(props: DemandTTLOptionProps) {
  const {
    timeToLife,
    name = '',
    selected = false,
    onSelect = () => undefined,
    demandSelected = 'regular',
  } = props;

  const classes = makeClasses({ selected, demandSelected });
  const texts = useDemandTTLOptionTexts({ ttl: timeToLife });

  function handleClick(event: MouseEvent) {
    event.stopPropagation();
    onSelect(timeToLife);
  }

  return (
    <Button
      name={name}
      role='option'
      variant='contained'
      aria-selected={selected}
      onClick={handleClick}
      classes={{ root: classes.option, label: classes.title }}
    >
      {texts.title}
    </Button>
  );
}

interface DemandTTLOptionProps {
  name?: string;
  selected?: boolean;
  timeToLife: TTLOption;
  onSelect?: (ttl: TTLOption) => void;
  demandSelected: Saturation;
}
