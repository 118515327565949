import React from 'react';

export function EditPencilIcon({
  isInEditionMode = false,
  isDisabled = false,
}: Props) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.81366 16.6899L15.1357 7.2212C15.5336 6.71059 15.6751 6.12025 15.5424 5.51916C15.4275 4.97271 15.0914 4.45314 14.5874 4.05899L13.3582 3.08255C12.2882 2.23153 10.9618 2.32111 10.2013 3.29755L9.37887 4.36446C9.27276 4.49793 9.29929 4.69501 9.43193 4.80251C9.43193 4.80251 11.51 6.46872 11.5542 6.50455C11.6957 6.63892 11.8018 6.81808 11.8284 7.03308C11.8726 7.45411 11.5808 7.84827 11.1475 7.90201C10.9441 7.92889 10.7495 7.86618 10.608 7.74973L8.42383 6.01185C8.31772 5.93213 8.15854 5.94915 8.07011 6.05664L2.87928 12.7752C2.54325 13.1963 2.42829 13.7427 2.54325 14.2712L3.20647 17.1468C3.24184 17.2991 3.37449 17.4066 3.53366 17.4066L6.45185 17.3707C6.98242 17.3618 7.47763 17.1199 7.81366 16.6899Z'
        fill={isInEditionMode ? '#856423' : isDisabled ? '#ededed' : '#404041'}
      />
      <path
        d='M11.4163 15.5401C10.841 15.5401 10.3747 16.0065 10.3747 16.5818C10.3747 17.1571 10.841 17.6234 11.4163 17.6234H16.7305C17.3058 17.6234 17.7722 17.1571 17.7722 16.5818C17.7722 16.0065 17.3058 15.5401 16.7305 15.5401H11.4163Z'
        fill={isInEditionMode ? '#856423' : isDisabled ? '#ededed' : '#404041'}
      />
    </svg>
  );
}

interface Props {
  isInEditionMode?: boolean;
  isDisabled?: boolean;
}
