import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme, useMediaQuery } from '@material-ui/core';

import CustomTextField from '../common/custom-text-field';
import CustomDialog from '../common/custom-dialog/CustomDialog';
import CustomButton, {
  Colors,
  Variants,
} from '../common/buttons/custom-button';

import hooks from './hooks';
import { useFormatMessage } from '../../intl/intl';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';

import successIconImg from '../../assets/images/successIcon.png';
import { textIds } from './consts';
import useStyles from './styles';

export function SuccessfulSuggestingProductDialog() {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const closeButtonText = useFormatMessage(textIds.closeButtonText);
  const title = useFormatMessage(textIds.title);
  const replaceWithText = useFormatMessage(textIds.replaceWithText);
  const description = useFormatMessage(textIds.description);
  const productPlaceHolder = useFormatMessage(textIds.productPlaceHolder);
  const placeHolderNotHaveProduct = useFormatMessage(
    textIds.placeHolderNotHaveProduct,
  );
  const selectedProduct = hooks.useSelectedProduct();
  const open = hooks.useOpen();
  const userSuggest = hooks.useSuggestion();
  const componentClasses = useStyles();

  const dispatch = useDispatch();
  const handleClose = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  return (
    <CustomDialog
      actions={
        isMdUp
          ? [
              {
                text: closeButtonText,
                onClick: handleClose,
                fullWidth: false,
                disabled: false,
                color: 'primary',
                variant: 'contained',
              },
            ]
          : []
      }
      classes={{
        childrenContainer: componentClasses.body,
        headerRoot: componentClasses.headerRoot,
        paper: componentClasses.paper,
      }}
      CustomDialogHeaderChildren={''}
      onClose={handleClose}
      open={open}
      showBackButton={false}
      showCloseButton={false}
      title={title}
    >
      <div className={componentClasses.container}>
        <img
          src={successIconImg}
          alt='success-icon-img'
          className={componentClasses.successIconImg}
        />
      </div>
      <div className={componentClasses.productName}>{description}</div>
      <div className={componentClasses.containerProduct}>
        <CustomTextField
          disabled
          classes={{
            textFieldRoot: componentClasses.productInput,
            input: componentClasses.input,
            labelOutlined: componentClasses.inputLabelOutlined,
            notchedOutlineInput: componentClasses.inputLabel,
          }}
          name='input'
          value={selectedProduct}
          label={placeHolderNotHaveProduct}
          variant='outlined'
        />
        <CustomTextField
          disabled
          classes={{
            textFieldRoot: componentClasses.replaceInput,
            input: componentClasses.input,
            labelOutlined: componentClasses.inputLabelOutlined,
            notchedOutlineInput: componentClasses.inputLabel,
          }}
          name='input'
          value={userSuggest}
          placeholder={productPlaceHolder}
          label={replaceWithText}
          variant='outlined'
        />
      </div>
      {!isMdUp && (
        <div className={componentClasses.containerFooterButtons}>
          <CustomButton
            classes={{ submitButton: componentClasses.rejectButtonFooter }}
            color={Colors.PRIMARY}
            fullWidth={true}
            onClick={handleClose}
            variant={Variants.CONTAINED}
          >
            {closeButtonText}
          </CustomButton>
        </div>
      )}
    </CustomDialog>
  );
}
