import { CustomEditButton } from '../../../common/buttons/custom-edit-button';
import { CustomDeleteButton } from '../../../common/buttons/custom-delete-button';

import useStyles from './styles';

import type { Interval } from '../../../../redux/schedule-PM-flow-dialog';

export function ReadOnlyInterval(props: Props) {
  const {
    interval,
    disabledButtons,
    onClickEdit = () => {},
    onClickDelete = () => {},
  } = props;

  const componentClasses = useStyles();

  return (
    <div className={componentClasses.root}>
      <div className={componentClasses.intervalContainer}>
        <div className={componentClasses.timeBox}>{interval.startsTime}</div>
        <div className={componentClasses.divider}>a</div>
        <div className={componentClasses.timeBox}>{interval.endsTime}</div>
      </div>
      <div className={componentClasses.buttonContainer}>
        <CustomEditButton
          classes={{
            button: componentClasses.editButton,
            icon: componentClasses.icon,
          }}
          disabled={disabledButtons}
          onClick={onClickEdit}
        />
        <CustomDeleteButton
          classes={{
            button: componentClasses.deleteButton,
            icon: componentClasses.icon,
          }}
          disabled={disabledButtons}
          onClick={onClickDelete}
        />
      </div>
    </div>
  );
}

export interface Props {
  interval: Interval;
  disabledButtons: boolean;
  onClickEdit: () => void | Promise<void>;
  onClickDelete: () => void | Promise<void>;
}
