const scoreTitle = {
  id: 'supportChatScore.setScoreSupport',
}
const feedbackComment = {
  id: 'supportChatScore.feedBackComment',
}
const save = {
  id: 'save',
}

const textIds = {
  scoreTitle,
  feedbackComment,
  save,
}

export default textIds
