// React
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
// External libs
import { get, some } from 'lodash';
// Utils
import OrdersUtils from '../../utils/OrdersUtils';
// Components
import OrderDetailDialogCustomerBox from './OrderDetailDialogCustomerBox';
// Const
import { OrdersType } from '../../constants/OrdersType';

const mapStateToProps = (
  {
    orderDetailDialogReducer: { orderId },
    ordersReducer: { orders },
    partnerReducer: {
      profile: { stores },
      isKanbanUserAppChatEnabled,
    },
  },
  { intl: { formatMessage } },
) => {
  const client = formatMessage({
    id: 'orderDetailDialogCustomerBox.client',
  });
  const alertMessage = formatMessage({
    id: 'order.legalInfoTitle',
  });
  const billTypeLabel = formatMessage({
    id: 'order.billType',
  });
  const showMapButtonText = formatMessage({
    id: 'orderDetailDialogCustomerBox.showMapButtonText',
  });
  const emailLabel = formatMessage({
    id: 'order.email',
  });
  const zipCodeLabel = formatMessage({
    id: 'orderDetailDialogCustomerBox.zipCodeLabel',
  });
  const neighborhoodLabel = formatMessage({
    id: 'orderDetailDialogCustomerBox.neighborhoodLabel',
  });
  const userTypeLabel = formatMessage({
    id: 'orderDetailDialogCustomerBox.userTypeLabel',
  });
  const highValueUserLabel = formatMessage({
    id: 'orderDetailDialogCustomerBox.highValueUserLabel',
  });
  const newUserLabel = formatMessage({
    id: 'orderDetailDialogCustomerBox.newUserLabel',
  });
  const newUserDescription = formatMessage({
    id: 'orderDetailDialogCustomerBox.newUserDescription',
  });
  const propioDetailText = formatMessage({
    id: 'orderDetailDialogCustomerBox.propioDescription',
  });
  const propioLabel = formatMessage({
    id: 'orderDetailDialogCustomerBox.propioLabel',
  });
  const callUserDisclaimer = formatMessage({
    id: 'orderDetailDialogCustomerBox.callUserDisclaimer',
  });
  const tryACallDisclaimer = formatMessage({
    id: 'orderDetailDialogCustomerBox.tryACallDisclaimer',
  });

  const order = orders.find(order => order.id === +orderId);
  const storeId = order && order?.store_id;
  const createdAt = order && order?.created_at;
  const userId = order && order?.detail?.user.id;

  const orderDetail =
    order && order.detail
      ? order.detail
      : {
          id: orderId,
          products: {},
          user: {},
        };

  const user = get(orderDetail, 'user', null);
  const vendors = get(orderDetail, 'vendors', []);
  const showPropioType = some(vendors, { type: OrdersType.PROPIO });
  const isMarketPlace = OrdersUtils.isMarketPlace(orderDetail.delivery_method);

  const email = get(user, 'email', null);

  const hasDocumentType = get(user, 'has_document_type', false);
  const showEmail = hasDocumentType && !!email && email.trim() !== '';
  const showAddress = isMarketPlace && get(user, 'address');
  const showAddressDescription =
    showAddress && get(user, 'address_description');
  const showZipCode = showAddress && get(user, 'zip_code');
  const showNeighborhood = showAddress && get(user, 'neighborhood');
  const showMapButton =
    showAddress && get(user, 'address_lat') && get(user, 'address_lng');
  const billType = get(user, 'bill_type', null);
  const showBillType = hasDocumentType && !!billType && billType.trim() !== '';
  const showBillAlert = hasDocumentType;
  const proxySession = get(orderDetail, 'proxy_session', false);
  const showPhoneBtn = proxySession;

  const documentTypeDescriptionLabel = get(
    user,
    'document_type_description_label',
    null,
  );
  const documentTypeDescriptionValue = get(
    user,
    'document_type_description_value',
    null,
  );

  const documentTypeOwnerLabel = get(user, 'document_type_owner_label', null);
  const documentTypeOwnerValue = get(user, 'document_type_owner_value', null);
  const showTypeDescription =
    !!documentTypeDescriptionLabel &&
    documentTypeDescriptionLabel.trim() !== '' &&
    !!documentTypeDescriptionValue &&
    documentTypeDescriptionValue.trim() !== '';

  const showTypeOwner =
    !!documentTypeOwnerLabel &&
    documentTypeOwnerLabel.trim() !== '' &&
    !!documentTypeOwnerValue &&
    documentTypeOwnerValue.trim() !== '';

  const userAppChatEnabled = get(orderDetail, 'user_app_chat_enabled', true);
  const showSupportWebChatButton =
    userAppChatEnabled && isKanbanUserAppChatEnabled;

  return {
    alertMessage,
    billTypeLabel,
    client,
    emailLabel,
    highValueUserLabel,
    propioDetailText,
    propioLabel,
    showAddress,
    showAddressDescription,
    showBillAlert,
    showBillType,
    showEmail,
    showMapButton,
    showMapButtonText,
    showPhoneBtn,
    showPropioType,
    showTypeDescription,
    showTypeOwner,
    showZipCode,
    showNeighborhood,
    user,
    userTypeLabel,
    zipCodeLabel,
    neighborhoodLabel,
    newUserLabel,
    newUserDescription,
    showSupportWebChatButton,
    orderId,
    storeId,
    createdAt,
    userId,
    callUserDisclaimer,
    tryACallDisclaimer,
  };
};

const mapDispatchToProps = {};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(OrderDetailDialogCustomerBox),
);
