// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  clarification: {
    color: neutral.D15,
    fontSize: 13,
    marginTop: 30,
  },
  content: {
    color: neutral.D20,
    fontFamily: 'Poppins',
    fontSize: 17,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  dialogPaper: {
    width: '488px',
  },
  partnerNextInterval: {
    marginTop: 20,
  },
}));
