// Redux
import { createActions } from 'redux-actions';

const {
  closeRTDialogAction,
  clearRTDialogAction,
  openRTDialogAction,
} = createActions({
  CLOSE_R_T_DIALOG_ACTION: () => {},
  CLEAR_R_T_DIALOG_ACTION: () => {},
  OPEN_R_T_DIALOG_ACTION: orderId => ({ orderId }),
});

const clearRTDialog = () => dispatch => {
  dispatch(clearRTDialogAction());
};

const closeRTDialog = () => dispatch => {
  dispatch(closeRTDialogAction());
};

const openRTDialog = orderId => dispatch => {
  dispatch(openRTDialogAction(orderId));
};

export default {
  clearRTDialog,
  clearRTDialogAction,
  closeRTDialog,
  closeRTDialogAction,
  openRTDialog,
  openRTDialogAction,
};
