import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import CustomDialog from '../../../common/custom-dialog/CustomDialog';
import { OrderMgmtUndoSuggestionDialogStyles } from './OrderMgmtUndoSuggestionDialog.style';
import CustomButton, {
  Types,
  Variants,
} from '../../../common/buttons/custom-button';
import { OrderMgmtAnalytics } from '../../../../business/order-mgmt/analitycs';
import { OrderMgmtDisclaimer } from '../order-mgmt-dialog/components';

export const OrderMgmtUndoSuggestionDialog = ({
  orderId,
  storeId,
  productId,
  toppingId,
  isOpen,
  onClose,
  onConfirm,
  undoSuggestionTitleText,
  undoSuggestionDescriptionText,
  undoSuggestionOkText,
  undoSuggestionCancelText,
  undoSuggestionDisclaimerText,
}: Props) => {
  const componentClasses = OrderMgmtUndoSuggestionDialogStyles();
  const theme = useTheme<any>();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const handleConfirmUndoSuggestion = () => {
    OrderMgmtAnalytics.whenRemoveChanges(
      { orderId, storeId },
      { action: 'undo' },
    );
    onConfirm(productId, toppingId);
  };

  return (
    <CustomDialog
      classes={{
        paper: componentClasses.paper,
        headerRoot: componentClasses.undoSuggestionDialogHeader,
      }}
      onClose={onClose}
      open={isOpen}
      showBackButton={!isMdUp}
      showCloseButton={isMdUp}
    >
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        padding='1rem'
        width='100%'
        height='100%'
      >
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='flex-start'
          marginBottom='1.5rem'
          textAlign='center'
          gridGap='0.5rem'
        >
          <Typography
            className={componentClasses.undoSuggestionDialogHeaderTitle}
          >
            {undoSuggestionTitleText}
          </Typography>
          <Typography
            className={componentClasses.undoSuggestionDialogHeaderDescription}
            dangerouslySetInnerHTML={{ __html: undoSuggestionDescriptionText }}
          />
        </Box>
        <OrderMgmtDisclaimer description={undoSuggestionDisclaimerText} />
        <Box
          display='flex'
          flex={1}
          alignItems='center'
          justifyContent='flex-end'
          gridGap='1rem'
          width='100%'
        >
          <CustomButton
            onClick={onClose}
            type={Types.RESET}
            variant={Variants.TEXT}
            fullWidth={false}
          >
            {undoSuggestionCancelText}
          </CustomButton>
          <CustomButton
            onClick={handleConfirmUndoSuggestion}
            type={Types.BUTTON}
            variant={Variants.CONTAINED}
            fullWidth={false}
          >
            {undoSuggestionOkText}
          </CustomButton>
        </Box>
      </Box>
    </CustomDialog>
  );
};

interface Props {
  orderId: string;
  storeId: string;
  productId: number;
  toppingId: number;
  isOpen: boolean;
  onClose: any;
  onConfirm: any;
  undoSuggestionTitleText: string;
  undoSuggestionDescriptionText: string;
  undoSuggestionOkText: string;
  undoSuggestionCancelText: string;
  undoSuggestionDisclaimerText: string;
}
