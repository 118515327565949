import { clsx } from 'clsx';

import { OrderDetailCard } from '../order-detail-card';

import useStyles from './OrderProductsByCorridor.styles';

export function OrderProductsByCorridor(props) {
  const {
    corridor = { products: [] },
    showAddValueButton = true,
    classes = {},
  } = props;

  const { products } = corridor;
  const sortedProducts = [...products].sort(
    (a, b) => b.has_defect_rate_report - a.has_defect_rate_report,
  );

  const _classes = useStyles();

  return (
    <div className={clsx(classes?.root)}>
      <div className={clsx(_classes.corridor, classes?.corridor)}>
        {corridor.corridor_name.toLowerCase()}
      </div>
      {sortedProducts.map((product, index) => {
        return (
          <OrderDetailCard
            key={`p-${index}`}
            product={product}
            showAddValueButton={showAddValueButton}
          />
        );
      })}
    </div>
  );
}
