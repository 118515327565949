// React
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// External lib
import { find, get, isEmpty } from 'lodash';
// Components
import OrderDetailDialogMainSection from './OrderDetailDialogMainSection';
import OrdersUtils from '../../utils/OrdersUtils';
import PartnerUtils from '../../utils/PartnerUtils';
import { orderMgmtState } from '../../business/order-mgmt';
import { OM_STATE_NAME } from '../../business/order-mgmt/infra';
import { OM_ORDER_MODIFICATION_SLICE_NAME } from '../../business/order-mgmt/infra/store/order-modification';

const mapStateToProps = (
  {
    orderDetailDialogReducer: { orderId },
    ordersReducer: { orders },
    partnerReducer: { profile },
    [OM_STATE_NAME]: {
      [OM_ORDER_MODIFICATION_SLICE_NAME]: { products, currentOrderState },
    },
  },
  { intl: { formatMessage } },
) => {
  const order = find(orders, { id: +orderId });
  const storeId = order?.store_id ?? NaN;

  const orderDetail = get(order, 'detail', null);

  const storeKeeper = get(orderDetail, 'storekeeper', {});

  const isBeingManaged = OrdersUtils.isBeingManaged(order);
  const deliveryMethod = orderDetail?.delivery_method ?? null;
  const isFullDelivery = OrdersUtils.isFullDelivery(deliveryMethod);
  const isInStore = OrdersUtils.isInStore(get(orderDetail, 'state', null));

  const isOrderBeingCooking = OrdersUtils.isBeingCooking(
    get(orderDetail, 'partner_order_state', null),
  );
  const isOrderCancel = OrdersUtils.isCanceled(get(orderDetail, 'state', null));

  const isOrderNew = OrdersUtils.isNew(
    get(orderDetail, 'partner_order_state', null),
  );
  const isOrderReadyAndPack = OrdersUtils.isReadyAndPacked(
    get(orderDetail, 'partner_order_state', null),
  );
  const isOrderDeliveredToRT = OrdersUtils.isDeliveredToRT(
    get(orderDetail, 'partner_order_state', null),
  );
  const orderStatus = get(orderDetail, 'state', null);

  const isFinished = OrdersUtils.isFinished(orderStatus);
  const isRTInStore = OrdersUtils.isRTInStoreFromDetail(orderDetail);

  const isPartnerIntegrated = PartnerUtils.isIntegratedPartner(profile);

  const showCookingTimeInputBox =
    !isPartnerIntegrated && isBeingManaged && isInStore && isOrderNew;

  const showCancellationBox = isBeingManaged && isOrderCancel;

  const isSearchingForRT = OrdersUtils.isSearchingForRTFromDetail(orderDetail);
  const isRTOnTheWay = OrdersUtils.isRTOnTheWayFromDetail(orderDetail);

  const showRTBox =
    !isOrderCancel &&
    isFullDelivery &&
    (isOrderBeingCooking || isOrderReadyAndPack || isOrderDeliveredToRT) &&
    ((!isFinished && (isSearchingForRT || isRTOnTheWay)) ||
      (isRTInStore && !isEmpty(storeKeeper)));

  const showActionBox =
    isBeingManaged &&
    (isOrderCancel ||
      (isInStore &&
        (!isFullDelivery ||
          !isOrderReadyAndPack ||
          (isOrderReadyAndPack && isRTInStore))));

  const isFastOperation = orderDetail?.is_fast_operation
  const isSendingUserApproval =
    currentOrderState === orderMgmtState.SendUserApproval;
  const hasNoUserResponse = currentOrderState === orderMgmtState.NoUserResponse;
  const isInEditionMode = isSendingUserApproval || hasNoUserResponse;
  const hasOrderProductsRemoved = products.some(product => product?.isRemoved);
  const isCookingTimeDisabled = (isInEditionMode && !hasOrderProductsRemoved) || isFastOperation
  const showOrderDetailBox = !!orderDetail?.qr_information ?? null;
  const canEditOrderDetailBox = !isFinished && !isOrderCancel;
  const productConfirmationCode =
    orderDetail?.qr_information?.product_confirmation_code ?? '';
  const orderState = orderDetail?.partner_order_state;
  const isMandatory = orderDetail?.qr_information?.is_mandatory
  const hasDeliveryDetailMessage = orderDetail?.has_input_mandatory_message;
  const isBagQuantitySet = orderDetail?.is_bags_quantity_set

  return {
    showCancellationBox,
    showCookingTimeInputBox,
    showActionBox,
    showRTBox,
    orderId,
    storeId,
    orderStatus,
    deliveryMethod,
    isCookingTimeDisabled,
    showOrderDetailBox,
    productConfirmationCode,
    orderState,
    canEditOrderDetailBox,
    isFastOperation,
    isMandatory,
    isBagQuantitySet,
    hasDeliveryDetailMessage
  };
};

const mapDispatchToProps = {};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(OrderDetailDialogMainSection),
);
