import {
  orderMgmtSearchFacade,
  ordermgmtDialogFacade,
} from '../../../../../business/order-mgmt';

export const useOrderMgmtUndoChangesDialog = () => {
  const {
    selectUndoChangesDialogIsOpen,
    onCloseUndoChangesDialog,
    onConfirmUndoChangesButton,
  } = ordermgmtDialogFacade();
  const { onClearDialog, onClearSearch } = orderMgmtSearchFacade();

  const onConfirm = () => {
    onConfirmUndoChangesButton();
    onClearDialog();
    onClearSearch();
  };

  return {
    isOpen: selectUndoChangesDialogIsOpen,
    onClose: onCloseUndoChangesDialog,
    onConfirm,
  };
};
