import { useFormatMessage } from '../../../intl/intl';

export function useCookingColumnLang() {
  const title = useFormatMessage({ id: 'cookingColumn.title' });
  const noOrders = useFormatMessage({ id: 'cookingColumn.emptyText' });

  return {
    title,
    noOrders,
  };
}
