import { makeStyles } from '@material-ui/core/styles';

import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {
    fontFamily: 'Poppins',
    fontSize: 13,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  box: {
    alignItems: 'center',
    border: `solid 1px ${neutral['20']}`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 4px 5px 16px',
    whiteSpace: 'pre-wrap',
  },
  price: {
    color: neutral['50'],
    marginLeft: 'auto',
    marginRight: 16,
  },
  titleContainer: {
    color: neutral['50'],
    marginBottom: 10,
    whiteSpace: 'pre-wrap',
  },
  infoContainer: {
    width: '30vw',
  },
  generalOrder: {
    order: 1,
  },
  customOrder: {
    order: 1,
    [theme.breakpoints.down('xs')]: {
      order: 2,
      alignItems: 'flex-start',
    },
  },
  toggleSwitch: {
    alignItems: 'flex-end',
  },
}));
