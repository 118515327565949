import { useMainButtonLang } from './useMianButtonLang';

import type { ButtonAppearance } from '../MainButton';

export function useMainButton(props: { appearance: ButtonAppearance }) {
  const { appearance } = props;

  const lang = useMainButtonLang({ appearance });

  return {
    lang,
  };
}
