// React
import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
// Styles
import useStyles from './MenuStoreRequiredBox.styles';

import Box from '../box/Box';
import placeholderImg from '../../assets/images/schedules-placeholder.svg';

const MenuStoreRequiredBox = ({
  loading,
  mustSelectStoreLabel,
  onSelectStore,
  selectStoreLabel,
  title,
}) => {
  const classes = useStyles();
  return (
    <Box
      title={title}
      loading={loading}
      classes={{
        box: classes.root,
        boxContent: classes.placeholderBox,
      }}
    >
      <img
        src={placeholderImg}
        className={classes.placeholderImg}
        alt='placeholder'
      />
      <p className={classes.placeholderBoxText}>{mustSelectStoreLabel}</p>
      <div className={classes.actionContainer}>
        <span className={classes.placeholderBoxAction} onClick={onSelectStore}>
          {selectStoreLabel}
        </span>
      </div>
    </Box>
  );
};

MenuStoreRequiredBox.propTypes = {
  loading: PropTypes.bool,
  title: oneOfType([PropTypes.string, PropTypes.object]),
  onSelectStore: PropTypes.func,
};

export default MenuStoreRequiredBox;
