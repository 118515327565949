import { clsx } from 'clsx';

import icon from '../../../assets/images/restaurant.png';

import useStyles from './RestaurantIcon.styles';

export function RestaurantIcon({ className = {} }) {
  const _classes = useStyles();
  return (
    <img
      className={clsx(_classes.root, className)}
      alt='Restaurant icon'
      src={icon}
    />
  );
}
