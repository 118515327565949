import { clsx } from 'clsx';
import { useState } from 'react';
import { Box } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, Typography, useMediaQuery } from '@material-ui/core';

import { CustomOutlineHeadphonesButton } from '../common/buttons/custom-outline-headphones-button';
import { CustomOutlinePrintButton } from '../common/buttons/custom-outline-print-button';
import { CustomOutlineEditButton } from '../common/buttons/custom-outline-edit-button';
import { CustomMoreVertButton } from '../common/buttons/custom-more-vert-button';

import { useOrderManagement } from '../order-management/hooks';

import useStyles from './OrderDetailDialogHeaderMenu.styles';

function OrderDetailDialogHeaderMenu(props) {
  const {
    classes = {},
    onPrint,
    onSupportButtonClick,
    orderDetail,
    printOrderText,
    showSupport,
    supportText,
    showPrinter,
    // editOrderText, // from itself container
    closeEditionModeText,
    isEditionModeEnabled,
    editionModeDisclaimerText,
    areAllProductsDeactivatedText,
  } = props;

  const _classes = useStyles();
  const options = [printOrderText];
  if (showSupport) {
    options.push(supportText);
  }
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const {
    isDeactivatingItemsState,
    isInEditionMode,
    handleClickReplaceButton,
    handleCloseEditionMode,
    isOrderBeingCooked,
    wasOrderItemsDeactivated,
    areAllProductsDeactivated,
  } = useOrderManagement();

  const onClickOptionItem = option => {
    switch (option) {
      case printOrderText:
        onPrint(orderDetail.id);
        break;
      case supportText:
        onSupportButtonClick();
        break;
      default:
        break;
    }
    handleClose();
  };

  const onClickButton = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      {!isMdUp && (
        <>
          <CustomMoreVertButton
            onClick={onClickButton}
            className={clsx(classes?.icon)}
          />
          <Menu
            id='long-menu'
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200,
              },
            }}
          >
            {options.map((option, index) => (
              <MenuItem
                key={`t-${index}`}
                onClick={() => onClickOptionItem(option)}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
      {isMdUp && (
        <Box
          display='flex'
          alignItems='center'
          gridGap='1rem'
          className={clsx(classes?.desktopRoot)}
        >
          {isOrderBeingCooked && !areAllProductsDeactivated && (
            <Box
              className={_classes.editionModeContainer}
              padding='0.5rem'
              position='relative'
              width='16.75rem'
            >
              <Typography
                className={_classes.editionModeDisclaimer}
                dangerouslySetInnerHTML={{
                  __html: editionModeDisclaimerText || '',
                }}
              />
            </Box>
          )}
          {isInEditionMode && (
            <Button
              color='primary'
              variant='text'
              onClick={handleCloseEditionMode}
              className={_classes.closeEditionMode}
            >
              {closeEditionModeText}
            </Button>
          )}
          <CustomOutlineEditButton
            tooltipText={areAllProductsDeactivatedText}
            showTooltip={areAllProductsDeactivated}
            isDisabled={
              isDeactivatingItemsState ||
              !isEditionModeEnabled ||
              (!isOrderBeingCooked && wasOrderItemsDeactivated) ||
              areAllProductsDeactivated
            }
            isInEditionMode={isInEditionMode}
            onClick={() =>
              isInEditionMode
                ? handleCloseEditionMode()
                : handleClickReplaceButton({ editType: 'Pencil' })
            }
          />
          {showPrinter && (
            <CustomOutlinePrintButton
              classes={{ button: _classes.button }}
              onClick={() => {
                onPrint(orderDetail.id);
              }}
            />
          )}
          {showSupport && (
            <CustomOutlineHeadphonesButton
              onClick={() => {
                onSupportButtonClick(orderDetail.id);
              }}
            />
          )}
        </Box>
      )}
    </div>
  );
}

export default OrderDetailDialogHeaderMenu;
