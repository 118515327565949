// External libs
import { createReducer, PayloadAction } from '@reduxjs/toolkit'
// Interfaces
import { State, LoadActionPayload } from '../interfaces'
// Actions
import actions from '../actions'

export const initialState: State = {
  open: false,
  selectedStoreId: 0,
  storesDiagnosticsCache: {},
}

export default createReducer(initialState, {
  [actions.clearAction.type]: state => clear(state),
  [actions.closeAction.type]: state => close(state),
  [actions.maximizeAction.type]: state => setOpen(state, true),
  [actions.openAction.type]: (
    state,
    { payload: selectedStoreId }: PayloadAction<number>,
  ) => open(state, selectedStoreId),
  [actions.loadAction.type]: (
    state,
    { payload }: PayloadAction<LoadActionPayload>,
  ) => load(state, payload),
})

const clear = (state: State) => {
  return {
    ...initialState,
    storesDiagnosticsCache: state.storesDiagnosticsCache,
  }
}

const close = (state: State) => {
  state.open = false
  return state
}

const load = (state: State, payload: LoadActionPayload) => {
  state.open = true

  state.storesDiagnosticsCache[payload.selectedStoreId] =
    payload.storeDiagnostic

  state.selectedStoreId = payload.selectedStoreId
  return state
}

const open = (state: State, selectedStoreId: number) => {
  state.open = true
  state.selectedStoreId = selectedStoreId
  return state
}

const setOpen = (state: State, open: boolean) => {
  state.open = open
  return state
}
