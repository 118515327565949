import { makeStyles } from '@material-ui/core';
import { neutral, orange, red, teal, yellow } from '../../../shared/styles/colors';

export default makeStyles(() => ({
  root: ({ isInKanban }: Props) => ({
    border: isInKanban ? 0 : `1px solid ${neutral.D10}`,
    padding: isInKanban ? 0 : '1rem 0.625rem',
  }),
  main: ({ isInKanban }: Props) => ({
    backgroundColor: isInKanban ? neutral[10] : neutral.white,
    padding: isInKanban ? '0.5rem 1rem' : 0,
    borderRadius: isInKanban ? '0.5rem' : 0
  }),
  title: {
    fontSize: '0.625rem',
    color: neutral['50'],
    fontWeight: 600,
    textTransform: 'uppercase',
    lineHeight: '1rem',
    letterSpacing: '1.2px',
  },
  checkCodeText: {
    fontSize: '0.75rem',
    fontWeight: 600,
    color: neutral['80'],
  },
  checkCode: {
    fontSize: '0.75rem',
    fontWeight: 600,
    lineHeight: '1.25rem',
    color: neutral['80'],
    borderRadius: '62.4375rem',
    backgroundColor: neutral['20'],
    padding: '0.25rem 0.5rem',
  },
  bagsQuantityText: {
    fontSize: '0.75rem',
    fontWeight: 600,
    color: neutral['80'],
  },
  quantityButton: {
    borderRadius: '50%',
    backgroundColor: teal[50],
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: neutral.white,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
  quantityButtonDisabled: {
    borderRadius: '50%',
    backgroundColor: neutral['20'],
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: neutral.white,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'not-allowed',
  },
  quantityText: {
    fontSize: '0.75rem',
    fontWeight: 600,
    color: neutral['80'],
  },
  drinksText: {
    fontSize: '0.75rem',
    fontWeight: 600,
    color: neutral['80'],
  },
  radioGroupOptions: {
    display: 'flex',
    flexDirection: 'row',
  },
  radioRootButton: {
    margin: '0',
    flexDirection: 'row-reverse',
    '& .MuiRadio-root': {
      padding: 0,
      marginLeft: '0.25rem',
    },
  },
  radioLabelButton: {
    fontSize: '0.75rem',
    fontWeight: 600,
    color: neutral['80'],
  },
  loading: {
    color: neutral.D15,
    fontSize: '0.625rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '0.031235rem',
    animationDuration: '0.5s',
    animationName: 'fadeIn',
  },
  success: {
    color: teal.D5,
    fontSize: '0.625rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '0.031235rem',
    animationDuration: '0.5s',
    animationName: 'fadeIn',
  },
  successIcon: {
    color: teal.D5,
    fontSize: '0.75rem',
    fontWeight: 'bold',
    animationDuration: '0.5s',
    animationName: 'fadeIn',
  },
  error: {
    color: red.D10,
    fontSize: '0.625rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '0.031235rem',
    animationDuration: '0.5s',
    animationName: 'fadeIn',
  },
  errorIcon: {
    color: red[40],
    fontSize: '0.75rem',
    fontWeight: 'bold',
    animationDuration: '0.5s',
    animationName: 'fadeIn',
  },
  retry: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  mandatory: {
    color: red[60],
    fontSize: '0.625rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    lineHeight: '1rem',
    letterSpacing: '1.2px',
  },
  alert: {
    fontSize: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: yellow.D5,
    color: orange.D4,
    '& svg': {
      fill: orange.D4
    }
  },
  cta: {
    alignSelf: 'flex-end'
  },
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
}));

interface Props {
  isInKanban?: boolean;
}
