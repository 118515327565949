import Paths from '../../constants/Paths';
import { infoArticleId, infoCategoryId } from '../store-status/constants';

export const SUSPENDED_NOTIFY_SS = 'suspended-stores-notify';

export const MORE_INFO_FULL_URL = Paths.goToHelpCenterWithCategoryAndArticle(
  infoCategoryId,
  infoArticleId,
);

export const OPTIONS_TO_ANALYTICS = {
  close: 'CLOSE',
  moreInfo: 'NEED_MORE_HELP',
  activeStore: 'ACTIVATE_MY_STORE',
};
