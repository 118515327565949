// React
import React from 'react';
import PropTypes from 'prop-types';
// Material UI

// Components
import CustomSwitch from '../custom-switch/CustomSwitch';
import { CustomItemsDeactivationDrawer } from '../custom-items-deactivation-drawer';
import { useDispatch } from 'react-redux';
import {
  analitycsEvent,
  GAActions,
  GACategories,
} from '../../../utils/ga-utils/GAUtils';
import menuActions from '../../../redux/actions/menu.actions';
// Styles

const CustomToggleSwitchOptions = ({
  active,
  item,
  isProduct,
  partnerId,
  deactivatedItemId,
  setDeactivatedItemId,
}) => {
  const isDeactivatedItemSelected = deactivatedItemId === item?.id;
  const dispatch = useDispatch();

  const handleClick = event => {
    event.stopPropagation();

    if (active) {
      setDeactivatedItemId(item?.id);
      return;
    }

    if (isProduct) {
      analitycsEvent(
        GACategories.MENU_MANAGEMENT,
        GAActions.ACTIVATE_PRODUCTS,
        `partner_id=${partnerId}`,
      );
      dispatch(menuActions.setProductAvailability(item?.id, true));
      return;
    }

    if (!isProduct) {
      analitycsEvent(
        GACategories.MENU_MANAGEMENT,
        GAActions.ACTIVATE_TOPPING,
        `partner_id=${partnerId}`,
      );
      dispatch(
        menuActions.setToppingAvailability(
          true,
          item?.toppingCategoryId,
          item?.id,
        ),
      );
      return;
    }
  };

  const handleClose = () => {
    setDeactivatedItemId(null);
  };

  return (
    <>
      <CustomSwitch checked={active} onClick={handleClick} />
      {isDeactivatedItemSelected && (
        <CustomItemsDeactivationDrawer
          open={isDeactivatedItemSelected}
          onClose={handleClose}
          item={item}
          isProduct={isProduct}
          partnerId={partnerId}
        />
      )}
    </>
  );
};

CustomToggleSwitchOptions.propTypes = {
  active: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  deactivatedItemId: PropTypes.number,
  setDeactivatedItemId: PropTypes.func,
};

export default CustomToggleSwitchOptions;
