import { OrderMgmtProduct } from '../../../../domain/models';
import {
  ItemsToRemoveRequest,
  RemoveToppingListDTO,
} from '../../../core/requests';
import { stateOMType } from '../../../core/responses';

export interface OM_OrderDetailState {
  orderId: number;
  productIdList: string[];
  toppingIdList: RemoveToppingListDTO[];
  itemsToRemove: ItemsToRemoveRequest;
  products: Array<OrderMgmtProduct>;
  detailProducts: Array<OrderMgmtProduct>;
  isOpenDialog: boolean;
  isCountdownComplete: boolean;
  isDisabled: boolean;
  orderState: stateOMType;
  status: 'idle' | 'isLoading' | 'successful' | 'rejected';
  startedAt: string;
}

export const INITIAL_STATE: OM_OrderDetailState = {
  orderId: 0,
  isCountdownComplete: false,
  productIdList: [],
  toppingIdList: [],
  itemsToRemove: {},
  products: [],
  detailProducts: [],
  isOpenDialog: false,
  isDisabled: false,
  orderState: '',
  status: 'idle',
  startedAt: ''
};
