// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => ({
  imageRoot: {
    minWidth: 100,
    maxHeight: 61,
    maxWidth: 100,
    objectFit: 'cover',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
  },
  productContainer: {
    color: neutral.D20,
    lineHeight: 'normal',
  },
  infoContainer: {
    display: 'flex',
    overflowWrap: 'break-word',
    width: '30vw',
    alignItems: 'center',
  },
  containerInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 24,
    whiteSpace: 'pre-wrap',
  },
  description: {
    fontSize: 12,
    color: neutral.D15,
    '--lh': '1.4rem',
    '--max-lines': 3,
    lineHeight: 'var(--lh)',
    maxHeight: 'calc(var(--lh) * var(--max-lines))',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '12rem',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '7rem',
    },
  },
  generalOrder: {
    order: 1,
  },
  customOrder: {
    order: 1,
    [theme.breakpoints.down('xs')]: {
      order: 2,
      alignItems: 'flex-start',
    },
  },
  toggleSwitch: {
    alignItems: 'flex-end',
  },
}));
