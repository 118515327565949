import { useSelector } from 'react-redux';

import { selectors as justCookedOrderIssueDialogSelectors } from '../../../redux/just-cooked-order-issue-dialog';
import selectors from '../selectors';

const useDescription = () =>
  useSelector(justCookedOrderIssueDialogSelectors.selectDescription);

const useOpen = () =>
  useSelector(justCookedOrderIssueDialogSelectors.selectOpen);

const useOptions = () => {
  const options = useSelector(selectors.selectOptions);
  return options ?? [];
};

const useOrderId = () =>
  useSelector(justCookedOrderIssueDialogSelectors.selectOrderId);

const useTitle = () => useSelector(selectors.selectTitle);

const hooks = {
  useDescription,
  useOpen,
  useOptions,
  useOrderId,
  useTitle,
};

export default hooks;
