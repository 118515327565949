// Material UI
import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  body: {
    height: '100%',
  },
  headerRoot: {
    [theme.breakpoints.up('md')]: {
      padding: 12,
    },
  },
  checkerRoot: {},
  paper: {
    [theme.breakpoints.up('md')]: {
      height: 608,
      width: 620,
    },
  },
  root: {},
}));
