import { StartupPrinterBanner, StartupPrinterDialog } from './printer';

import { BrowserBanner, BrowserDialog, useBrowserApp } from './chrome-browser';

export function StartupNotifications() {
  const { showBanner } = useBrowserApp();

  return (
    <>
      {showBanner && <BrowserBanner />}
      <StartupPrinterBanner />

      <BrowserDialog />
      <StartupPrinterDialog />
    </>
  );
}
