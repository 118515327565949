import { Bullets } from '../bullets';
import CustomButton, { Colors } from '../../../common/buttons/custom-button';

import useStyles from './styles';

import type { BaseProps } from '../../../../interfaces';

export function Footer({
  currentStep = 0,
  totalSteps = 0,
  onPreviousStep = () => {},
  onLastStep = () => {},
  onNextStep = () => {},
  startText = '',
  backText = '',
  nextText = '',
  finalText = '',
}: Props) {
  const componentClasses = useStyles();
  const isLastStep = currentStep === totalSteps - 1;
  const isFirstStep = currentStep === 0;
  return (
    <div className={componentClasses.footer}>
      <div className={componentClasses.containerFooter}>
        <div className={componentClasses.bullets}>
          <Bullets activeStep={currentStep} steps={totalSteps} />
        </div>
        <div className={componentClasses.containerButtons}>
          {isFirstStep ? (
            <div></div>
          ) : (
            <CustomButton
              classes={{
                root: componentClasses.rootBackButton,
                submitButton: componentClasses.backButton,
              }}
              color={Colors.PRIMARY}
              onClick={onPreviousStep}
              fullWidth={false}
              disabled={false}
            >
              {backText}
            </CustomButton>
          )}
          {isFirstStep && (
            <CustomButton
              classes={{
                root: componentClasses.rootSubmitButton,
                submitButton: componentClasses.submitButton,
              }}
              color={Colors.PRIMARY}
              onClick={onNextStep}
              fullWidth={false}
              disabled={false}
            >
              {startText}
            </CustomButton>
          )}
          {!isFirstStep && !isLastStep && (
            <CustomButton
              classes={{
                root: componentClasses.rootSubmitButton,
                submitButton: componentClasses.submitButton,
              }}
              color={Colors.PRIMARY}
              onClick={onNextStep}
              fullWidth={false}
              disabled={false}
            >
              {nextText}
            </CustomButton>
          )}
          {isLastStep && (
            <CustomButton
              classes={{
                root: componentClasses.rootSubmitButton,
                submitButton: componentClasses.submitButton,
              }}
              color={Colors.PRIMARY}
              onClick={onLastStep}
              fullWidth={false}
              disabled={false}
            >
              {finalText}
            </CustomButton>
          )}
        </div>
      </div>
    </div>
  );
}

interface Props extends BaseProps {
  currentStep: number;
  totalSteps: number;
  onPreviousStep: any;
  onNextStep: any;
  startText: string;
  nextText: string;
  finalText: string;
  onLastStep: any;
  backText: string;
}
