export const EVENT_NAME = 'EVENT_CHROME';
export const EVENT_NAME_HAS_EXTENSION = 'EVENT_HAS_CHROME_EXTENSION';

export const propertiesNames = {
  partnerId: 'PARTNER_ID',
  action: 'ACTION_TYPE',
  component: 'MODAL_TYPE',
  type: 'TYPE',
  test: 'TEST',
} as const;

export const ACTION_TYPE = {
  open: 'open',
  need_help: 'need_help',
  install: 'install',
  closed: 'closed',
  outside: 'outside',
} as const;

export const TEST_TYPE = {
  ['']: '',
  a: 'a',
  b: 'b',
} as const;

export type ActionType = keyof typeof ACTION_TYPE;
export type TestType = keyof typeof TEST_TYPE;
