// External libs
import { createSelector } from 'reselect';
// Consts
import RefuseReasons from '../../../constants/RefuseReasons';
import { textIds } from '../consts';
// Tools
import { formatMessage } from '../../../intl/intl';
// Selectors
import { selectors as OrderRejectionByStockOutDialogSelectors } from '../../../redux/order-rejection-by-stock-out-dialog';

const selectLabel = createSelector<any, string, string>(
  OrderRejectionByStockOutDialogSelectors.selectRefuseReasonEnum,
  refuseReasonEnum => {
    let labelId;
    switch (refuseReasonEnum) {
      case RefuseReasons.BADLY_CONFIGURED_PRODUCT:
        labelId = textIds.badlyConfiguredProductLabel;
        break;
      case RefuseReasons.NO_LONGER_HANDLE_PRODUCT:
        labelId = textIds.noLongerHandleProductLabel;
        break;
      case RefuseReasons.RAN_OUT_PRODUCTS:
      default:
        labelId = textIds.ranOutProductsLabel;
    }

    return formatMessage(labelId);
  },
);

const selectors = { selectLabel };

export default selectors;
