// React
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// External libs
import { get, find } from 'lodash';
// Utils
import OrdersUtils from '../../utils/OrdersUtils';
// Actions
import AppActions from './../../redux/actions/app-actions';
import OrderActions from './../../redux/actions/orders-actions';
// Components
import OrderDetailDialogHeaderMenu from './OrderDetailDialogHeaderMenu';
import PartnerUtils from '../../utils/PartnerUtils';

const mapStateToProps = (
  {
    orderDetailDialogReducer: { orderId },
    ordersReducer: { orders },
    partnerReducer: { isChatV2Available, profile },
  },
  { intl: { formatMessage } },
) => {
  const title = formatMessage({ id: 'ordersKanbanPage.title' });
  const printOrderText = formatMessage({
    id: 'orderDetailDialogHeaderMenu.printOrder',
  });
  const supportText = formatMessage({
    id: 'globals.support',
  });
  const editOrderText = formatMessage({
    id: 'orderDetailDialogHeaderMenu.editOrder',
  });
  const closeEditionModeText = formatMessage({
    id: 'orderDetailDialogHeaderMenu.closeEditionMode',
  });
  const editionModeDisclaimerText = formatMessage({
    id: 'orderDetailDialogHeaderMenu.editionModeDisclaimer',
  });
  const areAllProductsDeactivatedText = formatMessage({
    id: 'orderDetailDialogHeaderMenu.areAllProductsDeactivated',
  });

  const order = find(orders, { id: +orderId });
  const orderDetail = get(order, ['detail'], {});

  const isBeingManaged = OrdersUtils.isBeingManaged(order);
  const isPartnerIntegrated = PartnerUtils.isIntegratedPartner(profile);

  const showSupport =
    isBeingManaged &&
    !isChatV2Available &&
    !OrdersUtils.isMarketPlace(orderDetail.delivery_method) &&
    !OrdersUtils.isPickup(orderDetail.delivery_method) &&
    !OrdersUtils.isFinished(orderDetail.state) &&
    !OrdersUtils.isNew(orderDetail.partner_order_state);

  const showPrinter = !(
    OrdersUtils.isNew(orderDetail.partner_order_state) &&
    OrdersUtils.isMarketPlace(orderDetail.delivery_method)
  );

  const isEditionModeEnabled =
    isBeingManaged &&
    !isPartnerIntegrated &&
    (OrdersUtils.isInStore(orderDetail.partner_order_state) ||
      OrdersUtils.isNew(orderDetail.partner_order_state) ||
      OrdersUtils.isBeingCooking(orderDetail.partner_order_state));

  const isBeingCooking = OrdersUtils.isBeingCooking(
    orderDetail.partner_order_state,
  );

  return {
    title,
    orderDetail,
    printOrderText,
    supportText,
    showSupport,
    showPrinter,
    editOrderText,
    closeEditionModeText,
    editionModeDisclaimerText,
    areAllProductsDeactivatedText,
    isEditionModeEnabled,
    isBeingCooking,
  };
};

const mapDispatchToProps = {
  openSnackBarError: AppActions.openSnackBarError,
  onPrint: OrderActions.sendToPrint,
  onSupportButtonClick: OrderActions.openSupport,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(OrderDetailDialogHeaderMenu),
);
