import { styled } from '@material-ui/core'
import { Button } from '@material-ui/core'
import Colors from '../../../../../constants/Colors'

export const TurnoffButton = styled(Button)({
  backgroundColor: Colors.PRIMARY_COLOR,
  color: Colors.WHITE,
  fontStyle: 'Poppins',
  padding: '8px 16px 8px 16px',
  '&:hover': {
    backgroundColor: Colors.PRIMARY_COLOR_DARK,
  },
})
