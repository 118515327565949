// Consts
import urls from '../../constants/Urls'
// Utils
import UrlUtils from '../../utils/url-utils'
// Services
import http from '../HttpService'
import LocalStorageService from '../local-storage-service'

const sendSupportCalification = async (
  partnerId: number,
  storeId: any,
  orderId: number,
  comment: string,
  rate: number,
) => {
  const microserviceUrl = LocalStorageService.getBaseUrl()
  const payload = {
    partner_id: partnerId,
    store_id: storeId,
    order_id: orderId,
    comment: comment,
    rating: rate,
  }
  const replacedUrl = UrlUtils.replaceUrlParams(urls.SEND_SUPPORT_CALIFICATION)
  return http.post(`${microserviceUrl}${replacedUrl}`, payload)
}

const getSupportCalification = async (orderId: number) => {
  const microserviceUrl = LocalStorageService.getBaseUrl()
  const replacedUrl = UrlUtils.replaceUrlParams(urls.GET_SUPPORT_CALIFICATION, {
    orderId,
  })
  return http.get(`${microserviceUrl}${replacedUrl}`)
}
const supportRateService = {
  sendSupportCalification,
  getSupportCalification,
}

export default supportRateService
