// Material UI
import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  headerRoot: {
    [theme.breakpoints.up('md')]: {
      padding: 12,
    },
  },
  paper: {
    height: 300,
    width: 488,
  },
  fontText: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '100',
    margin: '0px',
    padding: '40px 24px 0px 24px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  body: {
    overflow: 'hidden',
  },
}))
