import { makeStyles } from '@material-ui/core';

import { VARIANT } from '../../shared/consts';

import type {
  SaturationIconProps,
  SaturationIconSizeOpt,
} from './SaturationIcon.model';

const SIZE: SaturationIconSizeOpt = {
  small: {
    width: '1.0rem',
    height: '1.0rem',
  },
  medium: {
    width: '1.5rem',
    height: '1.5rem',
  },
  large: {
    width: '2.0rem',
    height: '2.0rem',
  },
};

export default makeStyles({
  icon: ({ variant, color, size = 'medium' }: SaturationIconProps) => ({
    width: SIZE[size].width,
    height: SIZE[size].height,
    [variant === 'regular' ? 'stroke' : 'fill']: !!color
      ? color
      : VARIANT[variant].color,
  }),
});
