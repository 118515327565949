import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../shared/styles/colors';

export default makeStyles(() => ({
  article: {
    padding: '1rem',
    display: 'grid',
    columnGap: '0.5rem',
    alignItems: 'center',
    gridTemplateColumns: '1fr auto',
    color: neutral['70'],
    cursor: 'pointer',
    borderRadius: '0.5rem',
    animationName: '$pulse',
    animationDuration: '1s',
    animationFillMode: 'both',
    animationIterationCount: 'infinite',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '0.25rem',
  },
  status: {
    textAlign: 'right',
    textTransform: 'uppercase',
  },
  fw600: {
    fontWeight: 600,
    color: neutral['90'],
  },
  storeName: {
    fontWeight: 600,
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
  },
  turboTag: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  '@keyframes pulse': {
    from: {
      transform: 'scale3d(1, 1, 1)',
    },
    '50%': {
      transform: 'scale3d(1.05, 1.05, 1.05)',
    },
    to: {
      transform: 'scale3d(1, 1, 1)',
    },
  },
}));
