// External libs
import { createSelector } from 'reselect'

const selectSlice = (state: any) => state.phonePMFlowDialog

const selectOpen = createSelector(selectSlice, reducer => reducer.open)
const selectFormId = createSelector(selectSlice, reducer => reducer.formId)
const selectPhone = createSelector(selectSlice, reducer => reducer.phone)
const selectPhoneErrorMessage = createSelector(
  selectSlice,
  reducer => reducer.errorMessage,
)

const selectors = {
  selectOpen,
  selectFormId,
  selectPhone,
  selectPhoneErrorMessage,
}

export default selectors
