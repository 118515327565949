import { makeStyles } from '@material-ui/core';
import Colors from '../../../constants/Colors';

export default makeStyles(theme => ({
  dialog: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      bottom: 0,
      height: '100vh',
      padding: '1rem',
      position: 'absolute',
      right: 0,
      top: 0,
      width: '42.5vw',
      overflowY: 'hidden',
      margin: 0,
      maxHeight: '100vh',
    },
  },
  actionBtn: {
    padding: '0.5rem 1rem',
    fontSize: '1rem',
    lineHeight: 1.5,
    '&.Mui-disabled': {
      opacity: 0.4,
      color: Colors.WHITE,
      backgroundColor: Colors.PRIMARY_COLOR,
    },
  },
}));
