import { useSelector } from 'react-redux';
import { selectors as partnerSelectors } from '../../../redux/partner';
import { find, get } from 'lodash';
import PartnerUtils from '../../../utils/PartnerUtils';
import OrdersUtils from '../../../utils/OrdersUtils';
import OrdersSelector from '../../../redux/selectors/orders-selectors/orders.selectors';
import OrderDetailDialogSelectors from '../../../redux/selectors/order-detail-dialog-selectors/orderDetailDialog.selectors';

const useOrders = () => useSelector(OrdersSelector.selectOrders);

const useProfile = () => useSelector(partnerSelectors.selectProfile);

const useOrderId = () => useSelector(OrderDetailDialogSelectors.selectOrderId);

const useOrderDetail = () => {
  const orders = useOrders();
  const orderId = useOrderId();

  return get(find(orders, { id: +orderId }), ['detail'], {
    id: +orderId,
    storekeeper: { id: 0 },
  });
};

const useShowNewOrderActions = () => {
  const profile = useProfile();
  const orderDetail = useOrderDetail();
  const isPartnerIntegrated = PartnerUtils.isIntegratedPartner(profile);
  const isInStore = OrdersUtils.isInStore(orderDetail.state);
  return (
    !isPartnerIntegrated &&
    isInStore &&
    OrdersUtils.isNew(orderDetail.partner_order_state)
  );
};

const useShowOnHoldOrderActions = () => {
  const profile = useProfile();
  const orderDetail = useOrderDetail();
  const isPartnerIntegrated = PartnerUtils.isIntegratedPartner(profile);
  const isInStore = OrdersUtils.isInStore(orderDetail.state);
  return (
    !isPartnerIntegrated &&
    isInStore &&
    OrdersUtils.isOnHold(orderDetail.partner_order_state)
  );
};

const useStoreId = () => {
  const orderDetail = useOrderDetail();
  const storeId = orderDetail?.stores && orderDetail?.stores[0]?.store_id;
  return storeId;
};

const useHasOrderStateManagement = () => {
  const orderDetail = useOrderDetail();
  return !!orderDetail?.state_order_management;
};

const useIsOrderStateManagementAccepted = () => {
  const orderDetail = useOrderDetail();
  return orderDetail?.state_order_management?.state === 'accepted';
};

const useIsOrderStateManagementTimeOut = () => {
  const orderDetail = useOrderDetail();
  return orderDetail?.state_order_management?.state === 'timeout';
};

export const hooks = {
  useOrderDetail,
  useShowNewOrderActions,
  useShowOnHoldOrderActions,
  useProfile,
  useStoreId,
  useHasOrderStateManagement,
  useIsOrderStateManagementAccepted,
  useIsOrderStateManagementTimeOut,
  useOrderId,
};
