export function RightArrowIcon() {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='filled/filled_chevron_right'>
        <path
          id='color'
          d='M7.1928 4.00011C7.44973 4.00011 7.71341 4.10011 7.90949 4.29344L11.0129 7.34677C11.405 7.73344 11.405 8.36677 11.0129 8.76011L7.90949 11.8201C7.51734 12.2068 6.87502 12.2068 6.47611 11.8201C6.08396 11.4334 6.08396 10.8001 6.47611 10.4068L8.86282 8.06011L6.47611 5.70011C6.08396 5.31344 6.08396 4.68011 6.47611 4.28677C6.67895 4.09344 6.93587 4.00011 7.1928 4.00011Z'
          fill='#1B1E23'
        />
      </g>
    </svg>
  );
}
