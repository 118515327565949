import { find, get } from 'lodash';
import { createActions } from 'redux-actions';

import EventActions from '../event-actions';
import GAUtils from '../../../utils/ga-utils';
import { PWEXUtils } from '../../../exceptions';
import Amplitude from '../../../tools/amplitude';
import { formatMessage } from '../../../intl/intl';
import StringUtils from '../../../utils/StringUtils';

import appStore from '../../stores/app.store';
import CountriesService from '../../../services/CountriesService';
import LocalStorageService from '../../../services/local-storage-service';
import PartnerStoreLogsService from '../../../services/partner-store-logs-service';
import ChromeExtensionService from '../../../services/ChromeExtensionService';

import { APP_VERSION } from '../../../constants/commons';

const {
  closeSnackbarAction,
  closeWelcomeDialogAction,
  loadCountryCodeFromLocalStorageAction,
  loadCountryFlagFromLocalStorageAction,
  openSnackbarErrorAction,
  openSnackbarInfoAction,
  openSnackbarSuccessAction,
  openSnackbarWarningAction,
  openWelcomeDialogAction,
  setLanguageAction,
  setNewUpdateOnReloadAction,
  setOfflineAction,
  setOnlineAction,
} = createActions({
  CLOSE_WELCOME_DIALOG_ACTION: () => {},
  CLOSE_SNACKBAR_ACTION: () => {},
  OPEN_WELCOME_DIALOG_ACTION: () => {},
  OPEN_SNACKBAR_ERROR_ACTION: (message, title, autoHideDuration) => ({
    message,
    title,
    autoHideDuration,
  }),
  OPEN_SNACKBAR_INFO_ACTION: (message, title, autoHideDuration) => ({
    message,
    title,
    autoHideDuration,
  }),
  OPEN_SNACKBAR_SUCCESS_ACTION: (message, title, autoHideDuration) => ({
    message,
    title,
    autoHideDuration,
  }),
  OPEN_SNACKBAR_WARNING_ACTION: (message, title, autoHideDuration) => ({
    message,
    title,
    autoHideDuration,
  }),
  SET_LANGUAGE_ACTION: language => ({
    language,
  }),
  LOAD_COUNTRY_FLAG_FROM_LOCAL_STORAGE_ACTION: countryFlag => ({ countryFlag }),
  LOAD_COUNTRY_CODE_FROM_LOCAL_STORAGE_ACTION: countryCode => ({ countryCode }),
  SET_NEW_UPDATE_ON_RELOAD_ACTION: () => ({}),
  SET_OFFLINE_ACTION: () => ({}),
  SET_ONLINE_ACTION: () => ({}),
});

const handleCatch = (
  ex,
  openErrorSnackBar = true,
  logInAmplitude = true,
) => async dispatch => {
  if (!ex) {
    return;
  }

  const partnerId = appStore.getPartnerId();
  const exFormatted = PWEXUtils.formatEx(ex, partnerId);

  if (openErrorSnackBar) {
    AppActions.openSnackBarError(exFormatted.message)(dispatch);
  }

  if (logInAmplitude) {
    Amplitude.logException(exFormatted);
  }

  GAUtils.analitycsEvent(
    GAUtils.GACategories.ERRORS,
    GAUtils.GAActions.NO_TAGGED_ERRORS,
    exFormatted.GAData,
  );
};

const showError = (formatMessageObj, formatMessageParams) => async dispatch => {
  const message = formatMessage(formatMessageObj, formatMessageParams);
  const ex = { message };
  await handleCatch(ex)(dispatch);
};

const closeSnackBar = () => dispatch => {
  return dispatch(closeSnackbarAction());
};

const closeWelcomeDialog = () => dispatch => {
  return dispatch(closeWelcomeDialogAction());
};
const openWelcomeDialog = () => dispatch => {
  return dispatch(openWelcomeDialogAction());
};

const openSnackBarError = (message, title, autoHideDuration) => dispatch => {
  dispatch(openSnackbarErrorAction(message, title, autoHideDuration));
};

const openSnackBarInfo = (message, title, autoHideDuration) => dispatch => {
  return dispatch(openSnackbarInfoAction(message, title, autoHideDuration));
};

const openSnackBarSuccess = (message, title, autoHideDuration) => dispatch => {
  return dispatch(openSnackbarSuccessAction(message, title, autoHideDuration));
};

const openSnackBarWarning = (message, title, autoHideDuration) => dispatch => {
  return dispatch(openSnackbarWarningAction(message, title, autoHideDuration));
};

const setLanguage = language => dispatch => {
  return dispatch(setLanguageAction(language));
};

const loadCountryCodeFromLocalStorage = () => async dispatch => {
  try {
    const countryCode = LocalStorageService.getCountryCode();
    if (StringUtils.isNullOrEmpty(countryCode)) {
      console.error('no se encontro el CountryCode');
      return;
    }
    dispatch(loadCountryCodeFromLocalStorageAction(countryCode));
  } catch (ex) {
    await handleCatch(ex)(dispatch);
  }
};

const loadCountryFlagFromLocalStorage = () => async dispatch => {
  try {
    let countryFlag = LocalStorageService.getFlag();
    if (StringUtils.isNullOrEmpty(countryFlag)) {
      const countries = await CountriesService.getAllAvailable();
      const country = find(countries, {
        code: LocalStorageService.getCountryCode(),
      });
      countryFlag = get(country, 'flag', null);
      LocalStorageService.setFlag(countryFlag);
    }
    dispatch(loadCountryFlagFromLocalStorageAction(countryFlag));
  } catch (ex) {
    await handleCatch(ex)(dispatch);
  }
};
/**
 * Sets app state to offline.
 * @function setOffline
 */
const setOffline = () => async dispatch => {
  const isOffline = appStore.getIsOffline();
  if (isOffline) {
    return;
  }

  const partnerId = appStore.getPartnerId();
  const hasExtension = !!(await ChromeExtensionService.isAvailable());
  EventActions.onNetworkError(partnerId, hasExtension, APP_VERSION);
  dispatch(setOfflineAction());

  const tz = appStore.getTz();

  LocalStorageService.setNetworkErrorEvent(hasExtension, tz);
};

/**
 * Sets app state to online.
 * @function setOnline
 */
const setOnline = () => async dispatch => {
  const isOffline = appStore.getIsOffline();

  if (!isOffline) {
    return;
  }

  const hasExtension = !!(await ChromeExtensionService.isAvailable());

  const partnerId = appStore.getPartnerId();

  EventActions.onConnectionEstablished(partnerId, hasExtension, APP_VERSION);

  const tz = appStore.getTz();

  await PartnerStoreLogsService.tryCreateNetworkConnectionIssue(partnerId, tz);

  return dispatch(setOnlineAction());
};

const setNewUpdateOnReload = (message, title) => dispatch => {
  return dispatch(setNewUpdateOnReloadAction(message, title));
};

const AppActions = {
  closeSnackBar,
  closeSnackbarAction,
  closeWelcomeDialog,
  closeWelcomeDialogAction,
  handleCatch,
  loadCountryCodeFromLocalStorage,
  loadCountryCodeFromLocalStorageAction,
  loadCountryFlagFromLocalStorage,
  loadCountryFlagFromLocalStorageAction,
  openSnackBarError,
  openSnackbarErrorAction,
  openSnackBarInfo,
  openSnackbarInfoAction,
  openSnackBarSuccess,
  openSnackbarSuccessAction,
  openSnackBarWarning,
  openSnackbarWarningAction,
  openWelcomeDialog,
  openWelcomeDialogAction,
  setLanguage,
  setLanguageAction,
  setNewUpdateOnReload,
  setNewUpdateOnReloadAction,
  setOffline,
  setOfflineAction,
  setOnline,
  setOnlineAction,
  showError,
};

export default AppActions;
