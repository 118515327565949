import { makeStyles } from '@material-ui/core';

import { neutral, red, teal } from '../../../../../../shared/styles/colors';
import { BOX_SHADOW_OPT_2, VARIANT } from '../../../../shared/consts';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    height: '100%',
  },
  alertMaxUses: {
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    borderRadius: '0.5rem',
    color: red['60'],
    backgroundColor: red['10'],
    '& >[class*="-icon"]': {
      fontSize: '1.5rem',
      alignItems: 'center',
      color: red['60'],
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    letterSpacing: 1.12,
    color: neutral['70'],
  },
  main: {
    paddingBottom: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '0.5rem',
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {
      overflowX: 'unset',
    },
    '&::-webkit-scrollbar': {
      background: 'transparent',
    },
  },
  sectionMergeSuperAndHigh: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  superAndHighSectionTitle: {
    fontWeight: 600,
  },
  containerSuperAndHigh: {
    display: 'flex',
    gap: '0.5rem',
  },
  btnOptionSuperAndHigh: {
    width: 'fit-content',
    padding: '0.5rem 0.75rem',
    fontWeight: 600,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    boxShadow: 'none',
    borderRadius: '0.5rem',
    color: VARIANT.high.color,
    backgroundColor: neutral.white,
    border: `1px solid ${VARIANT.high.color}`,
    '&:hover': {
      boxShadow: BOX_SHADOW_OPT_2,
      backgroundColor: VARIANT.high.bgColor,
    },
    '&[aria-selected="true"]': {
      color: neutral.white,
      backgroundColor: VARIANT.high.color,
    },
  },
  sectionTTL: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  ttlSectionTitle: {
    fontWeight: 600,
  },
  containerTTLOpts: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '0.5rem',
  },
  extendedText: {
    color: teal.D5,
    fontSize: '0.875rem',
    fontWeight: 600,
  },
  deactivateText: {
    color: red.D9,
    fontSize: '0.875rem',
    fontWeight: 600,
  },
  actionButton: {
    borderRadius: '0.5rem',
    border: `1px solid ${neutral.D10}`,
    padding: '0.75rem',
  },
  actionButtonLabel: {
    display: 'flex',
    gridGap: '0.5rem',
    justifyContent: 'flex-start',
  },
}));
