import { clsx } from 'clsx';
import { Box, Dialog, useMediaQuery } from '@material-ui/core';

import { OrderTypeChip } from '../../order-type-chip';
import { CustomDialogFooter } from '../custom-dialog-footer';
import { CustomDialogHeader } from '../custom-dialog-header';

import useStyles from './CustomDialog.styles';

function CustomDialog(props) {
  const {
    classes = {},
    children,
    CustomDialogHeaderChildren,
    onClickBackButton = () => {},
    onClose = () => {},
    onBackdropClick = () => {},
    onEscapeKeyDown = () => {},
    open = false,
    showBackButton = false,
    showCloseButton = false,
    title = '',
    actions = [],
    disableBackdropClick = false,
    disableEnforceFocus = false,
    disableEscapeKeyDown = false,
    showDialogHeader = true,
    totalProducts = 0,
    ordersSameBundle = [],
  } = props;

  const _classes = useStyles();
  const isPrinting = useMediaQuery('print');

  return (
    <>
      {!isPrinting && (
        <Dialog
          classes={{
            container: clsx(_classes.container, classes?.container),
            paper: clsx(_classes.paper, classes?.paper),
            root: clsx(_classes.root, classes?.root),
          }}
          BackdropProps={{
            classes: {
              root: classes.backdrop,
            },
          }}
          onClose={onClose}
          onBackdropClick={onBackdropClick}
          onEscapeKeyDown={onEscapeKeyDown}
          open={open}
          disableBackdropClick={disableBackdropClick}
          disableEnforceFocus={disableEnforceFocus}
          disableEscapeKeyDown={disableEscapeKeyDown}
        >
          {showDialogHeader && (
            <Box component='header' p='1rem 0.25rem 1rem 1rem'>
              <OrderTypeChip
                orderType={ordersSameBundle.length ? 'bundle' : 'normal'}
              />
              <CustomDialogHeader
                classes={{
                  root: clsx(classes?.headerRoot),
                  title: clsx(classes?.title),
                  closeButton: clsx(classes?.closeButton),
                }}
                onClickBackButton={onClickBackButton}
                onClickCloseButton={onClose}
                showBackButton={showBackButton}
                showCloseButton={showCloseButton}
                title={title}
                totalProducts={totalProducts}
              >
                {CustomDialogHeaderChildren}
              </CustomDialogHeader>
            </Box>
          )}
          <div
            className={clsx(
              _classes.childrenContainer,
              classes?.childrenContainer,
            )}
          >
            {children}
          </div>
          {!!actions && !!actions.length && (
            <CustomDialogFooter
              actions={actions}
              classes={{ root: clsx(_classes.footerRoot, classes?.footerRoot) }}
            />
          )}
        </Dialog>
      )}
    </>
  );
}

export default CustomDialog;
