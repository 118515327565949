// TODO: REMOVE WHEN GET STORES DATA
export const MAP_CENTER = { lat: 4.678695, lng: -74.055456 };
export const MAP_ZOOM = 15;

export const DELAY_DEBOUNCE_TIME = 800;
export const PULL_INTERVAL_TIME = 60_000;
export const PULL_STORES_INTERVAL_TIME = 900_000; //15min

export const FETCH_LIMIT_CARGO_ORDER_LIST = 100;
export const FETCH_OFFSET_CARGO_ORDER_LIST = 1;

export const AUTO_HIDE_TIME_DURATION = 2_500;

export const HOW_WORKS_CARGO_URL = 'http://cargo-ra-faq.rappi.com.co';

export const MAX_NUMBER_OF_CHARACTER_ALLOWED = 255;

export const MIN_PRICE_ALLOWED = 1;

export const MIN_NUMBER_OF_COORDINATES_ALLOWED = 6;
