import { makeStyles } from '@material-ui/core';
import { colorTheme } from '../../../../../shared/styles/colors';
import { turboCreationAtColor } from '../../../../../shared/styles/colors/feat';

export const turboTagStyles = makeStyles({
  img: {
    transform: 'scale(0.6)',
  },
  container: ({ orderCreationStatus }: Props) => ({
    backgroundColor:
      turboCreationAtColor[orderCreationStatus] || colorTheme.turbo.tag,
    borderRadius: '0.5rem 0',
    padding: '0 0.25rem',
  }),
});

interface Props {
  orderCreationStatus: string;
}
