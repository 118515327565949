import { useState, useEffect } from 'react';
import { clsx } from 'clsx';

import DefaultImage from './DefaultImage';

import useStyles from './ImagePlaceholder.style';

export function ImagePlaceholder({ src, classes = {} }) {
  const [error, setError] = useState(false);
  const _classes = useStyles();

  useEffect(() => {
    setError(false);
  }, [src]);

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      {error && <DefaultImage />}
      {!error && (
        <img
          src={src}
          alt=''
          className={clsx(_classes.img, classes?.img)}
          onError={() => setError(true)}
        />
      )}
    </div>
  );
}
