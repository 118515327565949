import { makeStyles } from '@material-ui/styles';

import {
  neutral,
  purple,
  red,
  teal,
  yellow,
} from '../../../shared/styles/colors';
import { ELEVATED } from '../../../shared/styles/shadows';

export default makeStyles(theme => ({
  rowCard: {
    marginBottom: '1rem',
    padding: '0 1rem',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1.25rem',
    color: neutral['80'],
    boxShadow: ELEVATED.strong,
    backgroundColor: neutral.white,
    borderBottom: `1px solid ${neutral['20']}`,
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${neutral['30']}`,
    },
    [theme.breakpoints.up('lg')]: {
      margin: 0,
      flexDirection: 'row',
    },
    '& > div[role="cell"]': {
      flexGrow: 0,
      padding: '0.5rem 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: neutral.white,
      borderBottom: `1px solid ${neutral['20']}`,
      '& > label': {
        display: 'inline-flex',
        fontWeight: 600,
        textTransform: 'uppercase',
        [theme.breakpoints.up('lg')]: {
          display: 'none',
        },
      },
      [theme.breakpoints.up('lg')]: {
        padding: '0.75rem',
        borderBottom: 'none',
        '&[data-tag="cellState"]': {
          padding: '0.75rem 0',
        },
        '&[data-tag="cellOrderId"]': {
          flexGrow: 0.9,
          flexBasis: '8.75rem',
        },
        '&[data-tag="cellDateTime"]': {
          flexGrow: 1.3,
          flexBasis: '12.5rem',
        },
        '&[data-tag="cellPrice"]': {
          flexGrow: 0.84,
          flexBasis: '7.5rem',
        },
        '&[data-tag="cellRT"]': {
          flexGrow: 1.24,
          flexBasis: '11.875rem',
        },
        '&[data-tag="cellAdditionalRT"]': {
          flexGrow: 0.92,
          flexBasis: '9.0rem',
          padding: '0.75rem 0.25rem',
        },
        '&[data-tag="cellQualify"]': {
          flexGrow: 0.85,
          flexBasis: '8.125rem',
          justifyContent: 'center',
        },
        '&[data-tag="cellOpenDetail"]': {
          flexGrow: 0.2,
          flexBasis: '2.0rem',
          padding: '0.75rem 0',
        },
      },
    },
  },
  state: {
    width: 'fit-content',
    minWidth: '8.75rem',
    margin: 0,
    padding: '0.5rem 0.75rem',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      minWidth: '7.75rem',
      maxWidth: '7.75rem',
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: '10.0rem',
      maxWidth: '10.0rem',
    },
  },
  finished: {
    color: teal['60'],
    backgroundColor: teal['10'],
  },
  inStore: {
    color: yellow['70'],
    backgroundColor: yellow['10'],
  },
  canceled: {
    color: red['50'],
    backgroundColor: red['10'],
  },
  rt: {
    margin: 0,
    display: 'inline-flex',
    alignItems: 'center',
    columnGap: '0.5rem',
    '& > svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  AddRTLabel: {
    columnGap: '0.25rem',
    alignItems: 'center',
  },
  addRTLabelTooltip: {
    maxWidth: '13rem',
    padding: '0.5rem',
    fontSize: '0.75rem',
    fontWeight: 600,
    lineHeight: '1.25rem',
    borderRadius: '0.5rem',
    color: neutral.white,
    backgroundColor: neutral['90'],
  },
  AddRTBtn: {
    padding: '0.25rem 0.5rem',
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontWeight: 'normal',
    lineHeight: '1.25rem',
    borderRadius: '0.5rem',
    color: neutral['10'],
    backgroundColor: purple['50'],
    '&:hover': {
      color: neutral.white,
      backgroundColor: purple['70'],
    },
    '&[disabled]': {
      color: neutral['10'],
      backgroundColor: purple['20'],
    },
  },
  AddRTBtnEndIcon: {
    margin: 0,
    marginLeft: '0.25rem',
  },
  openDetailIcon: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'inline-block',
    },
  },
  openDetailText: {
    margin: 0,
    padding: '0.5rem 0',
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
    color: teal['50'],
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));
