import useMediaQuery from '@material-ui/core/useMediaQuery';

import OrdersKanbanTabs from '../orders-kanban-tabs';
import OrdersKanbanAdvancedFiltersToolbar from '../orders-kanban-advanced-filters-toolbar';

import useStyles from './OrdersKanbanLayout.styles';
import { SwipeableViews } from '../common/swipeable-views';

function OrdersKanbanLayout({ children, index, onChangeIndex, title }) {
  const componentClasses = useStyles();
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  return (
    <div className={componentClasses.root}>
      {!isMdUp && (
        <>
          <OrdersKanbanTabs />
          <OrdersKanbanAdvancedFiltersToolbar title={title} />
          <SwipeableViews
            index={index}
            onChangeIndex={onChangeIndex}
            className={componentClasses.swipeableViewsRoot}
          >
            {children}
          </SwipeableViews>
        </>
      )}
      {isMdUp && (
        <>
          <OrdersKanbanAdvancedFiltersToolbar title={title} />
          <div className={componentClasses.board}>{children}</div>
        </>
      )}
    </div>
  );
}

export default OrdersKanbanLayout;
