// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import Colors from '../../../constants/Colors';
import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => ({
  dangerValue: {
    backgroundColor: `${Colors.SECONDARY_COLOR} !important`,
    color: neutral.white,
  },
  helperText: {
    color: neutral.D15,
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    fontSize: 12,
    textAlign: 'right',
    width: '100%',
  },
  root: {
    color: neutral.D20,
    display: 'grid',
    gridRowGap: 5,
    gridTemplateRows: 'min-content',
    fontFamily: 'Poppins Bold',
    fontSize: 14,
    fontWeight: 'bold',
    justifyContent: 'end',
    justifyItems: 'end',
    lineHeight: 'normal',
  },
  value: {
    borderRadius: 10,
    border: `solid 1px ${neutral.D10}`,
    textAlign: 'center',
    padding: '1px 0px',
    width: 60,
  },
}));
