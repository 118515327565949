// React
import { useCallback } from 'react';
// Const
import { textIds } from './consts';
// External libs
import { get, find } from 'lodash';
// Tools
import { useFormatMessage } from '../../intl/intl';
// Hooks
import hooks from './hooks';
// Components
import CustomDialog from '../common/custom-dialog';
import CustomMobileDialogFooter from '../common/custom-mobile-dialog-footer';
import Typography, {
  Variants,
  FontWeight,
} from '../../components/common/typography';
// Styles
import useStyles from './styles';
// Material UI
import { useDispatch } from 'react-redux';
import { useTheme, useMediaQuery } from '@material-ui/core';
// Actions
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';
import EventActions from '../../redux/actions/event-actions/event.actions';
// Image
import { Banner } from './banner';
/// Store
import appStore from '../../redux/stores/app.store';
// Actions
import { actions as endPMFlowDialogActions } from '../../redux/end-PM-flow-dialog';

const IMAGE = [
  { breakpoint: 'md', url: process.env.REACT_APP_PM_FLOW_HAND_IMAGE_MD },
  { breakpoint: 'lg', url: process.env.REACT_APP_PM_FLOW_HAND_IMAGE_MD },
  { breakpoint: 'xl', url: process.env.REACT_APP_PM_FLOW_HAND_IMAGE_MD },
  { breakpoint: 'xs', url: process.env.REACT_APP_PM_FLOW_HAND_IMAGE_XS },
  { breakpoint: 'sm', url: process.env.REACT_APP_PM_FLOW_HAND_IMAGE_SM },
];

export function EndPMFlowDialog() {
  const dispatch = useDispatch();
  const componentClasses = useStyles();
  const open = hooks.useOpen();
  const formId = hooks.useFormId();
  const storeId = hooks.useStoreId();
  const title = useFormatMessage(textIds.title);
  const submitText = useFormatMessage(textIds.submitButton);
  const cancelText = useFormatMessage(textIds.cancelButton);
  const description = useFormatMessage(textIds.description);
  const bannerDescription = useFormatMessage(textIds.bannerDescription);

  const handleClose = useCallback(async () => {
    dispatch(dialogsManagerActions.close());
    dispatch(endPMFlowDialogActions.sendSkipCloseForm(formId));
  }, [dispatch, formId]);

  const handleBack = useCallback(() => {
    dispatch(dialogsManagerActions.openPhonePMFlowDialog(formId));
  }, [dispatch, formId]);

  const onConfirm = useCallback(async () => {
    const partnerId = appStore.getPartnerId();
    dispatch(endPMFlowDialogActions.sendCompletePMFlow(formId));
    EventActions.onSendCompletePMDialog(partnerId, storeId);
    dispatch(dialogsManagerActions.close());
  }, [dispatch, formId, storeId]);

  const theme = useTheme<any>();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const mediaQueryCode = hooks.useMediaQueryCode(theme);
  const imageSelected = find(IMAGE, { breakpoint: mediaQueryCode });
  const image = get(imageSelected, 'url', '');

  return (
    <CustomDialog
      classes={{
        childrenContainer: componentClasses.body,
        headerRoot: componentClasses.headerRoot,
        title: componentClasses.titleDialog,
        paper: componentClasses.paper,
      }}
      actions={
        isMdUp
          ? [
              {
                text: cancelText,
                onClick: handleBack,
                color: 'primary',
                variant: 'outlined',
                fullWidth: false,
              },
              {
                text: submitText,
                onClick: onConfirm,
                color: 'primary',
                variant: 'contained',
                fullWidth: false,
              },
            ]
          : []
      }
      CustomDialogHeaderChildren={''}
      onClose={handleClose}
      open={open}
      showBackButton={false}
      showCloseButton
      title={title}
      disableBackdropClick
      disableEnforceFocus
      disableEscapeKeyDown
    >
      <div className={componentClasses.root}>
        <Banner
          classes={{ root: componentClasses.banner }}
          bannerDescription={bannerDescription}
          backgroundImg={image}
        />
        <Typography
          classes={{ root: componentClasses.info }}
          fontWeight={FontWeight.Regular}
          variant={Variants.Body}
        >
          {description}
        </Typography>
      </div>
      {!isMdUp && (
        <CustomMobileDialogFooter
          onConfirm={onConfirm}
          onBack={handleBack}
          submitText={submitText}
          cancelText={cancelText}
        />
      )}
    </CustomDialog>
  );
}
