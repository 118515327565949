import { makeStyles } from '@material-ui/core';

import type { OMCardColors } from './models';
import Colors from '../../../../constants/Colors';

export const KanbanOMCardStyle = makeStyles(() => ({
  root: ({ cardColors }: OMCardColors) => ({
    backgroundColor: cardColors.background,
    color: cardColors.color,
    border: `solid 1px ${cardColors.border}`,
    borderRadius: 8,
    boxShadow: '0 0.25rem .5rem 0 rgba(0, 0, 0, 0.1)',
  }),
  childContainer: {
    cursor: 'pointer',
  },
  orderId: {
    fontSize: '1rem',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    '&::before': {
      content: '"ID: "',
    },
  },
  disclaimerBox: ({ cardColors }: Partial<OMCardColors>) => ({
    backgroundColor: cardColors?.box,
    padding: '0.25rem',
    borderRadius: '0.5rem',
  }),
  disclaimerText: ({ cardColors }: Partial<OMCardColors>) => ({
    fontSize: '0.625rem',
    fontWeight: 'bold',
    userSelect: 'none',
    color: cardColors?.boxText || 'inherit',
    background: cardColors?.backgroundBox || 'inherit',
    padding: cardColors?.padding || 0,
    borderRadius: cardColors?.borderRadius || 0,
    display: 'flex',
    gridGap: '0.25rem',
    alignItems: 'center',
  }),
  clock: {
    color: Colors.BROWN,
    fontSize: '1rem',
    fontWeight: 'bold',
  },
}));

export const OMCardStyle = makeStyles(() => ({
  progressLabel: {
    textTransform: 'uppercase',
    fontSize: '0.625rem',
    fontWeight: 'bold',
    letterSpacing: '1px',
    userSelect: 'none',
  },
}));
