import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import PartnerActions from '../../redux/actions/partners-actions';
import SalesSummaryActions from '../../redux/actions/salesSummary.actions';

import SummarySalesBill from './SummarySalesBill';

const mapStateToProps = (
  {
    partnerReducer: { profile },
    salesSummaryReducer: { filter, salesSummary, pendingToPrint },
  },
  { intl: { formatMessage } },
) => {
  const selectedStore =
    profile.stores.find(store => store.id === +filter.selectedStoreId) || {};

  const selectedDate = `${moment(filter.selectedDate).format('YYYY-MM-DD')}`;

  const SummarySales = formatMessage({ id: 'menu.sales.summary' });
  const date = formatMessage({ id: 'date' });
  const storeName = formatMessage({ id: 'storeName' });
  const finishedTitle = formatMessage({ id: 'store.summary.finished' });
  const canceledTitle = formatMessage({ id: 'store.summary.canceled' });
  const ordersTitle = formatMessage({ id: 'store.summary.orders' });
  const totalAccumulated = formatMessage({
    id: 'store.summary.totalAccumulated',
  });
  const totalWithDiscount = formatMessage({
    id: 'store.summary.totalWithDiscount',
  });
  const totalDiscount = formatMessage({ id: 'store.summary.totalDiscount' });

  const cancelOrders = salesSummary?.canceled_orders ?? {};
  const finishOrders = salesSummary?.finished_orders ?? {};

  const finishOrderDiscount = finishOrders?.discounts ?? null;
  const showFinishOrderDiscount = Boolean(finishOrderDiscount);
  const cancelOrderDiscount = cancelOrders?.discounts ?? null;
  const showCancelOrderDiscount = Boolean(cancelOrderDiscount);
  const finishOrderWithDiscount = finishOrders?.total_with_discounts ?? null;
  const showTotalFinishOrderWithDiscount = Boolean(finishOrderWithDiscount);
  const cancelOrderWithDiscount = cancelOrders?.total_with_discounts ?? null;
  const showTotalCancelOrderWithDiscount = Boolean(cancelOrderWithDiscount);

  return {
    cancelOrders,
    canceledTitle,
    date,
    finishedTitle,
    finishOrders,
    ordersTitle,
    pendingToPrint: pendingToPrint,
    salesSummary: salesSummary,
    selectedDate,
    selectedStore,
    showCancelOrderDiscount,
    showFinishOrderDiscount,
    showTotalCancelOrderWithDiscount,
    showTotalFinishOrderWithDiscount,
    storeName,
    SummarySales,
    totalAccumulated,
    totalDiscount,
    totalWithDiscount,
  };
};

const mapDispatchToProps = {
  getSalesSummary: PartnerActions.getSalesSummary,
  onPrint: SalesSummaryActions.confirmSalesSummaryToPrint,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SummarySalesBill),
);
