// React
import { connect } from 'react-redux';
// Components
import MarketingPage from './MarketingPage';
// Actions
import { injectIntl } from 'react-intl';

const mapStateToProps = ({ partnerReducer }, { intl: { formatMessage } }) => {
  const title = formatMessage({
    id: 'menu.marketing',
  });
  const campaginTitle = formatMessage({
    id: 'marketing.campaignTitle',
  });
  const downloadApp = formatMessage({
    id: 'marketing.downloadApp',
  });
  const downloadAppContent = formatMessage({
    id: 'marketing.downloadAppContent',
  });
  const donwloadAppButton = formatMessage({
    id: 'marketing.downloadAppButton',
  });
  const goPortalButton = formatMessage({
    id: 'marketing.goPortalButton',
  });
  const donwloadAppGrowth = process.env.REACT_APP_DOWNLOAD_GROWTH_APP;
  const goToPortalPartners = process.env.REACT_APP_GO_PORTAL_PARTNERS;

  return {
    title,
    campaginTitle,
    downloadApp,
    downloadAppContent,
    donwloadAppButton,
    goPortalButton,
    donwloadAppGrowth,
    goToPortalPartners,
    partnerId: partnerReducer.profile.id,
  };
};
const mapDispatchToProps = {};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(MarketingPage),
);
