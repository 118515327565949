// React
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
// External libs
import moment from 'moment'
// Actions
import appStore from './../../redux/stores/app.store'
import AppActions from './../../redux/actions/app-actions'
import SalesSummaryActions from '../../redux/actions/salesSummary.actions'
// Components
import SalesSummaryAdvancedFilterToolbar from './SalesSummaryAdvancedFilterToolbar'

const mapStateToProps = (
  { salesSummaryReducer, partnerReducer },
  { intl: { formatMessage } },
) => {
  const selectedStore = partnerReducer.profile.stores.find(
    store => store.id === +salesSummaryReducer.filter.selectedStoreId,
  )

  const selectedFilterOptions = []

  if (selectedStore) {
    const selectedStoreFilterOption = {
      label: `${selectedStore.name}`,
      id: selectedStore.id,
      onDelete: () => {
        SalesSummaryActions.clearFilter()(appStore.store.dispatch)
      },
    }

    const selectedDateFilterOption = {
      label: `${moment(salesSummaryReducer.filter.selectedDate).format(
        'YYYY-MM-DD',
      )}`,
      id: '1',
      onDelete: () => {
        SalesSummaryActions.clearFilter()(appStore.store.dispatch)
      },
    }

    selectedFilterOptions.push(selectedStoreFilterOption)
    selectedFilterOptions.push(selectedDateFilterOption)
  }

  return {
    selectedFilterOptions,
  }
}

const mapDispatchToProps = {
  onClick: SalesSummaryActions.openFilterDrawer,
  openSnackBarError: AppActions.openSnackBarError,
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SalesSummaryAdvancedFilterToolbar),
)
