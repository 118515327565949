import React from 'react';
import useStyles from '../../ExtendClosingHoursDialog.styles';
import { ExtendClosingHourListProps } from '../../types';
import { ExtendClosingHourItem } from '../extend-closing-hour-item';
import { Grid } from '@material-ui/core';
import { getCurrentTime } from '../../utils';

export const ExtendClosingHourList: React.FC<ExtendClosingHourListProps> = ({
  storeDaySchedules,
  setExtendSchedules,
}) => {
  const componentClasses = useStyles();
  const hasOneTurn = storeDaySchedules.length <= 1;

  return (
    <Grid
      className={componentClasses.extendClosingHoursList}
      container
      spacing={1}
    >
      {storeDaySchedules.map(
        (
          {
            id,
            starts_time,
            ends_time,
            previous_end_time,
            next_starts_time,
            skip_error_time,
            is_starts_time_disabled,
            is_ends_time_disabled,
          },
          idx,
        ) => (
          <ExtendClosingHourItem
            key={`ExtendClosingHourItem-${id}`}
            idx={idx}
            scheduleId={id}
            hasOneTurn={hasOneTurn}
            startTime={
              hasOneTurn
                ? getCurrentTime()
                : is_starts_time_disabled
                ? starts_time
                : getCurrentTime()
            }
            endTime={ends_time}
            previousEndTime={previous_end_time}
            nextStartTime={next_starts_time}
            skipErrorTime={skip_error_time}
            isStartTimeDisabled={is_starts_time_disabled}
            isEndTimeDisabled={is_ends_time_disabled}
            setExtendSchedules={setExtendSchedules}
          />
        ),
      )}
    </Grid>
  );
};
