import { makeStyles } from '@material-ui/core';

export default makeStyles({
  root: {
    margin: 'auto 0',
  },
  input: {
    height: '1.25rem',
    marginLeft: '0.5rem',
    '&::placeholder': {
      fontWeight: 'normal',
    },
  },
  adornedStart: {
    paddingLeft: '0.5rem',
    height: '100%',
  },
});
