const PWEXCodes = {
  PWEX0: 'PWEX0',
  PWEX1: 'PWEX1',
  PWEX2: 'PWEX2',
  PWEX3: 'PWEX3',
  PWEX4: 'PWEX4',
  PWEX5: 'PWEX5',
  PWEX6: 'PWEX6',
  PWEX7: 'PWEX7',
  PWEX8: 'PWEX8',
  PWEX9: 'PWEX9',
  PWEX10: 'PWEX10',
  PWEX11: 'PWEX11',
  PWEX12: 'PWEX12',
  PWEX13: 'PWEX13',
  PWEX14: 'PWEX14',
}

export default PWEXCodes
