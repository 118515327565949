import { clsx } from 'clsx';

import { ImagePlaceholder } from '../image-placeholder';
import MenuProductDeactivationOptions from '../menu-product-deactivation-options';

import useStyles from './ProductBox.styles';
import { ensureHttps } from '../../utils/url-utils/UrlUtils';

function ProductBox(props) {
  const {
    classes = {},
    product,
    showDetails = false,
    deactivatedItemId = null,
    setDeactivatedItemId = () => {},
  } = props;

  const _classes = useStyles();

  const { corridorName, description, image, is_available, name } = product;
  const imageHttps = ensureHttps(image);

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <ImagePlaceholder
        src={imageHttps}
        classes={{
          root: clsx(classes?.imageRoot),
          img: clsx(_classes.imageSmall, classes?.imageSmall),
        }}
      />
      <div
        className={clsx(
          _classes.productInfoContainer,
          classes?.productInfoContainer,
        )}
      >
        <div
          className={clsx(
            _classes.productNameContainer,
            classes?.productNameContainer,
          )}
        >
          <span className={_classes.productName}>{name}</span>
        </div>
        {showDetails && (
          <div
            className={clsx(
              _classes.productDetailsContainer,
              classes?.productDetailsContainer,
            )}
          >
            <span className={_classes.corridorName}>{corridorName}</span>
            <span className={_classes.productDescription}>{description}</span>
          </div>
        )}
      </div>
      <div className={_classes.actionContainer}>
        <MenuProductDeactivationOptions
          product={product}
          isAvailable={is_available}
          deactivatedItemId={deactivatedItemId}
          setDeactivatedItemId={setDeactivatedItemId}
        />
      </div>
    </div>
  );
}

export default ProductBox;
