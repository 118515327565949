// Redux
import { createActions } from 'redux-actions';

const {
  closeOrderRejectionByStockOutDialogAction,
  clearOrderRejectionByStockOutDialogAction,
  openOrderRejectionByStockOutDialogAction,
} = createActions({
  CLOSE_ORDER_REJECTION_BY_STOCK_OUT_DIALOG_ACTION: () => {},
  CLEAR_ORDER_REJECTION_BY_STOCK_OUT_DIALOG_ACTION: () => {},
  OPEN_ORDER_REJECTION_BY_STOCK_OUT_DIALOG_ACTION: (orderId, refuseReason) => ({
    orderId,
    refuseReason,
  }),
});

const clearOrderRejectionByStockOutDialog = () => dispatch => {
  dispatch(clearOrderRejectionByStockOutDialogAction());
};

const closeOrderRejectionByStockOutDialog = () => dispatch => {
  dispatch(closeOrderRejectionByStockOutDialogAction());
};

const openOrderRejectionByStockOutDialog = (
  orderId,
  refuseReason,
) => dispatch => {
  dispatch(openOrderRejectionByStockOutDialogAction(orderId, refuseReason));
};

export default {
  clearOrderRejectionByStockOutDialog,
  clearOrderRejectionByStockOutDialogAction,
  closeOrderRejectionByStockOutDialog,
  closeOrderRejectionByStockOutDialogAction,
  openOrderRejectionByStockOutDialog,
  openOrderRejectionByStockOutDialogAction,
};
