import { createSelector } from 'reselect'

const selectStoresSchedulesReducer = (state: any) =>
  state.storesSchedulesReducer

const selectAreStoresSchedulesLoading = createSelector(
  selectStoresSchedulesReducer,
  storesSchedulesReducer => storesSchedulesReducer.areStoresSchedulesLoading,
)

const selectStoresSchedules = createSelector(
  selectStoresSchedulesReducer,
  storesSchedulesReducer => storesSchedulesReducer.storesSchedules,
)

const selectStoresSchedulesFilter = createSelector(
  selectStoresSchedulesReducer,
  storesSchedulesReducer => storesSchedulesReducer.filter,
)

export const StoresSchedulesSelectors = {
  selectAreStoresSchedulesLoading,
  selectStoresSchedules,
  selectStoresSchedulesFilter,
}
