import { useEffect } from 'react';

import { useCargoAlerts } from './useCargoAlerts';
import { CargoUICase } from '../../../business/cargo';

export function useCargo() {
  const { isEnabledHeaderBtn, isVisibleHeaderBtn } = CargoUICase();
  const isEnabledMainDialog = isVisibleHeaderBtn && isEnabledHeaderBtn;

  useCargoAlerts();

  useEffect(() => {
    if (isEnabledMainDialog) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_PLACE_API_KEY}&libraries=places`;
      script.async = true;
      document.body.appendChild(script);
    }
  }, [isEnabledMainDialog]);

  return {
    isEnabledHeaderBtn,
    isVisibleHeaderBtn,
    isEnabledMainDialog,
  };
}
