import CustomAvatar from './../common/custom-avatar';

import useStyles from './RtInfoCardSupportChat.style';

function RtInfoCardSupportChat(props) {
  const {
    storeKeeperId,
    storeKeeperName,
    storeKeeperPhone,
    storeKeeperLastName,
    storeKeeperPhoto,
  } = props;
  const classes = useStyles();
  if (!storeKeeperId) return null;
  return (
    <div className={classes.flexContainer}>
      <div>
        <CustomAvatar
          alt='Remy Sharp'
          letter={storeKeeperName && storeKeeperName.charAt(0)}
          src={storeKeeperPhoto}
        />
      </div>
      <div className={classes.flexContainerTwo}>
        <div className={classes.rtName}>
          RT: {storeKeeperName} {storeKeeperLastName}
        </div>
        <div className={classes.fontRtinfo}>
          <span>ID: {storeKeeperId}</span>
          <span className={classes.marginPhone}>Tel: {storeKeeperPhone}</span>
        </div>
      </div>
    </div>
  );
}

export default RtInfoCardSupportChat;
