import { clsx } from 'clsx';
import TextField from '@material-ui/core/TextField';

import StringUtils from '../../../utils/StringUtils';
import useStyles from './CustomTextField.styles';

function CustomTextField(props) {
  const {
    classes = {},
    endAdornment = <></>,
    error = false,
    helperText = '',
    highlight = false,
    label = '',
    maxLength = 0,
    minLength = 0,
    placeholder = '',
    readOnly = false,
    required = false,
    startAdornment = <></>,
    useExternalLabel = false,
    value,
    disabled = false,
    autoFocus = false,
    fullWidth = true,
    margin = 'none',
    variant = 'outlined',
    ...extraProps
  } = props;

  const _classes = useStyles();

  const inputLabelProps = {
    classes: {
      root: clsx(_classes.rootLabel, classes?.rootLabel),
      outlined: clsx(_classes.labelOutlined, classes?.labelOutlined),
    },
  };

  const emailInputProps = {
    classes: {
      adornedEnd: clsx(classes?.adornedEnd),
      adornedStart: clsx(classes?.adornedStart),
      input: clsx(_classes.input, classes?.input),
      root: clsx(
        _classes.rootInput,
        classes?.rootInput,
        highlight && _classes.rootInputHiglighted,
      ),
      notchedOutline: clsx(
        variant !== 'standard' && classes.notchedOutlineInput,
      ),
    },
    inputProps: {
      maxLength: maxLength === 0 ? undefined : maxLength,
      minLength,
    },
    endAdornment: endAdornment,
    readOnly: readOnly,
    startAdornment: startAdornment,
  };

  const newPlaceholder =
    required && placeholder ? `${placeholder} *` : placeholder;

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      {useExternalLabel && !StringUtils.isNullOrEmpty(label) && (
        <div className={_classes.labelContainer}>{label}</div>
      )}
      <TextField
        classes={{ root: clsx(classes?.textFieldRoot) }}
        error={error}
        helperText={helperText}
        InputLabelProps={inputLabelProps}
        InputProps={emailInputProps}
        label={useExternalLabel ? null : label}
        placeholder={newPlaceholder}
        required={required}
        value={value === null ? '' : value}
        disabled={disabled}
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        margin={margin}
        variant={variant}
        {...extraProps}
      />
    </div>
  );
}

export default CustomTextField;
