import moment from 'moment/moment';
import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';

import CustomButton, {
  Colors,
  Variants,
} from '../common/buttons/custom-button';
import Typography, {
  Variants as TypographyVariant,
  FontWeight,
} from '../../components/common/typography';
import { PMStepper } from '../PM-stepper';
import { DayGroup } from './components/day-group';
import CustomDialog from '../common/custom-dialog';

import hooks from './hooks';
import { useFormatMessage } from '../../intl/intl';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';

import { textIds } from './consts';
import iconClock from '../../assets/images/pm-flow/clock.png';

import useStyles from './styles';

export function SchedulePMFlowDialog() {
  const open = hooks.useOpen();
  const schedulesDays = hooks.useStoreSchedule();
  const formId = hooks.useFormId();
  const componentClasses = useStyles();
  const theme = useTheme<any>();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const title = useFormatMessage(textIds.title);
  const subTitle = useFormatMessage(textIds.subTitle);
  const backText = useFormatMessage(textIds.backText);
  const submitText = useFormatMessage(textIds.submitText);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  const handleBack = useCallback(() => {
    dispatch(dialogsManagerActions.openPhonePMFlowDialog(formId));
  }, [dispatch, formId]);

  const onConfirm = useCallback(() => {
    dispatch(dialogsManagerActions.openEndPMFlowDialog(formId));
  }, [dispatch, formId]);

  const hourList = useMemo(() => {
    let arry: string[] = [];
    for (let hour = 0; hour < 24; hour++) {
      arry.push(moment(`${hour}:0}`, 'H:m').format('HH:mm'));
      for (let minute = 1; minute <= 11; minute++) {
        arry.push(moment(`${hour}:${minute * 5}`, 'H:m').format('HH:mm'));
      }
    }
    arry.push('23:59');
    return arry;
  }, []);

  return (
    <CustomDialog
      classes={{
        childrenContainer: componentClasses.body,
        headerRoot: componentClasses.headerRoot,
        paper: componentClasses.paper,
        title: componentClasses.dialogTitle,
      }}
      CustomDialogHeaderChildren={''}
      onClose={handleClose}
      onClickBackButton={handleBack}
      open={open}
      showBackButton={false}
      showCloseButton
      title={!isMdUp ? title : ''}
      actions={
        isMdUp
          ? [
              {
                text: backText,
                onClick: handleBack,
                color: 'primary',
                variant: 'outlined',
                fullWidth: false,
              },
              {
                text: submitText,
                onClick: onConfirm,
                color: 'primary',
                variant: 'contained',
                fullWidth: false,
              },
            ]
          : []
      }
      disableBackdropClick
      disableEnforceFocus
      disableEscapeKeyDown
    >
      <div className={componentClasses.root}>
        <PMStepper
          classes={{ root: componentClasses.stepper }}
          activeStep={1}
        />
        {isMdUp && (
          <Typography
            variant={TypographyVariant.Headline}
            fontWeight={FontWeight.Bold}
            classes={{ root: componentClasses.title }}
          >
            {title}
          </Typography>
        )}
        <DayGroup
          scheduleDay={schedulesDays}
          week={'Semana'}
          hourList={hourList}
        />
        <div className={componentClasses.descriptionBox}>
          {isMdUp && (
            <img
              alt='icon-clock'
              src={iconClock}
              className={componentClasses.iconImage}
            />
          )}
          <Typography
            classes={{ root: componentClasses.description }}
            variant={TypographyVariant.Caption1}
            fontWeight={FontWeight.Regular}
          >
            {subTitle}
          </Typography>
        </div>
      </div>
      {!isMdUp && (
        <div className={componentClasses.containerButtons}>
          <CustomButton
            className={componentClasses.footerButtonSubmit}
            color={Colors.PRIMARY}
            fullWidth
            onClick={onConfirm}
            variant={Variants.CONTAINED}
          >
            {submitText}
          </CustomButton>
          <CustomButton
            className={componentClasses.footerButtonSubmit}
            color={Colors.PRIMARY}
            fullWidth
            onClick={handleBack}
            variant={Variants.OUTLINED}
          >
            {backText}
          </CustomButton>
        </div>
      )}
    </CustomDialog>
  );
}
