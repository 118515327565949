import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { useStyles } from './DeactivatedProductsStoreBox.styles';
import { useDeactivatedProductsDialogTexts } from '../../hooks';

export function DeactivatedProductsStoreBox({
  storeName,
  deactivatedProductsQuantity,
  deactivatedToppingsQuantity,
}: Props) {
  const styles = useStyles();
  const { productsLabel, toppingsLabel } = useDeactivatedProductsDialogTexts();

  return (
    <Box className={styles.storeBox}>
      <Typography className={styles.title}>{storeName}</Typography>
      <Box className={styles.listItem}>
        <Typography className={styles.deactivatedQuantity}>
          {deactivatedProductsQuantity}
        </Typography>
        <Typography className={styles.deactivatedType}>
          {productsLabel}
        </Typography>
      </Box>
      <Box className={styles.listItem}>
        <Typography className={styles.deactivatedQuantity}>
          {deactivatedToppingsQuantity}
        </Typography>
        <Typography className={styles.deactivatedType}>
          {toppingsLabel}
        </Typography>
      </Box>
    </Box>
  );
}
interface Props {
  storeName: string;
  deactivatedProductsQuantity: any;
  deactivatedToppingsQuantity: any;
}
