// React
import React from 'react';
// Constant
import { neutral } from '../../../shared/styles/colors';

const DialogAlertIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='167'
      height='136'
      viewBox='0 0 167 136'
    >
      <g fill='none' fillRule='evenodd'>
        <rect
          width='164'
          height='109'
          x='1.5'
          y='1.5'
          fill={neutral.white}
          stroke={neutral.D8}
          strokeWidth='3'
          opacity='.845'
          rx='13'
          transform='matrix(-1 0 0 1 167 0)'
        />
        <path
          fill={neutral.white}
          stroke={neutral.D8}
          strokeWidth='3'
          d='M85.907 42.075c-1.606-.803-3.384-.879-4.962-.353-1.579.526-2.956 1.654-3.759 3.26L37.13 125.093c-.45.903-.686 1.898-.686 2.907 0 1.795.728 3.42 1.904 4.596 1.176 1.176 2.801 1.904 4.596 1.904h80.112c1.009 0 2.004-.235 2.907-.686 1.605-.803 2.733-2.18 3.26-3.759.525-1.578.45-3.356-.353-4.962L88.814 44.982c-.63-1.258-1.65-2.278-2.907-2.907z'
        />
        <g stroke={neutral.D9} strokeWidth='2' transform='translate(137 8)'>
          <circle cx='11' cy='3' r='2' />
          <circle cx='3' cy='3' r='2' />
          <circle cx='19' cy='3' r='2' />
        </g>
        <text
          fill={neutral.D19}
          fontFamily='Roboto-Bold, Roboto'
          fontSize='58'
          fontWeight='bold'
        >
          <tspan x='75' y='115'>
            !
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default DialogAlertIcon;
