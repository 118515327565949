import { makeStyles } from '@material-ui/core';

import { neutral } from '../../shared/styles/colors';

export default makeStyles(() => ({
  root: {
    rowGap: '0.5rem',
  },
  body: {
    display: 'flex',
    rowGap: '1rem',
    flexDirection: 'column',
    gridAutoRows: 'min-content',
    gridTemplateColumns: 'auto min-content',
    fontSize: '0.75rem',
  },
  rtAvatarRoot: {
    width: 'fit-content',
    height: 'inherit',
    justifySelf: 'end',
  },
  avatarRoot: {
    width: '3rem',
    height: '3rem',
    backgroundColor: neutral['30'],
  },
  rtInfoContainer: ({ showRTInfo }) => ({
    display: showRTInfo ? 'flex' : 'none',
    gap: '1rem',
    alignItems: 'center',
  }),
  rtInfo: {
    display: 'grid',
    gridAutoRows: 'min-content',
  },
  rtName: {
    color: neutral['90'],
    fontSize: '0.75rem',
    fontWeight: 600,
  },
  secondaryInfo: {
    color: neutral['50'],
    fontSize: '0.75rem',
  },
  rtStateContainer: ({ showRTInfo }) => ({
    padding: '1rem 0',
    display: showRTInfo ? 'flex' : 'none',
    gridGap: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${neutral['20']}`,
    borderRight: 'none',
    borderLeft: 'none',
  }),
  rtBtnCT: ({ showRTInfo }) => ({
    padding: '0.5rem 1rem',
    display: showRTInfo ? 'flex' : 'none',
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    borderRadius: '0.5rem',
  }),
  extraTextRoot: {
    gridColumn: '1 / 3',
    fontSize: '0.625rem',
    fontWeight: 'bold',
    lineHeight: 1.8,
    color: neutral['90'],
  },
}));
