import { useEffect } from 'react';

import { useAdditionalRTLang } from './useAdditionalRTLang';
import { cargoAddRTCase, CargoUICase } from '../../../../business/cargo';

import type { MouseEvent } from 'react';
import type { UseCargoAdditionalRTProps } from '../CargoAdditionalRT.props';

export function useAdditionalRT(props: UseCargoAdditionalRTProps) {
  const { orderId } = props;

  const {
    isDisplayed,
    isEnabled,
    isLoading,
    isDialogEnabled,
    isValidationDenied,
    onValidateCargoAddRT,
  } = cargoAddRTCase(props);
  const { showErrorDialog: openErrorMsg } = CargoUICase();

  const lang = useAdditionalRTLang();

  function handleGetAdditionalRT(event: MouseEvent) {
    event.stopPropagation();
    onValidateCargoAddRT({ orderId });
  }

  useEffect(() => {
    if (isValidationDenied) {
      openErrorMsg(lang.error.title, lang.error.description, 5_000);
    }
  }, [isValidationDenied]);

  return {
    isDisplayed,
    isEnabled,
    isLoading,
    isDialogEnabled,
    handleGetAdditionalRT,
  };
}
