import React, { useState } from 'react'
import hooks from '../../hooks'
import useStyles from '../../ExtendClosingHoursDialog.styles'
import { ExportClosingHoursItemProps, IExtendSchedule } from '../../types'
import { Box, Grid, TextField, Typography } from '@material-ui/core'
import { STEP_MINUTE } from '../../constants'
import caution from '../../../../assets/images/icons/caution.svg'

export const ExtendClosingHourItem: React.FC<ExportClosingHoursItemProps> = ({
  idx,
  hasOneTurn,
  scheduleId,
  startTime,
  endTime,
  previousEndTime,
  nextStartTime,
  skipErrorTime = false,
  isStartTimeDisabled,
  isEndTimeDisabled,
  setExtendSchedules,
}) => {
  const [startExtendTime, setStartExtendTime] = useState(startTime)
  const [isStartExtendTimeError, setIsStartExtendTimeError] = useState(false)
  const [endExtendTime, setEndExtendTime] = useState(endTime)
  const [isEndExtendTimeError, setIsEndExtendTimeError] = useState(false)
  const [isPreviousExtendTimeError, setIsPreviousExtendTimeError] = useState(
    false,
  )
  const [isNextExtendTimeError, setIsNextExtendTimeError] = useState(false)
  const {
    opensLabel,
    closesLabel,
    extendOneTurnLabel,
    openErrorLabel,
    closeErrorLabel,
    nextErrorLabel,
    previousErrorLabel,
    extendTurnLabel,
  } = hooks.useExtendClosingHoursDialogTexts(idx + 1)
  const componentClasses = useStyles()

  const handleStartTime = (e: { target: any }) => {
    const {
      target: { value: time },
    } = e
    const isStartErrorTime = time > startTime && !skipErrorTime
    const isPreviousErrorTime = time < previousEndTime && !skipErrorTime
    setStartExtendTime(time)
    setIsStartExtendTimeError(isStartErrorTime)
    setIsPreviousExtendTimeError(isPreviousErrorTime)
    setExtendSchedules((prevState: IExtendSchedule) => ({
      ...prevState,
      startTime: time,
      endTime: endExtendTime,
      hasStartTimeError: isStartErrorTime,
      hasPreviousEndTimeError: isPreviousErrorTime,
      slotId: idx,
      scheduleId,
    }))
  }

  const handleEndTime = (e: { target: any }) => {
    const {
      target: { value: time },
    } = e
    const isEndErrorTime = time < endTime && !skipErrorTime
    const isNextErrorTime = time > nextStartTime && !skipErrorTime
    setEndExtendTime(time)
    setIsEndExtendTimeError(isEndErrorTime)
    setIsNextExtendTimeError(isNextErrorTime)
    setExtendSchedules((prevState: IExtendSchedule) => ({
      ...prevState,
      startTime: startExtendTime,
      endTime: time,
      hasEndTimeError: isEndErrorTime,
      hasNextStartTimeError: isNextErrorTime,
      slotId: idx,
      scheduleId,
    }))
  }

  return (
    <>
      <Grid item xs={4}>
        <Box className={componentClasses.extendClosingLabel}>
          {hasOneTurn ? extendOneTurnLabel : extendTurnLabel}
        </Box>
      </Grid>
      <Grid item xs={4}>
        <TextField
          id='time'
          label={opensLabel}
          type='time'
          defaultValue={startTime}
          className={componentClasses.extendClosingStartTime}
          InputLabelProps={{ shrink: true }}
          inputProps={{ step: STEP_MINUTE }}
          onChange={handleStartTime}
          error={isStartExtendTimeError}
          disabled={isStartTimeDisabled && !skipErrorTime}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          id='time'
          label={closesLabel}
          type='time'
          defaultValue={endTime}
          className={componentClasses.extendClosingEndTime}
          InputLabelProps={{ shrink: true }}
          inputProps={{ step: STEP_MINUTE }}
          onChange={handleEndTime}
          error={isEndExtendTimeError || isNextExtendTimeError}
          disabled={isEndTimeDisabled}
        />
      </Grid>
      <Box>
        {isStartExtendTimeError && (
          <Typography className={componentClasses.extendTimeError}>
            <img alt='schedule caution' src={caution} />
            {openErrorLabel}
          </Typography>
        )}
        {isEndExtendTimeError && (
          <Typography className={componentClasses.extendTimeError}>
            <img alt='schedule caution' src={caution} />
            {closeErrorLabel}
          </Typography>
        )}
        {isNextExtendTimeError && (
          <Typography className={componentClasses.extendTimeError}>
            <img alt='schedule error' src={caution} />
            {nextErrorLabel}
          </Typography>
        )}
        {isPreviousExtendTimeError && (
          <Typography className={componentClasses.extendTimeError}>
            <img alt='schedule error' src={caution} />
            {previousErrorLabel}
          </Typography>
        )}
      </Box>
    </>
  )
}
