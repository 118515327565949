// Material UI
import { makeStyles } from '@material-ui/core'
// Colors

export default makeStyles(theme => ({
  root: {
    padding: 12,
  },
  header: {
    paddingTop: 15,
    paddingBottom: 15,
    display: 'flex',
    alignItems: 'self-start',
    gap: '13px',
    fontSize: 18,
    fontWeight: 'bold',
  },
  arrowIcon: {
    cursor: 'pointer',
  },
  nested: {},
}))
