// React
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// Actions
import menuActions from '../../redux/actions/menu.actions';
// Components
import MenuStoreRequiredBox from './MenuStoreRequiredBox';

const mapStateToProps = (state, { intl: { formatMessage } }) => {
  const mustSelectStoreLabel = formatMessage({ id: 'mustSelectStore.label' });
  const selectStoreLabel = formatMessage({ id: 'selectStore.label' });
  const title = formatMessage({ id: 'restaurantMenuPage.board.title' });
  return { mustSelectStoreLabel, selectStoreLabel, title };
};

const mapDispatchToProps = {
  onSelectStore: menuActions.openMenuFilterDrawer,
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MenuStoreRequiredBox),
);
