// React
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
// Components
import SideMenu from './SideMenu'

const mapStateToProps = (
  {
    ordersReducer: { historicalFilter },
    partnerReducer: {
      profile: { partnerState },
    },
  },
  props,
) => {
  return {
    historicalFilter,
    partnerState,
  }
}

const mapDispatchToProps = {}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SideMenu),
)
