export const LL_FORMAT_DATE = 'LL'

export const START_TIME_DEFAULT_HOUR = '00:00'

export const END_TIME_DEFAULT_HOUR = '23:59'

export const ACTIVE = 'ACTIVE'

export const DEFAULT_STORE_DAY_SCHEDULE = [
  {
    starts_time: START_TIME_DEFAULT_HOUR,
    ends_time: END_TIME_DEFAULT_HOUR,
    skip_error_time: true,
  },
]

export const STEP_MINUTE = 60
