import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton, InputAdornment } from '@material-ui/core';

import Typography, {
  Variants as Variant,
  FontWeight,
} from '../../../common/typography';
import CustomButton, {
  Colors,
  Types,
  Variants,
} from '../../../common/buttons/custom-button';
import { CustomLink } from '../../../common/custom-link';
import CustomTextField from '../../../common/custom-text-field';
import CustomRadioCheck from '../../../common/custom-radio-check';
import { CountrySelector } from '../../../common/country-selector';

import { useForm } from './hooks';
import { InputType } from '../../../../constants/input-type';

import useStyles from './styles';

export function Form({ handleShowForm }: Props) {
  const {
    lang,
    availableCountries,
    didFetchingFail,
    email,
    helperText,
    isCountrySelectorLoading,
    mustInputsBeDisabled,
    mustSubmitButtonBeDisabled,
    password,
    remember,
    selectedCountry,
    showPassword,
    handleCountrySelectorChange,
    handleClickShowPassword,
    handleEmailChange,
    handlePasswordChange,
    handleRemember,
    handleSubmit,
    handleClickShowPasswordPath,
    handleCreateNowAccount,
  } = useForm({ handleShowForm });

  const _classes = useStyles();

  return (
    <div className={_classes.root}>
      <h1 className={_classes.title}>{lang.title}</h1>
      <form className={_classes.loginForm} autoComplete='on'>
        <CountrySelector
          error={didFetchingFail}
          helperText={helperText}
          isLoading={isCountrySelectorLoading}
          list={availableCountries}
          value={selectedCountry}
          onChange={handleCountrySelectorChange}
        />
        <CustomTextField
          classes={{ textFieldRoot: _classes.textFieldContainer }}
          disabled={mustInputsBeDisabled}
          label={lang.emailLabelInput}
          placeholder={'myEmail@rappi.com'}
          onChange={handleEmailChange}
          required={true}
          type={InputType.EMAIL}
          value={email}
          autoComplete='on'
        />
        <CustomTextField
          classes={{ textFieldRoot: _classes.textFieldContainer }}
          disabled={mustInputsBeDisabled}
          label={lang.passwordLabelInput}
          placeholder={'1234example'}
          onChange={handlePasswordChange}
          required={true}
          type={showPassword ? InputType.TEXT : InputType.PASSWORD}
          value={password}
          autoComplete='off'
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                className={_classes.visibilityIcon}
                edge='end'
                aria-label='Toggle password visibility'
                onClick={handleClickShowPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />

        <div className={_classes.linksContainer}>
          <CustomRadioCheck
            className={_classes.rememberContainer}
            disabled={mustInputsBeDisabled}
            label={lang.rememberMeText}
            onChange={handleRemember}
            value={remember}
          />
          <CustomLink
            classes={{ root: _classes.forgotPassword }}
            color='primary'
            onClick={handleClickShowPasswordPath}
          >
            {lang.forgotPasswordText}
          </CustomLink>
        </div>

        <CustomButton
          classes={{ root: _classes.buttonRoot }}
          color={Colors.PRIMARY}
          disabled={mustSubmitButtonBeDisabled}
          onClick={handleSubmit}
          text={lang.submitButtonText}
          type={Types.SUBMIT}
          variant={Variants.CONTAINED}
        />
        <div className={_classes.boxContainer}>
          <Typography
            classes={{ root: _classes.createAccount }}
            fontWeight={FontWeight.Regular}
            variant={Variant.Body}
          >
            {lang.createAccountText}
          </Typography>
          <CustomLink
            classes={{ root: _classes.createNow }}
            color='primary'
            onClick={handleCreateNowAccount}
          >
            {lang.createNowText}
          </CustomLink>
        </div>
      </form>
    </div>
  );
}

interface Props {
  handleShowForm: (show: boolean) => void;
}
