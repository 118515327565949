import makeClasses from './CustomBlock.styles';

export default function CustomBlock({ title, children }) {
  const _classes = makeClasses();

  return (
    <div className={_classes.root}>
      <div className={_classes.header}>{title}</div>
      <div className={_classes.content}>{children}</div>
    </div>
  );
}
