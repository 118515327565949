import { clsx } from 'clsx';

import useStyles from './StoreScheduleTableRow.styles';

export function StoreScheduleTableRow(props) {
  const { subtitle, title, children, classes = {} } = props;
  const _classes = useStyles();
  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <div className={_classes.header}>
        <div className={clsx(_classes.title, classes?.title)}>{title}</div>
        {!!subtitle && (
          <div className={clsx(_classes.subtitle, classes?.subtitle)}>
            {subtitle}
          </div>
        )}
      </div>
      <div className={_classes.body}>{children}</div>
    </div>
  );
}
