import http from './HttpService';
import LocalStorageService from './local-storage-service';

import urls from '../constants/Urls';
import { APP_VERSION } from '../constants/commons';

const isAuthenticated = () => {
  return LocalStorageService.isAuthenticated();
};

const login = (email, password) => {
  const baseUrl = LocalStorageService.getBaseUrl();
  const loginDto = {
    username: email,
    password: password,
  };
  return http.post(`${baseUrl}${urls.LOGIN}`, loginDto, {
    headers: {
      'PLATFORM-VERSION': `${APP_VERSION}`,
    },
  });
};

const getUUIDfromSession = () => {
  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
};

const setDeviceId = () => {
  // Get the current device Id in localStorage.
  const currentDeviceId = LocalStorageService.getDeviceId();
  if (currentDeviceId && currentDeviceId !== 'null') {
    // If it exists, mean it is already set, so just return it.
    return currentDeviceId;
  }
  // If no token Id in LocalStorage, create one and save it in LocalStorage.
  const newDeviceId = getUUIDfromSession();
  LocalStorageService.setDeviceId(newDeviceId);

  return newDeviceId;
};

const s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};

export default {
  getUUIDfromSession,
  isAuthenticated,
  login,
  setDeviceId,
};
