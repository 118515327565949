import React from 'react';

import CustomButton from '../common/buttons/custom-button';

import useStyles from './StoresFilterButton.styles';
import filterIcon from '../../assets/images/icons/filter_icon.svg';

function StoresFilterButton({ onClick }) {
  const componentClasses = useStyles();

  return (
    <CustomButton
      fullWidth={false}
      onClick={onClick}
      classes={{
        root: componentClasses.rootOfSubmitButto1n,
        submitButton: componentClasses.submitButton,
      }}
    >
      <div className={componentClasses.container}>
        <img src={filterIcon} alt='filters' />
      </div>
    </CustomButton>
  );
}

StoresFilterButton.defaultProps = {
  classes: {},
};

export default StoresFilterButton;
