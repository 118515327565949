// External libs
import { createSelector } from 'reselect'

const selectSlice = (state: any) => state.endPMFlowDialog

const selectOpen = createSelector(selectSlice, reducer => reducer.open)
const selectFormId = createSelector(selectSlice, reducer => reducer.formId)
const selectStoreId = createSelector(selectSlice, reducer => reducer.storeId)

const selectors = {
  selectOpen,
  selectFormId,
  selectStoreId,
}

export default selectors
