// External libs
import { handleActions } from 'redux-actions';
import newSpecialDayDialogActions from '../actions/newSpecialDayDialog.actions';

const initialState = {
  open: false,
};
export default handleActions(
  {
    [newSpecialDayDialogActions.openNewSpecialDayDialogAction]: state =>
      toggleDialog(state, true),
    [newSpecialDayDialogActions.closeNewSpecialDayDialogAction]: state =>
      toggleDialog(state, false),
  },
  initialState,
);
const toggleDialog = (state, open) => {
  return {
    ...state,
    open,
  };
};
