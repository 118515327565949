import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import OrdersUtils from '../../utils/OrdersUtils';
import DateTimeUtils from '../../utils/DateTimeUtils';
import OrdersService from '../../services/order-service';
import { formatCurrency } from '../../utils/CurrencyUtils';
import OrdersActions from '../../redux/actions/orders-actions';
import LocalStorageService from '../../services/local-storage-service/LocalStorageService';

import OrderBill from './OrderBill';

const mapStateToProps = (
  { ordersReducer: { orders }, appReducer: { language } },
  { intl: { formatMessage } },
) => {
  const selectedOrder = orders.find(order => order.pendingToPrint) || {
    id: 0,
  };

  let orderDetail =
    selectedOrder && selectedOrder.detail
      ? selectedOrder.detail
      : { id: 0, products: [], whims: [], user: {} };

  orderDetail = { ...orderDetail, id: selectedOrder.id };
  const products = orderDetail.products
    ? orderDetail.products.map(product => {
        const {
          id,
          comments,
          groupedToppings,
          name,
          toppings,
          total_price_product,
          total_real_price,
          units,
          replacement_suggestion,
          has_defect_rate_report,
          quantityText,
          global_offers = [],
        } = product;
        const thereAreToppings = toppings.some(topping => topping.units > 1);
        const toppingMessage = thereAreToppings
          ? OrdersService.getProductReminder(product, formatMessage, true)
          : null;

        return {
          id,
          comments,
          groupedToppings,
          name,
          toppingMessage,
          toppings,
          total_price_product,
          total_real_price,
          units,
          replacement_suggestion,
          has_defect_rate_report,
          quantityText,
          global_offers,
        };
      })
    : [];

  const whims = orderDetail.whims
    ? orderDetail.whims.map(whim => {
        const { id, name, price, comments } = whim;
        return {
          id,
          name,
          price,
          comments,
        };
      })
    : [];

  const whimTitle = formatMessage({
    id: 'orderBill.whim.title',
  });
  const takeOrderDate = DateTimeUtils.getLocaleDateString(
    orderDetail.cooking_time_started_at,
    'DD/MM/YYYY HH:mm:ss',
    language,
  );
  const dateNow = DateTimeUtils.getLocaleDateString(
    new Date(),
    'DD/MM/YYYY HH:mm:ss',
  );

  const localCurrency = LocalStorageService.getCountryCurrencyPrefix();
  const orderTotalsError = orderDetail.order_totals?.errors;
  const orderTotalsErrorMessage = orderTotalsError?.message ?? '';
  const hasOrderTotalsFailed = Boolean(orderTotalsError);

  const totalLabel = orderDetail.order_totals.total?.text ?? '';
  const totalValueWithoutFormat = orderDetail.order_totals.total?.value ?? 0;

  const totalValue = formatCurrency(totalValueWithoutFormat, false);
  const changeGivenByUser = orderDetail.cash_given_by_user ?? null;
  const cashGivenByUserWithFormat = formatCurrency(changeGivenByUser);

  const cashGivenByUser = formatMessage(
    { id: 'orderBill.cashGivenByUser' },
    { cash: cashGivenByUserWithFormat },
  );

  const isMarketPlace = OrdersUtils.isMarketPlace(orderDetail.delivery_method);
  const showChangeGivenByUser = Boolean(changeGivenByUser) && isMarketPlace;
  const showDeliverCashForUser =
    changeGivenByUser > totalValueWithoutFormat && isMarketPlace;

  const cashDeliveryToUserLabel = showDeliverCashForUser
    ? formatMessage(
        { id: 'orderBill.cashDeliveryToUser' },
        {
          currency: localCurrency,
          cash: formatCurrency(
            changeGivenByUser - totalValueWithoutFormat,
            true,
          ),
        },
      )
    : '';

  const totalDetailsWithoutFormat = orderDetail.order_totals?.details ?? [];

  const totalDetails = totalDetailsWithoutFormat.map(detail => ({
    ...detail,
    value: formatCurrency(detail.value),
  }));

  const showZipCode = Boolean(orderDetail.user?.zip_code);
  const showNeighborhood = Boolean(orderDetail.user?.neighborhood);
  const showPrime = Boolean(orderDetail?.prime_shipping_discount);

  const qrCode = orderDetail?.qr_information?.qr_code || null;
  const productConfirmationCode =
    orderDetail?.qr_information?.product_confirmation_code || null;

  return {
    dateNow,
    hasOrderTotalsFailed,
    orderDetail,
    orderTotalsErrorMessage,
    products,
    takeOrderDate,
    totalDetails,
    totalLabel,
    totalValue,
    showChangeGivenByUser,
    cashGivenByUser,
    user: orderDetail.user,
    showZipCode,
    showNeighborhood,
    showPrime,
    whims,
    whimTitle,
    showDeliverCashForUser,
    cashDeliveryToUserLabel,
    qrCode,
    productConfirmationCode,
  };
};

const mapDispatchToProps = { onPrint: OrdersActions.confirmPrint };

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(OrderBill),
);
