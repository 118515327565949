import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../../../../../shared/styles/colors';

export default makeStyles({
  btnRoot: {
    cursor: 'pointer',
    '&:disabled': {
      color: neutral['50'],
      fill: neutral['50'],
    },
  },
  btnLabel: {
    columnGap: '1rem',
    textAlign: 'left',
    '& > :nth-child(odd)': {
      margin: 0,
    },
  },
  title: {
    fontSize: '0.875rem',
    fontWeight: 600,
  },
  description: {
    fontSize: '0.625rem',
    fontWeight: 400,
  },
});
