import type {
  CoverageVerification,
  CreateAddRT,
  RTRequest,
} from '../../domain';
import type {
  CancelCargoOrderRequest,
  CancelCostOrderRequest,
  CancelOrderRequest,
  CoverageValidateRequest,
  CreateAddRTRequest,
  OrdersListRequest,
  OrdersRequest,
  RTCargoRequest,
} from '../core/requests';

const toCoverage = (model: CoverageVerification): CoverageValidateRequest => ({
  store_id: model.store.id,
  client_name: model.clientName,
  client_address: model.deliveryAddress.completeAddress,
  client_address_complement: model.deliveryReferences,
  client_address_city: model.deliveryAddress.city,
  client_lat: model.deliveryAddress.coordinates.lat,
  client_lng: model.deliveryAddress.coordinates.lng,
  phone: model.phoneNumber,
  total_value: model.totalValue,
});

const toCreateOrder = (model: Partial<RTRequest>): RTCargoRequest => ({
  store_id: model?.store?.id ?? 0,
  client_name: model?.clientName || '',
  phone: model?.phoneNumber || '',
  client_address: model?.deliveryAddress?.completeAddress || '',
  client_address_complement: model?.deliveryReferences || '',
  client_address_city: model?.deliveryAddress?.city || '',
  client_lat: model?.deliveryAddress?.coordinates?.lat ?? 0,
  client_lng: model?.deliveryAddress?.coordinates?.lng ?? 0,
  total_value: model?.totalValue ?? 0,
});

const toOrdersList = (model: OrdersRequest): OrdersListRequest => ({
  store_ids: model.storeIds,
});

const toCancelOrderCost = (model: CancelCostOrderRequest) => ({
  rappi_order_id: model.orderId,
});

const toCancelOrder = (model: CancelOrderRequest): CancelCargoOrderRequest => ({
  rappi_order_id: model.orderId,
});

function toCreateAddRT(params: CreateAddRT): CreateAddRTRequest['payload'] {
  return {
    marketplace_order_id: params.marketplaceOrderId,
    store_id: params.storeId,
    client_name: params.client.name,
    phone: params.client.phone,
    client_address: params.client.address,
    client_address_complement: params.client.addressComplement,
    client_address_city: params.client.addressCity,
    client_lat: params.client.mapPoint.lat,
    client_lng: params.client.mapPoint.lng,
    total_value: params.orderCost ?? params.partialValue,
    // estimated_price: params.deliveryCost,
    // partial_value: params.partialValue,
  };
}

export const requestAdapter = {
  toCoverage,
  toCreateOrder,
  toOrdersList,
  toCancelOrderCost,
  toCancelOrder,
  toCreateAddRT,
} as const;
