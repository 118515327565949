// React
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
// Components
import OrderDetailDialogErrorBody from './OrderDetailDialogErrorBody'

const mapStateToProps = (
  {
    orderDetailDialogReducer: {
      error: { message, title },
    },
  },
  { intl: { formatMessage } },
) => {
  const expressionText = formatMessage({
    id: 'orderDetailDialogErrorBody.expression',
  })
  return { message, expressionText, title }
}

const mapDispatchToProps = {}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(OrderDetailDialogErrorBody),
)
