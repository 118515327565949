import React, { Component } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
// Constant
import Colors from '../../../constants/Colors';
import { neutral } from '../../../shared/styles/colors';

class MenuIcon extends Component {
  getStroke() {
    return this.props.selected ? Colors.DANGER_FONT_COLOR : neutral.D15;
  }

  render() {
    const { d1, d2, fill, gFill, stroke } = this.props;

    return (
      <SvgIcon fontSize='inherit'>
        <g fill={gFill || 'none'} fillRule='evenodd'>
          <path d={d1 || 'M0 0h24v24H0z'} />
          <path
            fill={fill}
            stroke={stroke || this.getStroke()}
            strokeLinecap='round'
            strokeLinejoin='round'
            d={d2}
          />
        </g>
      </SvgIcon>
    );
  }
}

export default MenuIcon;
