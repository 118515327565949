import { makeStyles } from '@material-ui/core';

import { neutral, teal } from '../../../shared/styles/colors';

export default makeStyles({
  measureValueContainer: {
    backgroundColor: neutral['20'],
    borderRadius: '50%',
    color: teal['50'],
    display: 'grid',
    fontSize: '0.75rem',
    gridAutoFlow: 'row',
    gridAutoRows: 'min-content',
    rowGap: '0.25rem',
    justifyItems: 'center',
    lineHeight: 'normal',
    padding: '12px 0px 10px 0px',
    width: '5rem',
  },
  root: {
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: 'min-content min-content min-content',
    columnGap: '1rem',
  },
  value: {
    fontFamily: 'Poppins Bold',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
});
