import { useDispatch } from 'react-redux';
import { geoqueueHelper } from '../../business/order/geoqueue';
import { orderCase } from '../../business/order/shared';
import { formatMessage } from '../../intl/intl';
import orderActions from '../../redux/actions/orders-actions';
import OrdersUtils from '../../utils/OrdersUtils';
import RTUtils from '../../utils/RTUtils';

export function useOrderDetailDialogRTBox() {
  const { orderDetail, order, orderId } = orderCase();
  const reduxDispatch = useDispatch();

  const rt = orderDetail.storekeeper || {};
  const isBeingManaged = OrdersUtils.isBeingManaged(order);

  const steps =
    orderDetail.rt_state && orderDetail.rt_state.eta_steps
      ? orderDetail.rt_state.eta_steps
      : [];
  const activeStep = RTUtils.getActiveStep(steps);
  const activeStepIndex = activeStep && activeStep.index;
  const rtStateText = activeStep && activeStep.name;

  const isSearchingForRT = OrdersUtils.isSearchingForRTFromDetail(orderDetail);
  const isRTOnTheWay = OrdersUtils.isRTOnTheWayFromDetail(orderDetail);
  const isRTInStore = OrdersUtils.isRTInStoreFromDetail(orderDetail);
  const isRTNotifyAvailable = OrdersUtils.isRTNotifyAvailable(order);
  const isRtWaitingMuch = order?.rt_waiting_time_exceeded ?? false;

  const isRTVisible = Boolean(rt?.visible);
  const showMoreButton = isBeingManaged && isRTVisible;
  const showRTInfo = (isBeingManaged && isRTOnTheWay) || isRTInStore;
  const showGeoqueueSection =
    isSearchingForRT && geoqueueHelper.showSection(orderDetail);

  const rtBtnTextCT = isRTNotifyAvailable
    ? formatMessage({ id: 'ordersKanban.rtState.notify' })
    : formatMessage({ id: 'ordersKanban.rtState.locate' });

  const alertText = isSearchingForRT
    ? formatMessage({ id: 'orderDetailDialogRTBox.searchingForRTText' })
    : null;

  function handleRTDialog() {
    reduxDispatch(orderActions.openRTDialog(orderId));
  }
  function handleNotificationToRT() {
    reduxDispatch(orderActions.sendNotificationToRT(orderId));
  }

  return {
    alertText,
    activeStepIndex,
    orderId,
    handleRTDialog,
    handleNotificationToRT,
    rt,
    rtStateText,
    showRTInfo,
    rtBtnTextCT,
    showMoreButton,
    isRtWaitingMuch,
    isRTNotifyAvailable,
    storeId: order?.store_id ?? NaN,
    showGeoqueueSection,
  };
}
