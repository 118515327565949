import React from 'react';

import { LoadScripts } from 'web-adapter-js';
import { SupportWebChatConfig } from '../../constants/WebComponentsMf';

let load = new LoadScripts();

load.import([
  {
    name: SupportWebChatConfig.PortalName,
    src: `${process.env.REACT_APP_SUPPORT_WEB_CHAT_HOST}/${SupportWebChatConfig.FileName}`,
  },
]);

const SupportWebChat = ({ config }) => {
  return <support-web-chat confserialize={JSON.stringify(config)} />;
};

export default SupportWebChat;
