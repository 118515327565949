// Material UI
import { makeStyles } from '@material-ui/core';
// Constant
import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(theme => {
  return {
    loginForm: {
      fontSize: '1em',
      marginTop: '40px',
      [theme.breakpoints.up('lg')]: {
        marginTop: '50px',
      },
    },
    root: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'flex-start',
      height: '100%',
      padding: '36px 24px 30px 24px !important',
      [theme.breakpoints.up('md')]: {
        padding: '76px 36px 52px 36px !important',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '126px 100px 100px 100px !important',
      },
    },
    title: {
      color: neutral.D20,
      fontFamily: 'Poppins',
      fontSize: '1.872em',
      fontWeight: 'normal',
      lineHeight: 'normal',
      margin: '0px',
    },
    textFieldContainer: {
      margin: '16px 0px 0px 0px !important',
    },
    linksContainer: {
      display: 'grid',
      margin: '16px 0px 0px 0px',
      rowGap: 16,
      gridTemplateAreas: `"checkBox"
                          "forgotPassword"`,
      [theme.breakpoints.up('sm')]: {
        rowGap: 16,
        gridTemplateAreas: `"checkBox"
                          "forgotPassword"`,
      },
      [theme.breakpoints.up('md')]: {
        margin: '20px 0px 0px 0px',
        gridTemplateAreas: `"checkBox forgotPassword"`,
      },
    },
    rememberContainer: {
      gridArea: 'checkBox',
      paddingLeft: 11,
    },
    forgotPassword: {
      gridArea: 'forgotPassword',
      justifySelf: 'left',
      fontSize: 12,
      [theme.breakpoints.up('sm')]: {
        justifySelf: 'left',
      },
      [theme.breakpoints.up('md')]: {
        justifySelf: 'right',
      },
    },
    boxContainer: {
      display: 'grid',
      rowGap: 6,
      gridTemplateAreas: `"createAccount"
                          "createNow"`,
      paddingTop: 24,
      [theme.breakpoints.up('sm')]: {
        rowGap: 6,
        gridTemplateAreas: `"createAccount"
                          "createNow"`,
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: 26,
        justifyContent: 'center',
        gridTemplateColumns: 'max-content',
        gridTemplateAreas: `"createAccount createNow"`,
      },
    },
    createAccount: {
      gridArea: 'createAccount',
      textAlign: 'left',
      fontSize: 12,
      [theme.breakpoints.up('sm')]: {
        textAlign: 'left',
      },
      [theme.breakpoints.up('md')]: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    createNow: {
      gridArea: 'createNow',
      fontSize: 12,
      [theme.breakpoints.up('sm')]: {},
      [theme.breakpoints.up('md')]: {
        paddingLeft: 6,
        fontSize: 14,
        alignSelf: 'center',
      },
    },
    textFieldInput: {
      color: neutral.D20,
      padding: '13px 0px 13px 10px !important',
    },
    inputLabelOutlined: {
      transform: 'translate(14px, 15px) scale(1)',
    },
    visibilityIcon: {
      color: neutral.D15,
    },
    buttonRoot: {
      marginTop: '46px',
    },
  };
});
