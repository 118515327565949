import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import DialogsManagerActions from '../../../redux/actions/dialogsManager.actions';
import OrderActions from '../../../redux/actions/orders-actions';
import OrdersSelectors from '../../../redux/selectors/orders-selectors';
import RTReleaseConfirmationDialogSelectors from '../../../redux/selectors/rt-release-confirmation-dialog-selectors';

const useHandleBack = dispatch => {
  return useCallback(async () => {
    DialogsManagerActions.back()(dispatch);
  }, [dispatch]);
};

const useHandleConfirm = (dispatch, orderId, storekeeperId) => {
  return useCallback(async () => {
    await OrderActions.releaseRTnow(storekeeperId, orderId)(dispatch);
  }, [dispatch, orderId, storekeeperId]);
};

const useOpen = () =>
  useSelector(RTReleaseConfirmationDialogSelectors.selectOpen);

const useOrderId = () =>
  useSelector(RTReleaseConfirmationDialogSelectors.selectOrderId);

const useStoreKeeperId = () =>
  useSelector(OrdersSelectors.selectStorekeeperIdInRTReleaseConfirmationDialog);

const Hooks = {
  useHandleBack,
  useHandleConfirm,
  useOpen,
  useOrderId,
  useStoreKeeperId,
};

export default Hooks;
