// Material UI
import { makeStyles } from '@material-ui/core';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  body: {
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.up('md')]: {},
  },
  headerRoot: {
    gridTemplateColumns: 'auto auto min-content',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'min-content auto min-content',
      borderBottom: 'none',
      padding: '12px 4px 14px 12px',
    },
  },
  titleDialog: {
    padding: '0px 0px 0px 16px',
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.up('md')]: {
      padding: '0px 0px 0px 0px',
    },
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      width: 620,
      height: 431,
    },
  },
  banner: {
    gridArea: 'banner',
  },
  iconImage: {
    width: 79,
    height: 79,
    [theme.breakpoints.up('sm')]: {
      width: 118,
      height: 118,
    },
    [theme.breakpoints.up('md')]: {
      width: 54,
      height: 54,
    },
  },
  text: {
    gridArea: 'text',
    alignSelf: 'center',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 52,
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
      fontSize: 18,
      marginBottom: 0,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 14,
      marginBottom: 0,
      justifySelf: 'left',
    },
  },
  info: {
    gridArea: 'info',
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      fontSize: 18,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
      textAlign: 'left',
      width: 492,
    },
  },
  root: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 34,
    display: 'grid',
    gridGap: 14,
    gridTemplateAreas: `"banner"
                        " text"
                        "info"`,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 22,
      paddingRight: 22,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 34,
      gridGap: 26,
      gridTemplateAreas: `"banner"
                        "info"`,
    },
  },
  buttonSubmit: {
    fontSize: 16,
  },
  footerButtonSubmit: {
    fontSize: 16,
    gridArea: 'footerButtonSubmit',
    justifySelf: 'end',
  },
  containerButtons: {
    fontSize: 16,
    borderTop: `1px solid ${neutral.D10}`,
    position: 'fixed',
    bottom: 0,
    width: '100%',
    gap: 14,
    padding: 16,
    display: 'grid',
    gridTemplateAreas: `"footerButtonSubmit"
                        "footerRejectButton"`,
  },
  rootBanner: {},
}));
