import { clsx } from 'clsx';
import { IconButton } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';

export function CustomBackButton({ onClick, classes = {} }) {
  return (
    <IconButton
      className={clsx(classes?.button)}
      aria-label='Back'
      onClick={onClick}
    >
      <BackIcon className={clsx(classes?.icon)} />
    </IconButton>
  );
}
