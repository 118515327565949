import { Dialog } from './components/dialog';
import ComponentWithTreatments from '../../../components/split-io/ComponentWithTreatments';
import FEATURES_NAMES from '../../../tools/split-io/consts/SplitFeaturesConstantsNames';
import { BrowserApp } from '../../../business/common/browser';

export function BrowserDialog() {
  const { countryCode, partnerId } = BrowserApp();

  return (
    <ComponentWithTreatments
      featureName={FEATURES_NAMES.CHROME_EXTENSION}
      componentOn={<Dialog showCloseButton={true} />}
      componentOff={<Dialog showCloseButton={false} />}
      attributes={{ COUNTRY: countryCode, PARTNER_ID: `${partnerId}` }}
    />
  );
}
