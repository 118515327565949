import { clsx } from 'clsx';
import { TextField } from '@material-ui/core';

import useStyles from './CustomTextField.style';

export function CustomTextField({ maxLength, ...textFieldProps }) {
  const _classes = useStyles();
  return (
    <TextField
      {...textFieldProps}
      margin='none'
      InputLabelProps={{
        disableAnimation: true,
        focused: false,
        shrink: true,
        classes: {
          root: _classes.textFieldLabel,
        },
      }}
      InputProps={{
        disableUnderline: true,
        classes: {
          input: clsx(
            _classes.textFieldInput,
            textFieldProps.label && _classes.inputWithLabel,
          ),
          multiline: _classes.textAreaRoot,
        },
        inputProps: {
          maxLength: maxLength,
        },
      }}
    />
  );
}
