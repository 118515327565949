import { infoboxStyles } from './styles';

export function InfoBox({ title, description }: InfoBoxProps) {
  const componentClasses = infoboxStyles();
  return (
    <div>
      <div className={componentClasses.containerComments}>
        <span className={componentClasses.comment}>{title}:</span>
        <span className={componentClasses.fontTextCommentDescripcion}>
          &nbsp;{description}
        </span>
      </div>
    </div>
  );
}

export interface InfoBoxProps {
  title: string;
  description: string;
}
