// Material UI
import { makeStyles } from '@material-ui/core'
// Constant
import Colors from '../../constants/Colors'

export default makeStyles(theme => ({
  body: {
    height: '100%',
    paddingTop: 24,
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
    },
  },
  headerRoot: {
    [theme.breakpoints.up('md')]: {
      padding: 12,
    },
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      height: 385,
      width: 488,
    },
  },
  root: {},
  description: {
    alignItems: 'center',
    backgroundColor: Colors.WHITE,
    display: 'flex',
    flexDirection: 'column',
    fontSize: 16,
    justifyContent: 'center',
    padding: 24,
  },
  descriptionText: {
    color: Colors.BLACK,
  },
  descriptionPhone: {
    fontWeight: 'bold',
  },
  descriptionImg: {
    maxHeight: 98,
    maxWidth: 98,
  },
}))
