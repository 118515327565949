import React from 'react';
import CustomDialog from '../../../common/custom-dialog/CustomDialog';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import CustomButton, {
  Types,
  Variants,
} from '../../../common/buttons/custom-button';
import { OrderMgmtAcceptOrderDialogStyles } from './OrderMgmtAcceptOrderDialog.styles';
import warningIcon from '../../../../assets/images/icons/warningIcon.svg';
import { useOrderMgmtAcceptOrderDialog } from './hooks/useOrderMgmtAcceptOrderDialog';
import { OrderMgmtProduct } from '../../../../business/order-mgmt';

export function OrderMgmtAcceptOrderDialog({
  products,
  onAcceptOrder,
  onClose,
  isOpen,
  cancelButtonText,
  confirmButtonText,
  acceptOrderDescriptionText,
  acceptOrderDisclaimerText,
  acceptOrderTitleText,
}: Props) {
  const styles = OrderMgmtAcceptOrderDialogStyles();
  const theme = useTheme<any>();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  function handleClose() {
    onClose();
  }

  return (
    <CustomDialog
      classes={{ paper: styles.paper, headerRoot: styles.header }}
      onClose={handleClose}
      open={isOpen}
      showBackButton={!isMdUp}
      showCloseButton={isMdUp}
    >
      <Box display='flex' flexDirection='column' gridGap='0.5rem'>
        <Typography className={styles.title}>{acceptOrderTitleText}</Typography>
        <Typography className={styles.description}>
          {acceptOrderDescriptionText}
        </Typography>
        <Box
          className={styles.disclaimerContent}
          display='flex'
          padding='1rem'
          gridGap='0.5rem'
          marginBottom='1rem'
        >
          <img
            className={styles.disclaimerIcon}
            src={warningIcon}
            alt='accept-icon'
          />
          <Box>
            <Typography className={styles.disclaimerText}>
              {acceptOrderDisclaimerText}
            </Typography>
            <ul className={styles.disclaimerItems}>
              {products.map(product =>
                product?.suggestions ? (
                  <li className={styles.disclaimerItem} key={product.id}>
                    {product.name}
                  </li>
                ) : (
                  (product?.toppings || []).map(
                    topping =>
                      topping?.suggestions && (
                        <li className={styles.disclaimerItem} key={topping.id}>
                          {topping.name}
                        </li>
                      ),
                  )
                ),
              )}
            </ul>
          </Box>
        </Box>
        <Box
          display='flex'
          flex={1}
          alignItems='center'
          justifyContent='flex-end'
          gridGap='1rem'
          width='100%'
        >
          <CustomButton
            onClick={handleClose}
            type={Types.RESET}
            variant={Variants.TEXT}
            fullWidth={false}
          >
            {cancelButtonText}
          </CustomButton>
          <CustomButton
            onClick={onAcceptOrder}
            type={Types.BUTTON}
            variant={Variants.CONTAINED}
            fullWidth={false}
          >
            {confirmButtonText}
          </CustomButton>
        </Box>
      </Box>
    </CustomDialog>
  );
}

interface Props {
  products: Array<OrderMgmtProduct>;
  onAcceptOrder: any;
  onClose: any;
  isOpen: boolean;
  cancelButtonText: string;
  confirmButtonText: string;
  acceptOrderDescriptionText: string;
  acceptOrderDisclaimerText: string;
  acceptOrderTitleText: string;
}
