import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(() => ({
  tooltip: {
    fontSize: '0.75rem',
    backgroundColor: neutral.black,
    padding: '0.5rem',
  },
  lastUpdate: {
    backgroundColor: neutral.white,
    fontSize: '0.75rem',
    fontWeight: 400,
    textDecorationLine: 'underline',
    color: neutral.D15,
    cursor: 'pointer',
    paddingTop: '1rem',
  },
  skeleton: {
    border: `1px solid ${neutral.D13}`,
    borderRadius: '0.5rem',
  },
  divider: {
    height: '1px',
    width: '100%',
    border: `1px solid ${neutral.D13}`,
  },
  dataZeroTitle: {
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: '1.625rem',
  },
  dataZeroDescription: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
  },
  emptyTitle: {
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: '1.625rem',
  },
  emptyDescription: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
  },
  filterText: {
    fontSize: '0.875rem',
    fontWeight: 700,
    lineHeight: '1rem',
  },
  searchInputRoot: {
    width: '9.375rem',
  },
  searchInput: {
    '&::placeholder': {
      color: neutral.black,
      fontSize: '0.875rem',
      fontWeight: 400,
    },
  },
  searchAdorned: {
    flexFlow: 'row-reverse',
    paddingRight: '1rem',
    borderRadius: '0.5rem',
  },
  selectRoot: {
    padding: '0.5rem',
    borderRadius: '0.5rem',
    width: '14.375rem',
    color: neutral.D16,
    fontSize: '0.875rem',
    border: `1px solid ${neutral.D11}`,
  },
  clearIcon: {
    cursor: 'pointer',
    fill: neutral['90'],
  },
  orderCardContainer: {
    border: `1px solid ${neutral.D10}`,
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
  },
  orderId: {
    lineHeight: 1.5,
    fontWeight: 'bold',
    workBreak: 'break-all',
    color: neutral.D20,
  },
  storeName: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    lineHeight: '1.25rem',
    wordBreak: 'break-word',
    color: neutral.D15,
  },
  userName: {
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '1.25rem',
  },
  phoneNumber: {
    color: neutral.D15,
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.25rem',
  },
  cardButton: {
    height: '2rem',
  },
}));
