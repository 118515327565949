// Consts
import urls from '../../constants/Urls'
// Utils
import UrlUtils from '../../utils/url-utils/UrlUtils'
// Services
import http from '../HttpService'
import LocalStorageService from '../local-storage-service'
// Interface
import { StepResponse } from './interfaces'

const getOnboardingList = () => {
  const baseUrl = LocalStorageService.getBaseUrl()
  const replacedUrl = UrlUtils.replaceUrlParams(urls.ONBOARDING_LIST)
  return http.get<StepResponse[]>(`${baseUrl}${replacedUrl}`)
}

const onboardingService = {
  getOnboardingList,
}

export default onboardingService
