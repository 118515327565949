import { createSlice } from '@reduxjs/toolkit';
import { INITIAL_STATE, OM_ORDER_DETAIL_SLICE_NAME } from './core';
import { orderDetailThunks } from './orderDetail.thunks';

export const orderMgmtDetailSlice = createSlice({
  name: OM_ORDER_DETAIL_SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    clear: state => ({ ...INITIAL_STATE, startedAt: state.startedAt }),
    setOrderId: (state, { payload }) => {
      state.orderId = payload;
    },
    setOrderState: (state, { payload }) => {
      state.orderState = payload;
    },
    setIsCountdownComplete: (state, { payload }) => {
      state.isCountdownComplete = payload;
    },
    disabled: (state, { payload }) => {
      state.isDisabled = payload;
    },
    openDialog: (state, { payload }) => {
      state.isOpenDialog = payload;
    },
    removeAllProducts: state => {
      state.products = [];
      state.productIdList = [];
      state.toppingIdList = [];
    },
    removeProductsWithOM: state => {
      const products = state.products.filter(p => !p.suggestions);
      state.products = products.map(x => ({
        ...x,
        toppings: x.toppings?.filter(t => !t.suggestions),
      }));
    },
    getProductsWithOM: state => {
      state.productIdList = !state.productIdList.length
        ? state.products.filter(p => p.suggestions).map(x => x.id)
        : state.productIdList;
    },
    addProducts: (state, { payload }) => {
      const data = payload.sort((a, b) =>
        a?.suggestions ? -1 : b?.suggestions ? 1 : 0,
      );
      state.products = data;
      state.detailProducts = data;
    },
    removeProductDetail: (state, { payload }) => {
      state.detailProducts = state.detailProducts.filter(
        x => parseInt(x.id) != payload,
      );
    },
    removeToppingDetail: (state, { payload }) => {
      const toppings = state.detailProducts.find(
        x => parseInt(x.id) === payload.productId,
      )?.toppings;

      state.detailProducts = state.detailProducts.map(x => {
        if (parseInt(x.id) === payload.productId) {
          return {
            ...x,
            toppings: toppings?.filter(
              t => parseInt(t.id) !== payload.toppingId,
            ),
          };
        }
        return x;
      });
    },
    addProductToRemove: (state, { payload }) => {
      const data = state.itemsToRemove?.products ?? [];
      state.itemsToRemove = {
        ...state.itemsToRemove,
        products: [...data, payload],
      };
    },
    addToppingToRemove: (state, { payload }) => {
      const data = state.itemsToRemove?.toppings ?? [];
      state.itemsToRemove = {
        ...state.itemsToRemove,
        toppings: [...data, payload],
      };
    },
    resetDetailProducts: state => {
      state.detailProducts = state.products;
      state.itemsToRemove = {};
    },
    addToppingIdList: (state, { payload }) => {
      state.toppingIdList = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(orderDetailThunks.acceptOrder.pending, state => {
      state.status = 'isLoading';
    });
    builder.addCase(orderDetailThunks.acceptOrder.fulfilled, state => {
      state.status = 'successful';
    });
    builder.addCase(orderDetailThunks.acceptOrder.rejected, state => {
      state.status = 'rejected';
    });
    builder.addCase(orderDetailThunks.rejectedOrder.pending, state => {
      state.status = 'isLoading';
    });
    builder.addCase(orderDetailThunks.rejectedOrder.fulfilled, state => {
      state.status = 'successful';
    });
    builder.addCase(orderDetailThunks.rejectedOrder.rejected, state => {
      state.status = 'rejected';
    });
    builder.addCase(orderDetailThunks.disabledAutoTake.pending, state => {
      state.status = 'isLoading';
    });
    builder.addCase(
      orderDetailThunks.disabledAutoTake.fulfilled,
      (state, { payload: { created_at } }) => {
        state.startedAt = created_at;
      },
    );
    builder.addCase(orderDetailThunks.disabledAutoTake.rejected, state => {
      state.status = 'rejected';
    });
  },
});

export const orderMgmtDetailActions = orderMgmtDetailSlice.actions;
export const orderMgmtDetailReducer = orderMgmtDetailSlice.reducer;
