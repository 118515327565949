import { makeStyles } from '@material-ui/core';

import { neutral, orange, red } from '../../shared/styles/colors';

export default makeStyles(() => {
  return {
    buttonRoot: {
      gridArea: '1 / 2 / 2 / 3',
    },
    secondaryButtonRoot: {
      gridArea: 'initial',
    },
    buttonSubmitRoot: {
      width: 'auto',
      padding: '0.25rem 1rem',
      fontSize: '0.875rem',
      lineHeight: '1.5rem',
      borderRadius: '0.5rem',
    },
    secondaryButtonSubmitRoot: {
      padding: 0,
      fontSize: '0.875rem',
      lineHeight: '1.5rem',
      borderRadius: '0.5rem',
    },
    footerText: {
      color: neutral['50'],
      fontSize: 12,
      lineHeight: 'normal',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    root: ({ showOrderDeliveryDetails }: Props) => ({
      alignItems: 'center',
      display: 'grid',
      gridAutoFlow: 'column',
      gap: '1rem',
      justifyContent: showOrderDeliveryDetails ? 'stretch' : 'end',
      fontSize: '1rem',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: 'normal',
    }),
    checkCodeContainer: {
      borderRadius: '62.5rem',
      background: neutral['20'],
    },
    checkCode: {
      color: neutral['80'],
      fontSize: '0.75rem',
      fontWeight: 600,
    },
    alert: ({ hasOrderDeliveryDetailMessage }: Props) => ({
      backgroundColor: hasOrderDeliveryDetailMessage ? red[10]: orange[10],
      color: hasOrderDeliveryDetailMessage ? red[60] : orange[60],
      padding: '0 0.75rem',
      fontSize: '10px',
      fontWeight: 600,
      lineHeight: '1rem',
      letterSpacing: '1.2px',
      textTransform: 'uppercase',
      '& svg': {
        fill: hasOrderDeliveryDetailMessage ? red[60] : orange[60]
      }
    }),
    alertMessage: {
      padding: '0.625rem 0'
    },
  };
});

interface Props {
  showOrderDeliveryDetails: boolean;
  hasOrderDeliveryDetailMessage: boolean;
}
