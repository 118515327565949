import { useFormatMessage } from '../../../../intl/intl';

export function useAdditionalRTLang() {
  return {
    error: {
      title: useFormatMessage({ id: 'cargo.form.coverageRejectedTitle' }),
      description: useFormatMessage({
        id: 'cargo.form.coverageRejectedDescription',
      }),
    },
  };
}
