// Material UI
import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  childrenContainer: {
    paddingBottom: 16,
    [theme.breakpoints.up('md')]: {
      paddingBottom: 40,
    },
  },
  headerRoot: {
    [theme.breakpoints.up('md')]: {
      padding: 12,
    },
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      height: 445,
    },
  },
  root: {},
}));
