// Redux
import { createActions } from 'redux-actions';
// External Libs
import { get } from 'lodash';
// Consts
import createProxySessionErrorTypes from '../../constants/CreateProxySessionErrorTypes';
import createWhimErrorTypes from '../../constants/CreateWhimErrorTypes';
import DialogType from '../../constants/DialogType';
import { OrderDeliveryMethodType } from '../../constants/OrderDeliveryMethodType';
// Utils
import { formatMessage } from '../../intl/intl';
import utils from '../../redux/onboarding-dialog/utils.ts';
// Services
import OrderService from '../../services/order-service';
import PhoneProxySessionService from '../../services/phone-proxy-session-service';
import supportIssueSuggestionsService from '../../services/support-issue-suggestions-service';
// Actions
import appActions from './app-actions';
import appStore from '../stores/app.store';
import customerPhoneProxySessionDialogActions from './customerPhoneProxySessionDialog.actions';
import EventActions from './event-actions';
import failedProxySessionDialogActions from './failedProxySessionDialog.actions';
import { actions as logOutDialogActions } from '../log-out-dialog';
import { actions as logOutDialoReasonsgActions } from '../log-out-reasons-dialog';
import OrderActions from './orders-actions/orders.actions';
import orderDetailDialogActions from './orderDetailDialog.actions';
import orderRejectionByStockOutDialogActions from './orderRejectionByStockOutDialog.actions';
import orderRejectionReasonsDialogActions from './orderRejectionReasonsDialog.actions';
import orderWhimDialogActions from './orderWhimDialog.actions';
import rtDialogActions from './RTDialog.actions';
import RTReleaseConfirmationDialogActions from './rt-release-confirmation-dialog-actions';
import rtReportReasonsDialogActions from './RTReportReasonsDialog.actions';
import successfulProxySessionDialogActions from './successfulProxySessionDialog.actions';
import { actions as cancelByStockOutSuggestionConfirmationDialogActions } from '../cancel-by-stock-out-suggestion-confirmation-dialog';
import { actions as cookedOrderConfirmationIssueDialogActions } from '../cooked-order-confirmation-issue-dialog';
import { actions as itemsDeactivationStockOutIssueDialogActions } from '../items-deactivation-stock-out-issue-dialog';
import { actions as justCookedOrderIssueDialogActions } from '../just-cooked-order-issue-dialog';
import { actions as orderRejectionByMotoboyLackDialogActions } from '../order-rejection-by-motoboy-lack-dialog';
import { actions as orderRejectionBySaturatedStoreDialogActions } from '../order-rejection-by-saturated-store-dialog';
import { actions as RTAssignedIssueSuggestionDialogActions } from '../rt-assigned-issue-suggestions-dialog';
import { actions as RTLackIssueSuggestionsDialogActions } from '../rt-lack-issue-suggestions-dialog';
import { actions as stockOutIssueSuggestionsDialogActions } from '../stock-out-issue-suggestions-dialog';
import { actions as storeDiagnosticsDialogActions } from '../store-diagnostics-dialog';
import { actions as supportDialogActions } from '../support-dialog';
import { actions as supportReasonsDialogActions } from '../support-reasons-dialog';
import { actions as suggestProductDialogActions } from '../suggest-product-dialog';
import { actions as productSuggestionAvailabilityDialogActions } from '../product-suggestion-availability-dialog';
import { actions as successfulSuggestingProductDialogActions } from '../successful-suggesting-product-dialog';
import { actions as errorSuggestingProductDialogActions } from '../error-suggesting-product-dialog';
import { actions as onBoardingDialogActions } from '../onboarding-dialog';
import { actions as startPMFlowDialogActions } from '../start-PM-flow-dialog';
import { actions as errorPMFlowDialogActions } from '../error-PM-flow-dialog';
import { actions as endPMFlowDialogActions } from '../end-PM-flow-dialog';
import { actions as phonePMFlowDialogActions } from '../phone-PM-flow-dialog';
import { actions as schedulePMFlowDialogActions } from '../schedule-PM-flow-dialog';
import { actions as multiStoreDropDialogActions } from '../multi-store-drop-dialog';
import { actions as singleStoreDropDialogActions } from '../single-store-drop-dialog';
import { actions as extendClosingHoursDialogActions } from '../extend-closing-hours-dialog';
import { actions as deactivatedProductsDialogActions } from '../deactivated-products-dialog';
import {
  orderMgmtDialogActions,
  orderMgmtItemSearchDialogActions,
} from '../../business/order-mgmt/infra/store';
import onboardingService from '../../services/onboarding-service';
import PMAutomationService from '../../services/PM-automation-service';
import { PWEXCodes } from '../../exceptions';

const { popStackAction, pushStackAction } = createActions({
  POP_STACK_ACTION: () => {},
  PUSH_STACK_ACTION: dialogType => ({ dialogType }),
});

const back = () => dispatch => {
  const currentDialogCode = appStore.getCurrentDialogCode();
  clear(currentDialogCode)(dispatch);
  openCurrent()(dispatch);
};

const close = () => dispatch => {
  const stack = appStore.getDialogsStack();
  for (let i = stack.length - 1; i >= 0; i--) {
    clear(stack[i])(dispatch);
  }
};

const closeIfOrderDoesNotExist = () => dispatch => {
  const stack = appStore.getDialogsStack();
  for (let i = stack.length - 1; i >= 0; i--) {
    const orderId = appStore.getOrderIdByDialogCode(stack[i]);

    if (orderId === undefined) {
      return;
    }

    const doesOrderIdExist = appStore.doesOrderExists(orderId);
    if (doesOrderIdExist) {
      return;
    }

    clear(stack[i])(dispatch);
  }
};

const clear = dialogCode => dispatch => {
  switch (dialogCode) {
    case DialogType.CANCEL_BY_STOCK_OUT_SUGGESTION_CONFIRMATION:
      cancelByStockOutSuggestionConfirmationDialogActions.clear()(dispatch);
      break;
    case DialogType.COOKED_ORDER_CONFIRMATION_ISSUE:
      cookedOrderConfirmationIssueDialogActions.clear()(dispatch);
      break;
    case DialogType.CUSTOMER_PHONE_PROXY_SESSION:
      customerPhoneProxySessionDialogActions.clearCustomerPhoneProxySessionDialog()(
        dispatch,
      );
      break;
    case DialogType.FAILED_PROXY_SESSION:
      failedProxySessionDialogActions.clearFailedProxySessionDialog()(dispatch);
      break;
    case DialogType.ITEMS_DEACTIVATION_STOCK_OUT_ISSUE:
      itemsDeactivationStockOutIssueDialogActions.clear()(dispatch);
      break;
    case DialogType.JUST_COOKED_ORDER_ISSUE:
      justCookedOrderIssueDialogActions.clear()(dispatch);
      break;
    case DialogType.LOG_OUT:
      logOutDialogActions.clear()(dispatch);
      break;
    case DialogType.ORDER_DETAIL:
      orderDetailDialogActions.clearOrderDetailDialog()(dispatch);
      break;
    case DialogType.ORDER_REJECTION_BY_MOTOBOY_LACK:
      orderRejectionByMotoboyLackDialogActions.clear()(dispatch);
      break;
    case DialogType.ORDER_REJECTION_BY_SATURATED_STORE:
      orderRejectionBySaturatedStoreDialogActions.clear()(dispatch);
      break;
    case DialogType.ORDER_REJECTION_BY_STOCK_OUT:
      orderRejectionByStockOutDialogActions.clearOrderRejectionByStockOutDialog()(
        dispatch,
      );
      break;
    case DialogType.ORDER_REJECTION_REASONS:
      orderRejectionReasonsDialogActions.clearOrderRejectionReasonsDialog()(
        dispatch,
      );
      break;
    case DialogType.ORDER_WHIM:
      orderWhimDialogActions.clearOrderWhimDialog()(dispatch);
      break;
    case DialogType.RT_ASSIGNED_ISSUE:
      RTAssignedIssueSuggestionDialogActions.clear()(dispatch);
      break;
    case DialogType.RT_LACK_ISSUE:
      RTLackIssueSuggestionsDialogActions.clear()(dispatch);
      break;
    case DialogType.RT:
      rtDialogActions.clearRTDialog()(dispatch);
      break;
    case DialogType.RT_RELEASE_CONFIRMATION:
      RTReleaseConfirmationDialogActions.clear()(dispatch);
      break;
    case DialogType.RT_REPORT_REASONS:
      rtReportReasonsDialogActions.clearRTReportReasonsDialog()(dispatch);
      break;
    case DialogType.STOCK_OUT_ISSUE_SUGGESTION:
      stockOutIssueSuggestionsDialogActions.clear()(dispatch);
      break;
    case DialogType.STORE_DIAGNOSTICS_DIALOG:
      storeDiagnosticsDialogActions.clear()(dispatch);
      break;
    case DialogType.SUGGEST_PRODUCT_DIALOG:
      suggestProductDialogActions.clear()(dispatch);
      break;
    case DialogType.SUCCESSFUL_PROXY_SESSION:
      successfulProxySessionDialogActions.clearSuccessfulProxySessionDialog()(
        dispatch,
      );
      break;
    case DialogType.SUPPORT_MENU_DIALOG:
      supportDialogActions.clear()(dispatch);
      break;
    case DialogType.SUPPORT_REASONS:
      supportReasonsDialogActions.clear()(dispatch);
      break;
    case DialogType.ONBOARDING_DIALOG:
      onBoardingDialogActions.clear()(dispatch);
      break;
    case DialogType.PRODUCT_SUGGESTION_AVAILABILITY_DIALOG:
      productSuggestionAvailabilityDialogActions.clear()(dispatch);
      break;
    case DialogType.SUCCESSFUL_SUGGESTING_PRODUCT_DIALOG:
      successfulSuggestingProductDialogActions.clear()(dispatch);
      break;
    case DialogType.ERROR_SUGGESTING_PRODUCT_DIALOG:
      errorSuggestingProductDialogActions.clear()(dispatch);
      break;
    case DialogType.LOG_OUT_REASONS:
      logOutDialoReasonsgActions.clear()(dispatch);
      break;
    case DialogType.START_PM_FLOW_DIALOG:
      startPMFlowDialogActions.clear()(dispatch);
      break;
    case DialogType.ERROR_PM_FLOW_DIALOG:
      errorPMFlowDialogActions.clear()(dispatch);
      break;
    case DialogType.END_PM_FLOW_DIALOG:
      endPMFlowDialogActions.clear()(dispatch);
      break;
    case DialogType.PHONE_PM_FLOW_DIALOG:
      phonePMFlowDialogActions.clear()(dispatch);
      break;
    case DialogType.SCHEDULE_PM_FLOW_DIALOG:
      schedulePMFlowDialogActions.clear()(dispatch);
      break;
    case DialogType.MULTI_STORE_DROP_DIALOG:
      multiStoreDropDialogActions.clear()(dispatch);
      break;
    case DialogType.SINGLE_STORE_DROP_DIALOG:
      singleStoreDropDialogActions.clear()(dispatch);
      break;
    case DialogType.EXTEND_CLOSING_HOURS:
      extendClosingHoursDialogActions.clear()(dispatch);
      break;
    case DialogType.DEACTIVATED_PRODUCTS:
      deactivatedProductsDialogActions.clear()(dispatch);
      break;
    case DialogType.ORDER_MANAGEMENT:
      dispatch(orderMgmtDialogActions.clear());
      break;
    case DialogType.ORDER_MANAGEMENT_ITEM_SEARCH:
      dispatch(orderMgmtItemSearchDialogActions.clear());
      break;
    default:
      break;
  }
  dispatch(popStackAction());
};

const closeCurrent = () => dispatch => {
  const currentDialogCode = appStore.getCurrentDialogCode();

  switch (currentDialogCode) {
    case DialogType.CANCEL_BY_STOCK_OUT_SUGGESTION_CONFIRMATION:
      cancelByStockOutSuggestionConfirmationDialogActions.close()(dispatch);
      break;
    case DialogType.COOKED_ORDER_CONFIRMATION_ISSUE:
      cookedOrderConfirmationIssueDialogActions.close()(dispatch);
      break;
    case DialogType.CUSTOMER_PHONE_PROXY_SESSION:
      customerPhoneProxySessionDialogActions.closeCustomerPhoneProxySessionDialog()(
        dispatch,
      );
      break;
    case DialogType.FAILED_PROXY_SESSION:
      failedProxySessionDialogActions.closeFailedProxySessionDialog()(dispatch);
      break;
    case DialogType.ITEMS_DEACTIVATION_STOCK_OUT_ISSUE:
      itemsDeactivationStockOutIssueDialogActions.close()(dispatch);
      break;
    case DialogType.JUST_COOKED_ORDER_ISSUE:
      justCookedOrderIssueDialogActions.close()(dispatch);
      break;
    case DialogType.LOG_OUT:
      logOutDialogActions.close()(dispatch);
      break;
    case DialogType.ORDER_DETAIL:
      orderDetailDialogActions.closeOrderDetailDialog()(dispatch);
      break;
    case DialogType.ORDER_REJECTION_BY_MOTOBOY_LACK:
      orderRejectionByMotoboyLackDialogActions.close()(dispatch);
      break;
    case DialogType.ORDER_REJECTION_BY_SATURATED_STORE:
      orderRejectionBySaturatedStoreDialogActions.close()(dispatch);
      break;
    case DialogType.ORDER_REJECTION_BY_STOCK_OUT:
      orderRejectionByStockOutDialogActions.closeOrderRejectionByStockOutDialog()(
        dispatch,
      );
      break;
    case DialogType.ORDER_REJECTION_REASONS:
      orderRejectionReasonsDialogActions.closeOrderRejectionReasonsDialog()(
        dispatch,
      );
      break;
    case DialogType.ORDER_WHIM:
      orderWhimDialogActions.closeOrderWhimDialog()(dispatch);
      break;
    case DialogType.RT_ASSIGNED_ISSUE:
      RTAssignedIssueSuggestionDialogActions.close()(dispatch);
      break;
    case DialogType.RT_LACK_ISSUE:
      RTLackIssueSuggestionsDialogActions.close()(dispatch);
      break;
    case DialogType.RT:
      rtDialogActions.closeRTDialog()(dispatch);
      break;
    case DialogType.RT_RELEASE_CONFIRMATION:
      RTReleaseConfirmationDialogActions.close()(dispatch);
      break;
    case DialogType.RT_REPORT_REASONS:
      rtReportReasonsDialogActions.closeRTReportReasonsDialog()(dispatch);
      break;
    case DialogType.STOCK_OUT_ISSUE_SUGGESTION:
      stockOutIssueSuggestionsDialogActions.close()(dispatch);
      break;
    case DialogType.STORE_DIAGNOSTICS_DIALOG:
      storeDiagnosticsDialogActions.close()(dispatch);
      break;
    case DialogType.SUGGEST_PRODUCT_DIALOG:
      suggestProductDialogActions.close()(dispatch);
      break;
    case DialogType.SUCCESSFUL_PROXY_SESSION:
      successfulProxySessionDialogActions.closeSuccessfulProxySessionDialog()(
        dispatch,
      );
      break;
    case DialogType.SUPPORT_MENU_DIALOG:
      supportDialogActions.close()(dispatch);
      break;
    case DialogType.SUPPORT_REASONS:
      supportReasonsDialogActions.close()(dispatch);
      break;
    case DialogType.ONBOARDING_DIALOG:
      onBoardingDialogActions.close()(dispatch);
      break;
    case DialogType.PRODUCT_SUGGESTION_AVAILABILITY_DIALOG:
      productSuggestionAvailabilityDialogActions.close()(dispatch);
      break;
    case DialogType.SUCCESSFUL_SUGGESTING_PRODUCT_DIALOG:
      successfulSuggestingProductDialogActions.close()(dispatch);
      break;
    case DialogType.ERROR_SUGGESTING_PRODUCT_DIALOG:
      errorSuggestingProductDialogActions.close()(dispatch);
      break;
    case DialogType.LOG_OUT_REASONS:
      logOutDialoReasonsgActions.close()(dispatch);
      break;
    case DialogType.START_PM_FLOW_DIALOG:
      startPMFlowDialogActions.close()(dispatch);
      break;
    case DialogType.ERROR_PM_FLOW_DIALOG:
      errorPMFlowDialogActions.close()(dispatch);
      break;
    case DialogType.END_PM_FLOW_DIALOG:
      endPMFlowDialogActions.close()(dispatch);
      break;
    case DialogType.PHONE_PM_FLOW_DIALOG:
      phonePMFlowDialogActions.close()(dispatch);
      break;
    case DialogType.SCHEDULE_PM_FLOW_DIALOG:
      schedulePMFlowDialogActions.close()(dispatch);
      break;
    case DialogType.MULTI_STORE_DROP_DIALOG:
      multiStoreDropDialogActions.close()(dispatch);
      break;
    case DialogType.SINGLE_STORE_DROP_DIALOG:
      singleStoreDropDialogActions.close()(dispatch);
      break;
    case DialogType.EXTEND_CLOSING_HOURS:
      extendClosingHoursDialogActions.close()(dispatch);
      break;
    case DialogType.DEACTIVATED_PRODUCTS:
      deactivatedProductsDialogActions.close()(dispatch);
      break;
    case DialogType.ORDER_MANAGEMENT:
      dispatch(orderMgmtDialogActions.close());
      break;
    case DialogType.ORDER_MANAGEMENT_ITEM_SEARCH:
      dispatch(orderMgmtItemSearchDialogActions.close());
      break;
    default:
      break;
  }
};

const closeFailedProxySessionDialog = () => dispatch => {
  closeCurrent()(dispatch);
};

const closeSuccessfulProxySessionDialog = () => dispatch => {
  closeCurrent()(dispatch);
};

const openCurrent = () => dispatch => {
  const currentDialogCode = appStore.getCurrentDialogCode();
  switch (currentDialogCode) {
    case DialogType.CANCEL_BY_STOCK_OUT_SUGGESTION_CONFIRMATION:
      cancelByStockOutSuggestionConfirmationDialogActions.open()(dispatch);
      break;
    case DialogType.COOKED_ORDER_CONFIRMATION_ISSUE:
      cookedOrderConfirmationIssueDialogActions.open()(dispatch);
      break;
    case DialogType.CUSTOMER_PHONE_PROXY_SESSION:
      customerPhoneProxySessionDialogActions.openCustomerPhoneProxySessionDialog()(
        dispatch,
      );
      break;
    case DialogType.FAILED_PROXY_SESSION:
      failedProxySessionDialogActions.openFailedProxySessionDialog()(dispatch);
      break;
    case DialogType.ITEMS_DEACTIVATION_STOCK_OUT_ISSUE:
      itemsDeactivationStockOutIssueDialogActions.open()(dispatch);
      break;
    case DialogType.JUST_COOKED_ORDER_ISSUE:
      justCookedOrderIssueDialogActions.open()(dispatch);
      break;
    case DialogType.LOG_OUT:
      logOutDialogActions.open()(dispatch);
      break;
    case DialogType.ORDER_DETAIL:
      orderDetailDialogActions.openOrderDetailDialog()(dispatch);
      break;
    case DialogType.ORDER_REJECTION_BY_MOTOBOY_LACK:
      orderRejectionByMotoboyLackDialogActions.open()(dispatch);
      break;
    case DialogType.ORDER_REJECTION_BY_SATURATED_STORE:
      orderRejectionBySaturatedStoreDialogActions.open()(dispatch);
      break;
    case DialogType.ORDER_REJECTION_BY_STOCK_OUT:
      orderRejectionByStockOutDialogActions.openOrderRejectionByStockOutDialog()(
        dispatch,
      );
      break;
    case DialogType.ORDER_REJECTION_REASONS:
      orderRejectionReasonsDialogActions.openOrderRejectionReasonsDialog()(
        dispatch,
      );
      break;
    case DialogType.ORDER_WHIM:
      orderWhimDialogActions.openOrderWhimDialog()(dispatch);
      break;
    case DialogType.RT_ASSIGNED_ISSUE:
      RTAssignedIssueSuggestionDialogActions.open()(dispatch);
      break;
    case DialogType.RT_LACK_ISSUE:
      RTLackIssueSuggestionsDialogActions.open()(dispatch);
      break;
    case DialogType.RT:
      rtDialogActions.openRTDialog()(dispatch);
      break;
    case DialogType.RT_RELEASE_CONFIRMATION:
      RTReleaseConfirmationDialogActions.open()(dispatch);
      break;
    case DialogType.RT_REPORT_REASONS:
      rtReportReasonsDialogActions.openRTReportReasonsDialog()(dispatch);
      break;
    case DialogType.STOCK_OUT_ISSUE_SUGGESTION:
      stockOutIssueSuggestionsDialogActions.open()(dispatch);
      break;
    case DialogType.STORE_DIAGNOSTICS_DIALOG:
      storeDiagnosticsDialogActions.maximize()(dispatch);
      break;
    case DialogType.SUGGEST_PRODUCT_DIALOG:
      suggestProductDialogActions.open()(dispatch);
      break;
    case DialogType.SUCCESSFUL_PROXY_SESSION:
      successfulProxySessionDialogActions.open()(dispatch);
      break;
    case DialogType.SUPPORT_MENU_DIALOG:
      supportDialogActions.open()(dispatch);
      break;
    case DialogType.SUPPORT_REASONS:
      supportReasonsDialogActions.open()(dispatch);
      break;
    case DialogType.ONBOARDING_DIALOG:
      onBoardingDialogActions.open()(dispatch);
      break;
    case DialogType.PRODUCT_SUGGESTION_AVAILABILITY_DIALOG:
      productSuggestionAvailabilityDialogActions.open()(dispatch);
      break;
    case DialogType.SUCCESSFUL_SUGGESTING_PRODUCT_DIALOG:
      successfulSuggestingProductDialogActions.open()(dispatch);
      break;
    case DialogType.ERROR_SUGGESTING_PRODUCT_DIALOG:
      errorSuggestingProductDialogActions.open()(dispatch);
      break;
    case DialogType.LOG_OUT_REASONS:
      logOutDialoReasonsgActions.open()(dispatch);
      break;
    case DialogType.START_PM_FLOW_DIALOG:
      startPMFlowDialogActions.open()(dispatch);
      break;
    case DialogType.ERROR_PM_FLOW_DIALOG:
      errorPMFlowDialogActions.open()(dispatch);
      break;
    case DialogType.END_PM_FLOW_DIALOG:
      endPMFlowDialogActions.open()(dispatch);
      break;
    case DialogType.PHONE_PM_FLOW_DIALOG:
      phonePMFlowDialogActions.open()(dispatch);
      break;
    case DialogType.SCHEDULE_PM_FLOW_DIALOG:
      schedulePMFlowDialogActions.open()(dispatch);
      break;
    case DialogType.MULTI_STORE_DROP_DIALOG:
      multiStoreDropDialogActions.open()(dispatch);
      break;
    case DialogType.SINGLE_STORE_DROP_DIALOG:
      singleStoreDropDialogActions.open()(dispatch);
      break;
    default:
      break;
  }
};

const openCancelByStockOutSuggestionConfirmationDialog = () => dispatch => {
  const orderId = appStore.getOrderIdInOrderDetailDialog();
  closeCurrent()(dispatch);
  dispatch(
    pushStackAction(DialogType.CANCEL_BY_STOCK_OUT_SUGGESTION_CONFIRMATION),
  );
  cancelByStockOutSuggestionConfirmationDialogActions.open(orderId)(dispatch);
};

const openItemsDeactivationStockOutIssueDialog = () => dispatch => {
  const orderId = appStore.getOrderIdInOrderDetailDialog();
  closeCurrent()(dispatch);
  dispatch(pushStackAction(DialogType.ITEMS_DEACTIVATION_STOCK_OUT_ISSUE));
  itemsDeactivationStockOutIssueDialogActions.open(orderId)(dispatch);
};

const openLogOutDialog = () => dispatch => {
  const doesUserHavePendingOrders = appStore.doesUserHavePendingOrders();

  if (doesUserHavePendingOrders) {
    const cantLogOutWithOrders = formatMessage({
      id: 'mainLayout.cantLogout',
    });
    appActions.openSnackBarError(cantLogOutWithOrders)(dispatch);
    return;
  }
  dispatch(pushStackAction(DialogType.LOG_OUT));
  logOutDialogActions.open()(dispatch);
};
const openLogOutDialogReasons = () => dispatch => {
  closeCurrent()(dispatch);
  dispatch(pushStackAction(DialogType.LOG_OUT_REASONS));
  logOutDialoReasonsgActions.open()(dispatch);
};

const openOrderDetailDialog = orderId => async dispatch => {
  dispatch(pushStackAction(DialogType.ORDER_DETAIL));
  orderDetailDialogActions.openOrderDetailDialog(orderId)(dispatch);
};

const openOrderRejectionByMotoboyLackDialog = refuseReason => dispatch => {
  const order = appStore.getOrderIdInOrderDetailDialog();
  closeCurrent()(dispatch);
  dispatch(pushStackAction(DialogType.ORDER_REJECTION_BY_MOTOBOY_LACK));
  orderRejectionByMotoboyLackDialogActions.open(
    order.id,
    refuseReason,
  )(dispatch);
};

const openSupportReasonsDialog = (
  description,
  supportSuggestions,
) => async dispatch => {
  try {
    const orderId = appStore.getOrderIdInOrderDetailDialog();

    EventActions.onOpenSupportReasonsDialog(orderId);

    closeCurrent()(dispatch);
    dispatch(pushStackAction(DialogType.SUPPORT_REASONS));

    supportReasonsDialogActions.open(
      orderId,
      description,
      supportSuggestions || [],
    )(dispatch);
  } catch (ex) {
    await appActions.handleCatch(ex)(dispatch);
  }
};

const openRtAssignedIssueDialog = () => async dispatch => {
  try {
    const response = await supportIssueSuggestionsService.getWithRTIssueSuggestions();
    const description = get(response, 'data.description', '');
    const withRTSuggestions = get(response, 'data.next_steps', []);
    const orderId = appStore.getOrderIdInOrderDetailDialog();
    closeCurrent()(dispatch);
    dispatch(pushStackAction(DialogType.RT_ASSIGNED_ISSUE));
    RTAssignedIssueSuggestionDialogActions.open(
      orderId,
      description,
      withRTSuggestions,
    )(dispatch);
  } catch (ex) {
    await appActions.handleCatch(ex)(dispatch);
  }
};

const openJustCookedOrderIssueDialog = () => async dispatch => {
  try {
    const orderId = appStore.getOrderIdInOrderDetailDialog();
    const response = await supportIssueSuggestionsService.getNoRTIssueSuggestions(
      orderId,
    );
    const description = get(response, 'data.description', '');
    const withoutRTSuggestions = get(response, 'data.next_steps', []);
    closeCurrent()(dispatch);
    dispatch(pushStackAction(DialogType.JUST_COOKED_ORDER_ISSUE));
    justCookedOrderIssueDialogActions.open(
      orderId,
      description,
      withoutRTSuggestions,
    )(dispatch);
  } catch (ex) {
    await appActions.handleCatch(ex)(dispatch);
  }
};

const openRTLackIssueSuggestionsDialog = () => async dispatch => {
  try {
    const orderId = appStore.getOrderIdInOrderDetailDialog();
    const response = await supportIssueSuggestionsService.getNoRTIssueSuggestions(
      orderId,
    );
    const description = get(response, 'data.description', '');
    const withoutRTSuggestions = get(response, 'data.next_steps', []);
    closeCurrent()(dispatch);
    dispatch(pushStackAction(DialogType.RT_LACK_ISSUE));
    RTLackIssueSuggestionsDialogActions.open(
      orderId,
      description,
      withoutRTSuggestions,
    )(dispatch);
  } catch (ex) {
    await appActions.handleCatch(ex)(dispatch);
  }
};

const openCookedOrderConfirmationIssueDialog = () => async dispatch => {
  try {
    const orderId = appStore.getOrderIdInOrderDetailDialog();
    const response = await supportIssueSuggestionsService.getNoRTIssueSuggestions(
      orderId,
    );
    const description = get(response, 'data.description', '');
    const withoutRTSuggestions = get(response, 'data.next_steps', []);
    closeCurrent()(dispatch);
    dispatch(pushStackAction(DialogType.COOKED_ORDER_CONFIRMATION_ISSUE));

    cookedOrderConfirmationIssueDialogActions.open(
      orderId,
      description,
      withoutRTSuggestions,
    )(dispatch);
  } catch (ex) {
    await appActions.handleCatch(ex)(dispatch);
  }
};

const openOrderRejectionBySaturatedStoreDialog = refuseReason => dispatch => {
  const order = appStore.getOrderDetailInOrderRejectionDialog();
  closeCurrent()(dispatch);
  dispatch(pushStackAction(DialogType.ORDER_REJECTION_BY_SATURATED_STORE));
  orderRejectionBySaturatedStoreDialogActions.open(
    order.id,
    refuseReason,
  )(dispatch);
};

const openOrderRejectionByStockOutDialog = refuseReason => dispatch => {
  const order = appStore.getOrderDetailInOrderRejectionDialog();
  closeCurrent()(dispatch);
  dispatch(pushStackAction(DialogType.ORDER_REJECTION_BY_STOCK_OUT));
  orderRejectionByStockOutDialogActions.openOrderRejectionByStockOutDialog(
    order.id,
    refuseReason,
  )(dispatch);
};
const openOrderRejectionDialog = orderId => async dispatch => {
  dispatch(pushStackAction(DialogType.ORDER_REJECTION_REASONS));
  orderRejectionReasonsDialogActions.openOrderRejectionReasonsDialog(orderId)(
    dispatch,
  );
};

const openSupportDialog = () => async dispatch => {
  dispatch(pushStackAction(DialogType.SUPPORT_MENU_DIALOG));
  supportDialogActions.open()(dispatch);
};

const openOrderWhimDialog = (productName, productId) => async dispatch => {
  const orderId = appStore.getOrderIdInOrderDetailDialog();
  EventActions.onClickAddWhimButton(orderId);
  dispatch(pushStackAction(DialogType.ORDER_WHIM));
  orderWhimDialogActions.openOrderWhimDialog(
    productName,
    productId,
    orderId,
  )(dispatch);
};

const openCustomerPhoneProxySessionDialog = ({
  isOM = false,
} = {}) => async dispatch => {
  EventActions.onClickShowPhoneButton({ isOM });
  const orderId = appStore.getOrderIdInOrderDetailDialog();
  closeCurrent()(dispatch);
  dispatch(pushStackAction(DialogType.CUSTOMER_PHONE_PROXY_SESSION));
  customerPhoneProxySessionDialogActions.openCustomerPhoneProxySessionDialog(
    orderId,
    isOM,
  )(dispatch);
};

const openSuccessfulProxySessionDialog = (
  callFrom,
  callTo,
) => async dispatch => {
  const orderId = appStore.getOrderIdInCustomerPhoneProxySessionDialog();
  closeCurrent()(dispatch);
  dispatch(pushStackAction(DialogType.SUCCESSFUL_PROXY_SESSION));
  successfulProxySessionDialogActions.openSuccessfulProxySessionDialog(
    orderId,
    callFrom,
    callTo,
  )(dispatch);
};

const openFailedProxySessionDialog = () => async dispatch => {
  const orderId = appStore.getOrderIdInCustomerPhoneProxySessionDialog();
  closeCurrent()(dispatch);
  dispatch(pushStackAction(DialogType.FAILED_PROXY_SESSION));
  failedProxySessionDialogActions.openFailedProxySessionDialog(orderId)(
    dispatch,
  );
};

const openRTDialog = orderId => async dispatch => {
  dispatch(pushStackAction(DialogType.RT));
  rtDialogActions.openRTDialog(orderId)(dispatch);
};

const openRTReleaseConfirmationDialog = params => async dispatch => {
  const { orderId = NaN, storeId = NaN } = params;
  EventActions.onClickOrderFreeupRT({ orderId, storeId });
  closeCurrent()(dispatch);
  dispatch(pushStackAction(DialogType.RT_RELEASE_CONFIRMATION));
  RTReleaseConfirmationDialogActions.open(orderId)(dispatch);
};

const openRTReportReasonsDialog = params => async dispatch => {
  const { orderId = NaN, storeId = NaN } = params;
  EventActions.onClickOrderReportRT({ orderId, storeId });
  try {
    const getRTIncidentResponse = await OrderService.getRTIncident(orderId);

    if (!getRTIncidentResponse || !!get(getRTIncidentResponse, 'data', null)) {
      const message = formatMessage({
        id: 'openRTReportReasonsDialog.alreadyReportedMessage',
      });
      const title = formatMessage({
        id: 'openRTReportReasonsDialog.alreadyReportedTitle',
      });
      appActions.openSnackBarError(message, title)(dispatch);
      return {
        hasFailed: false,
      };
    }

    closeCurrent()(dispatch);
    dispatch(pushStackAction(DialogType.RT_REPORT_REASONS));
    rtReportReasonsDialogActions.openRTReportReasonsDialog(orderId)(dispatch);
    rtReportReasonsDialogActions.setRTReportReasonsDialogIsLoading()(dispatch);
    const getQualifyNegativeReasonsResponse = await OrderService.getQualifyNegativeReasons();
    const reasons = get(getQualifyNegativeReasonsResponse, 'data');

    rtReportReasonsDialogActions.setRTReportReasonsDialogReasons(reasons)(
      dispatch,
    );

    rtReportReasonsDialogActions.setRTReportReasonsDialogIsNotLoading()(
      dispatch,
    );
    return {
      hasFailed: false,
    };
  } catch (ex) {
    await appActions.handleCatch(ex)(dispatch);
    return {
      hasFailed: true,
    };
  }
};

const openStockOutIssueSuggestionsDialog = () => async dispatch => {
  try {
    const orderDetail = appStore.getOrderDetailInOrderDetailDialog();
    const deliveryMethod = get(
      orderDetail,
      'delivery_method',
      OrderDeliveryMethodType.DELIVERY,
    );
    const orderId = get(orderDetail, 'id', 0);
    const response = await supportIssueSuggestionsService.getStockOutIssueSuggestions(
      deliveryMethod,
    );

    const description = get(response, 'data.description', '');
    const supportSuggestions = get(response, 'data.next_steps', []);

    closeCurrent()(dispatch);

    dispatch(pushStackAction(DialogType.STOCK_OUT_ISSUE_SUGGESTION));

    stockOutIssueSuggestionsDialogActions.open(
      orderId,
      description,
      supportSuggestions,
    )(dispatch);
  } catch (ex) {
    await appActions.handleCatch(ex)(dispatch);
  }
};

const confirmOrderWhim = (price, name) => async dispatch => {
  try {
    const orderId = appStore.getOrderIdInOrderWhimDialog();
    await OrderService.addWhim(price, name, orderId);
    const message = formatMessage({
      id: 'orderDetailWhim.successSnackBar.message',
    });
    const title = formatMessage({
      id: 'orderDetailWhim.successSnackBar.title',
    });
    await appActions.openSnackBarSuccess(message, title)(dispatch);
    OrderActions.getOrderDetailAndStoreKeeperChannel(orderId)(dispatch);
    EventActions.onSuccessfulWhimCreation();
    back()(dispatch);
    orderWhimDialogActions.clearErrorText()(dispatch);
  } catch (ex) {
    let showSnackbar = true;
    const errorCode = get(ex, 'response.data.error.code', null);
    const errorMessage = get(ex, 'response.data.error.message', null);
    const inValidWhimPrice =
      errorCode === createWhimErrorTypes.INVALID_WHIM_PRICE;
    EventActions.onFailedWhimCreation();
    if (!inValidWhimPrice) {
      back()(dispatch);
    } else {
      showSnackbar = false;
      orderWhimDialogActions.setErrorText(errorCode, errorMessage)(dispatch);
    }
    await appActions.handleCatch(ex, showSnackbar)(dispatch);
  }
};

const confirmProxySession = (phone, isOM) => async dispatch => {
  let response;
  try {
    const orderDetail = appStore.getOrderDetailInCustomerPhoneProxySessionDialog();
    const orderId = get(orderDetail, 'id', null);
    const deliveryMethod = get(orderDetail, 'delivery_method', null);
    customerPhoneProxySessionDialogActions.setPhoneNumber(phone)(dispatch);
    response = await PhoneProxySessionService.createProxySession(
      orderId,
      phone,
      deliveryMethod,
    );
    const callFrom = get(response, 'data.call_from', null);
    const callTo = get(response, 'data.call_to', null);
    EventActions.onSuccessfulProxySessionCreation({ isOM });
    closeCurrent()(dispatch);
    openSuccessfulProxySessionDialog(callFrom, callTo)(dispatch);
    customerPhoneProxySessionDialogActions.clearErrorText()(dispatch);
  } catch (ex) {
    const errorCode = get(ex, 'response.data.error.code', null);
    const errorMessage = get(ex, 'response.data.error.message', null);
    const isInvalidPhone =
      errorCode === createProxySessionErrorTypes.INVALID_PHONE;
    if (!isInvalidPhone) {
      closeCurrent()(dispatch);
      openFailedProxySessionDialog()(dispatch);
      EventActions.onFailedProxySessionCreation();
    } else {
      EventActions.onProxySessionInvalidPhone();
      customerPhoneProxySessionDialogActions.setErrorText(
        errorCode,
        errorMessage,
      )(dispatch);
    }
    await appActions.handleCatch(ex, false)(dispatch);
  }
};

const openStoreDiagnosticDialog = storeId => async dispatch => {
  dispatch(closeCurrent());
  dispatch(pushStackAction(DialogType.STORE_DIAGNOSTICS_DIALOG));
  EventActions.onOpenStoreDiagnosticDialog(storeId);
  await dispatch(storeDiagnosticsDialogActions.open(storeId));
};

const openSuggestProductDialog = (
  productId,
  productName,
  selectedProduct,
  refuseReason,
  refuseReasonsDescription,
) => dispatch => {
  dispatch(closeCurrent());
  dispatch(pushStackAction(DialogType.SUGGEST_PRODUCT_DIALOG));
  const orderId = appStore.getOrderIdInOrderDetailDialog();
  dispatch(
    suggestProductDialogActions.open(
      orderId,
      productId,
      productName,
      selectedProduct,
      refuseReason,
      refuseReasonsDescription,
    ),
  );
};
const openErrorPMFlowDialog = (errorCode, errorMessage) => async dispatch => {
  dispatch(close());
  dispatch(pushStackAction(DialogType.ERROR_PM_FLOW_DIALOG));
  dispatch(errorPMFlowDialogActions.open());
  const schedulePMAForm = appStore.getScheduledPMAutomationForm();
  const errorMessageTosend = !errorMessage ? '' : errorMessage;
  const errorCodeTosend = !errorCode ? PWEXCodes.PWEX0 : errorCode;
  const formId = get(schedulePMAForm, 'form_id', null);
  if (!formId) {
    return;
  }
  await PMAutomationService.setStoreErrorPMForm(
    formId,
    errorCodeTosend,
    errorMessageTosend,
  );
};
const openStartPMFlowDialog = () => async dispatch => {
  try {
    dispatch(closeCurrent());
    dispatch(pushStackAction(DialogType.START_PM_FLOW_DIALOG));
    const schedulePMAForm = appStore.getScheduledPMAutomationForm();
    const partnerId = appStore.getPartnerId();
    const formId = get(schedulePMAForm, 'form_id', null);
    const storeId = get(schedulePMAForm, 'store_id', null);
    if (!formId || !storeId) {
      return;
    }
    const response = await PMAutomationService.setAsSeen(formId);
    const storeName = get(response, 'data.store_name', '');
    dispatch(pushStackAction(DialogType.START_PM_FLOW_DIALOG));
    dispatch(startPMFlowDialogActions.open(storeName, formId, storeId));
    EventActions.onOpenPMDialogIntro(partnerId, storeId);
  } catch (ex) {
    await appActions.handleCatch(ex, false)(dispatch);
  }
};
const openPhonePMFlowDialog = formId => async dispatch => {
  try {
    dispatch(closeCurrent());
    dispatch(pushStackAction(DialogType.PHONE_PM_FLOW_DIALOG));
    const response = await PMAutomationService.getPMAutomationStorePhone(
      formId,
    );
    const phone = get(response, 'data.phone', '');
    dispatch(phonePMFlowDialogActions.open(formId, phone));
  } catch (ex) {
    const errorCode = get(ex, 'response.data.error.code', null);
    const errorMessage = get(ex, 'response.data.error.message', null);
    await dispatch(openErrorPMFlowDialog(errorCode, errorMessage));
    await appActions.handleCatch(ex, false)(dispatch);
  }
};
const openSchedulePMFlowDialog = formId => async dispatch => {
  try {
    dispatch(closeCurrent());
    dispatch(pushStackAction(DialogType.SCHEDULE_PM_FLOW_DIALOG));
    dispatch(schedulePMFlowDialogActions.open(formId));
  } catch (ex) {
    const errorCode = get(ex, 'response.data.error.code', null);
    const errorMessage = get(ex, 'response.data.error.message', null);
    await dispatch(openErrorPMFlowDialog(errorCode, errorMessage));
    await appActions.handleCatch(ex, false)(dispatch);
  }
};
const openEndPMFlowDialog = formId => async dispatch => {
  try {
    dispatch(closeCurrent());
    dispatch(pushStackAction(DialogType.END_PM_FLOW_DIALOG));
    dispatch(endPMFlowDialogActions.open(formId));
  } catch (ex) {
    const errorCode = get(ex, 'response.data.error.code', null);
    const errorMessage = get(ex, 'response.data.error.message', null);
    await dispatch(openErrorPMFlowDialog(errorCode, errorMessage));
    await appActions.handleCatch(ex, false)(dispatch);
  }
};

const openProductSuggestionAvailabilityDialog = (
  productId,
  productName,
  selectedProduct,
  refuseReason,
  refuseReasonsDescription,
) => dispatch => {
  dispatch(closeCurrent());
  dispatch(pushStackAction(DialogType.PRODUCT_SUGGESTION_AVAILABILITY_DIALOG));
  const orderId = appStore.getOrderIdInOrderDetailDialog();
  dispatch(
    productSuggestionAvailabilityDialogActions.open(
      orderId,
      productId,
      productName,
      selectedProduct,
      refuseReason,
      refuseReasonsDescription,
    ),
  );
};
const openSuccessfulSuggestingProductDialog = (
  selectedProduct,
  suggestion,
) => dispatch => {
  dispatch(closeCurrent());
  dispatch(pushStackAction(DialogType.SUCCESSFUL_SUGGESTING_PRODUCT_DIALOG));
  const orderId = appStore.getOrderIdInOrderDetailDialog();
  dispatch(
    successfulSuggestingProductDialogActions.open(
      orderId,
      selectedProduct,
      suggestion,
    ),
  );
};
const openErrorSuggestingProductDialog = () => dispatch => {
  dispatch(closeCurrent());
  dispatch(pushStackAction(DialogType.ERROR_SUGGESTING_PRODUCT_DIALOG));
  const orderId = appStore.getOrderIdInOrderDetailDialog();
  dispatch(errorSuggestingProductDialogActions.open(orderId));
};
const openOnBoardingDialog = source => async dispatch => {
  try {
    dispatch(closeCurrent());
    const response = await onboardingService.getOnboardingList();
    const parsedResponse = utils.parseToStepArray(response.data);
    const partnerId = appStore.getPartnerId();
    dispatch(pushStackAction(DialogType.ONBOARDING_DIALOG));
    EventActions.onSuccessStartOnboarding(partnerId, source);
    await dispatch(onBoardingDialogActions.open(parsedResponse, source));
  } catch (ex) {
    await appActions.handleCatch(ex)(dispatch);
  }
};
const openMultiStoreDropDialog = () => dispatch => {
  dispatch(closeCurrent());
  dispatch(pushStackAction(DialogType.MULTI_STORE_DROP_DIALOG));
  dispatch(multiStoreDropDialogActions.open());
};
const openSingleStoreDropDialog = () => async dispatch => {
  dispatch(closeCurrent());
  dispatch(pushStackAction(DialogType.SINGLE_STORE_DROP_DIALOG));
  await dispatch(singleStoreDropDialogActions.open());
};

const openExtendClosingHoursDialog = storeId => dispatch => {
  dispatch(closeCurrent());
  dispatch(pushStackAction(DialogType.EXTEND_CLOSING_HOURS));
  dispatch(extendClosingHoursDialogActions.open(storeId));
};

const closeExtendClosingHoursDialog = () => dispatch => {
  close()(dispatch);
};

const openDeactivatedProductsDialog = partnerId => dispatch => {
  dispatch(closeCurrent());
  dispatch(pushStackAction(DialogType.DEACTIVATED_PRODUCTS));
  dispatch(deactivatedProductsDialogActions.open(partnerId));
};

const closeDeactivatedProductsDialog = () => dispatch => {
  close()(dispatch);
};

const openSubstitutionFlowDialog = (orderId, reason) => dispatch => {
  closeCurrent()(dispatch);
  dispatch(pushStackAction(DialogType.ORDER_REJECTION_BY_STOCK_OUT));
  orderRejectionByStockOutDialogActions.openOrderRejectionByStockOutDialog(
    orderId,
    reason,
  )(dispatch);
};

const openOrderManagementDialog = ({ isOpenBy, editType }) => dispatch => {
  dispatch(pushStackAction(DialogType.ORDER_MANAGEMENT));
  dispatch(orderMgmtDialogActions.open({ isOpenBy, editType }));
};

const closeOrderManagementDialog = () => dispatch => {
  dispatch(orderMgmtDialogActions.close());
};

const openOrderManagementItemSearchDialog = () => dispatch => {
  dispatch(closeCurrent());
  dispatch(pushStackAction(DialogType.ORDER_MANAGEMENT_ITEM_SEARCH));
  dispatch(orderMgmtItemSearchDialogActions.open());
};

const closeOrderManagementItemSearchDialog = () => dispatch => {
  dispatch(orderMgmtItemSearchDialogActions.close());
};

const openUndoChangesDialog = () => dispatch => {
  dispatch(pushStackAction(DialogType.ORDER_MANAGEMENT_UNDO_CHANGES_DIALOG));
  dispatch(orderMgmtDialogActions.openUndoChangesDialog());
};

const closeUndoChangesDialog = () => dispatch => {
  dispatch(orderMgmtDialogActions.closeUndoChangesDialog());
};

const openUndoSuggestionDialog = (productId, toppingId) => dispatch => {
  dispatch(pushStackAction(DialogType.ORDER_MANAGEMENT_UNDO_SUGGESTION_DIALOG));
  dispatch(
    orderMgmtDialogActions.openUndoSuggestionDialog({ productId, toppingId }),
  );
};

const closeUndoSuggestionDialog = () => dispatch => {
  dispatch(orderMgmtDialogActions.closeUndoSuggestionDialog());
};

const openReplaceProductDialog = productId => dispatch => {
  dispatch(pushStackAction(DialogType.ORDER_MANAGEMENT_REPLACE_PRODUCT));
  dispatch(orderMgmtDialogActions.openReplaceProductDialog({ productId }));
};

const openOrderMgmtAcceptOrderDialog = () => dispatch => {
  dispatch(pushStackAction(DialogType.ORDER_MANAGEMENT_ACCEPT_ORDER));
  dispatch(orderMgmtDialogActions.openOrderMgmtAcceptOrderDialog());
};

const openAcceptRemoveChangesDialog = () => dispatch => {
  dispatch(
    pushStackAction(DialogType.ORDER_MANAGEMENT_ACCEPT_ORDER_REMOVE_CHANGES),
  );
  dispatch(orderMgmtDialogActions.openAcceptRemoveChangesDialog());
};

export default {
  back,
  close,
  closeCurrent,
  closeFailedProxySessionDialog,
  closeIfOrderDoesNotExist,
  closeSuccessfulProxySessionDialog,
  confirmOrderWhim,
  confirmProxySession,
  openCancelByStockOutSuggestionConfirmationDialog,
  openCookedOrderConfirmationIssueDialog,
  openCustomerPhoneProxySessionDialog,
  openFailedProxySessionDialog,
  openItemsDeactivationStockOutIssueDialog,
  openJustCookedOrderIssueDialog,
  openLogOutDialog,
  openOrderDetailDialog,
  openOrderRejectionByMotoboyLackDialog,
  openOrderRejectionBySaturatedStoreDialog,
  openOrderRejectionByStockOutDialog,
  openOrderRejectionDialog,
  openOrderWhimDialog,
  openRtAssignedIssueDialog,
  openRTDialog,
  openRTLackIssueSuggestionsDialog,
  openRTReleaseConfirmationDialog,
  openRTReportReasonsDialog,
  openStockOutIssueSuggestionsDialog,
  openStoreDiagnosticDialog,
  openSuccessfulProxySessionDialog,
  openSupportDialog,
  openSupportReasonsDialog,
  popStackAction,
  pushStackAction,
  openOnBoardingDialog,
  openSuggestProductDialog,
  openProductSuggestionAvailabilityDialog,
  openSuccessfulSuggestingProductDialog,
  openErrorSuggestingProductDialog,
  openLogOutDialogReasons,
  openStartPMFlowDialog,
  openErrorPMFlowDialog,
  openEndPMFlowDialog,
  openPhonePMFlowDialog,
  openSchedulePMFlowDialog,
  openMultiStoreDropDialog,
  openSingleStoreDropDialog,
  openExtendClosingHoursDialog,
  closeExtendClosingHoursDialog,
  openDeactivatedProductsDialog,
  closeDeactivatedProductsDialog,
  openSubstitutionFlowDialog,
  openOrderManagementDialog,
  closeOrderManagementDialog,
  openOrderManagementItemSearchDialog,
  closeOrderManagementItemSearchDialog,
  openUndoSuggestionDialog,
  closeUndoSuggestionDialog,
  openUndoChangesDialog,
  closeUndoChangesDialog,
  openReplaceProductDialog,
  openOrderMgmtAcceptOrderDialog,
  openAcceptRemoveChangesDialog,
};
