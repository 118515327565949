import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectors as helpCenterSelectors } from '../../../../redux/help-center-page';

export function useArticleDetail() {
  const navigate = useNavigate();
  const {
    selectSelectedArticle,
    selectedSubCategoryName,
  } = helpCenterSelectors;

  const article = useSelector(selectSelectedArticle);
  const subcategoryName = useSelector(selectedSubCategoryName);

  const handleGoBack = () => navigate(-1);

  return {
    subcategoryName,
    articleName: article?.name ?? '',
    articleBody: article?.body ?? '',
    handleGoBack,
  };
}
