import { createAction } from '@reduxjs/toolkit'

const closeAction = createAction('extendClosingHoursDialog/close')

const clearAction = createAction('extendClosingHoursDialog/clear')

const openAction = createAction<{ storeId: number }>(
  'extendClosingHoursDialog/open',
)

const clear = () => (dispatch: any) => {
  dispatch(clearAction())
}

const close = () => (dispatch: any) => {
  dispatch(closeAction())
}

const open = (storeId: number) => (dispatch: any) => {
  dispatch(openAction({ storeId }))
}

const actions = {
  clear,
  clearAction,
  close,
  closeAction,
  open,
  openAction,
}

export default actions
