// Primary libraries (third party library)
import React from 'react';
// Constants and styles
import { CtaUiModes } from '../../../store-status/constants';
import useStyles from './StoreManagerStatusList.styles';
// Our components
import { StoreStatusCard } from '../../../store-status/store-status-card';
// Types
import { IStoreManagerStatusList } from '../../interfaces';

export function StoreManagerStatusList(props: IStoreManagerStatusList) {
  const { partnerId, filteredStores, isSingleStore } = props;

  const componentClasses = useStyles();

  return (
    <div className={componentClasses.storeStatusContainer} role='grid'>
      {filteredStores.map((store, idx) => (
        <div
          className={componentClasses.storeStatusContent}
          key={store.store_id + idx}
        >
          <StoreStatusCard
            partnerId={partnerId}
            storeId={store.store_id}
            status={store.status}
            storeName={store.store_name}
            cta={store.cta_type}
            msg1={store.first_message}
            msg2={store.second_message}
            ctaUiMode={CtaUiModes.Right}
            isSingleStore={isSingleStore}
          />
        </div>
      ))}
    </div>
  );
}
