// Redux
import { createAction } from '@reduxjs/toolkit';

const closeAction = createAction(
  'cancelByStockOutSuggestionConfirmationDialog/close',
);

const clearAction = createAction(
  'cancelByStockOutSuggestionConfirmationDialog/clear',
);

const openAction = createAction<{ orderId: number }>(
  'cancelByStockOutSuggestionConfirmationDialog/open',
);

const clear = () => (dispatch: any) => {
  dispatch(clearAction());
};

const close = () => (dispatch: any) => {
  dispatch(closeAction());
};

const open = (orderId: number) => (dispatch: any) => {
  dispatch(openAction({ orderId }));
};

const actions = {
  clear,
  clearAction,
  close,
  closeAction,
  open,
  openAction,
};

export default actions;
