// Material UI
import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  mobileTitle: {
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 'bold',
    paddingBottom: 17,
  },
  headerRoot: {
    textAlign: 'right',
    gridTemplateColumns: 'auto auto min-content',
    display: 'none',
    borderBottom: 'none',
    [theme.breakpoints.up('md')]: {
      padding: '12px 0px 0px 0px',
      display: 'block',
    },
  },
  paper: {
    borderRadius: 8,
    [theme.breakpoints.up('md')]: {
      height: 682,
      width: 791,
    },
  },
  root: {},
  container: {
    display: 'grid',
    gridRowGap: 20,
    gridTemplateRows: '1fr',
    gridTemplateAreas: `"banner banner"
                       "description description"
                       "footer footer"`,
    [theme.breakpoints.up('md')]: {
      gridRowGap: 6,
      gridTemplateAreas: `"banner banner"
                       "supportIcon description"
                       "footer footer"`,
    },
  },
  banner: {
    gridArea: 'banner',
    justifySelf: 'center',
    [theme.breakpoints.up('md')]: {
      padding: '0px 28px 0px 28px',
    },
  },
  img: {
    imageRendering: '-webkit-optimize-contrast',
    width: 360,
    height: 390,
    [theme.breakpoints.up('sm')]: {
      height: 543,
      width: 600,
    },
    [theme.breakpoints.up('md')]: {
      height: 440,
      width: 735,
      borderRadius: 10,
    },
    [theme.breakpoints.up('lg')]: {
      height: 440,
      width: 735,
      borderRadius: 10,
    },
  },
  supportIcon: {
    gridArea: 'supportIcon',
    paddingLeft: 28,
  },
  imgSupport: {
    width: 93,
    height: 93,
  },
  description: {
    gridArea: 'description',
    alignSelf: 'center',
    paddingLeft: 16,
    paddingRight: 28,
    fontSize: 14,
    overflowWrap: 'break-word',
    height: 78,
    maxHeight: 78,
    overflowY: 'auto',
    [theme.breakpoints.up('md')]: {
      maxHeight: 78,
      height: 78,
      overflowY: 'auto',
    },
  },
  markDownDescription: {
    padding: 0,
    whiteSpace: 'pre-wrap',
  },
}))
