// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => ({
  actionsContainer: {
    alignItems: 'center',
    borderTop: `1px solid ${neutral.D10}`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 16px',
  },
  clearFilterButton: {
    color: neutral.D15,
    fontSize: '13px !important',
    lineHeight: 'normal',
  },
  drawerBody: {
    overflow: 'inherit',
    padding: '24px',
  },
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    overflow: 'inherit',
  },
}));
