// Material UI
import { makeStyles } from '@material-ui/core';
// constant
import Colors from '../../constants/Colors';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
  },
  containerBox: {
    backgroundColor: neutral.white,
    padding: '40px 16px 0px 16px',
    marginTop: '24px',
    marginBottom: '24px',
    [theme.breakpoints.up('md')]: {
      backgroundColor: neutral.white,
      margin: '24px',
      padding: '50px 16px 50px 16px',
    },
  },
  titles: {
    fontSize: '17px',
    textAlign: 'center',
    letterSpacing: '0',
    color: neutral.D20,
    lineHeight: 'normal',
  },
  Subtitles: {
    fontSize: '30px',
    textAlign: 'center',
    letterSpacing: '0',
    color: neutral.D20,
    fontFamily: 'Poppins Bold',
    lineHeight: 'normal',
    borderBottom: `1px solid ${neutral.D10}`,
    paddingBottom: '20px',
    marginTop: '20px',
  },
  SubContent: {
    fontSize: '15px',
    textAlign: 'center',
    letterSpacing: '0',
    color: neutral.D20,
    lineHeight: 'normal',
    padding: '20px 16px 0px 16px',
    fontFamily: 'Poppins',
  },
  buttonContainer: {
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  downLoadApp: {
    backgroundColor: Colors.SECONDARY_COLOR,
    color: Colors.WHITE,
    width: '100%',
    borderRadius: 8,
    [theme.breakpoints.up('md')]: {
      width: '200px',
      height: '60px',
    },
  },
  goPortal: {
    marginTop: '20px',
    marginBottom: '40px',
    backgroundColor: Colors.AFFAIR,
    color: Colors.WHITE,
    width: '100%',
    borderRadius: 8,
    '&:hover': {
      backgroundColor: Colors.JACARTA,
    },
    [theme.breakpoints.up('md')]: {
      width: '200px',
      height: '60px',
      margin: '0px 0px 0px 40px',
    },
  },
}));
