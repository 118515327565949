// React
import React, { useState } from 'react'
import PropTypes from 'prop-types'
// Material UI
import { List, ListItem } from '@material-ui/core'
//Components
import CustomLoader from '../common/custom-loader'
import ProductBox from '../product-box'
// Styles
import useStyles from './MenuProductsList.styles'

const MenuProductsList = ({
  isLoading,
  onSelectProduct,
  products,
  selectedProductId,
}) => {
  const [deactivatedItemId, setDeactivatedItemId] = useState(null)
  const classes = useStyles()
  const isCustomItemsDeactivationDrawerClose = deactivatedItemId === null

  return (
    <List component='div' disablePadding className={classes.productsListRoot}>
      <CustomLoader open={isLoading} />
      {products &&
        products.map((product, index) => {
          return (
            <ListItem
              key={'product-' + index}
              onClick={() =>
                isCustomItemsDeactivationDrawerClose &&
                onSelectProduct(product['product_id'])
              }
              selected={selectedProductId === product['product_id']}
              disableGutters
              classes={{
                root: classes.productsListItemRoot,
                selected: classes.selected,
              }}
            >
              <ProductBox
                deactivatedItemId={deactivatedItemId}
                setDeactivatedItemId={setDeactivatedItemId}
                product={product}
              />
            </ListItem>
          )
        })}
    </List>
  )
}

MenuProductsList.propTypes = {
  products: PropTypes.array,
  selectedProductId: PropTypes.number,
  onSelectProduct: PropTypes.func,
  productDeactivationOptions: PropTypes.array,
}

export default MenuProductsList
