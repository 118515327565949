// React
import React, { useEffect, useState } from 'react';
// Components
import CustomItemsFilterDrawer from './../common/custom-items-filter-drawer';

const StoresSchedulesFilterDrawer = ({
  applyFilter,
  classes,
  filter,
  onClearFilter,
  onClose,
  open,
  stores,
}) => {
  const [selectedStoreId, setSelectedStoreId] = useState(
    filter.selectedStoreId,
  );

  useEffect(() => {
    setSelectedStoreId(filter.selectedStoreId);
  }, [filter.selectedStoreId]);

  const handleApplyFilter = () => {
    if (!selectedStoreId) {
      return;
    }
    applyFilter(selectedStoreId);
  };

  const handleItemsSelectionChange = selectedItems => {
    setSelectedStoreId(selectedItems[0] || 0);
  };

  return (
    <CustomItemsFilterDrawer
      items={stores}
      onApplyFilter={handleApplyFilter}
      onClearFilter={onClearFilter}
      onClose={() => {
        setSelectedStoreId(filter.selectedStoreId);
        onClose();
      }}
      onItemsSelectionChange={handleItemsSelectionChange}
      open={filter.open}
      selectedItems={[selectedStoreId]}
      unique
    ></CustomItemsFilterDrawer>
  );
};

StoresSchedulesFilterDrawer.defaultProps = {
  classes: {},
};

export default StoresSchedulesFilterDrawer;
