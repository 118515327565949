import OrderMgmtCardStyles from './card.styles';
import Typography, {
  FontWeight,
  Variants,
} from '../../../../common/typography';
import { useOrderMgmtLang } from '../../../hooks';
import { OrderMgmtProduct } from '../../../../../business/order-mgmt';
import { useProductCard } from './hooks';
import Box from '@material-ui/core/Box';
import {
  CorridorName,
  Footer,
  Disclaimer,
  Suggestions,
  ToppingCard,
} from './components';

export const ProductCard = ({ product, orderId }: Props) => {
  const {
    id,
    units,
    name,
    description,
    toppings,
    subtotal,
    suggestions,
    corridorName,
  } = product;
  const { subtotalOrderText, soldOutProductText } = useOrderMgmtLang({
    orderId,
  });
  const {
    hasProductItemSuggestions,
    deleteProduct,
    deleteTopping,
    isEnabledToDelete,
  } = useProductCard({ product });

  const componentClasses = OrderMgmtCardStyles();

  return (
    <Box display='flex' flexDirection='column' gridGap='1rem'>
      <CorridorName
        className={componentClasses.corridorName}
        name={corridorName}
      />
      <Box
        className={componentClasses.cardContainer}
        display='flex'
        width='100%'
        flexDirection='column'
      >
        <Box display='flex' alignItems='center' position='relative'>
          <Typography
            classes={{ root: componentClasses.quantity }}
            fontWeight={FontWeight.SemiBold}
          >
            {`${units}x`}
          </Typography>
          <Box
            className={componentClasses.headerDivider}
            height='100%'
            left={54}
            position='absolute'
          />
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            minHeight='3rem'
            padding='0 1rem'
            width='100%'
          >
            <Typography>{name}</Typography>
            {hasProductItemSuggestions && (
              <Disclaimer
                enabledToDelete={product.hasSuggestions && isEnabledToDelete}
                soldoutText={soldOutProductText}
                classDelete={componentClasses.deleteItem}
                item={product}
                onDelete={deleteProduct}
              />
            )}
          </Box>
        </Box>
        <Box className={componentClasses.cardBody} padding='0 1rem'>
          <Typography
            classes={{ root: componentClasses.description }}
            variant={Variants.Caption2}
          >
            {description}
          </Typography>
          {hasProductItemSuggestions && (
            <Suggestions
              productName={name}
              suggestions={suggestions}
              units={units}
              enabledDisclaimer
            />
          )}
          {toppings && (
            <Box
              display='flex'
              flexDirection='column'
              gridGap='0.5rem'
              marginBottom='1.25rem'
              marginTop='0.5rem'
            >
              {toppings.map(topping => (
                <ToppingCard
                  topping={topping}
                  key={`t-${topping?.id}`}
                  onDelete={deleteTopping}
                />
              ))}
            </Box>
          )}
        </Box>
        <Footer
          classWrapper={componentClasses.cardFooter}
          footerText={subtotalOrderText}
          classBody={componentClasses.subtotal}
          subtotal={subtotal}
        />
      </Box>
    </Box>
  );
};

interface Props {
  product: OrderMgmtProduct;
  orderId: number;
  hasDelete?: boolean;
}
