import { OrderMgmtDisclaimer } from '../../../../../business/order-mgmt';
import { useFormatMessage } from '../../../../../intl/intl';

export function useOrderDetailDisclaimerLang(changed?: OrderMgmtDisclaimer) {
  const messageCategory = 'orderManagement';

  const itemName = changed?.isTopping
    ? useFormatMessage({
        id: `${messageCategory}.topping`,
      })
    : useFormatMessage({
        id: `${messageCategory}.product`,
      });

  const acceptButtonText = useFormatMessage({
    id: `${messageCategory}.acceptButtonText`,
  });
  const rejectButtonText = useFormatMessage({
    id: `${messageCategory}.rejectButtonText`,
  });
  const disclaimerDetailText = useFormatMessage({
    id: `${messageCategory}.expiredTimeText`,
  });
  const acceptedUserSuggestionChangesText = useFormatMessage(
    { id: `${messageCategory}.acceptedUserSuggestionChangesText` },
    {
      name: changed?.name,
      replacementName: changed?.replacementName,
    },
  );
  const thisProductWasTurnedOffAutomaticallyText = useFormatMessage(
    { id: `${messageCategory}.thisProductWasTurnedOffAutomaticallyText` },
    { type: itemName, name: changed?.name },
  );
  const thisProductIsNotAvailableTodayText = useFormatMessage(
    {
      id: `${messageCategory}.thisProductIsNotAvailableTodayText`,
    },
    { type: itemName },
  );
  const userIsCheckingSuggestionChangesText = useFormatMessage({
    id: `${messageCategory}.userIsCheckingSuggestionChangesText`,
  });
  const disclaimerAcceptedToppingText = useFormatMessage({
    id: `${messageCategory}.disclaimerAcceptedTopping`,
  });

  return {
    acceptButtonText,
    rejectButtonText,
    disclaimerDetailText,
    acceptedUserSuggestionChangesText,
    thisProductWasTurnedOffAutomaticallyText,
    thisProductIsNotAvailableTodayText,
    userIsCheckingSuggestionChangesText,
    disclaimerAcceptedToppingText,
  };
}
