import { useFormatMessage } from '../../../../../intl/intl';

export function useOrderActionsLang(hasChanges: boolean) {
  const _acceptBtnText = useFormatMessage({
    id: 'orderDetailDialogActionsBox.acceptButtonText',
  });
  const undoChangesText = useFormatMessage({
    id: 'undoChanges',
  });
  const _withChanges = useFormatMessage({
    id: 'withChanges',
  });
  const acceptBtnText = `${_acceptBtnText} ${hasChanges ? _withChanges : ''}`;

  return {
    acceptBtnText,
    undoChangesText,
  };
}
