import { useSelector } from 'react-redux';

import { selectors as phonePMFlowDialogSelectors } from '../../redux/phone-PM-flow-dialog';

const useOpen = () => useSelector(phonePMFlowDialogSelectors.selectOpen);

const useFormId = () => useSelector(phonePMFlowDialogSelectors.selectFormId);

const usePhone = () => useSelector(phonePMFlowDialogSelectors.selectPhone);

const useErrorMessage = () =>
  useSelector(phonePMFlowDialogSelectors.selectPhoneErrorMessage);

const hooks = {
  useOpen,
  useFormId,
  usePhone,
  useErrorMessage,
};

export default hooks;
