import { clsx } from 'clsx';
import { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import {
  Button,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

// Constants
import Paths from '../../constants/Paths';
import { SupportWebChatConfig } from '../../constants/WebComponentsMf';
import { MILLISECONDS_PER_DAY } from './consts/consts';
// Tools
import { useFormatMessage } from '../../intl/intl';
// Hooks
import Hooks from './hooks';
// Actions
import NotificationsDrawerActions from '../../redux/actions/notifications-drawer-actions';
import EventActions from '../../redux/actions/event-actions/event.actions';
import appStore from '../../redux/stores/app.store';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';
// Services
import LocalStorageService from '../../services/local-storage-service';
// Components
import { CancelByStockOutSuggestionConfirmationDialog } from '../cancel-by-stock-out-suggestion-confirmation-dialog';
import CheckNetworkDialog from './../check-network-dialog';
import { CheckErrorServiceDialog } from '../check-error-service-dialog';
import ClosedPartnerStateDialog from '../closed-partner-state-dialog';
import { CookedOrderConfirmationIssueDialog } from '../cooked-order-confirmation-issue-dialog';
import { CustomBellButton } from '../common/buttons/custom-bell-button';
import CustomButton from './../common/buttons/custom-button';
import CustomerPhoneProxySessionDialog from '../customer-phone-proxy-session-dialog';
import CustomNotificationDrawer from '../custom-notification-drawer';
import DeactivatePartnerDialog from './../deactivate-partner-dialog';
import FailedProxySessionDialog from '../failed-proxy-session-dialog';
import { ItemsDeactivationStockOutIssueDialog } from '../items-deactivation-stock-out-issue-dialog';
import { JustCookedOrderIssueDialog } from '../just-cooked-order-issue-dialog';
import { LogOutDialog } from '../log-out-dialog';
import NoDeactivatedToppingDrawer from '../no-deactivated-topping-drawer';
import OpenPartnerStateDialog from '../open-partner-state-dialog';
import OrderDetailDialog from '../order-detail-dialog';
import { OrderRejectionByMotoboyLackDialog } from '../order-rejection-by-motoboy-lack-dialog';
import { OrderRejectionBySaturatedStoreDialog } from '../order-rejection-by-saturated-store-dialog';
import { OrderRejectionByStockOutDialog } from '../order-rejection-by-stock-out-dialog';
import OrderRejectionReasonsDialog from '../order-rejection-reasons-dialog';
import OrdersListFilterDrawer from '../orders-list-filter-drawer';
import OrderWhimDialog from '../order-whim-dialog';
import PartnerActivationReminderDialog from './../partner-activation-reminder-dialog';
import PickupCodeDialog from './../pickup-code-dialog';
import PrinterManagment from '../printer-managment';
import { RTAssignedIssueSuggestionDialog } from '../rt-assigned-issue-suggestions-dialog';
import { RTDialog } from '../rt-dialog';
import { RTLackIssueSuggestionsDialog } from '../rt-lack-issue-suggestions-dialog';
import { RTReleaseConfirmationDialog } from '../rt-release-confirmation-dialog';
import RTReportReasonsDialog from '../rt-report-reasons-dialog';
import SideMenu from '../side-menu/';
import { StockOutIssueSuggestionsDialog } from '../stock-out-issue-suggestions-dialog';
import { StoreDiagnosticsDialog } from '../store-diagnostics-dialog';
import SuccessfulProxySessionDialog from '../successful-proxy-session-dialog';
import SupportChatDrawer from '../support-chat-drawer';
import { SupportDialog } from '../support-dialog';
import { SupportReasonsDialog } from '../support-reasons-dialog';
import { ToppingsMassiveDeactivationDrawer } from '../toppings-massive-deactivation-drawer';
import { DynAlertSnackbar } from '../dyn-alert-snackbar';
import { SuggestProductDialog } from '../suggest-product-dialog';
import SupportWebChat from '../web-components-mf/support-web-chat';
import { ProductSuggestionAvailabilityDialog } from '../product-suggestion-availability-dialog';
import { SuccessfulSuggestingProductDialog } from '../successful-suggesting-product-dialog';
import { ErrorSuggestingProductDialog } from '../error-suggesting-product-dialog';
import { DynInAppDialog } from '../dyn-in-app-dialog';
import { LogOutReasonsDialog } from '../log-out-reasons-dialog';
import { StartPMFlowDialog } from '../start-pm-flow-dialog';
import { ErrorPMFlowDialog } from '../error-pm-flow-dialog';
import { EndPMFlowDialog } from '../end-pm-flow-dialog';
import { PhonePMFlowDialog } from '../phone-pm-flow-dialog';
import { SchedulePMFlowDialog } from '../schedule-pm-flow-dialog';
import { DeactivatedProductsNotificationsDialog } from '../deactivated-products-notifications-dialog';
import { ExtendClosingHoursDialog } from '../extend-closing-hours-dialog/ExtendClosingHoursDialog';
import { OrderManagement } from '../order-management/OrderManagement';
import { CheckInCodeRTGlobal } from '../../features/check-in-code-rt';
import { StoreSuspensionAlert } from '../store-suspension-alert';
import { HighDemand } from '../../features/high-demand';
import { ToggleAllStoreStatus } from '../store-status/toggle-all-store-status';
import { StartupNotifications } from '../../features/startup-notifications';
import { Cargo } from '../../features/cargo';
import rappiLogo from '../../assets/images/logos/logo_rappi.svg';
import menuIcon from '../../assets/images/icons/menu_icon.svg';

import useStyles from './MainLayout.styles';

const MainLayout = ({
  classes,
  children,
  getOrders,
  getState,
  language,
  notificationsUnseen,
  openMenuFilterDrawer,
  openOrdersHistoricalFilterDrawer,
  openPickupCodeDialog,
  openSalesSummaryFilterDrawer,
  openStoresSchedulesFilterDrawer,
  orderId,
  pollOrderDetailAndStoreKeeperChannel,
  showPickupButton,
  showPartnerStateSwitch,
  title,
}) => {
  const profile = Hooks.useProfile();
  const isChatV2Available = Hooks.useIsChatV2Available();
  const accessToken = LocalStorageService.getAccessToken();
  const baseUrl = LocalStorageService.getBaseUrl();
  const navigate = useNavigate();

  const componentClasses = useStyles();
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  const isPrinting = useMediaQuery('print');

  const { pathname } = useLocation();

  const deliverToUserText = useFormatMessage({
    id: 'mainLayout.deliverToUserText',
  });
  const footerClientLang = useFormatMessage({ id: 'client' });
  const footerSupportLang = useFormatMessage({ id: 'globals.support' });

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  // It must be shown only for views different to orders management.
  const showFiltersButton =
    isMdUp &&
    (pathname.indexOf(Paths.MENU) >= 0 ||
      pathname.indexOf(`${Paths.ORDERS_HISTORY}`) >= 0 ||
      pathname.indexOf(`${Paths.STORES_SCHEDULES}`) >= 0 ||
      pathname.indexOf(`${Paths.SALES_SUMMARY}`) >= 0);

  const inMyAccountPage = pathname.startsWith(Paths.MY_ACCOUNT);
  const isStoreManager = pathname.startsWith(Paths.STORE_MANAGEMENT);
  const partnerId = appStore.getPartnerId();

  useEffect(() => {
    const getOrdersInterval = setInterval(() => {
      getOrders();
    }, 30_000);

    const getOrderDetailInterval = setInterval(() => {
      pollOrderDetailAndStoreKeeperChannel(+orderId);
    }, 60_000);

    const getStateInterval = setInterval(() => {
      getState();
    }, 60_000);

    function checkLastShown() {
      const lastShown = new Date(
        LocalStorageService.getDeactivatedProductsDialogLastShown(),
      );
      const now = new Date();
      if (now - lastShown > MILLISECONDS_PER_DAY) {
        dispatch(
          dialogsManagerActions.openDeactivatedProductsDialog(partnerId),
        );
        now.setHours('05', '00', '00'); //Producto needs the feature to check every 05:00 am each day.
        LocalStorageService.setDeactivatedProductsDialogLastShown(
          now.toISOString(),
        );
      }
    }
    checkLastShown();
    const getLastShownInterval = setInterval(() => {
      checkLastShown();
    }, 60_000); // Checks each minute if 24 hours have passed since 05:00 from the day before.

    return () => {
      clearInterval(getOrdersInterval);
      clearInterval(getOrderDetailInterval);
      clearInterval(getStateInterval);
      clearInterval(getLastShownInterval);
    };
  }, [orderId]);

  const supportWebChatConfig = useMemo(() => {
    if (isChatV2Available) {
      return {
        portal: SupportWebChatConfig.PortalName,
        portalToken: `Bearer ${accessToken}`,
        languaje: language,
        receiverId: profile.id,
        hostByCountry: baseUrl,
        additionalParams: {
          stores: profile.stores,
          portalInfo: {
            name: `${profile.first_name} ${profile.last_name}`,
            email: profile.email,
          },
        },
      };
    }
  }, [isChatV2Available]);

  const closeSideMenu = () => {
    setOpen(false);
  };

  const onClickShowFilters = () => {
    if (pathname.indexOf(Paths.ORDERS_HISTORY) !== -1) {
      openOrdersHistoricalFilterDrawer();
    } else if (pathname.indexOf(Paths.SALES_SUMMARY) !== -1) {
      openSalesSummaryFilterDrawer();
    } else if (pathname.indexOf(Paths.MENU) !== -1) {
      openMenuFilterDrawer();
    } else if (pathname.indexOf(Paths.STORES_SCHEDULES) !== -1) {
      openStoresSchedulesFilterDrawer();
    }
  };

  const toggleSideMenu = () => {
    setOpen(!open);
  };

  return (
    <div>
      <PrinterManagment />
      {!isPrinting && (
        <div className={componentClasses.root}>
          <SideMenu open={open} onClose={closeSideMenu} />
          <main className={componentClasses.content}>
            <StartupNotifications />
            <Toolbar
              disableGutters={true}
              classes={{
                root: componentClasses.mobileToolbarRoot,
              }}
            >
              <div className={componentClasses.toolbarTitleContainer}>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  onClick={toggleSideMenu}
                  className={componentClasses.menuButton}
                >
                  <img src={menuIcon} alt='menu' />
                </IconButton>
                <div
                  className={componentClasses.sectionLogo}
                  onClick={() => navigate(Paths.ORDERS_KANBAN)}
                >
                  <img src={rappiLogo} alt='Rappi logo' />
                </div>
              </div>
              <div className={componentClasses.chipsContainer}>
                {!isStoreManager && (
                  <>
                    {isMdUp && showPickupButton && (
                      <CustomButton
                        classes={{
                          submitButton: componentClasses.deliverToUserButton,
                        }}
                        color='primary'
                        disabled={false}
                        fullWidth={false}
                        onClick={openPickupCodeDialog}
                        text={deliverToUserText}
                        variant='contained'
                      />
                    )}
                    {showFiltersButton && (
                      <CustomButton
                        classes={{
                          submitButton: componentClasses.showFiltersButton,
                        }}
                        color='primary'
                        fullWidth={false}
                        onClick={onClickShowFilters}
                        text={'Ver filtros'}
                        variant='outlined'
                      />
                    )}
                    {!inMyAccountPage && <CheckInCodeRTGlobal />}
                    <HighDemand />
                  </>
                )}
                {(!isStoreManager || isMdUp) && (
                  <CustomBellButton
                    onClick={() => {
                      EventActions.onOpenNotificationCenter(
                        appStore.getPartnerId(),
                      );
                      NotificationsDrawerActions.open()(dispatch);
                    }}
                    classes={{
                      button: componentClasses.notificationsIconButton,
                    }}
                    badgeContent={notificationsUnseen}
                  />
                )}
              </div>
              {showPartnerStateSwitch && isStoreManager && !isMdUp && (
                <ToggleAllStoreStatus />
              )}
            </Toolbar>
            <Toolbar
              disableGutters={true}
              classes={{ root: componentClasses.toolbarLvl2Root }}
            >
              {showPickupButton && !isMdUp && (
                <Button
                  variant='contained'
                  onClick={openPickupCodeDialog}
                  classes={{ root: componentClasses.deliverToUserButton }}
                >
                  {deliverToUserText}
                </Button>
              )}
            </Toolbar>

            <div
              className={clsx(
                componentClasses.sectionContent,
                classes?.sectionContent,
              )}
            >
              {children}
            </div>

            <footer className={componentClasses.footer}>
              <div className={componentClasses.footerOurFns}>
                <Cargo />
              </div>
              {isChatV2Available && supportWebChatConfig && (
                <div className={componentClasses.footerSWC}>
                  {/* TODO: replace hard texts with your translations */}
                  <Typography component='label'>CHAT</Typography>
                  <Button startIcon={<PersonOutlineIcon />}>
                    {footerClientLang}
                  </Button>
                  <Button startIcon={<HeadsetMicIcon />}>
                    {footerSupportLang}
                  </Button>
                  <SupportWebChat
                    config={supportWebChatConfig}
                  ></SupportWebChat>
                </div>
              )}
            </footer>
          </main>

          <CancelByStockOutSuggestionConfirmationDialog />
          <CheckNetworkDialog />
          <CheckErrorServiceDialog />
          <ClosedPartnerStateDialog />
          <CookedOrderConfirmationIssueDialog />
          <CustomerPhoneProxySessionDialog />
          <CustomNotificationDrawer />
          <DeactivatePartnerDialog />
          <DynAlertSnackbar />
          <ErrorSuggestingProductDialog />
          <FailedProxySessionDialog />
          <ItemsDeactivationStockOutIssueDialog />
          <JustCookedOrderIssueDialog />
          <LogOutDialog />
          <LogOutReasonsDialog />
          <NoDeactivatedToppingDrawer />
          <OpenPartnerStateDialog />
          <OrderDetailDialog />
          <OrderRejectionByMotoboyLackDialog />
          <OrderRejectionBySaturatedStoreDialog />
          <OrderRejectionByStockOutDialog />
          <OrderRejectionReasonsDialog />
          <OrdersListFilterDrawer />
          <OrderWhimDialog />
          <PartnerActivationReminderDialog />
          <PickupCodeDialog />
          <RTAssignedIssueSuggestionDialog />
          <RTDialog />
          <RTLackIssueSuggestionsDialog />
          <RTReleaseConfirmationDialog />
          <RTReportReasonsDialog />
          <StockOutIssueSuggestionsDialog />
          <StoreDiagnosticsDialog />
          <ProductSuggestionAvailabilityDialog />
          <SuggestProductDialog />
          <SuccessfulProxySessionDialog />
          <SuccessfulSuggestingProductDialog />
          <SupportChatDrawer />
          <SupportDialog />
          <SupportReasonsDialog />
          <ToppingsMassiveDeactivationDrawer />
          <StartPMFlowDialog />
          <ErrorPMFlowDialog />
          <EndPMFlowDialog />
          <PhonePMFlowDialog />
          <SchedulePMFlowDialog />
          <DynInAppDialog />
          <DeactivatedProductsNotificationsDialog />
          <ExtendClosingHoursDialog />
          <OrderManagement />
          <StoreSuspensionAlert />
        </div>
      )}
    </div>
  );
};

MainLayout.defaultProps = {
  classes: {},
  children: '',
  getOrders: () => {},
  profile: {},
  selectedFilterOptions: [],
  title: '',
};

export default MainLayout;
