import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import MenuUtils from '../../utils/MenuUtils';

import MenuProductDetail from './MenuProductDetail';

const mapStateToProps = ({
  menuReducer: {
    areProductToppingsLoading: isLoading,
    filter,
    menus,
    selectedProductId,
  },
}) => {
  const selectedStoreId = +filter.selectedStoreId;
  const allProducts = menus[selectedStoreId]?.products ?? [];
  const allToppings = menus[selectedStoreId]?.toppings ?? [];
  const product = MenuUtils.findProductById(selectedProductId, allProducts) || {
    id: 0,
  };
  const toppings = MenuUtils.filterByProductId(product.id, allToppings);
  return {
    isLoading,
    product,
    toppingsGroups: toppings,
  };
};

const mapDispatchToProps = {};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(MenuProductDetail),
);
