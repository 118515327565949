// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  resultContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
    width: '100%',
  },
  root: {
    backgroundColor: neutral.white,
    color: neutral.D20,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Poppins',
    fontSize: 17,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    height: '100%',
    width: '100%',
  },
  searchInputRoot: {
    maxWidth: 290,
  },
  header: {
    alignItems: 'center',
    borderBottom: `1px solid ${neutral.D10}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '24px',
    [theme.breakpoints.up('md')]: {
      padding: '11px 24px',
    },
  },
}));
