//Utils
import UrlUtils from '../utils/url-utils/UrlUtils';
// Consts
// Constants
import urls from '../constants/Urls';
// Base services
import http from './HttpService';
import { countryCodes } from './../constants/CountryCodes';
import CountryUtils from '../utils/country-utils';

const countriesServiceBaseURL = process.env.REACT_APP_PARTNER_BASE_URL;

const getAllAvailable = async () => {
  let { data: availableCountries } = await http.get(
    `${countriesServiceBaseURL}${urls.GET_ALL_AVAILABLE_COUNTRIES}`,
  );

  if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    availableCountries = availableCountries.filter(
      country => country.code === countryCodes.COLOMBIA,
    );
  }

  availableCountries = availableCountries.map(country => {
    return {
      ...country,
      flag: country.flag && country.flag.replace('http://', 'https://'),
    };
  });
  return CountryUtils.sortByName(availableCountries);
};

const resolveCountry = (lat, lng) => {
  const params = { lat, lng };
  const uriWithParams = UrlUtils.replaceUrlParams(urls.RESOLVE_COUNTRY, params);
  return http.get(`${countriesServiceBaseURL}${uriWithParams}`);
};

export default {
  getAllAvailable,
  resolveCountry,
};
