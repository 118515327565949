import { useDispatch, useSelector } from 'react-redux';

import dialogsManagerActions from '../../../../redux/actions/dialogsManager.actions';
import { orderMgmtSearchAdapter } from '../adapters/orderMgmt-search.adapter';
import { searchRepository } from '../repositories';
import { orderMgmtItemSearchDialogActions, searchOrderItem } from '../store';

import type {
  IItemTypeToReplace,
  IRecentlySearchedQueries,
} from '../../domain/models';
import { orderMgmtStatus } from '../../domain/models';

export function orderMgmtSearchFacade() {
  const dispatch = useDispatch();

  const selectIsOpen = useSelector(
    ({ orderManagementItemSearchDialog }: any) =>
      orderManagementItemSearchDialog.isOpen,
  );

  const selectStatus = useSelector(
    ({ orderManagementItemSearchDialog }: any) =>
      orderManagementItemSearchDialog.status,
  );

  const selectResults = useSelector(
    ({ orderManagementItemSearchDialog }: any) =>
      orderManagementItemSearchDialog.search.results,
  );

  const selectItemCategoryIndex = useSelector(
    ({ orderManagementItemSearchDialog }: any) =>
      orderManagementItemSearchDialog.search.itemCategoryIndex,
  );

  const selectItemCategoryDescription = useSelector(
    ({ orderManagementItemSearchDialog }: any) =>
      orderManagementItemSearchDialog.search.itemCategoryDescription,
  );

  const selectIsLoading = useSelector(
    ({ orderManagementItemSearchDialog }: any) =>
      orderManagementItemSearchDialog.status === orderMgmtStatus.Pending,
  );

  const selectIsSuccessful = useSelector(
    ({ orderManagementItemSearchDialog }: any) =>
      orderManagementItemSearchDialog.status === orderMgmtStatus.Successful,
  );

  const selectIsEmptyResults = useSelector(
    ({ orderManagementItemSearchDialog }: any) =>
      orderManagementItemSearchDialog.status === orderMgmtStatus.EmptyResponse,
  );

  const selectIsRejected = useSelector(
    ({ orderManagementItemSearchDialog }: any) =>
      orderManagementItemSearchDialog.status === orderMgmtStatus.Rejected,
  );

  const selectSearchAttempts = useSelector(
    ({ orderManagementItemSearchDialog }: any) =>
      orderManagementItemSearchDialog.searchAttempts,
  );

  const onSearchByQuery = (item: any, query: string) => {
    const request = orderMgmtSearchAdapter.toSearchRequest(item, query);
    dispatch(searchOrderItem(request));
  };

  const onSearchItem = () =>
    dispatch(orderMgmtItemSearchDialogActions.setLoading());

  const onOpenOrderMgmtDialog = () =>
    dispatch(dialogsManagerActions.openOrderManagementDialog());

  const onClearDialog = () =>
    dispatch(orderMgmtItemSearchDialogActions.clear());

  const onClearSearch = () =>
    dispatch(orderMgmtItemSearchDialogActions.clearSearch());

  function getRecentlySearchedQueries(params: IItemTypeToReplace) {
    const { itemType } = params;
    return searchRepository.getRecentlySearchedQueries({ itemType });
  }

  function setRecentlySearchedQuery(params: IRecentlySearchedQueries) {
    const { query, itemType = 'product' } = params;
    return searchRepository.setRecentlySearchedQueries({
      query,
      itemType,
    });
  }

  return {
    selectIsOpen,
    selectStatus,
    selectResults,
    selectIsLoading,
    selectIsSuccessful,
    selectIsEmptyResults,
    selectItemCategoryIndex,
    selectItemCategoryDescription,
    selectIsRejected,
    selectSearchAttempts,
    onSearchByQuery,
    onSearchItem,
    onOpenOrderMgmtDialog,
    onClearDialog,
    onClearSearch,
    getRecentlySearchedQueries,
    setRecentlySearchedQuery,
  };
}
