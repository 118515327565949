// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  customAlertPaymentMethodRoot: {
    fontSize: 12,
    fontStretch: 'normal !important',
    fontStyle: 'normal !important',
    lineHeight: 'normal !important',
    letterSpacing: 0,
    textAlign: 'initial !important',
    padding: '10px !important',
    width: '100%',
  },
  description: { whiteSpace: 'pre-line' },
  root: {
    color: neutral.D20,
    display: 'grid',
    gridRowGap: 12,
    fontSize: 12,
    lineHeight: 'normal',
  },
}));
