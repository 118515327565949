import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  corridor: {
    fontSize: '0.75rem',
    marginTop: '1rem',
    '&::first-letter': {
      textTransform: 'capitalize',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '0',
    },
  },
}));
