import { useEffect } from 'react';
import {
  OrderManagementResponse,
  OrderMgmtDisclaimerFacade,
  ordermgmtDialogFacade,
} from '../../../../../business/order-mgmt';
import { orderMgmtDetailFacade } from '../../../../../business/order-mgmt/infra/store/order-detail';

export function useOrderDetailDisclaimer(order: OrderManagementResponse) {
  const { getAcceptedData } = OrderMgmtDisclaimerFacade();
  const { selectStatus } = orderMgmtDetailFacade();
  const { DialogError, closeDialog } = ordermgmtDialogFacade();
  useEffect(() => {
    if (selectStatus === 'rejected') {
      DialogError();
      closeDialog();
    } else if (selectStatus === 'successful') {
      closeDialog();
    }
  }, [selectStatus]);

  return {
    acceptedData: getAcceptedData(order),
  };
}
