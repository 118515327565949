// Redux
import { createActions } from 'redux-actions';

const {
  closeCustomerPhoneProxySessionDialogAction,
  clearCustomerPhoneProxySessionDialogAction,
  openCustomerPhoneProxySessionDialogAction,
  setPhoneNumberAction,
  setErrorTextAction,
  clearErrorTextAction,
} = createActions({
  CLOSE_CUSTOMER_PHONE_PROXY_SESSION_DIALOG_ACTION: () => {},
  CLEAR_CUSTOMER_PHONE_PROXY_SESSION_DIALOG_ACTION: () => {},
  OPEN_CUSTOMER_PHONE_PROXY_SESSION_DIALOG_ACTION: (orderId, isOM) => ({
    orderId,
    isOM,
  }),
  SET_PHONE_NUMBER_ACTION: phoneNumber => ({ phoneNumber }),
  SET_ERROR_TEXT_ACTION: (errorCode, messageError) => ({
    errorCode,
    messageError,
  }),
  CLEAR_ERROR_TEXT_ACTION: () => {},
});

const clearCustomerPhoneProxySessionDialog = () => dispatch => {
  dispatch(clearCustomerPhoneProxySessionDialogAction());
};

const closeCustomerPhoneProxySessionDialog = () => dispatch => {
  dispatch(closeCustomerPhoneProxySessionDialogAction());
};

const openCustomerPhoneProxySessionDialog = (
  orderId,
  isOM = false,
) => dispatch => {
  dispatch(openCustomerPhoneProxySessionDialogAction(orderId, isOM));
};
const setPhoneNumber = phoneNumber => dispatch => {
  dispatch(setPhoneNumberAction(phoneNumber));
};
const setErrorText = (errorCode, messageError) => dispatch => {
  dispatch(setErrorTextAction(errorCode, messageError));
};
const clearErrorText = () => dispatch => {
  dispatch(clearErrorTextAction());
};

export default {
  clearCustomerPhoneProxySessionDialog,
  clearCustomerPhoneProxySessionDialogAction,
  closeCustomerPhoneProxySessionDialog,
  closeCustomerPhoneProxySessionDialogAction,
  openCustomerPhoneProxySessionDialog,
  openCustomerPhoneProxySessionDialogAction,
  setPhoneNumber,
  setPhoneNumberAction,
  setErrorText,
  setErrorTextAction,
  clearErrorText,
  clearErrorTextAction,
};
