import Box from '@material-ui/core/Box';
import { orderMgmtTimeoutCase } from '../../../../business/order-mgmt';
import { ProductCard } from './card/ProductCard';

export function TimeoutProducts() {
  const { products, orderId } = orderMgmtTimeoutCase();

  return (
    <Box overflow='auto'>
      {products.map(product => (
        <ProductCard
          key={`c-${product.id}`}
          product={product}
          orderId={orderId}
        />
      ))}
    </Box>
  );
}
