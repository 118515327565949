import { Box } from '@material-ui/core';

import {
  Colors,
  Variants as ButtonVariants,
} from '../../../../components/common/buttons/custom-button';
import CustomButton from '../../../../components/common/buttons/custom-button/CustomButton';

import { useDialogData } from './hooks';
import { useRequestLang } from '../request/hooks';

export function Actions() {
  const {
    requestCoverage,
    isLoading,
    isCargoFormRequest,
    isCheckingCoverage,
    clearNewOrder,
    isDisabled,
    coverageStatus,
    handleCoverageBackClick,
    handleCoverageNextClick,
  } = useDialogData();

  const {
    backCoverageButtonText,
    nextCoverageButtonText,
    backFormButtonText,
    nextFormButtonText,
  } = useRequestLang({ coverageStatus });

  const CoverageAction = () => {
    return (
      <>
        <CustomButton
          color={Colors.SECONDARY}
          onClick={handleCoverageBackClick}
          variant={ButtonVariants.TEXT}
          fullWidth={false}
        >
          {backCoverageButtonText}
        </CustomButton>
        <CustomButton
          variant={ButtonVariants.CONTAINED}
          onClick={handleCoverageNextClick}
          fullWidth={false}
          disabled={isDisabled()}
          isLoading={isLoading}
        >
          {nextCoverageButtonText}
        </CustomButton>
      </>
    );
  };

  const FormActions = () => {
    return (
      <>
        <CustomButton
          color={Colors.SECONDARY}
          onClick={clearNewOrder}
          variant={ButtonVariants.TEXT}
          fullWidth={false}
        >
          {backFormButtonText}
        </CustomButton>
        <CustomButton
          variant={ButtonVariants.CONTAINED}
          onClick={requestCoverage}
          fullWidth={false}
          disabled={isDisabled()}
          isLoading={isLoading}
        >
          {nextFormButtonText}
        </CustomButton>
      </>
    );
  };

  return (
    <Box
      gridArea='actions'
      width='100%'
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      gridGap='0.5rem'
      padding='0 0.5rem'
      margin='1rem 0'
      paddingTop='1rem'
      borderTop='1px solid #ededed'
    >
      {isCargoFormRequest && <FormActions />}
      {isCheckingCoverage && <CoverageAction />}
    </Box>
  );
}
