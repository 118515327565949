export interface OrderManagementResponse {
  state: stateOMType;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  pivot: number;
  products: OMProduct[];
  toppings: OMToppingDTO[];
}

export interface OMProduct {
  product_id: number;
  order_product_id: number;
  name: string;
  description: string;
  image: string;
  units: number;
  price: number;
  total: number;
  type: string;
  replacement_product_options: ReplacementProductOptionDTO[];
}

export interface OMToppingDTO {
  topping_id: number;
  product_id: number;
  order_product_id: number;
  product_name: string;
  topping_description: string;
  units: number;
  price: number;
  type: string;
  image: string;
  topping_category_id: number;
  topping_category_index: number;
  topping_category_description: string;
  is_removable_topping: boolean;
  replacement_topping_options: ReplacementToppingOptionDTO[];
}

export interface ReplacementToppingOptionDTO {
  added_topping_id: number;
  added_topping_description: string;
  added_topping_image: string;
  added_topping_units: number;
  added_topping_price: number;
  added_topping_total: number;
  added_topping_index: number;
  added_topping_age_restriction: boolean;
  accepted: boolean;
}

export interface ReplacementProductOptionDTO {
  added_product_id: number;
  added_product_name: string;
  added_product_description: string;
  added_product_image: string;
  added_product_units: number;
  added_product_price: number;
  added_product_total: number;
  accepted: boolean;
}

export const AllTimeoutStates: any = [
  'timeout',
  'timeout_rejection',
  'timeout_rejection_quantity',
  'timeout_rejection_toppings_rule',
] as const;
type TimeoutStates = typeof AllTimeoutStates[number];

export type stateOMType =
  | TimeoutStates
  | 'to_wait'
  | 'accepted'
  | 'canceled'
  | '';
