// Material UI
import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  body: {
    display: 'grid',
    gridRowGap: 24,
    margin: 24,
    [theme.breakpoints.up('md')]: {
      flex: 'none',
    },
  },
  diagnosticsList: {
    display: 'grid',
    gridRowGap: 16,
  },
  headerRoot: {
    padding: '20px 24px',
    gridTemplateColumns: 'auto',
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      width: 488,
    },
  },
}))
