import { useFormatMessage } from '../../../../intl/intl';

import { textIds } from './consts';
import useStyles from './styles';

import type { BaseProps } from '../../../../interfaces';

export function SuggestedProductComment(props: Props) {
  const { suggestedProduct, productNameToBeReplaced } = props;

  const textLabel = useFormatMessage(textIds.textLabel);
  const comments = useFormatMessage(textIds.comments);
  const replaceProduct = useFormatMessage(textIds.replaceProduct, {
    productReplaceName: productNameToBeReplaced,
    productSuggested: suggestedProduct,
  });

  const componentClasses = useStyles();

  return (
    <div className={componentClasses.root}>
      <div className={componentClasses.LabelComment}>{comments}:</div>
      <div className={componentClasses.LabelText}>
        {textLabel} {replaceProduct}
      </div>
    </div>
  );
}

interface Props extends BaseProps {
  suggestedProduct: string;
  productNameToBeReplaced: string;
}
