import { clsx } from 'clsx';
import { Typography } from '@material-ui/core';

import { OrderTypeChip } from '../../order-type-chip';

import { formatCurrency } from '../../../utils/CurrencyUtils';
import makeClasses from './CustomActionKanbanCard.styles';

import { TurboTag } from '../../../features/order/geoqueues/components';

export default function CustomActionKanbanCard(props) {
  const {
    onClick,
    storeName,
    orderID,
    totalPrice,
    showChipOfBundle = false,
    classes: externalClasses = {},
    isTurboqueue = false,
  } = props;

  const showStoreName = Boolean(storeName);
  const showTotalPrice = Boolean(totalPrice);

  const classes = makeClasses();

  return (
    <article
      onClick={onClick}
      className={clsx(classes.article, externalClasses?.root)}
    >
      {isTurboqueue && <TurboTag parentClass={classes.turboTag} />}
      <section className={classes.section}>
        <OrderTypeChip orderType={showChipOfBundle ? 'bundle' : 'normal'} />
        <main>
          <Typography component='h5' variant='body1' className={classes.fw600}>
            ID: {orderID}
          </Typography>
        </main>
        {(showStoreName || showTotalPrice) && (
          <footer>
            {showStoreName && (
              <Typography className={classes.storeName} variant='overline'>
                {storeName}
              </Typography>
            )}
            {showTotalPrice && (
              <Typography variant='subtitle2'>
                {formatCurrency(totalPrice, true)}
              </Typography>
            )}
          </footer>
        )}
      </section>
    </article>
  );
}
