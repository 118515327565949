import clsx from 'clsx';
import Tab from '@material-ui/core/Tab';

import useStyles from './CustomTitleBadgeTab.styles';

const getLabel = (count, label) => {
  if (!count) {
    return `${label}`;
  }
  const countLabel = count < 99 ? count : `+99`;
  return `${label}  (${countLabel})`;
};

export function CustomTitleBadgeTab({ count, label, classes = {}, ...props }) {
  const _classes = useStyles();
  const labelText = getLabel(count, label);
  return (
    <Tab
      classes={{ root: clsx(_classes.root, classes?.root) }}
      {...props}
      label={labelText}
    />
  );
}
