// External libs
import { createSelector } from 'reselect';
// Interfaces
import { Action } from '../../../transpilers/dsl-transpiler';
import { AlertSnackbar, Button, State } from '../interfaces';
import { StyleRules } from '../../../components/common/custom-alert-snackbar/components/custom-base-alert-snackbar';

const selectSlice: (state: any) => State = (state: any) =>
  state.dynAlertSnackbar;

const selectStack = createSelector<any, State, AlertSnackbar[]>(
  selectSlice,
  (reducer) => reducer.stack,
);

const selectConfigToRender = createSelector<
  any,
  AlertSnackbar[],
  AlertSnackbar | undefined
>(selectStack, (stack) =>
  stack.length > 0 ? stack[stack.length - 1] : undefined,
);

const selectButtons = createSelector<any, AlertSnackbar | undefined, Button[]>(
  selectConfigToRender,
  (alertSnackbar) => alertSnackbar?.buttons || [],
);

const selectId = createSelector<any, AlertSnackbar | undefined, string | null>(
  selectConfigToRender,
  (alertSnackbar) => alertSnackbar?.id || null,
);

const selectOnClose = createSelector<
  any,
  AlertSnackbar | undefined,
  Action | null
>(selectConfigToRender, (alertSnackbar) => alertSnackbar?.onClose || null);

const selectOpen = createSelector<any, State, boolean>(
  selectSlice,
  (reducer) => reducer.open,
);

const selectMessage = createSelector<
  any,
  AlertSnackbar | undefined,
  string | null | undefined
>(selectConfigToRender, (alertSnackbar) => alertSnackbar?.message || '');

const selectStyleRules = createSelector<
  any,
  AlertSnackbar | undefined,
  StyleRules | undefined
>(selectConfigToRender, (alertSnackbar) => alertSnackbar?.stylesRules);

const selectors = {
  selectButtons,
  selectId,
  selectMessage,
  selectOnClose,
  selectOpen,
  selectStyleRules,
};

export default selectors;
