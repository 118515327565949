import { createSelector } from 'reselect';

import PartnerTypes from '../../constants/PartnerTypes';
import utils from './utils';

import type { SuspendedStoreNotifyResp } from '../../components/store-suspension-alert';
import type { StoreStatusDetail } from '../../services/partners-service';
import type { StoreStatusDTO } from '../../shared/infra';
import type { Store, SuspendedStore } from './interfaces';

const selectSlice: (state: any) => any = (state: any) => state.partnerReducer;

const selectProfile = createSelector<any, any, any>(
  selectSlice,
  slice => slice.profile || {},
);

const selectSupportPhone = createSelector<any, any, string>(
  selectProfile,
  profile => profile?.support_phone ?? '',
);

const selectPartnerType = createSelector<any, any, PartnerTypes>(
  selectProfile,
  profile => profile?.type || PartnerTypes.RESTAURANT,
);

const selectIsPartnerIntegrated = createSelector<any, PartnerTypes, boolean>(
  selectPartnerType,
  partnerType => partnerType === PartnerTypes.PARTNERS_INTEGRADO,
);

const selectCookingTimeStep = createSelector<any, any, number>(
  selectProfile,
  profile => profile.cookingTimeStep,
);

const selectDeactivatedProducts = createSelector<any, any, any[]>(
  selectSlice,
  reducer => {
    return reducer.deactivatedProducts;
  },
);

const selectSupportTypifications = createSelector<any, any, any[]>(
  selectSlice,
  reducer => reducer.typifications,
);

const selectStores = createSelector<any, any, any>(selectProfile, profile => {
  return profile?.stores || [];
});

const selectStoreProfileById = createSelector<any, any, any>(
  selectStores,
  stores => storeId => stores.find(s => s.id === storeId),
);

const selectStoresSupendedReasons = createSelector<any, any, SuspendedStore[]>(
  selectSlice,
  reducer => {
    return reducer.suspendedStoresReasons || [];
  },
);

const selectStoreStatusDetail = createSelector<any, any, StoreStatusDetail[]>(
  selectSlice,
  reducer => {
    return reducer.storeStatusDetail || [];
  },
);

const selectIsStoreStatusDetailLoading = createSelector<any, any, boolean>(
  selectSlice,
  reducer => reducer.isStoreStatusDetailLoading,
);

const selectStoresWithSupended = createSelector(
  selectStores,
  selectStoresSupendedReasons,
  (stores: Store[], suspensionReasons: SuspendedStore[]) => {
    return utils.SuspensionStoreWithReason(stores, suspensionReasons);
  },
);

const selectPartnerSaturation = createSelector<any, any, string>(
  selectProfile,
  profile => {
    return profile?.saturated;
  },
);
const selectIsFirstLogin = createSelector<any, any, any>(
  selectSlice,
  reducer => reducer.isFirstLogin,
);

const selectIsChatV2Available = createSelector<any, any, boolean>(
  selectSlice,
  reducer => {
    return reducer.isChatV2Available;
  },
);

const selectIsKanbanUserAppChatEnabled = createSelector<any, any, boolean>(
  selectSlice,
  reducer => {
    return reducer.isKanbanUserAppChatEnabled;
  },
);

const selectScheduledPMAutomationForm = createSelector<any, any, any>(
  selectSlice,
  reducer => {
    return reducer.scheduledPMAutomationForm;
  },
);
const selectIsAutoReadyForPickupMarketplaceAvailable = createSelector<
  any,
  any,
  boolean
>(selectSlice, reducer => {
  return reducer.isAutoReadyForPickupMarketplaceAvailable;
});

const selectSimpleStoreStatusNotification = createSelector<
  any,
  any,
  { total: number; state: StoreStatusDTO }[]
>(selectSlice, reducer => {
  return reducer.simpleStoreStatusNotification;
});

const selectIsSwitchStoreStatusLoading = createSelector<any, any, boolean>(
  selectSlice,
  reducer => reducer.isSwitchStoreStatusLoading,
);

const selectIsPartnerStateLoading = createSelector<any, any, boolean>(
  selectSlice,
  reducer => reducer.isPartnerStateLoading,
);

const selectHasBeenUpdatedStoreStatusNotification = createSelector<
  any,
  any,
  boolean
>(selectSlice, reducer => reducer.hasBeenUpdatedStoreStatusNotification);

const selectSuspendedStoresNotification = createSelector<
  any,
  any,
  SuspendedStoreNotifyResp[]
>(selectSlice, reducer => reducer.suspendedStores);

const selectIsFastOperation = createSelector<any, any, boolean>(
  selectSlice,
  reducer => reducer.isFastOperation,
);

const selectors = {
  selectCookingTimeStep,
  selectDeactivatedProducts,
  selectSupportTypifications,
  selectIsPartnerIntegrated,
  selectPartnerSaturation,
  selectProfile,
  selectSupportPhone,
  selectStores,
  selectStoreProfileById,
  selectStoresSupendedReasons,
  selectStoresWithSupended,
  selectIsFirstLogin,
  selectIsChatV2Available,
  selectIsAutoReadyForPickupMarketplaceAvailable,
  selectScheduledPMAutomationForm,
  selectSuspendedStoresNotification,
  selectStoreStatusDetail,
  selectSimpleStoreStatusNotification,
  selectIsStoreStatusDetailLoading,
  selectIsSwitchStoreStatusLoading,
  selectIsPartnerStateLoading,
  selectHasBeenUpdatedStoreStatusNotification,
  selectIsKanbanUserAppChatEnabled,
  selectIsFastOperation,
};

export default selectors;
