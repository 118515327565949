import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useFormatMessage } from '../../intl/intl';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';
import Hooks from './hooks';

import CustomDialog from '../common/custom-dialog/CustomDialog';

import useStyles from './styles';

export function RTReleaseConfirmationDialog() {
  const componentClasses = useStyles();

  const message = useFormatMessage({
    id: 'rtReleaseConfirmationDialog.message',
  });

  const title = useFormatMessage({
    id: 'rtReleaseConfirmationDialog.title',
  });

  const open = Hooks.useOpen();
  const orderId = Hooks.useOrderId();
  const storekeeperId = Hooks.useStoreKeeperId();

  const dispatch = useDispatch();

  const handleBack = useCallback(() => {
    dispatch(dialogsManagerActions.back());
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  const handleConfirm = Hooks.useHandleConfirm(
    dispatch,
    orderId,
    storekeeperId,
  );

  return (
    <CustomDialog
      classes={{
        headerRoot: componentClasses.headerRoot,
        paper: componentClasses.paper,
        childrenContainer: componentClasses.body,
      }}
      actions={[
        {
          text: 'confirmar',
          onClick: handleConfirm,
          color: 'primary',
          variant: 'contained',
          fullWidth: false,
        },
      ]}
      onClickBackButton={handleBack}
      showBackButton
      showCloseButton
      onClose={handleClose}
      open={open}
      title={title}
    >
      <div className={componentClasses.fontText}>{message}</div>
    </CustomDialog>
  );
}
