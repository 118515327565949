import { clsx } from 'clsx';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import useStyles from './CustomAddMobileButton.styles';

export function CustomAddMobileButton(props) {
  const { onClick, textLabel, disabled = false, classes = {} } = props;
  const _classes = useStyles({ disabled });
  return (
    <Button
      aria-label='Add'
      className={clsx(classes?.button)}
      disabled={disabled}
      onClick={onClick}
    >
      <AddIcon
        className={clsx(
          _classes.icon,
          classes?.icon,
          disabled && classes?.iconDisabled,
        )}
      />
      <span className={_classes.label}>{textLabel}</span>
    </Button>
  );
}
