// React
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
// Actions
import AppActions from '../../redux/actions/app-actions'
// Components
import SpecialDayDateAddButton from './SpecialDayDateAddButton'
import newSpecialDayDialogActions from '../../redux/actions/newSpecialDayDialog.actions'

const mapStateToProps = (
  { partnerReducer: { profiles } },
  { intl: { formatMessage } },
) => {
  const SpecialDayButton = formatMessage({
    id: 'specialDayDateAddButton.SpecialDayButton',
  })
  return {
    SpecialDayButton,
  }
}

const mapDispatchToProps = {
  openSnackBarError: AppActions.openSnackBarError,
  onClick: newSpecialDayDialogActions.openNewSpecialDayDialog,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SpecialDayDateAddButton),
)
