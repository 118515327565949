import { HandoffDetail } from '../../domain';
import { HandoffDetailResponse } from '../core/responses/checkoutRT.response';

const toGetHandoffDetail = (response: HandoffDetailResponse): HandoffDetail => {
  return {
    quantityOfPackages: response.number_of_bags,
    hasDrinksOutside: response.has_drinks_outside_bag,
  };
};

export const responseAdapter = {
  toGetHandoffDetail,
};
