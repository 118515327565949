import { useRef } from 'react';
import { ChevronRight } from '@material-ui/icons';
import { Button, Drawer, Popover } from '@material-ui/core';

import { DropdownContent } from './comps';
import { ReactComponent as RTIcon } from '../../../../assets/images/icons/rappitendero.svg';

import { useCargoMainBtn } from './hooks';

import makeClasses from './cargo-main-btn.style';

export function CargoMainBtn({ isEnabled }: Props) {
  const anchorRef = useRef(null);

  const {
    lang,
    isLarge,
    isOpenDropdown,
    handleOpenDropDown,
    handleCloseDropDown,
  } = useCargoMainBtn();

  const _classes = makeClasses({ isOpenDropdown });

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={handleOpenDropDown}
        endIcon={isLarge && <ChevronRight />}
        startIcon={<RTIcon className={_classes.startIcon} />}
        classes={{ root: _classes.btn, endIcon: _classes.btnEndIcon }}
      >
        {lang.cta}
      </Button>

      {isLarge ? (
        <Popover
          open={isOpenDropdown}
          role='tooltip'
          anchorEl={anchorRef.current}
          onClose={handleCloseDropDown}
          anchorOrigin={{ vertical: -4, horizontal: 'right' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          PaperProps={{
            elevation: 0,
            classes: { root: _classes.popoverPaper },
          }}
        >
          <DropdownContent isEnabled={isEnabled} />
        </Popover>
      ) : (
        <Drawer
          open={isOpenDropdown}
          anchor='bottom'
          onClose={handleCloseDropDown}
          PaperProps={{ classes: { root: _classes.drawerPaper } }}
        >
          <DropdownContent isEnabled={isEnabled} />
          <Button
            size='large'
            variant='text'
            onClick={handleCloseDropDown}
            classes={{ root: _classes.drawerCloseBtn }}
          >
            {lang.close}
          </Button>
        </Drawer>
      )}
    </>
  );
}

interface Props {
  isEnabled: boolean;
}
