import { clsx } from 'clsx';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import useStyles from './CustomAddButton.styles';

export function CustomAddButton({ disabled, onClick, classes = {} }) {
  const _classes = useStyles({ disabled });
  return (
    <IconButton
      aria-label='Add'
      className={clsx(classes?.button)}
      disabled={disabled}
      onClick={onClick}
    >
      <AddIcon
        className={clsx(
          _classes.icon,
          classes?.icon,
          disabled && classes?.iconDisabled,
        )}
      />
    </IconButton>
  );
}
