import { clsx } from 'clsx';

import { FormControlLabel, Radio } from '@material-ui/core';

import useStyles from './CustomRadio.styles';

export function CustomRadio(props) {
  const {
    classes = {},
    color,
    label = '',
    onChange,
    value,
    disabled = false,
  } = props;
  const _classes = useStyles();
  return (
    <FormControlLabel
      classes={{
        root: clsx(classes?.root),
        label: clsx(_classes.label, classes?.label),
      }}
      control={<Radio classes={{}} color={color} disabled={disabled} />}
      label={label}
      onChange={onChange}
      value={value}
    />
  );
}
