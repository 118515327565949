import { clsx } from 'clsx';

import CustomButton from '../buttons/custom-button';

import useStyles from './CustomDialogFooter.styles';

export function CustomDialogFooter({ actions, classes = {} }) {
  const _classes = useStyles();
  return (
    <div className={clsx(_classes.root, classes?.root)}>
      {!!actions &&
        !!actions.length &&
        actions.map((action, index) => {
          const actionColor = action?.color;
          return (
            <CustomButton
              classes={{
                root: clsx(
                  _classes.actionRoot,
                  classes?.actionRoot,
                  action?.classes?.root,
                ),
                submitButton: clsx(
                  _classes.actionSubmitButton,
                  !actionColor && _classes?.actionSubmitButtonDefault,
                  classes?.actionSubmitButton,
                  action?.classes?.submitButton,
                ),
              }}
              color={actionColor}
              key={`action-${index}`}
              onClick={action?.onClick ?? (() => {})}
              fullWidth={false}
              variant={action?.variant ?? null}
              disabled={action?.disabled ?? false}
            >
              {action?.text ?? ''}
            </CustomButton>
          );
        })}
    </div>
  );
}
