import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../../../shared/styles/colors';

export default makeStyles({
  card: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
    borderRadius: '0.5rem',
    border: `1px solid ${neutral['20']}`,
  },
  title: {
    fontSize: '0.75rem',
    fontWeight: 700,
    lineHeight: '1rem',
    letterSpacing: '2px',
    color: neutral['50'],
  },
  description: {
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    color: neutral['90'],
  },
});
