import CustomDialog from '../common/custom-dialog';
import { PhoneBox } from '../phone-box';

import useStyles from './SuccessfulProxySessionDialog.styles';

function SuccessfulProxySessionDialog(props) {
  const {
    callFromText,
    callToText,
    description,
    noteForUser,
    onBack,
    onClose,
    open,
    title,
    callTo = null,
    callFrom = null,
  } = props;

  const componentClasses = useStyles();

  return (
    <CustomDialog
      classes={{
        headerRoot: componentClasses.headerRoot,
        paper: componentClasses.paper,
      }}
      onClickBackButton={onBack}
      onClose={onClose}
      open={open}
      showBackButton
      showCloseButton
      title={title}
    >
      <div className={componentClasses.body}>
        <div className={componentClasses.text}>{description}</div>
        <PhoneBox
          classes={{ root: componentClasses.box }}
          title={callFromText}
          number={callFrom}
        />
        <PhoneBox title={callToText} number={callTo} />
        <div className={componentClasses.noteText}>{noteForUser}</div>
      </div>
    </CustomDialog>
  );
}

export default SuccessfulProxySessionDialog;
