import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { browserDialog } from '../../../../shared/services';
import { BrowserApp } from '../../../../business/common/browser';

export function useBrowser(showCloseButton) {
  const {
    setup,
    showDialog,
    isOpen,
    openDialog,
    redirect2Help,
    contentType,
    redirect2Install,
    closeDialog,
    closeOutside,
    partnerId,
  } = BrowserApp();
  const navigate = useNavigate();

  const DialogCloseReasons = {
    escapeKeyDown: 'escapeKeyDown',
    backdropClick: 'backdropClick',
  } as const;

  useEffect(() => {
    setup();
    if (showDialog && partnerId) {
      browserDialog.call.add(() => openDialog(showCloseButton));
      browserDialog.call.dispatch();
    }
  }, [partnerId, showDialog]);

  const dialogClose = (e, reason) => {
    if (
      reason === DialogCloseReasons.escapeKeyDown ||
      reason === DialogCloseReasons.backdropClick
    ) {
      closeOutside();
      return;
    }
    browserDialog.call.remove();
    closeDialog();
  };

  const go2Help = () => navigate(redirect2Help());
  return {
    isOpen,
    contentType,
    go2Help,
    redirect2Install,
    dialogClose,
  };
}
