import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { TurnoffButton } from './components/buttons/TurnoffButton';
import { CancelButton } from './components/buttons/CancelButton';
import CustomDrawer from '../custom-drawer/CustomDrawer';
import CustomItemInformation from './components/custom-item-information/CustomItemInformation';
import { CustomDeactivationOptionsRadioGroup } from './components/custom-deactivation-options-radio-group';

import { useTexts } from './hooks';
import {
  analitycsEvent,
  GACategories,
  GAActions,
} from '../../../utils/ga-utils/GAUtils';

import DeactivationType from '../../../constants/DeactivationType';
import menuActions from '../../../redux/actions/menu.actions';
import { CANCEL_BUTTON_PROPS, TURNOFF_BUTTON_PROPS } from './constants';
import useStyles from './CustomItemsDeactivationDrawer.styles';

import type { ICustomItemsDeactivationDrawer } from './types';

export function CustomItemsDeactivationDrawer(props: Props) {
  const {
    item = { id: null, name: '', description: '', image: '' },
    open,
    onClose,
    isProduct = true,
    partnerId,
  } = props;

  const itemName = isProduct ? item?.name : item?.description;
  const itemImage = item?.image;

  const { radioButtonOptions: options } = useTexts();
  const { button: buttonLabels } = useTexts();
  const { title: drawerTitle } = useTexts();

  const _classes = useStyles();
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState('');
  const [deactivationDate, setDeactivationDate] = useState('');

  const handleDeactivationDate = (date: string) => {
    console.log(date);
    setDeactivationDate(date);
    setSelectedOption(DeactivationType.CUSTOM);
  };

  const handleChange = (event: { target: { value: string } }) => {
    const {
      target: { value },
    } = event;
    setDeactivationDate('');
    if (value === DeactivationType.CUSTOM && deactivationDate === '') return;
    setSelectedOption(value);
  };

  const handleClose = () => {
    onClose();
    setSelectedOption('');
    setDeactivationDate('');
  };

  const handleSubmit = () => {
    if (isProduct) {
      analitycsEvent(
        GACategories.MENU_MANAGEMENT,
        GAActions.DEACTIVATE_PRODUCTS,
        `deactivate_period=${selectedOption}#partner_id=${partnerId}`,
      );
      dispatch(
        menuActions.setProductAvailability(
          item.id,
          false,
          selectedOption,
          deactivationDate,
        ),
      );
    }

    if (!isProduct) {
      analitycsEvent(
        GACategories.MENU_MANAGEMENT,
        GAActions.DEACTIVATE_TOPPING,
        `deactivate_period=${selectedOption}#partner_id=${partnerId}`,
      );
      dispatch(
        menuActions.setToppingAvailability(
          false,
          item?.toppingCategoryId,
          item?.id,
          selectedOption,
          deactivationDate,
        ),
      );
    }
    handleClose();
  };

  return (
    <CustomDrawer open={open} onClose={handleClose} title={drawerTitle}>
      <div className={_classes.menuContainer}>
        <CustomItemInformation
          itemName={itemName}
          itemImage={itemImage}
          isProduct={isProduct}
        />
        <CustomDeactivationOptionsRadioGroup
          handleDeactivationDate={handleDeactivationDate}
          deactivationDate={deactivationDate}
          selectedOption={selectedOption}
          handleChange={handleChange}
          options={options}
        />
        <div className={_classes.buttonsContainer}>
          <CancelButton
            variant={CANCEL_BUTTON_PROPS.variant}
            disableRipple
            onClick={handleClose}
          >
            {buttonLabels.cancel}
          </CancelButton>
          <TurnoffButton
            variant={TURNOFF_BUTTON_PROPS.variant}
            disabled={!selectedOption}
            onClick={handleSubmit}
            disableRipple
            disableElevation
          >
            {buttonLabels.turnoff}
          </TurnoffButton>
        </div>
      </div>
    </CustomDrawer>
  );
}

type Props = ICustomItemsDeactivationDrawer;
