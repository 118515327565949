import { clsx } from 'clsx';

import { CustomStateLabel } from '../../../common/custom-state-label';
import { GeoqueueRTSection } from '../../../../features/order/geoqueues';

import useStyles from './SearchRTStateLabel.styles';

export function SearchRTStateLabel(props) {
  const { isGeoqueue = false, classes = {}, ...extraProps } = props;
  const _classes = useStyles();
  return (
    <>
      {!isGeoqueue && (
        <CustomStateLabel
          classes={{
            root: clsx(_classes.root, classes?.root),
            iconRoot: clsx(_classes.iconRoot, classes?.iconRoot),
          }}
          {...extraProps}
        />
      )}
      {isGeoqueue && <GeoqueueRTSection showStatusAction />}
    </>
  );
}
