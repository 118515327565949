import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { formatCurrency } from '../../../../../utils/CurrencyUtils';
import { useOrderMgmtLang } from '../../../hooks';
import OrderMgmtCardStyles from '../../../OrderManagement.style';
import {
  OrderMgmtProduct,
  OrderMgmtTopping,
} from '../../../../../business/order-mgmt';

export const ResumeItemSuggestions = ({ item, isTopping = false }: Props) => {
  const componentClasses = OrderMgmtCardStyles();
  const { userCanChooseText, insteadOfText } = useOrderMgmtLang();
  const itemSuggestions = item?.suggestions || [];

  return (
    <Box
      className={componentClasses.orderMgmtResumeItemSuggestions}
      width='100%'
      padding='0.5rem 1rem'
    >
      <Typography className={componentClasses.userDisclaimer}>
        {userCanChooseText}
      </Typography>
      {itemSuggestions.map(suggestion => (
        <Box
          className={componentClasses.item}
          key={suggestion.id}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          gridGap='0.5rem'
        >
          <Box display='flex' gridGap='0.25rem'>
            {item?.units > 1 && (
              <Typography
                className={componentClasses.itemUnits}
              >{`${item?.units}x`}</Typography>
            )}
            <Typography className={componentClasses.itemName}>
              {suggestion.name}
            </Typography>
          </Box>
          <Typography className={componentClasses.itemPrice}>
            {`${suggestion.price}`}
          </Typography>
        </Box>
      ))}
      {isTopping && (
        <>
          <Typography className={componentClasses.insteadOf}>
            {insteadOfText}
          </Typography>
          <Box
            className={componentClasses.item}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            gridGap='0.5rem'
          >
            {item?.units > 1 && (
              <Typography
                className={componentClasses.itemUnits}
              >{`${item?.units}x`}</Typography>
            )}
            <Typography className={componentClasses.itemName}>
              {item?.name}
            </Typography>
            <Typography className={componentClasses.itemPrice}>
              {formatCurrency(item?.price)}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

interface Props {
  item: OrderMgmtProduct | OrderMgmtTopping;
  isTopping?: boolean;
}
