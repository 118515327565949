// React
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
// External lib
import { find, get } from 'lodash';
// Components
import OrderDetailDialogBody from './OrderDetailDialogBody';
import OrdersUtils from '../../utils/OrdersUtils';

const mapStateToProps = (
  {
    orderDetailDialogReducer: { isLoading, orderId },
    ordersReducer: { orders },
  },
  { intl: { formatMessage } },
  props,
) => {
  const order = find(orders, { id: +orderId });
  const orderDetail = get(order, 'detail', null);

  const isBeingManaged = OrdersUtils.isBeingManaged(order);
  const isOrderCancel = OrdersUtils.isCanceled(get(orderDetail, 'state', null));
  const isInStore = OrdersUtils.isInStore(get(orderDetail, 'state', null));
  const isFullDelivery = OrdersUtils.isFullDelivery(
    get(orderDetail, 'delivery_method', null),
  );
  const isOrderReadyAndPack = OrdersUtils.isReadyAndPacked(
    get(orderDetail, 'partner_order_state', null),
  );
  const isRTInStore = OrdersUtils.isRTInStoreFromDetail(orderDetail);

  const showActionBox =
    isBeingManaged &&
    (isOrderCancel ||
      (isInStore &&
        (!isFullDelivery ||
          !isOrderReadyAndPack ||
          (isOrderReadyAndPack && isRTInStore))));

  const editTitle = formatMessage({ id: 'orderManagement.editCountdownTitle' });
  const editDescription = formatMessage({
    id: 'orderManagement.editCountdownDescription',
  });

  return {
    isLoading,
    showActionBox,
    orderDetail,
    editTitle,
    editDescription,
  };
};

const mapDispatchToProps = {};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(OrderDetailDialogBody),
);
