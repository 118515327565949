import { useFormatMessage } from '../../../../../intl/intl';
import { CoverageStatus } from '../../../../../business/cargo';

export function useRequestLang({
  amount,
  coverageStatus,
  orderId,
}: Props = {}) {
  const messageCategory = 'cargo';
  const messageFormSubcategory = 'form';
  const messageCoverageSubcategory = 'coverage';
  const messageDisclaimerSubcategory = 'disclaimer';
  const messageValidationSubcategory = 'validation';
  const _covStatus = coverageStatus || 'hasCoverage';

  const disclaimerTitleText = useFormatMessage({
    id: `${messageCategory}.${messageDisclaimerSubcategory}.${_covStatus}.title`,
  });

  const disclaimerDescriptionText = useFormatMessage(
    {
      id: `${messageCategory}.${messageDisclaimerSubcategory}.${_covStatus}.description`,
    },
    { amount },
  );

  const pickupPointLabelText = useFormatMessage({
    id: `${messageCategory}.${messageFormSubcategory}.pickupPoint`,
  });

  const userNameLabelText = useFormatMessage({
    id: `${messageCategory}.${messageFormSubcategory}.userName`,
  });

  const phoneNumberLabelText = useFormatMessage({
    id: `${messageCategory}.${messageFormSubcategory}.phoneNumber`,
  });

  const deliveryAddressLabelText = useFormatMessage({
    id: `${messageCategory}.${messageFormSubcategory}.deliveryAddress`,
  });

  const addressDetailLabelText = useFormatMessage({
    id: `${messageCategory}.${messageFormSubcategory}.addressDetail`,
  });

  const totalValueLabelText = useFormatMessage({
    id: `${messageCategory}.${messageFormSubcategory}.totalValue`,
  });

  const backFormButtonText = useFormatMessage({
    id: `${messageCategory}.${messageFormSubcategory}.backButton`,
  });

  const nextFormButtonText = useFormatMessage({
    id: `${messageCategory}.${messageFormSubcategory}.nextButton`,
  });

  const defaultValueAddressRef = useFormatMessage({
    id: `${messageCategory}.${messageFormSubcategory}.defaultAddressRef`,
  });

  const coverageRejectedTitleText = useFormatMessage({
    id: `${messageCategory}.${messageFormSubcategory}.coverageRejectedTitle`,
  });

  const coverageRejectedDescriptionText = useFormatMessage(
    {
      id: `${messageCategory}.${messageFormSubcategory}.coverageRejectedDescription`,
    },
    { orderId },
  );

  const fieldsRequiredText = useFormatMessage({
    id: `${messageCategory}.${messageFormSubcategory}.fieldsRequired`,
  });

  const backCoverageButtonText = useFormatMessage({
    id: `${messageCategory}.${messageCoverageSubcategory}.${_covStatus}.backButton`,
  });

  const nextCoverageButtonText = useFormatMessage({
    id: `${messageCategory}.${messageCoverageSubcategory}.${_covStatus}.nextButton`,
  });

  const coverageMapDisclaimerText = useFormatMessage({
    id: `${messageCategory}.${messageCoverageSubcategory}.mapDisclaimer`,
  });

  const orderSuccessfullyCreatedTitleText = useFormatMessage({
    id: `${messageCategory}.${messageCoverageSubcategory}.orderSuccessfullyCreatedTitle`,
  });

  const orderSuccessfullyCreatedDescriptionText = useFormatMessage(
    {
      id: `${messageCategory}.${messageCoverageSubcategory}.orderSuccessfullyCreatedDescription`,
    },
    { orderId },
  );

  const orderRejectedCreatedTitleText = useFormatMessage({
    id: `${messageCategory}.${messageCoverageSubcategory}.orderRejectedCreatedTitle`,
  });

  const orderRejectedCreatedDescriptionText = useFormatMessage(
    {
      id: `${messageCategory}.${messageCoverageSubcategory}.orderRejectedCreatedDescription`,
    },
    { orderId },
  );

  const maxCharactersValidationText = useFormatMessage({
    id: `${messageCategory}.${messageFormSubcategory}.${messageValidationSubcategory}.maxCharacters`,
  });

  const requiredValidationText = useFormatMessage({
    id: `${messageCategory}.${messageFormSubcategory}.${messageValidationSubcategory}.required`,
  });

  const wrongAddressValidationText = useFormatMessage({
    id: `${messageCategory}.${messageFormSubcategory}.${messageValidationSubcategory}.wrongAddress`,
  });

  return {
    disclaimerTitleText,
    disclaimerDescriptionText,
    pickupPointLabelText,
    userNameLabelText,
    phoneNumberLabelText,
    deliveryAddressLabelText,
    addressDetailLabelText,
    totalValueLabelText,
    defaultValueAddressRef,
    backFormButtonText,
    nextFormButtonText,
    coverageRejectedTitleText,
    coverageRejectedDescriptionText,
    fieldsRequiredText,
    backCoverageButtonText,
    nextCoverageButtonText,
    coverageMapDisclaimerText,
    orderSuccessfullyCreatedTitleText,
    orderSuccessfullyCreatedDescriptionText,
    orderRejectedCreatedTitleText,
    orderRejectedCreatedDescriptionText,
    maxCharactersValidationText,
    requiredValidationText,
    wrongAddressValidationText,
  };
}

interface Props {
  amount?: string;
  isCargoFormRequest?: boolean;
  coverageStatus?: CoverageStatus | string;
  orderId?: number;
}
