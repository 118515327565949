import { useFormatMessage } from '../../../../../intl/intl';

export function useDialogLang({ orderId }: Props = {}) {
  const orderSuccessfullyCancelledTitleText = useFormatMessage({
    id: `cargo.cancelDialog.orderSuccessfullyCancelledTitle`,
  });

  const orderSuccessfullyCancelledDescriptionText = useFormatMessage(
    { id: `cargo.cancelDialog.orderSuccessfullyCancelledDescription` },
    { orderId },
  );

  const orderRejectedCancelledTitleText = useFormatMessage({
    id: `cargo.cancelDialog.orderRejectedCancelledTitle`,
  });

  const orderRejectedCancelledDescriptionText = useFormatMessage(
    { id: `cargo.cancelDialog.orderRejectedCancelledDescription` },
    { orderId },
  );

  return {
    orderSuccessfullyCancelledTitleText,
    orderSuccessfullyCancelledDescriptionText,
    orderRejectedCancelledTitleText,
    orderRejectedCancelledDescriptionText,
  };
}

interface Props {
  orderId?: number;
}
