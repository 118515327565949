import { orderTurboCase } from '../../../business/order/geoqueue';
import { OrderResponseFromStatusV2 } from '../../../business/shared/infra';

export function useTurboNewOrderCard(props: Props) {
  const { order } = props;
  const { isTurboqueue } = orderTurboCase({ order });

  return {
    isTurboqueue,
  };
}

interface Props {
  order: OrderResponseFromStatusV2;
}
