import { CustomAddButton } from '../buttons/custom-add-button';
import { CustomMinusButton } from '../buttons/custom-minus-button';

import StringUtils from '../../../utils/StringUtils';
import useStyles from './styles';

export function CustomMeasureValueSelector(props: Props) {
  const {
    value,
    maxValue,
    minValue,
    measureText,
    onIncrease,
    onDecrease,
  } = props;

  const _classes = useStyles();

  return (
    <div className={_classes.root}>
      <CustomMinusButton disabled={value <= minValue} onClick={onDecrease} />
      <div className={_classes.measureValueContainer}>
        <span className={_classes.value}>{value}</span>
        {!StringUtils.isNullOrEmpty(measureText) && <span>{measureText}</span>}
      </div>
      <CustomAddButton disabled={value >= maxValue} onClick={onIncrease} />
    </div>
  );
}

interface Props {
  value: number;
  minValue: number;
  maxValue: number;
  measureText?: string;
  onDecrease: () => void;
  onIncrease: () => void;
}
