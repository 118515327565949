import { makeStyles } from '@material-ui/core';

import { VARIANT } from '../../shared/consts';

import { green, neutral } from '../../../../shared/styles/colors';
import type { SaturationStateProp } from '../../shared/models';

export default makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem',
    border: `1px solid ${neutral[20]}`,
    borderRadius: '0.5rem',
    cursor: 'pointer',
    gap: '0.5rem',
  },
  status: {
    backgroundColor: green.D2,
    width: '0.375rem',
    height: '0.375rem',
    borderRadius: '50%',
  },
  storeStatus: () => ({
    fontSize: '0.875rem',
    fontWeight: 600,
    color: neutral[100],
  }),
  btn: ({ variant }: SaturationStateProp) => ({
    margin: '0 0.5rem',
    padding: '0.25rem 0.625rem',
    fontWeight: 600,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    boxShadow: 'none',
    borderRadius: '0.5rem',
    color: VARIANT[variant].color,
    backgroundColor: VARIANT[variant].bgColor,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: `${VARIANT[variant].bgColor}9B`,
    },
    '&:disabled': {
      color: VARIANT.deactivated.color,
      backgroundColor: VARIANT[variant].bgColor,
    },
  }),
});
