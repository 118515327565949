import { toLocalISOString } from '../../../../shared/utils/date-time';

import type { Saturation } from '../../../../business/highDemand';

const CACHE_KEY = 'CURRENT_DEMAND_TIMER';
const DEMAND_TIMER_INIT: DemandTimer = {
  ttl: 0,
  severity: 'regular',
  endTime: null,
};

export function useDemandTimer({ isSingleStore }: Props) {
  function getLocalDemandTimer() {
    let data = readDemandTimer();
    if (data === null) data = setNewDemandTimer(DEMAND_TIMER_INIT);
    return data;
  }

  function setNewDemandTimer(data: Pick<DemandTimer, 'severity' | 'ttl'>) {
    const { severity, ttl } = data;
    if (!isSingleStore) return null;
    const ttlInMS = ttl * 60_000;
    const endTime = toLocalISOString(new Date(Date.now() + ttlInMS));
    return saveDemandTimer({ ttl, severity, endTime });
  }

  function getDemandTimer({ variant }: { variant: Saturation }): number {
    const demandTimer = getLocalDemandTimer();
    if (demandTimer === null || demandTimer.endTime === null) return 0;
    if (demandTimer.severity !== variant) {
      if (variant !== 'deactivated') saveDemandTimer(DEMAND_TIMER_INIT);
      return 0;
    }
    if (
      (demandTimer.severity === 'fast' && variant === 'fast')
    ) return 0;
    const endTime = new Date(demandTimer.endTime).getTime();
    const nowTime = Date.now();
    // subtract 1 minute to avoid error synchronisation with server
    if (nowTime > endTime - 60_000) return 0;
    // return timer in seconds as integer
    return Math.floor(new Date(endTime - nowTime).getTime() / 1_000);
  }

  return {
    getDemandTimer,
    setNewDemandTimer,
  };
}

function readDemandTimer(): DemandTimer | null {
  const data = window.localStorage.getItem(CACHE_KEY);
  return !!data ? JSON.parse(data) : null;
}

function saveDemandTimer(data: DemandTimer): DemandTimer {
  window.localStorage.setItem(CACHE_KEY, JSON.stringify(data));
  return data;
}

interface DemandTimer {
  ttl: number; // time to live in minutes
  severity: Saturation;
  endTime: string | null; // 'YYYY-MM-DDTHH:MM:SS' local time
}

interface Props {
  isSingleStore: boolean;
}
