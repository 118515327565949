const title = {
  id: 'errorSuggestingProductDialog.title',
}
const subTitle = {
  id: 'errorSuggestingProductDialog.subTitle',
}
const description = {
  id: 'errorSuggestingProductDialog.description',
}
const closeButtonText = {
  id: 'errorSuggestingProductDialog.closeButtonText',
}

const textIds = {
  description,
  subTitle,
  title,
  closeButtonText,
}

export default textIds
