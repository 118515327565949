// Material UI
import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  mobileTitle: {
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 'bold',
    paddingBottom: 17,
  },
  root: {},
  container: {
    display: 'grid',
    gridTemplateAreas: `"banner banner"
                       "description description"
                       "footer footer"`,
    gridRowGap: 20,
    gridTemplateRows: '1fr',
    [theme.breakpoints.up('md')]: {
      gridRowGap: 6,
    },
  },
  banner: {
    gridArea: 'banner',
    justifySelf: 'center',
  },
  img: {
    imageRendering: '-webkit-optimize-contrast',
    width: 360,
    height: 290,
    [theme.breakpoints.up('sm')]: {
      height: 437,
      width: 600,
    },
    [theme.breakpoints.up('md')]: {
      height: 302,
      width: 737,
    },
  },
  description: {
    gridArea: 'description',
    paddingLeft: 28,
    paddingRight: 28,
    paddingTop: 6,
    paddingBottom: 6,
    fontSize: 14,
    maxHeight: 170,
    height: 170,
    overflowY: 'auto',
    overflowWrap: 'break-word',
    [theme.breakpoints.up('md')]: {
      maxHeight: 78,
      height: 78,
      overflowY: 'auto',
      marginBottom: 10,
    },
  },
  markDownDescription: {
    padding: 0,
    whiteSpace: 'pre-wrap',
  },
}))
