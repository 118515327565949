import React from 'react';
import ReactDOM from 'react-dom';
import history from './history';
import { get } from 'lodash';
import amplitude from './tools/amplitude';
import { PWA_INSTALL_STATUS } from './business/common/browser';
// Services
import AuthService from './services/AuthService';
import IndexedDBService from './services/IndexedDBService';
import * as serviceWorker from './serviceWorker';
import App from './components/app';
import './index.css';

try {
  amplitude.initialize();
  IndexedDBService.initializeDB();
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.register();
  console.log('start rendering');
  ReactDOM.render(<App />, document.getElementById('root'));
} catch (ex) {
  console.error(ex);
  // It could failed when amplitude was not initialized when error ocurred.
  // But it is catched inside the method
  amplitude.logException(ex);
}

window.addEventListener('beforeunload', ev => {
  const isLogin = get(history, 'location.pathname', '') === '/login';

  if (
    process.env.NODE_ENV === 'development' ||
    isLogin ||
    !AuthService.isAuthenticated()
  ) {
    return;
  }
  ev.preventDefault();
  return (ev.returnValue = '');
});

window.addEventListener('beforeinstallprompt', event => {
  // Prevent the mini-infobar from appearing on mobile.
  event.preventDefault();
  window.pwaInstallStatus = PWA_INSTALL_STATUS.not;
  console.info('beforeinstallprompt:', event);
});

window.addEventListener('appinstalled', event => {
  window.pwaInstallStatus = PWA_INSTALL_STATUS.yes;
  console.info('appinstalled', event);
});

const getState = () => {
  if (document.visibilityState === 'hidden') {
    return 'hidden';
  }
  if (document.hasFocus()) {
    return 'active';
  }
  return 'passive';
};

// Stores the initial state using the `getState()` function (defined above).
let state = getState();

// Accepts a next state and, if there's been a state change, logs the
// change to the console. It also updates the `state` value defined above.
const logStateChange = nextState => {
  const prevState = state;
  if (nextState !== prevState) {
    console.log(`State change: ${prevState} >>> ${nextState}`);
    state = nextState;
  }
};

// These lifecycle events can all use the same listener to observe state
// changes (they call the `getState()` function to determine the next state).
['pageshow', 'focus', 'blur', 'visibilitychange', 'resume'].forEach(type => {
  window.addEventListener(type, () => logStateChange(getState()), {
    capture: true,
  });
});

// The next two listeners, on the other hand, can determine the next
// state from the event itself.
window.addEventListener(
  'freeze',
  () => {
    // In the freeze event, the next state is always frozen.
    logStateChange('frozen');
  },
  { capture: true },
);

window.addEventListener(
  'pagehide',
  event => {
    if (event.persisted) {
      // If the event's persisted property is `true` the page is about
      // to enter the Back-Forward Cache, which is also in the frozen state.
      logStateChange('frozen');
    } else {
      // If the event's persisted property is not `true` the page is
      // about to be unloaded.
      logStateChange('terminated');
    }
  },
  { capture: true },
);
