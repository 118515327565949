import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import { HeadsetMic, Tune as TuneIcon } from '@material-ui/icons';

import appStore from '../../../../redux/stores/app.store';
import { useFormatMessage } from '../../../../intl/intl';
import RouterService from '../../../../services/RouterService';
import LocalStorageService from '../../../../services/local-storage-service';

import ConfigIcon from '../../../icons/menu/ConfigIcon';
import CustomHelpCenterIcon from '../../../icons/custom-help-center-icon';
import FoodMenuIcon from '../../../icons/menu/FoodMenuIcon';
import LogoutIcon from '../../../icons/menu/LogoutIcon';
import MarketingIcon from '../../../icons/menu/MarketingIcon';
import MyAccountIcon from '../../../icons/menu/MyAccountIcon';
import OrdersHistoricalIcon from '../../../icons/menu/OrdersHistoricalIcon';
import OrdersManagementIcon from '../../../icons/menu/OrdersManagementIcon';
import RegulrScheduleIcon from '../../../icons/menu/RegularScheduleIcon';
import SalesSummary from '../../../icons/menu/SalesSummary';
import { SideMenuItem } from '../side-menu-item';

import Paths from '../../../../constants/Paths';
import Colors from '../../../../constants/Colors';
import { APP_VERSION } from '../../../../constants/commons';
import SpainFlag from '../../../../assets/images/flags/es.svg';
import BrazilFlag from '../../../../assets/images/flags/br.svg';
import dialogsManagerActions from '../../../../redux/actions/dialogsManager.actions';
import EventActions from '../../../../redux/actions/event-actions/event.actions';

import useStyles from './SideMenuItems.styles';
import { neutral } from '../../../../shared/styles/colors';

const SideMenuItems = ({ onClickLogout, onClose }) => {
  const componentClasses = useStyles();

  const accountTitle = useFormatMessage({ id: 'menu.account' });
  const helpCenterTitle = useFormatMessage({ id: 'globals.helpCenter' });
  const logoutTitle = useFormatMessage({ id: 'menu.logout' });
  const marketingTitle = useFormatMessage({ id: 'menu.marketing' });
  const menuTitle = useFormatMessage({ id: 'menu.menu' });
  const ordersHistoricalTitle = useFormatMessage({
    id: 'menu.ordersHistorical',
  });
  const ordersTitle = useFormatMessage({ id: 'menu.ordersManagement' });
  const salesSumaryTitle = useFormatMessage({ id: 'menu.sales.summary' });
  const settingsTitle = useFormatMessage({ id: 'menu.config' });
  const schedulesTitle = useFormatMessage({ id: 'storesSchedulePage.title' });
  const supportTitle = useFormatMessage({ id: 'globals.support' });
  const storeManager = useFormatMessage({ id: 'globals.storeManager' });

  const ordersPath = RouterService.getOrdersPath();
  const dispatch = useDispatch();
  const partnerId = appStore.getPartnerId();

  const handleClickSupport = useCallback(() => {
    EventActions.onSibeBarMenuSelection(partnerId, 'support');
    dispatch(dialogsManagerActions.openSupportDialog());
    onClose();
  }, [dispatch, onClose, partnerId]);

  const handleClickLanguage = lang => {
    localStorage.setItem('language', lang);
    window.location.reload();
  };

  const handleClickLogOut = () => {
    EventActions.onSibeBarMenuSelection(partnerId, 'logout');
    onClose();
    onClickLogout();
  };

  const getItemsToShow = () => {
    const items = [
      {
        id: 'orders-management',
        label: ordersTitle,
        path: ordersPath,
        icon: <OrdersManagementIcon />,
      },
      {
        id: 'menu',
        label: menuTitle,
        path: Paths.MENU,
        icon: <FoodMenuIcon />,
      },
      {
        id: 'orders-historical',
        label: ordersHistoricalTitle,
        path: Paths.ORDERS_HISTORY,
        icon: <OrdersHistoricalIcon />,
      },
      {
        id: 'sales-summary',
        label: salesSumaryTitle,
        path: Paths.SALES_SUMMARY,
        icon: <SalesSummary />,
      },
      {
        id: 'schedule',
        label: schedulesTitle,
        path: Paths.STORES_SCHEDULES,
        icon: <RegulrScheduleIcon />,
      },
      {
        id: 'marketing',
        label: marketingTitle,
        path: Paths.MARKETING,
        icon: <MarketingIcon />,
      },
      {
        id: 'account',
        label: accountTitle,
        path: Paths.MY_ACCOUNT,
        icon: <MyAccountIcon />,
      },
      {
        id: 'settings',
        label: settingsTitle,
        path: Paths.SETTINGS,
        icon: <ConfigIcon />,
      },
      {
        id: 'storeManager',
        label: storeManager,
        path: Paths.STORE_MANAGEMENT,
        icon: <TuneIcon style={{ color: neutral.D15 }} />,
      },
      {
        id: 'helpCenter',
        label: helpCenterTitle,
        path: Paths.HELP_CENTER,
        icon: <CustomHelpCenterIcon />,
      },
      {
        id: 'support',
        label: supportTitle,
        action: handleClickSupport,
        icon: <HeadsetMic classes={{ root: componentClasses.headSets }} />,
      },
      {
        id: 'close',
        label: logoutTitle,
        action: handleClickLogOut,
        icon: <LogoutIcon />,
      },
    ];

    const filteredMenu = items => {
      const selectItemsFilter = () => {
        const settingsFilter = item => item.id !== 'settings';
        const isIntegrated = appStore.getIfPartnerIsIntegrated();

        if (isIntegrated) {
          return settingsFilter;
        }
        return () => true;
      };
      const selectedFilter = selectItemsFilter();

      return items.filter(selectedFilter);
    };

    return filteredMenu(items);
  };

  const itemsToShow = getItemsToShow();
  return (
    <div className={componentClasses.itemsContainer}>
      {itemsToShow.map((item, index) => {
        return <SideMenuItem key={index} item={item} />;
      })}
      <div className={componentClasses.languageSelector}>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={() => handleClickLanguage('es')}
          classes={{
            root: componentClasses.flagButtonRoot,
          }}
        >
          <img
            src={SpainFlag}
            alt='es'
            className={componentClasses.flagButtonIcon}
          />
        </IconButton>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={() => handleClickLanguage('pt')}
          classes={{
            root: componentClasses.flagButtonRoot,
          }}
        >
          <img
            src={BrazilFlag}
            alt='pt'
            className={componentClasses.flagButtonIcon}
          />
        </IconButton>
        <div className={componentClasses.version}>
          <p>V {APP_VERSION}</p>
        </div>
      </div>
    </div>
  );
};

SideMenuItems.defaultProps = {
  onClickLogout: () => {},
  onClose: () => {},
};

export default SideMenuItems;
