import { makeStyles } from '@material-ui/core';
import Colors from '../../../../constants/Colors';
import { neutral } from '../../../../shared/styles/colors';

export const OrderMgmtAcceptOrderDialogStyles = makeStyles(() => ({
  paper: {
    height: '27.1875rem',
    width: '29rem',
    padding: '0 1.5rem',
    position: 'relative',
  },
  header: {
    height: '2rem',
    borderBottomColor: 'transparent',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  description: {
    fontSize: '1rem',
    fontWeight: 400,
    color: neutral.D16,
  },
  disclaimerContent: {
    background: Colors.WARNING_LIGHT_4,
    borderLeft: `3px solid ${Colors.WARNING}`,
    height: '9.6875rem',
    overflow: 'auto',
  },
  disclaimerText: {
    fontSize: '0.75rem',
    fontWeight: 400,
    color: neutral.D18,
  },
  disclaimerIcon: {
    alignSelf: 'flex-start',
  },
  disclaimerItems: {
    padding: '0 1rem',
  },
  disclaimerItem: {
    fontSize: '0.75rem',
    fontWeight: 700,
    color: neutral.D18,
  },
}));
