import { clsx } from 'clsx';
import { useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import useStyles from './style';

import type { BaseProps } from '../../../interfaces';

export function CustomBanner(props: Props) {
  const {
    title,
    subTitle,
    description,
    backgroundImg,
    classes,
    onClick,
  } = props;

  const theme = useTheme<any>();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const _classes = useStyles({ backgroundImg });
  return (
    <div className={clsx(_classes.root, classes?.root)} onClick={onClick}>
      <div className={_classes.textContainer}>
        <div className={_classes.text}>{title}</div>
        {isMdUp && <div className={_classes.description}>{description}</div>}
        <div className={_classes.link}>{subTitle}</div>
      </div>
    </div>
  );
}

interface Props extends BaseProps {
  title: string;
  subTitle: string;
  description: string;
  backgroundImg: string;
  onClick?: (e: any) => void | Promise<void>;
}
