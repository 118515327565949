import {
  DANGER_TIME_IN_MM,
  WARNING_TIME_IN_MM,
  turboHelper,
} from '../../domain';
import { getDiffFromNow } from '../../../../../shared/utils/date-time';

import type {
  ITurboCase,
  ITurboCaseParams,
  orderCreationTimeStatus,
} from '../../domain';

export function orderTurboCase(params: ITurboCaseParams): ITurboCase {
  const { order } = params;
  const _specialOrder = order?.special_order ?? [];
  const _createdAt = order?.created_at || '';
  const isTurboqueue = turboHelper.isTurboqueue(_specialOrder);

  // TODO: fn name is not following the PascalCase convention
  const _getcreatedAtStatus = (): orderCreationTimeStatus => {
    if (!isTurboqueue || !Boolean(_createdAt)) {
      return '';
    }
    const { min } = getDiffFromNow(_createdAt);
    if (min >= DANGER_TIME_IN_MM) {
      return 'danger';
    }
    if (min >= WARNING_TIME_IN_MM) {
      return 'warning';
    }
    return '';
  };

  return {
    isTurboqueue,
    createdAtStatus: _getcreatedAtStatus(),
  };
}
