import { useSelector } from 'react-redux'

import { useFormatMessage } from '../../../intl/intl'

import { selectors as partnerSelectors } from '../../../redux/partner'

import { storeCtaOpts, storeStatusStatusOpts as SSSOpts } from '../constants'

import type { TpStoreCtaOpts, TpStoreStatusStatus } from '../types'

function useTexts(props: { cta: TpStoreCtaOpts }) {
  const { cta } = props
  return {
    cta: useFormatMessage({ id: storeCtaOpts[cta].textId }),
  }
}

function useIsLoadingActiveOrDeactivate() {
  return useSelector(partnerSelectors.selectIsSwitchStoreStatusLoading)
}

function useOpenNowLabel() {
  const openNowLabel = useFormatMessage({
    id: 'extendClosingHoursDialog.openNow',
  })
  return openNowLabel
}

function useExtendScheduleLabel() {
  const extendScheduleLabel = useFormatMessage({
    id: 'extendClosingHoursDialog.extendSchedule',
  })

  return extendScheduleLabel
}

function useExtendClosingHoursCTALabel(status: TpStoreStatusStatus) {
  const extendScheduleLabel = useExtendScheduleLabel()
  const openNowLabel = useOpenNowLabel()
  return status === SSSOpts.ACTIVE.status ? extendScheduleLabel : openNowLabel
}

function useCanExtendClosingHours(status: TpStoreStatusStatus) {
  return status === SSSOpts.ON_HOLD.status || status === SSSOpts.ACTIVE.status
}

export const hooks = {
  useTexts,
  useIsLoadingActiveOrDeactivate,
  useExtendClosingHoursCTALabel,
  useCanExtendClosingHours,
}
