import { DELIVERY_METHOD_OPTION } from './order.const';

export type OrderId = number;

export type BundleId = string | null | '';

export type DeliveryMethod = typeof DELIVERY_METHOD_OPTION[keyof typeof DELIVERY_METHOD_OPTION];

export type WhimId = number;

export const enum RTStepType {
  searchRTByOrderAccepted = 1,
  searchRT = 2,
  onTheWay = 3,
  inStore = 4,
}
