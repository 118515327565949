import { InfoOutlined } from '@material-ui/icons';
import { Tooltip, Fade } from '@material-ui/core';

import { useFormatMessage } from '../../../../intl/intl';
import makeClasses from './OrdersHistoricalHeader.styles';

export function OrdersHistoricalHeader() {
  const classes = makeClasses();
  const texts = useOrdersHistoricalHeaderLang();
  return (
    <header className={classes.header}>
      <p className={classes.state}>{texts.state}</p>
      <p className={classes.orderId}>{texts.orderId}</p>
      <p className={classes.dateTime}>{texts.dateTime}</p>
      <p className={classes.price}>{texts.price}</p>
      <p className={classes.price}>{texts.priceWithDiscount}</p>
      <p className={classes.rt}>{texts.rt}</p>
      <p className={classes.additionalRT}>
        {texts.additionalRT}
        <Tooltip
          arrow
          TransitionComponent={Fade}
          title={texts.additionalRTTooltip}
          classes={{ tooltipArrow: classes.additionalRTTooltip }}
        >
          <InfoOutlined style={{ fontSize: '1rem' }} />
        </Tooltip>
      </p>
      <p className={classes.qualify}>{texts.qualify}</p>
      <p className={classes.toIconOpen} />
    </header>
  );
}

function useOrdersHistoricalHeaderLang() {
  return {
    state: useFormatMessage({ id: 'orders.historical.header.state' }),
    orderId: useFormatMessage({ id: 'orders.historical.header.orderId' }),
    dateTime: useFormatMessage({ id: 'orders.historical.header.dateTime' }),
    price: useFormatMessage({ id: 'orders.historical.header.price' }),
    priceWithDiscount: useFormatMessage({
      id: 'orders.historical.header.priceWithDiscount',
    }),
    additionalRT: useFormatMessage({ id: 'cargo.addRT.orderHistory.label' }),
    additionalRTTooltip: useFormatMessage({
      id: 'cargo.addRT.orderHistory.tooltip',
    }),
    rt: useFormatMessage({ id: 'orders.historical.header.rt' }),
    qualify: useFormatMessage({ id: 'orders.historical.header.qualify' }),
  };
}
