import { useFormatMessage } from '../../../../../../intl/intl';

export function useMarketplaceDetailCard() {
  const lang = useMarketplaceDetailCardLang();
  return {
    lang,
  };
}

function useMarketplaceDetailCardLang() {
  return {
    title: useFormatMessage({
      id: 'cargo.addRT.behavior.detailMarketplace.title',
    }),
    description: useFormatMessage({
      id: 'cargo.addRT.behavior.detailMarketplace.description',
    }),
  };
}
