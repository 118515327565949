// React
import { useCallback } from 'react';
// External libs
import { useDispatch } from 'react-redux';
// Consts
import { textIds } from './consts';
import { Serverities } from '../../constants/Severities';
// Utils
import stringUtils from '../../utils/StringUtils';
// Tools
import { useFormatMessage } from '../../intl/intl';
// Hooks
import hooks from './hooks';
// Actions
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';
// Components
import CustomDialog from '../common/custom-dialog';
import CustomAlert from '../common/custom-alert/CustomAlert';
import { DiagnosticRule } from './components/diagnostic-rule';
// Styles
import useStyles from './styles';

export function StoreDiagnosticsDialog() {
  const componentClasses = useStyles();

  const okButtonText = useFormatMessage(textIds.okButtonText);
  const title = useFormatMessage(textIds.title);

  const open = hooks.useOpen();
  const alertText = hooks.useAlertMessage();
  const diagnostics = hooks.useDiagnostics();

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  return (
    <CustomDialog
      actions={[
        {
          text: okButtonText,
          onClick: handleClose,
          color: 'primary',
          variant: 'contained',
          fullWidth: false,
        },
      ]}
      classes={{
        childrenContainer: componentClasses.body,
        headerRoot: componentClasses.headerRoot,
        paper: componentClasses.paper,
      }}
      CustomDialogHeaderChildren={''}
      onClose={handleClose}
      onClickBackButton={() => {}}
      open={open}
      title={title}
    >
      {diagnostics.length > 0 && (
        <div className={componentClasses.diagnosticsList}>
          {diagnostics.map((diagnostic, index) => (
            <DiagnosticRule
              key={`diagnostic-rule-${index}`}
              label={diagnostic.message}
              value={diagnostic.value}
            />
          ))}
        </div>
      )}
      {!stringUtils.isNullOrEmpty(alertText) && (
        <CustomAlert type={Serverities.ERROR} text={alertText} />
      )}
    </CustomDialog>
  );
}
