import { makeStyles } from '@material-ui/core';

export default makeStyles({
  root: {
    display: 'flex',
    scrollSnapType: 'x mandatory',
    overflowX: 'scroll',
    scrollBehavior: 'smooth',
    '& > *': {
      width: '100%',
      flexShrink: 0,
      scrollSnapAlign: 'center',
      scrollSnapStop: 'always',
    },
  },
});
