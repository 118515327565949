import { clsx } from 'clsx';

import useStyles from './styles';

import Typography from '../../../components/common/typography';

import type { BaseProps } from '../../../interfaces';

export function Banner(props: Props) {
  const { classes, bannerDescription, backgroundImg } = props;
  const _classes = useStyles({ backgroundImg });
  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <Typography classes={{ root: _classes.text }}>
        {bannerDescription}
      </Typography>
    </div>
  );
}

interface Props extends BaseProps {
  bannerDescription: string;
  backgroundImg: string;
}
