import { useExpiredAtFormatted, useLang } from '../shared/hooks';

import type { ExpiredAt } from '../shared/types';

export function useCheckInCodeRT(props: { expiredAt: ExpiredAt }) {
  const { expiredAt } = props;
  const lang = useLang();
  const expiredAtFormatted = useExpiredAtFormatted(expiredAt);
  return {
    lang,
    expiredAtFormatted,
  };
}
