import { useState } from 'react';

import appStore from '../../../redux/stores/app.store';

import type { UseCheckinCodeStoresData } from '../shared/models';

export function useCheckInCodeRTGlobal(): Output {
  const [open, setOpen] = useState(false);

  // TODO: should come from a business layer case or infrastructure
  const stores = appStore.getStores();
  const storesWithCode = (stores?.filter(s => s?.checkin_code) ?? []).map(
    s => ({
      id: s.id,
      name: s?.name ?? '',
      checkinCode: s?.checkin_code ?? '',
      expiredAt: s?.expired_at ?? '',
    }),
  );

  const withoutStores = stores.length === 0 || storesWithCode.length === 0;
  const isSingleStore = stores.length === 1;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return {
    open,
    withoutStores,
    isSingleStore,
    storesWithCode,
    handleOpen,
    handleClose,
  };
}

interface Output {
  open: boolean;
  withoutStores: boolean;
  isSingleStore: boolean;
  storesWithCode: UseCheckinCodeStoresData[];
  handleOpen: () => void;
  handleClose: () => void;
}
