import React from 'react'
// constant
import Colors from '../../../constants/Colors'

const FailureIcon = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='54'
      height='54'
      viewBox='0 0 54 54'
    >
      <g fill='none' fillRule='evenodd'>
        <path fill={Colors.WHITE} d='M0 0h50v48H0z' />
        <path
          fill={Colors.LAVENDER_BLUSH}
          d='M40.232 36.444c-5 2-4.764-.859-10-4-5-3-15 9-22 3-5.579-4.782-5.579-24 4-24 4.286 0 6 4 14 0 7.216-3.608 10.836-4.718 15.892 4.482 5.341 9.72 1.346 19.222-1.892 20.518z'
        />
        <g fill={Colors.WHITE} stroke={Colors.SECONDARY_COLOR}>
          <path d='M12.5 18.265h26v16h-26zM12.5 18.265h26v-3h-26z' />
        </g>
        <g fill={Colors.LAVENDER_BLUSH} stroke={Colors.SECONDARY_COLOR}>
          <path d='M19.5 21.265v2h16v-2h-16zM19.5 25.265v2h16v-2h-16zM19.5 29.265v2h16v-2h-16zM15.5 21.265h2v2h-2zM15.5 25.265h2v2h-2zM15.5 29.265h2v2h-2z' />
        </g>
        <g transform='translate(31.91 27.856)'>
          <circle
            cx='6.154'
            cy='6.154'
            r='6.154'
            fill={Colors.LAVENDER_BLUSH}
            stroke={Colors.SECONDARY_COLOR}
          />
          <rect
            width='1.25'
            height='7.385'
            x='5.528'
            y='2.659'
            fill={Colors.SECONDARY_COLOR}
            rx='.625'
            transform='rotate(45 6.153 6.351)'
          />
          <rect
            width='1.25'
            height='7.385'
            x='5.528'
            y='2.659'
            fill={Colors.SECONDARY_COLOR}
            rx='.625'
            transform='scale(-1 1) rotate(45 0 -8.504)'
          />
        </g>
      </g>
    </svg>
  )
}

export default FailureIcon
