import { clsx } from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import { useTheme, useMediaQuery } from '@material-ui/core';

import logo from '../../../assets/images/logo.svg';
import useStyles from './RappiLogoAppBar.styles';
import { partnerStateType } from '../../../constants/PartnerStateType';

const getBarColor = (classes, partnerState) => {
  switch (partnerState) {
    case partnerStateType.CLOSED:
      return classes.closedPartnerState;
    case partnerStateType.DEACTIVATED:
      return classes.deactivatedPartnerState;
    case partnerStateType.INACTIVE:
      return classes.deactivatedPartnerState;
    case partnerStateType.OPEN:
      return classes.openPartnerState;
    default:
      return classes.deactivatedPartnerState;
  }
};

function RappiLogoAppBar({ partnerState }) {
  const theme = useTheme();
  const _classes = useStyles();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const partnerStateClass = getBarColor(_classes, partnerState);
  return (
    <AppBar
      color='secondary'
      classes={{ root: clsx(_classes.root, partnerStateClass) }}
    >
      {isLgUp && <img src={logo} alt='logo' className={_classes.logo} />}
    </AppBar>
  );
}

export default RappiLogoAppBar;
