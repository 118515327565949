import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import PendingToAcceptColumn from './PendingToAcceptColumn';

import PartnerTypes from '../../constants/PartnerTypes';

const mapStateToProps = (
  { ordersReducer: { orders: allOrders }, partnerReducer: { profile } },
  { intl: { formatMessage } },
) => {
  const isPartnerIntegrated = PartnerTypes.PARTNERS_INTEGRADO === profile.type;
  const emptyTextId = isPartnerIntegrated
    ? 'pendingToAcceptColumn.integratedEmptyText'
    : 'pendingToAcceptColumn.emptyText';
  const emptyText = formatMessage({ id: emptyTextId });
  const title = formatMessage({ id: 'pendingToAcceptColumn.title' });

  return {
    allOrders,
    emptyText,
    title,
    isPartnerIntegrated,
  };
};

const mapDispatchToProps = {};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(PendingToAcceptColumn),
);
