// External libs
import { createActions } from 'redux-actions';

const {
  closeNotificationsDrawerAction,
  openNotificationsDrawerAction,
} = createActions({
  CLOSE_NOTIFICATIONS_DRAWER_ACTION: () => ({}),
  OPEN_NOTIFICATIONS_DRAWER_ACTION: () => ({}),
});

const close = () => (dispatch) => {
  dispatch(closeNotificationsDrawerAction());
};

const open = () => (dispatch) => {
  dispatch(openNotificationsDrawerAction());
};

const NotificationsDrawerActions = {
  close,
  closeNotificationsDrawerAction,
  open,
  openNotificationsDrawerAction,
};

export default NotificationsDrawerActions;
