// React
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
// Components
import PartnerActivationReminderDialog from './PartnerActivationReminderDialog'
import partnersActions from '../../redux/actions/partners-actions'

const mapStateToProps = ({ partnerReducer }, { intl: { formatMessage } }) => {
  const buttonText = formatMessage({
    id: 'partnerActivationReminderDialog.buttonText',
  })
  const description = formatMessage({
    id: 'partnerActivationReminderDialog.description',
  })
  const title = formatMessage({ id: 'partnerActivationReminderDialog.title' })
  const open = partnerReducer.profile.isPartnerActivationReminderDialogOpened

  return {
    buttonText,
    description,
    open,
    title,
    partnerId: partnerReducer.profile.id,
  }
}

const mapDispatchToProps = {
  onClickAccept: partnersActions.acceptPartnerActivationReminderDialog,
  onClose: partnersActions.closePartnerActivationReminderDialog,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(PartnerActivationReminderDialog),
)
