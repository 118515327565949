// Redux
import { useSelector } from 'react-redux'
// redux
import { selectors as schedulePMFlowDialogSelectors } from '../../../../redux/schedule-PM-flow-dialog'

const useBeingEditedIntervalId = () =>
  useSelector(schedulePMFlowDialogSelectors.selectBeingEditedIntervalId)
const useFormId = () => useSelector(schedulePMFlowDialogSelectors.selectFormId)

const hooks = {
  useBeingEditedIntervalId,
  useFormId,
}

export default hooks
