import { useFormatMessage } from '../../../../../intl/intl';

export const useOrderMgmtUndoChangesDialogLang = () => {
  const messageCategory = 'orderManagement';
  const messageSubcategory = 'undoChangesDialog';

  const undoChangesTitleText = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.titleText`,
  });

  const undoChangesDescriptionText = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.descriptionText`,
  });

  const undoChangesCancelText = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.cancelText`,
  });

  const undoChangesOkText = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.okText`,
  });

  const undoChangesDisclaimer1Text = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.disclaimer1Text`,
  });

  const undoChangesDisclaimer2Text = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.disclaimer2Text`,
  });

  const undoChangesDisclaimer3Text = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.disclaimer3Text`,
  });

  const undoChangesDisclaimer4Text = useFormatMessage({
    id: `${messageCategory}.${messageSubcategory}.disclaimer4Text`,
  });

  const undoChangesDisclaimerTexts = [
    undoChangesDisclaimer1Text,
    undoChangesDisclaimer2Text,
    undoChangesDisclaimer3Text,
    undoChangesDisclaimer4Text,
  ];

  return {
    undoChangesTitleText,
    undoChangesDescriptionText,
    undoChangesCancelText,
    undoChangesOkText,
    undoChangesDisclaimerTexts,
  };
};
