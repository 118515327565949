// React
import { injectIntl } from 'react-intl'
// Redux
import { connect } from 'react-redux'
// Utils
import DateTimeUtils from '../../utils/DateTimeUtils'
// Actions
import PartnerAction from '../../redux/actions/partners-actions'
// Components
import CustomNotificationDrawer from './CustomNotificationDrawer'
import produce from 'immer'

const mapStateToProps = (
  {
    partnerReducer: { notifications },
    ordersReducer: { zoneId },
    appReducer: { language },
  },
  { intl: { formatMessage } },
) => {
  const modifiedNotifications = notifications.map(notification => {
    return produce(notification, notificationDraft => {
      notificationDraft.since = DateTimeUtils.getDiffFromNow(
        notificationDraft.created_at,
        'minutes',
        zoneId,
      )

      notificationDraft.sinceMessage = DateTimeUtils.getMessageFromNow(
        notificationDraft.created_at,
        notificationDraft.since,
        zoneId,
        formatMessage,
        language,
      )
    })
  })

  const lastSeenCreatedAtDate =
    (modifiedNotifications[0] && modifiedNotifications[0].created_at) ||
    new Date()

  return {
    lastSeenCreatedAtDate,
    notifications: modifiedNotifications,
    page: 1,
    perPage: 10,
  }
}
const mapDispatchToProps = {
  getNotifications: PartnerAction.getNotifications,
  notificationPutSeenAndReload: PartnerAction.notificationPutSeenAndReload,
  notificationPutSeenAllAndReload:
    PartnerAction.notificationPutSeenAllAndReload,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(CustomNotificationDrawer),
)
