import { STORE_STATUS_INPUT_DTO } from '../core';
import { highDemandAdapter } from './highDemand.adapter';

import type {
  HighDemandBtnSaturation,
  NumberOfStoresByStatus,
} from '../../domain';
import type { PartnerSaturationDTO, StoreStateDTO } from '../core';

export const highDemandBtnAdapter = {
  toModelSaturation,
  toStoresStatusList,
} as const;

function toModelSaturation(dto: PartnerSaturationDTO): HighDemandBtnSaturation {
  return dto === 'HIDDEN'
    ? 'hidden'
    : highDemandAdapter.toModelSaturation(dto) ?? 'deactivated';
  // the API can send an unknown string to property 'saturation'
}

function toStoresStatusList(dto: StoreStateDTO[]): NumberOfStoresByStatus[] {
  return dto.map(store => ({
    total: store.total,
    status: STORE_STATUS_INPUT_DTO[store.state],
  }));
}
