//react
import React from 'react';
//components
import CustomButton from '../common/buttons/custom-button/CustomButton';
import { analitycsEvent, GACategories, GAActions } from '../../utils/ga-utils';
//style
import useStyles from './PrintingAgentDownloadSetting.styles';

import SilentPrintAgent from '../../assets/batch/RappiAliadosSilentPrinting.bat';

const PrintingAgentDownloadSetting = ({
  agentButton,
  descriptionAgent,
  partnerId,
  titleAgent,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      <div className={classes.flexContainer}>
        <div className={classes.primaryDescriptionFonts}>
          {titleAgent}
          <div className={classes.secondaryDescriptionFonts}>
            {descriptionAgent}
          </div>
        </div>
        <div>
          <CustomButton
            className={classes.buttonContent}
            color='primary'
            text={agentButton}
            variant='contained'
            onClick={() => {
              analitycsEvent(
                GACategories.CONFIGURATIONS,
                GAActions.INSTALL_PLUG_IN,
                `download_printer_agent#partner_id=${partnerId}`,
              );
              window.open(SilentPrintAgent, '_blank');
            }}
          />
        </div>
      </div>
    </div>
  );
};

PrintingAgentDownloadSetting.defaultProps = {
  classes: {},
};
export default PrintingAgentDownloadSetting;
