// React
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
// Actions
import AppActions from '../../redux/actions/app-actions';
// Components
import DeleteSpecialDayDialog from './DeleteSpecialDayDialog';
import deleteSpecialDayDialogActions from '../../redux/actions/deleteSpecialDayDialog.actions';
import storesSchedulesActions from '../../redux/actions/storesSchedules.actions';

const mapStateToProps = (
  {
    partnerReducer,
    storesSchedulesReducer: {
      filter: { selectedStoreId },
      storesSchedules,
    },
    deleteSpecialDayDialogReducer: { open },
  },
  { intl: { formatMessage } },
) => {
  const descriptionSpecialDay = formatMessage({
    id: 'deleteSpecialDayDialog.deleteModalDescription',
  });
  const titleSpecialDay = formatMessage({
    id: 'deleteSpecialDayDialog.deleteModalTitle',
  });
  const cancel = formatMessage({
    id: 'cancel',
  });
  const confirm = formatMessage({
    id: 'globals.confirm',
  });
  return {
    confirm,
    cancel,
    descriptionSpecialDay,
    open,
    selectedStoreId,
    titleSpecialDay,
  };
};

const mapDispatchToProps = {
  openSnackBarError: AppActions.openSnackBarError,
  onClose: deleteSpecialDayDialogActions.closeDeleteSpecialDayDialog,
  onConfirm: storesSchedulesActions.deleteSpecialDay,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DeleteSpecialDayDialog),
);
