import { clsx } from 'clsx';

import { useShowSuccessSection } from './hooks';
import { FailedSection, SuccessSection } from './components';

import useStyles from './styles';

import type { BaseProps } from '../../../../interfaces';

export function TotalDetailSection({ classes = {} }: BaseProps) {
  const _classes = useStyles();
  const showSuccessSection = useShowSuccessSection();
  return (
    <div className={clsx(_classes.root, classes?.root)}>
      {showSuccessSection ? <SuccessSection /> : <FailedSection />}
    </div>
  );
}
