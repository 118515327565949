import { useEffect, useState } from 'react';
import { checkoutRTCase, defaultValueHandoffDetail } from '../../../../business/order/rt';
import { OrderDeliveryDetailAnalytics } from '../../../../business/order/rt/analytics';

export function useDeliveryDetail({ 
  code, 
  orderId, 
  storeId, 
  orderState, 
  isMandatory,
  isInKanban,
  isBagQuantitySet,
  hasDeliveryDetailMessage, 
}) {
  const {
    defaultValue,
    maxQuantityOfPackages,
    status,
    debonceTime,
    updateHandoffDetail,
    getHandoffDetail,
    getV2Orders
  } = checkoutRTCase({ code });
  const [isLoading, setIsLoading] = useState(false);
  const [quantityOfPackages, setQuantityOfPackages] = useState(
    defaultValue.quantityOfPackages,
  );
  const [hasDrinksOutside, setHasDrinksOutside] = useState(defaultValue.hasDrinksOutside);

  useEffect(() => {
    if (orderId && !isInKanban) {
      getHandoffDetail({ orderId });
    }
  }, []);

  useEffect(() => {
    if (!status.isNotReadyToSetData) {
      setQuantityOfPackages(isBagQuantitySet ? defaultValue.quantityOfPackages : defaultValueHandoffDetail.quantityOfPackages);
      setHasDrinksOutside(isBagQuantitySet ? defaultValue.hasDrinksOutside : defaultValueHandoffDetail.hasDrinksOutside);
    }
  }, [defaultValue]);

  useEffect(() => {
    const canNotUpdateBagsAndDrinks =
      ((isBagQuantitySet ? quantityOfPackages === defaultValue.quantityOfPackages : Number.isNaN(quantityOfPackages)) &&
      (isBagQuantitySet ? hasDrinksOutside === defaultValue.hasDrinksOutside : hasDrinksOutside === defaultValueHandoffDetail.hasDrinksOutside)) &&
      status.isNotReadyToUpdateData;

    if (!canNotUpdateBagsAndDrinks && !isInKanban) {
      const delayDebounceFn = setTimeout(() => {
        updateHandoffDetail({
          orderId,
          storeId,
          hasDrinksOutside,
          quantityOfPackages,
        });
        setIsLoading(false);
        OrderDeliveryDetailAnalytics.whenHandOffBagsAndDrinksInput(
          { orderId },
          {
            numberOfBags: quantityOfPackages,
            outsideDrink: hasDrinksOutside ? 'outside' : 'inside',
            partnerOrderState: orderState,
            isMandatory,
            rtWaiting: hasDeliveryDetailMessage,
          },
        );
      }, debonceTime);
      return () => {
        clearTimeout(delayDebounceFn);
      };
    }
  }, [quantityOfPackages, hasDrinksOutside]);

  function handleDownQuantityOfPackages() {
    if (quantityOfPackages === 0 || Number.isNaN(quantityOfPackages)) return;
    if (!isInKanban) setIsLoading(true);
    setQuantityOfPackages(bags => --bags);
  }

  function handleUpQuantityOfPackages() {
    if (quantityOfPackages >= maxQuantityOfPackages) return;
    if (!isInKanban) setIsLoading(true);
    setQuantityOfPackages(bags => Number.isNaN(bags) ? 0 : ++bags);
  }

  function handleHasDrinksOutside(hasDrinksOutside) {
    if (!isInKanban) setIsLoading(true);
    setHasDrinksOutside(hasDrinksOutside);
  }

  function handleRetry() {
    updateHandoffDetail({
      orderId,
      storeId,
      hasDrinksOutside,
      quantityOfPackages,
    });
  }

  function handleSubmit() {
    try {
      updateHandoffDetail({
        orderId,
        storeId,
        hasDrinksOutside,
        quantityOfPackages,
      });
      OrderDeliveryDetailAnalytics.whenHandOffBagsAndDrinksInput(
        { orderId },
        {
          numberOfBags: quantityOfPackages,
          outsideDrink: hasDrinksOutside ? 'outside' : 'inside',
          partnerOrderState: orderState,
          isMandatory,
          rtWaiting: hasDeliveryDetailMessage,
        },
      );
      setTimeout(() => getV2Orders(), 1_000)
    } catch(error) {
      console.error(error)
    }
  }

  return {
    defaultValue,
    maxQuantityOfPackages,
    quantityOfPackages,
    handleDownQuantityOfPackages,
    handleUpQuantityOfPackages,
    hasDrinksOutside,
    handleHasDrinksOutside,
    handleRetry,
    isUpdateLoading: status.updateInprogress || isLoading,
    isUpdateSuccess: status.isUpdated && !isLoading,
    isUpdateDenied: status.isUpdateDenied && !isLoading,
    isGetLoading: status.getInProgress,
    isInputMandatory: isMandatory && !isBagQuantitySet && !isLoading && !status.isUpdated && !status.updateInprogress && !isInKanban,
    isDisabled: hasDrinksOutside === defaultValueHandoffDetail.hasDrinksOutside || quantityOfPackages === defaultValueHandoffDetail.quantityOfPackages,
    handleSubmit
  };
}
