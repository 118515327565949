// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import Colors from '../../constants/Colors';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => {
  return {
    customAlertPaymentMethodRoot: {
      fontSize: 11,
      fontStyle: 'normal !important',
      lineHeight: 'normal !important',
      letterSpacing: 0,
      padding: '10px !important',
      width: '100%',
    },
    description: {
      whiteSpace: 'pre-line',
      fontSize: 11,
    },
    root: {
      color: neutral.D20,
      display: 'grid',
      gridRowGap: 12,
      fontSize: 12,
      lineHeight: 'normal',
    },
    pickUpChipContainer: {
      alignItems: 'center',
    },
    chipRoot: {
      fontSize: '11px !important',
      margin: 0,
      height: 'auto',
      width: 'min-content',
      fontWeight: 'bold',
      padding: '4px 0px 4px 12px',
      justifySelf: 'start',
      backgroundColor: neutral.D5,
      color: neutral.black,
      '&:hover': {
        backgroundColor: neutral.D5,
      },
    },
    payInCashChip: {
      backgroundColor: Colors.DANGER_FONT_COLOR,
      color: Colors.WHITE,
      '&:hover': {
        backgroundColor: Colors.DANGER_FONT_COLOR,
      },
    },
    label: {
      paddingLeft: 9,
      paddingRight: 12,
    },
  };
});
