// Material UI
import { makeStyles } from '@material-ui/core';
import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => ({
  buttonSubmit: {
    fontSize: 16,
  },
  footerButtonSubmit: {
    fontSize: 16,
    gridArea: 'footerButtonSubmit',
    justifySelf: 'end',
  },
  containerButtons: {
    fontSize: 16,
    borderTop: `1px solid ${neutral.D10}`,
    position: 'fixed',
    bottom: 0,
    width: '100%',
    gap: 14,
    padding: 16,
    display: 'grid',
    gridTemplateAreas: `"footerButtonSubmit"
                        "footerRejectButton"`,
  },
}));
