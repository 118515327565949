import { Box, useMediaQuery, useTheme } from '@material-ui/core';

import { Actions } from './Actions';
import { OrderList } from '../list';
import { Coverage, Form } from '../request';
import CustomDialog from '../../../../components/common/custom-dialog/CustomDialog';

import { useDialogData } from './hooks';
import { useCargoLang, useDialog } from '../../hooks';

import makeClasses from './main-dialog.style';

export function MainDialog() {
  const {
    isOpenDialog,
    isCargoFormRequest,
    isCheckingCoverage,
    isCargoOrderList,
  } = useDialogData();

  const { closeDialog } = useDialog();

  const { dialogTitleText } = useCargoLang({
    isCheckingCoverage,
    isCargoOrderList,
  });

  const _classes = makeClasses();
  const theme = useTheme<any>();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <CustomDialog
      classes={{ paper: _classes.dialogPaper, title: _classes.dialogTitle }}
      onClose={closeDialog}
      open={isOpenDialog}
      showBackButton={!isMdUp}
      showCloseButton={isMdUp}
      title={dialogTitleText}
    >
      <Box
        width='100%'
        height='100%'
        padding='0 1rem'
        className={_classes.root}
      >
        <Box
          gridArea='main'
          overflow='auto'
          margin={isCheckingCoverage ? '0' : '1rem 0'}
          padding='0.25rem 0'
        >
          {isCargoFormRequest && <Form />}
          {isCheckingCoverage && <Coverage />}
          {isCargoOrderList && <OrderList />}
        </Box>
        {!isCargoOrderList && <Actions />}
      </Box>
    </CustomDialog>
  );
}
