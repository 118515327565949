import { makeStyles } from '@material-ui/styles';

import { neutral, teal } from '../../../../shared/styles/colors';

export default makeStyles({
  toppingsContainer: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridAutoRows: 'min-content',
    rowGap: '0.25rem',
    paddingTop: '0.25rem',
  },
  toppingCheckboxRoot: {
    padding: '0px',
  },
  root: {
    borderBottom: `1px solid ${neutral['20']}`,
    borderLeft: `3px solid ${teal['50']}`,
    borderRight: `1px solid ${neutral['20']}`,
    borderTop: `1px solid ${neutral['20']}`,
  },
  toppingGroupContainer: {
    borderLeft: `1px solid ${neutral['20']}`,
    padding: '0px 16px 0px 16px',
  },
  toppings: {
    padding: '20px 0px 24px 13px',
  },
  checkboxLine: {
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: 'auto min-content',
    justifyContent: 'space-between',
    fontFamily: 'Poppins',
    fontSize: '1rem',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
  },
  productName: {
    borderBottom: `1px solid ${neutral['20']}`,
    padding: '14px 7px 14px 16px',
  },
  groupTitle: {
    fontSize: '0.75rem',
    color: neutral['50'],
    lineHeight: 'normal',
  },
});
