import { clsx } from 'clsx';

import DateTimeUtils from '../../utils/DateTimeUtils';
import {
  analitycsEvent,
  GACategories,
  GAActions,
} from './../../utils/ga-utils';

import CustomLoader from '../common/custom-loader';
import StoreScheduleIntervals from '../store-schedule-intervals';
import StoreScheduleTableHeader from '../store-schedule-table-header';
import { StoreScheduleTableRow } from '../store-schedule-table-row';

import useStyles from './HolidayStoreScheduleTable.styles';

function HolidayStoreScheduleTable(props) {
  const {
    classes = {},
    day,
    isLoading,
    language,
    onClickAddSchedule,
    onClickDeleteSchedule,
    partnerId,
    schedules = [],
  } = props;

  const _classes = useStyles();

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <CustomLoader open={isLoading} />
      <StoreScheduleTableHeader day={day} />
      {schedules.map((scheduleDay, index) => {
        return (
          <StoreScheduleTableRow
            key={`schedules-${index}`}
            subtitle={scheduleDay.name}
            title={DateTimeUtils.getFormattedNextDate(
              scheduleDay.month,
              scheduleDay.day,
              language,
              'ddd DD MMM',
            )}
          >
            <StoreScheduleIntervals
              dayId={scheduleDay.id}
              showDeleteButton={false}
              onClickAddSchedule={(dayId, endsTime, startsTime) => {
                analitycsEvent(
                  GACategories.SCHEDULES,
                  GAActions.SAVE_HOLIDAY_SCHEDULE,
                  `partner_id=${partnerId}`,
                );
                onClickAddSchedule(dayId, endsTime, startsTime);
              }}
              onClickDeleteSchedule={scheduleId => {
                analitycsEvent(
                  GACategories.SCHEDULES,
                  GAActions.DELETE_HOLIDAY_SCHEDULE,
                  `partner_id=${partnerId}`,
                );
                onClickDeleteSchedule(scheduleDay.id, scheduleId);
              }}
              schedules={scheduleDay.schedules}
            />
          </StoreScheduleTableRow>
        );
      })}
    </div>
  );
}

export default HolidayStoreScheduleTable;
