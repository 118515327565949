// Consts
import urls from '../../constants/Urls'
// Utils
import UrlUtils from '../../utils/url-utils/UrlUtils'
// Services
import http from '../HttpService'
import LocalStorageService from '../local-storage-service'
// Interface
import { CategoriesResponse } from './interfaces'

const getArticle = (articleId: string) => {
  const language = LocalStorageService.getLanguage()
  const microserviceUrl = LocalStorageService.getBaseUrl()
  const replacedUrl = UrlUtils.replaceUrlParams(urls.GET_ARTICLE, {
    articleId,
  })
  return http.get(`${microserviceUrl}${replacedUrl}`, {
    headers: { 'Accept-Language': language },
  })
}

const getHelpCenterTree = () => {
  const language = LocalStorageService.getLanguage()
  const microserviceUrl = LocalStorageService.getBaseUrl()
  const replacedUrl = UrlUtils.replaceUrlParams(urls.GET_HELP_CENTER_TREE)
  return http.get<CategoriesResponse[]>(
    `${microserviceUrl}${replacedUrl}`,

    {
      headers: { 'Accept-Language': language },
    },
  )
}

const getPartnerTypifications = () => {
  const microserviceUrl = LocalStorageService.getBaseUrl()
  return http.get(
    `${microserviceUrl}${urls.SUPPORT_ACTIONS_MANAGER_PARTNER_TYPIFICATIONS}`,
  )
}

export default { getArticle, getHelpCenterTree, getPartnerTypifications }
