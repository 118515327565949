// React
import React from 'react';
// Consts
import Colors from '../../../constants/Colors';
import { neutral } from '../../../shared/styles/colors';

const SalesSummary = ({ selected }) => {
  const stroke = selected ? Colors.SECONDARY_COLOR : neutral.D15;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          stroke={stroke}
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M4.749 17.653l3.593-5.455 2.592 1.38 1.08-5.36 2.278 2.474 1.98-1.922M4.689 17.653h14.624V3H4.688v14.653zM16.273 21L12 17.653 7.727 21M3 3h18'
        />
      </g>
    </svg>
  );
};

export default SalesSummary;
