// Material UI
import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  chatContainer: {
    height: '100%',
  },
  root: {
    padding: '24px',
    fontFamily: 'Poppins',
  },
  title: {
    marginBottom: '24px',
  },
}))
