// Material UI
import { makeStyles } from '@material-ui/core';
// Constant
import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(theme => ({
  flagButtonIcon: {
    height: 17,
    width: 24,
  },
  flagButtonRoot: {
    height: 40,
    width: 40,
  },
  headSets: {
    height: '24px',
    color: neutral.D14,
    width: '24px',
  },
  itemsContainer: {
    marginTop: '30px',
    [theme.breakpoints.up('md')]: {
      marginTop: '39px',
    },
  },
  languageSelector: {
    borderTop: `1px solid ${neutral.D10}`,
    margin: '8px 24px 0',
    paddingTop: 16,
  },
  version: {
    color: neutral.D14,
    display: 'inline-block',
    fontSize: 13,
    float: 'right',
  },
}));
