export const SATURATION = {
  deactivated: 'deactivated',
  low: 'low',
  regular: 'regular',
  high: 'high',
  superHigh: 'superHigh',
  fast: 'fast'
} as const;

export const STORE_STATUS = {
  active: 'active',
  onHold: 'onHold',
  inactive: 'inactive',
  suspended: 'suspended',
} as const;
