import { getIsTimeoutState } from '../../../../business/order-mgmt';
import { TimeoutOrderDisclaimer } from '../../timeout';
import { ToAcceptedDisclaimer, ToWaitDisclaimer } from './components';
import { useOrderDetailDisclaimer } from './hooks';

import type { OrderManagementResponse } from '../../../../business/order-mgmt';

export function OrderDetailDisclaimer({ order }: Props) {
  const { state, updated_at } = order;
  const { acceptedData } = useOrderDetailDisclaimer(order);
  const isTimeoutState = getIsTimeoutState(state);
  return (
    <>
      {state === 'to_wait' && <ToWaitDisclaimer />}
      {state === 'accepted' && (
        <ToAcceptedDisclaimer
          orderChanges={acceptedData.items}
          hasToppings={acceptedData.hasToppings}
        />
      )}
      {isTimeoutState && <TimeoutOrderDisclaimer updateAt={updated_at} />}
    </>
  );
}

interface Props {
  order: OrderManagementResponse;
}
