import { clsx } from 'clsx';
import { useState, useCallback } from 'react';
import { Button, Collapse } from '@material-ui/core';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';

import { useFormatMessage } from '../../../../../../intl/intl';
import hooks from './hooks';

import { CustomKeyValueBox } from '../../../../../common/custom-key-value-box';
import { CustomKeyValueListBox } from '../../../../../common/custom-key-value-list-box';

import useStyles from './styles';

import Typography, {
  Variants,
  FontWeight,
} from '../../../../../common/typography';

import type { BaseProps } from '../../../../../../interfaces';

const hideDetailButtonTextId = {
  id: 'orderDetailDialogTotalDetailSuccessSection.hideDetailButtonText',
};
const showDetailButtonTextId = {
  id: 'orderDetailDialogTotalDetailSuccessSection.showDetailButtonText',
};

export function SuccessSection({ classes = {} }: BaseProps) {
  const hideDetailButtonText = useFormatMessage(hideDetailButtonTextId);
  const showDetailButtonText = useFormatMessage(showDetailButtonTextId);

  const hasDetail = true;
  const [isDetailOpened, setIsDetailOpened] = useState(false);

  const detailButtonText = isDetailOpened
    ? hideDetailButtonText
    : showDetailButtonText;

  const DetailButtonIcon = isDetailOpened ? ExpandMoreIcon : ExpandLessIcon;

  const _classes = useStyles({ hasDetail });

  const totalDetailsList = hooks.useTotalDetailList();

  const totalLabel = hooks.useTotalLabel();
  const totalValue = hooks.useTotalValue();

  const handleClickDetailButton = useCallback(() => {
    setIsDetailOpened(!isDetailOpened);
  }, [isDetailOpened]);

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      {!!totalDetailsList && !!totalDetailsList.length && (
        <>
          <Button
            classes={{
              root: clsx(_classes.detailButtonRoot, classes?.detailButtonRoot),
            }}
            fullWidth
            onClick={handleClickDetailButton}
          >
            <div className={clsx(_classes.detailHeader, classes?.detailHeader)}>
              <Typography
                classes={{
                  root: clsx(
                    _classes.detailHeaderLabel,
                    classes?.detailHeaderLabel,
                  ),
                }}
                fontWeight={FontWeight.SemiBold}
                variant={Variants.Sublabel}
              >
                {detailButtonText}
              </Typography>
              <DetailButtonIcon
                classes={{
                  root: clsx(
                    _classes.detailHeaderIconRoot,
                    classes?.detailHeaderIconRoot,
                  ),
                }}
              />
            </div>
          </Button>
          <Collapse in={isDetailOpened} timeout='auto' unmountOnExit>
            <CustomKeyValueListBox
              classes={{
                root: clsx(
                  _classes.detailsListContainer,
                  classes?.detailsListContainer,
                ),
              }}
              list={totalDetailsList}
              labelPropertyName='text'
              valuePropertyName='value'
            />
          </Collapse>
        </>
      )}
      <CustomKeyValueBox
        classes={{
          label: clsx(classes?.totalLabel),
          root: clsx(_classes.totalContainer, classes?.totalContainer),
          value: clsx(classes?.totalValue),
        }}
        label={totalLabel}
        labelFontWeight={FontWeight.Bold}
        value={totalValue}
        valueVariant={Variants.Subheadline}
        valueFontWeight={FontWeight.Bold}
      />
    </div>
  );
}
