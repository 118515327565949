const ORDERS_KANBAN = '/orders-kanban';
const MENU = '/menu';
const ORDERS_HISTORY = '/orders-history';
const SALES_SUMMARY = '/sales-summary';
const STORES_SCHEDULES = '/schedules';
const MARKETING = '/marketing';
const MY_ACCOUNT = '/my-account';
const SETTINGS = '/settings';
const STORE_MANAGEMENT = '/store-management';
const HELP_CENTER = '/help-center';
const HELP_CENTER_WITH_CATEGORY = `${HELP_CENTER}/:categoryId`;
const HELP_CENTER_WITH_CATEGORY_AND_ARTICLE = `${HELP_CENTER_WITH_CATEGORY}/article/:articleId`;
const ORDERS_MANAGEMENT = '/orders-management';

const getMenuQueryParamsPath = storeId => {
  return `${MENU}?store_id=${storeId}`;
};

const getOrdersHistoricalQueryParamsPath = storeId => {
  return `${ORDERS_HISTORY}?store_id=${storeId}`;
};

const getStoresSchedulesQueryParamsPath = storeId => {
  return `${STORES_SCHEDULES}?store_id=${storeId}`;
};

const getHelpcenterCategoryPath = categoryId => {
  return `${HELP_CENTER}/${categoryId}`;
};

const goToHelpCenterWithCategoryAndArticle = (categoryId, article) => {
  return `${HELP_CENTER}/${categoryId}/article/${article}`;
};

export default {
  ORDERS_KANBAN,
  MENU,
  ORDERS_HISTORY,
  SALES_SUMMARY,
  STORES_SCHEDULES,
  MARKETING,
  MY_ACCOUNT,
  SETTINGS,
  STORE_MANAGEMENT,
  HELP_CENTER,
  HELP_CENTER_WITH_CATEGORY,
  HELP_CENTER_WITH_CATEGORY_AND_ARTICLE,
  ORDERS_MANAGEMENT,
  getMenuQueryParamsPath,
  getOrdersHistoricalQueryParamsPath,
  getStoresSchedulesQueryParamsPath,
  getHelpcenterCategoryPath,
  goToHelpCenterWithCategoryAndArticle,
};
