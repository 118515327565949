import { useFormatMessage } from '../../../../intl/intl';
import { useSaturationStateText } from '../../shared/hooks';

import type { SaturationStateProp } from '../../shared/models';

export function useDemandStatusOptionTexts(props: Props) {
  const { variant, extraTime, isEligibleForFastOperation } = props;

  const time = Math.abs(extraTime);
  const { status: title } = useSaturationStateText({ variant });
  const legend = `highDemand.stateOption.legend.${variant}`;
  const disclaimer =
    variant === 'fast' ? `highDemand.stateOption.disclaimer.${variant}` : '';
  const fastOperationOptions = [
    'highDemand.stateOption.legend.fast.option1',
    'highDemand.stateOption.legend.fast.option2',
    'highDemand.stateOption.legend.fast.option3',
    'highDemand.stateOption.legend.fast.option4',
  ];
  const tooltipTitle = `highDemand.stateOption.tooltip.${
    isEligibleForFastOperation
      ? 'turnOffFastOperation'
      : 'accessToFastOperation'
  }`;

  return {
    title,
    legend: useFormatMessage({ id: legend }, { time }),
    ...(disclaimer && { disclaimer: useFormatMessage({ id: disclaimer }) }),
    fastOperationOptions: fastOperationOptions.map(option =>
      useFormatMessage({ id: option }),
    ),
    tooltipTitle: useFormatMessage({ id: tooltipTitle }),
  };
}

interface Props extends SaturationStateProp {
  extraTime: number;
  isEligibleForFastOperation: boolean;
}
