import { useSelector } from 'react-redux';

import { CargoStateRedux } from './cargo.slice';

type CSProps = { cargo: CargoStateRedux };

export function CargoSelectors() {
  const selectUI = useSelector(({ cargo }: any) => cargo.ui);
  const selectIsDisplayed = useSelector(({ cargo }: any) => cargo.isDisplayed);

  const selectStatus = useSelector(({ cargo }: any) => cargo.status);

  const selectNewOrder = useSelector(({ cargo }: CSProps) => cargo.newOrder);

  const selectCoverageStatus = useSelector(
    ({ cargo }: any) => cargo.coverageStatus,
  );
  const selectStores = useSelector(({ cargo }: any) => cargo.stores);
  const selectOrderId = useSelector(({ cargo }: any) => cargo.selectOrderId);
  const selectShippingCost = useSelector(
    ({ cargo }: any) => cargo.shippingCost,
  );
  const selectOrders = useSelector(({ cargo }: any) => cargo.orders);
  const selectFilteredOrders = useSelector(
    ({ cargo }: any) => cargo.filteredOrders,
  );
  const selectKanbanStatus = useSelector(
    ({ cargo }: any) => cargo.kanbanStatus,
  );
  const selectCancelCostOrder = useSelector(
    ({ cargo }: any) => cargo.cancelCost,
  );
  const selectCancelCostOrderStatus = useSelector(
    ({ cargo }: any) => cargo.cancelCostStatus,
  );
  const selectCancelCargoOrderStatus = useSelector(
    ({ cargo }: any) => cargo.cancelOrderStatus,
  );
  const selectPartnerStores = useSelector(
    ({ partnerReducer }: any) => partnerReducer.profile?.stores,
  );
  const selectLastUpdate = useSelector(({ cargo }: any) => cargo.lastUpdate);
  const selectFilters = useSelector(({ cargo }: any) => cargo.filters);

  return {
    selectIsDisplayed,
    selectStatus,
    selectNewOrder,
    selectCoverageStatus,
    selectUI,
    selectStores,
    selectShippingCost,
    selectOrderId,
    selectOrders,
    selectFilteredOrders,
    selectKanbanStatus,
    selectCancelCostOrder,
    selectCancelCostOrderStatus,
    selectCancelCargoOrderStatus,
    selectPartnerStores,
    selectLastUpdate,
    selectFilters,
  };
}
