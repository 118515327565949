import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';

import CustomDialog from '../../../../../../../../../../components/common/custom-dialog/CustomDialog';
import CustomLoader from '../../../../../../../../../../components/common/custom-loader/CustomLoader';
import CustomButton from '../../../../../../../../../../components/common/buttons/custom-button/CustomButton';

import { useCancelModal } from './hooks';

import {
  Colors,
  Types,
  Variants,
} from '../../../../../../../../../../components/common/buttons/custom-button';
import { ZIndexType } from '../../../../../../../../../../constants/ZIndexType';
import dangerIcon from '../../../../../../../../../../assets/images/icons/dangerIcon.svg';

import useCancelDialogStyles from './cancel-modal.style';

import type { OrderId } from '../../../../../../../../../../business/shared/domain';

export function CancelModal(props: CancelDialogProps) {
  const { orderId, onClose } = props;

  const {
    lang,
    cancelCost,
    isCancelCostLoading,
    isCancelCargoOrderLoading,
    onCancelCargoOrder,
  } = useCancelModal({ orderId, onClose });

  const styles = useCancelDialogStyles();
  const theme = useTheme<any>();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <CustomDialog
      classes={{
        paper: styles.cancelDialogPaper,
        headerRoot: styles.cancelDialogHeader,
      }}
      open={true}
      onClose={onClose}
      onClickBackButton={onClose}
      showBackButton={!isMdUp}
      showCloseButton={isMdUp}
    >
      {isCancelCostLoading ? (
        <CustomLoader
          invisible
          open={isCancelCostLoading}
          zIndexType={ZIndexType.DRAWER}
        />
      ) : (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          padding='0 1rem 3rem'
          width='100%'
          height='100%'
        >
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            marginBottom='1.5rem'
            textAlign='center'
            gridGap='0.5rem'
          >
            <Typography className={styles.cancelDialogTitle}>
              {lang.title}
            </Typography>
            <Typography
              className={styles.cancelDialogDescription}
              dangerouslySetInnerHTML={{ __html: lang.description }}
            />
          </Box>
          <Box
            className={styles.cancelDialogDisclaimer}
            display='flex'
            alignItems='center'
            gridGap='0.5rem'
            padding='0.5rem'
            marginBottom='1rem'
            width='100%'
          >
            <img
              className={styles.cancelDisclaimerIcon}
              alt='danger-icon'
              src={dangerIcon}
            />
            <Typography className={styles.cancelDisclaimerText}>
              {`${lang.disclaimer} $${cancelCost}`}
            </Typography>
          </Box>
          <Box
            display='flex'
            flex={1}
            alignItems='center'
            justifyContent='flex-end'
            gridGap='1rem'
            width='100%'
          >
            <CustomButton
              onClick={onClose}
              type={Types.RESET}
              variant={Variants.TEXT}
              fullWidth={false}
            >
              {lang.backBtn}
            </CustomButton>
            <CustomButton
              onClick={onCancelCargoOrder}
              type={Types.BUTTON}
              variant={Variants.CONTAINED}
              fullWidth={false}
              color={Colors.SECONDARY}
              disabled={isCancelCargoOrderLoading}
            >
              {lang.mainBtn}
            </CustomButton>
          </Box>
        </Box>
      )}
    </CustomDialog>
  );
}

interface CancelDialogProps {
  orderId: OrderId;
  onClose: () => void;
}
