import appStore from '../../redux/stores/app.store';

export function useOrderDetailDialogProductsSection() {
  const { whims = [], productsByCorridor = {} } =
    appStore.getOrderDetailInOrderDetailDialog() ?? {};
  const orderId = appStore.getOrderIdInOrderDetailDialog();
  const isBeingManaged = appStore.isOrderBeingManaged(orderId) ?? false;

  return {
    productsByCorridor,
    whims,
    isBeingManaged,
  };
}
