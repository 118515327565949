import { makeStyles } from '@material-ui/core';
import type { Saturation } from '../../../../business/highDemand';
import { neutral } from '../../../../shared/styles/colors';
import { VARIANT } from '../../shared/consts';

export default makeStyles({
  chip: ({ variant, disabled }: Props) => ({
    borderRadius: '0.25rem',
    backgroundColor: disabled ? neutral['20'] : VARIANT[variant].bgColor,
    color: disabled ? VARIANT.deactivated.color : VARIANT[variant].color,
    fontWeight: 600,
    fontSize: '0.625rem',
    textTransform: 'uppercase',
    width: 'fit-content',
  }),
  disclaimer: {
    fontSize: '0.625rem',
    fontWeight: 600,
  },
});

interface Props {
  variant: Saturation;
  disabled: boolean;
}
