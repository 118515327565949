import { makeStyles } from '@material-ui/core';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(() => ({
  placesSuggestions: (props: any) => ({
    position: 'absolute',
    top: 56,
    right: 0,
    left: 0,
    zIndex: 10,
    backgroundColor: neutral.white,
    border: props?.disabled ? 'none' : `1px solid rgba(0, 0, 0, 0.23)`,
    padding: 0,
  }),
  placeSuggestion: {
    borderBottom: `1px solid rgba(0, 0, 0, 0.23)`,
  },
  disabled: (props: any) => ({
    backgroundColor: props?.disabled ? neutral.D11 : 'none',
  }),
}));
