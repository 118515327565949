// TODO: To move the corresponding stuff to the business layer.
import { useState } from 'react';

import { highDemandAdapter } from '../../../../../business/highDemand/infra/adapters/highDemand.adapter';
import Urls from '../../../../../constants/Urls';
import http from '../../../../../services/HttpService';
import LocalStorageService from '../../../../../services/local-storage-service/LocalStorageService';
import UrlUtils from '../../../../../utils/url-utils/UrlUtils';

const adapterToSaturationDTO = highDemandAdapter.toSaturationDTO;

const FLAGS_INIT_STATE = {
  isError: false,
  isSuccess: false,
  isLoading: false,
};

export function useFetchUpdateDemand() {
  const [flags, setFlags] = useState(FLAGS_INIT_STATE);
  const fnFetch = ({ payload, onSuccess = () => {} }) => {
    setFlags({ ...FLAGS_INIT_STATE, isLoading: true });
    const baseUrl = LocalStorageService.getBaseUrl();
    const uri = UrlUtils.replaceUrlParams(
      Urls.CHANGE_SATURATION_AS_CREATE_DEMAND,
    );
    http
      .post(`${baseUrl}${uri}`, {
        ttl: payload.ttl,
        store_id: payload.storeId,
        next_severity: adapterToSaturationDTO(payload.severity.next),
        current_severity: adapterToSaturationDTO(payload.severity.current),
      })
      .then(() => {
        onSuccess();
        setTimeout(() => {
          setFlags({ ...FLAGS_INIT_STATE, isSuccess: true });
        }, 3_000);
      })
      .catch(() => {
        setTimeout(() => {
          setFlags({ ...FLAGS_INIT_STATE, isError: true });
        }, 1_000);
      });
  };
  const fnFetchEnabledFastOperation = ({ payload, onSuccess = () => {} }) => {
    setFlags({ ...FLAGS_INIT_STATE, isLoading: true })
    const baseUrl  = LocalStorageService.getBaseUrl()
    const uri = UrlUtils.replaceUrlParams(Urls.FAST_OPERATION_TOGGLE_STATUS)
    http
      .patch(`${baseUrl}${uri}`, {
        store_id: payload.storeId,
        enable_fast_operation: payload.enabledFastOperation,
        ...payload?.ttl && { ttl: payload?.ttl }
      })
      .then(() => {
        onSuccess();
        setTimeout(() => {
          setFlags({ ...FLAGS_INIT_STATE, isSuccess: true })
        }, 3_000)
      })
      .catch(() => {
        setTimeout(() => {
          setFlags({ ...FLAGS_INIT_STATE, isError: true })
        }, 1_000)
      })
  }
  return {
    ...flags,
    fnFetch,
    fnFetchEnabledFastOperation
  };
}
