import { makeStyles } from "@material-ui/core";

import { Saturation } from "../../../../../../business/highDemand";
import { VARIANT } from '../../../consts';

export default makeStyles({
  icon: ({ variant, disabled }: Props) => ({
    fill: disabled ? VARIANT.deactivated.color : VARIANT[variant].color
  })
 })

 interface Props {
  variant: Saturation;
  disabled: boolean;
 }