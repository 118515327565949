import { Box } from '@material-ui/core';
import turboLogo from '../../../../../assets/images/logos/logo_turbo.webp';
import { turboTagStyles } from './TurboTag.styles';
import { orderCreationTimeStatus } from '../../../../../business/order/geoqueue';

export function TurboTag(props: Props) {
  const { parentClass, orderCreationStatus = '' } = props;
  const styles = turboTagStyles({ orderCreationStatus });
  return (
    <Box
      className={[styles.container, parentClass].join(' ')}
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <img alt='turbo-tag-logo' src={turboLogo} className={styles.img} />
    </Box>
  );
}

interface Props {
  parentClass: string;
  orderCreationStatus: orderCreationTimeStatus;
}
