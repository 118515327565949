import React from 'react';
//style
import useStyles from './CustomChatInputMessage.style';
import TextField from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Textsms from '@material-ui/icons/Textsms';
import IconButton from '@material-ui/core/IconButton';

const CustomChatInputMessage = ({ onChange, onKeyPress, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.fatherContainer}>
      <div className={classes.senderContainer}>
        <TextField
          className={classes.inputStyle}
          id='input-with-icon-adornment'
          placeholder='Enviar Mensaje...'
          onChange={onChange}
          onKeyPress={onKeyPress}
          startAdornment={
            <InputAdornment position='start'>
              <IconButton className={classes.messageIcon} edge='end'>
                <Textsms />
              </IconButton>
            </InputAdornment>
          }
          value={value}
        />
      </div>
    </div>
  );
};
CustomChatInputMessage.defaultProps = {
  classes: {},
};
export default CustomChatInputMessage;
