import { useSelector } from 'react-redux'
import { useMediaQuery, useTheme } from '@material-ui/core'

import { useFormatMessage } from '../../../intl/intl'

import { selectors as partnerSelectors } from '../../../redux/partner'
import PartnersActions from '../../../redux/actions/partners-actions/partners.actions'

function useTexts({ isToggleOff = true }) {
  const textIdToggle = {
    on: {
      large: 'storeManager.turnOnAllStores',
      short: 'mainLayout.turnOnStoresText',
    },
    off: {
      large: 'storeManager.turnOffAllStores',
      short: 'mainLayout.turnOffStoresText',
    },
  }
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))
  const turnMode = isToggleOff ? 'off' : 'on'
  const breakpointMode = isLarge ? 'large' : 'short'
  const turnTextId = textIdToggle[turnMode][breakpointMode]
  return {
    toggleStores: useFormatMessage({ id: turnTextId }),
  }
}

function usePartnerId(): number {
  return useSelector(partnerSelectors.selectProfile)?.id || 0
}

function useStoreDetails() {
  return useSelector(partnerSelectors.selectStoreStatusDetail)
}

function useActionToTogglePartnerState({ isToggleOff = true }) {
  const actionToTurnOn = PartnersActions.activatePartner
  const actionToTurnOff = PartnersActions.openDeactivatePartnerDialog
  return isToggleOff ? actionToTurnOff : actionToTurnOn
}

export const hooks = {
  useTexts,
  usePartnerId,
  useStoreDetails,
  useActionToTogglePartnerState,
}
