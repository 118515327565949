import { clsx } from 'clsx';

import { CustomStateLabel } from '../../../common/custom-state-label';
import MotorcycleIcon from '../../../../assets/images/icons/onTheWayRT.webp';

import useStyles from './OnTheWayRTStateLabel.styles';

export function OnTheWayRTStateLabel({ classes, ...extraProps }) {
  const _classes = useStyles();
  return (
    <CustomStateLabel
      classes={{
        root: clsx(_classes.root, classes?.root),
        iconRoot: clsx(_classes.iconRoot, classes?.iconRoot),
      }}
      Icon={MotorcycleIcon}
      {...extraProps}
    />
  );
}
