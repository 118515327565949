import React from 'react';
import { hooks } from './hooks';
import useStyles from './SupportWebChatButton.styles';
import type { SupportWebChatButtonProps as Props } from './types';
import { Box, Typography } from '@material-ui/core';

export const SupportWebChatButton = ({ onClick }: Props): JSX.Element => {
  const componentClasses = useStyles();
  const { supportWebChatText } = hooks.useText();

  return (
    <Box
      component='div'
      className={componentClasses.supportWebChatButton}
      onClick={onClick}
    >
      <Typography component='p' className={componentClasses.supportWebChatText}>
        {supportWebChatText}
      </Typography>
    </Box>
  );
};
