import { CustomHeadphonesButton } from '../custom-headphones-button';

import useStyles from './CustomOutlineHeadphonesButton.styles';

export function CustomOutlineHeadphonesButton({ onClick }) {
  const _classes = useStyles();
  return (
    <CustomHeadphonesButton
      classes={{ button: _classes.button, icon: _classes.icon }}
      onClick={onClick}
    />
  );
}
