import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

import { textIds } from './consts';
import { formatCurrency } from '../../utils/CurrencyUtils';
import OrdersUtils from '../../utils/OrdersUtils';
import LocalStorageService from '../../services/local-storage-service';
import { useFormatMessage } from '../../intl/intl';
import { CustomKeyValueBox } from '../common/custom-key-value-box';
import { CustomKeyValueListBox } from '../common/custom-key-value-list-box';
import RappiLogo from '../icons/rappi-logo';
import { useOrderBill } from './hooks';
import { orderBillStyles } from './styles';
import './printPage.css';
import { PrintedChecklist } from './components/printed-checklist';
import { OrderBillProduct } from './components/order-bill-product';
import { TurboTicketLogo } from '../icons/turbo-ticket-logo';
import { turboHelper } from '../../business/order/geoqueue';

const OrderBill = ({
  dateNow,
  hasOrderTotalsFailed,
  onPrint,
  orderDetail,
  orderTotalsErrorMessage,
  products,
  showZipCode,
  showNeighborhood,
  showPrime,
  takeOrderDate,
  totalDetails,
  totalLabel,
  totalValue,
  user,
  showChangeGivenByUser,
  cashDeliveryToUserLabel,
  showDeliverCashForUser,
  cashGivenByUser,
  whims,
  whimTitle,
  qrCode,
  productConfirmationCode,
}) => {
  const _classes = orderBillStyles({ qrCode });

  const acceptanceDateLabel = useFormatMessage(textIds.acceptanceDateLabel);
  const paymentMethodLabel = useFormatMessage(textIds.paymentMethodLabel);
  const deliveryPrimeLabel = useFormatMessage(textIds.deliveryPrimeLabel);
  const printingDateLabel = useFormatMessage(textIds.printingDateLabel);
  const retryOrderTotalsLabel = useFormatMessage(textIds.retryOrderTotalsLabel);
  const userAddressLabel = useFormatMessage(textIds.userAddressLabel);
  const userPhoneLabel = useFormatMessage(textIds.userPhoneLabel);
  const zipCodeLabel = useFormatMessage(textIds.zipCodeLabel);
  const neighborhoodLabel = useFormatMessage(textIds.neighborhoodLabel);
  const deliveryMethodLabel = useFormatMessage(textIds.deliveryMethodLabel);
  const pickupLabel = useFormatMessage(textIds.pickupLabel);
  const deliveryLabel = useFormatMessage(textIds.deliveryLabel);
  const scanQrLabel = useFormatMessage(textIds.scanQrLabel);
  const exclusiveUseLabel = useFormatMessage(textIds.exclusiveUseLabel);
  const deliveryCodeLabel = useFormatMessage(textIds.deliveryCodeLabel);
  const deliveryMethod = orderDetail?.delivery_method;
  const isPickup = OrdersUtils.isPickup(deliveryMethod);
  const showDeliveryMethod =
    OrdersUtils.isMarketPlace(deliveryMethod) ||
    (LocalStorageService.isChile() && deliveryMethod === 'EDENRED');
  const isActivatedChecklist = LocalStorageService.getIsActivatedPrinterChecklist();
  const isActivatedComanda = LocalStorageService.getIsActivatedComanda();

  const storeName = orderDetail?.stores?.[0]?.name || '';
  const storeId = orderDetail?.stores?.[0]?.store_id || 0;
  const _isTurboqueue = turboHelper.isTurboqueue(
    orderDetail?.special_order ?? [],
  );

  const { showCommand, showChecklist } = useOrderBill({
    onPrint,
    orderId: orderDetail?.id,
    storeId,
    createdAt: orderDetail?.created_at,
    isActivatedChecklist,
    isActivatedComanda,
    hasOrderQrCode: !!qrCode,
  });

  return (
    <div className={_classes.root}>
      {showCommand && (
        <>
          <div className={_classes.container}>
            <span>
              {_isTurboqueue && <TurboTicketLogo />}
              {!_isTurboqueue && <RappiLogo />}
            </span>
          </div>
          <div className={_classes.container}>
            <span className={_classes.orderIdBox}>
              <strong>ID: {orderDetail?.id}</strong>
              <br></br>
              <span className={_classes.orderIdBox}>
                <strong>
                  {user?.first_name}&ensp;{user?.last_name}
                </strong>
              </span>
            </span>
          </div>
          <div className={_classes.infoBox}>
            {isPickup && (
              <span className={_classes.fontText}>
                {deliveryMethodLabel}: {pickupLabel}
              </span>
            )}
            {productConfirmationCode && (
              <span className={_classes.fontText}>
                {deliveryCodeLabel}: {productConfirmationCode}
              </span>
            )}
            <span className={_classes.fontText}>PLATAFORMA: WEB</span>
            {!!orderDetail?.cooking_time_started_at && (
              <span className={_classes.fontText}>
                {`${acceptanceDateLabel}: ${takeOrderDate}`}
              </span>
            )}
            <span className={_classes.fontText}>
              {`${printingDateLabel}: ${dateNow}`}
            </span>
            {OrdersUtils.isMarketPlace(deliveryMethod) && (
              <>
                <span className={_classes.fontText}>
                  {`${userPhoneLabel}: ${user?.phone}`}
                </span>
                <span className={_classes.fontText}>
                  {`${userAddressLabel}: ${user?.address}, ${user?.address_description}`}
                </span>
                {showZipCode && (
                  <span className={_classes.fontText}>
                    {`${zipCodeLabel}: ${user?.zip_code ?? ''}`}
                  </span>
                )}
                {showNeighborhood && (
                  <span className={_classes.fontText}>
                    {`${neighborhoodLabel}: ${user?.neighborhood ?? ''}`}
                  </span>
                )}
                {showPrime && (
                  <span className={_classes.fontText}>
                    {`${deliveryPrimeLabel}`}
                  </span>
                )}
              </>
            )}
            {showDeliveryMethod && (
              <span className={_classes.fontText}>
                {`${paymentMethodLabel}: ${orderDetail?.payment_method}`}
              </span>
            )}
            <div className={_classes.container}>
              <span className={_classes.orderId}>
                <strong>{deliveryLabel}</strong>
              </span>
            </div>
          </div>
          <div>
            {products.map((product, index) => {
              return (
                <OrderBillProduct
                  key={`p-${index}`}
                  product={product}
                  index={index}
                  classes={_classes}
                />
              );
            })}
          </div>
          <div>
            {whims && whims.length > 0 && (
              <>
                <div className={_classes.borderBottom}>
                  <br></br>
                  <div className={_classes.fontWhimTitle}>{whimTitle}</div>
                  <br></br>
                  {whims.map(whim => {
                    return (
                      <div
                        key={`w-${whim.id}`}
                        className={_classes.containerWhim}
                      >
                        <span className={_classes.fontText}>{whim.name}</span>
                        <span className={_classes.fontTextWhim}>
                          {formatCurrency(whim.price)}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <br></br>
              </>
            )}
          </div>
          {hasOrderTotalsFailed ? (
            <div className={_classes.orderTotalsErrorMessageContainer}>
              {`${orderTotalsErrorMessage} ${retryOrderTotalsLabel}`}
            </div>
          ) : (
            <div>
              {!!totalDetails && !!totalDetails.length && (
                <CustomKeyValueListBox
                  classes={{
                    customKeyValueBoxRoot: _classes.fontText,
                    root: _classes.totalDetailsRoot,
                  }}
                  labelPropertyName='text'
                  list={totalDetails}
                  valuePropertyName='value'
                />
              )}
              <CustomKeyValueBox
                classes={{ root: _classes.totalContainer }}
                label={`${totalLabel}:`}
                value={totalValue}
              />
              {showChangeGivenByUser && (
                <div className={_classes.containerMostCharge}>
                  <span className={_classes.fontText}>{cashGivenByUser}</span>
                </div>
              )}
              {showDeliverCashForUser && (
                <div className={_classes.containerMostCharge}>
                  <span className={_classes.fontText}>
                    <strong>{cashDeliveryToUserLabel}</strong>
                  </span>
                </div>
              )}
            </div>
          )}
          <h1>
            <div className={_classes.blackLine}>
              <span className={_classes.left}>
                <KeyboardArrowRight />
              </span>
              <span className={_classes.right}>
                <KeyboardArrowLeft />
              </span>
            </div>
          </h1>
          <div className={_classes.cutPage}></div>
          {!!qrCode && (
            <>
              <div className={_classes.qrWrapper}>
                <strong className={_classes.scanQr}>{scanQrLabel}</strong>
                <div className={_classes.qrCodeContainer}>
                  <figure>
                    {/* NOTE: don't add 'alt' attribute to img tag */}
                    <img className={_classes.qrCode} />
                  </figure>
                </div>
                <span className={_classes.exclusiveUse}>
                  {exclusiveUseLabel}
                </span>
              </div>
              <h1>
                <div className={_classes.blackLine}>
                  <span className={_classes.left}>
                    <KeyboardArrowRight />
                  </span>
                  <span className={_classes.right}>
                    <KeyboardArrowLeft />
                  </span>
                </div>
              </h1>
              <div className={_classes.cutPage}></div>
            </>
          )}
        </>
      )}
      {showChecklist && (
        <PrintedChecklist
          products={products}
          orderId={orderDetail?.id}
          storeName={storeName}
          isActivatedComanda={isActivatedComanda}
          userName={`${user.first_name ?? ''} ${user.last_name ?? ''}`.trim()}
        />
      )}
    </div>
  );
};

export default OrderBill;
