// React
import React from 'react';
// Material UI
import { useMediaQuery, useTheme } from '@material-ui/core';
// Styles
import useStyles from './SalesSummaryAdvancedFilterToolbar.styles';
// Components
import CustomAdvancedFilterToolbar from './../common/custom-advanced-filter-toolbar';
import SalesSummaryFilterDrawer from './../sales-summary-filter-drawer';

const SalesSummaryAdvancedFilterToolbar = ({
  classes,
  onClick,
  selectedFilterOptions,
}) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const componentClasses = useStyles();

  return (
    <div>
      <CustomAdvancedFilterToolbar
        classes={{ root: componentClasses.advancedFilterToolbarRoot }}
        onClick={onClick}
        selectedFilterOptions={selectedFilterOptions}
        showAdvancedToolBar={!isMdUp}
      />
      <SalesSummaryFilterDrawer />
    </div>
  );
};

SalesSummaryAdvancedFilterToolbar.defaultProps = {
  classes: {},
  selectedFilterOptions: [],
};

export default SalesSummaryAdvancedFilterToolbar;
