import { BrowserApp } from '../../../../business/common/browser';
import { CONTENT_TYPE } from '../../../../business/common/browser/domain';
import { useFormatMessage } from '../../../../intl/intl';

export function useLang() {
  const { contentType } = BrowserApp();
  const { title, btn, banner } = _texts();

  const dialogTitle =
    contentType === CONTENT_TYPE.browser
      ? title.browserDialog
      : title.extension;

  const btnPrimaryText =
    contentType === CONTENT_TYPE.browser ? btn.browserDialog : btn.extension;
  const bannerText =
    contentType === CONTENT_TYPE.browser ? banner.browser : banner.extension;

  return {
    ..._texts(),
    dialogTitle,
    btnPrimaryText,
    bannerText,
  };
}

export function _texts() {
  return {
    extension: {
      description: useFormatMessage({
        id: 'browser.extension.description',
      }),
    },
    browserDialog: {
      description: useFormatMessage({
        id: 'browser.dialog.description',
      }),
      highlight_1: useFormatMessage({
        id: 'browser.dialog.highlight1',
      }),
      stepText_1: useFormatMessage({
        id: 'browser.dialog.text1',
      }),
      highlight_2: useFormatMessage({
        id: 'browser.dialog.highlight2',
      }),
      stepText_2: useFormatMessage({
        id: 'browser.dialog.text2',
      }),
      highlight_3: useFormatMessage({
        id: 'browser.dialog.highlight3',
      }),
      stepText_3: useFormatMessage({
        id: 'browser.dialog.text3',
      }),
    },
    title: {
      extension: useFormatMessage({
        id: 'browser.extension.title',
      }),
      browserDialog: useFormatMessage({
        id: 'browser.dialog.title',
      }),
    },
    btn: {
      extension: useFormatMessage({
        id: 'browser.extension.btnPrimary',
      }),
      browserDialog: useFormatMessage({
        id: 'browser.dialog.btnPrimary',
      }),
      help: useFormatMessage({
        id: 'browser.dialog.btnHelp',
      }),
    },
    banner: {
      browser: useFormatMessage({
        id: 'browser.dialog.banner',
      }),
      extension: useFormatMessage({
        id: 'browser.extension.banner',
      }),
    },
  };
}
