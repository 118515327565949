import { useFormatMessage } from '../../../../../../../intl/intl';

export function useDropdownContentLang() {
  return {
    title: useFormatMessage({ id: 'cargo.mainBtn.title' }),
    description: useFormatMessage({ id: 'cargo.mainBtn.description' }),
    banner: {
      disabled: {
        p1: useFormatMessage({ id: 'cargo.mainBtn.banner.disabled.p1' }),
        p2: useFormatMessage({ id: 'cargo.mainBtn.banner.disabled.p2' }),
      },
    },
    opt: {
      reqRT: {
        title: useFormatMessage({ id: 'cargo.mainBtn.opt.reqRT.title' }),
        description: useFormatMessage({
          id: 'cargo.mainBtn.opt.reqRT.description',
        }),
      },
      orders: {
        title: useFormatMessage({ id: 'cargo.mainBtn.opt.orders.title' }),
        description: useFormatMessage({
          id: 'cargo.mainBtn.opt.orders.description',
        }),
      },
      howWorks: {
        title: useFormatMessage({ id: 'cargo.mainBtn.opt.howWorks.title' }),
        description: useFormatMessage({
          id: 'cargo.mainBtn.opt.howWorks.description',
        }),
      },
    },
  };
}
