import React from 'react';
import { Box } from '@material-ui/core';
import CustomButton, {
  Colors,
  Variants as ButtonVariants,
} from '../../../../common/buttons/custom-button';
import OrderMgmtStyles from '../../../OrderManagement.style';

export const OrderMgmtActions = ({
  rejectActionText,
  rejectAction,
  undoChangesActionText,
  undoChangesAction,
  continueActionText,
  continueAction,
  isContinueActionDisabled,
  isSendingRequest,
}: Props) => {
  const componentClasses = OrderMgmtStyles();

  return (
    <Box
      gridArea='actions'
      width='100%'
      display='flex'
      alignItems='center'
      justifyContent='flex-end'
      gridGap='0.5rem'
      padding='0 0.5rem'
      margin='1rem 0'
    >
      {false && (
        <CustomButton
          classes={{
            submitButton: componentClasses.customButtonSecondaryRoot,
          }}
          color={Colors.SECONDARY}
          onClick={rejectAction}
          fullWidth={false}
        >
          {rejectActionText}
        </CustomButton>
      )}
      <CustomButton
        color={Colors.PRIMARY}
        onClick={undoChangesAction}
        variant={ButtonVariants.OUTLINED}
        fullWidth={false}
      >
        {undoChangesActionText}
      </CustomButton>
      <CustomButton
        onClick={continueAction}
        variant={ButtonVariants.CONTAINED}
        fullWidth={false}
        disabled={isContinueActionDisabled}
        isLoading={isSendingRequest}
      >
        {continueActionText}
      </CustomButton>
    </Box>
  );
};

interface Props {
  rejectActionText: string;
  rejectAction: React.MouseEventHandler<HTMLImageElement>;
  undoChangesActionText: string;
  undoChangesAction: React.MouseEventHandler<HTMLImageElement>;
  continueActionText: string;
  continueAction: React.MouseEventHandler<HTMLImageElement>;
  isContinueActionDisabled: boolean;
  isSendingRequest: boolean;
}
