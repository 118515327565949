import { makeStyles } from '@material-ui/core';

import Colors from '../../constants/Colors';
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {
    background: neutral.white,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      background: neutral.white,
    },
  },
  box: {
    padding: '3px',
    [theme.breakpoints.up('md')]: {
      border: `1px solid ${neutral.D10}`,
      backgroundColor: neutral.white,
      padding: '24px',
    },
  },
  container: {
    padding: '24px',
    [theme.breakpoints.up('md')]: {},
  },
  corridorName: {
    fontSize: '0.75rem',
    fontWeight: 600,
    color: Colors.PRIMARY_COLOR,
    marginTop: '1rem',
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
  rowBase: {
    fontFamily: 'Poppins Bold',
  },
  orderTotalDetailroot: {
    backgroundColor: 'transparent !important',
  },
  productsByCorridorContainer: {
    overflow: 'auto',
  },
}));
