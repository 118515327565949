import { Box, Typography, Button, CircularProgress } from '@material-ui/core';
import { CloseStoreIcon } from '../../../../shared/assets/icons/close-store-icon';
import { StoreSaturationInfo } from '../../../store-saturation-info';
import { useEmptyStoreStatusTexts } from './useEmptyStoreStatusText';
import makeStyles from './EmptyStoreStatus.style';
import { useEmptyStoreStatus } from './useEmptyStoreStatus';

export function EmptyStoreStatus({ store, isActiveStore, partnerId, onClose }) {
  const classes = makeStyles();
  const { title, subTitle, cta } = useEmptyStoreStatusTexts({
    status: store.status,
    cta: store.cta,
  });
  const { handleCTA, isLoading } = useEmptyStoreStatus({
    cta: store.cta,
    storeId: store.store_id,
    partnerId,
    onClose,
  });

  return (
    <section className={classes.container}>
      <StoreSaturationInfo
        key={`store-${store.store_id}`}
        storeName={store.name}
        variant={store.saturated}
        storeAddress={store.address}
        isActiveStore={isActiveStore}
      />
      <main className={classes.main}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          padding='0 1rem'
        >
          <CloseStoreIcon />
          <Typography className={classes.title}>{title}</Typography>
          <Typography
            className={classes.subTitle}
            dangerouslySetInnerHTML={{ __html: subTitle || '' }}
          />
          <Button
            className={classes.cta}
            variant='contained'
            color='primary'
            onClick={handleCTA}
            fullWidth={false}
          >
            {cta}
            {isLoading && (
              <CircularProgress
                size={16}
                color='inherit'
                className={classes.loader}
              />
            )}
          </Button>
        </Box>
      </main>
    </section>
  );
}
