// Material UI
import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  topMessage: {
    width: 'auto',
    [theme.breakpoints.up('md')]: {
      margin: '24px 24px 0px 24px',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '24px',
    },
    '& a': {
      color: 'inherit',
      fontSize: 'inherit',
      textDecoration: 'none',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxWidth: '100vw',
    [theme.breakpoints.up('md')]: {
      padding: '24px',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}))
