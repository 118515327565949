import { useTLang } from '../../../../../intl/intl';

export function useStartupPrinterDialogLang() {
  return {
    title: {
      part1: useTLang({
        id: 'startupNotifications.printer.dialog.title.part1',
      }),
      part2: useTLang({
        id: 'startupNotifications.printer.dialog.title.part2',
      }),
    },
    content: {
      title: useTLang({
        id: 'startupNotifications.printer.dialog.content.title',
      }),
      p1: useTLang({ id: 'startupNotifications.printer.dialog.content.p1' }),
      p2: useTLang({ id: 'startupNotifications.printer.dialog.content.p2' }),
      p3: useTLang({ id: 'startupNotifications.printer.dialog.content.p3' }),
    },
    aside: {
      note: useTLang({ id: 'startupNotifications.printer.dialog.aside.note' }),
    },
    link: {
      knowMore: useTLang({
        id: 'startupNotifications.printer.dialog.link.knowMore',
      }),
      configPrinter: useTLang({
        id: 'startupNotifications.printer.dialog.link.configPrinter',
      }),
    },
  };
}
