import moment from 'moment-timezone'

import 'moment/locale/es'
import 'moment/locale/pt'

const addDays = (date, days) => {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

const addZero = i => {
  if (i < 10) {
    i = '0' + i
  }
  return i
}

const getDiffFromNow = (date, unitOfTime, tz, precise = false) => {
  const momentDate = moment.tz(date, tz)
  const now = moment.tz(new Date(), tz)
  return now.diff(momentDate, unitOfTime, precise)
}

const getDiffFromNowMinutesSeconds = (date, tz) => {
  const minutesDiff = getDiffFromNow(date, 'minutes', tz, true)
  const secondsRest = (minutesDiff % 1) * 60
  const minutes = Math.trunc(minutesDiff)
  const seconds = Math.trunc(secondsRest)
  return [minutes, seconds]
}

const getDiffLabel = minutes => {
  if (minutes < 60) {
    return `${Math.floor(minutes)} min`
  }
  if (minutes < 120) {
    return `${Math.round(minutes / 60)} hora`
  }
  if (minutes < 1440) {
    return `${Math.round(minutes / 60)} horas`
  }
  if (minutes < 2880) {
    return `${Math.round(minutes / 1440)} dia`
  }
  if (minutes < 10080) {
    return `${Math.round(minutes / 1440)} dias`
  }
  if (minutes < 20160) {
    return `${Math.round(minutes / 10080)} semana`
  }
  if (minutes < 43800) {
    return `${Math.round(minutes / 10080)} semanas`
  }
  return `${Math.floor(minutes)} min`
}

const getMessageFromNow = (date, diff, tz, formatMessage, language) => {
  let message = ''
  const momentDate = moment.tz(date, tz)
  const today = moment.tz(new Date(), tz)
  today.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  const yesterday = moment.tz(new Date(), tz).subtract(1, 'days')
  yesterday.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

  if (diff <= 2) {
    message = formatMessage({ id: 'notification.panel.minute' })
  } else if (diff <= 60) {
    message = formatMessage({ id: 'notification.panel.minutes' }, { x: diff })
  } else if (momentDate > today) {
    message = formatMessage(
      { id: 'notification.panel.today' },
      { x: momentDate.format('HH:mm') },
    )
  } else if (today > momentDate && momentDate > yesterday) {
    message = formatMessage(
      { id: 'notification.panel.yesterday' },
      { x: momentDate.format('HH:mm') },
    )
  } else if (
    yesterday > momentDate &&
    momentDate > moment.tz(new Date(), tz).subtract(1, 'years')
  ) {
    message = formatMessage(
      { id: 'notification.panel.less.year' },
      {
        x: momentDate.format('D'),
        y: momentLocale(momentDate, language).format('MMM'),
        z: momentDate.format('HH:mm'),
      },
    )
  } else {
    message = formatMessage(
      { id: 'notification.panel.more.year' },
      {
        x: momentDate.format('D'),
        y: momentLocale(momentDate, language).format('MMM'),
        z: momentDate.format('YYYY'),
      },
    )
  }

  return message
}

const getMomentNow = tz => {
  return moment.tz(new Date(), tz)
}

const getISONow = tz => {
  return getMomentNow(tz).toISOString()
}

const formatToDate = date => date.format('YYYY-MM-DD')
const formatToTime = date => date.format('HH:mm:ss')

const getDateNowString = tz => formatToDate(getMomentNow(tz))

const getDateTzNow = tz => {
  return getMomentNow(tz).toDate()
}

const getMomentTodayEnd = tz => {
  const now = getMomentNow(tz)
  return now.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
}

const getNowWithAddedMinutes = (minutes, tz) => {
  const now = getMomentNow(tz)
  return now.add(minutes, 'minutes')
}

const getDiffLabelFromMilliseconds = (ms, tz) => {
  var date = moment.tz(ms, tz)
  const minutesDiff = getDiffFromNow(date, 'minutes', tz)
  return getDiffLabel(minutesDiff)
}

const getHourMinutes = date => {
  const h = addZero(date.getHours())
  const m = addZero(date.getMinutes())
  return `${h}:${m}`
}

const momentLocale = (moment, language) => moment.locale(language || 'es')

const currentYear = new Date().getFullYear()

const getLocaleDateString = (date, format, language) => {
  return momentLocale(moment(date), language).format(format)
}
const getFormattedNextDate = (month, day, language, format) => {
  const date = moment([currentYear, month - 1, day])
  const yearValidated =
    date.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
      ? currentYear + 1
      : currentYear
  const formattedDateWithYear = date.year(yearValidated)
  return momentLocale(formattedDateWithYear, language).format(format)
}
export default {
  addDays,
  addZero,
  currentYear,
  formatToDate,
  formatToTime,
  getDateTzNow,
  getDiffFromNow,
  getDiffFromNowMinutesSeconds,
  getDiffLabel,
  getDiffLabelFromMilliseconds,
  getHourMinutes,
  getLocaleDateString,
  getMessageFromNow,
  getMomentNow,
  getISONow,
  getMomentTodayEnd,
  getNowWithAddedMinutes,
  momentLocale,
  getFormattedNextDate,
  getDateNowString,
}
