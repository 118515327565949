import { CircularProgressWithLabel } from '../../../../shared/components';

import { useCookingClock } from './useCookingClock';

import type { CookingClockProps } from './CookingClock.domain';

export function CookingClock(props: CookingClockProps) {
  const { label, value, statusTime } = useCookingClock(props);
  return (
    <CircularProgressWithLabel
      label={label}
      value={value}
      statusTime={statusTime}
    />
  );
}
