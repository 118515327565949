import { clsx } from 'clsx';
import { IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

export function CustomEditButton({ disabled, onClick, classes = {} }) {
  return (
    <IconButton
      aria-label='Edit'
      className={clsx(classes?.button)}
      disabled={disabled}
      onClick={onClick}
    >
      <Edit className={clsx(classes?.icon)} />
    </IconButton>
  );
}
