import { formatMessage, useFormatMessage } from '../../../../intl/intl';
import { formatCurrency } from '../../../../utils/CurrencyUtils';
import StringUtils from '../../../../utils/StringUtils';
import { textIds } from '../../consts';
import { InfoBox } from '../infoBox';
import { useOrderBillProduct } from './useOrderBillProduct';

export function OrderBillProduct({ product, index, classes }: any) {
  const { getGlobalOfferText, hasGlobalOffer } = useOrderBillProduct(
    product?.global_offers,
  );
  const globalOfferRemember = useFormatMessage({
    id: 'globalOffer.remember',
  });
  const replaceTextLabel = useFormatMessage(textIds.replaceTextLabel);
  const commentsLabel = useFormatMessage(textIds.commentsLabel);

  const getToppingGroups = groupedToppings => {
    return groupedToppings.map((toppingCategory, index) => (
      <div key={`tc-${index}`} className={classes.indentForTopping}>
        <span className={classes.fontText}>&ensp;{toppingCategory.name}:</span>
        {toppingCategory.toppings.map((topping, index) => {
          return (
            <div key={`t-${index}`} className={classes.containerTopping}>
              <span className={classes.toppingFont}>
                {topping.units > 1 && (
                  <>
                    -&ensp;{topping.units}X&ensp;
                    {topping.description}
                  </>
                )}
                {topping.units <= 1 && <>-&ensp;{topping.description}</>}
              </span>
              <span className={classes.fontText}>
                {formatCurrency(topping.total_real_price)}
              </span>
            </div>
          );
        })}
      </div>
    ));
  };

  return (
    <div key={`p-${index}`} className={classes.borderBottom}>
      <br />
      <div className={classes.containerProducts}>
        <span className={classes.fontText}>
          <strong>
            {product.units}X&ensp;{product.name}
          </strong>
        </span>
        <span className={classes.fontText}>
          {formatCurrency(product.total_price_product)}
        </span>
      </div>
      <div>{getToppingGroups(product?.groupedToppings)}</div>
      {!StringUtils.isNullOrEmpty(product.replacement_suggestion) ? (
        <>
          <br />
          <InfoBox
            title={replaceTextLabel}
            description={formatMessage(
              {
                id: 'suggestedProductComment.replaceProduct',
              },
              {
                productReplaceName: product.name,
                productSuggested: product.replacement_suggestion,
              },
            )}
          />
        </>
      ) : (
        product.comments && (
          <>
            <br />
            <InfoBox title={commentsLabel} description={product.comments} />
          </>
        )
      )}
      {hasGlobalOffer && (
        <>
          <br />
          <InfoBox
            title={globalOfferRemember}
            description={getGlobalOfferText()}
          />
        </>
      )}
    </div>
  );
}
