import { useSelector } from 'react-redux';

import selectors from '../selectors';
import { selectors as dynAlertSnackbarSelectors } from '../../../redux/dyn-alert-snackbar';

const useButtons = () => {
  return useSelector(selectors.selectButtons);
};

const useMessage = () => {
  return useSelector(dynAlertSnackbarSelectors.selectMessage);
};

const useOnClose = () => {
  return useSelector(selectors.selectOnClose);
};

const useOpen = () => {
  return useSelector(dynAlertSnackbarSelectors.selectOpen);
};

const useStyleRules = () =>
  useSelector(dynAlertSnackbarSelectors.selectStyleRules);

const hooks = {
  useButtons,
  useMessage,
  useOnClose,
  useOpen,
  useStyleRules,
};

export default hooks;
