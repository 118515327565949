import { makeStyles } from '@material-ui/core';

import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => ({
  actionSubmitButton: {
    fontSize: '13px !important',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    padding: '6px 16px',
    textAlign: 'center',
    fontFamily: 'Poppins',
  },
  actionSubmitButtonDefault: {
    color: neutral['50'],
  },
  root: {
    alignItems: 'center',
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gridColumnGap: 24,
    justifyContent: 'right',
    padding: '20px 16px',
    [theme.breakpoints.up('md')]: {
      padding: '20px 24px',
    },
  },
}));
