// Interfaces
import { Option } from '../interfaces';

const getNumberOptions = (
  start: number,
  end: number,
  incrementBy = 1,
): Option[] => {
  const options: Option[] = [];
  for (let i = start; i <= end; i = i + incrementBy) {
    const option = {
      value: i,
      label: i.toString(),
    };

    options.push(option);
  }

  return options;
};

const utils = { getNumberOptions };

export default utils;
