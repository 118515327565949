import moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';

import { BaseSection } from '../base-section';

import hooks from './hooks';
import { useFormatMessage, formatMessage } from '../../../../intl/intl';

import { textIds } from './consts';

export function MarketplaceSection({ isFastOperation = false }) {
  const description = useFormatMessage(textIds.description);
  const title = useFormatMessage(textIds.title);
  const fastOperationText = useFormatMessage(textIds.fastOperationText)

  const [alertText, setAlertText] = useState<string>('');

  const ctToTakeOrder = hooks.useCTToTakeOrder();
  const maxValue = hooks.useMaxValue();
  const minValue = hooks.useMinValue();

  const updateAlertText = useCallback(() => {
    const deliveryTimeMoment = moment().add(ctToTakeOrder, 'minutes');

    const alertTextToSet = formatMessage(textIds.alertText, {
      deliveryTime: deliveryTimeMoment.format('HH:mm'),
    });

    if (alertTextToSet !== alertText) {
      setAlertText(alertTextToSet);
    }
  }, [ctToTakeOrder, alertText]);

  useEffect(() => {
    updateAlertText();
    const displayValueUpdateInterval = setInterval(
      () => updateAlertText(),
      5000,
    );

    return () => {
      clearInterval(displayValueUpdateInterval);
    };
  }, [ctToTakeOrder, updateAlertText]);

  return (
    <BaseSection
      alertText={isFastOperation ? fastOperationText : alertText }
      description={description}
      maxValue={maxValue}
      minValue={minValue}
      title={title}
      showSecondaryAlert={isFastOperation}
    />
  );
}
