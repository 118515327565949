import { useEffect, useState } from 'react';

import {
  DELAY_DEBOUNCE_TIME,
  MAX_NUMBER_OF_ITEM_SUGGESTIONS,
  MAX_NUMBER_OF_SEARCH_ATTEMPTS,
  MIN_NUMBER_OF_ITEM_SUGGESTIONS,
  orderMgmtCurrentScreen,
  orderMgmtFacade,
  orderMgmtSearchFacade,
} from '../../../../../../business/order-mgmt';
import { useOrderManagement } from '../../../../hooks';

import type { OrderMgmtItem } from '../../../../../../business/order-mgmt';
import { OrderMgmtAnalytics } from '../../../../../../business/order-mgmt/analitycs';
import { orderMgmtAdapter } from '../../../../../../business/order-mgmt/infra/adapters';
import ChromeExtensionService from '../../../../../../services/ChromeExtensionService';
import OrdersUtils from '../../../../../../utils/OrdersUtils';

export function useOrderMgmtSearch() {
  const { selectOrders } = orderMgmtFacade();
  const {
    selectIsOpen,
    selectResults,
    selectItemCategoryIndex,
    selectItemCategoryDescription,
    selectStatus,
    selectIsLoading,
    selectIsSuccessful,
    selectIsEmptyResults,
    selectIsRejected,
    selectSearchAttempts,
    onSearchByQuery,
    onSearchItem,
    onClearDialog,
    onOpenOrderMgmtDialog,
    getRecentlySearchedQueries,
    setRecentlySearchedQuery,
    onClearSearch,
  } = orderMgmtSearchFacade();

  const [currentIndexItem, setCurrentIndexItem] = useState(0);
  const [searchedQuery, setSearchedQuery] = useState('');
  const [suggestionItems, setSuggestionItems] = useState<OrderMgmtItem[]>([]);
  const [isCtaBtnDisabled, setIsCtaBtnDisabled] = useState(true);
  const {
    onSetSubstitutionProductItems,
    onSetSubstitutionToppingItems,
    orderId,
    storeId,
    products,
    onSetCurrentScreen,
    hasAnyProductHadSuggestions,
    hasAnyToppingHadSuggestions,
    onOpenUndoChangesDialog,
    onConfirmUndoChangesButton,
  } = useOrderManagement();

  useEffect(() => {
    if (selectIsOpen) {
      ChromeExtensionService.pauseAudio();
    }
  }, [selectIsOpen]);

  useEffect(() => {
    const newOrders = OrdersUtils.filterNew(selectOrders).filter(
      order => !order?.state_order_management,
    );
    if (newOrders.length > 1 && selectIsOpen) {
      ChromeExtensionService.playOrderMgmtNewOrderSound();
    }
  }, [selectOrders]);

  const handleClearSearch = () => {
    handleClear();
    onClearSearch();
    setSuggestionItems([]);
  };

  const handlePrevItem = () => {
    if (currentIndexItem === itemsToReplace.length)
      onSetCurrentScreen(orderMgmtCurrentScreen.Search);
    setCurrentIndexItem(index => --index);
    handleClearSearch();
  };

  const handleNextItem = () => {
    handleAddSubstitutionItems();
    if (currentIndexItem >= itemsToReplace.length - 1) {
      onSetCurrentScreen(orderMgmtCurrentScreen.Resume);
    }
    setCurrentIndexItem(index => ++index);
    handleClearSearch();
  };

  //!: Error when add ItemSuggestion (warn on 'acc')
  const itemsToReplace = products.reduce((acc: any, product) => {
    product.isSelected &&
      acc.push(
        orderMgmtAdapter.toOrderMgmtItemToReplace(
          { ...product, productId: product.id, isProduct: true },
          orderId,
        ),
      );
    (product?.toppings ?? []).forEach(
      topping =>
        topping.isSelected &&
        acc.push(
          orderMgmtAdapter.toOrderMgmtItemToReplace(
            { ...topping, productId: product.id, isProduct: false },
            orderId,
          ),
        ),
    );
    return acc;
  }, []);

  const item = itemsToReplace[currentIndexItem] as any;

  const showBackButton = currentIndexItem !== 0;

  const isBlockBySearchRejected =
    selectSearchAttempts > MAX_NUMBER_OF_SEARCH_ATTEMPTS;

  const handleClear = () => setSearchedQuery('');

  const handleClose = () => {
    if (hasAnyProductHadSuggestions || hasAnyToppingHadSuggestions) {
      onOpenUndoChangesDialog();
      return;
    }
    onClearDialog();
    handleClear();
    setSuggestionItems([]);
    onConfirmUndoChangesButton();
  };

  const handleChange = event => {
    event.preventDefault();
    setSearchedQuery(event.target.value);
  };

  function handleSelectPreviousQuery(opt: string) {
    setSearchedQuery(opt);
  }

  const handleAddSubstitutionItems = () => {
    const itemType = item?.isProduct ? 'product' : 'topping';
    item?.isProduct
      ? onSetSubstitutionProductItems(item, suggestionItems)
      : onSetSubstitutionToppingItems(
          item,
          suggestionItems,
          selectItemCategoryIndex,
          selectItemCategoryDescription,
        );
    setRecentlySearchedQuery({
      query: searchedQuery,
      itemType,
    });
    OrderMgmtAnalytics.whenAddItemBySearch(
      { orderId, storeId },
      { item: itemType, action: 'replace' },
    );
  };

  useEffect(() => {
    if (!searchedQuery) return;
    onSearchItem();
    const delayDebounceFn = setTimeout(() => {
      onSearchByQuery(item, searchedQuery);
    }, DELAY_DEBOUNCE_TIME);
    return () => clearTimeout(delayDebounceFn);
  }, [searchedQuery]);

  useEffect(() => {
    if (
      suggestionItems.length >= MIN_NUMBER_OF_ITEM_SUGGESTIONS &&
      suggestionItems.length <= MAX_NUMBER_OF_ITEM_SUGGESTIONS
    )
      setIsCtaBtnDisabled(false);
    else setIsCtaBtnDisabled(true);
  }, [suggestionItems.length]);

  useEffect(() => {
    if (!selectIsOpen) {
      setCurrentIndexItem(0);
    }
  }, [selectIsOpen]);

  return {
    searchedQuery,
    isCtaBtnDisabled,
    isOpen: selectIsOpen,
    item,
    status: selectStatus,
    results: selectResults,
    isEmptyResults: selectIsEmptyResults && Boolean(searchedQuery),
    isSearcherDisable: isBlockBySearchRejected,
    isLoading: selectIsLoading && Boolean(searchedQuery),
    isSearchRejected: selectIsRejected || isBlockBySearchRejected,
    isSuccessfulWithData: selectIsSuccessful && Boolean(searchedQuery),
    isSearcherDisabled: isBlockBySearchRejected,
    currentIndexItem: currentIndexItem + 1,
    totalItemsToReplace: itemsToReplace.length,
    onClearDialog,
    onOpenOrderMgmtDialog,
    setSearchedQuery,
    setSuggestionItems,
    handleClear,
    handleClose,
    handleChange,
    handleSelectPreviousQuery,
    handleAddSubstitutionItems,
    suggestionItems,
    handlePrevItem,
    handleNextItem,
    showBackButton,
    getRecentlySearchedQueries,
  };
}
