import { propertiesNames } from './Analytics.const';

import type { PartnerId } from '../../../../shared/domain';
import type { ActionType, TestType } from './Analytics.const';
import type {
  ComponentType,
  ContentType,
  PWAInstallStatus,
} from '../../domain';

export interface AnalyticProperties {
  partnerId: number;
  action: ActionType;
  component: ComponentType;
  type: ContentType;
  test: TestType;
}

export interface AnalyticHasChromeExtensionProperties {
  partnerId: PartnerId;
  isInstalled: boolean;
  pwaInstallStatus: PWAInstallStatus;
}

export function handleProperties(props: AnalyticProperties) {
  return {
    [propertiesNames.partnerId]: props.partnerId,
    [propertiesNames.action]: props.action,
    [propertiesNames.component]: props.component,
    [propertiesNames.type]: props.type,
    [propertiesNames.test]: props.test,
  };
}
