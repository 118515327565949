// External Libs
import { get } from 'lodash';
import Dexie from 'dexie';
// Consts
import PartnerOrderStateType from '../constants/PartnerOrderStateType';
// Utils
import IndexedDBUtils from '../utils/IndexedDBUtils';
import PWEX from '../exceptions/models/pwex/PWEX';
import PWEXCodes from '../exceptions/consts/PWEXCodes';

const version1 = 1;
const version2 = 2;
const rappiDBName = 'RappiDB';
let rappiDB;

const initializeDB = async () => {
  rappiDB = new Dexie(rappiDBName);

  rappiDB.version(version1).stores({
    orders: 'id,partner_order_state,beingManaged',
  });
  rappiDB.version(version2).stores({
    orders: 'id,beingManaged,state,partner_order_state,printedDate',
  });
  await rappiDB.open();
};

const closeDB = async () => {
  try {
    await rappiDB.close();
  } catch (ex) {
    console.error(ex);
  }
};

const deleteDB = async () => {
  try {
    await rappiDB.orders.clear();
  } catch (ex) {
    console.error(ex);
  }
};

const clearFCMTable = () => {
  var request = indexedDB.open('fcm_token_details_db');
  request.onerror = function(event) {
    console.log('logout df', event.currentTarget.error);
  };

  request.onsuccess = event => {
    let db = request.result;
    console.log('logout ds');
    var transaction = db.transaction(['fcm_token_object_Store'], 'readwrite');
    var objectStore = transaction.objectStore('fcm_token_object_Store');
    objectStore.clear();
  };
};

const deleteOrderById = async orderId => {
  try {
    await rappiDB.orders
      .where('id')
      .equals(+orderId)
      .delete();
  } catch (ex) {
    console.error(ex);
  }
};

const getCanceledOrders = async () => {
  try {
    const canceledOrders = await rappiDB.orders
      .where('state')
      .equalsIgnoreCase(PartnerOrderStateType.CANCEL_APP)
      .toArray();

    return canceledOrders;
  } catch (ex) {
    const PWEXCode = PWEXCodes.PWEX8;
    const exResult = new PWEX(PWEXCode, null, null, ex);
    throw exResult;
  }
};

const getOrderById = async orderId => {
  try {
    return await rappiDB.orders.get({ id: +orderId });
  } catch (ex) {
    console.error(ex);
    return null;
  }
};

const getPrintedOrders = async () => {
  try {
    const result = await rappiDB.orders
      .where('printedDate')
      .notEqual('')
      .toArray();

    return {
      hasFailed: false,
      data: result,
    };
  } catch (ex) {
    return {
      hasFailed: true,
      ex,
    };
  }
};

const getPrintedOrderIds = async () => {
  try {
    const getPrintedOrdersResult = await getPrintedOrders();

    if (get(getPrintedOrdersResult, 'hasFailed', true)) {
      return getPrintedOrdersResult;
    }

    const printedOrders = getPrintedOrdersResult.data;

    if (!printedOrders || !printedOrders.length) {
      return {
        hasFailed: false,
        data: [],
      };
    }

    const printedOrderIds = printedOrders.map(order => order.id);

    return {
      hasFailed: false,
      data: printedOrderIds,
    };
  } catch (ex) {
    return {
      hasFailed: true,
      ex,
    };
  }
};

const saveBulkOrders = async orders => {
  if (!rappiDB) {
    console.error(`Orders couldn't be saved. Database not initialized.`);
    return;
  }
  rappiDB
    .transaction('rw', rappiDB.orders, async () => {
      await rappiDB.orders.bulkPut(orders);
    })
    .catch(e => {
      // log any errors
      console.log(e.stack || e);
    });
};

const saveCanceledOrder = async orderId => {
  if (!rappiDB) {
    console.error(
      `canceled order ${orderId} couldn't be saved. Database not initialized.`,
    );
    return;
  }

  rappiDB
    .transaction('rw', rappiDB.orders, async () => {
      await rappiDB.orders.put({
        id: +orderId,
        beingManaged: true,
        state: PartnerOrderStateType.CANCEL_APP,
        partner_order_state: PartnerOrderStateType.CANCEL_APP,
        printedDate: '',
      });
    })
    .catch(e => {
      // log any errors
      console.log(e.stack || e);
    });
};

const savePrintedOrder = async (order, tz) => {
  if (!order) {
    return;
  }

  if (!rappiDB) {
    console.error(
      `printed order ${order.id} couldn't be saved. Database not initialized.`,
    );
    return;
  }

  try {
    const orderToSave = await IndexedDBUtils.getPrintedOrderToSave(order, tz);
    await rappiDB.transaction('rw', rappiDB.orders, async () => {
      await rappiDB.orders.put(orderToSave);
    });
  } catch (ex) {
    // log any errors
    console.log(ex.stack || ex);
  }
};

const setBulkPrintedOrders = async (orders, tz) => {
  if (!rappiDB) {
    console.error(`printed orders couldn't be set. Database not initialized.`);
    return;
  }

  if (!orders || !orders.length) {
    return;
  }

  try {
    const ordersToSave = IndexedDBUtils.getPrintedOrdersToSave(orders, tz);
    await rappiDB.transaction('rw', rappiDB.orders, async () => {
      await rappiDB.orders.bulkPut(ordersToSave);
    });
  } catch (ex) {
    // log any errors
    console.log(ex.stack || ex);
  }
};

const clearPrintedOrders = async () => {
  if (!rappiDB) {
    console.error(
      `printed orders couldn't be cleaned. Database not initialized.`,
    );
    return;
  }

  try {
    await rappiDB.transaction('rw', rappiDB.orders, async () => {
      const orders = await rappiDB.orders.toArray();
      const orderIdsToDelete = IndexedDBUtils.filterOrderIdsToDelete(orders);
      await rappiDB.orders.bulkDelete(orderIdsToDelete);
    });
  } catch (ex) {
    // log any errors
    console.log(ex.stack || ex);
  }
};

const IndexedDBService = {
  clearFCMTable,
  clearPrintedOrders,
  closeDB,
  deleteDB,
  deleteOrderById,
  getCanceledOrders,
  getOrderById,
  getPrintedOrderIds,
  initializeDB,
  saveBulkOrders,
  saveCanceledOrder,
  savePrintedOrder,
  setBulkPrintedOrders,
};

export default IndexedDBService;
