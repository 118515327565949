import { clsx } from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { ButtonBase, FormControlLabel, Switch } from '@material-ui/core';

import CustomLoader from '../custom-loader';

import useStyles from './CustomSwitch.styles';
import Colors from '../../../constants/Colors';
import MuiColors from '../../../constants/muiColors';
import { neutral } from '../../../shared/styles/colors';

function CustomSwitch(props) {
  const {
    classes = {},
    checked = false,
    disabled = false,
    isLoading = false,
    label = '',
    loaderSize = 24,
    onClick = () => {},
    onChange = () => {},
    value = false,
    invertColors = false,
  } = props;

  const [primaryColor, setPrimaryColor] = useState(Colors.PRIMARY_COLOR);
  const [secondaryColor, setSecondaryColor] = useState(neutral.D15);

  const _classes = useStyles({
    checked,
    label,
    secondaryColor,
    primaryColor,
  });

  const [isLoadingLocal, setIsLoadingLocal] = useState(isLoading);
  const _isMounted = useRef(true); // Initial value _isMounted = true

  useEffect(() => {
    setPrimaryColor(invertColors ? neutral.D15 : Colors.PRIMARY_COLOR);
    setSecondaryColor(invertColors ? Colors.PRIMARY_COLOR : neutral.D15);
  }, [invertColors]);

  useEffect(() => {
    setIsLoadingLocal(isLoading);
  }, [isLoading]);

  useEffect(() => {
    return () => {
      // ComponentWillUnmount
      _isMounted.current = false;
    };
  }, []);

  return (
    <ButtonBase disableRipple classes={{ root: clsx(classes?.root) }}>
      <CustomLoader invisible open={isLoadingLocal} size={loaderSize} />
      <FormControlLabel
        classes={{
          root: clsx(
            _classes.formControlLabelRoot,
            classes?.formControlLabelRoot,
          ),
          label: clsx(_classes.label, classes?.label),
        }}
        control={
          <Switch
            classes={{
              switchBase: clsx(_classes.control, classes?.control),
              thumb: clsx(_classes.switchThumb, classes?.switchThumb),
              track: clsx(_classes.controlTrack, classes?.controlTrack),
            }}
            checked={checked}
            color={invertColors ? MuiColors.SECONDARY : MuiColors.PRIMARY}
            disabled={disabled}
            onClick={onClick}
            value={value}
          />
        }
        label={label}
        onChange={async e => {
          try {
            setIsLoadingLocal(true);
            await onChange(e);
          } catch (ex) {
            console.error(ex);
          }

          if (_isMounted.current) {
            setIsLoadingLocal(false);
          }
        }}
        value={value}
      />
    </ButtonBase>
  );
}

export default CustomSwitch;
