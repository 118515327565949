// Material UI
import { makeStyles } from '@material-ui/core'
import Colors from '../../../../constants/Colors'

export default makeStyles(theme => ({
  paper: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      position: 'unset',
    },
  },
  root: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 230,
      width: 230,
    },
  },
  treeViewRoot: {
    padding: '36px 0px 0px 0px',
    width: '100%',
  },
  button: {},
  submitButton: {
    fontFamily: 'Poppins',
    fontWeight: 100,
    color: Colors.BLACK,
    fontSize: 13,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))
