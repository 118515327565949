// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {
    background: neutral.white,
    boxShadow: `0 -1px 0 0 ${neutral.D10}`,
  },
}));
