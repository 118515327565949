import { makeStyles } from '@material-ui/core';
import Colors from '../../../../../../../constants/Colors';
import { neutral } from '../../../../../../../shared/styles/colors';

export default makeStyles((theme: any) => ({
  suggestionsContainer: {
    backgroundColor: Colors.WARNING_LIGHT_5,
    borderLeft: `3px solid ${Colors.WARNING_FONT_COLOR}`,
  },
  suggestionDisclaimer: {
    color: `${neutral.D18}`,
    fontWeight: 600,
    fontSize: '1rem',
  },
  suggestionUnits: {
    color: neutral.D16,
    fontWeight: 500,
    fontSize: '0.875rem',
  },
  suggestionName: {
    color: neutral.D16,
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  suggestionPrice: {
    color: `${neutral.D16}`,
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  disclaimerContent: {
    background: Colors.WARNING_LIGHT_4,
    borderTop: `1px solid ${neutral.D10}`,
    paddingTop: '0.5rem',
  },
  disclaimerTitle: {
    color: neutral.D18,
    fontSize: '0.875rem',
    fontWeight: 600,
    marginLeft: '0.5rem',
    paddingBottom: '0.5rem',
    borderBottom: `1px solid ${neutral.D10}`,
  },
  disclaimerIcon: {
    alignSelf: 'flex-start',
  },
  disclaimerDescription: {
    fontSize: '0.75rem',
    padding: '0',
  },
}));
