// React
import React, { useEffect, useState } from 'react';
import {
  Route,
  Routes,
  unstable_HistoryRouter as Router,
  Navigate,
} from 'react-router-dom';
import history from '../../history';
// Consts
import Paths from '../../constants/Paths';
// Services
import AuthService from './../../services/AuthService';
import OrderProductsProcessService from '../../services/OrderProductsProcessService';
// Components
import AllowNotificationsDialog from '../allow-notifications-dialog';
import { AccountProfile } from '../account-profile';
import CustomSnackbar from '../common/custom-snackbar/CustomSnackbar';
import ExternalAuthPage from '../external-auth-page';
import { HelpCenterPage } from '../help-center-page';
import { LoginPage } from '../login-page';
import MarketingPage from '../marketing-page';
import { MenuPage } from '../menu-page';
import NotificationPermissionsType from '../../constants/NotificationPermissionsType';
import OrdersKanbanPage from '../orders-kanban-page';
import OrdersHistorical from '../orders-historical';
import { SalesSummary } from '../sales-summary';
import SettingsProfile from '../settings-profile';
import { StoresSchedulesPage } from '../stores-schedules-page';
import { SuspendedStoreAlertSnackbar } from '../suspended-store-alert-snackbar';
import { StoreManagerPage } from '../store-manager-page';
import { OnBoardingDialog } from '../onboarding-dialog';

const checkAuthentication = Page => {
  if (!AuthService.isAuthenticated()) {
    return <Navigate to='/login' />;
  }
  return (
    <AllowNotificationsDialog>
      <Page auth={AuthService} />
    </AllowNotificationsDialog>
  );
};

const checkLogin = () => {
  return AuthService.isAuthenticated() ? (
    <Navigate to={Paths.ORDERS_KANBAN} />
  ) : (
    <LoginPage auth={AuthService} />
  );
};

const handleExternalAuth = props => {
  return AuthService.isAuthenticated() ? (
    <Navigate to={Paths.ORDERS_KANBAN} />
  ) : (
    <ExternalAuthPage />
  );
};

const Routing = ({
  snackBar,
  startAppUp,
  closeSnackBar,
  intl: { formatMessage },
}) => {
  const [isNotificationAllowed, setIsNotificationAllowed] = useState(
    window.Notification.permission === NotificationPermissionsType.GRANTED,
  );

  OrderProductsProcessService.setFormatMessage(formatMessage);

  const handleSnackBarClose = () => {
    closeSnackBar();
  };

  useEffect(() => {
    let intervalId = undefined;
    if (isNotificationAllowed === false) {
      intervalId = setInterval(() => {
        if (
          window.Notification.permission === NotificationPermissionsType.GRANTED
        ) {
          setIsNotificationAllowed(true);
          clearInterval(intervalId);
        }
      }, 1_000);
    }
    startAppUp();
    return () => {
      clearInterval(intervalId);
    };
  }, [isNotificationAllowed]);

  return (
    <>
      <Router history={history}>
        <Routes>
          <Route path='/login' element={checkLogin()} />
          <Route path='auth' element={handleExternalAuth()} />
          <Route
            path={Paths.ORDERS_KANBAN}
            element={checkAuthentication(OrdersKanbanPage)}
          />
          <Route
            path={Paths.ORDERS_HISTORY}
            element={checkAuthentication(OrdersHistorical)}
          />
          <Route
            path={Paths.SALES_SUMMARY}
            element={checkAuthentication(SalesSummary)}
          />
          <Route path={Paths.MENU} element={checkAuthentication(MenuPage)} />
          <Route
            path={Paths.MY_ACCOUNT}
            element={checkAuthentication(AccountProfile)}
          />
          <Route
            path={`${Paths.STORES_SCHEDULES}/:id`}
            element={checkAuthentication(StoresSchedulesPage)}
          />
          <Route
            path={Paths.STORES_SCHEDULES}
            element={checkAuthentication(StoresSchedulesPage)}
          />
          <Route
            path={Paths.MARKETING}
            element={checkAuthentication(MarketingPage)}
          />
          <Route
            path={Paths.SETTINGS}
            element={checkAuthentication(SettingsProfile)}
          />
          <Route
            path={Paths.STORE_MANAGEMENT}
            element={checkAuthentication(StoreManagerPage)}
          />
          <Route
            path={Paths.HELP_CENTER_WITH_CATEGORY_AND_ARTICLE}
            element={checkAuthentication(HelpCenterPage)}
          />
          <Route
            path={Paths.HELP_CENTER_WITH_CATEGORY}
            element={checkAuthentication(HelpCenterPage)}
          />
          <Route
            path={Paths.HELP_CENTER}
            element={checkAuthentication(HelpCenterPage)}
          />
          <Route path='*' element={<Navigate to={Paths.ORDERS_KANBAN} />} />
        </Routes>
      </Router>
      <SuspendedStoreAlertSnackbar />
      <CustomSnackbar
        open={snackBar.open}
        message={snackBar.message}
        variant={snackBar.variant}
        onClose={handleSnackBarClose}
        title={snackBar.title}
        autoHideDuration={snackBar.autoHideDuration}
      />
      <OnBoardingDialog />
    </>
  );
};

Routing.defaultProps = {};

export default Routing;
