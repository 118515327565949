// Redux
import { createAction } from '@reduxjs/toolkit';
// Interfaces
import { Button } from '../interfaces';
import { StyleRules } from '../../../components/common/custom-alert-snackbar/components/custom-base-alert-snackbar/interfaces/styleRules';

const closeAction = createAction('dynAlertSnackbar/close');

const closeAllAction = createAction('dynAlertSnackbar/closeAll');

const hideAction = createAction('dynAlertSnackbar/hide');

const openAction = createAction<{
  id: string | null;
  buttons: Button[];
  message?: string | null;
  stylesRules?: StyleRules;
}>('dynAlertSnackbar/open');

const openCurrentAction = createAction('dynAlertSnackbar/openCurrent');

const hide = () => (dispatch: any) => {
  dispatch(hideAction());
};

const close = () => (dispatch: any) => {
  dispatch(closeAction());
  dispatch(actions.openCurrent());
};

const closeAll = () => (dispatch: any) => {
  dispatch(closeAllAction());
};

const open = (
  id: string | null,
  buttons: Button[],
  stylesRules?: StyleRules,
  message?: string | null,
) => (dispatch: any) => {
  dispatch(openAction({ id, buttons, message, stylesRules }));
};

const openCurrent = () => (dispatch: any) => {
  dispatch(openCurrentAction());
};

const actions = {
  close,
  closeAction,
  closeAll,
  closeAllAction,
  hide,
  hideAction,
  open,
  openAction,
  openCurrent,
  openCurrentAction,
};

export default actions;
