import { clsx } from 'clsx';

import BlueLupeIcon from '../icons/blue-lupe-icon/BlueLupeIcon';

import useStyles from './MenuSearchStateMessage.styles';

function MenuSearchStateMessage({ text = '', classes = {} }) {
  const _classes = useStyles();
  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <BlueLupeIcon classes={{ root: clsx(classes?.icon) }} />
      <div className={clsx(_classes.text, classes?.text)}>
        <span>{text}</span>
      </div>
    </div>
  );
}

export default MenuSearchStateMessage;
