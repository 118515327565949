import { clsx } from 'clsx';

import { CustomBackButton } from '../buttons/custom-back-button';
import { CustomCloseButton } from '../buttons/custom-close-button';
import { dialogStyles } from './CustomDialogHeader.styles';
import { useFormatMessage } from '../../../intl/intl';

export function CustomDialogHeader(props: Props) {
  const {
    classes,
    children,
    onClickBackButton,
    onClickCloseButton,
    showBackButton,
    showCloseButton,
    title,
    totalProducts = 0,
  } = props;

  const styles = dialogStyles();
  const productMessage = useFormatMessage({
    id: 'globals.products',
  });

  const productText =
    totalProducts > 1 ? productMessage : productMessage.slice(0, -1);

  return (
    <div className={clsx(styles.root, classes?.root)}>
      {showBackButton && <CustomBackButton onClick={onClickBackButton} />}
      <div className={clsx(styles.title, classes?.title)}>
        <span
          dangerouslySetInnerHTML={{
            __html: title || '',
          }}
        />{' '}
        {totalProducts > 0 && `(${totalProducts} ${productText})`}
      </div>
      {children}
      {showCloseButton && (
        <CustomCloseButton
          classes={{
            button: clsx(styles.customCloseButton, classes?.closeButton),
          }}
          onClick={onClickCloseButton}
        />
      )}
    </div>
  );
}

interface Props {
  classes: any;
  children: any;
  onClickBackButton: any;
  onClickCloseButton: any;
  showBackButton?: boolean;
  showCloseButton?: boolean;
  title: string;
  totalProducts: number;
}
