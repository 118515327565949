import { createStyles, makeStyles } from '@material-ui/core';
import Colors from '../../../../constants/Colors';
import { neutral } from '../../../../shared/styles/colors';

export const CountdownToEditStyle = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: Colors.WARNING_BG,
      color: neutral.D18,
      gridArea: 'disclaimer',
      margin: '0.5rem 0',
    },
    primaryText: {
      fontSize: '0.875rem',
      fontWeight: 600,
      color: Colors.BROWN,
    },
    secondaryText: {
      fontSize: '0.75rem',
      fontWeight: 400,
      color: Colors.BROWN,
    },
    icon: {
      color: Colors.BROWN,
      alignSelf: 'center',
    },
    countdownClock: {
      borderRadius: '1.25rem',
      backgroundColor: Colors.WARNING_COLOR,
    },
    clock: {
      color: Colors.BROWN_2,
    },
    time: {
      width: '2.5rem',
      fontSize: '0.875rem',
      fontWeight: 600,
      color: Colors.BROWN_2,
    },
    cta: {
      cursor: 'pointer',
      fontWeight: 700,
      fontSize: '0.75rem',
      color: Colors.BROWN,
    },
  }),
);
