// Redux
import { createActions } from 'redux-actions';

const {
  closeOrderRejectionReasonsDialogAction,
  clearOrderRejectionReasonsDialogAction,
  openOrderRejectionReasonsDialogAction,
} = createActions({
  CLOSE_ORDER_REJECTION_REASONS_DIALOG_ACTION: () => {},
  CLEAR_ORDER_REJECTION_REASONS_DIALOG_ACTION: () => {},
  OPEN_ORDER_REJECTION_REASONS_DIALOG_ACTION: orderId => ({ orderId }),
});

const clearOrderRejectionReasonsDialog = () => dispatch => {
  dispatch(clearOrderRejectionReasonsDialogAction());
};

const closeOrderRejectionReasonsDialog = () => dispatch => {
  dispatch(closeOrderRejectionReasonsDialogAction());
};

const openOrderRejectionReasonsDialog = orderId => dispatch => {
  dispatch(openOrderRejectionReasonsDialogAction(orderId));
};

export default {
  clearOrderRejectionReasonsDialog,
  clearOrderRejectionReasonsDialogAction,
  closeOrderRejectionReasonsDialog,
  closeOrderRejectionReasonsDialogAction,
  openOrderRejectionReasonsDialog,
  openOrderRejectionReasonsDialogAction,
};
