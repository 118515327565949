// Material UI
import { makeStyles } from '@material-ui/core';
// Constant
import Colors from '../../../constants/Colors';
import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {},
  fatherContainer: {
    bottom: '0',
    padding: '24px 16px 24px 4px',
    width: '100%',
    borderTop: `1px solid ${neutral.D10}`,
  },
  messageIcon: {
    color: Colors.BLUE_MARGARITA,
  },
  inputStyle: {
    position: 'inherit',
    width: '100%',
    color: neutral.D20,
    fontFamily: 'Poppins',
    fontSize: '15px',
    letterSpacing: '0',
  },
}));
