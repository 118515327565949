import { combineReducers } from 'redux';

import { printerGlobalStateConfig } from '../printer/infra';

import type { EntryToGlobalState } from './startupNotificationsGlobalState.model';

export const STARTUP_NOTIFICATIONS_REDUCERS = {
  ['startupNotifications' as EntryToGlobalState]: combineReducers({
    [printerGlobalStateConfig.name]: printerGlobalStateConfig.reducer,
  }),
};
