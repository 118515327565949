import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import hooks from './hooks';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';

import CustomDialog from '../common/custom-dialog';
import CustomClickableBoxList from '../common/boxes/custom-clickable-box-list';

import useStyles from './styles';

export function SupportReasonsDialog() {
  const componentClasses = useStyles();
  const open = hooks.useOpen();
  const options = hooks.useSupportSuggestions();
  const title = hooks.useTitle();
  const description = hooks.useDescription();
  const dispatch = useDispatch();

  const handleBack = useCallback(() => {
    dispatch(dialogsManagerActions.back());
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(dialogsManagerActions.close());
  }, [dispatch]);

  return (
    <CustomDialog
      classes={{
        childrenContainer: componentClasses.childrenContainer,
        headerRoot: componentClasses.headerRoot,
        paper: componentClasses.paper,
      }}
      onClickBackButton={handleBack}
      onClose={handleClose}
      open={open}
      showBackButton
      showCloseButton
      title={title}
    >
      <CustomClickableBoxList label={description} options={options} />
    </CustomDialog>
  );
}
