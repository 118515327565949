import { CloseRounded } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  Button,
  CircularProgress,
  Drawer,
  TextField,
  Typography,
} from '@material-ui/core';

import { useDialog } from './hooks';
import AlertIcon from '../../../../../components/icons/alert-icon/AlertIcon';
import { CurrencyTextField } from '../../../../../shared/components/currency-text-field';

import makeClasses from './Dialog.style';

import type { OrderId } from '../../../../../business/shared/domain';
import { AddRTBehavior } from '../../../../../business/cargo';

export function Dialog(props: Props) {
  const { orderId = NaN, behavior } = props;

  const {
    lang,
    openDialog,
    isLoading,
    isErrorValue,
    dataToForm,
    handleChangePartialValue,
    handleCloseDialog,
    handleCreateOrder,
  } = useDialog({ orderId, behavior });

  const _classes = makeClasses();

  return (
    <Drawer
      anchor='right'
      disableBackdropClick
      disableEscapeKeyDown
      open={openDialog}
      onClose={handleCloseDialog}
      onClick={e => e.stopPropagation()}
      classes={{ paper: _classes.drawer }}
    >
      <header className={_classes.header} data-info={`orderID:${orderId}`}>
        <Typography
          variant='h6'
          component='h1'
          classes={{ root: _classes.headerTitle }}
          dangerouslySetInnerHTML={{ __html: lang.title }}
        />
        <CloseRounded
          onClick={handleCloseDialog}
          style={{ cursor: 'pointer' }}
        />
      </header>

      <main className={_classes.main}>
        <Alert
          severity='info'
          role='alert'
          icon={<AlertIcon type='info' />}
          classes={{ root: _classes.alertBanner }}
        >
          <AlertTitle classes={{ root: _classes.alertBannerTitle }}>
            {lang.banner.title}
          </AlertTitle>
          {lang.banner.description}
        </Alert>
        <TextField
          variant='filled'
          label={lang.form.clientName}
          value={dataToForm.name}
          InputProps={{ disableUnderline: true }}
        />
        <TextField
          variant='filled'
          label={lang.form.clientAddress}
          value={dataToForm.address}
          InputProps={{ disableUnderline: true }}
        />
        <TextField
          variant='filled'
          label={lang.form.clientAddressComplement}
          value={dataToForm.addressComplement}
          InputProps={{ disableUnderline: true }}
        />
        <CurrencyTextField
          required
          autoFocus
          variant='filled'
          value={dataToForm.partialValue}
          onChange={handleChangePartialValue}
          label={lang.form.partialValue}
          error={isErrorValue}
          helperText={isErrorValue ? lang.form.error.required : ''}
          InputProps={{
            disableUnderline: true,
            classes: { root: _classes.priceProductsToSend },
          }}
        />
        <Typography style={{ fontSize: '0.75rem' }}>
          {lang.form.partialValueDetail}
        </Typography>
      </main>

      <footer className={_classes.footer}>
        <Button variant='text' color='secondary' onClick={handleCloseDialog}>
          {lang.action.cancel}
        </Button>
        <Button
          size='large'
          color='primary'
          variant='contained'
          disabled={isLoading}
          onClick={handleCreateOrder}
          classes={{ root: _classes.actionCreate }}
          endIcon={<EndIcon isLoading={isLoading} />}
        >
          {lang.action.create}
        </Button>
      </footer>
    </Drawer>
  );
}

interface Props {
  orderId: OrderId;
  behavior: AddRTBehavior;
}

function EndIcon({ isLoading }: { isLoading: boolean }) {
  if (isLoading) return <CircularProgress color='primary' size='1rem' />;
  return null;
}
