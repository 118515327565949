import { clsx } from 'clsx';

import CustomSwitch from '../common/custom-switch';

import useStyles from './AutomaticCookingTimeSetting.style';

function AutomaticCookingTimeSetting(props) {
  const {
    title,
    description,
    classes = {},
    isActive = false,
    onChangeSwitch = () => undefined,
  } = props;

  const _classes = useStyles();

  return (
    <div className={clsx(_classes.root, classes?.root)}>
      <div className={clsx(_classes.textContainer, classes?.textContainer)}>
        <div className={clsx(_classes.title, classes?.title)}>{title}</div>
        <div className={clsx(classes?.description)}>{description}</div>
      </div>
      <CustomSwitch
        classes={{ root: _classes.switchRoot }}
        checked={isActive}
        onChange={async event => {
          await onChangeSwitch(event.target?.checked ?? false);
        }}
      />
    </div>
  );
}

export default AutomaticCookingTimeSetting;
