import { makeStyles } from '@material-ui/core';

import { blue, neutral } from '../../../../shared/styles/colors';
import { VARIANT } from '../../shared/consts';

import type { SaturationStateProp } from '../../shared/models';

export default makeStyles({
  option: ({ selected, variant }: Props) => ({
    width: '100%',
    minWidth: '13rem',
    padding: '0.75rem',
    textAlign: 'left',
    borderRadius: '0.5rem',
    boxShadow: 'none',
    backgroundColor:
      selected && variant !== 'fast' ? VARIANT[variant].bgColor : neutral.white,
    border: `1px solid ${
      selected && variant !== 'fast' ? VARIANT[variant].color : neutral.D10
    }`,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor:
        selected && variant !== 'fast' ? VARIANT[variant].bgColor : neutral[10],
    },
    '&:disabled': {
      color: VARIANT.deactivated.color,
      backgroundColor: VARIANT.deactivated.bgColor,
    },
  }),
  optionLabel: {
    height: '100%',
    display: 'flex',
    gap: '0.5rem',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  sectionMain: {
    display: 'inline-flex',
    gap: '0.5rem',
    justifyContent: 'flex-start',
  },
  aside: ({ variant }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: variant === 'fast' ? 'flex-start' : 'center',
    paddingTop: variant === 'fast' ? '0.5rem' : 0,
    borderRadius: '0.5rem',
    '&[aria-disabled="true"] > *': {
      fill: VARIANT.deactivated.color,
      color: VARIANT.deactivated.color,
      stroke: VARIANT.deactivated.color,
    },
  }),
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  title: ({ variant }: Props) => ({
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    color: VARIANT[variant].color,
    '&[aria-disabled="true"]': {
      color: VARIANT.deactivated.color,
    },
  }),
  legend: {
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    color: neutral['80'],
    '&[aria-disabled="true"]': {
      color: VARIANT.deactivated.color,
    },
  },
  root: {
    width: 36,
    height: 22,
    padding: 0,
    margin: '0.5rem',
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(1rem)',
      color: neutral.white,
      '& + $track': {
        backgroundColor: blue.D6,
        opacity: 1,
        border: 'none',
      },
    },
  },
  thumb: {
    width: 19,
    height: 19,
  },
  track: {
    borderRadius: 22 / 2,
    border: `1px solid ${neutral.D15}`,
    backgroundColor: neutral.D15,
    opacity: 1,
  },
  checked: {},
  tooltip: {
    fontSize: '0.75rem',
    backgroundColor: neutral.black,
    padding: '1rem',
    fontWeight: 700,
    width: '14.6875rem',
  },
});

interface Props extends SaturationStateProp {
  selected: boolean;
}
