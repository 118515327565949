import { createReducer } from '@reduxjs/toolkit'
import { State } from '../interfaces'
import actions from '../actions'

const defaultStoreId = 0

const initialState: State = {
  storeId: defaultStoreId,
  open: false,
}

export default createReducer(initialState, {
  [actions.clearAction.type]: () => clear(),
  [actions.closeAction.type]: state => close(state),
  [actions.openAction.type]: (state, { payload: { storeId } }) =>
    open(state, storeId),
})

const clear = () => {
  return initialState
}

const close = (state: State) => {
  state.open = false
  return state
}

const open = (state: State, storeId: number = state.storeId) => {
  state.open = true
  state.storeId = storeId
  return state
}
