import { makeStyles } from '@material-ui/core';

import { neutral } from '../../shared/styles/colors';

export default makeStyles({
  body: {
    width: '100%',
    backgroundColor: neutral.white,
  },
  root: {
    border: `1px solid ${neutral['20']}`,
    padding: '1rem',
    borderRadius: '0.5rem',
    display: 'grid',
    gridRowGap: '1rem',
  },
  titleWrapper: {
    borderBottom: `1px solid ${neutral['20']}`,
    paddingBottom: '0.75rem',
  },
  title: {
    color: neutral['50'],
    fontSize: 10,
    fontWeight: 'bold',
    letterSpacing: '1px',
    lineHeight: 'normal',
    textTransform: 'uppercase',
  },
});
