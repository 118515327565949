import { makeStyles } from '@material-ui/core';

import { neutral, red } from '../../../../shared/styles/colors';

import type { SaturationStateProp } from '../../shared/models';

export default makeStyles(theme => ({
  article: ({ showCTA, isSuspended, isInactive }: Props) => ({
    backgroundColor: isSuspended ? red.D4 : 'inherit',
    padding: '0.75rem',
    '&>:last-child': { paddingBottom: 0 },
    display: 'flex',
    gap: '0.75rem',
    cursor: showCTA && !isSuspended && !isInactive ? 'pointer' : 'not-allowed',
    borderRadius: '0.5rem',
    border: `1px solid ${neutral[20]}`,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: isSuspended ? red.D4 : 'rgba(0, 0, 0, 0.04)',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
  inactive: {
    color: red.D10,
  },
  suspended: {
    color: red.D10,
  },
  header: {
    padding: 0,
    flexGrow: 1.5,
    contain: 'inline-size',
  },
  title: ({ isInactive, isSuspended }: Props) => ({
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
    color: isInactive ? neutral.D15 : isSuspended ? red.D10 : neutral.D20,
    display: '-webkit-box',
    overflow: 'hidden',
    textWrap: 'pretty',
    // whiteSpace: 'pre-wrap',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  }),
  subtitle: ({ isInactive, isSuspended }: Props) => ({
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    color: isInactive ? neutral.D15 : isSuspended ? red.D10 : neutral.D18,
    display: '-webkit-box',
    overflow: 'hidden',
    textWrap: 'pretty',
    // whiteSpace: 'pre-wrap',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  }),
  main: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  avatar: {
    margin: 0,
  },
  danger: {
    color: red.D10,
    fontWeight: 'bold',
    padding: 0,
  },
}));

interface Props extends SaturationStateProp {
  showCTA: boolean;
  isInactive?: boolean;
  isSuspended?: boolean;
}
