// External libs
import { handleActions } from 'redux-actions';
// Actions
import OrderRejectionReasonsDialogActions from '../actions/orderRejectionReasonsDialog.actions';

const defaultOrderId = 0;

const initialState = {
  orderId: defaultOrderId,
  open: false,
};

export default handleActions(
  {
    [OrderRejectionReasonsDialogActions.clearOrderRejectionReasonsDialogAction]: state =>
      clearDialog(state),
    [OrderRejectionReasonsDialogActions.closeOrderRejectionReasonsDialogAction]: state =>
      closeDialog(state),
    [OrderRejectionReasonsDialogActions.openOrderRejectionReasonsDialogAction]: (
      state,
      { payload: { orderId } },
    ) => openDialog(state, orderId),
  },
  initialState,
);

const clearDialog = state => {
  return {
    ...initialState,
  };
};

const closeDialog = state => {
  return {
    ...state,
    open: false,
  };
};

const openDialog = (state, orderId) => {
  return {
    ...state,
    open: true,
    orderId: orderId || state.orderId,
  };
};
