import { Box, Button, Switch, Tooltip, Typography } from '@material-ui/core';

import { SaturationIcon } from '../saturation-icon';
import { CheckIcon } from '../../shared/assets/icons/check-icon';
import { ChipDisclaimer } from '../chip-disclaimer';
import { useDemandStatusOptionTexts } from './useDemandStatusOptionTexts';
import makeClasses from './DemandStatusOption.style';

import MuiColors from '../../../../constants/muiColors';
import { useDemandStatusOption } from './useDemandStatusOption';

import type { ReactNode } from 'react';
import type { SaturationStateProp } from '../../shared/models';
import type { Saturation } from '../../../../business/highDemand';

export function DemandStatusOption(props: Props) {
  const {
    children = null,
    variant,
    onSelect,
    name = '',
    extraTime = 0,
    disabled = false,
    selected = false,
    isEligibleForFastOperation = true,
    saturated,
    isFastOperationChecked,
    setIsFastOperationChecked,
  } = props;

  const classes = makeClasses({ selected, variant });
  const texts = useDemandStatusOptionTexts({
    variant,
    extraTime,
    isEligibleForFastOperation,
  });
  const { handleClick, isFastOperation, showTooltip } = useDemandStatusOption({
    variant,
    saturated,
    onSelect,
    isChecked: isFastOperationChecked,
    setChecked: setIsFastOperationChecked,
    isEligibleForFastOperation,
  });

  function DemandStatusOptionWrapper({ condition, wrapper, children }) {
    return condition ? wrapper(children) : children;
  }

  return (
    <DemandStatusOptionWrapper
      condition={showTooltip}
      wrapper={children => (
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={texts.tooltipTitle}
          placement='bottom'
          arrow
        >
          <span>{children}</span>
        </Tooltip>
      )}
    >
      <Button
        name={name}
        role='option'
        variant='contained'
        disabled={disabled}
        aria-selected={selected}
        onClick={handleClick}
        classes={{ contained: classes.option, label: classes.optionLabel }}
      >
        <section className={classes.sectionMain}>
          <Box
            component='aside'
            className={classes.aside}
            aria-disabled={disabled}
          >
            <SaturationIcon size='medium' variant={variant} />
          </Box>
          <Box component='main' className={classes.main}>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              gridGap='0.5rem'
            >
              <Box display='flex' flexDirection='column'>
                <Typography
                  variant='h1'
                  aria-disabled={disabled}
                  className={classes.title}
                >
                  {texts.title}
                </Typography>
                {texts.disclaimer && (
                  <ChipDisclaimer
                    variant={variant}
                    disclaimer={texts.disclaimer}
                    disabled={disabled}
                  />
                )}
              </Box>
              {isFastOperation && (
                <Switch
                  classes={{
                    root: classes.root,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                  }}
                  checked={isFastOperationChecked}
                  color={MuiColors.PRIMARY}
                  value={isFastOperationChecked}
                />
              )}
            </Box>
            {isFastOperation ? (
              <Box
                display='flex'
                flexDirection='column'
                gridGap='0.5rem'
                margin='0.25rem 0'
              >
                <Typography
                  aria-disabled={disabled}
                  className={classes.legend}
                  dangerouslySetInnerHTML={{ __html: texts.legend }}
                />
                {texts.fastOperationOptions.map((option, idx) => (
                  <Box
                    display='flex'
                    alignItems='center'
                    gridGap='0.25rem'
                    key={`option-${idx}`}
                  >
                    <CheckIcon disabled={disabled} />
                    <Typography
                      aria-disabled={disabled}
                      className={classes.legend}
                      dangerouslySetInnerHTML={{ __html: option }}
                    />
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography
                aria-disabled={disabled}
                className={classes.legend}
                dangerouslySetInnerHTML={{ __html: texts.legend }}
              />
            )}
          </Box>
        </section>
        {children}
      </Button>
    </DemandStatusOptionWrapper>
  );
}

interface Props extends SaturationStateProp {
  children?: ReactNode;
  variant: Saturation;
  name?: string;
  extraTime?: number;
  disabled?: boolean;
  selected?: boolean;
  onSelect?: (v: Saturation) => void;
  isEligibleForFastOperation?: boolean;
  saturated: Saturation;
  isFastOperationChecked?: boolean;
  setIsFastOperationChecked?: any;
}
