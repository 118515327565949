// Material UI
import { makeStyles } from '@material-ui/core'
import Colors from '../../../constants/Colors'

export default makeStyles(theme => ({
  root: ({ backgroundImg }: { backgroundImg: string }) => ({
    borderRadius: 8,
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: 'cover',
    gridAutoFlow: 'row',
    display: 'grid',
    padding: '84px 10px 70px 38.1%',
    [theme.breakpoints.up('sm')]: {
      padding: '107px 42px 102px 264px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '50px 20px 44px 227px',
    },
  }),
  text: {
    color: Colors.WHITE,
    placeSelf: 'center',
    fontWeight: 'bold',
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 24,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
    },
  },
}))
