import { makeStyles } from '@material-ui/core'
// Constant
import Colors from '../../constants/Colors'

export default makeStyles(theme => {
  return {
    root: {
      display: 'none',
      '@media print': {
        display: 'block',
        '-webkit-print-color-adjust': 'exact !important' /* Chrome, Safari */,
        'color-adjust': 'exact !important',
      },
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    blackLine: {
      backgroundColor: `${Colors.BLACK} !important`,
      textAlign: 'center',
      color: Colors.WHITE,
      borderTop: `1px solid ${Colors.WHITE}`,
    },
    logo: {
      backgroundColor: Colors.WHITE,
      textAlign: 'left',
    },
    infoBox: {
      width: '100%',
      display: 'grid',
    },
    containerProducts: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    containerTopping: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      paddingLeft: '20px',
    },
    containerComments: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    containerMarketPlace: {
      borderTop: `1px solid ${Colors.RED}`,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    containerTotal: {
      borderTop: `1px solid ${Colors.BLACK}`,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      backgroundColor: `${Colors.BLACK} !important`,
      color: Colors.WHITE,
    },
    fontText: {
      fontSize: '10px',
      fontFamily: 'Poppins',
    },
    pagebreak: {
      '@media print': {
        BreakBefore: 'always',
        pageBreakBefore: 'always',
        display: 'block',
        whiteSpace: 'nowrap',
      },
    },
    left: {
      float: 'left',
      color: Colors.BLACK,
    },
    right: {
      float: 'right',
      color: Colors.BLACK,
    },
  }
})
