// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => {
  return {
    active: ({ activeColor }) => ({
      '& $line': {
        backgroundColor: activeColor,
        border: `1px solid ${activeColor}`,
      },
    }),
    alternativeLabel: {
      top: 8,
      left: 'calc(-50% + 10px)',
      right: 'calc(50% + 10px)',
      [theme.breakpoints.up('sm')]: {
        top: 13,
        left: 'calc(-50% + 15px)',
        right: 'calc(50% + 15px)',
      },
      [theme.breakpoints.up('md')]: {},
    },
    completed: ({ activeColor }) => ({
      '& $line': {
        backgroundColor: activeColor,
        border: `1px solid ${activeColor}`,
      },
    }),
    disabled: {
      '& $line': {
        borderColor: neutral.D10,
      },
    },
    line: ({ activeColor }) => ({
      borderColor: neutral.D10,
      transition: theme.transitions.create('border-color'),
      borderTopWidth: 3,
    }),
  };
});
