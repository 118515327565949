import { clsx } from 'clsx';

import CustomChip from './../custom-chip/CustomChip';

import { useFormatMessage } from '../../../intl/intl';
import FilterMenuIcon from './../../icons/menu/FilterMenuIcon';
import useStyles from './CustomAdvancedFilterToolbar.styles';

function CustomAdvancedFilterToolbar(props) {
  const {
    classes = {},
    onClick,
    selectedFilterOptions = [],
    showAdvancedToolBar = false,
    title,
  } = props;
  const defaultTitle = useFormatMessage({ id: 'global.showAdvancedFilter' });
  const _title = title || defaultTitle;
  const _classes = useStyles();
  return (
    <aside className={clsx(_classes.root, classes?.root)}>
      {showAdvancedToolBar && (
        <div
          className={clsx(_classes.toolBarRoot, classes?.toolBarRoot)}
          onClick={onClick}
        >
          {_title}
          <FilterMenuIcon />
        </div>
      )}
      <div
        data-tag='list of selected stores by filter'
        className={clsx(_classes.chipsRoot, classes?.chipsRoot)}
      >
        {selectedFilterOptions.map((filterOption, index) => {
          return (
            <CustomChip
              key={index}
              label={filterOption.label}
              onDelete={filterOption.onDelete}
            />
          );
        })}
      </div>
    </aside>
  );
}

export default CustomAdvancedFilterToolbar;
