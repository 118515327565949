import { Box, Button, RadioGroup, Typography } from '@material-ui/core';
import { useDeliveryDetail, useDrinksAndBagsLang } from './hooks';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { CustomRadio } from '../../../components/common/custom-radio';
import useStyles from './delivery-detial.styles';
import { orderStateType } from '../../../business/order/rt/analytics/core';
import { OrderDeliveryDetailLoader } from './components';
import { Alert } from '@material-ui/lab';
import { ExpandLess } from '@material-ui/icons';

export function OrderDeliveryDetail({
  code = '',
  orderId,
  storeId,
  orderState,
  canEditOrderDetailBox = true,
  isMandatory = false,
  isInKanban = false,
  onClose = null,
  isBagQuantitySet = false,
  hasDeliveryDetailMessage,
}: Props) {
  const {
    quantityOfPackages,
    handleDownQuantityOfPackages,
    handleUpQuantityOfPackages,
    hasDrinksOutside,
    handleHasDrinksOutside,
    handleRetry,
    isUpdateLoading,
    isUpdateSuccess,
    isUpdateDenied,
    isGetLoading,
    maxQuantityOfPackages,
    isInputMandatory,
    isDisabled,
    handleSubmit
  } = useDeliveryDetail({ code, orderId, storeId, orderState, isMandatory, isInKanban, isBagQuantitySet, hasDeliveryDetailMessage });
  const {
    bagsQuantityText,
    checkCodeText,
    outsideDrinksText,
    titleText,
    loadingText,
    successText,
    errorText,
    retryText,
    yesText,
    noText,
    completeOrderDetailsPlease,
    confirmOrderDetails,
    required
  } = useDrinksAndBagsLang();
  const styles = useStyles({ isInKanban });

  if (isGetLoading) return <OrderDeliveryDetailLoader />;

  return (
    <Box className={styles.root} display='flex' flexDirection='column' gridGap='0.5rem'>
      <Box className={styles.main}>
        <Box display='flex' alignItems='center' justifyContent='space-between' marginBottom='1rem'>
          <Box className={styles.title}>{titleText}</Box>
          {isInKanban 
            ? <ExpandLess onClick={onClose} /> 
            : (
              <>
                {isInputMandatory && (<Typography className={styles.mandatory}>{required}</Typography>)}
                {isUpdateLoading && (<Typography className={styles.loading}>{loadingText}</Typography>)}
                {isUpdateSuccess && (
                  <Box
                    display='flex'
                    justifyContent='flex-start'
                    alignItems='center'
                    gridGap='0.15rem'
                  >
                    <CheckCircleOutlineIcon className={styles.successIcon} />
                    <Typography className={styles.success}>{successText}</Typography>
                  </Box>
                )}
                {isUpdateDenied && (
                  <Box
                    display='flex'
                    justifyContent='flex-start'
                    alignItems='center'
                    gridGap='0.15rem'
                  >
                    <HighlightOffIcon className={styles.errorIcon} />
                    <Typography className={styles.error}>
                      {errorText}{' '}
                      <span className={styles.retry} onClick={handleRetry}>
                        {retryText}
                      </span>
                    </Typography>
                  </Box>
                )}
              </>
          )}
        </Box>
        <Box display='flex' flexDirection='column' gridGap='0.75rem'>
          {code && (
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography className={styles.checkCodeText}>
                {checkCodeText}
              </Typography>
              <Typography className={styles.checkCode}>{code}</Typography>
            </Box>
          )}
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography className={styles.bagsQuantityText}>
              {bagsQuantityText}
            </Typography>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              gridGap='0.5rem'
            >
              {canEditOrderDetailBox ? (
                <>
                  <Box
                    className={
                      Number.isNaN(quantityOfPackages) || quantityOfPackages === 0
                        ? styles.quantityButtonDisabled
                        : styles.quantityButton
                    }
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    onClick={handleDownQuantityOfPackages}
                  >
                    -
                  </Box>
                  <Typography className={styles.quantityText}>
                    {Number.isNaN(quantityOfPackages) ? '-' : quantityOfPackages}
                  </Typography>
                  <Box
                    className={
                      quantityOfPackages >= maxQuantityOfPackages
                        ? styles.quantityButtonDisabled
                        : styles.quantityButton
                    }
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    onClick={handleUpQuantityOfPackages}
                  >
                    +
                  </Box>
                </>
              ) : (
                <Typography className={styles.quantityText}>
                  {quantityOfPackages}
                </Typography>
              )}
            </Box>
          </Box>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography className={styles.drinksText}>
              {outsideDrinksText}
            </Typography>
            {canEditOrderDetailBox ? (
              <>
                <RadioGroup
                  className={styles.radioGroupOptions}
                  value={hasDrinksOutside}
                >
                  <CustomRadio
                    classes={{
                      root: styles.radioRootButton,
                      label: styles.radioLabelButton,
                    }}
                    color='primary'
                    value={true}
                    onChange={() => handleHasDrinksOutside(true)}
                    label={yesText}
                  />
                  <CustomRadio
                    classes={{
                      root: styles.radioRootButton,
                      label: styles.radioLabelButton,
                    }}
                    color='primary'
                    value={false}
                    onChange={() => handleHasDrinksOutside(false)}
                    label={noText}
                  />
                </RadioGroup>
              </>
            ) : (
              <Typography className={styles.radioLabelButton}>
                {hasDrinksOutside ? yesText : noText}
              </Typography>
            )}
          </Box>
          {isInputMandatory && (
            <Alert severity='error' classes={{ root: styles.alert }}>
              <span>{completeOrderDetailsPlease}</span>
            </Alert>
          )}
        </Box>
      </Box>
      {isInKanban && (
        <Box className={styles.cta}>
          <Button 
            color='primary' 
            fullWidth={false} 
            variant='contained' 
            onClick={handleSubmit}
            disabled={isDisabled || isUpdateLoading}
          >
            {confirmOrderDetails}
          </Button>
        </Box>
      )}
    </Box>
  );
}

interface Props {
  code?: string;
  orderId: number;
  storeId: number;
  orderState: orderStateType;
  canEditOrderDetailBox?: boolean;
  isMandatory?: boolean;
  hasOrderDeliveryDetailsUpdated?: boolean;
  isInKanban?: boolean;
  onClose?: any;
  isBagQuantitySet?: boolean;
  hasDeliveryDetailMessage: boolean;
}
