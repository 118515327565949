// React
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
// Actions
import AppActions from './../../redux/actions/app-actions'
import PartnersActions from '../../redux/actions/partners-actions'
// Components
import DeactivatePartnerDialog from './DeactivatePartnerDialog'

const mapStateToProps = ({ partnerReducer }, { intl: { formatMessage } }) => {
  const cancelText = formatMessage({ id: 'globals.cancel' })
  const confirmText = formatMessage({ id: 'globals.confirm' })
  const hoursText = formatMessage({ id: 'globals.hours' })
  const minutesText = formatMessage({ id: 'globals.minutes' })
  const subtitle = formatMessage({ id: 'deactivatePartnerDialog.subtitle' })
  const title = formatMessage({ id: 'deactivatePartnerDialog.title' })
  const turnOffByLimitedTimeText = formatMessage({
    id: 'deactivatePartnerDialog.turnOffByLimitedTimeText',
  })
  const turnOffByRestOfDay = formatMessage({
    id: 'deactivatePartnerDialog.turnOffByRestOfDay',
  })
  const turnOffForever = formatMessage({
    id: 'deactivatePartnerDialog.turnOffForever',
  })

  return {
    cancelText,
    confirmText,
    hoursText,
    minutesText,
    profile: partnerReducer.profile,
    subtitle,
    title,
    turnOffByLimitedTimeText,
    turnOffByRestOfDay,
    turnOffForever,
  }
}

const mapDispatchToProps = {
  onClose: PartnersActions.closeDeactivatePartnerDialog,
  onConfirm: PartnersActions.confirmPartnerDeactivation,
  openSnackBarError: AppActions.openSnackBarError,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DeactivatePartnerDialog),
)
