import { makeStyles } from '@material-ui/core/styles';

import { neutral, teal } from '../../../../../shared/styles/colors';

export default makeStyles({
  optionContainer: {
    display: 'flex',
    margin: '0 0 0 0 ',
    alignItems: 'flex-start',
    border: '1px solid',
    borderColor: neutral['20'],
    borderRadius: 8,
    padding: '8px 0 8px 12px',
  },
  optionContainerActive: {
    borderColor: teal['50'],
  },
  optionsContainer: {
    display: 'flex',
    gap: 8,
  },
  optionTitle: {
    padding: '10px 8px 8px 0px',
    margin: 0,
    fontWeight: 600,
    fontSize: 14,
  },
  optionDescription: {
    padding: '0px 16px 16px 0px',
    margin: 0,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '20px',
  },
  radio: {
    '&$checked': {
      color: teal['50'],
    },
  },
});
