import { Alert } from '@material-ui/lab';
import { Box, Typography } from '@material-ui/core';

import { DropdownOption } from './comps';
import { ReactComponent as OrderListIcon } from '../../../../../../assets/images/icons/papers.svg';
import { ReactComponent as QuestionIcon } from '../../../../../../assets/images/icons/question.svg';
import { ReactComponent as RTIcon } from '../../../../../../assets/images/icons/rappitendero_black.svg';

import { useDropdownContent } from './hooks';

import makeClasses from './dropdown-content.style';

export function DropdownContent(props: { isEnabled: boolean }) {
  const { isEnabled } = props;

  const {
    lang,
    handleOpenReqRT,
    handleOpenOrderList,
    handleOpenHowWorks,
  } = useDropdownContent();

  const _classes = makeClasses();

  return (
    <Box
      className={_classes.container}
      display={'flex'}
      flexDirection={'column'}
      gridGap={'1rem'}
      padding={'1.5rem'}
      minWidth={'23.5rem'}
    >
      <Box display={'flex'} flexDirection='column'>
        <Typography component='h4' className={_classes.title}>
          {lang.title}
        </Typography>
        <Typography component='p' className={_classes.description}>
          {lang.description}
        </Typography>
      </Box>

      <div className={_classes.divider} />

      {!isEnabled && (
        <Alert role='alert' severity='info' classes={{ root: _classes.banner }}>
          {lang.banner.disabled.p1}
          <a target='_blank' href='https://partners.rappi.com/cargo/home'>
            {lang.banner.disabled.p2}
          </a>
        </Alert>
      )}

      <DropdownOption
        title={lang.opt.reqRT.title}
        description={lang.opt.reqRT.description}
        disabled={!isEnabled}
        onClick={handleOpenReqRT}
        startIcon={<RTIcon fill='inherit' />}
      />
      <DropdownOption
        title={lang.opt.orders.title}
        description={lang.opt.orders.description}
        disabled={!isEnabled}
        onClick={handleOpenOrderList}
        startIcon={<OrderListIcon fill='inherit' />}
      />
      <DropdownOption
        title={lang.opt.howWorks.title}
        description={lang.opt.howWorks.description}
        onClick={handleOpenHowWorks}
        startIcon={<QuestionIcon fill='inherit' />}
      />
    </Box>
  );
}
