import Tabs from '@material-ui/core/Tabs';
import { useMediaQuery } from '@material-ui/core';

import useStyles from './OrdersKanbanTabs.styles';

import { CustomTitleBadgeTab } from '../common/custom-title-badge-tab';

function OrdersKanbanTabs(props) {
  const {
    cookingText,
    cookingMobileText,
    index = 0,
    onChange,
    pendingToAcceptText,
    pendingToAcceptMobileText,
    pendingToDeliverText,
    pendingToDeliverMobileText,
    totalCookingOrders = 0,
    totalPendingToAcceptOrders = 0,
    totalPendingToDeliverOrders = 0,
  } = props;

  const componentClasses = useStyles();

  const handleChange = (event, value) => {
    onChange(value);
  };

  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  const pendingToAcceptResponsiveText = isMdUp
    ? pendingToAcceptText
    : pendingToAcceptMobileText;

  const cookingResponsiveText = isMdUp ? cookingText : cookingMobileText;

  const pendingToDeliverResponsiveText = isMdUp
    ? pendingToDeliverText
    : pendingToDeliverMobileText;

  return (
    <>
      {!isMdUp && (
        <Tabs
          classes={{ root: componentClasses.root }}
          indicatorColor='primary'
          textColor='primary'
          value={index}
          variant='fullWidth'
          onChange={handleChange}
        >
          <CustomTitleBadgeTab
            label={pendingToAcceptResponsiveText}
            count={totalPendingToAcceptOrders}
          />
          <CustomTitleBadgeTab
            label={cookingResponsiveText}
            count={totalCookingOrders}
          />
          <CustomTitleBadgeTab
            label={pendingToDeliverResponsiveText}
            count={totalPendingToDeliverOrders}
          />
        </Tabs>
      )}
    </>
  );
}

export default OrdersKanbanTabs;
