// React
import React from 'react';
// Constant
import { neutral } from '../../../shared/styles/colors';

const ClockIcon = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
    >
      <g fill={neutral.D15} fillRule='nonzero'>
        <path d='M8 .5a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15zm0 1a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13z' />
        <path d='M8.5 5.291a.5.5 0 0 0-1 0v2.695a.5.5 0 0 0 .236.424l2.44 1.515a.5.5 0 0 0 .527-.85L8.5 7.708V5.29z' />
      </g>
    </svg>
  );
};

export default ClockIcon;
