import React from 'react';
import houseIcon from '../../../assets/images/houseIcon.png';

export function HouseIcon({ className }: Props) {
  return <img className={className} alt='House icon' src={houseIcon} />;
}

interface Props {
  className?: any;
}
