import { useMediaQuery } from '@material-ui/core';

import CustomDialog from '../common/custom-dialog';
import CustomLoader from '../common/custom-loader';
import { orderDetailApp } from '../../business/order-mgmt';
import { useOrderManagement } from '../order-management/hooks';
import OrderDetailDialogBody from '../order-detail-dialog-body';
import OrderDetailDialogErrorBody from '../order-detail-dialog-error-body';
import OrderDetailDialogHeaderMenu from '../../components/order-detail-dialog-header-menu';

import { ZIndexType } from '../../constants/ZIndexType';
import useStyles from './OrderDetailDialog.styles';

function OrderDetailDialog(props) {
  const {
    isError,
    isLoading,
    onClickBackButton,
    onClose,
    open = false,
    showDetailBody,
    showError,
    title,
    totalProducts,
    ordersSameBundle,
  } = props;

  const { isInEditionMode, handleCloseEditionMode } = useOrderManagement();
  const componentClasses = useStyles();
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  const showBackButton = !isMdUp;
  const showCloseButton = isMdUp;
  const { totalProductsInOrder } = orderDetailApp();

  const getMenu = () =>
    isLoading || isError ? '' : <OrderDetailDialogHeaderMenu />;

  const handleClose = () => {
    if (isInEditionMode) handleCloseEditionMode();
    onClose();
  };

  return (
    <CustomDialog
      classes={{ paper: componentClasses.paper }}
      CustomDialogHeaderChildren={getMenu()}
      onClickBackButton={onClickBackButton}
      onClose={handleClose}
      open={open}
      showBackButton={showBackButton}
      showCloseButton={showCloseButton}
      title={title}
      totalProducts={totalProductsInOrder || totalProducts}
      ordersSameBundle={ordersSameBundle}
    >
      {showDetailBody && <OrderDetailDialogBody />}
      {showError && <OrderDetailDialogErrorBody />}
      <CustomLoader invisible open={isLoading} zIndexType={ZIndexType.DRAWER} />
    </CustomDialog>
  );
}

export default OrderDetailDialog;
