import { makeStyles } from '@material-ui/core';
import NotificationPermissionsType from '../../../constants/NotificationPermissionsType';
// Constant
import Colors from '../../../constants/Colors';
import { neutral } from '../../../shared/styles/colors';

export default makeStyles(theme => ({
  box: {
    maxWidth: '300px',
    position: 'absolute',
    backgroundColor: Colors.WHITE,
    margin: 'auto',
    left: '0',
    right: '0',
    top: '105px',
    [theme.breakpoints.up('md')]: {
      maxWidth: () =>
        Notification.permission === NotificationPermissionsType.DENIED
          ? '550px'
          : '280px',
      top: '40px',
      left: '100px',
      right: 'auto',
    },
  },
  root: {
    display: 'flex',
    flexDirection: () =>
      Notification.permission === NotificationPermissionsType.DENIED
        ? 'row'
        : 'column',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontSize: '26px',
  },
  group: {
    textAlign: 'center',
  },
  icon: {
    padding: '30px 117px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  tutorialImg: {
    margin: '20px',
    [theme.breakpoints.up('md')]: {
      display: () =>
        Notification.permission === NotificationPermissionsType.DENIED
          ? 'auto'
          : 'none',
    },
  },
  first: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    marginTop: () =>
      Notification.permission === NotificationPermissionsType.DENIED
        ? '26px'
        : '0',
  },
  second: {
    fontFamily: 'Poppins Bold',
    [theme.breakpoints.up('md')]: {
      display: () =>
        Notification.permission === NotificationPermissionsType.DENIED
          ? 'auto'
          : 'none',
      fontSize: '15px',
    },
  },
  text: {
    display: 'flex',
    fontSize: '15px',
    marginTop: () =>
      Notification.permission === NotificationPermissionsType.DENIED
        ? '0'
        : '20px',
    textAlign: 'center',
    padding: () =>
      Notification.permission === NotificationPermissionsType.DENIED
        ? '20px 20px 0 0'
        : '20px',
    [theme.breakpoints.up('md')]: {
      fontSize: '13px',
    },
  },
  cta: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    borderTop: () =>
      Notification.permission === NotificationPermissionsType.DENIED
        ? 'none'
        : `1px solid ${neutral.D10}`,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
      display: () =>
        Notification.permission === NotificationPermissionsType.DENIED
          ? 'none'
          : 'auto',
    },
  },
  primary: {
    fontSize: '15px !important',
    fontFamily: 'Poppins',
    fontWeight: '600',
    [theme.breakpoints.up('md')]: {
      fontSize: '13px !important',
      padding: '5px 12px',
      flexDirection: 'row-reverse',
    },
  },
  cancelBtn: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    color: neutral.D15,
    margin: '8px 0',
    [theme.breakpoints.up('md')]: {
      fontSize: '13px !important',
      padding: '5px 12px',
      margin: '0 20px',
    },
  },
}));
