import { clsx } from 'clsx';
import { IconButton } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';

export function CustomCancelButton(props) {
  const {
    color = 'default',
    disabled = false,
    onClick = () => {},
    classes = {},
  } = props;
  return (
    <IconButton
      aria-label='Cancel'
      className={clsx(classes?.button)}
      color={color}
      disabled={disabled}
      onClick={onClick}
    >
      <Cancel className={clsx(classes?.icon)} />
    </IconButton>
  );
}
