import { useMemo, useCallback } from 'react';

import { useFormatMessage } from '../../../intl/intl';

import { CustomStateBlock } from '../custom-state-block';
import { ErrorStateIcon } from '../../icons/error-state-icon';

export function CustomErrorStateBlock({ onActionClick = null, text = null }) {
  const actionText = useFormatMessage({
    id: 'customErrorStateBlock.buttonText',
  });
  const defaultText = useFormatMessage({
    id: 'PWEX0',
  });

  const textToShow = useMemo(() => {
    return text || defaultText;
  }, [defaultText, text]);

  const handleClickAction = useCallback(() => {
    if (!onActionClick) {
      window.location.reload();
    }
    onActionClick();
  }, [onActionClick]);

  return (
    <CustomStateBlock
      actionText={actionText}
      Icon={ErrorStateIcon}
      onActionClick={handleClickAction}
      text={textToShow}
      showAction
    />
  );
}
