import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import StringUtils from '../../../../utils/StringUtils';
import OrderActions from '../../../../redux/actions/orders-actions';
import hooks from './hooks';

import CustomAlert from '../../../common/custom-alert';
import { CustomMeasureValueSelector } from '../../../common/custom-measure-value-selector';
import OrderDetailDialogMainSectionItem from '../../../order-detail-dialog-main-section-item';

import useStyles from './styles';

import type { BaseProps } from '../../../../interfaces';

export function BaseSection(props: Props) {
  const { alertText, description, maxValue, minValue, title, showSecondaryAlert = false } = props;
  const componentClasses = useStyles();

  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    const showAlertToSet = !StringUtils.isNullOrEmpty(alertText);
    setShowAlert(showAlertToSet);
  }, [alertText]);

  const orderId = hooks.useOrderId();
  const value = hooks.useValue();

  const dispatch = useDispatch();

  const handleDecrease = useCallback(() => {
    dispatch(OrderActions.decreaseCookingTimeToTakeOrder(minValue, orderId));
  }, [dispatch, minValue, orderId]);

  const handleIncrease = useCallback(() => {
    dispatch(OrderActions.increaseCookingTimeToTakeOrder(maxValue, orderId));
  }, [dispatch, maxValue, orderId]);

  return (
    <OrderDetailDialogMainSectionItem
      classes={{ body: componentClasses.root }}
      title={title}
    >
      {!StringUtils.isNullOrEmpty(description) && (
        <div className={componentClasses.description}>{description}</div>
      )}
      <CustomMeasureValueSelector
        maxValue={maxValue}
        measureText={'min'}
        minValue={minValue}
        onIncrease={handleIncrease}
        onDecrease={handleDecrease}
        value={value}
      />
      {showAlert && (
        <CustomAlert
          classes={{ root: showSecondaryAlert ? componentClasses.secondaryAlert : componentClasses.customAlertRoot }}
          text={alertText}
        />
      )}
    </OrderDetailDialogMainSectionItem>
  );
}

interface Props extends BaseProps {
  alertText?: string | null;
  description?: string | null;
  maxValue: number;
  minValue: number;
  title: string;
  showSecondaryAlert?: boolean;
}
