export const neutral = {
  10: '#F7F8F9',
  20: '#ECEFF3',
  30: '#E5E8EB',
  40: '#C5CAD3',
  50: '#919AAA',
  60: '#758195',
  70: '#5E6777',
  80: '#464D59',
  90: '#2F343C',
  100: '#1B1E23',
  white: '#fff',
  black: '#000',
  D1: '#fdf9ef',
  D2: '#f7f8fa',
  D3: '#f9f9f9',
  D4: '#FAFAFA',
  D5: '#f2f2f2',
  D6: '#f0f1f3',
  D7: '#EFEDF2',
  D8: '#EBEAEA',
  D9: '#E8E7E7',
  D10: '#ededed',
  D11: '#e6ebf1',
  D12: '#C8D3DF',
  D13: '#CDCDCE',
  D14: '#B6C1CD',
  D15: '#9e9e9f',
  D16: '#6F6F70',
  D17: '#706967',
  D18: '#404041',
  D19: '#332927',
  D20: '#010103',
} as const;

export const blue = {
  10: '#DCE8FB',
  20: '#BAD0F8',
  30: '#7FA9F1',
  40: '#5089EC',
  50: '#1662E5',
  60: '#114BB0',
  70: '#0C367E',
  80: '#09275C',
  90: '#051839',
  100: '#030F22',
  D1: '#F3F7FF', // ZIRCON
  D2: '#E8F0FE', // SOLITUDE
  D3: '#dae7ff', // CORNFLOWER_BLUE
  D4: '#A4C3FE', // ANAKIWA
  D5: '#75A4FE', // MALIBU
  D6: '#4589FF', // DODGER_BLUE
  D7: '#3B76DB', // INFO_COLOR_2
  D8: '#1867B0', // FUN_BLUE
  D0: '#0a75e5', // PROPIO_FONT_COLOR
  D10: '#0f62fe', // DODGER_BLUE_INTENSE
  D11: '#007AFF', // AZURE_RADIANCE
} as const;

export const green = {
  10: '#DFF9ED',
  20: '#CBF6E2',
  30: '#A0EEC8',
  40: '#64E3A6',
  50: '#29D884',
  60: '#00A86C',
  70: '#00725D',
  80: '#00553F',
  90: '#003422',
  100: '#001F14',
  D1: '#EAFCE6', // BACKGROUND_COLOR_RT_STATE
  D2: '#DBF6E8', // GRANNY_APPLE
  D3: '#1FD8B5', // CYAN
  D4: '#0CC665', // MALACHITE
  D5: '#178038', // JEWEL
  D6: '#005950', // SHERPA_BLUE
  D7: '#083410',
} as const;

export const red = {
  10: '#FFE3E3',
  20: '#FFC7C7',
  30: '#FF9797',
  40: '#FF7171',
  50: '#FF4242',
  60: '#C43333',
  70: '#8C2424',
  80: '#661A1A',
  90: '#401010',
  100: '#260A0A',
  D1: '#FFF7F7', // DANGER_LIGHT_COLOR_2
  D2: '#FFECEF', // LAVENDER_BLUSH
  D3: '#FDEBEE', // WISP_PINK
  D4: '#FFE9E9', // DANGER_LIGHT_COLOR
  D5: '#ffeff0', // CHABLIS
  D6: '#F6F1EC', // SPRING_WOOD
  D7: '#FFD6D6', // DANGER_LIGHT_3
  D8: '#FF8787', // DANGER_LIGHT_2
  D9: '#ff5656', // DANGER_FONT_COLOR
  D10: '#ff2525', // SECONDARY_COLOR | DANGER_COLOR
  D11: '#e02020', // INFO_LIGHT_COMMENT_FONT_COLOR
  D12: '#DB2020', // DANGER_LIGHT_1
  D13: '#f44336', // DANGER_LIGHT_COLOR_3
  D14: '#FF0000', // RED
  D15: '#C15558', // FUZZY_WUZZY_BROWN
  D16: '#b81b1b', // SECONDARY_COLOR_DARK
  D17: '#aa273e', // TALL_POPPY
  D18: '#804445', // SPICY_MIX
  D19: '#941616',
} as const;

export const orange = {
  10: '#FFECE0',
  20: '#FFD9C1',
  30: '#FFB88C',
  40: '#FF9E62',
  50: '#FF7E2E',
  60: '#C46123',
  70: '#8C4519',
  80: '#663212',
  90: '#401F0B',
  100: '#261307',
  D1: '#FFAA00', //WEB_ORANGE
  D2: '#FF7D00', //FLUSH_ORANGE
  D3: '#FF441F', //RED_ORANGE
  D4: '#E47000'
} as const;

export const yellow = {
  10: '#FFF4E2',
  20: '#FFEAC5',
  30: '#FFD893',
  40: '#FFC96C',
  50: '#FFB73B',
  60: '#C48D2D',
  70: '#8C6520',
  80: '#664918',
  90: '#402E0F',
  100: '#261B09',
  D1: '#FEFBF6', // WARNING_LIGHT_4
  D2: '#FFFDF6', // WARNING_LIGHT_5
  D3: '#fff7e2', // WARNING_CHIP_COLOR
  D4: '#FFF7E7', // WARNING_LIGHT_2
  D5: '#fff3da', // WARNING_COLOR
  D6: '#FFE9BC', // WARNING_BG
  D7: '#FFE9C6', // WARNING_LIGHT_1
  D8: '#FFE3AA', // NAVAJO_WHITE
  D9: '#FFDEBE', // NEGRONI
  D10: '#FFCE6B', // WARNING
  D11: '#ffc043', // WARNING_FONT_COLOR
  D12: '#dda63a', // ANZAC
  D13: '#ad832e', // WARNING_DARK_COLOR
  D14: '#856423', // BROWN
  D15: '#33260D', // BROWN_2
} as const;

export const teal = {
  10: '#DFF4F3',
  20: '#BFE8E6',
  30: '#89D5D1',
  40: '#5EC5C0',
  50: '#28B2AB',
  60: '#1F8984',
  70: '#16625E',
  80: '#104744',
  90: '#0A2C2B',
  100: '#061B1A',
  D1: '#edfcfb', // WHITE_ICE
  D2: '#daf3ef', // ICEBERG
  D3: '#d4eeed', // SUCCESS_LIGHT_COLOR
  D4: '#51bcb6', // SUCCESS_FONT_COLOR
  D5: '#26aba4', // PRIMARY_COLOR | SUCCESS_COLOR
  D6: '#23aeb9', // CYAN_SHADE
  D7: '#21938d', // PRIMARY_COLOR_DARK
  D8: '#088343', // GREEN_ACTIVE_STATUS
} as const;

export const purple = {
  10: '#E8E2FB',
  20: '#D1C5F7',
  30: '#AA95EF',
  40: '#8B6EE9',
  50: '#643DE2',
  60: '#4D2FAE',
  70: '#37227C',
  80: '#28185A',
  90: '#190F38',
  100: '#0F0922',
  D1: '#462663', //JACARTA
} as const;

export const indigo = {
  10: '#E8E9FA',
  20: '#D0D2F5',
  30: '#A8ACEB',
  40: '#898EE4',
  50: '#6168DB',
  60: '#4B50A9',
  70: '#353978',
  80: '#272A58',
  90: '#181A37',
  100: '#0F1021',
  D1: '#e7e3f4', // INFO_COLOR | INFO_DARK_COLOR
  D2: '#7356bf', // INFO_FONT_COLOR
  D3: '#6A5ACD', // BLUE_MARGARITA
  D4: '#5800DC',
  D5: '#694c84', // AFFAIR
} as const;

export const magenta = {
  10: '#FFE5F0',
  20: '#FFCCE1',
  30: '#FFA0C8',
  40: '#FF7DB3',
  50: '#FF529A',
  60: '#C43F77',
  70: '#8C2D55',
  80: '#66213E',
  90: '#401426',
  100: '#260C17',
  D1: '#3c000a', //TEMPTRESS
} as const;
