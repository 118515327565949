// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  root: {
    backgroundColor: neutral.white,
    borderBottom: `1px solid ${neutral.D10}`,
    display: 'grid',
    gridAutoRows: 'min-content',
    minHeight: '200px',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.up('md')]: {},
  },
}));
