import { useFormatMessage } from '../../../../../../intl/intl';

import type { ButtonAppearance } from '../MainButton';

export function useMainButtonLang(props: { appearance: ButtonAppearance }) {
  const { appearance } = props;

  const orderHistoricalBtn = 'cargo.addRT.behavior.orderHistorical.btn';
  const detailMarketplaceBtn = 'cargo.addRT.behavior.detailMarketplace.btn';

  const addRTBtn = useFormatMessage({
    id: appearance === 'primary' ? orderHistoricalBtn : detailMarketplaceBtn,
  });

  return {
    addRTBtn,
  };
}
