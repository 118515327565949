import { Box, Button, CircularProgress } from '@material-ui/core';
import { StoreStatusList } from '../store-status-list';
import { useListOfStoresText } from './useListOfStoresText';

export function ListOfStores({
  activeStores,
  onHoldStores,
  inactiveStores,
  suspededStores,
  navigate,
  onClickStore,
  isLoading,
}) {
  const {
    activeStoresText,
    inactiveAndCloseStoresText,
    suspededStoresText,
    manageStoresText,
  } = useListOfStoresText();

  return (
    <Box
      component='section'
      p='2px 2px 0.5rem'
      display='flex'
      flexDirection='column'
      gridGap='0.5rem'
    >
      {isLoading && (
        <CircularProgress style={{ display: 'block', margin: '4rem auto' }} />
      )}

      {!isLoading && activeStores.length > 0 && (
        <StoreStatusList
          title={activeStoresText}
          stores={activeStores}
          onClickStore={onClickStore}
          isActive
        />
      )}
      {!isLoading && (onHoldStores.length > 0 || inactiveStores.length > 0) && (
        <StoreStatusList
          title={inactiveAndCloseStoresText}
          stores={[...onHoldStores, ...inactiveStores]}
          onClickStore={onClickStore}
          isInactive
        />
      )}
      {!isLoading && suspededStores.length > 0 && (
        <StoreStatusList
          title={suspededStoresText}
          stores={suspededStores}
          onClickStore={onClickStore}
          isSuspended
        />
      )}
      {!isLoading && (
        <Box position='absolute' right={0} left={0} bottom={0} padding='1rem 0'>
          <Button variant='text' color='primary' onClick={navigate} fullWidth>
            {manageStoresText}
          </Button>
        </Box>
      )}
    </Box>
  );
}
