import { makeStyles } from '@material-ui/styles';

import { neutral } from '../../../../shared/styles/colors';

export default makeStyles(theme => ({
  header: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      padding: '1rem',
      display: 'flex',
      columnGap: '0.75rem',
      fontSize: '0.75rem',
      fontWeight: 'bold',
      fontFamily: 'Poppins',
      lineHeight: '1.25rem',
      textTransform: 'uppercase',
      color: neutral['80'],
      backgroundColor: neutral.white,
      '& p': {
        width: '100%',
        margin: 0,
      },
    },
  },
  state: {
    flexGrow: 0,
    width: '100%',
    maxWidth: '7.75rem',
    [theme.breakpoints.up('xl')]: {
      maxWidth: '10.0rem',
    },
  },
  orderId: {
    flexGrow: 0.9,
    flexBasis: '8.75rem',
  },
  dateTime: {
    flexGrow: 1.3,
    flexBasis: '12.5rem',
  },
  price: {
    flexGrow: 0.84,
    flexBasis: '7.5rem',
  },
  rt: {
    flexGrow: 1.24,
    flexBasis: '11.875rem',
  },
  additionalRT: {
    flexGrow: 0.92,
    flexBasis: '9.0rem',
    display: 'flex',
    columnGap: '0.25rem',
    alignItems: 'center',
  },
  additionalRTTooltip: {
    maxWidth: '13rem',
    padding: '0.5rem',
    fontSize: '0.75rem',
    fontWeight: 600,
    lineHeight: '1.25rem',
    borderRadius: '0.5rem',
    color: neutral.white,
    backgroundColor: neutral['90'],
  },
  qualify: {
    flexGrow: 0.85,
    flexBasis: '8.125rem',
    textAlign: 'center',
  },
  toIconOpen: {
    flexGrow: 0.2,
    flexBasis: '2.0rem',
  },
}));
