// External libs
import { handleActions } from 'redux-actions'
// Actions
import DialogsManagerActions from '../actions/dialogsManager.actions'

const initialState = {
  stack: [],
}

export default handleActions(
  {
    [DialogsManagerActions?.popStackAction]: state => popStack(state),
    [DialogsManagerActions?.pushStackAction]: (
      state,
      { payload: { dialogType } },
    ) => pushStack(state, dialogType),
  },
  initialState,
)

const popStack = state => {
  const shallowStack = [...state.stack]
  shallowStack.pop()
  return { ...state, stack: shallowStack }
}
const pushStack = (state, dialogType) => {
  const shallowStack = [...state.stack, dialogType]
  return { ...state, stack: shallowStack }
}
