// Redux
import { useDispatch } from 'react-redux';
// Material UI
import { useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
// Actions
import { actions as helpCenterActions } from '../../../../redux/help-center-page';
// Hooks
import hooks from './hooks';
// Styles
import useStyles from './styles';
import EventActions from '../../../../redux/actions/event-actions/event.actions';
import Paths from '../../../../constants/Paths';

export function CategoryButton() {
  const componentClasses = useStyles();
  const data = hooks.useData() || [];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className={componentClasses.root}>
      {data.map((category, index: number) => {
        return (
          <div
            key={`category-${index}`}
            className={componentClasses.categoryRoot}
          >
            <List component='nav'>
              <ListItem
                button
                classes={{
                  root: componentClasses.listItemRoot,
                }}
              >
                <ListItemText
                  primary={category.name}
                  onClick={() => {
                    EventActions.onSuccessfulCategory(category.id);
                    navigate(Paths.getHelpcenterCategoryPath(category.id));
                    dispatch(
                      helpCenterActions.setSelectedCategoryId(category.id),
                    );
                  }}
                  classes={{
                    root: componentClasses.listItemTextRoot,
                  }}
                />
                <ListItemIcon
                  classes={{
                    root: componentClasses.listItemIconRoot,
                  }}
                >
                  <KeyboardArrowRightIcon className={componentClasses.arrow} />
                </ListItemIcon>
              </ListItem>
            </List>
          </div>
        );
      })}
    </div>
  );
}
