// Material UI
import { makeStyles } from '@material-ui/core';
// Consts
import { neutral } from '../../shared/styles/colors';

export default makeStyles(theme => ({
  body: {
    borderTop: `1px solid ${neutral.D10}`,
    display: 'grid',
    gridAutoFlow: 'rows',
    gridRowGap: 24,
    height: '100%',
    paddingTop: 24,
    [theme.breakpoints.up('md')]: {
      padding: '40px 24px',
      borderTop: `none`,
      gridRowGap: 40,
    },
  },
  childrenContainer: {
    height: '100%',
    paddingTop: 24,
    [theme.breakpoints.up('md')]: {
      padding: '0px',
    },
  },
  description: {},
  headerRoot: {
    [theme.breakpoints.up('md')]: {
      padding: 12,
    },
  },
  paper: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      height: 608,
      width: 904,
      overflowY: 'hidden',
    },
  },
  reasonsContainer: {
    overflow: 'auto',
  },
  root: {},
  sectionLabelsContainer: {
    display: 'grid',
    fontSize: 16,
    gridAutoFlow: 'row',
    gridAutoRows: 'min-content',
    gridRowGap: 8,
    lineHeight: 'normal',
    padding: '0px 16px',
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
  },
  sectionTitle: {
    fontFamily: 'Poppins Bold',
  },
}));
