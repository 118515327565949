import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import PartnersService from '../../services/partners-service';

import MenuSearchStateMessage from './MenuSearchStateMessage';

const mapStateToProps = (
  {
    menuReducer: { filter, menus, searchedProducts, searchedToppings },
    partnerReducer: { profile },
  },
  { intl: { formatMessage } },
) => {
  const selectedStoreId = +filter.selectedStoreId;
  const selectedStoreMenu = menus[selectedStoreId];
  const stores = profile.stores ?? [];
  const title = PartnersService.getStoreName(stores, selectedStoreId);
  const showProductsList = searchedProducts && searchedProducts.length > 0;
  const showToppingsList = searchedToppings && searchedToppings.length > 0;

  let textId = 'menuSearchStateMessage.menuNoDownloaded';

  if (
    selectedStoreMenu.wasCompletelyDownloaded &&
    !showProductsList &&
    !showToppingsList
  ) {
    textId = 'menuSearchStateMessage.emptyResult';
  }

  const text = formatMessage({ id: textId });

  return { showProductsList, showToppingsList, text, title };
};

const mapDispatchToProps = {};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(MenuSearchStateMessage),
);
