import React from 'react'
// Constant
import Colors from '../../../constants/Colors'

const AllowNotificationsIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='53'
      height='53'
      viewBox='0 0 53 53'
    >
      <g fill='none' fillRule='evenodd'>
        <rect
          width='44.4'
          height='44.4'
          x='1.8'
          y='6.6'
          stroke={Colors.INFO_FONT_COLOR}
          strokeWidth='3.6'
          rx='12'
        />
        <circle cx='44.4' cy='7.8' r='7.8' fill={Colors.PRIMARY_COLOR} />
      </g>
    </svg>
  )
}

export default AllowNotificationsIcon
