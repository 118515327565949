// External libs
import { createSelector } from 'reselect';

const selectReducer = state => state.suspendedStoreAlertSnackbarReducer;

const selectMessage = createSelector(
  selectReducer,
  reducer => reducer.message,
);

const selectOpen = createSelector(
  selectReducer,
  reducer => reducer.open,
);

const SuspendedStoreAlertSnackbarSelectors = { selectMessage, selectOpen };

export default SuspendedStoreAlertSnackbarSelectors;
