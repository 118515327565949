import { makeStyles } from '@material-ui/core';

import Colors from '../../../../../constants/Colors';
import { neutral } from '../../../../../shared/styles/colors';

export const dialogStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    top: '0.25rem',
    color: 'white',
    right: '0.5rem',
  },
  cancelButton: {
    backgroundColor: neutral.white,
    color: Colors.PRIMARY_COLOR,
    borderRadius: '.5rem',
    border: `1px solid ${Colors.PRIMARY_COLOR}`,
    height: '2.625rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: neutral.white,
    },
  },
  title: {
    fontSize: '1.5rem',
    whiteSpace: 'normal',
    paddingTop: '1.2rem',
    textAlign: 'center',
  },
  readyButton: {
    height: '2.625rem',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.PRIMARY_COLOR,
    },
  },
  body: {
    flex: 'none',
    color: neutral.D20,
    fontSize: '1rem',
    height: '100%',
    lineHeight: 'normal',
    padding: '1rem 2rem',
  },
  headerRoot: {
    gridTemplateColumns: 'auto',
    [theme.breakpoints.up('md')]: {
      padding: '1.5rem',
    },
  },
  footerRoot: {
    columnGap: '0.5rem',
  },
  paper: () => ({
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      width: 'auto',
      maxWidth: '56.25rem',
      borderRadius: '0.75rem',
    },
  }),
}));
