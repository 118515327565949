import { createSlice } from '@reduxjs/toolkit';
import {
  COMPONENT_TYPE,
  CONTENT_TYPE,
  ComponentType,
  ContentType,
  DIALOG_STATE,
  DialogState,
} from '../../domain';

export interface State {
  dialog: DialogState;
  contentType: ContentType;
  component: ComponentType;
}

export const initialState: State = {
  dialog: DIALOG_STATE.idle,
  contentType: CONTENT_TYPE[''],
  component: COMPONENT_TYPE[''],
};

export const browserSlice = createSlice({
  name: 'browserDialog',
  initialState,
  reducers: {
    close: state => {
      state.dialog = DIALOG_STATE.closed;
      state.contentType = CONTENT_TYPE[''];
      state.component = COMPONENT_TYPE[''];
    },
    outside: state => {
      state.dialog = DIALOG_STATE.outside;
      state.component = COMPONENT_TYPE.banner;
    },
    dialogOpen: state => {
      state.dialog = DIALOG_STATE.isOpen;
      state.component = COMPONENT_TYPE.dialog;
    },
    dialogSeen: state => {
      state.dialog = DIALOG_STATE.seen;
      state.component = COMPONENT_TYPE.banner;
    },
    content: (state, { payload }) => {
      state.contentType = payload;
    },
  },
});

export const browserDialogActions = browserSlice.actions;
export const browserDialogName = browserSlice.name;
export const browserDialogReducer = browserSlice.reducer;
