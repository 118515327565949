// React
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// Components
import MenuSearchToppingList from './MenuSearchToppingList';

const mapStateToProps = (
  { menuReducer: { searchedToppings } },
  { intl: { formatMessage } },
) => {
  const title = formatMessage({ id: 'globals.toppings' });

  return { title, toppings: searchedToppings };
};

const mapDispatchToProps = {};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MenuSearchToppingList),
);
