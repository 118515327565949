import { formatMessage } from '../../intl/intl'

export function useOutStockMenuText({ hasOutstock, products, toppings }) {
  let quantityLabel = ''
  let descriptionLabel = ''

  if (!hasOutstock) {
    return { quantityLabel, descriptionLabel }
  }
  const productCta = formatMessage({
    id: `menuBoard.outOfStock.products`,
  })
  const toppingCta = formatMessage({
    id: `menuBoard.outOfStock.toppings`,
  })
  const productLabel = products > 1 ? productCta : productCta.slice(0, -1)
  const toppingLabel = toppings > 1 ? toppingCta : toppingCta.slice(0, -1)

  if (toppings === 0) {
    quantityLabel = `${products} ${productLabel}`
  } else if (products === 0) {
    quantityLabel = `${toppings} ${toppingLabel}`
  } else {
    quantityLabel = formatMessage(
      {
        id: 'menuBoard.outOfStock.quantity',
      },
      { x: `${products} ${productLabel}`, y: `${toppings} ${toppingLabel}` },
    )
  }
  if (products > 1 || toppings > 1 || (products === 1 && toppings === 1)) {
    descriptionLabel = formatMessage({
      id: 'menuBoard.outOfStock.descPlural',
    })
  } else {
    descriptionLabel = formatMessage({
      id: 'menuBoard.outOfStock.descSingular',
    })
  }
  return { quantityLabel, descriptionLabel }
}
