// React
import { connect } from 'react-redux';
// Actions
import dialogsManagerActions from '../../../../redux/actions/dialogsManager.actions';
// Components
import SideMenuItems from './SideMenuItems';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  onClickLogout: dialogsManagerActions.openLogOutDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SideMenuItems);
