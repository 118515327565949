// External libs
import { createSelector } from 'reselect';
// Interfaces
import { State } from '../interfaces/state';

const selectSlice: (state: any) => State = (state: any) =>
  state.cancelByStockOutSuggestionConfirmationDialog;

const selectOpen = createSelector<any, State, boolean>(
  selectSlice,
  reducer => reducer.open,
);

const selectOrderId = createSelector<any, State, number>(
  selectSlice,
  reducer => reducer.orderId,
);

const selectors = { selectOpen, selectOrderId };

export default selectors;
