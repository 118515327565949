import { clsx } from 'clsx';

import CustomActionKanbanCard from '../../components/common/custom-action-kanban-card';

import { useTurboNewOrderCard } from './hooks';

import useStyles from './NewKanbanCard.styles';

function NewKanbanCard(props) {
  const {
    classes = {},
    onClickCard,
    order = {},
    showChipOfBundle = false,
    totalStores,
  } = props;

  const { id, store_name } = order;
  const { isTurboqueue } = useTurboNewOrderCard({ order });
  const _classes = useStyles();

  return (
    <CustomActionKanbanCard
      classes={{ root: clsx(_classes.root, classes?.root) }}
      onClick={() => {
        onClickCard(id);
      }}
      orderID={id}
      storeName={totalStores > 1 ? store_name : null}
      showChipOfBundle={showChipOfBundle}
      isTurboqueue={isTurboqueue}
    />
  );
}

export default NewKanbanCard;
