import { useSelector } from 'react-redux';

import { selectors as productSuggestionAvailabilityDialog } from '../../redux/product-suggestion-availability-dialog';

const useOpen = () =>
  useSelector(productSuggestionAvailabilityDialog.selectOpen);

const useSelectedProduct = () =>
  useSelector(productSuggestionAvailabilityDialog.selectedProduct);

const useProductId = () =>
  useSelector(productSuggestionAvailabilityDialog.selectProductId);

const useProductName = () =>
  useSelector(productSuggestionAvailabilityDialog.selectProductName);

const useRefuseReason = () =>
  useSelector(productSuggestionAvailabilityDialog.selectRefuseReason);

const useRefuseReasonsDescription = () =>
  useSelector(
    productSuggestionAvailabilityDialog.selectRefuseReasonsDescription,
  );

const hooks = {
  useOpen,
  useSelectedProduct,
  useProductId,
  useProductName,
  useRefuseReason,
  useRefuseReasonsDescription,
};

export default hooks;
