import { transformToAnalyticsParams } from 'shared/infra/analytics';
import Amplitude from 'tools/amplitude/amplitude';
import type {
  PrinterCampaignParams,
  PrinterSetupParams,
} from './analytics.model';

function logPrinterCampaign(params: PrinterCampaignParams) {
  const transformedParams = transformToAnalyticsParams(params);
  Amplitude.logEvent('PRINTER_CAMPAIGN', transformedParams);
}

function logPrinterSetup(params: PrinterSetupParams) {
  const transformedParams = transformToAnalyticsParams(params);
  Amplitude.logEvent('PRINTER_SETUP', transformedParams);
}

export const printerAnalytics = {
  logPrinterCampaign,
  logPrinterSetup,
} as const;
