import { makeStyles } from '@material-ui/core/styles';

import { neutral, teal } from '../../../../../shared/styles/colors';

export default makeStyles({
  selectDateButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '7px 48px 7px 24px',
    marginBottom: '8px',
    width: '298px',
    height: '32px',
    borderRadius: '8px',
    backgroundColor: neutral['20'],
    fontSize: '10px',
    lineHeight: '18px',
    letterSpacing: '0.012em',
    '&:hover': {
      backgroundColor: neutral['30'],
    },
  },
  selectDateButtonActive: {
    backgroundColor: teal['10'],
    '&:hover': {
      backgroundColor: teal['10'],
    },
  },
  keyboardDatePickerTextField: {
    width: 0,
    margin: '0px 0px 0px 0px',
  },
});
