import { makeStyles } from '@material-ui/core';

import type { CSSProperties } from 'react';

export const printedChecklistClasses = makeStyles({
  section: {
    display: 'none',
    '@media print': {
      display: 'block',
      printColorAdjust: 'exact',
      '-webkit-print-color-adjust': 'exact' /* Chrome, Safari */,
    },
  },
});

export function printedChecklistStyles(params: PrintedChecklistStylesParams) {
  const { isActivatedComanda = false } = params ?? {};
  const STYLES: { [p: string]: CSSProperties } = {
    section: {
      fontWeight: 400,
      fontSize: '.625rem',
      fontStyle: 'normal',
      fontFamily: 'Poppins',
      // fontFamily: 'Lucida Console',
      printColorAdjust: 'exact',
      pageBreakBefore: isActivatedComanda ? 'always' : 'auto',
    },
    header: {
      fontWeight: 600,
      fontSize: '0.625rem',
      fontFamily: 'Poppins',
      textAlign: 'center',
      borderBottom: '1px solid #000',
    },
    logo: {
      filter: 'brightness(0.3)',
    },
    title: {
      margin: 0,
      fontSize: '1rem',
    },
    orderId: {
      margin: 0,
    },
    storeName: {
      margin: 0,
    },
    product: {
      marginTop: '0.25rem',
    },
    productHeader: {
      display: 'flex',
      gap: '0.5rem',
      alignItems: 'baseline',
    },
    productDescription: {
      margin: 0,
      fontWeight: 600,
      fontSize: '0.625rem',
      // fontFamily: 'Lucida Console',
      lineHeight: 1.15,
    },
    productHasDefect: {
      paddingLeft: '1.25rem',
      display: 'flex',
      alignItems: 'flex-end',
      gap: '0.25rem',
    },
    productHasDefectIcon: {
      fontSize: '0.875rem',
    },
    productHasDefectText: {
      margin: 0,
      fontSize: '0.5rem',
      textTransform: 'uppercase',
    },
    toppings: {
      margin: '0.25rem 0',
      paddingLeft: '0.875rem',
      fontSize: '0.625rem',
    },
    topping: {
      margin: 0,
      listStyle: 'none',
      lineHeight: 1.25,
    },
    comments: {
      display: 'flex',
      marginLeft: '0.875rem',
      padding: '0.25rem 0.375rem',
      fontSize: '0.5rem',
      border: '1px solid #000',
    },
    commentsCheckbox: {
      fontSize: '0.625rem',
    },
    commentsLabel: {
      margin: 0,
      fontWeight: 'bold',
    },
    commentsText: {
      margin: 0,
    },
    forRT: {
      marginTop: '1rem',
    },
    forRTTitle: {
      margin: '0 0 0.625rem',
      fontWeight: 600,
      fontSize: '0.625rem',
      lineHeight: 1.15,
    },
    forRTList: {
      margin: 0,
      padding: 0,
    },
    forRTItem: {
      marginBottom: '0.5rem',
      display: 'flex',
      columnGap: '0.5rem',
      listStyle: 'none',
    },
  };
  return STYLES;
}

interface PrintedChecklistStylesParams {
  isActivatedComanda: boolean;
}
