// React
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
// Actions
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions'
// Components
import FailedProxySessionDialog from './FailedProxySessionDialog'

const mapStateToProps = (
  { failedProxySessionDialogReducer: { open } },
  { intl: { formatMessage } },
) => {
  const title = formatMessage({ id: 'failedProxySessionDialog.title' })
  const description = formatMessage({
    id: 'failedProxySessionDialog.dialogDescription',
  })
  return {
    open,
    title,
    description,
  }
}

const mapDispatchToProps = {
  onBack: dialogsManagerActions.back,
  onClose: dialogsManagerActions.closeFailedProxySessionDialog,
}
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(FailedProxySessionDialog),
)
