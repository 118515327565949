import { CookingClock } from '../cooking-clock';
import { OrderDeliveryTimeChip } from '../../../order-delivery-time-chip';

import type { CookingClockProps } from '../cooking-clock';

export function CookingProgressTimeChip(props: Props) {
  const { cookingTime, cookingTimeStartedAt, isMarketplace = false } = props;
  const TimeChip = isMarketplace ? OrderDeliveryTimeChip : CookingClock;
  return (
    <TimeChip
      cookingTime={cookingTime}
      cookingTimeStartedAt={cookingTimeStartedAt}
    />
  );
}

interface Props extends CookingClockProps {
  isMarketplace: boolean;
}
