import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { find, get } from 'lodash';

import RTUtils from '../../utils/RTUtils';
import OrdersUtils from '../../utils/OrdersUtils';
import orderActions from '../../redux/actions/orders-actions';

import RTDetailBox from './RTDetailBox';

const mapStateToProps = (
  { rtDialogReducer: { open, orderId }, ordersReducer: { orders } },
  { intl: { formatMessage } },
) => {
  const orderDetail = get(find(orders, { id: +orderId }), ['detail'], {
    storekeeper: {},
  });

  const steps =
    orderDetail.rt_state && orderDetail.rt_state.eta_steps
      ? orderDetail.rt_state.eta_steps
      : [];

  const activeStep = RTUtils.getActiveStep(steps);
  const activeStepIndex = activeStep && activeStep.index;
  const rtStateText = activeStep && activeStep.name;

  const order = orders.find(item => item.id === orderId);
  const showRTButton = OrdersUtils.isRTNotifyAvailable(order);
  const isRtWaitingMuch = order?.rt_waiting_time_exceeded ?? false;
  const rtStateBtnText = showRTButton
    ? formatMessage({ id: 'pendingToDeliverKanbanCard.secondaryButtonText' })
    : null;

  return {
    activeStep,
    open,
    rt: orderDetail.storekeeper,
    rtStateText,
    activeStepIndex,
    rtStateBtnText,
    isRtWaitingMuch,
    orderId,
  };
};

const mapDispatchToProps = {
  sendNotificationToRT: orderActions.sendNotificationToRT,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(RTDetailBox),
);
