import { useSelector } from 'react-redux';

import orderSelectors from '../../../redux/selectors/orders-selectors';

const useSelectSupportScore = () =>
  useSelector(orderSelectors.selectSupportScore);

const hooks = {
  useSelectSupportScore,
};

export default hooks;
