import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { OrderMgmtResumeCard } from './components';
import OrderMgmtCardStyle from '../../OrderManagement.style';
import { OrderMgmtProduct } from '../../../../business/order-mgmt';
import { ErrorOutline } from '@material-ui/icons';

export const OrderMgmtResume = ({
  orderSummaryText,
  soldOutProductText,
  products,
  replaceDisclaimerText,
}: Props) => {
  const componentClasses = OrderMgmtCardStyle();

  return (
    <Box padding='0 1.5rem' width='100%'>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='flex-start'
        gridGap='1rem'
        margin='1.5rem 0'
      >
        <Typography className={componentClasses.resumeText}>
          {orderSummaryText}
        </Typography>
      </Box>
      <Box
        className={componentClasses.resumeContainer}
        width='100%'
        marginTop='1rem'
        display='flex'
        flexDirection='column'
        gridGap='0.75rem'
      >
        {products.map(product => (
          <OrderMgmtResumeCard
            key={`p-${product.id}`}
            product={product}
            soldOutProductText={soldOutProductText}
          />
        ))}
      </Box>
      <Box display='flex' alignItems='center' gridGap='0.5rem' margin='1rem 0'>
        <ErrorOutline
          fontSize='small'
          className={componentClasses.resumeDisclaimerIcon}
        />
        <Typography className={componentClasses.resumeDisclaimerText}>
          {replaceDisclaimerText}
        </Typography>
      </Box>
    </Box>
  );
};

interface Props {
  orderSummaryText: string;
  soldOutProductText: string;
  products: Array<OrderMgmtProduct>;
  replaceDisclaimerText: string;
}
