import { useMediaQuery } from '@material-ui/core';

import CustomButton from './../common/buttons/custom-button';
import { CustomAddCircleOutlineButton } from '../common/buttons/custom-add-circle-outline-button';

import useStyles from './SpecialDayDateAddButton.styles';

function SpecialDayDateAddButton({ SpecialDayButton, onClick }) {
  const _classes = useStyles();
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  return (
    <div className={_classes.root}>
      {!isMdUp && (
        <CustomAddCircleOutlineButton onClick={onClick} color='primary' />
      )}
      {isMdUp && (
        <CustomButton
          fullWidth={false}
          classes={{
            root: _classes.button,
            submitButton: _classes.submitButton,
          }}
          onClick={onClick}
        >
          {SpecialDayButton}
        </CustomButton>
      )}
    </div>
  );
}

export default SpecialDayDateAddButton;
