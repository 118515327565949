import { makeStyles } from '@material-ui/core';

import Colors from '../../../constants/Colors';

export const browserStyles = makeStyles(theme => ({
  banner: {
    backgroundColor: Colors.DANGER_LIGHT_1,
    color: Colors.WHITE,
    fontSize: '0.75rem',
    padding: '.5rem 1rem',
  },
  bannerText: {
    fontSize: 'inherit',
    flex: 1,
  },
  bannerImg: {
    height: '1.5rem',
    width: '1.5rem',
  },
  bannerBtn: {
    color: Colors.DANGER_LIGHT_1,
    backgroundColor: Colors.WHITE,
    padding: '0.25rem 0.5rem',
    fontSize: 'inherit',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.WHITE,
    },
  },
  bannerClose: {
    color: Colors.WHITE,
    fill: 'white',
    width: '1rem',
    height: '1rem',
  },
}));
