import {
  OrderMgmtProduct,
  orderMgmtTimeoutCase,
} from '../../../../../../business/order-mgmt';

export const useProductCard = ({ product }: Props) => {
  const {
    isEnabledToDelete,
    removeProduct,
    removeTopping,
  } = orderMgmtTimeoutCase();
  const hasProductItemSuggestions = (product?.suggestions || []).length > 0;
  const hasProductItemToppingSuggestions = (product?.toppings || []).some(
    topping => topping?.suggestions,
  );

  const deleteProduct = product => {
    removeProduct(product);
  };
  const deleteTopping = topping => {
    removeTopping(topping);
  };

  return {
    hasProductItemSuggestions,
    deleteProduct,
    deleteTopping,
    hasProductItemToppingSuggestions,
    isEnabledToDelete,
  };
};

type Props = {
  product?: OrderMgmtProduct;
};
