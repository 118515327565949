// import * as firebase from 'firebase';
// External libs
import firebase from 'firebase/app';
import '@firebase/auth';
import '@firebase/database';
import '@firebase/messaging';
import { get } from 'lodash';
// Consts
import { PushNotificationType } from '../constants/PushNotificationType';
// Services
import IndexedDBService from '../services/IndexedDBService';
import LocalStorageService from '../services/local-storage-service';
import NotificationsService from '../services/NotificationsService';
import PartnersService from '../services/partners-service';
import * as serviceWorker from '../serviceWorker';

// Actions
import OrdersActions from '../redux/actions/orders-actions';
import PartnersActions from '../redux/actions/partners-actions';
import appStore from '../redux/stores/app.store';
import AuthService from '../services/AuthService';
import RealTimeService from '../services/RealTimeService';
import Paths from '../constants/Paths';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_CLOUD_MESSAGING_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_CLOUD_MESSAGING_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_CLOUD_MESSAGING_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_CLOUD_MESSAGING_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_CLOUD_MESSAGING_STORAGE_BUCKET,
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_CLOUD_MESSAGING_MESSAGING_SENDER_ID,
};

let messaging;

const deleteToken = async () => {
  const currentTokenInLocalStorage = LocalStorageService.getFCMToken();
  await messaging.deleteToken(currentTokenInLocalStorage);
};

const registerSWToFCM = registration => {
  console.log('r sw to fcm');
  messaging.useServiceWorker(registration);
};

const initialize = async () => {
  // we need to check if messaging is supported by the browser
  if (!firebase.messaging.isSupported()) {
    return;
  }

  try {
    if (!firebase.apps.find(app => app.name === '[DEFAULT]')) {
      // Initialize FCM instances
      console.log('initializing fcm instances');
      const appInstance = firebase.initializeApp(firebaseConfig);
      await loginFirebaseApp(appInstance);
      messaging = firebase.messaging();
    }

    if ('serviceWorker' in navigator) {
      // Get service worker registration.
      console.log('waiting until sw is ready');
      const registration = await navigator.serviceWorker.ready;
      console.log('sw is ready (fr)');
      // This is necessary to ensure that when a new service worker is deployed, it starts firebase connection without requering re login for the user.
      navigator.serviceWorker.controller.postMessage({
        command: 'INITIALIZE_FIREBASE',
        firebaseConfig,
        webUrl: process.env.REACT_APP_WEB_URL,
      });
      console.log(
        'Get service worker registration on scope',
        registration.scope,
      );
      // This event fires when the web app is on background and SW is working.
      navigator.serviceWorker.addEventListener('message', async function (
        event,
      ) {
        // Receive and process all messages from SW
        await executeMessage(get(event, 'data', null));
      });
      // Get subscription
      const subscription = await registration.pushManager.getSubscription();

      const currentTokenInLocalStorage = LocalStorageService.getFCMToken();
      if (!subscription && !currentTokenInLocalStorage) {
        registerSWToFCM(registration);
      }
    }

    await getTokenAndRegisterToken();
  } catch (ex) {
    console.error(ex);
    console.log('Retrying in 1 min...');
    setTimeout(() => {
      console.log('Retrying...');
      initialize();
    }, 60000);
    return;
  }

  messaging.onTokenRefresh(function () {
    messaging
      .getToken()
      .then(async function (refreshedToken) {
        console.log('Token refreshed.');
        await PartnersService.registerFCMToken(refreshedToken);
        LocalStorageService.setFCMToken(refreshedToken);
      })
      .catch(function (err) {
        console.log('Unable to retrieve refreshed token ', err);
      });
  });

  messaging.onMessage(async payload => {
    await executeMessage(payload);
  });
};

const executeMessage = async payload => {
  console.log('executeMessage', payload);
  const data = get(payload, 'data', null);
  const event = get(data, 'event', null);

  if (!event) {
    console.error('No event received:', payload);
    return false;
  }

  if (!AuthService.isAuthenticated()) {
    return false;
  }

  switch (event) {
    case PushNotificationType.CANCELED_FROM_USER_TO_PARTNER:
      await executeCanceledOrderMessage(data);
      break;
    case PushNotificationType.CANCELED_IN_CMS_TO_PARTNER:
      await executeCanceledOrderMessage(data);
      break;
    case PushNotificationType.OPEN_STORES:
      await PartnersActions.setIsPendingTurnPartnerOnBySchedule()(
        appStore.store.dispatch,
      );
      break;
    default:
      console.error('Unknown event received:', payload);
      return false;
  }
  return true;
};

const executeCanceledOrderMessage = async data => {
  if (!data) {
    return;
  }

  const alert = get(data, 'alert', null);
  const orderId = get(data, 'order_id', null);

  NotificationsService.spawnNotification(
    alert,
    'Orden cancelada',
    `${Paths.ORDERS_MANAGEMENT}/${orderId}`,
  );

  if (!orderId) {
    console.error(`no orderId received in push.`);
    return;
  }

  await IndexedDBService.saveCanceledOrder(orderId);
  await OrdersActions.getOrders()(appStore.store.dispatch);
};

const getTokenAndRegisterToken = async () => {
  const currentTokenInLocalStorage = LocalStorageService.getFCMToken();
  if (!currentTokenInLocalStorage) {
    // If there is no FCM token set, get one.
    const token = await messaging.getToken();
    try {
      await PartnersService.registerFCMToken(token);
    } catch (error) {
      // If the registration fails, return and don't save the token in localstorage
      console.error(error);
      return;
    }

    // Ensure that the token is only save after it was registered.
    // Otherwise, the service could fail and the token will never be registered, so the app will never receive pushes.
    // If the service fails, when the app reloads, it will detect no token, so it will try to register it again.
    LocalStorageService.setFCMToken(token);
  }
};

const unRegisterServiceWorker = () => {
  serviceWorker.unregister();
};

const getPermission = async () => {
  if (!('Notification' in window) || !('serviceWorker' in navigator)) {
    return alert('Tu browser no soporta notificaciones');
  }

  if (Notification.permission === 'default') {
    await Notification.requestPermission();
  }
};

const loginFirebaseApp = async app => {
  const {
    data: { access_token },
  } = await RealTimeService.loginFirebase();

  await firebase.auth(app).signInWithCustomToken(access_token);
};

const logoutAll = async () => {
  for (const app of firebase.apps) {
    await firebase.auth(app).signOut();
  }
};

const startListeningRTDatabase = async (channel, database, callback) => {
  let appInstance = firebase.apps.find(app => app.name === database);
  if (!appInstance) {
    const RTFirebaseConfig = { ...firebaseConfig, databaseURL: database };
    appInstance = firebase.initializeApp(RTFirebaseConfig, database);
  }

  await loginFirebaseApp(appInstance);
  appInstance
    .database()
    .ref(channel)
    .on('value', snapshot => callback(snapshot));
};

const startListeningOrderSupportChat = async (database, orderId, callback) => {
  const country = LocalStorageService.getCountryCode().toLocaleLowerCase();
  const channel = `${country}/orders/${orderId}/chats/tablet_support`;

  let appInstance = firebase.apps.find(app => app.name === database);

  if (!appInstance) {
    const orderSupportChatFirebaseConfig = {
      ...firebaseConfig,
      databaseURL: database,
    };
    appInstance = firebase.initializeApp(
      orderSupportChatFirebaseConfig,
      database,
    );
    await loginFirebaseApp(appInstance);
  }

  appInstance
    .database()
    .ref(channel)
    .on('value', snapshot => callback(snapshot));
};

const startListeningOrderSupportNotifications = async (
  database,
  orderId,
  callback,
) => {
  const country = LocalStorageService.getCountryCode().toLocaleLowerCase();
  const channel = `${country}/orders/${orderId}/notifications`;

  let appInstance = firebase.apps.find(app => app.name === database);

  if (!appInstance) {
    const orderSupportNotificationsFirebaseConfig = {
      ...firebaseConfig,
      databaseURL: database,
    };
    appInstance = firebase.initializeApp(
      orderSupportNotificationsFirebaseConfig,
      database,
    );
    await loginFirebaseApp(appInstance);
  }

  appInstance
    .database()
    .ref(channel)
    .on('value', snapshot => callback(snapshot));
};

const Firebase = {
  executeMessage,
  firebaseConfig,
  initialize,
  messaging,
  getPermission,
  logoutAll,
  startListeningRTDatabase,
  startListeningOrderSupportChat,
  startListeningOrderSupportNotifications,
  unRegisterServiceWorker,
  deleteToken,
};

export default Firebase;
