import { useCallback, useState } from 'react';

import { useFormatMessage } from '../../../../intl/intl';
import { CustomCancelButton } from '../../../common/buttons/custom-cancel-button';
import { CustomCheckCircleButton } from '../../../common/buttons/custom-check-circle-button';
import { CustomResponsiveTimePicker } from '../../../common/custom-responsive-time-picker';

import textIds from './textIds';
import useStyles from './styles';

export function BeingEditedInterval(props: Props) {
  const {
    listOfHours = [],
    onClickCancelButton = () => {},
    onClickCheckButton = () => {},
    onChangeStartInterval,
    onChangeEndInterval,
    startTime = '',
    endTime = '',
  } = props;

  const componentClasses = useStyles();
  const tooltipTitle = useFormatMessage(textIds.tooltiptitle);
  const [beingHovered, setBeingHovered] = useState(false);
  const [beingRemovingHover, setBeingRemovingHover] = useState(false);
  const [beingRemovingHoverTimeOut, setBeingRemovingHoverTimeOut] = useState<
    NodeJS.Timeout
  >();

  const handleHoverToolTip = useCallback(
    e => {
      e.preventDefault();
      if (!beingRemovingHover) {
        setBeingHovered(true);
      }
    },
    [beingRemovingHover],
  );

  const handleHoverCloseToolTip = useCallback(
    e => {
      e.preventDefault();
      setBeingRemovingHover(true);
      if (beingRemovingHoverTimeOut) {
        clearTimeout(beingRemovingHoverTimeOut);
      }
      setBeingRemovingHoverTimeOut(
        setTimeout(() => {
          console.log('timeout');
          setBeingRemovingHover(false);
        }, 500),
      );
      setBeingHovered(false);
    },
    [beingRemovingHoverTimeOut],
  );

  return (
    <div className={componentClasses.root}>
      <div className={componentClasses.inputContainer}>
        <CustomResponsiveTimePicker
          classes={{ root: componentClasses.inputReadOnlyStart }}
          listOfHours={listOfHours}
          value={startTime}
          onHandleChange={onChangeStartInterval}
        />
        <span className={componentClasses.divider}>a</span>
        <CustomResponsiveTimePicker
          classes={{ root: componentClasses.inputReadOnlyEnd }}
          listOfHours={listOfHours}
          value={endTime}
          onHandleChange={onChangeEndInterval}
        />
      </div>
      <div className={componentClasses.buttonContainer}>
        <CustomCancelButton
          classes={{
            button: componentClasses.deleteButton,
            icon: componentClasses.icon,
          }}
          disabled={false}
          onClick={onClickCancelButton}
          color='secondary'
        />
        <CustomCheckCircleButton
          classes={{
            button: componentClasses.checkButton,
            icon: componentClasses.icon,
          }}
          disabled={false}
          onClick={onClickCheckButton}
          color='primary'
          isArrowShown={true}
          titleCheckToolTip={tooltipTitle}
          openToolTip={beingHovered}
          onOpenToolTip={handleHoverToolTip}
          onCloseToolTip={handleHoverCloseToolTip}
        />
      </div>
    </div>
  );
}

interface Props {
  listOfHours: any[];
  startTime: string;
  endTime: string;
  onClickCancelButton: () => void | Promise<void>;
  onClickCheckButton: () => void | Promise<void>;
  onChangeStartInterval: (time: string) => void;
  onChangeEndInterval: (time: string) => void;
}
