export enum orderMgmtStatus {
  Idle = 'idle',
  Pending = 'pending',
  Successful = 'successful',
  EmptyResponse = 'empty',
  Rejected = 'rejected',
}

export enum orderMgmtCurrentScreen {
  Idle,
  Dialog,
  Search,
  Modifications,
  Resume,
}

export enum orderMgmtState {
  Idle = 'idle',
  DeactivateItems = 'replace_without_editing',
  SendUserApproval = 'replace_with_approval',
  NoUserResponse = 'user_didnt_respond',
}
