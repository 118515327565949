import { connect } from 'react-redux';

import OrdersUtils from '../../utils/OrdersUtils';
import AppActions from '../../redux/actions/app-actions';
import dialogsManagerActions from '../../redux/actions/dialogsManager.actions';

import { OrderDetailCard } from './OrderDetailCard';

// TODO: refact to hook
/* WIP hook
  const orders = () =>
    useSelector(ordersSelector.selectProductsInOrderDetailDialog)
  const useOrderId = () => useSelector(orderDetailDialogSelectors.selectOrderId)
*/
// ? how to get the product value
const mapStateToProps = (
  { ordersReducer: { orders }, orderDetailDialogReducer: { orderId } },
  { product },
) => {
  const order = orders.find(order => order.id === +orderId) || {
    id: 0,
    detail: { id: 0, products: [], user: {}, whims: [] },
  };
  const orderDetail = order.detail ?? {};
  const productComments = product?.comments ?? '';
  const showAddWhimsButton =
    (order?.beingManaged ?? false) &&
    !OrdersUtils.isOrderIntegrated(orderDetail) &&
    Boolean(productComments);
  const deactivated = orderDetail?.deactivated_products_and_toppings ?? {};
  const deactivateProductIds = deactivated?.product_ids ?? [];
  const deactivateToppingIds = deactivated?.topping_ids ?? [];

  return {
    showAddWhimsButton,
    orderId,
    deactivateProductIds,
    deactivateToppingIds,
  };
};

const mapDispatchToProps = {
  openSnackBarError: AppActions.openSnackBarError,
  openWhimDialog: dialogsManagerActions.openOrderWhimDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailCard);
