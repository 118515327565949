import { PWA_INSTALL_STATUS } from './Browser.const';

export const CONTENT_TYPE = {
  ['']: '',
  browser: 'browser',
  extension: 'extension',
} as const;

export const COMPONENT_TYPE = {
  ['']: '',
  banner: 'banner',
  dialog: 'dialog',
} as const;

export const DIALOG_STATE = {
  idle: 'idle',
  isOpen: 'isOpen',
  seen: 'seen',
  closed: 'closed',
  outside: 'outside',
} as const;

export type ContentType = keyof typeof CONTENT_TYPE;

export type DialogState = keyof typeof DIALOG_STATE;

export type ComponentType = keyof typeof COMPONENT_TYPE;

export type PWAInstallStatus = keyof typeof PWA_INSTALL_STATUS;
