import { useSelector } from 'react-redux';
import { ORDER_STATE_NAME } from '../../../../store.config';
import { CheckoutRTState } from '../../../domain';
import { CHECKOUT_RT_SLICE_NAME } from './checkoutRT.const';

const _getState = (s: any): CheckoutRTState =>
  s[ORDER_STATE_NAME][CHECKOUT_RT_SLICE_NAME];

export const checkoutRTSelectors = {
  status: () => useSelector(s => _getState(s).status),
  currentData: () => useSelector(s => _getState(s).currentData),
};
