// External libs
import { createSelector } from 'reselect'

const selectSlice = (state: any) => state.productSuggestionAvailabilityDialog

const selectOpen = createSelector(selectSlice, reducer => reducer.open)

const selectedProduct = createSelector(
  selectSlice,
  reducer => reducer.selectedProduct,
)
const selectRefuseReason = createSelector(
  selectSlice,
  reducer => reducer.refuseReason,
)
const selectRefuseReasonsDescription = createSelector(
  selectSlice,
  reducer => reducer.refuseReasonsDescription,
)

const selectProductId = createSelector(
  selectSlice,
  reducer => reducer.productId,
)
const selectProductName = createSelector(
  selectSlice,
  reducer => reducer.productName,
)
const selectOrderId = createSelector(selectSlice, reducer => reducer.orderId)

const selectors = {
  selectOpen,
  selectProductId,
  selectProductName,
  selectedProduct,
  selectRefuseReason,
  selectRefuseReasonsDescription,
  selectOrderId,
}

export default selectors
