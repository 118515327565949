import { Rating } from '@material-ui/lab';
import { Typography } from '@material-ui/core';

import CustomTextField from '../custom-text-field';
import CustomButton from '../buttons/custom-button';

import useStyles from './styles';

import type { ChangeEvent } from 'react';

export function CustomRating(props: Props) {
  const {
    titleLabel,
    maxLengthChar,
    minLengthChar,
    placeHolderBox,
    buttonTextLabel,
    onHandleConfirmRate,
    handleFeedBackRate,
    handleFeedBackText,
    showContainerFeedback,
    showButton,
    feedback,
    value,
  } = props;

  const _classes = useStyles();

  return (
    <div className={_classes.root}>
      <div className={_classes.container}>
        <Typography component='legend'>{titleLabel} </Typography>
        <Rating
          name='simple-controlled'
          value={value}
          onChange={handleFeedBackRate}
        />
      </div>
      {showContainerFeedback && (
        <>
          <CustomTextField
            multiline
            rowsMax={1}
            classes={{ root: _classes.feedbackBox }}
            minLength={minLengthChar}
            maxLength={maxLengthChar}
            placeholder={placeHolderBox}
            onChange={handleFeedBackText}
            value={feedback}
          ></CustomTextField>

          {showButton && (
            <CustomButton
              classes={{ root: _classes.button }}
              text={buttonTextLabel}
              fullWidth={false}
              onClick={onHandleConfirmRate}
            ></CustomButton>
          )}
        </>
      )}
    </div>
  );
}

interface Props {
  titleLabel: string;
  maxLengthChar: number;
  minLengthChar: number;
  placeHolderBox: string;
  buttonTextLabel: string;
  onHandleConfirmRate: () => void;
  handleFeedBackRate: (ev: any, value: any) => void;
  handleFeedBackText: (event: ChangeEvent<HTMLInputElement>) => void;
  showContainerFeedback: boolean;
  value: number;
  feedback: string | null;
  showButton: boolean;
}
