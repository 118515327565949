import { useSelector } from 'react-redux';
import { turboHelper } from '../../../business/order/geoqueue';
import { ordersKanbanSelector } from '../../../redux/selectors/orders-kanban';

export function usePendingToAcceptColumn() {
  const _selectPendingOrders = useSelector(ordersKanbanSelector.pendingOrders);
  const canceledOrders = useSelector(ordersKanbanSelector.canceledOrders) ?? [];

  const getSortedPendingOrders = () => {
    const sorted = [
      ..._selectPendingOrders.sort((a, b) =>
        a?.state_order_management ? 1 : b?.state_order_management ? -1 : 0,
      ),
    ];
    return turboHelper.filterV2([...sorted])
  };

  const showEmpty =
    canceledOrders.length === 0 && _selectPendingOrders.length === 0;

  return {
    pendingOrders: getSortedPendingOrders(),
    canceledOrders,
    showEmpty,
  };
}
