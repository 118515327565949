// Material UI
import { makeStyles } from '@material-ui/core';
import { neutral } from '../../../../shared/styles/colors';

export default makeStyles({
  button: {
    border: `1px solid ${neutral.D6}`,
    backgroundColor: neutral.D6,
  },
  icon: {
    color: neutral.black,
  },
});
