import { useFormatMessage } from '../../../../intl/intl'

function useTexts() {
  return {
    filters: useFormatMessage({ id: 'storeManager.filters' }),
    placeholderOfSearch: useFormatMessage({ id: 'storeManager.searchText' }),
  }
}

export const hooks = {
  useTexts,
}
