// Redux
import { createActions } from 'redux-actions';
import appStore from '../stores/app.store';
import { formatMessage } from '../../intl/intl';
// Utils
import OrdersUtils from '../../utils/OrdersUtils';

const {
  closeOrderDetailDialogAction,
  clearOrderDetailDialogAction,
  openOrderDetailDialogAction,
  setErrorOrderDetailDialogAction,
  setLoadingOrderDetailDialogAction,
} = createActions({
  CLOSE_ORDER_DETAIL_DIALOG_ACTION: () => {},
  CLEAR_ORDER_DETAIL_DIALOG_ACTION: () => {},
  OPEN_ORDER_DETAIL_DIALOG_ACTION: orderId => ({ orderId }),
  SET_ERROR_ORDER_DETAIL_DIALOG_ACTION: (isError, message, title) => ({
    isError,
    message,
    title,
  }),
  SET_LOADING_ORDER_DETAIL_DIALOG_ACTION: isLoading => ({ isLoading }),
});

const clearOrderDetailDialog = () => dispatch => {
  dispatch(clearOrderDetailDialogAction());
};

const closeOrderDetailDialog = () => dispatch => {
  dispatch(closeOrderDetailDialogAction());
};

const openOrderDetailDialog = orderId => dispatch => {
  dispatch(openOrderDetailDialogAction(orderId));
};

const setErrorOrderDetailDialog = (isError, message, title) => dispatch => {
  const isOrderDetailDialogOpened = appStore.isOrderDetailDialogOpened();

  if (!isOrderDetailDialogOpened) {
    return;
  }
  let messageToSet = message;
  if (!messageToSet) {
    messageToSet = formatMessage({
      id: 'orderDetailDialogErrorBody.description',
    });
  }

  let titleToSet = title;
  if (!titleToSet) {
    titleToSet = formatMessage({
      id: 'orderDetailDialogErrorBody.title',
    });
  }

  dispatch(setErrorOrderDetailDialogAction(isError, messageToSet, titleToSet));
};

const setIsOrderBeingIntegrated = () => dispatch => {
  const order = appStore.getOrderDetailInOrderDetailDialog();

  if (!order) {
    return;
  }

  const beingIntegrated = OrdersUtils.isBeingIntegrated(order);

  if (!beingIntegrated) {
    return;
  }

  const message = formatMessage({
    id: 'integratedStore.content',
  });
  const title = formatMessage({
    id: 'integratedStore.message',
  });

  setErrorOrderDetailDialog(true, message, title)(dispatch);
};

const setLoadingOrderDetailDialog = isLoading => dispatch => {
  dispatch(setLoadingOrderDetailDialogAction(isLoading));
};

export default {
  clearOrderDetailDialog,
  clearOrderDetailDialogAction,
  closeOrderDetailDialog,
  closeOrderDetailDialogAction,
  openOrderDetailDialog,
  openOrderDetailDialogAction,
  setErrorOrderDetailDialog,
  setErrorOrderDetailDialogAction,
  setIsOrderBeingIntegrated,
  setLoadingOrderDetailDialog,
  setLoadingOrderDetailDialogAction,
};
