//React
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// Actions
import partnersActions from '../../redux/actions/partners-actions';
// Components
import ClosedPartnerStateDialog from './ClosedPartnerStateDialog';

const getDescription = (formatMessage, totalStores) => {
  const id =
    totalStores > 1
      ? 'closedPartnerStateDialog.description.plural'
      : 'closedPartnerStateDialog.description';
  return formatMessage({
    id,
  });
};

const getListSupportPhone = (formatMessage, totalStores, supportPhone) => {
  const id =
    totalStores > 1
      ? 'closedPartnerStateDialog.clarification.plural'
      : 'closedPartnerStateDialog.clarification';
  return formatMessage(
    {
      id,
    },
    {
      phone: supportPhone,
    },
  );
};

const mapStateToProps = (
  {
    partnerReducer: {
      isClosedPartnerStateDialogOpened,
      profile: { stores, id: partnerId, support_phone: supportPhone },
    },
  },
  { intl: { formatMessage } },
) => {
  const confirmText = formatMessage({
    id: 'closedPartnerStateDialog.confirmText',
  });
  const title = formatMessage({ id: 'closedPartnerStateDialog.title' });

  const totalStores = (stores && stores.length) || 0;

  const description = getDescription(formatMessage, totalStores);
  const clarification = getListSupportPhone(
    formatMessage,
    totalStores,
    supportPhone,
  );

  return {
    clarification,
    confirmText,
    description,
    open: isClosedPartnerStateDialogOpened,
    title,
    partnerId,
  };
};

const mapDispatchToProps = {
  onClose: partnersActions.closeClosedPartnerStateDialog,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ClosedPartnerStateDialog),
);
