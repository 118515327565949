// External libs
import { createReducer } from '@reduxjs/toolkit';
// Interfaces
import { State } from '../interfaces';
// Actions
import actions from '../actions';

const defaultOrderId: number = 0;

const initialState: State = {
  orderId: defaultOrderId,
  open: false,
  refuseReason: {},
};

export default createReducer(initialState, {
  [actions.clearAction.type]: state => clear(state),
  [actions.closeAction.type]: state => close(state),
  [actions.openAction.type]: (state, { payload: { orderId, refuseReason } }) =>
    open(state, orderId, refuseReason),
});

const clear = (state: State) => {
  return initialState;
};

const close = (state: State) => {
  state.open = false;
  return state;
};

const open = (
  state: State,
  orderId: number = state.orderId,
  refuseReason: any = state.refuseReason,
) => {
  state.open = true;
  state.orderId = orderId;
  state.refuseReason = refuseReason;
  return state;
};
