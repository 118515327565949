// External libs
import produce from 'immer';
import { get } from 'lodash';
// Consts
import PWEXCodes from '../consts/PWEXCodes';
// Utils
import { formatMessage } from '../../intl/intl';
import { buildGAData } from '../../utils/ga-utils';
import StringUtils from '../../utils/StringUtils';

const formatEx = (ex, partnerId = '') => {
  if (!ex) {
    return null;
  }
  const PWEXCode = get(ex, 'PWEXCode', null);
  const params = get(ex, 'params', null);
  Object.defineProperty(ex, 'message', {
    writable: true,
    configurable: true,
    ...ex.message,
  });
  return produce(ex, exDraft => {
    exDraft.message = Utils.getMessage(exDraft);
    exDraft.GAData = buildGAData(PWEXCode, exDraft.message, params, partnerId);
  });
};

const getDefaultExceptionMessage = () => {
  const formatMessageObj = {
    id: PWEXCodes.PWEX0,
  };
  return formatMessage(formatMessageObj);
};

const getNetworkErrorMessage = () => {
  const formatMessageObj = {
    id: 'networkError.message',
  };
  return formatMessage(formatMessageObj);
};

const getMessage = ex => {
  if (!ex) {
    return Utils.getDefaultExceptionMessage();
  }

  const formatMessageObj = get(ex, 'formatMessageObj', null);

  if (!!formatMessageObj) {
    const params = get(ex, 'params', null);
    return formatMessage(formatMessageObj, params);
  }

  let responseDataMsg = get(ex, 'response.data.msg', null);
  if (!StringUtils.isNullOrEmpty(responseDataMsg)) {
    return responseDataMsg;
  }

  responseDataMsg = get(ex, 'data.msg', null);
  if (!StringUtils.isNullOrEmpty(responseDataMsg)) {
    return responseDataMsg;
  }

  let responseDataErrorMessage = get(ex, 'response.data.error.message', null);
  if (!StringUtils.isNullOrEmpty(responseDataErrorMessage)) {
    return responseDataErrorMessage;
  }

  responseDataErrorMessage = get(ex, 'data.error.message', null);
  if (!StringUtils.isNullOrEmpty(responseDataErrorMessage)) {
    return responseDataErrorMessage;
  }

  let responseDataError = get(ex, 'response.data.error', null);
  if (!StringUtils.isNullOrEmpty(responseDataError)) {
    return responseDataError;
  }

  responseDataError = get(ex, 'data.error', null);
  if (!StringUtils.isNullOrEmpty(responseDataError)) {
    return responseDataError;
  }

  const message = get(ex, 'message', null);
  if (!StringUtils.isNullOrEmpty(message)) {
    return message !== 'Network Error' ? message : getNetworkErrorMessage();
  }
  return Utils.getDefaultExceptionMessage();
};

const Utils = {
  formatEx,
  getDefaultExceptionMessage,
  getMessage,
  getNetworkErrorMessage,
};

export default Utils;
