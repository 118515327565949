import { makeStyles } from '@material-ui/core';

import Colors from '../../../../../../constants/Colors';

export default makeStyles({
  container: {
    height: 'calc(100vh - 32rem)',
    overflow: 'auto',
  },
  empty: {
    margin: '0 auto',
    textAlign: 'center',
  },
  error: {
    margin: '1rem 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
  },
  errorFigure: {
    width: 'fit-content',
    margin: 'auto',
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: Colors.DANGER_LIGHT_COLOR,
  },
});
