import { CircularProgress, Typography } from '@material-ui/core';

import makeClasses from './CircularProgressWithLabel.style';

import type { CircularProgressProps } from '@material-ui/core';

export function CircularProgressWithLabel(props: Props) {
  const {
    label = '',
    value = 0,
    thickness = 4,
    size = '2rem',
    statusTime,
    ...extraCircularProgressProps
  } = props;

  const styles = makeClasses({ statusTime });

  return (
    <div className={styles.container}>
      <div className={[styles.toBackground, styles.specialMargin].join(' ')}>
        <CircularProgress
          value={100}
          size='100%'
          color='secondary'
          variant='static'
          thickness={thickness - 1}
          className={styles.circularBase}
        />
      </div>

      <div className={styles.toBackground}>
        <Typography variant='caption' className={styles.label}>
          {label}
        </Typography>
      </div>

      <CircularProgress
        size={size}
        color='primary'
        variant='static'
        thickness={thickness}
        value={Math.ceil(value)}
        className={styles.circularProgress}
        {...extraCircularProgressProps}
      />
    </div>
  );
}

interface Props extends CircularProgressProps {
  label: string;
  statusTime: string;
}
