import { OPTIONS_TO_ANALYTICS } from '../constants';
import EventActions from '../../../redux/actions/event-actions/event.actions';

import type { StoreId } from '../models';

export const eventsRemoteAnalytics = {
  close: ({ storeId = NaN }: StoreId) => {
    EventActions.onClickSuspensionAlert({
      storeId,
      options: OPTIONS_TO_ANALYTICS.close,
    });
  },

  modeInfo: ({ storeId = NaN }: StoreId) => {
    EventActions.onClickSuspensionAlert({
      storeId,
      options: OPTIONS_TO_ANALYTICS.moreInfo,
    });
  },

  activeStore: ({ storeId = NaN }: StoreId) => {
    EventActions.onClickSuspensionAlert({
      storeId,
      options: OPTIONS_TO_ANALYTICS.activeStore,
    });
  },
};
