// External libs
import { createAction } from '@reduxjs/toolkit';

const closeAction = createAction('startPMFlowDialog/close');

const clearAction = createAction('startPMFlowDialog/clear');

const maximizeAction = createAction('startPMFlowDialog/maximize');

const openAction = createAction<{
  storeName: string;
  formId: number;
  storeId: number;
}>('startPMFlowDialog/open');

const clear = () => (dispatch: any) => {
  dispatch(clearAction());
};

const close = () => (dispatch: any) => {
  dispatch(closeAction());
};

const maximize = () => (dispatch: any) => dispatch(maximizeAction());

const open = (storeName: string, formId: number, storeId: number) => (
  dispatch: any,
) => {
  dispatch(openAction({ storeName, formId, storeId }));
};
const actions = {
  clear,
  clearAction,
  close,
  closeAction,
  maximize,
  maximizeAction,
  open,
  openAction,
};

export default actions;
